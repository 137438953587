import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AuthenticationService,
    BetsnapdetailService,
    CurrencyFormatPipe,
    DecimalPrizePipe,
    ErrorService,
    GoogleAnalyticsService,
    HintService,
    MyModalService, MyNotificationsService,
    MyTranslateService,
    OneSignalService,
    ProfileService, TenantImageTypeEnum,
    TenantService
} from '../../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {ABetsnapdetailDetailsComponent} from '../a-betsnapdetail-details-component';
import {take, takeWhile} from 'rxjs/operators';
import {NotificationType} from 'angular2-notifications';
import {
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse
} from '../../../../../api';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';

@Component({
    selector: 'betsnaps-betsnapdetail-details-default',
    templateUrl: './betsnapdetail-details-default.component.html',
    styles: [],
})
export class BetsnapdetailDetailsDefaultComponent extends ABetsnapdetailDetailsComponent {

    @ViewChild('modalJoinedTemplate') modalJoinedTemplate: ModalTemplate<null, string, string>;

    public modalJoined = null;
    public joinModalImage: string;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected myModalService: MyModalService,
                public translations: MyTranslateService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                public tenantService: TenantService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected profileService: ProfileService,
                protected errorService: ErrorService,
                protected decimalPrizePipe: DecimalPrizePipe,
                protected currencyFormatPipe: CurrencyFormatPipe,
                protected oneSignalService: OneSignalService) {
        super(
            betsnapdetailService,
            authenticationService,
            router,
            route,
            myModalService,
            translations,
            myNotificationsService,
            translateService,
            tenantService,
            hintService,
            googleAnalyticsService,
            profileService,
            errorService,
            decimalPrizePipe,
            currencyFormatPipe,
            oneSignalService
        );
    }

    ngOnInit() {
        // set join widget image
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.JOIN_MODAL_IMAGE)) {
            this.joinModalImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.JOIN_MODAL_IMAGE).media_url;
        } else {
            this.joinModalImage = './assets/img/img_winner.png';
        }
    }

    protected showApproveJoin() {
        this.joinApproveVisible = true;
    }

    protected hideApproveJoin() {
        this.joinApproveVisible = false;
    }

    approveJoin() {
        if (!this.processJoin) {
            if (this.currentUser && !this.game.is_current_user_joined) {
                if (!this.currentUser.username || !this.currentUser.is_username_confirmed) {
                    this.myModalService.openUsernameInputModal().onApprove(() => this.approveJoin());
                } else {
                    if (this.currentUser.wallets[0].balance < this.game.entry_fee) {
                        this.translateService.get(['GENERAL.LABELS.alert', 'GENERAL.GAMES.insufficient_amount_to_join'])
                            .pipe(take(1)).subscribe(
                            translation => {
                                this.myNotificationsService.createNotificationToast(translation['GENERAL.LABELS.alert'], translation['GENERAL.GAMES.insufficient_amount_to_join'], NotificationType.Alert);
                            });
                    } else {
                        if (this.game.confirm_join_process) {
                            this.showApproveJoin();
                        } else {
                            this.joinGame();
                        }
                    }
                }
            } else {
                const loginRegisterModal = this.myModalService.openLoginRegisterModal('login', null, true, 'game - details');
                if (loginRegisterModal) {
                    loginRegisterModal.onApprove(() => {
                        this.showLoader = true;
                        localStorage.setItem('join-force-' + this.game.game_unique_id, 'true');
                        this.betsnapdetailService.getGameData(this.game.game_unique_id);
                    });
                }
            }
        }
    }

    public joinGame() {
        if (!this.processJoin) {
            this.processJoin = true;

            const subscribeForGameNotifications = (
                this.authenticationService.currentUser && this.game &&
                this.showGameNotificationsCheckbox &&
                this.gameNotificationsForm &&
                this.gameNotificationsForm.value.game_notifications_not_joined &&
                this.oneSignalService.webPushAvailableForTenant
            );

            this.betsnapdetailService.joinGame(this.game, this.authenticationService.currentUser.id, subscribeForGameNotifications)
                .pipe(take(1))
                .subscribe({
                    next: (leaderboardUserHttpResponse: LeaderboardUserHttpResponse) => {
                        this.hideApproveJoin();
                        this.processJoin = false;

                        if (subscribeForGameNotifications) {
                            this.oneSignalService.subscribeForWebPushNotifications();
                        }

                        if (this.tenantService.tenantData.configuration.show_join_success_modal && !this.betsnapdetailService.widgetBets) {
                            this.openModalJoined();
                        } else {
                            this.translateService.get([
                                'GENERAL.GAMES.join-modal_heading',
                                'GENERAL.GAMES.join-modal_firsttextpart',
                                'GENERAL.GAMES.join-modal_secondtextpart'
                            ])
                                .pipe(takeWhile(() => this.componentAlive))
                                .subscribe(
                                    translation => {
                                        this.myNotificationsService.createNotificationToast(
                                            translation['GENERAL.GAMES.join-modal_heading'],
                                            translation['GENERAL.GAMES.join-modal_firsttextpart'] + ' ' + translation['GENERAL.GAMES.join-modal_secondtextpart'],
                                            NotificationType.Success
                                        );
                                    });

                            if (this.betsnapdetailService.widgetBets && Object.keys(this.betsnapdetailService.widgetBets).length > 0) {
                                this.router.navigate(['../bets'], {relativeTo: this.route});
                                return;
                            } else {
                                if (this.game.sport_id === 999) {
                                    this.router.navigate(['../games'], {relativeTo: this.route});
                                } else {
                                    this.router.navigate(['../matches'], {relativeTo: this.route});
                                }
                                return;
                            }
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        this.hideApproveJoin();
                        this.processJoin = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    public openModalJoined() {
        const modalJoinedConfig = new TemplateModalConfig<null, string, string>(this.modalJoinedTemplate);
        modalJoinedConfig.size = ModalSize.Tiny;
        this.modalJoined = this.myModalService.openModal(modalJoinedConfig);
    }

}
