import { OnDestroy, OnInit, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameHttpResponse as GameVendorGameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse
} from '../../../../api';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    GameVendorGameStatusEnum,
    GoogleAnalyticsService
} from '../../../../shared';
import {takeWhile} from 'rxjs/operators';
import {Router} from '@angular/router';

@Directive()
export abstract class ABetsnapdetailVendorGamesComponent implements OnInit, OnDestroy {

    public adPositions: number[] = [];

    protected componentAlive = true;

    public game: GameHttpResponse;
    public gameVendorGames: GameVendorGameHttpResponse[];
    public filteredGameVendorGames: GameVendorGameHttpResponse[];
    public gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[] = null;

    public processGameVendorGames: boolean = true;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected router: Router,
                protected adService: AdService) {
        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (gameHttpResponse) {
                        if (!this.game && gameHttpResponse.sport_id !== 999) {
                            this.router.navigate(['/betsnapdetail/' + gameHttpResponse.game_unique_id + '/details']);
                            return;
                        }

                        if (!this.gameVendorGames) {
                            // load game vendor games from api
                            this.betsnapdetailService.getGameVendorGames(gameHttpResponse.game_unique_id);
                        }

                        if (this.gameVendorGameParticipations === null &&
                            this.authenticationService.currentUser && gameHttpResponse.is_current_user_joined) {
                            // load game vendor game participations
                            this.betsnapdetailService.getGameVendorGameParticipations(
                                gameHttpResponse.game_unique_id,
                                this.authenticationService.currentUser.id
                            );
                        }
                    }
                    this.game = gameHttpResponse;
                }
            );

        this.betsnapdetailService.gameVendorGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                    if (gameVendorGameParticipations) {
                        this.gameVendorGameParticipations = gameVendorGameParticipations;
                    }
                }
            );

        this.betsnapdetailService.gameVendorGames$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameListResults: GameVendorGameHttpResponse[]) => {
                    if (gameVendorGameListResults) {
                        if (!this.adPositions || (Array.isArray(this.adPositions) && this.adPositions.length === 0)) {
                            this.adPositions = this.adService.defineAdPosition('game-content', gameVendorGameListResults.length);
                        }
                    }
                }
            );
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }


    upComingGameVendorGames(): GameVendorGameHttpResponse[] {
        return (this.gameVendorGames) ? this.gameVendorGames.filter(
            (gameVendorGame: GameVendorGameHttpResponse) => gameVendorGame.status === GameVendorGameStatusEnum.NOT_STARTED
        ) : null;
    }

    runningGameVendorGames(): GameVendorGameHttpResponse[] {
        return (this.gameVendorGames) ? this.gameVendorGames.filter(
            (gameVendorGame: GameVendorGameHttpResponse) => gameVendorGame.status === GameVendorGameStatusEnum.RUNNING
        ) : null;
    }

    closedGameVendorGames(): GameVendorGameHttpResponse[] {
        return (this.gameVendorGames) ? this.gameVendorGames.filter(
            (gameVendorGame: GameVendorGameHttpResponse) => (
                gameVendorGame.status === GameVendorGameStatusEnum.FINISHED ||
                gameVendorGame.status === GameVendorGameStatusEnum.CLOSED ||
                gameVendorGame.status === GameVendorGameStatusEnum.CANCELLED
            )
        ) : null;
    }

    activeGameVendorGames() {
        return this.filteredGameVendorGames.filter((gameVendorGame: GameVendorGameHttpResponse) =>
            (
                gameVendorGame.status === GameVendorGameStatusEnum.NOT_STARTED ||
                gameVendorGame.status === GameVendorGameStatusEnum.RUNNING
            )
        );
    }

    endedGameVendorGames() {
        return this.filteredGameVendorGames.filter((gameVendorGame: GameVendorGameHttpResponse) =>
            (
                gameVendorGame.status === GameVendorGameStatusEnum.FINISHED ||
                gameVendorGame.status === GameVendorGameStatusEnum.CLOSED ||
                gameVendorGame.status === GameVendorGameStatusEnum.CANCELLED
            )
        );
    }

    gameVendorGamesTrackBy(index: number, gameVendorGame: GameVendorGameHttpResponse): number {
        return gameVendorGame.id;
    }

}
