<div class="ui middle aligned grid margin-0">
    <div class="row">
        <div class="two wide column right aligned">
            <div class="ui list stats" *ngIf="match.score">
                <div class="item">
                    <span class="ui red tiny label margin-t-0 margin-b-0 margin-l-5 icon-content"
                          *ngIf="match.score.away_suspend > match.score.home_suspend">
                        <i class="i tiny icon whatshot white margin-0 vertical-align-middle"></i>
                    </span>
                    <span class="ui blue tiny label margin-0 vertical-align-middle text-content"
                          *ngIf="match.score.home_suspend > 0">
                        {{match.score.home_suspend}}
                    </span>
                </div>
                <div class="item">
                    <span class="ui red tiny label margin-t-0 margin-b-0 margin-l-5 icon-content"
                          *ngIf="match.score.home_suspend > match.score.away_suspend">
                        <i class="i tiny icon whatshot white margin-0 vertical-align-middle"></i>
                    </span>
                    <span class="ui blue tiny label margin-0 vertical-align-middle text-content"
                          *ngIf="match.score.away_suspend > 0">
                        {{match.score.away_suspend}}
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="(shouldShowScore() ? getPeriodColumnClasses()[0] : 'fourteen') + ' wide column left aligned padding-l-0'">
            <div class="ui list team-names">
                <div class="item truncate" [class.font-normal]="matchIsFinished(match) && match.score && match.score.winner_id === match.home_id">
                    <span *ngIf="match.home">
                        {{match.home.name | dbtranslation:'name':match.home?.translations}}
                    </span><span *ngIf="!match.home">
                        {{match.home_name}}
                    </span>
                </div>
                <div class="item truncate" [class.font-normal]="matchIsFinished(match) && match.score && match.score.winner_id === match.away_id">
                    <span *ngIf="match.away">
                        {{match.away.name | dbtranslation:'name':match.away?.translations}}
                    </span>
                    <span *ngIf="!match.away">
                        {{match.away_name}}
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="getPeriodColumnClasses()[1] + ' wide column right aligned padding-l-0'" *ngIf="shouldShowScore()">
            <div class="ice-hockey-score-wrap">
                <div class="ui list ice-hockey-score current-server center aligned"
                     *ngIf="match.score.home_game_score !== null && match.score.away_game_score !== null &&
                            match.score.current_server !== null">
                    <div class="item">
                        <span class="ui yellow empty circular mini label margin-0 current-server-dot"
                              *ngIf="match.score.current_server === 1">
                        </span>
                    </div>
                    <div class="item">
                        <span class="ui yellow empty circular mini label margin-0 current-server-dot"
                              *ngIf="match.score.current_server === 2">
                        </span>
                    </div>
                </div>
                <div class="ui list ice-hockey-score game-score center aligned"
                           *ngIf="match.score.home_game_score !== null &&
                                  match.score.away_game_score !== null &&
                                  !matchIsFinished(match)">
                    <div class="item">
                        {{match.score.home_game_score}}
                    </div>
                    <div class="item">
                        {{match.score.away_game_score}}
                    </div>
                </div>
                <div class="period-score-wrap display-inline-block" *ngVar="hasPenaltiesPeriod(match) as matchHasPenaltiesPeriod">
                    <ng-container *ngFor="let period of getMatchPeriods(); trackBy: periodTrackBy; let i = index; let isLast = last;">
                        <div class="ui list ice-hockey-score period-score center aligned"
                             *ngIf="period.type !== MatchPeriodTypeEnum.OVERTIME
                                || (period.type === MatchPeriodTypeEnum.OVERTIME && !matchHasPenaltiesPeriod)"
                             [class.last-period-score]="isLast || period.type === MatchPeriodTypeEnum.PENALTIES"
                             [class.font-very-bold]="(i + 1) === match.score.periods.length && match.status !== 'closed' && match.status !== 'ended'">
                            <div class="item"
                                 [class.color-grey]="periodIsFinished(i, match)"
                                 [class.font-very-bold]="periodIsFinished(i, match) && period.home_score > period.away_score">
                                {{period.home_score}} <span *ngIf="period.type === MatchPeriodTypeEnum.PENALTIES && match.score.home_penalty_score"> ({{match.score.home_penalty_score}})</span>
                            </div>
                            <div class="item"
                                 [class.color-grey]="periodIsFinished(i, match)"
                                 [class.font-very-bold]="periodIsFinished(i, match) && period.away_score > period.home_score">
                                {{period.away_score}} <span *ngIf="period.type === MatchPeriodTypeEnum.PENALTIES && match.score.away_penalty_score"> ({{match.score.away_penalty_score}})</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="ui list ice-hockey-score total-score center aligned">
                    <div class="item">
                        {{match.score.home_score}}
                    </div>
                    <div class="item">
                        {{match.score.away_score}}
                    </div>
                </div>
                <div class="display-inline-block vertical-align-middle center aligned padding-l-10" *ngIf="withScoreExtraInformation()">
                    <div class="text-mini">
                        ({{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}})
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
