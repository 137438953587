import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {DynamicComponentLoaderService} from '../../../shared/services/dynamic-component-loader.service';
import {DynamicComponentHostDirective} from '../../../shared/directives/dynamic-component-host.directive';
import {AHeaderComponent} from './a-header-component';

@Component({
    selector: 'betsnaps-header-loader',
    template: `
        <ng-template dynamicComponentHost></ng-template>
    `
})
export class HeaderLoaderComponent implements OnInit, OnDestroy {

    private componentRef: ComponentRef<AHeaderComponent>;
    @ViewChild(DynamicComponentHostDirective, { static: true }) dynamicComponentHost: DynamicComponentHostDirective;

    constructor(private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private dynamicComponentLoaderService: DynamicComponentLoaderService) {
    }

    ngOnInit() {

        const componentToLoad = this.dynamicComponentLoaderService.getComponent('header');

        if (componentToLoad) {
            const componentFactory: ComponentFactory<AHeaderComponent> = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);

            const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent<AHeaderComponent>(componentFactory);
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

}
