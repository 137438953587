/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesTranslationsMediaTranslationHttpResponse } from './appHttpResponsesTranslationsMediaTranslationHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * HintHttpResponse
 */
export interface AppHttpResponsesHintsHintHttpResponse { 
    /**
     * Hint ID
     */
    id: number;
    /**
     * Hint Code
     */
    code?: string;
    /**
     * Hint Title
     */
    title: string;
    /**
     * Hint Description
     */
    description: string;
    /**
     * Hint Order
     */
    order: number;
    /**
     * Is a general Hint
     */
    is_general: boolean;
    /**
     * Hint Trigger Key
     */
    trigger_key?: string;
    /**
     * Hint Trigger Condition
     */
    trigger_condition?: string;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Media per language
     */
    media_translations?: Array<AppHttpResponsesTranslationsMediaTranslationHttpResponse>;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

