export interface GameFilter {
    per_page?: number;
    current_page?: number;
    game_state?: string;
    sport_id?: string;
    is_public?: boolean;
    competition_type?: string;
    is_highlight?: boolean;
    joined_user_id?: number;
    only_with_friends?: boolean;
    exclude_fully_booked?: boolean;
    include_all_joined_snaps?: boolean;
    exclude_closed_door?: boolean;
    check_for_fitting_tags?: boolean;
    exclude_singleplayer?: boolean;
    sorting?: string;
}
