/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * FriendGroupUpdateRequest
 */
export interface AppHttpRequestsFriendsFriendGroupUpdateRequest { 
    /**
     * Group name
     */
    name?: string;
    /**
     * Group abbreviation (Length: 2 characters)
     */
    abbr?: string;
}

