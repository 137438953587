import {Component, ElementRef, ViewChild} from '@angular/core';
import {AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameListHttpResponse} from '../../../../api';
import {
    AuthenticationService,
    CreateSnapService,
    ErrorService, FriendshipStateEnum,
    FriendsService,
    GoogleAnalyticsService,
    MyModalService,
    MyTranslateService,
    TenantService
} from '../../../../shared';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AUserAvatarComponent} from '../a-user-avatar-component';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {HttpErrorResponse} from '@angular/common/http';
import {PlayerService} from '../../../../shared/services/player.service';

@Component({
    selector: 'betsnaps-user-avatar-default',
    templateUrl: './user-avatar-default.component.html',
    styles: []
})

export class UserAvatarDefaultComponent extends AUserAvatarComponent {

    @ViewChild('inviteMessage') public inviteMessage: ElementRef;
    @ViewChild('userProfileModalTemplate', {static: true}) public userProfileModalTemplate: ModalTemplate<null, string, string>;

    constructor(public translations: MyTranslateService,
                protected myModalService: MyModalService,
                protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected friendsService: FriendsService,
                protected createSnapService: CreateSnapService,
                protected playerService: PlayerService,
                protected errorService: ErrorService,
                protected router: Router) {
        super(
            translations,
            myModalService,
            authenticationService,
            tenantService,
            googleAnalyticsService,
            friendsService,
            createSnapService,
            playerService,
            errorService,
            router
        );
    }

    protected openUserProfileModal() {
        if (!this.userProfileModal) {
            const userProfileModalConfig = new TemplateModalConfig<null, string, string>(this.userProfileModalTemplate);
            userProfileModalConfig.size = ModalSize.Tiny;

            // get joined games if user is friend
            if (this.currentUser && this.friendshipState.state === FriendshipStateEnum.FRIEND) {
                this.processGameList = true;
                this.friendsService.getJoinedGameListOfFriend(
                    this.userId
                ).pipe(take(1))
                .subscribe({
                    next: (gameHttpListResponse: GameListHttpResponse) => {
                        this.joinedGameList = gameHttpListResponse;
                        this.processGameList = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.errorService.handleHttpErrorResponse(err);
                        this.processGameList = false;
                    }
                });
            }

            this.userProfileModal = this.myModalService.openModal(userProfileModalConfig)
                .onApprove(() => {
                    this.userProfileModal = null;
                }).onDeny(() => {
                    this.userProfileModal = null;
                });
        }
    }

}
