import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {
    AuthenticationService,
    ErrorService,
    MyModalService, MyNotificationsService,
    ProfileService,
    TenantService,
    WindowRef
} from '../../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AProfileChangeEmailComponent} from '../a-profile-change-email.component';
import {ActiveModal, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';

@Component({
    selector: 'betsnaps-profile-change-email-v3',
    templateUrl: './profile-change-email-v3.component.html',
    styles: []
})
export class ProfileChangeEmailV3Component extends AProfileChangeEmailComponent {

    public profileChangeEmailV3Modal: ActiveModal<{}, {}, {}>;

    protected navigationTargetUrl = '/profile/edit';

    @Output() profileChangeEmailSetSuccess: EventEmitter<any> = new EventEmitter();

    @ViewChild('profileChangeEmailV3Modal', { static: true }) public profileChangeEmailV3ModalTemplate: ModalTemplate<null, string, string>;

    constructor(protected authenticationService: AuthenticationService,
                protected profileService: ProfileService,
                protected tenantService: TenantService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected errorService: ErrorService,
                protected windowRef: WindowRef,
                protected activatedRoute: ActivatedRoute,
                protected router: Router,
                protected myModalService: MyModalService) {
        super(
            authenticationService,
            profileService,
            tenantService,
            myNotificationsService,
            translateService,
            errorService,
            windowRef,
            activatedRoute,
            router
        );
    }

    public openProfileChangeEmailV3Modal() {
        if (this.profileChangeEmailV3Modal) {
            this.profileChangeEmailV3Modal.destroy();
        }
        const profileChangeEmailV3ModalConfig = new TemplateModalConfig<null, string, string>(this.profileChangeEmailV3ModalTemplate);
        profileChangeEmailV3ModalConfig.size = 'tiny';
        profileChangeEmailV3ModalConfig.isClosable = true;
        this.profileChangeEmailV3Modal = this.myModalService.openModal(profileChangeEmailV3ModalConfig);
    }
}
