/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesVendorGamesVendorGamePublicHttpResponse } from './appHttpResponsesVendorGamesVendorGamePublicHttpResponse';


/**
 * GameVendorGameHttpResponse
 */
export interface AppHttpResponsesVendorGamesGameVendorGameHttpResponse { 
    /**
     * Game Vendor Game Id
     */
    id: number;
    /**
     * Game Unique Id
     */
    game_unique_id: string;
    /**
     * Vendor Game Id
     */
    vendor_game_id: number;
    /**
     * Start-Date
     */
    start_date: Date;
    /**
     * End-Date
     */
    end_date: Date;
    /**
     * Status
     */
    status: number;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
    vendor_game?: AppHttpResponsesVendorGamesVendorGamePublicHttpResponse;
}

