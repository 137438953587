/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataTournamentHttpResponse } from './appHttpResponsesSportsDataTournamentHttpResponse';


/**
 * TenantTournamentHttpResponse
 */
export interface AppHttpResponsesTenantsTenantTournamentHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tournament Id
     */
    tournament_id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Priority
     */
    priority: boolean;
    /**
     * Order
     */
    order: number;
    /**
     * Created At
     */
    created_at?: Date;
    /**
     * Updated At
     */
    updated_at?: Date;
    tournament?: AppHttpResponsesSportsDataTournamentHttpResponse;
}

