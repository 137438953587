import {
    Component,
    Inject,
    Renderer2,
} from '@angular/core';
import {
    ArenaService,
    AuthenticationService,
    GoogleAnalyticsService,
    MobiledetectService, MyModalService,
    TenantService,
    WindowRef
} from '../../../../shared';

import {ErrorService} from '../../../../shared';
import {DOCUMENT} from '@angular/common';
import {ALandingpageComponent} from '../a-landingpage-component';

@Component({
    selector: 'betsnaps-landingpage-bwin-canada',
    templateUrl: './landingpage-bwin-canada.component.html',
    styles: []
})
export class LandingpageBwinCanadaComponent extends ALandingpageComponent {

    constructor(@Inject(DOCUMENT) protected document: any,
                protected authenticationService: AuthenticationService,
                public tenantService: TenantService,
                public mobileDetect: MobiledetectService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected windowRef: WindowRef,
                protected errorService: ErrorService,
                protected arenaService: ArenaService,
                protected renderer: Renderer2,
                public myModalService: MyModalService
    ) {
       super(
           document,
           authenticationService,
           tenantService,
           mobileDetect,
           googleAnalyticsService,
           windowRef,
           errorService,
           arenaService,
           renderer,
           myModalService
       );
    }
}
