import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AlertMessage, areEqualValidator,
    AuthenticationService,
    ErrorService, GoogleAnalyticsService, TenantService
} from '../../../../shared';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ALoginRegisterComponent} from '../a-login-register.component';

@Component({
    selector: 'betsnaps-reset-password-phone-form',
    templateUrl: './reset-password-phone-form.component.html',
    styles: []
})
export class ResetPasswordPhoneFormComponent extends ALoginRegisterComponent implements OnInit {

    public processInitForm: boolean = false;
    public processResetForm: boolean = false;
    public showAlert: boolean = false;

    public showLoginButton: boolean = false;

    public showInitForm: boolean = true;
    public showResetForm: boolean = false;

    public initPhoneNumber: string;

    public alertMessage: AlertMessage;

    public initPasswordReset: UntypedFormGroup = new UntypedFormGroup({
        phone_country_code: new UntypedFormControl('', [Validators.required]),
        phone: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9|\/|\(|\)|\x20]+'), Validators.minLength(4), Validators.maxLength(13)]),
    });

    public resetPassword: UntypedFormGroup = new UntypedFormGroup({
        new_password_key: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
        'passwordGroup': new UntypedFormGroup({
            newpassword: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
            newpassword_confirm: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
        }, areEqualValidator),
    });

    constructor(protected tenantService: TenantService,
                private authenticationService: AuthenticationService,
                private errorService: ErrorService,
                protected googleAnalyticsService: GoogleAnalyticsService) {
        super(tenantService, googleAnalyticsService);
        this.loadPhoneSignupAllowedCountryCodes();
        this.initPasswordReset.patchValue({'phone_country_code': this.phoneSignupAllowedCountryCodes[0]});
    }

    onSubmitInitRequest() {
        this.processInitForm = true;
        const phoneNumber = this.initPasswordReset.value.phone_country_code + this.initPasswordReset.value.phone;
        this.authenticationService.initiatePasswordPhoneReset(phoneNumber)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.processInitForm = false;
                    this.initPhoneNumber = phoneNumber;
                    this.initPasswordReset.reset();

                    this.alertMessage = {
                        type: 'success',
                        messagetranslatekey: 'RESETPASSWORD.initiate_phone_successfully_title',
                        messagetxttranslatekey: 'RESETPASSWORD.initiate_phone_successfully'
                    };

                    this.showInitForm = false;
                    this.showAlert = true;
                    this.showResetForm = true;

                },
                error: (err: HttpErrorResponse) => {
                    this.processInitForm = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    onSubmitResetPasswordRequest() {
        this.processResetForm = true;
        this.showAlert = false;
        this.authenticationService.passwordPhoneReset(this.initPhoneNumber, this.resetPassword.value.new_password_key.toString(), this.resetPassword.value.passwordGroup.newpassword)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.processResetForm = false;
                    this.resetPassword.reset();

                    this.alertMessage = {
                        type: 'success',
                        messagetranslatekey: 'RESETPASSWORD.reset_phone_successfully_title',
                        messagetxttranslatekey: 'RESETPASSWORD.reset_phone_successfully'
                    };
                    this.showAlert = true;
                    this.showResetForm = false;
                    this.showLoginButton = true;

                },
                error: (err: HttpErrorResponse) => {
                    this.processResetForm = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    ngOnInit() {
    }
}
