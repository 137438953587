import {
    Component, ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {DynamicComponentLoaderService} from '../../../shared/services/dynamic-component-loader.service';
import {DynamicComponentHostDirective} from '../../../shared/directives/dynamic-component-host.directive';
import {ABetsnapdetailComponent} from './a-betsnapdetail-component';

@Component({
    selector: 'betsnaps-betsnapdetail-loader',
    template: `
        <ng-template dynamicComponentHost></ng-template>
    `
})

export class BetsnapdetailLoaderComponent implements OnInit, OnDestroy {

    @ViewChild(DynamicComponentHostDirective, { static: true }) dynamicComponentHost: DynamicComponentHostDirective;

    private componentRef: ComponentRef<ABetsnapdetailComponent>;

    constructor(private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private dynamicComponentLoaderService: DynamicComponentLoaderService) {
    }

    ngOnInit() {

        const componentToLoad = this.dynamicComponentLoaderService.getComponent('betsnapdetail');

        if (componentToLoad) {
            const componentFactory: ComponentFactory<ABetsnapdetailComponent> = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);

            const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent<ABetsnapdetailComponent>(componentFactory);
        }

    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

}
