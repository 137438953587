import { ChangeDetectorRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, Directive } from '@angular/core';
import {
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse,
    AppHttpResponsesSportsDataMatchMarketHttpResponse as MatchMarketHttpResponse,
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse
} from '../../../api';
import {
    AuthenticationService,
    GameBetPlacementOptionEnum,
    GamePointsEngineEnum,
    GoogleAnalyticsService,
    MyModalService, MyNotificationsService, TenantImageTypeEnum,
    TenantService
} from '../../../shared';
import {take} from 'rxjs/operators';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {ActivatedRoute, Router} from '@angular/router';

@Directive()
export abstract class AMarketOutcomeButtonComponent implements OnInit, OnChanges {

    @Input() game: GameHttpResponse;
    @Input() match: MatchHttpResponse;
    @Input() market: MatchMarketHttpResponse;
    @Input() outcome: MatchMarketOutcomeHttpResponse;

    @Input() marketUpdatedDate: Date;
    @Input() marketStatus: number;
    @Input() outcomeStatus: number;
    @Input() oddDecimal: number;
    @Input() oddDisplayFormat: string = 'value';

    @Input() matchStatus: string;
    @Input() gameState: number;
    @Input() betPlacementAllowed: boolean;
    @Input() liveodds: string;

    @Input() isSelected: boolean = false;
    @Input() isUserBetAvailable: boolean = false;

    @Output() onPlaceBetClick: EventEmitter<any> = new EventEmitter();

    public outcomeChange: number = 0; // 1 = up; 0 = same; -1 = down
    protected oldOutcomeValue: number;

    public isDisabled: boolean = false;
    @Input() forceDisabled: boolean = false;

    public showAnimation: boolean = false;

    public loginModalImage: string;

    @ViewChild('modalJoinTemplate', { static: true }) modalJoinTemplate: ModalTemplate<null, string, string>;
    public modalJoin = null;

    public gamePointsEngineEnum = GamePointsEngineEnum;

    protected constructor(public googleAnalyticsService: GoogleAnalyticsService,
                protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected myModalService: MyModalService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.oldOutcomeValue = this.outcome.odd_decimal;

        // set login widget image
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE)) {
            this.loginModalImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE).media_url;
        } else {
            this.loginModalImage = './assets/img/i-login-circle.png';
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.oddDecimal && !changes.oddDecimal.firstChange) {
            // compare outcomes
            if (this.oldOutcomeValue > this.outcome.odd_decimal) {
                this.outcomeChange = -1;
                this.animateArrow();
            } else if (this.oldOutcomeValue < this.outcome.odd_decimal) {
                this.outcomeChange = 1;
                this.animateArrow();
            } else {
                this.outcomeChange = 0;
            }
            this.oldOutcomeValue = this.outcome.odd_decimal;
        } else if (changes.marketUpdatedDate) {
            this.outcomeChange = 0;
        }

        if (changes.oddDisplayFormat && !changes.oddDisplayFormat.currentValue) {
            this.oddDisplayFormat = this.tenantService.tenantData.internationalization.odd_display_format;
        }

        this.isDisabled = (
            this.outcome.outcome_status !== 1 || this.market.market_status !== 1 || !this.betPlacementAllowed || this.outcome.outcome_probability_value === 0
        );
    }

    openPlaceBetDialog() {
        if (!this.authenticationService.currentUser) {
            this.myModalService.openLoginRegisterModal('login', null, true, 'matchcard - bet');
        } else if (!this.game.is_current_user_joined) {
            if (this.game.game_state < 3) {
                this.openModalJoin();
            } else {
                this.alertUserNotification('GENERAL.BETS.ERRORS.not_joined_snap_already_running');
            }
        } else if (!this.betPlacementAllowed) {
            if (this.match.liveodds !== 'booked') {
                this.alertUserNotification('ERRORS.GENERAL.live_odds_not_available');
            } else if (this.game.bet_placement_option === GameBetPlacementOptionEnum.ONLY_PRE_GAME) {
                this.alertUserNotification('ERRORS.GENERAL.only_pre_game_bets_allowed');
            } else if (this.game.bet_placement_option === GameBetPlacementOptionEnum.ONLY_PRE_MATCH) {
                this.alertUserNotification('ERRORS.GENERAL.only_pre_match_bets_allowed');
            } else if (this.game.bet_placement_option === GameBetPlacementOptionEnum.ONLY_LIVE) {
                this.alertUserNotification('ERRORS.GENERAL.only_live_bets_allowed');
            }
        } else if (this.game.max_bet_count > 0 && this.game.current_user_bet_count >= this.game.max_bet_count) {
            this.alertUserNotification('GENERAL.BETS.ERRORS.max_bet_count_reached');
        } else if (this.game.game_user.user_game_points === 0) {
            this.alertUserNotification('GENERAL.BETS.ERRORS.insufficient_points');
        } else if (this.outcome.outcome_status !== 1 || this.market.market_status !== 1) {
            this.alertUserNotification('GENERAL.BETS.ERRORS.bet_not_possible_outcome_inactive');
        } else {
            this.onPlaceBetClick.emit();
            this.googleAnalyticsService.trackEvent(
                'matchcard - bet',
                'show',
                this.outcome.market_id + '-' + this.outcome.outcome_id + '-' + this.outcome.specifier_val
            );
        }
    }

    animateArrow() {
        this.showAnimation = true;
        setTimeout(() => {
            this.showAnimation = false;
            this.cdr.markForCheck();
        }, 1000);
    }

    alertUserNotification(key: string) {
        this.translateService.get(['GENERAL.BETS.ERRORS.bet_not_possible_title', key])
            .pipe(take(1)).subscribe(
            translation => {
                this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.bet_not_possible_title'], translation[key], NotificationType.Error);
            });
    }

    openModalJoin() {
        const config = new TemplateModalConfig<null, string, string>(this.modalJoinTemplate);
        config.size = ModalSize.Tiny;
        this.modalJoin = this.myModalService.openModal(config);
    }

    linkRedirect(link: string, modal = null) {
        if (modal) {
            modal.approve();
        }
        this.router.navigate([link], {relativeTo: this.route});
        return;
    }

}
