/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * CurrencyHttpResponse
 */
export interface AppHttpResponsesFinancialsCurrenciesExchangeRateHeadHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Created Date
     */
    created_at: string;
    /**
     * Updated Date
     */
    updated_at: string;
}

