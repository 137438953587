import {Component} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {
    AuthenticationService,
    ErrorService,
    MyNotificationsService,
    ProfileService,
    TenantService
} from '../../../../../shared';
import {AProfilePrivacyComponent} from '../a-profile-privacy-component';

@Component({
    selector: 'betsnaps-profile-privacy-default',
    templateUrl: './profile-privacy-default.component.html',
    styles: []
})
export class ProfilePrivacyDefaultComponent extends AProfilePrivacyComponent {

    constructor(protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected profileService: ProfileService,
                protected formBuilder: UntypedFormBuilder,
                protected myNotificationsService: MyNotificationsService,
                protected errorService: ErrorService) {
        super(
            authenticationService,
            tenantService,
            profileService,
            formBuilder,
            myNotificationsService,
            errorService
        );
    }

}
