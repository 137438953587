/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantInternationalizationHttpResponse
 */
export interface AppHttpResponsesTenantsTenantInternationalizationHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * default language id
     */
    default_language_id?: number;
    /**
     * date time format
     */
    date_time_format: string;
    /**
     * date format
     */
    date_format: string;
    /**
     * time format
     */
    time_format: string;
    /**
     * timezone id
     */
    timezone_id?: number;
    /**
     * decimal point
     */
    decimal_point: string;
    /**
     * thousands separator
     */
    thousands_separator: string;
    /**
     * odd display format (\'odds\', \'value\', \'fractional\', \'moneyline\')
     */
    odd_display_format: string;
    /**
     * currency format (\'prefix\', \'suffix\')
     */
    currency_format: string;
}

