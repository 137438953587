import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {
    GoogleAnalyticsService,
    MobiledetectService, MyModalService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'betsnaps-landingpage-fallback',
    templateUrl: './landingpage-fallback.component.html',
    styles: []
})
export class LandingpageFallbackComponent implements OnInit, OnDestroy {

    public shareUrl: string;
    public showcopyinfo: boolean = false;

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    private layout: HTMLElement;

    constructor(@Inject(DOCUMENT) private document: any,
        public tenantService: TenantService,
        public mobileDetect: MobiledetectService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private windowRef: WindowRef,
        private renderer: Renderer2,
        public myModalService: MyModalService
    ) {
        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    ngOnInit() {
        this.shareUrl = this.tenantService.getTenantDomainWithDefaultRelativePath();
        this.layout = this.document.getElementById('betsnaps-layout');
        this.showDefaultBgImage();
    }

    ngOnDestroy() {
        this.hideDefaultBgImage();
    }

    private showDefaultBgImage(): void {
        this.renderer.addClass(this.layout, 'with-bg-image');
    }

    private hideDefaultBgImage(): void {
        this.renderer.removeClass(this.layout, 'with-bg-image');
    }

    goToB2bLogin() {
        this.nativeWindow.location.href = this.tenantService.getB2bWebsiteLoginUrl();
        return false;
    }

    showCopyInformation() {
        this.showcopyinfo = true;
        const self = this;
        setTimeout(() => {
            self.showcopyinfo = false;
        }, 3000);
    }

    callWrapperShare() {
        if (this.isWrapperWindow) {
            this.nativeWindow.window.BetSnapMobileWrapper.share([this.shareUrl]);
        }
    }

}
