<div class="login-register-modal scrolling content">
    <div *ngIf="viewToShow === 'login'">
        <betsnaps-login-form
            (onFormSuccess)="modal.approve()"
            (onChangeView)="switchView($event)"
            (onSocialLoginError)="viewToShow = 'register'; socialSignupPlatform = $event;"></betsnaps-login-form>
    </div>
    <div *ngIf="viewToShow === 'register'">
        <betsnaps-register-form
            (onChangeView)="switchView($event)"
            [socialSignupPlatform]="socialSignupPlatform"
            (onFormSuccess)="modal.approve()"
            (onSocialLoginError)="socialSignupPlatform = $event;"></betsnaps-register-form>
    </div>
    <div *ngIf="viewToShow === 'reset-password-email'">
        <betsnaps-reset-password-email-form
            (onChangeView)="switchView($event)"></betsnaps-reset-password-email-form>
    </div>
    <div *ngIf="viewToShow === 'reset-password-phone'">
        <betsnaps-reset-password-phone-form
            (onChangeView)="switchView($event)"></betsnaps-reset-password-phone-form>
    </div>
    <div *ngIf="viewToShow === 'resend-email-verification'">
        <betsnaps-resend-email-verification-form
            (onChangeView)="switchView($event)"></betsnaps-resend-email-verification-form>
    </div>
    <div *ngIf="viewToShow === 'resend-phone-verification'">
        <betsnaps-resend-phone-verification-form
            (onChangeView)="switchView($event)"></betsnaps-resend-phone-verification-form>
    </div>
    <div *ngIf="viewToShow === 'phone-verification'">
        <betsnaps-phone-verification-form
            (onChangeView)="switchView($event)"
            [phone]="phoneVerificationPhone"
            [initialAlertMessage]="phoneVerificationInitialAlertMessage"
            (onFormSuccess)="modal.approve()"></betsnaps-phone-verification-form>
    </div>
</div>
