import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse
} from '../../../../api';
import {BetsnapdetailService, GoogleAnalyticsService, TenantService} from '../../../../shared';
import {takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-sidebar-game-user',
    templateUrl: './sidebar-game-user.component.html',
    styles: []
})
export class SidebarGameUserComponent implements OnInit, OnDestroy {

    @Input() game: GameHttpResponse;
    @Input() gameUniqueId: string;
    @Input() h2hCompetitor: LeaderboardUserHttpResponse;

    private componentAlive = true;

    public gameUserPointsChanged: boolean = false;
    public processData: boolean = false;

    constructor(public betsnapdetailService: BetsnapdetailService,
                private tenantService: TenantService,
                public googleAnalyticsService: GoogleAnalyticsService) {

        if (!this.tenantService.tenantData.configuration.broadcast_game_leaderboard) {
            this.betsnapdetailService.leaderboardTimestamp$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (gameLeaderboardTimestamp: string) => {
                        this.gameUserPointsChanged = (gameLeaderboardTimestamp !== null);
                    }
                );
        }
    }

    ngOnInit() {
        if (!this.tenantService.tenantData.configuration.broadcast_game_leaderboard) {
            this.betsnapdetailService.game$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (gameHttpResponse: GameHttpResponse) => {
                        if (gameHttpResponse) {
                            this.game = gameHttpResponse;
                            this.processData = false;
                        }
                    }
                );
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    public refreshGameData() {
        this.processData = true;
        this.betsnapdetailService.refreshGameData(true, false, false, false, false, false, false, false, false);
    }

}
