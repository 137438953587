<div class="ui container">
    <div class="ui centered one column grid">
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div class="ui inverted transparent padded segment">
                <h2 class="ui center aligned header">
                    <i class="account circle icon"></i>
                    {{'REGISTER.heading_verify' | translate}}
                </h2>
                <div class="ui active centered inline text loader" *ngIf="showSpinner">
                    {{'REGISTER.verify_loader_txt' | translate}}
                </div>

                <fui-message *ngIf="showAlert" class="{{alert.type ?? ''}}" [hasDismissButton]="false">
                    <div class="header" *ngIf="alert.messagetranslatekey">
                        {{alert.messagetranslatekey | translate}}
                    </div>
                    <p *ngIf="alert.messagetxttranslatekey">{{alert.messagetxttranslatekey | translate}}</p>
                </fui-message>
            </div>
        </div>
    </div>
</div>
