<div *ngIf="!isWidget" id="main-container" class="ui main container"
     [class.with-mobile-ad]="showMobileAd && mobilePresentedByAd"
     [class.authenticated]="(isAuthenticated$ | async)">
    <div class="ui centered relaxed grid container user-notifications">
        <div class="row padding-0">
            <div class="four wide tablet or lower hidden column">
            </div>
            <div class="sixteen wide mobile ten wide tablet eight wide computer column padding-0">
                <simple-notifications [options]="options"></simple-notifications>
            </div>
            <div class="mobile hidden six wide tablet four wide computer column">
            </div>
        </div>
    </div>

    <ng-template let-context let-modal="modal" #notificationModalTemplate>
        <div class="header">
            <i *ngIf="context.icon" class="ui icon {{ context.icon }} massive"></i>
            <div *ngIf="!context.icon && context.type === 'success'" class="success">
                <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24"
                     viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                </svg>
            </div>
            <div *ngIf="!context.icon && context.type === 'alert'" class="alert">
                <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24"
                     viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
                </svg>
            </div>
            <div *ngIf="!context.icon && context.type === 'error'" class="error">
                <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24"
                     viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                </svg>
            </div>
        </div>
        <div class="content">
            <p>{{ context.data }}</p>
        </div>
        <div class="actions">
            <button class="ui {{ context.type }} button" (click)="modal.approve()" autofocus>OK</button>
        </div>
    </ng-template>

    <ng-template let-context let-modal="modal" #marketingNotificationModalTemplate>
        <div class="header">
            <div *ngIf="!context.reward">
                {{'PROFILE.PRIVACY.data_transfer_approval' | translate}} <span class="font-very-bold">{{ context.name }}</span>
            </div>
            <div *ngIf="context.reward">
                {{'PROFILE.PRIVACY.data_transfer_approval_bonus' | translate:{partner_name: context.name, reward: context.reward} }}
            </div>
        </div>
        <div class="scrolling content">
            <div [innerHtml]="context.data"></div>
        </div>
        <div class="actions">
            <button class="ui basic button" (click)="marketingNotificationModal.deny()">
                {{'PROFILE.PRIVACY.btn_cancel' | translate}}
            </button>
            <button class="ui button" (click)="approveMarketingNotification(context.id);"
                    [class.loading]="processApproveMarketingNotification">
                {{'PROFILE.PRIVACY.btn_ok' | translate}}
            </button>
        </div>
    </ng-template>

    <div id="router-outlet-wrap" class="router-outlet-wrap">
        <router-outlet></router-outlet>
    </div>

    <div class="ui hints container {{hintClass}}" #hintContainer>
        <div class="hint-wrapper" *ngIf="currentHintToDisplay">
            <div class="ui top aligned grid">
                <div class="two column row">
                    <div class="three wide column left aligned padding-r-0">
                        <img class="ui right aligned fluid image" [src]="hintAvatarImage" [alt]="" />
                    </div>
                    <div class="thirteen wide column left aligned">
                        <div class="ui header hint-title margin-0" *ngIf="currentHintToDisplay.title">
                            {{currentHintToDisplay.title | uppercase}}
                        </div>
                        <div class="hint-content text-small margin-b-10"
                             [innerHtml]="currentHintToDisplay.description">
                        </div>
                        <div class="ui one column grid">
                            <div class="column right aligned">
                                <button class="ui button margin-0" (click)="readHint(currentHintToDisplay.master_hint.id)"
                                    [class.loading]="hintProcessing"
                                    [disabled]="hintDisabled">
                                    {{'HINTS.confirm_btn' | translate | uppercase}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showMobileAd && mobilePresentedByAd && mobilePresentedByAd.media"
         class="mobile-presented-by-ad mobile only"
         [class.element-clickable]="mobilePresentedByAd.image_link">
        <a *ngIf="!isWrapperWindow && mobilePresentedByAd.image_link"
           [href]="mobilePresentedByAd.image_link"
           [target]="(mobilePresentedByAd.open_link_in_new_tab) ? '_blank' : '_self'"
           [rel]="(mobilePresentedByAd.open_link_in_new_tab) ? 'noopener noreferrer' : null">
            <span class="ad-text">{{'GENERAL.LABEL.presented-by' | translate}}</span><img [src]="mobilePresentedByAd.media.media_url" [alt]="" />
        </a>
        <div *ngIf="isWrapperWindow || !mobilePresentedByAd.image_link">
            <span class="ad-text">{{'GENERAL.LABEL.presented-by' | translate}}</span><img [src]="mobilePresentedByAd.media.media_url" [alt]="" />
        </div>
    </div>

    <betsnaps-go-to-top-button *ngIf="showGeneralScrollToTopButton"
                   [animate]="true"
                   [speed]="50"
                   [acceleration]="2"
                   [scrollDistance]="300"
                   [additionalClass]="'main-scroll-to-top'"
                   [class.with-mobile-ad]="showMobileAd && mobilePresentedByAd">
    </betsnaps-go-to-top-button>

    <betsnaps-game-vendor-game-fullscreen></betsnaps-game-vendor-game-fullscreen>
</div>
<div *ngIf="isWidget">
    <router-outlet></router-outlet>
</div>
