import {Injectable} from '@angular/core';

function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowRef {

    public isWrapperWindow: boolean = false;

    constructor() {
        const nativeWindow = this.nativeWindow;

        if (nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    get nativeWindow(): any {
        return _window();
    }
}
