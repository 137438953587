/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsFreemiumRewardHttpResponse } from './appHttpResponsesBetsnapsFreemiumRewardHttpResponse';
import { AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesCurrencyHttpResponse';


/**
 * TenantRewardHttpResponse
 */
export interface AppHttpResponsesBetsnapsFreemiumTenantRewardHttpResponse { 
    /**
     * Reward-Id
     */
    id: number;
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Master Freemium Reward ID
     */
    master_freemium_reward_id: number;
    master_freemium_reward?: AppHttpResponsesBetsnapsFreemiumRewardHttpResponse;
    /**
     * Reward Amount
     */
    reward: number;
    /**
     * Master Currency ID
     */
    master_currency_id: number;
    master_currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
}

