import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService, MyNotificationsService,
    TenantService
} from '../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {FeedService} from '../../../../shared/services/feed.service';
import {AUserBetComponent} from '../a-user-bet-component';

@Component({
    selector: 'betsnaps-user-bet-default',
    templateUrl: './user-bet-default.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserBetDefaultComponent extends AUserBetComponent {

    @Input() compactUserBetView: boolean = false;

    constructor(public authenticationService: AuthenticationService,
                public tenantService: TenantService,
                protected betsnapdetailService: BetsnapdetailService,
                protected myNotificationsService: MyNotificationsService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected translateService: TranslateService,
                protected errorService: ErrorService,
                protected cdr: ChangeDetectorRef,
                protected feedService: FeedService) {
        super(
            authenticationService,
            tenantService,
            betsnapdetailService,
            myNotificationsService,
            googleAnalyticsService,
            translateService,
            errorService,
            cdr,
            feedService
        );
    }

}
