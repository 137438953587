<div *ngIf="chat && initialLoadFinished">
    <div class="ui borderless secondary segment padding-0">
        <fui-accordion [closeOthers]="false" [transitionDuration]="200" class="chat-accordion">
            <fui-accordion-panel [(isOpen)]="chatVisible">
                <div title
                     (click)="!chatVisible ?
                          googleAnalyticsService.trackEvent('game', 'expand', 'chat') :
                          googleAnalyticsService.trackEvent('game', 'collapse', 'chat')">
                    <div class="title-inner" [class.isOpen]="chatVisible">
                        <span class="icon-wrap display-inline-block vertical-align-middle margin-r-10">
                            <i class="fa fa-comments huge icon margin-0"></i>
                            <span class="floating ui small circular shadowed calltoaction label"
                                  *ngIf="chat.unread_messages_count > 0">
                                {{(chat.unread_messages_count > 99) ? '99+' : chat.unread_messages_count}}
                            </span>
                        </span><span class="display-inline-block vertical-align-middle text-large chat-title-wrap truncate">
                            {{'CHAT.header-title' | translate}}
                        </span>
                        <i class="keyboard arrow right icon huge"
                           [ngClass]="{'clockwise rotated': chatVisible}"></i>
                    </div>
                </div>
                <div content>
                    <div class="content-inner">
                        <betsnaps-chat-messages
                            [chatVisible]="chatVisible"
                        ></betsnaps-chat-messages>
                    </div>
                </div>
            </fui-accordion-panel>
        </fui-accordion>
    </div>
</div>
