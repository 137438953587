import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DebugService} from './debug.service';
import {
    LogsApi, HealthChecksApi,
    AppHttpRequestsLogsCreateLogEntryRequest as CreateLogEntryRequest
} from '../../api';
import * as moment from 'moment';
import {take} from 'rxjs/operators';

export class TimeMeasurementResult {
    measurementKey: string;
    startDate: string;
    endDate: string;
    duration: number;
}

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    public doDebug = false;

    private startedMeasurements = [];
    private _measurements: Array<TimeMeasurementResult> = [];

    private _currentEventKey: string;

    constructor(private healthChecksApi: HealthChecksApi,
                private logsApi: LogsApi,
                private debugService: DebugService) {
        if (environment.DEBUG) {
            this.doDebug = true;
        }
    }

    public set currentEventKey(eventKey: string) {
        if (this.doDebug) {
            this._currentEventKey = eventKey;
        }
    }

    public startMeasurement(eventKey: string): void {
        if (this.doDebug) {
            this.startedMeasurements.push({
                'key': eventKey,
                'start': moment().utc(),
                'startDatetime': moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
            });
        }
    }

    public endMeasurement(eventKey: string): void {
        if (this.doDebug) {
            if (this.getStartedMeasurement(eventKey)) {
                const startedMeasurement = this.getStartedMeasurement(eventKey);
                const measurementStart = startedMeasurement.start;
                const measurementEnd = moment().utc();
                const measurementDuration = moment.duration(measurementEnd.diff(measurementStart)).asSeconds();

                const measurement: TimeMeasurementResult = {
                    measurementKey: eventKey,
                    startDate: startedMeasurement.startDatetime,
                    endDate: measurementEnd.format('YYYY-MM-DD HH:mm:ss.SSS'),
                    duration: Number(measurementDuration.toFixed(3))
                };
                this._measurements.push(measurement);
                this.startedMeasurements.filter((sM) => sM.key !== eventKey);
            } else {
                this.debugService.writeMessageToConsoleLog('Measurement (' + eventKey + ') does not exist');
            }
        }
    }

    public getMeasurement(eventKey: string): TimeMeasurementResult {
        return this._measurements.find((measurement: TimeMeasurementResult) => (measurement.measurementKey === eventKey));
    }

    private getStartedMeasurement(eventKey: string) {
        return this.startedMeasurements.find((startedMeasurement) => startedMeasurement.key === eventKey);
    }

    public finishCurrentMeasurement(logData?: Object) {
        if (this.doDebug) {
            let logPayload = null;
            if (this._measurements.length > 0) {
                if (logData !== undefined) {
                    logPayload = logData;
                    logPayload.detailedTimeMeasurements = this._measurements;
                } else {
                    logPayload = {
                        'detailedTimeMeasurements': this._measurements
                    };
                }
            } else if (logData !== undefined) {
                logPayload = logData;
            }

            if (this._currentEventKey) {
                this.writeLogEntry(this._currentEventKey, logPayload);
            }

            this._currentEventKey = null;
            this.startedMeasurements = [];
            this._measurements = [];
        }
    }

    public writeLogEntry(logEventKey: string, logPayloadObject?: Object): void {
        if (this.doDebug) {

            // only log if api is working
            this.healthChecksApi.apiHealthcheckIsapiworkingGet()
                .pipe(take(1)).subscribe(
                () => {

                    let logPayloadString: string;
                    if (logPayloadObject !== undefined) {
                        logPayloadString = JSON.stringify(logPayloadObject);
                    }

                    const logBody: CreateLogEntryRequest = {
                        level: 'INFO',
                        appkey: 'angular',
                        eventkey: logEventKey,
                        payload: logPayloadString
                    };

                    this.logsApi.apiLogsPost(
                        logBody
                    ).subscribe();

                }
            );
        }
    }

}
