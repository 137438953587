import {Component} from '@angular/core';
import {AMatchCardDetailsComponent} from '../a-match-card-details-component';

@Component({
    selector: 'betsnaps-match-card-details-ice-hockey-v3',
    templateUrl: './match-card-details-ice-hockey-v3.component.html',
    styles: []
})
export class MatchCardDetailsIceHockeyV3Component extends AMatchCardDetailsComponent {

}
