/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * SimpleTenantRankingHttpResponse
 */
export interface AppHttpResponsesBetsnapsRankingsSimpleTenantRankingHttpResponse { 
    /**
     * Tenant-Ranking-Id
     */
    id: number;
    /**
     * Tenant-Id
     */
    tenant_id: number;
    /**
     * Tenant-Ranking-Code
     */
    code: string;
    /**
     * Title
     */
    title: string;
    /**
     * Description
     */
    description?: string;
    /**
     * Ranking-Publish-Date
     */
    publish_date?: Date;
    /**
     * Ranking-From-Date
     */
    from_date?: Date;
    /**
     * Ranking-To-Date
     */
    to_date?: Date;
    /**
     * game_final_points, game_highscore
     */
    ranking_type?: string;
    /**
     * Ranking State
     */
    ranking_state?: number;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Last Recalculation At
     */
    last_recalculation_at?: Date;
    /**
     * sum of ALL ranks of this Tenant-Ranking
     */
    ranking_positions_total: number;
    /**
     * Current User Rank
     */
    user_rank?: number;
}

