/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataMarketHttpResponse } from './appHttpResponsesSportsDataMarketHttpResponse';


/**
 * GameMarketHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameMarketHttpResponse { 
    /**
     * Game Market ID
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * Market ID
     */
    market_id: number;
    market?: AppHttpResponsesSportsDataMarketHttpResponse;
}

