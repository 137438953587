/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpRequestsGeneralTranslationEntryCreate } from './appHttpRequestsGeneralTranslationEntryCreate';


/**
 * TenantRankingUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantRankingUpdateRequest { 
    /**
     * Ranking type
     */
    ranking_type: string;
    /**
     * Code
     */
    code: string;
    /**
     * Title
     */
    title: string;
    /**
     * Description
     */
    description?: string;
    /**
     * Publish-Date
     */
    publish_date?: Date;
    /**
     * From-Date
     */
    from_date?: Date;
    /**
     * To-Date
     */
    to_date?: Date;
    /**
     * Is dedicated
     */
    is_dedicated?: boolean;
    /**
     * Show in widget
     */
    show_in_widget?: boolean;
    /**
     * Info page URL
     */
    info_page_url?: string;
    /**
     * Banner image Media ID
     */
    banner_image_media_id?: number;
    /**
     * Content image Media ID
     */
    content_image_media_id?: number;
    /**
     * Tenant prize structure template IDs
     */
    tenant_prize_structure_template_ids?: Array<number>;
    /**
     * Tenant prize structure template translation entries
     */
    translations?: Array<AppHttpRequestsGeneralTranslationEntryCreate>;
}

