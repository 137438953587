/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesCurrencyHttpResponse';


/**
 * CurrencyHttpResponse
 */
export interface AppHttpResponsesFinancialsCurrenciesExchangeRateHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Exchange Rate Head ID
     */
    currency_exchange_rate_heads_id: number;
    /**
     * Base Currency ID
     */
    base_currency_id: number;
    base_currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
    /**
     * Target Currency ID
     */
    target_currency_id: number;
    target_currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
    /**
     * Exchange Rate
     */
    exchange_rate: number;
}

