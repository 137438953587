/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UserIdentityHttpResponse
 */
export interface AppHttpResponsesUsersUserIdentityHttpResponse { 
    /**
     * Identity Id
     */
    id: number;
    /**
     * User ID
     */
    user_id: number;
    /**
     * First name
     */
    first_name?: string;
    /**
     * Last name
     */
    last_name?: string;
    /**
     * Gender
     */
    gender?: string;
    /**
     * Date of Birth YYYY-mm-dd
     */
    dob?: string;
    /**
     * Media ID
     */
    media_id?: number;
    /**
     * Media Name
     */
    media_name?: string;
    /**
     * Absolute Media URL
     */
    media_url?: string;
    /**
     * Absolute Media URL (Thumb Big)
     */
    media_url_big?: string;
    /**
     * Absolute Media URL (Thumb Small)
     */
    media_url_small?: string;
    /**
     * Phone Code
     */
    phone_code?: string;
    /**
     * Phone No
     */
    phone_no?: string;
    /**
     * Facebook ID
     */
    facebook_id?: string;
    /**
     * Twitter ID
     */
    twitter_id?: string;
    /**
     * Google ID
     */
    google_id?: string;
}

