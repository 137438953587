<div class="ui text center aligned container">
    <div class="ui padded basic segment padding-l-0 padding-r-0 margin-0">
        <h1 class="hyphenate home-headline">
            {{'HOME.headline' | translate}}
        </h1>
        <p class="hyphenate home-subheadline">
            {{'HOME.subheadline' | translate}}
        </p>
        <div class="ui one column centered grid margin-b-40">
            <div class="row">
                <div class="eight wide column">
                    <a routerLink="/arena" class="ui calltoaction2 fluid large button margin-0 padding-r-0 padding-l-0">
                        {{'HOME.arena_btn' | translate | uppercase}}
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="eight wide column">
                    <betsnaps-landingpage-login-register-buttons remove-wrapping>
                    </betsnaps-landingpage-login-register-buttons>
                </div>
            </div>
        </div>
        <p class="center aligned color-white">
            {{'GENERAL.GAMES.share' | translate}}
        </p>
        <div *ngIf="isWrapperWindow" class="ui basic segment center aligned padding-0 margin-0">
            <button type="button"
                    class="ui circular calltoaction2 icon button"
                    (click)="callWrapperShare(); googleAnalyticsService.trackEvent('home', 'share', 'wrapper app')">
                <i class="share alternat icon"></i>
            </button>
        </div>

        <div *ngIf="!isWrapperWindow" class="ui basic segment center aligned padding-0 margin-0">
            <button type="button" class="ui circular facebook icon button" shareButton="facebook"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('home', 'share', 'facebook')">
                <i class="fa fa-facebook icon"></i>
            </button>
            <button type="button" class="ui circular twitter icon button" shareButton="twitter"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('home', 'share', 'twitter')">
                <i class="fa fa-twitter icon"></i>
            </button>
            <button type="button" class="ui circular whatsapp icon button" shareButton="whatsapp"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('home', 'share', 'whatsapp')"
                    *ngIf="mobileDetect.device_detection() == 'desktop'">
                <i class="fa fa-whatsapp icon"></i>
            </button>
            <a [href]="'whatsapp://send?text=' + shareUrl | safeUrl" class="ui circular whatsapp icon button"
               (click)="googleAnalyticsService.trackEvent('home', 'share', 'whatsapp')"
               *ngIf="mobileDetect.device_detection() == 'mobile' || mobileDetect.device_detection() == 'tablet'">
                <i class="fa fa-whatsapp icon"></i>
            </a>
            <button type="button" class="ui circular black icon button margin-0"
                    (click)="googleAnalyticsService.trackEvent('home', 'share', 'copied to clipboard')"
                    ngxClipboard
                    [cbContent]="shareUrl"
                    (cbOnSuccess)="showCopyInformation()">
                <i class="fa fa-link icon"></i>
            </button>
        </div>
        <p *ngIf="showcopyinfo" class="center aligned color-white">
            <i class="icon check color-positive"></i> {{'GENERAL.LABELS.NOTIFICATIONS.link_copied' | translate}}
        </p>
    </div>
</div>
