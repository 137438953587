import {Pipe, PipeTransform} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';

@Pipe({
    name: 'dbtranslation',
    pure: false
})
export class DbTranslationsPipe implements PipeTransform {

    constructor(private authenticationService: AuthenticationService) {
    }

    transform(value: string, ...args: any[]): string {
        if (value) {
            const transLang = this.authenticationService.currentLang;
            if (args[0] && typeof args[0] === 'string' &&
                args[1] && typeof args[1] === 'object' &&
                transLang) {
                const translationLabelField = args[0];
                const translationsObject = args[1];

                let translation;
                if (translationsObject.length > 0) {
                    translation = translationsObject.find(
                        (translationItem) =>
                            translationItem.label_field === translationLabelField &&
                            translationItem.iso_code2 === transLang.iso_code2);
                }

                if (translation) {
                    return translation.label_value;
                } else {
                    return value;
                }
            } else {
                return value;
            }
        } else {
            return '';
        }
    }

}
