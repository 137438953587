<div [ngClass]="'ui fluid card simple-game-card-v3 ' + (backgroundImage ? 'withBgImage' : '') + ' tile-style-' + game.game_card_tile_style"
     [fuiTransition]="transitionController"
     (swiperight)="swipeRightEnabled ? previousMatch() : ''"
     (swipeleft)="swipeLeftEnabled ? nextMatch() : ''"
     [ngStyle]="{ 'background-image': (backgroundImage) ? backgroundImage : 'none' }">
    <div class="ui active loader" *ngIf="loading"></div>
    <ng-container *ngIf="!loading && (!showSuccessPage && !showErrorPage && !showWaitingForLoginPage && !showMissingMarketsPage)">
        <div class="content top">
            <span class="ui game-state-label label"
                  [class.black]="backgroundImage && game.game_state !== 3"
                  [class.red]="game.game_state === 3"
                  [class.game-closed]="game.game_state > 3">
                <span *ngIf="(secondsToStart > 7200 || secondsToStart < 0) && game.game_state < 3">
                    <i class="ui schedule icon font-very-bold"></i> {{game.start_date | amLocal | amCalendar:
                    {
                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                        sameElse: tenantService.tenantData.internationalization.date_time_format
                    }
                    }}
                </span>
                <span *ngIf="secondsToStart <= 7200 && secondsToStart > 0 && game.game_state < 3">
                    <betsnaps-countdown #gameStartCountdown
                                        [timeInSeconds]="secondsToStart"
                                        [autoStart]="true"
                                        [secondsForHighlight]="secondsToStart"
                                        [hideAfterFinish]="true"
                                        (onCountdownFinished)="game.game_state = 3"></betsnaps-countdown>
                    <span *ngIf="gameStartCountdown?.hasFinished()">
                        <i class="ui lens icon"></i> {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                    </span>
                </span>
                <span *ngIf="game.game_state === 3">
                    <i class="ui lens icon"></i> {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                </span>
                <span *ngIf="game.game_state === 4 || game.game_state === 5 || game.game_state === 6">
                      {{'GENERAL.GAMES.STATI.finished' | translate | uppercase}}
                </span>
                <span *ngIf="game.game_state === 7">
                    {{'GENERAL.GAMES.STATI.closed' | translate | uppercase}}
                </span>
                <span *ngIf="game.game_state === 99">
                    {{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}
                </span>
            </span>
            <span class="ui game-state-label label green"
                  *ngIf="game.competition_type === 1 || game.competition_type === 3">
                {{'GENERAL.LABELS.h2h' | translate}}
            </span>
            <div class="right floated icons">
                <img class="iconimage" [src]="categoryImageSrc" alt="{{currentMatch.tournament.category_name}}" />
                <i [ngClass]="'sport-icon icon bsi bsi-sport-' + game.sport_id"></i>
            </div>
        </div>
        <div class="content main with-bottom" [class.tile-style-full-width]="game.game_card_tile_style === gameCardTileStyleEnum.FULL_WIDTH">
            <div class="ui row center aligned text-tiny padding-b-10">
                {{this.currentMatchIndex+1}} / {{this.matchList.length}}
            </div>
            <div class="row center aligned text-large">
                {{currentMatch.home.name | uppercase}} &ndash; {{currentMatch.away.name | uppercase}}
            </div>
            <hr class="decent"/>
            <div class="ui center aligned text-big font-very-bold market-name border-top">
                {{currentMatch.markets[0].market_name}}
            </div>
        </div>
        <div class="content bottom" [class.position-relative]="(game.game_card_tile_style === gameCardTileStyleEnum.FULL_WIDTH) || (currentMatch.markets[0] && isCorrectScoreWidgetCompatibleMarket)">
            <div *ngIf="currentMatch.markets[0] && currentMatch.markets[0].outcomes && isCorrectScoreWidgetCompatibleMarket && currentMatchMarketCorrectScoreOptions['home'].length > 0 && currentMatchMarketCorrectScoreOptions['away'].length > 0"
                 class="ui top aligned centered tight grid three column">
                <div class="ui row center aligned">
                    <div class="six wide column">
                        <div class="ui top attached basic button correct-score-swiper-navigation-button correct-score-home-swiper-navigation-button correct-score-home-swiper-navigation-button-next-{{game.game_unique_id}} padding-l-5 padding-t-5 padding-b-0"><i class="icon chevron right huge fa-rotate-270"></i></div>
                        <swiper class="correct-score-home-swiper" [class.color-negative]="!currentMatchMarketCorrectScoreValuesAreValid" #correctScoreHomeSwiper [config]="correctScoreHomeSwiperConfig" [initialize]="correctScoreSwiperInit">
                            <div class="swiper-wrapper">
                                <div class="seven wide swiper-slide" *ngFor="let item of currentMatchMarketCorrectScoreOptions['away'][currentMatchMarketCorrectScoreValueAway]">
                                    <div class="ui row center aligned text-massive padding-t-10 padding-b-10">
                                        {{item}}
                                    </div>
                                </div>
                            </div>
                        </swiper>
                        <div class="ui bottom attached basic button correct-score-swiper-navigation-button correct-score-home-swiper-navigation-button correct-score-home-swiper-navigation-button-prev-{{game.game_unique_id}} padding-l-5 padding-t-0 padding-b-5"><i class="icon chevron right huge fa-rotate-90"></i></div>
                    </div>
                    <div class="two wide column middle aligned">
                        <div class="ui row center aligned text-massive">
                            :
                        </div>
                    </div>
                    <div class="six wide column">
                        <div class="ui top attached basic button correct-score-swiper-navigation-button correct-score-away-swiper-navigation-button correct-score-away-swiper-navigation-button-next-{{game.game_unique_id}} padding-l-5 padding-t-5 padding-b-0"><i class="icon chevron right huge fa-rotate-270"></i></div>
                        <swiper class="correct-score-away-swiper"[class.color-negative]="!currentMatchMarketCorrectScoreValuesAreValid" #correctScoreAwaySwiper [config]="correctScoreAwaySwiperConfig" [initialize]="correctScoreSwiperInit">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" *ngFor="let item of currentMatchMarketCorrectScoreOptions['home'][currentMatchMarketCorrectScoreValueHome]">
                                    <div class="ui row center aligned text-massive padding-t-10 padding-b-10">
                                        {{item}}
                                    </div>
                                </div>
                            </div>
                        </swiper>
                        <div class="ui bottom attached basic button correct-score-swiper-navigation-button correct-score-away-swiper-navigation-button correct-score-away-swiper-navigation-button-prev-{{game.game_unique_id}} padding-l-5 padding-t-0 padding-b-5"><i class="icon chevron right huge fa-rotate-90"></i></div>
                    </div>
                </div>
                <div class="ui row center aligned">
                    <div class="fourteen wide column margin-t-5">
                        <button type="button"
                                [ngClass]="'ui fluid large button outcome-button outcome-' + currentMatchMarketCorrectScoreOutcome?.outcome_id + ' nowrap ' + ((currentBet && currentBet.outcomeId === currentMatchMarketCorrectScoreOutcome?.outcome_id) ? 'selected' : '')"
                                [class.narrow]="game.game_card_tile_style !== gameCardTileStyleEnum.LARGE"
                                [class.disabled]="!currentMatchMarketCorrectScoreValuesAreValid"
                                (click)="storeBet(currentMatchMarketCorrectScoreOutcome?.outcome_id, currentMatchMarketCorrectScoreOutcome?.odd_decimal)">
                            <span class="outcome-name truncate">
                                {{currentMatchMarketCorrectScoreValuesAreValid ? currentMatchMarketCorrectScoreOptionsSelected : ('GENERAL.GAMES.invalid_bet' | translate | uppercase)}}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="currentMatch.markets[0] && currentMatch.markets[0].outcomes && !isCorrectScoreWidgetCompatibleMarket"
                 class="ui top aligned centered tight grid"
                 [ngClass]="{'equal width': currentMatch.markets[0].outcomes.length < 4, 'three column': currentMatch.markets[0].outcomes.length > 3}">
                <div *ngFor="let outcome of currentMatch.markets[0].outcomes;" class="column padding-t-5">
                    <button type="button"
                            [ngClass]="'ui fluid large button outcome-button outcome-' + outcome.outcome_id + ' nowrap ' + ((currentBet && currentBet.outcomeId === outcome.outcome_id) ? 'selected' : '')"
                            [class.narrow]="game.game_card_tile_style !== gameCardTileStyleEnum.LARGE"
                            (click)="storeBet(outcome.outcome_id, outcome.odd_decimal)">
                    <span class="outcome-name truncate" *ngIf="outcome.outcome_name_modified">
                        {{outcome.outcome_name_modified}}
                    </span>
                        <span class="outcome-name truncate" *ngIf="!outcome.outcome_name_modified">
                        {{outcome.outcome_name}}
                    </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="singleplayer-games-swiper-navigation-button singleplayer-games-swiper-navigation-button-prev"
             *ngIf="this.currentMatchIndex !== 0" (click)="previousMatch()">
            <i class="navigate before huge icon"></i>
        </div>
        <div class="singleplayer-games-swiper-navigation-button singleplayer-games-swiper-navigation-button-next"
             *ngIf="currentBet" (click)="nextMatch()">
            <i class="navigate next huge icon"></i>
        </div>
    </ng-container>
    <div *ngIf="showWaitingForLoginPage" class="content main success">
        <div class="inner center aligned">
            <h3>{{'GENERAL.GAMES.SIMPLE.waiting_for_login_title' | translate}}</h3>
            <div class="margin-b-10">
                <p>{{'GENERAL.GAMES.SIMPLE.waiting_for_login_text' | translate}}</p>
            </div>
            <button type="button" class="ui large button" (click)="finish()">
                <strong class="nowrap">{{'GENERAL.GAMES.SIMPLE.waiting_for_login_button_text' | translate}}</strong>
            </button>
        </div>
    </div>
    <div *ngIf="showSuccessPage" class="content main success">
        <div class="ui active text loader" *ngIf="successPageLoading">{{successPageStatus}}</div>
        <div class="inner center aligned" *ngIf="!successPageLoading" [class.padding-10]="successPageBetsErrors">
            <ng-container *ngIf="!successPageBetsErrors">
                <h3>{{'GENERAL.GAMES.SIMPLE.success_text' | translate}}</h3>
            </ng-container>
            <ng-container *ngIf="successPageBetsErrors">
                <h3>{{'GENERAL.GAMES.SIMPLE.success_text_with_bets_errors' | translate}}</h3>
                <div class="ui negative message padding-10">
                    <p>{{'GENERAL.GAMES.SIMPLE.success_bets_errors_text' | translate}}</p>
                </div>
            </ng-container>
            <button type="button" class="ui large button" (click)="goToSnapDetailPage()">
                <strong class="nowrap">{{'GENERAL.GAMES.SIMPLE.success_button_text' | translate}}</strong>
            </button>
        </div>
    </div>
    <div *ngIf="showErrorPage" class="content main error">
        <div class="inner padding-10 center aligned">
            <h3>{{'GENERAL.GAMES.SIMPLE.error_title' | translate}}</h3>
            <div class="ui negative message">
                <p>{{'GENERAL.GAMES.SIMPLE.error_text' | translate}}</p>
            </div>
            <button type="button" class="ui red button" (click)="exitWithError()">
                <strong class="nowrap">{{'GENERAL.GAMES.SIMPLE.error_button_text' | translate}}</strong>
            </button>
        </div>
    </div>
    <div *ngIf="showMissingMarketsPage" class="content main error">
        <div class="inner padding-10 center aligned">
            <h3>{{'GENERAL.GAMES.SIMPLE.missing_markets_title' | translate}}</h3>
            <div class="ui negative message">
                <p>{{'GENERAL.GAMES.SIMPLE.missing_markets_text' | translate}}</p>
            </div>
            <button type="button" class="ui red button" (click)="exitMissingMarkets()">
                <strong class="nowrap">{{'GENERAL.GAMES.SIMPLE.missing_markets_button_text' | translate}}</strong>
            </button>
        </div>
    </div>
</div>
