<div class="ui secondary borderless segment" *ngIf="currentUser">
    <div class="ui one column middle aligned centered grid">
        <div class="three column middle aligned row padding-b-0">
            <div class="three wide column"></div>
            <div class="ten wide center aligned column text-large font-very-bold">
                {{currentUser.username}}
            </div>
            <div class="three wide right aligned column">
                <a *ngIf="!isB2b" routerLink="/profile/edit" class="ui circular icon calltoaction2 button margin-0">
                    <i class="edit icon"></i>
                </a>
            </div>
        </div>
        <div class="one column middle aligned row">
            <div class="column center aligned">
                <a routerLink="/profile/media">
                    <img class="ui middle aligned circular bordered secondary large image profileimg bg-white"
                         [src]="currentUser.media_url_big"/>
                </a>
            </div>
            <div class="column center aligned margin-t-minus">
                <a routerLink="/profile/media" class="ui circular icon white button margin-0">
                    <i class="photo camera big icon"></i>
                </a>
            </div>
        </div>
        <div *ngIf="showBalance" class="one column middle aligned row padding-0">
            <div class="column center aligned padding-t-0 padding-b-0 text small">
                {{'PROFILE.OVERVIEW.current_balance' | translate}}
            </div>
            <div class="column center aligned padding-t-0">
                <div class="ui currency tertiary label margin-l-0">
                    <span class="currency-wrap">
                        <span class="currency-symbol"
                              [class.longsymbol]="currentUser.wallets[0].currency.symbol.length > 1">
                            {{currentUser.wallets[0].currency.symbol}}
                        </span>
                    </span>
                    <span class="detail">{{currentUser.wallets[0].balance | number:'1.2-2' }}</span>
                </div>
            </div>
        </div>
        <div class="one column middle aligned row">
            <div class="column center aligned">
                <a routerLink="/profile/wallet" class="ui calltoaction2 button">
                    {{'PROFILE.OVERVIEW.MENU.wallet_details' | translate | uppercase}}
                </a>
            </div>
        </div>
    </div>
</div>

<div class="ui vertical left aligned fluid menu">
    <a routerLink="/profile/snaphistory" class="ui dropdown item">
        {{'PROFILE.OVERVIEW.MENU.history' | translate}}
        <i class="chevron right big icon"></i>
    </a>
</div>

<div class="ui vertical left aligned fluid menu">
    <a *ngIf="!isB2b" routerLink="/profile/password" class="item">
        {{'PROFILE.OVERVIEW.MENU.change_password' | translate}}
        <i class="chevron right big icon"></i>
    </a>
    <a *ngIf="!isB2b" routerLink="/profile/email" class="item">
        {{'PROFILE.OVERVIEW.MENU.change-email' | translate}}
        <i class="chevron right big icon"></i>
    </a>
    <a routerLink="/profile/settings" class="item">
        {{'PROFILE.OVERVIEW.MENU.settings' | translate}}
        <i class="chevron right big icon"></i>
    </a>
    <a *ngIf="!isB2b" routerLink="/profile/administration" class="item">
        {{'PROFILE.OVERVIEW.MENU.administration' | translate}}
        <i class="chevron right big icon"></i>
    </a>
    <a *ngIf="showPrivacyButton" routerLink="/profile/privacy" class="item">
        {{'PROFILE.OVERVIEW.MENU.privacy' | translate}}
        <i class="chevron right big icon"></i>
    </a>
</div>

<div class="ui basic center aligned segment padding-0 margin-0">
    <button type="button" (click)="logout()"
            [class.loading]="logoutProcess"
            [disabled]="logoutProcess"
            class="ui basic large button">
        {{'PROFILE.OVERVIEW.logout_btn' | translate | uppercase}}
    </button>
</div>
