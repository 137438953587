import {
    AuthenticationService,
    CreateSnapService,
    ErrorService,
    FriendshipState,
    FriendshipStateEnum,
    FriendsService,
    GoogleAnalyticsService,
    IPopup,
    MyModalService,
    MyTranslateService,
    TenantService
} from '../../../shared';
import {Router} from '@angular/router';
import { ElementRef, EventEmitter, OnDestroy, OnInit, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameListHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse,
    AppHttpResponsesFriendsFriendInvitationHttpResponse as FriendInvitationHttpResponse,
    AppHttpResponsesFriendsFriendInvitationListHttpResponse as FriendInvitationListHttpResponse,
    AppHttpResponsesFriendsFriendListHttpResponse as FriendListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse, AppHttpResponsesUsersPlayerPublicHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse
} from '../../../api';
import {ActiveModal, ModalTemplate} from '@aligorji/ngx-fomantic-ui';
import {take, takeWhile} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {cloneDeep} from 'lodash';
import {PlayerService} from '../../../shared/services/player.service';

@Directive()
export abstract class AUserAvatarComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    public user?: PlayerPublicHttpResponse;
    public userId?: number;
    public fetchPublicPlayer: boolean = false;
    public clickAble = true;
    public imageSize = 'small';
    public addClass = '';
    public closePopup: IPopup;
    public showFriendshipStateIcon: boolean = false;
    public notificationIconClass: string = null;

    public onUserAvatarClick: EventEmitter<any> = new EventEmitter();

    public currentUser: PlayerHttpResponse;
    public friendshipState: FriendshipState = {state: FriendshipStateEnum.NOT_A_FRIEND, friendInvitation: null};
    public friendshipStateEnum = FriendshipStateEnum;

    public isClickAble = false;

    public joinedGameList: GameListHttpResponse;
    public processGameList: boolean = false;

    public processButton: boolean = false;
    public processAcceptFriendInvitationButton: boolean = false;
    public processDeclineFriendInvitationButton: boolean = false;
    public processDeleteFriendInvitationButton: boolean = false;
    public processChallengeButton: boolean = false;
    public challengeFriendPossible: boolean = false;

    public addFriendExpanded: boolean = false;

    public userProfileModal: ActiveModal<{}, {}, {}>;

    public showAvatarLoader: boolean = true;

    public abstract inviteMessage: ElementRef;
    public abstract userProfileModalTemplate: ModalTemplate<null, string, string>;

    constructor(public translations: MyTranslateService,
                protected myModalService: MyModalService,
                protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected friendsService: FriendsService,
                protected createSnapService: CreateSnapService,
                protected playerService: PlayerService,
                protected errorService: ErrorService,
                protected router: Router) {
    }

    protected abstract openUserProfileModal();

    public ngOnInit() {

        // After 10 seconds show avatar default image instead of loader
        this.hideAvatarLoaderAfterSeconds(10);

        // If userId and user are empty
        if (!this.userId && !this.user) {
            return;
        }
        // Set userId if empty or if user is set
        if (!this.userId || this.user) {
            this.userId = this.user.id;
        }

        this.fetchPublicPlayerIfNecessary();

        this.challengeFriendPossible = (this.tenantService.tenantData.configuration.allow_h2h && this.tenantService.tenantData.configuration.show_createbetsnaps);
        this.friendshipState = this.friendsService.getFriendshipState(this.userId);
        this.currentUser = this.authenticationService.currentUser;

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (userData) {
                        this.currentUser = cloneDeep(userData);
                        this.isClickAble = this.checkIfAvatarIsClickAble();
                    } else {
                        this.currentUser = null;
                    }
                });

        // set friendship state
        this.friendsService.userFriends$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendListHttpResponse: FriendListHttpResponse) => {
                    if (friendListHttpResponse) {
                        this.friendshipState = this.friendsService.getFriendshipState(this.userId);
                    }
                }
            );
        this.friendsService.userFriendInvitationsOutgoing$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (outgoingFriendInvitationList: FriendInvitationListHttpResponse) => {
                    if (outgoingFriendInvitationList) {
                        this.friendshipState = this.friendsService.getFriendshipState(this.userId);
                    }
                }
            );
        this.friendsService.userFriendInvitationsIncomingDeclined$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (incomingDeclinedFriendInvitationList: FriendInvitationListHttpResponse) => {
                    if (incomingDeclinedFriendInvitationList) {
                        this.friendshipState = this.friendsService.getFriendshipState(this.userId);
                    }
                }
            );
        this.friendsService.userFriendInvitationsIncoming$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (incomingFriendInvitationList: FriendInvitationListHttpResponse) => {
                    if (incomingFriendInvitationList) {
                        this.friendshipState = this.friendsService.getFriendshipState(this.userId);
                    }
                }
            );
    }

    // only clickable if currentUser exists and user tenants are equal
    private checkIfAvatarIsClickAble(): boolean {
        if (!this.currentUser || !this.user) {
            return false;
        }

        return (this.clickAble && (this.userId !== this.currentUser.id && this.user.tenant_id === this.currentUser.tenant_id));
    }

    protected fetchPublicPlayerIfNecessary(): void {
        if (!this.user) {
            const publicPlayer = this.playerService.getStoredPublicPlayer(this.userId);
            if (publicPlayer) {
                this.user = publicPlayer;
                this.isClickAble = this.checkIfAvatarIsClickAble();
            } else if (this.fetchPublicPlayer) {
                this.playerService.addPublicPlayerIdToFetchQueue(this.userId);
                this.playerService.publicPlayersUpdate$
                    .pipe(takeWhile(() => (!this.user && this.componentAlive)))
                    .subscribe((updatedPublicPlayers: AppHttpResponsesUsersPlayerPublicHttpResponse[]) => {
                        if (updatedPublicPlayers) {
                            const publicPlayer = updatedPublicPlayers.find(
                                (publicPlayer: PlayerPublicHttpResponse) => publicPlayer.id === this.userId
                            );
                            if (publicPlayer) {
                                this.user = publicPlayer;
                                this.isClickAble = this.checkIfAvatarIsClickAble();
                            }
                        }
                    });
            }
        }
    }

    protected hideAvatarLoaderAfterSeconds(seconds: number): void {
        if (!this.user) {
            const self = this;
            setTimeout(function() {
                self.showAvatarLoader = false;
            }, (seconds * 1000));
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    public userAvatarClicked(): void {
        if (this.isClickAble) {
            if (this.closePopup) {
                this.closePopup.close();
            }
            this.openUserProfileModal();
            this.onUserAvatarClick.emit();

            this.googleAnalyticsService.trackEvent(
                'profile - avatar',
                'view user',
                'avatar modal'
            );
        }
    }

    public showAddFriend() {
        if (!this.currentUser.username || !this.currentUser.is_username_confirmed) {
            this.myModalService.openUsernameInputModal().onApprove(() => this.showAddFriend());
        } else {
            this.addFriendExpanded = true;
            const self = this;
            setTimeout(() => { // this will make the execution after the above boolean has changed
                if (self.inviteMessage) {
                    self.inviteMessage.nativeElement.focus();
                }
            }, 10);
        }
    }

    public addFriend(friendUserId: number, inviteMessage: string): void {
        this.processButton = true;
        this.friendsService.addFriend(
            friendUserId,
            inviteMessage
        ).pipe(take(1))
        .subscribe({
            next: (friendInvitationHttpResponse: FriendInvitationHttpResponse) => {
                if (this.friendshipState.state === FriendshipStateEnum.INCOMING_PENDING_FRIEND_INVITATION || this.friendshipState.state === FriendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION) {
                    this.friendshipState.state = FriendshipStateEnum.FRIEND;
                    this.friendshipState.friendInvitation = null;
                } else {
                    this.friendshipState.state = FriendshipStateEnum.OUTGOING_FRIEND_INVITATION;
                    this.friendshipState.friendInvitation = friendInvitationHttpResponse;
                }
                this.processButton = false;
                this.addFriendExpanded = false;
            },
            error: (err: HttpErrorResponse) => {
                this.processButton = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    public removeFriend(friendUserId: number): void {
        this.processButton = true;
        this.friendsService.removeFriend(
            friendUserId
        ).pipe(take(1))
        .subscribe({
            next: () => {
                this.joinedGameList = null;
                this.friendshipState.state = FriendshipStateEnum.NOT_A_FRIEND;
                this.friendshipState.friendInvitation = null;
                this.processButton = false;
            },
            error: (err: HttpErrorResponse) => {
                this.processButton = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    public challengeFriend(friendUser: PlayerPublicHttpResponse): void {
        if (this.challengeFriendPossible) {
            this.processChallengeButton = true;
            this.createSnapService.challengedUser = friendUser;
            this.router.navigate(['/createsnap/select-league']);
            return;
        }
    }

    public acceptFriendInvitation(friendInvitation: FriendInvitationHttpResponse) {
        this.processAcceptFriendInvitationButton = true;
        this.friendsService.acceptFriendInvitation(
            friendInvitation
        ).pipe(take(1))
        .subscribe({
            next: (friendHttpResponse: FriendHttpResponse) => {
                this.friendshipState.state = FriendshipStateEnum.FRIEND;
                this.friendshipState.friendInvitation = null;
                this.processAcceptFriendInvitationButton = false;
            },
            error: (err: HttpErrorResponse) => {
                this.processAcceptFriendInvitationButton = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });

        this.googleAnalyticsService.trackEvent('friends', 'accept', 'friend invitations');
    }

    public declineFriendInvitation(friendInvitation: FriendInvitationHttpResponse) {
        this.processDeclineFriendInvitationButton = true;
        this.friendsService.declineFriendInvitation(
            friendInvitation
        ).pipe(take(1))
        .subscribe({
            next: (friendInvitationHttpResponse: FriendInvitationHttpResponse) => {
                this.friendshipState.state = FriendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION;
                this.friendshipState.friendInvitation = friendInvitationHttpResponse;
                this.processDeclineFriendInvitationButton = false;
            },
            error: (err: HttpErrorResponse) => {
                this.processDeclineFriendInvitationButton = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });

        this.googleAnalyticsService.trackEvent('friends', 'decline', 'friend invitations');
    }

    public deleteFriendInvitation(friendInvitation: FriendInvitationHttpResponse) {
        this.processDeleteFriendInvitationButton = true;
        this.friendsService.deleteFriendInvitation(
            friendInvitation
        ).pipe(take(1))
        .subscribe({
            next: () => {
                this.friendshipState.state = FriendshipStateEnum.NOT_A_FRIEND;
                this.friendshipState.friendInvitation = null;
                this.processDeleteFriendInvitationButton = false;
            },
            error: (err: HttpErrorResponse) => {
                this.processDeleteFriendInvitationButton = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });

        this.googleAnalyticsService.trackEvent('friends', 'delete', 'friend invitations');
    }

}
