import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {WindowRef} from './window.service';
import {DOCUMENT} from '@angular/common';
import {TenantService} from './tenant.service';

@Injectable({
    providedIn: 'root'
})
export class SideNavService {

    private nativeWindow: Window;
    private currWindowScrollPosition = 0;
    private renderer: Renderer2;

    public sideProfileMenuVisible: boolean = false;

    constructor(@Inject(DOCUMENT) private document: any,
                private tenantService: TenantService,
                private windowRef: WindowRef,
                private rendererFactory: RendererFactory2) {
        this.nativeWindow = windowRef.nativeWindow;
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    toggleSideProfileMenu() {
        if (!this.sideProfileMenuVisible) {
            // save current scroll position
            this.currWindowScrollPosition = Math.floor(
                this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
            );
            this.renderer.addClass(this.document.documentElement, 'noscroll');
            this.sideProfileMenuVisible = true;
            this.tenantService.loadTenantFooterDataIfNecessary();
        } else {
            this.renderer.removeClass(this.document.documentElement, 'noscroll');
            // scroll to saved position
            this.nativeWindow.window.scrollTo(0, this.currWindowScrollPosition || 0);
            this.sideProfileMenuVisible = false;
        }
    }
}
