<div class="ui tertiary borderless segment primary-color">
    <div class="ui header margin-t-0">
        {{'SIDEBARS.CREATESNAP.heading' | translate | uppercase}}
    </div>
    <div class="content">
        <p>{{'SIDEBARS.CREATESNAP.text' | translate}}</p>
        <a routerLink="/createsnap" class="ui fluid big button padding-l-0 padding-r-0"
           (click)="googleAnalyticsService.trackEvent('sidebar', 'create snap', 'button');">
            <i class="add circle big icon"></i>{{'ROUTEDATA.PAGETITLES.createsnap' | translate | uppercase}}
        </a>
    </div>
</div>