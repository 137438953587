/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantSocialNetworkHttpResponse
 */
export interface AppHttpResponsesTenantsTenantSocialNetworkHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Social Login Facebook
     */
    facebook_login_enabled?: boolean;
    /**
     * Social Facebook ID
     */
    facebook_id?: string;
    /**
     * Social Login Google
     */
    google_login_enabled?: boolean;
    /**
     * Social Google Client ID
     */
    google_client_id?: string;
    /**
     * Social Google API Key
     */
    google_api_key?: string;
    /**
     * Google Analytics Tracking Id
     */
    google_analytics_tracking_id?: string;
}

