<ng-template let-context let-modal="modal" #profileChangeEmailV3Modal>
    <div class="header">
        {{'PROFILE.OVERVIEW.MENU.change-email' | translate}}
    </div>

    <div class="content" *ngIf="currentUser">
        <div class="ui active centered inline text loader" *ngIf="processConfirmation">
            {{'GENERAL.loadertext' | translate}}
        </div>

        <form *ngIf="!processConfirmation" class="ui form v3" novalidate [formGroup]="emailForm"
              (ngSubmit)="onSubmit()"
              [class.error]="emailForm.invalid && !processForm">

            <div class="field">
                <p class="text-label">
                    {{'PROFILE.CHANGEEMAIL.current_email' | translate}}
                </p>
                <p class="font-bold">{{currentUser.email}}</p>
            </div>

            <div class="field">
                <label for="new_email">{{'PROFILE.CHANGEEMAIL.new_email_field' | translate}}</label>
                <input id="new_email" type="text" formControlName="new_email">
                <div class="ui pointing red basic label"
                     *ngIf="emailForm.get('new_email').invalid && (emailForm.get('new_email').dirty || emailForm.get('new_email').touched)">
                    <p *ngIf="emailForm.get('new_email').hasError('required')">
                        {{'PROFILE.CHANGEEMAIL.ERRORS.new_email_field_required' | translate}}
                    </p>
                    <p *ngIf="emailForm.get('new_email').dirty && emailForm.get('new_email').hasError('email')">
                        {{'PROFILE.CHANGEEMAIL.ERRORS.new_email:validation_error_email' | translate}}
                    </p>
                </div>
            </div>

            <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10">
                <button class="ui large button margin-0" type="submit" [class.loading]="processForm"
                        [disabled]="processForm || !emailForm.valid">
                    {{'PROFILE.CHANGEEMAIL.initialize_emailchange_btn' | translate | uppercase}}
                </button>
            </div>

        </form>

        <div class="ui segment" *ngIf="showAlert">
            <fui-message class="success" [hasDismissButton]="false">
                <div class="header">
                    {{'PROFILE.CHANGEEMAIL.emailchange_initialize_successfully_title' | translate}}
                </div>
                <p>{{'PROFILE.CHANGEEMAIL.emailchange_initialize_successfully' | translate}}</p>
            </fui-message>
        </div>
    </div>
</ng-template>
