import {Component, Input, OnInit} from '@angular/core';
import {ErrorService, MyTranslateService, TenantService} from '../../../shared';
import {CMSService} from '../../../shared/services/cms.service';
import {
    AppHttpResponsesCMSsCMSHttpResponse as CMSHttpResponse,
    AppHttpResponsesCMSsCMSListHttpResponse as CMSListHttpResponse,
} from '../../../api';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-cms',
    templateUrl: './cms.html'
})
export class CMSComponent implements OnInit {
    @Input() cmsKey: string;
    @Input() translateCutIndex: number = 0;

    private cmsListSubscription: Subscription;
    public cmsList: CMSListHttpResponse;
    public cmsListProcess: boolean = false;

    public cms: CMSHttpResponse;

    constructor(
        public cmsService: CMSService,
        private errorService: ErrorService,
        public tenantService: TenantService,
        public translations: MyTranslateService
    ) {
    }

    ngOnInit() {
        if (this.cmsService.cmsList) {
            this.cms = this.cmsService.findCMSByKey(this.cmsKey);
        } else {
            if (!this.cmsListProcess) {
                this.cmsListProcess = true;
            }
            this.cmsListSubscription = this.cmsService.getCMSList(this.tenantService.tenantData.id)
                .pipe(take(1))
                .subscribe({
                    next: (cmsList: CMSListHttpResponse) => {
                        if (cmsList) {
                            this.cmsService.setCMSList(cmsList);
                            this.cmsListProcess = false;

                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        this.errorService.handleHttpErrorResponse(err);
                        this.cmsListProcess = false;
                    },
                    complete: () => {
                        this.cms = this.cmsService.findCMSByKey(this.cmsKey);
                        this.cmsListSubscription.unsubscribe();
                        this.cmsListSubscription = null;
                    }
                });
        }
    }
}
