import {Injectable} from '@angular/core';
import {TenantService} from './tenant.service';
import {GameCardDefaultComponent} from '../../ui/components/game-card/game-card-default/game-card-default.component';
import {GameCardV3Component} from '../../ui/components/game-card/game-card-v3/game-card-v3.component';
import {UserAvatarDefaultComponent} from '../../ui/components/user-avatar/user-avatar-default/user-avatar-default.component';
import {UserAvatarV3Component} from '../../ui/components/user-avatar/user-avatar-v3/user-avatar-v3.component';
import {BetsnapdetailDefaultComponent} from '../../ui/views/betsnapdetail/betsnapdetail-default/betsnapdetail-default.component';
import {BetsnapdetailV3Component} from '../../ui/views/betsnapdetail/betsnapdetail-v3/betsnapdetail-v3.component';
import {BetsnapdetailDetailsDefaultComponent} from '../../ui/views/betsnapdetail/betsnapdetail-details/betsnapdetail-details-default/betsnapdetail-details-default.component';
import {BetsnapdetailDetailsV3Component} from '../../ui/views/betsnapdetail/betsnapdetail-details/betsnapdetail-details-v3/betsnapdetail-details-v3.component';
import {HeaderDefaultComponent} from '../../ui/layout/header/header-default/header-default.component';
import {HeaderV3Component} from '../../ui/layout/header/header-v3/header-v3.component';
import {NewsfeedDefaultComponent} from '../../ui/views/newsfeed/newsfeed-default/newsfeed-default.component';
import {NewsfeedV3Component} from '../../ui/views/newsfeed/newsfeed-v3/newsfeed-v3.component';
import {ProfilePrivacyDefaultComponent} from '../../ui/views/profile/profile-privacy/profile-privacy-default/profile-privacy-default.component';
import {ProfilePrivacyV3Component} from '../../ui/views/profile/profile-privacy/profile-privacy-v3/profile-privacy-v3.component';
import {ProfileWalletDefaultComponent} from '../../ui/views/profile/profile-wallet/profile-wallet-default/profile-wallet-default.component';
import {ProfileWalletV3Component} from '../../ui/views/profile/profile-wallet/profile-wallet-v3/profile-wallet-v3.component';
import {ArenaDefaultComponent} from '../../ui/views/arena/arena-default/arena-default.component';
import {ArenaV3Component} from '../../ui/views/arena/arena-v3/arena-v3.component';
import {ProfileEditDefaultComponent} from '../../ui/views/profile/profile-edit/profile-edit-default/profile-edit-default.component';
import {ProfileEditV3Component} from '../../ui/views/profile/profile-edit/profile-edit-v3/profile-edit-v3.component';
import {ProfileChangeEmailDefaultComponent} from '../../ui/views/profile/profile-change-email/profile-change-email-default/profile-change-email-default.component';
import {ProfileChangeEmailV3Component} from '../../ui/views/profile/profile-change-email/profile-change-email-v3/profile-change-email-v3.component';
import {ProfileSettingsDefaultComponent} from '../../ui/views/profile/profile-settings/profile-settings-default/profile-settings-default.component';
import {ProfileSettingsV3Component} from '../../ui/views/profile/profile-settings/profile-settings-v3/profile-settings-v3.component';
import {BetsnapdetailMatchesDefaultComponent} from '../../ui/views/betsnapdetail/betsnapdetail-matches/betsnapdetail-matches-default/betsnapdetail-matches-default.component';
import {BetsnapdetailMatchesV3Component} from '../../ui/views/betsnapdetail/betsnapdetail-matches/betsnapdetail-matches-v3/betsnapdetail-matches-v3.component';
import {MatchCardDefaultComponent} from '../../ui/components/match-card/match-card-default/match-card-default.component';
import {MatchCardV3Component} from '../../ui/components/match-card/match-card-v3/match-card-v3.component';
import {PlaceBetDialogDefaultComponent} from '../../ui/components/place-bet-dialog/place-bet-dialog-default/place-bet-dialog-default.component';
import {PlaceBetDialogV3Component} from '../../ui/components/place-bet-dialog/place-bet-dialog-v3/place-bet-dialog-v3.component';
import {BetsnapdetailBetsDefaultComponent} from '../../ui/views/betsnapdetail/betsnapdetail-bets/betsnapdetail-bets-default/betsnapdetail-bets-default.component';
import {BetsnapdetailBetsV3Component} from '../../ui/views/betsnapdetail/betsnapdetail-bets/betsnapdetail-bets-v3/betsnapdetail-bets-v3.component';
import {FriendsListDefaultComponent} from '../../ui/views/friends/friends-list/friend-list-default/friends-list-default.component';
import {FriendsListV3Component} from '../../ui/views/friends/friends-list/friend-list-v3/friends-list-v3.component';
import {FriendsRequestsDefaultComponent} from '../../ui/views/friends/friends-requests/friends-requests-default/friends-requests-default.component';
import {FriendsRequestsV3Component} from '../../ui/views/friends/friends-requests/friends-requests-v3/friends-requests-v3.component';
import {BetsnapdetailRankingDefaultComponent} from '../../ui/views/betsnapdetail/betsnapdetail-ranking/betsnapdetail-ranking-default/betsnapdetail-ranking-default.component';
import {BetsnapdetailRankingV3Component} from '../../ui/views/betsnapdetail/betsnapdetail-ranking/betsnapdetail-ranking-v3/betsnapdetail-ranking-v3.component';
import {LeftSidebarDefaultComponent} from '../../ui/components/sidebar-components/sidebar-left/sidebar-left-default/left-sidebar-default.component';
import {RightSidebarDefaultComponent} from '../../ui/components/sidebar-components/right-sidebar/right-sidebar-default/right-sidebar-default.component';
import {RightSidebarV3Component} from '../../ui/components/sidebar-components/right-sidebar/right-sidebar-v3/right-sidebar-v3.component';
import {LeftSidebarV3Component} from '../../ui/components/sidebar-components/sidebar-left/siderbar-left-v3/left-sidebar-v3.component';
import {RankingDefaultComponent} from '../../ui/views/ranking/ranking-default/ranking-default.component';
import {RankingV3Component} from '../../ui/views/ranking/ranking-v3/ranking-v3.component';
import {BetsnapdetailVendorGamesDefaultComponent} from '../../ui/views/betsnapdetail/betsnapdetail-vendor-games/betsnapdetail-vendor-games-default/betsnapdetail-vendor-games-default.component';
import {BetsnapdetailVendorGamesV3Component} from '../../ui/views/betsnapdetail/betsnapdetail-vendor-games/betsnapdetail-vendor-games-v3/betsnapdetail-vendor-games-v3.component';
import {GameVendorGameCardDefaultComponent} from '../../ui/components/game-vendor-game-card/game-vendor-game-card-default/game-vendor-game-card-default.component';
import {GameVendorGameCardV3Component} from '../../ui/components/game-vendor-game-card/game-vendor-game-card-v3/game-vendor-game-card-v3.component';
import {BetsnapdetailVendorGameParticipationsDefaultComponent} from '../../ui/views/betsnapdetail/betsnapdetail-vendor-game-participations/betsnapdetail-vendor-game-participations-default/betsnapdetail-vendor-game-participations-default.component';
import {BetsnapdetailVendorGameParticipationsV3Component} from '../../ui/views/betsnapdetail/betsnapdetail-vendor-game-participations/betsnapdetail-vendor-game-participations-v3/betsnapdetail-vendor-game-participations-v3.component';
import {MainDefaultComponent} from '../../ui/layout/main/main-default/main-default.component';
import {MainV3Component} from '../../ui/layout/main/main-v3/main-v3.component';
import {ProfileOverviewDefaultComponent} from '../../ui/views/profile/profile-overview/profile-overview-default/profile-overview-default.component';
import {ProfileOverviewV3Component} from '../../ui/views/profile/profile-overview/profile-overview-v3/profile-overview-v3.component';

@Injectable({
    providedIn: 'root'
})
export class DynamicComponentLoaderService {

    private componentMap = {
        // components
        'header': {
            'default': HeaderDefaultComponent,
            'v3': HeaderV3Component
        },
        'main': {
            'default': MainDefaultComponent,
            'v3': MainV3Component
        },
        'game-card': {
            'default': GameCardDefaultComponent,
            'v3': GameCardV3Component
        },
        'match-card': {
            'default': MatchCardDefaultComponent,
            'v3': MatchCardV3Component
        },
        'game-vendor-game-card': {
            'default': GameVendorGameCardDefaultComponent,
            'v3': GameVendorGameCardV3Component
        },
        'place-bet-dialog': {
            'default': PlaceBetDialogDefaultComponent,
            'v3': PlaceBetDialogV3Component
        },
        'user-avatar': {
            'default': UserAvatarDefaultComponent,
            'v3': UserAvatarV3Component
        },
        'left-sidebar': {
            'default': LeftSidebarDefaultComponent,
            'v3': LeftSidebarV3Component
        },
        'right-sidebar': {
            'default': RightSidebarDefaultComponent,
            'v3': RightSidebarV3Component
        },

        // views
        'arena': {
            'default': ArenaDefaultComponent,
            'v3': ArenaV3Component
        },
        'betsnapdetail': {
            'default': BetsnapdetailDefaultComponent,
            'v3': BetsnapdetailV3Component
        },
        'betsnapdetail-details': {
            'default': BetsnapdetailDetailsDefaultComponent,
            'v3': BetsnapdetailDetailsV3Component
        },
        'betsnapdetail-matches': {
            'default': BetsnapdetailMatchesDefaultComponent,
            'v3': BetsnapdetailMatchesV3Component
        },
        'betsnapdetail-game-vendor-games': {
            'default': BetsnapdetailVendorGamesDefaultComponent,
            'v3': BetsnapdetailVendorGamesV3Component
        },
        'betsnapdetail-bets': {
            'default': BetsnapdetailBetsDefaultComponent,
            'v3': BetsnapdetailBetsV3Component
        },
        'betsnapdetail-game-vendor-game-participations': {
            'default': BetsnapdetailVendorGameParticipationsDefaultComponent,
            'v3': BetsnapdetailVendorGameParticipationsV3Component
        },
        'betsnapdetail-ranking': {
            'default': BetsnapdetailRankingDefaultComponent,
            'v3': BetsnapdetailRankingV3Component
        },
        'newsfeed': {
            'default': NewsfeedDefaultComponent,
            'v3': NewsfeedV3Component
        },
        'friends-list': {
            'default': FriendsListDefaultComponent,
            'v3': FriendsListV3Component
        },
        'friends-requests': {
            'default': FriendsRequestsDefaultComponent,
            'v3': FriendsRequestsV3Component
        },
        'profile-overview': {
            'default': ProfileOverviewDefaultComponent,
            'v3': ProfileOverviewV3Component
        },
        'profile-privacy': {
            'default': ProfilePrivacyDefaultComponent,
            'v3': ProfilePrivacyV3Component
        },
        'profile-wallet': {
            'default': ProfileWalletDefaultComponent,
            'v3': ProfileWalletV3Component
        },
        'profile-edit': {
            'default': ProfileEditDefaultComponent,
            'v3': ProfileEditV3Component
        },
        'profile-change-email': {
            'default': ProfileChangeEmailDefaultComponent,
            'v3': ProfileChangeEmailV3Component
        },
        'profile-settings': {
            'default': ProfileSettingsDefaultComponent,
            'v3': ProfileSettingsV3Component
        },
        'ranking': {
            'default': RankingDefaultComponent,
            'v3': RankingV3Component
        }
    };

    private componentTemplateToLoad = 'default';

    constructor(private tenantService: TenantService) {
        this.componentTemplateToLoad = this.tenantService.componentTemplateToLoad;
    }

    public getComponent(key: string): any {
        if (this.componentMap[key]) {
            if (this.componentMap[key][this.componentTemplateToLoad]) {
                return this.componentMap[key][this.componentTemplateToLoad];
            } else {
                return this.componentMap[key]['default'];
            }
        }
        return null;
    }
}
