import {ComponentModalConfig, ModalSize} from '@aligorji/ngx-fomantic-ui';
import {UsernameModalComponent} from '../../ui/components/username-modal/username-modal.component';

export class UserNameModal extends ComponentModalConfig<void, void, void> {
        constructor() {
            super(UsernameModalComponent);
            this.isClosable = false;
            this.transitionDuration = 200;
            this.size = ModalSize.Tiny;
            this.mustScroll = true;
        }
}
