/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantPrizeHttpResponse
 */
export interface AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeHttpResponse { 
    /**
     * Tenant Prize ID
     */
    id: number;
    /**
     * Title
     */
    title: string;
    /**
     * Tenant Prize Template ID
     */
    tenant_prize_template_id?: number;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Payload
     */
    payload?: string;
    /**
     * Unique Prize Identifier
     */
    unique_prize_identifier?: string;
    /**
     * Is Pooling Prize
     */
    is_pooling_prize?: boolean;
    /**
     * Is Available
     */
    is_available?: boolean;
    /**
     * Created At
     */
    created_at?: Date;
    /**
     * Updated At
     */
    updated_at?: Date;
}

