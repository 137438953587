/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataSportHttpResponse } from './appHttpResponsesSportsDataSportHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * MarketHttpResponse
 */
export interface AppHttpResponsesSportsDataMarketHttpResponse { 
    /**
     * Market Id
     */
    id: number;
    /**
     * Market Name
     */
    name: string;
    /**
     * Total Outcomes
     */
    total_outcomes: number;
    /**
     * Total Specifiers
     */
    total_specifiers: number;
    /**
     * Has Variants
     */
    has_variants: boolean;
    /**
     * Enable Variants
     */
    enable_variants: boolean;
    /**
     * Is Simple Snap Capable
     */
    is_simple_snap_capable: boolean;
    /**
     * Betsnap Settlement
     */
    betsnap_settlement: boolean;
    /**
     * Sports
     */
    sports?: Array<AppHttpResponsesSportsDataSportHttpResponse>;
    /**
     * Market Translation
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Simple Market Translation
     */
    simple_translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

