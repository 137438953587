/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UserUpdateRequest
 */
export interface AppHttpRequestsUsersUserUpdateRequest { 
    /**
     * Username
     */
    username?: string;
    /**
     * E-Mail
     */
    email?: string;
    /**
     * First name
     */
    first_name?: string;
    /**
     * Last name
     */
    last_name?: string;
    /**
     * Gender (1...male, 2...female)
     */
    gender?: number;
    /**
     * Date of Birth YYYY-mm-dd
     */
    dob?: string;
    /**
     * Phone Code
     */
    phone_code?: string;
    /**
     * Phone No
     */
    phone_no?: string;
    /**
     * ZIP Code
     */
    zip_code?: string;
    /**
     * Country
     */
    country?: string;
    /**
     * City
     */
    city?: string;
    /**
     * State ID
     */
    state_id?: number;
}

