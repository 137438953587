import {
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {
    AdService,
    ArenaService,
    AuthenticationService,
    BroadcastingService,
    DebugService,
    ErrorService,
    FriendsService, GameFilter,
    GoogleAnalyticsService,
    HintService,
    MyModalService,
    MyNotificationsService,
    MyTranslateService, SportFilter,
    TenantService,
    WindowRef
} from '../../../../shared';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';
import {AArenaComponent} from '../a-arena-component';
import {Subscription} from 'rxjs';
import {
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameHttpListResponse,
    AppHttpResponsesNotificationsUserNotificationHttpResponse as UserNotificationHttpResponse,
    AppHttpResponsesTenantsTenantSportHttpResponse as TenantSportHttpResponse,
    AppHttpResponsesTenantsTenantSportListHttpResponse as TenantSportListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../api';
import {ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {filter, take, takeWhile} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ConnectionEvents} from 'ngx-laravel-echo-jn';

@Component({
    selector: 'betsnaps-arena-default',
    templateUrl: './arena-default.component.html',
    styles: []
})
export class ArenaDefaultComponent extends AArenaComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentClass = 'standardview';

    protected historyGameListSubscription: Subscription;
    public historyGameList: GameHttpListResponse;
    public processHistoryList = false;
    public processHistoryLoadMore = false;

    protected invitedGamesModal = null;
    public processInvitedList = false;

    protected highlightListCheckSubscription: Subscription;

    public arenaMenu = [
        {
            'key': 'all',
            'translatekey': 'ARENA.MENU.allsnaps',
            'active': true
        }
    ];

    public sportFilterOpen = false;
    public sportFilters: SportFilter[] = [];

    public snapOptionsFilterOpen = false;
    public snapOptionsFilter = [
        {
            'id': 1,
            'translatekey': 'GENERAL.LABELS.h2h',
            'active': false,
            'analyticskey': 'h2h'
        }, {
            'id': 2,
            'translatekey': 'GENERAL.LABELS.tournament',
            'active': false,
            'analyticskey': 'tournament'
        }
    ];

    protected arenaHistoryFilters: GameFilter = {
        'per_page': 5,
        'current_page': 1,
        'game_state': '7,99',
        'sport_id': undefined,
        'is_public': undefined,
        'competition_type': undefined,
        'is_highlight': undefined,
        'joined_user_id': undefined,
        'only_with_friends': undefined,
        'exclude_fully_booked': undefined,
        'include_all_joined_snaps': undefined,
        'exclude_closed_door': undefined,
        'check_for_fitting_tags': undefined,
        'sorting': 'desc'
    };

    @ViewChild('invitedGamesModalTemplate') public invitedGamesModalTemplate: ModalTemplate<null, string, string>;
    @ViewChild('filterModalTemplate') public filterModalTemplate: ModalTemplate<null, string, string>;

    @ViewChild('loadMoreHistoryButton') public loadMoreHistoryButton: ElementRef;

    constructor(protected authenticationService: AuthenticationService,
                protected broadcastingService: BroadcastingService,
                public tenantService: TenantService,
                protected arenaService: ArenaService,
                protected friendsService: FriendsService,
                protected windowRef: WindowRef,
                protected debugService: DebugService,
                protected renderer: Renderer2,
                protected adService: AdService,
                protected hintService: HintService,
                protected notificationsService: MyNotificationsService,
                public myModalService: MyModalService,
                public translations: MyTranslateService,
                protected router: Router,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService,
                protected pageVisibilityService: AngularPageVisibilityService) {
        super(
            authenticationService,
            broadcastingService,
            tenantService,
            friendsService,
            arenaService,
            windowRef,
            debugService,
            renderer,
            adService
        );

        this.tenantService.getTenantSports(this.tenantService.tenantData.id)
            .pipe(take(1))
            .subscribe((tenantSportList: TenantSportListHttpResponse) => {
                if (tenantSportList) {
                    // if more than one sport exists
                    if (tenantSportList.results.length > 1) {
                        tenantSportList.results.forEach((tenantSport: TenantSportHttpResponse) => {
                            this.sportFilters.push(
                                {
                                    'id': tenantSport.sport.id,
                                    'sport': tenantSport.sport,
                                    'active': false
                                }
                            );
                        });
                    }
                }
            });

        if (this.broadcastingService.echoService) {
            this.broadcastingService.echoService.rawConnectionState.subscribe(
                (connectionEvent: ConnectionEvents) => {
                    if (this.currentUser && connectionEvent.type === 'reconnect') {
                        this.debugService.writeMessageToConsoleLog('update arena content on socket reconnect');
                        this.updateArenaContent();
                    }
                }
            );
        }

        // handle page suspends
        this.pageVisibilityService.$onPageVisibilityChange
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                if (visibilityState === AngularPageVisibilityStateEnum.VISIBLE && this.componentInForeground) {
                    this.updateArenaContent();
                }
            });
    }

    ngOnInit() {
        this.componentInForeground = true;
        this.router.onSameUrlNavigation = 'reload';

        // check when reused component is current navigation
        this.componentUrl = this.router.url;
        this.router.events
            .pipe(
                filter(routerEvent => (routerEvent instanceof NavigationStart || routerEvent instanceof NavigationEnd)),
                takeWhile(() => this.componentAlive)
            ).subscribe((routerEvent) => {
            if (routerEvent instanceof NavigationEnd && routerEvent.urlAfterRedirects === this.componentUrl) {
                this.componentInForeground = true;

                if (this.router.onSameUrlNavigation === 'reload') {
                    this.arenaMenuChange('all');
                } else {
                    this.updateArenaContent();
                }

                this.router.onSameUrlNavigation = 'reload';

                this.hintService.checkForHintToDisplay('arena');
            } else if (routerEvent instanceof NavigationStart && this.componentInForeground && routerEvent.url !== this.componentUrl) {
                this.componentInForeground = false;
                this.router.onSameUrlNavigation = 'ignore';
                this.stopRunningRequests();
            }
        });

        // if game invitation notification received
        this.notificationsService.addedUserNotification$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userNotificationHttpResponse: UserNotificationHttpResponse) => {
                    if (this.currentUser && userNotificationHttpResponse &&
                        userNotificationHttpResponse.notification && userNotificationHttpResponse.notification.code === 'game_invitation_received') {
                        this.updateInvitedGameList();
                    }
                }
            );

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (userData && !this.currentUser) {
                        this.currentUser = userData;

                        this.arenaHistoryFilters.joined_user_id = this.currentUser.id;

                        if (!this.arenaMenu.find(item => item.key === 'mysnaps')) {
                            this.arenaMenu.push(
                                {
                                    'key': 'mysnaps',
                                    'translatekey': 'ARENA.MENU.mysnaps',
                                    'active': false
                                }
                            );
                        }
                        if (!this.snapOptionsFilter.find(item => item.id === 3)) {
                            this.snapOptionsFilter.push({
                                'id': 3,
                                'translatekey': 'GENERAL.LABELS.friendsonly',
                                'active': false,
                                'analyticskey': 'friends only'
                            });
                        }

                        this.hintService.checkForHintToDisplay('arena');

                        this.updateArenaContent(true);
                    }
                });

        this.updateArenaContent(true);
    }

    ngOnDestroy() {
        this.gameList = null;
        this.historyGameList = null;

        if (this.invitedGameListSubscription) {
            this.invitedGameListSubscription.unsubscribe();
        }
        this.invitedGameListSubscription = null;
        this.invitedGameList = null;

        this.stopRunningRequests();

        super.onDestroy();
    }

    protected updateGameList() {
        this.updatedDataAvailable = false;

        this.stopRunningRequests(true);

        this.gameListSubscription = this.arenaService.getGameList(this.arenaFilters)
            .pipe(take(1))
            .subscribe({
                next: (response: GameHttpListResponse) => {

                    if (!this.gameList) {
                        this.adPositions = this.adService.defineAdPosition('content', response.total);
                    }

                    this.gameList = response;
                    if (this.activeTab !== 'mysnaps') {
                        this.checkForEmptyGameList();
                    } else {
                        this.processList = false;
                    }

                    this.arenaFilters.per_page = 10;
                    if (this.gameList.results.length > 10) {
                        this.arenaFilters.current_page = Math.ceil(this.gameList.results.length / 10);
                    }

                    this.loadHistoryList();
                },
                error: (err: HttpErrorResponse) => {
                    this.processList = false;
                    this.errorService.handleHttpErrorResponse(err);
                },
                complete: () => {
                    this.gameListSubscription.unsubscribe();
                    this.gameListSubscription = null;
                }
            });
    }

    loadMore(): void {
        if (this.gameList.results.length < this.gameList.total && !this.gameListSubscription) {
            this.processLoadMore = true;
            this.arenaFilters.current_page = this.arenaFilters.current_page + 1;
            this.gameListSubscription = this.arenaService.getGameList(this.arenaFilters)
                .pipe(take(1))
                .subscribe({
                    next: (response: GameHttpListResponse) => {
                        this.gameList.total = response.total;
                        this.gameList.last_page = response.last_page;
                        this.gameList.results.push(...response.results);
                        this.processLoadMore = false;

                        this.loadHistoryList();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processLoadMore = false;
                        this.errorService.handleHttpErrorResponse(err);
                    },
                    complete: () => {
                        this.gameListSubscription.unsubscribe();
                        this.gameListSubscription = null;
                    }
                });
        }
    }

    protected loadHistoryList() {
        if (this.activeTab === 'mysnaps' && this.gameList.results.length === this.gameList.total) {
            if (!this.historyGameList) {
                this.processHistoryList = true;
            }
            this.updateHistoryGameList();
        }
    }

    protected updateHistoryGameList() {
        this.historyGameListSubscription = this.arenaService.getGameList(this.arenaHistoryFilters)
            .pipe(take(1))
            .subscribe({
                next: (response: GameHttpListResponse) => {
                    this.historyGameList = response;
                    if (this.activeTab === 'mysnaps' && this.historyGameList.total < 1) {
                        this.checkForEmptyGameList('empty-my-snaps');
                    }

                    this.processHistoryList = false;

                    this.arenaHistoryFilters.per_page = 10;
                    if (this.historyGameList.results.length > 10) {
                        this.arenaHistoryFilters.current_page = Math.ceil(this.historyGameList.results.length / 10);
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.processHistoryList = false;
                    this.errorService.handleHttpErrorResponse(err);
                },
                complete: () => {
                    this.historyGameListSubscription.unsubscribe();
                    this.historyGameListSubscription = null;
                }
            });
    }

    loadMoreHistory(): void {
        if (this.historyGameList.results.length < this.historyGameList.total && !this.gameListSubscription && !this.historyGameListSubscription) {
            this.processHistoryLoadMore = true;
            this.arenaHistoryFilters.current_page = this.arenaHistoryFilters.current_page + 1;
            this.historyGameListSubscription = this.arenaService.getGameList(this.arenaHistoryFilters)
                .pipe(take(1))
                .subscribe({
                    next: (response: GameHttpListResponse) => {
                        this.historyGameList.total = response.total;
                        this.historyGameList.last_page = response.last_page;
                        this.historyGameList.results.push(...response.results);
                        this.processHistoryLoadMore = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processHistoryLoadMore = false;
                        this.errorService.handleHttpErrorResponse(err);
                    },
                    complete: () => {
                        this.historyGameListSubscription.unsubscribe();
                        this.historyGameListSubscription = null;
                    }
                });
        }
    }

    openInvitedGamesModal() {
        if (this.currentUser) {
            const invitedGamesModalConfig = new TemplateModalConfig<null, string, string>(this.invitedGamesModalTemplate);
            invitedGamesModalConfig.size = 'tiny';
            invitedGamesModalConfig.mustScroll = true;
            this.invitedGamesModal = this.myModalService.openModal(invitedGamesModalConfig);
            this.googleAnalyticsService.trackEvent('arena', 'show', 'invited snaps');
        }
    }

    protected updateInvitedGameList() {
        if (this.currentUser) {
            this.processInvitedList = true;
            this.invitedGameListSubscription = this.arenaService.getInvitedGameList(this.currentUser.id)
                .pipe(take(1))
                .subscribe({
                    next: (response: GameHttpListResponse) => {
                        this.invitedGameList = response;
                        this.processInvitedList = false;
                        if (this.invitedGamesModal && response.results.length < 1) {
                            this.invitedGamesModal.approve();
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processInvitedList = false;
                        this.errorService.handleHttpErrorResponse(err);
                    },
                    complete: () => {
                        this.invitedGameListSubscription.unsubscribe();
                        this.invitedGameListSubscription = null;
                    }
                });
        }
    }

    sportFilterUpdate(selectedOption = null) {
        this.stopRunningRequests();

        let sportFilterValue = '';
        for (const s of this.sportFilters) {
            if (s.active) {
                sportFilterValue += s.id + ',';
            }
        }
        if (sportFilterValue.length > 0) {
            sportFilterValue = sportFilterValue.slice(0, -1);
            this.arenaFilters.sport_id = sportFilterValue;
            this.arenaHistoryFilters.sport_id = sportFilterValue;
        } else {
            this.arenaFilters.sport_id = undefined;
            this.arenaHistoryFilters.sport_id = undefined;
        }

        this.arenaFilters.current_page = this.arenaHistoryFilters.current_page = 1;
        this.arenaFilters.per_page = this.arenaHistoryFilters.per_page = 10;

        this.processList = true;
        this.gameList = null;
        this.historyGameList = null;
        this.updateGameList();

        if (selectedOption && selectedOption.active) {
            this.googleAnalyticsService.trackEvent('arena', 'filter', 'sports - ' + selectedOption.sport.sport_desc.toLowerCase());
        }
    }

    activeSportFilter() {
        return this.sportFilters.filter(f => f.active === true);
    }

    snapOptionsFilterUpdate(selectedOption = null) {
        this.stopRunningRequests();

        let onlyWithFriends = false;
        let competitionFilterValue = '';
        for (const c of this.snapOptionsFilter) {
            if (c.active) {
                if (c.id === 3) {
                    onlyWithFriends = true;
                } else if (c.id === 1) {
                    competitionFilterValue += c.id + ',3,';
                } else {
                    competitionFilterValue += c.id + ',';
                }
            }
        }
        if (competitionFilterValue.length > 0) {
            competitionFilterValue = competitionFilterValue.slice(0, -1);
            this.arenaFilters.competition_type = competitionFilterValue;
            this.arenaHistoryFilters.competition_type = competitionFilterValue;
        } else {
            this.arenaFilters.competition_type = undefined;
            this.arenaHistoryFilters.competition_type = undefined;
        }

        this.arenaFilters.only_with_friends = onlyWithFriends;
        this.arenaHistoryFilters.only_with_friends = onlyWithFriends;

        this.arenaFilters.current_page = this.arenaHistoryFilters.current_page = 1;
        this.arenaFilters.per_page = this.arenaHistoryFilters.per_page = 10;

        this.processList = true;
        this.gameList = null;
        this.updateGameList();

        if (selectedOption && selectedOption.active) {
            this.googleAnalyticsService.trackEvent('arena', 'filter', 'snapoptions - ' + selectedOption.analyticskey.toLowerCase());
        }
    }

    activeSnapOptions() {
        return this.snapOptionsFilter.filter(f => f.active === true);
    }

    removeActiveFilters() {
        this.stopRunningRequests();

        if (this.sportFilters.length > 0) {
            this.sportFilters.map(f => f.active = false);
            this.sportFilterUpdate();
        }
        this.snapOptionsFilter.map(f => f.active = false);
        this.snapOptionsFilterUpdate();

        this.arenaFilters.current_page = this.arenaHistoryFilters.current_page = 1;
        this.arenaFilters.per_page = this.arenaHistoryFilters.per_page = 10;

        this.processList = true;
        this.gameList = null;
        this.updateGameList();

        this.googleAnalyticsService.trackEvent('arena', 'click', 'clear all active filter');
    }

    arenaMenuChange(activatekey: string, event?: Event) {
        this.showEmptyArenaAd = false;

        if (event) {
            event.preventDefault();
        }

        this.stopRunningRequests(true);

        this.activeTab = activatekey;

        for (const menuitem of this.arenaMenu) {
            menuitem.active = (menuitem.key === activatekey);
        }

        if (activatekey === 'mysnaps') {

            this.arenaFilters.game_state = '1,2,3,4,5,6';
            this.arenaFilters.is_highlight = undefined;
            this.arenaFilters.is_public = undefined;
            this.arenaFilters.joined_user_id = this.currentUser.id;
            this.arenaFilters.exclude_fully_booked = false;
            this.arenaFilters.include_all_joined_snaps = false;
            this.arenaFilters.exclude_closed_door = false;
            this.arenaFilters.check_for_fitting_tags = false;

            this.updateInvitedGameList();
        } else {

            this.arenaFilters.game_state = '1,2';
            this.arenaFilters.is_highlight = undefined;

            if (activatekey === 'highlights') {
                this.arenaFilters.is_highlight = true;
            }

            this.arenaFilters.is_public = true;
            this.arenaFilters.joined_user_id = undefined;
            this.arenaFilters.exclude_fully_booked = true;
            this.arenaFilters.include_all_joined_snaps = true;
            this.arenaFilters.exclude_closed_door = true;
            this.arenaFilters.check_for_fitting_tags = true;
        }

        this.arenaFilters.current_page = this.arenaHistoryFilters.current_page = 1;
        this.arenaFilters.per_page = this.arenaHistoryFilters.per_page = 10;

        this.processList = true;
        this.gameList = null;
        this.historyGameList = null;
        this.updateGameList();

        this.googleAnalyticsService.trackEvent('arena', 'filter', 'games - ' + activatekey);
    }

    protected stopRunningRequests(allowHighlightListCheck: boolean = false) {
        if (this.gameListSubscription) {
            this.gameListSubscription.unsubscribe();
        }

        if (!allowHighlightListCheck && this.highlightListCheckSubscription) {
            this.highlightListCheckSubscription.unsubscribe();
        }

        if (this.historyGameListSubscription) {
            this.historyGameListSubscription.unsubscribe();
        }

        this.processHistoryList = false;
        this.processLoadMore = false;
        this.processHistoryLoadMore = false;
    }

    openFilterModal() {
        const filterModalConfig = new TemplateModalConfig<null, string, string>(this.filterModalTemplate);
        filterModalConfig.size = 'tiny';
        filterModalConfig.mustScroll = true;
        this.myModalService.openModal(filterModalConfig);

        this.googleAnalyticsService.trackEvent('arena', 'click', 'open filter modal');
    }

    reloadData() {
        this.stopRunningRequests();

        this.arenaFilters.current_page = this.arenaHistoryFilters.current_page = 1;
        this.arenaFilters.per_page = this.arenaHistoryFilters.per_page = 10;

        this.processList = true;
        this.gameList = null;
        this.updateGameList();
        this.updatedDataAvailable = false;

        this.googleAnalyticsService.trackEvent('arena', 'click', 'reload gamelist');
    }

    // updates the content data
    protected updateArenaContent(selectHighlightsIfAvailable: boolean = false) {
        this.updatedDataAvailable = false;
        if (!this.currentUser) {
            // remove mysnaps menuitem if no user loggedin
            this.arenaMenu = this.arenaMenu.filter(item => item.key !== 'mysnaps');
            this.snapOptionsFilter = this.snapOptionsFilter.filter(item => item.id !== 3);

            if (this.gameList) {
                this.arenaFilters.current_page = this.arenaHistoryFilters.current_page = 1;
                if (this.gameList && this.gameList.results.length > 10) {
                    this.arenaFilters.per_page = this.gameList.results.length;
                }
                if (this.historyGameList && this.historyGameList.results.length > 10) {
                    this.arenaHistoryFilters.per_page = this.historyGameList.results.length;
                }
            }

            this.updateGameList();

        } else {
            if (this.highlightListCheckSubscription) {
                this.highlightListCheckSubscription.unsubscribe();
            }

            this.highlightListCheckSubscription = this.arenaService.checkIfHighlightGamesExist()
                .pipe(take(1))
                .subscribe({
                    next: (highlightGamesExist: boolean) => {

                        // add is highlight item
                        if (highlightGamesExist) {
                            const highlightMenuItem = this.arenaMenu.find(item => item.key === 'highlights');
                            if (!highlightMenuItem) {
                                this.arenaMenu.unshift(
                                    {
                                        'key': 'highlights',
                                        'translatekey': 'ARENA.MENU.highlights',
                                        'active': false
                                    }
                                );
                            }
                            if (selectHighlightsIfAvailable) {
                                this.arenaFilters.is_highlight = true;
                                this.activeTab = 'highlights';
                                for (const menuitem of this.arenaMenu) {
                                    menuitem.active = (menuitem.key === 'highlights');
                                }
                            }
                        } else {
                            // if no highlights anymore available remove item from menu and load all tabs
                            this.arenaMenu = this.arenaMenu.filter(item => item.key !== 'highlights');
                            if (this.activeTab === 'highlights') {
                                this.arenaFilters.is_highlight = false;
                                this.activeTab = 'all';
                                for (const menuitem of this.arenaMenu) {
                                    menuitem.active = (menuitem.key === 'all');
                                }
                            }
                        }

                        if (this.gameList) {
                            this.arenaFilters.current_page = this.arenaHistoryFilters.current_page = 1;
                            if (this.gameList && this.gameList.results.length > 10) {
                                this.arenaFilters.per_page = this.gameList.results.length;
                            }
                            if (this.historyGameList && this.historyGameList.results.length > 10) {
                                this.arenaHistoryFilters.per_page = this.historyGameList.results.length;
                            }
                        }

                        this.updateGameList();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processList = false;
                        this.errorService.handleHttpErrorResponse(err);
                    },
                    complete: () => {
                        this.highlightListCheckSubscription.unsubscribe();
                        this.highlightListCheckSubscription = null;
                    }
                });

            if (this.invitedGameList) {
                this.updateInvitedGameList();
            }
        }

        this.updateSideBarStickyDimensions();
    }

    infiniteScroll() {
        if (typeof this.loadMoreButton !== 'undefined' && this.gameList.results.length < this.gameList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMore && !this.processList && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMore();
            }
        }
        if (typeof this.loadMoreHistoryButton !== 'undefined' && this.historyGameList.results.length < this.historyGameList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreHistoryButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processHistoryLoadMore && !this.processHistoryList && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMoreHistory();
            }
        }
    }

}
