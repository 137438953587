/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PlayerPublicHttpResponse
 */
export interface AppHttpResponsesUsersPlayerPublicHttpResponse { 
    /**
     * User Id
     */
    id: number;
    /**
     * B2B User Id
     */
    b2b_user_id?: string;
    /**
     * Username
     */
    username?: string;
    /**
     * Is Active
     */
    is_active: number;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Media ID
     */
    media_id?: number;
    /**
     * Media Name
     */
    media_name?: string;
    /**
     * Absolute Media URL
     */
    media_url?: string;
    /**
     * Absolute Media URL (Thumb Big)
     */
    media_url_big?: string;
    /**
     * Absolute Media URL (Thumb Small)
     */
    media_url_small?: string;
    /**
     * Tag Ids
     */
    tag_ids?: Array<number>;
}

