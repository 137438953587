/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponseDetail } from './betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponseDetail';


/**
 * ErrorHttpResponse Base Object
 */
export interface BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse { 
    /**
     * Error Key
     */
    error: string;
    /**
     * Error Description
     */
    message: string;
    details?: Array<BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponseDetail>;
    /**
     * Error Timestamp Rfc3339
     */
    timestamp: Date;
}

