import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'betsnaps-sidebar-tenant-content',
    templateUrl: './sidebar-tenant-content.component.html',
    styles: []
})
export class SidebarTenantContentComponent implements OnInit {

    @Input() adTypeTop: string = 'sidebar-right';
    @Input() adTypeBottom: string = 'sidebar-right-2';

    constructor() {
    }

    ngOnInit() {
    }

}
