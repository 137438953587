/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataMatchHttpResponse } from './appHttpResponsesSportsDataMatchHttpResponse';


/**
 * GameMatchHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameMatchHttpResponse { 
    /**
     * Game Match Id
     */
    game_match_id: number;
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Game Unique Id
     */
    game_unique_id: string;
    /**
     * Is Cancel
     */
    is_cancel: boolean;
    match?: AppHttpResponsesSportsDataMatchHttpResponse;
}

