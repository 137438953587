import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'matchtime'
})
export class MatchtimePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const timeArray = value.split(':');
            if (timeArray[0]) {
                if (+timeArray[1] > 0 || (+timeArray[0] < 1 && +timeArray[1] < 1)) {
                    return +timeArray[0] + 1;
                } else {
                    return +timeArray[0];
                }
            } else {
                return 0;
            }
        } else {
            return '';
        }
    }

}
