import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameListHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationListHttpResponse as GameVendorGameParticipationListHttpResponse,
    AppHttpResponsesVendorGamesTenantVendorGameListHttpResponse as TenantVendorGameListHttpResponse,
    GamesApi,
    VendorGamesApi
} from '../../api';
import {AuthenticationService} from './authentication.service';
import {TenantService} from './tenant.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ArenaService {

    private gameUpdateSubject = new BehaviorSubject<GameHttpResponse>(null);
    public gameUpdate$ = this.gameUpdateSubject.asObservable();
    private activeSimpleSnaps: Map<string, any> = new Map<string, any>();

    constructor(
        private authenticationService: AuthenticationService,
        private tenantService: TenantService,
        private gamesApi: GamesApi,
        private vendorGamesApi: VendorGamesApi) {
    }

    public registerActiveSimpleSnap(gameUniqueId: string): void {
        this.activeSimpleSnaps.set(gameUniqueId, null);
    }

    public unregisterActiveSimpleSnap(gameUniqueId: string): void {
        this.activeSimpleSnaps.delete(gameUniqueId);
    }

    public isActiveSimpleSnap(gameUniqueId: string): boolean {
        return this.activeSimpleSnaps.has(gameUniqueId);
    }

    /**
     *  Method to update a Game in the arena (arena game list)
     *
     * @param game GameHttpResponse (should be a user specific Object with all user values populated)
     */
    public updateGame(game: GameHttpResponse): void {
        this.gameUpdateSubject.next(game);
    }

    public getGameList(gameFilters): Observable<GameListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGet(
            this.tenantService.tenantData.id,
            gameFilters.per_page,
            gameFilters.current_page,
            undefined,
            gameFilters.game_state,
            gameFilters.sport_id,
            gameFilters.is_public,
            undefined,
            undefined,
            undefined,
            gameFilters.is_highlight,
            gameFilters.competition_type,
            undefined,
            gameFilters.joined_user_id,
            this.authenticationService.authToken,
            gameFilters.sorting,
            gameFilters.only_with_friends,
            gameFilters.exclude_fully_booked,
            gameFilters.include_all_joined_snaps,
            gameFilters.exclude_closed_door,
            gameFilters.check_for_fitting_tags,
            gameFilters.exclude_singleplayer
        );
    }

    public getInvitedGameList(userId): Observable<GameListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdUsersUserIdGameinvitationsGamesGet(
            this.tenantService.tenantData.id,
            userId,
            undefined,
            undefined
        );
    }

    public checkIfHighlightGamesExist(): Observable<boolean> {
        return this.gamesApi.apiTenantsTenantIdGamesGet(
            this.tenantService.tenantData.id,
            1,
            1,
            undefined,
            '1,2',
            undefined,
            true,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            undefined,
            undefined,
            this.authenticationService.authToken,
            undefined,
            undefined,
            true,
            true,
            true,
            true
        ).pipe(map(
            (gameList: GameListHttpResponse) => {
                return (gameList.total > 0);
            }
        ));
    }

    public getSinglePlayerTenantVendorGameList(): Observable<TenantVendorGameListHttpResponse> {
        return this.vendorGamesApi.apiTenantsTenantIdVendorgamesGet(
            this.tenantService.tenantData.id,
            null,
            null,
            true
        );
    }

    public getSingleGameParticipations(userId: number): Observable<GameVendorGameParticipationListHttpResponse> {
        return this.vendorGamesApi.apiTenantsTenantIdUsersUserIdSingleplayergamesvendorgameparticipationsGet(
            this.tenantService.tenantData.id,
            userId
        );
    }

}
