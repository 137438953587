import { ElementRef, HostBinding, HostListener, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import {
    AuthenticationService,
    ErrorService,
    GoogleAnalyticsService,
    MyTranslateService,
    RankingService, TenantImageTypeEnum,
    TenantService,
    WindowRef,
} from '../../../shared';
import {
    AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse as FullTenantRankingHttpResponse,
    AppHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse as TenantRankingPositionListHttpResponse,
    AppHttpResponsesBetsnapsRankingsSimpleTenantRankingHttpResponse as SimpleTenantRankingHttpResponse,
    AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse as SimpleTenantRankingListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {take, takeWhile} from 'rxjs/operators';
import {TenantRankingsRankingTypeEnum} from '../../../shared/enums';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

@Directive()
export abstract class ARankingComponent implements OnInit, OnDestroy {

    @HostBinding('class') componentClass = 'standardview';

    protected componentAlive = true;
    public currentUser: PlayerHttpResponse;

    public processTenantRankingLists: boolean = true;
    protected tenantRankingListSubscription: Subscription;
    public tenantRankingList: SimpleTenantRankingListHttpResponse;
    public tenantRankingVisible: SimpleTenantRankingHttpResponse[];
    public tenantRankingsAllVisible: boolean = false;

    public showHistoryList: boolean = false;
    protected historyTenantRankingListSubscription: Subscription;
    public historyTenantRankingList: SimpleTenantRankingListHttpResponse;
    public processLoadMoreHistory: boolean = false;

    public processTenantRanking: boolean = true;
    protected currentTenantRankingSubscription: Subscription;
    public currentTenantRankingId: number;
    public currentTenantRanking: FullTenantRankingHttpResponse;

    public processTenantRankingPositions: boolean = true;
    protected tenantRankingPositionsListSubscription: Subscription;
    public tenantRankingPositionsList: TenantRankingPositionListHttpResponse;
    public processLoadMorePositions: boolean = false;

    public rankingMenu = [
        {
            'key': 'all',
            'translatekey': 'BETSNAPDETAIL.RANKING.MENU.all',
            'active': true
        }, {
            'key': 'friends',
            'translatekey': 'BETSNAPDETAIL.RANKING.MENU.friends',
            'active': false
        }
    ];

    public showOnlyFriends: boolean = false;

    public tenantRankingsRankingTypeEnum = TenantRankingsRankingTypeEnum;

    // Pagination
    protected perPageHistory: number = 10;
    protected currentPageHistory: number = 1;

    protected perPagePositions: number = 20;
    protected currentPagePositions: number = 1;

    @ViewChild('loadMoreButtonTenantRankings') public loadMoreButtonTenantRankings: ElementRef;
    @ViewChild('loadMoreButtonPositions') public loadMoreButtonPositions: ElementRef;

    protected nativeWindow: Window;

    public tenantDefaultRankingBannerImage: string = '';
    public showPrizes: boolean = true;

    protected constructor(protected authenticationService: AuthenticationService,
                          public  tenantService: TenantService,
                          protected rankingService: RankingService,
                          protected activatedRoute: ActivatedRoute,
                          protected router: Router,
                          public translations: MyTranslateService,
                          protected translateService: TranslateService,
                          protected windowRef: WindowRef,
                          protected errorService: ErrorService,
                          protected location: Location,
                          protected googleAnalyticsService: GoogleAnalyticsService) {
        this.nativeWindow = windowRef.nativeWindow;
        this.showPrizes = this.authenticationService.showPrizes();

        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_RANKING_BANNER)) {
            this.tenantDefaultRankingBannerImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_RANKING_BANNER).media_url;
        }
    }

    ngOnInit() {
        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    this.currentUser = userData;
                });

        if (!this.tenantService.tenantData.configuration.show_global_rankings) {
            this.router.navigate(['/arena']);
            return;
        }

        this.getTenantRankings();
    }

    // subscribe to url changes
    subscribeToRouteChanges(): void {
        this.activatedRoute.params
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(params => {
                let tenantRankingParameter = (params['tenant_ranking_id'] || null);

                // load ranking history
                if (tenantRankingParameter === 'history') {
                    tenantRankingParameter = 0;
                }

                if (tenantRankingParameter !== null) {
                    if (!this.currentTenantRanking ||
                        (
                            this.currentTenantRanking &&
                            this.currentTenantRanking.id !== Number(tenantRankingParameter) &&
                            ((this.showHistoryList && tenantRankingParameter !== 0) || !this.showHistoryList)
                        )
                    ) {
                        this.selectTenantRankingId(Number(tenantRankingParameter), true);
                    }
                }
            });
    }


    getButtonsForTenantRankings(setTenantRankingsAllVisible: boolean): void {

        this.tenantRankingsAllVisible = setTenantRankingsAllVisible;

        // evaluate the buttons for tenant rankings
        if (this.tenantRankingsAllVisible) {
            this.tenantRankingVisible = this.tenantRankingList.results;
        } else {
            let i = 0;
            this.tenantRankingVisible = [];
            for (const tenantRanking of this.tenantRankingList.results) {
                if (
                    (this.tenantRankingList.total > 3 && this.historyTenantRankingList.total < 1 && i > 1) ||
                    (this.tenantRankingList.total > 2 && this.historyTenantRankingList.total > 0 && i > 1)
                ) {
                    break;
                }
                this.tenantRankingVisible.push(tenantRanking);
                i++;
            }
        }
    }

    getRankingBannerImg(tenantRanking) {
        if (tenantRanking.bannerimg) {
            return 'url("' + tenantRanking.bannerimg + '")';
        } else if (this.tenantDefaultRankingBannerImage) {
            return 'url("' + this.tenantDefaultRankingBannerImage + '")';
        }
    }

    hasRankingBannerImg(tenantRanking): boolean {
        return (tenantRanking.bannerimg || this.tenantDefaultRankingBannerImage);
    }

    ngOnDestroy() {
        if (this.historyTenantRankingListSubscription) {
            this.historyTenantRankingListSubscription.unsubscribe();
        }
        if (this.tenantRankingListSubscription) {
            this.tenantRankingListSubscription.unsubscribe();
        }
        if (this.currentTenantRankingSubscription) {
            this.currentTenantRankingSubscription.unsubscribe();
        }
        if (this.tenantRankingPositionsListSubscription) {
            this.tenantRankingPositionsListSubscription.unsubscribe();
        }

        this.componentAlive = false;
    }

    getRankingDetailsAndPositions(tenantRankingId: number): void {
        this.processTenantRanking = true;
        this.perPagePositions = 20;
        this.currentPagePositions = 1;

        if (this.currentTenantRankingSubscription) {
            this.currentTenantRankingSubscription.unsubscribe();
        }

        this.currentTenantRankingSubscription = this.rankingService.getTenantRankingDetails(
            tenantRankingId
        ).pipe(take(1))
        .subscribe({
            next: (tenantRanking: FullTenantRankingHttpResponse) => {
                if (tenantRanking) {
                    this.currentTenantRanking = tenantRanking;
                    this.tenantRankingUpdated(tenantRanking);
                    this.processTenantRanking = false;
                    this.getRankingPositions();
                }
            },
            error: (err: HttpErrorResponse) => {
                this.currentTenantRankingSubscription.unsubscribe();
                this.currentTenantRankingSubscription = null;
                this.errorService.handleHttpErrorResponse(err);
            },
            complete: () => {
                this.currentTenantRankingSubscription.unsubscribe();
                this.currentTenantRankingSubscription = null;
            }
        });
    }

    getRankingPositions(): void {
        this.processTenantRankingPositions = true;

        if (this.tenantRankingPositionsListSubscription) {
            this.tenantRankingPositionsListSubscription.unsubscribe();
        }

        this.tenantRankingPositionsListSubscription = this.rankingService.getRankingPositions(
            this.currentTenantRankingId,
            this.showOnlyFriends,
            this.currentPagePositions,
            this.perPagePositions
        ).pipe(take(1))
        .subscribe({
            next: (rankingPositionList: TenantRankingPositionListHttpResponse) => {
                if (rankingPositionList) {
                    this.tenantRankingPositionsList = rankingPositionList;
                    this.processTenantRankingPositions = false;
                }
            },
            error: (err: HttpErrorResponse) => {
                this.tenantRankingPositionsListSubscription.unsubscribe();
                this.tenantRankingPositionsListSubscription = null;
                this.errorService.handleHttpErrorResponse(err);
            },
            complete: () => {
                this.tenantRankingPositionsListSubscription.unsubscribe();
                this.tenantRankingPositionsListSubscription = null;
            }
        });
    }

    loadMorePositions(): void {
        if (this.tenantRankingPositionsList.results.length < this.tenantRankingPositionsList.total) {
            this.processLoadMorePositions = true;
            this.currentPagePositions = this.currentPagePositions + 1;
            this.tenantRankingPositionsListSubscription = this.rankingService.getRankingPositions(
                this.currentTenantRankingId, this.showOnlyFriends, this.currentPagePositions, this.perPagePositions
            ).pipe(take(1))
            .subscribe({
                next: (rankingPositionList: TenantRankingPositionListHttpResponse) => {
                    if (rankingPositionList) {
                        this.tenantRankingPositionsList.total = rankingPositionList.total;
                        this.tenantRankingPositionsList.last_page = rankingPositionList.last_page;
                        this.tenantRankingPositionsList.results.push(...rankingPositionList.results);

                        this.processLoadMorePositions = false;
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.tenantRankingPositionsListSubscription.unsubscribe();
                    this.tenantRankingPositionsListSubscription = null;
                    this.errorService.handleHttpErrorResponse(err);
                },
                complete: () => {
                    this.tenantRankingPositionsListSubscription.unsubscribe();
                    this.tenantRankingPositionsListSubscription = null;
                }
            });
        }
    }

    loadMoreHistory(): void {
        if (this.historyTenantRankingList.results.length < this.historyTenantRankingList.total) {
            this.processLoadMoreHistory = true;
            this.currentPageHistory = this.currentPageHistory + 1;
            this.rankingService.getTenantRankings(
                true, true, true, this.perPageHistory, this.currentPageHistory
            ).pipe(take(1))
            .subscribe({
                next: (historySimpleTenantRankingList: SimpleTenantRankingListHttpResponse) => {
                    if (historySimpleTenantRankingList) {
                        this.historyTenantRankingList.total = historySimpleTenantRankingList.total;
                        this.historyTenantRankingList.last_page = historySimpleTenantRankingList.last_page;
                        this.historyTenantRankingList.results.push(...historySimpleTenantRankingList.results);

                        this.processLoadMoreHistory = false;
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
        }
    }

    infiniteScroll() {
        if (typeof this.loadMoreButtonPositions !== 'undefined' && this.tenantRankingPositionsList.results.length < this.tenantRankingPositionsList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButtonPositions.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMorePositions && !this.processTenantRankingPositions && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMorePositions();
            }
        }
        if (typeof this.loadMoreButtonTenantRankings !== 'undefined' && this.historyTenantRankingList.results.length < this.historyTenantRankingList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButtonTenantRankings.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMoreHistory && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMoreHistory();
            }
        }
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    rankingListeners() {
        this.infiniteScroll();
    }

    selectTenantRankingId(tenantRankingId: number, forceLoad: boolean = false, event: Event = null) {

        if (event) {
            event.preventDefault();
        }

        if (forceLoad === false &&
            this.currentTenantRanking &&
            this.currentTenantRanking.id === tenantRankingId) {
            return false;
        }

        this.currentTenantRankingId = tenantRankingId;

        this.processTenantRanking = true;
        this.tenantRankingPositionsList = null;
        this.currentTenantRanking = null;

        // reset ranking positions menu
        for (const menuitem of this.rankingMenu) {
            menuitem.active = (menuitem.key === 'all');
        }
        this.showOnlyFriends = false;

        // if tenantRankingId === 0 => show history rankings
        if (tenantRankingId === 0) {
            this.showHistoryList = true;
            this.location.replaceState('/ranking/history');
            this.googleAnalyticsService.trackEvent('globalrankings', 'click', 'globalrankings - history');
        } else {
            this.showHistoryList = false;
            this.location.replaceState('/ranking/' + tenantRankingId);
            this.getRankingDetailsAndPositions(tenantRankingId);
            this.googleAnalyticsService.trackEvent('globalrankings', 'click', 'globalrankings - period_id: ' + tenantRankingId);
        }
    }

    // get days until a date
    getDaysLeft(toDate: Date): number {
        const minutesLeft = moment(toDate).diff(moment().utc(), 'minutes');

        let daysLeft = 0;
        const minutesADay = 60 * 24;

        if (minutesLeft > 0 && minutesLeft <= minutesADay) {
            daysLeft = 1;
        } else if (minutesLeft > minutesADay) {
            daysLeft = Math.ceil(minutesLeft / (minutesADay));
        }

        return daysLeft;
    }

    checkIfHasAFromOrToDate(tenantRanking): boolean {
        return (tenantRanking.from_date || tenantRanking.to_date);
    }

    checkIfHasLastRecalculationDate(tenantRanking): boolean {
        return (tenantRanking.last_recalculation_at && tenantRanking.ranking_state === 2 && tenantRanking.ranking_type === this.tenantRankingsRankingTypeEnum.GAME_FINAL_POINTS_FLOATING);
    }

    rankingMenuChange(activatekey) {
        this.processTenantRankingPositions = true;

        for (const menuitem of this.rankingMenu) {
            menuitem.active = (menuitem.key === activatekey);
        }
        if (activatekey === 'all') {
            this.showOnlyFriends = false;
        } else if (activatekey === 'friends') {
            this.showOnlyFriends = true;
        }

        this.perPagePositions = 20;
        this.currentPagePositions = 1;
        this.tenantRankingPositionsList = null;

        this.getRankingPositions();
        this.googleAnalyticsService.trackEvent('globalrankings', 'filter', 'ranking - ' + activatekey);
    }

    getTenantRankings() {
        // get first history
        this.historyTenantRankingListSubscription = this.rankingService.getTenantRankings(
            true, true, true, this.perPageHistory, this.currentPageHistory
        ).pipe(take(1))
        .subscribe({
            next: (historySimpleTenantRankingList: SimpleTenantRankingListHttpResponse) => {
                if (historySimpleTenantRankingList) {
                    this.historyTenantRankingList = historySimpleTenantRankingList;
                    this.getAllCurrentTenantRankings();
                }
            },
            error: (err: HttpErrorResponse) => {
                this.historyTenantRankingListSubscription.unsubscribe();
                this.historyTenantRankingListSubscription = null;
                this.errorService.handleHttpErrorResponse(err);
            },
            complete: () => {
                this.historyTenantRankingListSubscription.unsubscribe();
                this.historyTenantRankingListSubscription = null;
            }
        });
    }

    tenantRankingUpdated(tenantRanking: FullTenantRankingHttpResponse) {
    }

    getAllCurrentTenantRankings() {
        // get all current tenant rankings
        this.tenantRankingListSubscription = this.rankingService.getTenantRankings()
            .pipe(take(1))
            .subscribe({
                next: (tenantRankingList: SimpleTenantRankingListHttpResponse) => {
                    if (tenantRankingList) {
                        this.tenantRankingList = tenantRankingList;

                        this.getButtonsForTenantRankings(false);

                        this.processTenantRankingLists = false;

                        this.subscribeToRouteChanges();

                        // if no detail page
                        if (!this.activatedRoute.snapshot.params['tenant_ranking_id']) {
                            if (this.tenantRankingList.total > 0) {
                                // if no ranking has been selected then select the first active ranking
                                this.selectTenantRankingId(this.tenantRankingList.results[0].id, true);
                            } else if (this.historyTenantRankingList && this.historyTenantRankingList.total > 0) {
                                this.selectTenantRankingId(0, true);
                            }
                        }
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.tenantRankingListSubscription.unsubscribe();
                    this.tenantRankingListSubscription = null;
                    this.errorService.handleHttpErrorResponse(err);
                },
                complete: () => {
                    this.tenantRankingListSubscription.unsubscribe();
                    this.tenantRankingListSubscription = null;
                }
            });
    }
}
