/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpRequestsBetsnapsGamesPrizeStructureDataList } from './appHttpRequestsBetsnapsGamesPrizeStructureDataList';
import { AppHttpRequestsBetsnapsGamesGameTenantDataList } from './appHttpRequestsBetsnapsGamesGameTenantDataList';
import { AppHttpRequestsGeneralTranslationEntryCreate } from './appHttpRequestsGeneralTranslationEntryCreate';
import { AppHttpRequestsBetsnapsGamesGameMatchSimpleMarketDataList } from './appHttpRequestsBetsnapsGamesGameMatchSimpleMarketDataList';


/**
 * GameCreateGameAdminRequest
 */
export interface AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest { 
    /**
     * Game Name
     */
    game_name: string;
    /**
     * Game Subtitle
     */
    game_subtitle?: string;
    /**
     * Competition Type
     */
    competition_type: number;
    /**
     * Tournaments Type Id
     */
    tournaments_type_id?: number;
    /**
     * Is Simple Snap
     */
    is_simple_snap: boolean;
    /**
     * BetSnap Start-Date
     */
    start_date?: Date;
    /**
     * BetSnap End-Date
     */
    end_date?: Date;
    /**
     * Maximum Size
     */
    maximum_size: number;
    /**
     * Minimum Size
     */
    minimum_size?: number;
    /**
     * Game Spots Left Limit
     */
    game_spots_left_limit?: number;
    /**
     * Currency Id
     */
    currency_id?: number;
    /**
     * Entry Fee
     */
    entry_fee: number;
    /**
     * Rake
     */
    rake?: number;
    /**
     * Rake Share
     */
    rake_share?: number;
    /**
     * Prize Pool
     */
    prize_pool?: number;
    /**
     * Prize Structures
     */
    prize_structures: Array<AppHttpRequestsBetsnapsGamesPrizeStructureDataList>;
    /**
     * Publish Date
     */
    publish_date?: Date;
    /**
     * Is Public
     */
    is_public: boolean;
    /**
     * Is Flagged
     */
    is_flagged?: boolean;
    /**
     * Is Highlight
     */
    is_highlight?: boolean;
    /**
     * Auto Recreate
     */
    auto_recreate?: boolean;
    /**
     * Recreation Deadline Date
     */
    recreate_deadline?: Date;
    /**
     * Bet Placement Option (1=always, 2=only pre-game, 3=only pre-match, 4=only live)
     */
    bet_placement_option?: number;
    /**
     * Cashout Option (1=always, 2=only pre-game, 3=only pre-match, 4=only live, 5=never)
     */
    cashout_option?: number;
    /**
     * Use Initial Bet Outcome
     */
    use_initial_bet_outcome?: boolean;
    /**
     * Show pre game start odd can change info
     */
    show_pre_game_start_odd_can_change_info?: boolean;
    /**
     * Confirm Join Process
     */
    confirm_join_process: boolean;
    /**
     * Leave Free Game
     */
    leave_free_game: boolean;
    /**
     * Allow prizes without valid participation
     */
    allow_prizes_without_valid_participation: boolean;
    /**
     * Allow prizes with zero points
     */
    allow_prizes_with_zero_points: boolean;
    /**
     * Notification no valid bets last match start (yes / no)
     */
    notification_no_valid_bets_last_match_start: boolean;
    /**
     * Notification no valid bets last match start minutes
     */
    notification_no_valid_bets_last_match_start_min: number;
    /**
     * Notification no valid bets game start (yes / no)
     */
    notification_no_valid_bets_game_start: boolean;
    /**
     * Notification no valid bets game start minutes
     */
    notification_no_valid_bets_game_start_min: number;
    /**
     * Max allowed Bets - 0 is unlimited
     */
    max_bet_count: number;
    /**
     * Sport Id
     */
    sport_id: number;
    /**
     * Is Closed Door
     */
    is_closed_door?: boolean;
    /**
     * Chat enabled
     */
    chat_enabled?: boolean;
    /**
     * Game Tenants
     */
    game_tenants: Array<AppHttpRequestsBetsnapsGamesGameTenantDataList>;
    /**
     * Match Ids
     */
    match_ids: Array<number>;
    /**
     * Vendor Game Ids
     */
    vendor_game_ids?: Array<number>;
    /**
     * Tenant Tag Ids
     */
    tenant_tag_ids?: Array<number>;
    /**
     * Game Market Ids
     */
    game_market_ids?: Array<number>;
    /**
     * Tenant Ranking Ids
     */
    tenant_ranking_ids?: Array<number>;
    /**
     * Card Background Image Media Id
     */
    bgimg_card_media_id?: number;
    /**
     * Details Header Image Media Id
     */
    bgimg_header_media_id?: number;
    /**
     * Details Background Image Media Id
     */
    bgimg_details_media_id?: number;
    /**
     * Game card tile style
     */
    game_card_tile_style?: string;
    /**
     * Non-Human-Player Fill Percentage
     */
    nhp_fill_percentage?: number;
    /**
     * Non-Human-Player max. Bets to place
     */
    nhp_max_bets?: number;
    /**
     * Game Match Simple Markets
     */
    game_match_simple_markets?: Array<AppHttpRequestsBetsnapsGamesGameMatchSimpleMarketDataList>;
    /**
     * Game Translation Entries
     */
    translations?: Array<AppHttpRequestsGeneralTranslationEntryCreate>;
    /**
     * odds / prediction based
     */
    points_engine: string;
    /**
     * Winning points for prediction based game bets
     */
    prediction_game_winning_points?: number;
    /**
     * Battle Royal Minimum Won Bets Count
     */
    battle_royal_minimum_won_bets_count?: number;
    /**
     * Initial game points
     */
    initial_game_points: number;
    /**
     * Widget game points
     */
    widget_game_points: number;
}

