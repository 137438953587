/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantWinParticipationHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsTenantWinParticipationHttpResponse { 
    /**
     * Category Name
     */
    category?: string;
    /**
     * Participants Count
     */
    participants: number;
    /**
     * Winners
     */
    winners: number;
}

