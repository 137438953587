import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdminApi } from './api/adminApi.service';
import { AuthenticationApi } from './api/authenticationApi.service';
import { CMSApi } from './api/cMSApi.service';
import { CRMApi } from './api/cRMApi.service';
import { ChatApi } from './api/chatApi.service';
import { FeedApi } from './api/feedApi.service';
import { FinancialsApi } from './api/financialsApi.service';
import { FreemiumApi } from './api/freemiumApi.service';
import { FriendsApi } from './api/friendsApi.service';
import { GameCreationsApi } from './api/gameCreationsApi.service';
import { GamesApi } from './api/gamesApi.service';
import { GeneralApi } from './api/generalApi.service';
import { GlobalRankingsApi } from './api/globalRankingsApi.service';
import { HealthChecksApi } from './api/healthChecksApi.service';
import { HintsApi } from './api/hintsApi.service';
import { LogsApi } from './api/logsApi.service';
import { MarketingApi } from './api/marketingApi.service';
import { PrizesApi } from './api/prizesApi.service';
import { ReportsApi } from './api/reportsApi.service';
import { SignupApi } from './api/signupApi.service';
import { SportsDataApi } from './api/sportsDataApi.service';
import { StatisticsApi } from './api/statisticsApi.service';
import { TenantsApi } from './api/tenantsApi.service';
import { UserMediaApi } from './api/userMediaApi.service';
import { UsersApi } from './api/usersApi.service';
import { VendorGamesApi } from './api/vendorGamesApi.service';
import { VendorGamesCWSApi } from './api/vendorGamesCWSApi.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
