import { OnDestroy, OnInit, Directive } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../api';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    GamePointsEngineEnum,
    GoogleAnalyticsService,
    HintService,
    MyTranslateService
} from '../../../../shared';
import {takeWhile} from 'rxjs/operators';

@Directive()
export abstract class ABetsnapdetailMatchesComponent implements OnInit, OnDestroy {

    public adPositions: number[] = [];

    protected componentAlive = true;

    public game: GameHttpResponse;
    public gameMatches: GameMatchHttpResponse[];

    public currentUser: PlayerHttpResponse;

    public processMatches: boolean = true;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected hintService: HintService,
                protected activatedRoute: ActivatedRoute,
                public translations: MyTranslateService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected router: Router,
                protected adService: AdService) {

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (!this.game && gameHttpResponse) {

                        if (gameHttpResponse.sport_id === 999) {
                            this.router.navigate(['/betsnapdetail/' + gameHttpResponse.game_unique_id + '/details']);
                            return;
                        }

                        if (!this.gameMatches) {
                            // load matches from api
                            this.betsnapdetailService.getGameMatches(gameHttpResponse.game_unique_id);
                        }

                        const currentUser = this.authenticationService.currentUser;
                        this.handleUserData(currentUser, gameHttpResponse);
                    }
                    this.game = gameHttpResponse;
                }
            );

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    this.handleUserData(userData, this.game);
                });

        this.betsnapdetailService.gameMatches$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameMatchListResults: GameMatchHttpResponse[]) => {
                    if (gameMatchListResults) {
                        if (!this.adPositions || (Array.isArray(this.adPositions) && this.adPositions.length === 0)) {
                            this.adPositions = this.adService.defineAdPosition('game-content', gameMatchListResults.length);
                        }
                    }
                }
            );
    }

    ngOnInit() {
        if (this.game.points_engine === GamePointsEngineEnum.PREDICTION) {
            if (this.authenticationService.currentUser && this.game.is_current_user_joined) {
                // get user bets for current user
                this.betsnapdetailService.getUserBets(
                    this.game.game_unique_id,
                    this.authenticationService.currentUser.id
                );
            }
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    protected abstract handleUserData(user: PlayerHttpResponse, game: GameHttpResponse);

    public upComingMatches(): GameMatchHttpResponse[] {
        return (this.gameMatches) ? this.gameMatches.filter(
            (gameMatch: GameMatchHttpResponse) =>
                (gameMatch.match.status === 'scheduled' || gameMatch.match.status === 'not_started' ||
                    gameMatch.match.status === 'delayed'
                ) && !gameMatch.is_cancel
        ) : null;
    }

    public liveMatches(): GameMatchHttpResponse[] {
        return (this.gameMatches) ? this.gameMatches.filter(
            (gameMatch: GameMatchHttpResponse) =>
                (gameMatch.match.status === 'live' || gameMatch.match.status === 'interrupted' || gameMatch.match.status === 'suspended')
                && !gameMatch.is_cancel
        ) : null;
    }

    public completedMatches(): GameMatchHttpResponse[] {
        return (this.gameMatches) ? this.gameMatches.filter(
            (gameMatch: GameMatchHttpResponse) =>
                (gameMatch.match.status === 'closed' || gameMatch.match.status === 'ended' ||
                    gameMatch.match.status === 'cancelled' || gameMatch.match.status === 'postponed' ||
                    gameMatch.match.status === 'abandoned' || gameMatch.is_cancel)
        ) : null;
    }

    matchesTrackBy(index: number, gameMatch: GameMatchHttpResponse): number {
        return gameMatch.match_id;
    }

}
