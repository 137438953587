import { ChangeDetectorRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Directive } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as UserBetHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse
} from '../../../api';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GameCashoutOptionEnum,
    GamePointsEngineEnum,
    GoogleAnalyticsService, MyNotificationsService,
    TenantService
} from '../../../shared';
import {UserBetStateEnum} from '../../../shared/enums';
import {TranslateService} from '@ngx-translate/core';
import {NotificationType} from 'angular2-notifications';
import * as moment from 'moment';
import {DbTranslationsPipe} from '../../../shared/pipes';
import {take, takeWhile} from 'rxjs/operators';
import {FeedService} from '../../../shared/services/feed.service';

@Directive()
export abstract class AUserBetComponent implements OnInit, OnDestroy, OnChanges {

    protected componentAlive = true;

    @Input() gameUpdatedDate: Date;
    @Input() marketUpdatedDate: Date;
    @Input() userbetUpdatedDate: Date;

    @Input() matchLiveodds: string;
    @Input() matchStatus: string;
    @Input() marketStatus: number;
    @Input() outcomeStatus: number;
    @Input() userbetStatus: number;

    @Input() userBet: UserBetHttpResponse;
    @Input() game: GameHttpResponse;
    @Input() match: MatchHttpResponse;

    @Input() oddDisplayFormat: string = 'value';

    public settledOutcomes: MatchMarketOutcomeHttpResponse[];
    public settledOutcomesTxt: string;

    public cashoutPoints: number = 0;
    public processCashOut: boolean = false;
    public preGameStartOddsCanChange: boolean = false;

    public userBetStateEnum = UserBetStateEnum;

    public cashOutProgress1: number = 100;
    public cashOutProgress2: number = 0;

    public gamePointsEngineEnum = GamePointsEngineEnum;

    constructor(public authenticationService: AuthenticationService,
                public tenantService: TenantService,
                protected betsnapdetailService: BetsnapdetailService,
                protected myNotificationsService: MyNotificationsService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected translateService: TranslateService,
                protected errorService: ErrorService,
                protected cdr: ChangeDetectorRef,
                protected feedService: FeedService) {
        this.feedService.feedPrematchProducerUp$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((isProductUp: boolean) => {
                this.cdr.markForCheck();
            });

        this.feedService.feedLiveProducerUp$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((isProductUp: boolean) => {
                this.cdr.markForCheck();
            });
    }

    ngOnInit() {
        this.preGameStartOddsCanChange = this.betsnapdetailService.preGameStartOddsCanChange;
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.oddDisplayFormat && !changes.oddDisplayFormat.currentValue) {
            this.oddDisplayFormat = this.tenantService.tenantData.internationalization.odd_display_format;
        }

        this.preGameStartOddsCanChange = this.betsnapdetailService.preGameStartOddsCanChange;

        if (this.game.use_initial_bet_outcome) {
            this.userBet.bet_outcome = this.userBet.initial_bet_outcome;
            this.userBet.bet_outcome_unlimited = this.userBet.initial_bet_outcome_unlimited;
            this.userBet.bet_outcome_probability = this.userBet.initial_bet_outcome_probability;
            this.userBet.bet_outcome_decimal = this.userBet.initial_bet_outcome_decimal;
            this.userBet.bet_outcome_points = this.userBet.initial_bet_outcome_points;
            this.userBet.bet_outcome_fractional = this.userBet.initial_bet_outcome_fractional;
            this.userBet.bet_outcome_moneyline = this.userBet.initial_bet_outcome_moneyline;
        } else if (!this.game.use_initial_bet_outcome && this.game.game_state < 3 && this.userBet.outcome.outcome_probability_value !== 0) {
            this.userBet.bet_outcome = this.userBet.outcome.odd_decimal;
            this.userBet.bet_outcome_unlimited = this.userBet.outcome.odd_decimal_unlimited;
            this.userBet.bet_outcome_probability = this.userBet.outcome.outcome_probability_value;
            this.userBet.bet_outcome_decimal = this.userBet.outcome.odd_decimal;
            this.userBet.bet_outcome_points = this.userBet.outcome.odd_points;
            this.userBet.bet_outcome_fractional = this.userBet.outcome.odd_fractional;
            this.userBet.bet_outcome_moneyline = this.userBet.outcome.odd_moneyline;
        }

        if (this.game.game_state < 3) {
            this.userBet.current_outcome = this.userBet.bet_outcome;
            this.userBet.current_outcome_unlimited = this.userBet.bet_outcome_unlimited;
            this.userBet.current_outcome_probability = this.userBet.bet_outcome_probability;
        } else {
            this.userBet.current_outcome = this.userBet.outcome.odd_decimal;
            this.userBet.current_outcome_unlimited = this.userBet.outcome.odd_decimal_unlimited;
            this.userBet.current_outcome_probability = this.userBet.outcome.outcome_probability_value;
        }

        // get market settlement outcomes
        if (this.userBet.result_outcomes) {
            this.settledOutcomes = this.userBet.result_outcomes;
            this.settledOutcomesTxt = this.getSettledOutcomesText();
        } else {
            this.settledOutcomes = null;
            this.settledOutcomesTxt = null;
        }

        this.cashoutPoints = 0;
        if (this.userBet.current_outcome !== 0) {
            let oddLimit = 0;
            if (this.game.outcome_odd_limit) {
                oddLimit = this.game.outcome_odd_limit;
            }

            let bet_outcome_reference = this.userBet.bet_outcome;
            let current_outcome_reference = this.userBet.current_outcome;

            if (oddLimit !== 0 && oddLimit < this.userBet.current_outcome_unlimited && this.userBet.current_outcome_unlimited > this.userBet.bet_outcome_unlimited) {
                bet_outcome_reference = this.userBet.bet_outcome_unlimited;
                current_outcome_reference = this.userBet.current_outcome_unlimited;
            }

            this.cashoutPoints = Math.round(((bet_outcome_reference * this.userBet.point) / current_outcome_reference) * 100) / 100;
        }

        this.calculateCashoutProgress();
    }

    isCashoutAllowed(): boolean {
        return !(
            this.game.cashout_option === GameCashoutOptionEnum.NEVER ||
            (
                this.game.cashout_option === GameCashoutOptionEnum.ONLY_PRE_GAME && this.game.game_state >= 3
            ) ||
            (
                (this.match.status === 'live' || (moment(this.match.season_scheduled_date).isBefore(moment().utc()) && !this.game.is_simulation)) &&
                (this.game.cashout_option === GameCashoutOptionEnum.ONLY_PRE_MATCH || this.match.liveodds !== 'booked')
            ) ||
            (
                (this.match.status !== 'live' || (moment(this.match.season_scheduled_date).isAfter(moment().utc()) && !this.game.is_simulation)) &&
                this.game.cashout_option === GameCashoutOptionEnum.ONLY_LIVE
            ) ||
            (
                (this.match.status !== 'live' || (moment(this.match.season_scheduled_date).isAfter(moment().utc()) && !this.game.is_simulation)) &&
                (!this.feedService.feedPrematchProducerUp || !this.feedService.feedLiveProducerUp)
            ) ||
            (
                (this.match.status === 'live' || (moment(this.match.season_scheduled_date).isBefore(moment().utc()) && !this.game.is_simulation)) &&
                !this.feedService.feedLiveProducerUp
            )
        );
    }

    isCashoutAvailable(): boolean {
        let cashoutStatus = true;

        if (this.game.game_state > 3 ||
            this.match.status === 'closed' || this.match.status === 'ended' ||
            this.match.status === 'cancelled' || this.match.status === 'postponed' ||
            this.userBet.status !== UserBetStateEnum.ACTIVE ||
            this.userBet.market.market_status === -1 ||
            this.userBet.market.market_status === -3 ||
            this.userBet.market.market_status === -4 ||
            (this.userBet.market.market_status === 0 && this.userBet.market.cashout_status !== 1) ||
            (this.userBet.outcome.outcome_status === 0 && this.userBet.market.cashout_status !== 1) ||
            this.userBet.outcome.outcome_status === -1 ||
            this.cashoutPoints === 0 ||
            this.cashoutOddLimitViolation() ||
            !this.isCashoutAllowed()
        ) {
            cashoutStatus = false;
        }

        return cashoutStatus;
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    cashOutBet() {
        if (!this.processCashOut) {
            if (this.isCashoutAvailable()) {
                this.processCashOut = true;
                this.betsnapdetailService.cashOutBet(this.userBet, this.cashoutPoints)
                    .pipe(take(1))
                    .subscribe({
                        next: (userBet: UserBetHttpResponse) => {
                            this.googleAnalyticsService.trackEvent(
                                'matchcard - bet',
                                'cashout',
                                this.userBet.outcome.market_id + '-' + this.userBet.outcome.outcome_id + '-' + this.userBet.outcome.specifier_val
                            );

                            if (userBet.status === this.userBetStateEnum.CASHOUT_PENDING) {
                                this.processCashOut = false;
                            }

                        },
                        error: (err: HttpErrorResponse) => {
                            this.processCashOut = false;

                            if (err.error && err.error.error && err.error.error === 'odd_bet_value_not_allowed_difference') {
                                this.betsnapdetailService.getUserBets(this.game.game_unique_id, this.userBet.user_id, true);
                            }

                            this.cdr.markForCheck();
                            this.errorService.handleHttpErrorResponse(err);
                        }
                    });
            } else {
                if (!this.isCashoutAllowed() && this.game.cashout_option === GameCashoutOptionEnum.NEVER) {
                    this.translateService.get(['GENERAL.BETS.ERRORS.cashout_not_possible_title', 'ERRORS.GENERAL.cashout_not_allowed'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.cashout_not_possible_title'], translation['ERRORS.GENERAL.cashout_not_allowed'], NotificationType.Error);
                        });
                } else if (!this.isCashoutAllowed() && this.match.liveodds !== 'booked') {
                    this.translateService.get(['GENERAL.BETS.ERRORS.cashout_not_possible_title', 'ERRORS.GENERAL.live_odds_not_available'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.cashout_not_possible_title'], translation['ERRORS.GENERAL.live_odds_not_available'], NotificationType.Error);
                        });
                } else if (!this.isCashoutAllowed() && this.game.cashout_option === GameCashoutOptionEnum.ONLY_PRE_GAME) {
                    this.translateService.get(['GENERAL.BETS.ERRORS.cashout_not_possible_title', 'ERRORS.GENERAL.only_pre_game_cashout_allowed'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.cashout_not_possible_title'], translation['ERRORS.GENERAL.only_pre_game_cashout_allowed'], NotificationType.Error);
                        });
                } else if (!this.isCashoutAllowed() && this.game.cashout_option === GameCashoutOptionEnum.ONLY_PRE_MATCH) {
                    this.translateService.get(['GENERAL.BETS.ERRORS.cashout_not_possible_title', 'ERRORS.GENERAL.only_pre_match_cashout_allowed'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.cashout_not_possible_title'], translation['ERRORS.GENERAL.only_pre_match_cashout_allowed'], NotificationType.Error);
                        });
                } else if (!this.isCashoutAllowed() && this.game.cashout_option === GameCashoutOptionEnum.ONLY_LIVE) {
                    this.translateService.get(['GENERAL.BETS.ERRORS.cashout_not_possible_title', 'ERRORS.GENERAL.only_live_cashout_allowed'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.cashout_not_possible_title'], translation['ERRORS.GENERAL.only_live_cashout_allowed'], NotificationType.Error);
                        });
                } else if (this.isCashoutAllowed() && this.cashoutOddLimitViolation()) {
                    this.translateService.get(['GENERAL.BETS.ERRORS.cashout_not_possible_title', 'ERRORS.GENERAL.cashout_odd_limit_error'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.cashout_not_possible_title'], translation['ERRORS.GENERAL.cashout_odd_limit_error'], NotificationType.Error);
                        });
                } else {
                    this.translateService.get(['GENERAL.BETS.ERRORS.cashout_currently_not_possible_title', 'GENERAL.BETS.ERRORS.cashout_not_possible'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.cashout_currently_not_possible_title'], translation['GENERAL.BETS.ERRORS.cashout_not_possible'], NotificationType.Error);
                        });
                }
            }
        }
    }

    getSettledOutcomesText(): string {
        let settledOutcomeString = '';

        if (this.settledOutcomes) {
            this.settledOutcomes.forEach(function (settledOutcome, index) {
                const label = new DbTranslationsPipe(this.authenticationService).transform(settledOutcome.outcome_name, 'name', settledOutcome.translations);
                if (index === 0) {
                    settledOutcomeString += label;
                } else {
                    settledOutcomeString += ', ' + label;
                }
            }, this);
        }

        return settledOutcomeString;
    }

    calculateUserBetSaldo(): number {

        let payOut = this.userBet.total_point;

        if (this.userBet.status === UserBetStateEnum.ACTIVE || this.userBet.status === UserBetStateEnum.CASHOUT_PENDING) {
            payOut = this.cashoutPoints;
        }

        if (this.userBet.status === UserBetStateEnum.PENDING) {
            payOut = this.userBet.point;
        }

        return payOut - this.userBet.point;
    }

    private calculateCashoutProgress() {
        const userBetSaldo = this.calculateUserBetSaldo();
        const maxWin = (this.userBet.total_point * this.userBet.bet_outcome);

        if (userBetSaldo > 0) {
            this.cashOutProgress1 = 100;
            this.cashOutProgress2 = Math.round((userBetSaldo * 100) / (maxWin - this.userBet.point));
        } else if (userBetSaldo < 0) {
            this.cashOutProgress1 = Math.round((this.cashoutPoints * 100) / this.userBet.point);
            this.cashOutProgress2 = 0;
        } else {
            this.cashOutProgress1 = 100;
            this.cashOutProgress2 = 0;
        }
    }

    removeFailedBet() {
        this.betsnapdetailService.removeFailedBet(this.userBet);
    }

    cashoutOddLimitViolation(): boolean {
        return (this.game.cashout_odd_limit !== null && this.userBet.bet_outcome > this.game.cashout_odd_limit)
    }

    isBetFinished(): boolean {
        return (
            this.userBet.status === this.userBetStateEnum.SETTLED ||
            this.userBet.status === this.userBetStateEnum.CANCELLED ||
            this.userBet.status === this.userBetStateEnum.CASHED_OUT ||
            this.userBet.status === this.userBetStateEnum.AUTO_CASHED_OUT ||
            this.userBet.status === this.userBetStateEnum.ROLLED_BACK_BET_SETTLEMENT ||
            this.userBet.status === this.userBetStateEnum.ROLLED_BACK_BET_CANCELLATION
        );
    }

}
