<div class="ui text center aligned container">
    <div class="ui padded basic segment margin-t-10">
        <h1 class="hyphenate home-headline margin-b-15 padding-b-15">
            {{'MAINTENANCE.headline' | translate}}
        </h1>
        <p class="hyphenate home-subheadline margin-t-15 padding-t-15">
            {{'MAINTENANCE.maintext' | translate}}
        </p>

        <div class="ui one column centered grid margin-t-10">
            <div class="ten wide mobile seven wide tablet six wide computer column">
                <a (click)="reloadPage()" class="ui calltoaction2 fluid large button margin-0 padding-r-0 padding-l-0">
                    {{'MAINTENANCE.reload_page_btn' | translate | uppercase}}
                </a>
            </div>
        </div>
    </div>
</div>
