/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpResponsesSportsDataMarketListHttpResponse } from '../model/appHttpResponsesSportsDataMarketListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchListHttpResponse } from '../model/appHttpResponsesSportsDataMatchListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchMarketListHttpResponse } from '../model/appHttpResponsesSportsDataMatchMarketListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchScoreListHttpResponse } from '../model/appHttpResponsesSportsDataMatchScoreListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchTimelineEventListHttpResponse } from '../model/appHttpResponsesSportsDataMatchTimelineEventListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataSportListHttpResponse } from '../model/appHttpResponsesSportsDataSportListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataSubscribedSportEventHttpResponse } from '../model/appHttpResponsesSportsDataSubscribedSportEventHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataSubscribedSportEventListHttpResponse } from '../model/appHttpResponsesSportsDataSubscribedSportEventListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataTeamListHttpResponse } from '../model/appHttpResponsesSportsDataTeamListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataTournamentListHttpResponse } from '../model/appHttpResponsesSportsDataTournamentListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SportsDataApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get all Markets
     * Get all Markets
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param sport_ids Filter Sport Ids (comma separated)
     * @param is_simple_snap_capable Is Simple Snap Capable
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMarketListHttpResponse>;
    public apiSportsdataMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMarketListHttpResponse>>;
    public apiSportsdataMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMarketListHttpResponse>>;
    public apiSportsdataMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }
        if (is_simple_snap_capable !== undefined && is_simple_snap_capable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_simple_snap_capable, 'is_simple_snap_capable');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/markets`;
        return this.httpClient.request<AppHttpResponsesSportsDataMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Matches
     * Get all Matches
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param match_ids Match Ids
     * @param tenant_ids Tenant Ids
     * @param sport_ids Sport Ids
     * @param tournament_ids Tournament Ids
     * @param start_date Filter BetSnap Start-Date
     * @param end_date Filter BetSnap End-Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataMatchesGet(per_page?: number, page?: number, match_ids?: string, tenant_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchListHttpResponse>;
    public apiSportsdataMatchesGet(per_page?: number, page?: number, match_ids?: string, tenant_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchListHttpResponse>>;
    public apiSportsdataMatchesGet(per_page?: number, page?: number, match_ids?: string, tenant_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchListHttpResponse>>;
    public apiSportsdataMatchesGet(per_page?: number, page?: number, match_ids?: string, tenant_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (match_ids !== undefined && match_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>match_ids, 'match_ids');
        }
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }
        if (tournament_ids !== undefined && tournament_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tournament_ids, 'tournament_ids');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/matches`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Matches Markets
     * Get all Matches Markets
     * @param start_date Filter Match Start-Date
     * @param end_date Filter Match End-Date
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param tournament_ids Tournament Ids (comma separated)
     * @param market_ids Market Ids (comma separated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataMatchesMarketsGet(start_date: string, end_date: string, per_page?: number, page?: number, tournament_ids?: string, market_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchMarketListHttpResponse>;
    public apiSportsdataMatchesMarketsGet(start_date: string, end_date: string, per_page?: number, page?: number, tournament_ids?: string, market_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchMarketListHttpResponse>>;
    public apiSportsdataMatchesMarketsGet(start_date: string, end_date: string, per_page?: number, page?: number, tournament_ids?: string, market_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchMarketListHttpResponse>>;
    public apiSportsdataMatchesMarketsGet(start_date: string, end_date: string, per_page?: number, page?: number, tournament_ids?: string, market_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (start_date === null || start_date === undefined) {
            throw new Error('Required parameter start_date was null or undefined when calling apiSportsdataMatchesMarketsGet.');
        }
        if (end_date === null || end_date === undefined) {
            throw new Error('Required parameter end_date was null or undefined when calling apiSportsdataMatchesMarketsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (tournament_ids !== undefined && tournament_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tournament_ids, 'tournament_ids');
        }
        if (market_ids !== undefined && market_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>market_ids, 'market_ids');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/matches/markets`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Match Timeline Events
     * Get all Match Timeline Events
     * @param match_id Match Id
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param type Type (comma separated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataMatchesMatchIdTimelinesGet(match_id: number, per_page?: number, page?: number, type?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchTimelineEventListHttpResponse>;
    public apiSportsdataMatchesMatchIdTimelinesGet(match_id: number, per_page?: number, page?: number, type?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchTimelineEventListHttpResponse>>;
    public apiSportsdataMatchesMatchIdTimelinesGet(match_id: number, per_page?: number, page?: number, type?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchTimelineEventListHttpResponse>>;
    public apiSportsdataMatchesMatchIdTimelinesGet(match_id: number, per_page?: number, page?: number, type?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiSportsdataMatchesMatchIdTimelinesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/timelines`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchTimelineEventListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Matches Scores
     * Get all Matches Scores
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param match_ids Match Ids (comma separated)
     * @param home_ids Home Ids (comma separated)
     * @param away_ids Away Ids (comma separated)
     * @param start_date Filter Match Start-Date
     * @param end_date Filter Match End-Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataMatchesScoresGet(per_page?: number, page?: number, match_ids?: string, home_ids?: string, away_ids?: string, start_date?: string, end_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchScoreListHttpResponse>;
    public apiSportsdataMatchesScoresGet(per_page?: number, page?: number, match_ids?: string, home_ids?: string, away_ids?: string, start_date?: string, end_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchScoreListHttpResponse>>;
    public apiSportsdataMatchesScoresGet(per_page?: number, page?: number, match_ids?: string, home_ids?: string, away_ids?: string, start_date?: string, end_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchScoreListHttpResponse>>;
    public apiSportsdataMatchesScoresGet(per_page?: number, page?: number, match_ids?: string, home_ids?: string, away_ids?: string, start_date?: string, end_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (match_ids !== undefined && match_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>match_ids, 'match_ids');
        }
        if (home_ids !== undefined && home_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>home_ids, 'home_ids');
        }
        if (away_ids !== undefined && away_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>away_ids, 'away_ids');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/matches/scores`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchScoreListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Subscribe a Sport Event
     * @param event_id Event Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataSporteventsEventIdSubscribePost(event_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataSubscribedSportEventHttpResponse>;
    public apiSportsdataSporteventsEventIdSubscribePost(event_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataSubscribedSportEventHttpResponse>>;
    public apiSportsdataSporteventsEventIdSubscribePost(event_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataSubscribedSportEventHttpResponse>>;
    public apiSportsdataSporteventsEventIdSubscribePost(event_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (event_id === null || event_id === undefined) {
            throw new Error('Required parameter event_id was null or undefined when calling apiSportsdataSporteventsEventIdSubscribePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/sportevents/${this.configuration.encodeParam({name: "event_id", value: event_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/subscribe`;
        return this.httpClient.request<AppHttpResponsesSportsDataSubscribedSportEventHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unsubscribe a Sport Event
     * @param event_id Event Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataSporteventsEventIdUnsubscribeDelete(event_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiSportsdataSporteventsEventIdUnsubscribeDelete(event_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiSportsdataSporteventsEventIdUnsubscribeDelete(event_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiSportsdataSporteventsEventIdUnsubscribeDelete(event_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (event_id === null || event_id === undefined) {
            throw new Error('Required parameter event_id was null or undefined when calling apiSportsdataSporteventsEventIdUnsubscribeDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/sportevents/${this.configuration.encodeParam({name: "event_id", value: event_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/unsubscribe`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Client Subscribed Sport Events
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataSporteventsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataSubscribedSportEventListHttpResponse>;
    public apiSportsdataSporteventsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataSubscribedSportEventListHttpResponse>>;
    public apiSportsdataSporteventsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataSubscribedSportEventListHttpResponse>>;
    public apiSportsdataSporteventsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/sportevents`;
        return this.httpClient.request<AppHttpResponsesSportsDataSubscribedSportEventListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sports
     * Get all sports
     * @param active Active filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataSportsGet(active?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataSportListHttpResponse>;
    public apiSportsdataSportsGet(active?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataSportListHttpResponse>>;
    public apiSportsdataSportsGet(active?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataSportListHttpResponse>>;
    public apiSportsdataSportsGet(active?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (active !== undefined && active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>active, 'active');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/sports`;
        return this.httpClient.request<AppHttpResponsesSportsDataSportListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all teams
     * Get all teams
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param team_ids Filter Team Ids (comma separated)
     * @param team_abbr Filter Team Abbreviations (comma separated)
     * @param tournament_ids Filter Tournament Ids (comma separated)
     * @param tenant_id Tenant Id (for specific translations)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataTeamsGet(per_page?: number, page?: number, team_ids?: string, team_abbr?: string, tournament_ids?: string, tenant_id?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataTeamListHttpResponse>;
    public apiSportsdataTeamsGet(per_page?: number, page?: number, team_ids?: string, team_abbr?: string, tournament_ids?: string, tenant_id?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataTeamListHttpResponse>>;
    public apiSportsdataTeamsGet(per_page?: number, page?: number, team_ids?: string, team_abbr?: string, tournament_ids?: string, tenant_id?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataTeamListHttpResponse>>;
    public apiSportsdataTeamsGet(per_page?: number, page?: number, team_ids?: string, team_abbr?: string, tournament_ids?: string, tenant_id?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (team_ids !== undefined && team_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>team_ids, 'team_ids');
        }
        if (team_abbr !== undefined && team_abbr !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>team_abbr, 'team_abbr');
        }
        if (tournament_ids !== undefined && tournament_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tournament_ids, 'tournament_ids');
        }
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/teams`;
        return this.httpClient.request<AppHttpResponsesSportsDataTeamListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tournaments
     * Get all tournaments admin
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param sport_ids Filter Sport Ids (comma separated)
     * @param start_date Filter Tournament Start-Date
     * @param end_date Filter Tournament End-Date
     * @param tournament_ids Filter Tournament Ids (comma separated)
     * @param active Active filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataTournamentListHttpResponse>;
    public apiSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataTournamentListHttpResponse>>;
    public apiSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataTournamentListHttpResponse>>;
    public apiSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }
        if (tournament_ids !== undefined && tournament_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tournament_ids, 'tournament_ids');
        }
        if (active !== undefined && active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>active, 'active');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/sportsdata/tournaments`;
        return this.httpClient.request<AppHttpResponsesSportsDataTournamentListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
