import {Component} from '@angular/core';
import {
    AuthenticationService, ErrorService, FriendsService, GoogleAnalyticsService
} from '../../../../../shared';

import {AFriendsRequestsComponent} from '../a-friends-requests.component';
import {
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse,
    AppHttpResponsesFriendsFriendInvitationHttpResponse as FriendInvitationHttpResponse
} from '../../../../../api';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-friends-requests-default',
    templateUrl: './friends-requests-default.component.html',
    styles: []
})
export class FriendsRequestsDefaultComponent extends AFriendsRequestsComponent {

    constructor(public authenticationService: AuthenticationService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected friendsService: FriendsService,
                protected errorService: ErrorService) {
        super(
            authenticationService,
            googleAnalyticsService,
            friendsService
        );
    }

    showDeclinedFriendInvitations(): void {
        this.visibleDeclinedFriendInvitations = true;
        this.googleAnalyticsService.trackEvent('friends', 'show', 'declined friend invitations');
    }

    acceptFriendInvitation(friendInvitation: FriendInvitationHttpResponse) {

        this.friendsService.acceptFriendInvitation(
            friendInvitation
        ).pipe(take(1))
        .subscribe({
            next: (friendHttpResponse: FriendHttpResponse) => {},
            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        });

        this.googleAnalyticsService.trackEvent('friends', 'accept', 'friend invitations');
    }

    declineFriendInvitation(friendInvitation: FriendInvitationHttpResponse) {
        this.friendsService.declineFriendInvitation(
            friendInvitation
        ).pipe(take(1))
        .subscribe({
            next: (friendInvitationHttpResponse: FriendInvitationHttpResponse) => {},
            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        });
        this.googleAnalyticsService.trackEvent('friends', 'decline', 'friend invitations');
    }

    deleteFriendInvitation(friendInvitation: FriendInvitationHttpResponse) {
        this.friendsService.deleteFriendInvitation(
            friendInvitation
        ).pipe(take(1))
        .subscribe({
            next: () => {},
            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        });
        this.googleAnalyticsService.trackEvent('friends', 'delete', 'friend invitations');
    }
}
