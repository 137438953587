import {Injectable} from '@angular/core';
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {
    FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import {CustomGoogleLoginProvider} from '../classes/custom-google-login-provider';

@Injectable({
    providedIn: 'root'
})
export class SocialService {

    constructor(private socialAuthService: SocialAuthService) {
    }

    getSocialUser(socialPlatform: string): Promise<any> {
        let socialPlatformProvider;
        if (socialPlatform === 'facebook') {
            socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        } else if (socialPlatform === 'google') {
            socialPlatformProvider = CustomGoogleLoginProvider.PROVIDER_ID;
        }

        return this.socialAuthService.signIn(socialPlatformProvider);
    }
}
