import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'betsnaps-notfound',
  templateUrl: './notfound.component.html',
  styles: []
})
export class NotfoundComponent implements OnInit {
  @HostBinding('class') componentClass = 'standardview';

  constructor() { }

  ngOnInit() {
  }

}
