import {Component, HostBinding, OnInit} from '@angular/core';
import {
    ErrorService,
    GoogleAnalyticsService,
    MyNotificationsService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {Router} from '@angular/router';
import {ANewsfeedComponent} from '../a-newsfeed-component';
import {take} from 'rxjs/operators';
import {
    AppHttpResponsesNotificationsUserNotificationHttpResponse as UserNotificationHttpResponse,
    AppHttpResponsesNotificationsUserNotificationListHttpResponse as UserNotificationListHttpResponse
} from '../../../../api';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-newsfeed-v3',
    templateUrl: './newsfeed-v3.component.html',
    styles: []
})
export class NewsfeedV3Component extends ANewsfeedComponent implements OnInit {
    @HostBinding('class') componentClass = 'standardview';

    constructor(public tenantService: TenantService,
                public notificationService: MyNotificationsService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService,
                protected router: Router,
                protected windowRef: WindowRef) {
        super(
            tenantService,
            notificationService,
            googleAnalyticsService,
            errorService,
            router,
            windowRef
        );
    }

    ngOnInit(): void {
        if (this.userNotificationListSubscription) {
            this.userNotificationListSubscription.unsubscribe();
        }

        this.userNotificationListSubscription = this.notificationService.getUserNotifications(
            undefined,
            true,
            undefined,
            this.perPage,
            this.currentPage
        ).pipe(take(1))
        .subscribe({
            next: (userNotificationList: UserNotificationListHttpResponse) => {
                if (userNotificationList) {
                    this.userNotifications = userNotificationList;
                    this.processList = false;

                    // mark notifications as read if unread exists
                    if (this.notificationService.unreadNewsCount > 0) {
                        this.markAllNotificationsAsRead();
                    }
                }
            },
            error: (err: HttpErrorResponse) => {
                this.userNotifications = null;
                this.errorService.handleHttpErrorResponse(err);
            },
            complete: () => {
                this.userNotificationListSubscription.unsubscribe();
                this.userNotificationListSubscription = null;
            }
        });
    }

    loadMore(): void {
        if (this.userNotifications.results.length < this.userNotifications.total) {
            this.processLoadMore = true;
            this.currentPage = this.currentPage + 1;

            if (this.userNotificationListSubscription) {
                this.userNotificationListSubscription.unsubscribe();
            }

            this.userNotificationListSubscription = this.notificationService.getUserNotifications(
                undefined,
                true,
                undefined,
                this.perPage,
                this.currentPage
            ).pipe(take(1))
            .subscribe({
                next: (userNotificationList: UserNotificationListHttpResponse) => {
                    if (userNotificationList) {
                        this.userNotifications.total = userNotificationList.total;
                        this.userNotifications.last_page = userNotificationList.last_page;
                        this.userNotifications.results.push(...userNotificationList.results);
                        this.processLoadMore = false;
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
        }
    }

    navigateToTarget(userNotification: UserNotificationHttpResponse) {
        if (userNotification.target_relative_path) {
            this.googleAnalyticsService.trackEvent('news', 'click', 'notification - ' + userNotification.notification.code);
            this.router.navigate([userNotification.target_relative_path]);
            return;
        }
    }

    private markAllNotificationsAsRead() {
        this.notificationService.markAllNotificationsAsRead()
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.notificationService.updateNotificationsNewsCounter(0);
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

}
