<div *ngIf="chat">
    <button type="button"
            class="ui circular icon shadowed calltoaction2 button margin-0 chat-button chat-bottom-fixed-button"
            *ngIf="initialLoadFinished"
            (click)="toggleChat(); googleAnalyticsService.trackEvent('game', 'open', 'chat');">
        <i class="fa fa-comments big icon"></i>
        <span class="floating ui small circular shadowed calltoaction label"
              *ngIf="chat.unread_messages_count > 0">
            {{(chat.unread_messages_count > 99) ? '99+' : chat.unread_messages_count}}
        </span>
    </button>

    <div [hidden]="!chatVisible" #chatContainer
         id="chat-bottom-fixed-container"
         class="is-fullscreen"
         [class.betsnap-detail-view]="betsnapDetailView">
        <div class="chat-header">
            <div class="ui middle aligned grid margin-0">
                <div class="row">
                    <div class="fourteen wide left aligned column truncate">
                        <i class="fa fa-comments huge icon margin-r-10"></i><span
                            class="display-inline-block vertical-align-middle text-large">
                            {{'CHAT.header-title' | translate}}
                        </span>
                    </div>
                    <div class="two wide right aligned column">
                        <i class="close huge icon margin-0 link" (click)="toggleChat()"></i>
                    </div>
                </div>
            </div>
        </div>
        <betsnaps-chat-messages
            [chatVisible]="chatVisible"
        ></betsnaps-chat-messages>
    </div>
</div>
