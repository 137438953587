import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameSinglePlayerCreateHttpResponse as GameSinglePlayerCreateHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameHttpResponse as GameVendorGameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesTenantVendorGameHttpResponse as TenantVendorGameHttpResponse
} from '../../../api';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GameVendorGameParticipationStatusEnum,
    GoogleAnalyticsService,
    MyModalService, MyNotificationsService,
    TenantService
} from '../../../shared';
import * as moment from 'moment';
import {SinglePlayerGameService} from '../../../shared/services/singleplayer-game.service';
import {take, takeWhile} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {SingleplayerJoinModalComponent} from '../singleplayer-join-modal/singleplayer-join-modal.component';

@Component({
    selector: 'betsnaps-singleplayer-game-card',
    templateUrl: './singleplayer-game-card.component.html',
    styles: []
})
export class SingleplayerGameCardComponent implements OnInit, OnDestroy {

    private componentAlive: boolean = true;

    @Input() tenantVendorGame: TenantVendorGameHttpResponse;

    @ViewChild('singleplayerJoinModalComponent', {static: true}) singleplayerJoinModalComponent: SingleplayerJoinModalComponent;

    public backgroundImage: string;
    public backgroundImageUrl: string;
    public gameVendorGame: GameVendorGameHttpResponse;
    public activeParticipation: GameVendorGameParticipationHttpResponse;
    public showFullScreenGame: boolean = false;

    public processParticipation: boolean = false;

    protected singlePlayerGame: GameHttpResponse;

    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;

    constructor(private authenticationService: AuthenticationService,
                private singlePlayerGameService: SinglePlayerGameService,
                public betsnapDetailService: BetsnapdetailService,
                public googleAnalyticsService: GoogleAnalyticsService,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService,
                private myModalService: MyModalService,
                private tenantService: TenantService,
                private errorService: ErrorService) {
    }

    ngOnInit(): void {
        // get header background image
        if (this.tenantVendorGame.initial_game_image_media) {
            this.backgroundImageUrl = this.tenantVendorGame.initial_game_image_media.media_url;
        } else if (this.tenantVendorGame.vendor_game.initial_game_image_media) {
            this.backgroundImageUrl = this.tenantVendorGame.vendor_game.initial_game_image_media.media_url;
        }
        if (this.backgroundImageUrl) {
            this.backgroundImage = 'url("' + this.backgroundImageUrl + '")';
        }

        // subscribe to singleplayer game participation changes
        this.singlePlayerGameService.singlePlayerGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((singlePlayerGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                if (singlePlayerGameParticipations && this.tenantVendorGame) {
                    this.activeParticipation = singlePlayerGameParticipations.find((participation: GameVendorGameParticipationHttpResponse) => (
                        participation.vendor_game_id === this.tenantVendorGame.vendor_game_id &&
                        participation.status === this.gameVendorGameParticipationStatusEnum.ACTIVE &&
                        participation.game_user_points > 0
                    ));
                } else {
                    this.activeParticipation = null;
                }
            });

        this.singlePlayerGameService.showFullScreenGame$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((showFullScreenGame: boolean) => {
                if (showFullScreenGame && showFullScreenGame !== this.showFullScreenGame) {
                    this.showFullScreenGame = showFullScreenGame;
                } else {
                    this.showFullScreenGame = false;
                }
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    public openSingleplayerJoinModal() {
        if (!this.processParticipation) {
            if (this.authenticationService.currentUser) {
                this.singleplayerJoinModalComponent.open();
            } else {
                const loginRegisterModal = this.myModalService.openLoginRegisterModal('login', null, true, 'singleplayergamecard');
                if (loginRegisterModal) {
                    loginRegisterModal.onApprove(() => {
                        this.openSingleplayerJoinModal();
                    });
                }
            }
        }
    }

    public startParticipation(points: number = null) {
        if (!this.processParticipation) {
            if (this.authenticationService.currentUser) {
                if (!this.authenticationService.currentUser.username || !this.authenticationService.currentUser.is_username_confirmed) {
                    this.myModalService.openUsernameInputModal()
                        .onApprove(() => this.startParticipation(points));
                } else if (this.authenticationService.currentUser.wallets[0].balance === 0) {
                    this.translateService.get(['GENERAL.LABELS.alert', 'GENERAL.SINGLE_PLAYER_GAME.insufficient_amount_to_start'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['GENERAL.LABELS.warning'], translation['GENERAL.SINGLE_PLAYER_GAME.insufficient_amount_to_start'], NotificationType.Alert);
                        });
                } else {
                    this.processParticipation = true;
                    this.singlePlayerGameService.startGameVendorSinglePlayerGame(this.tenantVendorGame.vendor_game_id, points)
                        .pipe(take(1))
                        .subscribe({
                            next: (singlePlayerGameCreateResponse: GameSinglePlayerCreateHttpResponse) => {
                                this.singlePlayerGame = singlePlayerGameCreateResponse.game;
                                this.gameVendorGame = singlePlayerGameCreateResponse.game_vendor_game;
                                this.activeParticipation = singlePlayerGameCreateResponse.game_vendor_game_participation;

                                this.singlePlayerGameService.getSinglePlayerParticipations(this.authenticationService.currentUser.id);
                                this.singleplayerJoinModalComponent.close();

                                this.openGameUrl();
                            },
                            error: (err: HttpErrorResponse) => {
                                this.processParticipation = false;
                                this.singleplayerJoinModalComponent.confirmPointsProcess = false;
                                this.errorService.handleHttpErrorResponse(err);
                            }
                        });
                }
            } else {
                this.myModalService.openLoginRegisterModal('login', null, true, 'singleplayergamecard');
            }
        }
    }

    public resumeParticipation(participation: GameVendorGameParticipationHttpResponse) {
        if (!this.processParticipation &&
            this.authenticationService.currentUser &&
            participation) {
            this.processParticipation = true;

            // check if url expired
            if (moment.utc() < moment(participation.game_url_expiration)) {
                this.openGameUrl();
            } else {
                this.betsnapDetailService.getGameVendorGameParticipation(
                    participation.game_unique_id,
                    participation.id
                ).pipe(take(1))
                .subscribe({
                    next: (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                        this.activeParticipation = gameVendorGameParticipation;
                        this.openGameUrl();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processParticipation = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
            }
        }
    }

    private openGameUrl() {
        this.processParticipation = false;
        this.singlePlayerGameService.showFullscreenVendorGame(this.activeParticipation, this.tenantVendorGame);
    }
}
