/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from './appHttpResponsesUsersPlayerPublicHttpResponse';


/**
 * ChatUserHttpResponse
 */
export interface AppHttpResponsesChatsChatUserHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Chat Id
     */
    chat_id: number;
    /**
     * User Id
     */
    user_id: number;
    user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    /**
     * Last Read Message ID
     */
    last_read_message_id?: number;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

