import {Component} from '@angular/core';
import {AMatchCardDetailsComponent} from '../a-match-card-details-component';

@Component({
    selector: 'betsnaps-match-card-details-basketball',
    templateUrl: './match-card-details-basketball.component.html',
    styles: []
})
export class MatchCardDetailsBasketballComponent extends AMatchCardDetailsComponent {

    public getBasketballColumnClasses(): string[] {
        let competitorColumnClass = 'nine';
        let scoreColumnClass = 'five';
        if (this.match.score.periods) {
            if (this.match.score.periods.length === 3) {
                competitorColumnClass = 'eight';
                scoreColumnClass = 'six';
            } else if (this.match.score.periods.length === 4) {
                competitorColumnClass = 'seven';
                scoreColumnClass = 'seven';
            } else if (this.match.score.periods.length > 4) {
                competitorColumnClass = 'six';
                scoreColumnClass = 'eight';
            }
        }
        return [competitorColumnClass, scoreColumnClass];
    }

}
