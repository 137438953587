<div class="ui middle aligned centered padded grid">
    <div class="three column row">
        <div class="column">
            <div class="text-tiny color-grey truncate">
                {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.entry_points' | translate}}
            </div>
            <div class="font-bold truncate">
                {{gameVendorGameParticipation.entry_points | number:'1.2-2'}}
            </div>
        </div>
        <div class="column">
            <div class="text-tiny color-grey truncate">
                {{'GENERAL.GAMEVENDORGAMEPARTICIPATIONLOG.total' | translate}}
            </div>
            <strong><span *ngIf="participationSaldo() > 0">+</span>{{participationSaldo() | number:'1.2-2'}}</strong>
        </div>

        <div class="center aligned column" *ngIf="gameVendorGameParticipation.status !== gameVendorGameParticipationStatusEnum.CLOSED">
            <button (click)="cashOutVendorGameParticipation()" type="button" class="ui fluid button padding-5"
                    [class.loading]="processCashOut"
                    [disabled]="processCashOut || !isCashoutAvailable()"
                    [ngClass]="{
                    'grey disabled': processCashOut,
                    'positive': (gameVendorGameParticipation.game_user_points > gameVendorGameParticipation.entry_points),
                    'negative': (gameVendorGameParticipation.game_user_points < gameVendorGameParticipation.entry_points),
                    'opacity-50': (!isCashoutAvailable())
                  }">
                <span class="display-block" *ngIf="isCashoutAvailable()"><strong>{{'GENERAL.BETS.cashout' | translate | uppercase}}</strong></span>
                <span class="display-block text-mini" *ngIf="!isCashoutAvailable()">{{'GENERAL.BETS.no_cashout' | translate | uppercase}}</span>
                <span class="display-block">{{cashoutValue | number:'1.2-2'}}</span>
            </button>
        </div>
        <div class="center aligned column" *ngIf="gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.CLOSED">
            <div class="ui fluid label points-label"
                 [class.basic]="participationSaldo() !== 0"
                 [ngClass]="{
                    'positive': (participationSaldo() > 0),
                    'negative': (participationSaldo() < 0)
                }">
                <strong class="text-medium">{{gameVendorGameParticipation.game_user_points | number:'1.2-2'}}</strong>
            </div>
        </div>
    </div>
</div>

<div class="logs" *ngIf="closedParticipationTransactions() && closedParticipationTransactions().length > 0">
    <div class="ui horizontal divider tiny margin-0">
        {{closedParticipationTransactions().length}} {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.logs' | translate | uppercase}}
    </div>
    <betsnaps-game-vendor-game-participation-transaction
        class="game-vendor-game-participation-transaction"
        *ngFor="let gameVendorGameParticipationTransaction of closedParticipationTransactions(); trackBy: gameVendorGameParticipationTransactionsTrackBy"
        [gameVendorGameParticipationTransaction]="gameVendorGameParticipationTransaction">
    </betsnaps-game-vendor-game-participation-transaction>
</div>
