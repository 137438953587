import {Injectable} from '@angular/core';
import {
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesTenantsTenantHintHttpResponse as TenantHintHttpResponse,
    AppHttpResponsesTenantsTenantHintListHttpResponse as TenantHintListHttpResponse,
    HintsApi,
    AppHttpResponsesGeneralLanguageHttpResponse as LanguageHttpResponse,
    AppHttpRequestsHintsUserHintUpdateRequest as HintUpdateRequest
} from '../../api';
import {Observable, BehaviorSubject, Subscription, EMPTY} from 'rxjs';
import {DebugService} from './debug.service';
import {BroadcastingService} from './broadcasting.service';
import {ErrorService} from './error.service';
import {TenantService} from './tenant.service';
import {HttpErrorResponse} from '@angular/common/http';
import {cloneDeep} from 'lodash';
import {AppEventsHintsUserHintListUpdated as UserHintListUpdatedEvent} from '../';
import {map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HintService {

    private _currentUser: PlayerHttpResponse;
    private _currentLang: LanguageHttpResponse;

    private unreadGeneralUserHintsSubject = new BehaviorSubject<TenantHintHttpResponse[]>(null);
    public unreadGeneralUserHints$ = this.unreadGeneralUserHintsSubject.asObservable();

    private unreadUserHintsSubject = new BehaviorSubject<TenantHintHttpResponse[]>(null);
    public unreadUserHints$ = this.unreadUserHintsSubject.asObservable();
    private unreadUserHintsLoadedSubscription: Subscription;

    private unreadUserHintsLoadedSubject = new BehaviorSubject<boolean>(false);
    public unreadUserHintsLoaded$ = this.unreadUserHintsLoadedSubject.asObservable();

    private currentHintToDisplaySubject = new BehaviorSubject<TenantHintHttpResponse>(null);
    public currentHintToDisplay$ = this.currentHintToDisplaySubject.asObservable();

    private broadcastEventSubscription: Subscription;
    private apiGetRequestSubscriptions: Subscription[] = [];

    constructor(private tenantService: TenantService,
                private broadcastingService: BroadcastingService,
                private debugService: DebugService,
                private errorService: ErrorService,
                private hintsApi: HintsApi) {
    }

    public get unreadGeneralUserHints(): TenantHintHttpResponse[] {
        return this.unreadGeneralUserHintsSubject.value;
    }

    public get unreadUserHints(): TenantHintHttpResponse[] {
        return this.unreadUserHintsSubject.value;
    }

    public set currentLang(languageHttpResponse: LanguageHttpResponse) {
        this._currentLang = languageHttpResponse;
    }

    public set currentUser(userInfo: PlayerHttpResponse) {
        this._currentUser = userInfo;
    }

    initUserData(currentUser: PlayerHttpResponse, currentLang: LanguageHttpResponse) {
        this._currentUser = currentUser;
        this._currentLang = currentLang;

        this.loadAllHintsData();
    }

    public loadAllHintsData() {
        if (this._currentUser) {
            this.getUnreadUserHints();
            this.subscribeForBroadcastEvents();
        }
    }

    private getUnreadUserHints() {
        if (this._currentUser) {
            this.apiGetRequestSubscriptions.push(
                this.hintsApi.apiTenantsTenantIdUsersUserIdHintsGet(
                    this.tenantService.tenantData.id,
                    this._currentUser.id
                ).pipe(take(1))
                .subscribe({
                    next: (hintListHttpResponse: TenantHintListHttpResponse) => {
                        if (hintListHttpResponse) {
                            const unreadGeneralUserHints = hintListHttpResponse.results.filter(
                                (hint: TenantHintHttpResponse) => hint.master_hint.is_general
                            );
                            this.unreadGeneralUserHintsSubject.next(unreadGeneralUserHints);
                            const unreadUserHints = hintListHttpResponse.results.filter(
                                (hint: TenantHintHttpResponse) => !hint.master_hint.is_general
                            );
                            this.unreadUserHintsSubject.next(unreadUserHints);
                            if (!this.unreadUserHintsLoadedSubject.value) {
                                this.unreadUserHintsLoadedSubject.next(true);
                            }
                        }
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                })
            );
        }
    }

    private subscribeForBroadcastEvents() {

        if (this.broadcastEventSubscription) {
            this.broadcastEventSubscription.unsubscribe();
        }

        this.broadcastEventSubscription =
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Hints\\UserHintListUpdated')
                .subscribe((broadcastEventData: UserHintListUpdatedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('UserHintListUpdatedEvent');
                        this.unreadUserHintsSubject.next(broadcastEventData.tenantHintHttpResponses);
                    }
                });
    }

    public markHintsAsRead(masterHintIds: number[]): Observable<any> {
        if (!this._currentUser) {
            return EMPTY;
        }

        const hintUpdateRequest: HintUpdateRequest = {
            master_hint_ids: masterHintIds,
            is_read: true
        };

        return this.hintsApi.apiTenantsTenantIdUsersUserIdHintsPut(
            this.tenantService.tenantData.id,
            this._currentUser.id,
            hintUpdateRequest
        ).pipe(map((hintListHttpResponse: TenantHintListHttpResponse) => {
            this.unreadUserHintsSubject.next(hintListHttpResponse.results);
        }));
    }

    public checkForHintToDisplay(triggerKey: string, triggerConditionValues = null) {
        if (this._currentUser) {
            if (this.unreadUserHintsLoadedSubscription) {
                this.unreadUserHintsLoadedSubscription.unsubscribe();
            }
            this.unreadUserHintsLoadedSubscription = this.unreadUserHintsLoaded$.subscribe(
                (unreadUserHintsLoaded: boolean) => {
                    if (unreadUserHintsLoaded) {
                        if (!this.currentHintToDisplaySubject.value && this.unreadUserHints && this.unreadUserHints.length > 0) {
                            const unreadUserHints = cloneDeep(this.unreadUserHints);
                            const hintsToDisplay = unreadUserHints.filter(
                                (hint: TenantHintHttpResponse) => (
                                    hint.master_hint.trigger_key === triggerKey &&
                                    (!hint.master_hint.trigger_condition || (hint.master_hint.trigger_condition && this.checkTriggerConditions(hint, triggerConditionValues)))
                                )
                            );
                            if (hintsToDisplay && hintsToDisplay.length > 0) {
                                if (hintsToDisplay.length > 1) {
                                    // sort hints by order
                                    hintsToDisplay.sort(function (hint1: TenantHintHttpResponse, hint2: TenantHintHttpResponse) {
                                        if (hint1.order < hint2.order) {
                                            return -1;
                                        } else if (hint1.order > hint2.order) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    });
                                }
                                this.displayHint(hintsToDisplay[0]);
                            }
                        }
                    }
                }
            );
        }
    }

    private checkTriggerConditions(hint: TenantHintHttpResponse, triggerConditionValues: string): boolean {
        const hintConditions = JSON.parse(hint.master_hint.trigger_condition);
        if (hintConditions && Object.keys(hintConditions).length > 0 && triggerConditionValues && Object.keys(triggerConditionValues).length > 0) {
            let matchingConditions = 0;
            for (const conditionKey in hintConditions) {
                if (hintConditions.hasOwnProperty(conditionKey)) {
                    const conditionValue = hintConditions[conditionKey];
                    if (triggerConditionValues.hasOwnProperty(conditionKey)) {
                        if (conditionValue === triggerConditionValues[conditionKey]) {
                            matchingConditions++;
                        }
                    }
                }
            }
            return (matchingConditions === Object.keys(hintConditions).length);
        }
        return false;
    }

    private displayHint(hint: TenantHintHttpResponse) {
        if (hint) {
            // get translation for possible value replacements
            if (hint.translations) {
                if (hint.title) {
                    const titleTranslation = hint.translations.find(
                        (translationItem) =>
                            translationItem.label_field === 'title' &&
                            translationItem.iso_code2 === this._currentLang.iso_code2);
                    if (titleTranslation) {
                        hint.title = titleTranslation.label_value;
                    }
                }
                if (hint.description) {
                    const descriptionTranslation = hint.translations.find(
                        (translationItem) =>
                            translationItem.label_field === 'description' &&
                            translationItem.iso_code2 === this._currentLang.iso_code2);
                    if (descriptionTranslation) {
                        hint.description = descriptionTranslation.label_value;
                    }
                }
            }

            this.currentHintToDisplaySubject.next(hint);
        }
    }

    resetHint() {
        if (this.currentHintToDisplaySubject.value) {
            this.currentHintToDisplaySubject.next(null);
        }
    }

    resetAllData() {
        this._currentUser = null;
        this._currentLang = null;
        this.unreadGeneralUserHintsSubject.next(null);
        this.unreadUserHintsSubject.next(null);
        this.unreadUserHintsLoadedSubject.next(false);
        this.currentHintToDisplaySubject.next(null);

        if (this.unreadUserHintsLoadedSubscription) {
            this.unreadUserHintsLoadedSubscription.unsubscribe();
        }

        if (this.broadcastEventSubscription) {
            this.broadcastEventSubscription.unsubscribe();
        }

        this.apiGetRequestSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.apiGetRequestSubscriptions = [];
    }

}
