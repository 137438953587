import {Component} from '@angular/core';
import {
    AuthenticationService, ErrorService, GoogleAnalyticsService, ProfileService,
    TenantService, WindowRef
} from '../../../../../shared';
import {AProfileOverviewComponent} from '../a-profile-overview.component';

@Component({
    selector: 'betsnaps-profile-overview-default',
    templateUrl: './profile-overview-default.component.html',
    styles: []
})
export class ProfileOverviewDefaultComponent extends AProfileOverviewComponent {

    constructor(protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected profileService: ProfileService,
                protected errorService: ErrorService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected windowRef: WindowRef
    ) {
        super(
            authenticationService,
            tenantService,
            profileService,
            errorService,
            googleAnalyticsService,
            windowRef
        );
    }
}
