import {Component, Renderer2} from '@angular/core';
import {ASidebarComponent} from '../../a-sidebar-component';
import {AuthenticationService} from '../../../../../shared';

@Component({
    selector: 'betsnaps-right-sidebar-default',
    templateUrl: './right-sidebar-default.component.html',
    styles: []
})
export class RightSidebarDefaultComponent extends ASidebarComponent {

    constructor(protected renderer: Renderer2,
                protected authenticationService: AuthenticationService) {
        super(
            authenticationService,
            renderer
        );
    }

}
