import {Injectable} from '@angular/core';
import {
    AppHttpRequestsBetsnapsGamesGameCreateGameRequest as CreateGameRequest,
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse as TenantPrizeStructureTemplateHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse as TenantPrizeStructureTemplateListHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse,
    AppHttpResponsesSportsDataMatchListHttpResponse as MatchListHttpResponse,
    AppHttpResponsesTenantsTenantGameEntryFeeListHttpResponse as EntryFeeListHttpResponse,
    AppHttpResponsesTenantsTenantSportHttpResponse as TenantSportHttpResponse,
    AppHttpResponsesTenantsTenantSportListHttpResponse as TenantSportListHttpResponse,
    AppHttpResponsesTenantsTenantTournamentHttpResponse as TenantTournamentHttpResponse,
    AppHttpResponsesTenantsTenantTournamentListHttpResponse as TenantTournamentListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse,
    GameCreationsApi,
    GamesApi,
    PrizesApi,
    TenantsApi
} from '../../api';
import {TenantService} from './tenant.service';
import {AuthenticationService} from './authentication.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from './error.service';
import {take, takeWhile} from 'rxjs/operators';
import {DateRange} from '@angular/material/datepicker';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {MyNotificationsService} from './my-notifications.service';

@Injectable({
    providedIn: 'root'
})
export class CreateSnapService {

    private tenantTournamentsSubject = new Subject<TenantTournamentHttpResponse[]>();
    public tenantTournaments$ = this.tenantTournamentsSubject.asObservable();
    public tenantTournamentsFiltered: TenantTournamentHttpResponse[];

    private tenantMatchesSubject = new Subject<MatchHttpResponse[]>();
    public tenantMatches$ = this.tenantMatchesSubject.asObservable();

    private tenantEntryFeesSubject = new BehaviorSubject<EntryFeeListHttpResponse>(null);
    public tenantEntryFees$ = this.tenantEntryFeesSubject.asObservable();

    private tenantPrizeStructureTemplateIsKeepItFriendlyListSubject = new BehaviorSubject<TenantPrizeStructureTemplateListHttpResponse>(null);
    public tenantPrizeStructureTemplateIsKeepItFriendlyList$ = this.tenantPrizeStructureTemplateIsKeepItFriendlyListSubject.asObservable();

    private tenantPrizeStructureTemplateIsNotKeepItFriendlyListSubject = new BehaviorSubject<TenantPrizeStructureTemplateListHttpResponse>(null);
    public tenantPrizeStructureTemplateIsNotKeepItFriendlyList$ = this.tenantPrizeStructureTemplateIsNotKeepItFriendlyListSubject.asObservable();

    private tenantSelectedSportSubject = new BehaviorSubject<TenantSportHttpResponse>(null);
    public tenantSelectedSport$ = this.tenantSelectedSportSubject.asObservable();

    private tenantSportListSubject = new BehaviorSubject<TenantSportHttpResponse[]>(null);
    public tenantSportList$ = this.tenantSportListSubject.asObservable();

    private createSnapDataAvailableSubject = new BehaviorSubject<boolean>(null);
    public createSnapDataAvailable$ = this.createSnapDataAvailableSubject.asObservable();

    private _matchFilterFromDate: Date;
    private _matchFilterToDate: Date;
    private _selectedMatchTimeFilterOption;
    private _selectedMatchFilterOption;
    private _selectedMatchTournamentsFilterOption;
    private _selectedMatches: MatchHttpResponse[];
    private _selectedCustomLeague: boolean = true;
    private _selectedPredefinedLeagueId: number = null;
    private _selectedRangeValue: DateRange<Date>;
    private _selectedTenantPrizeStructureTemplate: TenantPrizeStructureTemplateHttpResponse = null;
    private _selectedTenantPrizeStructureTemplateIsKeepItFriendly: boolean = false;
    private _selectedStakeValue: number = 0;
    private _selectedGameName: string = null;

    private _createdGame: GameHttpResponse;
    private _challengedUser: PlayerPublicHttpResponse;

    private defaultCompetitionTypeId: number = 2;
    private defaultMaximumSize: number = 50;
    private defaultIsPublic: boolean = false;
    private defaultBetPlacementOption: number = 1;

    private componentAlive = true;
    private apiGetRequestSubscriptions: Subscription[] = [];

    public isSelectSportEnabled: boolean = false;
    public isSelectLeagueEnabled: boolean = false;

    public enoughUserBalance: boolean = false;

    constructor(public authenticationService: AuthenticationService,
                private translateService: TranslateService,
                private myNotificationsService: MyNotificationsService,
                private tenantService: TenantService,
                private tenantsApi: TenantsApi,
                private prizesApi: PrizesApi,
                private gameCreationsApi: GameCreationsApi,
                private gamesApi: GamesApi,
                private errorService: ErrorService) {

        // default next 2 days
        this.matchFilterFromDate = new Date();
        this.matchFilterToDate = new Date();
        this.matchFilterToDate.setDate(this.matchFilterFromDate.getDate() + 2);
        this.matchFilterToDate.setHours(23, 59, 59, 999);
        this._challengedUser = null;

        // as we skip the sports selection for now
        // select the first available tenant sport where user game creation is allowed
        this.getTenantSportList();
    }

    getTenantSportList() {
        this.tenantService.getTenantSports(
            this.tenantService.tenantData.id,
            undefined,
            undefined,
            true,
            true
        ).pipe(take(1))
        .subscribe({
            next: (tenantSportList: TenantSportListHttpResponse) => {
                if (tenantSportList) {
                    const tenantSports = tenantSportList.results.filter(
                        (tenantSport: TenantSportHttpResponse) => tenantSport.tournaments_count > 0 && tenantSport.matches_count > 0 && tenantSport.user_generation_allowed
                    );
                    if (tenantSports.length > 0) {
                        // if only one sport available select it and don't show sport selection step
                        if (tenantSports.length === 1) {
                            this.tenantSelectedSportSubject.next(tenantSports[0]);
                            this.isSelectSportEnabled = false;
                        } else {
                            this.isSelectSportEnabled = true;
                        }
                    }
                    this.tenantSportListSubject.next(tenantSports);

                    // check if user has enough balance
                    this.getEntryFees();
                }
            },
            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        });

        this.tenantEntryFees$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe({
                next: (entryFeeListHttpResponse: EntryFeeListHttpResponse) => {
                    if (entryFeeListHttpResponse) {
                        if (this.getCurrentUserBalance() >= entryFeeListHttpResponse.results[0].entry_fee) {
                            this.enoughUserBalance = true;
                        } else {
                            this.enoughUserBalance = false;
                            this.showNotEnoughUserBalanceNotification();
                        }
                        this.createSnapDataAvailableSubject.next(true);
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });

    }

    getTenantMatches(fromDate: Date = null, toDate: Date = null, sportId: number = null, tournamentIds = null, matchIds = null) {
        if (!tournamentIds || tournamentIds.length === 0) {
            this.tenantMatchesSubject.next([]);
        } else {
            this.apiGetRequestSubscriptions.push(
                this.tenantsApi.apiTenantsTenantIdMatchesGet(
                    this.tenantService.tenantData.id,
                    undefined,
                    undefined,
                    (!matchIds ? undefined : matchIds),
                    sportId.toString(),
                    (!tournamentIds || tournamentIds.length === 0 ? undefined : this.getTournamentsString(tournamentIds)),
                    fromDate.toISOString(),
                    toDate.toISOString()
                ).pipe(take(1))
                .subscribe({
                    next: (matchListHttpResponse: MatchListHttpResponse) => {
                        if (matchListHttpResponse) {
                            this.tenantMatchesSubject.next(matchListHttpResponse.results);
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    getTournamentsString(selectedTournaments): string {
        let tournamentsString = '';
        for (const selectedTournament of selectedTournaments) {
            tournamentsString += (tournamentsString.length > 0 ? ',' : '') + selectedTournament.tournament_id;
        }
        return tournamentsString;
    }

    getTenantTournaments(tenantId: number = null, sportIds: string = null) {
        if (!tenantId) {
            tenantId = this.tenantService.tenantData.id;
        }
        this.apiGetRequestSubscriptions.push(
            this.tenantsApi.apiTenantsTenantIdTournamentsGet(
                tenantId,
                undefined,
                undefined,
                true,
                (sportIds) ? sportIds : undefined
            ).pipe(take(1))
            .subscribe({
                next: (tenantTournamentListHttpResponse: TenantTournamentListHttpResponse) => {
                    if (tenantTournamentListHttpResponse) {
                        this.tenantTournamentsSubject.next(tenantTournamentListHttpResponse.results);
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            })
        );
    }

    getTenantPrizeStructureTemplates(isKeepItFriendly: boolean = null, forceLoad: boolean = false) {
        let myTenantPrizeStructureTemplateListSubject = this.tenantPrizeStructureTemplateIsKeepItFriendlyListSubject;
        if (!isKeepItFriendly) {
            myTenantPrizeStructureTemplateListSubject = this.tenantPrizeStructureTemplateIsNotKeepItFriendlyListSubject;
        }

        if (!myTenantPrizeStructureTemplateListSubject.value || forceLoad) {
            this.apiGetRequestSubscriptions.push(
                this.prizesApi.apiTenantsTenantIdPrizestructuretemplatesGet(this.tenantService.tenantData.id, isKeepItFriendly)
                    .pipe(take(1))
                    .subscribe({
                        next: (tenantPrizeStructureTemplateList: TenantPrizeStructureTemplateListHttpResponse) => {
                            if (tenantPrizeStructureTemplateList) {
                                myTenantPrizeStructureTemplateListSubject.next(tenantPrizeStructureTemplateList);
                            }
                        },
                        error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                    })
            );
        }
    }

    getEntryFees() {
        if (!this.tenantEntryFeesSubject.value) {
            this.apiGetRequestSubscriptions.push(
                this.tenantsApi.apiTenantsTenantIdEntryfeesGet(this.tenantService.tenantData.id)
                    .pipe(take(1))
                    .subscribe({
                        next: (entryFeeListHttpResponse: EntryFeeListHttpResponse) => {
                            if (entryFeeListHttpResponse) {
                                this.tenantEntryFeesSubject.next(entryFeeListHttpResponse);
                            }
                        },
                        error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                    })
            );
        }
    }

    createGame(): Observable<GameHttpResponse> {
        const selectedMatchIds: number[] = [];
        this._selectedMatches.forEach((match: MatchHttpResponse) => {
            selectedMatchIds.push(match.match_id);
        });

        const createGameRequest: CreateGameRequest = {
            game_name: this._selectedGameName,
            competition_type: this.defaultCompetitionTypeId,
            maximum_size: this.defaultMaximumSize,
            entry_fee: this._selectedStakeValue,
            tenant_prize_structure_template_id: this._selectedTenantPrizeStructureTemplate.id,
            is_public: this.defaultIsPublic,
            bet_placement_option: this.defaultBetPlacementOption,
            sport_id: this.tenantSelectedSportSubject.value.sport.id,
            match_ids: selectedMatchIds
        };

        return this.gameCreationsApi.apiTenantsTenantIdGamesPost(
            this.tenantService.tenantData.id,
            createGameRequest
        );
    }

    joinGame(game: GameHttpResponse): Observable<LeaderboardUserHttpResponse> {
        if (this.authenticationService.currentUser) {
            return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(
                this.tenantService.tenantData.id,
                game.game_unique_id, this.authenticationService.currentUser.id
            );
        }
    }

    set matchFilterFromDate(fromDate: Date) {
        this._matchFilterFromDate = fromDate;
    }

    get matchFilterFromDate(): Date {
        return this._matchFilterFromDate;
    }

    set matchFilterToDate(toDate: Date) {
        this._matchFilterToDate = toDate;
    }

    get matchFilterToDate(): Date {
        return this._matchFilterToDate;
    }

    set selectedMatchTimeFilterOption(filterOption) {
        this._selectedMatchTimeFilterOption = filterOption;
    }

    get selectedMatchTimeFilterOption() {
        return this._selectedMatchTimeFilterOption;
    }

    set selectedMatchFilterOption(filterOption) {
        this._selectedMatchFilterOption = filterOption;
    }

    get selectedMatchFilterOption() {
        return this._selectedMatchFilterOption;
    }

    set selectedMatches(tenantMatches: MatchHttpResponse[]) {
        this._selectedMatches = tenantMatches;
    }

    get selectedMatches(): MatchHttpResponse[] {
        return this._selectedMatches;
    }

    set selectedCustomLeague(selectedCustomLeague: boolean) {
        this._selectedCustomLeague = selectedCustomLeague;
    }

    get selectedCustomLeague(): boolean {
        return this._selectedCustomLeague;
    }

    set selectedPredefinedLeagueId(selectedPredefinedLeagueId: number) {
        this._selectedPredefinedLeagueId = selectedPredefinedLeagueId;
    }

    get selectedPredefinedLeagueId(): number {
        return this._selectedPredefinedLeagueId;
    }

    set selectedMatchTournamentsFilterOption(filterOption) {
        this._selectedMatchTournamentsFilterOption = filterOption;
    }

    get selectedMatchTournamentsFilterOption() {
        return this._selectedMatchTournamentsFilterOption;
    }

    set selectedRangeValue(dateRange: DateRange<Date>) {
        this._selectedRangeValue = dateRange;
    }

    get selectedRangeValue(): DateRange<Date> {
        return this._selectedRangeValue;
    }

    set createdGame(game: GameHttpResponse) {
        this._createdGame = game;
    }

    get createdGame(): GameHttpResponse {
        return this._createdGame;
    }

    set challengedUser(user: PlayerPublicHttpResponse) {
        this._challengedUser = user;
    }

    get challengedUser(): PlayerPublicHttpResponse {
        return this._challengedUser;
    }

    get currentUser(): PlayerHttpResponse {
        return this.authenticationService.currentUser;
    }

    set selectedTenantPrizeStructureTemplate(tenantPrizeStructureTemplate: TenantPrizeStructureTemplateHttpResponse) {
        this._selectedTenantPrizeStructureTemplate = tenantPrizeStructureTemplate;
    }

    get selectedTenantPrizeStructureTemplate(): TenantPrizeStructureTemplateHttpResponse {
        return this._selectedTenantPrizeStructureTemplate;
    }

    public setSelectedSport(sport: TenantSportHttpResponse) {
        if (this.tenantSelectedSportSubject.value !== sport) {
            this.tenantSelectedSportSubject.next(sport);
            this.selectedMatches = null;
            this.tenantTournamentsFiltered = null;
            this.selectedCustomLeague = true;
            this.selectedPredefinedLeagueId = null;
            this.tenantSelectedSportSubject.next(sport);
            this.tenantTournamentsSubject.next(null);
            this.tenantMatchesSubject.next(null);
        }
    }

    set selectedTenantPrizeStructureTemplateIsKeepItFriendly(isKeepItFriendly: boolean) {
        this._selectedTenantPrizeStructureTemplateIsKeepItFriendly = isKeepItFriendly;
    }

    get selectedTenantPrizeStructureTemplateIsKeepItFriendly(): boolean {
        return this._selectedTenantPrizeStructureTemplateIsKeepItFriendly;
    }

    set selectedStakeValue(stakeValue: number) {
        this._selectedStakeValue = stakeValue;
    }

    get selectedStakeValue(): number {
        return this._selectedStakeValue;
    }

    set selectedGameName(gameName: string) {
        this._selectedGameName = gameName;
    }

    get selectedGameName(): string {
        return this._selectedGameName;
    }

    resetSelectedData() {
        // reset selected data
        this._matchFilterFromDate = null;
        this._matchFilterToDate = null;
        // default next 2 days
        this.matchFilterFromDate = new Date();
        this.matchFilterToDate = new Date();
        this.matchFilterToDate.setDate(this.matchFilterFromDate.getDate() + 2);
        this.matchFilterToDate.setHours(23, 59, 59, 999);

        this._selectedMatchFilterOption = null;
        this._selectedMatchTimeFilterOption = null;
        this._selectedMatches = null;
        this._selectedCustomLeague = null;
        this._selectedPredefinedLeagueId = null;
    }

    resetAllData() {

        this.tenantTournamentsSubject.next(null);
        this.tenantMatchesSubject.next(null);

        this.tenantEntryFeesSubject.next(null);
        this.tenantPrizeStructureTemplateIsKeepItFriendlyListSubject.next(null);
        this.tenantPrizeStructureTemplateIsNotKeepItFriendlyListSubject.next(null);

        this._matchFilterFromDate = null;
        this._matchFilterToDate = null;

        // default next 2 days
        this.matchFilterFromDate = new Date();
        this.matchFilterToDate = new Date();
        this.matchFilterToDate.setDate(this.matchFilterFromDate.getDate() + 2);
        this.matchFilterToDate.setHours(23, 59, 59, 999);
        this._selectedMatchFilterOption = null;
        this._selectedMatchTimeFilterOption = null;

        this.tenantTournamentsFiltered = null;
        this._selectedMatchTournamentsFilterOption = null;
        this._selectedMatches = null;

        this._selectedCustomLeague = null;
        this._selectedPredefinedLeagueId = null;
        this._selectedStakeValue = 0;
        this._selectedGameName = null;

        this._createdGame = null;

        this._challengedUser = null;

        this.apiGetRequestSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.apiGetRequestSubscriptions = [];
    }

    // gets the wallet balance of the current user
    public getCurrentUserBalance(): number {
        let userBalance = 0;
        if (this.authenticationService.currentUser) {
            userBalance = this.authenticationService.currentUser.wallets[0].balance;
        }
        return userBalance;
    }

    public showNotEnoughUserBalanceNotification() {
        this.translateService.get(['GENERAL.LABELS.alert', 'CREATESNAP.insufficient_amount'])
            .pipe(take(1)).subscribe(
            translation => {
                this.myNotificationsService.createNotificationToast(translation['GENERAL.LABELS.alert'], translation['CREATESNAP.insufficient_amount'], NotificationType.Alert);
            });
    }
}
