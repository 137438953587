<div class="ui fluid card game-card" [class.isWidget]="isWidget || isLandingpageWidget" [class.game-closed]="game.game_state === 7 || game.game_state === 99">
    <div class="content top container font-bold">
        <div class="ui list horizontal">
            <div class="left aligned item">
                <i [ngClass]="'display-inline-block vertical-align-middle icon bsi bsi-sport-' + game.sport_id + ' bsi-bold'">
                </i><span class="display-inline-block vertical-align-middle">
                    {{(game.competition_type === 1 || game.competition_type === 3) ? ('GENERAL.LABELS.h2h' | translate) : ('GENERAL.LABELS.tournament' | translate)}}
                </span><span class="ui tiny basic label line-height-standard margin-0 margin-l-10 display-inline-block vertical-align-middle"
                    *ngIf="currentUser && game.is_current_user_joined && !isWidget && !isLandingpageWidget">
                    <i class="fa fa-check-circle-o small icon margin-0"></i> {{'GENERAL.LABELS.joined' | translate | uppercase}}
                </span><span class="ui tiny basic line-height-standard label margin-0 margin-l-10 display-inline-block vertical-align-middle"
                    *ngIf="!game.is_current_user_joined && !isWidget && !isLandingpageWidget">
                    {{'GENERAL.LABELS.not-joined' | translate | uppercase}}
                </span>
            </div>
            <div class="right aligned item">
                <span *ngIf="game.game_state !== 99">
                    <span *ngIf="(secondsToStart > 7200 || secondsToStart < 0) && game.game_state < 3">
                        {{game.start_date | amLocal | amCalendar:
                        {
                                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                lastDay: tenantService.tenantData.internationalization.date_time_format,
                                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                sameElse: tenantService.tenantData.internationalization.date_time_format
                        }
                        }}
                    </span>
                    <span *ngIf="secondsToStart <= 7200 && secondsToStart > 0 && game.game_state < 3">
                        <betsnaps-countdown #gameStartCountdown
                                            [timeInSeconds]="secondsToStart"
                                            [autoStart]="true"
                                            [secondsForHighlight]="secondsToStart"
                                            [hideAfterFinish]="true"
                                            (onCountdownFinished)="game.game_state = 3"></betsnaps-countdown>
                        <span *ngIf="gameStartCountdown?.hasFinished()" class="font-very-bold">
                            {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                        </span>
                    </span>
                    <span *ngIf="game.game_state === 3" class="font-very-bold">
                      {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                    </span>
                    <span *ngIf="game.game_state === 4 || game.game_state === 5 || game.game_state === 6"
                          class="line-height-standard">
                        <strong class="font-very-bold">
                            {{'GENERAL.GAMES.STATI.finished' | translate | uppercase}}
                        </strong>
                        <br/>{{game.start_date | amLocal | amDateFormat:tenantService.tenantData.internationalization.date_time_format}}
                    </span>
                    <span *ngIf="game.game_state === 7" class="line-height-standard">
                        <strong class="font-very-bold">
                            {{'GENERAL.GAMES.STATI.closed' | translate | uppercase}}
                        </strong>
                        <br/>{{game.start_date | amLocal | amDateFormat:tenantService.tenantData.internationalization.date_time_format}}
                    </span>
                </span>
                <span *ngIf="game.game_state === 99" class="line-height-standard">
                    <strong class="font-very-bold">{{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}</strong>
                    <br/>{{game.start_date | amLocal | amDateFormat:tenantService.tenantData.internationalization.date_time_format}}
                </span>
            </div>
        </div>
    </div>
    <div [ngClass]="'content main ' + (backgroundImage ? 'withBgImage' : '')"
         *ngVar="GameHelpers.getAdvancedButtonType(game) as advancedButtonType"
         (click)="navigateToDetail(game.game_unique_id, advancedButtonType);switchSnap();">
        <div [ngClass]="{'withBgImage-inner': backgroundImage}"
             [ngStyle]="{'background-image': (backgroundImage) ? backgroundImage : 'none'}">
            <div class="title text container center aligned"
                 [class.user-joined]="currentUser && game.is_current_user_joined">
                <div class="game-description">
                    <h2><betsnaps-game-name [game]="game"></betsnaps-game-name></h2>
                </div>
            </div>
            <div class="ui tight grid margin-0" *ngIf="game.competition_type === 3">
                <div class="row middle aligned centered h2hrow padding-0 margin-b-5">
                    <div class="seven wide column right aligned">
                        <betsnaps-user-avatar-loader
                            *ngIf="game.is_current_user_joined && game?.game_state >= 3 && game?.game_user_group_competitors && game?.game_user_group_competitors[0]"
                            [user]="game?.game_user_group_competitors[0]" [clickAble]="false"></betsnaps-user-avatar-loader>
                        <betsnaps-user-avatar-loader
                            *ngIf="game.is_current_user_joined && currentUser && (game?.game_state < 3 || !game.game_user_group_competitors)"
                            [user]="currentUser" [clickAble]="false"></betsnaps-user-avatar-loader>
                        <i class="help icon text-primary margin-0"
                           *ngIf="!(currentUser && game.is_current_user_joined)"></i>
                    </div>
                    <div class="two wide column center aligned">
                        <strong class="text-huge font-very-bold" [class.color-white]="backgroundImage">
                            {{'GENERAL.GAMES.h2h_vs' | translate}}
                        </strong>
                    </div>
                    <div class="seven wide column left aligned">
                        <betsnaps-user-avatar-loader
                            *ngIf="game.is_current_user_joined && game?.game_state >= 3 && game?.game_user_group_competitors && game?.game_user_group_competitors[1]"
                            [user]="game?.game_user_group_competitors[1]" [clickAble]="false"></betsnaps-user-avatar-loader>
                        <i class="help icon text-primary margin-0"
                           *ngIf="!(currentUser && game.is_current_user_joined) || (game.is_current_user_joined && (game?.game_state < 3 || !game?.game_user_group_competitors))"></i>
                    </div>
                </div>
            </div>
            <div class="ui tight grid margin-0" *ngIf="game.competition_type === 1">
                <div class="row middle aligned centered h2hrow padding-0 margin-b-5">
                    <div class="seven wide column right aligned">
                        <betsnaps-user-avatar-loader *ngIf="game?.h2h_competitors[0]" [user]="game?.h2h_competitors[0]"
                                              [clickAble]="false"></betsnaps-user-avatar-loader>
                        <i class="help icon text-primary margin-0" *ngIf="!game?.h2h_competitors[0]"></i>
                    </div>
                    <div class="two wide column center aligned">
                        <strong class="text-huge font-very-bold" [class.color-white]="backgroundImage">
                            {{'GENERAL.GAMES.h2h_vs' | translate}}
                        </strong>
                    </div>
                    <div class="seven wide column left aligned">
                        <betsnaps-user-avatar-loader *ngIf="game?.h2h_competitors[1]" [user]="game?.h2h_competitors[1]"
                                              [clickAble]="false"></betsnaps-user-avatar-loader>
                        <i class="help icon text-primary margin-0" *ngIf="!game?.h2h_competitors[1]"></i>
                    </div>
                </div>
            </div>
            <div class="ui grid margin-0" *ngIf="!backgroundImage">
                <div [ngClass]="(showGameSpotLimit() ? 'padding-0' : 'padding-t-0') + ' two column middle aligned centered row color-grey'"
                     *ngIf="game.competition_type === 2">
                    <div class="right aligned column" *ngIf="!game.is_current_user_joined || game.game_state < 3 || game.game_state === 99">
                        <i class="people icon big"></i>
                        <strong class="display-inline-block vertical-align-middle">
                            {{game.users_count}}
                        </strong><span class="display-inline-block vertical-align-middle"
                                       *ngIf="game.tournaments_type_id != 3">
                            /{{game.size}}
                        </span>
                    </div>
                    <div class="right aligned column" *ngIf="game.is_current_user_joined && game.game_state > 2 && game.game_state != 99">
                        <i class="fa fa-trophy icon"></i>
                        <span *ngIf="game.game_user.rank > 0"><strong class="display-inline-block vertical-align-middle">
                            {{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span>
                        </strong><strong class="display-inline-block vertical-align-middle"
                                       *ngIf="game.tournaments_type_id != 3">
                            &nbsp;{{'GENERAL.GAMES.rank' | translate}}
                        </strong></span>
                        <span *ngIf="game.game_user.rank === 0"><strong class="display-inline-block vertical-align-middle">
                            {{'GENERAL.GAMES.no_rank' | translate}}
                        </strong></span>
                    </div>
                    <div class="left aligned column">
                        <i [ngClass]="'icon bsi bsi-sport-' + game.sport_id + ' bsi-bold'"></i>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.game_state !== 3 && game.sport_id !== 999">
                            {{game.matches_count}} {{game.matches_count | i18nPlural:translations.labelTranslations.matchplural}}
                        </span>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.game_state === 3 && game.sport_id !== 999">
                            {{game.open_matches_count}} {{game.open_matches_count | i18nPlural:translations.labelTranslations.matchplural}} {{'GENERAL.GAMES.open_games_suffix' | translate}}
                        </span>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.game_state !== 3 && game.sport_id === 999">
                            {{game.vendor_games_count}} {{game.vendor_games_count | i18nPlural:translations.labelTranslations.vendorgameplural}}
                        </span>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.game_state === 3 && game.sport_id === 999">
                            {{game.open_vendor_games_count}} {{game.open_vendor_games_count | i18nPlural:translations.labelTranslations.vendorgameplural}}
                        </span>
                    </div>
                </div>
                <div class="one column middle aligned centered row padding-t-0 game-spots-left-row"
                     *ngIf="showGameSpotLimit()">
                    <div class="center aligned column game-spots-left">
                        {{(game.size - game.users_count)}} {{(game.size - game.users_count) | i18nPlural:translations.labelTranslations.spotsleftplural}}
                    </div>
                </div>
                <div class="ui one column row center aligned padding-t-0 color-grey"
                     *ngIf="game.competition_type === 1 || game.competition_type === 3">
                    <div class="center aligned column">
                        <i [ngClass]="'icon bsi bsi-sport-' + game.sport_id + ' bsi-bold'"></i>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.sport_id !== 999">
                            {{game.matches_count}} {{game.matches_count | i18nPlural:translations.labelTranslations.matchplural}}
                        </span>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.sport_id === 999">
                            {{game.vendor_games_count}} {{game.vendor_games_count | i18nPlural:translations.labelTranslations.vendorgameplural}}
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="isSwitcher" class="center aligned">
                <button type="button"
                        class="ui button margin-l-0 margin-r-0 margin-t-0 margin-b-10"
                        (click)="switchSnap();">
                    <strong>{{'GENERAL.GAMES.switch_to_snap_btn' | translate | uppercase}}</strong>
                </button>
            </div>
            <div *ngIf="(!currentUser || !game.is_current_user_joined) && !isSwitcher" class="game-card-buttons">
                <div [ngClass]="'ui ' + (showPrizes ? 'three' : 'one') + ' column tight grid middle aligned centered'">
                    <div *ngIf="showPrizes" class="eight-wide-low-mobile six wide mobile column">
                        <div
                            [ngClass]="'ui currency fluid ' + ((game.game_state === 7 || game.game_state === 99) ? 'grey' : 'light-grey') + ' label margin-0'">
                            <span class="currency-wrap">
                                <span class="currency-symbol singlebig"
                                      [class.longsymbol]="game.currency.symbol.length > 1">
                                    {{game.currency.symbol}}
                                </span>
                            </span>
                            <span class="detail">
                                <span class="detail-top-text" [class.single-line]="game.entry_fee === 0">
                                    {{(game.entry_fee !== 0) ? (game.entry_fee | decimalPrize: 2) : ('GENERAL.GAMES.entry_fee_free' | translate | uppercase)}}
                                </span>
                                <span class="detail-bottom-text" *ngIf="game.entry_fee !== 0">
                                    {{'GENERAL.GAMES.entry_fee' | translate | uppercase}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <ng-container *ngIf="showPrizes && GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                        <div class="eight-wide-low-mobile six wide mobile column">
                            <div [ngClass]="'ui currency fluid ' + ((game.game_state === 7 || game.game_state === 99) ? 'grey' : 'light-grey') + ' label margin-0'">
                                <span class="currency-wrap">
                                    <span *ngIf="game.primary_cash_prize_structure_index !== null" class="currency-symbol singlebig"
                                          [class.longsymbol]="game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol.length > 1">
                                        {{game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                                    </span>
                                </span>
                                <span class="detail">
                                    <span class="detail-top-text">
                                        <span *ngFor="let prizeStructure of GameHelpers.getGamePrizeStructuresWithPrizeTitleInGame(game.prize_structures); let i = index;">
                                            <ng-container *ngIf="i !== 0"><br/>+ </ng-container>{{prizeStructure.prize_title_in_game | dbtranslation:'prize_title_in_game':prizeStructure.translations}}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="showPrizes && !GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                        <div *ngIf="(game.is_gift_game === 0 && game.competition_type === 2)" class="eight-wide-low-mobile six wide mobile column">
                            <div
                                [ngClass]="'ui currency fluid ' + ((game.game_state === 7 || game.game_state === 99) ? 'grey' : 'light-grey') + ' label margin-0'">
                                <span class="currency-wrap">
                                    <span *ngIf="game.primary_cash_prize_structure_index !== null" class="currency-symbol singlebig"
                                          [class.longsymbol]="game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol.length > 1">
                                        {{game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                                    </span>
                                </span>
                                <span class="detail" *ngIf="game.tournaments_type_id !== 3">
                                    <span class="detail-top-text">
                                        {{game.prize_pool | decimalPrize: 2}}
                                    </span>
                                    <span class="detail-bottom-text truncate">
                                        {{'GENERAL.GAMES.potsize' | translate | uppercase}}
                                    </span>
                                </span>
                                <span class="detail" *ngIf="game.tournaments_type_id === 3">
                                    <span class="detail-top-text">
                                        {{game.current_prize_pool | decimalPrize: 2}}
                                    </span>
                                    <span class="detail-bottom-text truncate">
                                        {{'GENERAL.GAMES.potsize' | translate | uppercase}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="(game.is_gift_game === 0 && game.competition_type === 1)" class="eight-wide-low-mobile six wide mobile column">
                            <div
                                [ngClass]="'ui currency fluid ' + ((game.game_state === 7 || game.game_state === 99) ? 'grey' : 'light-grey') + ' label margin-0'">
                                <span class="currency-wrap">
                                    <span *ngIf="game.primary_cash_prize_structure_index !== null" class="currency-symbol singlebig"
                                          [class.longsymbol]="game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol.length > 1">
                                        {{game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                                    </span>
                                </span>
                                <span class="detail">
                                    <span class="detail-top-text">
                                        {{game.prize_pool | decimalPrize: 2}}
                                    </span>
                                    <span class="detail-bottom-text truncate">
                                        {{'GENERAL.GAMES.h2h_win' | translate | uppercase}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="(game.is_gift_game === 0 && game.competition_type === 3)" class="eight-wide-low-mobile six wide mobile column">
                            <div
                                [ngClass]="'ui currency fluid ' + ((game.game_state === 7 || game.game_state === 99) ? 'grey' : 'light-grey') + ' label margin-0'">
                                <span class="currency-wrap">
                                    <span *ngIf="game.primary_cash_prize_structure_index !== null" class="currency-symbol singlebig"
                                          [class.longsymbol]="game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol.length > 1">
                                        {{game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                                    </span>
                                </span>
                                <span class="detail">
                                    <span class="detail-top-text">
                                        {{game.h2h_prize_pool | decimalPrize: 2}}
                                    </span>
                                    <span class="detail-bottom-text">
                                        {{'GENERAL.GAMES.h2h_win' | translate | uppercase}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="(game.is_gift_game === 1)" class="eight-wide-low-mobile six wide mobile column">
                            <div
                                [ngClass]="'ui currency fluid ' + ((game.game_state === 7 || game.game_state === 99) ? 'grey' : 'light-grey') + ' label margin-0'">
                                <span class="detail">
                                    <span class="detail-top-text">
                                        {{'GENERAL.GAMES.giftprizes' | translate | uppercase}}
                                    </span>
                                    <span class="detail-bottom-text">
                                        {{'GENERAL.GAMES.prizes' | translate | uppercase}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <div [ngClass]="'sixteen-wide-low-mobile ' + (showPrizes ? 'four' : 'sixteen') + ' wide mobile column'">
                        <button type="button"
                                class="ui fluid large button game-card-button game-card-button-details"
                                title="{{'GENERAL.GAMES.details_btn' | translate | uppercase}}">
                            <span class="nowrap"><strong>{{'GENERAL.GAMES.details_btn' | translate | uppercase}}</strong></span>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="(currentUser && game.is_current_user_joined) && !isSwitcher"
                 class="game-card-buttons">
                <div class="ui one column tight grid middle aligned centered">
                    <div *ngIf="game.game_state < 4" class="column sixteen wide">
                        <button type="button"
                                *ngVar="GameHelpers.getAdvancedButtonTitleLabel(advancedButtonType) as advancedButtonTitleLabel"
                                class="ui fluid large button game-card-button" [class.padding-5]="advancedButtonType !== 'actionCasinoSnap'" [class.padding-10]="advancedButtonType === 'actionCasinoSnap'">
                            <strong>{{advancedButtonTitleLabel | translate | uppercase}}</strong>
                            <span class="small text display-block" *ngIf="(advancedButtonType === 'actionDefault' || advancedButtonType === 'actionPlaceBet')">{{'GENERAL.GAMES.POINTS.free_points' | translate }}: {{game.game_user.user_game_points | number:'1.2-2'}}</span>
                            <span class="small text display-block" *ngIf="advancedButtonType === 'actionCashout'">{{'GENERAL.GAMES.open_bets' | translate }}: {{game.current_user_open_bet_count}}</span>
                        </button>
                    </div>
                    <div *ngIf="game.game_state > 3" class="column sixteen wide">
                        <button type="button"
                                *ngIf="advancedButtonType === 'pastSnap'"
                                class="ui fluid large button game-card-button padding-5"
                                [class.black]="game.game_state === 7 || game.game_state === 99">
                            <strong>{{'GENERAL.GAMES.not_in_prize_ranks' | translate | uppercase}}</strong>
                            <span class="small text display-block">{{'GENERAL.GAMES.better_luck_next_time' | translate }}</span>
                        </button>
                        <button type="button"
                                *ngIf="advancedButtonType === 'pastSnapCancelled'"
                                class="ui fluid large black button game-card-button padding-5">
                            <strong>{{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}</strong>
                            <span class="small text display-block">{{'GENERAL.GAMES.entry_fee_refunded' | translate }}</span>
                        </button>
                        <button type="button"
                            *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 0"
                            class="ui fluid large button game-card-button padding-5"
                            [class.black]="game.game_state === 7 || game.game_state === 99">
                            <span class="nowrap" *ngIf="game.game_user && game.prize_structures && game.primary_cash_prize_structure_index !== null"><strong>{{game.game_user.prize_amount | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}</strong></span>
                            <span class="text-small display-block" *ngIf="game.primary_cash_prize_structure_index !== null"><span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true">{{'GENERAL.LABELS.bet_bonus' | translate | uppercase}}&nbsp;</span>{{'GENERAL.LABELS.won' | translate | uppercase}}</span>
                        </button>
                        <button type="button"
                            *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 1"
                            class="ui fluid large button game-card-button padding-5"
                            [class.black]="game.game_state === 7 || game.game_state === 99">
                            <strong *ngIf="getLeaderBoardUserProperty(game, 'tenant_prize')">{{getLeaderBoardUserProperty(game, 'tenant_prize')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize')?.translations}}</strong>
                            <strong *ngIf="!getLeaderBoardUserProperty(game, 'tenant_prize') && getLeaderBoardUserProperty(game, 'tenant_prize_template')">{{getLeaderBoardUserProperty(game, 'tenant_prize_template')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize_template')?.translations}}</strong>
                            <span class="text-small display-block">{{'GENERAL.LABELS.won' | translate | uppercase}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ui grid margin-0" *ngIf="backgroundImage">
                <div [ngClass]="'two column middle aligned centered row bg-dimmed color-white ' + (showGameSpotLimit() ? 'padding-b-0' : '')"
                     *ngIf="game.competition_type === 2">
                    <div class="right aligned column" *ngIf="!game.is_current_user_joined || game.game_state < 3 || game.game_state === 99">
                        <i class="people icon big white"></i><strong class="display-inline-block vertical-align-middle">
                            {{game.users_count}}
                        </strong><span class="display-inline-block vertical-align-middle"
                                   *ngIf="game.tournaments_type_id != 3">
                            /{{game.size}}
                        </span>
                    </div>
                    <div class="right aligned column" *ngIf="game.is_current_user_joined && game.game_state > 2 && game.game_state != 99">
                        <i class="fa fa-trophy icon white"></i>
                        <span *ngIf="game.game_user.rank > 0">
                            <strong class="display-inline-block vertical-align-middle">
                                {{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span>
                            </strong>
                            <span class="display-inline-block vertical-align-middle"
                                       *ngIf="game.tournaments_type_id != 3">
                                &nbsp;{{'GENERAL.GAMES.rank' | translate}}
                            </span>
                        </span>
                        <span *ngIf="game.game_user.rank === 0">
                            <strong class="display-inline-block vertical-align-middle">
                                {{'GENERAL.GAMES.no_rank' | translate}}
                            </strong>
                        </span>
                    </div>
                    <div class="left aligned column">
                        <i [ngClass]="'icon bsi bsi-sport-' + game.sport_id + ' bsi-bold color-white'"></i>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.sport_id !== 999">
                            {{game.matches_count}} {{game.matches_count | i18nPlural:translations.labelTranslations.matchplural}}
                        </span>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.sport_id === 999">
                            {{game.vendor_games_count}} {{game.vendor_games_count | i18nPlural:translations.labelTranslations.vendorgameplural}}
                        </span>
                    </div>
                </div>
                <div class="ui one column row center aligned bg-dimmed game-spots-left-row"
                     *ngIf="showGameSpotLimit()">
                    <div class="center aligned column">
                        <span class="ui black label countdown-label margin-0 game-spots-left">
                            {{(game.size - game.users_count)}} {{(game.size - game.users_count) | i18nPlural:translations.labelTranslations.spotsleftplural}}
                        </span>
                    </div>
                </div>
                <div class="ui one column row center aligned bg-dimmed color-white"
                     *ngIf="game.competition_type === 1 || game.competition_type === 3">
                    <div class="center aligned column">
                        <i [ngClass]="'small icon bsi bsi-sport-' + game.sport_id + ' bsi-bold color-white'"></i>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.sport_id !== 999">
                            {{game.matches_count}} {{game.matches_count | i18nPlural:translations.labelTranslations.matchplural}}
                        </span>
                        <span class="display-inline-block vertical-align-middle" *ngIf="game.sport_id === 999">
                            {{game.vendor_games_count}} {{game.vendor_games_count | i18nPlural:translations.labelTranslations.vendorgameplural}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isSwitcher && tenantService.tenantData.configuration.show_createbetsnaps" class="content extra container">
        <div class="ui grid middle aligned margin-l-0 margin-r-0">
            <div [ngClass]="((!isWidget && !isLandingpageWidget) ? 'seven' : 'thirteen') + ' wide column nowrap left aligned'">
                <div *ngIf="game?.game_creator" class="inline">
                    <betsnaps-user-avatar-loader class="display-inline-block vertical-align-middle"
                                          [user]="game.game_creator"
                                          [imageSize]="'mini'">
                    </betsnaps-user-avatar-loader>
                    <div class="display-inline-block vertical-align-middle creator">
                        <div class="text-mini">{{'GENERAL.GAMES.created_by' | translate}}:</div>
                        <div class="truncate">{{game.game_creator.username}}</div>
                    </div>
                </div>
                <div *ngIf="!game?.game_creator">
                    <img
                        class="ui middle aligned mini circular light bordered bg-grey image display-inline-block vertical-align-middle tenant-avatar"
                        [src]="(tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO_SQUARE)) ? tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO_SQUARE).media_url : './assets/img/betsnap_logo_mobile.png'"
                        alt="{{tenantData?.name}} Logo"/>
                    <div class="creator">
                        <div class="mini">{{'GENERAL.GAMES.created_by' | translate}}:</div>
                        <div class="truncate">{{tenantData?.name}}</div>
                    </div>
                </div>
            </div>
            <div class="six wide column nowrap center aligned" *ngIf="!isWidget && !isLandingpageWidget">
                <a *ngIf="game.current_user_friends && game.current_user_friends.length > 0"
                   fuiPopup
                   [popupTemplate]="popupUserFriendsJoinedTemplate"
                   popupPlacement="bottom"
                   popupTrigger="outsideClick"
                   #popup="fuiPopup"
                   (click)="googleAnalyticsService.trackEvent('gamecard - footer', 'show', 'joined friends');">
                    <img
                        *ngIf="game.current_user_friends[game.current_user_friends.length - 1].friend_user.media_url_small"
                        class="ui middle aligned mini circular light bordered primary image margin-r-5"
                        [src]="game.current_user_friends[game.current_user_friends.length - 1].friend_user.media_url_small"
                    /><span>{{game.current_user_friends.length}} {{game.current_user_friends.length | i18nPlural:translations.labelTranslations.friendplural}}</span>
                </a>
                <ng-template let-popup #popupUserFriendsJoinedTemplate>
                    <div class="content center aligned friends-popup">
                        <h5>{{game.current_user_friends.length}} {{game.current_user_friends.length | i18nPlural:translations.labelTranslations.joinedfriendplural}}</h5>
                        <div class="ui three column centered tight grid">
                            <div *ngFor="let gameUserFriend of game.current_user_friends" class="column">
                                <div class="ui center aligned column">
                                    <betsnaps-user-avatar-loader [user]="gameUserFriend.friend_user"
                                                          [imageSize]="'tiny'"
                                                          [closePopup]="popup">
                                    </betsnaps-user-avatar-loader>
                                    <span class="truncate">{{gameUserFriend.friend_user.username}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="three wide column right aligned">
                <a href="#" (click)="$event.preventDefault(); openGameShareModal();" *ngIf="game.game_state < 3">
                    <i class="share alternat big grey icon"></i>
                    <span class="mobile hidden">{{'GENERAL.GAMES.share' | translate}}</span>
                </a>
            </div>
        </div>
    </div>
</div>

<betsnaps-game-share-modal-default
    #gameShareModalDefaultComponent
    [game]="game"
    [gameShareData]="gameShareData"
    [sentGameInvitations]="sentGameInvitations"
></betsnaps-game-share-modal-default>
