import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService,
    MyModalService, MyNotificationsService, MyTranslateService,
    OneSignalService,
    TenantService
} from '../../../../../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as UserBetHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse as UserBetPlaceBetReportHttpResponse
} from '../../../../../api';
import {takeWhile} from 'rxjs/operators';
import {ABetsnapdetailBetsComponent} from '../a-betsnapdetail-bets-component';

@Component({
    selector: 'betsnaps-betsnapdetail-bets-v3',
    templateUrl: './betsnapdetail-bets-v3.component.html',
    styles: []
})
export class BetsnapdetailBetsV3Component extends ABetsnapdetailBetsComponent implements OnInit {
    @HostBinding('class') componentClass = 'betsnapdetail-content';

    @ViewChild('placedBetsSection') placedBetsSection: ElementRef;
    @ViewChild('widgetBetsSection') widgetBetsSection: ElementRef;

    constructor(public authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected betsnapdetailService: BetsnapdetailService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected myModalService: MyModalService,
                protected oneSignalService: OneSignalService,
                protected errorService: ErrorService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected adService: AdService,
                public translations: MyTranslateService) {
        super(
            authenticationService,
            tenantService,
            betsnapdetailService,
            googleAnalyticsService,
            myNotificationsService,
            translateService,
            myModalService,
            oneSignalService,
            errorService,
            adService
        );
    }

    ngOnInit() {

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {

                    if (gameHttpResponse) {
                        if (this.authenticationService.currentUser && gameHttpResponse.is_current_user_joined && !this.gameMatches) {
                            // load matches from api
                            this.betsnapdetailService.getGameMatches(gameHttpResponse.game_unique_id);
                        }

                        // remove widget bets if game started
                        if (gameHttpResponse.game_state > 2) {
                            this.removeWidgetBets();
                        }

                        if (!this.game) {
                            if ((!gameHttpResponse.is_current_user_joined || gameHttpResponse.sport_id === 999) && !this.betsnapdetailService.widgetBets) {
                                this.router.navigate(['/betsnapdetail/' + gameHttpResponse.game_unique_id + '/details']);
                                return;
                            } else {
                                if (this.betsnapdetailService.widgetBets) {
                                    this.widgetBets = this.betsnapdetailService.widgetBets;
                                    this.processBets = false;

                                    // do automatic placement if force parameter available
                                    if (localStorage.getItem('widgetBets-force') && localStorage.getItem('widgetBets-force') === 'true') {
                                        this.game = gameHttpResponse;
                                        this.initiatePlaceWidgetBets();
                                    }
                                }

                                if (this.authenticationService.currentUser && gameHttpResponse.is_current_user_joined) {
                                    // get user bets for current user
                                    this.betsnapdetailService.getUserBets(
                                        gameHttpResponse.game_unique_id,
                                        this.authenticationService.currentUser.id
                                    );
                                }
                            }
                        }

                        if (this.placeWidgetBetsAfterJoin && !this.game.is_current_user_joined && gameHttpResponse.is_current_user_joined) {
                            this.game = gameHttpResponse;
                            this.initiatePlaceWidgetBets();
                        }
                    }

                    this.game = gameHttpResponse;
                }
            );

        this.betsnapdetailService.userBets$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userBetsMatchGroup: UserBetHttpResponse[][]) => {
                    this.userBets = userBetsMatchGroup;
                    this.filterData();
                    this.processBets = false;
                }
            );

        this.betsnapdetailService.gameMatches$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameMatchListResults: GameMatchHttpResponse[]) => {
                    if (gameMatchListResults) {
                        this.gameMatches = gameMatchListResults;
                        this.filterData();
                    }
                }
            );

        if (this.oneSignalService.webPushAvailableForTenant) {
            this.showGameNotificationsCheckbox = true;
        }
    }

    filterData() {
        this.filteredBetsTotal = 0;
        if (this.gameMatches && this.userBets && Object.keys(this.userBets).length > 0) {
            this.filteredGameMatches = this.gameMatches.filter(
                (gameMatch: GameMatchHttpResponse) => {
                    if (this.userBets && this.userBets['match' + gameMatch.match_id]) {

                        // sort bets
                        this.userBets['match' + gameMatch.match_id].sort(function (bet1, bet2) {
                            if (bet1.user_bet_id > bet2.user_bet_id) {
                                return -1;
                            } else if (bet1.user_bet_id < bet2.user_bet_id) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });

                        const userBetsForMatch: UserBetHttpResponse[] = this.userBets['match' + gameMatch.match_id];
                        this.filteredBetsTotal += userBetsForMatch.length;
                        return (userBetsForMatch.length > 0);
                    } else {
                        return false;
                    }
                }
            );
        }
    }

    protected removeProcessedWidgetBetFromArray(userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) {
        this.removeItemFromWidgetBets(userBetPlaceBetReport);
    }

    protected removeWidgetBets(): void {
        if (this.widgetBets) {
            this.processWidgetBets = false;
            this.betsnapdetailService.widgetBets = null;
            if (localStorage.getItem('widgetBets-' + this.game.game_unique_id)) {
                localStorage.removeItem('widgetBets-' + this.game.game_unique_id);
            }
        }
    }

    scrollToNextSection(element: ElementRef) {
        element.nativeElement.scrollIntoView({behavior: 'smooth'});
    }
}
