/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameCreateGameRequest
 */
export interface AppHttpRequestsBetsnapsGamesGameCreateGameRequest { 
    /**
     * Game Name
     */
    game_name: string;
    /**
     * Competition Type
     */
    competition_type: number;
    /**
     * Tournaments Type Id
     */
    tournaments_type_id?: number;
    /**
     * BetSnap Start-Date
     */
    start_date?: Date;
    /**
     * BetSnap End-Date
     */
    end_date?: Date;
    /**
     * Maximum Size
     */
    maximum_size: number;
    /**
     * Minimum Size
     */
    minimum_size?: number;
    /**
     * Currency Id
     */
    currency_id?: number;
    /**
     * Entry Fee
     */
    entry_fee: number;
    /**
     * Rake
     */
    rake?: number;
    /**
     * Rake Share
     */
    rake_share?: number;
    /**
     * Tenant Prize Structure Template Id
     */
    tenant_prize_structure_template_id: number;
    /**
     * Publish Date
     */
    publish_date?: Date;
    /**
     * Is Public
     */
    is_public: boolean;
    /**
     * Is Flagged
     */
    is_flagged?: boolean;
    /**
     * Is Highlight
     */
    is_highlight?: boolean;
    /**
     * Auto Recreate
     */
    auto_recreate?: boolean;
    /**
     * Bet Placement Option (1=always, 2=only pre-game, 3=only pre-match, 4=only live)
     */
    bet_placement_option?: number;
    /**
     * Cashout Option (1=always, 2=only pre-game, 3=only pre-match, 4=only live, 5=never)
     */
    cashout_option?: number;
    /**
     * Use Initial Bet Outcome
     */
    use_initial_bet_outcome?: boolean;
    /**
     * Sport Id
     */
    sport_id: number;
    /**
     * Match Ids
     */
    match_ids: Array<number>;
}

