// Module
import {NgModule, LOCALE_ID, Injectable} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {ApiModule, Configuration, ConfigurationParameters} from './api';
import {UiModule} from './ui/ui.module';
import {
    RECAPTCHA_SETTINGS,
    RecaptchaSettings
} from 'ng-recaptcha';
import {getAuthServiceConfigs} from './shared/configs/social-login-config';

// SOCKET IO
import {io} from 'socket.io-client';

export function socketIoFactory() {
    return io;
}

// Components
import {AppComponent} from './app.component';

// Constants
import {environment} from '../environments/environment';
import {SOCKET_IO, WEB_SOCKET_URL} from './app.tokens';

const recaptchaSettings: RecaptchaSettings = {
    siteKey: environment.GOOGLE_RECAPTCHA_PUBLIC,
    badge: 'inline',
    theme: 'dark'
};

// Shared
import {
    MyHttpRequestInterceptorService,
    TenantService,
    MyTranslateLoaderService,
    DebugService
} from './shared';
import {registerLocaleData} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared.module';

registerLocaleData(localeDE, 'de');

// custom configuration Hammerjs
@Injectable()
export class CustomHammerConfig extends HammerGestureConfig  {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false }
    };
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: MyTranslateHttpLoaderFactory,
                deps: [TenantService, HttpClient, DebugService]
            }
        }),
        ApiModule.forRoot(apiConfigFactory),

        UiModule,
        SharedModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: SOCKET_IO, useFactory: socketIoFactory},
        {provide: WEB_SOCKET_URL, useValue: environment.WEB_SOCKET_URL},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpRequestInterceptorService,
            multi: true
        },
        {provide: RECAPTCHA_SETTINGS, useValue: recaptchaSettings},
        {provide: 'SocialAuthServiceConfig', useFactory: getAuthServiceConfigs, deps: [TenantService]},
        {provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function apiConfigFactory(): Configuration {
    const params: ConfigurationParameters = {
        apiKeys: {'Authorization': ''},
        basePath: environment.APIURL
    };
    return new Configuration(params);
}

export function MyTranslateHttpLoaderFactory(tenantService: TenantService, httpClient: HttpClient, debugService: DebugService) {
    return new MyTranslateLoaderService(tenantService, httpClient, debugService);
}
