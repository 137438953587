/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesCurrencyHttpResponse';


/**
 * UserWalletHttpResponse
 */
export interface AppHttpResponsesUsersUserWalletHttpResponse { 
    /**
     * User Wallet Id
     */
    id: number;
    /**
     * User ID
     */
    user_id: number;
    /**
     * Currency ID
     */
    currency_id: number;
    currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
    /**
     * Balance
     */
    balance: number;
    /**
     * is the wallet an app wallet (0...no, 1...yes)
     */
    is_app_wallet: number;
}

