import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {WindowRef} from '../../../shared';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'betsnaps-go-to-top-button',
    templateUrl: './go-to-top-button.component.html',
    styles: [],
    animations: [
        trigger('appearInOut', [
            state('in', style({
                'opacity': '0.85'
            })),
            state('out', style({
                'opacity': '0'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class GoToTopButtonComponent implements OnInit {

    public animationState: string = 'out';
    private timerID = null;
    private currentScrollPosition: number = 0;

    @Input() scrollDistance: number = 200;
    @Input() animate: boolean = false;
    @Input() speed: number = 80;
    @Input() acceleration = 0;
    @Input() additionalClass: string;

    private nativeWindow: Window;

    constructor(@Inject(DOCUMENT) private document: any,
                private windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;
    }

    ngOnInit() {
    }

    public scrollTop(event) {
        event.preventDefault();
        if (this.animate) {
            this.animateScrollTop();
        } else {
            this.nativeWindow.window.scrollTo(0, 0);
        }
    }

    private animateScrollTop() {
        if (this.timerID !== null) {
            return;
        }
        let initialSpeed = this.speed;
        const that = this;
        this.timerID = setInterval((
            function () {
                that.nativeWindow.window.scrollBy(0, -initialSpeed);
                initialSpeed = initialSpeed + that.acceleration;
                if (that.currentScrollPosition === 0) {
                    clearInterval(that.timerID);
                    that.timerID = null;
                }
            }
        ), 15);
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    goTopListeners() {
        this.currentScrollPosition = Math.floor(
            this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
        );
        this.animationState = this.currentScrollPosition > this.scrollDistance ? 'in' : 'out';
    }
}
