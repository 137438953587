import {Component, OnInit} from '@angular/core';
import {WindowRef} from '../../../../shared';

@Component({
    selector: 'betsnaps-maintenance-fallback',
    templateUrl: './maintenance-fallback.component.html',
    styles: []
})
export class MaintenanceFallbackComponent implements OnInit {

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    private maintenanceRedirect: string;

    constructor(
        private windowRef: WindowRef
    ) {
        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }

        if (localStorage.getItem('maintenanceRedirect')) {
            this.maintenanceRedirect = localStorage.getItem('maintenanceRedirect');
        }
    }

    ngOnInit() {
    }

    reloadPage() {
        if (localStorage.getItem('maintenanceRedirect')) {
            localStorage.removeItem('maintenanceRedirect');
        }

        if (this.maintenanceRedirect) {
            this.nativeWindow.location.href = this.maintenanceRedirect;
        } else {
            this.nativeWindow.location.href = '/';
        }

        return;
    }

}
