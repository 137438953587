/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse } from './appHttpResponsesTenantsTenantLandingpageFeatureHttpResponse';


/**
 * TenantLandingpageFeatureListHttpResponse Object
 */
export interface AppHttpResponsesTenantsTenantLandingpageFeatureListHttpResponse { 
    /**
     * Tenant Langinpage Features
     */
    results?: Array<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>;
    /**
     * sum of ALL records in the Data-Source
     */
    total: number;
    /**
     * sum of returned records
     */
    count: number;
    /**
     * current page of paginated response
     */
    current_page: number;
    /**
     * last possible page to query with pagination
     */
    last_page: number;
    /**
     * is response paginated?
     */
    is_paginated: boolean;
}

