/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantFooterTranslationHttpResponse
 */
export interface AppHttpResponsesTenantsTenantFooterTranslationHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * ISO Code 2
     */
    iso_code2: string;
    /**
     * Label Field
     */
    label_field: string;
    /**
     * Label Value
     */
    label_value: string;
}

