import {Component, Inject, Input, OnInit} from '@angular/core';
import {GoogleAnalyticsService, MyModalService, TenantService, WindowRef} from '../../../shared';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'betsnaps-landingpage-login-register-buttons',
    templateUrl: './landingpage-login-register-buttons.component.html',
    styles: []
})
export class LandingpageLoginRegisterButtonsComponent {

    @Input() showRegisterButton: boolean = true;

    private nativeWindow: Window;

    constructor(@Inject(DOCUMENT) private document: any,
                private windowRef: WindowRef,
                public myModalService: MyModalService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public tenantService: TenantService) {
        this.nativeWindow = windowRef.nativeWindow;
    }

    goToB2bLogin(eventCategory: string = 'home') {
        this.googleAnalyticsService.trackEvent(eventCategory, 'login', 'b2b redirect');
        this.nativeWindow.location.href = this.tenantService.getB2bWebsiteLoginUrl();
        return false;
    }

    goToB2bRegister(eventCategory: string = 'home') {
        this.googleAnalyticsService.trackEvent(eventCategory, 'register', 'b2b redirect');
        this.nativeWindow.location.href = this.tenantService.getB2bWebsiteRegisterUrl();
        return false;
    }
}
