<div class="ui container">
    <div class="ui centered one column grid">
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">

            <div class="ui inverted transparent padded segment">

                <h1 class="ui center aligned header">
                    <i class="account circle icon"></i>
                    {{'RESETPASSWORD.heading' | translate}}
                </h1>

                <form class="ui inverted form" novalidate [formGroup]="resetForm"
                      [class.error]="!processForm || resetForm.invalid"
                      (ngSubmit)="resetPassword()">

                    <div class="ui field" formGroupName="passwordGroup">
                        <div class="field">
                            <div class="ui left input">
                                <input type="password"
                                       id="newpassword"
                                       name="newpassword"
                                       placeholder="{{'RESETPASSWORD.newpassword_field' | translate}}"
                                       formControlName="newpassword" required/>
                            </div>
                            <div
                                *ngIf="(resetForm.controls['passwordGroup'].get('newpassword').touched || resetForm.controls['passwordGroup'].get('newpassword').dirty) && resetForm.controls['passwordGroup'].get('newpassword').invalid"
                                class="ui pointing red basic label">
                                <p *ngIf="resetForm.controls['passwordGroup'].get('newpassword').hasError('required')">
                                    {{'RESETPASSWORD.ERRORS.newpassword_field_required' | translate}}
                                </p>
                                <p *ngIf="resetForm.controls['passwordGroup'].get('newpassword').hasError('minlength')">
                                    {{'RESETPASSWORD.ERRORS.newpassword_too_short' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui left input">
                                <input type="password"
                                       id="newpassword_confirm"
                                       name="newpassword_confirm"
                                       placeholder="{{'RESETPASSWORD.newpassword_confirm_field' | translate}}"
                                       formControlName="newpassword_confirm" required/>
                            </div>
                            <div
                                *ngIf="(resetForm.controls['passwordGroup'].get('newpassword_confirm').touched || resetForm.controls['passwordGroup'].get('newpassword_confirm').dirty) && resetForm.controls['passwordGroup'].get('newpassword_confirm').invalid"
                                class="ui pointing red basic label">
                                <p *ngIf="resetForm.controls['passwordGroup'].get('newpassword_confirm').hasError('required')">
                                    {{'RESETPASSWORD.ERRORS.newpassword_confirm_field_required' | translate}}
                                </p>
                                <p *ngIf="resetForm.controls['passwordGroup'].get('newpassword_confirm').hasError('minlength')">
                                    {{'RESETPASSWORD.ERRORS.newpassword_too_short' | translate}}
                                </p>
                            </div>

                            <div
                                *ngIf="resetForm.controls['passwordGroup'].get('newpassword').valid && resetForm.controls['passwordGroup'].get('newpassword_confirm').valid && resetForm.controls['passwordGroup'].hasError('areEqual')"
                                class="ui pointing red basic label">
                                <p>
                                    {{'RESETPASSWORD.ERRORS.newpasswords_equal' | translate}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="ui center aligned">
                        <button class="ui large button"
                                type="submit"
                                [class.loading]="processForm"
                                [disabled]="processForm || resetForm.invalid">
                            {{'RESETPASSWORD.reset_btn' | translate | uppercase}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
