import {AppHttpResponsesTenantsTenantPublicHttpResponse as TenantPublicHttpResponse} from '../../api';

export class PhoneHelpers {
    public phoneSignupDefaultCountryCode: string = '+43';

    public getPhoneSignupAllowedCountryCodes(tenantData: TenantPublicHttpResponse): string[] {
        let phoneSignupAllowedCountryCodes: string[] = [];
        if (tenantData.configuration.b2c_signup_method_phone_allowed_country_codes) {
            phoneSignupAllowedCountryCodes = tenantData.configuration.b2c_signup_method_phone_allowed_country_codes;
        } else {
            phoneSignupAllowedCountryCodes.push(this.phoneSignupDefaultCountryCode);
        }

        return phoneSignupAllowedCountryCodes;
    }
}


