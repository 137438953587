/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from './appHttpResponsesUsersPlayerPublicHttpResponse';


/**
 * ChatTimelineHttpResponse
 */
export interface AppHttpResponsesChatsChatTimelineHttpResponse { 
    /**
     * Type
     */
    type: string;
    /**
     * Id
     */
    id: number;
    /**
     * Chat Id
     */
    chat_id: number;
    user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    blocked_user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    /**
     * Message (max. 255 chars)
     */
    message?: string;
    /**
     * Is ghosted message
     */
    is_ghosted?: boolean;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
    /**
     * Deleted Date
     */
    deleted_at?: Date;
}

