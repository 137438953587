import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {
    AuthenticationService,
    MyModalService, TenantService
} from '../../../shared';
@Component({
    selector: 'betsnaps-register',
    template: '',
    styles: []
})
export class RegisterComponent implements OnInit {

    constructor(private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private router: Router,
                private myModalService: MyModalService) {
    }

    ngOnInit() {

        if (this.authenticationService.validToken()) {
            this.router.navigate(['arena']);
            return;
        } else if (!this.authenticationService.validToken() && this.tenantService.noB2cSignupMethod) {
            this.router.navigate(['/']);
            return;
        } else {
            this.router.navigate(['/']);
            this.myModalService.openLoginRegisterModal('register');
            return false;
        }

    }

}
