import {Component, HostBinding, ViewContainerRef} from '@angular/core';
import {GameVendorGameParticipationStatusEnum} from '../../../../shared/enums';
import {AdService, TenantService} from '../../../../shared';
import {AGameVendorGameParticipationCardComponent} from '../a-game-vendor-game-participation-card-component';
import {AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse as GameVendorGameParticipationTransactionHttpResponse} from '../../../../api';

@Component({
    selector: 'betsnaps-game-vendor-game-participation-card-v3',
    templateUrl: './game-vendor-game-participation-card-v3.component.html',
    styles: []
})
export class GameVendorGameParticipationCardV3Component extends AGameVendorGameParticipationCardComponent {
    @HostBinding('class') componentClass = 'ui fluid card game-vendor-game-card-v3';

    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;

    constructor(protected viewContainerRef: ViewContainerRef,
                public tenantService: TenantService,
                protected adService: AdService) {
        super(viewContainerRef, tenantService, adService);
    }

    gameVendorGameParticipationTransactionsTrackBy(index: number, gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse): number {
        return gameVendorGameParticipationTransaction.id;
    }

    calculateParticipationTransactionSaldo(gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) {
        return gameVendorGameParticipationTransaction.total_points - gameVendorGameParticipationTransaction.points;
    }

}
