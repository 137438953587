/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesCurrencyHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresPrizeTypeHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresPrizeCategoryHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * AdminGamePrizeStructureHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesAdminGamePrizeStructureHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Game Unique Id
     */
    game_unique_id: string;
    /**
     * Master Prize Categories Id
     */
    prize_category_id: number;
    /**
     * Master Prize Type Id
     */
    prize_type_id: number;
    /**
     * Master Currency Id
     */
    currency_id?: number;
    /**
     * Media Id
     */
    media_id?: number;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    /**
     * Title
     */
    title: string;
    /**
     * Title Game
     */
    title_in_game: string;
    /**
     * Prize Title in Game
     */
    prize_title_in_game?: string;
    /**
     * Tenant Prize Structure Template Id
     */
    tenant_prize_structure_template_id: number;
    /**
     * Is Bonus Money
     */
    is_bonus_money: boolean;
    prize_category?: AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryHttpResponse;
    prize_type?: AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeHttpResponse;
    currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

