<div id="placeBetDialog-{{outcome.market_id + '-' + outcome.outcome_id + '-' + outcome.specifier_val}}">
    <div class="ui middle aligned centered grid" *ngIf="!gameUsesPredictionPointsEngine">
        <div class="row">
            <div class="ten wide left aligned column">
                <div class="ui large fluid input v3"
                     [class.disabled]="placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1">
                    <input type="text" inputmode="decimal" id="betpoints" name="betpoints" #betPointsInput
                           (focus)="inputOnFocus()"
                           (blur)="inputOnBlur()"
                           [ngModel]="betPoints | number:'1.2-2'"
                           [formControl]="betPointsControl"
                           [autocomplete]="'off'"
                           required/>
                </div>
            </div>
            <div class="three wide center aligned column">
                <button
                    [class.disabled]="betPoints <= 0 || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
                    [disabled]="placeBetProcess"
                    type="button" class="ui basic primary fluid large icon button margin-0 padding-l-0 padding-r-0"
                    (click)="
                        googleAnalyticsService.trackEvent('matchcard - bet', 'click', '-100');
                        decreaseBetPoints(100);
                    ">
                    <i class="fa fa-minus icon"></i>
                </button>
            </div>
            <div class="three wide center aligned column">
                <button
                    [class.disabled]="freePoints === betPoints || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
                    [disabled]="placeBetProcess"
                    type="button" class="ui basic primary fluid large icon button margin-0 padding-l-0 padding-r-0"
                    (click)="
                        googleAnalyticsService.trackEvent('matchcard - bet', 'click', '+100');
                        increaseBetPoints(100);
                    ">
                    <i class="fa fa-plus icon"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="margin-t-10" *ngIf="!gameUsesPredictionPointsEngine">
        <button
            [class.disabled]="freePoints === betPoints || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
            [disabled]="placeBetProcess"
            type="button" class="ui basic primary fluid large button font-bold margin-0"
            (click)="
                googleAnalyticsService.trackEvent('matchcard - bet', 'click', 'ALL-IN');
                allIn();
            ">
            {{'GENERAL.BETS.all_in' | translate | uppercase}}
        </button>
    </div>
    <div class="ui divider" *ngIf="!gameUsesPredictionPointsEngine"></div>
    <div>
        <p class="center aligned color-negative"
           *ngIf="betPoints < 0.01">
            {{'GENERAL.BETS.ERRORS.invalid_bet_points' | translate}}
        </p>
        <button
            [class.disabled]="freePoints === 0 || betPoints < 0.01 || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
            [class.loading]="placeBetProcess" [disabled]="placeBetProcess"
            type="button" class="ui fluid tiny button line-height-standard padding-5 margin-0" (click)="placeBet()">
            <span class="text-large display-block" *ngIf="!gameUsesPredictionPointsEngine">{{'GENERAL.BETS.possible-win' | translate | uppercase}}: {{(betPoints * outcome.odd_decimal) | number:'1.2-2'}}</span>
            <strong class="display-block" [class.padding-10]="gameUsesPredictionPointsEngine">
                <span *ngIf="game.max_bet_count === 0">
                    {{'GENERAL.BETS.place-bet' | translate}}
                </span>
                <span *ngIf="game.max_bet_count > 0">
                    {{'GENERAL.BETS.place-bet-number-of-total' | translate:{
                        bet_number: ((game.current_user_bet_count + 1) | ordinalNumber),
                        max_bet_count: game.max_bet_count
                    } }}
                </span>
            </strong>
        </button>
        <div *ngIf="cashoutOddLimitViolation() && cashoutAvailable()" class="text-mini center aligned margin-t-5 color-negative">
            {{'GENERAL.BETS.cashout_odd_limit_error_notice' | translate}}
        </div>
        <div *ngIf="preGameStartOddsCanChange" class="text-mini color-grey center aligned margin-t-5">
            {{'GENERAL.BETS.pre_game_start_odds_can_change' | translate}}
        </div>
    </div>
</div>
