/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantCreateRequest
 */
export interface AppHttpRequestsTenantsTenantCreateRequest { 
    /**
     * Tenant Name
     */
    name: string;
    /**
     * Tenant Domain Name
     */
    domain_name: string;
    /**
     * Default Language ID
     */
    default_language_id: number;
    /**
     * Base Currency ID
     */
    base_currency_id: number;
    /**
     * Language IDs
     */
    language_ids: Array<number>;
    /**
     * Currency IDs
     */
    currency_ids: Array<number>;
    /**
     * Clone From Tenant ID
     */
    clone_from_tenant_id?: number;
    /**
     * Tenant Clone Option IDs
     */
    tenant_clone_option_ids?: Array<number>;
}

