import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef, Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {DynamicComponentLoaderService} from '../../../shared/services/dynamic-component-loader.service';
import {DynamicComponentHostDirective} from '../../../shared/directives/dynamic-component-host.directive';
import {AMainComponent} from './a-main.component';

@Component({
    selector: 'betsnaps-main-loader',
    template: `
        <ng-template dynamicComponentHost></ng-template>
    `
})
export class MainLoaderComponent implements OnInit, OnDestroy {

    private componentRef: ComponentRef<AMainComponent>;
    @ViewChild(DynamicComponentHostDirective, { static: true }) dynamicComponentHost: DynamicComponentHostDirective;

    @Input() isWidget: boolean;

    constructor(private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private dynamicComponentLoaderService: DynamicComponentLoaderService) {
    }

    ngOnInit() {

        const componentToLoad = this.dynamicComponentLoaderService.getComponent('main');

        if (componentToLoad) {
            const componentFactory: ComponentFactory<AMainComponent> = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);

            const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent<AMainComponent>(componentFactory);
            this.componentRef.instance.isWidget = this.isWidget;
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

}
