import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DebugService {

    public doDebug: boolean = false;

    constructor() {
        if (environment.DEBUG) {
            this.doDebug = true;
        }
    }

    public writeMessageToConsoleLog(msg: string | Object,  ...args: any[]) {
        if (this.doDebug) {
            if(args[0]){
                console.log(msg, args[0]);
            } else {
                console.log(msg);
            }
        }
    }

}
