<div class="ui middle aligned grid">
    <div class="row">
        <div [ngClass]="(shouldShowScore() ? getPeriodColumnClasses()[0] : 'sixteen') + ' wide column left aligned'">
            <div class="ui list team-names">
                <div class="item">
                    <span *ngIf="match.home" class="truncate">
                        {{match.home.name | dbtranslation:'name':match.home?.translations}}
                    </span>
                    <span *ngIf="!match.home" class="truncate">
                        {{match.home_name}}
                    </span>
                    <span class="red-card-indicator-wrap" *ngIf="match.home_statistics && (match.home_statistics.red_cards + match.home_statistics.yellow_red_cards) > 0">
                        <span class="red-card-indicator"></span>
                    </span>
                </div>
                <div class="item truncate">
                    <span *ngIf="match.away" class="truncate">
                        {{match.away.name | dbtranslation:'name':match.away?.translations}}
                    </span>
                    <span *ngIf="!match.away" class="truncate">
                        {{match.away_name}}
                    </span>
                    <span class="red-card-indicator-wrap" *ngIf="match.away_statistics && (match.away_statistics.red_cards + match.away_statistics.yellow_red_cards) > 0">
                        <span class="red-card-indicator"></span>
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="getPeriodColumnClasses()[1] + ' wide column right aligned padding-l-0'" *ngIf="shouldShowScore()">
            <div class="period-score-wrap">
                <div class="ui list match-score-list period-score center aligned"
                     *ngFor="let period of getMatchPeriods(); trackBy: periodTrackBy; let i = index; let isLast = last"
                     [class.last-period-score]="isLast">
                    <div class="item font-very-bold"
                         [class.color-light-grey]="periodIsFinished(i, match)">
                        {{period.home_score}}
                    </div>
                    <div class="item font-very-bold"
                         [class.color-light-grey]="periodIsFinished(i, match)">
                        {{period.away_score}}
                    </div>
                </div>
                <div class="ui list match-score-list total-score center aligned font-very-bold"
                           [class.text-primary]="match.status === 'live'">
                    <div class="item">
                        {{match.score.home_score}}
                    </div>
                    <div class="item">
                        {{match.score.away_score}}
                    </div>
                </div>
                <div class="display-inline-block vertical-align-middle center aligned padding-l-10" *ngIf="withScoreExtraInformation()">
                    <div class="text-mini">
                        ({{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}})
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
