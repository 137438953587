import {Router} from '@angular/router';

export class GeneralHelpers {
    public doLoginRedirectIfDefined(router: Router) {
        if (localStorage.getItem('loginRedirect')) {
            const loginRedirectUrl = localStorage.getItem('loginRedirect');
            localStorage.removeItem('loginRedirect');

            if (loginRedirectUrl.indexOf('?') === -1) {
                router.navigate([loginRedirectUrl]);
            } else {
                router.navigateByUrl(loginRedirectUrl);
            }
        }
    }
}
