/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataMatchScoreDetailHttpResponse } from './appHttpResponsesSportsDataMatchScoreDetailHttpResponse';
import { AppHttpResponsesSportsDataMatchStatusCodeHttpResponse } from './appHttpResponsesSportsDataMatchStatusCodeHttpResponse';


/**
 * MatchScoreHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchScoreHttpResponse { 
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Match Status
     */
    match_status?: string;
    /**
     * Home Id
     */
    home_id: number;
    /**
     * Away Id
     */
    away_id: number;
    /**
     * Winner Id
     */
    winner_id?: number;
    /**
     * Home Score
     */
    home_score: number;
    /**
     * Away Score
     */
    away_score: number;
    /**
     * Home Game Score (Tennis)
     */
    home_game_score?: number;
    /**
     * Away Game Score (Tennis)
     */
    away_game_score?: number;
    /**
     * Home Penalty Score (Ice Hockey)
     */
    home_penalty_score?: number;
    /**
     * Away Penalty Score (Ice Hockey)
     */
    away_penalty_score?: number;
    /**
     * Current Server (Tennis)
     */
    current_server?: number;
    /**
     * Is period in Tiebreak (Tennis)
     */
    tiebreak?: boolean;
    /**
     * Home Dismissals (Cricket)
     */
    home_dismissals?: number;
    /**
     * Away Dismissals (Cricket)
     */
    away_dismissals?: number;
    /**
     * Home Penalty Runs (Cricket)
     */
    home_penalty_runs?: number;
    /**
     * Home Penalty Runs (Cricket)
     */
    away_penalty_runs?: number;
    /**
     * Home Suspensions (Ice Hockey)
     */
    home_suspend?: number;
    /**
     * Away Suspensions (Ice Hockey)
     */
    away_suspend?: number;
    /**
     * Innings (Cricket)
     */
    innings?: number;
    /**
     * Over (Cricket)
     */
    over?: number;
    /**
     * Delivery (Cricket)
     */
    delivery?: number;
    /**
     * Current Match Time
     */
    matchtime?: string;
    /**
     * Current Stoppage Time
     */
    stoppagetime?: string;
    /**
     * Announced Stoppage Time
     */
    stoppagetimeannounced?: string;
    /**
     * Current Remaining Time
     */
    remaining_time?: string;
    /**
     * Current Period Remaining Time
     */
    remaining_time_in_period?: string;
    /**
     * Currently Stopped
     */
    stopped?: boolean;
    match_status_code?: AppHttpResponsesSportsDataMatchStatusCodeHttpResponse;
    /**
     * Periods
     */
    periods?: Array<AppHttpResponsesSportsDataMatchScoreDetailHttpResponse>;
}

