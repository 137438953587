/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MediaTypeHttpResponse
 */
export interface AppHttpResponsesMediaMediaTypeHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Type
     */
    type: string;
    /**
     * Description
     */
    description?: string;
    /**
     * Path
     */
    path?: string;
    /**
     * Pixel Width
     */
    pixel_width?: number;
    /**
     * Pixel Height
     */
    pixel_height?: number;
    /**
     * Max Filesize in KB
     */
    max_filesize_kb?: number;
    /**
     * Allowed File Extensions
     */
    allowed_file_extensions?: string;
    /**
     * Strict Dimensions Check
     */
    strict_dimensions_check: boolean;
}

