import { Component, OnDestroy, OnInit, Directive } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';

import {
    AppHttpResponsesGeneralLanguageHttpResponse as LanguageHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersUserSettingHttpResponse as UserSettingHttpResponse,
} from '../../../../api';
import {
    AuthenticationService,
    ProfileService,
    TenantService,
    WindowRef,
    ErrorService,
    OneSignalService,
    MyNotificationsService
} from '../../../../shared';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';

@Directive()
export abstract class AProfileSettingsComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    public currentUser: PlayerHttpResponse;
    public languages: LanguageHttpResponse[];

    public showSpinner: boolean = true;
    public processForm: boolean = false;
    public submitted: boolean = false;

    public settingsForm: UntypedFormGroup = new UntypedFormGroup({
        user_language_id: new UntypedFormControl('1', [Validators.required, Validators.pattern('[0-9]+')]),
        odd_display_format: new UntypedFormControl('value', [Validators.required, Validators.pattern('value|odds|fractional|moneyline')])
    });

    protected nativeWindow;
    public isWrapperWindow: boolean = false;

    public showPushNotificationsSetting: boolean = false;
    public showEmailNotificationsSetting: boolean = false;
    public showSmsNotificationsSetting: boolean = false;
    protected currentPushNotificationsSetting: boolean = true;

    protected constructor(
        protected authenticationService: AuthenticationService,
        public tenantService: TenantService,
        protected profileService: ProfileService,
        protected windowRef: WindowRef,
        protected myNotificationsService: MyNotificationsService,
        protected translateService: TranslateService,
        protected oneSignalService: OneSignalService,
        protected errorService: ErrorService
    ) {
        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }

        if (this.tenantService.tenantData.configuration.outgoing_notifications) {

            if (this.authenticationService.currentUser.email) {
                this.settingsForm.addControl('email_notifications', new UntypedFormControl('1'));
                this.showEmailNotificationsSetting = true;
            }

            if (this.authenticationService.currentUser.phone) {
                this.settingsForm.addControl('sms_notifications', new UntypedFormControl('1'));
                this.showSmsNotificationsSetting = true;
            }

            // push notifications settings
            if (this.isWrapperWindow || this.oneSignalService.webPushAvailableForTenant) {
                this.showPushNotificationsSetting = true;
                this.settingsForm.addControl('push_notifications', new UntypedFormControl('1'));
            }
        }
    }

    onSubmit() {
        this.processForm = true;

        this.profileService.updateUserSettings(
            this.settingsForm,
            this.isWrapperWindow
        ).pipe(take(1))
        .subscribe({
            next: (userSettings: UserSettingHttpResponse) => {

                const newUserInfo = this.currentUser;
                newUserInfo.settings = userSettings;
                this.authenticationService.setCurrentUser(newUserInfo);

                this.submitted = true;
                this.processForm = false;

                this.translateService.get(['PROFILE.SETTINGS.updated_successfully'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast('', translation['PROFILE.SETTINGS.updated_successfully'], NotificationType.Success);
                    });

                // show notification prompt if push settings changed
                if (this.oneSignalService.webPushAvailableForTenant &&
                    this.currentUser.settings.push_notifications &&
                    !this.currentPushNotificationsSetting) {
                    this.oneSignalService.subscribeForWebPushNotifications();
                }
                this.currentPushNotificationsSetting = this.currentUser.settings.push_notifications;

                this.nativeWindow.window.scrollTo(0, 0);
            },
            error: (err: HttpErrorResponse) => {
                this.processForm = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    ngOnInit() {
        this.currentUser = this.authenticationService.currentUser;
        if (this.currentUser) {
            this.settingsForm.patchValue(this.currentUser.settings);

            this.currentPushNotificationsSetting = this.currentUser.settings.push_notifications;

            if (!this.languages && this.tenantService.tenantLangs && this.tenantService.tenantLangs.length > 0) {

                this.languages = this.tenantService.tenantLangs;

                if (this.languages) {
                    const userLangExists = this.tenantService.tenantLangs.find(
                        (language: LanguageHttpResponse) => language.id === this.currentUser.settings.user_language_id);

                    if (this.currentUser.settings.user_language_id && userLangExists) {
                        // if user language exists in tenant languages
                        this.settingsForm.patchValue({'user_language_id': this.currentUser.settings.user_language_id});
                    } else if (this.languages[0]) {
                        // set first language if user language doesnt exist
                        this.settingsForm.patchValue({'user_language_id': this.languages[0].id});
                    }
                }
            }
            this.showSpinner = false;
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

}
