import {
    AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse
} from '../../api';

export interface PrizeStructureRankRange {
    fromRank: number;
    toRank: number;
    prize_amount: number;
    tenant_prize_template?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse;
    tenant_prize?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse;
}
