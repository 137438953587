import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {
    AuthenticationService,
    BetsnapdetailService,
    DebugService,
    ErrorService,
    FriendsService,
    GameLeaderboardViewEnum,
    GoogleAnalyticsService,
    HintService,
    LoggerService,
    MyModalService,
    MyTranslateService,
    TenantImageTypeEnum,
    TenantService,
    WindowRef
} from '../../../../../shared';
import {ABetsnapdetailRankingComponent} from '../a-betsnapdetail-ranking.component';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse as LeaderboardUserListHttpResponse
} from '../../../../../api';
import {take, takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-betsnapdetail-ranking-v3',
    templateUrl: './betsnapdetail-ranking-v3.component.html',
    styles: [],
})
export class BetsnapdetailRankingV3Component extends ABetsnapdetailRankingComponent {

    public profileHeaderImage: string;
    public gameUsersChanged: boolean = false;

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                public tenantService: TenantService,
                protected router: Router,
                public translations: MyTranslateService,
                protected friendsService: FriendsService,
                protected myModalService: MyModalService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected debugService: DebugService,
                protected errorService: ErrorService,
                protected loggerService: LoggerService,
                protected windowRef: WindowRef) {
        super(
            authenticationService,
            betsnapdetailService,
            tenantService,
            router,
            translations,
            friendsService,
            myModalService,
            hintService,
            googleAnalyticsService,
            debugService,
            errorService,
            loggerService,
            windowRef
        );

        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER)) {
            if (this.game.bgimg_game_card) {
                this.profileHeaderImage = this.game.bgimg_game_card;
            } else {
                this.profileHeaderImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER).media_url;
            }
        }

        if (this.game.game_state < 3) {
            this.betsnapdetailService.gameUsersChanged$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (gameUsersChanged: boolean) => {
                        if (gameUsersChanged) {
                            this.gameUsersChanged = true;
                        }
                    }
                );
        }
    }

    protected getRankingMenuFilter() {
        if (this.betsnapdetailService.isTournament) {
            if (this.game.game_state > 2 && this.game.game_state < 6) {
                this.rankingMenu = [];
                if (this.betsnapdetailService.shouldRenderCompactLeaderBoard(this.game)) {
                    // running game with compact leaderboard
                    this.rankingMenu.push({
                        'key': 'me',
                        'translatekey': 'BETSNAPDETAIL.RANKING.MENU.me',
                        'active': (this.betsnapdetailService.rankingMenuActiveKey === 'me')
                    });
                } else {
                    // running game with full leaderboard
                    this.rankingMenu.push({
                        'key': 'all',
                        'translatekey': 'BETSNAPDETAIL.RANKING.MENU.all',
                        'active': (this.betsnapdetailService.rankingMenuActiveKey === 'all')
                    });
                }
                if (this.game.current_user_friends?.length > 0) {
                    // running game current users friends
                    this.rankingMenu.push({
                        'key': 'friends',
                        'translatekey': 'BETSNAPDETAIL.RANKING.MENU.friends',
                        'active': (this.betsnapdetailService.rankingMenuActiveKey === 'friends')
                    });
                }
                if (this.betsnapdetailService.shouldRenderCompactLeaderBoard(this.game) && this.game.competition_type !== 5) {
                    // running game with compact leaderboard - top players
                    this.rankingMenu.push({
                        'key': 'top',
                        'translatekey': 'BETSNAPDETAIL.RANKING.MENU.top_players',
                        'active': (this.betsnapdetailService.rankingMenuActiveKey === 'top')
                    });
                }
            } else {
                // pre or post game - full paginated leaderboard - friends if available
                if (this.game.current_user_friends?.length > 0) {
                    this.rankingMenu = [
                        {
                            'key': 'all',
                            'translatekey': 'BETSNAPDETAIL.RANKING.MENU.all',
                            'active': (this.betsnapdetailService.rankingMenuActiveKey === 'all')
                        }, {
                            'key': 'friends',
                            'translatekey': 'BETSNAPDETAIL.RANKING.MENU.friends',
                            'active': (this.betsnapdetailService.rankingMenuActiveKey === 'friends')
                        }
                    ];
                } else {
                    this.rankingMenu = [];
                }
            }
        }
    }

    checkForPlayersRouteRedirect(gameHttpResponse: GameHttpResponse) {
        return false;
    }

    shouldGetPaginatedLeaderBoard() {
        if (this.betsnapdetailService.rankingMenuActiveKey === 'me') {
            return false;
        } else {
            if (this.game.game_state < 3) {
                return true;
            } else {
                return this.isGamePrizeDistributedOrClosed(this.game);
            }
        }
    }

    handleRankingMenuChange(activateKey: string) {
        if (activateKey !== this.betsnapdetailService.rankingMenuActiveKey) {
            if (activateKey === 'all') {
                this.showOnlyFriends = false;
                this.showCompactLeaderboard = false;
                if (!this.leaderBoardUserList && !this.processList) {
                    this.reloadPaginatedLeaderBoard();
                }
            } else if (activateKey === 'top') {
                this.showOnlyFriends = false;
                this.showCompactLeaderboard = false;
                if (!this.leaderBoardUserList ||
                    (this.leaderBoardUserList && (this.leaderBoardUserList.results?.length < this.leaderBoardUserList.total))) {
                    this.showTopRankedLeaderboard();
                }
            } else if (activateKey === 'friends') {
                // generate in and out game leaderboard friend user list if not available
                if ((typeof this.outGameLeaderBoardFriendUserList === 'undefined' || this.outGameLeaderBoardFriendUserList?.length === 0) &&
                    (typeof this.inGameLeaderBoardFriendUserList === 'undefined' || this.inGameLeaderBoardFriendUserList?.length === 0) &&
                    this.leaderBoardFriendUserList?.length > 0) {
                    this.getInAndOutGameLeaderBoardUserList(true);
                }
                this.showOnlyFriends = true;
                this.showCompactLeaderboard = false;
            } else if (activateKey === 'me') {
                this.showOnlyFriends = false;
                this.showCompactLeaderboard = true;
                if (this.leaderBoardUserList.results.indexOf(this.currentLeaderBoardUser) === -1) {
                    this.betsnapdetailService.getFullLeaderBoard(this.game, GameLeaderboardViewEnum.COMPACT, true);
                }
            }
        }
    }

    reloadPaginatedLeaderBoard() {
        this.processList = true;
        this.currentPage = 1;
        this.getPaginatedLeaderBoard(this.game, true);
        this.gameUsersChanged = false;
    }

    showTopRankedLeaderboard(): void {
        if (this.showCompactLeaderboardTransition) {
            this.loadPaginatedLeaderboardDueToTransition();
        } else {
            this.processList = true;
            this.betsnapdetailService.loadTopRankedLeaderboardUsers(this.game)
                .pipe(take(1)).subscribe(
                (leaderboardUserListHttpResponse: LeaderboardUserListHttpResponse): void => {
                    if (leaderboardUserListHttpResponse) {
                        this.betsnapdetailService.storePublicPlayersFromLeaderboardUserslist(leaderboardUserListHttpResponse);
                        this.leaderBoardUserList = leaderboardUserListHttpResponse;
                    }
                    this.processList = false;
                });
        }
    }

    getDefaultRankingMenuActiveKey() {
        if (this.game.game_state > 2 && this.game.game_state < 6
            && this.betsnapdetailService.shouldRenderCompactLeaderBoard(this.game)) {
            return 'me';
        } else {
            return 'all';
        }
    }

    checkRankingMenuState(game: GameHttpResponse) {
        if (game && game.game_state < 3 &&
            this.betsnapdetailService.rankingMenuActiveKey === 'friends' &&
            game.current_user_friends && game.current_user_friends.length === 0
        ) {
            this.handleRankingMenuChange('all');
        }
        if (game && ((game.competition_type !== 5 && game.game_state >= 3 && game.game_state < 6 &&
            this.betsnapdetailService.shouldRenderCompactLeaderBoard(game) &&
            this.betsnapdetailService.rankingMenuActiveKey === 'all')
        || (game.competition_type === 5 && (game.game_state > 2 && game.game_state < 7)))) {
            this.betsnapdetailService.rankingMenuActiveKey = 'me';
            this.showOnlyFriends = false;
            this.showCompactLeaderboard = true;
        }
        if (game && game.game_state >= 6 &&
            (this.betsnapdetailService.rankingMenuActiveKey === 'me' ||
                this.betsnapdetailService.rankingMenuActiveKey === 'top')) {
            this.betsnapdetailService.rankingMenuActiveKey = 'all';
            this.showOnlyFriends = false;
            this.showCompactLeaderboard = false;
        }
    }

    shouldRenderCompactLeaderBoard() {
        if (this.betsnapdetailService.rankingMenuActiveKey === 'me') {
            return this.betsnapdetailService.shouldRenderCompactLeaderBoard(this.game);
        }

        return false;
    }

    showFullLeaderBoard(game: GameHttpResponse): boolean {
        return (
            !this.showCompactLeaderboard
            && ((game.competition_type !== 5 && (this.isGamePrizeDistributedOrClosed(game) || game.game_state === 99 || game.game_state < 3))
            || (game.competition_type === 5 && game.game_state < 3))
        );
    }

    protected handleStateTransitionFromFinishedToPrizeDistributed() {
        super.handleStateTransitionFromFinishedToPrizeDistributed();
        if (this.game.competition_type === 5) {
            this.reloadPaginatedLeaderBoard();
        }
    }
}
