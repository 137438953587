import {SocialAuthServiceConfig, FacebookLoginProvider} from '@abacritt/angularx-social-login';
import {TenantService} from '../../shared/services/tenant.service';
import {CustomGoogleLoginProvider} from '../classes/custom-google-login-provider';

export function getAuthServiceConfigs(tenantService: TenantService): SocialAuthServiceConfig {

    const facebookLoginEnabled = tenantService.tenantData.social_network.facebook_login_enabled;
    const googleLoginEnabled = tenantService.tenantData.social_network.google_login_enabled;
    const facebook_id = tenantService.tenantData.social_network.facebook_id;
    const google_client_id = tenantService.tenantData.social_network.google_client_id;

    const socialAuthServiceConfig: SocialAuthServiceConfig = {
        autoLogin: false,
        providers: []
    };

    if (facebookLoginEnabled && facebook_id) {
        socialAuthServiceConfig.providers.push(
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider(facebook_id, {
                    scope: 'email,public_profile',
                    fields: 'name,email,picture',
                    version: 'v19.0',
                    return_scopes: false
                })
            }
        );
    }

    if (googleLoginEnabled && google_client_id) {
        socialAuthServiceConfig.providers.push(
            {
                id: CustomGoogleLoginProvider.PROVIDER_ID,
                provider: new CustomGoogleLoginProvider(google_client_id)
            }
        );
    }

    return socialAuthServiceConfig;
}
