import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {
    AuthenticationService,
    FriendsService,
    GoogleAnalyticsService,
    MyModalService,
    MyNotificationsService,
    TenantImageTypeEnum,
    TenantService,
    WindowRef
} from '../../../../shared';
import {SideNavService} from '../../../../shared/services/side-nav.service';
import {AHeaderComponent} from '../a-header-component';
import {filter, takeWhile} from 'rxjs/operators';
import {animate, group, style, transition, trigger} from '@angular/animations';
import {AppHttpResponsesTenantsTenantFooterListHttpResponse as TenantFooterListHttpResponse} from '../../../../api';
import {AppHttpResponsesTenantsTenantFooterHttpResponse as TenantFooterHttpResponse} from '../../../../api';

@Component({
    selector: 'betsnaps-header-default',
    templateUrl: './header-default.component.html',
    styles: [],
    animations: [
        trigger('walletValueChangeAnimation', [
            transition('stateOne <=> stateTwo', [
                group([
                    animate('100ms 500ms ease',
                        style({
                            transform: 'scale(1.25)'
                        })
                    ),
                    animate('500ms 300ms ease',
                        style({
                            transform: 'scale(1)'
                        })
                    ),
                ])
            ])
        ]),
    ]
})
export class HeaderDefaultComponent extends AHeaderComponent {
    public tenantImageTypeEnum = TenantImageTypeEnum;

    constructor(public tenantService: TenantService,
                public authenticationService: AuthenticationService,
                protected notificationService: MyNotificationsService,
                public router: Router,
                protected activatedRoute: ActivatedRoute,
                protected friendsService: FriendsService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public sideNavService: SideNavService,
                public myModalService: MyModalService,
                protected windowRef: WindowRef) {
        super(
            tenantService,
            authenticationService,
            notificationService,
            router,
            activatedRoute,
            friendsService,
            googleAnalyticsService,
            sideNavService,
            myModalService,
            windowRef
        );

        this.checkRouteData();
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.checkRouteData();
            });

        this.tenantService.tenantFooters$
            .pipe(takeWhile(() => this.tenantFooterListResults === undefined))
            .subscribe((tenantFooterList: TenantFooterListHttpResponse) => {
                if (tenantFooterList) {
                    // show only not fixed footers (only supported in V3 themes)
                    this.tenantFooterListResults = tenantFooterList.results.filter(
                        (tenantFooter: TenantFooterHttpResponse) => tenantFooter.is_fixed !== true);
                }
            });
    }

    public checkRouteData(): void {
        let route = this.activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
            this.bigLogoHeader = route.snapshot.data['bigLogoHeader'] || false;
        }
    }

}
