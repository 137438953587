import { HostBinding, OnInit, Directive } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {
    AuthenticationService,
    FriendsService,
    GoogleAnalyticsService,
    MyModalService,
    MyNotificationsService,
    replacePlaceholder,
    TenantService,
    WindowRef
} from '../../../shared';
import {SideNavService} from '../../../shared/services/side-nav.service';
import {
    AppHttpResponsesTenantsTenantFooterHttpResponse,
    AppHttpResponsesTenantsTenantFooterListHttpResponse as TenantFooterListHttpResponse,
    AppHttpResponsesTenantsTenantFooterListHttpResponse as FooterListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {cloneDeep} from 'lodash';
import {takeWhile} from 'rxjs/operators';

@Directive()
export abstract class AHeaderComponent implements OnInit {

    @HostBinding('class') componentClass = 'header-wrapper';
    @HostBinding('class.withLoginRegisterBar') get matchCompletedClass() {
        return (!this.currentUser && !this.bigLogoHeader);
    }

    public currentUser: PlayerHttpResponse;
    public isAuthenticated$: Observable<boolean>;
    public bigLogoHeader: boolean = false;
    public walletValueChangeState: string = 'stateOne';

    public unreadNewsCount$: Observable<number>;
    public unreadFriendsCount$: Observable<number>;

    public currYear: number = new Date().getFullYear();
    public isWrapperWindow: boolean = false;

    public logoutProcess: boolean = false;

    public tenantFooterListResults: Array<AppHttpResponsesTenantsTenantFooterHttpResponse>;

    protected nativeWindow;

    constructor(public tenantService: TenantService,
                public authenticationService: AuthenticationService,
                protected notificationService: MyNotificationsService,
                public router: Router,
                protected activatedRoute: ActivatedRoute,
                protected friendsService: FriendsService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public sideNavService: SideNavService,
                public myModalService: MyModalService,
                protected windowRef: WindowRef) {

        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }

    }

    ngOnInit() {
        this.isAuthenticated$ = this.authenticationService.isAuthenticated$;
        this.unreadNewsCount$ = this.notificationService.unreadNewsCount$;
        this.unreadFriendsCount$ = this.friendsService.userFriendInvitationsIncomingCount$;

        // get current user updates
        this.authenticationService.currentUser$
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (userData) {
                        if (this.currentUser && this.currentUser.wallets[0].balance !== userData.wallets[0].balance) {
                            this.animateWalletValueChange();
                        }
                        this.currentUser = cloneDeep(userData);
                    } else {
                        this.currentUser = null;
                    }
                }
            );

    }

    public onLogoClick() {
        if (this.tenantService.isMaintenanceMode) {
            return;
        } else if (this.authenticationService.isAuthenticated && this.authenticationService.currentUser) {
            if (this.tenantService.b2bWebsiteUrl) {
                this.nativeWindow.location.href = replacePlaceholder(
                    this.authenticationService.currentUser, this.tenantService.b2bWebsiteUrl
                );
            } else {
                this.router.navigate(['/arena'], {relativeTo: this.activatedRoute});
                return;
            }
        } else {
            this.router.navigate(['/'], {relativeTo: this.activatedRoute});
            return;
        }
    }

    public isMailToLink(url: string): boolean {
        return (url.indexOf('mailto') !== -1);
    }

    public goToB2bLogin(eventCategory: string = 'header') {
        this.googleAnalyticsService.trackEvent(eventCategory, 'login', 'b2b redirect');
        this.nativeWindow.location.href = this.tenantService.getB2bWebsiteLoginUrl();
        return false;
    }

    public goToB2bRegister(eventCategory: string = 'header') {
        this.googleAnalyticsService.trackEvent(eventCategory, 'register', 'b2b redirect');
        this.nativeWindow.location.href = this.tenantService.getB2bWebsiteRegisterUrl();
        return false;
    }

    public logout() {
        this.logoutProcess = true;
        this.googleAnalyticsService.trackEvent('side profile menu', 'click', 'logout');
        this.authenticationService.logout();

        if (this.isWrapperWindow) {
            this.nativeWindow.window.BetSnapMobileWrapper.facebookLogout([]);
            this.nativeWindow.window.BetSnapMobileWrapper.googleLogout([]);
        }

        this.sideNavService.toggleSideProfileMenu();
    }

    protected animateWalletValueChange() {
        this.walletValueChangeState = (this.walletValueChangeState === 'stateOne' ? 'stateTwo' : 'stateOne');
    }

}
