import {Component, HostBinding, OnInit, ViewContainerRef} from '@angular/core';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService,
    TenantService
} from '../../../../shared';
import {AGameVendorGameCardComponent} from '../a-game-vendor-game-card-component';
import {take, takeWhile} from 'rxjs/operators';
import {AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse} from '../../../../api';
import * as moment from 'moment';
import {HttpErrorResponse} from '@angular/common/http';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';

@Component({
    selector: 'betsnaps-game-vendor-game-card-v3',
    templateUrl: './game-vendor-game-card-v3.component.html',
    styles: []
})
export class GameVendorGameCardV3Component extends AGameVendorGameCardComponent implements OnInit {
    @HostBinding('class') componentClass = 'ui fluid card game-vendor-game-card-v3';

    @HostBinding('class.game-vendor-game-completed') get gameVendorGameCompletedClass() {
        return this.isGameVendorGameCompleted();
    };

    public activeParticipation: GameVendorGameParticipationHttpResponse;

    public showFullScreenGame: boolean = false;
    public secondsToEnd: number;

    constructor(protected viewContainerRef: ViewContainerRef,
                protected authenticationService: AuthenticationService,
                protected betsnapdetailService: BetsnapdetailService,
                public tenantService: TenantService,
                protected errorService: ErrorService,
                protected adService: AdService,
                private pageVisibilityService: AngularPageVisibilityService,
                public googleAnalyticsService: GoogleAnalyticsService) {
        super(
            viewContainerRef,
            authenticationService,
            betsnapdetailService,
            tenantService,
            errorService,
            adService
        );

        // handle page suspends
        this.pageVisibilityService.$onPageVisibilityChange
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                if (visibilityState === AngularPageVisibilityStateEnum.HIDDEN) {
                    // hide overlay on tab change
                    this.showFullScreenGame = false;
                }
            });
    }

    ngOnInit() {
        super.ngOnInit();

        this.betsnapdetailService.gameVendorGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                    if (gameVendorGameParticipations && this.gameVendorGame) {
                        this.activeParticipation = gameVendorGameParticipations.find((participation: GameVendorGameParticipationHttpResponse) => (
                            participation.vendor_game_id === this.gameVendorGame.vendor_game_id &&
                            participation.status === this.gameVendorGameParticipationStatusEnum.ACTIVE &&
                            participation.game_user_points > 0
                        ));

                        this.loadingParticipations = false;
                    } else {
                        this.activeParticipation = null;
                    }
                });

        this.checkSecondsToEnd();
    }

    private checkSecondsToEnd() {
        const gameVendorGameEndDate = moment(this.gameVendorGame.end_date);
        const secondsToEnd = gameVendorGameEndDate.diff(moment.utc(), 'seconds', false);
        this.secondsToEnd = (secondsToEnd > 0) ? secondsToEnd : 0;
    }

    startParticipation() {
        if (!this.processParticipation &&
            this.authenticationService.currentUser && this.game.is_current_user_joined) {
            const points = this.game.game_user.user_game_points;
            if (this.game.game_user.user_game_points > 0) {
                this.processParticipation = true;

                this.betsnapdetailService.substractFromUserGamePoints(points);

                this.betsnapdetailService.startGameVendorGameParticipation(
                    this.game.game_unique_id,
                    this.gameVendorGame.vendor_game_id,
                    points
                ).pipe(take(1)).
                subscribe({
                    next: (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                        this.activeParticipation = gameVendorGameParticipation;
                        this.openGameUrl();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.betsnapdetailService.addToUserGamePoints(points);
                        this.processParticipation = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
            }
        }
    }

    resumeParticipation(participation: GameVendorGameParticipationHttpResponse) {
        if (!this.processParticipation &&
            this.authenticationService.currentUser && this.game.is_current_user_joined &&
            participation) {
            this.processParticipation = true;

            // check if url expired
            if (moment.utc() < moment(participation.game_url_expiration)) {
                this.openGameUrl();
            } else {
                this.betsnapdetailService.getGameVendorGameParticipation(
                    this.game.game_unique_id,
                    participation.id
                ).pipe(take(1))
                .subscribe({
                    next: (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                        this.activeParticipation = gameVendorGameParticipation;
                        this.openGameUrl();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processParticipation = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
            }
        }
    }

    openGameUrl() {
        this.processParticipation = false;
        this.showFullScreenGame = true;
    }

    closeParticipation(participation: GameVendorGameParticipationHttpResponse) {
        this.showFullScreenGame = false;
        if (!this.processParticipation &&
            this.authenticationService.currentUser && this.game.is_current_user_joined &&
            participation) {
            this.processParticipation = true;
            this.betsnapdetailService.closeGameVendorGameParticipation(
                this.game.game_unique_id,
                participation.id,
                participation.game_user_points,
            ).pipe(take(1))
            .subscribe({
                next: (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                    this.betsnapdetailService.addToUserGamePoints(gameVendorGameParticipation.game_user_points);
                    this.activeParticipation = null;
                    this.processParticipation = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.processParticipation = false;
                }
            });
        }
    }

    showParticipationButton(): boolean {
        return (
            this.game.game_state === 3 &&
            this.gameVendorGame.status === this.gameVendorGameStatusEnum.RUNNING &&
            !this.gameVendorGameEnded() &&
            this.game.is_current_user_joined
        );
    }

    public gameVendorGameEnded(): boolean {
        return moment(this.gameVendorGame.end_date).isBefore(moment().utc());
    }

}
