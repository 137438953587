import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {BroadcastingService} from './broadcasting.service';
import {DebugService} from './debug.service';
import {ErrorService} from './error.service';
import {
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse,
    AppHttpRequestsChatsChatMessageCreateRequest as ChatMessageCreateRequest,
    AppHttpRequestsChatsChatUserLastReadMessageRequest as LastReadMessageRequest,
    AppHttpResponsesChatsChatHttpResponse as ChatHttpResponse,
    AppHttpResponsesChatsChatMessageHttpResponse as ChatMessageHttpResponse,
    AppHttpResponsesChatsChatMessageListHttpResponse as ChatMessageListHttpResponse,
    AppHttpResponsesChatsChatBlockedUserHttpResponse as ChatBlockedUserHttpResponse,
    AppHttpResponsesChatsChatGhostedUserHttpResponse as ChatGhostedUserHttpResponse,
    AppHttpRequestsChatsUserChatBlockedUserCreateRequest as UserChatBlockedUserCreateRequest,
    ChatApi
} from '../../api';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {
    AppEventsChatsChatMessageCreated as ChatMessageCreatedEvent,
    AppEventsChatsChatGhostedUserMessageCreated as ChatGhostedUserMessageCreated,
    AppEventsChatsChatUserUpdated as ChatUserUpdatedEvent,
    AppEventsChatsChatUserBlocked as ChatUserBlockedEvent,
    AppEventsChatsChatUserGhosted as ChatUserGhostedEvent
} from '../interfaces';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {MyNotificationsService} from './my-notifications.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private initialLoadFinishedSubject = new BehaviorSubject<boolean>(false);
    public initialLoadFinished$ = this.initialLoadFinishedSubject.asObservable();
    private initialLoadChatMessageList: ChatMessageListHttpResponse;

    private chatSubject = new BehaviorSubject<ChatHttpResponse>(null);
    public chat$ = this.chatSubject.asObservable();
    private chatSubscription: Subscription;

    private chatMessageListSubject = new BehaviorSubject<ChatMessageListHttpResponse>(null);
    public chatMessageList$ = this.chatMessageListSubject.asObservable();
    private chatMessageListSubscription: Subscription;

    public currentUserId: number;

    // Pagination
    private perPage: number = 10;
    private currentPage: number = 1;

    private lastMessageReadId: number = 0;

    private broadcastEventSubscriptions: Subscription[] = [];

    constructor(private authenticationService: AuthenticationService,
                private broadcastingService: BroadcastingService,
                private debugService: DebugService,
                private errorService: ErrorService,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService,
                private chatApi: ChatApi) {
    }

    public get chat() {
        return this.chatSubject.value;
    }

    public get chatMessageList() {
        return this.chatMessageListSubject.value;
    }

    public loadChat(chatId: number, reload: boolean = false) {
        if ((!this.chatSubject.value || reload) && !this.chatSubscription) {
            if (this.authenticationService.currentUser) {

                this.currentUserId = this.authenticationService.currentUser.id;

                this.chatSubscription = this.chatApi.apiUsersUserIdChatsChatIdGet(
                    this.currentUserId,
                    chatId
                ).pipe(take(1))
                .subscribe({
                    next: (chat: ChatHttpResponse) => {
                        if (chat) {
                            this.chatSubject.next(chat);

                            // reset ChatMessageList before reload
                            this.chatMessageListSubject.next(null);

                            this.chatMessageListSubscription = this.chatApi.apiUsersUserIdChatsChatIdMessagesGet(
                                this.currentUserId,
                                chatId,
                                this.perPage,
                                undefined,
                                1
                            ).pipe(take(1))
                            .subscribe({
                                next: (chatMessageList: ChatMessageListHttpResponse) => {
                                    this.currentPage = chatMessageList.current_page;
                                    this.initialLoadChatMessageList = chatMessageList;
                                },
                                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err),
                                complete: () => {
                                    this.chatMessageListSubscription.unsubscribe();
                                    this.chatMessageListSubscription = null;

                                    // load more if less than perPage
                                    if (this.initialLoadChatMessageList.total > this.perPage &&
                                        this.initialLoadChatMessageList.results.length < this.perPage) {
                                        this.loadMore(true);
                                    } else {
                                        this.finishInitialLoad(this.initialLoadChatMessageList);
                                    }
                                }
                            });
                        }
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err),
                    complete: () => {
                        this.chatSubscription.unsubscribe();
                        this.chatSubscription = null;
                    }
                });
            }
        }
    }

    private finishInitialLoad(initialLoadChatMessageList: ChatMessageListHttpResponse) {
        this.chatMessageListSubject.next(initialLoadChatMessageList);
        this.initialLoadChatMessageList = null;
        this.initialLoadFinishedSubject.next(true);

        this.broadcastingService.joinChannel('Chat.' + this.chat.id, 'private');
        this.broadcastingService.joinChannel('ChatUser.' + this.chat.id + '.' + this.currentUserId, 'private');

        this.subscribeToChatBroadcastEvents();
    }

    public loadMore(finishInitialLoad: boolean = false) {
        if (this.chat &&
            ((this.chatMessageList && this.chatMessageList.results.length < this.chatMessageList.total) || this.initialLoadChatMessageList) &&
            !this.chatMessageListSubscription) {

            this.currentPage = this.currentPage - 1;
            this.chatMessageListSubscription = this.chatApi.apiUsersUserIdChatsChatIdMessagesGet(
                this.currentUserId,
                this.chat.id,
                this.perPage,
                this.currentPage,
                undefined
            ).pipe(take(1))
            .subscribe({
                next: (chatMessageList: ChatMessageListHttpResponse) => {
                    if (finishInitialLoad) {

                        this.initialLoadChatMessageList.total = chatMessageList.total;
                        this.initialLoadChatMessageList.last_page = chatMessageList.last_page;

                        this.initialLoadChatMessageList.results.unshift(...chatMessageList.results);

                        this.finishInitialLoad(this.initialLoadChatMessageList);
                    } else {
                        const newChatMessageList = this.chatMessageList;

                        newChatMessageList.total = chatMessageList.total;
                        newChatMessageList.last_page = chatMessageList.last_page;

                        newChatMessageList.results.unshift(...chatMessageList.results);

                        this.chatMessageListSubject.next(newChatMessageList);
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err),
                complete: () => {
                    this.chatMessageListSubscription.unsubscribe();
                    this.chatMessageListSubscription = null;
                }
            });
        }
    }

    private updateUnreadMessagesCount(unreadMessagesCount: number) {
        const currentChat = this.chat;
        if (currentChat) {
            currentChat.unread_messages_count = unreadMessagesCount;
            this.chatSubject.next(currentChat);
        }
    }

    public updateUsersLastMessageRead() {
        if (this.chatMessageList) {
            // get all unread message from other users
            const otherUserMessages = this.chatMessageList.results.filter(
                (chatMessage: ChatMessageHttpResponse) =>
                    chatMessage.user_id !== this.currentUserId &&
                    !chatMessage.is_read &&
                    chatMessage.id > this.lastMessageReadId
            );
            if (otherUserMessages) {
                const lastMessage = otherUserMessages[otherUserMessages.length - 1];
                if (lastMessage && lastMessage.id > this.lastMessageReadId) {
                    const currentUnreadMessagesCount = this.chat.unread_messages_count;
                    const currentLastMessageReadId = this.lastMessageReadId;

                    this.updateUnreadMessagesCount(0);
                    this.lastMessageReadId = lastMessage.id;

                    const lastMessageReadRequest: LastReadMessageRequest = {
                        'last_read_message_id': lastMessage.id
                    };
                    this.chatApi.apiChatsChatIdUsersUserIdLastreadmessagePut(
                        this.chat.id,
                        this.currentUserId,
                        lastMessageReadRequest
                    ).pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.readAllUnreadMessagesBeforeLastMessageRead();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.lastMessageReadId = currentLastMessageReadId;
                            this.updateUnreadMessagesCount(currentUnreadMessagesCount);
                            this.errorService.handleHttpErrorResponse(err);
                        }
                    });
                }
            }
        }
    }

    private readAllUnreadMessagesBeforeLastMessageRead() {
        if (this.chatMessageList) {
            this.chatMessageList.results = this.chatMessageList.results.map(
                (chatMessage: ChatMessageHttpResponse) => {
                    if (chatMessage.user_id !== this.currentUserId &&
                        !chatMessage.is_read &&
                        chatMessage.id <= this.lastMessageReadId) {
                        chatMessage.is_read = true;
                    }
                    return chatMessage;
                });
        }
    }

    private subscribeToChatBroadcastEvents() {

        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];

        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Chat.' + this.chat.id, 'Chats\\ChatMessageCreated')
                .subscribe((broadcastEventData: ChatMessageCreatedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('ChatMessageCreatedEvent');

                        if (this.chatMessageList &&
                            !this.isUserBlocked(broadcastEventData.chatMessageHttpResponse.user_id) &&
                            !this.isUserGhosted(broadcastEventData.chatMessageHttpResponse.user_id)) {
                            // set is read to 0 if not current users message
                            const chatMessage = broadcastEventData.chatMessageHttpResponse;
                            if (chatMessage.user_id !== this.currentUserId) {
                                chatMessage.is_read = false;
                            }

                            if (chatMessage.user_id !== this.currentUserId) {
                                this.updateUnreadMessagesCount(this.chat.unread_messages_count + 1);
                            }

                            this.addChatMessageToList(chatMessage);
                        }
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Chat.' + this.chat.id, 'Chats\\ChatUserGhosted')
                .subscribe((broadcastEventData: ChatUserGhostedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('ChatUserGhostedEvent');
                        this.addUserToGhostedUsers(broadcastEventData.chatGhostedUserHttpResponse);
                    }
                })
        );

        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('ChatUser.' + this.chat.id + '.' + this.currentUserId, 'Chats\\ChatGhostedUserMessageCreated')
                .subscribe((broadcastEventData: ChatGhostedUserMessageCreated) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('ChatGhostedUserMessageCreated');
                        if (this.chatMessageList) {
                            this.addChatMessageToList(broadcastEventData.chatMessageHttpResponse);
                        }
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('ChatUser.' + this.chat.id + '.' + this.currentUserId, 'Chats\\ChatUserUpdated')
                .subscribe((broadcastEventData: ChatUserUpdatedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('ChatUserUpdatedEvent');
                        if (broadcastEventData.chatUserHttpResponse.last_read_message_id > this.lastMessageReadId) {
                            this.lastMessageReadId = broadcastEventData.chatUserHttpResponse.last_read_message_id;
                            this.readAllUnreadMessagesBeforeLastMessageRead();
                            this.updateUnreadMessagesCount(0);
                        }
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('ChatUser.' + this.chat.id + '.' + this.currentUserId, 'Chats\\ChatUserBlocked')
                .subscribe((broadcastEventData: ChatUserBlockedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('ChatUserBlockedEvent');
                        this.addUserToBlockedUsers(broadcastEventData.chatBlockedUserHttpResponse);
                    }
                })
        );

    }

    public createChatMessage(chatMessage: string): Observable<ChatMessageHttpResponse> {
        const chatMessageCreateRequest: ChatMessageCreateRequest = {
            'user_id': this.currentUserId,
            'message': chatMessage
        };
        return this.chatApi.apiChatsChatIdMessagesPost(
            this.chat.id,
            chatMessageCreateRequest
        ).pipe(map((chatMessage: ChatMessageHttpResponse) => {
            this.addChatMessageToList(chatMessage);
            return chatMessage;
        }));
    }

    private addChatMessageToList(chatMessage: ChatMessageHttpResponse) {
        if (this.chatMessageList) {
            const currentChatMessageList = this.chatMessageList;
            const chatMessageExistsInList = currentChatMessageList.results.find(
                (chatMessageInList: ChatMessageHttpResponse) =>
                    chatMessageInList.id === chatMessage.id
            );
            if (!chatMessageExistsInList) {
                currentChatMessageList.results.push(chatMessage);
                currentChatMessageList.total++;
                this.chatMessageListSubject.next(currentChatMessageList);
            }
        }
    }

    public blockUser(user: PlayerPublicHttpResponse): Observable<ChatBlockedUserHttpResponse> {

        const blockedUserCreateRequest: UserChatBlockedUserCreateRequest = {
            blocked_user_id: user.id
        };

        return this.chatApi.apiUsersUserIdChatsChatIdBlockedusersPost(
            this.currentUserId,
            this.chat.id,
            blockedUserCreateRequest
        ).pipe(map((chatBlockedUser: ChatBlockedUserHttpResponse) => {
            const currentChat = this.chat;
            if (currentChat) {
                this.addUserToBlockedUsers(chatBlockedUser);
                this.translateService.get('CHAT.block_user_success', {
                    user_name: user.username,
                    chat_title: currentChat.title
                }).pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast(
                            '',
                            translation,
                            NotificationType.Success
                        );
                    });
            }
            return chatBlockedUser;
        }));
    }

    private addUserToBlockedUsers(chatBlockedUser: ChatBlockedUserHttpResponse) {
        const currentChat = this.chat;
        if (currentChat) {
            if (currentChat.blocked_user_ids && !currentChat.blocked_user_ids.includes(chatBlockedUser.blocked_user_id)) {
                currentChat.blocked_user_ids.push(chatBlockedUser.blocked_user_id);
            } else {
                currentChat.blocked_user_ids = [chatBlockedUser.blocked_user_id];
            }
            this.chatSubject.next(currentChat);
        }
    }

    public isUserBlocked(userId: number): boolean {
        return (
            this.chat &&
            this.chat.blocked_user_ids &&
            this.chat.blocked_user_ids.includes(userId)
        );
    }

    private addUserToGhostedUsers(chatGhostedUser: ChatGhostedUserHttpResponse) {
        const currentChat = this.chat;
        if (currentChat) {
            if (currentChat.ghosted_user_ids && !currentChat.ghosted_user_ids.includes(chatGhostedUser.user_id)) {
                currentChat.ghosted_user_ids.push(chatGhostedUser.user_id);
            } else {
                currentChat.ghosted_user_ids = [chatGhostedUser.user_id];
            }
            this.chatSubject.next(currentChat);
        }
    }

    private isUserGhosted(userId: number): boolean {
        return (
            this.chat &&
            this.chat.ghosted_user_ids &&
            this.chat.ghosted_user_ids.includes(userId)
        );
    }

    public abortRequestsAndUnsubscribeFromBroadcastEvents() {

        if (this.chatSubscription) {
            this.chatSubscription.unsubscribe();
            this.chatSubscription = null;
        }

        if (this.chatMessageListSubscription) {
            this.chatMessageListSubscription.unsubscribe();
            this.chatMessageListSubscription = null;
        }

        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];

        if (this.chat) {
            this.broadcastingService.leaveChannel('Chat.' + this.chat.id);
            this.broadcastingService.leaveChannel('ChatUser.' + this.chat.id + '.' + this.currentUserId);
        }

        this.currentUserId = null;
    }

    public resetAllData() {

        this.abortRequestsAndUnsubscribeFromBroadcastEvents();

        this.initialLoadFinishedSubject.next(false);
        this.initialLoadChatMessageList = null;
        this.chatSubject.next(null);
        this.chatMessageListSubject.next(null);

        this.currentPage = 1;
        this.lastMessageReadId = 0;
    }
}
