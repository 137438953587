/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsRankingsPrizeStructuresTenantRankingPrizeStructureHttpResponse } from './appHttpResponsesBetsnapsRankingsPrizeStructuresTenantRankingPrizeStructureHttpResponse';
import { AppHttpResponsesBetsnapsRankingsTenantRankingPositionHttpResponse } from './appHttpResponsesBetsnapsRankingsTenantRankingPositionHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * FullTenantRankingHttpResponse
 */
export interface AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse { 
    /**
     * Ranking-Period-Id
     */
    id: number;
    /**
     * Tenant-Id
     */
    tenant_id: number;
    /**
     * Ranking-Period-Code
     */
    code: string;
    /**
     * Title
     */
    title: string;
    /**
     * Description
     */
    description?: string;
    /**
     * Ranking-Publish-Date
     */
    publish_date?: Date;
    /**
     * Ranking-From-Date
     */
    from_date?: Date;
    /**
     * Ranking-To-Date
     */
    to_date?: Date;
    /**
     * game_final_points, game_highscore
     */
    ranking_type?: string;
    /**
     * Ranking State
     */
    ranking_state?: number;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Info Page Url
     */
    info_page_url?: string;
    /**
     * Last Recalculation At
     */
    last_recalculation_at?: Date;
    /**
     * Banner Image Media Id
     */
    bannerimg_media_id?: number;
    /**
     * Banner Image
     */
    bannerimg?: string;
    /**
     * Content Image Media Id
     */
    contentimg_media_id?: number;
    /**
     * Content Image
     */
    contentimg?: string;
    /**
     * sum of ALL ranks of this Ranking-Period
     */
    ranking_positions_total: number;
    user_ranking_position?: AppHttpResponsesBetsnapsRankingsTenantRankingPositionHttpResponse;
    /**
     * sum of all user friends in ranking
     */
    user_friends_ranking_positions_count?: number;
    /**
     * Prize Structures
     */
    prize_structures?: Array<AppHttpResponsesBetsnapsRankingsPrizeStructuresTenantRankingPrizeStructureHttpResponse>;
    /**
     * Primary Cash Prize Structure Index
     */
    primary_cash_prize_structure_index?: number;
}

