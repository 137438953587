/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MatchTeamStatisticHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchTeamStatisticHttpResponse { 
    /**
     * Match ID
     */
    match_id: number;
    /**
     * Team ID
     */
    team_id: number;
    /**
     * Yellow Cards Count
     */
    yellow_cards: number;
    /**
     * Red Cards Count
     */
    red_cards: number;
    /**
     * Yellow Red Cards Count
     */
    yellow_red_cards: number;
    /**
     * Corners Count
     */
    corners: number;
}

