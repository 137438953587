import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGamePrizeStructureHttpResponse as GamePrizeStructureHttpResponse
} from '../../api';

export class GameHelpers {

    public getAdvancedButtonType(game: GameHttpResponse): string {
        let type = null;

        if (game && game.is_current_user_joined) {
            type = 'actionDefault';

            if (game.game_state <= 3) {

                // user game points left
                if (game.game_user && game.sport_id !== 999) {
                    if (game.game_user.user_game_points > 0) {
                        if (this.canPlaceBet(game)) {
                            type = 'actionPlaceBet';
                        }
                    } else {
                        if (this.canCashoutBet(game)) {
                            type = 'actionCashout';
                        }
                    }
                } else if (game.game_user && game.sport_id === 999) {
                    type = 'actionCasinoSnap';
                }
            } else if (game.game_state === 99) {
                type = 'pastSnapCancelled';
            } else {
                type = 'pastSnap';

                if (game.game_user) {
                    if (game.game_user.is_winner === '1') {
                        type = 'pastSnapIsWinner';
                    }
                }
            }
        }

        return type;
    }

    public getAdvancedButtonTitleLabel(advancedButtonType: string): string {
        if (advancedButtonType === 'actionCashout') {
            return 'GENERAL.GAMES.cashout_available';
        }
        if (advancedButtonType === 'actionPlaceBet') {
            return 'GENERAL.GAMES.place_bets_btn';
        }
        if (advancedButtonType === 'actionCasinoSnap') {
            return 'GENERAL.GAMES.show_vendor_games';
        }

        return 'GENERAL.GAMES.show_leaderboard';
    }

    private canPlaceBet(game: GameHttpResponse): boolean {
        // user game points left
        if (game.game_user.user_game_points === 0) {
            return false;
        }
        // max bet count
        if (game.max_bet_count !== 0 && (game.current_user_bet_count >= game.max_bet_count)) {
            return false;
        }
        // only pre game betting
        if (game.bet_placement_option === 2 && game.game_state > 2) {
            return false;
        }
        // only live betting
        if (game.bet_placement_option === 4 && game.game_state !== 3) {
            return false;
        }

        return true;
    }

    private canCashoutBet(game: GameHttpResponse): boolean {
        // user open bet count
        if (game.current_user_open_bet_count === 0) {
            return false;
        }
        // cashout not allowed
        if (game.cashout_option === 5) {
            return false;
        }
        // only pre game cashout
        if (game.cashout_option === 2 && game.game_state > 2) {
            return false;
        }
        // only live cashout
        if (game.cashout_option === 4 && game.game_state !== 3) {
            return false;
        }

        return true;
    }

    // check if the game prize structures have a custom prize title to show in game
    public hasPrizeTitleInGame(prizeStructures: Array<GamePrizeStructureHttpResponse>): boolean {
        return ((this.getGamePrizeStructuresWithPrizeTitleInGame(prizeStructures)).length > 0);
    }

    // get all game prize structures that have a custom prize title to show in game
    public getGamePrizeStructuresWithPrizeTitleInGame(prizeStructures: Array<GamePrizeStructureHttpResponse>): Array<GamePrizeStructureHttpResponse> {
        if (typeof prizeStructures !== 'undefined' && prizeStructures !== null) {
            const foundPrizeStructures = prizeStructures.filter((gamePrizeStructure: GamePrizeStructureHttpResponse) => gamePrizeStructure.prize_title_in_game !== null);

            if (typeof foundPrizeStructures !== 'undefined' && foundPrizeStructures !== null) {
                return foundPrizeStructures;
            }
        }

        return [];
    }
}
