/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsGamesGameTenantHttpResponse } from './appHttpResponsesBetsnapsGamesGameTenantHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * BasicGameHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesBasicGameHttpResponse { 
    /**
     * Game Id
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * Game Name
     */
    game_name: string;
    /**
     * BetSnap Start-Date
     */
    start_date: Date;
    /**
     * Clone number
     */
    clone_number?: number;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Game Tenants
     */
    game_tenants?: Array<AppHttpResponsesBetsnapsGamesGameTenantHttpResponse>;
}

