import {Component, HostBinding, OnInit} from '@angular/core';
import {TenantService, WindowRef} from '../../../shared';
import {Router} from '@angular/router';

@Component({
    selector: 'betsnaps-b2b-login-initialize',
    templateUrl: './b2b-login-initialize.component.html',
    styles: []
})
export class B2bLoginInitializeComponent implements OnInit {
    @HostBinding('class') componentClass = 'standardview';

    private nativeWindow: Window;

    constructor(private tenantService: TenantService,
                private router: Router,
                private windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;
    }

    ngOnInit() {
        if (this.tenantService.isB2b && this.tenantService.b2bLoginType === 'OAUTH2') {
            this.nativeWindow.location.href = this.tenantService.getB2bWebsiteLoginUrl();
        } else {
            this.router.navigate(['/']);
            return;
        }
    }

}
