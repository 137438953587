<ng-template let-context let-modal="modal" #singleplayerJoinModalTemplate>
    <div class="scrolling content" [class.with-profile-header-image]="profileHeaderImage">
        <div class="ui fluid profile-header-image image" *ngIf="profileHeaderImage">
            <img [src]="profileHeaderImage" [alt]="" />
        </div>
        <div class="inner-content" [class.with-profile-header-image]="profileHeaderImage"
            [class.margin-t-20]="!authenticationService.currentUser">
            <div *ngIf="authenticationService.currentUser" class="center aligned margin-b-30">
                <betsnaps-user-avatar-loader [user]="authenticationService.currentUser"
                                             [imageSize]="'medium'">
                </betsnaps-user-avatar-loader>
                <p class="margin-t-10 margin-b-0" *ngIf="authenticationService.currentUser.username">
                    <strong class="font-very-bold text-large">{{authenticationService.currentUser.username}}</strong>
                </p>
                <p class="text-tiny color-light-grey text-uppercase font-bold margin-b-0">
                    {{'PROFILE.OVERVIEW.current_balance' | translate}}:
                    <strong class="balance-value">{{authenticationService.currentUser.wallets[0].balance | number:'1.2-2' | currencyFormat:authenticationService.currentUser.wallets[0].currency.symbol }}</strong>
                </p>
            </div>

            <div class="scrolling content margin-t-10">
                <div class="font-very-bold color-light-grey text-small margin-b-10">
                    {{'GENERAL.VENDORGAMES.JOIN.play' | translate}} {{tenantVendorGame.name ? (tenantVendorGame.name | dbtranslation:'name':tenantVendorGame.translations) : (tenantVendorGame.vendor_game.name | dbtranslation:'name':tenantVendorGame.vendor_game.translations)}} {{'GENERAL.VENDORGAMES.JOIN.for' | translate}}
                </div>
                <div id="placeBetDialog-{{tenantVendorGame.id}}">
                    <div class="ui middle aligned centered grid">
                        <div class="row">
                            <div class="ten wide left aligned column">
                                <div class="ui large fluid input v3"
                                     [class.disabled]="confirmPointsProcess">
                                    <input type="text" id="points-{{tenantVendorGame.id}}"
                                           (focus)="inputOnFocus()"
                                           (blur)="inputOnBlur()"
                                           [ngModel]="points | number:'1.2-2'"
                                           [formControl]="pointsControl"
                                           [autocomplete]="'off'"
                                           required/>
                                </div>
                            </div>
                            <div class="three wide center aligned column">
                                <button
                                    [class.disabled]="points <= 0 || confirmPointsProcess"
                                    [disabled]="confirmPointsProcess"
                                    type="button" class="ui basic primary fluid large icon button margin-0 padding-l-0 padding-r-0"
                                    (click)="
                                        googleAnalyticsService.trackEvent('singleplayergamecard - points', 'click', '-100');
                                        decreasePoints(100);
                                    ">
                                    <i class="fa fa-minus icon"></i>
                                </button>
                            </div>
                            <div class="three wide center aligned column">
                                <button
                                    [class.disabled]="freePoints === points || confirmPointsProcess"
                                    [disabled]="confirmPointsProcess"
                                    type="button" class="ui basic primary fluid large icon button margin-0 padding-l-0 padding-r-0"
                                    (click)="
                                        googleAnalyticsService.trackEvent('singleplayergamecard - points', 'click', '+100');
                                        increasePoints(100);
                                    ">
                                    <i class="fa fa-plus icon"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10">
                        <button
                            [class.disabled]="freePoints === points || confirmPointsProcess"
                            [disabled]="confirmPointsProcess"
                            type="button" class="ui basic primary fluid large button font-bold margin-0"
                            (click)="
                                googleAnalyticsService.trackEvent('singleplayergamecard - points', 'click', 'ALL-IN');
                                allIn();
                            ">
                            {{'GENERAL.BETS.all_in' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="ui divider"></div>
                    <div>
                        <p class="center aligned color-negative"
                           *ngIf="points < 0.01">
                            {{'GENERAL.BETS.ERRORS.invalid_bet_points' | translate}}
                        </p>
                        <button
                            [class.disabled]="freePoints === 0 || points < 0.01 || confirmPointsProcess"
                            [class.loading]="confirmPointsProcess" [disabled]="confirmPointsProcess"
                            type="button" class="ui fluid tiny button line-height-standard padding-10 margin-0" (click)="confirmPoints()">
                            <strong class="text-large display-block">
                                <span>
                                    {{'GENERAL.VENDORGAMES.JOIN.enter_game' | translate}}
                                </span>
                            </strong>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
