<div class="promotion-container {{adToDisplay.type}}" *ngIf="showAd && adToDisplay">
    <div #adScriptPlaceholder id="adScriptPlaceholder-{{adScriptId}}" *ngIf="isScriptAd"></div>
    <a *ngIf="isMediaAvailable && !isScriptAd && adToDisplay.image_link"
        [href]="(adToDisplay.image_link | dbtranslation:imageLinkTranslationName:adToDisplay.translations)"
       [target]="(adToDisplay.open_link_in_new_tab) ? '_blank' : '_self'"
       [rel]="(adToDisplay.open_link_in_new_tab) ? 'noopener noreferrer' : null">
        <img class="promoimage {{adImageClass ? adImageClass : ''}}" [src]="getAdvertisementMediaTranslationForLanguage(adToDisplay)?.media_url" [alt]="" />
    </a>
    <img class="promoimage {{adImageClass ? adImageClass : ''}}" [src]="getAdvertisementMediaTranslationForLanguage(adToDisplay)?.media_url" [alt]="" *ngIf="isMediaAvailable && !isScriptAd && !adToDisplay.image_link"/>

    <ng-container *ngIf="adToDisplay.text">
        <div class="ui vertical borderless center aligned segment margin-t-40 padding-t-40"
        [innerHTML]="adToDisplay.text | dbtranslation:'text':adToDisplay.translations">
        </div>
    </ng-container>
</div>
