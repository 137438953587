import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AuthenticationService,
    BetsnapdetailService,
    CurrencyFormatPipe,
    DecimalPrizePipe,
    ErrorService,
    GoogleAnalyticsService,
    HintService,
    MyModalService, MyNotificationsService,
    MyTranslateService,
    OneSignalService,
    ProfileService,
    TenantImageTypeEnum,
    TenantService, WindowRef
} from '../../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {ABetsnapdetailDetailsComponent} from '../a-betsnapdetail-details-component';
import {GameShareModalV3Component} from '../../../../components/game-share-modal/game-share-modal-v3/game-share-modal-v3.component';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {AppHttpResponsesUsersPlayerPublicHttpResponse as UsersPlayerPublicHttpResponse} from '../../../../../api';

@Component({
    selector: 'betsnaps-betsnapdetail-details-v3',
    templateUrl: './betsnapdetail-details-v3.component.html',
    styles: []
})
export class BetsnapdetailDetailsV3Component extends ABetsnapdetailDetailsComponent implements AfterViewInit {
    public tenantImageTypeEnum = TenantImageTypeEnum;

    @ViewChild('betsnapDetailContentCard') protected betsnapDetailContentCard: ElementRef;
    @ViewChild('gameShareModalV3Component', {static: true}) gameShareModalV3Component: GameShareModalV3Component;

    public profileHeaderImage: string;

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected myModalService: MyModalService,
                public translations: MyTranslateService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                public tenantService: TenantService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected profileService: ProfileService,
                protected errorService: ErrorService,
                protected decimalPrizePipe: DecimalPrizePipe,
                protected currencyFormatPipe: CurrencyFormatPipe,
                protected oneSignalService: OneSignalService,
                private windowRef: WindowRef) {
        super(
            betsnapdetailService,
            authenticationService,
            router,
            route,
            myModalService,
            translations,
            myNotificationsService,
            translateService,
            tenantService,
            hintService,
            googleAnalyticsService,
            profileService,
            errorService,
            decimalPrizePipe,
            currencyFormatPipe,
            oneSignalService
        );

        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }

        if (this.game.bgimg_game_header) {
            this.profileHeaderImage = this.game.bgimg_game_header;
        } else if (this.game.bgimg_game_card) {
            this.profileHeaderImage = this.game.bgimg_game_card;
        } else if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER)) {
            this.profileHeaderImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER).media_url;
        }
    }

    ngAfterViewInit() {
        let openShareModal: boolean = false;
        this.route.queryParams.subscribe(params => {
            openShareModal = params['share'];
        });

        if (!!openShareModal) {
            this.openGameShareModal();
        }
    }

    openGameShareModal() {
        this.googleAnalyticsService.trackEvent('game - details', 'show', 'sharemodal');

        if (this.isWrapperWindow) {
            this.nativeWindow.window.BetSnapMobileWrapper.share([this.gameShareModalV3Component.shareUrl]);
        } else {
            this.gameShareModalV3Component.openGameShareModal();
        }
    }

    public getFirstCompetitor(): UsersPlayerPublicHttpResponse {
        let userCompetitor = null;

        if (this.currentUser && this.game.is_current_user_joined) {
            userCompetitor = this.game.game_user.user;
        } else {
            // in non pooled H2H show the competitors if game is running or full
            if (this.game.competition_type === 1 &&
                ((this.game.size === this.game.users_count) || (this.game.game_state >= 3)) &&
                this.game.h2h_competitors &&
                this.game.h2h_competitors.length > 0
            ) {
                userCompetitor = this.game.h2h_competitors[0];
            }
        }

        return userCompetitor;
    }

    public getSecondCompetitor(): UsersPlayerPublicHttpResponse {
        let userCompetitor = null;
        let userCompetitors: UsersPlayerPublicHttpResponse[] = [];

        // if pooled H2H and pre game -> return no competitor
        if (this.game.competition_type === 3 && this.game.game_state < 3) {
            return null;
        }

        if (this.game?.game_user_group_competitors) {
            userCompetitors = this.game?.game_user_group_competitors;
        } else {
            userCompetitors = this.game?.h2h_competitors;
        }

        if (userCompetitors && userCompetitors.length > 0) {
            // if current user is available show his opponent
            if (this.currentUser) {
                if (this.game.is_current_user_joined) {
                    userCompetitor = userCompetitors.find(
                        (usersPlayerPublicHttpResponse: UsersPlayerPublicHttpResponse) => (
                            usersPlayerPublicHttpResponse.id !== this.currentUser.id
                        ));
                }
            }

            // in non pooled H2H show always the opponent (if not found for current user)
            if (!userCompetitor) {
                if (this.game.competition_type === 1) {
                    if (userCompetitors.length > 1) {
                        userCompetitor = userCompetitors[1];
                    }
                }
            }
        }

        if (this.currentUser && userCompetitor && userCompetitor.id === this.currentUser.id) {
            userCompetitor = null;
        }

        return userCompetitor;
    }
}
