<div class="content top container  font-bold" [ngClass]="{
    'game-vendor-game-running': gameVendorGame.status === gameVendorGameStatusEnum.RUNNING,
    'game-vendor-game-closed': isGameVendorGameCompleted()
    }">
    <div class="ui list horizontal">
        <div class="left aligned item truncate element-clickable" fuiPopup
             popupText="{{gameVendorGame.vendor_game.name | dbtranslation:'name':gameVendorGame.vendor_game.translations}}"
             popupTrigger="outsideClick">
            <img class="iconimage" [src]="'./assets/img/sport-999-color.svg'" alt="Casino" />
            {{gameVendorGame.vendor_game.name | dbtranslation:'name':gameVendorGame.vendor_game.translations}}
        </div>
        <div class="right aligned item">
            <span *ngIf="gameVendorGame.status === gameVendorGameStatusEnum.RUNNING" class="line-height-standard text-primary">
                {{'GENERAL.GAMES.STATI.running_until' | translate}} <br /> {{gameVendorGame.end_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }}
            </span>
            <div class="line-height-standard" *ngIf="gameVendorGame.status !== gameVendorGameStatusEnum.RUNNING">
                <div *ngIf="gameVendorGame.status === gameVendorGameStatusEnum.FINISHED || gameVendorGame.status === gameVendorGameStatusEnum.CLOSED">
                    {{'GENERAL.VENDORGAMES.STATI.closed' | translate | uppercase}}
                </div>
                <div *ngIf="gameVendorGame.status === gameVendorGameStatusEnum.CANCELLED">
                    {{'GENERAL.VENDORGAMES.STATI.cancelled' | translate | uppercase}}
                </div>
                {{gameVendorGame.start_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }}
            </div>
        </div>
    </div>
</div>
<div class="content preview-image"
     *ngIf="(gameVendorGame.status !== gameVendorGameStatusEnum.RUNNING || (gameVendorGame.status === gameVendorGameStatusEnum.RUNNING && (!activeParticipations || activeParticipations?.length < 1))) &&
            gameVendorGame.vendor_game.initial_game_image_media"
     [ngClass]="{
        'game-vendor-game-running': gameVendorGame.status === gameVendorGameStatusEnum.RUNNING,
        'game-vendor-game-closed': gameVendorGame.status === gameVendorGameStatusEnum.FINISHED || gameVendorGame.status === gameVendorGameStatusEnum.CLOSED || gameVendorGame.status === gameVendorGameStatusEnum.CANCELLED
    }">
    <div class="ui fluid image">
        <img [src]="gameVendorGame.vendor_game.initial_game_image_media.media_url" alt="" />
    </div>
</div>

<div class="ui center aligned borderless vertical segment margin-0"
     *ngIf="game.game_state === 3 &&
            gameVendorGame.status === gameVendorGameStatusEnum.RUNNING &&
            (!activeParticipations || activeParticipations?.length < 1) &&
            game.is_current_user_joined && (game.game_user.user_game_points > 0 || processParticipation)">
    <button type="button"
            (click)="startParticipation()"
            class="ui button"
            [class.loading]="processParticipation"
            [disabled]="processParticipation">
        {{'GENERAL.VENDORGAMES.start_participation' | translate}}
    </button>
</div>

<div class="ui basic segment margin-0" *ngIf="loadingParticipations">
    <div class="ui active centered inline text loader">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!loadingParticipations && (activeParticipations && activeParticipations?.length > 0)">
    <div *ngFor="let gameVendorGameParticipation of activeParticipations; trackBy: gameVendorGameParticipationsTrackBy">
        <betsnaps-game-vendor-game-participation-iframe
            *ngIf="gameVendorGame.status === gameVendorGameStatusEnum.RUNNING &&
                   gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.ACTIVE &&
                   gameVendorGameParticipation.game_url"
            [gameUrl]="gameVendorGameParticipation.game_url">
        </betsnaps-game-vendor-game-participation-iframe>
        <betsnaps-game-vendor-game-participation
            [game]="game"
            [gameVendorGameParticipation]="gameVendorGameParticipation"
            [gameVendorGameParticipationUpdatedDate]="gameVendorGameParticipation.updated_at"
            class="game-vendor-game-participation">
        </betsnaps-game-vendor-game-participation>
    </div>
</div>
