import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as postscribe from 'betsnap-krux-postscribe';
import * as moment from 'moment';
import {AuthenticationService, DebugService, TenantService} from '../../../shared';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'betsnaps-externalbodyscript',
    templateUrl: './external-body-script.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalBodyScriptComponent implements OnInit, AfterViewInit {

    @Input() embedCode: string;
    @Input() scriptId: number;
    @Input() title: string;
    @Input() callWithEveryNavigationChange: boolean = true;

    @ViewChild('externalScriptPlaceholder', { static: true }) externalScriptPlaceholder: ElementRef;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private debugService: DebugService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getReplacedEmbedCode(this.router.url);
        if (this.callWithEveryNavigationChange) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.getReplacedEmbedCode(event.urlAfterRedirects);
                }
            });
        }
    }

    getReplacedEmbedCode(currPath: string) {
        if (this.embedCode) {
            this.externalScriptPlaceholder.nativeElement.innerHTML = '';

            let relativePath = '';
            if (this.tenantService.tenantData.relative_default_path) {
                relativePath += this.tenantService.tenantData.relative_default_path + '/';
            }
            relativePath += currPath;

            const placeholderData = {
                'domain_name': this.tenantService.tenantData.domain_name,
                'path_name': relativePath,
                'unix_timestamp': moment().format('X'),
                'user_id': (this.authenticationService.currentUser) ? this.authenticationService.currentUser.id : 0
            };
            let replacedEmbedCode = this.embedCode;
            for (const entryKey in placeholderData) {
                if (placeholderData.hasOwnProperty(entryKey)) {
                    replacedEmbedCode = replacedEmbedCode.replace('{' + entryKey + '}', placeholderData[entryKey]);
                }
            }
            postscribe('#externalScriptPlaceholder' + this.scriptId, replacedEmbedCode);

            this.debugService.writeMessageToConsoleLog(
                'external script activated: ' + this.title
            );
        }
    }

}
