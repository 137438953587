<div class="ui fluid card" [class.mobile-stretch-bottom-card]="!processList && !searchActive">

    <div class="content">
        <form class="ui form v3" novalidate [formGroup]="searchForm" (ngSubmit)="submitSearch()">
            <div class="ui fluid icon input field margin-b-5">
                <input type="text"
                       placeholder="{{'FRIENDS.OVERVIEW_SEARCH.searchstring_field' | translate}}"
                       formControlName="searchstring" name="searchstring" (keyup)="autoSubmitSearch($event)"/>
                <i *ngIf="!searchActive" class="search icon"></i>
                <i *ngIf="searchActive" class="close link icon" (click)="closeSearch()"></i>
            </div>
            <div *ngIf="searchForm.touched && searchForm.value.searchstring && searchForm.invalid"
                 class="ui pointing red basic label">
                {{'FRIENDS.OVERVIEW_SEARCH.searchstring_minlength' | translate:{minlength: 2} }}
            </div>
        </form>
    </div>

    <div class="content" *ngIf="!searchActive">
        <div *ngIf="hasFriendsOrFriendInvitations()">
            <div class="ui vertical borderless padded segment margin-0" *ngIf="processList">
                <div class="ui active centered inline text loader on-body-bg">
                    {{'GENERAL.loadertext' | translate}}
                </div>
            </div>
            <div *ngIf="!processList">
                <betsnaps-friends-requests-loader></betsnaps-friends-requests-loader>
            </div>
            <div *ngIf="!processList">
                <h3 class="ui header section-header" *ngIf="userFriends">
                    {{'GENERAL.LABELS.friendplural.other' | translate | uppercase}} ({{userFriends.length}})
                </h3>
                <h3 class="ui header section-header" *ngIf="!userFriends">
                    {{'GENERAL.LABELS.friendplural.other' | translate | uppercase}} (0)
                </h3>
                <div class="ui four column grid">
                    <div *ngFor="let userFriend of userFriends; trackBy: userFriendsTrackBy" class="column center aligned">
                        <betsnaps-user-avatar-loader [user]="userFriend.friend_user" [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                        <div class="truncate text-small margin-t-5">{{userFriend.friend_user.username}}</div>
                    </div>
                    <div class="column center aligned">
                        <i class="add icon circular medium-circled margin-0 element-clickable" (click)="this.googleAnalyticsService.trackEvent('friends', 'open', 'friend invite modal'); friendsInviteV3ModalComponent.openFriendsInviteV3Modal()"></i>
                        <div class="text-small margin-t-5">{{'FRIENDS.invite_friends' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui vertical borderless very padded segment center aligned margin-0" *ngIf="!hasFriendsOrFriendInvitations()">
            <div class="ui header text-big margin-t-0 margin-b-30">
                {{'FRIENDS.no_friends_title' | translate}}
                <div class="sub header text-large">{{'FRIENDS.no_friends_subtitle' | translate}}</div>
            </div>
            <button type="button"
                    class="ui button primary large margin-0"
                    (click)="this.googleAnalyticsService.trackEvent('friends', 'open', 'friend invite modal'); friendsInviteV3ModalComponent.openFriendsInviteV3Modal()">
                {{'FRIENDS.invite_friends' | translate}}
            </button>
        </div>
    </div>

    <div class="content" *ngIf="searchActive">
        <div class="ui vertical borderless very padded segment margin-0" *ngIf="processSearchList">
            <div class="ui active centered inline text loader">
                {{'GENERAL.loadertext' | translate}}
            </div>
        </div>
        <div *ngIf="!processSearchList && searchResultFriendsList && searchResultUsersList">

            <div class="ui vertical borderless padded segment margin-0 padding-t-0" *ngIf="searchResultFriendsList.total > 0">
                <h3 class="ui header section-header">
                    {{searchResultFriendsList.total | i18nPlural:translations.labelTranslations.friendplural}} ({{searchResultFriendsList.total}})
                </h3>
                <div class="ui four column grid" *ngIf="searchResultFriendsList.total > 0">
                    <div *ngFor="let publicUser of searchResultFriendsList.results" class="column center aligned">
                        <betsnaps-user-avatar-loader [user]="publicUser" [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                        <div class="truncate text-small margin-t-5">{{publicUser.username}}</div>
                    </div>
                </div>
            </div>

            <div *ngIf="searchResultUsersList.total > 0">
                <h3 class="ui header section-header">
                    {{'FRIENDS.OVERVIEW_SEARCH.not_on_your_list' | translate}} ({{searchResultUsersList.total}})
                </h3>
                <div class="ui four column grid">
                    <div *ngFor="let publicUser of searchResultUsersList.results" class="column center aligned">
                        <betsnaps-user-avatar-loader [user]="publicUser" [imageSize]="'tiny'" [showFriendshipStateIcon]="true"></betsnaps-user-avatar-loader>
                        <div class="truncate text-small margin-t-5">{{publicUser.username}}</div>
                    </div>
                </div>
            </div>

            <div class="ui basic segment center aligned" *ngIf="searchResultUsersList.results.length < searchResultUsersList.total" #loadMoreButton>
                <button type="button" class="ui button margin-0" [class.loading]="processLoadMore" (click)="loadMore()">
                    {{'GENERAL.load-more_btn' | translate}}
                </button>
            </div>

            <div class="ui vertical borderless padded segment center aligned margin-0"
                 *ngIf="searchResultFriendsList.total < 1 && searchResultUsersList.total < 1">
                <p class="margin-0">{{'GENERAL.no_results_found' | translate}}</p>
            </div>
        </div>
    </div>

</div>

<betsnaps-friends-invite-v3-modal #friendsInviteV3ModalComponent></betsnaps-friends-invite-v3-modal>
