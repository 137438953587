import { AfterViewInit, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, Directive } from '@angular/core';
import {takeWhile} from 'rxjs/operators';
import {AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse} from '../../../api';
import {AuthenticationService} from '../../../shared';

@Directive()
export abstract class ASidebarComponent implements OnInit, OnDestroy, AfterViewInit {

    public viewInitialized: boolean = false;
    public hostElement: ElementRef;
    protected componentAlive: boolean = true;

    @ViewChild('sideBarStickyContainer') public sideBarStickyContainer: ElementRef;

    constructor(protected authenticationService: AuthenticationService,
                protected renderer: Renderer2) {

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (this.viewInitialized) {
                        this.updateSideBarStickyDimensions();
                    }
                });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    ngAfterViewInit() {
        this.renderer.setStyle(this.sideBarStickyContainer.nativeElement, 'position', 'fixed');
        this.updateSideBarStickyDimensions();

        this.viewInitialized = true;
    }

    updateSideBarStickyDimensions() {
        if (this.authenticationService.currentUser) {
            this.renderer.setStyle(this.sideBarStickyContainer.nativeElement, 'top', '68px');
        } else {
            this.renderer.setStyle(this.sideBarStickyContainer.nativeElement, 'top', '118px');
        }

        this.renderer.setStyle(this.sideBarStickyContainer.nativeElement, 'width', this.hostElement.nativeElement.offsetWidth + 'px');
        this.renderer.setStyle(this.sideBarStickyContainer.nativeElement, 'left', this.hostElement.nativeElement.getBoundingClientRect().left + 'px');
    }

    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    componentListeners() {
        this.updateSideBarStickyDimensions();
    }

}
