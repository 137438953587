import {AfterViewInit, Component, EventEmitter, Inject, Output} from '@angular/core';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {APlaceBetDialogComponent} from '../a-place-bet-dialog-component';

@Component({
    selector: 'betsnaps-place-bet-dialog-default',
    templateUrl: './place-bet-dialog-default.component.html',
    styles: []
})
export class PlaceBetDialogDefaultComponent extends APlaceBetDialogComponent implements AfterViewInit {

    private nativeWindow: Window;

    @Output() onCloseClick: EventEmitter<any> = new EventEmitter();

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected translateService: TranslateService,
                protected tenantService: TenantService,
                protected errorService: ErrorService,
                private windowRef: WindowRef,
                @Inject(DOCUMENT) private document: any) {
        super(
            authenticationService,
            betsnapdetailService,
            googleAnalyticsService,
            translateService,
            tenantService,
            errorService
        );

        this.nativeWindow = windowRef.nativeWindow;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.centerDialogInView();
        }, 400);
    }

    centerDialogInView() {
        if (typeof this.placeBetEL !== 'undefined') {
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementRect = this.placeBetEL.nativeElement.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + this.nativeWindow.window.pageYOffset;
            const scrollPosition = absoluteElementTop - ((windowHeight - elementRect.height) / 2);

            // animate scroll
            const currentScrollTop = Math.floor(
                this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
            );
            const diff = scrollPosition - currentScrollTop;
            let start;

            this.nativeWindow.window.requestAnimationFrame(function step(timestamp) {
                start = (!start) ? timestamp : start;

                const time = timestamp - start;
                const percent = Math.min(time / 200, 1);

                window.scrollTo(0, currentScrollTop + diff * percent);

                if (time < 200) {
                    window.requestAnimationFrame(step);
                }
            });
        }
    }

    closeDialog(): void {
        this.onCloseClick.emit();
    }

}
