<div class="ui fluid card" *ngIf="currentUser">
    <div class="content">

        <div class="ui vertical borderless very padded segment" *ngIf="showSpinner">
            <div class="ui active centered inline text loader">
                {{'GENERAL.loadertext' | translate}}
            </div>
        </div>

        <form class="ui form" novalidate [formGroup]="privacyForm" *ngIf="!showSpinner">

            <h3 class="ui dividing header section-header margin-t-15 margin-b-0 padding-b-15">
                {{'PROFILE.PRIVACY.data_processing' | translate | uppercase}}
            </h3>

            <div *ngFor="let marketingPartner of marketingPartnersList?.results; let mpIndex = index">
                <fui-accordion [closeOthers]="false" [transitionDuration]="200">
                    <fui-accordion-panel #panel>
                        <div title>
                            <div class="title-inner">
                                <div class="ui container top aligned grid">
                                    <div class="fourteen wide column text-medium">
                                        <fui-checkbox class="toggle v3"
                                                      name="{{'data-' + marketingPartner.id}}"
                                                      id="{{'data-' + marketingPartner.id}}"
                                                      formControlName="{{'data-' + marketingPartner.id}}"
                                                      (click)="setUsersMarketingPartnerPermissions(marketingPartner.id);">
                                            {{'PROFILE.PRIVACY.data_transfer_approval' | translate}}
                                            <span class="font-very-bold">
                                                {{marketingPartner.name | dbtranslation:'name':marketingPartner.translations}}
                                            </span>
                                        </fui-checkbox>
                                    </div>
                                    <div class="two wide column right aligned">
                                        <i [ngClass]="'keyboard arrow ' + ((panel.isOpen) ? 'up' : 'down') + ' huge icon color-grey margin-0'"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div content>
                            <div class="content-inner color-grey text-small"
                                 [innerHTML]="marketingPartner.data_transfer_declaration | dbtranslation:'data_transfer_declaration':marketingPartner.translations">
                            </div>
                        </div>
                    </fui-accordion-panel>
                </fui-accordion>
                <div class="ui divider margin-0" *ngIf="mpIndex !== (marketingPartnersList?.count - 1)"></div>
            </div>
        </form>

    </div>
</div>
