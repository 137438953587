/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantAppStorePackageHttpResponse
 */
export interface AppHttpResponsesTenantsTenantAppStorePackageHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Unique Package-Code (SKU)
     */
    code: string;
    /**
     * Description of the package
     */
    description?: string;
    /**
     * is active?
     */
    is_active: boolean;
    /**
     * available from date
     */
    from_date?: Date;
    /**
     * available until date
     */
    to_date?: Date;
    /**
     * Reward for buying a certain App-Store Package
     */
    purchase_reward?: number;
}

