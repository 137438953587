<div class="ui centered relaxed grid container margin-t-0 margin-b-0">
    <div class="row padding-0">
        <betsnaps-left-sidebar-loader class="four wide tablet or lower hidden column sidebar left-sidebar"></betsnaps-left-sidebar-loader>
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">

            <div class="ui fluid card" [class.mobile-stretch-bottom-card]="!processList && userNotifications?.total > 4">
                <div class="content">

                    <div class="ui vertical borderless very padded segment" *ngIf="processList">
                        <div class="ui active centered inline text loader">
                            {{'GENERAL.loadertext' | translate}}
                        </div>
                    </div>

                    <div class="ui fluid divided very relaxed list"
                         *ngIf="!processList && userNotifications?.total > 0">
                        <div *ngFor="let userNotification of userNotifications?.results; trackBy: notificationTrackBy"
                             class="item"
                             [ngStyle]="{ 'opacity': (userNotification.is_read) ? '0.6' : '1' }"
                             [class.font-very-bold]="!userNotification.is_read">
                            <div class="ui top aligned grid">
                                <div class="three wide column center aligned">
                                    <i class="icons">
                                        <i *ngIf="notificationService.getNotificationIconImageClass(userNotification) && !notificationService.hasSenderUser(userNotification)"
                                           class="ui circular bordered centered huge icon bsi {{notificationService.getNotificationIconImageClass(userNotification)}}"></i>
                                        <i *ngIf="notificationService.isNegative(userNotification) && !notificationService.hasSenderUser(userNotification)"
                                           class="ui circular bordered centered huge icon bsi bsi-stroke negative"></i>
                                        <img *ngIf="!notificationService.getNotificationIconImageClass(userNotification) && !notificationService.hasSenderUser(userNotification)"
                                             class="ui circular bordered centered tiny image"
                                             [src]="'./assets/img/notification_images/notification-whistle.png'"
                                             alt=""/>
                                        <betsnaps-user-avatar-loader
                                            *ngIf="notificationService.hasSenderUser(userNotification)"
                                            [user]="notificationService.getSenderUser(userNotification)"
                                            [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                                    </i>
                                </div>
                                <div class="thirteen wide column left aligned">
                                    <p class="text-tiny margin-b-0 color-grey"
                                       [class.element-clickable]="userNotification.target_relative_path"
                                       (click)="navigateToTarget(userNotification)">
                                        {{userNotification.created_at | amLocal | amCalendar:
                                        {
                                            sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                            nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                            nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                            lastDay: tenantService.tenantData.internationalization.date_time_format,
                                            lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                            sameElse: tenantService.tenantData.internationalization.date_time_format
                                        }
                                        }}
                                    </p>
                                    <p [class.element-clickable]="userNotification.target_relative_path"
                                       [innerHTML]="getReplacedText(userNotification) | safeHtml"
                                       (click)="navigateToTarget(userNotification)"></p>
                                    <div class="ui fluid card" *ngIf="hasActionSection(userNotification)">
                                        <div class="content" *ngIf="isSelfServicePrizePickup(userNotification)">
                                            <ng-container *ngIf="hasTenantPrizePromoCode(userNotification)">
                                                <div class="ui medium header">{{'GENERAL.LABELS.promo_code_title' | translate}}</div>
                                                <div class="meta text-small">
                                                    <span>{{'GENERAL.LABELS.promo_code_subtitle' | translate}}</span>
                                                </div>
                                                <div class="description">
                                                    <betsnaps-copy-to-clipboard [analyticsEventCategory]="'news'" [analyticsEventLabel]="'promo code'" [value]="getTenantPrizePromoCode(userNotification)"></betsnaps-copy-to-clipboard>
                                                </div>
                                                <div class="ui fluid button margin-t-10" (click)="navigateToUrl(getTenantPrizeCtaLinkUrl(userNotification))" *ngIf="hasTenantPrizeCtaLinkUrl(userNotification)">
                                                    {{'GENERAL.LABELS.promo_code_cta_text' | translate}}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!hasTenantPrizePromoCode(userNotification)">
                                                <div class="ui medium header">{{'GENERAL.LABELS.notification_action_title' | translate}}</div>
                                                <div class="meta text-small">
                                                    <span>{{'GENERAL.LABELS.notification_action_subtitle' | translate}}</span>
                                                </div>
                                                <div class="ui fluid button margin-t-10" (click)="navigateToUrl(getTenantPrizeCtaLinkUrl(userNotification))" *ngIf="hasTenantPrizeCtaLinkUrl(userNotification)">
                                                    {{'GENERAL.LABELS.notification_action_cta_text' | translate}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui vertical borderless center aligned font-bold margin-20"
                         *ngIf="!processList && userNotifications?.total < 1">
                        {{'GENERAL.no_results_found' | translate}}
                    </div>
                    <div class="ui basic segment center aligned"
                         *ngIf="userNotifications?.results.length < userNotifications?.total" #loadMoreButton>
                        <button type="button"
                                class="ui button margin-0"
                                [class.loading]="processLoadMore"
                                (click)="loadMore()">
                            {{'GENERAL.load-more_btn' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <betsnaps-right-sidebar-loader class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar"></betsnaps-right-sidebar-loader>
    </div>
</div>
