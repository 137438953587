/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesTranslationsMediaTranslationHttpResponse } from './appHttpResponsesTranslationsMediaTranslationHttpResponse';
import { AppHttpResponsesTenantsTenantImageTypeHttpResponse } from './appHttpResponsesTenantsTenantImageTypeHttpResponse';


/**
 * TenantImageHttpResponse
 */
export interface AppHttpResponsesTenantsTenantImageHttpResponse { 
    /**
     * Tenant image ID
     */
    id: number;
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Media ID
     */
    media_id: number;
    /**
     * Tenant image type ID
     */
    tenant_image_type_id: number;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    tenant_image_type?: AppHttpResponsesTenantsTenantImageTypeHttpResponse;
    /**
     * Media per language
     */
    media_translations?: Array<AppHttpResponsesTranslationsMediaTranslationHttpResponse>;
}

