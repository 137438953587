import {Component} from '@angular/core';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService,
    TenantService
} from '../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {APlaceBetDialogComponent} from '../a-place-bet-dialog-component';

@Component({
    selector: 'betsnaps-place-bet-dialog-v3',
    templateUrl: './place-bet-dialog-v3.component.html',
    styles: []
})
export class PlaceBetDialogV3Component extends APlaceBetDialogComponent {

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected translateService: TranslateService,
                protected tenantService: TenantService,
                protected errorService: ErrorService) {
        super(
            authenticationService,
            betsnapdetailService,
            googleAnalyticsService,
            translateService,
            tenantService,
            errorService
        );
    }

}
