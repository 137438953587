/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTenantsTenantSeoHttpResponse } from './appHttpResponsesTenantsTenantSeoHttpResponse';
import { AppHttpResponsesTenantsTenantImageHttpResponse } from './appHttpResponsesTenantsTenantImageHttpResponse';
import { AppHttpResponsesTenantsTenantB2bPublicHttpResponse } from './appHttpResponsesTenantsTenantB2bPublicHttpResponse';
import { AppHttpResponsesTenantsTenantConfigurationPublicHttpResponse } from './appHttpResponsesTenantsTenantConfigurationPublicHttpResponse';
import { AppHttpResponsesTenantsTenantSocialNetworkPublicHttpResponse } from './appHttpResponsesTenantsTenantSocialNetworkPublicHttpResponse';
import { AppHttpResponsesGeneralLanguageHttpResponse } from './appHttpResponsesGeneralLanguageHttpResponse';
import { AppHttpResponsesTenantsTenantExternalScriptHttpResponse } from './appHttpResponsesTenantsTenantExternalScriptHttpResponse';
import { AppHttpResponsesTenantsTenantInternationalizationHttpResponse } from './appHttpResponsesTenantsTenantInternationalizationHttpResponse';
import { AppHttpResponsesTenantsTenantFinancialPublicHttpResponse } from './appHttpResponsesTenantsTenantFinancialPublicHttpResponse';
import { AppHttpResponsesTenantsTenantPublicHttpResponse } from './appHttpResponsesTenantsTenantPublicHttpResponse';


/**
 * TenantInfoHttpResponse
 */
export interface AppHttpResponsesTenantsTenantInfoHttpResponse { 
    tenant: AppHttpResponsesTenantsTenantPublicHttpResponse;
    /**
     * Tenant Languages
     */
    languages?: Array<AppHttpResponsesGeneralLanguageHttpResponse>;
    /**
     * External Scripts
     */
    external_scripts?: Array<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>;
    /**
     * Tenant ID
     */
    id: number;
    /**
     * Name
     */
    name: string;
    /**
     * Domain Name
     */
    domain_name: string;
    /**
     * Relative Default Path
     */
    relative_default_path?: string;
    /**
     * CTS Tenant Id
     */
    cts_tenant_id?: number;
    /**
     * is B2B
     */
    is_b2b_tenant: boolean;
    /**
     * is demo Tenant
     */
    is_demo_tenant: boolean;
    /**
     * is Tenant active
     */
    is_active: boolean;
    configuration?: AppHttpResponsesTenantsTenantConfigurationPublicHttpResponse;
    /**
     * Tenant images
     */
    images?: Array<AppHttpResponsesTenantsTenantImageHttpResponse>;
    internationalization?: AppHttpResponsesTenantsTenantInternationalizationHttpResponse;
    financial?: AppHttpResponsesTenantsTenantFinancialPublicHttpResponse;
    b2b?: AppHttpResponsesTenantsTenantB2bPublicHttpResponse;
    social_network?: AppHttpResponsesTenantsTenantSocialNetworkPublicHttpResponse;
    seo?: AppHttpResponsesTenantsTenantSeoHttpResponse;
}

