/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantBasicSettingsUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantBasicSettingsUpdateRequest { 
    /**
     * Tenant Name
     */
    name: string;
    /**
     * Tenant Domain
     */
    domain_name: string;
    /**
     * Relative default path
     */
    relative_default_path?: string;
    /**
     * Is active
     */
    is_active: boolean;
    /**
     * Is demo tenant
     */
    is_demo_tenant: boolean;
    /**
     * Admin Email-Addresses
     */
    email_admin_users?: string;
    /**
     * Send admin game-winners notification
     */
    admin_game_winners_notification: boolean;
    /**
     * Send admin global-leaderboard winners notification
     */
    admin_tenant_ranking_winners_notification: boolean;
    /**
     * Use Email Validation Service
     */
    email_validation_service: boolean;
    /**
     * Email Validation Service invalid states
     */
    email_validation_service_invalid_states?: string;
    /**
     * Is AglieCRM Sync Enabled
     */
    is_agilecrm_sync_enabled: boolean;
    /**
     * B2c Authentication Method
     */
    b2c_signup_method: string;
    /**
     * B2c Authentication Method Config
     */
    b2c_signup_method_config?: string;
    /**
     * Show Cookie Banner
     */
    show_cookie_banner: boolean;
}

