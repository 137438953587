/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantNewUserInvitationRequest
 */
export interface AppHttpRequestsTenantsTenantNewUserInvitationRequest { 
    /**
     * Sender User Id
     */
    sender_user_id: number;
    /**
     * Email addresses to invite
     */
    email_addresses: Array<string>;
}

