/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * League.OAuth2.Server.ResponseTypes.BearerTokenResponse
 */
export interface LeagueOAuth2ServerResponseTypesBearerTokenResponse { 
    /**
     * Token Type (Bearer)
     */
    token_type: string;
    /**
     * Token Expiration
     */
    expires_in: number;
    /**
     * Access Token
     */
    access_token: string;
    /**
     * Refresh Token
     */
    refresh_token?: string;
}

