import {Component, ElementRef, HostBinding, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    GoogleAnalyticsService,
    HintService,
    MyTranslateService
} from '../../../../../shared';
import {ABetsnapdetailMatchesComponent} from '../a-betsnapdetail-matches-component';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../../api';
import {takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-betsnapdetail-matches-v3',
    templateUrl: './betsnapdetail-matches-v3.component.html',
    styles: []
})
export class BetsnapdetailMatchesV3Component extends ABetsnapdetailMatchesComponent {
    @HostBinding('class') componentClass = 'betsnapdetail-content';

    @ViewChild('liveMatchesSection') liveMatchesSection: ElementRef;
    @ViewChild('upcomingMatchesSection') upcomingMatchesSection: ElementRef;
    @ViewChild('completedMatchesSection') completedMatchesSection: ElementRef;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected hintService: HintService,
                protected activatedRoute: ActivatedRoute,
                public translations: MyTranslateService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected router: Router,
                protected adService: AdService) {
        super(
            betsnapdetailService,
            authenticationService,
            hintService,
            activatedRoute,
            translations,
            googleAnalyticsService,
            router,
            adService
        );

        this.betsnapdetailService.gameMatches$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameMatchListResults: GameMatchHttpResponse[]) => {
                    if (gameMatchListResults) {
                        this.gameMatches = gameMatchListResults;
                        this.processMatches = false;
                    }
                }
            );
    }

    protected handleUserData(user: PlayerHttpResponse, game: GameHttpResponse) {
        if (!this.currentUser && user && game) {
            const hintConditionValues = {
                bet_placement_option: game.bet_placement_option,
                cashout_option: game.cashout_option,
                game_state: game.game_state,
                odd_display_format: user.settings.odd_display_format
            };
            this.hintService.checkForHintToDisplay('betsnapdetail-matches', hintConditionValues);
        }
        this.currentUser = user;
    }

    scrollToNextSection(element: ElementRef) {
        element.nativeElement.scrollIntoView({behavior: 'smooth'});
    }

}
