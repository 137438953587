/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UserSettingHttpResponse
 */
export interface AppHttpResponsesUsersUserSettingHttpResponse { 
    /**
     * Settings Id
     */
    id: number;
    /**
     * User ID
     */
    user_id: number;
    /**
     * User Timezone ID
     */
    user_timezone_id: number;
    /**
     * User Language ID
     */
    user_language_id: number;
    /**
     * Odd Display Format
     */
    odd_display_format: string;
    /**
     * Email Notification
     */
    email_notifications: boolean;
    /**
     * Push Notification
     */
    push_notifications: boolean;
    /**
     * SMS Notification
     */
    sms_notifications: boolean;
}

