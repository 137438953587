/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesTranslationsMediaTranslationHttpResponse } from './appHttpResponsesTranslationsMediaTranslationHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * CMSHttpResponse
 */
export interface AppHttpResponsesCMSsCMSHttpResponse { 
    /**
     * CMS ID
     */
    id: number;
    /**
     * CMS Key
     */
    key: string;
    /**
     * CMS Description
     */
    description: string;
    /**
     * CMS Value
     */
    value: string;
    /**
     * CMS Media ID
     */
    media_id?: number;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Media per language
     */
    media_translations?: Array<AppHttpResponsesTranslationsMediaTranslationHttpResponse>;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

