/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsGamesGameWinnerUserHttpResponse } from './appHttpResponsesBetsnapsGamesGameWinnerUserHttpResponse';


/**
 * GameWinnerHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameWinnerHttpResponse { 
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * Game Name
     */
    game_name: string;
    /**
     * Clone number
     */
    clone_number?: number;
    /**
     * BetSnap Start-Date
     */
    start_date: Date;
    /**
     * BetSnap End-Date
     */
    end_date: Date;
    /**
     * Is Gift
     */
    is_gift?: AppHttpResponsesBetsnapsGamesGameWinnerHttpResponse.IsGiftEnum;
    /**
     * Is cash Prize
     */
    is_cash_prize?: AppHttpResponsesBetsnapsGamesGameWinnerHttpResponse.IsCashPrizeEnum;
    /**
     * Is Combined
     */
    is_combined?: AppHttpResponsesBetsnapsGamesGameWinnerHttpResponse.IsCombinedEnum;
    /**
     * Cash Prizes Are Withdrawable
     */
    cash_prizes_are_withdrawable?: AppHttpResponsesBetsnapsGamesGameWinnerHttpResponse.CashPrizesAreWithdrawableEnum;
    /**
     * Game Winner Users List
     */
    game_winners?: Array<AppHttpResponsesBetsnapsGamesGameWinnerUserHttpResponse>;
}
export namespace AppHttpResponsesBetsnapsGamesGameWinnerHttpResponse {
    export type IsGiftEnum = '0' | '1';
    export const IsGiftEnum = {
        _0: '0' as IsGiftEnum,
        _1: '1' as IsGiftEnum
    };
    export type IsCashPrizeEnum = '0' | '1';
    export const IsCashPrizeEnum = {
        _0: '0' as IsCashPrizeEnum,
        _1: '1' as IsCashPrizeEnum
    };
    export type IsCombinedEnum = '0' | '1';
    export const IsCombinedEnum = {
        _0: '0' as IsCombinedEnum,
        _1: '1' as IsCombinedEnum
    };
    export type CashPrizesAreWithdrawableEnum = '0' | '1';
    export const CashPrizesAreWithdrawableEnum = {
        _0: '0' as CashPrizesAreWithdrawableEnum,
        _1: '1' as CashPrizesAreWithdrawableEnum
    };
}


