<div class="ui middle aligned grid">
    <div class="row">
        <div [ngClass]="(shouldShowScore() ? getPeriodColumnClasses()[0] : 'sixteen') + ' wide column left aligned padding-l-0'">
            <div class="ui list team-names">
                <div class="item">
                    <span *ngIf="match.home" class="truncate">
                        {{match.home.name | dbtranslation:'name':match.home?.translations}}
                    </span>
                    <span *ngIf="!match.home" class="truncate">
                        {{match.home_name}}
                    </span>
                    <span class="ui blue tiny label margin-b-0 margin-l-5 text-content"
                          *ngIf="match.score && match.score.home_suspend > 0">
                        {{match.score.home_suspend}}
                    </span>
                    <span class="ui red tiny label margin-b-0 margin-l-5 icon-content"
                          *ngIf="match.score && match.score.away_suspend > match.score.home_suspend">
                        <i class="i tiny icon whatshot white margin-0 vertical-align-middle"></i>
                    </span>
                </div>
                <div class="item">
                    <span *ngIf="match.away" class="truncate">
                        {{match.away.name | dbtranslation:'name':match.away?.translations}}
                    </span>
                    <span *ngIf="!match.away" class="truncate">
                        {{match.away_name}}
                    </span>
                    <span class="ui blue tiny label margin-b-0 margin-l-5 text-content"
                          *ngIf="match.score && match.score.away_suspend > 0">
                        {{match.score.away_suspend}}
                    </span>
                    <span class="ui red tiny label margin-b-0 margin-l-5 icon-content"
                          *ngIf="match.score && match.score.home_suspend > match.score.away_suspend">
                        <i class="i tiny icon whatshot white margin-0 vertical-align-middle"></i>
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="getPeriodColumnClasses()[1] + ' wide column right aligned'" *ngIf="shouldShowScore()">
            <div class="period-score-wrap" *ngVar="hasPenaltiesPeriod(match) as matchHasPenaltiesPeriod">
                <ng-container *ngFor="let period of getMatchPeriods(); trackBy: periodTrackBy; let i = index; let isLast = last;">
                    <div class="ui list match-score-list period-score center aligned"
                         *ngIf="period.type !== MatchPeriodTypeEnum.OVERTIME
                                || (period.type === MatchPeriodTypeEnum.OVERTIME && !matchHasPenaltiesPeriod)"
                         [class.last-period-score]="isLast || period.type === MatchPeriodTypeEnum.PENALTIES">
                        <div class="item font-very-bold"
                             [class.color-light-grey]="periodIsFinished(i, match)">
                            {{period.home_score}} <span *ngIf="period.type === MatchPeriodTypeEnum.PENALTIES && match.score.home_penalty_score"> ({{match.score.home_penalty_score}})</span>
                        </div>
                        <div class="item font-very-bold"
                             [class.color-light-grey]="periodIsFinished(i, match)">
                            {{period.away_score}} <span *ngIf="period.type === MatchPeriodTypeEnum.PENALTIES && match.score.away_penalty_score"> ({{match.score.away_penalty_score}})</span>
                        </div>
                    </div>
                </ng-container>
                <div class="ui list match-score-list total-score center aligned font-very-bold"
                     [class.text-primary]="match.status === 'live'">
                    <div class="item">
                        {{match.score.home_score}}
                    </div>
                    <div class="item">
                        {{match.score.away_score}}
                    </div>
                </div>
                <div class="display-inline-block vertical-align-middle center aligned padding-l-10" *ngIf="withScoreExtraInformation()">
                    <div class="text-mini">
                        ({{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}})
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
