import {Injectable} from '@angular/core';
import {
    AppHttpResponsesGeneralCountryListHttpResponse as GeneralCountryListHttpResponse,
    AppHttpResponsesGeneralStateListHttpResponse as GeneralStateListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as  PlayerHttpResponse,
    AppHttpResponsesUsersUserSettingHttpResponse as UserSettingHttpResponse,
    AppHttpResponsesMediaMediaPublicListHttpResponse as MediaListHttpResponse,
    AppHttpResponsesMediaMediaPublicHttpResponse as MediaPublicHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameListHttpResponse,
    AppHttpRequestsUsersUserUpdateRequest as UserUpdateRequest,
    AppHttpRequestsUsersUserSettingsUpdateRequest as UserSettingsUpdateRequest,
    AppHttpRequestsUsersUserChangePasswordRequest as UserChangePasswordRequest,
    AppHttpResponsesUsersUserTransactionListHttpResponse as UserTransactionListHttpResponse,
    AppHttpRequestsUsersUserMediaUploadRequest as UserMediaUploadRequest,
    AppHttpResponsesMarketingMarketingPartnerListHttpResponse as MarketingPartnerListHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerUserPermissionListHttpResponse as MarketingPartnerUserPermissionListHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerUserPermissionHttpResponse as MarketingPartnerUserPermissionHttpResponse,
    AppHttpRequestsUsersUserMarketingPartnerPermissionUpdateRequest as UserMarketingPartnerPermissionUpdateRequest,
    AppHttpRequestsUsersUserInitializeEmailChangeRequest as UserInitializeEmailChangeRequest,
    AppHttpRequestsUsersUserChangeEmailRequest as UserChangeEmailRequest,
    AppHttpRequestsUsersUserInitializePhoneChangeRequest as UserInitializePhoneChangeRequest,
    AppHttpRequestsUsersUserChangePhoneRequest as UserChangePhoneRequest,
    UsersApi,
    UserMediaApi,
    FinancialsApi,
    GamesApi,
    GeneralApi,
    MarketingApi
} from '../../api';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationType} from 'angular2-notifications';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {TenantService} from './tenant.service';
import {ErrorService} from './error.service';
import {TranslateService} from '@ngx-translate/core';
import {MobiledetectService} from './mobiledetect.service';
import {MyNotificationsService} from './my-notifications.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    private marketingPartnerListSubject = new BehaviorSubject<MarketingPartnerListHttpResponse>(null);
    public marketingPartnerList$ = this.marketingPartnerListSubject.asObservable();

    private marketingPartnerPermissionsUpdateSubject = new BehaviorSubject<MarketingPartnerUserPermissionHttpResponse>(null);
    public marketingPartnerPermissionsUpdate$ = this.marketingPartnerPermissionsUpdateSubject.asObservable();

    private apiGetRequestSubscriptions: Subscription[] = [];

    constructor(private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private mobileDetect: MobiledetectService,
                private usersApi: UsersApi,
                private userMediaApi: UserMediaApi,
                private financialsApi: FinancialsApi,
                private gamesApi: GamesApi,
                private generalApi: GeneralApi,
                private marketingApi: MarketingApi,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService,
                private errorService: ErrorService) {
    }

    public getCountries(): Observable<GeneralCountryListHttpResponse> {
        return this.generalApi.apiGeneralCountriesGet();
    }

    public getStatesForCountryIso2(countryIso2: string): Observable<GeneralStateListHttpResponse> {
        return this.generalApi.apiGeneralStatesGet(
            countryIso2
        );
    }

    public updateUserData(userData, isB2b: boolean = false): Observable<PlayerHttpResponse> {
        if (!isB2b) {
            if (userData.value.dob === '0000-00-00') {
                userData.value.dob = null;
            }
            return this.doUpdateUserRequest(userData);
        } else {
            return this.doUpdateUserRequestB2b(userData);
        }
    }

    private doUpdateUserRequest(userData): Observable<PlayerHttpResponse> {

        const userUpdateRequest: UserUpdateRequest = {
            'first_name': userData.value.first_name,
            'last_name': userData.value.last_name,
            'gender': userData.value.gender,
            'dob': userData.value.dob,
            'phone_code': userData.value.phone_code,
            'phone_no': userData.value.phone_no,
            'zip_code': userData.value.zip_code,
            'country': userData.value.country,
            'city': userData.value.city,
            'state_id': userData.value.state_id
        };

        return this.usersApi.apiUsersUserIdPut(
            this.authenticationService.currentUser.id,
            userUpdateRequest
        );
    }

    private doUpdateUserRequestB2b(userData): Observable<PlayerHttpResponse> {

        const userUpdateRequest: UserUpdateRequest = {
            'username': userData.value.username
        };

        return this.usersApi.apiUsersUserIdPut(
            this.authenticationService.currentUser.id,
            userUpdateRequest
        );
    }

    public updateUserSettings(settingsData, isWrapperWindow: boolean = false): Observable<UserSettingHttpResponse> {

        const userSettingsUpdateRequest: UserSettingsUpdateRequest = {
            'user_language_id': settingsData.value.user_language_id,
            'odd_display_format': settingsData.value.odd_display_format
        };

        if (this.tenantService.tenantData.configuration.outgoing_notifications) {

            if (this.authenticationService.currentUser.email) {
                userSettingsUpdateRequest['email_notifications'] = !!(settingsData.value.email_notifications);
            }

            if (this.authenticationService.currentUser.phone) {
                userSettingsUpdateRequest['sms_notifications'] = !!(settingsData.value.sms_notifications);
            }

            if (isWrapperWindow ||
                (this.tenantService.tenantData.configuration.allow_web_push_notifications && this.tenantService.tenantData.configuration.one_signal_push_notifications_app_id != null && this.tenantService.tenantData.configuration.one_signal_push_notifications_app_id !== '')) {
                userSettingsUpdateRequest['push_notifications'] = !!(settingsData.value.push_notifications);
            }
        }

        return this.usersApi.apiUsersUserIdSettingsPut(
            this.authenticationService.currentUser.id,
            userSettingsUpdateRequest
        );
    }

    public updateUserPassword(passwordData): Observable<{}> {

        const userChangePasswordRequest: UserChangePasswordRequest = {
            'old_password': passwordData.value.old_password,
            'new_password': passwordData.value.passwordGroup.new_password
        };

        return this.usersApi.apiUsersUserIdChangepasswordPut(
            this.authenticationService.currentUser.id,
            userChangePasswordRequest
        );
    }

    public getPlayerAvatars(): Observable<MediaListHttpResponse> {
        return this.userMediaApi.apiMediaPlayeravatarsGet();
    }

    public getMediaUser(): Observable<MediaListHttpResponse> {
        return this.userMediaApi.apiUsersUserIdMediaGet(this.authenticationService.currentUser.id);
    }

    public getMediaUserSocialAvatars(): Observable<MediaListHttpResponse> {
        return this.userMediaApi.apiUsersUserIdMediaSocialavatarsGet(this.authenticationService.currentUser.id);
    }

    public setMediaUser(mediaId: number): Observable<MediaPublicHttpResponse> {
        return this.userMediaApi.apiUsersUserIdMediaMediaIdPut(this.authenticationService.currentUser.id, mediaId);
    }

    public deleteMediaUser(mediaId: number): Observable<{}> {
        let apiMethodName = 'apiUsersUserIdMediaMediaIdDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteUsersUserIdMediaMediaIdPost';
        }

        return this.userMediaApi[apiMethodName](this.authenticationService.currentUser.id, mediaId);
    }

    public uploadMediaUser(imageData: string): Observable<MediaPublicHttpResponse> {
        const uploadRequest: UserMediaUploadRequest = {
            'data': imageData
        };
        return this.userMediaApi.apiUsersUserIdMediaPost(this.authenticationService.currentUser.id, uploadRequest);
    }

    public initializeDeleteAccount(): Observable<{}> {
        return this.usersApi.apiUsersUserIdInitializedeletePut(this.authenticationService.currentUser.id);
    }

    public initializeEmailChange(newEmail: string): Observable<{}> {
        const initializeEmailChangeRequest: UserInitializeEmailChangeRequest = {
            'new_email': newEmail
        };
        return this.usersApi.apiUsersUserIdInitializeemailchangePut(this.authenticationService.currentUser.id, initializeEmailChangeRequest);
    }

    public changeEmail(newEmailVerificationToken: string): Observable<PlayerHttpResponse> {
        const userChangeEmailRequest: UserChangeEmailRequest = {
            'new_email_verification_token': newEmailVerificationToken
        };
        return this.usersApi.apiUsersUserIdChangeemailPut(this.authenticationService.currentUser.id, userChangeEmailRequest);
    }

    public initializePhoneChange(newPhone: string): Observable<{}> {
        const initializePhoneChangeRequest: UserInitializePhoneChangeRequest = {
            'new_phone': newPhone
        };
        return this.usersApi.apiUsersUserIdInitializephonechangePut(this.authenticationService.currentUser.id, initializePhoneChangeRequest);
    }

    public changePhone(newPhoneVerificationToken: string): Observable<PlayerHttpResponse> {
        const userChangePhoneRequest: UserChangePhoneRequest = {
            'new_phone_verification_token': newPhoneVerificationToken
        };
        return this.usersApi.apiUsersUserIdChangephonePut(this.authenticationService.currentUser.id, userChangePhoneRequest);
    }

    public getGameList(gameFilter): Observable<GameListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGet(
            this.tenantService.tenantData.id,
            gameFilter.per_page,
            gameFilter.current_page,
            undefined,
            gameFilter.game_state,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            gameFilter.joined_user_id,
            this.authenticationService.authToken,
            gameFilter.sorting
        );
    }

    public getUserTransactions(perPage?: number, currentPage?: number): Observable<UserTransactionListHttpResponse> {
        return this.usersApi.apiUsersUserIdTransactionsGet(
            this.authenticationService.currentUser.id,
            perPage,
            currentPage
        );
    }

    public getMarketingPartners(tenantId?: number) {
        if (!this.marketingPartnerListSubject.value) {
            if (typeof tenantId === 'undefined') {
                tenantId = this.tenantService.tenantData.id;
            }
            this.apiGetRequestSubscriptions.push(
                this.marketingApi.apiMarketingpartnersGet(tenantId)
                    .pipe(take(1))
                    .subscribe({
                        next: (marketingPartnerList: MarketingPartnerListHttpResponse) => {
                            if (marketingPartnerList) {
                                this.marketingPartnerListSubject.next(marketingPartnerList);
                            }
                        },
                        error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                    })
            );
        }
    }

    public getUsersMarketingPartnerPermissions(userId: number): Observable<MarketingPartnerUserPermissionListHttpResponse> {
        return this.marketingApi.apiUsersUserIdMarketingPartnerpermissionsGet(userId);
    }

    public setUsersMarketingPartnerPermissions(userId: number, partnerId: number, is_permitted: boolean): void {
        const userMarketingPartnerPermissionUpdateRequest: UserMarketingPartnerPermissionUpdateRequest = {
            is_permitted: is_permitted
        };

        this.marketingApi.apiUsersUserIdMarketingPartnerpermissionsPartnerIdPut(
            userId,
            partnerId,
            userMarketingPartnerPermissionUpdateRequest
        ).pipe(take(1))
        .subscribe({
            next: (marketingPartnerUserPermissionHttpResponse: MarketingPartnerUserPermissionHttpResponse) => {
                if (marketingPartnerUserPermissionHttpResponse) {
                    this.marketingPartnerPermissionsUpdateSubject.next(marketingPartnerUserPermissionHttpResponse);
                    if (is_permitted) {
                        this.translateService.get(
                            'PROFILE.PRIVACY.data_transfer_approved',
                            {partner_name: marketingPartnerUserPermissionHttpResponse.marketing_partner.name}
                        ).pipe(take(1)).subscribe(
                            translation => {
                                this.myNotificationsService.createNotificationToast('', translation, NotificationType.Success);
                            });
                    } else {
                        this.translateService.get(
                            'PROFILE.PRIVACY.data_transfer_withdrawn',
                            {partner_name: marketingPartnerUserPermissionHttpResponse.marketing_partner.name}
                        ).pipe(take(1)).subscribe(
                            translation => {
                                this.myNotificationsService.createNotificationToast('', translation, NotificationType.Success);
                            });
                    }
                }
            },
            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        });
    }

    public marketingPartnerPermissionsUpdateProcessed() {
        this.marketingPartnerPermissionsUpdateSubject.next(null);
    }

    public subscribeForGameNotifications(gameUniqueId: string, userId: number): Observable<any> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(
            this.tenantService.tenantData.id,
            gameUniqueId,
            userId
        );
    }

    public unsubscribeFromGameNotifications(gameUniqueId: string, userId: number): Observable<any> {
        let apiMethodName = 'apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost';
        }

        return this.gamesApi[apiMethodName](
            this.tenantService.tenantData.id,
            gameUniqueId,
            userId
        );
    }

    public resetAllData() {
        this.marketingPartnerListSubject.next(null);
        this.marketingPartnerPermissionsUpdateSubject.next(null);

        this.apiGetRequestSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.apiGetRequestSubscriptions = [];
    }
}
