<div class="ui fluid flexauto item tiny separatorless menu">
    <a href="#" class="item" [class.active]="menu.active" *ngFor="let menu of matchesMenu" (click)="$event.preventDefault(); matchesMenuChange(menu.key);">
            <span class="display-inline-block vertical-align-middle">
                {{menu.translatekey | translate | uppercase}}
            </span><span class="display-inline-block vertical-align-middle text-mini">&nbsp;({{menu.count}})</span>
    </a>
</div>
<div class="ui basic segment" *ngIf="processMatches">
    <div class="ui active centered inline text loader on-body-bg">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processMatches">
    <div *ngIf="filteredGameMatches?.length > 0">
        <betsnaps-match-card-loader
            *ngFor="let gameMatch of activeGameMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
            [attr.id]="'match'+ gameMatch.match.match_id"
            [game]="game"
            [isCurrentUserJoined]="game.is_current_user_joined"
            [match]="gameMatch.match"
            [currentUser]="currentUser"
            [marketsVisible]="true"
            [activeBetsVisible]="true"
            [compactUserBetView]="true"
            [gameUpdatedDate]="game.updated_at"
            [matchStatus]="gameMatch.match.status"
            [matchIndexInList]="matchIndexInList"
            [adPositions]="adPositions"
            (onPlaceBetClick)="closeOpenPlaceBetDialogs()">
        </betsnaps-match-card-loader>
        <betsnaps-match-card-loader
            *ngFor="let gameMatch of closedGameMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
            [attr.id]="'match'+ gameMatch.match.match_id"
            [game]="game"
            [isCurrentUserJoined]="game.is_current_user_joined"
            [match]="gameMatch.match"
            [currentUser]="currentUser"
            [isCancelled]="gameMatch.is_cancel"
            [marketsVisible]="false"
            [activeBetsVisible]="true"
            [compactUserBetView]="true"
            [gameUpdatedDate]="game.updated_at"
            [matchStatus]="gameMatch.match.status"
            [matchIndexInList]="matchIndexInList + activeGameMatches().length"
            [adPositions]="adPositions"
            (onPlaceBetClick)="closeOpenPlaceBetDialogs()">
        </betsnaps-match-card-loader>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="filteredGameMatches?.length < 1">
        <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
