import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesTenantVendorGameHttpResponse as TenantVendorGameHttpResponse
} from '../../../api';
import {GameVendorGameParticipationStatusEnum, GameVendorGameStatusEnum} from '../../../shared/enums';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService
} from '../../../shared';
import {take, takeWhile} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {SinglePlayerGameService} from '../../../shared/services/singleplayer-game.service';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';

@Component({
    selector: 'betsnaps-game-vendor-game-fullscreen',
    templateUrl: './game-vendor-game-fullscreen.component.html',
    styles: []
})

export class GameVendorGameFullscreenComponent implements OnInit, OnDestroy {

    public showFullScreenGame: boolean = false;
    public activeParticipation: GameVendorGameParticipationHttpResponse;
    public activeTenantVendorGame: TenantVendorGameHttpResponse;

    public processParticipation: boolean = false;
    public loadingParticipations: boolean = false;

    protected componentAlive = true;

    public gameVendorGameStatusEnum = GameVendorGameStatusEnum;
    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;

    constructor(private authenticationService: AuthenticationService,
                private betsnapdetailService: BetsnapdetailService,
                public singlePlayerGameService: SinglePlayerGameService,
                public googleAnalyticsService: GoogleAnalyticsService,
                private pageVisibilityService: AngularPageVisibilityService,
                private errorService: ErrorService) {

        // handle page suspends
        this.pageVisibilityService.$onPageVisibilityChange
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                if (visibilityState === AngularPageVisibilityStateEnum.HIDDEN) {
                    // hide overlay on tab change
                    this.singlePlayerGameService.hideFullScreenVendorGame();
                }
            });

        this.singlePlayerGameService.fullscreenGameVendorGameParticipation$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((singlePlayerGameParticipation: GameVendorGameParticipationHttpResponse) => {
                if (singlePlayerGameParticipation) {
                    this.activeParticipation = singlePlayerGameParticipation;
                } else {
                    this.activeParticipation = null;
                }
            });

        this.singlePlayerGameService.fullscreenTenantVendorGame$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((singlePlayerTenantVendorGame: TenantVendorGameHttpResponse) => {
                if (singlePlayerTenantVendorGame) {
                    this.activeTenantVendorGame = singlePlayerTenantVendorGame;
                } else {
                    this.activeTenantVendorGame = null;
                }
            });

        this.singlePlayerGameService.showFullScreenGame$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((showFullScreenGame: boolean) => {
                if (showFullScreenGame) {
                    this.showFullScreenGame = showFullScreenGame;
                } else {
                    this.showFullScreenGame = false;
                }
            });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    closeParticipation(participation: GameVendorGameParticipationHttpResponse) {
        if (!this.processParticipation &&
            this.authenticationService.currentUser &&
            participation) {
            this.processParticipation = true;

            // finish game
            this.singlePlayerGameService.finishGameVendorSinglePlayerGame(participation.game_unique_id)
                .pipe(take(1))
                .subscribe({
                    next: (gameResponse: GameHttpResponse) => {
                        this.singlePlayerGameService.getSinglePlayerParticipations(this.authenticationService.currentUser.id, true);
                        this.singlePlayerGameService.closeFullscreenVendorGame();
                        this.activeParticipation = null;
                        this.processParticipation = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.singlePlayerGameService.closeFullscreenVendorGame();
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });

        }
    }

}
