import {LoginRegisterViewSwitcherInterface, TenantService, PhoneHelpers, GoogleAnalyticsService} from '../../../shared';
import { EventEmitter, Output, Directive } from '@angular/core';


@Directive()
export abstract class ALoginRegisterComponent {

    @Output() onChangeView: EventEmitter<any> = new EventEmitter();

    public phoneSignupAllowedCountryCodes: string[] = [];

    protected PhoneHelpers = new PhoneHelpers();

    protected constructor(protected tenantService: TenantService,
                          protected googleAnalyticsService: GoogleAnalyticsService) {
    }

    showView(targetView: string, payload: Map<string, any> = null): void {
        if (targetView === 'login' || targetView === 'register') {
            this.googleAnalyticsService.trackEvent('login register modal', targetView, 'open modal');
        }

        const viewSwitcher: LoginRegisterViewSwitcherInterface = {
            targetView: targetView,
            payload: payload
        };
        this.onChangeView.emit(viewSwitcher);
    }

    loadPhoneSignupAllowedCountryCodes(): void {
        this.phoneSignupAllowedCountryCodes = this.PhoneHelpers.getPhoneSignupAllowedCountryCodes(this.tenantService.tenantData);
    }
}
