import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameInvitationHttpResponse as GameInvitationHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendsHttpResponse,
    AppHttpResponsesFriendsFriendListHttpResponse as FriendListHttpResponse
} from '../../../../api';
import {
    AuthenticationService, ErrorService, FriendsService, MobiledetectService,
    GoogleAnalyticsService, BetsnapdetailService, WindowRef, TenantService
} from '../../../../shared';
import {cloneDeep} from 'lodash';
import {HttpErrorResponse} from '@angular/common/http';
import {take, takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-sidebar-share-snap',
    templateUrl: './sidebar-share-snap.component.html',
    styles: []
})
export class SidebarShareSnapComponent implements OnInit, OnDestroy {

    private componentAlive = true;

    @Input() game: GameHttpResponse;
    @Input() gameUniqueId: string;
    public shareUrl: string;

    public userFriends: FriendsHttpResponse[] = [];
    public sentGameInvitations: GameInvitationHttpResponse[];

    public selectedUserFriends: FriendsHttpResponse[] = [];
    public friendsInvited: boolean = false;
    public noSelectionError: boolean = false;

    public processInvite: boolean = false;

    public showcopyinfo: boolean = false;

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    constructor(private authenticationService: AuthenticationService,
                private betsnapdetailService: BetsnapdetailService,
                private friendsService: FriendsService,
                public tenantService: TenantService,
                public mobileDetect: MobiledetectService,
                public googleAnalyticsService: GoogleAnalyticsService,
                private errorService: ErrorService,
                private windowRef: WindowRef) {

        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    ngOnInit() {
        // set share url to betsnapdetail view
        if (this.authenticationService.currentUser) {
            this.shareUrl = this.tenantService.getTenantDomainWithDefaultRelativePath() + '/betsnapdetail/' + this.game.game_unique_id + '/i/' + this.authenticationService.currentUser.unique_id;
        } else {
            this.shareUrl = this.tenantService.getTenantDomainWithDefaultRelativePath() + '/betsnapdetail/' + this.game.game_unique_id + '/details';
        }

        if (this.authenticationService.currentLang) {
            this.shareUrl += '?lang=' + this.authenticationService.currentLang.iso_code2;
        }

        this.friendsService.userFriends$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendListHttpResponse: FriendListHttpResponse) => {
                    if (friendListHttpResponse) {
                        let friendListResults = cloneDeep(friendListHttpResponse.results);
                        if (friendListResults.length > 0) {
                            // only include not joined friends
                            if (this.game.current_user_friends) {
                                friendListResults = friendListResults.filter((friendsHttpResponse: FriendsHttpResponse) =>
                                    !this.game.current_user_friends.find((joinedFriend: FriendsHttpResponse) => joinedFriend.friend_user_id === friendsHttpResponse.friend_user_id)
                                );
                            }
                            this.userFriends = friendListResults;
                        }
                    }
                }
            );

        this.betsnapdetailService.sentGameInvitations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (sentGameInvitations: GameInvitationHttpResponse[]) => {
                    if (sentGameInvitations) {
                        if (sentGameInvitations.length > 0) {
                            this.userFriends = this.userFriends.filter((friendsHttpResponse: FriendsHttpResponse) =>
                                !sentGameInvitations.find((invitedFriend: GameInvitationHttpResponse) =>
                                    invitedFriend.receiver_user_id === friendsHttpResponse.friend_user_id
                                )
                            );
                        }
                        this.sentGameInvitations = sentGameInvitations;
                    }
                }
            );
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    selectFriend(userFriend: FriendsHttpResponse) {
        if (!this.processInvite) {
            if (this.selectedUserFriends.includes(userFriend)) {
                // remove from selection
                this.selectedUserFriends = this.selectedUserFriends.filter((selectedFriend: FriendsHttpResponse) =>
                    selectedFriend.friend_user_id !== userFriend.friend_user_id
                );
            } else {
                // add to selection
                this.selectedUserFriends.push(userFriend);
            }
        }
    }

    inviteFriends() {
        if (this.selectedUserFriends.length > 0) {
            // CALL API
            const selectedUserIds = [];
            this.selectedUserFriends.forEach((selectedFriend: FriendsHttpResponse) => {
                selectedUserIds.push(selectedFriend.friend_user_id);
            });

            this.processInvite = true;

            this.friendsService.inviteFriends(
                this.game.game_unique_id,
                this.authenticationService.currentUser.id,
                selectedUserIds
            ).pipe(take(1))
            .subscribe({
                next: () => {
                    // remove from available friends
                    this.userFriends = this.userFriends.filter((friendsHttpResponse: FriendsHttpResponse) =>
                        !this.selectedUserFriends.find((selectedFriend: FriendsHttpResponse) => selectedFriend.friend_user_id === friendsHttpResponse.friend_user_id)
                    );
                    // update invitations
                    this.betsnapdetailService.getSentGameInvitations(this.game);
                    // reset selected friends
                    this.selectedUserFriends = [];
                    this.noSelectionError = false;
                    this.friendsInvited = true;
                    this.processInvite = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.handleHttpErrorResponse(err);
                    this.processInvite = false;
                }
            });
        } else {
            this.noSelectionError = true;
        }
    }

    showCopyInformation() {
        this.showcopyinfo = true;
        const self = this;
        setTimeout(() => {
            self.showcopyinfo = false;
        }, 3000);
    }

    callWrapperShare() {
        if (this.isWrapperWindow) {
            this.nativeWindow.window.BetSnapMobileWrapper.share([this.shareUrl]);
        }
    }

}
