<div class="ui centered relaxed grid container margin-t-0 margin-b-0">
    <div class="row padding-0">
        <div class="four wide tablet or lower hidden column sidebar left-sidebar" #leftSidebar>
            <div class="sticky-container" [class.with-skyscraper]="tenantService.hasSidebarLeftSkyscraperAd()" #leftSidebarSticky>
                <div *ngIf="tenantService.tenantData.configuration.show_arena_filters && !tenantService.hasSidebarLeftSkyscraperAd()">
                    <div class="ui secondary borderless segment" *ngIf="sportFilters.length > 0">
                        <fui-accordion [closeOthers]="false">
                            <fui-accordion-panel [(isOpen)]="sportFilterOpen">
                                <div title>
                                    <i class="filter list icon"
                                       [class.primary]="sportFilterOpen"></i> {{'ARENA.FILTER.SPORT.heading' | translate | uppercase}}
                                    <i class="keyboard arrow right icon large"
                                       [ngClass]="{'clockwise rotated': sportFilterOpen}"></i>
                                </div>
                                <div content>
                                    <div class="ui tiny list">
                                        <div class="item" *ngFor="let sportFilter of sportFilters">
                                            <fui-checkbox name="sport"
                                                          [(ngModel)]="sportFilter.active"
                                                          (checkChange)="sportFilterUpdate(sportFilter)">
                                                {{sportFilter.sport.sport_desc | dbtranslation:'sports_desc':sportFilter.sport.translations | uppercase}}
                                            </fui-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </fui-accordion-panel>
                        </fui-accordion>
                    </div>
                    <div class="ui secondary borderless segment">
                        <fui-accordion [closeOthers]="false">
                            <fui-accordion-panel [(isOpen)]="snapOptionsFilterOpen">
                                <div title>
                                    <i class="filter list icon"
                                       [class.primary]="snapOptionsFilterOpen"></i> {{'ARENA.FILTER.SNAPOPTIONS.heading' | translate | uppercase}}
                                    <i class="keyboard arrow right icon large"
                                       [ngClass]="{'clockwise rotated': snapOptionsFilterOpen}"></i>
                                </div>
                                <div content>
                                    <div class="ui tiny list">
                                        <div class="item" *ngFor="let option of snapOptionsFilter">
                                            <fui-checkbox name="competition"
                                                          [(ngModel)]="option.active"
                                                          (checkChange)="snapOptionsFilterUpdate(option)">
                                                {{option.translatekey | translate | uppercase}}
                                            </fui-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </fui-accordion-panel>
                        </fui-accordion>
                    </div>
                </div>

                <betsnaps-tenant-content [adType]="'sidebar-left-skyscraper'" [adScriptId]="'sidebar-left-skyscraper'"></betsnaps-tenant-content>
            </div>
        </div>
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div class="ui fluid flexauto item tiny separatorless menu" *ngIf="arenaMenu.length > 1">
                <a href="#" class="item" *ngFor="let menu of arenaMenu" [class.active]="menu.active" (click)="arenaMenuChange(menu.key, $event);">
                    {{menu.translatekey | translate | uppercase}}
                </a>
            </div>

            <div class="ui vertical borderless segment center aligned padding-t-0"
                 *ngIf="currentUser && invitedGameList && invitedGameList?.results.length > 0 && activeTab === 'mysnaps'">
                <button type="button"
                        class="ui basic fluid button margin-0"
                        (click)="openInvitedGamesModal()">
                    {{'ARENA.show-invited-snaps' | translate | uppercase}}
                </button>
                <ng-template let-context let-modal="modal" #invitedGamesModalTemplate>
                    <div class="big header">
                        {{'ARENA.invited-modal_heading' | translate | uppercase}}
                    </div>
                    <div class="scrolling content">
                        <div class="ui vertical borderless very padded segment" *ngIf="processInvitedList">
                            <div class="ui active centered inline text loader">
                                {{'GENERAL.loadertext' | translate}}
                            </div>
                        </div>
                        <div *ngIf="!processInvitedList">
                            <div class="ui horizontal divider margin-t-0">
                                {{invitedGameList.total}} {{invitedGameList.total | i18nPlural:translations.labelTranslations.snapplural}}
                            </div>
                            <betsnaps-game-card-loader *ngFor="let invitedGame of invitedGameList.results"
                                                [game]="invitedGame"
                                                [currentUser]="currentUser"
                                                (onGameCardClick)="modal.approve();"
                                                class="game-card-wrap">
                            </betsnaps-game-card-loader>
                        </div>
                    </div>
                </ng-template>
            </div>

            <div class="ui vertical borderless segment center aligned padding-t-0" *ngIf="updatedDataAvailable">
                <button type="button"
                        class="ui basic icon fluid button margin-0"
                        (click)="reloadData()">
                    <i class="refresh icon"></i> {{'ARENA.refresh_games_btn' | translate}}
                </button>
            </div>

            <div class="ui vertical borderless segment center aligned padding-0 activefilters"
                 *ngIf="(sportFilters.length > 0 && activeSportFilter().length > 0) || activeSnapOptions().length > 0">
                <div class="ui label" *ngFor="let sportFilter of activeSportFilter()">
                    {{sportFilter.sport.sport_desc | dbtranslation:'sports_desc':sportFilter.sport.translations | uppercase}}
                    <i class="close icon" (click)="sportFilter.active = false; sportFilterUpdate();"></i>
                </div>
                <div class="ui label" *ngFor="let optionsFilter of activeSnapOptions()">
                    {{optionsFilter.translatekey | translate | uppercase}}
                    <i class="close icon" (click)="optionsFilter.active = false; snapOptionsFilterUpdate();"></i>
                </div>
                <button type="button" class="ui icon tiny button" (click)="removeActiveFilters()">
                    <i class="clear icon"></i>
                </button>
            </div>

            <div class="ui vertical borderless very padded segment" *ngIf="processList">
                <div class="ui active centered inline text loader on-body-bg">
                    {{'GENERAL.loadertext' | translate}}
                </div>
            </div>

            <div *ngIf="!showEmptyArenaAd && !processList && gameList">

                <div class="ui horizontal divider text-on-body-bg"
                     [class.margin-t-0]="arenaMenu.length < 2 && activeSportFilter().length < 1 && activeSnapOptions().length < 1">
                    <button type="button"
                            class="ui circular calltoaction2 icon button {{(!tenantService.hasSidebarLeftSkyscraperAd()) ? 'mobile only tablet only' : ''}}"
                            *ngIf="tenantService.tenantData.configuration.show_arena_filters"
                            (click)="openFilterModal()">
                        <i class="filter list large icon"></i>
                    </button>
                    {{gameList.total}} {{gameList.total | i18nPlural:translations.labelTranslations.snapplural}}
                </div>

                <a routerLink="/createsnap" class="ui fluid large button mobile only margin-b-15"
                   *ngIf="currentUser && tenantService.tenantData.configuration.show_createbetsnaps">
                    <i class="add circle big icon"></i>{{'ROUTEDATA.PAGETITLES.createsnap' | translate | uppercase}}
                </a>

                <betsnaps-game-list [gameList]="gameList"
                                    [currentUser]="currentUser"
                                    [adPositions]="adPositions"
                                    [showHeaderDivider]="false">
                </betsnaps-game-list>

                <div class="ui basic segment center aligned"
                     *ngIf="gameList?.results.length < gameList.total"
                     #loadMoreButton>
                    <button type="button"
                            class="ui button margin-0"
                            [class.loading]="processLoadMore"
                            (click)="loadMore()">
                        {{'GENERAL.load-more_btn' | translate}}
                    </button>
                </div>
            </div>

            <div class="ui vertical borderless very padded segment"
                 *ngIf="!processList && processHistoryList && activeTab === 'mysnaps'">
                <div class="ui active centered inline text loader on-body-bg">
                    {{'GENERAL.loadertext' | translate}}
                </div>
            </div>

            <div *ngIf="!showEmptyArenaAd && !processList && !processHistoryList && historyGameList && activeTab === 'mysnaps'">
                <betsnaps-game-list [gameList]="historyGameList"
                                    [currentUser]="currentUser"
                                    [isHistory]="true"
                                    [showCount]="false">
                </betsnaps-game-list>

                <div class="ui basic segment center aligned"
                     *ngIf="historyGameList?.results.length < historyGameList.total"
                     #loadMoreHistoryButton>
                    <button type="button"
                            class="ui button margin-0"
                            [class.loading]="processHistoryLoadMore"
                            (click)="loadMoreHistory()">
                        {{'GENERAL.load-more_btn' | translate}}
                    </button>
                </div>
            </div>
            <div [hidden]="!showEmptyArenaAd">
                <ng-container #emptyArenaPlaceholder></ng-container>
            </div>

            <ng-template let-context let-modal="modal" #filterModalTemplate
                         *ngIf="(gameList || historyGameList) && tenantService.tenantData.configuration.show_arena_filters">
                <div class="big header">
                    <i class="filter list big icon"></i> {{'ARENA.FILTER.modal-heading' | translate | uppercase}}
                </div>
                <div class="scrolling content">
                    <div class="ui secondary borderless segment" *ngIf="sportFilters && sportFilters.length > 0">
                        <fui-accordion [closeOthers]="false">
                            <fui-accordion-panel [(isOpen)]="sportFilterOpen">
                                <div title>
                                    <i class="filter list icon"
                                       [class.primary]="sportFilterOpen"></i> {{'ARENA.FILTER.SPORT.heading' | translate | uppercase}}
                                    <i class="keyboard arrow right icon large"
                                       [ngClass]="{'clockwise rotated': sportFilterOpen}"></i>
                                </div>
                                <div content>
                                    <div class="ui tiny list">
                                        <div class="item" *ngFor="let sportFilter of sportFilters">
                                            <fui-checkbox name="sport"
                                                          [(ngModel)]="sportFilter.active"
                                                          (checkChange)="sportFilterUpdate(sportFilter)">
                                                {{sportFilter.sport.sport_desc | dbtranslation:'sports_desc':sportFilter.sport.translations | uppercase}}
                                            </fui-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </fui-accordion-panel>
                        </fui-accordion>
                    </div>
                    <div class="ui secondary borderless segment">
                        <fui-accordion [closeOthers]="false">
                            <fui-accordion-panel [(isOpen)]="snapOptionsFilterOpen">
                                <div title>
                                    <i class="filter list icon large"
                                       [class.primary]="snapOptionsFilterOpen"></i> {{'ARENA.FILTER.SNAPOPTIONS.heading' | translate | uppercase}}
                                    <i class="keyboard arrow right icon large"
                                       [ngClass]="{'clockwise rotated': snapOptionsFilterOpen}"></i>
                                </div>
                                <div content>
                                    <div class="ui tiny list">
                                        <div class="item" *ngFor="let option of snapOptionsFilter">
                                            <fui-checkbox name="competition"
                                                          [(ngModel)]="option.active"
                                                          (checkChange)="snapOptionsFilterUpdate(option)">
                                                {{option.translatekey | translate | uppercase}}
                                            </fui-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </fui-accordion-panel>
                        </fui-accordion>
                    </div>
                </div>
                <div class="actions">
                    <button type="button"
                            class="ui button"
                            (click)="modal.deny()">
                        {{'ARENA.FILTER.modal_btn' | translate | uppercase}}
                    </button>
                </div>
            </ng-template>
        </div>
        <div class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar general-right-sidebar" #rightSidebar>
            <div class="sticky-container" #rightSidebarSticky>
                <betsnaps-sidebar-create-snap
                    *ngIf="currentUser && tenantService.tenantData.configuration.show_createbetsnaps"></betsnaps-sidebar-create-snap>
                <betsnaps-sidebar-tenant-content></betsnaps-sidebar-tenant-content>
            </div>
        </div>
    </div>
</div>
