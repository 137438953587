/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * RefreshTokenGrant
 */
export interface LeagueOAuth2ServerGrantRefreshTokenGrant { 
    /**
     * Refresh Grant Type
     */
    grant_type: string;
    /**
     * Client ID
     */
    client_id: number;
    /**
     * Client Secret
     */
    client_secret: string;
    /**
     * Refresh Token
     */
    refresh_token: string;
}

