<div class="ui centered relaxed grid container">
    <div class="sixteen wide mobile fourteen wide tablet twelve wide computer center aligned column">

        <div class="center aligned">
            <img [src]="loginModalImage" [alt]="" class="ui centered medium image" />
        </div>

        <div class="ui borderless vertical very padded segment margin-0 padding-b-0">
            <form class="ui form" novalidate
                  [formGroup]="loginForm"
                  (ngSubmit)="onSubmit()"
                  [class.error]="loginForm.invalid && submitted && !processForm">

                <div class="field">
                    <div [ngClass]="'ui ' + ((facebookLoginEnabled && googleLoginEnabled) ? 'two' : 'one') + ' column grid'">
                        <div class="column center aligned" *ngIf="facebookLoginEnabled">
                            <button type="button"
                                    class="ui facebook social fluid button"
                                    (click)="initiateSocialSignIn('facebook')"
                                    [class.loading]="(processSocialLogin === 'facebook')"
                                    [disabled]="(processSocialLogin !== null || processForm)">
                                <i class="fa fa-facebook-square icon"></i><span class="display-inline-block vertical-align-middle">
                                    FACEBOOK
                                </span>
                            </button>
                        </div>
                        <div class="column center aligned" *ngIf="googleLoginEnabled">
                            <button type="button"
                                    *ngIf="!isIOSPrivateBrowser"
                                    class="ui google social fluid button margin-0"
                                    (click)="initiateSocialSignIn('google')"
                                    [class.loading]="(processSocialLogin === 'google')"
                                    [disabled]="(processSocialLogin !== null || processForm)">
                                <img [src]="'./assets/img/google-signin-logo.png'" [alt]=""
                                     height="16" width="16"
                                     class="display-inline-block vertical-align-middle iconimage"/><span class="display-inline-block vertical-align-middle">
                                        GOOGLE
                                 </span>
                            </button>
                            <button type="button"
                                    *ngIf="isIOSPrivateBrowser"
                                    class="ui google social fluid button margin-0 opacity-50"
                                    fuiPopup
                                    popupText="{{'REGISTER.gplus_login_ioschrome_disabled_txt' | translate}}"
                                    popupTrigger="manual" [popupInverted]=false popupPlacement="bottom left" #popup="fuiPopup"
                                    (click)="popup.toggle()">
                                <img [src]="'./assets/img/google-signin-logo.png'" [alt]=""
                                     height="16" width="16"
                                     class="display-inline-block vertical-align-middle iconimage"/><span class="display-inline-block vertical-align-middle">
                                    GOOGLE
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="field" *ngIf="tenantService.isB2b && tenantService.getB2bWebsiteLoginUrl()">
                    <div class="column center aligned">
                        <button type="button"
                                class="ui primary social fluid button"
                                [class.disabled]="(processForm || processSocialLogin !== null)"
                                (click)="goToB2bLogin()">
                            <img *ngIf="tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.B2B_LOGIN_BUTTON)"
                                 [src]="tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.B2B_LOGIN_BUTTON).media_url"
                                 class="display-inline-block vertical-align-middle iconimage"
                                 [class.margin-0]="('LOGIN.b2b_login_btn' | translate) === '-'" />
                            <span *ngIf="('LOGIN.b2b_login_btn' | translate) !== '-'"
                                  class="display-inline-block vertical-align-middle">
                                {{'LOGIN.b2b_login_btn' | translate}}
                            </span>
                        </button>
                    </div>
                </div>
                <div class="ui horizontal divider" *ngIf="facebookLoginEnabled || googleLoginEnabled || (tenantService.isB2b && tenantService.getB2bWebsiteLoginUrl())">{{'LOGIN.divider_label' | translate}}</div>
                <div class="field" *ngIf="b2cSignupMethod === b2cSignupMethodEnum.EMAIL">
                    <div class="field" [class.disabled]="(processForm || processSocialLogin !== null)">
                        <input id="login_email"
                               type="text"
                               placeholder="{{'LOGIN.username-email_field' | translate}}"
                               formControlName="email" required/>
                        <div class="ui pointing red basic label"
                             *ngIf="loginForm.get('email').invalid && loginForm.get('email').hasError('required') &&
                                         (loginForm.get('email').dirty || loginForm.get('email').touched)">
                            {{'LOGIN.ERRORS.email_field_required' | translate}}
                        </div>
                    </div>
                </div>
                <betsnaps-phone-field *ngIf="b2cSignupMethod === b2cSignupMethodEnum.PHONE"
                                      [processForm]="processForm"
                                      [phoneSignupAllowedCountryCodes]="phoneSignupAllowedCountryCodes"
                                      [form]="loginForm"></betsnaps-phone-field>
                <div class="field" [class.disabled]="(processForm || processSocialLogin !== null)">
                    <div class="ui icon input">
                        <input type="password"
                               id="login_password"
                               name="password"
                               #passwordInput
                               placeholder="{{'LOGIN.password_field' | translate}}"
                                   formControlName="password" required/>
                        <i (click)="passwordVisible = togglePasswordVisibility(passwordInputElement)"
                           class="link icon fa"
                           [class.fa-eye]="!passwordVisible"
                           [class.fa-eye-slash]="passwordVisible"></i>
                    </div>
                    <div class="ui pointing red basic label"
                         *ngIf="loginForm.get('password').invalid && loginForm.get('password').hasError('required') &&
                                         (loginForm.get('password').dirty || loginForm.get('password').touched)">
                        {{'LOGIN.ERRORS.password_field_required' | translate}}
                    </div>
                    <div class="ui left aligned padding-t-5">
                        <div class="label tiny color-grey">
                            {{'LOGIN.password_forgot_label' | translate}}
                            <a *ngIf="b2cSignupMethod === b2cSignupMethodEnum.EMAIL" class="text small font-bold padding-l-5"
                               (click)="showView('reset-password-email')">
                                {{'LOGIN.password_forgot_btn' | translate}}
                            </a>
                            <a *ngIf="b2cSignupMethod === b2cSignupMethodEnum.PHONE" class="text small font-bold padding-l-5"
                               (click)="showView('reset-password-phone')">
                                {{'LOGIN.password_forgot_btn' | translate}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10 padding-b-0">
                    <button class="ui large fluid button margin-0"
                            type="submit"
                            [class.loading]="processForm"
                            [disabled]="processForm || loginForm.invalid || processSocialLogin !== null">
                        {{'LOGIN.login_btn' | translate | uppercase}}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="ui sixteen wide center aligned column padding-b-0">
        <div class="ui row divider"></div>
        <div class="label large color-grey">
            {{'LOGIN.no_account_label' | translate}}
            <a class="text font-bold padding-l-5 nowrap"
               (click)="showView('register')">
                {{'LOGIN.no_account_btn' | translate}}
            </a>
        </div>
    </div>
</div>
