import {
    Component,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {DOCUMENT} from '@angular/common';
import {GoogleAnalyticsService} from '../../../shared';

@Component({
    selector: 'betsnaps-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styles: []
})
export class CopyToClipboardComponent implements OnInit, OnDestroy {

    @HostBinding('class') @Input() componentClass: string = '';

    private componentAlive = true;
    public showcopyinfo: boolean = false;

    @Input() analyticsEventCategory: string = 'copy-to-clipboard';
    @Input() analyticsEventAction: string = 'copy';
    @Input() analyticsEventLabel: string = 'general text';
    @Input() value: string;

    constructor(@Inject(DOCUMENT) private document: any,
                public googleAnalyticsService: GoogleAnalyticsService) {
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.componentAlive = false;
    }

    showCopyInformation() {
        this.showcopyinfo = true;
        const self = this;
        setTimeout(() => {
            self.showcopyinfo = false;
        }, 3000);
    }

}
