import {
    Component,
    ElementRef,
    HostListener, Inject,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {
    ChatService,
    GoogleAnalyticsService,
    WindowRef
} from '../../../shared';
import {
    AppHttpResponsesChatsChatHttpResponse as ChatHttpResponse
} from '../../../api';
import {takeWhile} from 'rxjs/operators';
import {AngularPageVisibilityService} from 'angular-page-visibility-v2';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'betsnaps-chat-bottom-fixed',
    templateUrl: './chat-bottom-fixed.component.html',
    styles: []
})
export class ChatBottomFixedComponent implements OnInit, OnDestroy {

    private componentAlive = true;

    @Input() chatId: number;
    @Input() betsnapDetailView: boolean = false;
    @ViewChild('chatContainer') chatContainer: ElementRef;

    private nativeWindow: Window;
    private windowWidth: number = 0;
    private windowHeight: number = 0;

    public chatVisible: boolean = false;

    public initialLoadFinished: boolean = false;
    public chat: ChatHttpResponse;

    private currWindowScrollPosition = 0;

    constructor(@Inject(DOCUMENT) private document: any,
                public chatService: ChatService,
                public googleAnalyticsService: GoogleAnalyticsService,
                private pageVisibilityService: AngularPageVisibilityService,
                private renderer: Renderer2,
                private windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;
    }

    ngOnInit() {
        this.windowWidth = this.nativeWindow.window.innerWidth;
        this.windowHeight = this.nativeWindow.window.innerHeight;

        this.chatService.initialLoadFinished$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((initialLoadFinished: boolean) => {
                    if (initialLoadFinished === true) {
                        this.initialLoadFinished = initialLoadFinished;
                    }
                }
            );

        this.chatService.chat$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((chat: ChatHttpResponse) => {
                this.chat = chat;
            }
        );
    }

    ngOnDestroy(): void {
        this.componentAlive = false;
    }

    toggleChat() {
        if (this.chatVisible) {
            this.chatVisible = false;
            this.removeNoScrollClass();
        } else {
            this.chatVisible = true;
            this.addNoScrollClass();
        }
    }

    addNoScrollClass() {
        // save current scroll position
        this.currWindowScrollPosition = Math.floor(
            this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
        );
        this.renderer.addClass(this.document.documentElement, 'noscroll');
    }

    removeNoScrollClass() {
        this.renderer.removeClass(this.document.documentElement, 'noscroll');
        // scroll to saved position
        this.nativeWindow.window.scrollTo(0, this.currWindowScrollPosition || 0);
    }

    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    resizeListeners() {
        this.windowWidth = this.nativeWindow.window.innerWidth;
        this.windowHeight = this.nativeWindow.window.innerHeight;
    }

}
