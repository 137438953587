<div class="ui fluid card">
    <div class="ui fluid profile-header-image image" *ngIf="profileHeaderImage">
        <img [src]="profileHeaderImage" [alt]="" />
    </div>
    <div class="content" [class.with-profile-header-image]="profileHeaderImage">
        <div>
            <div class="center aligned margin-b-15">
                <div class="user-avatar-wrap">
                    <betsnaps-user-avatar-loader [user]="this.currentUser"
                                                 [imageSize]="'medium'">
                    </betsnaps-user-avatar-loader>
                    <button type="button"
                            class="ui circular mini icon user-avatar-button button margin-0"
                            (click)="profileMediaV3ModalComponent.openMediaV3Modal()">
                        <i class="edit icon"></i>
                    </button>
                </div>
                <div class="margin-t-10 margin-b-0">
                    <strong class="font-very-bold text-huge" *ngIf="currentUser.username">
                        {{this.currentUser.username}}
                    </strong>
                    <div>
                        <button type="button" *ngIf="!currentUser.username || !currentUser.is_username_confirmed"
                                class="ui mini button margin-0"
                                (click)="myModalService.openUsernameInputModal()">
                            {{'PROFILE.EDIT.set_username_button' | translate}}
                        </button>
                    </div>
                </div>
                <div *ngIf="showBalance" class="one column middle aligned row margin-b-10 margin-t-10">
                    <div class="column center aligned text small">
                        {{'PROFILE.OVERVIEW.current_balance' | translate}}
                    </div>
                    <div class="column center aligned padding-t-0">
                        <div class="ui currency tertiary label margin-l-0">
                    <span class="currency-wrap">
                        <span class="currency-symbol"
                              [class.longsymbol]="currentUser.wallets[0].currency.symbol.length > 1">
                            {{currentUser.wallets[0].currency.symbol}}
                        </span>
                    </span>
                            <span class="detail">{{currentUser.wallets[0].balance | number:'1.2-2' }}</span>
                        </div>
                    </div>
                </div>
                <div class="one column middle aligned row">
                    <div class="column center aligned">
                        <a routerLink="/profile/wallet" class="ui calltoaction2 button">
                            {{'PROFILE.OVERVIEW.MENU.wallet_details' | translate | uppercase}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<betsnaps-profile-media-modal-v3 #profileMediaV3ModalComponent (profileMediaV3ModalSetSuccess)="profileMediaV3ModalComponent.closeMediaV3Modal()"></betsnaps-profile-media-modal-v3>
