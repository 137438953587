import {
    AppHttpResponsesSportsDataMatchHttpResponse,
    AppHttpResponsesSportsDataMatchMarketHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse,
} from '../../api';

export interface WidgetBet {
    points: number;
    match: AppHttpResponsesSportsDataMatchHttpResponse;
    market: AppHttpResponsesSportsDataMatchMarketHttpResponse;
    outcome: AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse;
}
