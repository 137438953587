import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse} from '../../../api';
import {IPopup} from '../../../shared';
import {DynamicComponentLoaderService} from '../../../shared/services/dynamic-component-loader.service';
import {DynamicComponentHostDirective} from '../../../shared/directives/dynamic-component-host.directive';
import {AUserAvatarComponent} from './a-user-avatar-component';

@Component({
    selector: 'betsnaps-user-avatar-loader',
    template: `
        <ng-template dynamicComponentHost></ng-template>
    `
})
export class UserAvatarLoaderComponent implements OnInit, OnChanges, OnDestroy {

    @Input() user?: PlayerPublicHttpResponse;
    @Input() userId?: number;
    @Input() fetchPublicPlayer: boolean = false;
    @Input() clickAble = true;
    @Input() imageSize = 'small';
    @Input() addClass = '';
    @Input() closePopup: IPopup;
    @Input() showFriendshipStateIcon: boolean = false;
    @Input() notificationIconClass: string = null;

    @Output() onUserAvatarClick: EventEmitter<any> = new EventEmitter();

    private componentRef: ComponentRef<AUserAvatarComponent>;
    @ViewChild(DynamicComponentHostDirective, { static: true }) dynamicComponentHost: DynamicComponentHostDirective;

    constructor(private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private dynamicComponentLoaderService: DynamicComponentLoaderService) {
    }

    ngOnInit() {

        const componentToLoad = this.dynamicComponentLoaderService.getComponent('user-avatar');

        if (componentToLoad) {
            const componentFactory: ComponentFactory<AUserAvatarComponent> = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);

            const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent<AUserAvatarComponent>(componentFactory);

            this.setComponentValues();

        }
    }

    setComponentValues(): void {
        this.componentRef.instance.user = this.user;
        this.componentRef.instance.userId = this.userId;
        this.componentRef.instance.fetchPublicPlayer = this.fetchPublicPlayer;
        this.componentRef.instance.clickAble = this.clickAble;
        this.componentRef.instance.imageSize = this.imageSize;
        this.componentRef.instance.notificationIconClass = this.notificationIconClass;
        this.componentRef.instance.addClass = this.addClass;
        this.componentRef.instance.closePopup = this.closePopup;
        this.componentRef.instance.showFriendshipStateIcon = this.showFriendshipStateIcon;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.componentRef) {
            this.setComponentValues();
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    userAvatarClicked() {
        if (this.componentRef) {
            this.componentRef.instance.userAvatarClicked();
        }
    }


}
