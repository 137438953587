<div class="ui middle aligned centered grid margin-t-0 margin-b-0">
    <div class="two column row padding-0">
        <div class="column element-clickable left aligned" fuiPopup
             [popupTemplate]="popupBetMarketOutcome"
             popupTrigger="outsideClick">
            <div [ngClass]="'font-very-bold color-light-grey truncate ' + (game.points_engine !== gamePointsEngineEnum.PREDICTION ? 'text-small' : 'text-large')">
                {{userBet.market.market_name | dbtranslation:'name':userBet.market.translations}} | {{((authenticationService.currentUser && userBet.user_id === authenticationService.currentUser.id) ? 'GENERAL.BETS.your-tip' : 'GENERAL.BETS.tip') | translate | uppercase}}: {{userBet.outcome.outcome_name | dbtranslation:'name':userBet.outcome.translations}}
            </div>
        </div>
        <div class="column right aligned">
            <div *ngIf="!isBetFinished() && game.points_engine !== gamePointsEngineEnum.PREDICTION">
                {{'GENERAL.BETS.amount' | translate}}: <strong class="font-very-bold">{{userBet.point | number:'1.2-2'}}</strong>
            </div>
            <div *ngIf="isBetFinished()">
                <span class="ui small label margin-0"
                      [ngClass]="{
                        'black': calculateUserBetSaldo() === 0,
                        'positive': calculateUserBetSaldo() >= 0,
                        'negative': calculateUserBetSaldo() < 0
                      }">
                    <span *ngIf="userBet.status === userBetStateEnum.CANCELLED || (userBet.status === userBetStateEnum.SETTLED && userBet.void_factor === 1)">
                        {{game.points_engine === gamePointsEngineEnum.PREDICTION ?
                        ('GENERAL.BETS.STATI.refunded_prediction_points_engine' | translate | uppercase) :
                        ('GENERAL.BETS.STATI.refunded' | translate | uppercase)}}
                        <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">:&nbsp;</span>
                    </span>
                    <span *ngIf="userBet.status === userBetStateEnum.CASHED_OUT">
                        {{'GENERAL.BETS.STATI.cashedout' | translate | uppercase}}
                        <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">:&nbsp;</span>
                    </span>
                    <span *ngIf="userBet.status === userBetStateEnum.ROLLED_BACK_BET_CANCELLATION">
                        {{'GENERAL.BETS.STATI.rollback_betcancel' | translate | uppercase}}
                        <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">:&nbsp;</span>
                    </span>
                    <span *ngIf="userBet.status === userBetStateEnum.ROLLED_BACK_BET_SETTLEMENT">
                        {{'GENERAL.BETS.STATI.rollback_betsettlement' | translate | uppercase}}
                        <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">:&nbsp;</span>
                    </span>
                    <span *ngIf="userBet.status === userBetStateEnum.AUTO_CASHED_OUT">
                        {{'GENERAL.BETS.STATI.auto_cashedout' | translate | uppercase}}
                        <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">:&nbsp;</span>
                    </span>
                    <span *ngIf="userBet.status === userBetStateEnum.FAILED">
                        {{'GENERAL.BETS.STATI.failed' | translate | uppercase}}
                        <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">:&nbsp;</span>
                    </span>
                    <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION || (game.points_engine === gamePointsEngineEnum.PREDICTION && userBet.status === userBetStateEnum.SETTLED)">{{userBet.total_point | number:'1.2-2'}}</span>
                </span>
            </div>
        </div>
    </div>
    <div class="one column row padding-b-0" *ngIf="!isBetFinished()">
        <div class="center aligned column"
             *ngIf="userBet.status === userBetStateEnum.ACTIVE && game.points_engine !== gamePointsEngineEnum.PREDICTION">
            <button *ngIf="userBet.status === userBetStateEnum.ACTIVE"
                    type="button"
                    class="ui fluid button cashout-button"
                    (click)="cashOutBet()"
                    [class.loading]="processCashOut"
                    [disabled]="(processCashOut || !isCashoutAllowed())"
                    [ngClass]="{
                        'grey disabled': (processCashOut || !isCashoutAllowed()),
                        'positive': (calculateUserBetSaldo() > 0),
                        'negative': (calculateUserBetSaldo() < 0),
                        'opacity-50': (isCashoutAllowed() && !isCashoutAvailable())
                      }">
                <span class="cashout-progress cashout-progress-1">
                    <span class="cashout-progress-inner" [style.width.%]="cashOutProgress1"></span>
                </span>
                <span class="cashout-progress cashout-progress-2">
                    <span class="cashout-progress-inner" [style.width.%]="cashOutProgress2"></span>
                </span>
                <span class="button-text">
                    <strong class="text-large display-block margin-b-5">
                        <span *ngIf="(isCashoutAllowed() && isCashoutAvailable())">{{'GENERAL.BETS.cashout' | translate | uppercase}}&nbsp;</span>
                        <span *ngIf="(isCashoutAllowed() && !isCashoutAvailable())">{{'GENERAL.BETS.no_cashout' | translate | uppercase}}&nbsp;</span>
                        {{cashoutPoints | number:'1.2-2'}}
                    </strong>
                    {{'GENERAL.BETS.possible-win' | translate}}: {{(userBet.point * userBet.bet_outcome) | number:'1.2-2'}}
                </span>
            </button>
        </div>
        <div class="center aligned column" *ngIf="userBet.status === userBetStateEnum.PENDING">
            <button type="button" class="ui grey disabled fluid button">
                <span class="display-block">
                    <span class="ui active inline mini inverted loader display-inline-block margin-0"></span>
                </span>
                {{'GENERAL.BETS.bet_verifying' | translate}}
            </button>
        </div>
        <div class="center aligned column" *ngIf="userBet.status === userBetStateEnum.FAILED">
            <button (click)="removeFailedBet()" type="button"
                    class="ui fluid button negative">
                <strong class="text-large display-block">{{'GENERAL.BETS.bet_placement' | translate}}</strong>
                {{'GENERAL.BETS.STATI.failed' | translate | lowercase}}
            </button>
        </div>
        <div class="center aligned column" *ngIf="userBet.status === userBetStateEnum.CASHOUT_PENDING">
            <button type="button"
                    class="ui fluid button disabled"
                    [ngClass]="{
                        'positive': (calculateUserBetSaldo() > 0),
                        'negative': (calculateUserBetSaldo() < 0),
                        'grey': (calculateUserBetSaldo() === 0)
                        }">
                <span class="display-block">
                    <span class="ui active inline mini inverted loader display-inline-block margin-0"></span>
                </span>
                {{'GENERAL.BETS.cashout_verifying' | translate}}
            </button>
        </div>
    </div>
</div>

<div class="center aligned text-mini color-grey margin-t-10"
     *ngIf="preGameStartOddsCanChange && game.show_pre_game_start_odd_can_change_info && (userBet.status === userBetStateEnum.ACTIVE || userBet.status === userBetStateEnum.CASHOUT_PENDING)">
    {{'GENERAL.BETS.pre_game_start_odds_can_change' | translate}}
</div>

<ng-template let-popup #popupBetMarketOutcome>
    <div class="content betmarketoutcome-popup">
        <p class="margin-b-5">
            {{userBet.market.market_name | dbtranslation:'name':userBet.market.translations}}
        </p>
        <p class="margin-0">
            <strong>{{((authenticationService.currentUser && userBet.user_id === authenticationService.currentUser.id) ? 'GENERAL.BETS.your-tip' : 'GENERAL.BETS.tip') | translate | uppercase}}: {{userBet.outcome.outcome_name | dbtranslation:'name':userBet.outcome.translations}}</strong>
        </p>
    </div>
</ng-template>
