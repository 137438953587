import {Component} from '@angular/core';
import {AdService, AuthenticationService, BetsnapdetailService, GoogleAnalyticsService} from '../../../../../shared';
import {Router} from '@angular/router';
import {ABetsnapdetailVendorGamesComponent} from '../a-betsnapdetail-vendor-games-component';
import {takeWhile} from 'rxjs/operators';
import {AppHttpResponsesVendorGamesGameVendorGameHttpResponse as GameVendorGameHttpResponse} from '../../../../../api';

@Component({
    selector: 'betsnaps-betsnapdetail-vendor-games-default',
    templateUrl: './betsnapdetail-vendor-games-default.component.html',
    styles: []
})
export class BetsnapdetailVendorGamesDefaultComponent extends ABetsnapdetailVendorGamesComponent {

    public gameVendorGamesMenu = [
        {
            'key': 'all',
            'translatekey': 'BETSNAPDETAIL.VENDORGAMES.MENU.all',
            'count': 0,
            'active': true
        }, {
            'key': 'upcoming',
            'translatekey': 'BETSNAPDETAIL.VENDORGAMES.MENU.upcoming',
            'count': 0,
            'active': false
        }, {
            'key': 'running',
            'translatekey': 'BETSNAPDETAIL.VENDORGAMES.MENU.running',
            'count': 0,
            'active': false
        }, {
            'key': 'closed',
            'translatekey': 'BETSNAPDETAIL.VENDORGAMES.MENU.closed',
            'count': 0,
            'active': false
        }
    ];
    protected currentMenuKey: string = 'all';

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected router: Router,
                protected adService: AdService) {
        super(
            betsnapdetailService,
            authenticationService,
            googleAnalyticsService,
            router,
            adService
        );

        this.betsnapdetailService.gameVendorGames$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameListResults: GameVendorGameHttpResponse[]) => {
                    this.gameVendorGames = gameVendorGameListResults;
                    if (gameVendorGameListResults) {
                        this.setMenuCounts();
                        this.filterVendorGames(this.currentMenuKey);
                        this.processGameVendorGames = false;
                    } else {
                        this.processGameVendorGames = true;
                        this.filteredGameVendorGames = null;
                        for (const menuitem of this.gameVendorGamesMenu) {
                            menuitem.count = 0;
                        }
                    }
                }
            );
    }

    protected setMenuCounts() {
        for (const menuitem of this.gameVendorGamesMenu) {
            if (menuitem.key === 'all') {
                menuitem.count = this.gameVendorGames.length;
            } else if (menuitem.key === 'upcoming') {
                menuitem.count = this.upComingGameVendorGames().length;
            } else if (menuitem.key === 'running') {
                menuitem.count = this.runningGameVendorGames().length;
            } else if (menuitem.key === 'closed') {
                menuitem.count = this.closedGameVendorGames().length;
            }
        }
    }

    filterVendorGames(key) {
        if (key === 'all') {
            this.filteredGameVendorGames = this.gameVendorGames;
        } else if (key === 'upcoming') {
            this.filteredGameVendorGames = this.upComingGameVendorGames();
        } else if (key === 'running') {
            this.filteredGameVendorGames = this.runningGameVendorGames();
        } else if (key === 'closed') {
            this.filteredGameVendorGames = this.closedGameVendorGames();
        }
    }

    gameVendorGamesMenuChange(activatekey: string) {
        this.processGameVendorGames = true;
        for (const menuitem of this.gameVendorGamesMenu) {
            menuitem.active = (menuitem.key === activatekey);
        }
        this.currentMenuKey = activatekey;
        this.filterVendorGames(activatekey);
        this.processGameVendorGames = false;

        this.googleAnalyticsService.trackEvent('game - vendorgames', 'filter', 'vendorgames - ' + activatekey);
    }

}
