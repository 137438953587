/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesTranslationsMediaTranslationHttpResponse } from './appHttpResponsesTranslationsMediaTranslationHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TenantLandingpageFeatureHttpResponse
 */
export interface AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Landingpage ID
     */
    tenant_landingpage_id: number;
    /**
     * Feature Title
     */
    title: string;
    /**
     * Feature Description
     */
    description: string;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    /**
     * Item Order
     */
    order: number;
    /**
     * Active
     */
    active: boolean;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Media Translations
     */
    media_translations?: Array<AppHttpResponsesTranslationsMediaTranslationHttpResponse>;
}

