import { OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeHttpResponse as CalculatedPrizeHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse as CalculatedPrizeListHttpResponse,
    AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse as SimpleTenantRankingListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../api';
import {
    AuthenticationService,
    BetsnapdetailService,
    CurrencyFormatPipe,
    DbTranslationsPipe,
    DecimalPrizePipe,
    ErrorService,
    GameBetPlacementOptionEnum,
    GameCashoutOptionEnum,
    GameHelpers,
    GamePointsEngineEnum,
    GoogleAnalyticsService,
    HintService,
    MyModalService, MyNotificationsService,
    MyTranslateService,
    OneSignalService,
    PrizeStructureRankRange,
    ProfileService,
    TenantImageTypeEnum,
    TenantService
} from '../../../../shared';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {TranslateService} from '@ngx-translate/core';
import {NotificationType} from 'angular2-notifications';
import {take, takeWhile} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Directive()
export abstract class ABetsnapdetailDetailsComponent implements OnInit, OnDestroy {

    protected componentAlive = true;
    public currentUser: PlayerHttpResponse;

    public game: GameHttpResponse;
    public prizeStructure: CalculatedPrizeListHttpResponse;
    public prizeStructureRanges: Array<PrizeStructureRankRange>;
    public relatedTenantRankings: SimpleTenantRankingListHttpResponse;
    public processJoin: boolean = false;
    public processLeave: boolean = false;
    public joinApproveVisible: boolean = false;

    protected winnerPrize: CalculatedPrizeHttpResponse;

    public showH2hWinnerInformation: boolean = false;
    public h2hWinnerInformation: string;

    public gameBetPlacementOptionEnum = GameBetPlacementOptionEnum;
    public gameCashoutOptionEnum = GameCashoutOptionEnum;

    public gameNotificationsForm: UntypedFormGroup = new UntypedFormGroup({
        game_notifications_not_joined: new UntypedFormControl('1')
    });
    public showGameNotificationsCheckbox: boolean = false;
    public processGameNotificationChange: boolean = false;

    @ViewChild('modalLeaveTemplate') modalLeaveTemplate: ModalTemplate<null, string, string>;
    public modalLeave = null;

    public GameHelpers = new GameHelpers();
    public advancedButtonType: string;
    public advancedButtonTitleLabel: string;

    public showPrizes: boolean = true;

    public showLoader: boolean = false;
    public gamePointsEngineEnum = GamePointsEngineEnum;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected myModalService: MyModalService,
                public translations: MyTranslateService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                public tenantService: TenantService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected profileService: ProfileService,
                protected errorService: ErrorService,
                protected decimalPrizePipe: DecimalPrizePipe,
                protected currencyFormatPipe: CurrencyFormatPipe,
                protected oneSignalService: OneSignalService) {

        this.showPrizes = this.authenticationService.showPrizes();

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (gameHttpResponse) {

                        if (this.showPrizes && !this.prizeStructure) {
                            this.betsnapdetailService.getPrizeStructure(
                                gameHttpResponse
                            );
                        }

                        // on first load or if there is a game_state change
                        if ((!this.game || !this.relatedTenantRankings || (this.game && this.game.game_state !== gameHttpResponse.game_state))) {
                            this.getGameTenantRankings(this.tenantService.tenantData.id, gameHttpResponse.game_unique_id);
                        }

                        this.showLoader = false;
                    }

                    this.game = gameHttpResponse;

                    if (gameHttpResponse) {
                        if (this.isH2HGame() && !this.showH2hWinnerInformation) {
                            this.generateH2HWinnerInformation();
                        }
                    }

                    this.advancedButtonType = this.GameHelpers.getAdvancedButtonType(gameHttpResponse);
                    this.advancedButtonTitleLabel = this.GameHelpers.getAdvancedButtonTitleLabel(this.advancedButtonType);
                }
            );


        this.betsnapdetailService.prizeStructure$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (calculatedPrizeListHttpResponse: CalculatedPrizeListHttpResponse) => {
                    if (this.showPrizes) {
                        this.prizeStructure = calculatedPrizeListHttpResponse;
                        if (this.prizeStructure) {
                            this.winnerPrize = this.prizeStructure.results.find(
                                (prizeStructure: CalculatedPrizeHttpResponse) => prizeStructure.rank === 1);

                            if (this.isH2HGame()) {
                                this.generateH2HWinnerInformation();
                            }

                            this.buildPrizeStructureRanges();
                        }
                    }
                }
            );

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (userData && !this.currentUser) {
                        this.hintService.checkForHintToDisplay('betsnapdetail-details');

                        if (this.oneSignalService.webPushAvailableForTenant) {
                            this.showGameNotificationsCheckbox = true;
                        }

                        // refresh game data if user is retrieved
                        if (this.game.is_current_user_joined === null) {
                            this.betsnapdetailService.refreshGameData();
                        }
                    }
                    this.currentUser = userData;
                });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    isH2HGame(): boolean {
        return (this.game.competition_type === 1 || this.game.competition_type === 3);
    }

    generateH2HWinnerInformation() {
        this.translateService.get(['GENERAL.GAMES.h2h_prize_pool_label', 'GENERAL.LABELS.bet_bonus'])
            .pipe(take(1)).subscribe(
                translation => {

                    const betBonusLabel = translation['GENERAL.LABELS.bet_bonus'];
                    this.h2hWinnerInformation = '';

                    if (this.game.is_gift_game === 0) {

                        let cashPrizeStructure = null;

                        if (this.game.primary_cash_prize_structure_index !== null) {
                            cashPrizeStructure = this.game.prize_structures[this.game.primary_cash_prize_structure_index];
                        }

                        if (this.game.competition_type === 3) {
                            if (cashPrizeStructure) {
                                this.h2hWinnerInformation += this.currencyFormatPipe.transform(this.decimalPrizePipe.transform(this.game.h2h_prize_pool, 2, undefined), cashPrizeStructure.currency.symbol);
                            } else {
                                this.h2hWinnerInformation += this.decimalPrizePipe.transform(this.game.h2h_prize_pool, 2, undefined);
                            }
                        }

                        if (this.game.competition_type === 1) {
                            if (cashPrizeStructure) {
                                this.h2hWinnerInformation += this.currencyFormatPipe.transform(this.decimalPrizePipe.transform(this.game.prize_pool, 2, undefined), cashPrizeStructure.currency.symbol);
                            } else {
                                this.h2hWinnerInformation += this.decimalPrizePipe.transform(this.game.prize_pool, 2, undefined);
                            }
                        }

                        if (cashPrizeStructure && cashPrizeStructure.is_bonus_money === true) {
                            this.h2hWinnerInformation += ' ' + betBonusLabel;
                        }

                        // if there is more than 1 prize structure we assume that its a combined prize structure
                        if (this.game.prize_structures.length > 1) {
                            if (this.winnerPrize) {
                                this.h2hWinnerInformation += ' + ' + this.getTranslatedWinnerTenantPrize(this.winnerPrize);
                            }
                        }
                    }

                    if (this.game.is_gift_game === 1) {
                        if (this.winnerPrize) {
                            this.h2hWinnerInformation += this.getTranslatedWinnerTenantPrize(this.winnerPrize);
                        }
                    }

                    this.showH2hWinnerInformation = true;
                });
    }

    getTranslatedWinnerTenantPrize(prize: AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeHttpResponse): string {

        let tenant_prize_title, tenant_prize_translations;

        // first look if tenant_prize is set, if not use tenant_prize_template
        if (prize.tenant_prize) {
            tenant_prize_title =  prize.tenant_prize.title;
            tenant_prize_translations =  prize.tenant_prize?.translations;
        } else {
            tenant_prize_title =  prize.tenant_prize_template?.title;
            tenant_prize_translations =   prize.tenant_prize_template?.translations;
        }

        return new DbTranslationsPipe(this.authenticationService).transform(tenant_prize_title, 'title', tenant_prize_translations);
    }

    protected buildPrizeStructureRanges() {
        if (this.prizeStructure) {
            const prizeStructureRanges = [];

            let prizeRangeStartRank = null;

            for (let i = 0; i < this.prizeStructure.results.length; i++) {

                const currentPrize = this.prizeStructure.results[i];

                let nextPrize = null;
                if ((i + 1) < this.prizeStructure.results.length) {
                    nextPrize = this.prizeStructure.results[i + 1];
                }

                // first look it tenant_prize is set, if not use tenant_prize_template
                const currentPrizeTenantPrize = (currentPrize.tenant_prize) ? currentPrize.tenant_prize.title : currentPrize.tenant_prize_template?.title;
                let nextPrizeTenantPrize = null;
                if (nextPrize) {
                    nextPrizeTenantPrize = (nextPrize.tenant_prize) ? nextPrize.tenant_prize.title : nextPrize.tenant_prize_template?.title;
                }

                if (currentPrize.rank > 3) {

                    if (nextPrize && currentPrize.prize_amount === nextPrize.prize_amount && currentPrizeTenantPrize === nextPrizeTenantPrize) {
                        if (!prizeRangeStartRank) {
                            prizeRangeStartRank = currentPrize.rank;
                        }
                    } else {

                        let fromRank = null;
                        let toRank = null;

                        if (prizeRangeStartRank) {
                            fromRank = prizeRangeStartRank;
                            toRank = currentPrize.rank;
                            prizeRangeStartRank = null;
                        } else {
                            fromRank = currentPrize.rank;
                            toRank = fromRank;
                        }
                        prizeStructureRanges.push({
                            'fromRank': fromRank,
                            'toRank': toRank,
                            'prize_amount': currentPrize.prize_amount,
                            'tenant_prize': currentPrize.tenant_prize,
                            'tenant_prize_template': currentPrize.tenant_prize_template
                        });
                    }

                } else {
                    prizeStructureRanges.push({
                        'fromRank': currentPrize.rank,
                        'toRank': currentPrize.rank,
                        'prize_amount': currentPrize.prize_amount,
                        'tenant_prize': currentPrize.tenant_prize,
                        'tenant_prize_template': currentPrize.tenant_prize_template
                    });
                }
            }

            this.prizeStructureRanges = prizeStructureRanges;
        }
    }

    private getGameTenantRankings(tenantId: number, gameUniqueId: string) {
        this.betsnapdetailService.getGameTenantRankings(tenantId, gameUniqueId)
            .pipe(take(1))
            .subscribe({
                next: (tenantRankingListHttpResponse: SimpleTenantRankingListHttpResponse) => {
                    this.relatedTenantRankings = tenantRankingListHttpResponse;
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    leaveGame() {
        if (this.currentUser && this.game.is_current_user_joined && !this.processLeave) {
            this.processLeave = true;
            this.betsnapdetailService.leaveGame(this.game, this.currentUser.id)
                .pipe(take(1))
                .subscribe({
                    next: (leaderboardUserHttpResponse: LeaderboardUserHttpResponse) => {
                        this.game.game_notifications_subscribed = false;
                        this.modalLeave.approve();
                        this.processLeave = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processLeave = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    public openLeaveModal() {
        const modalLeaveConfig = new TemplateModalConfig<null, string, string>(this.modalLeaveTemplate);
        modalLeaveConfig.size = ModalSize.Normal;
        modalLeaveConfig.isBasic = true;
        this.modalLeave = this.myModalService.openModal(modalLeaveConfig);
    }

    public modalRedirect(link: string, modal = null) {
        if (modal) {
            modal.approve();
        }
        this.router.navigate([link], {relativeTo: this.route});
        return;
    }

    public checkGameNotificationChange() {
        if (this.game && this.oneSignalService.webPushAvailableForTenant) {
            this.processGameNotificationChange = true;
            if (this.game.game_notifications_subscribed) {
                this.profileService.subscribeForGameNotifications(
                    this.game.game_unique_id,
                    this.currentUser.id
                ).pipe(take(1))
                .subscribe({
                    next: () => {
                        if (this.game) {
                            this.game.game_notifications_subscribed = true;
                            this.oneSignalService.subscribeForWebPushNotifications();
                        }
                        this.processGameNotificationChange = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        if (this.game) {
                            this.game.game_notifications_subscribed = false;
                        }
                        this.processGameNotificationChange = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
            } else {
                this.profileService.unsubscribeFromGameNotifications(
                    this.game.game_unique_id,
                    this.currentUser.id
                ).pipe(take(1))
                .subscribe({
                    next: () => {
                        if (this.game) {
                            this.game.game_notifications_subscribed = false;
                        }
                        this.processGameNotificationChange = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        if (this.game) {
                            this.game.game_notifications_subscribed = true;
                        }
                        this.processGameNotificationChange = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
            }
        }
    }

    getLeaderBoardUserProperty(game: GameHttpResponse, property: string) {
        const leaderBoardUser = game.game_user;

        if (leaderBoardUser.game_user_group && property === 'is_participation_valid') {
            leaderBoardUser.game_user_group['is_participation_valid'] = leaderBoardUser['is_participation_valid'];
        }
        if (leaderBoardUser.game_user_group && property === 'is_eliminated') {
            leaderBoardUser.game_user_group['is_eliminated'] = leaderBoardUser['is_eliminated'];
        }

        return (game.competition_type === 1 || this.betsnapdetailService.isTournament) ? leaderBoardUser[property] : leaderBoardUser.game_user_group[property];
    }

    public prizeStructureVisible(): boolean {
        if (!this.showPrizes) {
            return false;
        }

        // Hide Prize Structure if no prizes
        const prizePool = (this.game.is_guaranteed) ? this.game.prize_pool : this.game.current_prize_pool;
        return (
            this.prizeStructure &&
            !(prizePool === 0 && this.game.is_gift_game === 0 && this.betsnapdetailService.isTournament)
        );
    }
}
