import {NgModule} from '@angular/core';

import {SharedModule} from '../shared.module';
import {NgxCookieBannerModule} from 'ngx-cookie-banner-gg';
import {SocialLoginModule} from '@abacritt/angularx-social-login';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {AngularPageVisibilityModule} from 'angular-page-visibility-v2';

import {LayoutComponent} from './layout/layout.component';
import {NotfoundComponent} from './views/notfound/notfound.component';
import {LoginComponent} from './views/login/login.component';
import {B2bLoginComponent} from './views/login/b2b-login.component';
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {RegisterVerifyComponent} from './views/register/register-verify.component';
import {NewUserInviteComponent} from './views/new-user-invite/new-user-invite.component';
import {B2bLoginInitializeComponent} from './views/login/b2b-login-initialize.component';
import {MaintenanceComponent} from './views/maintenance/maintenance.component';
import {RegisterComponent} from './views/register/register.component';
import {DeleteAccountComponent} from './views/delete-account/delete-account.component';
import {MaintenanceFallbackComponent} from './views/maintenance/maintenance-fallback/maintenance-fallback.component';
import {HomeComponent} from './views/home/home.component';
import {LandingpageFallbackComponent} from './views/home/landingpage-fallback/landingpage-fallback.component';
import {LandingpageDefaultComponent} from './views/home/landingpage-default/landingpage-default.component';
import {LandingpageDefaultPublisherWidgetComponent} from './views/home/landingpage-default-publisher-widget/landingpage-default-publisher-widget.component';
import {LandingpageBetfoxComponent} from './views/home/landingpage-betfox/landingpage-betfox.component';
import {LandingpageBwinComponent} from './views/home/landingpage-bwin/landingpage-bwin.component';
import {LandingpageBwinPremiumComponent} from './views/home/landingpage-bwin-premium/landingpage-bwin-premium.component';
import {LandingpageBwinBelgiumComponent} from './views/home/landingpage-bwin-belgium/landingpage-bwin-belgium.component';
import {LandingpageBwinGreeceComponent} from './views/home/landingpage-bwin-greece/landingpage-bwin-greece.component';
import {LandingpageBwinCanadaComponent} from './views/home/landingpage-bwin-canada/landingpage-bwin-canada.component';
import {LandingpageEsoccerstarComponent} from './views/home/landingpage-esoccerstar/landingpage-esoccerstar.component';
import {LandingpageFcCologneComponent} from './views/home/landingpage-fccologne/landingpage-fccologne.component';
import {HeaderLoaderComponent} from './layout/header/header-loader.component';
import {HeaderDefaultComponent} from './layout/header/header-default/header-default.component';
import {HeaderV3Component} from './layout/header/header-v3/header-v3.component';
import {LandingpageBwinGermanyComponent} from './views/home/landingpage-bwin-germany/landingpage-bwin-germany.component';
import {MainLoaderComponent} from './layout/main/main-loader.component';
import {MainDefaultComponent} from './layout/main/main-default/main-default.component';
import {MainV3Component} from './layout/main/main-v3/main-v3.component';

@NgModule({
    imports: [
        SharedModule,
        SocialLoginModule,
        SimpleNotificationsModule.forRoot(),
        NgxCookieBannerModule.forRoot({
            cookieName: "betsnap-game-cookie-law",
        }),
        AngularPageVisibilityModule
    ],
    declarations: [
        LayoutComponent,
        HeaderLoaderComponent,
        HeaderDefaultComponent,
        HeaderV3Component,
        MainLoaderComponent,
        MainDefaultComponent,
        MainV3Component,
        HomeComponent,
        LoginComponent,
        B2bLoginComponent,
        B2bLoginInitializeComponent,
        RegisterComponent,
        RegisterVerifyComponent,
        ResetPasswordComponent,
        DeleteAccountComponent,
        NewUserInviteComponent,
        LandingpageFallbackComponent,
        LandingpageDefaultComponent,
        LandingpageDefaultPublisherWidgetComponent,
        LandingpageBetfoxComponent,
        LandingpageBwinComponent,
        LandingpageBwinPremiumComponent,
        LandingpageBwinGermanyComponent,
        LandingpageBwinBelgiumComponent,
        LandingpageBwinGreeceComponent,
        LandingpageBwinCanadaComponent,
        LandingpageEsoccerstarComponent,
        LandingpageFcCologneComponent,
        MaintenanceComponent,
        MaintenanceFallbackComponent,
        NotfoundComponent
    ],
    exports: [
        LayoutComponent
    ]
})
export class UiModule {
}
