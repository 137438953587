import {Component, OnInit} from '@angular/core';
import {AlertMessage} from '../../../../shared/interfaces';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AppHttpRequestsUsersUserPhoneSignupResendVerificationRequest as PhoneSignupResendVerificationRequest,
    SignupApi
} from '../../../../api';
import {AuthenticationService, ErrorService, GoogleAnalyticsService, TenantService} from '../../../../shared';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ALoginRegisterComponent} from '../a-login-register.component';

@Component({
    selector: 'betsnaps-resend-phone-verification-form',
    templateUrl: './resend-phone-verification-form.component.html',
    styles: []
})
export class ResendPhoneVerificationFormComponent extends ALoginRegisterComponent implements OnInit {

    public processForm: boolean = false;
    public alertMessage: AlertMessage;
    public showAlert: boolean = false;

    public initResendVerification: UntypedFormGroup = new UntypedFormGroup({
        phone_country_code: new UntypedFormControl('', [Validators.required]),
        phone: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9|\/|\(|\)|\x20]+'), Validators.minLength(4), Validators.maxLength(13)]),
    });

    constructor(private signupApi: SignupApi,
                private authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                private errorService: ErrorService,
                protected googleAnalyticsService: GoogleAnalyticsService) {
        super(tenantService, googleAnalyticsService);
        this.loadPhoneSignupAllowedCountryCodes();
        this.initResendVerification.patchValue({'phone_country_code': this.phoneSignupAllowedCountryCodes[0]});
    }

    onSubmit() {
        this.processForm = true;
        this.showAlert = false;
        this.alertMessage = null;

        const phoneNumber = this.initResendVerification.value.phone_country_code + this.initResendVerification.value.phone;
        const resendVerificationRequest: PhoneSignupResendVerificationRequest = {
            tenant_id: this.tenantService.tenantData.id,
            phone: phoneNumber
        };

        this.signupApi.apiUsersSignupPhoneResendverificationPut(
            resendVerificationRequest
        ).pipe(take(1))
        .subscribe({
            next: () => {
                this.processForm = false;
                this.showView('phone-verification', new Map([
                    ['phone', phoneNumber],
                    ['initialAlertMessage', {
                        type: 'success',
                        messagetranslatekey: 'RESENDPHONEVERIFICATION.initiate_successfully_title',
                        messagetxttranslatekey: 'RESENDPHONEVERIFICATION.initiate_successfully'
                    }]
                ]));
                this.initResendVerification.reset();
            },
            error: (err: HttpErrorResponse) => {
                this.processForm = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    ngOnInit() {
    }

}
