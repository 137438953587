/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameInvitationHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameInvitationHttpResponse { 
    /**
     * Game Invitation Id
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * Sender User Id
     */
    sender_user_id: number;
    /**
     * Receiver User Id
     */
    receiver_user_id: number;
}

