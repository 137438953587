/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresPrizeTypeHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresPrizeCategoryHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TenantPrizeStructureTemplateHttpResponse
 */
export interface AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Master Prize Categories Id
     */
    prize_category_id: number;
    prize_category?: AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryHttpResponse;
    /**
     * Master Prize Type Id
     */
    prize_type_id: number;
    prize_type?: AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeHttpResponse;
    /**
     * Media Id
     */
    media_id?: number;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    /**
     * Title
     */
    title: string;
    /**
     * Title Game
     */
    title_in_game: string;
    /**
     * Prize Title in Game
     */
    prize_title_in_game?: string;
    /**
     * Minimum Players
     */
    minimum_players: number;
    /**
     * Order
     */
    order: number;
    /**
     * Is Active
     */
    is_active: boolean;
    /**
     * Is Admin Only
     */
    is_admin_only: boolean;
    /**
     * Is H2H
     */
    is_h2h: boolean;
    /**
     * Is Singleplayer
     */
    is_singleplayer: boolean;
    /**
     * Is Bonus Money
     */
    is_bonus_money: boolean;
    /**
     * Is Battle Royal
     */
    is_battle_royal?: boolean;
    /**
     * Is Keep It Friendly
     */
    is_keep_it_friendly: boolean;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
    /**
     * Ranks
     */
    ranks?: Array<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

