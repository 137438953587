<ng-template let-context let-modal="modal" #gameShareModalTemplate>
    <div class="game-share-modal header">
        <i class="share alternat icon big"></i>{{'GENERAL.GAMES.SHAREGAME.heading' | translate | uppercase}}
    </div>
    <div class="scrolling content padding-0">
        <div class="ui basic borderless segment center aligned margin-0">
            <img class="ui fluid image padding-0 margin-0"
                 [src]="gameShareData.game_share_img_url"
                 *ngIf="gameShareData.game_share_img_url"/>
            <h2 class="margin-b-0">
                <betsnaps-game-name [game]="gameShareData.game"></betsnaps-game-name>
            </h2>
            <p>{{gameShareData.game.start_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }}
            </p>

            <div *ngIf="isWrapperWindow" class="ui segment vertical borderless segment padding-0 margin-0">
                <button type="button"
                        class="ui circular calltoaction2 icon button"
                        (click)="callWrapperShare(); googleAnalyticsService.trackEvent('game', 'share', 'wrapper app - ' + game.game_unique_id)">
                    <i class="share alternat icon"></i>
                </button>
            </div>

            <div *ngIf="!isWrapperWindow" class="ui segment vertical borderless segment padding-0 margin-0">
                <button type="button"
                        class="ui circular facebook icon button"
                        shareButton="facebook"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('game', 'share', 'facebook - ' + game.game_unique_id)">
                    <i class="fa fa-facebook icon"></i>
                </button>
                <button type="button"
                        class="ui circular twitter icon button"
                        shareButton="twitter"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('game', 'share', 'twitter - ' + game.game_unique_id)">
                    <i class="fa fa-twitter icon"></i>
                </button>
                <button type="button"
                        class="ui circular whatsapp icon button"
                        shareButton="whatsapp"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('game', 'share', 'whatsapp - ' + game.game_unique_id)"
                        *ngIf="mobileDetect.device_detection() == 'desktop'">
                    <i class="fa fa-whatsapp icon"></i>
                </button>
                <a [href]="'whatsapp://send?text=' + shareUrl | safeUrl"
                   class="ui circular whatsapp icon button"
                   (click)="googleAnalyticsService.trackEvent('game', 'share', 'whatsapp - ' + game.game_unique_id)"
                   *ngIf="mobileDetect.device_detection() == 'mobile' || mobileDetect.device_detection() == 'tablet'">
                    <i class="fa fa-whatsapp icon"></i>
                </a>
                <button type="button" class="ui circular black icon button margin-0"
                        (click)="googleAnalyticsService.trackEvent('game', 'share', 'copied to clipboard - ' + game.game_unique_id)"
                        ngxClipboard
                        [cbContent]="shareUrl"
                        (cbOnSuccess)="showCopyInformation()">
                    <i class="fa fa-link icon"></i>
                </button>
            </div>
            <p *ngIf="showcopyinfo" class="margin-t-5">
                <i class="icon check color-positive"></i> {{'GENERAL.LABELS.NOTIFICATIONS.link_copied' | translate}}
            </p>
        </div>
        <div class="ui basic borderless segment center aligned game-share-modal-friends margin-0"
             *ngIf="userFriends.length > 0 || friendsInvited">
            <p>{{'GENERAL.GAMES.SHAREGAME.share_with_friends' | translate | uppercase}}</p>
            <div *ngIf="!friendsInvited">
                <div class="ui four column centered tight grid">
                    <div *ngFor="let userFriend of userFriends" class="column">
                        <div class="ui center aligned column">
                            <div class="ui link" (click)="selectFriend(userFriend)">
                                <div>
                                    <div class="img-wrap">
                                        <img *ngIf="userFriend.friend_user.media_url_small"
                                             class="ui circular bordered centered tiny image"
                                             [src]="userFriend.friend_user.media_url_small"
                                             [ngClass]="{'primary': selectedUserFriends.includes(userFriend)}"/>
                                        <span class="image-overlay primary circular"
                                              *ngIf="selectedUserFriends.includes(userFriend)">
                                            <i class="check small icon"></i>
                                        </span>
                                    </div>
                                </div>
                                <span class="truncate"
                                      [ngClass]="{'text-primary font-bold': selectedUserFriends.includes(userFriend)}">
                                    {{userFriend.friend_user.username}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui segment vertical borderless segment padding-b-0 margin-0" *ngIf="noSelectionError">
                    <p class="color-negative margin-0">{{'GENERAL.GAMES.SHAREGAME.no_friends_selected' | translate}}</p>
                </div>
            </div>
            <div class="ui success message" *ngIf="friendsInvited">
                <p>{{'GENERAL.GAMES.SHAREGAME.friends_invited_success' | translate}}</p>
                <button type="button"
                        class="ui fluid button"
                        *ngIf="userFriends.length > 0"
                        (click)="friendsInvited = false">
                    {{'GENERAL.GAMES.SHAREGAME.invite-more-friends_btn' | translate | uppercase}}
                </button>
            </div>
        </div>
    </div>
    <div class="actions" *ngIf="userFriends.length > 0 && !friendsInvited">
        <button type="button"
                class="ui black fluid button margin-l-0"
                (click)="inviteFriends()"
                [class.loading]="processInvite"
                [class.disabled]="processInvite"
                [disabled]="processInvite">
            {{'GENERAL.GAMES.SHAREGAME.invite-friends_btn' | translate | uppercase}}
        </button>
    </div>
</ng-template>
