import {Injectable} from '@angular/core';
import {DebugService} from './debug.service';
import {ErrorService} from './error.service';
import {
    AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchHttpResponse as SimpleSnapGameMatchHttpResponse,
    AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchListHttpResponse as SimpleSnapGameMatchListHttpResponse,
    AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchHttpResponse as SimpleSnapMatchHttpResponse,
    AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchMarketHttpResponse as SimpleSnapMatchMarketHttpResponse,
    GamesApi
} from '../../api';
import {take} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {SimpleSnapBetInterface, SimpleSnapDataInterface, SimpleSnapMatchDataListInterface} from '../interfaces';
import {TenantService} from './tenant.service';


@Injectable({
    providedIn: 'root'
})
export class SimpleSnapService {

    private simpleSnapDataSubject = new BehaviorSubject<SimpleSnapDataInterface[]>([]);
    public simpleSnapData$ = this.simpleSnapDataSubject.asObservable();

    protected runningRequests: Map<string, any> = new Map<string, any>();

    constructor(private errorService: ErrorService,
                private debugService: DebugService,
                private tenantService: TenantService,
                protected gamesApi: GamesApi) {

    }

    public loadMatchesDataIfNecessary(gameUniqueId: string, lang: string): void {

        if (this.hasDataForGameAndLang(gameUniqueId, lang)) {
            return;
        }

        const requestIdentifier: string = gameUniqueId + '_' + lang;

        if (this.runningRequests.has(requestIdentifier)) {
            return;
        }

        this.runningRequests.set(requestIdentifier, null);

        this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdSimpleMatchesGet(
            this.tenantService.tenantData.id,
            gameUniqueId,
            lang
        ).pipe(take(1))
        .subscribe({
            next: (gameMatchList: SimpleSnapGameMatchListHttpResponse) => {
                this.runningRequests.delete(requestIdentifier);
                if (gameMatchList) {
                    const matchList: SimpleSnapMatchHttpResponse[] = [];

                    // Create Match Entry for each Match Market, eg: if a Match contains 2 markets we will create 2 Match Entries
                    if (gameMatchList.count > 0) {
                        gameMatchList.results.forEach((gameMatch: SimpleSnapGameMatchHttpResponse) => {
                            gameMatch.match.markets.forEach((matchMarket: SimpleSnapMatchMarketHttpResponse) => {
                                const newMatch = cloneDeep(gameMatch.match);
                                newMatch.markets = [];
                                newMatch.markets.push(matchMarket);
                                matchList.push(newMatch);
                            });
                        });
                    }

                    const matchDataList: SimpleSnapMatchDataListInterface = {
                        lang: lang,
                        matchList: matchList
                    };

                    this.updateOrCreateMatchDataList(gameUniqueId, lang, matchDataList);

                }
            },
            error: (err: HttpErrorResponse) => {
                this.runningRequests.delete(requestIdentifier);
                const matchDataList: SimpleSnapMatchDataListInterface = {
                    lang: lang,
                    matchList: []
                };
                this.updateOrCreateMatchDataList(gameUniqueId, lang, matchDataList);
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    protected updateOrCreateMatchDataList(gameUniqueId: string, lang: string, matchDataList: SimpleSnapMatchDataListInterface): void {

        const currentData = this.simpleSnapDataSubject.value;
        const existingDataEntry = this.getDataForGame(gameUniqueId);
        if (existingDataEntry) {
            if (this.hasDataForGameAndLang(gameUniqueId, lang)) {
                const index = existingDataEntry.matchData.findIndex((simpleSnapMatchDataList: SimpleSnapMatchDataListInterface) => (simpleSnapMatchDataList.lang === lang));
                if (existingDataEntry[index]) {
                    existingDataEntry[index] = matchDataList;
                }
            } else {
                existingDataEntry.matchData.push(matchDataList);
            }
        } else {
            const matchData: Array<SimpleSnapMatchDataListInterface> = [];
            matchData.push(matchDataList);
            const newDataEntry: SimpleSnapDataInterface = {
                gameUniqueId: gameUniqueId,
                matchData: matchData,
                bets: []
            };
            currentData.push(newDataEntry);
        }

        this.simpleSnapDataSubject.next(currentData);
    }

    protected hasDataForGameAndLang(gameUniqueId: string, lang: string): boolean {
        if (this.simpleSnapDataSubject.value) {
            const simpleSnapDataForGame = this.getDataForGameAndLang(gameUniqueId, lang);
            if (simpleSnapDataForGame) {
                return true;
            }
        }
        return false;
    }

    protected getDataForGame(gameUniqueId: string): SimpleSnapDataInterface | null {
        const currentSimpleSnapDataList = this.simpleSnapDataSubject.value;
        if (currentSimpleSnapDataList && currentSimpleSnapDataList.length > 0) {
            const simpleSnapDataForGame = currentSimpleSnapDataList.find(
                (simpleSnapData: SimpleSnapDataInterface) => (simpleSnapData.gameUniqueId === gameUniqueId)
            );
            return simpleSnapDataForGame;
        }

        return null;
    }

    protected getDataForGameAndLang(gameUniqueId: string, lang: string): SimpleSnapDataInterface | null {
        const simpleSnapDataForGame = this.getDataForGame(gameUniqueId);
        if (simpleSnapDataForGame) {
            const langData = simpleSnapDataForGame.matchData.find((simpleSnapMatchDataList: SimpleSnapMatchDataListInterface) => (simpleSnapMatchDataList.lang === lang));
            if (langData) {
                return simpleSnapDataForGame;
            }
        }

        return null;
    }

    public storeBet(gameUniqueId: string, matchId: number, marketId: number, specifierVal: string, outcomeId: number, oddDecimal: number): void {
        const simpleSnapBet: SimpleSnapBetInterface = {
            'gameUniqueId': gameUniqueId,
            'matchId': matchId,
            'marketId': marketId,
            'specifierVal': specifierVal,
            'outcomeId': outcomeId,
            'oddDecimal': oddDecimal
        };
        const simpleSnapDataForGame = this.getDataForGame(gameUniqueId);
        if (simpleSnapDataForGame) {
            const existingBet = this.getBet(gameUniqueId, matchId, marketId, specifierVal);
            if (existingBet) {
                existingBet.outcomeId = outcomeId;
                existingBet.oddDecimal = oddDecimal;
            } else {
                simpleSnapDataForGame.bets.push(simpleSnapBet);
            }
        }
    }

    public getBet(gameUniqueId: string, matchId: number, marketId: number, specifierVal: string): SimpleSnapBetInterface | null {
        const simpleSnapDataForGame = this.getDataForGame(gameUniqueId);
        if (simpleSnapDataForGame) {
            return simpleSnapDataForGame.bets.find((simpleSnapBet: SimpleSnapBetInterface) => (simpleSnapBet.matchId === matchId && simpleSnapBet.marketId === marketId && simpleSnapBet.specifierVal));
        }
        return null;
    }

    public getBets(gameUniqueId: string): SimpleSnapBetInterface[] | null {
        const simpleSnapDataForGame = this.getDataForGame(gameUniqueId);
        if (simpleSnapDataForGame) {
            return simpleSnapDataForGame.bets;
        }

        return null;
    }

    public resetBets(gameUniqueId: string): void {
        const simpleSnapDataForGame = this.getDataForGame(gameUniqueId);
        if (simpleSnapDataForGame) {
            simpleSnapDataForGame.bets = [];
        }
    }

    public resetDataForGame(gameUniqueId: string): void {
        const currentSimpleSnapDataList = this.simpleSnapDataSubject.value;
        if (currentSimpleSnapDataList && currentSimpleSnapDataList.length > 0) {
            const newSimpleSnapDataList = currentSimpleSnapDataList.filter((simpleSnapData: SimpleSnapDataInterface) => (simpleSnapData.gameUniqueId !== gameUniqueId));
            this.simpleSnapDataSubject.next(newSimpleSnapDataList);
        }
    }

    public resetData(): void {
        this.simpleSnapDataSubject.next([]);
    }

}
