import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse} from '../../../../api';
import {distinctUntilChanged, take, takeWhile} from 'rxjs/operators';
import {NotificationType} from 'angular2-notifications';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {usernameAlphabeticalValidator} from '../../../../shared/validators';
import {
    AuthenticationService,
    ErrorService,
    MyModalService,
    MyNotificationsService,
    TenantService
} from '../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';

@Component({
    selector: 'betsnaps-username-modal-view',
    templateUrl: './username-modal-view.component.html',
    styles: []
})
export class UsernameModalViewComponent implements OnInit {

    private componentAlive = true;

    @Output() usernameSetSuccess: EventEmitter<any> = new EventEmitter();

    @ViewChild('username', {static: true}) public usernameInput: ElementRef;

    public usernameForm: UntypedFormGroup = new UntypedFormGroup({
        username: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(30), usernameAlphabeticalValidator])
    });
    public processForm: boolean = false;

    public currentUser: PlayerHttpResponse;

    public showConsentText: boolean = false;
    public showUsernameInputNoticeAddition: boolean = false;

    constructor(private authenticationService: AuthenticationService,
                private errorService: ErrorService,
                private myNotificationsService: MyNotificationsService,
                private myModalService: MyModalService,
                private translateService: TranslateService,
                private tenantService: TenantService) {

        if (this.tenantService.tenantData.configuration.show_consent_text_in_username_modal) {
            this.showConsentText = true;
            this.usernameForm.addControl(
                'consent_checked', new UntypedFormControl(false, Validators.required)
            );
        }

    }

    ngOnInit() {

        this.usernameForm.get('username').valueChanges.pipe(
            takeWhile(() => this.componentAlive),
            distinctUntilChanged()
        ).subscribe((inputValue) => {
            // replace whitespace
            if (inputValue) {
                this.usernameForm.patchValue({'username': inputValue.replace(/\s/g, '')});
            }
        });

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (userData) {
                        this.currentUser = cloneDeep(userData);
                        this.usernameForm.patchValue({'username': this.currentUser.username});
                        setTimeout(() => {
                            this.usernameInput.nativeElement.select();
                        }, 100);
                    } else {
                        this.currentUser = null;
                    }
                }
            );

        // check if username input notice addition text is available
        this.translateService.get(['REGISTER.username_input_notice_addition'])
            .pipe(take(1)).subscribe(
            translation => {
                if (translation['REGISTER.username_input_notice_addition'] !== 'REGISTER.username_input_notice_addition') {
                    this.showUsernameInputNoticeAddition = true;
                }
            });
    }

    // sends a username set request
    public sendUsernameInput() {
        if (!this.processForm) {
            this.processForm = true;
            this.authenticationService.setUsername(this.usernameForm.value.username)
                .pipe(take(1))
                .subscribe({
                    next: (player: PlayerHttpResponse) => {
                        if (player.username) {
                            this.translateService.get(['REGISTER.username_set_successfully'])
                                .pipe(take(1)).subscribe(
                                translation => {
                                    this.authenticationService.setCurrentUser(player);

                                    this.usernameForm.reset();

                                    this.processForm = false;
                                    this.myNotificationsService.createNotificationToast('', translation['REGISTER.username_set_successfully'], NotificationType.Success);

                                    this.usernameSetSuccess.emit();
                                });
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processForm = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }
}
