/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpRequestsUsersFingerprintWriteRequest } from '../model/appHttpRequestsUsersFingerprintWriteRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersResetPasswordInitializePasswordEmailResetRequest } from '../model/appHttpRequestsUsersResetPasswordInitializePasswordEmailResetRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersResetPasswordInitializePasswordPhoneResetRequest } from '../model/appHttpRequestsUsersResetPasswordInitializePasswordPhoneResetRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersResetPasswordPasswordEmailResetRequest } from '../model/appHttpRequestsUsersResetPasswordPasswordEmailResetRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersResetPasswordPasswordPhoneResetRequest } from '../model/appHttpRequestsUsersResetPasswordPasswordPhoneResetRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserChangeEmailRequest } from '../model/appHttpRequestsUsersUserChangeEmailRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserChangePasswordRequest } from '../model/appHttpRequestsUsersUserChangePasswordRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserChangePhoneRequest } from '../model/appHttpRequestsUsersUserChangePhoneRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserInitializeEmailChangeRequest } from '../model/appHttpRequestsUsersUserInitializeEmailChangeRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserInitializePhoneChangeRequest } from '../model/appHttpRequestsUsersUserInitializePhoneChangeRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserSetPasswordRequest } from '../model/appHttpRequestsUsersUserSetPasswordRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserSettingsUpdateRequest } from '../model/appHttpRequestsUsersUserSettingsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserSocialConnectionRequest } from '../model/appHttpRequestsUsersUserSocialConnectionRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserUpdateRequest } from '../model/appHttpRequestsUsersUserUpdateRequest';
// eslint-disable-next-line
import { AppHttpResponsesNotificationsUserNotificationHttpResponse } from '../model/appHttpResponsesNotificationsUserNotificationHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesNotificationsUserNotificationListHttpResponse } from '../model/appHttpResponsesNotificationsUserNotificationListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersPlayerHttpResponse } from '../model/appHttpResponsesUsersPlayerHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersPlayerListHttpResponse } from '../model/appHttpResponsesUsersPlayerListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from '../model/appHttpResponsesUsersPlayerPublicHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersPlayerPublicListHttpResponse } from '../model/appHttpResponsesUsersPlayerPublicListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserSettingHttpResponse } from '../model/appHttpResponsesUsersUserSettingHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserTransactionListHttpResponse } from '../model/appHttpResponsesUsersUserTransactionListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserWalletHttpResponse } from '../model/appHttpResponsesUsersUserWalletHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserWalletListHttpResponse } from '../model/appHttpResponsesUsersUserWalletListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UsersApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Delete user, fulfilling EU-GDPR
     * delete User for EU-GDPR
     * @param delete_token Delete Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteUsersDeleteTokenPost(delete_token: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteUsersDeleteTokenPost(delete_token: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteUsersDeleteTokenPost(delete_token: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteUsersDeleteTokenPost(delete_token: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (delete_token === null || delete_token === undefined) {
            throw new Error('Required parameter delete_token was null or undefined when calling apiOperaminideleteUsersDeleteTokenPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/users/${this.configuration.encodeParam({name: "delete_token", value: delete_token, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get information of current player
     * Get information of current player
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPlayerinfoGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerHttpResponse>;
    public apiPlayerinfoGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiPlayerinfoGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiPlayerinfoGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/playerinfo`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all public users
     * Get all public users
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param searchstring Searchstring
     * @param only_friends Only Friends
     * @param exclude_friends Exclude Friends
     * @param user_ids User Ids (comma separated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicUsersGet(per_page?: number, page?: number, searchstring?: string, only_friends?: boolean, exclude_friends?: boolean, user_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerPublicListHttpResponse>;
    public apiPublicUsersGet(per_page?: number, page?: number, searchstring?: string, only_friends?: boolean, exclude_friends?: boolean, user_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerPublicListHttpResponse>>;
    public apiPublicUsersGet(per_page?: number, page?: number, searchstring?: string, only_friends?: boolean, exclude_friends?: boolean, user_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerPublicListHttpResponse>>;
    public apiPublicUsersGet(per_page?: number, page?: number, searchstring?: string, only_friends?: boolean, exclude_friends?: boolean, user_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (searchstring !== undefined && searchstring !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchstring, 'searchstring');
        }
        if (only_friends !== undefined && only_friends !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_friends, 'only_friends');
        }
        if (exclude_friends !== undefined && exclude_friends !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exclude_friends, 'exclude_friends');
        }
        if (user_ids !== undefined && user_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>user_ids, 'user_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/public/users`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerPublicListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get single public player info
     * Get single public player info
     * @param user_id User ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicUsersUserIdGet(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerPublicHttpResponse>;
    public apiPublicUsersUserIdGet(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerPublicHttpResponse>>;
    public apiPublicUsersUserIdGet(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerPublicHttpResponse>>;
    public apiPublicUsersUserIdGet(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiPublicUsersUserIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/public/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerPublicHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete user, fulfilling EU-GDPR
     * @param delete_token Delete Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersDeleteTokenDelete(delete_token: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersDeleteTokenDelete(delete_token: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersDeleteTokenDelete(delete_token: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersDeleteTokenDelete(delete_token: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (delete_token === null || delete_token === undefined) {
            throw new Error('Required parameter delete_token was null or undefined when calling apiUsersDeleteTokenDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "delete_token", value: delete_token, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Users
     * Get all Users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerListHttpResponse>;
    public apiUsersGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerListHttpResponse>>;
    public apiUsersGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerListHttpResponse>>;
    public apiUsersGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize a user password reset by email
     * Initialize a user password reset by email
     * @param app_http_requests_users_reset_password_initialize_password_email_reset_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersPasswordEmailInitializeresetPut(app_http_requests_users_reset_password_initialize_password_email_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordEmailResetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersPasswordEmailInitializeresetPut(app_http_requests_users_reset_password_initialize_password_email_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordEmailResetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersPasswordEmailInitializeresetPut(app_http_requests_users_reset_password_initialize_password_email_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordEmailResetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersPasswordEmailInitializeresetPut(app_http_requests_users_reset_password_initialize_password_email_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordEmailResetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_reset_password_initialize_password_email_reset_request === null || app_http_requests_users_reset_password_initialize_password_email_reset_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_reset_password_initialize_password_email_reset_request was null or undefined when calling apiUsersPasswordEmailInitializeresetPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/password/email/initializereset`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_reset_password_initialize_password_email_reset_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset a user password by email
     * Reset a user password by email
     * @param app_http_requests_users_reset_password_password_email_reset_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersPasswordEmailResetPut(app_http_requests_users_reset_password_password_email_reset_request: AppHttpRequestsUsersResetPasswordPasswordEmailResetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersPasswordEmailResetPut(app_http_requests_users_reset_password_password_email_reset_request: AppHttpRequestsUsersResetPasswordPasswordEmailResetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersPasswordEmailResetPut(app_http_requests_users_reset_password_password_email_reset_request: AppHttpRequestsUsersResetPasswordPasswordEmailResetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersPasswordEmailResetPut(app_http_requests_users_reset_password_password_email_reset_request: AppHttpRequestsUsersResetPasswordPasswordEmailResetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_reset_password_password_email_reset_request === null || app_http_requests_users_reset_password_password_email_reset_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_reset_password_password_email_reset_request was null or undefined when calling apiUsersPasswordEmailResetPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/password/email/reset`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_reset_password_password_email_reset_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize a user password reset by phone number
     * Initialize a user password reset by phone number
     * @param app_http_requests_users_reset_password_initialize_password_phone_reset_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersPasswordPhoneInitializeresetPut(app_http_requests_users_reset_password_initialize_password_phone_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordPhoneResetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersPasswordPhoneInitializeresetPut(app_http_requests_users_reset_password_initialize_password_phone_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordPhoneResetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersPasswordPhoneInitializeresetPut(app_http_requests_users_reset_password_initialize_password_phone_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordPhoneResetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersPasswordPhoneInitializeresetPut(app_http_requests_users_reset_password_initialize_password_phone_reset_request: AppHttpRequestsUsersResetPasswordInitializePasswordPhoneResetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_reset_password_initialize_password_phone_reset_request === null || app_http_requests_users_reset_password_initialize_password_phone_reset_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_reset_password_initialize_password_phone_reset_request was null or undefined when calling apiUsersPasswordPhoneInitializeresetPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/password/phone/initializereset`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_reset_password_initialize_password_phone_reset_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset a user password by phone number
     * Reset a user password by phone number
     * @param app_http_requests_users_reset_password_password_phone_reset_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersPasswordPhoneResetPut(app_http_requests_users_reset_password_password_phone_reset_request: AppHttpRequestsUsersResetPasswordPasswordPhoneResetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersPasswordPhoneResetPut(app_http_requests_users_reset_password_password_phone_reset_request: AppHttpRequestsUsersResetPasswordPasswordPhoneResetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersPasswordPhoneResetPut(app_http_requests_users_reset_password_password_phone_reset_request: AppHttpRequestsUsersResetPasswordPasswordPhoneResetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersPasswordPhoneResetPut(app_http_requests_users_reset_password_password_phone_reset_request: AppHttpRequestsUsersResetPasswordPasswordPhoneResetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_reset_password_password_phone_reset_request === null || app_http_requests_users_reset_password_password_phone_reset_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_reset_password_password_phone_reset_request was null or undefined when calling apiUsersPasswordPhoneResetPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/password/phone/reset`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_reset_password_password_phone_reset_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change user email with token
     * Change user email with token
     * @param user_id User Id
     * @param app_http_requests_users_user_change_email_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdChangeemailPut(user_id: number, app_http_requests_users_user_change_email_request: AppHttpRequestsUsersUserChangeEmailRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerHttpResponse>;
    public apiUsersUserIdChangeemailPut(user_id: number, app_http_requests_users_user_change_email_request: AppHttpRequestsUsersUserChangeEmailRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdChangeemailPut(user_id: number, app_http_requests_users_user_change_email_request: AppHttpRequestsUsersUserChangeEmailRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdChangeemailPut(user_id: number, app_http_requests_users_user_change_email_request: AppHttpRequestsUsersUserChangeEmailRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdChangeemailPut.');
        }
        if (app_http_requests_users_user_change_email_request === null || app_http_requests_users_user_change_email_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_change_email_request was null or undefined when calling apiUsersUserIdChangeemailPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/changeemail`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_change_email_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change an User Password
     * Change user password
     * @param user_id User Id
     * @param app_http_requests_users_user_change_password_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdChangepasswordPut.');
        }
        if (app_http_requests_users_user_change_password_request === null || app_http_requests_users_user_change_password_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_change_password_request was null or undefined when calling apiUsersUserIdChangepasswordPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/changepassword`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_change_password_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change user phone with token
     * Change user phone with token
     * @param user_id User Id
     * @param app_http_requests_users_user_change_phone_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdChangephonePut(user_id: number, app_http_requests_users_user_change_phone_request: AppHttpRequestsUsersUserChangePhoneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerHttpResponse>;
    public apiUsersUserIdChangephonePut(user_id: number, app_http_requests_users_user_change_phone_request: AppHttpRequestsUsersUserChangePhoneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdChangephonePut(user_id: number, app_http_requests_users_user_change_phone_request: AppHttpRequestsUsersUserChangePhoneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdChangephonePut(user_id: number, app_http_requests_users_user_change_phone_request: AppHttpRequestsUsersUserChangePhoneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdChangephonePut.');
        }
        if (app_http_requests_users_user_change_phone_request === null || app_http_requests_users_user_change_phone_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_change_phone_request was null or undefined when calling apiUsersUserIdChangephonePut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/changephone`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_change_phone_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Fingerprint
     * @param user_id User Id
     * @param app_http_requests_users_fingerprint_write_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFingerprintsPost(user_id: number, app_http_requests_users_fingerprint_write_request: AppHttpRequestsUsersFingerprintWriteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdFingerprintsPost(user_id: number, app_http_requests_users_fingerprint_write_request: AppHttpRequestsUsersFingerprintWriteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdFingerprintsPost(user_id: number, app_http_requests_users_fingerprint_write_request: AppHttpRequestsUsersFingerprintWriteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdFingerprintsPost(user_id: number, app_http_requests_users_fingerprint_write_request: AppHttpRequestsUsersFingerprintWriteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFingerprintsPost.');
        }
        if (app_http_requests_users_fingerprint_write_request === null || app_http_requests_users_fingerprint_write_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_fingerprint_write_request was null or undefined when calling apiUsersUserIdFingerprintsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/fingerprints`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_fingerprint_write_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single User
     * Get single User Information
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdGet(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerHttpResponse>;
    public apiUsersUserIdGet(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdGet(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdGet(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize a user deletion
     * Initialize a user deletion for EU-GDPR
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdInitializedeletePut(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdInitializedeletePut(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdInitializedeletePut(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdInitializedeletePut(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdInitializedeletePut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/initializedelete`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize a user email change
     * Initialize a user email change
     * @param user_id User Id
     * @param app_http_requests_users_user_initialize_email_change_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_user_initialize_email_change_request: AppHttpRequestsUsersUserInitializeEmailChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_user_initialize_email_change_request: AppHttpRequestsUsersUserInitializeEmailChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_user_initialize_email_change_request: AppHttpRequestsUsersUserInitializeEmailChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_user_initialize_email_change_request: AppHttpRequestsUsersUserInitializeEmailChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdInitializeemailchangePut.');
        }
        if (app_http_requests_users_user_initialize_email_change_request === null || app_http_requests_users_user_initialize_email_change_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_initialize_email_change_request was null or undefined when calling apiUsersUserIdInitializeemailchangePut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/initializeemailchange`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_initialize_email_change_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize a user phone change
     * Initialize a user phone change
     * @param user_id User Id
     * @param app_http_requests_users_user_initialize_phone_change_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdInitializephonechangePut(user_id: number, app_http_requests_users_user_initialize_phone_change_request: AppHttpRequestsUsersUserInitializePhoneChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdInitializephonechangePut(user_id: number, app_http_requests_users_user_initialize_phone_change_request: AppHttpRequestsUsersUserInitializePhoneChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdInitializephonechangePut(user_id: number, app_http_requests_users_user_initialize_phone_change_request: AppHttpRequestsUsersUserInitializePhoneChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdInitializephonechangePut(user_id: number, app_http_requests_users_user_initialize_phone_change_request: AppHttpRequestsUsersUserInitializePhoneChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdInitializephonechangePut.');
        }
        if (app_http_requests_users_user_initialize_phone_change_request === null || app_http_requests_users_user_initialize_phone_change_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_initialize_phone_change_request was null or undefined when calling apiUsersUserIdInitializephonechangePut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/initializephonechange`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_initialize_phone_change_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Notifications
     * Get all User Notifications
     * @param user_id User Id
     * @param is_read Is Read
     * @param is_shown_in_news Shown in News Counter
     * @param is_shown_in_friends Shown in Friends Counter
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdNotificationsGet(user_id: number, is_read?: boolean, is_shown_in_news?: boolean, is_shown_in_friends?: boolean, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesNotificationsUserNotificationListHttpResponse>;
    public apiUsersUserIdNotificationsGet(user_id: number, is_read?: boolean, is_shown_in_news?: boolean, is_shown_in_friends?: boolean, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesNotificationsUserNotificationListHttpResponse>>;
    public apiUsersUserIdNotificationsGet(user_id: number, is_read?: boolean, is_shown_in_news?: boolean, is_shown_in_friends?: boolean, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesNotificationsUserNotificationListHttpResponse>>;
    public apiUsersUserIdNotificationsGet(user_id: number, is_read?: boolean, is_shown_in_news?: boolean, is_shown_in_friends?: boolean, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdNotificationsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (is_read !== undefined && is_read !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_read, 'is_read');
        }
        if (is_shown_in_news !== undefined && is_shown_in_news !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_shown_in_news, 'is_shown_in_news');
        }
        if (is_shown_in_friends !== undefined && is_shown_in_friends !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_shown_in_friends, 'is_shown_in_friends');
        }
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notifications`;
        return this.httpClient.request<AppHttpResponsesNotificationsUserNotificationListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark all unread user notifications as read
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdNotificationsMarkallasreadPut(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdNotificationsMarkallasreadPut(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdNotificationsMarkallasreadPut(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdNotificationsMarkallasreadPut(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdNotificationsMarkallasreadPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notifications/markallasread`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update User Notification
     * @param user_id User Id
     * @param notification_id Notification Id
     * @param is_read Is Read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdNotificationsNotificationIdPut(user_id: number, notification_id: number, is_read?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesNotificationsUserNotificationHttpResponse>;
    public apiUsersUserIdNotificationsNotificationIdPut(user_id: number, notification_id: number, is_read?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesNotificationsUserNotificationHttpResponse>>;
    public apiUsersUserIdNotificationsNotificationIdPut(user_id: number, notification_id: number, is_read?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesNotificationsUserNotificationHttpResponse>>;
    public apiUsersUserIdNotificationsNotificationIdPut(user_id: number, notification_id: number, is_read?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdNotificationsNotificationIdPut.');
        }
        if (notification_id === null || notification_id === undefined) {
            throw new Error('Required parameter notification_id was null or undefined when calling apiUsersUserIdNotificationsNotificationIdPut.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (is_read !== undefined && is_read !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_read, 'is_read');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notifications/${this.configuration.encodeParam({name: "notification_id", value: notification_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesNotificationsUserNotificationHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an User Entry
     * Update User Base Data
     * @param user_id User Id
     * @param app_http_requests_users_user_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdPut(user_id: number, app_http_requests_users_user_update_request: AppHttpRequestsUsersUserUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersPlayerHttpResponse>;
    public apiUsersUserIdPut(user_id: number, app_http_requests_users_user_update_request: AppHttpRequestsUsersUserUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdPut(user_id: number, app_http_requests_users_user_update_request: AppHttpRequestsUsersUserUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersPlayerHttpResponse>>;
    public apiUsersUserIdPut(user_id: number, app_http_requests_users_user_update_request: AppHttpRequestsUsersUserUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdPut.');
        }
        if (app_http_requests_users_user_update_request === null || app_http_requests_users_user_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_update_request was null or undefined when calling apiUsersUserIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersPlayerHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set an User Password (only possible if User Password is empty - e.g after Social Signup)
     * Set user password if current password is empty
     * @param user_id User Id
     * @param app_http_requests_users_user_set_password_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdSetpasswordPut(user_id: number, app_http_requests_users_user_set_password_request: AppHttpRequestsUsersUserSetPasswordRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdSetpasswordPut(user_id: number, app_http_requests_users_user_set_password_request: AppHttpRequestsUsersUserSetPasswordRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdSetpasswordPut(user_id: number, app_http_requests_users_user_set_password_request: AppHttpRequestsUsersUserSetPasswordRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdSetpasswordPut(user_id: number, app_http_requests_users_user_set_password_request: AppHttpRequestsUsersUserSetPasswordRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdSetpasswordPut.');
        }
        if (app_http_requests_users_user_set_password_request === null || app_http_requests_users_user_set_password_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_set_password_request was null or undefined when calling apiUsersUserIdSetpasswordPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/setpassword`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_set_password_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Settings
     * Get Current User Settings
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdSettingsGet(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserSettingHttpResponse>;
    public apiUsersUserIdSettingsGet(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserSettingHttpResponse>>;
    public apiUsersUserIdSettingsGet(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserSettingHttpResponse>>;
    public apiUsersUserIdSettingsGet(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdSettingsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/settings`;
        return this.httpClient.request<AppHttpResponsesUsersUserSettingHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change User Settings
     * Update User Settings
     * @param user_id User Id
     * @param app_http_requests_users_user_settings_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdSettingsPut(user_id: number, app_http_requests_users_user_settings_update_request: AppHttpRequestsUsersUserSettingsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserSettingHttpResponse>;
    public apiUsersUserIdSettingsPut(user_id: number, app_http_requests_users_user_settings_update_request: AppHttpRequestsUsersUserSettingsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserSettingHttpResponse>>;
    public apiUsersUserIdSettingsPut(user_id: number, app_http_requests_users_user_settings_update_request: AppHttpRequestsUsersUserSettingsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserSettingHttpResponse>>;
    public apiUsersUserIdSettingsPut(user_id: number, app_http_requests_users_user_settings_update_request: AppHttpRequestsUsersUserSettingsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdSettingsPut.');
        }
        if (app_http_requests_users_user_settings_update_request === null || app_http_requests_users_user_settings_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_settings_update_request was null or undefined when calling apiUsersUserIdSettingsPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/settings`;
        return this.httpClient.request<AppHttpResponsesUsersUserSettingHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_settings_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connect an User Account to a Social Network Account
     * Connect existing user account with social network id
     * @param user_id User ID
     * @param app_http_requests_users_user_social_connection_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdSocialconnectionPut(user_id: number, app_http_requests_users_user_social_connection_request: AppHttpRequestsUsersUserSocialConnectionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdSocialconnectionPut(user_id: number, app_http_requests_users_user_social_connection_request: AppHttpRequestsUsersUserSocialConnectionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdSocialconnectionPut(user_id: number, app_http_requests_users_user_social_connection_request: AppHttpRequestsUsersUserSocialConnectionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdSocialconnectionPut(user_id: number, app_http_requests_users_user_social_connection_request: AppHttpRequestsUsersUserSocialConnectionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdSocialconnectionPut.');
        }
        if (app_http_requests_users_user_social_connection_request === null || app_http_requests_users_user_social_connection_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_social_connection_request was null or undefined when calling apiUsersUserIdSocialconnectionPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/socialconnection`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_social_connection_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Transactions
     * Get all User Transactions
     * @param user_id User Id
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdTransactionsGet(user_id: number, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserTransactionListHttpResponse>;
    public apiUsersUserIdTransactionsGet(user_id: number, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserTransactionListHttpResponse>>;
    public apiUsersUserIdTransactionsGet(user_id: number, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserTransactionListHttpResponse>>;
    public apiUsersUserIdTransactionsGet(user_id: number, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdTransactionsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/transactions`;
        return this.httpClient.request<AppHttpResponsesUsersUserTransactionListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Wallets
     * Get all User Wallets
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdWalletsGet(user_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserWalletListHttpResponse>;
    public apiUsersUserIdWalletsGet(user_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserWalletListHttpResponse>>;
    public apiUsersUserIdWalletsGet(user_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserWalletListHttpResponse>>;
    public apiUsersUserIdWalletsGet(user_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdWalletsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wallets`;
        return this.httpClient.request<AppHttpResponsesUsersUserWalletListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Wallet
     * Get specific User Wallet
     * @param user_id User Id
     * @param wallet_id Wallet Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdWalletsWalletIdGet(user_id: string, wallet_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserWalletHttpResponse>;
    public apiUsersUserIdWalletsWalletIdGet(user_id: string, wallet_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserWalletHttpResponse>>;
    public apiUsersUserIdWalletsWalletIdGet(user_id: string, wallet_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserWalletHttpResponse>>;
    public apiUsersUserIdWalletsWalletIdGet(user_id: string, wallet_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdWalletsWalletIdGet.');
        }
        if (wallet_id === null || wallet_id === undefined) {
            throw new Error('Required parameter wallet_id was null or undefined when calling apiUsersUserIdWalletsWalletIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wallets/${this.configuration.encodeParam({name: "wallet_id", value: wallet_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersUserWalletHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
