import {
    TenantService,
    GeneralHelpers,
    GoogleAnalyticsService,
    WindowRef, AuthenticationService, SocialService, MobiledetectService
} from '../../../shared';
import { ChangeDetectorRef, EventEmitter, NgZone, OnInit, Output, Directive } from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {WrapperAppSocialSignInCallback} from '../../../shared/interfaces/wrapperappsocialsignincallback-interface';
import {NavigationEnd, Router} from '@angular/router';
import {ALoginRegisterComponent} from './a-login-register.component';


@Directive()
export abstract class ALoginRegisterSocialComponent extends ALoginRegisterComponent implements OnInit {

    @Output() onFormSuccess: EventEmitter<any> = new EventEmitter();
    @Output() onSocialLoginError: EventEmitter<any> = new EventEmitter();

    public facebookLoginEnabled: boolean = false;
    public googleLoginEnabled: boolean = false;
    public processSocialLogin: string = null;
    public isWrapperWindow: boolean = false;
    public nativeWindow;
    public isIOSPrivateBrowser: boolean = false;

    protected GeneralHelpers = new GeneralHelpers();

    protected constructor(public tenantService: TenantService,
                          protected googleAnalyticsService: GoogleAnalyticsService,
                          protected authenticationService: AuthenticationService,
                          protected socialService: SocialService,
                          protected windowRef: WindowRef,
                          protected zone: NgZone,
                          protected changeDetector: ChangeDetectorRef,
                          protected mobileDetect: MobiledetectService,
                          protected router: Router) {

        super(tenantService, googleAnalyticsService);

        this.facebookLoginEnabled = this.tenantService.tenantData.social_network.facebook_login_enabled;
        this.googleLoginEnabled = this.tenantService.tenantData.social_network.google_login_enabled;

        this.nativeWindow = windowRef.nativeWindow;
        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }

        // workaround because of delay when logging in with facebook auth using our app wrapper
        // more info: https://github.com/angular/angular/issues/18816
        //            https://github.com/angular/angular/issues/23697
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.detectChangesForWrapper();
        });
    }

    ngOnInit() {
        if (this.mobileDetect.isIOS() && this.mobileDetect.isChromeIos()) {
            const self = this;
            this.mobileDetect.isPrivateBrowser(function (isPrivateBrowser) {
                if (isPrivateBrowser) {
                    self.isIOSPrivateBrowser = isPrivateBrowser;
                }
            });
        }
    }

    detectChangesForWrapper() {
        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            setTimeout(() => {
                this.zone.run(() => {
                    this.changeDetector.detectChanges();
                });
            });
        }
    }

    initiateSocialSignIn(socialPlatform: string) {
        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.socialSignInWrapperApp(socialPlatform);
        } else {
            this.socialService.getSocialUser(socialPlatform)
                .then(
                    (userData) => {
                        this.socialSignIn(socialPlatform, userData.authToken);
                    }
                );
        }

    }

    socialSignIn(socialPlatform: string, authToken: string) {
        this.processSocialLogin = socialPlatform;
        this.authenticationService.sociallogin(socialPlatform, authToken)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.googleAnalyticsService.trackEvent('login modal', 'login', 'social success - ' + socialPlatform);
                    this.processSocialLogin = null;
                    this.GeneralHelpers.doLoginRedirectIfDefined(this.router);
                    this.onFormSuccess.emit();
                },
                error: (err: HttpErrorResponse) => {
                    this.googleAnalyticsService.trackEvent('login modal', 'login', 'social failed - ' + socialPlatform);
                    this.processSocialLogin = null;
                    if (err.status === 400 && err.error.error === 'invalid_grant') {
                        this.onSocialLoginError.emit(socialPlatform);
                    }
                }
            });
    }

    socialSignInWrapperApp(socialPlatform: string) {
        this.nativeWindow.window.socialSignInWrapperAppCallback = function (wrapperAppSocialSignInCallback: WrapperAppSocialSignInCallback) {
            if (wrapperAppSocialSignInCallback !== null) {
                this.socialSignIn(
                    wrapperAppSocialSignInCallback.type,
                    wrapperAppSocialSignInCallback.token
                );
            }
            this.detectChangesForWrapper();
        }.bind(this);

        if (socialPlatform === 'facebook') {
            this.nativeWindow.window.BetSnapMobileWrapper.facebookLogin(['window.socialSignInWrapperAppCallback']);
        } else if (socialPlatform === 'google') {
            this.nativeWindow.window.BetSnapMobileWrapper.googleLogin(['window.socialSignInWrapperAppCallback']);
        }
    }
}
