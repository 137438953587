<div class="ui centered relaxed grid container">
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <div class="ui center aligned borderless vertical segment margin-0">
            <h2 class="ui center aligned small header">
                {{'PHONEVERIFICATION.heading' | translate | uppercase}}
            </h2>
        </div>
    </div>
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <fui-message *ngIf="showAlert" class="{{alertMessage.type ?? ''}} margin-b-20" [hasDismissButton]="false">
            <div class="header" *ngIf="alertMessage.messagetranslatekey">
                {{alertMessage.messagetranslatekey | translate}}
            </div>
            <p *ngIf="alertMessage.messagetxttranslatekey">
                {{alertMessage.messagetxttranslatekey | translate}}
            </p>
        </fui-message>
        <div *ngIf="showLoginButton">
            <button class="ui fluid button" type="button" (click)="showView('login')">{{'PHONEVERIFICATION.login_btn' | translate}}</button>
        </div>
        <form *ngIf="!showLoginButton" class="ui form" novalidate [formGroup]="initVerification"
              [class.error]="!processForm"
              (ngSubmit)="onSubmit()">
            <betsnaps-phone-field *ngIf="!this.phone"
                                  [processForm]="processForm"
                                  [phoneSignupAllowedCountryCodes]="phoneSignupAllowedCountryCodes"
                                  [form]="initVerification"></betsnaps-phone-field>
            <div class="field" [class.disabled]="processForm">
                <div class="ui left input">
                    <input type="number" id="token" name="token"
                           placeholder="{{'PHONEVERIFICATION.token' | translate}}"
                           formControlName="token"
                           required/>
                </div>
                <div *ngIf="initVerification.get('token').touched && initVerification.get('token').invalid"
                    class="ui pointing red basic label">
                    {{'PHONEVERIFICATION.ERRORS.token_required' | translate}}
                </div>
            </div>
            <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10 padding-b-0">
                <button class="ui large fluid button margin-0" type="submit"
                        [class.loading]="processForm"
                        [disabled]="processForm || initVerification.invalid">
                    {{'PHONEVERIFICATION.initialize_btn' | translate | uppercase}}
                </button>
            </div>
        </form>
    </div>
    <div class="ui sixteen wide center aligned column padding-b-0">
        <div class="ui row divider"></div>
        <div class="color-grey">
            {{'LOGIN.no_account_label' | translate}}
            <a class="text font-bold padding-l-5 nowrap"
               (click)="showView('register')">
                {{'LOGIN.no_account_btn' | translate}}
            </a>
        </div>
        <div class="color-grey">
            {{'REGISTER.resend_phone_verification_label' | translate}}
            <a class="text font-bold padding-l-5 nowrap"
               (click)="showView('resend-phone-verification')">
                {{'REGISTER.resend_verification_btn' | translate}}
            </a>
        </div>
    </div>
</div>
