<div [class.element-clickable]="isClickAble"
     (click)="userAvatarClicked()"
     *ngIf="user">
    <div class="user-avatar-wrap">
        <img *ngIf="user.media_id > 0" [ngClass]="'ui circular bordered centered image profileimg bg-white ' + imageSize + ' ' + addClass"
             [class.primary]="friendshipState.state === friendshipStateEnum.FRIEND"
             [class.grey]="friendshipState.state === friendshipStateEnum.INCOMING_PENDING_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.OUTGOING_FRIEND_INVITATION"
             [src]="user.media_url_big"
             [alt]=""/>
        <img *ngIf="!user.media_id || user.media_id === 0"
             [ngClass]="'ui circular bordered centered image profileimg bg-white ' + imageSize + ' ' + addClass"
             [src]="'./assets/img/default-profile.png'" alt="" />
        <div *ngIf="showFriendshipStateIcon && friendshipState.state === friendshipStateEnum.INCOMING_PENDING_FRIEND_INVITATION" class="ui small circular label user-avatar-label primary"><i class="fa fa-envelope icon margin-0"></i></div>
        <div *ngIf="showFriendshipStateIcon && friendshipState.state === friendshipStateEnum.OUTGOING_FRIEND_INVITATION" class="ui small circular label user-avatar-label white text-primary"><i class="fa fa-paper-plane icon margin-0"></i></div>
        <div *ngIf="showFriendshipStateIcon && friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION" class="ui small circular label user-avatar-label white color-negative"><i class="fa fa-ban icon margin-0"></i></div>
        <div *ngIf="notificationIconClass" class="floating ui tiny circular circle yellow label bottom margin-r-10 margin-b-10"><i [ngClass]="'small icon bsi ' + notificationIconClass + ' color-white margin-r-0 margin-b-0'"></i></div>
    </div>
</div>
<div *ngIf="!user">
    <div class="user-avatar-wrap">
        <div *ngIf="showAvatarLoader" class="ui active z-index-1 inline loader right spaced image profileimg"></div>
        <img *ngIf="!showAvatarLoader"
             [ngClass]="'ui circular bordered centered image profileimg bg-white ' + imageSize + ' ' + addClass"
             [src]="'./assets/img/default-profile.png'" alt="" />
    </div>
</div>
<ng-template let-context let-modal="modal" #userProfileModalTemplate>
    <div class="ui fluid profile-header-image image" *ngIf="profileHeaderImage">
        <img [src]="profileHeaderImage" [alt]="" />
    </div>
    <div class="user-modal-v3 content" [class.with-profile-header-image]="profileHeaderImage" *ngIf="user">
        <div class="center aligned margin-b-10">
            <img class="ui circular bordered centered medium image profileimg bg-white"
                 [class.primary]="friendshipState.state === friendshipStateEnum.FRIEND"
                 [class.grey]="friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.OUTGOING_FRIEND_INVITATION"
                 [src]="user.media_url_big"
                 [alt]="" />
        </div>
        <p class="center aligned margin-0">
            <strong class="font-very-bold text-big">{{user.username}}</strong>
        </p>
        <div class="ui padded borderless vertical segment margin-0" *ngIf="user.is_active">
            <div *ngIf="friendshipState.state === friendshipStateEnum.OUTGOING_FRIEND_INVITATION">
                <h3 class="ui header section-header center aligned">
                    {{'FRIENDS.REQUESTS.pending_outgoing_friend_request' | translate}}
                </h3>
                <div class="ui centered middle aligned grid margin-0">
                    <div class="sixteen wide column center aligned">
                        <button type="button" (click)="deleteFriendInvitation(friendshipState.friendInvitation)"
                                [class.loading]="processDeleteFriendInvitationButton"
                                [disabled]="processDeleteFriendInvitationButton"
                                class="ui basic button margin-0">
                            {{'FRIENDS.REQUESTS.delete_outgoing_request' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="ui centered middle aligned grid margin-0" *ngIf="friendshipState.state === friendshipStateEnum.FRIEND && currentUser.username">
                <div class="eight wide column right aligned" *ngIf="challengeFriendPossible">
                    <button type="button" (click)="modal.approve(null); challengeFriend(user)"
                            [class.loading]="processChallengeButton"
                            [disabled]="processChallengeButton"
                            class="ui basic primary button margin-0">
                        {{'GENERAL.USER.challenge' | translate | uppercase}}
                    </button>
                </div>
                <div [ngClass]="challengeFriendPossible ? 'eight wide column left aligned' : 'sixteen wide column center aligned'">
                    <button type="button" (click)="removeFriend(user.id)"
                            [class.loading]="processButton"
                            [disabled]="processButton"
                            class="ui basic button margin-0">
                        {{'FRIENDS.remove_friend' | translate | uppercase}}
                    </button>
                </div>
            </div>
            <div class="center aligned" *ngIf="friendshipState.state === friendshipStateEnum.NOT_A_FRIEND">
                <button type="button" (click)="showAddFriend()"
                        [hidden]="addFriendExpanded"
                        class="ui button margin-0">
                    {{'FRIENDS.add_friend' | translate | uppercase}}
                </button>
                <div [hidden]="!addFriendExpanded">
                    <div class="ui form v3">
                        <textarea #inviteMessage rows="3"
                                  placeholder="{{'FRIENDS.add_friend_message_placeholder' | translate}}"></textarea>
                    </div>
                    <div class="right aligned margin-t-10">
                        <button type="button" (click)="addFriendExpanded = false"
                                class="ui button basic padding-r-10 padding-l-10">
                            {{'GENERAL.abort_btn' | translate | uppercase}}
                        </button>
                        <button type="button" (click)="addFriend(user.id, inviteMessage.value)"
                                [class.loading]="processButton"
                                [disabled]="processButton"
                                class="ui button margin-0 padding-r-10 padding-l-10">
                            {{'FRIENDS.add_friend' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="friendshipState.state === friendshipStateEnum.INCOMING_PENDING_FRIEND_INVITATION  && friendshipState.friendInvitation">
                <h3 class="ui header section-header center aligned">
                    {{'FRIENDS.REQUESTS.pending_incoming_friend_request' | translate}}
                </h3>
                <p class="margin-t-5 invitation-message center aligned" *ngIf="friendshipState.friendInvitation.message">
                    {{friendshipState.friendInvitation.message}}
                </p>
                <div class="ui centered middle aligned grid margin-0">
                    <div class="eight wide column right aligned">
                        <button type="button" (click)="acceptFriendInvitation(friendshipState.friendInvitation)"
                                [class.loading]="processAcceptFriendInvitationButton"
                                [disabled]="processAcceptFriendInvitationButton || processDeclineFriendInvitationButton"
                                class="ui primary button margin-0">
                            {{'FRIENDS.add_friend' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="eight wide column left aligned">
                        <button type="button" (click)="declineFriendInvitation(friendshipState.friendInvitation)"
                                [class.loading]="processDeclineFriendInvitationButton"
                                [disabled]="processDeclineFriendInvitationButton || processAcceptFriendInvitationButton"
                                class="ui basic button margin-0">
                            {{'FRIENDS.REQUESTS.decline_incoming_request' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION && friendshipState.friendInvitation">
                <h3 class="ui header section-header center aligned">
                    {{'FRIENDS.REQUESTS.declined_incoming_friend_request' | translate}}
                </h3>
                <p class="margin-t-5 invitation-message center aligned" *ngIf="friendshipState.friendInvitation.message">
                    {{friendshipState.friendInvitation.message}}
                </p>
                <div class="ui centered middle aligned grid margin-0">
                    <div class="sixteen wide column center aligned">
                        <button type="button" (click)="acceptFriendInvitation(friendshipState.friendInvitation)"
                                [class.loading]="processAcceptFriendInvitationButton"
                                [disabled]="processAcceptFriendInvitationButton"
                                class="ui primary button margin-0">
                            {{'FRIENDS.add_friend' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
