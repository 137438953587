/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsGamesGameLeaderboardUserGroupHttpResponse } from './appHttpResponsesBetsnapsGamesGameLeaderboardUserGroupHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse';
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from './appHttpResponsesUsersPlayerPublicHttpResponse';


/**
 * GameLeaderboardUserHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse { 
    /**
     * Game Id
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * User Id
     */
    user_id: number;
    /**
     * User Game Points
     */
    user_game_points?: number;
    /**
     * Total Score
     */
    total_score: number;
    /**
     * Highest Point
     */
    highest_point: number;
    /**
     * Lowest Point
     */
    lowest_point: number;
    /**
     * Rank
     */
    rank?: number;
    /**
     * Rank Position Change Up
     */
    rank_change_down?: number;
    /**
     * Rank Position Change Down
     */
    rank_change_up?: number;
    /**
     * Points to Second Rank if First
     */
    points_in_lead?: number;
    /**
     * Points to First Rank
     */
    points_to_first?: number;
    /**
     * Points to Prize Ranks
     */
    points_to_prizes?: number;
    /**
     * Prize Amount
     */
    prize_amount: number;
    /**
     * Tenant Prize Id
     */
    tenant_prize_id?: number;
    tenant_prize?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse;
    tenant_prize_template?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse;
    /**
     * Determines if participation of the user is valid
     */
    is_participation_valid?: boolean;
    /**
     * Determines if user is eliminated
     */
    is_eliminated: boolean;
    /**
     * Is Winner
     */
    is_winner?: AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse.IsWinnerEnum;
    /**
     * Lost Bets Count
     */
    lost_bets_count?: number;
    user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    game_user_group?: AppHttpResponsesBetsnapsGamesGameLeaderboardUserGroupHttpResponse;
}
export namespace AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse {
    export type IsWinnerEnum = '0' | '1';
    export const IsWinnerEnum = {
        _0: '0' as IsWinnerEnum,
        _1: '1' as IsWinnerEnum
    };
}


