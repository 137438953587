import {Component, Input, OnInit} from '@angular/core';

import {MyTranslateService, TenantService} from '../../../shared';
import {
    AppHttpResponsesBetsnapsGamesBasicGameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameTenantHttpResponse as GameTenantHttpResponse
} from '../../../api';

@Component({
    selector: 'betsnaps-game-name',
    templateUrl: './game-name.component.html',
    styles: []
})
export class GameNameComponent implements OnInit {

    @Input() game: AppHttpResponsesBetsnapsGamesBasicGameHttpResponse;

    public showCloneNumber = false;

    constructor(
        public translations: MyTranslateService,
        public tenantService: TenantService
    ) {
    }

    ngOnInit() {
        if (this.game.clone_number && this.game.clone_number > 0) {
            const currentGameTenant = this.game.game_tenants.find(
                (gameTenant: GameTenantHttpResponse) => (gameTenant.tenant_id === this.tenantService.tenantData.id)
            );
            if (currentGameTenant && currentGameTenant.allow_join_multiple_game_clones) {
                this.showCloneNumber = true;
            }
        }
    }

}
