import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {
    AppHttpResponsesTenantsTenantFooterListHttpResponse as TenantFooterListHttpResponse,
    AppHttpResponsesTenantsTenantFooterHttpResponse as TenantFooterHttpResponse,
    AppHttpResponsesGeneralLanguageHttpResponse as LanguageHttpResponse,
    AppHttpResponsesTenantsTenantAdvertisementHttpResponse as AdvertisementHttpResponse,
    AppHttpResponsesTenantsTenantAdvertisementListHttpResponse as AdvertisementListHttpResponse,
    AppHttpResponsesTenantsTenantExternalScriptHttpResponse as ExternalScriptHttpResponse,
    AppHttpResponsesTenantsTenantInquiryListHttpResponse as TenantInquiryListHttpResponse,
    AppHttpResponsesTenantsTenantInfoHttpResponse as TenantInfoHttpResponse,
    AppHttpResponsesTenantsTenantPublicHttpResponse as TenantPublicHttpResponse,
    AppHttpResponsesTenantsTenantSportListHttpResponse as TenantSportListHttpResponse,
    AppHttpResponsesTenantsTenantImageHttpResponse as TenantImageHttpResponse,
    GeneralApi,
    TenantsApi,
    AppHttpResponsesTranslationsMediaTranslationHttpResponse as MediaTranslationHttpResponse,
    AppHttpResponsesMediaMediaHttpResponse as MediaHttpResponse
} from '../../api';
import {DebugService} from './debug.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from './error.service';
import {distinctUntilChanged, shareReplay, take} from 'rxjs/operators';
import {convertPhpToJsMomentFormat, getUrlGetParameterSeparator} from '../helpers';
import * as moment from 'moment';
import {WindowRef} from './window.service';
import {B2cSignupMethodEnum, TenantImageTypeEnum} from '../enums';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    private tenantInfoRequest: Observable<any>;
    private tenantInquiryRequest: Observable<any>;

    private tenantInfoDataSubject = new BehaviorSubject<TenantInfoHttpResponse>(null);
    private tenantInfoData$ = this.tenantInfoDataSubject.asObservable().pipe(distinctUntilChanged());

    private allTenantDataLoadedSubject = new BehaviorSubject<boolean>(false);
    public allTenantDataLoaded$ = this.allTenantDataLoadedSubject.asObservable().pipe(distinctUntilChanged());

    public tenantLangs: LanguageHttpResponse[];
    public tenantExternalScripts: ExternalScriptHttpResponse[];

    private tenantAdvertisementsSubject = new BehaviorSubject<AdvertisementListHttpResponse>(null);
    public tenantAdvertisements$ = this.tenantAdvertisementsSubject.asObservable().pipe(distinctUntilChanged());
    private tenantHasSidebarLeftSkyscraperAd: boolean = false;
    private tenantAdPresentedBy: AdvertisementHttpResponse;

    private tenantFootersSubject = new BehaviorSubject<TenantFooterListHttpResponse>(null);
    public tenantFooters$ = this.tenantFootersSubject.asObservable().pipe(distinctUntilChanged());
    private tenantFootersRequestRunning: boolean = false;

    public isB2b = false;
    public b2bLoginType: string;
    public b2bWebsiteUrl: string;
    private b2bWebsiteLoginUrl: string;
    private b2bWebsiteLoginUrlAddLang: boolean;
    public b2bWebsiteLoginDirectForwarding: boolean;
    private b2bWebsiteRegisterUrl: string;

    public noB2cSignupMethod: boolean = false;

    private _currentLang: LanguageHttpResponse;

    public tenantInfoDataFallbackRequest: Observable<any>;
    public isMaintenanceMode = false;

    public isWrapperWindow: boolean = false;
    public isWrapperAndroid: boolean = false;
    public isWrapperIos: boolean = false;

    public browserLangIsoCode2 = 'en';

    public componentTemplateToLoad = 'default';

    constructor(@Inject(DOCUMENT) private document: any,
                private tenantsApi: TenantsApi,
                private generalApi: GeneralApi,
                private debugService: DebugService,
                private errorService: ErrorService,
                private httpClient: HttpClient,
                private windowRef: WindowRef) {
        // get browser language
        this.browserLangIsoCode2 = this.getBrowserLangIsoCode2();

        if (windowRef.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
            if (typeof windowRef.nativeWindow.window.BetSnapMobileWrapper.platform === 'function') {
                this.isWrapperAndroid = windowRef.nativeWindow.window.BetSnapMobileWrapper.platform() === 'Android';
                this.isWrapperIos = windowRef.nativeWindow.window.BetSnapMobileWrapper.platform() === 'iOS';
            }
        }
    }

    public set currentLang(languageHttpResponse: LanguageHttpResponse) {
        this._currentLang = languageHttpResponse;
    }

    getBrowserLangIsoCode2() {
        if (typeof this.windowRef.nativeWindow.window === 'undefined' || typeof this.windowRef.nativeWindow.navigator === 'undefined') {
            return undefined;
        }

        let browserLangIsoCode2 = this.windowRef.nativeWindow.navigator.languages ? this.windowRef.nativeWindow.navigator.languages[0] : null;
        browserLangIsoCode2 = browserLangIsoCode2 || this.windowRef.nativeWindow.navigator.language || this.windowRef.nativeWindow.navigator.browserLanguage || this.windowRef.nativeWindow.navigator.userLanguage;

        // if culture code is in language code
        if (browserLangIsoCode2.indexOf('-') !== -1) {
            browserLangIsoCode2 = browserLangIsoCode2.split('-')[0];
        }
        if (browserLangIsoCode2.indexOf('_') !== -1) {
            browserLangIsoCode2 = browserLangIsoCode2.split('_')[0];
        }

        return browserLangIsoCode2;
    }

    loadTenantDataForDomain() {
        this.getTenantInfo()
            .pipe(take(1))
            .subscribe({
                next: (tenantInfo: TenantInfoHttpResponse) => {
                    if (tenantInfo) {

                        this.setTenantData(tenantInfo);

                        this.tenantsApi.apiTenantsTenantIdTenantContentGet(
                            tenantInfo.tenant.id,
                            (!this.isWrapperAndroid && !this.isWrapperIos),
                            this.isWrapperAndroid,
                            this.isWrapperIos
                        ).pipe(take(1))
                        .subscribe({
                            next: (advertisementList: AdvertisementListHttpResponse) => {
                                if (advertisementList) {
                                    this.tenantHasSidebarLeftSkyscraperAd = advertisementList.results.filter((ad: AdvertisementHttpResponse) => ad.type === 'sidebar-left-skyscraper').length > 0;
                                    const presentedByAd = advertisementList.results.filter((ad: AdvertisementHttpResponse) => ad.type === 'mobile-presented-by');
                                    if (presentedByAd) {
                                        this.tenantAdPresentedBy = presentedByAd[0];
                                    }
                                    this.tenantAdvertisementsSubject.next(advertisementList);
                                }
                            },
                            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                        });

                        this.allTenantDataLoadedSubject.next(true);

                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.loadDataFallbackTenantHttpResponse()
                        .pipe(take(1))
                        .subscribe({
                            next: (fallbackTenantInfoHttpResponse: TenantInfoHttpResponse) => {
                                if (fallbackTenantInfoHttpResponse) {
                                    this.isMaintenanceMode = true;
                                    this.setTenantData(fallbackTenantInfoHttpResponse);
                                    this.allTenantDataLoadedSubject.next(true);
                                }
                            },
                            error: () => {
                                this.errorService.handleHttpErrorResponse(err);
                            }
                        });

                }
            });

    }

    public loadDataFallbackTenantHttpResponse(): Observable<TenantInfoHttpResponse> {
        if (this.tenantInfoDataSubject.value) {
            this.tenantInfoDataFallbackRequest = null;
            return this.tenantInfoData$;
        } else if (this.tenantInfoDataFallbackRequest) {
            return this.tenantInfoDataFallbackRequest;
        } else {
            const domain = this.getCurrentDomain();
            const domainDirectory = domain.replace(/\//g, '').replace(/:/g, '');
            const dataShareBaseUrl = environment.S3_NL_URL + '/' + environment.S3_NL_BUCKET_ROOT_DATASHARE;
            this.tenantInfoDataFallbackRequest = this.httpClient.get(
                dataShareBaseUrl + '/' + domainDirectory + '/tenantinfo.json'
            ).pipe(shareReplay(1));

            return this.tenantInfoDataFallbackRequest;
        }
    }

    private setTenantData(tenantInfo: TenantInfoHttpResponse) {
        if (tenantInfo) {
            // define b2b settings
            const tenant: TenantPublicHttpResponse = tenantInfo.tenant;
            if (tenant.is_b2b_tenant) {
                this.isB2b = true;
                this.b2bLoginType = tenant.b2b.b2b_login_type;
                this.b2bWebsiteUrl = tenant.b2b.website_url;
                this.b2bWebsiteLoginUrl = tenant.b2b.website_login_url;
                this.b2bWebsiteLoginUrlAddLang = tenant.b2b.website_login_url_add_lang;
                this.b2bWebsiteLoginDirectForwarding = tenant.b2b.website_login_direct_forwarding;
                this.b2bWebsiteRegisterUrl = tenant.b2b.website_register_url;
            }

            if (tenant.internationalization) {
                if (tenant.internationalization.date_format) {
                    tenant.internationalization.date_format = convertPhpToJsMomentFormat(tenant.internationalization.date_format);
                }
                if (tenant.internationalization.date_time_format) {
                    tenant.internationalization.date_time_format = convertPhpToJsMomentFormat(tenant.internationalization.date_time_format);
                }
                if (tenant.internationalization.time_format) {
                    tenant.internationalization.time_format = convertPhpToJsMomentFormat(tenant.internationalization.time_format);
                }
            }

            if (tenantInfo.languages) {
                this.tenantLangs = tenantInfo.languages;
            }

            if (tenantInfo.external_scripts) {
                this.tenantExternalScripts = tenantInfo.external_scripts;
            }

            // evaluate component template
            if (tenant.configuration?.theme && tenant.configuration?.theme.version === 3) {
                this.componentTemplateToLoad = 'v3';
            }

            if (tenant.configuration?.b2c_signup_method) {
                this.noB2cSignupMethod = tenant.configuration?.b2c_signup_method === B2cSignupMethodEnum.NONE;
            }

            this.tenantInfoDataSubject.next(tenantInfo);
        }
    }

    public loadTenantFooterDataIfNecessary(): void {
        if (!this.tenantFootersSubject.value && !this.tenantFootersRequestRunning) {
            this.tenantFootersRequestRunning = true;
            this.tenantsApi.apiTenantsTenantIdFootersGet(this.tenantData.id)
                .pipe(take(1))
                .subscribe({
                    next: (footerList: TenantFooterListHttpResponse) => {
                        if (footerList) {
                            footerList.results = this.filterTenantFooterIfNecessary(footerList.results);
                            footerList.count = footerList.results.length;
                            footerList.total = footerList.count;
                            this.tenantFootersSubject.next(footerList);
                        }
                        this.tenantFootersRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.tenantFootersRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    public getTenantImageMediaTranslationForLanguage(tenantImageType: TenantImageTypeEnum): MediaHttpResponse {
        const tenantImage = this.findTenantImage(tenantImageType);

        if (!tenantImage) {
            return null;
        }

        let media = null;

        if (tenantImage?.media) {
            media = tenantImage.media;
        }
        if (tenantImage?.media_translations && tenantImage.media_translations.length > 0) {
            const mediaForLanguage = tenantImage.media_translations.find((tenantImageMediaTranslation: MediaTranslationHttpResponse) => (tenantImageMediaTranslation.iso_code2 === this._currentLang.iso_code2 && tenantImageMediaTranslation.label_field === 'media_id'));
            if (mediaForLanguage) {
                media = mediaForLanguage.media;
            }
        }
        return media;
    }

    public findTenantImage(tenantImageTypeName: string): TenantImageHttpResponse {
        return this.tenantData.images?.find((tenantImage: TenantImageHttpResponse) => tenantImage.tenant_image_type.name === tenantImageTypeName);
    }

    private filterTenantFooterIfNecessary(tenantFooters: TenantFooterHttpResponse[]): TenantFooterHttpResponse[] {

        if (this.isWrapperWindow) {
            tenantFooters = tenantFooters.filter((tenantFooter: TenantFooterHttpResponse) => tenantFooter.show_in_app === true);
        }

        return tenantFooters;
    }

    public getB2bWebsiteLoginUrl(): string {
        let b2bWebsiteLoginUrl = this.b2bWebsiteLoginUrl;
        if (b2bWebsiteLoginUrl) {
            if (this.b2bLoginType === 'OAUTH2') {
                const oauth2State = this.getOauth2State();
                b2bWebsiteLoginUrl += getUrlGetParameterSeparator(b2bWebsiteLoginUrl) + 'state=' + oauth2State;
            }
            if (this._currentLang && this.b2bWebsiteLoginUrlAddLang) {
                b2bWebsiteLoginUrl += getUrlGetParameterSeparator(b2bWebsiteLoginUrl) + 'language=' + this._currentLang.iso_code2;
            }
        }
        return b2bWebsiteLoginUrl;
    }

    public getB2bWebsiteRegisterUrl(): string {
        let b2bWebsiteRegisterUrl = this.b2bWebsiteRegisterUrl;
        if (b2bWebsiteRegisterUrl && this._currentLang && this.b2bWebsiteLoginUrlAddLang) {
            b2bWebsiteRegisterUrl += getUrlGetParameterSeparator(b2bWebsiteRegisterUrl) + 'language=' + this._currentLang.iso_code2;
        }
        return b2bWebsiteRegisterUrl;
    }


    private getOauth2State(): string {
        let oauth2State = null;
        if (localStorage.getItem('oauth2State')) {
            oauth2State = localStorage.getItem('oauth2State');
        } else {
            oauth2State = Math.random().toString(36).substr(2, 10);
            localStorage.setItem('oauth2State', oauth2State);
        }
        return oauth2State;
    }

    public revokeB2bOauth2State(): void {
        if (localStorage.getItem('oauth2State')) {
            localStorage.removeItem('oauth2State');
        }
    }

    get tenantData(): TenantPublicHttpResponse {
        return this.tenantInfoDataSubject.value.tenant;
    }

    get tenantAdvertisements(): Array<AdvertisementHttpResponse> {
        if (this.tenantAdvertisementsSubject.value) {
            return this.tenantAdvertisementsSubject.value.results;
        }
    }

    getTenantInfo(): Observable<TenantInfoHttpResponse> {
        if (this.tenantInfoDataSubject.value) {
            this.tenantInfoRequest = null;
            return this.tenantInfoData$;
        } else if (this.tenantInfoRequest) {
            return this.tenantInfoRequest;
        } else {
            const domain = this.getCurrentDomain();

            this.debugService.writeMessageToConsoleLog('TenantData for: ' + domain);
            this.tenantInfoRequest = this.tenantsApi.apiTenantinfoGet(domain).pipe(shareReplay(1));
            return this.tenantInfoRequest;
        }
    }

    public getTenantDomainWithDefaultRelativePath(): string {
        let url = this.tenantData.domain_name;
        if (this.tenantData.relative_default_path) {
            url += '/' + this.tenantData.relative_default_path;
        }
        return url;
    }

    getTenantInquiries(): Observable<TenantInquiryListHttpResponse> {
        this.tenantInquiryRequest = null;
        if (this.tenantInfoDataSubject && this.tenantInfoDataSubject.value && this.tenantInfoDataSubject.value.tenant.id) {
            this.tenantInquiryRequest = this.tenantsApi.apiTenantsTenantIdInquiriesGet(this.tenantInfoDataSubject.value.tenant.id).pipe(shareReplay(1));
        }
        return this.tenantInquiryRequest;
    }

    private getCurrentDomain(): string {
        return this.document.location.protocol + '//' + this.document.location.hostname + (this.document.location.port ? ':' + this.document.location.port : '');
    }

    public hasSidebarLeftSkyscraperAd(): boolean {
        return this.tenantHasSidebarLeftSkyscraperAd;
    }

    public getMobilePresentedByAd(): AdvertisementHttpResponse {
        if (this.tenantAdPresentedBy) {
            return this.tenantAdPresentedBy;
        }
        return null;
    }

    public getTenantSports(tenantId: number, perPage?: number, page?: number, withCounts?: boolean, userGenerationAllowed?: boolean): Observable<TenantSportListHttpResponse> {
        return this.tenantsApi.apiTenantsTenantIdSportsGet(
            tenantId,
            (perPage) ? perPage : undefined,
            (page) ? page : undefined,
            (withCounts) ? withCounts : undefined,
            (userGenerationAllowed) ? userGenerationAllowed : undefined
        );
    }
}
