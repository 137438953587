<div class="ui centered relaxed grid container margin-t-0 margin-b-0">
    <div class="row padding-0">
        <betsnaps-left-sidebar-loader class="four wide tablet or lower hidden column sidebar left-sidebar"></betsnaps-left-sidebar-loader>
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div class="ui fluid flexauto item tiny separatorless menu">
                <a href="#" class="item" *ngFor="let menu of newsFeedMenu"
                   [class.active]="menu.active"
                   (click)="$event.preventDefault(); newsFeedMenuChange(menu.key);">
                    <span class="display-inline-block vertical-align-middle">
                        {{menu.translatekey | translate | uppercase}}
                    </span><span class="display-inline-block vertical-align-middle text-mini" *ngIf="menu.key === 'new'">&nbsp;({{notificationService.unreadNewsCount$ | async}})</span>
                </a>
            </div>

            <button type="button" class="fluid ui basic button"
                    *ngIf="(activeTab == 'new' && !processList && userNotifications?.results.length > 0)"
                    [class.loading]="processAllNotificationsRead"
                    (click)="markAllNotificationsAsRead(); googleAnalyticsService.trackEvent('news', 'read', 'notifications (all)');">
                <i class="icon done all medium"></i> {{'NEWS.mark_all_as_read_btn' | translate}}
            </button>

            <div class="ui vertical borderless very padded segment" *ngIf="processList">
                <div class="ui active centered inline text loader on-body-bg">
                    {{'GENERAL.loadertext' | translate}}
                </div>
            </div>

            <div *ngFor="let userNotification of userNotifications?.results; trackBy: notificationTrackBy"
                 class="ui fluid card user-notification-card">
                <div class="content">
                    <div class="ui top aligned grid">
                        <div class="three wide mobile two wide tablet two wide computer column center aligned padding-l-0">
                            <i class="icons">
                                <img *ngIf="getNotificationImage(userNotification) && !notificationService.hasSenderUser(userNotification)"
                                        [src]="getNotificationImage(userNotification)" class="ui circular bordered centered tiny image" />
                                <i *ngIf="!getNotificationImage(userNotification) && !notificationService.hasSenderUser(userNotification)"
                                   class="circular icon margin-0 comment"></i>
                                <betsnaps-user-avatar-loader *ngIf="notificationService.hasSenderUser(userNotification)"
                                                      [user]="notificationService.getSenderUser(userNotification)"
                                                      [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                                <i *ngIf="getSecondaryNotificationIcon(userNotification)"
                                        [ngClass]="'top right corner ' + getSecondaryNotificationIcon(userNotification) + ' huge icon'"></i>
                            </i>
                        </div>
                        <div class="thirteen wide mobile fourteen wide tablet fourteen wide computer column left aligned padding-r-0">
                            <button type="button" class="ui circular icon basic button right floated"
                                    [class.loading]="processNewsRead[userNotification.id]"
                                    [disabled]="processNewsRead[userNotification.id]"
                                    (click)="updateUserNotification(userNotification); googleAnalyticsService.trackEvent('news', 'read', 'notification');"
                                    *ngIf="!userNotification.is_read">
                                <i class="icon check massive"></i>
                            </button>
                            <p class="text-tiny margin-b-0"
                               [class.element-clickable]="userNotification.target_relative_path"
                               (click)="navigateToTarget(userNotification)">
                                {{userNotification.created_at | amLocal | amCalendar:
                                {
                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                }
                                }}
                            </p>
                            <p [class.element-clickable]="userNotification.target_relative_path"
                               [innerHTML]="getReplacedText(userNotification) | safeHtml"
                               (click)="navigateToTarget(userNotification)"></p>
                            <div class="ui fluid card" *ngIf="hasActionSection(userNotification)">
                                <div class="content" *ngIf="isSelfServicePrizePickup(userNotification)">
                                    <ng-container *ngIf="hasTenantPrizePromoCode(userNotification)">
                                        <div class="ui medium header">{{'GENERAL.LABELS.promo_code_title' | translate}}</div>
                                        <div class="meta text-small color-grey">
                                            <span>{{'GENERAL.LABELS.promo_code_subtitle' | translate}}</span>
                                        </div>
                                        <div class="description">
                                            <betsnaps-copy-to-clipboard [analyticsEventCategory]="'news'" [analyticsEventLabel]="'promo code'" [value]="getTenantPrizePromoCode(userNotification)"></betsnaps-copy-to-clipboard>
                                        </div>
                                        <div class="ui fluid button margin-t-10" (click)="navigateToUrl(getTenantPrizeCtaLinkUrl(userNotification))" *ngIf="hasTenantPrizeCtaLinkUrl(userNotification)">
                                            {{'GENERAL.LABELS.promo_code_cta_text' | translate}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!hasTenantPrizePromoCode(userNotification)">
                                        <div class="ui medium header">{{'GENERAL.LABELS.notification_action_title' | translate}}</div>
                                        <div class="meta text-small color-grey">
                                            <span>{{'GENERAL.LABELS.notification_action_subtitle' | translate}}</span>
                                        </div>
                                        <div class="ui fluid button margin-t-10" (click)="navigateToUrl(getTenantPrizeCtaLinkUrl(userNotification))" *ngIf="hasTenantPrizeCtaLinkUrl(userNotification)">
                                            {{'GENERAL.LABELS.notification_action_cta_text' | translate}}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ui vertical borderless center aligned segment"
                 *ngIf="!processList && userNotifications?.total < 1">
                <p class="text-on-body-bg">
                    {{'GENERAL.no_results_found' | translate}}
                </p>
            </div>
            <div class="ui basic segment center aligned"
                 *ngIf="userNotifications?.results.length < userNotifications?.total" #loadMoreButton>
                <button type="button"
                        class="ui button margin-0"
                        [class.loading]="processLoadMore"
                        (click)="loadMore()">
                    {{'GENERAL.load-more_btn' | translate}}
                </button>
            </div>
        </div>
        <betsnaps-right-sidebar-loader class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar"></betsnaps-right-sidebar-loader>
    </div>
</div>
