import {Component, OnInit} from '@angular/core';
import {FuiBaseModal} from '@aligorji/ngx-fomantic-ui';

@Component({
    selector: 'betsnaps-username-modal',
    templateUrl: './username-modal.component.html',
    styles: []
})

export class UsernameModalComponent implements OnInit {
    constructor(public modal: FuiBaseModal<void, void, void>) {
    }

    ngOnInit() {
    }
}
