/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantExternalScriptHttpResponse
 */
export interface AppHttpResponsesTenantsTenantExternalScriptHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Title
     */
    title: string;
    /**
     * Head Script Src
     */
    head_script_src?: string;
    /**
     * Body Top Embed Code
     */
    body_top_embed_code?: string;
    /**
     * Body Bottom Embed Code
     */
    body_bottom_embed_code?: string;
    /**
     * After Login Embed Code
     */
    after_login_embed_code?: string;
}

