import { OnInit, Directive } from '@angular/core';
import {
    AppHttpResponsesFriendsFriendInvitationHttpResponse as FriendInvitationHttpResponse,
    AppHttpResponsesFriendsFriendInvitationListHttpResponse as FriendInvitationListHttpResponse
} from '../../../../api';
import {
    AuthenticationService, FriendsService, GoogleAnalyticsService
} from '../../../../shared';
import {takeWhile} from 'rxjs/operators';


@Directive()
export abstract class AFriendsRequestsComponent implements OnInit {

    protected componentAlive = true;

    public userFriendInvitationsIncoming: FriendInvitationHttpResponse[];
    public userFriendInvitationsOutgoing: FriendInvitationHttpResponse[];
    public userFriendInvitationsIncomingDeclined: FriendInvitationHttpResponse[];

    visibleDeclinedFriendInvitations = false;

    protected declinedIncomingModal = null;

    processAcceptInvitation: boolean[] = [];
    processDeclineInvitation: boolean[] = [];
    processDeleteInvitation: boolean[] = [];

    constructor(public authenticationService: AuthenticationService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected friendsService: FriendsService) {

        this.friendsService.userFriendInvitationsIncoming$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                    if (friendInvitationListHttpResponse) {
                        this.userFriendInvitationsIncoming = friendInvitationListHttpResponse.results;
                    }
                }
            );

        this.friendsService.userFriendInvitationsOutgoing$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                    if (friendInvitationListHttpResponse) {
                        this.userFriendInvitationsOutgoing = friendInvitationListHttpResponse.results;
                    }
                }
            );

        this.friendsService.userFriendInvitationsIncomingDeclined$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                    if (friendInvitationListHttpResponse) {
                        this.userFriendInvitationsIncomingDeclined = friendInvitationListHttpResponse.results;

                        if(this.userFriendInvitationsIncomingDeclined.length === 0){
                            if(this.declinedIncomingModal){
                                this.declinedIncomingModal.approve();
                            }
                        }
                    }
                }
            );
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    userFriendInvitationsTrackBy(index: number, userFriendInvitation: FriendInvitationHttpResponse) {
        return userFriendInvitation.id;
    }
}
