/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesGameFriendInvitationRequest } from '../model/appHttpRequestsBetsnapsGamesGameFriendInvitationRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesUserBetCashoutRequest } from '../model/appHttpRequestsBetsnapsGamesUserBetCashoutRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest } from '../model/appHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesUserBetPlaceBetRequest } from '../model/appHttpRequestsBetsnapsGamesUserBetPlaceBetRequest';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameInvitationListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameInvitationListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameMatchHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameMatchHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameMatchListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameSettingHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameSettingHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameShareDataHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameUserListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameUserListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameWinnerListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameWinnerListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesUserBetHttpResponse } from '../model/appHttpResponsesBetsnapsGamesUserBetHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesUserBetListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesUserBetListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse } from '../model/appHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchMarketHttpResponse } from '../model/appHttpResponsesSportsDataMatchMarketHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchMarketListHttpResponse } from '../model/appHttpResponsesSportsDataMatchMarketListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class GamesApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Remove a Game from Favorite
     * Remove a Game from Favorite
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdFavoritePost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdFavoritePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/favorite`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Disconnects a User from a Game
     * Disconnects a User from a Game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost(tenant_id: number, game_unique_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/leave`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unsubscribe from game notifications
     * Unsubscribe from game notifications
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notifications`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a Game from Favorite
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdFavoriteDelete(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdGamesGameUniqueIdFavoriteDelete(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdFavoriteDelete(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdFavoriteDelete(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdFavoriteDelete.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdFavoriteDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/favorite`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a Game to Favorite
     * Add a Game to Favorite
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdFavoritePost(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdFavoritePost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdFavoritePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/favorite`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single Game
     * Get single Game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param authorization Valid oAuth access_token (Format-Hint: Bearer {Token}) (optional)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, authorization?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, authorization?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, authorization?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, authorization?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get current Leaderboard for a Game
     * get current Leaderboard for a Game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param group_id Game User Group ID
     * @param only_friends Show only users who are friends of authenticated user
     * @param view Choose which leaderboard view should be delivered, available: default / compact / topranked
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(tenant_id: number, game_unique_id: string, group_id?: number, only_friends?: boolean, view?: string, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(tenant_id: number, game_unique_id: string, group_id?: number, only_friends?: boolean, view?: string, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(tenant_id: number, game_unique_id: string, group_id?: number, only_friends?: boolean, view?: string, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(tenant_id: number, game_unique_id: string, group_id?: number, only_friends?: boolean, view?: string, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (group_id !== undefined && group_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>group_id, 'group_id');
        }
        if (only_friends !== undefined && only_friends !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_friends, 'only_friends');
        }
        if (view !== undefined && view !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>view, 'view');
        }
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/leaderboard`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Matches for a Game
     * Get all Matches for a Game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdMatchesGet(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesGet(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesGet(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesGet(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/matches`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single Match
     * Get specific Game Match
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param match_id Match Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdGet(tenant_id: number, game_unique_id: string, match_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameMatchHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdGet(tenant_id: number, game_unique_id: string, match_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameMatchHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdGet(tenant_id: number, game_unique_id: string, match_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameMatchHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdGet(tenant_id: number, game_unique_id: string, match_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdGet.');
        }
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameMatchHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Markets for a Match
     * Get all Markets for a Match
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param match_id Match Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet(tenant_id: number, game_unique_id: string, match_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchMarketListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet(tenant_id: number, game_unique_id: string, match_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchMarketListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet(tenant_id: number, game_unique_id: string, match_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchMarketListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet(tenant_id: number, game_unique_id: string, match_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet.');
        }
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/markets`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a specific Market with all Market Lines (Specifiers) for a Match
     * Get a Market for a Match
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param match_id Match Id
     * @param market_id Market Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchMarketListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchMarketListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchMarketListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet.');
        }
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet.');
        }
        if (market_id === null || market_id === undefined) {
            throw new Error('Required parameter market_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/markets/${this.configuration.encodeParam({name: "market_id", value: market_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get specific Market (single Market Line) for a Match
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param match_id Match Id
     * @param market_id Market Id
     * @param specifier Specifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, specifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchMarketHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, specifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchMarketHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, specifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchMarketHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet(tenant_id: number, game_unique_id: string, match_id: number, market_id: number, specifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet.');
        }
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet.');
        }
        if (market_id === null || market_id === undefined) {
            throw new Error('Required parameter market_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet.');
        }
        if (specifier === null || specifier === undefined) {
            throw new Error('Required parameter specifier was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsMarketIdSpecifierGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/markets/${this.configuration.encodeParam({name: "market_id", value: market_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/${this.configuration.encodeParam({name: "specifier", value: specifier, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchMarketHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the calculated Prize-Values of a Prize-Structure for Game
     * Get the calculated Prize-Values of a Game Prize-Structure
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdPrizesGet(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdPrizesGet(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdPrizesGet(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdPrizesGet(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdPrizesGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdPrizesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/prizes`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all related Tenant Rankings
     * Get all Related Tenant Rankings
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdRelatedtenantrankingsGet(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdRelatedtenantrankingsGet(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdRelatedtenantrankingsGet(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdRelatedtenantrankingsGet(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdRelatedtenantrankingsGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdRelatedtenantrankingsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/relatedtenantrankings`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Game Settings
     * Get all Game Settings
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdSettingsGet(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameSettingHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdSettingsGet(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameSettingHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdSettingsGet(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameSettingHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdSettingsGet(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdSettingsGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdSettingsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/settings`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameSettingHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Game Share Data
     * Get Game Share Data
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdSharedataGet(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdSharedataGet(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdSharedataGet(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdSharedataGet(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdSharedataGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdSharedataGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/sharedata`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Game Matches for Simple Snap
     * @param tenant_id Tenant ID
     * @param game_unique_id Game Unique ID
     * @param lang Language ISO2
     * @param market_ids Market Ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdSimpleMatchesGet(tenant_id: number, game_unique_id: string, lang?: string, market_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdSimpleMatchesGet(tenant_id: number, game_unique_id: string, lang?: string, market_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdSimpleMatchesGet(tenant_id: number, game_unique_id: string, lang?: string, market_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdSimpleMatchesGet(tenant_id: number, game_unique_id: string, lang?: string, market_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdSimpleMatchesGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdSimpleMatchesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (lang !== undefined && lang !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lang, 'lang');
        }
        if (market_ids !== undefined && market_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>market_ids, 'market_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/simple/matches`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapGameMatchListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shows all Users of a Game
     * shows all Users of a Game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param user_ids User Ids (comma separated)
     * @param only_friends Show only users who are friends of authenticated user
     * @param authorization Valid oAuth access_token (Format-Hint: Bearer {Token}) (optional)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersGet(tenant_id: number, game_unique_id: string, per_page?: number, page?: number, user_ids?: string, only_friends?: boolean, authorization?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameUserListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersGet(tenant_id: number, game_unique_id: string, per_page?: number, page?: number, user_ids?: string, only_friends?: boolean, authorization?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameUserListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersGet(tenant_id: number, game_unique_id: string, per_page?: number, page?: number, user_ids?: string, only_friends?: boolean, authorization?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameUserListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersGet(tenant_id: number, game_unique_id: string, per_page?: number, page?: number, user_ids?: string, only_friends?: boolean, authorization?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (user_ids !== undefined && user_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>user_ids, 'user_ids');
        }
        if (only_friends !== undefined && only_friends !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_friends, 'only_friends');
        }

        let localVarHeaders = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameUserListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Game Invitation from foreign User
     * Set Game Invitation from foreign User
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param sender_user_unique_id Sender User Unique Id
     * @param receiver_user_unique_id Receiver User Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut(tenant_id: number, game_unique_id: string, sender_user_unique_id: string, receiver_user_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut(tenant_id: number, game_unique_id: string, sender_user_unique_id: string, receiver_user_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut(tenant_id: number, game_unique_id: string, sender_user_unique_id: string, receiver_user_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut(tenant_id: number, game_unique_id: string, sender_user_unique_id: string, receiver_user_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut.');
        }
        if (sender_user_unique_id === null || sender_user_unique_id === undefined) {
            throw new Error('Required parameter sender_user_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut.');
        }
        if (receiver_user_unique_id === null || receiver_user_unique_id === undefined) {
            throw new Error('Required parameter receiver_user_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "sender_user_unique_id", value: sender_user_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/invite/${this.configuration.encodeParam({name: "receiver_user_unique_id", value: receiver_user_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Place a batch of User Bets
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param user_id User Id
     * @param app_http_requests_betsnaps_games_user_bet_place_bet_batch_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_batch_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_batch_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_batch_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_batch_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost.');
        }
        if (app_http_requests_betsnaps_games_user_bet_place_bet_batch_request === null || app_http_requests_betsnaps_games_user_bet_place_bet_batch_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_user_bet_place_bet_batch_request was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/bets-batch`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_user_bet_place_bet_batch_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all User Bets for a Game
     * Get all Bets for a User
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param user_id User Id
     * @param status Filter Status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet(tenant_id: number, game_unique_id: string, user_id: number, status?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesUserBetListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet(tenant_id: number, game_unique_id: string, user_id: number, status?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesUserBetListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet(tenant_id: number, game_unique_id: string, user_id: number, status?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesUserBetListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet(tenant_id: number, game_unique_id: string, user_id: number, status?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/bets`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesUserBetListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Place a User Bet
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param user_id User Id
     * @param app_http_requests_betsnaps_games_user_bet_place_bet_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_user_bet_place_bet_request: AppHttpRequestsBetsnapsGamesUserBetPlaceBetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost.');
        }
        if (app_http_requests_betsnaps_games_user_bet_place_bet_request === null || app_http_requests_betsnaps_games_user_bet_place_bet_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_user_bet_place_bet_request was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/bets`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_user_bet_place_bet_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cashout a User Bet
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param user_id User Id
     * @param user_bet_id User Bet Id
     * @param app_http_requests_betsnaps_games_user_bet_cashout_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, app_http_requests_betsnaps_games_user_bet_cashout_request: AppHttpRequestsBetsnapsGamesUserBetCashoutRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, app_http_requests_betsnaps_games_user_bet_cashout_request: AppHttpRequestsBetsnapsGamesUserBetCashoutRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, app_http_requests_betsnaps_games_user_bet_cashout_request: AppHttpRequestsBetsnapsGamesUserBetCashoutRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, app_http_requests_betsnaps_games_user_bet_cashout_request: AppHttpRequestsBetsnapsGamesUserBetCashoutRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut.');
        }
        if (user_bet_id === null || user_bet_id === undefined) {
            throw new Error('Required parameter user_bet_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut.');
        }
        if (app_http_requests_betsnaps_games_user_bet_cashout_request === null || app_http_requests_betsnaps_games_user_bet_cashout_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_user_bet_cashout_request was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/bets/${this.configuration.encodeParam({name: "user_bet_id", value: user_bet_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cashout`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_user_bet_cashout_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single User Bet
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param user_id User Id
     * @param user_bet_id User Bet Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet(tenant_id: number, game_unique_id: string, user_id: number, user_bet_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet.');
        }
        if (user_bet_id === null || user_bet_id === undefined) {
            throw new Error('Required parameter user_bet_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/bets/${this.configuration.encodeParam({name: "user_bet_id", value: user_bet_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Invite friends to a game
     * Invite friends to a game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game Unique Id
     * @param user_id User Id
     * @param app_http_requests_betsnaps_games_game_friend_invitation_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_game_friend_invitation_request: AppHttpRequestsBetsnapsGamesGameFriendInvitationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_game_friend_invitation_request: AppHttpRequestsBetsnapsGamesGameFriendInvitationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_game_friend_invitation_request: AppHttpRequestsBetsnapsGamesGameFriendInvitationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut(tenant_id: number, game_unique_id: string, user_id: number, app_http_requests_betsnaps_games_game_friend_invitation_request: AppHttpRequestsBetsnapsGamesGameFriendInvitationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut.');
        }
        if (app_http_requests_betsnaps_games_game_friend_invitation_request === null || app_http_requests_betsnaps_games_game_friend_invitation_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_friend_invitation_request was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/invitefriends`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_friend_invitation_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Joins a User to a Game
     * Joins a User to a Game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(tenant_id: number, game_unique_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/join`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Disconnects a User from a Game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete(tenant_id: number, game_unique_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/leave`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unsubscribe from game notifications
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete(tenant_id: number, game_unique_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notifications`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Subscribe for game notifications
     * Subscribe for game notifications
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost(tenant_id: number, game_unique_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notifications`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unsubscribe from game notifications by signuature
     * Unsubscribe from game notifications by signuature
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param user_id User Id
     * @param signature Signature
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet(tenant_id: number, game_unique_id: string, user_id: number, signature: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet(tenant_id: number, game_unique_id: string, user_id: number, signature: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet(tenant_id: number, game_unique_id: string, user_id: number, signature: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet(tenant_id: number, game_unique_id: string, user_id: number, signature: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet.');
        }
        if (signature === null || signature === undefined) {
            throw new Error('Required parameter signature was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (signature !== undefined && signature !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>signature, 'signature');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notifications/unsubscribe`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sent game invitations of User
     * Get all sent game invitations of User for Game
     * @param tenant_id Tenant ID
     * @param game_unique_id Game Unique Id
     * @param user_id User ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameInvitationListHttpResponse>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameInvitationListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet(tenant_id: number, game_unique_id: string, user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameInvitationListHttpResponse>>;
    public apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet(tenant_id: number, game_unique_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/sentgameinvitations`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameInvitationListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Games
     * Get all Games
     * @param tenant_id Tenant-ID
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param game_unique_id Filter Game Unique Id
     * @param game_state Filter Game State
     * @param sport_id Filter Sports Id
     * @param is_public Filter is Snap public?
     * @param is_flagged Filter is Snap flagged for some reasons? (Feed-Problems, etc.)
     * @param start_date Filter BetSnap Start-Date
     * @param end_date Filter BetSnap End-Date
     * @param is_highlight Filter Is Highlight
     * @param competition_type Filter Competition Type
     * @param tournaments_type_id Filter Tournaments Type Id
     * @param joined_user_id Filter by games joined User Id
     * @param authorization Valid oAuth access_token (Format-Hint: Bearer {Token}) (optional)
     * @param sorting Sorting of Game Entries (asc or desc, default:asc)
     * @param only_with_friends Show only Game Entries where at least one friend has joined
     * @param exclude_fully_booked Filter by to exclude fully booked games
     * @param include_all_joined_snaps Show also all joined Game Entries which are running, private or fully booked
     * @param exclude_closed_door Filter to exclude closed door games the (authenticated) user is not allowed to join
     * @param check_for_fitting_tags Show only Game Entries where at least one game tag matches users tags or game has no tag
     * @param exclude_singleplayer Exclude Singleplayer games
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamesGet(tenant_id: number, per_page?: number, page?: number, game_unique_id?: string, game_state?: string, sport_id?: string, is_public?: boolean, is_flagged?: boolean, start_date?: string, end_date?: string, is_highlight?: boolean, competition_type?: string, tournaments_type_id?: string, joined_user_id?: number, authorization?: string, sorting?: string, only_with_friends?: boolean, exclude_fully_booked?: boolean, include_all_joined_snaps?: boolean, exclude_closed_door?: boolean, check_for_fitting_tags?: boolean, exclude_singleplayer?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameListHttpResponse>;
    public apiTenantsTenantIdGamesGet(tenant_id: number, per_page?: number, page?: number, game_unique_id?: string, game_state?: string, sport_id?: string, is_public?: boolean, is_flagged?: boolean, start_date?: string, end_date?: string, is_highlight?: boolean, competition_type?: string, tournaments_type_id?: string, joined_user_id?: number, authorization?: string, sorting?: string, only_with_friends?: boolean, exclude_fully_booked?: boolean, include_all_joined_snaps?: boolean, exclude_closed_door?: boolean, check_for_fitting_tags?: boolean, exclude_singleplayer?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameListHttpResponse>>;
    public apiTenantsTenantIdGamesGet(tenant_id: number, per_page?: number, page?: number, game_unique_id?: string, game_state?: string, sport_id?: string, is_public?: boolean, is_flagged?: boolean, start_date?: string, end_date?: string, is_highlight?: boolean, competition_type?: string, tournaments_type_id?: string, joined_user_id?: number, authorization?: string, sorting?: string, only_with_friends?: boolean, exclude_fully_booked?: boolean, include_all_joined_snaps?: boolean, exclude_closed_door?: boolean, check_for_fitting_tags?: boolean, exclude_singleplayer?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameListHttpResponse>>;
    public apiTenantsTenantIdGamesGet(tenant_id: number, per_page?: number, page?: number, game_unique_id?: string, game_state?: string, sport_id?: string, is_public?: boolean, is_flagged?: boolean, start_date?: string, end_date?: string, is_highlight?: boolean, competition_type?: string, tournaments_type_id?: string, joined_user_id?: number, authorization?: string, sorting?: string, only_with_friends?: boolean, exclude_fully_booked?: boolean, include_all_joined_snaps?: boolean, exclude_closed_door?: boolean, check_for_fitting_tags?: boolean, exclude_singleplayer?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (game_unique_id !== undefined && game_unique_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>game_unique_id, 'game_unique_id');
        }
        if (game_state !== undefined && game_state !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>game_state, 'game_state');
        }
        if (sport_id !== undefined && sport_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_id, 'sport_id');
        }
        if (is_public !== undefined && is_public !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_public, 'is_public');
        }
        if (is_flagged !== undefined && is_flagged !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_flagged, 'is_flagged');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }
        if (is_highlight !== undefined && is_highlight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_highlight, 'is_highlight');
        }
        if (competition_type !== undefined && competition_type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>competition_type, 'competition_type');
        }
        if (tournaments_type_id !== undefined && tournaments_type_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tournaments_type_id, 'tournaments_type_id');
        }
        if (joined_user_id !== undefined && joined_user_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>joined_user_id, 'joined_user_id');
        }
        if (sorting !== undefined && sorting !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sorting, 'sorting');
        }
        if (only_with_friends !== undefined && only_with_friends !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_with_friends, 'only_with_friends');
        }
        if (exclude_fully_booked !== undefined && exclude_fully_booked !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exclude_fully_booked, 'exclude_fully_booked');
        }
        if (include_all_joined_snaps !== undefined && include_all_joined_snaps !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>include_all_joined_snaps, 'include_all_joined_snaps');
        }
        if (exclude_closed_door !== undefined && exclude_closed_door !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exclude_closed_door, 'exclude_closed_door');
        }
        if (check_for_fitting_tags !== undefined && check_for_fitting_tags !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>check_for_fitting_tags, 'check_for_fitting_tags');
        }
        if (exclude_singleplayer !== undefined && exclude_singleplayer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exclude_singleplayer, 'exclude_singleplayer');
        }

        let localVarHeaders = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Game Winners
     * Get all Game Winners
     * @param tenant_id Tenant-ID
     * @param game_unique_id Filter Game Unique Id
     * @param start_date Filter BetSnap Start-Date
     * @param end_date Filter BetSnap End-Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGamewinnersGet(tenant_id: number, game_unique_id?: string, start_date?: string, end_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameWinnerListHttpResponse>;
    public apiTenantsTenantIdGamewinnersGet(tenant_id: number, game_unique_id?: string, start_date?: string, end_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameWinnerListHttpResponse>>;
    public apiTenantsTenantIdGamewinnersGet(tenant_id: number, game_unique_id?: string, start_date?: string, end_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameWinnerListHttpResponse>>;
    public apiTenantsTenantIdGamewinnersGet(tenant_id: number, game_unique_id?: string, start_date?: string, end_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGamewinnersGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (game_unique_id !== undefined && game_unique_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>game_unique_id, 'game_unique_id');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/gamewinners`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameWinnerListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all not joined not fully booked not running invited Games of User
     * Get all not joined not fully booked not running invited Games of User
     * @param tenant_id Tenant ID
     * @param user_id User ID
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdUsersUserIdGameinvitationsGamesGet(tenant_id: number, user_id: number, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameListHttpResponse>;
    public apiTenantsTenantIdUsersUserIdGameinvitationsGamesGet(tenant_id: number, user_id: number, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameListHttpResponse>>;
    public apiTenantsTenantIdUsersUserIdGameinvitationsGamesGet(tenant_id: number, user_id: number, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameListHttpResponse>>;
    public apiTenantsTenantIdUsersUserIdGameinvitationsGamesGet(tenant_id: number, user_id: number, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdUsersUserIdGameinvitationsGamesGet.');
        }
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiTenantsTenantIdUsersUserIdGameinvitationsGamesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/gameinvitations/games`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
