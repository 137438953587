
export function replacePlaceholder(replacementData: Object, text: string): string {
    if (replacementData && typeof replacementData === 'object') {
        for (const replacementKey in replacementData) {
            if (replacementData.hasOwnProperty(replacementKey)) {
                const replacementValue = replacementData[replacementKey];
                if (replacementValue && typeof replacementValue === 'string') {
                    text = text.replace('{' + replacementKey + '}', replacementValue);
                } else {
                    text = text.replace('{' + replacementKey + '}', '');
                }
            }
        }
    }
    return text;
}

export function clearNotReplacedPlaceholder(text: string): string {
    const regex = /\{\S+?\}/g;
    text = text.replace(regex, '');
    return text;
}

export function getUrlGetParameterSeparator(url: string): string {
    let parameterSeparator = '?';
    if (url.split('?')[1]) {
        parameterSeparator = '&';
    }
    return parameterSeparator;
}

export function atobUnicode(str: string): string {
    return decodeURIComponent(atob(str));
}

export function btoaUnicode(str: string): string {
    return btoa(encodeURIComponent(str));
}
