<div class="ui centered relaxed grid container">
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <div class="ui center aligned borderless vertical segment margin-0">
            <h2 class="ui center aligned small header">
                {{'REGISTER.heading' | translate | uppercase}}
            </h2>
        </div>
    </div>
    <div class="sixteen wide mobile fourteen wide tablet twelve wide computer center aligned column">
        <div class="ui vertical very padded padding-t-0 segment" *ngIf="showAlert">
            <fui-message class="{{alertMessage.type ?? ''}}" [hasDismissButton]="false">
                <div class="header" *ngIf="alertMessage.messagetranslatekey">
                    {{alertMessage.messagetranslatekey | translate}}
                </div>
                <p *ngIf="alertMessage.messagetxttranslatekey">
                    {{alertMessage.messagetxttranslatekey | translate}}
                </p>
            </fui-message>
        </div>
        <div class="ui center aligned borderless vertical very padded segment margin-0" *ngIf="showSpinner">
            <div class="ui active centered inline text loader">
                {{'GENERAL.loadertext' | translate}}
            </div>
        </div>
        <div *ngIf="!showSpinner && showForm">
            <div class="field margin-b-20" *ngIf="!socialSignupPlatform && (facebookLoginEnabled || googleLoginEnabled)">
                <div [ngClass]="'ui ' + ((facebookLoginEnabled && googleLoginEnabled) ? 'two' : 'one') + ' column grid'">
                    <div class="column center aligned" *ngIf="facebookLoginEnabled">
                        <button type="button"
                                class="ui facebook social fluid button"
                                (click)="initiateSocialSignIn('facebook')"
                                [class.loading]="(processSocialLogin === 'facebook')"
                                [disabled]="(processSocialLogin !== null || processForm)">
                            <i class="fa fa-facebook-square icon"></i><span class="display-inline-block vertical-align-middle">
                                    FACEBOOK
                                </span>
                        </button>
                    </div>
                    <div class="column center aligned" *ngIf="googleLoginEnabled">
                        <button type="button"
                                *ngIf="!isIOSPrivateBrowser"
                                class="ui google social fluid button margin-0"
                                (click)="initiateSocialSignIn('google')"
                                [class.loading]="(processSocialLogin === 'google')"
                                [disabled]="(processSocialLogin !== null || processForm)">
                            <img [src]="'./assets/img/google-signin-logo.png'" [alt]=""
                                 height="16" width="16"
                                 class="display-inline-block vertical-align-middle iconimage"/><span class="display-inline-block vertical-align-middle">
                                        GOOGLE
                                 </span>
                        </button>
                        <button type="button"
                                *ngIf="isIOSPrivateBrowser"
                                class="ui google social fluid button margin-0 opacity-50"
                                fuiPopup
                                popupText="{{'REGISTER.gplus_login_ioschrome_disabled_txt' | translate}}"
                                popupTrigger="manual" [popupInverted]="false" popupPlacement="bottom left" #popup="fuiPopup"
                                (click)="popup.toggle()">
                            <img [src]="'./assets/img/google-signin-logo.png'" [alt]=""
                                 height="16" width="16"
                                 class="display-inline-block vertical-align-middle iconimage"/><span class="display-inline-block vertical-align-middle">
                                    GOOGLE
                                </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="field margin-b-20" *ngIf="tenantService.isB2b && tenantService.getB2bWebsiteRegisterUrl()">
                <div class="column center aligned">
                    <button type="button"
                            class="ui primary social fluid button"
                            [disabled]="processForm"
                            (click)="goToB2bRegister()">
                        <img *ngIf="tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.B2B_LOGIN_BUTTON)"
                             [src]="tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.B2B_LOGIN_BUTTON).media_url"
                             class="display-inline-block vertical-align-middle iconimage"
                             [class.margin-0]="('LOGIN.b2b_login_btn' | translate) === '-'" />
                        <span *ngIf="('LOGIN.b2b_login_btn' | translate) !== '-'"
                              class="display-inline-block vertical-align-middle">
                                {{'LOGIN.b2b_login_btn' | translate}}
                            </span>
                    </button>
                </div>
            </div>
            <div class="ui horizontal divider" *ngIf="(!socialSignupPlatform && (facebookLoginEnabled || googleLoginEnabled)) || (tenantService.isB2b && tenantService.getB2bWebsiteLoginUrl())">{{'LOGIN.divider_label' | translate}}</div>
            <form class="ui form" novalidate [formGroup]="registerForm" (ngSubmit)="onSubmit()"
                  [class.error]="registerForm.invalid && submitted && !processForm">
                <div class="field" [class.disabled]="processForm"
                     *ngIf="!socialSignupPlatform && b2cSignupMethod === b2cSignupMethodEnum.EMAIL">
                    <input id="email"
                           type="text"
                           placeholder="{{'REGISTER.email_field' | translate}}"
                           formControlName="email"/>
                    <div class="ui pointing red basic label"
                         *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)">
                        <p *ngIf="registerForm.get('email').hasError('required')">
                            {{'REGISTER.ERRORS.email_field_required' | translate}}
                        </p>
                        <p *ngIf="registerForm.get('email').dirty && registerForm.get('email').hasError('email')">
                            {{'REGISTER.ERRORS.email:validation_error_email' | translate}}
                        </p>
                    </div>
                </div>
                <betsnaps-phone-field *ngIf="!socialSignupPlatform && b2cSignupMethod === b2cSignupMethodEnum.PHONE"
                                      [processForm]="processForm"
                                      [phoneSignupAllowedCountryCodes]="phoneSignupAllowedCountryCodes"
                                      [form]="registerForm"></betsnaps-phone-field>
                <div class="field" [class.disabled]="processForm" *ngIf="isDemoTenant">
                    <input id="signup_reason"
                           type="text"
                           placeholder="{{'REGISTER.signup_reason_field' | translate}}"
                           formControlName="signup_reason"/>
                    <div class="ui pointing red basic label"
                         *ngIf="registerForm.get('signup_reason').invalid && (registerForm.get('signup_reason').dirty || registerForm.get('signup_reason').touched)">
                        <p *ngIf="registerForm.get('signup_reason').hasError('required')">
                            {{'REGISTER.ERRORS.signup_reason_field_required' | translate}}
                        </p>
                    </div>
                </div>
                <div class="field" [class.disabled]="processForm" *ngIf="playerCurrencies?.length > 1">
                    <select id="currency_id" name="currency_id" formControlName="currency_id" class="ui dropdown">
                        <option value="" disabled>{{'REGISTER.select_currency' | translate}}</option>
                        <option *ngFor="let currency of playerCurrencies" [value]="currency.currency_id">
                            {{currency.currency.name}} ({{currency.currency.iso_code3}})
                        </option>
                    </select>
                </div>
                <div *ngIf="playerCurrencies?.length === 1">
                    <input type="hidden" name="currency_id" formControlName="currency_id"/>
                </div>
                <div formGroupName="passwordGroup" class="one field" *ngIf="!socialSignupPlatform">
                    <div class="field" [class.disabled]="processForm">
                        <div class="ui icon input">
                            <input id="password"
                                   type="password"
                                   #passwordInput
                                   placeholder="{{'REGISTER.password_field' | translate}}"
                                   formControlName="password"/>
                            <i (click)="passwordVisible = togglePasswordVisibility(passwordInputElement)"
                               class="link icon fa"
                               [class.fa-eye]="!passwordVisible"
                               [class.fa-eye-slash]="passwordVisible"></i>
                        </div>
                        <div class="ui pointing red basic label"
                             *ngIf="registerForm.controls['passwordGroup'].get('password').invalid && (registerForm.controls['passwordGroup'].get('password').dirty || registerForm.controls['passwordGroup'].get('password').touched)">
                            <p *ngIf="registerForm.controls['passwordGroup'].get('password').hasError('required')">
                                {{'REGISTER.ERRORS.password_field_required' | translate}}
                            </p>
                            <p *ngIf="registerForm.controls['passwordGroup'].get('password').dirty && registerForm.controls['passwordGroup'].get('password').hasError('minlength')">
                                {{'REGISTER.ERRORS.password_too_short' | translate}}
                            </p>
                        </div>
                    </div>
                    <div class="field" [class.disabled]="processForm">
                        <div class="ui icon input">
                            <input id="password_confirm"
                                   type="password"
                                   #passwordConfirmInput
                                   placeholder="{{'REGISTER.password-confirm_field' | translate}}"
                                   formControlName="password_confirm"/>
                            <i (click)="passwordConfirmVisible = togglePasswordVisibility(passwordConfirmInputElement)"
                               class="link icon fa"
                               [class.fa-eye]="!passwordConfirmVisible"
                               [class.fa-eye-slash]="passwordConfirmVisible"></i>
                        </div>
                        <div class="ui pointing red basic label"
                             *ngIf="registerForm.controls['passwordGroup'].get('password_confirm').touched &&
                                                (registerForm.controls['passwordGroup'].get('password_confirm').invalid || registerForm.controls['passwordGroup'].hasError('areEqual'))">
                            <p *ngIf="registerForm.controls['passwordGroup'].get('password_confirm').hasError('required')">
                                {{'REGISTER.ERRORS.password-confirm_field_required' | translate}}
                            </p>
                            <p *ngIf="registerForm.controls['passwordGroup'].hasError('areEqual')">
                                {{'REGISTER.ERRORS.passwords_equal' | translate}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="field" [class.disabled]="processForm" *ngFor="let tenantInquiry of tenantInquiries">
                    <select id="{{tenantInquiry.name}}"
                            name="{{tenantInquiry.name}}"
                            formControlName="{{tenantInquiry.name}}"
                            class="ui dropdown">
                        <option value="" disabled>{{tenantInquiry.text}}</option>
                        <option *ngFor="let tenantInquiryAnswer of tenantInquiry.answers"
                                [value]="tenantInquiryAnswer.id">
                            {{tenantInquiryAnswer.text}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <input id="invite_token"
                           type="hidden"
                           placeholder="{{'REGISTER.invite_token_field' | translate}}"
                           formControlName="invite_token">
                </div>
                <div class="field left aligned" [class.disabled]="processForm">
                    <fui-checkbox class="single-checkbox"
                                  name="terms_checked"
                                  id="terms_checked"
                                  formControlName="terms_checked">
                        {{'REGISTER.agree_to_terms_and_conditions' | translate | translateCut: 0}}
                        <span *ngIf="!tenantFooterItem">
                            {{ 'REGISTER.agree_to_terms_and_conditions' | translate | translateCut: 1}}
                        </span>
                        <span *ngIf="tenantFooterItem && tenantFooterItem.landing_page_url">
                                        <a [routerLink]="[tenantFooterItem.landing_page_url]"
                                           *ngIf="!tenantFooterItem.is_landing_page_external">{{ 'REGISTER.agree_to_terms_and_conditions' | translate | translateCut: 1}}</a>
                                        <a *ngIf="tenantFooterItem.is_landing_page_external"
                                           [href]="tenantFooterItem.landing_page_url | dbtranslation:'landing_page_url':tenantFooterItem.translations"
                                           target="_blank"
                                           rel="noopener noreferrer">{{ 'REGISTER.agree_to_terms_and_conditions' | translate | translateCut: 1}}</a>
                                    </span>
                        {{ 'REGISTER.agree_to_terms_and_conditions' | translate | translateCut: 2}}
                    </fui-checkbox>
                </div>
                <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10 padding-b-0">
                    <button *ngIf="!socialSignupPlatform" class="ui large fluid button margin-0" type="submit"
                            (click)="$event.preventDefault(); onRegisterClick(captchaRef)"
                            [class.loading]="processForm"
                            [disabled]="processForm || (!registerForm.valid || registerForm.get('terms_checked').value !== true)">
                        {{'REGISTER.confirm_btn' | translate | uppercase}}
                    </button>
                    <button *ngIf="(socialSignupPlatform === 'facebook')"
                            class="ui facebook social fluid button margin-0"
                            type="submit"
                            [class.loading]="processForm"
                            [disabled]="processForm || (!registerForm.valid || registerForm.get('terms_checked').value !== true)">
                        <i class="fa fa-facebook-square icon"></i> {{'REGISTER.social_confirm_btn' | translate | uppercase}}
                        FACEBOOK
                    </button>
                    <button *ngIf="(socialSignupPlatform === 'google')" class="ui google social fluid button margin-0"
                            type="submit"
                            [class.loading]="processForm"
                            [disabled]="processForm || (!registerForm.valid || registerForm.get('terms_checked').value !== true)">
                        <img [src]="'./assets/img/google-signin-logo.png'" height="16" width="16"
                             class="display-inline-block vertical-align-middle iconimage"/><span
                        class="display-inline-block vertical-align-middle">
                                {{'REGISTER.social_confirm_btn' | translate | uppercase}} GOOGLE
                            </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="ui sixteen wide center aligned column padding-b-0">
        <div class="ui row divider"></div>
        <div class="color-grey" *ngIf="b2cSignupMethod === b2cSignupMethodEnum.EMAIL">
            {{'REGISTER.resend_verification_label' | translate}}
            <a class="text font-bold nowrap"
               (click)="showView('resend-email-verification')">
                {{'REGISTER.resend_verification_btn' | translate}}
            </a>
        </div>
        <div class="color-grey" *ngIf="b2cSignupMethod === b2cSignupMethodEnum.PHONE">
            {{'REGISTER.phone_verification_label' | translate}}<br/>
            <a class="text font-bold nowrap"
               (click)="showView('phone-verification')">
                {{'REGISTER.phone_verification_btn' | translate}}
            </a>&nbsp;-&nbsp;<a class="text font-bold nowrap"
                                (click)="showView('resend-phone-verification')">
            {{'REGISTER.resend_verification_btn' | translate}}</a>
        </div>
        <div class="color-grey margin-t-10">
            {{'LOGIN.already_an_account_label' | translate}}
            <a class="text font-bold nowrap"
               (click)="showView('login')">
                {{'LOGIN.already_an_account_btn' | translate}}
            </a>
        </div>
        <div *ngIf="recaptchaLoaderReady">
            <div class="badge-container">
                <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"></re-captcha>
            </div>
        </div>
    </div>
</div>
