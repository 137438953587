/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantGameMaxPlayerRequest
 */
export interface AppHttpRequestsTenantsTenantGameMaxPlayerRequest { 
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Is admin only
     */
    is_admin_only?: boolean;
}

