/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataTournamentSeasonHttpResponse } from './appHttpResponsesSportsDataTournamentSeasonHttpResponse';
import { AppHttpResponsesSportsDataSportHttpResponse } from './appHttpResponsesSportsDataSportHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TournamentHttpResponse
 */
export interface AppHttpResponsesSportsDataTournamentHttpResponse { 
    /**
     * Tournament Id
     */
    id: number;
    /**
     * Name
     */
    name: string;
    /**
     * Sport Id
     */
    sport_id: number;
    /**
     * Category Id
     */
    category_id: number;
    /**
     * Category Name
     */
    category_name: string;
    /**
     * Category Icon
     */
    category_icon?: string;
    /**
     * Round Type
     */
    round_type?: string;
    /**
     * Round Number
     */
    round_number?: string;
    /**
     * Round Name
     */
    round_name?: string;
    /**
     * Round Cup Round Match Number
     */
    round_cup_round_match_number?: string;
    /**
     * Round Cup Round Matches
     */
    round_cup_round_matches?: string;
    /**
     * Active
     */
    active: number;
    /**
     * Current Season Id
     */
    current_season_id?: number;
    /**
     * Current Season Start Date
     */
    start_date?: Date;
    /**
     * Current Season End Date
     */
    end_date?: Date;
    /**
     * Hasdetails
     */
    hasdetails: boolean;
    /**
     * Nodetails Message
     */
    nodetails_message?: string;
    /**
     * Tournament Translation
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    sport?: AppHttpResponsesSportsDataSportHttpResponse;
    current_season?: AppHttpResponsesSportsDataTournamentSeasonHttpResponse;
}

