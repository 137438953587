import {NgModule} from '@angular/core';
import {Routes, RouterModule, RouteReuseStrategy} from '@angular/router';

import {NotfoundComponent} from './ui/views/notfound/notfound.component';
import {LoginComponent} from './ui/views/login/login.component';
import {B2bLoginComponent} from './ui/views/login/b2b-login.component';
import {B2bLoginInitializeComponent} from './ui/views/login/b2b-login-initialize.component';
import {ResetPasswordComponent} from './ui/views/reset-password/reset-password.component';
import {RegisterVerifyComponent} from './ui/views/register/register-verify.component';
import {NewUserInviteComponent} from './ui/views/new-user-invite/new-user-invite.component';
import {DeleteAccountComponent} from './ui/views/delete-account/delete-account.component';

import {
    AuthGuard
} from './shared';
import {MaintenanceComponent} from './ui/views/maintenance/maintenance.component';
import {RegisterComponent} from './ui/views/register/register.component';
import {CustomRouteReuseStrategy} from './shared/classes/custom-route-reuse-strategy';
import {HomeComponent} from './ui/views/home/home.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            title: 'Home',
            translateKey: 'home',
            withBgImg: false,
            withMaintenanceBgImg: true,
            bigLogoHeader: true,
            showMobileAd: false
        }
    },
    {
        path: 'arena',
        loadChildren: () => import('./ui/views/arena/arena.module').then(m => m.ArenaModule),
    },
    {
        path: 'my-snaps',
        loadChildren: () => import('./ui/views/my-snaps/my-snaps.module').then(m => m.MySnapsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'ranking',
        loadChildren: () => import('./ui/views/ranking/ranking.module').then(m => m.RankingModule),
    },
    {
        path: 'friends',
        loadChildren: () => import('./ui/views/friends/friends.module').then(m => m.FriendsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'news',
        loadChildren: () => import('./ui/views/newsfeed/newsfeed.module').then(m => m.NewsfeedModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./ui/views/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'betsnapdetail/:game_unique_id',
        loadChildren: () => import('./ui/views/betsnapdetail/betsnapdetail.module').then(m => m.BetsnapdetailModule),
    },
    {
        path: 'createsnap',
        loadChildren: () => import('./ui/views/create-snap/create-snap.module').then(m => m.CreateSnapModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'betsnapcard',
        loadChildren: () => import('./ui/views/betsnapcard/betsnapcard.module').then(m => m.BetsnapcardModule),
    },

    // User relevant
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login',
            translateKey: 'login',
            withBgImg: true
        }
    },
    {
        path: 'b2b/login',
        component: B2bLoginComponent,
        data: {
            title: 'Login',
            translateKey: 'login',
            withBgImg: true
        }
    },
    {
        path: 'b2b/login/initialize',
        component: B2bLoginInitializeComponent,
        data: {
            title: 'Login',
            translateKey: 'login',
            withBgImg: true
        }
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            title: 'Register',
            translateKey: 'register',
            withBgImg: true
        }
    },
    {
        path: 'register/:verification_token',
        component: RegisterVerifyComponent,
        data: {
            title: 'Register',
            translateKey: 'register',
            withBgImg: true
        }
    },
    {
        path: 'resetpassword/:new_password_key',
        component: ResetPasswordComponent,
        data: {
            title: 'Reset Password',
            translateKey: 'resetpassword',
            withBgImg: true
        }
    },
    {
        path: 'deleteaccount/:delete_token',
        component: DeleteAccountComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Delete Account',
            translateKey: 'deleteaccount',
            withBgImg: true
        }
    },
    {
        path: 'i/:user_unique_id',
        component: NewUserInviteComponent,
        data: {
            withBgImg: true,
            bigLogoHeader: true
        }
    },

    // General
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        data: {
            title: 'Maintenance',
            translateKey: 'maintenance',
            withBgImg: true,
            withMaintenanceBgImg: true,
            bigLogoHeader: true
        }
    },
    // Redirect configuration
    {
        path: '**', component: NotfoundComponent,
        data: {
            title: '404 not found',
            translateKey: 'notfound',
            withBgImg: true,
            withMaintenanceBgImg: true
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [
        {provide: AuthGuard, useClass: AuthGuard},
        {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy}
    ]
})
export class AppRoutingModule {
}
