<div class="ui middle aligned grid">
    <div class="row">
        <div [ngClass]="(shouldShowScore() ? getCricketColumnClasses()[0] : 'sixteen') + ' wide column left aligned padding-l-0'">
            <div class="ui list team-names">
                <div class="item">
                    <span *ngIf="match.home" class="truncate">
                        {{match.home.name | dbtranslation:'name':match.home?.translations}}
                    </span>
                    <span *ngIf="!match.home" class="truncate">
                        {{match.home_name}}
                    </span>
                    <span class="ui red tiny circular empty label current-innings-team-label"
                          *ngIf="match.score && match.score.match_status_code && [501, 503, 506].includes(match.score.match_status_code.betradar_status_id)">
                    </span>
                </div>
                <div class="item">
                    <span *ngIf="match.away" class="truncate">
                        {{match.away.name | dbtranslation:'name':match.away?.translations}}
                    </span>
                    <span *ngIf="!match.away" class="truncate">
                        {{match.away_name}}
                    </span>
                    <span class="ui red tiny circular empty label current-innings-team-label"
                          *ngIf="match.score && match.score.match_status_code && [502, 504, 507].includes(match.score.match_status_code.betradar_status_id)">
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="getCricketColumnClasses()[1] + ' wide column right aligned'" *ngIf="shouldShowScore()">
            <div class="ui list cricket-score over-count center aligned"
                 *ngIf="match.score.over && match.score.match_status_code &&
                        [501, 502, 503, 504, 506, 507].includes(match.score.match_status_code.betradar_status_id) &&
                        (match.status === 'live' || match.status === 'interrupted' || match.status === 'suspended')">
                <div class="item">
                    <span class="text-mini font-bold">
                        {{'GENERAL.MATCHES.CRICKET.OVER' | translate}}
                    </span>
                </div>
                <div class="item padding-0">
                    <span class="text-big font-very-bold">
                        {{match.score.over}}
                    </span>
                </div>
            </div>
            <div class="ui list cricket-score delivery-count center aligned"
                 *ngIf="match.score && match.score.match_status_code && match.score.delivery &&
                        [501, 502, 503, 504, 506, 507].includes(match.score.match_status_code.betradar_status_id) &&
                        (match.status === 'live' || match.status === 'interrupted' || match.status === 'suspended')">
                <div class="item">
                    <span class="text-mini font-bold">
                        {{'GENERAL.MATCHES.CRICKET.DELIVERY' | translate}}
                    </span>
                </div>
                <div class="item padding-0">
                    <span class="text-big font-very-bold">
                        {{match.score.delivery}}
                    </span>
                </div>
            </div>
            <div class="ui list cricket-score total-score text-large center aligned font-very-bold"
                 [class.text-primary]="match.status === 'live'">
                <div class="item">
                    {{match.score.home_score}}<span *ngIf="match.score.home_dismissals !== null && match.liveodds === 'booked'">/{{match.score.home_dismissals}}</span>
                </div>
                <div class="item padding-0">
                    {{match.score.away_score}}<span *ngIf="match.score.away_dismissals !== null && match.liveodds === 'booked'">/{{match.score.away_dismissals}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
