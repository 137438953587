/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse';


/**
 * GameLeaderboardUserGroupHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameLeaderboardUserGroupHttpResponse { 
    /**
     * Game Id
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * User Id
     */
    user_id: number;
    /**
     * Group Id
     */
    group_id: number;
    /**
     * Rank
     */
    rank: number;
    /**
     * Rank Position Change Up
     */
    rank_change_down?: number;
    /**
     * Rank Position Change Down
     */
    rank_change_up?: number;
    /**
     * Points to Second Rank if First
     */
    points_in_lead?: number;
    /**
     * Points to First Rank
     */
    points_to_first: number;
    /**
     * Points to Prize Ranks
     */
    points_to_prizes: number;
    /**
     * Prize Amount
     */
    prize_amount: number;
    /**
     * Tenant Prize Id
     */
    tenant_prize_id?: number;
    tenant_prize?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse;
    tenant_prize_template?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse;
    /**
     * Is Winner
     */
    is_winner: AppHttpResponsesBetsnapsGamesGameLeaderboardUserGroupHttpResponse.IsWinnerEnum;
}
export namespace AppHttpResponsesBetsnapsGamesGameLeaderboardUserGroupHttpResponse {
    export type IsWinnerEnum = '0' | '1';
    export const IsWinnerEnum = {
        _0: '0' as IsWinnerEnum,
        _1: '1' as IsWinnerEnum
    };
}


