import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameUserHttpResponse as GameUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameUserListHttpResponse as GameUserListHttpResponse
} from '../../../../api';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    FriendsService,
    MyTranslateService,
    WindowRef
} from '../../../../shared';
import {Router} from '@angular/router';
import {take, takeWhile} from 'rxjs/operators';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';

@Component({
    selector: 'betsnaps-betsnapdetail-players',
    templateUrl: './betsnapdetail-players.component.html',
    styles: []
})
export class BetsnapdetailPlayersComponent implements OnInit, OnDestroy {

    private componentAlive = true;

    private game: GameHttpResponse;

    public gameUsersList: GameUserListHttpResponse;
    public gameUsersFriendsList: GameUserListHttpResponse;

    public visibleGameUsersList: GameUserListHttpResponse;

    public gameUsersChanged: boolean = false;
    public processList: boolean = true;
    public processLoadMore: boolean = false;

    public playersMenu = [
        {
            'key': 'all',
            'translatekey': 'BETSNAPDETAIL.RANKING.MENU.all',
            'active': true
        }, {
            'key': 'friends',
            'translatekey': 'BETSNAPDETAIL.RANKING.MENU.friends',
            'active': false
        }
    ];
    public activeTab = 'all';

    // Pagination
    private perPage: number = 24;
    private currentPage: number = 1;

    @ViewChild('loadMoreButton') public loadMoreButton: ElementRef;

    private nativeWindow: Window;

    constructor(private betsnapdetailService: BetsnapdetailService,
                private authenticationService: AuthenticationService,
                public translations: MyTranslateService,
                private friendsService: FriendsService,
                private pageVisibilityService: AngularPageVisibilityService,
                private router: Router,
                private windowRef: WindowRef,
                private errorService: ErrorService) {

        this.nativeWindow = windowRef.nativeWindow;

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (!this.game && gameHttpResponse) {
                        // if head2head redirect to details
                        if (gameHttpResponse.competition_type === 1) {
                            this.router.navigate(['/betsnapdetail/' + gameHttpResponse.game_unique_id + '/details']);
                            return;
                        } else {
                            if (!this.gameUsersList) {
                                this.updateGameUserList(gameHttpResponse.game_unique_id);
                            }
                        }
                    }
                    this.game = gameHttpResponse;
                }
            );

        this.betsnapdetailService.gameUsersChanged$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameUsersChanged: boolean) => {
                    if (gameUsersChanged && this.gameUsersList) {
                        this.gameUsersChanged = true;
                    }
                }
            );

        // handle page suspends
        this.pageVisibilityService.$onPageVisibilityChange
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                if (visibilityState === AngularPageVisibilityStateEnum.VISIBLE) {
                    if (this.game) {
                        this.updateGameUserList(this.game.game_unique_id);
                    }
                }
            });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    private updateGameUserList(gameUniqueId: string) {
        this.gameUsersChanged = false;
        this.processList = true;
        this.currentPage = 1;
        if (this.authenticationService.currentUser) {
            this.getGameUsersAndJoinedFriends(gameUniqueId);
        } else {
            this.getGameUsers(gameUniqueId);
        }
    }

    private getGameUsersAndJoinedFriends(gameUniqueId: string) {
        this.processList = true;
        this.betsnapdetailService.getGamePlayers(
            gameUniqueId,
            undefined,
            undefined,
            true
        ).pipe(take(1))
        .subscribe({
            next: (gameUserListHttpResponse: GameUserListHttpResponse) => {
                this.gameUsersFriendsList = gameUserListHttpResponse;
                this.betsnapdetailService.playerService.storePublicPlayersFromGameUserslist(gameUserListHttpResponse);
                this.getGameUsers(gameUniqueId);
            },
            error: (err: HttpErrorResponse) => {
                this.processList = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    private getGameUsers(gameUniqueId: string) {
        this.betsnapdetailService.getGamePlayers(
            gameUniqueId,
            this.perPage,
            this.currentPage,
            false
        ).pipe(take(1))
        .subscribe({
            next: (gameUserListHttpResponse: GameUserListHttpResponse) => {
                this.gameUsersList = gameUserListHttpResponse;
                this.betsnapdetailService.playerService.storePublicPlayersFromGameUserslist(gameUserListHttpResponse);
                if (gameUserListHttpResponse) {
                    this.visibleGameUsersList = this.gameUsersList;
                    this.processList = false;
                }
            },
            error: (err: HttpErrorResponse) => {
                this.processList = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    public playersMenuChange(activatekey) {
        this.activeTab = activatekey;
        for (const menuitem of this.playersMenu) {
            menuitem.active = (menuitem.key === activatekey);
        }
        if (activatekey === 'all') {
            this.visibleGameUsersList = this.gameUsersList;
        } else if (activatekey === 'friends') {
            this.visibleGameUsersList = this.gameUsersFriendsList;
        }
    }

    gameUserTrackBy(index: number, gameUser: GameUserHttpResponse): number {
        return gameUser.id;
    }

    reloadData() {
        // reset menu
        for (const menuitem of this.playersMenu) {
            menuitem.active = (menuitem.key === 'all');
        }
        this.activeTab = 'all';
        this.updateGameUserList(this.game.game_unique_id);
    }

    loadMore() {
        if (this.gameUsersList.results && this.gameUsersList.results.length < this.gameUsersList.total) {
            this.processLoadMore = true;
            this.currentPage = this.currentPage + 1;
            this.betsnapdetailService.getGamePlayers(
                this.game.game_unique_id,
                this.perPage,
                this.currentPage,
                false
            ).pipe(take(1))
            .subscribe({
                next: (gameUserListHttpResponse: GameUserListHttpResponse) => {
                    this.gameUsersList.total = gameUserListHttpResponse.total;
                    this.gameUsersList.last_page = gameUserListHttpResponse.last_page;
                    this.gameUsersList.results.push(...gameUserListHttpResponse.results);
                    if (this.activeTab === 'all') {
                        this.visibleGameUsersList = this.gameUsersList;
                    }
                    this.processLoadMore = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.processLoadMore = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
        }
    }

    infiniteScroll() {
        if (typeof this.loadMoreButton !== 'undefined' && this.gameUsersList.results.length < this.gameUsersList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMore && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMore();
            }
        }
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    gameUsersListeners() {
        this.infiniteScroll();
    }

}
