<div class="bs-phone-field" [class.v3]="componentTemplateToLoad === 'v3'" [formGroup]="form">
    <div class="phone_fields_wrapper">
        <div class="field phone_country_code"
             [class.disabled]="processForm || phoneSignupAllowedCountryCodes.length === 1"
             *ngIf="phoneSignupAllowedCountryCodes.length > 0">
            <input *ngIf="phoneSignupAllowedCountryCodes.length === 1"
                   id="phone_country_code_input" type="text"
                   value="{{phoneSignupAllowedCountryCodes[0]}}"
                   formControlName="phone_country_code"/>
            <select *ngIf="phoneSignupAllowedCountryCodes.length > 1"
                    id="phone_country_code_select" formControlName="phone_country_code">
                <option value="{{allowedCountryCode}}"
                        *ngFor="let allowedCountryCode of phoneSignupAllowedCountryCodes">{{allowedCountryCode}}</option>
            </select>
        </div>
        <div class="field phone" [class.disabled]="processForm">
            <input id="phone"
                   type="text"
                   placeholder="{{'REGISTER.phone_field' | translate}}"
                   formControlName="phone"/>
        </div>
    </div>
    <div class="ui pointing red basic label" *ngIf="form.get('phone').invalid && form.get('phone').touched">
        <p *ngIf="form.get('phone').hasError('required')">
            {{'REGISTER.ERRORS.phone_field_required' | translate}}
        </p>
        <p *ngIf="form.get('phone').dirty && (form.get('phone').hasError('pattern') || form.get('phone').hasError('minlength') || form.get('phone').hasError('maxlength'))">
            {{'REGISTER.ERRORS.phone:validation_error_phone' | translate}}
        </p>
    </div>
</div>
