import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    AuthenticationService,
    DebugService, ErrorService,
    GoogleAnalyticsService,
    TenantImageTypeEnum,
    TenantService
} from '../../shared';
import {
    AppHttpResponsesTenantsTenantFooterHttpResponse as TenantFooterHttpResponse,
    AppHttpRequestsGeneralCookieAgreeCreateRequest as CookieAgreeCreateRequest,
    AppHttpResponsesTenantsTenantExternalScriptHttpResponse as ExternalScriptHttpResponse,
    GeneralApi, AppHttpResponsesTenantsTenantFooterListHttpResponse as TenantFooterListHttpResponse
} from '../../api';
import {DOCUMENT} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {NgxCookieBannerComponent} from 'ngx-cookie-banner-gg';
import {take, takeWhile} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'betsnaps-layout',
    templateUrl: './layout.component.html',
    styles: []
})
export class LayoutComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {

    @Input() isWidget: boolean;
    @Input() withMaintenanceBgImg: boolean;

    public backgroundImage: string;

    //@ViewChild('cookieLaw') private cookieLawEl: any;
    @ViewChild("cookieLaw", { static: true }) cookieLawEl: NgxCookieBannerComponent;
    private _cookieSub: Subscription;
    private cookieIsDismiss = false;

    public tenantFooterItem: TenantFooterHttpResponse;
    public tenantFooterDataLoaded: boolean = false;
    public showCookieBanner: boolean = false;

    public externalScriptBodyTopEmbedCode: ExternalScriptHttpResponse[] = [];
    public externalScriptBodyBottomEmbedCode: ExternalScriptHttpResponse[] = [];
    public externalScriptAfterLoginEmbedCode: ExternalScriptHttpResponse[] = [];

    public userLoggedIn: boolean = false;

    constructor(@Inject(DOCUMENT) private document: any,
                private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private httpClient: HttpClient,
                private generalApi: GeneralApi,
                private googleAnalyticsService: GoogleAnalyticsService,
                private debugService: DebugService,
                private errorService: ErrorService) {
    }

    ngOnInit() {
        this.googleAnalyticsService.activateGoogleAnalytics(this.document);
        this.activateExternalScripts();
        this.showCookieBanner = this.tenantService.tenantData.configuration.show_cookie_banner;

        this._cookieSub

        // if cookie law is not yet seen
        if (this.showCookieBanner) {
            this.tenantService.tenantFooters$
                .pipe(takeWhile(() => this.tenantFooterItem === undefined))
                .subscribe((tenantFooterList: TenantFooterListHttpResponse) => {
                    if (tenantFooterList) {
                        this.tenantFooterItem = tenantFooterList.results.find(
                            (footer: TenantFooterHttpResponse) => footer.label === 'Privacy Policy'
                        );
                        this.tenantFooterDataLoaded = true;
                    }
                });
            this.tenantService.loadTenantFooterDataIfNecessary();
        }

        this.authenticationService.userLoginSuccess$.subscribe(
            (userLoggedInSuccessfully: boolean) => {
                if (userLoggedInSuccessfully === true) {
                    this.userLoggedIn = true;
                }
            }
        );
    }

    // It is currently necessary to manually subscribe at this
    // point to initialize the banner component.
    ngAfterViewInit() {
        this._cookieSub = this.cookieLawEl.isSeen.subscribe();
    }

    ngOnDestroy() {
        this._cookieSub.unsubscribe();
    }

    public dismiss() {

        const cookieAgreeCreateRequest: CookieAgreeCreateRequest = {
            'tenant_id': Number(this.tenantService.tenantData.id),
            'user_id': (this.authenticationService.validToken() && this.authenticationService.currentUser) ? Number(this.authenticationService.currentUser.id) : null
        };
        this.generalApi.apiGeneralCookieAgreesPost(cookieAgreeCreateRequest)
            .pipe(take(1))
            .subscribe({
                next: () => {},
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });

        this.cookieLawEl.dismiss();
        this.cookieIsDismiss = true;
    }

    private activateExternalScripts() {
        // create script tags of external scripts
        if (this.tenantService.tenantExternalScripts && this.tenantService.tenantExternalScripts.length > 0) {
            const externalScriptBodyTopEmbedCode = [];
            const externalScriptBodyBottomEmbedCode = [];
            const externalScriptAfterLoginEmbedCode = [];
            this.tenantService.tenantExternalScripts.forEach(
                (externalScriptHttpResponse: ExternalScriptHttpResponse) => {
                    if (externalScriptHttpResponse.head_script_src) {
                        const externalScript = this.document.createElement('script');
                        externalScript.type = 'text/javascript';
                        externalScript.src = externalScriptHttpResponse.head_script_src;
                        this.document.head.appendChild(externalScript);
                    }
                    if (externalScriptHttpResponse.body_top_embed_code) {
                        externalScriptBodyTopEmbedCode.push(externalScriptHttpResponse);
                    }
                    if (externalScriptHttpResponse.body_bottom_embed_code) {
                        externalScriptBodyBottomEmbedCode.push(externalScriptHttpResponse);
                    }
                    if (externalScriptHttpResponse.after_login_embed_code) {
                        externalScriptAfterLoginEmbedCode.push(externalScriptHttpResponse);
                    }
                }
            );
            this.externalScriptBodyTopEmbedCode = externalScriptBodyTopEmbedCode;
            this.externalScriptBodyBottomEmbedCode = externalScriptBodyBottomEmbedCode;
            this.externalScriptAfterLoginEmbedCode = externalScriptAfterLoginEmbedCode;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.withMaintenanceBgImg) {
            const bgImageType = (this.withMaintenanceBgImg === true) ? TenantImageTypeEnum.BACKGROUND_MAINTENANCE : TenantImageTypeEnum.BACKGROUND_MAIN;
            this.backgroundImage = null;
            const tenantImage = this.tenantService.getTenantImageMediaTranslationForLanguage(bgImageType);
            if (tenantImage) {
                this.backgroundImage = 'url("' + tenantImage.media_url + '")';
            }
        }
    }
}
