import {Component} from '@angular/core';
import {
    AuthenticationService, ErrorService,
    ProfileService,
    TenantService,
    WindowRef
} from '../../../../../shared';
import {AProfileWalletComponent} from '../a-profile-wallet.component';
import {Router} from '@angular/router';

@Component({
    selector: 'betsnaps-profile-wallet-v3',
    templateUrl: './profile-wallet-v3.component.html',
    styles: []
})
export class ProfileWalletV3Component extends AProfileWalletComponent {

    constructor(protected authenticationService: AuthenticationService,
                protected profileService: ProfileService,
                public tenantService: TenantService,
                protected windowRef: WindowRef,
                protected errorService: ErrorService,
                protected router: Router) {
        super(
            authenticationService,
            profileService,
            tenantService,
            windowRef,
            errorService,
            router
        );
    }

    protected perPage: number = 10;
}
