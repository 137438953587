<div class="landingpage-container landingpage-esoccerstar-container">
    <div class="landingpage-section landingpage-section-hero">
        <div #landingpageHeroContent class="landingpage-section-content landingpage-hero center aligned">
            <h1 class="hero-headline">
                {{tenantLandingpage?.title | dbtranslation:'title':tenantLandingpage?.translations}}
            </h1>
            <p class="hero-subheadline">
                {{tenantLandingpage?.description | dbtranslation:'description':tenantLandingpage?.translations}}
            </p>

            <div class="ui one column grid hero-buttons center aligned">
                <div class="row">
                    <div class="ten wide column">
                        <button type="button" routerLink="/arena" class="ui fluid big basic button margin-0 padding-r-0 padding-l-0">
                            {{'HOME.arena_btn' | translate | uppercase}}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="ten wide column">
                        <betsnaps-landingpage-login-register-buttons [showRegisterButton]="false" remove-wrapping>
                        </betsnaps-landingpage-login-register-buttons>
                    </div>
                </div>
            </div>

            <div class="element-clickable center aligned scroll-down-info" (click)="scrollToNextSection()">
                <i class="keyboard arrow down massive icon color-white margin-0"></i>
            </div>
        </div>
    </div>

    <div #landingpageHighlights class="landingpage-section landingpage-section-highlights"
         [class.with-sections-after]="((tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0) || tenantLandingpage?.show_widgets)"
         *ngIf="tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length > 0">
        <div class="landingpage-section-content landingpage-highlights">
            <h2 class="highlights-title center aligned color-white margin-0" *ngIf="tenantLandingpage?.highlights_title">
                {{tenantLandingpage.highlights_title | dbtranslation:'highlights_title':tenantLandingpage.translations}}
            </h2>
            <h3 class="highlights-title center aligned color-white margin-t-0 margin-b-15" *ngIf="tenantLandingpage?.highlights_subtitle">
                {{tenantLandingpage.highlights_subtitle | dbtranslation:'highlights_subtitle':tenantLandingpage.translations}}
            </h3>
            <div class="ui raised segment highlight-segment"
                 [class.animate]="this.highlightsAnimate"
                 [class.fromthird]="iHighlight > 1"
                 *ngFor="let highlight of tenantLandingpage.highlights; let iHighlight = index">
                <div class="ui middle aligned grid">
                    <div class="thirteen wide mobile fourteen wide tablet column fourteen wide computer column highlight-title">
                        {{highlight.title | dbtranslation:'title':highlight.translations}}
                    </div>
                    <div class="three wide mobile two wide tablet column two wide computer column center aligned">
                        <i *ngIf="highlight.icon_class && !highlight.icon_media" [ngClass]="(highlight.icon_class | dbtranslation:'icon_class':highlight.translations) + ' highlight-icon margin-0'"></i>
                        <img class="highlight-icon-image margin-0" *ngIf="highlight.icon_media" [alt]="" [src]="highlight.icon_media.media_url"/>
                    </div>
                </div>
            </div>

            <img class="landingpage-image highlight-image" [alt]="" [src]="'./assets/img/landingpage/esoccerstar/highlights.png'" />
        </div>
    </div>

    <div #landingpageFeatures class="landingpage-section landingpage-section-features"
         *ngIf="tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0">
        <div class="landingpage-features-content-wrap">
            <div class="landingpage-section-content landingpage-features">
                <swiper class="features-swiper" [config]="featuresSwiperConfig">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let feature of tenantLandingpage?.features">
                            <div class="ui mobile reversed stackable bottom aligned grid">
                                <div class="ten wide column">
                                    <h2 class="feature-title mobile hidden">
                                        {{feature.title | dbtranslation:'title':feature.translations | uppercase}}
                                    </h2>
                                    <div class="feature-description" [innerHtml]="feature.description | dbtranslation:'description':feature.translations"></div>
                                </div>
                                <div class="six wide column center aligned">
                                    <div class="ui image feature-image" *ngIf="feature.media">
                                        <img [alt]="" [src]="getFeatureMediaTranslationForLanguage(feature).media_url" />
                                    </div>
                                </div>
                                <div class="sixteen wide column mobile only">
                                    <h2 class="feature-title">
                                        {{feature.title | dbtranslation:'title':feature.translations | uppercase}}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-bottom">
                        <button type="button"
                                class="ui basic icon button features-swiper-navigation-button features-swiper-navigation-button-prev padding-0 margin-0">
                            <i class="navigate before huge icon"></i>
                        </button>
                        <div class="features-swiper-pagination center aligned"></div>
                        <button type="button"
                                class="ui basic icon button features-swiper-navigation-button features-swiper-navigation-button-next padding-0 margin-0">
                            <i class="navigate next huge icon"></i>
                        </button>
                    </div>
                </swiper>
            </div>
        </div>
        <img class="landingpage-image esoccerstar-features-image" [alt]="" [src]="'./assets/img/landingpage/esoccerstar/features.png'" />
    </div>

    <div #landingpageWidgets class="landingpage-section landingpage-section-widgets"
         [class.with-sections-before]="((tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length) || (tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0))"
         *ngIf="tenantLandingpage?.show_widgets">
        <div class="landingpage-widgets-header">
            <div class="landingpage-widgets-header-inner" [class.no-widget-content]="gameList?.results?.length < 1">
                <h2 class="widgets-title" *ngIf="tenantLandingpage.widgets_title">
                    {{tenantLandingpage.widgets_title | dbtranslation:'widgets_title':tenantLandingpage?.translations}}
                </h2>
                <p class="widgets-description" *ngIf="tenantLandingpage.widgets_description">
                    {{tenantLandingpage.widgets_description | dbtranslation:'widgets_description':tenantLandingpage?.translations}}
                </p>
            </div>
        </div>
        <div class="landingpage-section-content landingpage-widgets" *ngIf="gameList?.results?.length > 0">
            <swiper class="widgets-swiper" #widgetsSwiper [config]="widgetsSwiperConfig">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let game of gameList?.results">
                        <betsnaps-game-card-loader
                            [game]="game"
                            [isLandingpageWidget]="true"
                            class="game-card-wrap margin-0">
                        </betsnaps-game-card-loader>
                    </div>
                </div>
                <div class="swiper-bottom">
                    <button type="button"
                            class="ui basic icon button widgets-swiper-navigation-button widgets-swiper-navigation-button-prev padding-0 margin-0">
                        <i class="navigate before huge icon"></i>
                    </button>
                    <div class="widgets-swiper-pagination center aligned"></div>
                    <button type="button"
                            class="ui basic icon button widgets-swiper-navigation-button widgets-swiper-navigation-button-next padding-0 margin-0">
                        <i class="navigate next huge icon"></i>
                    </button>
                </div>
            </swiper>
        </div>
    </div>

    <div #landingpageFooter class="landingpage-section landingpage-section-footer">
        <div class="landingpage-section-content landingpage-footer center aligned">
            <div class="footer-buttons"
                *ngIf="((tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length) || (tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0) || tenantLandingpage?.show_widgets)">
                <div class="ui one column centered grid">
                    <div class="row">
                        <div class="twelve wide mobile six wide tablet five wide computer column">
                            <button type="button" routerLink="/arena" class="ui fluid large basic button margin-0 padding-r-0 padding-l-0">
                                {{'HOME.arena_btn' | translate | uppercase}}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <betsnaps-landingpage-login-register-buttons [showRegisterButton]="false" remove-wrapping>
                        </betsnaps-landingpage-login-register-buttons>
                    </div>
                </div>

                <img class="landingpage-image esoccerstar-footer-buttons-image" [alt]="" [src]="'./assets/img/landingpage/esoccerstar/footer.png'" />
            </div>

            <p class="share-title margin-b-0">
                {{'GENERAL.GAMES.share' | translate}}
            </p>

            <div *ngIf="isWrapperWindow" class="margin-t-15">
                <button type="button"
                        class="ui circular calltoaction2 icon shadowed button"
                        (click)="callWrapperShare(); googleAnalyticsService.trackEvent('home', 'share', 'wrapper app')">
                    <i class="share alternat icon"></i>
                </button>
            </div>

            <div *ngIf="!isWrapperWindow" class="margin-t-15">
                <button type="button" class="ui circular facebook icon shadowed button" shareButton="facebook"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'facebook')">
                    <i class="fa fa-facebook icon"></i>
                </button>
                <button type="button" class="ui circular twitter icon shadowed button" shareButton="twitter"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'twitter')">
                    <i class="fa fa-twitter icon"></i>
                </button>
                <button type="button" class="ui circular whatsapp icon shadowed button" shareButton="whatsapp"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'whatsapp')"
                        *ngIf="mobileDetect.device_detection() == 'desktop'">
                    <i class="fa fa-whatsapp icon"></i>
                </button>
                <a [href]="'whatsapp://send?text=' + shareUrl | safeUrl" class="ui circular whatsapp icon shadowed button"
                   (click)="googleAnalyticsService.trackEvent('home', 'share', 'whatsapp')"
                   *ngIf="mobileDetect.device_detection() == 'mobile' || mobileDetect.device_detection() == 'tablet'">
                    <i class="fa fa-whatsapp icon"></i>
                </a>
                <button type="button" class="ui circular black icon shadowed button margin-0"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'copied to clipboard')"
                        ngxClipboard
                        [cbContent]="shareUrl"
                        (cbOnSuccess)="showCopyInformation()">
                    <i class="fa fa-link icon"></i>
                </button>
            </div>

            <p *ngIf="showcopyinfo" class="color-white margin-t-5">
                <i class="icon check color-positive"></i> {{'GENERAL.LABELS.NOTIFICATIONS.link_copied' | translate}}
            </p>

        </div>
    </div>
</div>
