/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesWidgetsWidgetPlayerPublicHttpResponse } from './appHttpResponsesWidgetsWidgetPlayerPublicHttpResponse';


/**
 * WidgetTenantRankingPositionHttpResponse
 */
export interface AppHttpResponsesWidgetsWidgetTenantRankingPositionHttpResponse { 
    /**
     * Ranking Position Id
     */
    id: number;
    /**
     * Rank
     */
    rank?: number;
    /**
     * Points
     */
    points?: number;
    /**
     * Prize Amount
     */
    prize_amount: number;
    /**
     * Tenant Prize Template Title (Translated)
     */
    tenant_prize_template_title?: string;
    /**
     * Tenant Prize Title (Translated)
     */
    tenant_prize_title?: string;
    user?: AppHttpResponsesWidgetsWidgetPlayerPublicHttpResponse;
}

