import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'betsnaps-countdown',
    templateUrl: './countdown.component.html',
    styles: []
})
export class CountdownComponent implements OnInit, OnChanges, OnDestroy {

    @Input() timeInSeconds: number;
    @Input() autoStart = false;
    @Input() secondsForHighlight = 60;
    @Input() hideAfterFinish = false;
    @Input() showIcon = true;
    @Input() elementClass = 'countdown';

    @Output() onCountdownFinished: EventEmitter<any> = new EventEmitter();

    public countdown;

    private countdownTimeout;

    constructor() {
    }

    ngOnInit() {
        this.initTimer();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.timeInSeconds && changes.timeInSeconds.currentValue !== changes.timeInSeconds.previousValue) {
            if (this.countdownTimeout) {
                clearTimeout(this.countdownTimeout);
                this.initTimer();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.countdownTimeout) {
            clearTimeout(this.countdownTimeout);
        }
    }

    hasFinished() {
        return this.countdown.hasFinished;
    }

    initTimer() {
        if (!this.timeInSeconds) {
            this.timeInSeconds = 0;
        }

        this.countdown = {
            seconds: this.timeInSeconds,
            runTimer: false,
            hasStarted: false,
            hasFinished: false,
            secondsRemaining: this.timeInSeconds,
            secondsForHighlight: this.secondsForHighlight,
            isHighlighted: (this.timeInSeconds <= this.secondsForHighlight)
        };

        this.countdown.displayTime = this.getSecondsAsDigitalClock(this.countdown.secondsRemaining);

        if (this.autoStart) {
            this.startTimer();
        }
    }

    startTimer() {
        this.countdown.hasStarted = true;
        this.countdown.runTimer = true;
        this.countdownTick();
    }

    pauseTimer() {
        this.countdown.runTimer = false;
    }

    resumeTimer() {
        this.startTimer();
    }

    countdownTick() {
        this.countdownTimeout = setTimeout(() => {
            if (!this.countdown.runTimer) {
                return;
            }
            this.countdown.secondsRemaining--;
            this.countdown.displayTime = this.getSecondsAsDigitalClock(this.countdown.secondsRemaining);
            if (this.countdown.secondsRemaining <= this.countdown.secondsForHighlight) {
                this.countdown.isHighlighted = true;
            }
            if (this.countdown.secondsRemaining > 0) {
                this.countdownTick();
            } else {
                this.countdown.hasFinished = true;
                this.onCountdownFinished.emit();
            }
        }, 1000);
    }

    getSecondsAsDigitalClock(inputSeconds: number) {
        const sec_num = parseInt(inputSeconds.toString(), 10);
        const hours = Math.floor(sec_num / 3600);
        const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        const seconds = sec_num - (hours * 3600) - (minutes * 60);
        let hoursString = '';
        let minutesString = '';
        let secondsString = '';
        hoursString = (hours < 10) ? '0' + hours : hours.toString();
        minutesString = (minutes < 10) ? '0' + minutes : minutes.toString();
        secondsString = (seconds < 10) ? '0' + seconds : seconds.toString();
        return hoursString + ':' + minutesString + ':' + secondsString;
    }

}
