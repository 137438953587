import {Component, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    GoogleAnalyticsService,
    HintService,
    MyTranslateService
} from '../../../../../shared';
import {ABetsnapdetailMatchesComponent} from '../a-betsnapdetail-matches-component';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../../api';
import {takeWhile} from 'rxjs/operators';
import {MatchCardDefaultComponent} from '../../../../components/match-card/match-card-default/match-card-default.component';

@Component({
    selector: 'betsnaps-betsnapdetail-matches-default',
    templateUrl: './betsnapdetail-matches-default.component.html',
    styles: []
})
export class BetsnapdetailMatchesDefaultComponent extends ABetsnapdetailMatchesComponent {

    public filteredGameMatches: GameMatchHttpResponse[];

    public matchesMenu = [
        {
            'key': 'all',
            'translatekey': 'BETSNAPDETAIL.MATCHES.MENU.all',
            'count': 0,
            'active': true
        }, {
            'key': 'upcoming',
            'translatekey': 'BETSNAPDETAIL.MATCHES.MENU.upcoming',
            'count': 0,
            'active': false
        }, {
            'key': 'live',
            'translatekey': 'BETSNAPDETAIL.MATCHES.MENU.live',
            'count': 0,
            'active': false
        }, {
            'key': 'completed',
            'translatekey': 'BETSNAPDETAIL.MATCHES.MENU.completed',
            'count': 0,
            'active': false
        }
    ];
    protected currentMenuKey: string = 'all';

    @ViewChildren(MatchCardDefaultComponent) matchCardComponents: QueryList<MatchCardDefaultComponent>;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected hintService: HintService,
                protected activatedRoute: ActivatedRoute,
                public translations: MyTranslateService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected router: Router,
                protected adService: AdService) {
        super(
            betsnapdetailService,
            authenticationService,
            hintService,
            activatedRoute,
            translations,
            googleAnalyticsService,
            router,
            adService
        );

        this.betsnapdetailService.gameMatches$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameMatchListResults: GameMatchHttpResponse[]) => {
                    this.gameMatches = gameMatchListResults;
                    if (gameMatchListResults) {
                        this.setMenuCounts();
                        this.filterMatches(this.currentMenuKey);
                        this.processMatches = false;
                    } else {
                        this.processMatches = true;
                        this.filteredGameMatches = null;
                        for (const menuitem of this.matchesMenu) {
                            menuitem.count = 0;
                        }
                    }
                }
            );
    }

    public activeGameMatches() {
        return this.filteredGameMatches.filter((gameMatch: GameMatchHttpResponse) =>
            gameMatch.match.status !== 'closed' && gameMatch.match.status !== 'ended' &&
            gameMatch.match.status !== 'cancelled' && gameMatch.match.status !== 'postponed' &&
            !gameMatch.is_cancel);
    }

    public closedGameMatches() {
        return this.filteredGameMatches.filter((gameMatch: GameMatchHttpResponse) =>
            (gameMatch.match.status === 'closed' || gameMatch.match.status === 'ended' ||
                gameMatch.match.status === 'cancelled' || gameMatch.match.status === 'postponed' ||
                gameMatch.is_cancel));
    }

    public matchesMenuChange(activatekey: string) {
        this.processMatches = true;
        for (const menuitem of this.matchesMenu) {
            menuitem.active = (menuitem.key === activatekey);
        }
        this.currentMenuKey = activatekey;
        this.filterMatches(activatekey);
        this.processMatches = false;

        this.googleAnalyticsService.trackEvent('game - matches', 'filter', 'matches - ' + activatekey);
    }

    private setMenuCounts() {
        for (const menuitem of this.matchesMenu) {
            if (menuitem.key  === 'all') {
                menuitem.count = this.gameMatches.length;
            } else if (menuitem.key  === 'upcoming') {
                menuitem.count = this.upComingMatches().length;
            } else if (menuitem.key  === 'live') {
                menuitem.count = this.liveMatches().length;
            } else if (menuitem.key  === 'completed') {
                menuitem.count = this.completedMatches().length;
            }
        }
    }

    private filterMatches(key) {
        if (key === 'all') {
            this.filteredGameMatches = this.gameMatches;
        } else if (key === 'upcoming') {
            this.filteredGameMatches = this.upComingMatches();
        } else if (key === 'live') {
            this.filteredGameMatches = this.liveMatches();
        } else if (key === 'completed') {
            this.filteredGameMatches = this.completedMatches();
        }
    }

    closeOpenPlaceBetDialogs() {
        const matchCards: MatchCardDefaultComponent[] = this.matchCardComponents.toArray();
        matchCards.map((matchCard) => {
            matchCard.closeOpenPlaceBetDialogs();
            matchCard.closePlaceBetDialog();
        });
    }

    protected handleUserData(user: PlayerHttpResponse, game: GameHttpResponse) {
        if (!this.currentUser && user && game) {
            if (game.is_current_user_joined && game.game_state < 4) {
                // get user bets for current user
                this.betsnapdetailService.getUserBets(
                    game.game_unique_id,
                    user.id
                );
            }

            const hintConditionValues = {
                bet_placement_option: game.bet_placement_option,
                cashout_option: game.cashout_option,
                game_state: game.game_state,
                odd_display_format: user.settings.odd_display_format
            };
            this.hintService.checkForHintToDisplay('betsnapdetail-matches', hintConditionValues);
        }
        this.currentUser = user;
    }

}
