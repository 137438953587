import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {UsersApi} from '../../../api';
import {
    AuthenticationService,
    ErrorService,
    MobiledetectService,
    MyNotificationsService,
    TenantService
} from '../../../shared';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {take, takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-delete-account',
    templateUrl: './delete-account.component.html',
    styles: []
})
export class DeleteAccountComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentClass = 'standardview';

    private componentAlive = true;

    public showSpinner: boolean = true;
    public processDeletion: boolean = false;

    private deleteToken: string;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private usersApi: UsersApi,
                private mobileDetect: MobiledetectService,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService,
                private errorService: ErrorService) {
        this.activatedRoute.params
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((params: Params) => {
                this.deleteToken = params['delete_token'];
                this.showSpinner = false;
            });
    }

    ngOnInit() {
        if (this.tenantService.noB2cSignupMethod) {
            this.router.navigate(['/profile/overview']);
            return;
        }
    }

    deleteAccount(): void {
        let apiMethodName = 'apiUsersDeleteTokenDelete';

        if (!this.processDeletion) {
            this.processDeletion = true;

            if (this.mobileDetect.browserName() === 'operamini') {
                apiMethodName = 'apiOperaminideleteUsersDeleteTokenPost';
            }

            this.usersApi[apiMethodName](
                this.deleteToken
            ).pipe(take(1)).subscribe(
                () => {
                    this.processDeletion = false;
                    this.translateService.get(['DELETEACCOUNT.success_title', 'DELETEACCOUNT.success_txt'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['DELETEACCOUNT.success_title'], translation['DELETEACCOUNT.success_txt'], NotificationType.Success);
                        });
                    this.authenticationService.localLogout();
                },
                (err: HttpErrorResponse) => {
                    this.processDeletion = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            );
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

}
