import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef, ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {DynamicComponentLoaderService} from '../../../../shared/services/dynamic-component-loader.service';
import {DynamicComponentHostDirective} from '../../../../shared/directives/dynamic-component-host.directive';
import {ASidebarComponent} from '../a-sidebar-component';

@Component({
    selector: 'betsnaps-left-sidebar-loader',
    template: `
        <ng-template dynamicComponentHost></ng-template>
    `
})
export class LeftSidebarLoaderComponent implements OnInit, OnDestroy {

    private componentRef: ComponentRef<ASidebarComponent>;
    @ViewChild(DynamicComponentHostDirective, {static: true}) dynamicComponentHost: DynamicComponentHostDirective;

    constructor(private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private dynamicComponentLoaderService: DynamicComponentLoaderService,
                private hostElement: ElementRef) {
    }

    ngOnInit() {

        const componentToLoad = this.dynamicComponentLoaderService.getComponent('left-sidebar');

        if (componentToLoad) {
            const componentFactory: ComponentFactory<ASidebarComponent> = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);

            const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent<ASidebarComponent>(componentFactory);

            this.componentRef.instance.hostElement = this.hostElement;
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

}
