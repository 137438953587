/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * NotificationHttpResponse
 */
export interface AppHttpResponsesNotificationsNotificationHttpResponse { 
    /**
     * Notification ID
     */
    id: number;
    /**
     * Unique Notification Code
     */
    code: string;
    /**
     * Notification Description
     */
    description: string;
    /**
     * Notification Title
     */
    title: string;
    /**
     * Notification Message
     */
    message?: string;
    /**
     * Notification Short Message
     */
    short_message?: string;
    /**
     * Should Notification be shown in News-Section
     */
    is_shown_in_news: boolean;
    /**
     * Should Notification be shown in Friends-Section
     */
    is_shown_in_friends: boolean;
    /**
     * Should Notification be shown as On-Screen Message
     */
    is_screen_popup: boolean;
    /**
     * Should Notification be sent as an EMail
     */
    send_email: boolean;
    /**
     * Should Notification be sent as a Push-Notification
     */
    send_push: boolean;
    /**
     * Should Notification be sent as a SMS-Notification
     */
    send_sms: boolean;
    /**
     * Translation
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

