import {Component} from '@angular/core';
import {
    AuthenticationService,
    ErrorService,
    GoogleAnalyticsService,
    MyTranslateService,
    RankingService,
    TenantService,
    WindowRef,
} from '../../../../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {ARankingComponent} from '../a-ranking.component';

@Component({
    selector: 'betsnaps-ranking-default',
    templateUrl: './ranking-default.component.html',
    styles: []
})
export class RankingDefaultComponent extends ARankingComponent {

    constructor(protected authenticationService: AuthenticationService,
                public  tenantService: TenantService,
                protected rankingService: RankingService,
                protected activatedRoute: ActivatedRoute,
                protected router: Router,
                public translations: MyTranslateService,
                protected translateService: TranslateService,
                protected windowRef: WindowRef,
                protected errorService: ErrorService,
                protected location: Location,
                protected googleAnalyticsService: GoogleAnalyticsService) {
        super(authenticationService,
            tenantService,
            rankingService,
            activatedRoute,
            router,
            translations,
            translateService,
            windowRef,
            errorService,
            location,
            googleAnalyticsService);
    }
}
