/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantExternalScriptCreateRequest
 */
export interface AppHttpRequestsTenantsTenantExternalScriptCreateRequest { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Title
     */
    title: string;
    /**
     * Head script source
     */
    head_script_src?: string;
    /**
     * Body top embed code
     */
    body_top_embed_code?: string;
    /**
     * Body bottom embed code
     */
    body_bottom_embed_code?: string;
    /**
     * After Login embed code
     */
    after_login_embed_code?: string;
}

