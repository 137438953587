<button type="button"
        [ngClass]="'ui lightgrey2 fluid button outcome-button outcome-' + outcome.outcome_id"
        [class.basic]="!isUserBetAvailable"
        [class.disabled]="isDisabled || isUserBetAvailable || forceDisabled"
        [disabled]="isDisabled || isUserBetAvailable || forceDisabled"
        [class.selected]="isSelected"
        (click)="openPlaceBetDialog()">
    <span class="outcome-name"
          [class.padding-5]="game.points_engine === gamePointsEngineEnum.PREDICTION"
          [class.tiny]="game.points_engine !== gamePointsEngineEnum.PREDICTION">
        {{outcome.outcome_name | dbtranslation:'name':outcome.translations}}
    </span>
    <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">
        <span class="outcome-value" *ngIf="oddDisplayFormat === 'odds'">
            {{outcome.odd_decimal | number:'1.2-2'}}
        </span>
        <span class="outcome-value" *ngIf="oddDisplayFormat === 'value'">
            +{{outcome.odd_points}}
        </span>
        <span class="outcome-value" *ngIf="oddDisplayFormat === 'fractional'">
            {{outcome.odd_fractional}}
        </span>
        <span class="outcome-value" *ngIf="oddDisplayFormat === 'moneyline'">
            {{outcome.odd_moneyline}}
        </span>
    </span>
    <span class="outcome-change-arrow"
          *ngIf="outcomeChange !== 0"
          [ngClass]="{
            'flashit-animation' : showAnimation,
            'outcome-change-positive color-positive' : outcomeChange === 1,
            'outcome-change-negative color-negative' : outcomeChange === -1
          }">
    </span>
</button>

<ng-template let-context let-modal="modal" #modalJoinTemplate>
    <div class="content inverted primary">
        <div class="ui centered grid padded display-block">
            <div class="three column row middle aligned">
                <div class="four wide column middle aligned"></div>
                <div class="eight wide column middle aligned padding-20"><img class="ui middle aligned image" [src]="loginModalImage"/></div>
                <div class="four wide column middle aligned"></div>
            </div>
            <div class="font-bold huge margin-b-0">{{'GENERAL.BETS.ERRORS.bet_not_possible_title' | translate}}</div>
            <div class="font-bold large">{{'GENERAL.BETS.ERRORS.join_snap_first' | translate}}</div>
            <button type="button" class="ui primary button large margin-20 padding-l-30 padding-r-30"
                    (click)="linkRedirect('../details'); modal.approve();">
                <span class="nowrap">{{'GENERAL.GAMES.snap_details_btn' | translate}}</span>
            </button>
        </div>
    </div>
</ng-template>
