<div class="ui fluid card" *ngIf="currentUser" [class.mobile-stretch-bottom-card]="!processList">
    <div class="content">
        <div class="ui vertical borderless very padded segment" *ngIf="processList">
            <div class="ui active centered inline text loader">
                {{'GENERAL.loadertext' | translate}}
            </div>
        </div>
        <div class="content padding-t-10" *ngIf="!processList && tenantService.tenantData?.configuration?.show_balance">
            <div class="ui grid bottom aligned">
                <div class="seven wide column truncate">
                    <strong class="color-light-grey">{{'PROFILE.OVERVIEW.current_balance' | translate}}</strong>
                </div>
                <div class="nine wide column right aligned font-very-bold text-primary text-big">
                        <span class="currency-symbol" [class.longsymbol]="currentUser.wallets[0].currency.symbol.length > 1">
                            {{currentUser.wallets[0].currency.symbol}}
                        </span>{{currentUser.wallets[0].balance | number:'1.2-2' }}
                </div>
            </div>
            <div class="ui divider margin-t-10"></div>
        </div>
        <div class="ui fluid divided very relaxed list" *ngIf="(!processList && transactionsList?.total > 0)">
            <div *ngFor="let transaction of transactionsList?.results" class="item">
                <div class="content" (click)="navigateToTarget(transaction)" [class.element-clickable]="(transaction.game || transaction.ranking)">
                    <div class="ui grid">
                        <div class="eight wide mobile eight wide tablet six wide computer column">
                            {{transaction.created_at | amLocal | amCalendar:
                            {
                                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                lastDay: tenantService.tenantData.internationalization.date_time_format,
                                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                sameElse: tenantService.tenantData.internationalization.date_time_format
                            }
                            }}
                        </div>
                        <div
                            class="eight wide mobile eight wide tablet ten wide computer column truncate right aligned">
                            <span *ngIf="transaction.game">
                                <a (click)="navigateToTarget(transaction)">
                                    <betsnaps-game-name [game]="transaction.game"></betsnaps-game-name>
                                </a>
                            </span>
                            <span *ngIf="transaction.ranking">
                                <a (click)="navigateToTarget(transaction)">
                                    {{transaction.ranking.description | dbtranslation:'description':transaction.ranking.translations}}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="content margin-t-5 margin-b-5">
                        <div class="right aligned">
                            <p class="margin-0 text-massive font-very-bold"
                               [ngClass]="{'color-positive': transaction.payment_type === 2, 'color-negative': transaction.payment_type === 1}"
                               *ngIf="transaction.transaction_value">
                                {{transaction.transaction_value | dbtranslation:'transaction_value':transaction.translations}}
                                <span *ngIf="transaction.transaction_is_bonus_money === true">
                                    {{'GENERAL.LABELS.bet_bonus' | translate}}
                                </span>
                            </p>
                            <p class="margin-0 text-massive font-very-bold"
                               [ngClass]="{'color-positive': transaction.payment_type === 2, 'color-negative': transaction.payment_type === 1}"
                               *ngIf="transaction.transaction_value2">
                                {{transaction.transaction_value2 | dbtranslation:'transaction_value2':transaction.translations}}
                            </p>
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="six wide mobile six wide tablet four wide computer column">
                            <strong class="color-light-grey">ID {{transaction.id}}</strong>
                        </div>
                        <div class="ten wide mobile ten wide tablet twelve wide computer column right aligned truncate">
                            {{'PROFILE.WALLET.TRANSACTIONS.' + transaction.transaction_key | translate}}
                            <span *ngIf="transaction.reward_code">
                                &nbsp;({{'PROFILE.WALLET.TRANSACTIONS.REWARDS.' + transaction.reward_code | translate}})
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui vertical borderless center aligned segment" *ngIf="!processList && transactionsList?.total < 1">
            <p class="text-on-body-bg">
                {{'GENERAL.no_results_found' | translate}}
            </p>
        </div>
        <div class="ui basic segment center aligned"
             *ngIf="(!processList && transactionsList?.results.length < transactionsList?.total)"
             #loadMoreButton>
            <button type="button" class="ui button margin-0" [class.loading]="processLoadMore" (click)="loadMore()">
                {{'GENERAL.load-more_btn' | translate}}
            </button>
        </div>
    </div>
</div>




