/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpRequestsAuthOauthAccessTokenCreateRequest } from '../model/appHttpRequestsAuthOauthAccessTokenCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesFlagGameRequest } from '../model/appHttpRequestsBetsnapsGamesFlagGameRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesGameAllowedUserAddRemoveRequest } from '../model/appHttpRequestsBetsnapsGamesGameAllowedUserAddRemoveRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesGameAllowedUserCheckRequest } from '../model/appHttpRequestsBetsnapsGamesGameAllowedUserCheckRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest } from '../model/appHttpRequestsBetsnapsGamesGameCreateGameAdminRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesGameTagCreateRequest } from '../model/appHttpRequestsBetsnapsGamesGameTagCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest } from '../model/appHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeImportTenantPoolingPrizesRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeImportTenantPoolingPrizesRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateCreateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateOrderRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateOrderRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankChangeRankOrderRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankChangeRankOrderRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankCreateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankUpdateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateTranslationUpdateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateTranslationUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateUpdateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateCreateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateTranslationUpdateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateTranslationUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateUpdateRequest } from '../model/appHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsCMSsCMSCreateRequest } from '../model/appHttpRequestsCMSsCMSCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsCMSsCMSMediaTranslationsUpdateRequest } from '../model/appHttpRequestsCMSsCMSMediaTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsCMSsCMSTranslationUpdateRequest } from '../model/appHttpRequestsCMSsCMSTranslationUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsCMSsCMSUpdateRequest } from '../model/appHttpRequestsCMSsCMSUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsGeneralMediaTranslationsUpdateRequest } from '../model/appHttpRequestsGeneralMediaTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsMediaMediaUploadRequest } from '../model/appHttpRequestsMediaMediaUploadRequest';
// eslint-disable-next-line
import { AppHttpRequestsSportsDataMarketTranslationsUpdateRequest } from '../model/appHttpRequestsSportsDataMarketTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsSportsDataMatchCancelRequest } from '../model/appHttpRequestsSportsDataMatchCancelRequest';
// eslint-disable-next-line
import { AppHttpRequestsSportsDataMatchMarketSettlementRequest } from '../model/appHttpRequestsSportsDataMatchMarketSettlementRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantAdvertisementCreateRequest } from '../model/appHttpRequestsTenantsTenantAdvertisementCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantAdvertisementTranslationsUpdateRequest } from '../model/appHttpRequestsTenantsTenantAdvertisementTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantAdvertisementUpdateRequest } from '../model/appHttpRequestsTenantsTenantAdvertisementUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantB2bConnectionUpdateRequest } from '../model/appHttpRequestsTenantsTenantB2bConnectionUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantBasicSettingsUpdateRequest } from '../model/appHttpRequestsTenantsTenantBasicSettingsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantCMSCreateRequest } from '../model/appHttpRequestsTenantsTenantCMSCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantCMSMediaTranslationUpdateRequest } from '../model/appHttpRequestsTenantsTenantCMSMediaTranslationUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantCMSTranslationUpdateRequest } from '../model/appHttpRequestsTenantsTenantCMSTranslationUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantCMSUpdateRequest } from '../model/appHttpRequestsTenantsTenantCMSUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantChatSettingsUpdateRequest } from '../model/appHttpRequestsTenantsTenantChatSettingsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantCreateRequest } from '../model/appHttpRequestsTenantsTenantCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantExternalScriptCreateRequest } from '../model/appHttpRequestsTenantsTenantExternalScriptCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantExternalScriptUpdateRequest } from '../model/appHttpRequestsTenantsTenantExternalScriptUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantFinancialsUpdateRequest } from '../model/appHttpRequestsTenantsTenantFinancialsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantFooterChangeOrderRequest } from '../model/appHttpRequestsTenantsTenantFooterChangeOrderRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantFooterCreateRequest } from '../model/appHttpRequestsTenantsTenantFooterCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantFooterTranslationsUpdateRequest } from '../model/appHttpRequestsTenantsTenantFooterTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantFooterUpdateRequest } from '../model/appHttpRequestsTenantsTenantFooterUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantGameBehaviourUpdateRequest } from '../model/appHttpRequestsTenantsTenantGameBehaviourUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantGameFeaturesUpdateRequest } from '../model/appHttpRequestsTenantsTenantGameFeaturesUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantGuiInternationalizationUpdateRequest } from '../model/appHttpRequestsTenantsTenantGuiInternationalizationUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantHintChangeOrderRequest } from '../model/appHttpRequestsTenantsTenantHintChangeOrderRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantHintCreateRequest } from '../model/appHttpRequestsTenantsTenantHintCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantHintTranslationsUpdateRequest } from '../model/appHttpRequestsTenantsTenantHintTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantHintUpdateRequest } from '../model/appHttpRequestsTenantsTenantHintUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantImageCreateRequest } from '../model/appHttpRequestsTenantsTenantImageCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantImageUpdateRequest } from '../model/appHttpRequestsTenantsTenantImageUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageCreateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageFeatureChangeOrderRequest } from '../model/appHttpRequestsTenantsTenantLandingpageFeatureChangeOrderRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageFeatureCreateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageFeatureCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageFeatureTranslationsUpdateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageFeatureTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageFeatureUpdateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageFeatureUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageHighlightChangeOrderRequest } from '../model/appHttpRequestsTenantsTenantLandingpageHighlightChangeOrderRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageHighlightCreateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageHighlightCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageHighlightTranslationsUpdateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageHighlightTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageHighlightUpdateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageHighlightUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageTranslationsUpdateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageTranslationsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantLandingpageUpdateRequest } from '../model/appHttpRequestsTenantsTenantLandingpageUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantMarketCreateRequest } from '../model/appHttpRequestsTenantsTenantMarketCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantMediaAddRequest } from '../model/appHttpRequestsTenantsTenantMediaAddRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantNotificationSettingsUpdateRequest } from '../model/appHttpRequestsTenantsTenantNotificationSettingsUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantPlayerLanguageCreateRequest } from '../model/appHttpRequestsTenantsTenantPlayerLanguageCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantRankingCreateRequest } from '../model/appHttpRequestsTenantsTenantRankingCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantRankingUpdateRequest } from '../model/appHttpRequestsTenantsTenantRankingUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantSeoUpdateRequest } from '../model/appHttpRequestsTenantsTenantSeoUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantSocialNetworkUpdateRequest } from '../model/appHttpRequestsTenantsTenantSocialNetworkUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantSportCreateRequest } from '../model/appHttpRequestsTenantsTenantSportCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantSportUpdateRequest } from '../model/appHttpRequestsTenantsTenantSportUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantTournamentCreateRequest } from '../model/appHttpRequestsTenantsTenantTournamentCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantTournamentUpdateRequest } from '../model/appHttpRequestsTenantsTenantTournamentUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminAddTenantRequest } from '../model/appHttpRequestsUsersAdminAddTenantRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminChangeEmailRequest } from '../model/appHttpRequestsUsersAdminChangeEmailRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminCreateRequest } from '../model/appHttpRequestsUsersAdminCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminInitializeEmailChangeRequest } from '../model/appHttpRequestsUsersAdminInitializeEmailChangeRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminInitializePasswordResetRequest } from '../model/appHttpRequestsUsersAdminInitializePasswordResetRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminPasswordResetRequest } from '../model/appHttpRequestsUsersAdminPasswordResetRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminUpdateRequest } from '../model/appHttpRequestsUsersAdminUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersAdminVerifyRequest } from '../model/appHttpRequestsUsersAdminVerifyRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersApiConsumerCreateRequest } from '../model/appHttpRequestsUsersApiConsumerCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersApiConsumerUpdateRequest } from '../model/appHttpRequestsUsersApiConsumerUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserAddPermissionRequest } from '../model/appHttpRequestsUsersUserAddPermissionRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserAddRoleRequest } from '../model/appHttpRequestsUsersUserAddRoleRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserChangePasswordRequest } from '../model/appHttpRequestsUsersUserChangePasswordRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserPermissionCreateRequest } from '../model/appHttpRequestsUsersUserPermissionCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserRoleAddPermissionsRequest } from '../model/appHttpRequestsUsersUserRoleAddPermissionsRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserRoleCreateRequest } from '../model/appHttpRequestsUsersUserRoleCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsUsersUserTagCreateRequest } from '../model/appHttpRequestsUsersUserTagCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsVendorGamesTenantVendorGameCreateRequest } from '../model/appHttpRequestsVendorGamesTenantVendorGameCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsVendorGamesTenantVendorGameUpdateRequest } from '../model/appHttpRequestsVendorGamesTenantVendorGameUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsVendorGamesVendorCreateRequest } from '../model/appHttpRequestsVendorGamesVendorCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsVendorGamesVendorGameCreateRequest } from '../model/appHttpRequestsVendorGamesVendorGameCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsVendorGamesVendorGameUpdateRequest } from '../model/appHttpRequestsVendorGamesVendorGameUpdateRequest';
// eslint-disable-next-line
import { AppHttpRequestsVendorGamesVendorUpdateRequest } from '../model/appHttpRequestsVendorGamesVendorUpdateRequest';
// eslint-disable-next-line
import { AppHttpResponsesAuthClientListHttpResponse } from '../model/appHttpResponsesAuthClientListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesAuthOauthAccessTokenListHttpResponse } from '../model/appHttpResponsesAuthOauthAccessTokenListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesAuthPersonalAccessTokenHttpResponse } from '../model/appHttpResponsesAuthPersonalAccessTokenHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesAdminGameHttpResponse } from '../model/appHttpResponsesBetsnapsGamesAdminGameHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameAllowedUserCheckListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameAllowedUserCheckListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameFlagReasonListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameFlagReasonListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameMarketListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameMarketListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameMatchListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameMatchSimpleMarketListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameMatchSimpleMarketListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameTagHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameTagHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesGameTagListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesGameTagListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsGamesMasterGameFlagReasonListHttpResponse } from '../model/appHttpResponsesBetsnapsGamesMasterGameFlagReasonListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeImportHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresPoolingPrizeImportHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryListHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresPrizeCategoryListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeListHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresPrizeTypeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeListHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresTenantPrizeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankListHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateListHttpResponse } from '../model/appHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsAdminFullTenantRankingHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsAdminFullTenantRankingHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsAdminTenantRankingPositionListHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsAdminTenantRankingPositionListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesCMSsCMSHttpResponse } from '../model/appHttpResponsesCMSsCMSHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesCMSsCMSListHttpResponse } from '../model/appHttpResponsesCMSsCMSListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesChatsChatTimelineListHttpResponse } from '../model/appHttpResponsesChatsChatTimelineListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFinancialsAccountingInvoiceHttpResponse } from '../model/appHttpResponsesFinancialsAccountingInvoiceHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFinancialsAccountingInvoiceListHttpResponse } from '../model/appHttpResponsesFinancialsAccountingInvoiceListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFinancialsAccountingInvoicePositionListHttpResponse } from '../model/appHttpResponsesFinancialsAccountingInvoicePositionListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFinancialsAccountingPaymentHistoryTransactionListHttpResponse } from '../model/appHttpResponsesFinancialsAccountingPaymentHistoryTransactionListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesGeneralGUIThemeHttpResponse } from '../model/appHttpResponsesGeneralGUIThemeHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesGeneralGUIThemeListHttpResponse } from '../model/appHttpResponsesGeneralGUIThemeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesGeneralLanguageListHttpResponse } from '../model/appHttpResponsesGeneralLanguageListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesHintsHintListHttpResponse } from '../model/appHttpResponsesHintsHintListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesMediaMediaDeletedHttpResponse } from '../model/appHttpResponsesMediaMediaDeletedHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesMediaMediaHttpResponse } from '../model/appHttpResponsesMediaMediaHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesMediaMediaListHttpResponse } from '../model/appHttpResponsesMediaMediaListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesMediaMediaTypeListHttpResponse } from '../model/appHttpResponsesMediaMediaTypeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMarketHttpResponse } from '../model/appHttpResponsesSportsDataMarketHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMarketListHttpResponse } from '../model/appHttpResponsesSportsDataMarketListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchListHttpResponse } from '../model/appHttpResponsesSportsDataMatchListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataSportListHttpResponse } from '../model/appHttpResponsesSportsDataSportListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataTournamentListHttpResponse } from '../model/appHttpResponsesSportsDataTournamentListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantAdvertisementHttpResponse } from '../model/appHttpResponsesTenantsTenantAdvertisementHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantAdvertisementListHttpResponse } from '../model/appHttpResponsesTenantsTenantAdvertisementListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantCMSHttpResponse } from '../model/appHttpResponsesTenantsTenantCMSHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantCMSListHttpResponse } from '../model/appHttpResponsesTenantsTenantCMSListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantCloneOptionListHttpResponse } from '../model/appHttpResponsesTenantsTenantCloneOptionListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantExternalScriptHttpResponse } from '../model/appHttpResponsesTenantsTenantExternalScriptHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantFooterHttpResponse } from '../model/appHttpResponsesTenantsTenantFooterHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantFooterListHttpResponse } from '../model/appHttpResponsesTenantsTenantFooterListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantHintHttpResponse } from '../model/appHttpResponsesTenantsTenantHintHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantHintListHttpResponse } from '../model/appHttpResponsesTenantsTenantHintListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantHttpResponse } from '../model/appHttpResponsesTenantsTenantHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantImageHttpResponse } from '../model/appHttpResponsesTenantsTenantImageHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantImageTypeListHttpResponse } from '../model/appHttpResponsesTenantsTenantImageTypeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse } from '../model/appHttpResponsesTenantsTenantLandingpageFeatureHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantLandingpageFeatureListHttpResponse } from '../model/appHttpResponsesTenantsTenantLandingpageFeatureListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse } from '../model/appHttpResponsesTenantsTenantLandingpageHighlightHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantLandingpageHighlightListHttpResponse } from '../model/appHttpResponsesTenantsTenantLandingpageHighlightListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantLandingpageHttpResponse } from '../model/appHttpResponsesTenantsTenantLandingpageHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantLandingpageListHttpResponse } from '../model/appHttpResponsesTenantsTenantLandingpageListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantListHttpResponse } from '../model/appHttpResponsesTenantsTenantListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantSportHttpResponse } from '../model/appHttpResponsesTenantsTenantSportHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantTagListHttpResponse } from '../model/appHttpResponsesTenantsTenantTagListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantTournamentHttpResponse } from '../model/appHttpResponsesTenantsTenantTournamentHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersAdminHttpResponse } from '../model/appHttpResponsesUsersAdminHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersAdminListHttpResponse } from '../model/appHttpResponsesUsersAdminListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersApiConsumerHttpResponse } from '../model/appHttpResponsesUsersApiConsumerHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersApiConsumerListHttpResponse } from '../model/appHttpResponsesUsersApiConsumerListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserPermissionHttpResponse } from '../model/appHttpResponsesUsersUserPermissionHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserPermissionListHttpResponse } from '../model/appHttpResponsesUsersUserPermissionListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserRoleHttpResponse } from '../model/appHttpResponsesUsersUserRoleHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserRoleListHttpResponse } from '../model/appHttpResponsesUsersUserRoleListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserTagHttpResponse } from '../model/appHttpResponsesUsersUserTagHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesUsersUserTagListHttpResponse } from '../model/appHttpResponsesUsersUserTagListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesVendorGamesGameVendorGameListHttpResponse } from '../model/appHttpResponsesVendorGamesGameVendorGameListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesVendorGamesTenantVendorGameHttpResponse } from '../model/appHttpResponsesVendorGamesTenantVendorGameHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesVendorGamesTenantVendorGameListHttpResponse } from '../model/appHttpResponsesVendorGamesTenantVendorGameListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesVendorGamesVendorGameHttpResponse } from '../model/appHttpResponsesVendorGamesVendorGameHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesVendorGamesVendorGameListHttpResponse } from '../model/appHttpResponsesVendorGamesVendorGameListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesVendorGamesVendorHttpResponse } from '../model/appHttpResponsesVendorGamesVendorHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesVendorGamesVendorListHttpResponse } from '../model/appHttpResponsesVendorGamesVendorListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AdminApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Update Game
     * @param game_unique_id Game Unique Id
     * @param app_http_requests_betsnaps_games_game_update_game_admin_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiAdminAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminAdminGamesGameUniqueIdPut.');
        }
        if (app_http_requests_betsnaps_games_game_update_game_admin_request === null || app_http_requests_betsnaps_games_game_update_game_admin_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_update_game_admin_request was null or undefined when calling apiAdminAdminGamesGameUniqueIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admin-games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_update_game_admin_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Game
     * @param app_http_requests_betsnaps_games_game_create_game_admin_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiAdminAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_betsnaps_games_game_create_game_admin_request === null || app_http_requests_betsnaps_games_game_create_game_admin_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_create_game_admin_request was null or undefined when calling apiAdminAdminGamesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admin-games`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_create_game_admin_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Information of current admin user
     * Get User Information of current admin user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdmininfoGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersAdminHttpResponse>;
    public apiAdminAdmininfoGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdmininfoGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdmininfoGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admininfo`;
        return this.httpClient.request<AppHttpResponsesUsersAdminHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Admin Users
     * Get all Admin Users
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsGet(per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersAdminListHttpResponse>;
    public apiAdminAdminsGet(per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersAdminListHttpResponse>>;
    public apiAdminAdminsGet(per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersAdminListHttpResponse>>;
    public apiAdminAdminsGet(per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins`;
        return this.httpClient.request<AppHttpResponsesUsersAdminListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize a admin password reset
     * Initialize a admin password reset
     * @param app_http_requests_users_admin_initialize_password_reset_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsPasswordInitializeresetPut(app_http_requests_users_admin_initialize_password_reset_request: AppHttpRequestsUsersAdminInitializePasswordResetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsPasswordInitializeresetPut(app_http_requests_users_admin_initialize_password_reset_request: AppHttpRequestsUsersAdminInitializePasswordResetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsPasswordInitializeresetPut(app_http_requests_users_admin_initialize_password_reset_request: AppHttpRequestsUsersAdminInitializePasswordResetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsPasswordInitializeresetPut(app_http_requests_users_admin_initialize_password_reset_request: AppHttpRequestsUsersAdminInitializePasswordResetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_admin_initialize_password_reset_request === null || app_http_requests_users_admin_initialize_password_reset_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_initialize_password_reset_request was null or undefined when calling apiAdminAdminsPasswordInitializeresetPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/password/initializereset`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_initialize_password_reset_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset a admin password
     * Reset a admin password
     * @param app_http_requests_users_admin_password_reset_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsPasswordResetPut(app_http_requests_users_admin_password_reset_request: AppHttpRequestsUsersAdminPasswordResetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsPasswordResetPut(app_http_requests_users_admin_password_reset_request: AppHttpRequestsUsersAdminPasswordResetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsPasswordResetPut(app_http_requests_users_admin_password_reset_request: AppHttpRequestsUsersAdminPasswordResetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsPasswordResetPut(app_http_requests_users_admin_password_reset_request: AppHttpRequestsUsersAdminPasswordResetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_admin_password_reset_request === null || app_http_requests_users_admin_password_reset_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_password_reset_request was null or undefined when calling apiAdminAdminsPasswordResetPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/password/reset`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_password_reset_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Admin User
     * Create a new Admin User
     * @param app_http_requests_users_admin_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsPost(app_http_requests_users_admin_create_request: AppHttpRequestsUsersAdminCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsPost(app_http_requests_users_admin_create_request: AppHttpRequestsUsersAdminCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsPost(app_http_requests_users_admin_create_request: AppHttpRequestsUsersAdminCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsPost(app_http_requests_users_admin_create_request: AppHttpRequestsUsersAdminCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_admin_create_request === null || app_http_requests_users_admin_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_create_request was null or undefined when calling apiAdminAdminsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change email with token
     * Change email with token
     * @param user_id User Id
     * @param app_http_requests_users_admin_change_email_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdChangeemailPut(user_id: number, app_http_requests_users_admin_change_email_request: AppHttpRequestsUsersAdminChangeEmailRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersAdminHttpResponse>;
    public apiAdminAdminsUserIdChangeemailPut(user_id: number, app_http_requests_users_admin_change_email_request: AppHttpRequestsUsersAdminChangeEmailRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdminsUserIdChangeemailPut(user_id: number, app_http_requests_users_admin_change_email_request: AppHttpRequestsUsersAdminChangeEmailRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdminsUserIdChangeemailPut(user_id: number, app_http_requests_users_admin_change_email_request: AppHttpRequestsUsersAdminChangeEmailRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdChangeemailPut.');
        }
        if (app_http_requests_users_admin_change_email_request === null || app_http_requests_users_admin_change_email_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_change_email_request was null or undefined when calling apiAdminAdminsUserIdChangeemailPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/changeemail`;
        return this.httpClient.request<AppHttpResponsesUsersAdminHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_change_email_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change an User Password
     * Change user password
     * @param user_id User Id
     * @param app_http_requests_users_user_change_password_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsUserIdChangepasswordPut(user_id: number, app_http_requests_users_user_change_password_request: AppHttpRequestsUsersUserChangePasswordRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdChangepasswordPut.');
        }
        if (app_http_requests_users_user_change_password_request === null || app_http_requests_users_user_change_password_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_change_password_request was null or undefined when calling apiAdminAdminsUserIdChangepasswordPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/changepassword`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_change_password_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an Admin User
     * Delete a Admin User
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdDelete(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsUserIdDelete(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsUserIdDelete(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsUserIdDelete(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get admin user
     * Get admin user
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdGet(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersAdminHttpResponse>;
    public apiAdminAdminsUserIdGet(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdminsUserIdGet(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdminsUserIdGet(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersAdminHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initialize email change
     * Initialize email change
     * @param user_id User Id
     * @param app_http_requests_users_admin_initialize_email_change_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_admin_initialize_email_change_request: AppHttpRequestsUsersAdminInitializeEmailChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_admin_initialize_email_change_request: AppHttpRequestsUsersAdminInitializeEmailChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_admin_initialize_email_change_request: AppHttpRequestsUsersAdminInitializeEmailChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsUserIdInitializeemailchangePut(user_id: number, app_http_requests_users_admin_initialize_email_change_request: AppHttpRequestsUsersAdminInitializeEmailChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdInitializeemailchangePut.');
        }
        if (app_http_requests_users_admin_initialize_email_change_request === null || app_http_requests_users_admin_initialize_email_change_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_initialize_email_change_request was null or undefined when calling apiAdminAdminsUserIdInitializeemailchangePut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/initializeemailchange`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_initialize_email_change_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a new Admin User
     * Update a new Admin User
     * @param user_id User Id
     * @param app_http_requests_users_admin_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdPut(user_id: number, app_http_requests_users_admin_update_request: AppHttpRequestsUsersAdminUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersAdminHttpResponse>;
    public apiAdminAdminsUserIdPut(user_id: number, app_http_requests_users_admin_update_request: AppHttpRequestsUsersAdminUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdminsUserIdPut(user_id: number, app_http_requests_users_admin_update_request: AppHttpRequestsUsersAdminUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersAdminHttpResponse>>;
    public apiAdminAdminsUserIdPut(user_id: number, app_http_requests_users_admin_update_request: AppHttpRequestsUsersAdminUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdPut.');
        }
        if (app_http_requests_users_admin_update_request === null || app_http_requests_users_admin_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_update_request was null or undefined when calling apiAdminAdminsUserIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersAdminHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add tenant to admin
     * @param user_id User Id
     * @param app_http_requests_users_admin_add_tenant_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdTenantsPost(user_id: number, app_http_requests_users_admin_add_tenant_request: AppHttpRequestsUsersAdminAddTenantRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsUserIdTenantsPost(user_id: number, app_http_requests_users_admin_add_tenant_request: AppHttpRequestsUsersAdminAddTenantRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsUserIdTenantsPost(user_id: number, app_http_requests_users_admin_add_tenant_request: AppHttpRequestsUsersAdminAddTenantRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsUserIdTenantsPost(user_id: number, app_http_requests_users_admin_add_tenant_request: AppHttpRequestsUsersAdminAddTenantRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdTenantsPost.');
        }
        if (app_http_requests_users_admin_add_tenant_request === null || app_http_requests_users_admin_add_tenant_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_add_tenant_request was null or undefined when calling apiAdminAdminsUserIdTenantsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tenants`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_add_tenant_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove tenant from admin
     * @param user_id User ID
     * @param tenant_id Tenant ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsUserIdTenantsTenantIdDelete(user_id: number, tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsUserIdTenantsTenantIdDelete(user_id: number, tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsUserIdTenantsTenantIdDelete(user_id: number, tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsUserIdTenantsTenantIdDelete(user_id: number, tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminAdminsUserIdTenantsTenantIdDelete.');
        }
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminAdminsUserIdTenantsTenantIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify admin
     * Verify admin user
     * @param app_http_requests_users_admin_verify_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAdminsVerifyPut(app_http_requests_users_admin_verify_request: AppHttpRequestsUsersAdminVerifyRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminAdminsVerifyPut(app_http_requests_users_admin_verify_request: AppHttpRequestsUsersAdminVerifyRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminAdminsVerifyPut(app_http_requests_users_admin_verify_request: AppHttpRequestsUsersAdminVerifyRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminAdminsVerifyPut(app_http_requests_users_admin_verify_request: AppHttpRequestsUsersAdminVerifyRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_admin_verify_request === null || app_http_requests_users_admin_verify_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_admin_verify_request was null or undefined when calling apiAdminAdminsVerifyPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/admins/verify`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_admin_verify_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Api Consumers
     * Get all Api Consumers
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminApiconsumersGet(per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersApiConsumerListHttpResponse>;
    public apiAdminApiconsumersGet(per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersApiConsumerListHttpResponse>>;
    public apiAdminApiconsumersGet(per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersApiConsumerListHttpResponse>>;
    public apiAdminApiconsumersGet(per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/apiconsumers`;
        return this.httpClient.request<AppHttpResponsesUsersApiConsumerListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Api Consumer
     * Create a new Api Consumer
     * @param app_http_requests_users_api_consumer_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminApiconsumersPost(app_http_requests_users_api_consumer_create_request: AppHttpRequestsUsersApiConsumerCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>;
    public apiAdminApiconsumersPost(app_http_requests_users_api_consumer_create_request: AppHttpRequestsUsersApiConsumerCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>>;
    public apiAdminApiconsumersPost(app_http_requests_users_api_consumer_create_request: AppHttpRequestsUsersApiConsumerCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>>;
    public apiAdminApiconsumersPost(app_http_requests_users_api_consumer_create_request: AppHttpRequestsUsersApiConsumerCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_api_consumer_create_request === null || app_http_requests_users_api_consumer_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_api_consumer_create_request was null or undefined when calling apiAdminApiconsumersPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/apiconsumers`;
        return this.httpClient.request<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_api_consumer_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete api consumer
     * Delete api consumer
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminApiconsumersUserIdDelete(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminApiconsumersUserIdDelete(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminApiconsumersUserIdDelete(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminApiconsumersUserIdDelete(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminApiconsumersUserIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/apiconsumers/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get api consumer
     * Get api consumer
     * @param user_id User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminApiconsumersUserIdGet(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersApiConsumerHttpResponse>;
    public apiAdminApiconsumersUserIdGet(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersApiConsumerHttpResponse>>;
    public apiAdminApiconsumersUserIdGet(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersApiConsumerHttpResponse>>;
    public apiAdminApiconsumersUserIdGet(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminApiconsumersUserIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/apiconsumers/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersApiConsumerHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update api consumer
     * Update api consumer
     * @param user_id User Id
     * @param app_http_requests_users_api_consumer_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminApiconsumersUserIdPut(user_id: number, app_http_requests_users_api_consumer_update_request: AppHttpRequestsUsersApiConsumerUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersApiConsumerHttpResponse>;
    public apiAdminApiconsumersUserIdPut(user_id: number, app_http_requests_users_api_consumer_update_request: AppHttpRequestsUsersApiConsumerUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersApiConsumerHttpResponse>>;
    public apiAdminApiconsumersUserIdPut(user_id: number, app_http_requests_users_api_consumer_update_request: AppHttpRequestsUsersApiConsumerUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersApiConsumerHttpResponse>>;
    public apiAdminApiconsumersUserIdPut(user_id: number, app_http_requests_users_api_consumer_update_request: AppHttpRequestsUsersApiConsumerUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminApiconsumersUserIdPut.');
        }
        if (app_http_requests_users_api_consumer_update_request === null || app_http_requests_users_api_consumer_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_api_consumer_update_request was null or undefined when calling apiAdminApiconsumersUserIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/apiconsumers/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesUsersApiConsumerHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_api_consumer_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a chat timeline
     * @param chat_id Chat ID
     * @param tenant_id Tenant ID
     * @param game_unique_id Game Unique ID
     * @param user_ids User ID(s) (comma separated)
     * @param b2b_user_ids B2B User ID(s) (comma separated)
     * @param user_names User Name(s) (comma separated)
     * @param only_blocking Only blocking messages
     * @param only_ghosting Only ghosting messages
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminChatsTimelineGet(chat_id?: number, tenant_id?: number, game_unique_id?: string, user_ids?: string, b2b_user_ids?: string, user_names?: string, only_blocking?: boolean, only_ghosting?: boolean, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesChatsChatTimelineListHttpResponse>;
    public apiAdminChatsTimelineGet(chat_id?: number, tenant_id?: number, game_unique_id?: string, user_ids?: string, b2b_user_ids?: string, user_names?: string, only_blocking?: boolean, only_ghosting?: boolean, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesChatsChatTimelineListHttpResponse>>;
    public apiAdminChatsTimelineGet(chat_id?: number, tenant_id?: number, game_unique_id?: string, user_ids?: string, b2b_user_ids?: string, user_names?: string, only_blocking?: boolean, only_ghosting?: boolean, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesChatsChatTimelineListHttpResponse>>;
    public apiAdminChatsTimelineGet(chat_id?: number, tenant_id?: number, game_unique_id?: string, user_ids?: string, b2b_user_ids?: string, user_names?: string, only_blocking?: boolean, only_ghosting?: boolean, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (chat_id !== undefined && chat_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>chat_id, 'chat_id');
        }
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }
        if (game_unique_id !== undefined && game_unique_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>game_unique_id, 'game_unique_id');
        }
        if (user_ids !== undefined && user_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>user_ids, 'user_ids');
        }
        if (b2b_user_ids !== undefined && b2b_user_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>b2b_user_ids, 'b2b_user_ids');
        }
        if (user_names !== undefined && user_names !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>user_names, 'user_names');
        }
        if (only_blocking !== undefined && only_blocking !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_blocking, 'only_blocking');
        }
        if (only_ghosting !== undefined && only_ghosting !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_ghosting, 'only_ghosting');
        }
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/chats/timeline`;
        return this.httpClient.request<AppHttpResponsesChatsChatTimelineListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete CMS
     * Delete CMS
     * @param cms_id CMS-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCmsCmsIdDelete(cms_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminCmsCmsIdDelete(cms_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminCmsCmsIdDelete(cms_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminCmsCmsIdDelete(cms_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (cms_id === null || cms_id === undefined) {
            throw new Error('Required parameter cms_id was null or undefined when calling apiAdminCmsCmsIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/cms/${this.configuration.encodeParam({name: "cms_id", value: cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Content Manage System
     * Get Content Manage System
     * @param cms_id CMS-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCmsCmsIdGet(cms_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesCMSsCMSHttpResponse>;
    public apiAdminCmsCmsIdGet(cms_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdGet(cms_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdGet(cms_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (cms_id === null || cms_id === undefined) {
            throw new Error('Required parameter cms_id was null or undefined when calling apiAdminCmsCmsIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/cms/${this.configuration.encodeParam({name: "cms_id", value: cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesCMSsCMSHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update CMS Media Translations
     * Update CMS Media Translations
     * @param cms_id CMS-ID
     * @param app_http_requests_cmss_cms_media_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCmsCmsIdMediaTranslationPut(cms_id: number, app_http_requests_cmss_cms_media_translations_update_request: AppHttpRequestsCMSsCMSMediaTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesCMSsCMSHttpResponse>;
    public apiAdminCmsCmsIdMediaTranslationPut(cms_id: number, app_http_requests_cmss_cms_media_translations_update_request: AppHttpRequestsCMSsCMSMediaTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdMediaTranslationPut(cms_id: number, app_http_requests_cmss_cms_media_translations_update_request: AppHttpRequestsCMSsCMSMediaTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdMediaTranslationPut(cms_id: number, app_http_requests_cmss_cms_media_translations_update_request: AppHttpRequestsCMSsCMSMediaTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (cms_id === null || cms_id === undefined) {
            throw new Error('Required parameter cms_id was null or undefined when calling apiAdminCmsCmsIdMediaTranslationPut.');
        }
        if (app_http_requests_cmss_cms_media_translations_update_request === null || app_http_requests_cmss_cms_media_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_cmss_cms_media_translations_update_request was null or undefined when calling apiAdminCmsCmsIdMediaTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/cms/${this.configuration.encodeParam({name: "cms_id", value: cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media-translation`;
        return this.httpClient.request<AppHttpResponsesCMSsCMSHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_cmss_cms_media_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update CMS
     * Update CMS
     * @param cms_id CMS-ID
     * @param app_http_requests_cmss_cms_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCmsCmsIdPut(cms_id: number, app_http_requests_cmss_cms_update_request: AppHttpRequestsCMSsCMSUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesCMSsCMSHttpResponse>;
    public apiAdminCmsCmsIdPut(cms_id: number, app_http_requests_cmss_cms_update_request: AppHttpRequestsCMSsCMSUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdPut(cms_id: number, app_http_requests_cmss_cms_update_request: AppHttpRequestsCMSsCMSUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdPut(cms_id: number, app_http_requests_cmss_cms_update_request: AppHttpRequestsCMSsCMSUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (cms_id === null || cms_id === undefined) {
            throw new Error('Required parameter cms_id was null or undefined when calling apiAdminCmsCmsIdPut.');
        }
        if (app_http_requests_cmss_cms_update_request === null || app_http_requests_cmss_cms_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_cmss_cms_update_request was null or undefined when calling apiAdminCmsCmsIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/cms/${this.configuration.encodeParam({name: "cms_id", value: cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesCMSsCMSHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_cmss_cms_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update CMS Translations
     * Update CMS Translation
     * @param cms_id CMS-ID
     * @param app_http_requests_cmss_cms_translation_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCmsCmsIdTranslationPut(cms_id: number, app_http_requests_cmss_cms_translation_update_request: AppHttpRequestsCMSsCMSTranslationUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesCMSsCMSHttpResponse>;
    public apiAdminCmsCmsIdTranslationPut(cms_id: number, app_http_requests_cmss_cms_translation_update_request: AppHttpRequestsCMSsCMSTranslationUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdTranslationPut(cms_id: number, app_http_requests_cmss_cms_translation_update_request: AppHttpRequestsCMSsCMSTranslationUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesCMSsCMSHttpResponse>>;
    public apiAdminCmsCmsIdTranslationPut(cms_id: number, app_http_requests_cmss_cms_translation_update_request: AppHttpRequestsCMSsCMSTranslationUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (cms_id === null || cms_id === undefined) {
            throw new Error('Required parameter cms_id was null or undefined when calling apiAdminCmsCmsIdTranslationPut.');
        }
        if (app_http_requests_cmss_cms_translation_update_request === null || app_http_requests_cmss_cms_translation_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_cmss_cms_translation_update_request was null or undefined when calling apiAdminCmsCmsIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/cms/${this.configuration.encodeParam({name: "cms_id", value: cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesCMSsCMSHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_cmss_cms_translation_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All CMS
     * Get All CMS
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCmsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesCMSsCMSListHttpResponse>;
    public apiAdminCmsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesCMSsCMSListHttpResponse>>;
    public apiAdminCmsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesCMSsCMSListHttpResponse>>;
    public apiAdminCmsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/cms`;
        return this.httpClient.request<AppHttpResponsesCMSsCMSListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create CMS
     * Create CMS
     * @param app_http_requests_cmss_cms_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCmsPost(app_http_requests_cmss_cms_create_request: AppHttpRequestsCMSsCMSCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminCmsPost(app_http_requests_cmss_cms_create_request: AppHttpRequestsCMSsCMSCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminCmsPost(app_http_requests_cmss_cms_create_request: AppHttpRequestsCMSsCMSCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminCmsPost(app_http_requests_cmss_cms_create_request: AppHttpRequestsCMSsCMSCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_cmss_cms_create_request === null || app_http_requests_cmss_cms_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_cmss_cms_create_request was null or undefined when calling apiAdminCmsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/cms/`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_cmss_cms_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Invoices
     * returns all Currencies of a tenant
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesGet(tenant_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFinancialsAccountingInvoiceListHttpResponse>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesGet(tenant_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFinancialsAccountingInvoiceListHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesGet(tenant_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFinancialsAccountingInvoiceListHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesGet(tenant_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminFinancialsTenantsTenantIdAccountingInvoicesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/financials/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounting/invoices`;
        return this.httpClient.request<AppHttpResponsesFinancialsAccountingInvoiceListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single Tenant Invoice
     * returns an invoice by its invoice number or unique id
     * @param tenant_id Tenant ID
     * @param invoice_id Invoice ID or Invoice Number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdGet(tenant_id: string, invoice_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFinancialsAccountingInvoiceHttpResponse>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdGet(tenant_id: string, invoice_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFinancialsAccountingInvoiceHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdGet(tenant_id: string, invoice_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFinancialsAccountingInvoiceHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdGet(tenant_id: string, invoice_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdGet.');
        }
        if (invoice_id === null || invoice_id === undefined) {
            throw new Error('Required parameter invoice_id was null or undefined when calling apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/financials/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounting/invoices/${this.configuration.encodeParam({name: "invoice_id", value: invoice_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesFinancialsAccountingInvoiceHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Invoice Positions
     * @param tenant_id Tenant Id
     * @param invoice_id Invoice Id or Invoice Number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdPositionsGet(tenant_id: string, invoice_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFinancialsAccountingInvoicePositionListHttpResponse>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdPositionsGet(tenant_id: string, invoice_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFinancialsAccountingInvoicePositionListHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdPositionsGet(tenant_id: string, invoice_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFinancialsAccountingInvoicePositionListHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdPositionsGet(tenant_id: string, invoice_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdPositionsGet.');
        }
        if (invoice_id === null || invoice_id === undefined) {
            throw new Error('Required parameter invoice_id was null or undefined when calling apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdPositionsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/financials/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounting/invoices/${this.configuration.encodeParam({name: "invoice_id", value: invoice_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/positions`;
        return this.httpClient.request<AppHttpResponsesFinancialsAccountingInvoicePositionListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Invoice Transactions
     * @param tenant_id Tenant Id
     * @param invoice_id Invoice Id or Invoice Number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdTransactionsGet(tenant_id: string, invoice_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFinancialsAccountingPaymentHistoryTransactionListHttpResponse>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdTransactionsGet(tenant_id: string, invoice_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFinancialsAccountingPaymentHistoryTransactionListHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdTransactionsGet(tenant_id: string, invoice_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFinancialsAccountingPaymentHistoryTransactionListHttpResponse>>;
    public apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdTransactionsGet(tenant_id: string, invoice_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdTransactionsGet.');
        }
        if (invoice_id === null || invoice_id === undefined) {
            throw new Error('Required parameter invoice_id was null or undefined when calling apiAdminFinancialsTenantsTenantIdAccountingInvoicesInvoiceIdTransactionsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/financials/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounting/invoices/${this.configuration.encodeParam({name: "invoice_id", value: invoice_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transactions`;
        return this.httpClient.request<AppHttpResponsesFinancialsAccountingPaymentHistoryTransactionListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Master Flag Reasons
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminFlagreasonsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesMasterGameFlagReasonListHttpResponse>;
    public apiAdminFlagreasonsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesMasterGameFlagReasonListHttpResponse>>;
    public apiAdminFlagreasonsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesMasterGameFlagReasonListHttpResponse>>;
    public apiAdminFlagreasonsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/flagreasons`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesMasterGameFlagReasonListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancel a Game
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdAdminCancelPut(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGamesGameUniqueIdAdminCancelPut(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGamesGameUniqueIdAdminCancelPut(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGamesGameUniqueIdAdminCancelPut(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdAdminCancelPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/admin-cancel`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Flag Game from MyAdmin
     * @param game_unique_id Game Unique Id
     * @param app_http_requests_betsnaps_games_flag_game_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdAdminFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiAdminGamesGameUniqueIdAdminFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdAdminFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdAdminFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdAdminFlagPut.');
        }
        if (app_http_requests_betsnaps_games_flag_game_request === null || app_http_requests_betsnaps_games_flag_game_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_flag_game_request was null or undefined when calling apiAdminGamesGameUniqueIdAdminFlagPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/admin-flag`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_flag_game_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Game Flag from MyAdmin
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdAdminUnflagPut(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGamesGameUniqueIdAdminUnflagPut(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGamesGameUniqueIdAdminUnflagPut(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGamesGameUniqueIdAdminUnflagPut(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdAdminUnflagPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/admin-unflag`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks a Game Allowed Users Request
     * Checks a Game Allowed Users Request
     * @param game_unique_id Game Unique ID
     * @param app_http_requests_betsnaps_games_game_allowed_user_check_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdAllowedusersCheckPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_check_request: AppHttpRequestsBetsnapsGamesGameAllowedUserCheckRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameAllowedUserCheckListHttpResponse>;
    public apiAdminGamesGameUniqueIdAllowedusersCheckPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_check_request: AppHttpRequestsBetsnapsGamesGameAllowedUserCheckRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameAllowedUserCheckListHttpResponse>>;
    public apiAdminGamesGameUniqueIdAllowedusersCheckPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_check_request: AppHttpRequestsBetsnapsGamesGameAllowedUserCheckRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameAllowedUserCheckListHttpResponse>>;
    public apiAdminGamesGameUniqueIdAllowedusersCheckPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_check_request: AppHttpRequestsBetsnapsGamesGameAllowedUserCheckRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdAllowedusersCheckPost.');
        }
        if (app_http_requests_betsnaps_games_game_allowed_user_check_request === null || app_http_requests_betsnaps_games_game_allowed_user_check_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_allowed_user_check_request was null or undefined when calling apiAdminGamesGameUniqueIdAllowedusersCheckPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/allowedusers/check`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameAllowedUserCheckListHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_allowed_user_check_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete all allowed Users for a Game
     * @param game_unique_id Game Unique ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdAllowedusersDelete(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGamesGameUniqueIdAllowedusersDelete(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGamesGameUniqueIdAllowedusersDelete(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGamesGameUniqueIdAllowedusersDelete(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdAllowedusersDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/allowedusers`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Allowed Users for a Game
     * Get Allowed Users for a Game
     * @param game_unique_id Game Unique ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdAllowedusersGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>;
    public apiAdminGamesGameUniqueIdAllowedusersGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>>;
    public apiAdminGamesGameUniqueIdAllowedusersGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>>;
    public apiAdminGamesGameUniqueIdAllowedusersGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdAllowedusersGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/allowedusers`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Allowed Users for a Game
     * Set Allowed Users for a Game
     * @param game_unique_id Game Unique ID
     * @param app_http_requests_betsnaps_games_game_allowed_user_add_remove_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdAllowedusersSetPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_add_remove_request: AppHttpRequestsBetsnapsGamesGameAllowedUserAddRemoveRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>;
    public apiAdminGamesGameUniqueIdAllowedusersSetPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_add_remove_request: AppHttpRequestsBetsnapsGamesGameAllowedUserAddRemoveRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>>;
    public apiAdminGamesGameUniqueIdAllowedusersSetPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_add_remove_request: AppHttpRequestsBetsnapsGamesGameAllowedUserAddRemoveRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>>;
    public apiAdminGamesGameUniqueIdAllowedusersSetPost(game_unique_id: string, app_http_requests_betsnaps_games_game_allowed_user_add_remove_request: AppHttpRequestsBetsnapsGamesGameAllowedUserAddRemoveRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdAllowedusersSetPost.');
        }
        if (app_http_requests_betsnaps_games_game_allowed_user_add_remove_request === null || app_http_requests_betsnaps_games_game_allowed_user_add_remove_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_allowed_user_add_remove_request was null or undefined when calling apiAdminGamesGameUniqueIdAllowedusersSetPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/allowedusers/set`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameAllowedUserListHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_allowed_user_add_remove_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancel a Game
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdCancelPut(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGamesGameUniqueIdCancelPut(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGamesGameUniqueIdCancelPut(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGamesGameUniqueIdCancelPut(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdCancelPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/cancel`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Flag Game
     * @param game_unique_id Game Unique Id
     * @param app_http_requests_betsnaps_games_flag_game_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiAdminGamesGameUniqueIdFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdFlagPut(game_unique_id: string, app_http_requests_betsnaps_games_flag_game_request: AppHttpRequestsBetsnapsGamesFlagGameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdFlagPut.');
        }
        if (app_http_requests_betsnaps_games_flag_game_request === null || app_http_requests_betsnaps_games_flag_game_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_flag_game_request was null or undefined when calling apiAdminGamesGameUniqueIdFlagPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/flag`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_flag_game_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Game Flag Reasons
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdFlagreasonsGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameFlagReasonListHttpResponse>;
    public apiAdminGamesGameUniqueIdFlagreasonsGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameFlagReasonListHttpResponse>>;
    public apiAdminGamesGameUniqueIdFlagreasonsGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameFlagReasonListHttpResponse>>;
    public apiAdminGamesGameUniqueIdFlagreasonsGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdFlagreasonsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/flagreasons`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameFlagReasonListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find game
     * Find game
     * @param game_unique_id Game-Unique-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>;
    public apiAdminGamesGameUniqueIdGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get game markets
     * Get game markets
     * @param game_unique_id Game-Unique-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdMarketsGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameMarketListHttpResponse>;
    public apiAdminGamesGameUniqueIdMarketsGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameMarketListHttpResponse>>;
    public apiAdminGamesGameUniqueIdMarketsGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameMarketListHttpResponse>>;
    public apiAdminGamesGameUniqueIdMarketsGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdMarketsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/markets`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all matches for a game
     * Get all matches for a game
     * @param game_unique_id Game-Unique-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdMatchesGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>;
    public apiAdminGamesGameUniqueIdMatchesGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>>;
    public apiAdminGamesGameUniqueIdMatchesGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>>;
    public apiAdminGamesGameUniqueIdMatchesGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdMatchesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/matches`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Init Recovery of all Game Matches
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdMatchesInitrecoveryPut(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGamesGameUniqueIdMatchesInitrecoveryPut(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGamesGameUniqueIdMatchesInitrecoveryPut(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGamesGameUniqueIdMatchesInitrecoveryPut(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdMatchesInitrecoveryPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/matches/initrecovery`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Game (client_auth Route for old Admin)
     * Update game
     * @param game_unique_id Game Unique Id
     * @param app_http_requests_betsnaps_games_game_update_game_admin_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesGameUniqueIdPut(game_unique_id: string, app_http_requests_betsnaps_games_game_update_game_admin_request: AppHttpRequestsBetsnapsGamesGameUpdateGameAdminRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdPut.');
        }
        if (app_http_requests_betsnaps_games_game_update_game_admin_request === null || app_http_requests_betsnaps_games_game_update_game_admin_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_update_game_admin_request was null or undefined when calling apiAdminGamesGameUniqueIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_update_game_admin_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send Game Winners to Admins
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdSendgamewinnersPut(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGamesGameUniqueIdSendgamewinnersPut(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGamesGameUniqueIdSendgamewinnersPut(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGamesGameUniqueIdSendgamewinnersPut(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdSendgamewinnersPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/sendgamewinners`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all simple match markets by game_unique_id
     * Find all simple match markets by game_unique_id
     * @param game_unique_id Game-Unique-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdSimpleMatchMarketsGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameMatchSimpleMarketListHttpResponse>;
    public apiAdminGamesGameUniqueIdSimpleMatchMarketsGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameMatchSimpleMarketListHttpResponse>>;
    public apiAdminGamesGameUniqueIdSimpleMatchMarketsGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameMatchSimpleMarketListHttpResponse>>;
    public apiAdminGamesGameUniqueIdSimpleMatchMarketsGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdSimpleMatchMarketsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/simple/match-markets`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameMatchSimpleMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Tags for Game
     * Get all Tenant Tags for Game
     * @param game_unique_id Game ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdTagsGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameTagListHttpResponse>;
    public apiAdminGamesGameUniqueIdTagsGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameTagListHttpResponse>>;
    public apiAdminGamesGameUniqueIdTagsGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameTagListHttpResponse>>;
    public apiAdminGamesGameUniqueIdTagsGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdTagsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/tags`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameTagListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Game Tag
     * @param game_unique_id Game Id
     * @param app_http_requests_betsnaps_games_game_tag_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdTagsPost(game_unique_id: string, app_http_requests_betsnaps_games_game_tag_create_request: AppHttpRequestsBetsnapsGamesGameTagCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameTagHttpResponse>;
    public apiAdminGamesGameUniqueIdTagsPost(game_unique_id: string, app_http_requests_betsnaps_games_game_tag_create_request: AppHttpRequestsBetsnapsGamesGameTagCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameTagHttpResponse>>;
    public apiAdminGamesGameUniqueIdTagsPost(game_unique_id: string, app_http_requests_betsnaps_games_game_tag_create_request: AppHttpRequestsBetsnapsGamesGameTagCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameTagHttpResponse>>;
    public apiAdminGamesGameUniqueIdTagsPost(game_unique_id: string, app_http_requests_betsnaps_games_game_tag_create_request: AppHttpRequestsBetsnapsGamesGameTagCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdTagsPost.');
        }
        if (app_http_requests_betsnaps_games_game_tag_create_request === null || app_http_requests_betsnaps_games_game_tag_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_tag_create_request was null or undefined when calling apiAdminGamesGameUniqueIdTagsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/tags`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameTagHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_tag_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Game Flag
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdUnflagPut(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGamesGameUniqueIdUnflagPut(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGamesGameUniqueIdUnflagPut(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGamesGameUniqueIdUnflagPut(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdUnflagPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/unflag`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Vendor Games for a Game
     * Get all Vendor Games for a Game
     * @param game_unique_id Game Unique Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGameUniqueIdVendorgamesGet(game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesGameVendorGameListHttpResponse>;
    public apiAdminGamesGameUniqueIdVendorgamesGet(game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesGameVendorGameListHttpResponse>>;
    public apiAdminGamesGameUniqueIdVendorgamesGet(game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesGameVendorGameListHttpResponse>>;
    public apiAdminGamesGameUniqueIdVendorgamesGet(game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminGamesGameUniqueIdVendorgamesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/vendorgames`;
        return this.httpClient.request<AppHttpResponsesVendorGamesGameVendorGameListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all admin games
     * Get all admin games
     * @param tenant_ids Tenant-IDs
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param tenant_tag_ids Tenant Tag IDs
     * @param is_flagged Filter is Snap flagged for some reasons? (Feed-Problems, etc.)
     * @param start_date Filter BetSnap Start-Date
     * @param end_date Filter BetSnap End-Date
     * @param game_states Filter Game States
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesGet(tenant_ids?: string, per_page?: number, page?: number, tenant_tag_ids?: string, is_flagged?: boolean, start_date?: string, end_date?: string, game_states?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameListHttpResponse>;
    public apiAdminGamesGet(tenant_ids?: string, per_page?: number, page?: number, tenant_tag_ids?: string, is_flagged?: boolean, start_date?: string, end_date?: string, game_states?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameListHttpResponse>>;
    public apiAdminGamesGet(tenant_ids?: string, per_page?: number, page?: number, tenant_tag_ids?: string, is_flagged?: boolean, start_date?: string, end_date?: string, game_states?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameListHttpResponse>>;
    public apiAdminGamesGet(tenant_ids?: string, per_page?: number, page?: number, tenant_tag_ids?: string, is_flagged?: boolean, start_date?: string, end_date?: string, game_states?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (tenant_tag_ids !== undefined && tenant_tag_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_tag_ids, 'tenant_tag_ids');
        }
        if (is_flagged !== undefined && is_flagged !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_flagged, 'is_flagged');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }
        if (game_states !== undefined && game_states !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>game_states, 'game_states');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Game (client_auth Route for old Admin)
     * Create Game
     * @param app_http_requests_betsnaps_games_game_create_game_admin_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesGameHttpResponse>;
    public apiAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesGameHttpResponse>>;
    public apiAdminGamesPost(app_http_requests_betsnaps_games_game_create_game_admin_request: AppHttpRequestsBetsnapsGamesGameCreateGameAdminRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_betsnaps_games_game_create_game_admin_request === null || app_http_requests_betsnaps_games_game_create_game_admin_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_games_game_create_game_admin_request was null or undefined when calling apiAdminGamesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/games`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesGameHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_games_game_create_game_admin_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Game Tag
     * @param game_tag_id Game Tag Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGametagsGameTagIdDelete(game_tag_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminGametagsGameTagIdDelete(game_tag_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminGametagsGameTagIdDelete(game_tag_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminGametagsGameTagIdDelete(game_tag_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (game_tag_id === null || game_tag_id === undefined) {
            throw new Error('Required parameter game_tag_id was null or undefined when calling apiAdminGametagsGameTagIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/gametags/${this.configuration.encodeParam({name: "game_tag_id", value: game_tag_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all GUI-Themes
     * Get all GUI-Themes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGuiThemesGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesGeneralGUIThemeListHttpResponse>;
    public apiAdminGuiThemesGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesGeneralGUIThemeListHttpResponse>>;
    public apiAdminGuiThemesGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesGeneralGUIThemeListHttpResponse>>;
    public apiAdminGuiThemesGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/gui-themes`;
        return this.httpClient.request<AppHttpResponsesGeneralGUIThemeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a GUI-Theme
     * Find a GUI-Theme
     * @param gui_theme_id GUI Theme Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGuiThemesGuiThemeIdGet(gui_theme_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesGeneralGUIThemeHttpResponse>;
    public apiAdminGuiThemesGuiThemeIdGet(gui_theme_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesGeneralGUIThemeHttpResponse>>;
    public apiAdminGuiThemesGuiThemeIdGet(gui_theme_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesGeneralGUIThemeHttpResponse>>;
    public apiAdminGuiThemesGuiThemeIdGet(gui_theme_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (gui_theme_id === null || gui_theme_id === undefined) {
            throw new Error('Required parameter gui_theme_id was null or undefined when calling apiAdminGuiThemesGuiThemeIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/gui-themes/${this.configuration.encodeParam({name: "gui_theme_id", value: gui_theme_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesGeneralGUIThemeHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all languages
     * Get all languages
     * @param tenant_ids Tenant-IDs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminLanguagesGet(tenant_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesGeneralLanguageListHttpResponse>;
    public apiAdminLanguagesGet(tenant_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesGeneralLanguageListHttpResponse>>;
    public apiAdminLanguagesGet(tenant_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesGeneralLanguageListHttpResponse>>;
    public apiAdminLanguagesGet(tenant_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/languages`;
        return this.httpClient.request<AppHttpResponsesGeneralLanguageListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Markets
     * Get all Markets
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param sport_ids Filter Sport Ids (comma separated)
     * @param is_simple_snap_capable Is Simple Snap Capable
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMarketListHttpResponse>;
    public apiAdminMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMarketListHttpResponse>>;
    public apiAdminMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMarketListHttpResponse>>;
    public apiAdminMarketsGet(per_page?: number, page?: number, sport_ids?: string, is_simple_snap_capable?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }
        if (is_simple_snap_capable !== undefined && is_simple_snap_capable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_simple_snap_capable, 'is_simple_snap_capable');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/markets`;
        return this.httpClient.request<AppHttpResponsesSportsDataMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Simple Market Translations
     * Update Simple Market Translations
     * @param market_id Market ID
     * @param app_http_requests_sports_data_market_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMarketsMarketIdSimpleTranslationPut(market_id: number, app_http_requests_sports_data_market_translations_update_request: AppHttpRequestsSportsDataMarketTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMarketHttpResponse>;
    public apiAdminMarketsMarketIdSimpleTranslationPut(market_id: number, app_http_requests_sports_data_market_translations_update_request: AppHttpRequestsSportsDataMarketTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMarketHttpResponse>>;
    public apiAdminMarketsMarketIdSimpleTranslationPut(market_id: number, app_http_requests_sports_data_market_translations_update_request: AppHttpRequestsSportsDataMarketTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMarketHttpResponse>>;
    public apiAdminMarketsMarketIdSimpleTranslationPut(market_id: number, app_http_requests_sports_data_market_translations_update_request: AppHttpRequestsSportsDataMarketTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (market_id === null || market_id === undefined) {
            throw new Error('Required parameter market_id was null or undefined when calling apiAdminMarketsMarketIdSimpleTranslationPut.');
        }
        if (app_http_requests_sports_data_market_translations_update_request === null || app_http_requests_sports_data_market_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_sports_data_market_translations_update_request was null or undefined when calling apiAdminMarketsMarketIdSimpleTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/markets/${this.configuration.encodeParam({name: "market_id", value: market_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/simple-translation`;
        return this.httpClient.request<AppHttpResponsesSportsDataMarketHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_sports_data_market_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancel a Match in all Snaps
     * @param match_id Match Id
     * @param app_http_requests_sports_data_match_cancel_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMatchesMatchIdCancelPut(match_id: string, app_http_requests_sports_data_match_cancel_request: AppHttpRequestsSportsDataMatchCancelRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminMatchesMatchIdCancelPut(match_id: string, app_http_requests_sports_data_match_cancel_request: AppHttpRequestsSportsDataMatchCancelRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminMatchesMatchIdCancelPut(match_id: string, app_http_requests_sports_data_match_cancel_request: AppHttpRequestsSportsDataMatchCancelRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminMatchesMatchIdCancelPut(match_id: string, app_http_requests_sports_data_match_cancel_request: AppHttpRequestsSportsDataMatchCancelRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiAdminMatchesMatchIdCancelPut.');
        }
        if (app_http_requests_sports_data_match_cancel_request === null || app_http_requests_sports_data_match_cancel_request === undefined) {
            throw new Error('Required parameter app_http_requests_sports_data_match_cancel_request was null or undefined when calling apiAdminMatchesMatchIdCancelPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/cancel`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_sports_data_match_cancel_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Init Recovery of a specific Match
     * @param match_id Match Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMatchesMatchIdInitrecoveryPut(match_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminMatchesMatchIdInitrecoveryPut(match_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminMatchesMatchIdInitrecoveryPut(match_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminMatchesMatchIdInitrecoveryPut(match_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiAdminMatchesMatchIdInitrecoveryPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/initrecovery`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Force a match market settlement
     * @param match_id Match Id
     * @param market_id Market Id
     * @param specifier Market Specifier
     * @param app_http_requests_sports_data_match_market_settlement_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut(match_id: number, market_id: number, specifier: string, app_http_requests_sports_data_match_market_settlement_request: AppHttpRequestsSportsDataMatchMarketSettlementRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut(match_id: number, market_id: number, specifier: string, app_http_requests_sports_data_match_market_settlement_request: AppHttpRequestsSportsDataMatchMarketSettlementRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut(match_id: number, market_id: number, specifier: string, app_http_requests_sports_data_match_market_settlement_request: AppHttpRequestsSportsDataMatchMarketSettlementRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut(match_id: number, market_id: number, specifier: string, app_http_requests_sports_data_match_market_settlement_request: AppHttpRequestsSportsDataMatchMarketSettlementRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (match_id === null || match_id === undefined) {
            throw new Error('Required parameter match_id was null or undefined when calling apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut.');
        }
        if (market_id === null || market_id === undefined) {
            throw new Error('Required parameter market_id was null or undefined when calling apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut.');
        }
        if (specifier === null || specifier === undefined) {
            throw new Error('Required parameter specifier was null or undefined when calling apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut.');
        }
        if (app_http_requests_sports_data_match_market_settlement_request === null || app_http_requests_sports_data_match_market_settlement_request === undefined) {
            throw new Error('Required parameter app_http_requests_sports_data_match_market_settlement_request was null or undefined when calling apiAdminMatchesMatchIdMarketsMarketIdSpecifierSettlementPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/matches/${this.configuration.encodeParam({name: "match_id", value: match_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/markets/${this.configuration.encodeParam({name: "market_id", value: market_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/${this.configuration.encodeParam({name: "specifier", value: specifier, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/settlement`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_sports_data_match_market_settlement_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get media
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param tenant_id Tenant ID
     * @param media_type_id Media Type Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMediaGet(per_page?: number, page?: number, tenant_id?: number, media_type_id?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesMediaMediaListHttpResponse>;
    public apiAdminMediaGet(per_page?: number, page?: number, tenant_id?: number, media_type_id?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesMediaMediaListHttpResponse>>;
    public apiAdminMediaGet(per_page?: number, page?: number, tenant_id?: number, media_type_id?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesMediaMediaListHttpResponse>>;
    public apiAdminMediaGet(per_page?: number, page?: number, tenant_id?: number, media_type_id?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }
        if (media_type_id !== undefined && media_type_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>media_type_id, 'media_type_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/media`;
        return this.httpClient.request<AppHttpResponsesMediaMediaListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Media
     * Delete Media
     * @param media_id Media ID
     * @param tenant_ids Tenant Ids (comma separated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMediaMediaIdDelete(media_id: number, tenant_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesMediaMediaDeletedHttpResponse>;
    public apiAdminMediaMediaIdDelete(media_id: number, tenant_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesMediaMediaDeletedHttpResponse>>;
    public apiAdminMediaMediaIdDelete(media_id: number, tenant_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesMediaMediaDeletedHttpResponse>>;
    public apiAdminMediaMediaIdDelete(media_id: number, tenant_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (media_id === null || media_id === undefined) {
            throw new Error('Required parameter media_id was null or undefined when calling apiAdminMediaMediaIdDelete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/media/${this.configuration.encodeParam({name: "media_id", value: media_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesMediaMediaDeletedHttpResponse>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a media file
     * @param app_http_requests_media_media_upload_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMediaPost(app_http_requests_media_media_upload_request: AppHttpRequestsMediaMediaUploadRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesMediaMediaHttpResponse>;
    public apiAdminMediaPost(app_http_requests_media_media_upload_request: AppHttpRequestsMediaMediaUploadRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesMediaMediaHttpResponse>>;
    public apiAdminMediaPost(app_http_requests_media_media_upload_request: AppHttpRequestsMediaMediaUploadRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesMediaMediaHttpResponse>>;
    public apiAdminMediaPost(app_http_requests_media_media_upload_request: AppHttpRequestsMediaMediaUploadRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_media_media_upload_request === null || app_http_requests_media_media_upload_request === undefined) {
            throw new Error('Required parameter app_http_requests_media_media_upload_request was null or undefined when calling apiAdminMediaPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/media`;
        return this.httpClient.request<AppHttpResponsesMediaMediaHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_media_media_upload_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get media types
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminMediaTypesGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesMediaMediaTypeListHttpResponse>;
    public apiAdminMediaTypesGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesMediaMediaTypeListHttpResponse>>;
    public apiAdminMediaTypesGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesMediaMediaTypeListHttpResponse>>;
    public apiAdminMediaTypesGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/media/types`;
        return this.httpClient.request<AppHttpResponsesMediaMediaTypeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Api Consumers
     * Get all Oauth Clients
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param type Type [\&#39;personal_access\&#39;, \&#39;password\&#39;]
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminOauthclientsGet(per_page?: number, page?: number, type?: 'personal_access' | 'password', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesAuthClientListHttpResponse>;
    public apiAdminOauthclientsGet(per_page?: number, page?: number, type?: 'personal_access' | 'password', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesAuthClientListHttpResponse>>;
    public apiAdminOauthclientsGet(per_page?: number, page?: number, type?: 'personal_access' | 'password', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesAuthClientListHttpResponse>>;
    public apiAdminOauthclientsGet(per_page?: number, page?: number, type?: 'personal_access' | 'password', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/oauthclients`;
        return this.httpClient.request<AppHttpResponsesAuthClientListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all User Permissions
     * Get all User Permissions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminPermissionsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserPermissionListHttpResponse>;
    public apiAdminPermissionsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserPermissionListHttpResponse>>;
    public apiAdminPermissionsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserPermissionListHttpResponse>>;
    public apiAdminPermissionsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/permissions`;
        return this.httpClient.request<AppHttpResponsesUsersUserPermissionListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a User Permission
     * @param app_http_requests_users_user_permission_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminPermissionsPost(app_http_requests_users_user_permission_create_request: AppHttpRequestsUsersUserPermissionCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserPermissionHttpResponse>;
    public apiAdminPermissionsPost(app_http_requests_users_user_permission_create_request: AppHttpRequestsUsersUserPermissionCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserPermissionHttpResponse>>;
    public apiAdminPermissionsPost(app_http_requests_users_user_permission_create_request: AppHttpRequestsUsersUserPermissionCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserPermissionHttpResponse>>;
    public apiAdminPermissionsPost(app_http_requests_users_user_permission_create_request: AppHttpRequestsUsersUserPermissionCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_user_permission_create_request === null || app_http_requests_users_user_permission_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_permission_create_request was null or undefined when calling apiAdminPermissionsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/permissions`;
        return this.httpClient.request<AppHttpResponsesUsersUserPermissionHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_permission_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Prize Categories
     * Get all prize categories
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminPrizecategoriesGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryListHttpResponse>;
    public apiAdminPrizecategoriesGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryListHttpResponse>>;
    public apiAdminPrizecategoriesGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryListHttpResponse>>;
    public apiAdminPrizecategoriesGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/prizecategories`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresPrizeCategoryListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Prize Types
     * Get all prize types
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminPrizetypesGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeListHttpResponse>;
    public apiAdminPrizetypesGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeListHttpResponse>>;
    public apiAdminPrizetypesGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeListHttpResponse>>;
    public apiAdminPrizetypesGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/prizetypes`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresPrizeTypeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all User Roles
     * Get all User Roles
     * @param permission_name Filter by Permission Name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRolesGet(permission_name?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserRoleListHttpResponse>;
    public apiAdminRolesGet(permission_name?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserRoleListHttpResponse>>;
    public apiAdminRolesGet(permission_name?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserRoleListHttpResponse>>;
    public apiAdminRolesGet(permission_name?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (permission_name !== undefined && permission_name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>permission_name, 'permission_name');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/roles`;
        return this.httpClient.request<AppHttpResponsesUsersUserRoleListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a User Role
     * @param app_http_requests_users_user_role_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRolesPost(app_http_requests_users_user_role_create_request: AppHttpRequestsUsersUserRoleCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserRoleHttpResponse>;
    public apiAdminRolesPost(app_http_requests_users_user_role_create_request: AppHttpRequestsUsersUserRoleCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserRoleHttpResponse>>;
    public apiAdminRolesPost(app_http_requests_users_user_role_create_request: AppHttpRequestsUsersUserRoleCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserRoleHttpResponse>>;
    public apiAdminRolesPost(app_http_requests_users_user_role_create_request: AppHttpRequestsUsersUserRoleCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_users_user_role_create_request === null || app_http_requests_users_user_role_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_role_create_request was null or undefined when calling apiAdminRolesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/roles`;
        return this.httpClient.request<AppHttpResponsesUsersUserRoleHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_role_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Revoke permission from User Role
     * @param role_id Role Id
     * @param permission_id Permission Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRolesRoleIdPermissionsPermissionIdDelete(role_id: number, permission_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminRolesRoleIdPermissionsPermissionIdDelete(role_id: number, permission_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminRolesRoleIdPermissionsPermissionIdDelete(role_id: number, permission_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminRolesRoleIdPermissionsPermissionIdDelete(role_id: number, permission_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (role_id === null || role_id === undefined) {
            throw new Error('Required parameter role_id was null or undefined when calling apiAdminRolesRoleIdPermissionsPermissionIdDelete.');
        }
        if (permission_id === null || permission_id === undefined) {
            throw new Error('Required parameter permission_id was null or undefined when calling apiAdminRolesRoleIdPermissionsPermissionIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/roles/${this.configuration.encodeParam({name: "role_id", value: role_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/permissions/${this.configuration.encodeParam({name: "permission_id", value: permission_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Give permission to User Role
     * @param role_id Role Id
     * @param app_http_requests_users_user_role_add_permissions_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRolesRoleIdPermissionsPost(role_id: number, app_http_requests_users_user_role_add_permissions_request: AppHttpRequestsUsersUserRoleAddPermissionsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminRolesRoleIdPermissionsPost(role_id: number, app_http_requests_users_user_role_add_permissions_request: AppHttpRequestsUsersUserRoleAddPermissionsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminRolesRoleIdPermissionsPost(role_id: number, app_http_requests_users_user_role_add_permissions_request: AppHttpRequestsUsersUserRoleAddPermissionsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminRolesRoleIdPermissionsPost(role_id: number, app_http_requests_users_user_role_add_permissions_request: AppHttpRequestsUsersUserRoleAddPermissionsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (role_id === null || role_id === undefined) {
            throw new Error('Required parameter role_id was null or undefined when calling apiAdminRolesRoleIdPermissionsPost.');
        }
        if (app_http_requests_users_user_role_add_permissions_request === null || app_http_requests_users_user_role_add_permissions_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_role_add_permissions_request was null or undefined when calling apiAdminRolesRoleIdPermissionsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/roles/${this.configuration.encodeParam({name: "role_id", value: role_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/permissions`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_role_add_permissions_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tournaments admin
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param sport_ids Filter Sport Ids (comma separated)
     * @param start_date Filter Tournament Start-Date
     * @param end_date Filter Tournament End-Date
     * @param tournament_ids Filter Tournament Ids (comma separated)
     * @param active Active filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataTournamentListHttpResponse>;
    public apiAdminSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataTournamentListHttpResponse>>;
    public apiAdminSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataTournamentListHttpResponse>>;
    public apiAdminSportsdataTournamentsGet(per_page?: number, page?: number, sport_ids?: string, start_date?: string, end_date?: string, tournament_ids?: string, active?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }
        if (tournament_ids !== undefined && tournament_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tournament_ids, 'tournament_ids');
        }
        if (active !== undefined && active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>active, 'active');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/sportsdata/tournaments`;
        return this.httpClient.request<AppHttpResponsesSportsDataTournamentListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tenant clone options
     * Get all tenant clone options
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantCloneOptionsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantCloneOptionListHttpResponse>;
    public apiAdminTenantCloneOptionsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantCloneOptionListHttpResponse>>;
    public apiAdminTenantCloneOptionsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantCloneOptionListHttpResponse>>;
    public apiAdminTenantCloneOptionsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-clone-options`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantCloneOptionListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tenant image types
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantImageTypesGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantImageTypeListHttpResponse>;
    public apiAdminTenantImageTypesGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantImageTypeListHttpResponse>>;
    public apiAdminTenantImageTypesGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantImageTypeListHttpResponse>>;
    public apiAdminTenantImageTypesGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-image-types`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantImageTypeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Prize-Structures Templates
     * Get all Prize Structure Templates By Tenant_Ids
     * @param tenant_ids Tenant-IDs
     * @param is_active Is active
     * @param is_battle_royal Is Battle Royal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantPrizestructuretemplatesGet(tenant_ids: string, is_active?: boolean, is_battle_royal?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>;
    public apiAdminTenantPrizestructuretemplatesGet(tenant_ids: string, is_active?: boolean, is_battle_royal?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>>;
    public apiAdminTenantPrizestructuretemplatesGet(tenant_ids: string, is_active?: boolean, is_battle_royal?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>>;
    public apiAdminTenantPrizestructuretemplatesGet(tenant_ids: string, is_active?: boolean, is_battle_royal?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_ids === null || tenant_ids === undefined) {
            throw new Error('Required parameter tenant_ids was null or undefined when calling apiAdminTenantPrizestructuretemplatesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }
        if (is_active !== undefined && is_active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_active, 'is_active');
        }
        if (is_battle_royal !== undefined && is_battle_royal !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_battle_royal, 'is_battle_royal');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-prizestructuretemplates`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all shared tenant markets
     * Get all shared tenant markets
     * @param tenant_ids Tenant IDs
     * @param is_simple_snap_capable Is simple snap capable
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantSharedMarketsGet(tenant_ids: string, is_simple_snap_capable?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMarketListHttpResponse>;
    public apiAdminTenantSharedMarketsGet(tenant_ids: string, is_simple_snap_capable?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMarketListHttpResponse>>;
    public apiAdminTenantSharedMarketsGet(tenant_ids: string, is_simple_snap_capable?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMarketListHttpResponse>>;
    public apiAdminTenantSharedMarketsGet(tenant_ids: string, is_simple_snap_capable?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_ids === null || tenant_ids === undefined) {
            throw new Error('Required parameter tenant_ids was null or undefined when calling apiAdminTenantSharedMarketsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }
        if (is_simple_snap_capable !== undefined && is_simple_snap_capable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_simple_snap_capable, 'is_simple_snap_capable');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-shared-markets`;
        return this.httpClient.request<AppHttpResponsesSportsDataMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  Get all shared tenant matches
     * Get all shared tenant matches
     * @param tenant_ids Tenant-IDs
     * @param sport_ids Sport Ids
     * @param search_terms Search Terms
     * @param start_date Filter BetSnap Start-Date
     * @param end_date Filter BetSnap End-Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantSharedMatchesGet(tenant_ids: string, sport_ids?: string, search_terms?: string, start_date?: string, end_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchListHttpResponse>;
    public apiAdminTenantSharedMatchesGet(tenant_ids: string, sport_ids?: string, search_terms?: string, start_date?: string, end_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchListHttpResponse>>;
    public apiAdminTenantSharedMatchesGet(tenant_ids: string, sport_ids?: string, search_terms?: string, start_date?: string, end_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchListHttpResponse>>;
    public apiAdminTenantSharedMatchesGet(tenant_ids: string, sport_ids?: string, search_terms?: string, start_date?: string, end_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_ids === null || tenant_ids === undefined) {
            throw new Error('Required parameter tenant_ids was null or undefined when calling apiAdminTenantSharedMatchesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }
        if (search_terms !== undefined && search_terms !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search_terms, 'search_terms');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-shared-matches`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all shared tenant sports
     * Get all shared tenant sports
     * @param tenant_ids Tenant-IDs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantSharedSportsGet(tenant_ids: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataSportListHttpResponse>;
    public apiAdminTenantSharedSportsGet(tenant_ids: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataSportListHttpResponse>>;
    public apiAdminTenantSharedSportsGet(tenant_ids: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataSportListHttpResponse>>;
    public apiAdminTenantSharedSportsGet(tenant_ids: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_ids === null || tenant_ids === undefined) {
            throw new Error('Required parameter tenant_ids was null or undefined when calling apiAdminTenantSharedSportsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-shared-sports`;
        return this.httpClient.request<AppHttpResponsesSportsDataSportListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all shared tenant vendor-games
     * Get all shared tenant vendor-games
     * @param tenant_ids Tenant-IDs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantSharedVendorGamesGet(tenant_ids: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesVendorGameListHttpResponse>;
    public apiAdminTenantSharedVendorGamesGet(tenant_ids: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesVendorGameListHttpResponse>>;
    public apiAdminTenantSharedVendorGamesGet(tenant_ids: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesVendorGameListHttpResponse>>;
    public apiAdminTenantSharedVendorGamesGet(tenant_ids: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_ids === null || tenant_ids === undefined) {
            throw new Error('Required parameter tenant_ids was null or undefined when calling apiAdminTenantSharedVendorGamesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-shared-vendor-games`;
        return this.httpClient.request<AppHttpResponsesVendorGamesVendorGameListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tenant tags
     * Get all tenant tags
     * @param tenant_ids Tenant-IDs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantTagsGet(tenant_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantTagListHttpResponse>;
    public apiAdminTenantTagsGet(tenant_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantTagListHttpResponse>>;
    public apiAdminTenantTagsGet(tenant_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantTagListHttpResponse>>;
    public apiAdminTenantTagsGet(tenant_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_ids !== undefined && tenant_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_ids, 'tenant_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenant-tags`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantTagListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenants for Admin
     * Get all Tenants for Admin
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantListHttpResponse>;
    public apiAdminTenantsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantListHttpResponse>>;
    public apiAdminTenantsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantListHttpResponse>>;
    public apiAdminTenantsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create tenant
     * Create tenant
     * @param app_http_requests_tenants_tenant_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsPost(app_http_requests_tenants_tenant_create_request: AppHttpRequestsTenantsTenantCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsPost(app_http_requests_tenants_tenant_create_request: AppHttpRequestsTenantsTenantCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsPost(app_http_requests_tenants_tenant_create_request: AppHttpRequestsTenantsTenantCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsPost(app_http_requests_tenants_tenant_create_request: AppHttpRequestsTenantsTenantCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_tenants_tenant_create_request === null || app_http_requests_tenants_tenant_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_create_request was null or undefined when calling apiAdminTenantsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenants advertisements
     * Get all tenants advertisements
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdAdvertisementsGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>;
    public apiAdminTenantsTenantIdAdvertisementsGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/advertisements`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Advertisement
     * Create Tenant Advertisement
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_advertisement_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdAdvertisementsPost(tenant_id: number, app_http_requests_tenants_tenant_advertisement_create_request: AppHttpRequestsTenantsTenantAdvertisementCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdAdvertisementsPost(tenant_id: number, app_http_requests_tenants_tenant_advertisement_create_request: AppHttpRequestsTenantsTenantAdvertisementCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdAdvertisementsPost(tenant_id: number, app_http_requests_tenants_tenant_advertisement_create_request: AppHttpRequestsTenantsTenantAdvertisementCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdAdvertisementsPost(tenant_id: number, app_http_requests_tenants_tenant_advertisement_create_request: AppHttpRequestsTenantsTenantAdvertisementCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsPost.');
        }
        if (app_http_requests_tenants_tenant_advertisement_create_request === null || app_http_requests_tenants_tenant_advertisement_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_advertisement_create_request was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/advertisements`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_advertisement_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant advertisement
     * Delete tenant advertisement
     * @param tenant_id Tenant-ID
     * @param tenant_advertisement_id Tenant Advertisement ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdDelete(tenant_id: number, tenant_advertisement_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdDelete(tenant_id: number, tenant_advertisement_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdDelete(tenant_id: number, tenant_advertisement_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdDelete(tenant_id: number, tenant_advertisement_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdDelete.');
        }
        if (tenant_advertisement_id === null || tenant_advertisement_id === undefined) {
            throw new Error('Required parameter tenant_advertisement_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/advertisements/${this.configuration.encodeParam({name: "tenant_advertisement_id", value: tenant_advertisement_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get specific Tenant Advertisement
     * Find Tenant Advertisement
     * @param tenant_id Tenant-ID
     * @param tenant_advertisement_id Tenant Advertisement ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdGet(tenant_id: number, tenant_advertisement_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdGet(tenant_id: number, tenant_advertisement_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdGet(tenant_id: number, tenant_advertisement_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdGet(tenant_id: number, tenant_advertisement_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdGet.');
        }
        if (tenant_advertisement_id === null || tenant_advertisement_id === undefined) {
            throw new Error('Required parameter tenant_advertisement_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/advertisements/${this.configuration.encodeParam({name: "tenant_advertisement_id", value: tenant_advertisement_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Advertisement Media Translations
     * Update Tenant Advertisement Media Translations
     * @param tenant_id Tenant-ID
     * @param tenant_advertisement_id Tenant Advertisement ID
     * @param app_http_requests_general_media_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdMediaTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdMediaTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdMediaTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdMediaTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdMediaTranslationPut.');
        }
        if (tenant_advertisement_id === null || tenant_advertisement_id === undefined) {
            throw new Error('Required parameter tenant_advertisement_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdMediaTranslationPut.');
        }
        if (app_http_requests_general_media_translations_update_request === null || app_http_requests_general_media_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_general_media_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdMediaTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/advertisements/${this.configuration.encodeParam({name: "tenant_advertisement_id", value: tenant_advertisement_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media-translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_general_media_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Advertisement
     * Update Tenant Advertisement
     * @param tenant_id Tenant-ID
     * @param tenant_advertisement_id Tenant Advertisement ID
     * @param app_http_requests_tenants_tenant_advertisement_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_update_request: AppHttpRequestsTenantsTenantAdvertisementUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_update_request: AppHttpRequestsTenantsTenantAdvertisementUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_update_request: AppHttpRequestsTenantsTenantAdvertisementUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_update_request: AppHttpRequestsTenantsTenantAdvertisementUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdPut.');
        }
        if (tenant_advertisement_id === null || tenant_advertisement_id === undefined) {
            throw new Error('Required parameter tenant_advertisement_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdPut.');
        }
        if (app_http_requests_tenants_tenant_advertisement_update_request === null || app_http_requests_tenants_tenant_advertisement_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_advertisement_update_request was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/advertisements/${this.configuration.encodeParam({name: "tenant_advertisement_id", value: tenant_advertisement_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_advertisement_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Advertisement Translations
     * Update Tenant Advertisement Translations
     * @param tenant_id Tenant-ID
     * @param tenant_advertisement_id Tenant Advertisement ID
     * @param app_http_requests_tenants_tenant_advertisement_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_translations_update_request: AppHttpRequestsTenantsTenantAdvertisementTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_translations_update_request: AppHttpRequestsTenantsTenantAdvertisementTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_translations_update_request: AppHttpRequestsTenantsTenantAdvertisementTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>>;
    public apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdTranslationPut(tenant_id: number, tenant_advertisement_id: number, app_http_requests_tenants_tenant_advertisement_translations_update_request: AppHttpRequestsTenantsTenantAdvertisementTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdTranslationPut.');
        }
        if (tenant_advertisement_id === null || tenant_advertisement_id === undefined) {
            throw new Error('Required parameter tenant_advertisement_id was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_advertisement_translations_update_request === null || app_http_requests_tenants_tenant_advertisement_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_advertisement_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdAdvertisementsTenantAdvertisementIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/advertisements/${this.configuration.encodeParam({name: "tenant_advertisement_id", value: tenant_advertisement_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantAdvertisementHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_advertisement_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update B2B connection
     * Update B2B connection
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_b2b_connection_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdB2bConnectionPut(tenant_id: number, app_http_requests_tenants_tenant_b2b_connection_update_request: AppHttpRequestsTenantsTenantB2bConnectionUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdB2bConnectionPut(tenant_id: number, app_http_requests_tenants_tenant_b2b_connection_update_request: AppHttpRequestsTenantsTenantB2bConnectionUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdB2bConnectionPut(tenant_id: number, app_http_requests_tenants_tenant_b2b_connection_update_request: AppHttpRequestsTenantsTenantB2bConnectionUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdB2bConnectionPut(tenant_id: number, app_http_requests_tenants_tenant_b2b_connection_update_request: AppHttpRequestsTenantsTenantB2bConnectionUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdB2bConnectionPut.');
        }
        if (app_http_requests_tenants_tenant_b2b_connection_update_request === null || app_http_requests_tenants_tenant_b2b_connection_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_b2b_connection_update_request was null or undefined when calling apiAdminTenantsTenantIdB2bConnectionPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/b2b-connection`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_b2b_connection_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant basic settings
     * Update tenant basic settings
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_basic_settings_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdBasicSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_basic_settings_update_request: AppHttpRequestsTenantsTenantBasicSettingsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdBasicSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_basic_settings_update_request: AppHttpRequestsTenantsTenantBasicSettingsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdBasicSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_basic_settings_update_request: AppHttpRequestsTenantsTenantBasicSettingsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdBasicSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_basic_settings_update_request: AppHttpRequestsTenantsTenantBasicSettingsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdBasicSettingsPut.');
        }
        if (app_http_requests_tenants_tenant_basic_settings_update_request === null || app_http_requests_tenants_tenant_basic_settings_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_basic_settings_update_request was null or undefined when calling apiAdminTenantsTenantIdBasicSettingsPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/basic-settings`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_basic_settings_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update chat settings
     * Update chat settings
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_chat_settings_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdChatSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_chat_settings_update_request: AppHttpRequestsTenantsTenantChatSettingsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdChatSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_chat_settings_update_request: AppHttpRequestsTenantsTenantChatSettingsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdChatSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_chat_settings_update_request: AppHttpRequestsTenantsTenantChatSettingsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdChatSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_chat_settings_update_request: AppHttpRequestsTenantsTenantChatSettingsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdChatSettingsPut.');
        }
        if (app_http_requests_tenants_tenant_chat_settings_update_request === null || app_http_requests_tenants_tenant_chat_settings_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_chat_settings_update_request was null or undefined when calling apiAdminTenantsTenantIdChatSettingsPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/chat-settings`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_chat_settings_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all cms entries for a tenant
     * Get all cms entries for a tenant
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdCmsGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantCMSListHttpResponse>;
    public apiAdminTenantsTenantIdCmsGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantCMSListHttpResponse>>;
    public apiAdminTenantsTenantIdCmsGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantCMSListHttpResponse>>;
    public apiAdminTenantsTenantIdCmsGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdCmsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cms`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantCMSListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new CMS Entry
     * Create a new CMS Entry
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_cms_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdCmsPost(tenant_id: number, app_http_requests_tenants_tenant_cms_create_request: AppHttpRequestsTenantsTenantCMSCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdCmsPost(tenant_id: number, app_http_requests_tenants_tenant_cms_create_request: AppHttpRequestsTenantsTenantCMSCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdCmsPost(tenant_id: number, app_http_requests_tenants_tenant_cms_create_request: AppHttpRequestsTenantsTenantCMSCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdCmsPost(tenant_id: number, app_http_requests_tenants_tenant_cms_create_request: AppHttpRequestsTenantsTenantCMSCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdCmsPost.');
        }
        if (app_http_requests_tenants_tenant_cms_create_request === null || app_http_requests_tenants_tenant_cms_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_cms_create_request was null or undefined when calling apiAdminTenantsTenantIdCmsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cms`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_cms_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a CMS Entry
     * Delete a CMS Entry
     * @param tenant_id Tenant-ID
     * @param tenant_cms_id Tenant-CMS-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdCmsTenantCmsIdDelete(tenant_id: number, tenant_cms_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdDelete(tenant_id: number, tenant_cms_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdDelete(tenant_id: number, tenant_cms_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdDelete(tenant_id: number, tenant_cms_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdDelete.');
        }
        if (tenant_cms_id === null || tenant_cms_id === undefined) {
            throw new Error('Required parameter tenant_cms_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cms/${this.configuration.encodeParam({name: "tenant_cms_id", value: tenant_cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a specific tenant cms entry
     * Get a specific tenant cms entry
     * @param tenant_id Tenant-ID
     * @param tenant_cms_id Tenant-CMS-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdCmsTenantCmsIdGet(tenant_id: number, tenant_cms_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantCMSHttpResponse>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdGet(tenant_id: number, tenant_cms_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdGet(tenant_id: number, tenant_cms_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdGet(tenant_id: number, tenant_cms_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdGet.');
        }
        if (tenant_cms_id === null || tenant_cms_id === undefined) {
            throw new Error('Required parameter tenant_cms_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cms/${this.configuration.encodeParam({name: "tenant_cms_id", value: tenant_cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantCMSHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant CMS Media Translations
     * Update Tenant CMS Media Translations
     * @param tenant_id Tenant-ID
     * @param tenant_cms_id Tenant-CMS-ID
     * @param app_http_requests_tenants_tenant_cms_media_translation_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdCmsTenantCmsIdMediaTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_media_translation_update_request: AppHttpRequestsTenantsTenantCMSMediaTranslationUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantCMSHttpResponse>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdMediaTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_media_translation_update_request: AppHttpRequestsTenantsTenantCMSMediaTranslationUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdMediaTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_media_translation_update_request: AppHttpRequestsTenantsTenantCMSMediaTranslationUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdMediaTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_media_translation_update_request: AppHttpRequestsTenantsTenantCMSMediaTranslationUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdMediaTranslationPut.');
        }
        if (tenant_cms_id === null || tenant_cms_id === undefined) {
            throw new Error('Required parameter tenant_cms_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdMediaTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_cms_media_translation_update_request === null || app_http_requests_tenants_tenant_cms_media_translation_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_cms_media_translation_update_request was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdMediaTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cms/${this.configuration.encodeParam({name: "tenant_cms_id", value: tenant_cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media-translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantCMSHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_cms_media_translation_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a CMS Entry
     * Update a CMS Entry
     * @param tenant_id Tenant-ID
     * @param tenant_cms_id CMS-ID
     * @param app_http_requests_tenants_tenant_cms_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdCmsTenantCmsIdPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_update_request: AppHttpRequestsTenantsTenantCMSUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantCMSHttpResponse>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_update_request: AppHttpRequestsTenantsTenantCMSUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_update_request: AppHttpRequestsTenantsTenantCMSUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_update_request: AppHttpRequestsTenantsTenantCMSUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdPut.');
        }
        if (tenant_cms_id === null || tenant_cms_id === undefined) {
            throw new Error('Required parameter tenant_cms_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdPut.');
        }
        if (app_http_requests_tenants_tenant_cms_update_request === null || app_http_requests_tenants_tenant_cms_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_cms_update_request was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cms/${this.configuration.encodeParam({name: "tenant_cms_id", value: tenant_cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantCMSHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_cms_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant CMS Translations
     * Update Tenant CMS Translation
     * @param tenant_id Tenant-ID
     * @param tenant_cms_id Tenant-CMS-ID
     * @param app_http_requests_tenants_tenant_cms_translation_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdCmsTenantCmsIdTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_translation_update_request: AppHttpRequestsTenantsTenantCMSTranslationUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantCMSHttpResponse>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_translation_update_request: AppHttpRequestsTenantsTenantCMSTranslationUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_translation_update_request: AppHttpRequestsTenantsTenantCMSTranslationUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantCMSHttpResponse>>;
    public apiAdminTenantsTenantIdCmsTenantCmsIdTranslationPut(tenant_id: number, tenant_cms_id: number, app_http_requests_tenants_tenant_cms_translation_update_request: AppHttpRequestsTenantsTenantCMSTranslationUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdTranslationPut.');
        }
        if (tenant_cms_id === null || tenant_cms_id === undefined) {
            throw new Error('Required parameter tenant_cms_id was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_cms_translation_update_request === null || app_http_requests_tenants_tenant_cms_translation_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_cms_translation_update_request was null or undefined when calling apiAdminTenantsTenantIdCmsTenantCmsIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/cms/${this.configuration.encodeParam({name: "tenant_cms_id", value: tenant_cms_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantCMSHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_cms_translation_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create tenant external script
     * Create Tenant External Script
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_external_script_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdExternalscriptsPost(tenant_id: number, app_http_requests_tenants_tenant_external_script_create_request: AppHttpRequestsTenantsTenantExternalScriptCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdExternalscriptsPost(tenant_id: number, app_http_requests_tenants_tenant_external_script_create_request: AppHttpRequestsTenantsTenantExternalScriptCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdExternalscriptsPost(tenant_id: number, app_http_requests_tenants_tenant_external_script_create_request: AppHttpRequestsTenantsTenantExternalScriptCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdExternalscriptsPost(tenant_id: number, app_http_requests_tenants_tenant_external_script_create_request: AppHttpRequestsTenantsTenantExternalScriptCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsPost.');
        }
        if (app_http_requests_tenants_tenant_external_script_create_request === null || app_http_requests_tenants_tenant_external_script_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_external_script_create_request was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/externalscripts`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_external_script_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant external script
     * Delete tenant external script
     * @param tenant_id Tenant-ID
     * @param tenant_external_script_id Tenant External Script ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdDelete(tenant_id: number, tenant_external_script_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdDelete(tenant_id: number, tenant_external_script_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdDelete(tenant_id: number, tenant_external_script_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdDelete(tenant_id: number, tenant_external_script_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdDelete.');
        }
        if (tenant_external_script_id === null || tenant_external_script_id === undefined) {
            throw new Error('Required parameter tenant_external_script_id was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/externalscripts/${this.configuration.encodeParam({name: "tenant_external_script_id", value: tenant_external_script_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get specific tenant external script
     * Find Tenant External Script
     * @param tenant_id Tenant-ID
     * @param tenant_external_script_id Tenant External Script ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdGet(tenant_id: number, tenant_external_script_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdGet(tenant_id: number, tenant_external_script_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdGet(tenant_id: number, tenant_external_script_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdGet(tenant_id: number, tenant_external_script_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdGet.');
        }
        if (tenant_external_script_id === null || tenant_external_script_id === undefined) {
            throw new Error('Required parameter tenant_external_script_id was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/externalscripts/${this.configuration.encodeParam({name: "tenant_external_script_id", value: tenant_external_script_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant external script
     * Update Tenant External Script
     * @param tenant_id Tenant-ID
     * @param tenant_external_script_id Tenant External Script ID
     * @param app_http_requests_tenants_tenant_external_script_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdPut(tenant_id: number, tenant_external_script_id: number, app_http_requests_tenants_tenant_external_script_update_request: AppHttpRequestsTenantsTenantExternalScriptUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdPut(tenant_id: number, tenant_external_script_id: number, app_http_requests_tenants_tenant_external_script_update_request: AppHttpRequestsTenantsTenantExternalScriptUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdPut(tenant_id: number, tenant_external_script_id: number, app_http_requests_tenants_tenant_external_script_update_request: AppHttpRequestsTenantsTenantExternalScriptUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>>;
    public apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdPut(tenant_id: number, tenant_external_script_id: number, app_http_requests_tenants_tenant_external_script_update_request: AppHttpRequestsTenantsTenantExternalScriptUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdPut.');
        }
        if (tenant_external_script_id === null || tenant_external_script_id === undefined) {
            throw new Error('Required parameter tenant_external_script_id was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdPut.');
        }
        if (app_http_requests_tenants_tenant_external_script_update_request === null || app_http_requests_tenants_tenant_external_script_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_external_script_update_request was null or undefined when calling apiAdminTenantsTenantIdExternalscriptsTenantExternalScriptIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/externalscripts/${this.configuration.encodeParam({name: "tenant_external_script_id", value: tenant_external_script_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantExternalScriptHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_external_script_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant financials
     * Update tenant financials settings
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_financials_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFinancialsPut(tenant_id: number, app_http_requests_tenants_tenant_financials_update_request: AppHttpRequestsTenantsTenantFinancialsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdFinancialsPut(tenant_id: number, app_http_requests_tenants_tenant_financials_update_request: AppHttpRequestsTenantsTenantFinancialsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdFinancialsPut(tenant_id: number, app_http_requests_tenants_tenant_financials_update_request: AppHttpRequestsTenantsTenantFinancialsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdFinancialsPut(tenant_id: number, app_http_requests_tenants_tenant_financials_update_request: AppHttpRequestsTenantsTenantFinancialsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFinancialsPut.');
        }
        if (app_http_requests_tenants_tenant_financials_update_request === null || app_http_requests_tenants_tenant_financials_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_financials_update_request was null or undefined when calling apiAdminTenantsTenantIdFinancialsPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/financials`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_financials_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenants-Footers
     * Get all Tenant Footers
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFootersGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantFooterListHttpResponse>;
    public apiAdminTenantsTenantIdFootersGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantFooterListHttpResponse>>;
    public apiAdminTenantsTenantIdFootersGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantFooterListHttpResponse>>;
    public apiAdminTenantsTenantIdFootersGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFootersGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantFooterListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Footer
     * Create Tenant Footer
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_footer_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFootersPost(tenant_id: number, app_http_requests_tenants_tenant_footer_create_request: AppHttpRequestsTenantsTenantFooterCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdFootersPost(tenant_id: number, app_http_requests_tenants_tenant_footer_create_request: AppHttpRequestsTenantsTenantFooterCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdFootersPost(tenant_id: number, app_http_requests_tenants_tenant_footer_create_request: AppHttpRequestsTenantsTenantFooterCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdFootersPost(tenant_id: number, app_http_requests_tenants_tenant_footer_create_request: AppHttpRequestsTenantsTenantFooterCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFootersPost.');
        }
        if (app_http_requests_tenants_tenant_footer_create_request === null || app_http_requests_tenants_tenant_footer_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_footer_create_request was null or undefined when calling apiAdminTenantsTenantIdFootersPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_footer_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant footer
     * Delete tenant footer
     * @param tenant_id Tenant-ID
     * @param tenant_footer_id Tenant Footer ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFootersTenantFooterIdDelete(tenant_id: number, tenant_footer_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdDelete(tenant_id: number, tenant_footer_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdDelete(tenant_id: number, tenant_footer_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdDelete(tenant_id: number, tenant_footer_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdDelete.');
        }
        if (tenant_footer_id === null || tenant_footer_id === undefined) {
            throw new Error('Required parameter tenant_footer_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers/${this.configuration.encodeParam({name: "tenant_footer_id", value: tenant_footer_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get specific Tenant Footer
     * Find Tenant Footer
     * @param tenant_id Tenant-ID
     * @param tenant_footer_id Tenant Footer ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFootersTenantFooterIdGet(tenant_id: number, tenant_footer_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantFooterHttpResponse>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdGet(tenant_id: number, tenant_footer_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdGet(tenant_id: number, tenant_footer_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdGet(tenant_id: number, tenant_footer_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdGet.');
        }
        if (tenant_footer_id === null || tenant_footer_id === undefined) {
            throw new Error('Required parameter tenant_footer_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers/${this.configuration.encodeParam({name: "tenant_footer_id", value: tenant_footer_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantFooterHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Tenant Footer Order
     * Change Tenant Footer Order
     * @param tenant_id Tenant-ID
     * @param tenant_footer_id Tenant Footer ID
     * @param app_http_requests_tenants_tenant_footer_change_order_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFootersTenantFooterIdOrderPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_change_order_request: AppHttpRequestsTenantsTenantFooterChangeOrderRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantFooterHttpResponse>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdOrderPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_change_order_request: AppHttpRequestsTenantsTenantFooterChangeOrderRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdOrderPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_change_order_request: AppHttpRequestsTenantsTenantFooterChangeOrderRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdOrderPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_change_order_request: AppHttpRequestsTenantsTenantFooterChangeOrderRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdOrderPut.');
        }
        if (tenant_footer_id === null || tenant_footer_id === undefined) {
            throw new Error('Required parameter tenant_footer_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdOrderPut.');
        }
        if (app_http_requests_tenants_tenant_footer_change_order_request === null || app_http_requests_tenants_tenant_footer_change_order_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_footer_change_order_request was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdOrderPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers/${this.configuration.encodeParam({name: "tenant_footer_id", value: tenant_footer_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/order`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantFooterHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_footer_change_order_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Footer
     * Update Tenant Footer
     * @param tenant_id Tenant-ID
     * @param tenant_footer_id Tenant Footer ID
     * @param app_http_requests_tenants_tenant_footer_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFootersTenantFooterIdPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_update_request: AppHttpRequestsTenantsTenantFooterUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantFooterHttpResponse>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_update_request: AppHttpRequestsTenantsTenantFooterUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_update_request: AppHttpRequestsTenantsTenantFooterUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_update_request: AppHttpRequestsTenantsTenantFooterUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdPut.');
        }
        if (tenant_footer_id === null || tenant_footer_id === undefined) {
            throw new Error('Required parameter tenant_footer_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdPut.');
        }
        if (app_http_requests_tenants_tenant_footer_update_request === null || app_http_requests_tenants_tenant_footer_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_footer_update_request was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers/${this.configuration.encodeParam({name: "tenant_footer_id", value: tenant_footer_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantFooterHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_footer_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Footer Translations
     * Update Tenant Footer Translations
     * @param tenant_id Tenant-ID
     * @param tenant_footer_id Tenant Footer ID
     * @param app_http_requests_tenants_tenant_footer_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdFootersTenantFooterIdTranslationPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_translations_update_request: AppHttpRequestsTenantsTenantFooterTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantFooterHttpResponse>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdTranslationPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_translations_update_request: AppHttpRequestsTenantsTenantFooterTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdTranslationPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_translations_update_request: AppHttpRequestsTenantsTenantFooterTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantFooterHttpResponse>>;
    public apiAdminTenantsTenantIdFootersTenantFooterIdTranslationPut(tenant_id: number, tenant_footer_id: number, app_http_requests_tenants_tenant_footer_translations_update_request: AppHttpRequestsTenantsTenantFooterTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdTranslationPut.');
        }
        if (tenant_footer_id === null || tenant_footer_id === undefined) {
            throw new Error('Required parameter tenant_footer_id was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_footer_translations_update_request === null || app_http_requests_tenants_tenant_footer_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_footer_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdFootersTenantFooterIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers/${this.configuration.encodeParam({name: "tenant_footer_id", value: tenant_footer_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantFooterHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_footer_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update game behaviour settings
     * Update game behaviour settings
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_game_behaviour_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdGameBehaviourPut(tenant_id: number, app_http_requests_tenants_tenant_game_behaviour_update_request: AppHttpRequestsTenantsTenantGameBehaviourUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdGameBehaviourPut(tenant_id: number, app_http_requests_tenants_tenant_game_behaviour_update_request: AppHttpRequestsTenantsTenantGameBehaviourUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGameBehaviourPut(tenant_id: number, app_http_requests_tenants_tenant_game_behaviour_update_request: AppHttpRequestsTenantsTenantGameBehaviourUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGameBehaviourPut(tenant_id: number, app_http_requests_tenants_tenant_game_behaviour_update_request: AppHttpRequestsTenantsTenantGameBehaviourUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdGameBehaviourPut.');
        }
        if (app_http_requests_tenants_tenant_game_behaviour_update_request === null || app_http_requests_tenants_tenant_game_behaviour_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_game_behaviour_update_request was null or undefined when calling apiAdminTenantsTenantIdGameBehaviourPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/game-behaviour`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_game_behaviour_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update game features
     * Update game features
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_game_features_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdGameFeaturesPut(tenant_id: number, app_http_requests_tenants_tenant_game_features_update_request: AppHttpRequestsTenantsTenantGameFeaturesUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdGameFeaturesPut(tenant_id: number, app_http_requests_tenants_tenant_game_features_update_request: AppHttpRequestsTenantsTenantGameFeaturesUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGameFeaturesPut(tenant_id: number, app_http_requests_tenants_tenant_game_features_update_request: AppHttpRequestsTenantsTenantGameFeaturesUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGameFeaturesPut(tenant_id: number, app_http_requests_tenants_tenant_game_features_update_request: AppHttpRequestsTenantsTenantGameFeaturesUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdGameFeaturesPut.');
        }
        if (app_http_requests_tenants_tenant_game_features_update_request === null || app_http_requests_tenants_tenant_game_features_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_game_features_update_request was null or undefined when calling apiAdminTenantsTenantIdGameFeaturesPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/game-features`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_game_features_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find tenant game
     * Find tenant game
     * @param tenant_id Tenant-ID
     * @param game_unique_id Game-Unique-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>;
    public apiAdminTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>>;
    public apiAdminTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>>;
    public apiAdminTenantsTenantIdGamesGameUniqueIdGet(tenant_id: number, game_unique_id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdGamesGameUniqueIdGet.');
        }
        if (game_unique_id === null || game_unique_id === undefined) {
            throw new Error('Required parameter game_unique_id was null or undefined when calling apiAdminTenantsTenantIdGamesGameUniqueIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/games/${this.configuration.encodeParam({name: "game_unique_id", value: game_unique_id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsGamesAdminGameHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single tenant for Admin
     * Get a single tenant for Admin
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant GUI and Internationalization settings
     * Update tenant GUI and Internationalization settings
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_gui_internationalization_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdGuiInternationalizationPut(tenant_id: number, app_http_requests_tenants_tenant_gui_internationalization_update_request: AppHttpRequestsTenantsTenantGuiInternationalizationUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdGuiInternationalizationPut(tenant_id: number, app_http_requests_tenants_tenant_gui_internationalization_update_request: AppHttpRequestsTenantsTenantGuiInternationalizationUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGuiInternationalizationPut(tenant_id: number, app_http_requests_tenants_tenant_gui_internationalization_update_request: AppHttpRequestsTenantsTenantGuiInternationalizationUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdGuiInternationalizationPut(tenant_id: number, app_http_requests_tenants_tenant_gui_internationalization_update_request: AppHttpRequestsTenantsTenantGuiInternationalizationUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdGuiInternationalizationPut.');
        }
        if (app_http_requests_tenants_tenant_gui_internationalization_update_request === null || app_http_requests_tenants_tenant_gui_internationalization_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_gui_internationalization_update_request was null or undefined when calling apiAdminTenantsTenantIdGuiInternationalizationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/gui-internationalization`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_gui_internationalization_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Cloneable Master Hints to Tenant Hints
     * Get All Cloneable Master Hints to Tenant Hints
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsCloneableGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesHintsHintListHttpResponse>;
    public apiAdminTenantsTenantIdHintsCloneableGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesHintsHintListHttpResponse>>;
    public apiAdminTenantsTenantIdHintsCloneableGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesHintsHintListHttpResponse>>;
    public apiAdminTenantsTenantIdHintsCloneableGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsCloneableGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints-cloneable`;
        return this.httpClient.request<AppHttpResponsesHintsHintListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Tenant Hints
     * Get All Tenant Hints
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHintListHttpResponse>;
    public apiAdminTenantsTenantIdHintsGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHintListHttpResponse>>;
    public apiAdminTenantsTenantIdHintsGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHintListHttpResponse>>;
    public apiAdminTenantsTenantIdHintsGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHintListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Hint
     * Create Tenant Hint
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_hint_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsPost(tenant_id: number, app_http_requests_tenants_tenant_hint_create_request: AppHttpRequestsTenantsTenantHintCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdHintsPost(tenant_id: number, app_http_requests_tenants_tenant_hint_create_request: AppHttpRequestsTenantsTenantHintCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdHintsPost(tenant_id: number, app_http_requests_tenants_tenant_hint_create_request: AppHttpRequestsTenantsTenantHintCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdHintsPost(tenant_id: number, app_http_requests_tenants_tenant_hint_create_request: AppHttpRequestsTenantsTenantHintCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsPost.');
        }
        if (app_http_requests_tenants_tenant_hint_create_request === null || app_http_requests_tenants_tenant_hint_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_hint_create_request was null or undefined when calling apiAdminTenantsTenantIdHintsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_hint_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tenant Hint
     * Delete Tenant Hint
     * @param tenant_id Tenant-ID
     * @param tenant_hint_id Tenant Hint ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsTenantHintIdDelete(tenant_id: number, tenant_hint_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdHintsTenantHintIdDelete(tenant_id: number, tenant_hint_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdDelete(tenant_id: number, tenant_hint_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdDelete(tenant_id: number, tenant_hint_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdDelete.');
        }
        if (tenant_hint_id === null || tenant_hint_id === undefined) {
            throw new Error('Required parameter tenant_hint_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints/${this.configuration.encodeParam({name: "tenant_hint_id", value: tenant_hint_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Hint
     * Get Tenant Hint
     * @param tenant_id Tenant-ID
     * @param tenant_hint_id Tenant Hint ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsTenantHintIdGet(tenant_id: number, tenant_hint_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHintHttpResponse>;
    public apiAdminTenantsTenantIdHintsTenantHintIdGet(tenant_id: number, tenant_hint_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdGet(tenant_id: number, tenant_hint_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdGet(tenant_id: number, tenant_hint_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdGet.');
        }
        if (tenant_hint_id === null || tenant_hint_id === undefined) {
            throw new Error('Required parameter tenant_hint_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints/${this.configuration.encodeParam({name: "tenant_hint_id", value: tenant_hint_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHintHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Hint Media Translations
     * Update Tenant Hint Media Translations
     * @param tenant_id Tenant-ID
     * @param tenant_hint_id Tenant Hint ID
     * @param app_http_requests_general_media_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsTenantHintIdMediaTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHintHttpResponse>;
    public apiAdminTenantsTenantIdHintsTenantHintIdMediaTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdMediaTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdMediaTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdMediaTranslationPut.');
        }
        if (tenant_hint_id === null || tenant_hint_id === undefined) {
            throw new Error('Required parameter tenant_hint_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdMediaTranslationPut.');
        }
        if (app_http_requests_general_media_translations_update_request === null || app_http_requests_general_media_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_general_media_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdMediaTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints/${this.configuration.encodeParam({name: "tenant_hint_id", value: tenant_hint_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media-translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHintHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_general_media_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Tenant Hint Order
     * Change Tenant Hint Order
     * @param tenant_id Tenant-ID
     * @param tenant_hint_id Tenant Hint ID
     * @param app_http_requests_tenants_tenant_hint_change_order_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsTenantHintIdOrderPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_change_order_request: AppHttpRequestsTenantsTenantHintChangeOrderRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHintHttpResponse>;
    public apiAdminTenantsTenantIdHintsTenantHintIdOrderPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_change_order_request: AppHttpRequestsTenantsTenantHintChangeOrderRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdOrderPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_change_order_request: AppHttpRequestsTenantsTenantHintChangeOrderRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdOrderPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_change_order_request: AppHttpRequestsTenantsTenantHintChangeOrderRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdOrderPut.');
        }
        if (tenant_hint_id === null || tenant_hint_id === undefined) {
            throw new Error('Required parameter tenant_hint_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdOrderPut.');
        }
        if (app_http_requests_tenants_tenant_hint_change_order_request === null || app_http_requests_tenants_tenant_hint_change_order_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_hint_change_order_request was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdOrderPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints/${this.configuration.encodeParam({name: "tenant_hint_id", value: tenant_hint_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/order`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHintHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_hint_change_order_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Hint
     * Update Tenant Hint
     * @param tenant_id Tenant-ID
     * @param tenant_hint_id Tenant Hint ID
     * @param app_http_requests_tenants_tenant_hint_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsTenantHintIdPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_update_request: AppHttpRequestsTenantsTenantHintUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHintHttpResponse>;
    public apiAdminTenantsTenantIdHintsTenantHintIdPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_update_request: AppHttpRequestsTenantsTenantHintUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_update_request: AppHttpRequestsTenantsTenantHintUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_update_request: AppHttpRequestsTenantsTenantHintUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdPut.');
        }
        if (tenant_hint_id === null || tenant_hint_id === undefined) {
            throw new Error('Required parameter tenant_hint_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdPut.');
        }
        if (app_http_requests_tenants_tenant_hint_update_request === null || app_http_requests_tenants_tenant_hint_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_hint_update_request was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints/${this.configuration.encodeParam({name: "tenant_hint_id", value: tenant_hint_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHintHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_hint_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Hint Translations
     * Update Tenant Hint Translations
     * @param tenant_id Tenant-ID
     * @param tenant_hint_id Tenant Hint ID
     * @param app_http_requests_tenants_tenant_hint_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdHintsTenantHintIdTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_translations_update_request: AppHttpRequestsTenantsTenantHintTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHintHttpResponse>;
    public apiAdminTenantsTenantIdHintsTenantHintIdTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_translations_update_request: AppHttpRequestsTenantsTenantHintTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_translations_update_request: AppHttpRequestsTenantsTenantHintTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHintHttpResponse>>;
    public apiAdminTenantsTenantIdHintsTenantHintIdTranslationPut(tenant_id: number, tenant_hint_id: number, app_http_requests_tenants_tenant_hint_translations_update_request: AppHttpRequestsTenantsTenantHintTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdTranslationPut.');
        }
        if (tenant_hint_id === null || tenant_hint_id === undefined) {
            throw new Error('Required parameter tenant_hint_id was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_hint_translations_update_request === null || app_http_requests_tenants_tenant_hint_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_hint_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdHintsTenantHintIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/hints/${this.configuration.encodeParam({name: "tenant_hint_id", value: tenant_hint_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHintHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_hint_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Iamge
     * Create Tenant Image
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_image_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdImagesPost(tenant_id: number, app_http_requests_tenants_tenant_image_create_request: AppHttpRequestsTenantsTenantImageCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdImagesPost(tenant_id: number, app_http_requests_tenants_tenant_image_create_request: AppHttpRequestsTenantsTenantImageCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdImagesPost(tenant_id: number, app_http_requests_tenants_tenant_image_create_request: AppHttpRequestsTenantsTenantImageCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdImagesPost(tenant_id: number, app_http_requests_tenants_tenant_image_create_request: AppHttpRequestsTenantsTenantImageCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdImagesPost.');
        }
        if (app_http_requests_tenants_tenant_image_create_request === null || app_http_requests_tenants_tenant_image_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_image_create_request was null or undefined when calling apiAdminTenantsTenantIdImagesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/images`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_image_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tenant Image
     * Delete Tenant Image
     * @param tenant_id Tenant-ID
     * @param tenant_image_id Tenant Image ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdImagesTenantImageIdDelete(tenant_id: number, tenant_image_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdImagesTenantImageIdDelete(tenant_id: number, tenant_image_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdDelete(tenant_id: number, tenant_image_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdDelete(tenant_id: number, tenant_image_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdDelete.');
        }
        if (tenant_image_id === null || tenant_image_id === undefined) {
            throw new Error('Required parameter tenant_image_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/images/${this.configuration.encodeParam({name: "tenant_image_id", value: tenant_image_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Image
     * Get Tenant Image
     * @param tenant_id Tenant-ID
     * @param tenant_image_id Tenant Image ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdImagesTenantImageIdGet(tenant_id: number, tenant_image_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantImageHttpResponse>;
    public apiAdminTenantsTenantIdImagesTenantImageIdGet(tenant_id: number, tenant_image_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantImageHttpResponse>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdGet(tenant_id: number, tenant_image_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantImageHttpResponse>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdGet(tenant_id: number, tenant_image_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdGet.');
        }
        if (tenant_image_id === null || tenant_image_id === undefined) {
            throw new Error('Required parameter tenant_image_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/images/${this.configuration.encodeParam({name: "tenant_image_id", value: tenant_image_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantImageHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Image Media Translations
     * Update Tenant Image Media Translations
     * @param tenant_id Tenant-ID
     * @param tenant_image_id Tenant Image ID
     * @param app_http_requests_general_media_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdImagesTenantImageIdMediaTranslationPut(tenant_id: number, tenant_image_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantImageHttpResponse>;
    public apiAdminTenantsTenantIdImagesTenantImageIdMediaTranslationPut(tenant_id: number, tenant_image_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantImageHttpResponse>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdMediaTranslationPut(tenant_id: number, tenant_image_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantImageHttpResponse>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdMediaTranslationPut(tenant_id: number, tenant_image_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdMediaTranslationPut.');
        }
        if (tenant_image_id === null || tenant_image_id === undefined) {
            throw new Error('Required parameter tenant_image_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdMediaTranslationPut.');
        }
        if (app_http_requests_general_media_translations_update_request === null || app_http_requests_general_media_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_general_media_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdMediaTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/images/${this.configuration.encodeParam({name: "tenant_image_id", value: tenant_image_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media-translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantImageHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_general_media_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Image
     * Update Tenant Image
     * @param tenant_id Tenant-ID
     * @param tenant_image_id Tenant Image ID
     * @param app_http_requests_tenants_tenant_image_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdImagesTenantImageIdPut(tenant_id: number, tenant_image_id: number, app_http_requests_tenants_tenant_image_update_request: AppHttpRequestsTenantsTenantImageUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantImageHttpResponse>;
    public apiAdminTenantsTenantIdImagesTenantImageIdPut(tenant_id: number, tenant_image_id: number, app_http_requests_tenants_tenant_image_update_request: AppHttpRequestsTenantsTenantImageUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantImageHttpResponse>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdPut(tenant_id: number, tenant_image_id: number, app_http_requests_tenants_tenant_image_update_request: AppHttpRequestsTenantsTenantImageUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantImageHttpResponse>>;
    public apiAdminTenantsTenantIdImagesTenantImageIdPut(tenant_id: number, tenant_image_id: number, app_http_requests_tenants_tenant_image_update_request: AppHttpRequestsTenantsTenantImageUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdPut.');
        }
        if (tenant_image_id === null || tenant_image_id === undefined) {
            throw new Error('Required parameter tenant_image_id was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdPut.');
        }
        if (app_http_requests_tenants_tenant_image_update_request === null || app_http_requests_tenants_tenant_image_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_image_update_request was null or undefined when calling apiAdminTenantsTenantIdImagesTenantImageIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/images/${this.configuration.encodeParam({name: "tenant_image_id", value: tenant_image_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantImageHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_image_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Tenant Landingpages
     * Get all tenant landingpages
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageListHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageListHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageListHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Landingpage
     * Create Tenant Landingpage
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_landingpage_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesPost(tenant_id: number, app_http_requests_tenants_tenant_landingpage_create_request: AppHttpRequestsTenantsTenantLandingpageCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLandingpagesPost(tenant_id: number, app_http_requests_tenants_tenant_landingpage_create_request: AppHttpRequestsTenantsTenantLandingpageCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLandingpagesPost(tenant_id: number, app_http_requests_tenants_tenant_landingpage_create_request: AppHttpRequestsTenantsTenantLandingpageCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLandingpagesPost(tenant_id: number, app_http_requests_tenants_tenant_landingpage_create_request: AppHttpRequestsTenantsTenantLandingpageCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesPost.');
        }
        if (app_http_requests_tenants_tenant_landingpage_create_request === null || app_http_requests_tenants_tenant_landingpage_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_create_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tenant Landingpage
     * Delete Tenant Landingpage
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdDelete(tenant_id: number, tenant_landingpage_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdDelete(tenant_id: number, tenant_landingpage_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdDelete(tenant_id: number, tenant_landingpage_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdDelete(tenant_id: number, tenant_landingpage_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdDelete.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Tenant Landingpage Features
     * Get All Tenant Landingpage Features
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageFeatureListHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageFeatureListHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageFeatureListHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesGet(tenant_id: number, tenant_landingpage_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesGet.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageFeatureListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Landingpage Feature
     * Create Tenant Landingpage Feature
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param app_http_requests_tenants_tenant_landingpage_feature_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_feature_create_request: AppHttpRequestsTenantsTenantLandingpageFeatureCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_feature_create_request: AppHttpRequestsTenantsTenantLandingpageFeatureCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_feature_create_request: AppHttpRequestsTenantsTenantLandingpageFeatureCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_feature_create_request: AppHttpRequestsTenantsTenantLandingpageFeatureCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesPost.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesPost.');
        }
        if (app_http_requests_tenants_tenant_landingpage_feature_create_request === null || app_http_requests_tenants_tenant_landingpage_feature_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_feature_create_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_feature_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Landingpage Feature
     * Delete Landingpage Feature
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_feature_id Tenant Landingpage Feature ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdDelete.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdDelete.');
        }
        if (tenant_landingpage_feature_id === null || tenant_landingpage_feature_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_feature_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features/${this.configuration.encodeParam({name: "tenant_landingpage_feature_id", value: tenant_landingpage_feature_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Landingpage Feature
     * Get Tenant Landingpage Feature
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_feature_id Tenant Landingpage Feature ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdGet.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdGet.');
        }
        if (tenant_landingpage_feature_id === null || tenant_landingpage_feature_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_feature_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features/${this.configuration.encodeParam({name: "tenant_landingpage_feature_id", value: tenant_landingpage_feature_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Landingpage Feature Media Translations
     * Update Tenant Landingpage Feature Media Translations
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_feature_id Tenant Landingpage Feature ID
     * @param app_http_requests_general_media_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_general_media_translations_update_request: AppHttpRequestsGeneralMediaTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut.');
        }
        if (tenant_landingpage_feature_id === null || tenant_landingpage_feature_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_feature_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut.');
        }
        if (app_http_requests_general_media_translations_update_request === null || app_http_requests_general_media_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_general_media_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdMediaTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features/${this.configuration.encodeParam({name: "tenant_landingpage_feature_id", value: tenant_landingpage_feature_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media-translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_general_media_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Tenant Landingpage Feature Order
     * Change Tenant Landingpage Feature Order
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_feature_id Tenant Landingpage Feature ID
     * @param app_http_requests_tenants_tenant_landingpage_feature_change_order_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_change_order_request: AppHttpRequestsTenantsTenantLandingpageFeatureChangeOrderRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_change_order_request: AppHttpRequestsTenantsTenantLandingpageFeatureChangeOrderRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_change_order_request: AppHttpRequestsTenantsTenantLandingpageFeatureChangeOrderRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_change_order_request: AppHttpRequestsTenantsTenantLandingpageFeatureChangeOrderRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut.');
        }
        if (tenant_landingpage_feature_id === null || tenant_landingpage_feature_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_feature_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_feature_change_order_request === null || app_http_requests_tenants_tenant_landingpage_feature_change_order_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_feature_change_order_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdOrderPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features/${this.configuration.encodeParam({name: "tenant_landingpage_feature_id", value: tenant_landingpage_feature_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/order`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_feature_change_order_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Landingpage Feature
     * Update Tenant Landingpage Feature
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_feature_id Tenant Landingpage Feature ID
     * @param app_http_requests_tenants_tenant_landingpage_feature_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut.');
        }
        if (tenant_landingpage_feature_id === null || tenant_landingpage_feature_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_feature_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_feature_update_request === null || app_http_requests_tenants_tenant_landingpage_feature_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_feature_update_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features/${this.configuration.encodeParam({name: "tenant_landingpage_feature_id", value: tenant_landingpage_feature_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_feature_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Landingpage Feature Translations
     * Update Tenant Landingpage Highlight Translations
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Footer ID
     * @param tenant_landingpage_feature_id Tenant Landingpage Feature ID
     * @param app_http_requests_tenants_tenant_landingpage_feature_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_translations_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_translations_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_translations_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_feature_id: number, app_http_requests_tenants_tenant_landingpage_feature_translations_update_request: AppHttpRequestsTenantsTenantLandingpageFeatureTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut.');
        }
        if (tenant_landingpage_feature_id === null || tenant_landingpage_feature_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_feature_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_feature_translations_update_request === null || app_http_requests_tenants_tenant_landingpage_feature_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_feature_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdFeaturesTenantLandingpageFeatureIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/features/${this.configuration.encodeParam({name: "tenant_landingpage_feature_id", value: tenant_landingpage_feature_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_feature_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Landingpage
     * Get Tenant Landingpage
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdGet(tenant_id: number, tenant_landingpage_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdGet.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Tenant Landingpage Highlights
     * Get All Tenant Landingpage Highlights
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHighlightListHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHighlightListHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsGet(tenant_id: number, tenant_landingpage_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHighlightListHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsGet(tenant_id: number, tenant_landingpage_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsGet.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/highlights`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHighlightListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Landingpage Highlight
     * Create Tenant Landingpage Highlight
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param app_http_requests_tenants_tenant_landingpage_highlight_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_highlight_create_request: AppHttpRequestsTenantsTenantLandingpageHighlightCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_highlight_create_request: AppHttpRequestsTenantsTenantLandingpageHighlightCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_highlight_create_request: AppHttpRequestsTenantsTenantLandingpageHighlightCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsPost(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_highlight_create_request: AppHttpRequestsTenantsTenantLandingpageHighlightCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsPost.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsPost.');
        }
        if (app_http_requests_tenants_tenant_landingpage_highlight_create_request === null || app_http_requests_tenants_tenant_landingpage_highlight_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_highlight_create_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/highlights`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_highlight_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Landingpage Highlight
     * Delete Landingpage Highlight
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_highlight_id Tenant Landingpage Highlight ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdDelete(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdDelete.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdDelete.');
        }
        if (tenant_landingpage_highlight_id === null || tenant_landingpage_highlight_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_highlight_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/highlights/${this.configuration.encodeParam({name: "tenant_landingpage_highlight_id", value: tenant_landingpage_highlight_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Landingpage Highlight
     * Get Tenant Landingpage Highlight
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_highlight_id Tenant Landingpage Highlight ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdGet(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdGet.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdGet.');
        }
        if (tenant_landingpage_highlight_id === null || tenant_landingpage_highlight_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_highlight_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/highlights/${this.configuration.encodeParam({name: "tenant_landingpage_highlight_id", value: tenant_landingpage_highlight_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Tenant Landingpage Highlight Order
     * Change Tenant Landingpage Highlight Order
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_highlight_id Tenant Landingpage Highlight ID
     * @param app_http_requests_tenants_tenant_landingpage_highlight_change_order_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_change_order_request: AppHttpRequestsTenantsTenantLandingpageHighlightChangeOrderRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_change_order_request: AppHttpRequestsTenantsTenantLandingpageHighlightChangeOrderRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_change_order_request: AppHttpRequestsTenantsTenantLandingpageHighlightChangeOrderRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_change_order_request: AppHttpRequestsTenantsTenantLandingpageHighlightChangeOrderRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut.');
        }
        if (tenant_landingpage_highlight_id === null || tenant_landingpage_highlight_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_highlight_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_highlight_change_order_request === null || app_http_requests_tenants_tenant_landingpage_highlight_change_order_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_highlight_change_order_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdOrderPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/highlights/${this.configuration.encodeParam({name: "tenant_landingpage_highlight_id", value: tenant_landingpage_highlight_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/order`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_highlight_change_order_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Landingpage Highlight
     * Update Tenant Landingpage Highlight
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param tenant_landingpage_highlight_id Tenant Landingpage Highlight ID
     * @param app_http_requests_tenants_tenant_landingpage_highlight_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut.');
        }
        if (tenant_landingpage_highlight_id === null || tenant_landingpage_highlight_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_highlight_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_highlight_update_request === null || app_http_requests_tenants_tenant_landingpage_highlight_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_highlight_update_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/highlights/${this.configuration.encodeParam({name: "tenant_landingpage_highlight_id", value: tenant_landingpage_highlight_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_highlight_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Landingpage Highlight Translations
     * Update Tenant Landingpage Highlight Translations
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Footer ID
     * @param tenant_landingpage_highlight_id Tenant Landingpage Highlight ID
     * @param app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, tenant_landingpage_highlight_id: number, app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request: AppHttpRequestsTenantsTenantLandingpageHighlightTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut.');
        }
        if (tenant_landingpage_highlight_id === null || tenant_landingpage_highlight_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_highlight_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request === null || app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdHighlightsTenantLandingpageHighlightIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/highlights/${this.configuration.encodeParam({name: "tenant_landingpage_highlight_id", value: tenant_landingpage_highlight_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_highlight_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Landingpage
     * Update Tenant Landingpage
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param app_http_requests_tenants_tenant_landingpage_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_update_request: AppHttpRequestsTenantsTenantLandingpageUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_update_request: AppHttpRequestsTenantsTenantLandingpageUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_update_request: AppHttpRequestsTenantsTenantLandingpageUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_update_request: AppHttpRequestsTenantsTenantLandingpageUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_update_request === null || app_http_requests_tenants_tenant_landingpage_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_update_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Landingpage Translations
     * Update Tenant Landingpage Translations
     * @param tenant_id Tenant-ID
     * @param tenant_landingpage_id Tenant Landingpage ID
     * @param app_http_requests_tenants_tenant_landingpage_translations_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_translations_update_request: AppHttpRequestsTenantsTenantLandingpageTranslationsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHttpResponse>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_translations_update_request: AppHttpRequestsTenantsTenantLandingpageTranslationsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_translations_update_request: AppHttpRequestsTenantsTenantLandingpageTranslationsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdTranslationPut(tenant_id: number, tenant_landingpage_id: number, app_http_requests_tenants_tenant_landingpage_translations_update_request: AppHttpRequestsTenantsTenantLandingpageTranslationsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdTranslationPut.');
        }
        if (tenant_landingpage_id === null || tenant_landingpage_id === undefined) {
            throw new Error('Required parameter tenant_landingpage_id was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdTranslationPut.');
        }
        if (app_http_requests_tenants_tenant_landingpage_translations_update_request === null || app_http_requests_tenants_tenant_landingpage_translations_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_landingpage_translations_update_request was null or undefined when calling apiAdminTenantsTenantIdLandingpagesTenantLandingpageIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpages/${this.configuration.encodeParam({name: "tenant_landingpage_id", value: tenant_landingpage_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_landingpage_translations_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant player language
     * @param tenant_id Tenant Id
     * @param language_id Language Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLanguagesLanguageIdDelete(tenant_id: number, language_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLanguagesLanguageIdDelete(tenant_id: number, language_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLanguagesLanguageIdDelete(tenant_id: number, language_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLanguagesLanguageIdDelete(tenant_id: number, language_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLanguagesLanguageIdDelete.');
        }
        if (language_id === null || language_id === undefined) {
            throw new Error('Required parameter language_id was null or undefined when calling apiAdminTenantsTenantIdLanguagesLanguageIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/languages/${this.configuration.encodeParam({name: "language_id", value: language_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add tenant player language
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_player_language_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdLanguagesPost(tenant_id: number, app_http_requests_tenants_tenant_player_language_create_request: AppHttpRequestsTenantsTenantPlayerLanguageCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdLanguagesPost(tenant_id: number, app_http_requests_tenants_tenant_player_language_create_request: AppHttpRequestsTenantsTenantPlayerLanguageCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdLanguagesPost(tenant_id: number, app_http_requests_tenants_tenant_player_language_create_request: AppHttpRequestsTenantsTenantPlayerLanguageCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdLanguagesPost(tenant_id: number, app_http_requests_tenants_tenant_player_language_create_request: AppHttpRequestsTenantsTenantPlayerLanguageCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdLanguagesPost.');
        }
        if (app_http_requests_tenants_tenant_player_language_create_request === null || app_http_requests_tenants_tenant_player_language_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_player_language_create_request was null or undefined when calling apiAdminTenantsTenantIdLanguagesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/languages`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_player_language_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create tenant markets
     * Create tenant markets
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_market_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdMarketsPost(tenant_id: number, app_http_requests_tenants_tenant_market_create_request: AppHttpRequestsTenantsTenantMarketCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdMarketsPost(tenant_id: number, app_http_requests_tenants_tenant_market_create_request: AppHttpRequestsTenantsTenantMarketCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdMarketsPost(tenant_id: number, app_http_requests_tenants_tenant_market_create_request: AppHttpRequestsTenantsTenantMarketCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdMarketsPost(tenant_id: number, app_http_requests_tenants_tenant_market_create_request: AppHttpRequestsTenantsTenantMarketCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdMarketsPost.');
        }
        if (app_http_requests_tenants_tenant_market_create_request === null || app_http_requests_tenants_tenant_market_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_market_create_request was null or undefined when calling apiAdminTenantsTenantIdMarketsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/markets`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_market_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant market
     * Delete tenant market
     * @param tenant_id Tenant-ID
     * @param tenant_market_id Tenant Market ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdMarketsTenantMarketIdDelete(tenant_id: number, tenant_market_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdMarketsTenantMarketIdDelete(tenant_id: number, tenant_market_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdMarketsTenantMarketIdDelete(tenant_id: number, tenant_market_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdMarketsTenantMarketIdDelete(tenant_id: number, tenant_market_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdMarketsTenantMarketIdDelete.');
        }
        if (tenant_market_id === null || tenant_market_id === undefined) {
            throw new Error('Required parameter tenant_market_id was null or undefined when calling apiAdminTenantsTenantIdMarketsTenantMarketIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/markets/${this.configuration.encodeParam({name: "tenant_market_id", value: tenant_market_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes media for a specific tenant. If the media file is not used by other tenants or consumed by the system, it will be deleted
     * Deletes media for a specific tenant. If the media file is not used by other tenants or consumed by the system, it will be deleted.
     * @param tenant_id Tenant-ID
     * @param media_id Media ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdMediaMediaIdDelete(tenant_id: number, media_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesMediaMediaHttpResponse>;
    public apiAdminTenantsTenantIdMediaMediaIdDelete(tenant_id: number, media_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesMediaMediaHttpResponse>>;
    public apiAdminTenantsTenantIdMediaMediaIdDelete(tenant_id: number, media_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesMediaMediaHttpResponse>>;
    public apiAdminTenantsTenantIdMediaMediaIdDelete(tenant_id: number, media_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdMediaMediaIdDelete.');
        }
        if (media_id === null || media_id === undefined) {
            throw new Error('Required parameter media_id was null or undefined when calling apiAdminTenantsTenantIdMediaMediaIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media/${this.configuration.encodeParam({name: "media_id", value: media_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesMediaMediaHttpResponse>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds media for a specific tenant.
     * Adds media for a specific tenant.
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_media_add_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdMediaPost(tenant_id: number, app_http_requests_tenants_tenant_media_add_request: AppHttpRequestsTenantsTenantMediaAddRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesMediaMediaHttpResponse>;
    public apiAdminTenantsTenantIdMediaPost(tenant_id: number, app_http_requests_tenants_tenant_media_add_request: AppHttpRequestsTenantsTenantMediaAddRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesMediaMediaHttpResponse>>;
    public apiAdminTenantsTenantIdMediaPost(tenant_id: number, app_http_requests_tenants_tenant_media_add_request: AppHttpRequestsTenantsTenantMediaAddRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesMediaMediaHttpResponse>>;
    public apiAdminTenantsTenantIdMediaPost(tenant_id: number, app_http_requests_tenants_tenant_media_add_request: AppHttpRequestsTenantsTenantMediaAddRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdMediaPost.');
        }
        if (app_http_requests_tenants_tenant_media_add_request === null || app_http_requests_tenants_tenant_media_add_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_media_add_request was null or undefined when calling apiAdminTenantsTenantIdMediaPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/media`;
        return this.httpClient.request<AppHttpResponsesMediaMediaHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_media_add_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant notification settings
     * Update notification settings
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_notification_settings_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdNotificationSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_notification_settings_update_request: AppHttpRequestsTenantsTenantNotificationSettingsUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdNotificationSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_notification_settings_update_request: AppHttpRequestsTenantsTenantNotificationSettingsUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdNotificationSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_notification_settings_update_request: AppHttpRequestsTenantsTenantNotificationSettingsUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdNotificationSettingsPut(tenant_id: number, app_http_requests_tenants_tenant_notification_settings_update_request: AppHttpRequestsTenantsTenantNotificationSettingsUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdNotificationSettingsPut.');
        }
        if (app_http_requests_tenants_tenant_notification_settings_update_request === null || app_http_requests_tenants_tenant_notification_settings_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_notification_settings_update_request was null or undefined when calling apiAdminTenantsTenantIdNotificationSettingsPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/notification-settings`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_notification_settings_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Prize-Structures Templates for a Tenant
     * Get all Prize-Structures Templates for a Tenant
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Prize Structure Template
     * Create Tenant Prize Structure Template
     * @param tenant_id Tenant-ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesPost.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tenant Prize Structure Template
     * Delete Tenant Prize Structure Template
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize Structure Template ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdDelete(tenant_id: number, t_prize_structure_template_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdDelete(tenant_id: number, t_prize_structure_template_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdDelete(tenant_id: number, t_prize_structure_template_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdDelete(tenant_id: number, t_prize_structure_template_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdDelete.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Prize Structure Template
     * Get Tenant Prize Structure Template
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Structure Template ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdGet(tenant_id: number, t_prize_structure_template_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdGet(tenant_id: number, t_prize_structure_template_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdGet(tenant_id: number, t_prize_structure_template_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdGet(tenant_id: number, t_prize_structure_template_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdGet.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Tenant Prize Structure Template Order
     * Change Tenant Prize Structure Template Order
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize Structure Template ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdOrderPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateOrderRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdOrderPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateOrderRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdOrderPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateOrderRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdOrderPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateOrderRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdOrderPut.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdOrderPut.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdOrderPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/order`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_order_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Prize Structure Template
     * Update Tenant Prize Structure Template
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize Structure Template ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdPut.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdPut.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Prize-Structures Template Ranks for a Tenant
     * Get all Prize-Structures Template Ranks for a Tenant
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize-Structure Template ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksGet(tenant_id: number, t_prize_structure_template_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankListHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksGet(tenant_id: number, t_prize_structure_template_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankListHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksGet(tenant_id: number, t_prize_structure_template_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankListHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksGet(tenant_id: number, t_prize_structure_template_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksGet.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/ranks`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Prize-Structures Template Rank
     * Create Tenant Prize-Structures Template Rank
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize-Structure Template Id
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPost(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPost(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPost(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPost(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPost.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPost.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/ranks`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tenant Prize-Structures Template Rank
     * Delete Tenant Prize-Structures Template Rank
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize-Structure Template Id
     * @param prize_structure_template_rank_id Tenant Prize-Structures Template Rank Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdDelete(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdDelete(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdDelete(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdDelete(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdDelete.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdDelete.');
        }
        if (prize_structure_template_rank_id === null || prize_structure_template_rank_id === undefined) {
            throw new Error('Required parameter prize_structure_template_rank_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/ranks/${this.configuration.encodeParam({name: "prize_structure_template_rank_id", value: prize_structure_template_rank_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Prize-Structures Template Rank
     * Get Prize-Structures Template Rank
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize-Structure Template Id
     * @param prize_structure_template_rank_id Tenant Prize-Structures Template Rank Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdGet(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdGet(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdGet(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdGet(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdGet.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdGet.');
        }
        if (prize_structure_template_rank_id === null || prize_structure_template_rank_id === undefined) {
            throw new Error('Required parameter prize_structure_template_rank_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/ranks/${this.configuration.encodeParam({name: "prize_structure_template_rank_id", value: prize_structure_template_rank_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Prize-Structures Template Rank
     * Update Tenant Prize-Structures Template Rank
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize-Structure Template Id
     * @param prize_structure_template_rank_id Tenant Prize-Structures Template Rank Id
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut.');
        }
        if (prize_structure_template_rank_id === null || prize_structure_template_rank_id === undefined) {
            throw new Error('Required parameter prize_structure_template_rank_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/ranks/${this.configuration.encodeParam({name: "prize_structure_template_rank_id", value: prize_structure_template_rank_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Tenant Prize Structure Template Rank Rank-Order
     * Change Tenant Prize Structure Template Rank ValueOrder
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize-Structure Template Id
     * @param prize_structure_template_rank_id Tenant Prize-Structures Template Rank Id
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankChangeRankOrderRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankChangeRankOrderRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankChangeRankOrderRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut(tenant_id: number, t_prize_structure_template_id: number, prize_structure_template_rank_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankChangeRankOrderRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut.');
        }
        if (prize_structure_template_rank_id === null || prize_structure_template_rank_id === undefined) {
            throw new Error('Required parameter prize_structure_template_rank_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdRanksPrizeStructureTemplateRankIdRankOrderPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/ranks/${this.configuration.encodeParam({name: "prize_structure_template_rank_id", value: prize_structure_template_rank_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rank-order`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_rank_change_rank_order_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Prize Structure Template Translations
     * Update Tenant Prize Structure Template Translations
     * @param tenant_id Tenant-ID
     * @param t_prize_structure_template_id Tenant Prize Structure Template ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdTranslationPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateTranslationUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdTranslationPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateTranslationUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdTranslationPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateTranslationUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdTranslationPut(tenant_id: number, t_prize_structure_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateTranslationUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdTranslationPut.');
        }
        if (t_prize_structure_template_id === null || t_prize_structure_template_id === undefined) {
            throw new Error('Required parameter t_prize_structure_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdTranslationPut.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request was null or undefined when calling apiAdminTenantsTenantIdPrizestructuretemplatesTPrizeStructureTemplateIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizestructuretemplates/${this.configuration.encodeParam({name: "t_prize_structure_template_id", value: t_prize_structure_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_structure_template_translation_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import Tenant Pooling Prizes
     * Import Tenant Pooling Prizes
     * @param tenant_id Tenant-ID
     * @param tenant_prize_template_id Prize Template ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesImportPoolingPrizesPost(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeImportTenantPoolingPrizesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeImportHttpResponse>;
    public apiAdminTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesImportPoolingPrizesPost(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeImportTenantPoolingPrizesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeImportHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesImportPoolingPrizesPost(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeImportTenantPoolingPrizesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeImportHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesImportPoolingPrizesPost(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeImportTenantPoolingPrizesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesImportPoolingPrizesPost.');
        }
        if (tenant_prize_template_id === null || tenant_prize_template_id === undefined) {
            throw new Error('Required parameter tenant_prize_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesImportPoolingPrizesPost.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request was null or undefined when calling apiAdminTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesImportPoolingPrizesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplate/${this.configuration.encodeParam({name: "tenant_prize_template_id", value: tenant_prize_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizes/import-pooling-prizes`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeImportHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_import_tenant_pooling_prizes_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Tenant Prize Templates
     * Get all tenant prize templates
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizetemplatesGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateListHttpResponse>;
    public apiAdminTenantsTenantIdPrizetemplatesGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateListHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateListHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplates`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Prize Templates
     * Create Tenant Prize Templates
     * @param tenant_id Tenant-ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizetemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdPrizetemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdPrizetemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdPrizetemplatesPost(tenant_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesPost.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplates`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_template_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tenant Prize Template
     * Delete Tenant Prize Template
     * @param tenant_id Tenant-ID
     * @param tenant_prize_template_id Tenant Prize Template ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdDelete(tenant_id: number, tenant_prize_template_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdDelete(tenant_id: number, tenant_prize_template_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdDelete(tenant_id: number, tenant_prize_template_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdDelete(tenant_id: number, tenant_prize_template_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdDelete.');
        }
        if (tenant_prize_template_id === null || tenant_prize_template_id === undefined) {
            throw new Error('Required parameter tenant_prize_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplates/${this.configuration.encodeParam({name: "tenant_prize_template_id", value: tenant_prize_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Prize Template
     * Get Tenant Prize Template
     * @param tenant_id Tenant-ID
     * @param tenant_prize_template_id Tenant Prize Template ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdGet(tenant_id: number, tenant_prize_template_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdGet(tenant_id: number, tenant_prize_template_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdGet(tenant_id: number, tenant_prize_template_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdGet(tenant_id: number, tenant_prize_template_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdGet.');
        }
        if (tenant_prize_template_id === null || tenant_prize_template_id === undefined) {
            throw new Error('Required parameter tenant_prize_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplates/${this.configuration.encodeParam({name: "tenant_prize_template_id", value: tenant_prize_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Prize Template
     * Update Tenant Prize Template
     * @param tenant_id Tenant-ID
     * @param tenant_prize_template_id Tenant Prize Template ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdPut.');
        }
        if (tenant_prize_template_id === null || tenant_prize_template_id === undefined) {
            throw new Error('Required parameter tenant_prize_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdPut.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplates/${this.configuration.encodeParam({name: "tenant_prize_template_id", value: tenant_prize_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_template_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Prize Template Translations
     * Update Tenant Prize Template Translations
     * @param tenant_id Tenant-ID
     * @param tenant_prize_template_id Tenant Prize Template ID
     * @param app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdTranslationPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateTranslationUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdTranslationPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateTranslationUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdTranslationPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateTranslationUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>>;
    public apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdTranslationPut(tenant_id: number, tenant_prize_template_id: number, app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request: AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateTranslationUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdTranslationPut.');
        }
        if (tenant_prize_template_id === null || tenant_prize_template_id === undefined) {
            throw new Error('Required parameter tenant_prize_template_id was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdTranslationPut.');
        }
        if (app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request === null || app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request was null or undefined when calling apiAdminTenantsTenantIdPrizetemplatesTenantPrizeTemplateIdTranslationPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplates/${this.configuration.encodeParam({name: "tenant_prize_template_id", value: tenant_prize_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/translation`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_betsnaps_prize_structures_tenant_prize_template_translation_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant-Rankings
     * Get all Tenant-Rankings
     * @param tenant_id Tenant ID
     * @param get_positions_count Get positions count
     * @param ranking_state Ranking state
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param order_field Sort order field (valid options: \&#39;ranking_state|from_date|to_date|publish_date\&#39;)
     * @param order_ascending Sort order ascending
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdRankingsGet(tenant_id: number, get_positions_count?: boolean, ranking_state?: number, from_date?: string, to_date?: string, order_field?: string, order_ascending?: boolean, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>;
    public apiAdminTenantsTenantIdRankingsGet(tenant_id: number, get_positions_count?: boolean, ranking_state?: number, from_date?: string, to_date?: string, order_field?: string, order_ascending?: boolean, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsGet(tenant_id: number, get_positions_count?: boolean, ranking_state?: number, from_date?: string, to_date?: string, order_field?: string, order_ascending?: boolean, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsGet(tenant_id: number, get_positions_count?: boolean, ranking_state?: number, from_date?: string, to_date?: string, order_field?: string, order_ascending?: boolean, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdRankingsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (get_positions_count !== undefined && get_positions_count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>get_positions_count, 'get_positions_count');
        }
        if (ranking_state !== undefined && ranking_state !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ranking_state, 'ranking_state');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }
        if (order_field !== undefined && order_field !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order_field, 'order_field');
        }
        if (order_ascending !== undefined && order_ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order_ascending, 'order_ascending');
        }
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Ranking
     * Create Tenant Ranking
     * @param tenant_id Tenant ID
     * @param app_http_requests_tenants_tenant_ranking_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdRankingsPost(tenant_id: number, app_http_requests_tenants_tenant_ranking_create_request: AppHttpRequestsTenantsTenantRankingCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>;
    public apiAdminTenantsTenantIdRankingsPost(tenant_id: number, app_http_requests_tenants_tenant_ranking_create_request: AppHttpRequestsTenantsTenantRankingCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsPost(tenant_id: number, app_http_requests_tenants_tenant_ranking_create_request: AppHttpRequestsTenantsTenantRankingCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsPost(tenant_id: number, app_http_requests_tenants_tenant_ranking_create_request: AppHttpRequestsTenantsTenantRankingCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdRankingsPost.');
        }
        if (app_http_requests_tenants_tenant_ranking_create_request === null || app_http_requests_tenants_tenant_ranking_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_ranking_create_request was null or undefined when calling apiAdminTenantsTenantIdRankingsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_ranking_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Tenant Ranking
     * @param tenant_id Tenant ID
     * @param tenant_ranking_id Tenant ranking ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdRankingsTenantRankingIdDelete(tenant_id: number, tenant_ranking_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdDelete(tenant_id: number, tenant_ranking_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdDelete(tenant_id: number, tenant_ranking_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdDelete(tenant_id: number, tenant_ranking_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdDelete.');
        }
        if (tenant_ranking_id === null || tenant_ranking_id === undefined) {
            throw new Error('Required parameter tenant_ranking_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings/${this.configuration.encodeParam({name: "tenant_ranking_id", value: tenant_ranking_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a specific Tenant-Rankings Details
     * Get a specific Tenant-Rankings Details for admins
     * @param tenant_id ID of Tenant
     * @param tenant_ranking_id Tenant-Ranking-Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsAdminFullTenantRankingHttpResponse>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsAdminFullTenantRankingHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsAdminFullTenantRankingHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdGet.');
        }
        if (tenant_ranking_id === null || tenant_ranking_id === undefined) {
            throw new Error('Required parameter tenant_ranking_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings/${this.configuration.encodeParam({name: "tenant_ranking_id", value: tenant_ranking_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsAdminFullTenantRankingHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get specific Tenant-Rankings Leaderboard
     * Get specific Tenant-Rankings Leaderboard for admins
     * @param tenant_id ID of Tenant
     * @param tenant_ranking_id Tenant-Ranking-Id
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsAdminTenantRankingPositionListHttpResponse>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsAdminTenantRankingPositionListHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsAdminTenantRankingPositionListHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdLeaderboardGet.');
        }
        if (tenant_ranking_id === null || tenant_ranking_id === undefined) {
            throw new Error('Required parameter tenant_ranking_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdLeaderboardGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings/${this.configuration.encodeParam({name: "tenant_ranking_id", value: tenant_ranking_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/leaderboard`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsAdminTenantRankingPositionListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Ranking
     * Update Tenant Ranking
     * @param tenant_id Tenant ID
     * @param tenant_ranking_id Tenant ranking ID
     * @param app_http_requests_tenants_tenant_ranking_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdRankingsTenantRankingIdPut(tenant_id: number, tenant_ranking_id: number, app_http_requests_tenants_tenant_ranking_update_request: AppHttpRequestsTenantsTenantRankingUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdPut(tenant_id: number, tenant_ranking_id: number, app_http_requests_tenants_tenant_ranking_update_request: AppHttpRequestsTenantsTenantRankingUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdPut(tenant_id: number, tenant_ranking_id: number, app_http_requests_tenants_tenant_ranking_update_request: AppHttpRequestsTenantsTenantRankingUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>>;
    public apiAdminTenantsTenantIdRankingsTenantRankingIdPut(tenant_id: number, tenant_ranking_id: number, app_http_requests_tenants_tenant_ranking_update_request: AppHttpRequestsTenantsTenantRankingUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdPut.');
        }
        if (tenant_ranking_id === null || tenant_ranking_id === undefined) {
            throw new Error('Required parameter tenant_ranking_id was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdPut.');
        }
        if (app_http_requests_tenants_tenant_ranking_update_request === null || app_http_requests_tenants_tenant_ranking_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_ranking_update_request was null or undefined when calling apiAdminTenantsTenantIdRankingsTenantRankingIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings/${this.configuration.encodeParam({name: "tenant_ranking_id", value: tenant_ranking_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_ranking_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates tenant SEO setting
     * Updates tenant SEO setting
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_seo_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdSeoPut(tenant_id: number, app_http_requests_tenants_tenant_seo_update_request: AppHttpRequestsTenantsTenantSeoUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdSeoPut(tenant_id: number, app_http_requests_tenants_tenant_seo_update_request: AppHttpRequestsTenantsTenantSeoUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdSeoPut(tenant_id: number, app_http_requests_tenants_tenant_seo_update_request: AppHttpRequestsTenantsTenantSeoUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdSeoPut(tenant_id: number, app_http_requests_tenants_tenant_seo_update_request: AppHttpRequestsTenantsTenantSeoUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdSeoPut.');
        }
        if (app_http_requests_tenants_tenant_seo_update_request === null || app_http_requests_tenants_tenant_seo_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_seo_update_request was null or undefined when calling apiAdminTenantsTenantIdSeoPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/seo`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_seo_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant social networks settings
     * Update social networks settings
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_social_network_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdSocialNetworkPut(tenant_id: number, app_http_requests_tenants_tenant_social_network_update_request: AppHttpRequestsTenantsTenantSocialNetworkUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantHttpResponse>;
    public apiAdminTenantsTenantIdSocialNetworkPut(tenant_id: number, app_http_requests_tenants_tenant_social_network_update_request: AppHttpRequestsTenantsTenantSocialNetworkUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdSocialNetworkPut(tenant_id: number, app_http_requests_tenants_tenant_social_network_update_request: AppHttpRequestsTenantsTenantSocialNetworkUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantHttpResponse>>;
    public apiAdminTenantsTenantIdSocialNetworkPut(tenant_id: number, app_http_requests_tenants_tenant_social_network_update_request: AppHttpRequestsTenantsTenantSocialNetworkUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdSocialNetworkPut.');
        }
        if (app_http_requests_tenants_tenant_social_network_update_request === null || app_http_requests_tenants_tenant_social_network_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_social_network_update_request was null or undefined when calling apiAdminTenantsTenantIdSocialNetworkPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/social-network`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_social_network_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create tenant sports
     * Create tenant sports
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_sport_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdSportsPost(tenant_id: number, app_http_requests_tenants_tenant_sport_create_request: AppHttpRequestsTenantsTenantSportCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdSportsPost(tenant_id: number, app_http_requests_tenants_tenant_sport_create_request: AppHttpRequestsTenantsTenantSportCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdSportsPost(tenant_id: number, app_http_requests_tenants_tenant_sport_create_request: AppHttpRequestsTenantsTenantSportCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdSportsPost(tenant_id: number, app_http_requests_tenants_tenant_sport_create_request: AppHttpRequestsTenantsTenantSportCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdSportsPost.');
        }
        if (app_http_requests_tenants_tenant_sport_create_request === null || app_http_requests_tenants_tenant_sport_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_sport_create_request was null or undefined when calling apiAdminTenantsTenantIdSportsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/sports`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_sport_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant sport
     * Delete tenant sport
     * @param tenant_id Tenant-ID
     * @param tenant_sport_id Tenant Sport ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdSportsTenantSportIdDelete(tenant_id: number, tenant_sport_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdSportsTenantSportIdDelete(tenant_id: number, tenant_sport_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdSportsTenantSportIdDelete(tenant_id: number, tenant_sport_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdSportsTenantSportIdDelete(tenant_id: number, tenant_sport_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdSportsTenantSportIdDelete.');
        }
        if (tenant_sport_id === null || tenant_sport_id === undefined) {
            throw new Error('Required parameter tenant_sport_id was null or undefined when calling apiAdminTenantsTenantIdSportsTenantSportIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/sports/${this.configuration.encodeParam({name: "tenant_sport_id", value: tenant_sport_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant sport
     * Update tenant sport
     * @param tenant_id Tenant-ID
     * @param tenant_sport_id Tenant Sport ID
     * @param app_http_requests_tenants_tenant_sport_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdSportsTenantSportIdPut(tenant_id: number, tenant_sport_id: number, app_http_requests_tenants_tenant_sport_update_request: AppHttpRequestsTenantsTenantSportUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantSportHttpResponse>;
    public apiAdminTenantsTenantIdSportsTenantSportIdPut(tenant_id: number, tenant_sport_id: number, app_http_requests_tenants_tenant_sport_update_request: AppHttpRequestsTenantsTenantSportUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantSportHttpResponse>>;
    public apiAdminTenantsTenantIdSportsTenantSportIdPut(tenant_id: number, tenant_sport_id: number, app_http_requests_tenants_tenant_sport_update_request: AppHttpRequestsTenantsTenantSportUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantSportHttpResponse>>;
    public apiAdminTenantsTenantIdSportsTenantSportIdPut(tenant_id: number, tenant_sport_id: number, app_http_requests_tenants_tenant_sport_update_request: AppHttpRequestsTenantsTenantSportUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdSportsTenantSportIdPut.');
        }
        if (tenant_sport_id === null || tenant_sport_id === undefined) {
            throw new Error('Required parameter tenant_sport_id was null or undefined when calling apiAdminTenantsTenantIdSportsTenantSportIdPut.');
        }
        if (app_http_requests_tenants_tenant_sport_update_request === null || app_http_requests_tenants_tenant_sport_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_sport_update_request was null or undefined when calling apiAdminTenantsTenantIdSportsTenantSportIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/sports/${this.configuration.encodeParam({name: "tenant_sport_id", value: tenant_sport_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantSportHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_sport_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Vendor Games
     * Get all Tenant Vendor Games
     * @param tenant_id Tenant ID
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdTenantVendorGamesGet(tenant_id: number, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesTenantVendorGameListHttpResponse>;
    public apiAdminTenantsTenantIdTenantVendorGamesGet(tenant_id: number, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesTenantVendorGameListHttpResponse>>;
    public apiAdminTenantsTenantIdTenantVendorGamesGet(tenant_id: number, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesTenantVendorGameListHttpResponse>>;
    public apiAdminTenantsTenantIdTenantVendorGamesGet(tenant_id: number, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tenant-vendor-games`;
        return this.httpClient.request<AppHttpResponsesVendorGamesTenantVendorGameListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Tenant Vendor Game
     * Create Tenant Vendor Game
     * @param tenant_id Tenant-ID
     * @param app_http_requests_vendor_games_tenant_vendor_game_create_request Data
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdTenantVendorGamesPost(tenant_id: number, app_http_requests_vendor_games_tenant_vendor_game_create_request: AppHttpRequestsVendorGamesTenantVendorGameCreateRequest, per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdTenantVendorGamesPost(tenant_id: number, app_http_requests_vendor_games_tenant_vendor_game_create_request: AppHttpRequestsVendorGamesTenantVendorGameCreateRequest, per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdTenantVendorGamesPost(tenant_id: number, app_http_requests_vendor_games_tenant_vendor_game_create_request: AppHttpRequestsVendorGamesTenantVendorGameCreateRequest, per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdTenantVendorGamesPost(tenant_id: number, app_http_requests_vendor_games_tenant_vendor_game_create_request: AppHttpRequestsVendorGamesTenantVendorGameCreateRequest, per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesPost.');
        }
        if (app_http_requests_vendor_games_tenant_vendor_game_create_request === null || app_http_requests_vendor_games_tenant_vendor_game_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_vendor_games_tenant_vendor_game_create_request was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tenant-vendor-games`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_vendor_games_tenant_vendor_game_create_request,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Vendor Game
     * Find Tenant Vendor Game
     * @param tenant_id Tenant-ID
     * @param tenant_vendor_game_id Tenant Vendor Game ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdGet(tenant_id: number, tenant_vendor_game_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>;
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdGet(tenant_id: number, tenant_vendor_game_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>>;
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdGet(tenant_id: number, tenant_vendor_game_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>>;
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdGet(tenant_id: number, tenant_vendor_game_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdGet.');
        }
        if (tenant_vendor_game_id === null || tenant_vendor_game_id === undefined) {
            throw new Error('Required parameter tenant_vendor_game_id was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tenant-vendor-games/${this.configuration.encodeParam({name: "tenant_vendor_game_id", value: tenant_vendor_game_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Tenant Vendor Game
     * Update Tenant Vendor Game
     * @param tenant_id Tenant-ID
     * @param tenant_vendor_game_id Tenant Vendor Game ID
     * @param app_http_requests_vendor_games_tenant_vendor_game_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdPut(tenant_id: number, tenant_vendor_game_id: number, app_http_requests_vendor_games_tenant_vendor_game_update_request: AppHttpRequestsVendorGamesTenantVendorGameUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>;
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdPut(tenant_id: number, tenant_vendor_game_id: number, app_http_requests_vendor_games_tenant_vendor_game_update_request: AppHttpRequestsVendorGamesTenantVendorGameUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>>;
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdPut(tenant_id: number, tenant_vendor_game_id: number, app_http_requests_vendor_games_tenant_vendor_game_update_request: AppHttpRequestsVendorGamesTenantVendorGameUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>>;
    public apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdPut(tenant_id: number, tenant_vendor_game_id: number, app_http_requests_vendor_games_tenant_vendor_game_update_request: AppHttpRequestsVendorGamesTenantVendorGameUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdPut.');
        }
        if (tenant_vendor_game_id === null || tenant_vendor_game_id === undefined) {
            throw new Error('Required parameter tenant_vendor_game_id was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdPut.');
        }
        if (app_http_requests_vendor_games_tenant_vendor_game_update_request === null || app_http_requests_vendor_games_tenant_vendor_game_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_vendor_games_tenant_vendor_game_update_request was null or undefined when calling apiAdminTenantsTenantIdTenantVendorGamesTenantVendorGameIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tenant-vendor-games/${this.configuration.encodeParam({name: "tenant_vendor_game_id", value: tenant_vendor_game_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesVendorGamesTenantVendorGameHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_vendor_games_tenant_vendor_game_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create tenant tournaments
     * Create tenant tournaments
     * @param tenant_id Tenant-ID
     * @param app_http_requests_tenants_tenant_tournament_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdTournamentsPost(tenant_id: number, app_http_requests_tenants_tenant_tournament_create_request: AppHttpRequestsTenantsTenantTournamentCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdTournamentsPost(tenant_id: number, app_http_requests_tenants_tenant_tournament_create_request: AppHttpRequestsTenantsTenantTournamentCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdTournamentsPost(tenant_id: number, app_http_requests_tenants_tenant_tournament_create_request: AppHttpRequestsTenantsTenantTournamentCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdTournamentsPost(tenant_id: number, app_http_requests_tenants_tenant_tournament_create_request: AppHttpRequestsTenantsTenantTournamentCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdTournamentsPost.');
        }
        if (app_http_requests_tenants_tenant_tournament_create_request === null || app_http_requests_tenants_tenant_tournament_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_tournament_create_request was null or undefined when calling apiAdminTenantsTenantIdTournamentsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tournaments`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_tournament_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant tournament
     * Delete tenant tournament
     * @param tenant_id Tenant-ID
     * @param tenant_tournament_id Tenant Tournament ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdDelete(tenant_id: number, tenant_tournament_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdDelete(tenant_id: number, tenant_tournament_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdDelete(tenant_id: number, tenant_tournament_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdDelete(tenant_id: number, tenant_tournament_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdTournamentsTenantTournamentIdDelete.');
        }
        if (tenant_tournament_id === null || tenant_tournament_id === undefined) {
            throw new Error('Required parameter tenant_tournament_id was null or undefined when calling apiAdminTenantsTenantIdTournamentsTenantTournamentIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tournaments/${this.configuration.encodeParam({name: "tenant_tournament_id", value: tenant_tournament_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tenant tournament
     * Update tenant tournament
     * @param tenant_id Tenant-ID
     * @param tenant_tournament_id Tenant Tournament ID
     * @param app_http_requests_tenants_tenant_tournament_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdPut(tenant_id: number, tenant_tournament_id: number, app_http_requests_tenants_tenant_tournament_update_request: AppHttpRequestsTenantsTenantTournamentUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantTournamentHttpResponse>;
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdPut(tenant_id: number, tenant_tournament_id: number, app_http_requests_tenants_tenant_tournament_update_request: AppHttpRequestsTenantsTenantTournamentUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantTournamentHttpResponse>>;
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdPut(tenant_id: number, tenant_tournament_id: number, app_http_requests_tenants_tenant_tournament_update_request: AppHttpRequestsTenantsTenantTournamentUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantTournamentHttpResponse>>;
    public apiAdminTenantsTenantIdTournamentsTenantTournamentIdPut(tenant_id: number, tenant_tournament_id: number, app_http_requests_tenants_tenant_tournament_update_request: AppHttpRequestsTenantsTenantTournamentUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiAdminTenantsTenantIdTournamentsTenantTournamentIdPut.');
        }
        if (tenant_tournament_id === null || tenant_tournament_id === undefined) {
            throw new Error('Required parameter tenant_tournament_id was null or undefined when calling apiAdminTenantsTenantIdTournamentsTenantTournamentIdPut.');
        }
        if (app_http_requests_tenants_tenant_tournament_update_request === null || app_http_requests_tenants_tenant_tournament_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_tournament_update_request was null or undefined when calling apiAdminTenantsTenantIdTournamentsTenantTournamentIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tournaments/${this.configuration.encodeParam({name: "tenant_tournament_id", value: tenant_tournament_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantTournamentHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_tournament_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Oauth Access Tokens for User
     * Get all Oauth Access Tokens for User
     * @param user_id User Id
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param user_type Type [\&#39;player\&#39;, \&#39;admin\&#39;, \&#39;api_consumer\&#39;]
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdOauthaccesstokensGet(user_id: number, per_page?: number, page?: number, user_type?: 'player' | 'admin' | 'api_consumer', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesAuthOauthAccessTokenListHttpResponse>;
    public apiAdminUsersUserIdOauthaccesstokensGet(user_id: number, per_page?: number, page?: number, user_type?: 'player' | 'admin' | 'api_consumer', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesAuthOauthAccessTokenListHttpResponse>>;
    public apiAdminUsersUserIdOauthaccesstokensGet(user_id: number, per_page?: number, page?: number, user_type?: 'player' | 'admin' | 'api_consumer', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesAuthOauthAccessTokenListHttpResponse>>;
    public apiAdminUsersUserIdOauthaccesstokensGet(user_id: number, per_page?: number, page?: number, user_type?: 'player' | 'admin' | 'api_consumer', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdOauthaccesstokensGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (user_type !== undefined && user_type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>user_type, 'user_type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/oauthaccesstokens`;
        return this.httpClient.request<AppHttpResponsesAuthOauthAccessTokenListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Personal Access Token
     * Create Personal Access Token for User
     * @param user_id User Id
     * @param app_http_requests_auth_oauth_access_token_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdOauthaccesstokensPost(user_id: number, app_http_requests_auth_oauth_access_token_create_request: AppHttpRequestsAuthOauthAccessTokenCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>;
    public apiAdminUsersUserIdOauthaccesstokensPost(user_id: number, app_http_requests_auth_oauth_access_token_create_request: AppHttpRequestsAuthOauthAccessTokenCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>>;
    public apiAdminUsersUserIdOauthaccesstokensPost(user_id: number, app_http_requests_auth_oauth_access_token_create_request: AppHttpRequestsAuthOauthAccessTokenCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>>;
    public apiAdminUsersUserIdOauthaccesstokensPost(user_id: number, app_http_requests_auth_oauth_access_token_create_request: AppHttpRequestsAuthOauthAccessTokenCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdOauthaccesstokensPost.');
        }
        if (app_http_requests_auth_oauth_access_token_create_request === null || app_http_requests_auth_oauth_access_token_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_auth_oauth_access_token_create_request was null or undefined when calling apiAdminUsersUserIdOauthaccesstokensPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/oauthaccesstokens`;
        return this.httpClient.request<AppHttpResponsesAuthPersonalAccessTokenHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_auth_oauth_access_token_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove permission from user
     * @param user_id User ID
     * @param permission_id Permission ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdPermissionsPermissionIdDelete(user_id: number, permission_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminUsersUserIdPermissionsPermissionIdDelete(user_id: number, permission_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminUsersUserIdPermissionsPermissionIdDelete(user_id: number, permission_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminUsersUserIdPermissionsPermissionIdDelete(user_id: number, permission_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdPermissionsPermissionIdDelete.');
        }
        if (permission_id === null || permission_id === undefined) {
            throw new Error('Required parameter permission_id was null or undefined when calling apiAdminUsersUserIdPermissionsPermissionIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/permissions/${this.configuration.encodeParam({name: "permission_id", value: permission_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add permission to user
     * @param user_id User Id
     * @param app_http_requests_users_user_add_permission_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdPermissionsPost(user_id: number, app_http_requests_users_user_add_permission_request: AppHttpRequestsUsersUserAddPermissionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminUsersUserIdPermissionsPost(user_id: number, app_http_requests_users_user_add_permission_request: AppHttpRequestsUsersUserAddPermissionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminUsersUserIdPermissionsPost(user_id: number, app_http_requests_users_user_add_permission_request: AppHttpRequestsUsersUserAddPermissionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminUsersUserIdPermissionsPost(user_id: number, app_http_requests_users_user_add_permission_request: AppHttpRequestsUsersUserAddPermissionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdPermissionsPost.');
        }
        if (app_http_requests_users_user_add_permission_request === null || app_http_requests_users_user_add_permission_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_add_permission_request was null or undefined when calling apiAdminUsersUserIdPermissionsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/permissions`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_add_permission_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add role to user
     * @param user_id User Id
     * @param app_http_requests_users_user_add_role_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdRolesPost(user_id: number, app_http_requests_users_user_add_role_request: AppHttpRequestsUsersUserAddRoleRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminUsersUserIdRolesPost(user_id: number, app_http_requests_users_user_add_role_request: AppHttpRequestsUsersUserAddRoleRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminUsersUserIdRolesPost(user_id: number, app_http_requests_users_user_add_role_request: AppHttpRequestsUsersUserAddRoleRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminUsersUserIdRolesPost(user_id: number, app_http_requests_users_user_add_role_request: AppHttpRequestsUsersUserAddRoleRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdRolesPost.');
        }
        if (app_http_requests_users_user_add_role_request === null || app_http_requests_users_user_add_role_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_add_role_request was null or undefined when calling apiAdminUsersUserIdRolesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/roles`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_add_role_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove role from user
     * @param user_id User ID
     * @param role_id Role ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdRolesRoleIdDelete(user_id: number, role_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminUsersUserIdRolesRoleIdDelete(user_id: number, role_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminUsersUserIdRolesRoleIdDelete(user_id: number, role_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminUsersUserIdRolesRoleIdDelete(user_id: number, role_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdRolesRoleIdDelete.');
        }
        if (role_id === null || role_id === undefined) {
            throw new Error('Required parameter role_id was null or undefined when calling apiAdminUsersUserIdRolesRoleIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/roles/${this.configuration.encodeParam({name: "role_id", value: role_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Tags for User
     * Get all Tenant Tags for User
     * @param user_id User ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdTagsGet(user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserTagListHttpResponse>;
    public apiAdminUsersUserIdTagsGet(user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserTagListHttpResponse>>;
    public apiAdminUsersUserIdTagsGet(user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserTagListHttpResponse>>;
    public apiAdminUsersUserIdTagsGet(user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdTagsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tags`;
        return this.httpClient.request<AppHttpResponsesUsersUserTagListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a User Tag
     * @param user_id User Id
     * @param app_http_requests_users_user_tag_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdTagsPost(user_id: number, app_http_requests_users_user_tag_create_request: AppHttpRequestsUsersUserTagCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesUsersUserTagHttpResponse>;
    public apiAdminUsersUserIdTagsPost(user_id: number, app_http_requests_users_user_tag_create_request: AppHttpRequestsUsersUserTagCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesUsersUserTagHttpResponse>>;
    public apiAdminUsersUserIdTagsPost(user_id: number, app_http_requests_users_user_tag_create_request: AppHttpRequestsUsersUserTagCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesUsersUserTagHttpResponse>>;
    public apiAdminUsersUserIdTagsPost(user_id: number, app_http_requests_users_user_tag_create_request: AppHttpRequestsUsersUserTagCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiAdminUsersUserIdTagsPost.');
        }
        if (app_http_requests_users_user_tag_create_request === null || app_http_requests_users_user_tag_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_users_user_tag_create_request was null or undefined when calling apiAdminUsersUserIdTagsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tags`;
        return this.httpClient.request<AppHttpResponsesUsersUserTagHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_users_user_tag_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove User Tag
     * @param user_tag_id User Tag Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsertagsUserTagIdDelete(user_tag_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminUsertagsUserTagIdDelete(user_tag_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminUsertagsUserTagIdDelete(user_tag_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminUsertagsUserTagIdDelete(user_tag_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_tag_id === null || user_tag_id === undefined) {
            throw new Error('Required parameter user_tag_id was null or undefined when calling apiAdminUsertagsUserTagIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/usertags/${this.configuration.encodeParam({name: "user_tag_id", value: user_tag_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Vendor Games
     * Get all Vendor Games
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param is_active Is Active
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorGamesGet(per_page?: number, page?: number, is_active?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesVendorGameListHttpResponse>;
    public apiAdminVendorGamesGet(per_page?: number, page?: number, is_active?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesVendorGameListHttpResponse>>;
    public apiAdminVendorGamesGet(per_page?: number, page?: number, is_active?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesVendorGameListHttpResponse>>;
    public apiAdminVendorGamesGet(per_page?: number, page?: number, is_active?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (is_active !== undefined && is_active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_active, 'is_active');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendor-games`;
        return this.httpClient.request<AppHttpResponsesVendorGamesVendorGameListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Vendor Game
     * Create Vendor Game
     * @param app_http_requests_vendor_games_vendor_game_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorGamesPost(app_http_requests_vendor_games_vendor_game_create_request: AppHttpRequestsVendorGamesVendorGameCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminVendorGamesPost(app_http_requests_vendor_games_vendor_game_create_request: AppHttpRequestsVendorGamesVendorGameCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminVendorGamesPost(app_http_requests_vendor_games_vendor_game_create_request: AppHttpRequestsVendorGamesVendorGameCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminVendorGamesPost(app_http_requests_vendor_games_vendor_game_create_request: AppHttpRequestsVendorGamesVendorGameCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_vendor_games_vendor_game_create_request === null || app_http_requests_vendor_games_vendor_game_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_vendor_games_vendor_game_create_request was null or undefined when calling apiAdminVendorGamesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendor-games`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_vendor_games_vendor_game_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find Vendor Game
     * Find Vendor Game
     * @param vendor_game_id Vendor Game ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorGamesVendorGameIdGet(vendor_game_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesVendorGameHttpResponse>;
    public apiAdminVendorGamesVendorGameIdGet(vendor_game_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesVendorGameHttpResponse>>;
    public apiAdminVendorGamesVendorGameIdGet(vendor_game_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesVendorGameHttpResponse>>;
    public apiAdminVendorGamesVendorGameIdGet(vendor_game_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (vendor_game_id === null || vendor_game_id === undefined) {
            throw new Error('Required parameter vendor_game_id was null or undefined when calling apiAdminVendorGamesVendorGameIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendor-games/${this.configuration.encodeParam({name: "vendor_game_id", value: vendor_game_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesVendorGamesVendorGameHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Vendor Game
     * Update Vendor Game
     * @param vendor_game_id Vendor Game ID
     * @param app_http_requests_vendor_games_vendor_game_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorGamesVendorGameIdPut(vendor_game_id: number, app_http_requests_vendor_games_vendor_game_update_request: AppHttpRequestsVendorGamesVendorGameUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesVendorGameHttpResponse>;
    public apiAdminVendorGamesVendorGameIdPut(vendor_game_id: number, app_http_requests_vendor_games_vendor_game_update_request: AppHttpRequestsVendorGamesVendorGameUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesVendorGameHttpResponse>>;
    public apiAdminVendorGamesVendorGameIdPut(vendor_game_id: number, app_http_requests_vendor_games_vendor_game_update_request: AppHttpRequestsVendorGamesVendorGameUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesVendorGameHttpResponse>>;
    public apiAdminVendorGamesVendorGameIdPut(vendor_game_id: number, app_http_requests_vendor_games_vendor_game_update_request: AppHttpRequestsVendorGamesVendorGameUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (vendor_game_id === null || vendor_game_id === undefined) {
            throw new Error('Required parameter vendor_game_id was null or undefined when calling apiAdminVendorGamesVendorGameIdPut.');
        }
        if (app_http_requests_vendor_games_vendor_game_update_request === null || app_http_requests_vendor_games_vendor_game_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_vendor_games_vendor_game_update_request was null or undefined when calling apiAdminVendorGamesVendorGameIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendor-games/${this.configuration.encodeParam({name: "vendor_game_id", value: vendor_game_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesVendorGamesVendorGameHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_vendor_games_vendor_game_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Vendors
     * Get all Vendors
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorsGet(per_page?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesVendorListHttpResponse>;
    public apiAdminVendorsGet(per_page?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesVendorListHttpResponse>>;
    public apiAdminVendorsGet(per_page?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesVendorListHttpResponse>>;
    public apiAdminVendorsGet(per_page?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendors`;
        return this.httpClient.request<AppHttpResponsesVendorGamesVendorListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Vendor
     * Create Vendor
     * @param app_http_requests_vendor_games_vendor_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorsPost(app_http_requests_vendor_games_vendor_create_request: AppHttpRequestsVendorGamesVendorCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiAdminVendorsPost(app_http_requests_vendor_games_vendor_create_request: AppHttpRequestsVendorGamesVendorCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminVendorsPost(app_http_requests_vendor_games_vendor_create_request: AppHttpRequestsVendorGamesVendorCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminVendorsPost(app_http_requests_vendor_games_vendor_create_request: AppHttpRequestsVendorGamesVendorCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_vendor_games_vendor_create_request === null || app_http_requests_vendor_games_vendor_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_vendor_games_vendor_create_request was null or undefined when calling apiAdminVendorsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendors`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_vendor_games_vendor_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find Vendor
     * Find Vendor
     * @param vendor_id Vendor ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorsVendorIdGet(vendor_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesVendorHttpResponse>;
    public apiAdminVendorsVendorIdGet(vendor_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesVendorHttpResponse>>;
    public apiAdminVendorsVendorIdGet(vendor_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesVendorHttpResponse>>;
    public apiAdminVendorsVendorIdGet(vendor_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (vendor_id === null || vendor_id === undefined) {
            throw new Error('Required parameter vendor_id was null or undefined when calling apiAdminVendorsVendorIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendors/${this.configuration.encodeParam({name: "vendor_id", value: vendor_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesVendorGamesVendorHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Vendor
     * Update Vendor
     * @param vendor_id Vendor ID
     * @param app_http_requests_vendor_games_vendor_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVendorsVendorIdPut(vendor_id: number, app_http_requests_vendor_games_vendor_update_request: AppHttpRequestsVendorGamesVendorUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesVendorGamesVendorHttpResponse>;
    public apiAdminVendorsVendorIdPut(vendor_id: number, app_http_requests_vendor_games_vendor_update_request: AppHttpRequestsVendorGamesVendorUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesVendorGamesVendorHttpResponse>>;
    public apiAdminVendorsVendorIdPut(vendor_id: number, app_http_requests_vendor_games_vendor_update_request: AppHttpRequestsVendorGamesVendorUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesVendorGamesVendorHttpResponse>>;
    public apiAdminVendorsVendorIdPut(vendor_id: number, app_http_requests_vendor_games_vendor_update_request: AppHttpRequestsVendorGamesVendorUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (vendor_id === null || vendor_id === undefined) {
            throw new Error('Required parameter vendor_id was null or undefined when calling apiAdminVendorsVendorIdPut.');
        }
        if (app_http_requests_vendor_games_vendor_update_request === null || app_http_requests_vendor_games_vendor_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_vendor_games_vendor_update_request was null or undefined when calling apiAdminVendorsVendorIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/vendors/${this.configuration.encodeParam({name: "vendor_id", value: vendor_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesVendorGamesVendorHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_vendor_games_vendor_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export Tenant Pooling Prize ImportExample
     * Export Tenant Pooling Prize ImportExample
     * @param tenant_id Tenant-ID
     * @param export_file_format Export File Format
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdPrizesExportTenantPoolingPrizeImportexampleGet(tenant_id: number, export_file_format: 'xlsx' | 'csv', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse>;
    public apiTenantsTenantIdPrizesExportTenantPoolingPrizeImportexampleGet(tenant_id: number, export_file_format: 'xlsx' | 'csv', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse>>;
    public apiTenantsTenantIdPrizesExportTenantPoolingPrizeImportexampleGet(tenant_id: number, export_file_format: 'xlsx' | 'csv', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse>>;
    public apiTenantsTenantIdPrizesExportTenantPoolingPrizeImportexampleGet(tenant_id: number, export_file_format: 'xlsx' | 'csv', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdPrizesExportTenantPoolingPrizeImportexampleGet.');
        }
        if (export_file_format === null || export_file_format === undefined) {
            throw new Error('Required parameter export_file_format was null or undefined when calling apiTenantsTenantIdPrizesExportTenantPoolingPrizeImportexampleGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (export_file_format !== undefined && export_file_format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>export_file_format, 'export_file_format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizes/export-tenant-pooling-prize-importexample`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Tenant Prizes By Prize Template ID
     * Get All Tenant Prizes
     * @param tenant_id Tenant-ID
     * @param tenant_prize_template_id Tenant Prize Template ID
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet(tenant_id: number, tenant_prize_template_id: number, per_page: number, page: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeListHttpResponse>;
    public apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet(tenant_id: number, tenant_prize_template_id: number, per_page: number, page: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeListHttpResponse>>;
    public apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet(tenant_id: number, tenant_prize_template_id: number, per_page: number, page: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeListHttpResponse>>;
    public apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet(tenant_id: number, tenant_prize_template_id: number, per_page: number, page: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet.');
        }
        if (tenant_prize_template_id === null || tenant_prize_template_id === undefined) {
            throw new Error('Required parameter tenant_prize_template_id was null or undefined when calling apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet.');
        }
        if (per_page === null || per_page === undefined) {
            throw new Error('Required parameter per_page was null or undefined when calling apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling apiTenantsTenantIdPrizetemplateTenantPrizeTemplateIdPrizesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/admin/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizetemplate/${this.configuration.encodeParam({name: "tenant_prize_template_id", value: tenant_prize_template_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/prizes`;
        return this.httpClient.request<AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
