import {Component} from '@angular/core';
import {
    AuthenticationService,
    ErrorService,
    FriendsService,
    GoogleAnalyticsService,
    MobiledetectService,
    MyModalService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {AGameShareModalComponent} from '../a-game-share-modal-component';

@Component({
    selector: 'betsnaps-game-share-modal-default',
    templateUrl: './game-share-modal-default.component.html',
    styles: []
})
export class GameShareModalDefaultComponent extends AGameShareModalComponent {

    constructor(public myModalService: MyModalService,
                public mobileDetect: MobiledetectService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public tenantService: TenantService,
                protected authenticationService: AuthenticationService,
                protected friendsService: FriendsService,
                protected errorService: ErrorService,
                protected windowRef: WindowRef) {
        super(
            myModalService,
            mobileDetect,
            googleAnalyticsService,
            tenantService,
            authenticationService,
            friendsService,
            errorService,
            windowRef
        );
    }


}
