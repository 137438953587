import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AppHttpResponsesChatsChatHttpResponse as ChatHttpResponse
} from '../../../../api';
import {takeWhile} from 'rxjs/operators';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';
import {ChatService, GoogleAnalyticsService} from '../../../../shared';

@Component({
    selector: 'betsnaps-chat-accordion',
    templateUrl: './chat-accordion.component.html',
    styles: []
})
export class ChatAccordionComponent implements OnInit, OnDestroy {

    private componentAlive = true;

    @Input() chatId: number;
    @ViewChild('chatContainer') chatContainer: ElementRef;

    public chatVisible: boolean = false;

    public initialLoadFinished: boolean = false;
    public chat: ChatHttpResponse;

    constructor(public chatService: ChatService,
                public googleAnalyticsService: GoogleAnalyticsService) {
    }

    ngOnInit() {
        this.chatService.initialLoadFinished$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((initialLoadFinished: boolean) => {
                    if (initialLoadFinished === true) {
                        this.initialLoadFinished = initialLoadFinished;
                    }
                }
            );

        this.chatService.chat$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((chat: ChatHttpResponse) => {
                    this.chat = chat;
                }
            );
    }

    ngOnDestroy(): void {
        this.componentAlive = false;
    }

}
