/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantAdvertisementUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantAdvertisementUpdateRequest { 
    /**
     * Title
     */
    title: string;
    /**
     * Display From-Date
     */
    display_from_date?: Date;
    /**
     * Display To-Date
     */
    display_to_date?: Date;
    /**
     * Text to show
     */
    text?: string;
    /**
     * Ad Server Embed Code
     */
    adserver_embed_code?: string;
    /**
     * Type
     */
    type: string;
    /**
     * Position
     */
    position?: number;
    /**
     * Media ID
     */
    media_id?: number;
    /**
     * Web Distribution
     */
    web_distribution?: boolean;
    /**
     * Android Distribution
     */
    android_distribution?: boolean;
    /**
     * iOS Distribution
     */
    ios_distribution?: boolean;
    /**
     * Image Link
     */
    image_link?: string;
    /**
     * Open Image Link in new Tab
     */
    open_link_in_new_tab?: boolean;
    /**
     * Android Image Link
     */
    android_image_link?: string;
    /**
     * Open Android Link in new Tab
     */
    android_open_link_in_new_tab?: boolean;
    /**
     * iOS Image Link
     */
    ios_image_link?: string;
    /**
     * Open iOS Link in new Tab
     */
    ios_open_link_in_new_tab?: boolean;
}

