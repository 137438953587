import {Component, Input, OnInit} from '@angular/core';

import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameHttpListResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {MyTranslateService} from '../../../shared';

@Component({
    selector: 'betsnaps-game-list',
    templateUrl: './game-list.component.html',
    styles: []
})
export class GameListComponent implements OnInit {

    @Input() gameList: GameHttpListResponse;
    @Input() currentUser: PlayerHttpResponse;

    @Input() adPositions: number[];

    @Input() isHistory = false;
    @Input() showCount = true;
    @Input() showNoResultsMessage = true;
    @Input() showHeaderDivider = true;

    constructor(public translations: MyTranslateService) {
    }

    ngOnInit() {
    }

    gamesTrackBy(index: number, game: GameHttpResponse): string {
        return game.game_unique_id;
    }

}
