/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MonthlyStatisticRequest
 */
export interface AppHttpRequestsKlipfolioMonthlyStatisticRequest { 
    /**
     * Date YYYY-MM-DD
     */
    from_date?: string;
    /**
     * Date YYYY-MM-DD
     */
    to_date?: string;
    /**
     * Months back
     */
    months_back?: number;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Entries per Page
     */
    perPage?: number;
    /**
     * Page Nr
     */
    page?: number;
}

