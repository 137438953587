/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantSocialNetworkUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantSocialNetworkUpdateRequest { 
    /**
     * Facebook ID
     */
    facebook_id?: string;
    /**
     * Google Client ID
     */
    google_client_id?: string;
    /**
     * Google API Key
     */
    google_api_key?: string;
    /**
     * Google Analytics Tracking ID
     */
    google_analytics_tracking_id?: string;
    /**
     * Facebook Login enabled
     */
    facebook_login_enabled?: boolean;
    /**
     * Google Login enabled
     */
    google_login_enabled?: boolean;
}

