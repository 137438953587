import { ElementRef, HostListener, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';

import {
    AppHttpResponsesFriendsFriendListHttpResponse as FriendListHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendsHttpResponse,
    AppHttpResponsesUsersPlayerPublicListHttpResponse as PlayerPublicListHttpResponse,
    AppHttpResponsesFriendsFriendInvitationListHttpResponse as FriendInvitationListHttpResponse
} from '../../../../api';
import {
    AuthenticationService, ErrorService, FriendsService, GoogleAnalyticsService, HintService, MyTranslateService,
    WindowRef
} from '../../../../shared';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {take, takeWhile} from 'rxjs/operators';
import {zip} from 'rxjs';

@Directive()
export abstract class AFriendsListComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    public userFriends: FriendsHttpResponse[];

    public processList: boolean = true;
    public hasFriendInvitationsIncoming: boolean = false;
    public hasFriendInvitationsOutgoing: boolean = false;
    public hasFriendInvitationsIncomingDeclined: boolean = false;
    public processSearchList: boolean = false;
    public processLoadMore: boolean = false;

    public searchActive: boolean = false;

    // Pagination
    protected perPage: number = 24;
    protected currentPage: number = 1;

    @ViewChild('loadMoreButton') public loadMoreButton: ElementRef;
    protected nativeWindow: Window;

    public searchForm: UntypedFormGroup = new UntypedFormGroup({
        searchstring: new UntypedFormControl('', [Validators.required, Validators.minLength(2)])
    });

    public searchResultUsersList: PlayerPublicListHttpResponse;

    constructor(public authenticationService: AuthenticationService,
                protected friendsService: FriendsService,
                protected hintService: HintService,
                protected windowRef: WindowRef,
                public translations: MyTranslateService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService) {

        this.nativeWindow = windowRef.nativeWindow;

        this.friendsService.userFriends$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendListHttpResponse: FriendListHttpResponse) => {
                    if (friendListHttpResponse) {
                        this.userFriends = friendListHttpResponse.results;
                    }
                }
            );

        this.friendsService.userFriendInvitationsIncoming$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                    if (friendInvitationListHttpResponse) {
                        if(friendInvitationListHttpResponse.results.length > 0){
                            this.hasFriendInvitationsIncoming = true;
                        } else {
                            this.hasFriendInvitationsIncoming = false;
                        }
                    }
                }
            );

        this.friendsService.userFriendInvitationsOutgoing$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                    if (friendInvitationListHttpResponse) {
                        if(friendInvitationListHttpResponse.results.length > 0){
                            this.hasFriendInvitationsOutgoing = true;
                        } else {
                            this.hasFriendInvitationsOutgoing = false;
                        }
                    }
                }
            );

        this.friendsService.userFriendInvitationsIncomingDeclined$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                    if (friendInvitationListHttpResponse) {
                        if(friendInvitationListHttpResponse.results.length > 0){
                            this.hasFriendInvitationsIncomingDeclined = true;
                        } else {
                            this.hasFriendInvitationsIncomingDeclined = false;
                        }
                    }
                }
            );

        const allFriendsDataLoaded = zip(
            this.friendsService.userFriends$,
            this.friendsService.userFriendInvitationsIncoming$,
            this.friendsService.userFriendInvitationsOutgoing$,
            this.friendsService.userFriendInvitationsIncomingDeclined$
        );

        allFriendsDataLoaded.pipe(take(1)).subscribe(() => {
            this.processList = false;
        });

    }

    ngOnInit() {
        this.hintService.checkForHintToDisplay('friends-list');
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    abstract loadMore(): void;

    infiniteScroll() {
        if (typeof this.loadMoreButton !== 'undefined' && this.searchResultUsersList.results.length < this.searchResultUsersList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMore && !this.processList && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMore();
            }
        }
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    searchResultUsersListListeners() {
        this.infiniteScroll();
    }

    closeSearch() {
        this.searchForm.reset();
        this.searchResultUsersList = null;
        this.currentPage = 1;
        this.searchActive = false;
        this.googleAnalyticsService.trackEvent('friends', 'search', 'close result');
    }

    userFriendsTrackBy(index: number, userFriend: FriendsHttpResponse) {
        return userFriend.id;
    }

}
