/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PlayerHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsPlayerHttpResponse { 
    /**
     * All Players Count of a Tenant
     */
    players_count: number;
    /**
     * All Active Players Count of a Tenant
     */
    active_players_count?: number;
    /**
     * Players Count without an Username
     */
    active_players_without_username_count?: number;
}

