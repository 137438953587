<div class="ui centered relaxed grid container">
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <div class="ui center aligned borderless vertical segment margin-0">
            <h2 class="ui center aligned small header">
                {{'RESENDPHONEVERIFICATION.heading' | translate | uppercase}}
            </h2>
        </div>
    </div>
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <fui-message *ngIf="showAlert" class="{{alertMessage.type ?? ''}}" [hasDismissButton]="false">
            <div class="header" *ngIf="alertMessage.messagetranslatekey">
                {{alertMessage.messagetranslatekey | translate}}
            </div>
            <p *ngIf="alertMessage.messagetxttranslatekey">
                {{alertMessage.messagetxttranslatekey | translate}}
            </p>
        </fui-message>

        <form *ngIf="!showAlert" class="ui form" novalidate [formGroup]="initResendVerification"
              [class.error]="!processForm"
              (ngSubmit)="onSubmit()">
            <betsnaps-phone-field [processForm]="processForm"
                                  [phoneSignupAllowedCountryCodes]="phoneSignupAllowedCountryCodes"
                                  [form]="initResendVerification"></betsnaps-phone-field>
            <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10 padding-b-0">
                <button class="ui large fluid button margin-0" type="submit"
                        [class.loading]="processForm"
                        [disabled]="processForm || initResendVerification.invalid">
                    {{'RESENDPHONEVERIFICATION.initialize_btn' | translate | uppercase}}
                </button>
            </div>
        </form>
    </div>
    <div class="ui sixteen wide center aligned column padding-b-0">
        <div class="ui row divider"></div>
        <div class="color-grey">
            {{'LOGIN.no_account_label' | translate}}
            <a class="text font-bold padding-l-5 nowrap"
               (click)="showView('register')">
                {{'LOGIN.no_account_btn' | translate}}
            </a>
        </div>
        <div class="color-grey">
            {{'REGISTER.phone_verification_label' | translate}}
            <a class="text font-bold padding-l-5 nowrap"
               (click)="showView('phone-verification')">
                {{'REGISTER.phone_verification_btn' | translate}}
            </a>
        </div>
    </div>
</div>
