import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RecaptchaDynamicLanguageLoaderService {
    private readySubject = new BehaviorSubject<any>(null);
    public ready = this.readySubject.asObservable();
    public languageIsoCode = '';

    constructor() {
    }

    public loadLanguage(languageIsoCode: string): void {
        this.languageIsoCode = languageIsoCode;
        this.readySubject.next(null);
        this.init();
    }

    private init() {
        if (this.readySubject.getValue()) {
            return;
        } else {
            (<any>window).ng2recaptchaloaded = () => {
                this.readySubject.next(grecaptcha);
            };
        }

        const script = document.createElement('script') as HTMLScriptElement;
        script.innerHTML = '';
        const langParam = this.languageIsoCode ? '&hl=' + this.languageIsoCode : '';
        script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=ng2recaptchaloaded${langParam}`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }
}
