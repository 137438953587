import { EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameInvitationHttpResponse as GameInvitationHttpResponse,
    AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse as GameShareDataHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendsHttpResponse,
    AppHttpResponsesFriendsFriendListHttpResponse as FriendListHttpResponse
} from '../../../api';
import {
    AuthenticationService,
    ErrorService,
    FriendsService,
    GoogleAnalyticsService,
    MobiledetectService,
    MyModalService,
    TenantService,
    WindowRef
} from '../../../shared';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {cloneDeep} from 'lodash';
import {HttpErrorResponse} from '@angular/common/http';
import {take, takeWhile} from 'rxjs/operators';

@Directive()
export abstract class AGameShareModalComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    @Input() game: GameHttpResponse;
    @Input() gameShareData: GameShareDataHttpResponse;
    @Input() sentGameInvitations: GameInvitationHttpResponse[];

    @Output() onApprove: EventEmitter<any> = new EventEmitter();
    @Output() onDeny: EventEmitter<any> = new EventEmitter();

    public shareUrl: string;

    public userFriends: FriendsHttpResponse[] = [];
    public selectedUserFriends: FriendsHttpResponse[] = [];

    public friendsInvited: boolean = false;
    public noSelectionError: boolean = false;

    public processInvite: boolean = false;
    public showcopyinfo: boolean = false;

    protected nativeWindow;
    public isWrapperWindow: boolean = false;

    @ViewChild('gameShareModalTemplate') public gameShareModalTemplate: ModalTemplate<null, string, string>;

    constructor(public myModalService: MyModalService,
                public mobileDetect: MobiledetectService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public tenantService: TenantService,
                protected authenticationService: AuthenticationService,
                protected friendsService: FriendsService,
                protected errorService: ErrorService,
                protected windowRef: WindowRef) {

        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    ngOnInit() {

        // set share url to betsnapdetail view
        this.shareUrl = this.tenantService.getTenantDomainWithDefaultRelativePath() + '/betsnapdetail/' + this.game.game_unique_id + '/details';

        if (this.authenticationService.currentLang) {
            this.shareUrl += '?lang=' + this.authenticationService.currentLang.iso_code2;
        }

        this.friendsService.userFriends$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (friendListHttpResponse: FriendListHttpResponse) => {
                    if (friendListHttpResponse) {
                        let friendListResults = cloneDeep(friendListHttpResponse.results);
                        // only include not joined friends
                        if (this.game.current_user_friends) {
                            friendListResults = friendListResults.filter((friendsHttpResponse: FriendsHttpResponse) =>
                                !this.game.current_user_friends.find((joinedFriend: FriendsHttpResponse) => joinedFriend.friend_user_id === friendsHttpResponse.friend_user_id)
                            );
                        }
                        if (this.sentGameInvitations && this.sentGameInvitations.length > 0) {
                            friendListResults = friendListResults.filter((friendsHttpResponse: FriendsHttpResponse) =>
                                !this.sentGameInvitations.find((invitedFriend: GameInvitationHttpResponse) =>
                                    invitedFriend.receiver_user_id === friendsHttpResponse.friend_user_id
                                )
                            );
                        }
                        if (friendListHttpResponse.results.length > 0) {
                            this.userFriends = friendListResults;
                        }
                    }
                }
            );
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    selectFriend(userFriend: FriendsHttpResponse) {
        if (!this.processInvite) {
            if (this.selectedUserFriends.includes(userFriend)) {
                // remove from selection
                this.selectedUserFriends = this.selectedUserFriends.filter((selectedFriend: FriendsHttpResponse) =>
                    selectedFriend.friend_user_id !== userFriend.friend_user_id
                );
            } else {
                // add to selection
                this.selectedUserFriends.push(userFriend);
            }
        }
    }

    inviteFriends() {
        if (this.selectedUserFriends.length > 0) {
            // CALL API
            const selectedUserIds = [];
            this.selectedUserFriends.forEach((selectedFriend: FriendsHttpResponse) => {
                selectedUserIds.push(selectedFriend.friend_user_id);
            });

            this.processInvite = true;

            this.friendsService.inviteFriends(
                this.game.game_unique_id,
                this.authenticationService.currentUser.id,
                selectedUserIds
            ).pipe(take(1))
            .subscribe({
                next: () => {
                    // remove from available friends
                    this.userFriends = this.userFriends.filter((friendsHttpResponse: FriendsHttpResponse) =>
                        !this.selectedUserFriends.find((selectedFriend: FriendsHttpResponse) => selectedFriend.friend_user_id === friendsHttpResponse.friend_user_id)
                    );
                    // reset selected friends
                    this.selectedUserFriends = [];
                    this.noSelectionError = false;
                    this.friendsInvited = true;
                    this.processInvite = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.handleHttpErrorResponse(err);
                    this.processInvite = false;
                }
            });
        } else {
            this.noSelectionError = true;
        }
    }

    showCopyInformation() {
        this.showcopyinfo = true;
        const self = this;
        setTimeout(() => {
            self.showcopyinfo = false;
        }, 3000);
    }

    callWrapperShare() {
        if (this.isWrapperWindow) {
            this.nativeWindow.window.BetSnapMobileWrapper.share([this.shareUrl]);
        }
    }

    openGameShareModal() {
        const gameShareModalConfig = new TemplateModalConfig<null, string, string>(this.gameShareModalTemplate);
        gameShareModalConfig.size = ModalSize.Tiny;
        gameShareModalConfig.mustScroll = true;
        gameShareModalConfig.isClosable = true;
        this.myModalService.openModal(
            gameShareModalConfig
        ).onApprove(() => {
            this.onApprove.emit();
        }).onDeny(() => {
            this.onDeny.emit();
        });
    }

}
