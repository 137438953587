<div class="ui segments" *ngIf="currentUser">
    <div class="ui clearing segment">
        <div class="ui middle aligned two column grid">
            <div class="row">
                <div class="thirteen wide column">
                    <h2 class="ui header">{{'PROFILE.EDIT.heading' | translate}}</h2>
                </div>
                <div class="right aligned three wide column">
                    <a routerLink="/profile/overview" class="ui basic circular icon button margin-0">
                        <i class="close icon margin-0"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="ui segment">
        <div class="ui active centered inline text loader" *ngIf="showSpinner">
            {{'GENERAL.loadertext' | translate}}
        </div>
        <form class="ui form" novalidate [formGroup]="profileForm" (ngSubmit)="onSubmit()"
              [class.success]="submitted && !processForm"
              [class.error]="profileForm.invalid && submitted && !processForm" *ngIf="!showSpinner">

            <div class="margin-t-0 margin-b-10">
                <strong>{{'PROFILE.EDIT.username_field' | translate}}</strong><br/>
                <span *ngIf="currentUser.username">{{currentUser.username}}</span>
                <p *ngIf="!currentUser.username || !currentUser.is_username_confirmed" class="margin-l-10 margin-t-5 display-inline-block">
                    <button type="button"
                            class="ui mini button"
                            (click)="$event.preventDefault(); myModalService.openUsernameInputModal()">
                        {{'PROFILE.EDIT.set_username_button' | translate}}
                    </button>
                </p>
            </div>

            <p class="margin-t-0">
                <strong>{{'PROFILE.EDIT.email_field' | translate}}</strong><br/>
                {{currentUser.email}}
            </p>

            <div class="field">
                <label for="gender">{{'PROFILE.EDIT.gender_field' | translate}}</label>
                <select id="gender" name="gender" formControlName="gender" class="ui dropdown">
                    <option selected="selected" value="">{{'PROFILE.EDIT.gender_select' | translate}}</option>
                    <option value="1">{{'PROFILE.EDIT.gender_male' | translate}}</option>
                    <option value="2">{{'PROFILE.EDIT.gender_female' | translate}}</option>
                </select>
            </div>
            <div class="field">
                <label for="first_name">{{'PROFILE.EDIT.first_name_field' | translate}}</label>
                <input id="first_name" type="text" formControlName="first_name">
            </div>
            <div class="field">
                <label for="last_name">{{'PROFILE.EDIT.last_name_field' | translate}}</label>
                <input id="last_name" type="text" formControlName="last_name"/>
            </div>
            <div class="field">
                <label for="dob">{{'PROFILE.EDIT.dob_field' | translate}}</label>
                <input id="dob" type="date" formControlName="dob"/>
            </div>
            <div class="field">
                <label for="zip_code">{{'PROFILE.EDIT.zipcode_field' | translate}}</label>
                <input id="zip_code" type="text" formControlName="zip_code"/>
            </div>
            <div class="field">
                <label for="city">{{'PROFILE.EDIT.city_field' | translate}}</label>
                <input id="city" type="text" formControlName="city"/>
            </div>
            <div class="field">
                <label for="country">{{'PROFILE.EDIT.country_field' | translate}}</label>
                <select id="country" name="country" #country (change)="getStatesForCountryIso2(country.value)"
                        formControlName="country" class="ui dropdown">
                    <option selected="selected" value="">{{'PROFILE.EDIT.country_select' | translate}}</option>
                    <option *ngFor="let country of countries" [value]="country.iso2">{{country.country_name}}</option>
                </select>
            </div>
            <div class="field" *ngIf="(states.length > 0)">
                <label for="state_id">{{'PROFILE.EDIT.state_field' | translate}}</label>
                <select id="state_id" name="state_id" formControlName="state_id">
                    <option selected="selected" value="">{{'PROFILE.EDIT.select_state' | translate}}</option>
                    <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
                </select>
            </div>
            <div class="ui center aligned">
                <button class="ui large button margin-0" type="submit" [class.loading]="processForm"
                        [disabled]="processForm || !profileForm.valid">
                    {{'PROFILE.EDIT.save_changes_btn' | translate | uppercase}}
                </button>
            </div>
        </form>
    </div>
</div>
