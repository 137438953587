/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse';


/**
 * PoolingPrizeImportHttpResponse
 */
export interface AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeImportHttpResponse { 
    /**
     * Saved Tenant Prizes Count
     */
    saved_tenant_pooling_prizes_count?: number;
    invalid_imported_tenant_pooling_prizes?: AppHttpResponsesBetsnapsPrizeStructuresPoolingPrizeExportHttpResponse;
}

