<div class="ui vertical borderless very padded segment" *ngIf="processGameVendorGameParticipations">
    <div class="ui active centered inline text loader color-white text-shadow">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processGameVendorGameParticipations">
    <div *ngIf="gameVendorGameParticipations?.length > 0">
        <betsnaps-game-vendor-game-participation-card-v3
            class="ui fluid card game-vendor-game-participation-card"
            *ngFor="let gameVendorGameParticipation of activeGameVendorGameParticipations(); trackBy: gameVendorGameParticipationsTrackBy; let participationIndexInList = index"
            [game]="game"
            [gameVendorGameParticipation]="gameVendorGameParticipation"
            [gameVendorGameParticipationUpdatedDate]="gameVendorGameParticipation.updated_at"
            [participationIndexInList]="participationIndexInList"
            [adPositions]="adPositions">
        </betsnaps-game-vendor-game-participation-card-v3>
        <betsnaps-game-vendor-game-participation-card-v3
            class="ui fluid card game-vendor-game-participation-card"
            *ngFor="let gameVendorGameParticipation of closedGameVendorGameParticipations(); trackBy: gameVendorGameParticipationsTrackBy; let participationIndexInList = index"
            [game]="game"
            [gameVendorGameParticipation]="gameVendorGameParticipation"
            [gameVendorGameParticipationUpdatedDate]="gameVendorGameParticipation.updated_at"
            [participationIndexInList]="participationIndexInList + activeGameVendorGameParticipations().length"
            [adPositions]="adPositions">
        </betsnaps-game-vendor-game-participation-card-v3>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="gameVendorGameParticipations?.length < 1">
        <p class="color-white text-shadow">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
