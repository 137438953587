import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    AppHttpResponsesCMSsCMSListHttpResponse as CMSListHttpResponse,
    AppHttpResponsesCMSsCMSHttpResponse as CMSHttpResponse,
    CMSApi
} from '../../api';


@Injectable({
    providedIn: 'root'
})
export class CMSService {
    private cmsListSubject = new BehaviorSubject<CMSListHttpResponse>(null);

    constructor(private cmsApi: CMSApi) {
    }

    public setCMSList(list: CMSListHttpResponse) {
        this.cmsListSubject.next(list);
    }

    public get cmsList(): CMSListHttpResponse {
        return this.cmsListSubject.value;
    }

    public getCMSList(tenantId: number): Observable<CMSListHttpResponse> {
        return this.cmsApi.apiTenantsTenantIdCmsGet(tenantId);
    }

    public findCMSByKey(cmsKey: string): CMSHttpResponse {
        return this.cmsListSubject.value.results.find(
            (cms: CMSHttpResponse) => cms.key === cmsKey
        );
    }
}
