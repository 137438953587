import {Component, ViewChild} from '@angular/core';
import {
    AuthenticationService,
    ErrorService,
    GoogleAnalyticsService,
    MyModalService,
    MyTranslateService,
    RankingService, TenantImageTypeEnum,
    TenantService,
    WindowRef,
} from '../../../../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {ARankingComponent} from '../a-ranking.component';
import {
    AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse as FullTenantRankingHttpResponse,
    AppHttpResponsesBetsnapsRankingsTenantRankingPositionHttpResponse as TenantRankingPositionHttpResponse,
    AppHttpResponsesBetsnapsRankingsSimpleTenantRankingHttpResponse as SimpleTenantRankingHttpResponse
} from '../../../../api';
import {ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';

@Component({
    selector: 'betsnaps-ranking-v3',
    templateUrl: './ranking-v3.component.html',
    styles: []
})
export class RankingV3Component extends ARankingComponent {

    @ViewChild('rankingUserInfoModalTemplate') public rankingUserInfoModalTemplate: ModalTemplate<null, string, string>;
    @ViewChild('tenantRankingFilterModalTemplate') public tenantRankingFilterModalTemplate: ModalTemplate<null, string, string>;

    public profileHeaderImage: string;
    public profileHeaderImageDefault: string;
    public tenantRankingsNotVisible: SimpleTenantRankingHttpResponse[];
    public rankingUserInfoModalUser: TenantRankingPositionHttpResponse;

    constructor(public authenticationService: AuthenticationService,
                public  tenantService: TenantService,
                protected rankingService: RankingService,
                protected activatedRoute: ActivatedRoute,
                protected router: Router,
                public translations: MyTranslateService,
                protected translateService: TranslateService,
                protected windowRef: WindowRef,
                protected errorService: ErrorService,
                protected location: Location,
                protected googleAnalyticsService: GoogleAnalyticsService,
                private myModalService: MyModalService) {
        super(authenticationService,
            tenantService,
            rankingService,
            activatedRoute,
            router,
            translations,
            translateService,
            windowRef,
            errorService,
            location,
            googleAnalyticsService);

        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER)) {
            this.profileHeaderImage = this.profileHeaderImageDefault = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER).media_url;
        }
    }

    rankingPositionTrackBy(index: number, rankingPosition: TenantRankingPositionHttpResponse) {
        if (rankingPosition !== undefined) {
            return rankingPosition.user_id;
        }
        return 0;
    }

    public showRankingUserInfoModal(rankingUserInfoModalUser) {
        this.rankingUserInfoModalUser = rankingUserInfoModalUser;
        const rankingUserInfoModalConfig = new TemplateModalConfig<null, string, string>(this.rankingUserInfoModalTemplate);
        rankingUserInfoModalConfig.size = 'tiny';
        this.myModalService.openModal(rankingUserInfoModalConfig);
    }

    public showTenantRankingsModal() {
        const tenantRankingFilterModalConfig = new TemplateModalConfig<null, string, string>(this.tenantRankingFilterModalTemplate);
        tenantRankingFilterModalConfig.size = 'tiny';
        this.myModalService.openModal(tenantRankingFilterModalConfig);
    }

    getButtonsForTenantRankings(setTenantRankingsAllVisible: boolean): void {
        // evaluate the buttons for tenant rankings
        let i = 0;
        this.tenantRankingVisible = [];
        this.tenantRankingsNotVisible = [];
        for (const tenantRanking of this.tenantRankingList.results) {
            if (i < 3) {
                this.tenantRankingVisible.push(tenantRanking);
            } else {
                this.tenantRankingsNotVisible.push(tenantRanking);
            }
            i++;
        }
    }

    tenantRankingUpdated(tenantRanking: FullTenantRankingHttpResponse) {
        if (tenantRanking.bannerimg) {
            this.profileHeaderImage = tenantRanking.bannerimg;
        } else {
            this.profileHeaderImage = this.profileHeaderImageDefault;
        }
    }

    getTenantRankings() {
        this.getAllCurrentTenantRankings();
    }
}
