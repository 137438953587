/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaTypeHttpResponse } from './appHttpResponsesMediaMediaTypeHttpResponse';


/**
 * TenantImageTypeHttpResponse
 */
export interface AppHttpResponsesTenantsTenantImageTypeHttpResponse { 
    /**
     * Tenant image type ID
     */
    id: number;
    /**
     * Name
     */
    name: string;
    /**
     * Media type ID
     */
    media_type_id: number;
    media_type?: AppHttpResponsesMediaMediaTypeHttpResponse;
}

