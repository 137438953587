import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-new-user-invite',
    template: ``,
    styles: []
})
export class NewUserInviteComponent implements OnInit, OnDestroy {

    private componentAlive = true;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.activatedRoute.params
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((params: Params) => {
                const inviteToken = params['user_unique_id'];
                localStorage.setItem('inviteToken', inviteToken);
                this.router.navigate(['register']);
                return;
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

}
