import { ElementRef, HostListener, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import {
    AppHttpResponsesNotificationsUserNotificationHttpResponse as UserNotificationHttpResponse,
    AppHttpResponsesNotificationsUserNotificationListHttpResponse as UserNotificationListHttpResponse
} from '../../../api';
import {ErrorService, GoogleAnalyticsService, MyNotificationsService, TenantService, WindowRef} from '../../../shared';
import {Router} from '@angular/router';
import {take, takeWhile} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Directive()
export abstract class ANewsfeedComponent implements OnDestroy {
    protected componentAlive = true;

    public processList: boolean = true;
    public processLoadMore: boolean = false;

    protected userNotificationListSubscription: Subscription;
    public userNotifications: UserNotificationListHttpResponse;

    public activeTab: string;

    // Pagination
    protected perPage: number = 25;
    protected currentPage: number = 1;

    @ViewChild('loadMoreButton') public loadMoreButton: ElementRef;
    protected nativeWindow: Window;

    constructor(public tenantService: TenantService,
                public notificationService: MyNotificationsService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService,
                protected router: Router,
                protected windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;

        this.notificationService.addedUserNotification$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userNotification: UserNotificationHttpResponse) => {
                    if (!this.processList && (!this.activeTab || this.activeTab === 'new') &&
                        this.userNotifications && this.userNotifications.results &&
                        userNotification && userNotification.notification.is_shown_in_news && !userNotification.is_read) {
                        const notificationExistsInList = this.userNotifications.results.find(
                            (userNotificationInList: UserNotificationHttpResponse) =>
                                userNotificationInList.id === userNotification.id
                        );
                        if (!notificationExistsInList) {
                            this.userNotifications.results.unshift(userNotification);
                            this.userNotifications.total = this.userNotifications.total + 1;
                            this.userNotifications.count = this.userNotifications.count + 1;
                        }
                    }
                }
            );
    }

    ngOnDestroy() {
        this.componentAlive = false;

        if (this.userNotificationListSubscription) {
            this.userNotificationListSubscription.unsubscribe();
        }
    }

    public hasActionSection(userNotification: UserNotificationHttpResponse): boolean {
        return this.hasTenantPrizePromoCode(userNotification) || this.isSelfServicePrizePickup(userNotification);
    }

    // gets a notification text with its placeholder variables replaced
    public getReplacedText(userNotification: UserNotificationHttpResponse) {
        return this.notificationService.getReplacedText(userNotification);
    }

    public isSelfServicePrizePickup(userNotification: UserNotificationHttpResponse): boolean {
        return this.notificationService.getNotificationVariable(userNotification, 'self_service_prize_pickup');
    }

    public hasTenantPrizePromoCode(userNotification: UserNotificationHttpResponse): boolean {
        return this.notificationService.hasNotificationVariable(userNotification, 'tenant_prize_promo_code');
    }

    public getTenantPrizePromoCode(userNotification: UserNotificationHttpResponse): string | null {
        return this.notificationService.getNotificationVariable(userNotification, 'tenant_prize_promo_code');
    }

    public hasTenantPrizeCtaLinkUrl(userNotification: UserNotificationHttpResponse): boolean {
        return (this.getTenantPrizeCtaLinkUrl(userNotification)) ? true : false;
    }

    public getTenantPrizeCtaLinkUrl(userNotification: UserNotificationHttpResponse): string | null {
        if (this.notificationService.hasNotificationVariable(userNotification, 'cta_link_url')) {
            return this.notificationService.getNotificationVariable(userNotification, 'cta_link_url') ?? null;
        }

        return this.tenantService.tenantData.b2b?.website_promo_code_url ?? null;
    }

    public navigateToUrl(url: string) {
        this.googleAnalyticsService.trackEvent('news', 'click', 'navigate to url: ' + url);
        this.nativeWindow.open(url);
        return;
    }

    public notificationTrackBy(index: number, userNotification: UserNotificationHttpResponse) {
        return userNotification.id;
    }

    abstract loadMore();

    infiniteScroll() {
        if (typeof this.loadMoreButton !== 'undefined' && this.userNotifications.results.length < this.userNotifications.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMore && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMore();
            }
        }
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    notificationWindowListeners() {
        this.infiniteScroll();
    }

}
