import {Component} from '@angular/core';
import {
    AuthenticationService, ErrorService, GoogleAnalyticsService, MyModalService, ProfileService, TenantImageTypeEnum,
    TenantService, WindowRef
} from '../../../../../shared';
import {AProfileOverviewComponent} from '../a-profile-overview.component';

@Component({
    selector: 'betsnaps-profile-overview-v3',
    templateUrl: './profile-overview-v3.component.html',
    styles: []
})
export class ProfileOverviewV3Component extends AProfileOverviewComponent {

    public profileHeaderImage: string;

    constructor(protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected profileService: ProfileService,
                protected errorService: ErrorService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected windowRef: WindowRef,
                public myModalService: MyModalService
    ) {
        super(
            authenticationService,
            tenantService,
            profileService,
            errorService,
            googleAnalyticsService,
            windowRef
        );

        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER)) {
            this.profileHeaderImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER).media_url;
        }
    }
}
