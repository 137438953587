import {throwError, Observable, EMPTY} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {BroadcastingService} from '../broadcasting.service';
import {DebugService} from '../debug.service';
import {ErrorService} from '../error.service';
import {map, catchError} from 'rxjs/operators';
import {LoggerService} from '../logger.service';

@Injectable({
    providedIn: 'root'
})
export class MyHttpRequestInterceptorService implements HttpInterceptor {

    constructor(private broadcastingService: BroadcastingService,
                private debugService: DebugService,
                private errorService: ErrorService,
                private logService: LoggerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let socketID = null;
        if (this.broadcastingService.echoService) {
            socketID = this.broadcastingService.echoService.socketId;
        }
        if (socketID && (req.method === 'POST' || req.method === 'PUT' || req.method === 'DELETE')) {
            req = req.clone({
                setHeaders: {
                    'X-Socket-ID': socketID
                }
            });
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // write debug console message
                this.debugService.writeMessageToConsoleLog('ERROR DEBUG');
                this.debugService.writeMessageToConsoleLog(error);

                if (!navigator.onLine) {
                    // Handle offline error
                    this.errorService.createAlertModal('no_internet_connection');
                    return EMPTY;
                } else {
                    return throwError(error);
                }
            }));
    }
}
