import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {GameListComponent} from './ui/components/game-list/game-list.component';
import {GameNameComponent} from './ui/components/game-name/game-name.component';
import {GameCardLoaderComponent} from './ui/components/game-card/game-card-loader.component';
import {CountdownComponent} from './ui/components/countdown/countdown.component';
import {SidebarCreateSnapComponent} from './ui/components/sidebar-components/sidebar-create-snap/sidebar-create-snap.component';
import {SidebarGameUserComponent} from './ui/components/sidebar-components/sidebar-game-user/sidebar-game-user.component';
import {SidebarShareSnapComponent} from './ui/components/sidebar-components/sidebar-share-snap/sidebar-share-snap.component';
import {SidebarTenantContentComponent} from './ui/components/sidebar-components/sidebar-tenant-content/sidebar-tenant-content.component';
import {LoginRegisterModalComponent} from './ui/components/login-register-modal/login-register-modal.component';
import {
    CurrencyFormatPipe,
    DbTranslationsPipe,
    DecimalPrizePipe,
    GroupByPipe,
    OrdinalNumberPipe,
    RouteTransformerDirective,
    SafeHtmlPipe,
    SafeUrlPipe,
    ScrollerDirective,
    TranslateCutPipe,
    VarDirective
} from './shared';
import {ScriptIncludeComponent} from './ui/components/script-include/script-include.component';
import {TenantContentComponent} from './ui/components/tenant-content/tenant-content.component';
import {ExternalBodyScriptComponent} from './ui/components/externalbodyscript/external-body-script.component';
import {UsernameModalComponent} from './ui/components/username-modal/username-modal.component';
import {UsernameModalViewComponent} from './ui/components/username-modal/username-modal-view/username-modal-view.component';
import {GoToTopButtonComponent} from './ui/components/go-to-top-button/go-to-top-button.component';
import {LoginFormComponent} from './ui/components/login-register-modal/login-form/login-form.component';
import {RegisterFormComponent} from './ui/components/login-register-modal/register-form/register-form.component';
import {ResetPasswordEmailFormComponent} from './ui/components/login-register-modal/reset-password-email-form/reset-password-email-form.component';
import {ResetPasswordPhoneFormComponent} from './ui/components/login-register-modal/reset-password-phone-form/reset-password-phone-form.component';
import {ResendEmailVerificationFormComponent} from './ui/components/login-register-modal/resend-email-verification-form/resend-email-verification-form.component';
import {ResendPhoneVerificationFormComponent} from './ui/components/login-register-modal/resend-phone-verification-form/resend-phone-verification-form.component';
import {PhoneVerificationFormComponent} from './ui/components/login-register-modal/phone-verification-form/phone-verification-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {MomentModule} from 'ngx-moment';
import {ShareModule} from 'ngx-sharebuttons';
import {FomanticUIModule} from '@aligorji/ngx-fomantic-ui';
import {ClipboardModule} from 'ngx-clipboard';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {RecaptchaModule} from 'ng-recaptcha';
import {ImageCropperComponent} from 'ngx-image-cropper';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {NgxUsefulSwiperModule} from '@duxor/ngx-useful-swiper';
import {DynamicComponentHostDirective} from './shared/directives/dynamic-component-host.directive';
import {GameCardDefaultComponent} from './ui/components/game-card/game-card-default/game-card-default.component';
import {GameCardV3Component} from './ui/components/game-card/game-card-v3/game-card-v3.component';
import {UserAvatarLoaderComponent} from './ui/components/user-avatar/user-avatar-loader.component';
import {UserAvatarDefaultComponent} from './ui/components/user-avatar/user-avatar-default/user-avatar-default.component';
import {UserAvatarV3Component} from './ui/components/user-avatar/user-avatar-v3/user-avatar-v3.component';
import {GameShareModalDefaultComponent} from './ui/components/game-share-modal/game-share-modal-default/game-share-modal-default.component';
import {GameShareModalV3Component} from './ui/components/game-share-modal/game-share-modal-v3/game-share-modal-v3.component';
import {LeftSidebarDefaultComponent} from './ui/components/sidebar-components/sidebar-left/sidebar-left-default/left-sidebar-default.component';
import {RightSidebarDefaultComponent} from './ui/components/sidebar-components/right-sidebar/right-sidebar-default/right-sidebar-default.component';
import {RightSidebarV3Component} from './ui/components/sidebar-components/right-sidebar/right-sidebar-v3/right-sidebar-v3.component';
import {LeftSidebarV3Component} from './ui/components/sidebar-components/sidebar-left/siderbar-left-v3/left-sidebar-v3.component';
import {LeftSidebarLoaderComponent} from './ui/components/sidebar-components/sidebar-left/left-sidebar-loader.component';
import {RightSidebarLoaderComponent} from './ui/components/sidebar-components/right-sidebar/right-sidebar-loader.component';
import {SingleplayerGameCardComponent} from './ui/components/singleplayer-game-card/singleplayer-game-card.component';
import {GameVendorGameFullscreenComponent} from './ui/components/game-vendor-game-fullscreen/game-vendor-game-fullscreen.component';
import {SingleplayerJoinModalComponent} from './ui/components/singleplayer-join-modal/singleplayer-join-modal.component';
import {SimpleGameCardComponent} from './ui/components/game-card/simple/simple-game-card.component';
import {PhoneFieldComponent} from './ui/components/form-components/phone-field/phone-field.component';
import {CMSComponent} from './ui/components/cms/cms';
import {CopyToClipboardComponent} from './ui/components/copy-to-clipboard/copy-to-clipboard.component';
import {NotificationBubbleComponent} from './ui/components/notification-bubble/notification-bubble.component';
import {LandingpageLoginRegisterButtonsComponent} from './ui/components/landingpage-login-register-buttons/landingpage-login-register-buttons.component';
import {RemoveWrappingDirective} from './shared/directives/remove-wrapping.directive';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';

@NgModule({
    declarations: [
        // shared components
        GameListComponent,
        GameNameComponent,
        CMSComponent,
        CopyToClipboardComponent,
        GameCardLoaderComponent,
        GameCardDefaultComponent,
        GameCardV3Component,
        SimpleGameCardComponent,
        GameVendorGameFullscreenComponent,
        SingleplayerGameCardComponent,
        LeftSidebarLoaderComponent,
        RightSidebarLoaderComponent,
        LeftSidebarDefaultComponent,
        RightSidebarDefaultComponent,
        LeftSidebarV3Component,
        RightSidebarV3Component,
        SidebarCreateSnapComponent,
        SidebarGameUserComponent,
        SidebarShareSnapComponent,
        SidebarTenantContentComponent,
        ScriptIncludeComponent,
        TenantContentComponent,
        ExternalBodyScriptComponent,
        LoginFormComponent,
        RegisterFormComponent,
        ResetPasswordEmailFormComponent,
        ResetPasswordPhoneFormComponent,
        ResendEmailVerificationFormComponent,
        ResendPhoneVerificationFormComponent,
        PhoneVerificationFormComponent,
        CountdownComponent,
        GoToTopButtonComponent,
        NotificationBubbleComponent,
        PhoneFieldComponent,
        UserAvatarLoaderComponent,
        UserAvatarDefaultComponent,
        UserAvatarV3Component,
        LandingpageLoginRegisterButtonsComponent,
        // modals
        LoginRegisterModalComponent,
        UsernameModalComponent,
        UsernameModalViewComponent,
        GameShareModalDefaultComponent,
        GameShareModalV3Component,
        SingleplayerJoinModalComponent,
        // pipes
        DbTranslationsPipe,
        CurrencyFormatPipe,
        GroupByPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        TranslateCutPipe,
        OrdinalNumberPipe,
        DecimalPrizePipe,
        // directives
        RouteTransformerDirective,
        ScrollerDirective,
        VarDirective,
        DynamicComponentHostDirective,
        RemoveWrappingDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        MomentModule,
        ShareModule,
        FomanticUIModule,
        ClipboardModule,
        SimpleNotificationsModule,
        PickerModule,
        NgxUsefulSwiperModule,
        RecaptchaModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        ImageCropperComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        MomentModule,
        ShareModule,
        FomanticUIModule,
        ClipboardModule,
        SimpleNotificationsModule,
        PickerModule,
        NgxUsefulSwiperModule,
        // shared components
        ImageCropperComponent,
        GameListComponent,
        GameNameComponent,
        CMSComponent,
        CopyToClipboardComponent,
        GameCardLoaderComponent,
        SingleplayerGameCardComponent,
        GameVendorGameFullscreenComponent,
        LeftSidebarLoaderComponent,
        RightSidebarLoaderComponent,
        SidebarCreateSnapComponent,
        SidebarGameUserComponent,
        SidebarShareSnapComponent,
        SidebarTenantContentComponent,
        ScriptIncludeComponent,
        TenantContentComponent,
        ExternalBodyScriptComponent,
        LoginFormComponent,
        RegisterFormComponent,
        ResetPasswordEmailFormComponent,
        ResetPasswordPhoneFormComponent,
        ResendEmailVerificationFormComponent,
        ResendPhoneVerificationFormComponent,
        PhoneVerificationFormComponent,
        CountdownComponent,
        GoToTopButtonComponent,
        NotificationBubbleComponent,
        PhoneFieldComponent,
        UserAvatarLoaderComponent,
        LandingpageLoginRegisterButtonsComponent,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        // modals
        GameShareModalDefaultComponent,
        GameShareModalV3Component,
        LoginRegisterModalComponent,
        UsernameModalComponent,
        UsernameModalViewComponent,
        SingleplayerJoinModalComponent,
        // pipes
        DbTranslationsPipe,
        CurrencyFormatPipe,
        GroupByPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        TranslateCutPipe,
        OrdinalNumberPipe,
        DecimalPrizePipe,
        // directives
        RouteTransformerDirective,
        ScrollerDirective,
        VarDirective,
        DynamicComponentHostDirective,
        RemoveWrappingDirective
    ],
    providers: [
        // pipes
        DbTranslationsPipe,
        CurrencyFormatPipe,
        GroupByPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        TranslateCutPipe,
        OrdinalNumberPipe,
        DecimalPrizePipe,
        DecimalPipe
    ]
})
export class SharedModule {
}
