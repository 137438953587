<div class="ui vertical borderless segment padding-b-0 margin-b-0">

    <div class="ui vertical borderless segment padding-0"
         *ngIf="userFriendInvitationsIncoming?.length > 0 || userFriendInvitationsOutgoing?.length > 0">

        <h3 class="text-on-body-bg">
            {{'FRIENDS.REQUESTS.pending_friend_requests' | translate}}
            ({{userFriendInvitationsIncoming?.length + userFriendInvitationsOutgoing?.length}})
        </h3>

        <div class="ui segment"
             *ngFor="let userFriendInvitationIn of userFriendInvitationsIncoming; trackBy: userFriendInvitationsTrackBy">
            <div class="ui grid">
                <div class="middle aligned row padding-0">
                    <div class="three wide mobile three wide tablet three wide computer column">
                        <betsnaps-user-avatar-loader [user]="userFriendInvitationIn.sender_user"
                                              [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                    </div>
                    <div class="eight wide mobile nine wide tablet nine wide computer column">
                        <strong class="truncate">
                            {{userFriendInvitationIn.sender_user.username}}
                        </strong>
                        <span class="tiny">
                            {{'FRIENDS.REQUESTS.incoming_request' | translate}}
                        </span>
                        <p *ngIf="userFriendInvitationIn.message" class="margin-t-5">
                            {{userFriendInvitationIn.message}}
                        </p>
                    </div>
                    <div class="five wide mobile four wide tablet four wide computer right aligned column padding-l-0">
                        <button type="button"
                                (click)="processAcceptInvitation[userFriendInvitationIn.id] = !processAcceptInvitation[userFriendInvitationIn.id]; acceptFriendInvitation(userFriendInvitationIn)"
                                class="ui circular icon button margin-0"
                                [class.loading]="processAcceptInvitation[userFriendInvitationIn.id]"
                                [disabled]="processAcceptInvitation[userFriendInvitationIn.id]">
                            <i class="check icon"></i>
                        </button>
                        <button type="button"
                                (click)="processDeclineInvitation[userFriendInvitationIn.id] = !processDeclineInvitation[userFriendInvitationIn.id]; declineFriendInvitation(userFriendInvitationIn)"
                                class="ui circular icon basic button margin-0 margin-l-5"
                                [class.loading]="processDeclineInvitation[userFriendInvitationIn.id]"
                                [disabled]="processDeclineInvitation[userFriendInvitationIn.id]">
                            <i class="close icon"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui secondary segment"
             *ngFor="let userFriendInvitationOut of userFriendInvitationsOutgoing; trackBy: userFriendInvitationsTrackBy">
            <div class="ui grid">
                <div class="middle aligned row padding-0">
                    <div class="three wide mobile three wide tablet three wide computer column">
                        <betsnaps-user-avatar-loader [user]="userFriendInvitationOut.receiver_user"
                                              [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                    </div>
                    <div class="eight wide mobile nine wide tablet nine wide computer column">
                        <strong class="truncate">
                            {{userFriendInvitationOut.receiver_user?.username}}
                        </strong>
                        <span class="tiny">
                            {{'FRIENDS.REQUESTS.outgoing_request' | translate}}
                        </span>
                    </div>
                    <div class="five wide mobile four wide tablet four wide computer right aligned column padding-l-0">
                        <button type="button"
                                (click)="processDeleteInvitation[userFriendInvitationOut.id] = !processDeleteInvitation[userFriendInvitationOut.id]; deleteFriendInvitation(userFriendInvitationOut)"
                                class="ui circular icon basic button margin-0"
                                [class.loading]="processDeleteInvitation[userFriendInvitationOut.id]"
                                [disabled]="processDeleteInvitation[userFriendInvitationOut.id]">
                            <i class="close icon"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ui vertical borderless segment padding-0" *ngIf="userFriendInvitationsIncomingDeclined?.length > 0">

        <div class="center aligned" *ngIf="!visibleDeclinedFriendInvitations">
            <button type="button" class="ui basic fluid button margin-0"
                    (click)="showDeclinedFriendInvitations()">
                {{'FRIENDS.REQUESTS.show_all_declined_requests' | translate | uppercase}}
            </button>
        </div>

        <div *ngIf="visibleDeclinedFriendInvitations && userFriendInvitationsIncomingDeclined">

            <h3 class="text-on-body-bg">
                {{'FRIENDS.REQUESTS.declined_requests' | translate}} ({{userFriendInvitationsIncomingDeclined.length}})
            </h3>

            <div class="ui segment"
                 *ngFor="let userFriendInvitationInDeclined of userFriendInvitationsIncomingDeclined; trackBy: userFriendInvitationsTrackBy">
                <div class="ui grid">
                    <div class="middle aligned row padding-0">
                        <div class="three wide mobile three wide tablet three wide computer column">
                            <betsnaps-user-avatar-loader [user]="userFriendInvitationInDeclined.sender_user"
                                                  [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                        </div>
                        <div class="eight wide mobile nine wide tablet nine wide computer column">
                            <strong class="truncate">
                                {{userFriendInvitationInDeclined.sender_user?.username}}
                            </strong>
                            <span class="tiny">
                                {{'FRIENDS.REQUESTS.incoming_request' | translate}}
                            </span>
                        </div>
                        <div
                            class="five wide mobile four wide tablet four wide computer right aligned column padding-l-0">
                            <button type="button"
                                    (click)="processAcceptInvitation[userFriendInvitationInDeclined.id] = !processAcceptInvitation[userFriendInvitationInDeclined.id]; acceptFriendInvitation(userFriendInvitationInDeclined)"
                                    class="ui circular icon button margin-0"
                                    [class.loading]="processAcceptInvitation[userFriendInvitationInDeclined.id]"
                                    [disabled]="processAcceptInvitation[userFriendInvitationInDeclined.id]">
                                <i class="check icon"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
