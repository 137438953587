/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameStatisticHttpResponse
 */
export interface AppHttpResponsesStatisticsGamesGameStatisticHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * Game Name
     */
    game_name: string;
    /**
     * Clone number
     */
    clone_number?: number;
    /**
     * Game Start-Date
     */
    start_date?: Date;
    /**
     * Game End-Date
     */
    end_date?: Date;
    /**
     * Competition Type
     */
    competition_type?: number;
    /**
     * Competition Type Name
     */
    competition_type_name?: string;
    /**
     * Original Game Unique Id
     */
    original_game_unique_id?: string;
    /**
     * Entry Fee
     */
    buy_in?: number;
    /**
     * Sum of buy-ins
     */
    buy_in_total?: number;
    /**
     * Sum of pay-out
     */
    pay_out_total?: number;
    /**
     * Revenue
     */
    revenue?: number;
    /**
     * Site Rake
     */
    site_rake?: number;
    /**
     * Betsnaps Rake Share
     */
    betsnaps_rake_share?: number;
    /**
     * Size
     */
    size_max?: number;
    /**
     * Minimum Size
     */
    size_min?: number;
    /**
     * Number of players joined
     */
    users_joined?: number;
    /**
     * Total number of missing players (max-size - joined players)
     */
    users_missing?: number;
    /**
     * Fill rate of the game
     */
    users_fill_percentage?: number;
    /**
     * Number of players that left the game after joining
     */
    users_left?: number;
    /**
     * Max points made by a player
     */
    points_max?: number;
    /**
     * Min points made by a player
     */
    points_min?: number;
    /**
     * Average points made by a player
     */
    points_avg?: number;
    /**
     * Number of pre match bets
     */
    bets_pre_match?: number;
    /**
     * Number of live bets
     */
    bets_live?: number;
    /**
     * Live Bets Percentage
     */
    bets_live_percentage?: number;
    /**
     * Number of bets won (settled)
     */
    bets_won?: number;
    /**
     * Max points placed
     */
    bets_points_max?: number;
    /**
     * Min points placed
     */
    bets_points_min?: number;
    /**
     * Average points placed
     */
    bets_points_avg?: number;
    /**
     * Number of pre match cashouts
     */
    cashouts_pre_match?: number;
    /**
     * Number of live cashouts
     */
    cashouts_live?: number;
    /**
     * Live cashouts Percentage
     */
    cashouts_live_percentage?: number;
    /**
     * Average points made by cashouts
     */
    cashouts_avg_points_made?: number;
    /**
     * Average points lost by cashouts
     */
    cashouts_avg_points_lost?: number;
}

