export function number_format (number, decimals, dec_point, thousands_sep) {

    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    const n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point;
    let s = [];
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/B(?=(?:d{3})+(?!d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function toFixedFix (n, prec) {
    const k = Math.pow(10, prec);
    return '' + Math.round(n * k) / k;
}

export function stringNumberInputToFloat(value) {

    // Build regex to strip out everything except digits, decimal point, commas and minus sign:
    const regex = new RegExp('[^0-9-(-)-,.]', 'g');
    const replacedStringValue =
        ('' + value)
            .replace(regex, '')         // strip out any cruft
            .replace(',', '.')      // make sure decimal point is standard
            .replace(/\(([-]*\d*[^)]?\d+)\)/g, '-$1') // replace bracketed values with negatives
            .replace(/\((.*)\)/, '');   // remove any brackets that do not have numeric value

    const stringArray = replacedStringValue.split('.');
    let finalStringNumber = '';
    if (stringArray.length > 1) {
        stringArray.forEach((numPart, index) => {
            if (index === (stringArray.length - 1)) {
                finalStringNumber += '.' + numPart;
            } else {
                finalStringNumber += numPart;
            }
        });
    } else {
        finalStringNumber = replacedStringValue;
    }

    if (Number.isNaN(Number.parseFloat(value))) {
        finalStringNumber = '0';
    }

    return Number.parseFloat(finalStringNumber);
}
