<div class="ui middle aligned grid margin-0">
    <div class="one column row"
         *ngIf="matchIsFinished(match) &&
                match.score && match.score.match_status_code && match.score.match_status_code.translations &&
                [92, 95, 96].includes(match.score.match_status_code.betradar_status_id)">
        <!-- retired information -->
        <div class="right aligned column padding-b-0">
            <span class="ui basic label center aligned margin-0">
                {{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}}
            </span>
        </div>
    </div>
    <div class="row">
        <div class="two wide column right aligned">
            <div class="ui list stats">
                <div class="item">
                    <span class="ui basic mini label margin-0 vertical-align-middle" *ngIf="match.home?.country_code">
                        {{match.home.country_code}}
                    </span>
                </div>
                <div class="item">
                    <span class="ui basic mini label margin-0 vertical-align-middle" *ngIf="match.away?.country_code">
                        {{match.away.country_code}}
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="(shouldShowScore() ? getTennisColumnClasses()[0] : 'fourteen') + ' wide column left aligned padding-l-0'">
            <div class="ui list team-names">
                <div class="item truncate" [class.font-normal]="matchIsFinished(match) && match.score && match.score.winner_id === match.home_id">
                    <span *ngIf="match.home">
                        {{match.home.name | dbtranslation:'name':match.home?.translations}}
                    </span><span *ngIf="!match.home">
                        {{match.home_name}}
                    </span>
                </div>
                <div class="item truncate" [class.font-normal]="matchIsFinished(match) && match.score && match.score.winner_id === match.away_id">
                    <span *ngIf="match.away">
                        {{match.away.name | dbtranslation:'name':match.away?.translations}}
                    </span>
                    <span *ngIf="!match.away">
                        {{match.away_name}}
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="getTennisColumnClasses()[1] + ' wide column right aligned padding-l-0'" *ngIf="shouldShowScore()">
            <div class="tennis-score-wrap" *ngIf="![91, 93, 94].includes(this.match.score.match_status_code.betradar_status_id)">
                <div class="ui list tennis-score current-server center aligned"
                     *ngIf="match.score.home_game_score !== null && match.score.away_game_score !== null &&
                            match.score.current_server !== null">
                    <div class="item">
                        <span class="ui yellow empty circular mini label margin-0 current-server-dot"
                              *ngIf="match.score.current_server === 1">
                        </span>
                    </div>
                    <div class="item">
                        <span class="ui yellow empty circular mini label margin-0 current-server-dot"
                              *ngIf="match.score.current_server === 2">
                        </span>
                    </div>
                </div><div class="ui list tennis-score game-score center aligned"
                           *ngIf="match.score.home_game_score !== null && match.score.away_game_score !== null &&
                                  !matchIsFinished(match)">
                    <div class="item">
                        {{(match.score.home_game_score > 40) ? 'A' : match.score.home_game_score}}
                    </div>
                    <div class="item">
                        {{(match.score.away_game_score > 40) ? 'A' : match.score.away_game_score}}
                    </div>
                </div><div class="ui list tennis-score period-score center aligned"
                           *ngFor="let period of match.score.periods; trackBy: periodTrackBy; let i = index; let isLast = last"
                           [class.font-very-bold]="(i + 1) === match.score.periods.length && match.status !== 'closed' && match.status !== 'ended'"
                           [class.last-period-score]="isLast">
                    <div class="item"
                         [class.color-grey]="periodIsFinished(i, match)"
                         [class.font-very-bold]="periodIsFinished(i, match) && period.home_score > period.away_score">
                        {{period.home_score}}
                    </div>
                    <div class="item"
                         [class.color-grey]="periodIsFinished(i, match)"
                         [class.font-very-bold]="periodIsFinished(i, match) && period.away_score > period.home_score">
                        {{period.away_score}}
                    </div>
                </div><div class="ui list tennis-score total-score center aligned">
                    <div class="item">
                        {{match.score.home_score}}
                    </div>
                    <div class="item">
                        {{match.score.away_score}}
                    </div>
                </div>
            </div>
            <!-- show walkover information -->
            <div *ngIf="matchIsFinished(match) &&
                        this.match.score.match_status_code.translations &&
                        [91, 93, 94].includes(match.score.match_status_code.betradar_status_id)">
                <span class="ui basic label center aligned margin-0">
                    {{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}}
                </span>
            </div>
        </div>
    </div>
</div>
