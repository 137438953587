import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
    selector: 'betsnaps-game-vendor-game-participation-iframe',
    templateUrl: './game-vendor-game-participation-iframe.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameVendorGameParticipationIframeComponent implements AfterViewInit {

    @Input() gameUrl: string;

    constructor(private ref: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.ref.detach()
    }

}
