<div class="ui fluid flexauto item tiny separatorless menu">
    <a href="#" class="item" [class.active]="menu.key === activeTab" *ngFor="let menu of betsMenu" (click)="$event.preventDefault(); betsMenuChange(menu.key);">
            <span class="display-inline-block vertical-align-middle">
                {{menu.translatekey | translate | uppercase}}
            </span><span class="display-inline-block vertical-align-middle text-mini">&nbsp;({{menu.count}})</span>
    </a>
</div>
<div class="ui basic segment" *ngIf="(processBets || !userBets || !gameMatches) && activeTab !== 'to_place'">
    <div class="ui active centered inline text loader on-body-bg">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processBets && userBets && gameMatches && activeTab !== 'to_place'">
    <div *ngIf="filteredBetsTotal > 0">
        <betsnaps-match-card-loader
            *ngFor="let gameMatch of activeGameMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
            [attr.id]="'match'+ gameMatch.match.match_id"
            [game]="game"
            [isCurrentUserJoined]="game.is_current_user_joined"
            [match]="gameMatch.match"
            [currentUser]="authenticationService.currentUser"
            [compactUserBetView]="true"
            [activeBetsVisible]="activeBetsVisible"
            [completedBetsVisible]="completedBetsVisible"
            [isCancelled]="gameMatch.is_cancel"
            [gameUpdatedDate]="game.updated_at"
            [matchStatus]="gameMatch.match.status"
            [matchIndexInList]="matchIndexInList"
            [adPositions]="adPositions">
        </betsnaps-match-card-loader>
        <betsnaps-match-card-loader
            *ngFor="let gameMatch of closedGameMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
            [attr.id]="'match'+ gameMatch.match.match_id"
            [game]="game"
            [isCurrentUserJoined]="game.is_current_user_joined"
            [match]="gameMatch.match"
            [currentUser]="authenticationService.currentUser"
            [activeBetsVisible]="activeBetsVisible"
            [completedBetsVisible]="completedBetsVisible"
            [isCancelled]="gameMatch.is_cancel"
            [gameUpdatedDate]="game.updated_at"
            [matchStatus]="gameMatch.match.status"
            [matchIndexInList]="matchIndexInList + activeGameMatches().length"
            [adPositions]="adPositions">
        </betsnaps-match-card-loader>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="filteredBetsTotal < 1">
        <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<div *ngIf="widgetBets && activeTab === 'to_place'">
    <div *ngIf="widgetBets.length > 0">
        <betsnaps-widget-bet
            *ngFor="let widgetBet of widgetBets"
            [widgetBet]="widgetBet"
            class="ui fluid card">
        </betsnaps-widget-bet>

        <div class="ui basic segment center aligned margin-0 padding-5" *ngIf="showGameNotificationsCheckbox && !game.is_current_user_joined">
            <form class="ui form" novalidate [formGroup]="gameNotificationsForm">
                <fui-checkbox formControlName="game_notifications_not_joined"
                              class="single-checkbox"
                              [isDisabled]="processWidgetBets">
                    {{'GENERAL.GAMES.subscribe_for_game_notifications' | translate}}
                </fui-checkbox>
            </form>
        </div>

        <div class="ui vertical segment center aligned padding-0 join-place-bets-segment">
            <button type="button" class="ui fluid large button margin-0"
                    *ngIf="game.is_current_user_joined"
                    [class.loading]="processWidgetBets" [disabled]="processWidgetBets"
                    (click)="initiatePlaceWidgetBets()">
                {{(widgetBets.length > 1) ? ('GENERAL.BETS.place-bets' | translate | uppercase) : ('GENERAL.BETS.place-bet' | translate | uppercase)}}
            </button>
            <button type="button" class="ui fluid large button margin-0 game-detail-button"
                    *ngIf="!game.is_current_user_joined"
                    [class.loading]="processWidgetBets" [disabled]="processWidgetBets"
                    (click)="initiatePlaceWidgetBets()">
                <strong>{{'GENERAL.BETS.join_and_place_bets_btn' | translate | uppercase}}</strong>
                <div *ngIf="(game.entry_fee !== 0)" class="small text">
                    {{'GENERAL.GAMES.entry_fee' | translate}}: {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                </div>
                <div *ngIf="(game.entry_fee === 0)" class="small text">
                    {{'GENERAL.GAMES.entry_fee_free' | translate}}
                </div>
            </button>
        </div>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="widgetBets.length < 1">
        <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300"
    [additionalClass]="'betsnapdetail-details-scroll-to-top'"
    [class.notJoined]="!game.is_current_user_joined">
</betsnaps-go-to-top-button>
