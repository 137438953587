<div class="ui container">
    <div class="ui centered one column grid">
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div class="ui inverted transparent padded segment">

                <h1 class="ui center aligned header">
                    <i class="account circle icon"></i>
                    {{'DELETEACCOUNT.heading' | translate}}
                </h1>

                <div class="ui active centered inline text loader" *ngIf="showSpinner">
                    {{'GENERAL.loadertext' | translate}}
                </div>
                <div *ngIf="!showSpinner">
                    <p class="center aligned">{{'DELETEACCOUNT.txt' | translate}}</p>

                    <div class="center aligned">
                        <button (click)="deleteAccount()"
                                [class.loading]="processDeletion"
                                [disabled]="processDeletion"
                                type="button" class="ui button margin-0">
                            {{'DELETEACCOUNT.confirm_btn' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
