/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TenantPrizeTemplateHttpResponse
 */
export interface AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Title
     */
    title: string;
    /**
     * Payload
     */
    payload: string;
    /**
     * Pooling Prize
     */
    is_pooling_prize: boolean;
    /**
     * Contains Unique Prizes
     */
    contains_unique_prizes: boolean;
    /**
     * Self Service Prize Pickup
     */
    self_service_prize_pickup: boolean;
    /**
     * CTA Link URL
     */
    cta_link_url?: string;
    /**
     * Tenant Prize Structure Templates Count
     */
    tenant_prize_structure_templates_count?: number;
    /**
     * Tenant Prizes Count
     */
    tenant_prizes_count: number;
    /**
     * Availble Tenant Prize Structure Count
     */
    available_tenant_prizes_count: number;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

