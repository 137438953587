import {Component, HostListener, Inject, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AuthenticationService,
    BetsnapdetailService,
    ChatService,
    GoogleAnalyticsService, MyNotificationsService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {AngularPageVisibilityService} from 'angular-page-visibility-v2';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {FeedService} from '../../../../shared/services/feed.service';
import {ABetsnapdetailComponent} from '../a-betsnapdetail-component';
import {takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-betsnapdetail-default',
    templateUrl: './betsnapdetail-default.component.html',
    styles: []
})
export class BetsnapdetailDefaultComponent extends ABetsnapdetailComponent {

    private scrollBorder = 73;

    constructor(@Inject(DOCUMENT) protected document: any,
                protected renderer: Renderer2,
                protected activatedRoute: ActivatedRoute,
                protected router: Router,
                protected tenantService: TenantService,
                protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                protected chatService: ChatService,
                protected pageVisibilityService: AngularPageVisibilityService,
                protected translateService: TranslateService,
                protected myNotificationsService: MyNotificationsService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected feedService: FeedService,
                protected windowRef: WindowRef) {
        super(
            document,
            renderer,
            activatedRoute,
            router,
            tenantService,
            authenticationService,
            betsnapdetailService,
            chatService,
            pageVisibilityService,
            translateService,
            myNotificationsService,
            googleAnalyticsService,
            feedService,
            windowRef
        );

        this.gameUpdated$.pipe(takeWhile(() => this.componentAlive))
            .subscribe((gameUpdated: boolean) => {
                if (gameUpdated && this.game) {
                    this.setScrollBorder();
                }
            });
    }

    protected setScrollBorder() {
        const betsnapDetailHeader = this.document.getElementById('betsnapdetail-header');
        if (betsnapDetailHeader) {
            if (this.game && !this.processData && betsnapDetailHeader) {
                this.scrollBorder = betsnapDetailHeader.offsetHeight;
                if (this.windowWidth > 991) {
                    this.scrollBorder += 25;
                }
            }
        }
    }

    protected setSideBarSticky() {
        if (this.betsnapDetailContent) {
            const betsnapDetailContent = this.betsnapDetailContent.nativeElement;

            if (this.currentScrollPosition >= this.scrollBorder &&
                this.windowWidth > 767 &&
                (
                    betsnapDetailContent.offsetHeight > this.leftSidebarSticky.nativeElement.offsetHeight &&
                    betsnapDetailContent.offsetHeight > this.rightSidebarSticky.nativeElement.offsetHeight
                )
            ) {
                this.sidebarSticky = true;
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'position', 'fixed');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'position', 'fixed');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'top', '73px');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'top', '73px');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'left', this.leftSidebar.nativeElement.getBoundingClientRect().left + 'px');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'left', this.rightSidebar.nativeElement.getBoundingClientRect().left + 'px');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'width', this.leftSidebar.nativeElement.offsetWidth + 'px');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'width', this.rightSidebar.nativeElement.offsetWidth + 'px');
            } else {
                this.sidebarSticky = false;
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'position', 'static');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'position', 'static');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'top', 'auto');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'top', 'auto');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'left', 'auto');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'left', 'auto');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'width', 'auto');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'width', 'auto');
            }
        }
    }

    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    @HostListener('window:scroll', [])
    betsnapDetailListeners() {
        this.currentScrollPosition = Math.floor(
            this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
        );
        this.windowWidth = this.nativeWindow.window.innerWidth;
        this.windowHeight = this.nativeWindow.window.innerHeight;
        this.setScrollBorder();
        this.setSideBarSticky();
    }

}
