/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsGamesBasicGameHttpResponse } from './appHttpResponsesBetsnapsGamesBasicGameHttpResponse';


/**
 * GameShareDataHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse { 
    game: AppHttpResponsesBetsnapsGamesBasicGameHttpResponse;
    /**
     * Absolute Share Image URL
     */
    game_share_img_url?: string;
}

