<div class="special-market margin-b-15">
    <div class="ui header special-market-header color-light-grey text-small margin-b-10">
        {{market.market_name | dbtranslation:'name':market.translations | uppercase}}
    </div>
    <div class="special-market-content">
        <div *ngIf="marketOutcomesArray"
             class="ui top aligned centered tight grid"
             [ngClass]="{'equal width': marketOutcomesCount < 4, 'three column': marketOutcomesCount > 3}">
            <div *ngFor="let outcomeRow of marketOutcomesArray; let rowIndex = index; trackBy: outcomesRowTrackBy;"
                 class="row"
                 id="{{match.match_id}}-{{market.market_id}}-{{market.specifier_val}}-{{rowIndex}}">
                <div *ngFor="let outcome of outcomeRow; trackBy: outcomesTrackBy;"
                     class="column">
                    <betsnaps-market-outcome-button-v3
                        *ngIf="outcome"
                        [match]="match"
                        [game]="game"
                        [market]="market"
                        [outcome]="outcome"
                        [matchStatus]="match.status"
                        [marketUpdatedDate]="market.updated_date"
                        [marketStatus]="market.market_status"
                        [outcomeStatus]="outcome.outcome_status"
                        [oddDecimal]="outcome.odd_decimal"
                        [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
                        [gameState]="game.game_state"
                        [liveodds]="match.liveodds"
                        [betPlacementAllowed]="betPlacementAllowed"
                        [isUserBetAvailable]="game.points_engine === gamePointsEngineEnum.PREDICTION ? betsnapdetailService.hasUserBetOnMarketOutcomeSpecifier(match.match_id, market.market_id, outcome.specifier_val, outcome.outcome_id) : false"
                        [forceDisabled]="game.points_engine === gamePointsEngineEnum.PREDICTION ? betsnapdetailService.hasUserBetOnMarketOutcomeSpecifier(match.match_id, market.market_id, outcome.specifier_val) : false"
                        (onPlaceBetClick)="onPlaceBetClick.emit({market: market, outcome: outcome})">
                    </betsnaps-market-outcome-button-v3>
                </div>
            </div>
        </div>
    </div>
</div>
