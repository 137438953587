/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse';


/**
 * PaymentHistoryTransactionHttpResponse
 */
export interface AppHttpResponsesFinancialsAccountingPaymentHistoryTransactionHttpResponse { 
    /**
     * Transaction ID
     */
    id: number;
    /**
     * Transaction Unique ID
     */
    payment_history_transaction_id: string;
    /**
     * User ID
     */
    user_id: number;
    /**
     * Game Unique ID
     */
    game_unique_id?: string;
    /**
     * Description
     */
    description: string;
    /**
     * Reward Code
     */
    reward_code?: string;
    /**
     * Payment Type
     */
    payment_type: number;
    /**
     * Transaction Amount
     */
    transaction_amount: number;
    /**
     * Transaction Amount Currency ID
     */
    transaction_currency_id: number;
    /**
     * Transaction is Bonus Money
     */
    transaction_is_bonus_money: boolean;
    /**
     * Transaction Base Amount
     */
    transaction_base_amount: number;
    /**
     * Transaction Base Currency ID
     */
    transaction_base_currency_id: number;
    /**
     * Transaction Billing Amount
     */
    transaction_billing_amount: number;
    /**
     * Transaction Billing Currency ID
     */
    transaction_billing_currency_id: number;
    /**
     * Tenant Prize Id
     */
    transaction_tenant_prize_id?: number;
    transaction_tenant_prize?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse;
    /**
     * User Balance at Transaction
     */
    user_balance_at_transaction: number;
    /**
     * Payment Transaction ID
     */
    payment_transaction_id?: number;
    /**
     * User Payment Withdraw ID
     */
    user_payment_withdraw_id?: number;
    /**
     * B2b Payment ID
     */
    b2b_payment_id?: string;
    /**
     * Created At
     */
    created_at: Date;
    /**
     * is Processed
     */
    is_processed: number;
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Invoice ID
     */
    invoice_id?: number;
}

