import {Injectable} from '@angular/core';
import {
    AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse as SimpleTenantRankingListHttpResponse,
    AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse as FullTenantRankingHttpResponse,
    AppHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse as TenantRankingPositionListHttpResponse,
    GlobalRankingsApi
} from '../../api';
import {Observable} from 'rxjs';
import {TenantService} from './tenant.service';
import {AuthenticationService} from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class RankingService {

    constructor(private tenantService: TenantService,
                private authenticationService: AuthenticationService,
                private globalRankingsApi: GlobalRankingsApi) {
    }

    getTenantRankings(getHistory: boolean = false, getPositionsCount: boolean = false, getUserRank: boolean = false, perPage: number = undefined, page: number = undefined): Observable<SimpleTenantRankingListHttpResponse> {
        return this.globalRankingsApi.apiTenantsTenantIdRankingsGet(
            this.tenantService.tenantData.id,
            getHistory,
            getUserRank,
            getPositionsCount,
            perPage,
            page,
            this.authenticationService.authToken
        );
    }

    getRankingPositions(tenantRankingId: number, onlyFriends: boolean, currentPage: number, perPage: number): Observable<TenantRankingPositionListHttpResponse> {
        return this.globalRankingsApi.apiTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(
            this.tenantService.tenantData.id,
            tenantRankingId,
            onlyFriends,
            perPage,
            currentPage,
            this.authenticationService.authToken
        );
    }

    getTenantRankingDetails(tenantRankingId: number): Observable<FullTenantRankingHttpResponse> {
        return this.globalRankingsApi.apiTenantsTenantIdRankingsTenantRankingIdGet(
            this.tenantService.tenantData.id,
            tenantRankingId,
            this.authenticationService.authToken
        );
    }

}
