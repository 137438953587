/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * InactivePlayersGameRecordHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsInactivePlayersGameRecordHttpResponse { 
    /**
     * Game Participation Count
     */
    game_participation_count: number;
    /**
     * Finished Inside Prizeranks Count
     */
    finished_inside_prizeranks_count: number;
    /**
     * Gam Wins Count
     */
    game_wins_count: number;
    /**
     * Win Percentage
     */
    win_percentage: number;
    /**
     * Inactive Players Count
     */
    inactive_players_count: number;
}

