import { Component, OnDestroy, OnInit, Directive } from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';

import {
    AppHttpResponsesGeneralCountryListHttpResponse as CountryListHttpResponse,
    AppHttpResponsesGeneralCountryHttpResponse as CountryHttpResponse,
    AppHttpResponsesGeneralStateListHttpResponse as StateListHttpResponse,
    AppHttpResponsesGeneralStateHttpResponse as StateHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../api';
import {
    WindowRef,
    AuthenticationService,
    ProfileService,
    TenantService,
    ErrorService, MyNotificationsService
} from '../../../../shared';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {take, takeWhile} from 'rxjs/operators';

@Directive()
export abstract class AProfileEditComponent implements OnInit, OnDestroy {

    private componentAlive = true;

    public currentUser: PlayerHttpResponse;

    public countries: CountryHttpResponse[];
    public states: StateHttpResponse[] = [];

    public showSpinner: boolean = true;
    public processForm: boolean = false;
    public submitted: boolean = false;

    public profileForm: UntypedFormGroup;

    public profileFormB2c: UntypedFormGroup = new UntypedFormGroup({
        gender: new UntypedFormControl('1', Validators.pattern('1|2')),
        first_name: new UntypedFormControl(''),
        last_name: new UntypedFormControl(''),
        dob: new UntypedFormControl('', Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')),
        zip_code: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        country: new UntypedFormControl('', Validators.pattern('[A-Z]{2}')),
        state_id: new UntypedFormControl('', Validators.pattern('[0-9]+'))
    });

    public isB2b: boolean = false;

    private nativeWindow: Window;

    protected constructor(protected authenticationService: AuthenticationService,
                          protected profileService: ProfileService,
                          protected tenantService: TenantService,
                          protected windowRef: WindowRef,
                          protected router: Router,
                          protected myNotificationsService: MyNotificationsService,
                          protected translateService: TranslateService,
                          protected errorService: ErrorService) {
        this.nativeWindow = windowRef.nativeWindow;
        if (this.authenticationService.currentUser) {
            this.isB2b = this.authenticationService.currentUser.b2b_user_id !== null;
        }

        this.profileForm = this.profileFormB2c;
    }

    onSubmit() {
        this.processForm = true;

        this.profileService.updateUserData(
            this.profileForm,
            this.isB2b
        ).pipe(take(1))
        .subscribe({
            next: (player: PlayerHttpResponse) => {
                this.authenticationService.setCurrentUser(player);

                this.submitted = true;
                this.processForm = false;

                this.translateService.get(['PROFILE.EDIT.updated_successfully'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast('', translation['PROFILE.EDIT.updated_successfully'], NotificationType.Success);
                    });

                this.nativeWindow.window.scrollTo(0, 0);
            },
            error: (err: HttpErrorResponse) => {
                this.processForm = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    ngOnInit() {
        if (this.isB2b) {
            this.router.navigate(['/profile/overview']);
            return;
        }

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
            (userData: PlayerHttpResponse) => {
                if (userData) {
                    if (!this.currentUser) {
                        this.profileForm.patchValue(userData);
                        if (!userData.gender) {
                            this.profileForm.patchValue({'gender': ''});
                        }
                        if (!this.countries) {
                            this.profileService.getCountries()
                                .pipe(take(1))
                                .subscribe({
                                    next: (countryListHttpResponse: CountryListHttpResponse) => {
                                        this.countries = countryListHttpResponse.results;
                                        if (userData.country) {
                                            this.profileForm.patchValue({'country': userData.country});
                                            this.getStatesForCountryIso2(userData.country);
                                        } else {
                                            this.profileForm.patchValue({'country': ''});
                                            this.showSpinner = false;
                                        }
                                    },
                                    error: (err: HttpErrorResponse) => {
                                        this.showSpinner = false;
                                        this.errorService.handleHttpErrorResponse(err);
                                    }
                                });
                        } else {
                            if (userData.country) {
                                this.profileForm.patchValue({'country': userData.country});
                                if (!this.states) {
                                    this.getStatesForCountryIso2(userData.country);
                                } else if (userData.state_id) {
                                    this.profileForm.patchValue({'state_id': userData.state_id});
                                } else {
                                    this.profileForm.patchValue({'state_id': ''});
                                }
                            } else {
                                this.profileForm.patchValue({'country': ''});
                                this.showSpinner = false;
                            }
                        }
                    }
                    this.currentUser = userData;
                }
            }
        );
    }

    getStatesForCountryIso2(countryIso2: string): void {
        this.profileService.getStatesForCountryIso2(
            countryIso2
        ).pipe(take(1))
        .subscribe({
            next: (stateListHttpResponse: StateListHttpResponse) => {
                this.states = stateListHttpResponse.results;
                if (this.currentUser.state_id) {
                    this.profileForm.patchValue({'state_id': this.currentUser.state_id});
                } else {
                    this.profileForm.patchValue({'state_id': ''});
                }
                this.showSpinner = false;
            },
            error: (err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.states = [];
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

}
