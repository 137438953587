/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantPrizeStructureTemplateCreateRequest
 */
export interface AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeStructureTemplateCreateRequest { 
    /**
     * Master Prize Category Id
     */
    prize_category_id: number;
    /**
     * Master Prize Type Id
     */
    prize_type_id: number;
    /**
     * Title
     */
    title: string;
    /**
     * Prize Title in Game
     */
    prize_title_in_game: string;
    /**
     * Minimum players
     */
    minimum_players: number;
    /**
     * Media Id
     */
    media_id?: number;
    /**
     * Is Active
     */
    is_active: boolean;
    /**
     * Is Admin Only
     */
    is_admin_only: boolean;
    /**
     * Is H2h
     */
    is_h2h: boolean;
    /**
     * Is Singleplayer
     */
    is_singleplayer: boolean;
    /**
     * Is Bonus Money
     */
    is_bonus_money: boolean;
    /**
     * Is Battle Royal
     */
    is_battle_royal: boolean;
    /**
     * Is Keep It Friendly
     */
    is_keep_it_friendly: boolean;
}

