/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpRequestsStatisticsUserStatisticRequest } from '../model/appHttpRequestsStatisticsUserStatisticRequest';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsGamesGameStatisticListHttpResponse } from '../model/appHttpResponsesStatisticsGamesGameStatisticListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsBetsCountHttpResponse } from '../model/appHttpResponsesStatisticsTenantsBetsCountHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsCashoutsCountHttpResponse } from '../model/appHttpResponsesStatisticsTenantsCashoutsCountHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsGameFillingDegreeListHttpResponse } from '../model/appHttpResponsesStatisticsTenantsGameFillingDegreeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsInactivePlayersGameRecordHttpResponse } from '../model/appHttpResponsesStatisticsTenantsInactivePlayersGameRecordHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsMonthlyUniqueActivePlayersHttpResponse } from '../model/appHttpResponsesStatisticsTenantsMonthlyUniqueActivePlayersHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsMostSuccessfulPlayersListHttpResponse } from '../model/appHttpResponsesStatisticsTenantsMostSuccessfulPlayersListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsPlayerHttpResponse } from '../model/appHttpResponsesStatisticsTenantsPlayerHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse } from '../model/appHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse } from '../model/appHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse } from '../model/appHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsTenantWinParticipationListHttpResponse } from '../model/appHttpResponsesStatisticsTenantsTenantWinParticipationListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsUnifiedForecastHttpResponse } from '../model/appHttpResponsesStatisticsTenantsUnifiedForecastHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsUniqueWinnersListHttpResponse } from '../model/appHttpResponsesStatisticsTenantsUniqueWinnersListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsTenantsWeeklyUniqueActivePlayersHttpResponse } from '../model/appHttpResponsesStatisticsTenantsWeeklyUniqueActivePlayersHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesStatisticsUsersUserStatisticListHttpResponse } from '../model/appHttpResponsesStatisticsUsersUserStatisticListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class StatisticsApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get Game Statistics
     * Get Game Statistics
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStatisticsGamesGet(from_date: string, to_date?: string, tenant_id?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsGamesGameStatisticListHttpResponse>;
    public apiStatisticsGamesGet(from_date: string, to_date?: string, tenant_id?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsGamesGameStatisticListHttpResponse>>;
    public apiStatisticsGamesGet(from_date: string, to_date?: string, tenant_id?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsGamesGameStatisticListHttpResponse>>;
    public apiStatisticsGamesGet(from_date: string, to_date?: string, tenant_id?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (from_date === null || from_date === undefined) {
            throw new Error('Required parameter from_date was null or undefined when calling apiStatisticsGamesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/statistics/games`;
        return this.httpClient.request<AppHttpResponsesStatisticsGamesGameStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Daily Statistics
     * Get Daily Statistics
     * @param tenant_id Tenant Id
     * @param days_back Days back
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStatisticsTenantsDailyGet(tenant_id?: number, days_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>;
    public apiStatisticsTenantsDailyGet(tenant_id?: number, days_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>>;
    public apiStatisticsTenantsDailyGet(tenant_id?: number, days_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>>;
    public apiStatisticsTenantsDailyGet(tenant_id?: number, days_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }
        if (days_back !== undefined && days_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>days_back, 'days_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/statistics/tenants/daily`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get number of games, matches and players in the upcoming quarters
     * Get number of games, matches and players in the upcoming quarters
     * @param days_forward Analyze Weeks
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStatisticsTenantsGamesforecastGet(days_forward: number, tenant_id?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsUnifiedForecastHttpResponse>;
    public apiStatisticsTenantsGamesforecastGet(days_forward: number, tenant_id?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsUnifiedForecastHttpResponse>>;
    public apiStatisticsTenantsGamesforecastGet(days_forward: number, tenant_id?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsUnifiedForecastHttpResponse>>;
    public apiStatisticsTenantsGamesforecastGet(days_forward: number, tenant_id?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (days_forward === null || days_forward === undefined) {
            throw new Error('Required parameter days_forward was null or undefined when calling apiStatisticsTenantsGamesforecastGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }
        if (days_forward !== undefined && days_forward !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>days_forward, 'days_forward');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/statistics/tenants/gamesforecast`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsUnifiedForecastHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Monthly Tenant Statistics
     * Get Monthly Statistics
     * @param tenant_id Tenant Id
     * @param months_back Months back
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStatisticsTenantsMonthlyGet(tenant_id?: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>;
    public apiStatisticsTenantsMonthlyGet(tenant_id?: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>>;
    public apiStatisticsTenantsMonthlyGet(tenant_id?: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>>;
    public apiStatisticsTenantsMonthlyGet(tenant_id?: number, months_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }
        if (months_back !== undefined && months_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>months_back, 'months_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/statistics/tenants/monthly`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Weekly Statistics
     * Get Weekly Statistics
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks back
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStatisticsTenantsWeeklyGet(tenant_id?: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>;
    public apiStatisticsTenantsWeeklyGet(tenant_id?: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>>;
    public apiStatisticsTenantsWeeklyGet(tenant_id?: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>>;
    public apiStatisticsTenantsWeeklyGet(tenant_id?: number, weeks_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant_id !== undefined && tenant_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant_id, 'tenant_id');
        }
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/statistics/tenants/weekly`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Users Statistics
     * Get all Users Statistics
     * @param app_http_requests_statistics_user_statistic_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStatisticsUsersGet(app_http_requests_statistics_user_statistic_request: AppHttpRequestsStatisticsUserStatisticRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsUsersUserStatisticListHttpResponse>;
    public apiStatisticsUsersGet(app_http_requests_statistics_user_statistic_request: AppHttpRequestsStatisticsUserStatisticRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsUsersUserStatisticListHttpResponse>>;
    public apiStatisticsUsersGet(app_http_requests_statistics_user_statistic_request: AppHttpRequestsStatisticsUserStatisticRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsUsersUserStatisticListHttpResponse>>;
    public apiStatisticsUsersGet(app_http_requests_statistics_user_statistic_request: AppHttpRequestsStatisticsUserStatisticRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (app_http_requests_statistics_user_statistic_request === null || app_http_requests_statistics_user_statistic_request === undefined) {
            throw new Error('Required parameter app_http_requests_statistics_user_statistic_request was null or undefined when calling apiStatisticsUsersGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/statistics/users`;
        return this.httpClient.request<AppHttpResponsesStatisticsUsersUserStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_statistics_user_statistic_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the count of all Bets, Pre-Match Bets, Live Bets
     * Get the count of all Bets, Pre-Match Bets, Live Bets
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks Back
     * @param from_date From Date
     * @param to_date To Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsBetscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsBetsCountHttpResponse>;
    public apiTenantsTenantIdStatisticsBetscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsBetsCountHttpResponse>>;
    public apiTenantsTenantIdStatisticsBetscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsBetsCountHttpResponse>>;
    public apiTenantsTenantIdStatisticsBetscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsBetscountGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/betscount`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsBetsCountHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the count of all Cashouts, Pre-Match Cashouts, Live Cashouts
     * Get the count of all Cashouts, Pre-Match Cashouts, Live Cashouts
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks Back
     * @param from_date From Date
     * @param to_date To Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsCashoutscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsCashoutsCountHttpResponse>;
    public apiTenantsTenantIdStatisticsCashoutscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsCashoutsCountHttpResponse>>;
    public apiTenantsTenantIdStatisticsCashoutscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsCashoutsCountHttpResponse>>;
    public apiTenantsTenantIdStatisticsCashoutscountGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsCashoutscountGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/cashoutscount`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsCashoutsCountHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Daily Tenant Statistics
     * Get Daily Tenant Statistics
     * @param tenant_id Tenant Id
     * @param days_back Days back
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsDailyGet(tenant_id: number, days_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>;
    public apiTenantsTenantIdStatisticsDailyGet(tenant_id: number, days_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>>;
    public apiTenantsTenantIdStatisticsDailyGet(tenant_id: number, days_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>>;
    public apiTenantsTenantIdStatisticsDailyGet(tenant_id: number, days_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsDailyGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (days_back !== undefined && days_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>days_back, 'days_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/daily`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsTenantDailyStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get average snap size and average filling degree weekly
     * Get Game-Sizes and filling degrees
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks Back
     * @param from_date From Date
     * @param to_date To Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsGamesizesGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsGameFillingDegreeListHttpResponse>;
    public apiTenantsTenantIdStatisticsGamesizesGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsGameFillingDegreeListHttpResponse>>;
    public apiTenantsTenantIdStatisticsGamesizesGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsGameFillingDegreeListHttpResponse>>;
    public apiTenantsTenantIdStatisticsGamesizesGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsGamesizesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/gamesizes`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsGameFillingDegreeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get inactive players and their records for a given period
     * Get inactive players and their records for a given period
     * @param tenant_id Tenant Id
     * @param analyze_weeks Analyze Weeks
     * @param weeks_inactive Weeks Inactive
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsInactiveplayersGet(tenant_id: number, analyze_weeks: number, weeks_inactive: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsInactivePlayersGameRecordHttpResponse>;
    public apiTenantsTenantIdStatisticsInactiveplayersGet(tenant_id: number, analyze_weeks: number, weeks_inactive: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsInactivePlayersGameRecordHttpResponse>>;
    public apiTenantsTenantIdStatisticsInactiveplayersGet(tenant_id: number, analyze_weeks: number, weeks_inactive: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsInactivePlayersGameRecordHttpResponse>>;
    public apiTenantsTenantIdStatisticsInactiveplayersGet(tenant_id: number, analyze_weeks: number, weeks_inactive: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsInactiveplayersGet.');
        }
        if (analyze_weeks === null || analyze_weeks === undefined) {
            throw new Error('Required parameter analyze_weeks was null or undefined when calling apiTenantsTenantIdStatisticsInactiveplayersGet.');
        }
        if (weeks_inactive === null || weeks_inactive === undefined) {
            throw new Error('Required parameter weeks_inactive was null or undefined when calling apiTenantsTenantIdStatisticsInactiveplayersGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (analyze_weeks !== undefined && analyze_weeks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>analyze_weeks, 'analyze_weeks');
        }
        if (weeks_inactive !== undefined && weeks_inactive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_inactive, 'weeks_inactive');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/inactiveplayers`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsInactivePlayersGameRecordHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Monthly Tenant Statistics
     * Get Monthly Tenant Statistics
     * @param tenant_id Tenant Id
     * @param months_back Months back
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsMonthlyGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>;
    public apiTenantsTenantIdStatisticsMonthlyGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>>;
    public apiTenantsTenantIdStatisticsMonthlyGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>>;
    public apiTenantsTenantIdStatisticsMonthlyGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsMonthlyGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (months_back !== undefined && months_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>months_back, 'months_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/monthly`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get monthly unique active players
     * Get monthly unique active players
     * @param tenant_id Tenant Id
     * @param months_back Months Back
     * @param from_date From Date
     * @param to_date To Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsMonthlyuapGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsMonthlyUniqueActivePlayersHttpResponse>;
    public apiTenantsTenantIdStatisticsMonthlyuapGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsMonthlyUniqueActivePlayersHttpResponse>>;
    public apiTenantsTenantIdStatisticsMonthlyuapGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsMonthlyUniqueActivePlayersHttpResponse>>;
    public apiTenantsTenantIdStatisticsMonthlyuapGet(tenant_id: number, months_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsMonthlyuapGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (months_back !== undefined && months_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>months_back, 'months_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/monthlyuap`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsMonthlyUniqueActivePlayersHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Most Successful Players Finishes Inside The Prize Ranks
     * Get weekly unique winners
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks Back
     * @param from_date From Date
     * @param to_date To Date
     * @param limit Number of Users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsMostsuccessfulplayersGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, limit?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsMostSuccessfulPlayersListHttpResponse>;
    public apiTenantsTenantIdStatisticsMostsuccessfulplayersGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, limit?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsMostSuccessfulPlayersListHttpResponse>>;
    public apiTenantsTenantIdStatisticsMostsuccessfulplayersGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, limit?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsMostSuccessfulPlayersListHttpResponse>>;
    public apiTenantsTenantIdStatisticsMostsuccessfulplayersGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsMostsuccessfulplayersGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/mostsuccessfulplayers`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsMostSuccessfulPlayersListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get stats for user participation and wins in categories
     * Get stats for user participation and wins in categories
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks Back
     * @param from_date From Date
     * @param to_date To Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsParticipationGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsTenantWinParticipationListHttpResponse>;
    public apiTenantsTenantIdStatisticsParticipationGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsTenantWinParticipationListHttpResponse>>;
    public apiTenantsTenantIdStatisticsParticipationGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsTenantWinParticipationListHttpResponse>>;
    public apiTenantsTenantIdStatisticsParticipationGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsParticipationGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/participation`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsTenantWinParticipationListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the count of all users, all active users and all active users without username
     * Get the count of all users and the count all of them without an username
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsPlayerscountGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsPlayerHttpResponse>;
    public apiTenantsTenantIdStatisticsPlayerscountGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsPlayerHttpResponse>>;
    public apiTenantsTenantIdStatisticsPlayerscountGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsPlayerHttpResponse>>;
    public apiTenantsTenantIdStatisticsPlayerscountGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsPlayerscountGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/playerscount`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsPlayerHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Weekly Tenant Statistics
     * Get Weekly Tenant Statistics
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks back
     * @param from_date Start-Date YYYY-MM-DD
     * @param to_date End-Date YYYY-MM-DD
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsWeeklyGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>;
    public apiTenantsTenantIdStatisticsWeeklyGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>>;
    public apiTenantsTenantIdStatisticsWeeklyGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>>;
    public apiTenantsTenantIdStatisticsWeeklyGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsWeeklyGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/weekly`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsTenantWeeklyStatisticListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get weekly unique active players
     * Get weekly unique active players
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks Back
     * @param from_date From Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsWeeklyuapGet(tenant_id: number, weeks_back?: number, from_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsWeeklyUniqueActivePlayersHttpResponse>;
    public apiTenantsTenantIdStatisticsWeeklyuapGet(tenant_id: number, weeks_back?: number, from_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsWeeklyUniqueActivePlayersHttpResponse>>;
    public apiTenantsTenantIdStatisticsWeeklyuapGet(tenant_id: number, weeks_back?: number, from_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsWeeklyUniqueActivePlayersHttpResponse>>;
    public apiTenantsTenantIdStatisticsWeeklyuapGet(tenant_id: number, weeks_back?: number, from_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsWeeklyuapGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/weeklyuap`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsWeeklyUniqueActivePlayersHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get weekly unique winners
     * Get weekly unique winners
     * @param tenant_id Tenant Id
     * @param weeks_back Weeks Back
     * @param from_date From Date
     * @param to_date To Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdStatisticsWeeklyuwGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesStatisticsTenantsUniqueWinnersListHttpResponse>;
    public apiTenantsTenantIdStatisticsWeeklyuwGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesStatisticsTenantsUniqueWinnersListHttpResponse>>;
    public apiTenantsTenantIdStatisticsWeeklyuwGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesStatisticsTenantsUniqueWinnersListHttpResponse>>;
    public apiTenantsTenantIdStatisticsWeeklyuwGet(tenant_id: number, weeks_back?: number, from_date?: string, to_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdStatisticsWeeklyuwGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (weeks_back !== undefined && weeks_back !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>weeks_back, 'weeks_back');
        }
        if (from_date !== undefined && from_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from_date, 'from_date');
        }
        if (to_date !== undefined && to_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to_date, 'to_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/statistics/weeklyuw`;
        return this.httpClient.request<AppHttpResponsesStatisticsTenantsUniqueWinnersListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
