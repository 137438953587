<div class="header">
    {{'REGISTER.username_input_header' | translate | uppercase}}
</div>
<div class="content">
    <form class="ui form" novalidate [formGroup]="usernameForm" (ngSubmit)="sendUsernameInput()"
          [class.error]="usernameForm.invalid && !processForm">
        <div class="ui one column centered grid">
            <div class="column ui input text center aligned">
                <img class="ui middle aligned right spaced tiny circular bordered secondary image bg-white"
                     [src]="currentUser.media_url_small"/>
            </div>
            <div class="column text center aligned">
                {{'REGISTER.username_input_notice' | translate}}
                <span *ngIf="showUsernameInputNoticeAddition" class="text-small"><br/>{{'REGISTER.username_input_notice_addition' | translate}}</span>
            </div>
            <div class="column ui input text center aligned">
                <input id="username" type="text" placeholder="{{'REGISTER.username_field' | translate}}"
                       formControlName="username" autofocus #username>
                <div class="ui pointing red basic label"
                     *ngIf="usernameForm.get('username').invalid && (usernameForm.get('username').dirty || usernameForm.get('username').touched)">
                    <p *ngIf="usernameForm.get('username').hasError('required')">
                        {{'REGISTER.ERRORS.username_field_required' | translate}}
                    </p>
                    <p *ngIf="usernameForm.get('username').dirty && usernameForm.get('username').hasError('minlength')">
                        {{'REGISTER.ERRORS.username_too_short' | translate}}
                    </p>
                    <p *ngIf="usernameForm.get('username').dirty && usernameForm.get('username').hasError('maxlength')">
                        {{'REGISTER.ERRORS.username_too_long' | translate}}
                    </p>
                    <p *ngIf="usernameForm.get('username').dirty && usernameForm.get('username').hasError('usernameAlphabetical')">
                        {{'ERRORS.VALIDATION.username:validation_error_alphabetical:2' | translate}}
                    </p>
                </div>
            </div>
            <div *ngIf="showConsentText" class="column ui text left aligned">
                <fui-checkbox class="single-checkbox" name="consent_checked" id="consent_checked" formControlName="consent_checked">
                    <betsnaps-cms
                    [cmsKey]="'REGISTER.consent_to_terms_and_privacy_policy'">
                    </betsnaps-cms>
                </fui-checkbox>
            </div>


            <div class="column ui input text center aligned">
                <button type="submit" [class.loading]="processForm" class="ui button very padded"
                        [disabled]="processForm || usernameForm.invalid || (showConsentText && usernameForm.get('consent_checked').value !== true)">
                    {{'GENERAL.save_btn' | translate | uppercase}}
                </button>
            </div>
        </div>
    </form>
</div>
