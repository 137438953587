import { AfterViewInit, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, Directive } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {
    AuthenticationService,
    BetsnapdetailService,
    ChatService,
    GamePointsEngineEnum,
    GoogleAnalyticsService, MyNotificationsService,
    TenantService,
    WidgetBet,
    WindowRef
} from '../../../shared';
import {atobUnicode} from '../../../shared/helpers';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';
import {DOCUMENT} from '@angular/common';
import {filter, take, takeWhile} from 'rxjs/operators';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import * as postscribe from 'betsnap-krux-postscribe';
import {FeedService} from '../../../shared/services/feed.service';
import {BehaviorSubject} from 'rxjs';

@Directive()
export abstract class ABetsnapdetailComponent implements OnInit, OnDestroy, AfterViewInit {

    protected componentAlive = true;

    public processData: boolean = false;

    public currentUser: PlayerHttpResponse;
    public game: GameHttpResponse;

    private gameUpdatedSubject = new BehaviorSubject<boolean>(false);
    public gameUpdated$ = this.gameUpdatedSubject.asObservable();

    public smallMobileHeader: boolean = false;
    public sidebarData = {
        trending: false,
        comments: false,
        share: false,
        game_user: false
    };

    public gamePointsEngineEnum = GamePointsEngineEnum;

    @ViewChild('leftSidebar', {static: true}) protected leftSidebar: ElementRef;
    @ViewChild('rightSidebar', {static: true}) protected rightSidebar: ElementRef;
    @ViewChild('leftSidebarSticky', {static: true}) protected leftSidebarSticky: ElementRef;
    @ViewChild('rightSidebarSticky', {static: true}) protected rightSidebarSticky: ElementRef;

    @ViewChild('betsnapDetailContent') protected betsnapDetailContent: ElementRef;
    @ViewChild('betsnapDetailHeader') protected betsnapDetailHeader: ElementRef;

    protected currentScrollPosition: number = 0;
    public sidebarSticky: boolean = false;

    protected nativeWindow: Window;
    public windowWidth: number = 0;
    protected windowHeight: number = 0;

    constructor(@Inject(DOCUMENT) protected document: any,
                protected renderer: Renderer2,
                protected activatedRoute: ActivatedRoute,
                protected router: Router,
                protected tenantService: TenantService,
                protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                protected chatService: ChatService,
                protected pageVisibilityService: AngularPageVisibilityService,
                protected translateService: TranslateService,
                protected myNotificationsService: MyNotificationsService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected feedService: FeedService,
                protected windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;
    }

    ngOnInit() {
        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (gameHttpResponse) {
                        if (!this.game) {

                            // check for query param
                            this.activatedRoute.queryParams
                                .pipe(take(1)).subscribe((params: Params) => {

                                // unsubscribe from game notifications
                                if (params['notification_unsubscribe'] && params['user']) {
                                    this.betsnapdetailService.unsubscribeFromGameNotifications(
                                        gameHttpResponse.game_unique_id,
                                        params['user'],
                                        params['notification_unsubscribe']
                                    ).pipe(take(1)).subscribe(
                                        () => {
                                            this.translateService.get('GENERAL.GAMES.unsubscribed_from_game_notifications')
                                                .pipe(take(1)).subscribe(
                                                translation => {
                                                    this.myNotificationsService.createNotificationToast(
                                                        '',
                                                        translation,
                                                        NotificationType.Success
                                                    );
                                                });
                                        });
                                }

                                if (params['widgetBets-force'] && gameHttpResponse.game_state < 3) {
                                    localStorage.setItem('widgetBets-force', 'true');
                                }

                                //handle widget parameters
                                if (params['place_bets'] && gameHttpResponse.game_state < 3) {

                                    // clear local storage
                                    if (localStorage.getItem('verification-redirect-url')) {
                                        localStorage.removeItem('verification-redirect-url');
                                    }
                                    if (localStorage.getItem('widgetBets-' + gameHttpResponse.game_unique_id)) {
                                        localStorage.removeItem('widgetBets-' + gameHttpResponse.game_unique_id);
                                    }

                                    // need to load game matches
                                    this.betsnapdetailService.getGameMatches(gameHttpResponse.game_unique_id);
                                    this.betsnapdetailService.gameMatches$
                                        .pipe(takeWhile(() => !this.game))
                                        .subscribe(
                                            (gameMatchListResults: GameMatchHttpResponse[]) => {
                                                if (gameMatchListResults) {
                                                    this.betsnapdetailService.processWidgetBetsFromParameters(gameHttpResponse.game_unique_id, params['place_bets']);
                                                    this.betsnapdetailService.widgetBets$
                                                        .pipe(takeWhile(() => !this.game)).subscribe(
                                                        (widgetBets: WidgetBet[]) => {
                                                            if (widgetBets) {
                                                                if (widgetBets.length < 1) {
                                                                    this.betsnapdetailService.widgetBets = null;
                                                                } else if (!this.authenticationService.currentUser) {
                                                                    // if no loggedin user
                                                                    localStorage.setItem('verification-redirect-url', '/betsnapdetail/' + gameHttpResponse.game_unique_id + '/details?place_bets=' + params['place_bets'] + '&widgetBets-force=true');
                                                                }
                                                                this.finalizeGameInitialize(gameHttpResponse);
                                                            }
                                                        }
                                                    );
                                                }
                                            });
                                } else {

                                    // check if widgetBets exist in local storage
                                    if (localStorage.getItem('widgetBets-' + gameHttpResponse.game_unique_id)) {
                                        // only for not started games
                                        if (gameHttpResponse.game_state < 3) {
                                            this.betsnapdetailService.widgetBets = JSON.parse(atobUnicode(localStorage.getItem('widgetBets-' + gameHttpResponse.game_unique_id)));
                                        } else {
                                            localStorage.removeItem('widgetBets-' + gameHttpResponse.game_unique_id);
                                        }
                                    }

                                    this.finalizeGameInitialize(gameHttpResponse);
                                }
                            });
                        } else {
                            this.game = gameHttpResponse;
                            this.gameUpdatedSubject.next(true);
                        }

                        // handle chat
                        if (!this.chatService.chat &&
                            gameHttpResponse.chat_id > 0 && gameHttpResponse.is_current_user_joined && gameHttpResponse.chat_enabled) {
                            this.chatService.loadChat(gameHttpResponse.chat_id);
                        } else if (this.chatService.chat && !gameHttpResponse.is_current_user_joined) {
                            this.chatService.resetAllData();
                        }
                    } else {
                        this.processData = true;
                        this.game = null;
                    }
                }
            );

        this.activatedRoute.params
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(params => {
                const gameUniqueIdParam = params['game_unique_id'] || null;
                if (gameUniqueIdParam) {
                    this.processData = true;
                    this.betsnapdetailService.getGameData(gameUniqueIdParam, true);
                }
            });

        // handle data provided from routing definitions
        this.handleNavigationData();
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeWhile(() => this.componentAlive)
            ).subscribe(() => {
            this.handleNavigationData();
        });

        // handle page suspends
        this.pageVisibilityService.$onPageVisibilityChange
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                if (this.game) {
                    if (visibilityState === AngularPageVisibilityStateEnum.VISIBLE) {
                        this.refreshGameData();

                        if (this.chatService.chat) {
                            if (this.game.is_current_user_joined) {
                                this.chatService.loadChat(this.chatService.chat.id, true);
                            } else {
                                this.chatService.resetAllData();
                            }
                        }

                        if (this.game.sport_id !== 999) {
                            this.feedService.getFeedProductStatus();
                            this.feedService.subscribeToBroadcastEvents();
                        }

                    } else {
                        this.betsnapdetailService.abortRequestsAndUnsubscribeFromBroadcastEvents();

                        if (this.chatService.chat) {
                            if (this.game.is_current_user_joined) {
                                this.chatService.abortRequestsAndUnsubscribeFromBroadcastEvents();
                            } else {
                                this.chatService.resetAllData();
                            }
                        }

                        if (this.game.sport_id !== 999) {
                            this.feedService.unsubscribeFromBroadcastEvents();
                        }
                    }
                }
            });
    }

    ngAfterViewInit() {
        this.windowWidth = this.nativeWindow.window.innerWidth;
        this.windowHeight = this.nativeWindow.window.innerHeight;
    }

    ngOnDestroy() {

        // clear local storage
        if (localStorage.getItem('verification-redirect-url')) {
            localStorage.removeItem('verification-redirect-url');
        }
        if (localStorage.getItem('widgetBets-force')) {
            localStorage.removeItem('widgetBets-force');
        }
        if (this.game && localStorage.getItem('widgetBets-' + this.game.game_unique_id)) {
            localStorage.removeItem('widgetBets-' + this.game.game_unique_id);
        }

        this.betsnapdetailService.resetAllData();
        this.chatService.resetAllData();

        this.feedService.unsubscribeFromBroadcastEvents();

        this.componentAlive = false;
    }

    protected finalizeGameInitialize(game: GameHttpResponse) {

        if (game.sport_id !== 999 &&
            this.tenantService.tenantData.configuration.show_match_statistics_widget) {
            postscribe('#srWidgetScriptPlaceholder', '<script>\n' +
                '(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,\n' +
                'g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)\n' +
                ')})(window,document,"script", "https://widgets.sir.sportradar.com/cf7e7e946ba74a31484bcdfa66262735/widgetloader", "SIR", {\n' +
                '    theme: false, // using custom theme\n' +
                '    language: "' + this.authenticationService.currentLang.iso_code2 + '"\n' +
                '});' +
                '</script>');
        }

        // get current user updates
        this.authenticationService.currentUser$.pipe(
            takeWhile(() => this.componentAlive)
        ).subscribe(
            (userData: PlayerHttpResponse) => {
                if (userData) {
                    if (!this.currentUser) {
                        if (localStorage.getItem('gameInvitation-' + game.game_unique_id)) {
                            // check local storage for saved game invitation
                            if (!game.is_current_user_joined) {
                                const inviteUserUniqueId = localStorage.getItem('gameInvitation-' + game.game_unique_id);
                                this.betsnapdetailService.writeGameInvitation(inviteUserUniqueId, game.game_unique_id);
                            } else {
                                // remove invitation from local storage
                                localStorage.removeItem('gameInvitation-' + game.game_unique_id);
                            }
                        }
                    }
                    this.currentUser = userData;
                }
            });

        if (game.sport_id !== 999) {
            this.feedService.getFeedProductStatus();
            this.feedService.subscribeToBroadcastEvents();
        }

        this.game = game;
        this.processData = false;

        this.gameUpdatedSubject.next(true);

        if (this.betsnapdetailService.widgetBets && Object.keys(this.betsnapdetailService.widgetBets).length > 0) {
            this.router.navigate(['/betsnapdetail/' + game.game_unique_id + '/bets']);
            return;
        }
    }

    protected handleNavigationData() {
        this.smallMobileHeader = this.activatedRoute.firstChild.snapshot.data['small_mobile_header'] || false;
        this.sidebarData = this.activatedRoute.firstChild.snapshot.data['sidebar_data'];
    }

    protected refreshGameData() {
        this.betsnapdetailService.refreshGameData();
    }
}
