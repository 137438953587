/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ReportGameHttpResponse
 */
export interface AppHttpResponsesReportsReportGameHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Unique ID
     */
    game_unique_id: string;
    /**
     * Name
     */
    game_name: string;
    /**
     * Clone number
     */
    clone_number?: number;
    /**
     * State
     */
    game_state: string;
    /**
     * Start Date
     */
    start_date: Date;
    /**
     * End Date
     */
    end_date: Date;
    /**
     * Is public Game
     */
    is_public: boolean;
    /**
     * Maximum Size
     */
    maximum_size: number;
    /**
     * Minimum Size
     */
    minimum_size: number;
    /**
     * Entry Fee
     */
    entry_fee: number;
    /**
     * Competition Type
     */
    competition_type: string;
    /**
     * Parent Game Unique ID
     */
    parent_game_unique_id?: string;
}

