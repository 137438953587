/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MatchMarketOutcomeUpdateHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchMarketOutcomeUpdateHttpResponse { 
    /**
     * Outcome Id
     */
    outcome_id: number;
    /**
     * Outcome Name
     */
    outcome_name?: string;
    /**
     * Outcome Name Translations
     */
    outcome_name_translations?: string;
    /**
     * Outcome Probability
     */
    outcome_probability_value?: number;
    /**
     * Odd Decimal
     */
    odd_decimal?: number;
    /**
     * Odd Decimal Unlimited
     */
    odd_decimal_unlimited?: number;
    /**
     * Odd Fractional
     */
    odd_fractional?: string;
    /**
     * Odd Moneyline
     */
    odd_moneyline?: string;
    /**
     * Odd Points
     */
    odd_points?: number;
    /**
     * Outcome Status
     */
    outcome_status: number;
    /**
     * should the outcome be visible in market outcome lists
     */
    visibility: boolean;
}

