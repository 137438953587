import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    AppHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest as UserBetPlaceBetBatchRequest,
    AppHttpRequestsBetsnapsGamesUserBetPlaceBetRequest as UserBetPlaceBetRequest,
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchHttpResponse as SimpleSnapMatchHttpResponse,
    AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchMarketHttpResponse as SimpleSnapMatchMarketHttpResponse,
    AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchMarketOutcomeHttpResponse as OutcomeHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse as UserBetPlaceBetBatchResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse as UserBetPlaceBetReportHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    GamesApi
} from '../../../../api';
import {
    AuthenticationService,
    ErrorService,
    GamePointsEngineEnum,
    GoogleAnalyticsService,
    MobiledetectService,
    MyModalService,
    SimpleGameCardExitTypeEnum,
    SimpleSnapBetInterface,
    SimpleSnapDataInterface,
    SimpleSnapMatchDataListInterface,
    SimpleSnapService,
    TenantService,
    WindowRef,
} from '../../../../shared';

import {take, takeWhile} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Transition, TransitionController, TransitionDirection} from '@aligorji/ngx-fomantic-ui';
import {GameCardTileStyleEnum} from '../../../../shared/enums/game-card-tile-style.enum';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from '@duxor/ngx-useful-swiper';

@Component({
    selector: 'betsnaps-simple-game-card',
    templateUrl: './simple-game-card.component.html',
    styles: []
})

export class SimpleGameCardComponent implements OnInit, OnDestroy {

    @Input() game: GameHttpResponse;
    @Input() backgroundImage?: string;
    @Input() secondsToStart?: number;

    protected componentAlive = true;
    protected nativeWindow: Window;

    public loading: boolean = true;
    public showSuccessPage: boolean = false;
    public showWaitingForLoginPage: boolean = false;
    public showErrorPage: boolean = false;
    public showMissingMarketsPage: boolean = false;
    public successPageLoading: boolean = true;
    public successPageStatus: string;
    public successPageBetsErrors: boolean = false;
    public correctScoreSwiperInit: boolean = false;

    public matchList: SimpleSnapMatchHttpResponse[];
    public currentMatchIndex: number = 0;
    public currentBet?: SimpleSnapBetInterface = null;
    public currentMatch: SimpleSnapMatchHttpResponse;
    public categoryImageSrc = './assets/img/match_flags/international.svg';
    public currentMatchMarketCorrectScoreOutcome: OutcomeHttpResponse = null;
    public currentMatchMarketCorrectScoreOptions = [];
    public currentMatchMarketCorrectScoreOptionsSelected: string = '0:0';
    public currentMatchMarketCorrectScoreValueHome: number = 0;
    public currentMatchMarketCorrectScoreValueAway: number = 0;
    public currentMatchMarketCorrectScoreValuesAreValid: boolean = true;
    public isCorrectScoreWidgetCompatibleMarket: boolean = false;

    public transitionController = new TransitionController();

    public swipeLeftEnabled: boolean = false;
    public swipeRightEnabled: boolean = false;

    public gameCardTileStyleEnum = GameCardTileStyleEnum;

    public correctScoreHomeSwiperConfig: SwiperOptions;
    public correctScoreAwaySwiperConfig: SwiperOptions;

    private transitionName = 'horizontal flip';

    @ViewChild('correctScoreHomeSwiper') correctScoreHomeSwiper: SwiperComponent;
    @ViewChild('correctScoreAwaySwiper') correctScoreAwaySwiper: SwiperComponent;

    @Output() close: EventEmitter<string> = new EventEmitter();

    constructor(protected authenticationService: AuthenticationService,
                protected errorService: ErrorService,
                protected router: Router,
                public simpleSnapService: SimpleSnapService,
                public tenantService: TenantService,
                protected myModalService: MyModalService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected translateService: TranslateService,
                protected mobileDetect: MobiledetectService,
                protected gamesApi: GamesApi,
                protected windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;
        this.currentMatchMarketCorrectScoreOptions['home'] = [];
        this.currentMatchMarketCorrectScoreOptions['away'] = [];
    }

    ngOnInit() {
        if ((this.mobileDetect.isIOS() && this.mobileDetect.isMobile())
            || this.tenantService.isWrapperIos) {
            this.transitionName = 'fade';
        }

        if (!this.tenantService.tenantData.configuration.allow_simple_snaps) {
            this.exitWithError();
        }

        if (this.authenticationService.currentUser) {
            this.googleAnalyticsService.trackEvent('simple snap card', 'init', this.game.game_unique_id);
        } else {
            this.googleAnalyticsService.trackEvent('simple snap card', 'init not logged in', this.game.game_unique_id);
        }

        // generate swiper configuration options
        this.correctScoreHomeSwiperConfig = this.getSwiperConfig(this.game.game_unique_id, 'home');
        this.correctScoreAwaySwiperConfig = this.getSwiperConfig(this.game.game_unique_id, 'away');

        this.simpleSnapService.simpleSnapData$
            .pipe(takeWhile(() => this.componentAlive && !this.matchList))
            .subscribe((simpleSnapData: SimpleSnapDataInterface[]) => {
                if (simpleSnapData && simpleSnapData.length > 0) {
                    const simpleSnapDataForGame = simpleSnapData.find(
                        (simpleSnapDataEntry: SimpleSnapDataInterface) => (
                            simpleSnapDataEntry.gameUniqueId === this.game.game_unique_id
                        ));
                    if (simpleSnapDataForGame) {
                        const simpleSnapMatchDataListForLang = simpleSnapDataForGame.matchData.find((simpleSnapMatchData: SimpleSnapMatchDataListInterface) => (simpleSnapMatchData.lang === this.authenticationService.currentLang.iso_code2));
                        if (simpleSnapMatchDataListForLang) {
                            if (simpleSnapMatchDataListForLang.matchList.length === 0) {
                                this.googleAnalyticsService.trackEvent('simple snap card', 'init no markets', this.game.game_unique_id);
                                if (this.game.simple_snap_start_with_first_bet) {
                                    this.exitWithError();
                                } else {
                                    this.exitAndGoToDetailsPage();
                                }
                            } else {
                                this.loading = false;
                                this.matchList = simpleSnapMatchDataListForLang.matchList;
                                if (this.game.competition_type !== 5 || this.allSimpleMarketsAvailable()) {
                                    this.setCurrentMatch();
                                } else {
                                    this.exitMissingMarkets();
                                    this.showMissingMarketsPage = true;
                                }
                            }
                        }
                    }
                }
            });

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (userData && this.showWaitingForLoginPage) {
                        // check if simple snap card is on waiting fo login page, if yes: exit simple snap card process
                        this.exit();
                    }
            });

        this.simpleSnapService.loadMatchesDataIfNecessary(
            this.game.game_unique_id,
            this.authenticationService.currentLang.iso_code2
        );
    }

    public storeBet(betOutcomeId: number, oddDecimal: number): void {

        this.googleAnalyticsService.trackEvent('simple snap card', 'store bet', this.game.game_unique_id);

        this.simpleSnapService.storeBet(
            this.game.game_unique_id,
            this.currentMatch.match_id,
            this.currentMatch.markets[0].market_id,
            this.currentMatch.markets[0].specifier_val,
            betOutcomeId,
            oddDecimal
        );

        this.nextMatch();
    }

    protected setCurrentMatch(): void {
        if (this.matchList && this.matchList[this.currentMatchIndex]) {
            this.currentMatch = this.matchList[this.currentMatchIndex];
            if (this.currentMatch) {
                if (this.currentMatch.tournament?.category_icon) {
                    this.categoryImageSrc = './assets/img/match_flags/' + this.currentMatch.tournament.category_icon;
                }
                this.currentBet = this.simpleSnapService.getBet(
                    this.game.game_unique_id,
                    this.currentMatch.match_id,
                    this.currentMatch.markets[0].market_id,
                    this.currentMatch.markets[0].specifier_val);
                this.swipeRightEnabled = (this.isSwipeEnabled() && this.currentMatchIndex !== 0);
                // "this.currentBet != null" is intentionally to test both null and undefined
                this.swipeLeftEnabled = (this.isSwipeEnabled() && this.currentBet != null);

                // handle correct score market
                this.isCorrectScoreWidgetCompatibleMarket = this.getIsCorrectScoreWidgetCompatibleMarket(this.currentMatch.markets[0]);
                if (this.isCorrectScoreWidgetCompatibleMarket) {
                    const homeCorrectScoreOptions = [];
                    const awayCorrectScoreOptions = [];
                    // evaluate possible home / away score combinations
                    this.currentMatch.markets[0].outcomes.forEach((outcome: OutcomeHttpResponse) => {
                        const outcomeSplit = outcome.outcome_name.split(':');
                        if (outcomeSplit.length === 2) {
                            if (!(Number(outcomeSplit[0]) in homeCorrectScoreOptions)) {
                                homeCorrectScoreOptions[Number(outcomeSplit[0])] = [];
                            }
                            if (!(Number(outcomeSplit[1]) in awayCorrectScoreOptions)) {
                                awayCorrectScoreOptions[Number(outcomeSplit[1])] = [];
                            }
                            homeCorrectScoreOptions[Number(outcomeSplit[0])].push(Number(outcomeSplit[1]));
                            awayCorrectScoreOptions[Number(outcomeSplit[1])].push(Number(outcomeSplit[0]));
                        }
                    });
                    this.currentMatchMarketCorrectScoreOptions['home'] = homeCorrectScoreOptions;
                    this.currentMatchMarketCorrectScoreOptions['away'] = awayCorrectScoreOptions;
                    if (!this.correctScoreSwiperInit) {
                        this.correctScoreSwiperInit = true;
                    } else {
                        this.loadCurrentBetToCorrectScoreWidget();
                    }
                }
            }
        }
    }

    private isSwipeEnabled() {
        return (this.nativeWindow.window.innerWidth <= 991) && !this.loading && (!this.showSuccessPage && !this.showErrorPage && !this.showWaitingForLoginPage);
    }

    private getIsCorrectScoreWidgetCompatibleMarket(market: SimpleSnapMatchMarketHttpResponse): boolean {
        if (market.market_id === 41 || market.market_id === 100041) {
            return true;
        }

        if (market.market_id === 199 && market.specifier_val.includes("sr_s_correct_s_score_s_max_s_")) {
           //  return true;
        }

        return false;
    }

    public nextMatch(): void {
        if (!this.isLastPage()) {
            this.currentMatchIndex++;
            if (this.currentMatchIndex > 0) {
                this.animateCardTransition();
            } else {
                this.setCurrentMatch();
            }
        } else {
            this.animateCardTransition(true);
        }
    }

    public previousMatch(): void {
        if (this.currentMatchIndex > 0) {
            this.currentMatchIndex--;
            this.animateCardTransition();
        }
    }

    public isLastPage(): boolean {
        return (this.currentMatchIndex + 1) >= this.matchList.length;
    }

    public finish(): void {
        this.googleAnalyticsService.trackEvent('simple snap card', 'init finish', this.game.game_unique_id);
        if (!this.authenticationService.currentUser) {
            this.googleAnalyticsService.trackEvent('simple snap card', 'init finish not logged in', this.game.game_unique_id);
            this.showWaitingForLoginPage = true;
            const widgetBetsRedirectUrl = this.buildWidgetBetRedirectUrl();
            localStorage.setItem('verification-redirect-url', widgetBetsRedirectUrl);
            this.myModalService.openLoginRegisterModal('login', widgetBetsRedirectUrl, false, 'simple snap card');
        } else if (!this.authenticationService.currentUser.username || !this.authenticationService.currentUser.is_username_confirmed) {
            this.googleAnalyticsService.trackEvent('simple snap card', 'init finish no username', this.game.game_unique_id);
            this.myModalService.openUsernameInputModal().onApprove(() => this.joinGameAndPlaceBets());
        } else {
            this.joinGameAndPlaceBets();
        }
    }

    private getPointsPerBet(simpleSnapBets: SimpleSnapBetInterface[]): number {

        let pointsToPlace;
        if (this.game.points_engine === GamePointsEngineEnum.PREDICTION) {
            pointsToPlace = 1.00;
        } else {
            pointsToPlace = Math.floor(this.game.widget_game_points / simpleSnapBets.length);
        }

        return pointsToPlace;
    }

    private joinGameAndPlaceBets(): void {

        if (!this.authenticationService.currentUser) {
            return;
        }

        this.googleAnalyticsService.trackEvent('simple snap card', 'start join and place bets', this.game.game_unique_id);

        this.translateService.get(['GENERAL.GAMES.SIMPLE.success_loading_join', 'GENERAL.GAMES.SIMPLE.success_loading_bets'])
            .pipe(take(1)).subscribe(
            translation => {

                const messageLoadingJoin = translation['GENERAL.GAMES.SIMPLE.success_loading_join'];
                const messageLoadingBets = translation['GENERAL.GAMES.SIMPLE.success_loading_bets'];

                this.showWaitingForLoginPage = false;
                this.showSuccessPage = true;
                this.successPageStatus = messageLoadingJoin;

                this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(
                    this.tenantService.tenantData.id,
                    this.game.game_unique_id,
                    this.authenticationService.currentUser.id,
                ).pipe(take(1))
                .subscribe({
                    next: (newGameUser: AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse) => {
                        this.googleAnalyticsService.trackEvent('simple snap card', 'join success', this.game.game_unique_id);
                        const simpleSnapBets = this.simpleSnapService.getBets(this.game.game_unique_id);
                        if (simpleSnapBets.length > 0) {
                            this.successPageStatus = messageLoadingBets;
                            const pointsToPlace = this.getPointsPerBet(simpleSnapBets);
                            const betBatch = [];
                            simpleSnapBets.forEach((simpleSnapBet: SimpleSnapBetInterface) => {
                                const betBody: UserBetPlaceBetRequest = {
                                    'match_id': simpleSnapBet.matchId,
                                    'market_id': simpleSnapBet.marketId,
                                    'specifier': simpleSnapBet.specifierVal,
                                    'outcome_id': simpleSnapBet.outcomeId,
                                    'points': pointsToPlace,
                                    'odd': simpleSnapBet.oddDecimal
                                };

                                betBatch.push(betBody);
                            });

                            const betBatchBody: UserBetPlaceBetBatchRequest = {
                                'allow_failed_bets': true,
                                'bets': betBatch
                            };

                            this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost(
                                this.tenantService.tenantData.id,
                                this.game.game_unique_id,
                                this.authenticationService.currentUser.id,
                                betBatchBody
                            ).pipe(take(1))
                            .subscribe({
                                next: (userBetPlaceBetBatchResponse: UserBetPlaceBetBatchResponse) => {
                                    if (userBetPlaceBetBatchResponse) {
                                        if (userBetPlaceBetBatchResponse.report.find((userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) => userBetPlaceBetReport.bet_place_status === 'error')) {
                                            this.placeBetBatchFailed();
                                        } else {
                                            this.googleAnalyticsService.trackEvent('simple snap card', 'place bets success', this.game.game_unique_id);
                                            this.successPageLoading = false;
                                            this.successPageStatus = null;
                                            this.exitWithTimeout();
                                        }
                                    }
                                },
                                error: (err: HttpErrorResponse) => {
                                    this.placeBetBatchFailed();
                                }
                            });
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        this.googleAnalyticsService.trackEvent('simple snap card', 'join error', this.game.game_unique_id);
                        if (err.error.error === 'game_join_game_battle_royal_missing_markets') {
                            this.showMissingMarketsPage = true;
                            this.showSuccessPage = false;
                        } else {
                            this.showErrorPage = true;
                            this.showSuccessPage = false;
                        }
                    }
                });
            });
    }

    private placeBetBatchFailed(): void {
        this.googleAnalyticsService.trackEvent('simple snap card', 'place bets error', this.game.game_unique_id);
        this.successPageLoading = false;
        this.successPageStatus = null;
        this.successPageBetsErrors = true;
        this.exitWithTimeout(10);
    }

    private buildWidgetBetRedirectUrl(): string {

        const simpleSnapBets = this.simpleSnapService.getBets(this.game.game_unique_id);
        const pointsToPlace = this.getPointsPerBet(simpleSnapBets);

        let placeBetsString = '';
        simpleSnapBets.forEach((simpleSnapBet: SimpleSnapBetInterface) => {
            if (placeBetsString !== '') {
                placeBetsString += ',';
            }
            placeBetsString += simpleSnapBet.matchId + '|' + simpleSnapBet.marketId + '|' + simpleSnapBet.specifierVal + '|' + simpleSnapBet.outcomeId + '|' + pointsToPlace;
        });

        return '/betsnapdetail/' + this.game.game_unique_id + '/details?place_bets=' + placeBetsString + '&widgetBets-force=true';
    }

    public goToSnapDetailPage() {
        this.googleAnalyticsService.trackEvent('simple snap card', 'go to snap details', this.game.game_unique_id);
        this.router.navigate(['betsnapdetail', this.game.game_unique_id, 'matches']);
    }

    protected resetComponentData(): void {
        this.simpleSnapService.resetDataForGame(this.game.game_unique_id);
    }

    protected exitWithTimeout(timeoutInSeconds: number = 5): void {
        const self = this;
        setTimeout(function () {
            if (self.showSuccessPage) {
                self.exit();
            }
        }, (timeoutInSeconds * 1000));
    }

    public exitWithError(): void {
        this.exit(SimpleGameCardExitTypeEnum.ERROR);
    }

    public exitAndGoToDetailsPage(): void {
        this.exit(SimpleGameCardExitTypeEnum.EXIT_AND_TO_DETAILS);
    }

    public exitMissingMarkets(): void {
        this.exit(SimpleGameCardExitTypeEnum.MISSING_MARKETS);
    }

    protected exit(exitType: string = SimpleGameCardExitTypeEnum.SUCCESS) {
        this.resetComponentData();
        this.close.emit(exitType);
    }

    public animateCardTransition(finish: boolean = false) {
        this.transitionController.animate(
            new Transition(this.transitionName, 250, TransitionDirection.Out,
                () => {
                if (finish) {
                    this.finish();
                } else {
                    this.setCurrentMatch();
                }
                this.transitionController.animate(
                    new Transition(this.transitionName, 250, TransitionDirection.In));
            }));
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    private loadCurrentBetToCorrectScoreWidget() {
        if (this.correctScoreHomeSwiper?.swiper && this.correctScoreAwaySwiper?.swiper) {
            // if a current user bet is found select the values in the swiper
            if (this.currentBet) {
                const matchMarketCorrectScoreOutcome = this.currentMatch.markets[0].outcomes.find((outcome: OutcomeHttpResponse) => (outcome.outcome_id === this.currentBet.outcomeId));
                const outcomeSplit = matchMarketCorrectScoreOutcome.outcome_name.split(':');
                if (outcomeSplit.length === 2) {
                    this.correctScoreHomeSwiper?.swiper.slideTo(Number(outcomeSplit[0]));
                    this.correctScoreAwaySwiper?.swiper.slideTo(Number(outcomeSplit[1]));
                    // setting correct slide again because not all options are always available from the beginning
                    setTimeout(() => {
                        this.correctScoreHomeSwiper?.swiper.slideTo(Number(outcomeSplit[0]));
                        this.correctScoreAwaySwiper?.swiper.slideTo(Number(outcomeSplit[1]));
                    }, 50);
                }
            } else {
                this.correctScoreHomeSwiper?.swiper.slideTo(0);
                this.correctScoreAwaySwiper?.swiper.slideTo(0);
            }
        }
    }

    private checkSwiperValues() {
        // set the selected values for home and away score
        if (this.correctScoreHomeSwiper?.swiper && this.correctScoreAwaySwiper?.swiper) {
            this.currentMatchMarketCorrectScoreValueHome = Number(Object.keys(this.currentMatchMarketCorrectScoreOptions['home'])[this.correctScoreHomeSwiper.swiper.activeIndex]);
            this.currentMatchMarketCorrectScoreValueAway = Number(Object.keys(this.currentMatchMarketCorrectScoreOptions['away'])[this.correctScoreAwaySwiper.swiper.activeIndex]);
            this.currentMatchMarketCorrectScoreOptionsSelected = this.currentMatchMarketCorrectScoreValueHome + ':' + this.currentMatchMarketCorrectScoreValueAway;
        } else {
            this.currentMatchMarketCorrectScoreValueHome = 0;
            this.currentMatchMarketCorrectScoreValueAway = 0;
            this.currentMatchMarketCorrectScoreOptionsSelected = '0:0';
        }

        // check if provided values are valid and find related outcome
        this.currentMatchMarketCorrectScoreValuesAreValid = (this.currentMatchMarketCorrectScoreOptions['home'][this.currentMatchMarketCorrectScoreValueHome].indexOf(this.currentMatchMarketCorrectScoreValueAway) !== -1) && (this.currentMatchMarketCorrectScoreOptions['away'][this.currentMatchMarketCorrectScoreValueAway].indexOf(this.currentMatchMarketCorrectScoreValueHome) !== -1);
        if (this.currentMatchMarketCorrectScoreValuesAreValid) {
            this.currentMatchMarketCorrectScoreOutcome = this.currentMatch.markets[0].outcomes.find((outcome: OutcomeHttpResponse) => outcome.outcome_name === this.currentMatchMarketCorrectScoreOptionsSelected);
        }
    }

    // check if match markets count is same as game simple markets count
    private allSimpleMarketsAvailable(): boolean {
        let availableMarkets = 0;
        this.matchList.forEach((match: SimpleSnapMatchHttpResponse) => {
            availableMarkets += match.markets.length;
        });

        return this.game.simple_markets_count === availableMarkets;
    }

    // creates a valid home or away swiper options configuration
    private getSwiperConfig(uniqueId: string, type: string): SwiperOptions {
        let onEvents;

        if (type === 'away') {
            onEvents = {
                activeIndexChange: () => {
                    this.checkSwiperValues();
                },
                afterInit: () => {
                    this.checkSwiperValues();
                    // delay as swiper initialization takes some time
                    setTimeout(() => {
                        this.loadCurrentBetToCorrectScoreWidget();
                    }, 50);
                }
            };
        } else {
            onEvents = {
                activeIndexChange: () => {
                    this.checkSwiperValues();
                }
            };
        }

        return {
            navigation: {
                nextEl: '.correct-score-' + type + '-swiper-navigation-button-next-' + uniqueId,
                prevEl: '.correct-score-' + type + '-swiper-navigation-button-prev-' + uniqueId,
                disabledClass: 'disabled'
            },
            direction: 'vertical',
            height: 50,
            slidesPerView: 1,
            mousewheel: {
                invert: true
            },
            loop: false,
            simulateTouch: true,
            effect: 'coverflow',
            on: onEvents
        };
    }
}
