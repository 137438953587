<div class="ui tertiary borderless segment" *ngIf="game.is_current_user_joined && game?.game_user && game.game_state !== 99">
    <div class="ui middle aligned centered grid container">
        <div class="row" *ngIf="game.game_state > 2 && (game.competition_type === 1 || game.competition_type === 3) && game.game_user.is_participation_valid !== false">
            <div class="sixteen wide column center aligned">
                <button class="ui big label display-inline-block vertical-align-middle margin-t-0 margin-b-0 margin-l-0"
                        title="{{gameUserPointsChanged ? 'BETSNAPDETAIL.GAMEUSER.POINTS.refresh' : '' | translate}}"
                        *ngIf="(game.competition_type === 1 && game.game_user.rank) || (game.competition_type === 3 && game.game_user.game_user_group?.rank)"
                        [class.loading]="processData"
                        [ngClass]="{
                            'positive': (!gameUserPointsChanged) && ((game.competition_type === 1 && game.game_user.rank === 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank === 1)),
                            'negative': (!gameUserPointsChanged) && ((game.competition_type === 1 && game.game_user.rank > 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank > 1)),
                            'button calltoaction padding-t-0 padding-b-0': (gameUserPointsChanged)
                        }"
                        (click)="refreshGameData();
                                googleAnalyticsService.trackEvent('game - details - sidebar', 'click', 'refresh user game points');">
                    <span *ngIf="gameUserPointsChanged"><i class="refresh icon big"></i><br/></span>
                    <span *ngIf="(game.competition_type === 1 && game.game_user.rank > 0)">{{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span></span>
                    <span *ngIf="(game.competition_type === 3 && game.game_user.game_user_group?.rank > 0)">{{game.game_user.game_user_group.rank}}<span class="text-mini">{{game.game_user.game_user_group.rank | ordinalNumber:true}}</span></span>
                    <span *ngIf="(game.competition_type === 1 && game.game_user.rank < 1) || (game.competition_type === 3 && game.game_user.game_user_group?.rank < 1)">-</span>
                </button>
                <span class="display-inline-block vertical-align-middle text-tiny" *ngIf="h2hCompetitor">
                    &nbsp;{{'GENERAL.GAMES.h2h_vs' | translate}}&nbsp;
                </span><span class="display-inline-block vertical-align-middle" *ngIf="h2hCompetitor">
                    <betsnaps-user-avatar-loader *ngIf="h2hCompetitor" [user]="h2hCompetitor.user" [imageSize]="'mini'"></betsnaps-user-avatar-loader>
                </span><span class="display-inline-block vertical-align-middle font-very-bold" *ngIf="h2hCompetitor && h2hCompetitor.is_participation_valid !== false"
                    [ngClass]="{
                        'color-positive': (game.competition_type === 1 && game.game_user.rank === 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank === 1),
                        'color-negative': (game.competition_type === 1 && game.game_user.rank > 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank > 1)
                    }">
                    &nbsp;{{((game.game_user.total_score - h2hCompetitor.total_score) > 0) ? '+' : ''}}{{(game.game_user.total_score - h2hCompetitor.total_score) | number:'1.2-2'}}
                </span>
            </div>
        </div>
        <div class="row" *ngIf="game.game_state > 2 && (game.competition_type === 2 || game.game_user.is_participation_valid === false)">
            <div class="eight wide column center aligned">
                <button class="ui big label margin-t-0 margin-b-0 margin-l-0"
                        title="{{gameUserPointsChanged ? 'BETSNAPDETAIL.GAMEUSER.POINTS.refresh' : '' | translate}}"
                        *ngIf="game.game_user.rank"
                        [class.text-large]="game.game_user.rank > 9999"
                        [class.loading]="processData"
                        [ngClass]="{'button calltoaction padding-t-0 padding-b-0': (gameUserPointsChanged)}"
                        (click)="refreshGameData();
                                 googleAnalyticsService.trackEvent('game - details - sidebar', 'click', 'refresh user game points');">
                    <span *ngIf="gameUserPointsChanged"><i class="refresh icon big"></i><br/></span>
                    <span *ngIf="game.game_user.rank > 0">{{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span></span>
                    <span *ngIf="game.game_user.rank < 1">-</span>
                </button>
            </div>
            <div class="eight wide column center aligned" *ngIf="game.game_user.rank === 1 && game.game_user.points_in_lead !== null && game.game_user.is_participation_valid !== false">
                <span class="game-user-pointvalue">{{game.game_user.points_in_lead | number:'1.2-2'}}</span>
                <span class="game-user-label truncate">{{'GENERAL.GAMES.POINTS.points_in_lead' | translate}}</span>
            </div>
            <div class="eight wide column center aligned" *ngIf="(game.game_user.rank > 1 || (game.game_user.rank === 1 && game.game_user.points_in_lead === null)) && game.game_user.is_participation_valid !== false">
                <span class="game-user-pointvalue">{{game.game_user.points_to_first | number:'1.2-2'}}</span>
                <span class="game-user-label truncate">{{'GENERAL.GAMES.POINTS.points_to_first' | translate}}</span>
            </div>
            <div class="eight wide column center aligned"
                 *ngIf="game.game_user.is_participation_valid === false">
                <button type="button"
                        class="ui circular icon basic inverted mini button margin-0"
                        fuiPopup
                        [popupTemplate]="popupNoValidBetInfo"
                        popupTrigger="outsideClick"
                        popupPlacement="bottom right">
                    <i class="fa fa-info small icon"></i>
                </button>

                <ng-template let-popup #popupNoValidBetInfo>
                    <div class="content no-valid-bet-info-popup">
                        {{(game.game_state < 4) ? ('GENERAL.GAMES.no_valid_bet_placed_yet' | translate) : ('GENERAL.GAMES.no_valid_bet' | translate)}}
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="eight wide column center aligned">
                <span class="game-user-pointvalue">{{game.game_user.total_score | number:'1.2-2'}}</span>
                <span class="game-user-label truncate">{{'GENERAL.GAMES.POINTS.total_points' | translate}}</span>
            </div>
            <div class="eight wide column center aligned">
                <span class="game-user-pointvalue">{{game.game_user.user_game_points | number:'1.2-2'}}</span>
                <span class="game-user-label truncate">{{'GENERAL.GAMES.POINTS.free_points' | translate}}</span>
            </div>
        </div>
    </div>
</div>
