import {AbstractControl, ValidationErrors} from '@angular/forms';

export function emailValidator(control): { [key: string]: any } {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (control.value || control.valid === '' || re.test(control.value)) {
        return null;
    } else {
        return {
            'invalidEmail': true
        };
    }
}

export function areEqualValidator(c: AbstractControl): ValidationErrors | null {
    const keys: string[] = Object.keys(c.value);
    for (const i in keys) {
        if (i !== '0' && c.value[keys[+i - 1]] !== c.value[keys[i]]) {
            return {
                'areEqual': true
            };
        }
    }
}

export function usernameAlphabeticalValidator(c: AbstractControl): ValidationErrors | null {
    if (c.value) {
        const alphabeticalChars = c.value.match(/[a-zA-Z]/g);
        if (!alphabeticalChars || alphabeticalChars.length < 2) {
            return {
                'usernameAlphabetical': true
            };
        }
    }
    return null;
}
