/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantSeoHttpResponse
 */
export interface AppHttpResponsesTenantsTenantSeoHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Meta Title
     */
    meta_title?: string;
    /**
     * Meta Description
     */
    meta_description?: string;
}

