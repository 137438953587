<div class="ui fluid flexauto item tiny separatorless menu">
    <a href="#" class="item" [class.active]="menu.active" *ngFor="let menu of gameVendorGamesMenu" (click)="$event.preventDefault(); gameVendorGamesMenuChange(menu.key);">
            <span class="display-inline-block vertical-align-middle">
                {{menu.translatekey | translate | uppercase}}
            </span><span class="display-inline-block vertical-align-middle text-mini">&nbsp;({{menu.count}})</span>
    </a>
</div>
<div class="ui basic segment" *ngIf="processGameVendorGames">
    <div class="ui active centered inline text loader on-body-bg">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processGameVendorGames">
    <div *ngIf="filteredGameVendorGames?.length > 0">
        <betsnaps-game-vendor-game-card-loader
            *ngFor="let gameVendorGame of activeGameVendorGames(); trackBy: gameVendorGamesTrackBy; let gameIndexInList = index"
            [attr.id]="'vendorGame'+ gameVendorGame.vendor_game_id"
            [gameUpdatedDate]="game.updated_at"
            [game]="game"
            [isCurrentUserJoined]="game.is_current_user_joined"
            [gameVendorGame]="gameVendorGame"
            [gameVendorGameStatus]="gameVendorGame.status"
            [gameIndexInList]="gameIndexInList"
            [adPositions]="adPositions">
        </betsnaps-game-vendor-game-card-loader>
        <betsnaps-game-vendor-game-card-loader
            *ngFor="let gameVendorGame of endedGameVendorGames(); trackBy: gameVendorGamesTrackBy; let gameIndexInList = index"
            [attr.id]="'vendorGame'+ gameVendorGame.vendor_game_id"
            [gameUpdatedDate]="game.updated_at"
            [game]="game"
            [isCurrentUserJoined]="game.is_current_user_joined"
            [gameVendorGame]="gameVendorGame"
            [gameVendorGameStatus]="gameVendorGame.status"
            [gameIndexInList]="gameIndexInList + activeGameVendorGames().length"
            [adPositions]="adPositions">
        </betsnaps-game-vendor-game-card-loader>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="filteredGameVendorGames?.length < 1">
        <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
