/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from './appHttpResponsesUsersPlayerPublicHttpResponse';


/**
 * FriendInvitationHttpResponse
 */
export interface AppHttpResponsesFriendsFriendInvitationHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * User Id
     */
    sender_user_id: number;
    sender_user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    /**
     * Friend User Id
     */
    receiver_user_id: number;
    receiver_user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    /**
     * Confirmation State: 0 = pending, 1 = declined, 2 = confirmed
     */
    state: number;
    /**
     * Optional user message
     */
    message?: string;
    /**
     * Created At
     */
    created_at?: Date;
    /**
     * Updated At
     */
    updated_at?: Date;
}

