import {Component} from '@angular/core';
import {AMatchCardDetailsComponent} from '../a-match-card-details-component';

@Component({
    selector: 'betsnaps-match-card-details-cricket',
    templateUrl: './match-card-details-cricket.component.html',
    styles: []
})
export class MatchCardDetailsCricketComponent extends AMatchCardDetailsComponent {

    public getCricketColumnClasses(): string[] {
        let competitorColumnClass = 'six';
        let scoreColumnClass = 'eight';
        if (this.match.status === 'closed' || this.match.status === 'ended' || this.match.status === 'abandoned' ||
            ![501, 502, 503, 504, 506, 507].includes(this.match.score.match_status_code.betradar_status_id)) {
            competitorColumnClass = 'ten';
            scoreColumnClass = 'four';
        }
        return [competitorColumnClass, scoreColumnClass];
    }

}
