import {Injectable} from '@angular/core';
import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TenantService} from './tenant.service';
import {DebugService} from './debug.service';
import {
    AppHttpResponsesTenantsTenantInfoHttpResponse as TenantInfoHttpResponse,
    AppHttpResponsesTenantsTenantPublicHttpResponse as TenantPublicHttpResponse
} from '../../api';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {catchError, mergeMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MyTranslateLoaderService implements TranslateLoader {

    private ctsBaseUrl = environment.S3_NL_URL + '/' + environment.S3_NL_BUCKET_ROOT_TRANSLATIONSHARE + '/babel';
    private ctsProductId = '1';
    private ctsTranslationVersion = '2';
    private ctsFallbackTenantId = 1;

    constructor(private tenantService: TenantService,
                private httpClient: HttpClient,
                private debugService: DebugService) {
    }

    public getTranslation(lang: string): Observable<any> {
        return this.tenantService.getTenantInfo().pipe(mergeMap(
            (tenantInfo: TenantInfoHttpResponse) => {
                if (tenantInfo) {
                    if (tenantInfo.tenant.cts_tenant_id && tenantInfo.tenant.cts_tenant_id > 0) {
                        return this.loadCtsTranslation(lang, tenantInfo.tenant.cts_tenant_id);
                    }
                }
                return this.loadCtsTranslation(lang, this.ctsFallbackTenantId);
            }
        ), catchError(() => {
            return this.tenantService.loadDataFallbackTenantHttpResponse().pipe(mergeMap(
                (fallbackTenantInfoHttpResponse: TenantInfoHttpResponse) => {
                    if (fallbackTenantInfoHttpResponse) {
                        return this.loadCtsTranslation(this.tenantService.browserLangIsoCode2, fallbackTenantInfoHttpResponse.tenant.cts_tenant_id);
                    }
                    return this.loadCtsTranslation(this.tenantService.browserLangIsoCode2, this.ctsFallbackTenantId);
                }
            ), catchError(() => {
                return this.loadCtsTranslation(this.tenantService.browserLangIsoCode2, this.ctsFallbackTenantId);
            }));
        }));
    }

    private loadCtsTranslation(lang: string, ctsTenantId: number): Observable<any> {
        this.debugService.writeMessageToConsoleLog('load cts translation - tenant: ' + ctsTenantId + ' version: ' + this.ctsTranslationVersion + ' lang: ' + lang);
        const translation_url = this.ctsBaseUrl + '/' + this.ctsProductId + '/' + ctsTenantId.toString() + '/' + this.ctsTranslationVersion + '/' + lang + '.json';
        return this.httpClient.get(
            translation_url
        ).pipe(
            catchError(err => {
                return this.loadCtsTranslation('en', ctsTenantId);
            })
        );
    }

}
