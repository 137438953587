/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantGuiInternationalizationUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantGuiInternationalizationUpdateRequest { 
    /**
     * tenant theme ID
     */
    theme_id?: number;
    /**
     * tenant email theme ID
     */
    email_theme_id?: number;
    /**
     * CTS tenant ID
     */
    cts_tenant_id: number;
    /**
     * default language ID
     */
    default_language_id: number;
    /**
     * date time format
     */
    date_time_format: string;
    /**
     * date format
     */
    date_format: string;
    /**
     * time format
     */
    time_format: string;
    /**
     * timezone ID
     */
    timezone_id: number;
    /**
     * decimal point
     */
    decimal_point: string;
    /**
     * thousands separator
     */
    thousands_separator: string;
    /**
     * odd display format (\'odds\', \'value\', \'fractional\', \'moneyline\')
     */
    odd_display_format: string;
    /**
     * currency format (\'prefix\', \'suffix\')
     */
    currency_format: string;
    /**
     * Show custom landingpage
     */
    show_custom_landingpage: boolean;
    /**
     * Show arena filters
     */
    show_arena_filters: boolean;
    /**
     * Show balance
     */
    show_balance: boolean;
    /**
     * Markets default expanded
     */
    markets_default_expanded: boolean;
    /**
     * Show game spots left
     */
    show_game_spots_left: boolean;
    /**
     * Game Spots Left Limit
     */
    game_spots_left_limit: number;
}

