import {Component, Renderer2} from '@angular/core';
import {ASidebarComponent} from '../../a-sidebar-component';
import {AuthenticationService, TenantService} from '../../../../../shared';

@Component({
    selector: 'betsnaps-left-sidebar-v3',
    templateUrl: './left-sidebar-v3.component.html',
    styles: []
})
export class LeftSidebarV3Component extends ASidebarComponent {

    constructor(protected renderer: Renderer2,
                protected authenticationService: AuthenticationService,
                public tenantService: TenantService) {
        super(
            authenticationService,
            renderer
        );
    }
}
