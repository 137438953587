<div class="ui vertical borderless padded segment margin-0 padding-t-0" *ngIf="userFriendInvitationsIncoming?.length > 0 || userFriendInvitationsOutgoing?.length > 0 || userFriendInvitationsIncomingDeclined?.length > 0">
    <h3 class="ui header section-header">
        {{'FRIENDS.REQUESTS.pending_friend_requests' | translate}}
        ({{userFriendInvitationsIncoming?.length + userFriendInvitationsOutgoing?.length}})
    </h3>
    <div class="ui four column grid" *ngIf="userFriendInvitationsIncoming?.length > 0 || userFriendInvitationsOutgoing?.length > 0 || userFriendInvitationsIncomingDeclined?.length > 0">
        <div *ngFor="let userFriendInvitationIn of userFriendInvitationsIncoming; trackBy: userFriendInvitationsTrackBy" class="column center aligned">
            <betsnaps-user-avatar-loader [user]="userFriendInvitationIn.sender_user" [imageSize]="'tiny'" [showFriendshipStateIcon]="true"></betsnaps-user-avatar-loader>
            <div class="truncate text-small margin-t-5">{{userFriendInvitationIn.sender_user.username}}</div>
            <div class="center aligned text-tiny color-light-grey">{{'FRIENDS.REQUESTS.incoming' | translate}}</div>
        </div>
        <div *ngFor="let userFriendInvitationOut of userFriendInvitationsOutgoing; trackBy: userFriendInvitationsTrackBy" class="column center aligned">
            <betsnaps-user-avatar-loader [user]="userFriendInvitationOut.receiver_user" [imageSize]="'tiny'" [showFriendshipStateIcon]="true"></betsnaps-user-avatar-loader>
            <div class="truncate text-small margin-t-5">{{userFriendInvitationOut.receiver_user.username}}</div>
            <div class="center aligned text-tiny color-light-grey">{{'FRIENDS.REQUESTS.outgoing' | translate}}</div>
        </div>
        <div class="column center aligned" *ngIf="userFriendInvitationsIncomingDeclined?.length > 0">
            <i class="fa fa-ban icon circular medium-circled margin-0 element-clickable" (click)="openDeclinedIncomingModal()"></i>
            <div class="text-small margin-t-5">{{userFriendInvitationsIncomingDeclined.length}} {{userFriendInvitationsIncomingDeclined.length | i18nPlural:translations.labelTranslations.userplural}}</div>
            <div class="center aligned text-tiny color-light-grey">{{'FRIENDS.REQUESTS.declined' | translate}}</div>
        </div>
    </div>
</div>
<ng-template let-context let-modal="modal" #declinedIncomingModalTemplate>
    <div class="big header">
        {{'FRIENDS.REQUESTS.declined_requests' | translate | uppercase}} ({{userFriendInvitationsIncomingDeclined?.length}})
    </div>
    <div class="scrolling content">
        <div class="ui four column grid" *ngIf="userFriendInvitationsIncomingDeclined?.length > 0">
            <div *ngFor="let userFriendInvitationInDeclined of userFriendInvitationsIncomingDeclined; trackBy: userFriendInvitationsTrackBy" class="column center aligned">
                <betsnaps-user-avatar-loader [user]="userFriendInvitationInDeclined.sender_user" [imageSize]="'tiny'" [showFriendshipStateIcon]="true"></betsnaps-user-avatar-loader>
                <div class="truncate text-small margin-t-5">{{userFriendInvitationInDeclined.sender_user.username}}</div>
            </div>
        </div>
        <div class="ui segment" *ngIf="userFriendInvitationsIncomingDeclined.length === 0">
            <p>{{'GENERAL.no_results_found' | translate}}</p>
        </div>
    </div>
</ng-template>
