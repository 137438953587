import { AfterViewInit, ComponentRef, ElementRef, HostListener, Renderer2, ViewChild, ViewContainerRef, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameUserHttpResponse as GameUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameHttpListResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse,
    AppHttpResponsesTenantsTenantAdvertisementHttpResponse as AdvertisementHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse
} from '../../../api';
import {
    AdService,
    AppEventsBetsnapsGamesGameStateChanged as GameStateChanged,
    AppEventsBetsnapsGamesUserJoinedGame as UserJoinedGameEvent,
    AppEventsBetsnapsGamesUserLeftGame as UserLeftGameEvent,
    AuthenticationService,
    ArenaService,
    BroadcastingService,
    DebugService,
    FriendsService,
    GameFilter,
    TenantService,
    WindowRef
} from '../../../shared';
import {Subscription} from 'rxjs';
import {TenantContentComponent} from '../../components/tenant-content/tenant-content.component';
import {takeWhile} from 'rxjs/operators';

@Directive()
export abstract class AArenaComponent implements AfterViewInit {

    protected componentAlive = true;
    protected componentInForeground = false;
    protected componentUrl: string;

    public currentUser: PlayerHttpResponse;

    protected gameListSubscription: Subscription;
    public gameList: GameHttpListResponse;
    public processList = true;
    public processLoadMore = false;

    protected invitedGameListSubscription: Subscription;
    public invitedGameList: GameHttpListResponse;

    public updatedDataAvailable = false;

    protected arenaFilters: GameFilter = {
        'per_page': 10,
        'current_page': 1,
        'game_state': '1,2',
        'sport_id': undefined,
        'is_public': true,
        'competition_type': undefined,
        'is_highlight': undefined,
        'joined_user_id': undefined,
        'only_with_friends': undefined,
        'exclude_fully_booked': true,
        'include_all_joined_snaps': true,
        'exclude_closed_door': true,
        'check_for_fitting_tags': true,
        'exclude_singleplayer': true,
        'sorting': 'asc'
    };

    public activeTab = 'all';
    protected nativeWindow: Window;

    public adPositions: number[] = [];
    protected emptyArenaAdComponentRef: ComponentRef<TenantContentComponent>;
    @ViewChild('emptyArenaPlaceholder', {read: ViewContainerRef}) emptyArenaPlaceholderContainer: ViewContainerRef;

    public showEmptyArenaAd = false;

    protected broadcastEventSubscriptions: Subscription[] = [];

    @ViewChild('leftSidebar', {static: true}) public leftSidebar: ElementRef;
    @ViewChild('rightSidebar', {static: true}) public rightSidebar: ElementRef;
    @ViewChild('leftSidebarSticky', {static: true}) public leftSidebarSticky: ElementRef;
    @ViewChild('rightSidebarSticky', {static: true}) public rightSidebarSticky: ElementRef;

    @ViewChild('loadMoreButton') public loadMoreButton: ElementRef;

    constructor(protected authenticationService: AuthenticationService,
                protected broadcastingService: BroadcastingService,
                public tenantService: TenantService,
                protected friendsService: FriendsService,
                protected arenaService: ArenaService,
                protected windowRef: WindowRef,
                protected debugService: DebugService,
                protected renderer: Renderer2,
                protected adService: AdService) {
        this.nativeWindow = windowRef.nativeWindow;

        this.broadcastingService.joinChannel('Arena.' + this.tenantService.tenantData.id);
        this.subscribeToGameArenaChannelEvents();

        this.arenaService.gameUpdate$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((gameUpdate: GameHttpResponse) => {
                if (gameUpdate) {
                    this.gameList.results = this.gameList.results.map(
                        game => {
                            if (game.game_unique_id === gameUpdate.game_unique_id) {
                                game = gameUpdate;
                            }
                            return game;
                        }
                    );
                }
            });

    }

    protected checkForEmptyGameList(adType: string = 'empty-arena'): void {
        if (this.emptyArenaPlaceholderContainer && this.gameList && this.gameList.total < 1) {
            // should show empty arena ad
            if (!this.emptyArenaAdComponentRef || this.emptyArenaAdComponentRef.instance.adType !== adType) {

                const adsToDisplay = this.tenantService.tenantAdvertisements.filter((ad: AdvertisementHttpResponse) => ad.type === adType);
                if (adsToDisplay.length > 0) {
                    // destroy the old ad component
                    if (this.emptyArenaAdComponentRef) {
                        this.emptyArenaAdComponentRef.destroy();
                    }
                    // create the new ad component
                    this.emptyArenaAdComponentRef = this.adService.createTenantAd(adType, 0, this.emptyArenaPlaceholderContainer, 'standardview');
                    this.showEmptyArenaAd = true;
                    this.processList = false;
                } else {
                    this.showEmptyArenaAd = false;
                    this.processList = false;
                }
            } else {
                this.showEmptyArenaAd = true;
                this.processList = false;
            }
        } else {
            // hide empty arena ad if necessary
            if (this.emptyArenaAdComponentRef) {
                this.emptyArenaAdComponentRef.destroy();
                this.emptyArenaAdComponentRef = null;
            }
            this.showEmptyArenaAd = false;
            this.processList = false;
        }
    }

    subscribeToGameArenaChannelEvents() {

        // unsubscribe from all broadcast events
        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];

        // Listen for UserJoinedGame events
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\UserJoinedGame')
                .subscribe((broadcastEventData: UserJoinedGameEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('USER JOINED ' + broadcastEventData.gameUserHttpResponse.user_id);
                        this.handleUserJoinedGameEvent(broadcastEventData.gameHttpResponse, broadcastEventData.gameUserHttpResponse);
                    }
                })
        );
        // Listen for UserLeftGame events
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\UserLeftGame')
                .subscribe((broadcastEventData: UserLeftGameEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('USER LEFT ' + broadcastEventData.playerPublicHttpResponse.id);
                        this.handleUserLeftGameEvent(broadcastEventData.gameHttpResponse, broadcastEventData.playerPublicHttpResponse);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\GamePublished')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData && (this.activeTab === 'all' || (this.activeTab === 'highlights' && broadcastEventData.gameHttpResponse.is_highlight))) {
                        this.debugService.writeMessageToConsoleLog('GAME PUBLISHED - ' + broadcastEventData.gameHttpResponse.game_unique_id);
                        this.updatedDataAvailable = true;
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\GameFullyBooked')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('GAME FULLYBOOKED - ' + broadcastEventData.gameHttpResponse.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\GameStarted')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('GAME STARTED - ' + broadcastEventData.gameHttpResponse.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                        this.updatedDataAvailable = true;
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\GameFinished')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('GAME FINISHED - ' + broadcastEventData.gameHttpResponse.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\GameClosed')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('GAME CLOSED - ' + broadcastEventData.gameHttpResponse.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                        this.updatedDataAvailable = true;
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('Arena.' + this.tenantService.tenantData.id, 'Betsnaps\\Games\\GameCancelled')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('GAME CANCELLED - ' + broadcastEventData.gameHttpResponse.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                        this.updatedDataAvailable = true;
                    }
                })
        );
    }

    handleGameStateChange(broadcastEventData: GameStateChanged) {
        const receivedGame = broadcastEventData.gameHttpResponse;
        if (this.gameList && this.gameList.results.length > 0) {
            this.gameList.results = this.gameList.results.filter(
                (game: GameHttpResponse) => {
                    if (game.game_unique_id === receivedGame.game_unique_id) {
                        game.updated_at = receivedGame.updated_at;
                        game.game_state = receivedGame.game_state;
                        game.users_count = receivedGame.users_count;
                        game.current_prize_pool = receivedGame.current_prize_pool;
                    }

                    // hide if fully booked
                    if (game.is_current_user_joined || (!game.is_current_user_joined && game.users_count < game.size) || (game.is_simple_snap && this.arenaService.isActiveSimpleSnap(game.game_unique_id))) {
                        return game;
                    } else {
                        this.gameList.total -= 1;
                        return false;
                    }
                }
            );
            this.checkForEmptyGameList();
        }
        if (this.invitedGameList && this.invitedGameList.results.length > 0) {
            this.invitedGameList.results = this.invitedGameList.results.filter(
                (game: GameHttpResponse) => {
                    if (game.game_unique_id === receivedGame.game_unique_id) {
                        game.updated_at = receivedGame.updated_at;
                        game.game_state = receivedGame.game_state;
                        game.users_count = receivedGame.users_count;
                        game.current_prize_pool = receivedGame.current_prize_pool;
                    }
                    // hide if fully booked or already started
                    if (game.game_state < 3 && game.users_count < game.size) {
                        return game;
                    } else {
                        this.invitedGameList.total -= 1;
                        return false;
                    }
                }
            );
        }
    }

    protected handleUserJoinedGameEvent(receivedGame: GameHttpResponse, receivedGameUser: GameUserHttpResponse) {
        if (this.gameList && this.gameList.results.length > 0) {
            this.gameList.results = this.gameList.results.map(
                game => {
                    if (game.game_unique_id === receivedGame.game_unique_id) {
                        game.users_count = receivedGame.users_count;
                        game.current_prize_pool = receivedGame.current_prize_pool;
                        game.h2h_competitors = receivedGame.h2h_competitors;
                        if (this.currentUser) {
                            if (this.currentUser.id === receivedGameUser.user_id) {
                                game.is_current_user_joined = true;

                                // remove from invited list
                                if (this.invitedGameList && this.invitedGameList.results.length > 0) {
                                    this.invitedGameList.results = this.invitedGameList.results.filter(
                                        (invitedGame: GameHttpResponse) => {
                                            if (invitedGame.game_unique_id !== receivedGame.game_unique_id) {
                                                return invitedGame;
                                            } else {
                                                this.invitedGameList.total -= 1;
                                                return false;
                                            }
                                        }
                                    );
                                }
                            } else {
                                // if user is a friend add to current_user_friends
                                const userFriend = this.friendsService.userIsFriend(receivedGameUser.user_id);
                                if (userFriend) {
                                    if (!game.current_user_friends) {
                                        game.current_user_friends = [];
                                    }
                                    const friendExistsInList = game.current_user_friends.find(
                                        (friendInList: FriendHttpResponse) => friendInList.user_id === receivedGameUser.user_id);
                                    if (!friendExistsInList) {
                                        game.current_user_friends.push(userFriend);
                                    }
                                }
                            }
                        }
                    }
                    return game;
                }
            );

            this.checkForEmptyGameList();
        }
    }

    protected handleUserLeftGameEvent(receivedGame: GameHttpResponse, receivedPlayer: PlayerPublicHttpResponse, gameList: GameHttpListResponse = null) {

        if (!gameList) {
            gameList = this.gameList;
        }

        if (gameList && gameList.results.length > 0) {
            gameList.results = gameList.results.map(
                game => {
                    if (game.game_unique_id === receivedGame.game_unique_id) {
                        game.users_count = receivedGame.users_count;
                        game.current_prize_pool = receivedGame.current_prize_pool;
                        game.h2h_competitors = receivedGame.h2h_competitors;
                        if (this.currentUser) {
                            if (this.currentUser.id === receivedPlayer.id) {
                                game.is_current_user_joined = false;
                            } else {
                                // if user is a friend remove from current_user_friends
                                const userFriend = this.friendsService.userIsFriend(receivedPlayer.id);
                                if (userFriend && game.current_user_friends) {
                                    game.current_user_friends = game.current_user_friends.filter(
                                        (friendInList: FriendHttpResponse) => friendInList.friend_user_id !== userFriend.friend_user_id
                                    );
                                }
                            }
                        }
                    }
                    return game;
                }
            );
        }
    }

    ngAfterViewInit() {
        this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'position', 'fixed');
        this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'position', 'fixed');
        this.updateSideBarStickyDimensions();
    }

    updateSideBarStickyDimensions() {
        if (this.authenticationService.currentUser) {
            this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'top', '68px');
            this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'top', '68px');
        } else {
            this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'top', '118px');
            this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'top', '118px');
        }

        this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'width', this.leftSidebar.nativeElement.offsetWidth + 'px');
        this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'left', this.leftSidebar.nativeElement.getBoundingClientRect().left + 'px');
        this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'width', this.rightSidebar.nativeElement.offsetWidth + 'px');
        this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'left', this.rightSidebar.nativeElement.getBoundingClientRect().left + 'px');
    }

    public abstract infiniteScroll();

    onDestroy() {
        this.componentAlive = false;

        // unsubscribe from all broadcast events
        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];
        this.broadcastingService.leaveChannel('Arena.' + this.tenantService.tenantData.id);

        if (this.emptyArenaAdComponentRef) {
            this.emptyArenaAdComponentRef.destroy();
        }
    }

    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    arenaListeners() {
        if (this.componentInForeground) {
            this.updateSideBarStickyDimensions();
            this.infiniteScroll();
        }
    }

    @HostListener('window:scroll', [])
    arenaScrollListeners() {
        if (this.componentInForeground) {
            this.infiniteScroll();
        }
    }

}
