<betsnaps-externalbodyscript *ngFor="let externalScript of externalScriptBodyTopEmbedCode"
                             [embedCode]="externalScript.body_top_embed_code"
                             [scriptId]="externalScript.id"
                             [title]="externalScript.title">
</betsnaps-externalbodyscript>

<betsnaps-header-loader *ngIf="!isWidget"></betsnaps-header-loader>
<betsnaps-main-loader [isWidget]="isWidget"></betsnaps-main-loader>

<div id="content-bgimg" class="content-bgimg"
     [ngStyle]="{'background-image': (backgroundImage) ? backgroundImage : ''}"></div>

<ngx-cookie-banner #cookieLaw class="ui center aligned">
    <div class="cookie-law" *ngIf="showCookieBanner && !isWidget && tenantFooterDataLoaded">
        <div class="cookie-law-wrapper">
            <div class="copy">
                <span>
                    <p>
                        <betsnaps-cms
                            [cmsKey]="'GENERAL.cookie-policy_text'"
                            [translateCutIndex]="0"></betsnaps-cms>
                        <span *ngIf="tenantFooterItem">
                                    <betsnaps-cms
                                        [cmsKey]="'GENERAL.cookie-policy_text'"
                                        [translateCutIndex]="1"></betsnaps-cms>
                            <a [routerLink]="[tenantFooterItem.landing_page_url]" *ngIf="!tenantFooterItem.is_landing_page_external">
                                <betsnaps-cms
                                    [cmsKey]="'GENERAL.cookie-policy_text'"
                                    [translateCutIndex]="2"></betsnaps-cms>
                            </a>
                            <a [href]="tenantFooterItem.landing_page_url | dbtranslation:'landing_page_url':tenantFooterItem.translations"
                               target="_blank" *ngIf="tenantFooterItem.is_landing_page_external"
                               rel="noopener noreferrer">
                                <betsnaps-cms
                                    [cmsKey]="'GENERAL.cookie-policy_text'"
                                    [translateCutIndex]="2"></betsnaps-cms>
                            </a>
                            <betsnaps-cms
                                [cmsKey]="'GENERAL.cookie-policy_text'"
                                [translateCutIndex]="3"></betsnaps-cms>
                        </span>
                    </p>
                    <button class="ui button" type="button" (click)="dismiss()">
                        {{'GENERAL.cookie-policy_agree' | translate}}
                    </button>
                </span>
            </div>
        </div>
    </div>
</ngx-cookie-banner>

<betsnaps-externalbodyscript *ngFor="let externalScript of externalScriptBodyBottomEmbedCode"
                             [embedCode]="externalScript.body_bottom_embed_code"
                             [scriptId]="externalScript.id"
                             [title]="externalScript.title">
</betsnaps-externalbodyscript>

<div *ngIf="userLoggedIn">
    <betsnaps-externalbodyscript *ngFor="let externalScript of externalScriptAfterLoginEmbedCode"
                                 [embedCode]="externalScript.after_login_embed_code"
                                 [scriptId]="externalScript.id"
                                 [title]="externalScript.title"
                                 [callWithEveryNavigationChange]="false">
    </betsnaps-externalbodyscript>
</div>
