import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {ActiveModal, FuiModalService} from '@aligorji/ngx-fomantic-ui';
import {DOCUMENT} from '@angular/common';
import {WindowRef} from './window.service';
import {LoginRegisterModal} from '../classes/login-register-modal';
import {TenantService} from './tenant.service';
import {UserNameModal} from '../classes/user-name-modal';
import {GoogleAnalyticsService} from './google-analytics.service';

@Injectable({
    providedIn: 'root'
})
export class MyModalService {

    private nativeWindow: Window;
    private renderer: Renderer2;
    private currScrollPosition: number;

    constructor(@Inject(DOCUMENT) private document: any,
                private windowRef: WindowRef,
                private rendererFactory: RendererFactory2,
                private modalService: FuiModalService,
                private tenantService: TenantService,
                public googleAnalyticsService: GoogleAnalyticsService) {
        this.nativeWindow = windowRef.nativeWindow;
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public openModal(modalConfig): ActiveModal<{}, {}, {}> {
        // save current scroll position
        this.currScrollPosition = Math.floor(
            this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
        );

        this.renderer.addClass(this.document.documentElement, 'modal-open');

        return this.modalService
            .open(modalConfig)
            .onApprove(() => {
                this.renderer.removeClass(this.document.documentElement, 'modal-open');
                // scroll to saved position
                this.nativeWindow.window.scrollTo(0, this.currScrollPosition || 0);
            })
            .onDeny(() => {
                this.renderer.removeClass(this.document.documentElement, 'modal-open');
                // scroll to saved position
                this.nativeWindow.window.scrollTo(0, this.currScrollPosition || 0);
            });
    }

    public openLoginRegisterModal(viewToShow: string = 'login', loginRedirect?: string, skipRedirect: boolean = false, eventCategory: string = 'home'): ActiveModal<{}, {}, {}> {

        if (!skipRedirect) {
            if (loginRedirect) {
                localStorage.setItem('loginRedirect', loginRedirect);
            } else if (!localStorage.getItem('loginRedirect')) {
                // default login redirect
                localStorage.setItem('loginRedirect', 'arena');
            }
        }

        if (this.tenantService.noB2cSignupMethod) {
            this.googleAnalyticsService.trackEvent(eventCategory, 'login', 'b2b redirect');
            window.location.href = this.tenantService.getB2bWebsiteLoginUrl();
            return null;
        } else {
            this.googleAnalyticsService.trackEvent(eventCategory, viewToShow, 'open modal');
            return this.openModal(new LoginRegisterModal(viewToShow));
        }

    }

    public openUsernameInputModal(): ActiveModal<{}, {}, {}> {
        return this.openModal(new UserNameModal());
    }
}
