import {Component} from '@angular/core';
import {
    WindowRef,
    AuthenticationService,
    ProfileService,
    TenantService,
    ErrorService, MyNotificationsService, MyModalService
} from '../../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AProfileEditComponent} from '../a-profile-edit.component';

@Component({
    selector: 'betsnaps-profile-edit-default',
    templateUrl: './profile-edit-default.component.html',
    styles: []
})
export class ProfileEditDefaultComponent extends AProfileEditComponent {

    constructor(protected authenticationService: AuthenticationService,
                protected profileService: ProfileService,
                protected tenantService: TenantService,
                public myModalService: MyModalService,
                protected windowRef: WindowRef,
                protected router: Router,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected errorService: ErrorService) {
        super(
            authenticationService,
            profileService,
            tenantService,
            windowRef,
            router,
            myNotificationsService,
            translateService,
            errorService
        );
    }
}
