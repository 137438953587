/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataTeamJerseyHttpResponse } from './appHttpResponsesSportsDataTeamJerseyHttpResponse';
import { AppHttpResponsesSportsDataTournamentHttpResponse } from './appHttpResponsesSportsDataTournamentHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TeamHttpResponse
 */
export interface AppHttpResponsesSportsDataTeamHttpResponse { 
    /**
     * Team Id
     */
    id: number;
    /**
     * Team Abbr
     */
    abbr?: string;
    /**
     * Team Name
     */
    name: string;
    /**
     * Team Short Name
     */
    short_name?: string;
    /**
     * Team Country
     */
    country?: string;
    /**
     * Team Country Code
     */
    country_code?: string;
    /**
     * Sport Id
     */
    sport_id: number;
    /**
     * Team Translation
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Team Jerseys
     */
    jerseys?: Array<AppHttpResponsesSportsDataTeamJerseyHttpResponse>;
    /**
     * Team Tournaments
     */
    tournaments?: Array<AppHttpResponsesSportsDataTournamentHttpResponse>;
}

