/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantGameBehaviourUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantGameBehaviourUpdateRequest { 
    /**
     * Max. allowed bets
     */
    max_bet_count_default: number;
    /**
     * Cashout Odd Limit
     */
    cashout_odd_limit?: number;
    /**
     * Use initial PreMatch BetOddValue
     */
    use_initial_bet_outcome: boolean;
    /**
     * Show pre game start odd can change info
     */
    show_pre_game_start_odd_can_change_info: boolean;
    /**
     * Show consent text in username modal
     */
    show_consent_text_in_username_modal: boolean;
    /**
     * MatchMarkets expanded by default
     */
    markets_default_expanded: boolean;
    /**
     * 2-step join
     */
    confirm_join_process: boolean;
    /**
     * Show join success modal
     */
    show_join_success_modal: boolean;
    /**
     * leave FreeGames
     */
    leave_free_game: boolean;
    /**
     * Allow join of multiple SnapClones
     */
    allow_join_multiple_game_clones: boolean;
    /**
     * Allow prizes without valid participation
     */
    allow_prizes_without_valid_participation: boolean;
    /**
     * Allow prizes with zero points
     */
    allow_prizes_with_zero_points: boolean;
    /**
     * Send no-valid-bets notification after Snap-Start
     */
    notification_no_valid_bets_game_start: boolean;
    /**
     * Minutes after Snap-Start for no-valid-bets notification
     */
    notification_no_valid_bets_game_start_min: number;
    /**
     * Send no-valid-bets notification during last match of Snap
     */
    notification_no_valid_bets_last_match_start: boolean;
    /**
     * Minutes after last match of Snap-Start for no-valid-bets notification
     */
    notification_no_valid_bets_last_match_start_min: number;
    /**
     * odds / prediction based
     */
    points_engine?: string;
    /**
     * Winning points for prediction based game bets
     */
    prediction_game_winning_points?: number;
    /**
     * Default initial game points
     */
    default_initial_game_points: number;
    /**
     * Default widget game points
     */
    default_widget_game_points: number;
}

