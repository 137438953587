import {Component, Input, ViewContainerRef} from '@angular/core';
import {GameVendorGameParticipationStatusEnum} from '../../../../shared/enums';
import {AdService, TenantService} from '../../../../shared';
import {AGameVendorGameParticipationCardComponent} from '../a-game-vendor-game-participation-card-component';

@Component({
    selector: 'betsnaps-game-vendor-game-participation-card-default',
    templateUrl: './game-vendor-game-participation-card-default.component.html',
    styles: []
})
export class GameVendorGameParticipationCardDefaultComponent extends AGameVendorGameParticipationCardComponent {

    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;

    constructor(protected viewContainerRef: ViewContainerRef,
                public tenantService: TenantService,
                protected adService: AdService) {
        super(viewContainerRef, tenantService, adService);
    }

}
