import {BaseLoginProvider, SocialUser} from '@abacritt/angularx-social-login';


export class CustomGoogleLoginProvider extends BaseLoginProvider {

    static readonly PROVIDER_ID = 'GOOGLE';

    protected initOptions;
    protected clientId;
    protected client;

    constructor(clientId, initOptions = {scope: 'email'}) {
        super();
        this.clientId = clientId;
        this.initOptions = initOptions;
    }

    initialize() {
        return new Promise<void>((resolve, reject) => {

            try {
                // 1. Load GAPI Client
                // Without loading the gapi client we get a warning: [GSI_LOGGER-TOKEN_CLIENT]: Set token failed. Gapi.client.setToken undefined.
                this.loadScript(CustomGoogleLoginProvider.PROVIDER_ID + '-1', 'https://apis.google.com/js/api.js', () => {
                    // @ts-ignore
                    gapi.load('client');
                });
            } catch (err) {
                // Ignore Error, Missing GAPI Library only produces warning, Login will still work fine
            }

            try {
                // 2. Load GIS Client
                this.loadScript(CustomGoogleLoginProvider.PROVIDER_ID + '-2', 'https://accounts.google.com/gsi/client', () => {
                    let options = Object.assign(Object.assign({}, this.initOptions), {
                        client_id: this.clientId,
                        callback: ''
                    });

                    // @ts-ignore
                    this.client = google.accounts.oauth2.initTokenClient(options);
                    resolve();
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    signIn(): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            try {
                this.client.callback = (response) => {
                    if (response.error !== undefined) {
                        reject(response);
                    }
                    const user = new SocialUser();
                    user.authToken = response.access_token;
                    resolve(user);
                };

                this.client.requestAccessToken();
            } catch (err) {
                reject(err);
            }
        });
    }

    getLoginStatus(loginStatusOptions?: any): Promise<SocialUser> {
        console.log('CustomGoogleLoginProvider: getLoginStatus is not implemented');
        return Promise.resolve(undefined);
    }

    signOut(revoke?: boolean): Promise<void> {
        console.log('CustomGoogleLoginProvider: signOut is not implemented');
        return Promise.resolve(undefined);
    }
}
