<div [class.element-clickable]="isClickAble"
     (click)="userAvatarClicked()"
     *ngIf="user">
    <img *ngIf="user.media_id > 0" [ngClass]="'ui circular bordered centered image profileimg bg-white ' + imageSize + ' ' + addClass"
         [class.primary]="friendshipState.state === friendshipStateEnum.FRIEND"
         [class.grey]="friendshipState.state === friendshipStateEnum.INCOMING_PENDING_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.OUTGOING_FRIEND_INVITATION"
         [src]="user.media_url_small"
         [alt]=""/>
    <img *ngIf="!user.media_id || user.media_id === 0"
         [ngClass]="'ui circular bordered centered image profileimg bg-white ' + imageSize + ' ' + addClass"
         [src]="'./assets/img/default-profile.png'" alt="" />
</div>
<div *ngIf="!user">
    <div *ngIf="showAvatarLoader" class="ui active z-index-1 inline loader right spaced image profileimg"></div>
    <img *ngIf="!showAvatarLoader"
         [ngClass]="'ui circular bordered centered image profileimg bg-white ' + imageSize + ' ' + addClass"
         [src]="'./assets/img/default-profile.png'" alt="" />
</div>
<ng-template let-context let-modal="modal" #userProfileModalTemplate>
    <div class="user-modal header">
        <i class="person icon big"></i>{{'GENERAL.LABELS.user_profile' | translate | uppercase}}
    </div>
    <div class="user-modal scrolling content" *ngIf="user">
        <p class="center aligned">
            <strong class="font-very-bold">{{user.username}}</strong>
        </p>
        <p class="center aligned margin-b-15">
            <img class="ui circular bordered centered medium image profileimg bg-white"
                 [class.primary]="friendshipState.state === friendshipStateEnum.FRIEND"
                 [class.grey]="friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION || friendshipState.state === friendshipStateEnum.OUTGOING_FRIEND_INVITATION"
                 [src]="user.media_url_big"
                 [alt]="" />
        </p>
        <div *ngIf="friendshipState.state === friendshipStateEnum.OUTGOING_FRIEND_INVITATION">
            <p class="center aligned">
                <label class="ui label margin-r-0">
                    {{'FRIENDS.REQUESTS.pending_outgoing_friend_request' | translate | uppercase}}
                </label>
            </p>
            <div class="ui centered middle aligned grid margin-0">
                <div class="eight wide column right aligned">
                    <button type="button" class="ui grey button margin-0" routerLink="/friends/overview" (click)="modal.approve(null)">
                        {{'FRIENDS.friend_requests' | translate | uppercase}}
                    </button>
                </div>
                <div class="eight wide column left aligned">
                    <button type="button" (click)="deleteFriendInvitation(friendshipState.friendInvitation)"
                            [class.loading]="processDeleteFriendInvitationButton"
                            [disabled]="processDeleteFriendInvitationButton"
                            class="ui basic button margin-0">
                        {{'FRIENDS.REQUESTS.delete_outgoing_request' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
        <div class="ui centered grid center aligned" *ngIf="friendshipState.state === friendshipStateEnum.FRIEND && currentUser.username">
            <div class="three wide mobile four wide tablet four wide computer column"></div>
            <div class="ten wide mobile eight wide tablet eight wide computer column">
                <button type="button" (click)="modal.approve(null);challengeFriend(user)"
                        *ngIf="challengeFriendPossible"
                        [class.loading]="processChallengeButton"
                        [disabled]="processChallengeButton"
                        class="ui basic labeled icon button margin-b-10 fluid">
                    <img class="iconimage icon padding-0" [src]="'./assets/img/head2head_quad.png'"
                         alt="{{'GENERAL.LABELS.h2h' | translate}}"/>{{'GENERAL.USER.challenge' | translate | uppercase}}
                </button>
                <button type="button" (click)="removeFriend(user.id)"
                        [class.loading]="processButton"
                        [disabled]="processButton"
                        class="ui basic button fluid">
                    {{'FRIENDS.remove_friend' | translate | uppercase}}
                </button>
            </div>
            <div class="three wide mobile four wide tablet four wide computer column"></div>
        </div>
        <div class="center aligned" *ngIf="user.is_active && friendshipState.state === friendshipStateEnum.NOT_A_FRIEND">
            <button type="button" (click)="showAddFriend()"
                    [hidden]="addFriendExpanded"
                    class="ui button margin-0">
                <i class="person add large icon"></i><span
                class="display-inline-block vertical-align-middle">{{'FRIENDS.add_friend' | translate | uppercase}}</span>
            </button>
            <div [hidden]="!addFriendExpanded">
                <div class="ui form">
                    <textarea #inviteMessage rows="3"
                              placeholder="{{'FRIENDS.add_friend_message_placeholder' | translate}}"></textarea>
                </div>
                <div class="right aligned margin-t-10">
                    <button type="button" (click)="addFriendExpanded = false"
                            class="ui button basic padding-r-10 padding-l-10">
                        {{'GENERAL.abort_btn' | translate | uppercase}}
                    </button>
                    <button type="button" (click)="addFriend(user.id, inviteMessage.value)"
                            [class.loading]="processButton"
                            [disabled]="processButton"
                            class="ui button margin-0 padding-r-10 padding-l-10">
                        {{'FRIENDS.add_friend' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="friendshipState.state === friendshipStateEnum.INCOMING_PENDING_FRIEND_INVITATION && friendshipState.friendInvitation">
            <p class="center aligned">
                <label class="ui label margin-r-0">
                    {{'FRIENDS.REQUESTS.pending_incoming_friend_request' | translate | uppercase}}
                </label>
            </p>
            <p class="margin-t-5 center aligned text-small" *ngIf="friendshipState.friendInvitation.message">
                {{friendshipState.friendInvitation.message}}
            </p>
            <div class="ui centered middle aligned grid margin-0">
                <div class="eight wide column right aligned">
                    <button type="button" (click)="acceptFriendInvitation(friendshipState.friendInvitation)"
                            [class.loading]="processAcceptFriendInvitationButton"
                            [disabled]="processAcceptFriendInvitationButton"
                            class="ui primary button margin-0">
                        {{'FRIENDS.add_friend' | translate | uppercase}}
                    </button>
                </div>
                <div class="eight wide column left aligned">
                    <button type="button" (click)="declineFriendInvitation(friendshipState.friendInvitation)"
                            [class.loading]="processDeclineFriendInvitationButton"
                            [disabled]="processDeclineFriendInvitationButton"
                            class="ui basic button margin-0">
                        {{'FRIENDS.REQUESTS.decline_incoming_request' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="friendshipState.state === friendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION && friendshipState.friendInvitation">
            <p class="center aligned" >
                <label class="ui label margin-r-0">
                    {{'FRIENDS.REQUESTS.declined_incoming_friend_request' | translate | uppercase}}
                </label>
            </p>
            <p class="margin-t-5 center aligned text-small" *ngIf="friendshipState.friendInvitation.message">
                {{friendshipState.friendInvitation.message}}
            </p>
            <div class="ui centered middle aligned grid margin-0">
                <div class="sixteen wide column center aligned">
                    <button type="button" (click)="acceptFriendInvitation(friendshipState.friendInvitation)"
                            [class.loading]="processAcceptFriendInvitationButton"
                            [disabled]="processAcceptFriendInvitationButton"
                            class="ui primary button margin-0">
                        <i class="person add large icon"></i><span
                        class="display-inline-block vertical-align-middle">{{'FRIENDS.add_friend' | translate | uppercase}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="ui vertical borderless very padded segment padding-b-0" *ngIf="processGameList">
            <div class="ui active centered inline text loader">{{'FRIENDS.check_for_joined_snaps' | translate}}</div>
        </div>
        <div class="ui vertical borderless segment padding-b-0" *ngIf="!processGameList && joinedGameList">
            <div *ngIf="joinedGameList.results.length > 0">
                <div class="ui horizontal divider">
                    {{joinedGameList.results.length}} {{joinedGameList.total | i18nPlural:translations.labelTranslations.joinedsnapplural}}
                </div>
                <betsnaps-game-card-loader *ngFor="let joinedGame of joinedGameList.results"
                                           [game]="joinedGame"
                                           [currentUser]="currentUser"
                                           (onSnapSwitch)="modal.approve(null);"
                                           class="game-card-wrap">
                </betsnaps-game-card-loader>
            </div>
            <div class="ui vertical borderless center aligned segment" *ngIf="joinedGameList.results.length < 1">
                <p class="margin-0">{{'FRIENDS.no_joined_snaps_found' | translate}}</p>
            </div>
        </div>
    </div>
</ng-template>

