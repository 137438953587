<div class="ui container">
    <div class="ui centered one column grid">
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div class="ui inverted transparent padded segment">
                <h1 class="ui center aligned header">
                    <i class="account circle icon"></i>
                    {{'LOGIN.headline' | translate | uppercase}}
                </h1>

                <div class="ui active centered inline text loader" *ngIf="showSpinner"></div>

                <fui-message *ngIf="showAlert" class="{{alertMessage.type ?? ''}}" [hasDismissButton]="false">
                    <div class="header" *ngIf="alertMessage.messagetranslatekey">
                        {{alertMessage.messagetranslatekey | translate}}
                    </div>
                    <p *ngIf="alertMessage.messagetxttranslatekey">{{alertMessage.messagetxttranslatekey | translate}}</p>
                </fui-message>
            </div>
        </div>
    </div>
</div>
