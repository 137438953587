<div class="content top container font-bold" [ngClass]="{
    'game-vendor-game-participation-inactive': gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.INACTIVE,
    'game-vendor-game-participation-active': gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.ACTIVE,
    'game-vendor-game-participation-closed': gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.CLOSED
    }">
    <div class="ui list horizontal">
        <div class="left aligned item truncate element-clickable" fuiPopup
             popupText="{{gameVendorGameParticipation.vendor_game.name | dbtranslation:'name':gameVendorGameParticipation.vendor_game.translations}}"
             popupTrigger="outsideClick">
            <img class="iconimage" [src]="'./assets/img/sport-999-color.svg'" alt="Casino" />
            {{gameVendorGameParticipation.vendor_game.name | dbtranslation:'name':gameVendorGameParticipation.vendor_game.translations}}
        </div>
        <div class="right aligned item">
            <div class="line-height-standard">
                <div *ngIf="gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.INACTIVE">
                    {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.STATI.inactive' | translate | uppercase}}
                </div>
                <div *ngIf="gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.ACTIVE">
                    {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.STATI.active' | translate | uppercase}}
                </div>
                <div *ngIf="gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.CLOSED">
                    {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.STATI.closed' | translate | uppercase}}
                </div>
                {{gameVendorGameParticipation.created_at | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }}
            </div>
        </div>
    </div>
</div>

<betsnaps-game-vendor-game-participation [game]="game"
                                         [gameVendorGameParticipation]="gameVendorGameParticipation"
                                         [gameVendorGameParticipationUpdatedDate]="gameVendorGameParticipation.updated_at"
                                         class="game-vendor-game-participation">
</betsnaps-game-vendor-game-participation>

<div class="content completed-participation-transaction-total">
    <div class="ui middle aligned equal width grid">
        <div class="row">
            <div class="medium column">
                <strong>{{'GENERAL.GAMEVENDORGAMEPARTICIPATIONLOG.total' | translate}}</strong>
            </div>
            <div class="right aligned big column">
                <strong>
                    <span *ngIf="participationSaldo() > 0">+</span>{{participationSaldo() | number:'1.2-2'}}
                    <span class="color-positive" *ngIf="participationSaldo() > 0"> &#x25B2;</span>
                    <span class="color-negative" *ngIf="participationSaldo() < 0"> &#x25BC;</span>
                </strong>
            </div>
        </div>
    </div>
</div>
