/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpRequestsFriendsFriendChangeRequest } from '../model/appHttpRequestsFriendsFriendChangeRequest';
// eslint-disable-next-line
import { AppHttpRequestsFriendsFriendGroupChangeFriendsRequest } from '../model/appHttpRequestsFriendsFriendGroupChangeFriendsRequest';
// eslint-disable-next-line
import { AppHttpRequestsFriendsFriendGroupCreateRequest } from '../model/appHttpRequestsFriendsFriendGroupCreateRequest';
// eslint-disable-next-line
import { AppHttpRequestsFriendsFriendGroupUpdateRequest } from '../model/appHttpRequestsFriendsFriendGroupUpdateRequest';
// eslint-disable-next-line
import { AppHttpResponsesFriendsFriendGroupHttpResponse } from '../model/appHttpResponsesFriendsFriendGroupHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFriendsFriendGroupListHttpResponse } from '../model/appHttpResponsesFriendsFriendGroupListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFriendsFriendHttpResponse } from '../model/appHttpResponsesFriendsFriendHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFriendsFriendInvitationHttpResponse } from '../model/appHttpResponsesFriendsFriendInvitationHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFriendsFriendInvitationListHttpResponse } from '../model/appHttpResponsesFriendsFriendInvitationListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesFriendsFriendListHttpResponse } from '../model/appHttpResponsesFriendsFriendListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FriendsApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Deletes a Friend Group
     * @param user_id User Id
     * @param group_id Group Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdPost(user_id: number, group_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdPost(user_id: number, group_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdPost(user_id: number, group_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdPost(user_id: number, group_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendgroupsGroupIdPost.');
        }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendgroupsGroupIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups/${this.configuration.encodeParam({name: "group_id", value: group_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove specific Friend from a Group
     * @param user_id User Id
     * @param group_id Group Id
     * @param friend_user_id Friend User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost(user_id: number, group_id: number, friend_user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost(user_id: number, group_id: number, friend_user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost(user_id: number, group_id: number, friend_user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost(user_id: number, group_id: number, friend_user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost.');
        }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost.');
        }
        if (friend_user_id === null || friend_user_id === undefined) {
            throw new Error('Required parameter friend_user_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups/${this.configuration.encodeParam({name: "group_id", value: group_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/remove/${this.configuration.encodeParam({name: "friend_user_id", value: friend_user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Decline an incoming friend invitation of a user
     * @param user_id User Id
     * @param friend_invitation_id Friend Invitation Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclinePost(user_id: number, friend_invitation_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendInvitationHttpResponse>;
    public apiOperaminideleteUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclinePost(user_id: number, friend_invitation_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendInvitationHttpResponse>>;
    public apiOperaminideleteUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclinePost(user_id: number, friend_invitation_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendInvitationHttpResponse>>;
    public apiOperaminideleteUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclinePost(user_id: number, friend_invitation_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclinePost.');
        }
        if (friend_invitation_id === null || friend_invitation_id === undefined) {
            throw new Error('Required parameter friend_invitation_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclinePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations/incoming/${this.configuration.encodeParam({name: "friend_invitation_id", value: friend_invitation_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/decline`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendInvitationHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an outgoing friend invitation of a user
     * @param user_id User Id
     * @param friend_invitation_id Friend Invitation Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteUsersUserIdFriendinvitationsOutgoingFriendInvitationIdPost(user_id: number, friend_invitation_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteUsersUserIdFriendinvitationsOutgoingFriendInvitationIdPost(user_id: number, friend_invitation_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteUsersUserIdFriendinvitationsOutgoingFriendInvitationIdPost(user_id: number, friend_invitation_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteUsersUserIdFriendinvitationsOutgoingFriendInvitationIdPost(user_id: number, friend_invitation_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendinvitationsOutgoingFriendInvitationIdPost.');
        }
        if (friend_invitation_id === null || friend_invitation_id === undefined) {
            throw new Error('Required parameter friend_invitation_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendinvitationsOutgoingFriendInvitationIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations/outgoing/${this.configuration.encodeParam({name: "friend_invitation_id", value: friend_invitation_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Friend
     * @param user_id User Id
     * @param friend_user_id Friend User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOperaminideleteUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiOperaminideleteUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOperaminideleteUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOperaminideleteUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendsFriendUserIdPost.');
        }
        if (friend_user_id === null || friend_user_id === undefined) {
            throw new Error('Required parameter friend_user_id was null or undefined when calling apiOperaminideleteUsersUserIdFriendsFriendUserIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/operaminidelete/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friends/${this.configuration.encodeParam({name: "friend_user_id", value: friend_user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Users Friend Groups
     * Get all User Friend Groups
     * @param user_id User Id
     * @param friend_user_id Parameter to filter all Groups where a specific Friend User ID is a member
     * @param exclude_friend_user_id Parameter to filter all Groups where a specific Friend User ID is not a member
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendgroupsGet(user_id: number, friend_user_id?: number, exclude_friend_user_id?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendGroupListHttpResponse>;
    public apiUsersUserIdFriendgroupsGet(user_id: number, friend_user_id?: number, exclude_friend_user_id?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendGroupListHttpResponse>>;
    public apiUsersUserIdFriendgroupsGet(user_id: number, friend_user_id?: number, exclude_friend_user_id?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendGroupListHttpResponse>>;
    public apiUsersUserIdFriendgroupsGet(user_id: number, friend_user_id?: number, exclude_friend_user_id?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendgroupsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (friend_user_id !== undefined && friend_user_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>friend_user_id, 'friend_user_id');
        }
        if (exclude_friend_user_id !== undefined && exclude_friend_user_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exclude_friend_user_id, 'exclude_friend_user_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendGroupListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds Friends to a Group
     * @param user_id User Id
     * @param group_id Group Id
     * @param app_http_requests_friends_friend_group_change_friends_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendgroupsGroupIdAddPost(user_id: number, group_id: number, app_http_requests_friends_friend_group_change_friends_request: AppHttpRequestsFriendsFriendGroupChangeFriendsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdFriendgroupsGroupIdAddPost(user_id: number, group_id: number, app_http_requests_friends_friend_group_change_friends_request: AppHttpRequestsFriendsFriendGroupChangeFriendsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdFriendgroupsGroupIdAddPost(user_id: number, group_id: number, app_http_requests_friends_friend_group_change_friends_request: AppHttpRequestsFriendsFriendGroupChangeFriendsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdFriendgroupsGroupIdAddPost(user_id: number, group_id: number, app_http_requests_friends_friend_group_change_friends_request: AppHttpRequestsFriendsFriendGroupChangeFriendsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdAddPost.');
        }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdAddPost.');
        }
        if (app_http_requests_friends_friend_group_change_friends_request === null || app_http_requests_friends_friend_group_change_friends_request === undefined) {
            throw new Error('Required parameter app_http_requests_friends_friend_group_change_friends_request was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdAddPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups/${this.configuration.encodeParam({name: "group_id", value: group_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/add`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_friends_friend_group_change_friends_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a Friend Group
     * @param user_id User Id
     * @param group_id Group Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendgroupsGroupIdDelete(user_id: number, group_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdFriendgroupsGroupIdDelete(user_id: number, group_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdFriendgroupsGroupIdDelete(user_id: number, group_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdFriendgroupsGroupIdDelete(user_id: number, group_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdDelete.');
        }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups/${this.configuration.encodeParam({name: "group_id", value: group_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get single friend group
     * Get single friend group
     * @param user_id User Id
     * @param group_id Group Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendgroupsGroupIdGet(user_id: number, group_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendGroupHttpResponse>;
    public apiUsersUserIdFriendgroupsGroupIdGet(user_id: number, group_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendGroupHttpResponse>>;
    public apiUsersUserIdFriendgroupsGroupIdGet(user_id: number, group_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendGroupHttpResponse>>;
    public apiUsersUserIdFriendgroupsGroupIdGet(user_id: number, group_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdGet.');
        }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups/${this.configuration.encodeParam({name: "group_id", value: group_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendGroupHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Friend Group
     * @param user_id User Id
     * @param group_id Group Id
     * @param app_http_requests_friends_friend_group_update_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendgroupsGroupIdPut(user_id: number, group_id: number, app_http_requests_friends_friend_group_update_request: AppHttpRequestsFriendsFriendGroupUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendGroupHttpResponse>;
    public apiUsersUserIdFriendgroupsGroupIdPut(user_id: number, group_id: number, app_http_requests_friends_friend_group_update_request: AppHttpRequestsFriendsFriendGroupUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendGroupHttpResponse>>;
    public apiUsersUserIdFriendgroupsGroupIdPut(user_id: number, group_id: number, app_http_requests_friends_friend_group_update_request: AppHttpRequestsFriendsFriendGroupUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendGroupHttpResponse>>;
    public apiUsersUserIdFriendgroupsGroupIdPut(user_id: number, group_id: number, app_http_requests_friends_friend_group_update_request: AppHttpRequestsFriendsFriendGroupUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdPut.');
        }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdPut.');
        }
        if (app_http_requests_friends_friend_group_update_request === null || app_http_requests_friends_friend_group_update_request === undefined) {
            throw new Error('Required parameter app_http_requests_friends_friend_group_update_request was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups/${this.configuration.encodeParam({name: "group_id", value: group_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendGroupHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_friends_friend_group_update_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove specific Friend from a Group
     * @param user_id User Id
     * @param group_id Group Id
     * @param friend_user_id Friend User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete(user_id: number, group_id: number, friend_user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete(user_id: number, group_id: number, friend_user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete(user_id: number, group_id: number, friend_user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete(user_id: number, group_id: number, friend_user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete.');
        }
        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete.');
        }
        if (friend_user_id === null || friend_user_id === undefined) {
            throw new Error('Required parameter friend_user_id was null or undefined when calling apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups/${this.configuration.encodeParam({name: "group_id", value: group_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/remove/${this.configuration.encodeParam({name: "friend_user_id", value: friend_user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Friend Group
     * @param user_id User Id
     * @param app_http_requests_friends_friend_group_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendgroupsPost(user_id: number, app_http_requests_friends_friend_group_create_request: AppHttpRequestsFriendsFriendGroupCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendGroupHttpResponse>;
    public apiUsersUserIdFriendgroupsPost(user_id: number, app_http_requests_friends_friend_group_create_request: AppHttpRequestsFriendsFriendGroupCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendGroupHttpResponse>>;
    public apiUsersUserIdFriendgroupsPost(user_id: number, app_http_requests_friends_friend_group_create_request: AppHttpRequestsFriendsFriendGroupCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendGroupHttpResponse>>;
    public apiUsersUserIdFriendgroupsPost(user_id: number, app_http_requests_friends_friend_group_create_request: AppHttpRequestsFriendsFriendGroupCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendgroupsPost.');
        }
        if (app_http_requests_friends_friend_group_create_request === null || app_http_requests_friends_friend_group_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_friends_friend_group_create_request was null or undefined when calling apiUsersUserIdFriendgroupsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendgroups`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendGroupHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_friends_friend_group_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all friend invitations of a user filtered by incoming and outgoing states
     * @param user_id User Id
     * @param incoming_states 0...pending, 1...declined, 2...confirmed
     * @param outgoing_states 0...pending, 1...declined, 2...confirmed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendinvitationsGet(user_id: number, incoming_states?: string, outgoing_states?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendInvitationListHttpResponse>;
    public apiUsersUserIdFriendinvitationsGet(user_id: number, incoming_states?: string, outgoing_states?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendInvitationListHttpResponse>>;
    public apiUsersUserIdFriendinvitationsGet(user_id: number, incoming_states?: string, outgoing_states?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendInvitationListHttpResponse>>;
    public apiUsersUserIdFriendinvitationsGet(user_id: number, incoming_states?: string, outgoing_states?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendinvitationsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (incoming_states !== undefined && incoming_states !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>incoming_states, 'incoming_states');
        }
        if (outgoing_states !== undefined && outgoing_states !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>outgoing_states, 'outgoing_states');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendInvitationListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm an incoming friend invitation of a user
     * @param user_id User Id
     * @param friend_invitation_id Friend Invitation Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdConfirmPut(user_id: number, friend_invitation_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendHttpResponse>;
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdConfirmPut(user_id: number, friend_invitation_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendHttpResponse>>;
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdConfirmPut(user_id: number, friend_invitation_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendHttpResponse>>;
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdConfirmPut(user_id: number, friend_invitation_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdConfirmPut.');
        }
        if (friend_invitation_id === null || friend_invitation_id === undefined) {
            throw new Error('Required parameter friend_invitation_id was null or undefined when calling apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdConfirmPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations/incoming/${this.configuration.encodeParam({name: "friend_invitation_id", value: friend_invitation_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/confirm`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendHttpResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Decline an incoming friend invitation of a user
     * @param user_id User Id
     * @param friend_invitation_id Friend Invitation Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclineDelete(user_id: number, friend_invitation_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendInvitationHttpResponse>;
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclineDelete(user_id: number, friend_invitation_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendInvitationHttpResponse>>;
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclineDelete(user_id: number, friend_invitation_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendInvitationHttpResponse>>;
    public apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclineDelete(user_id: number, friend_invitation_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclineDelete.');
        }
        if (friend_invitation_id === null || friend_invitation_id === undefined) {
            throw new Error('Required parameter friend_invitation_id was null or undefined when calling apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclineDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations/incoming/${this.configuration.encodeParam({name: "friend_invitation_id", value: friend_invitation_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/decline`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendInvitationHttpResponse>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all incoming friend invitations of a user
     * @param user_id User Id
     * @param state 0...pending, 1...declined, 2...confirmed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendinvitationsIncomingGet(user_id: number, state?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendInvitationListHttpResponse>;
    public apiUsersUserIdFriendinvitationsIncomingGet(user_id: number, state?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendInvitationListHttpResponse>>;
    public apiUsersUserIdFriendinvitationsIncomingGet(user_id: number, state?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendInvitationListHttpResponse>>;
    public apiUsersUserIdFriendinvitationsIncomingGet(user_id: number, state?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendinvitationsIncomingGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (state !== undefined && state !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>state, 'state');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations/incoming`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendInvitationListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an outgoing friend invitation of a user
     * @param user_id User Id
     * @param friend_invitation_id Friend Invitation Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendinvitationsOutgoingFriendInvitationIdDelete(user_id: number, friend_invitation_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdFriendinvitationsOutgoingFriendInvitationIdDelete(user_id: number, friend_invitation_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdFriendinvitationsOutgoingFriendInvitationIdDelete(user_id: number, friend_invitation_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdFriendinvitationsOutgoingFriendInvitationIdDelete(user_id: number, friend_invitation_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendinvitationsOutgoingFriendInvitationIdDelete.');
        }
        if (friend_invitation_id === null || friend_invitation_id === undefined) {
            throw new Error('Required parameter friend_invitation_id was null or undefined when calling apiUsersUserIdFriendinvitationsOutgoingFriendInvitationIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations/outgoing/${this.configuration.encodeParam({name: "friend_invitation_id", value: friend_invitation_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all outgoing friend invitations of a user
     * @param user_id User Id
     * @param state 0...pending, 1...declined, 2...confirmed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendinvitationsOutgoingGet(user_id: number, state?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendInvitationListHttpResponse>;
    public apiUsersUserIdFriendinvitationsOutgoingGet(user_id: number, state?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendInvitationListHttpResponse>>;
    public apiUsersUserIdFriendinvitationsOutgoingGet(user_id: number, state?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendInvitationListHttpResponse>>;
    public apiUsersUserIdFriendinvitationsOutgoingGet(user_id: number, state?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendinvitationsOutgoingGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (state !== undefined && state !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>state, 'state');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friendinvitations/outgoing`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendInvitationListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Friend
     * @param user_id User Id
     * @param friend_user_id Friend User Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendsFriendUserIdDelete(user_id: number, friend_user_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiUsersUserIdFriendsFriendUserIdDelete(user_id: number, friend_user_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiUsersUserIdFriendsFriendUserIdDelete(user_id: number, friend_user_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiUsersUserIdFriendsFriendUserIdDelete(user_id: number, friend_user_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendsFriendUserIdDelete.');
        }
        if (friend_user_id === null || friend_user_id === undefined) {
            throw new Error('Required parameter friend_user_id was null or undefined when calling apiUsersUserIdFriendsFriendUserIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friends/${this.configuration.encodeParam({name: "friend_user_id", value: friend_user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Friend
     * @param user_id User Id
     * @param friend_user_id Friend User Id
     * @param app_http_requests_friends_friend_change_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, app_http_requests_friends_friend_change_request?: AppHttpRequestsFriendsFriendChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendInvitationHttpResponse>;
    public apiUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, app_http_requests_friends_friend_change_request?: AppHttpRequestsFriendsFriendChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendInvitationHttpResponse>>;
    public apiUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, app_http_requests_friends_friend_change_request?: AppHttpRequestsFriendsFriendChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendInvitationHttpResponse>>;
    public apiUsersUserIdFriendsFriendUserIdPost(user_id: number, friend_user_id: number, app_http_requests_friends_friend_change_request?: AppHttpRequestsFriendsFriendChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendsFriendUserIdPost.');
        }
        if (friend_user_id === null || friend_user_id === undefined) {
            throw new Error('Required parameter friend_user_id was null or undefined when calling apiUsersUserIdFriendsFriendUserIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friends/${this.configuration.encodeParam({name: "friend_user_id", value: friend_user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendInvitationHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_friends_friend_change_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all friends of a user
     * @param user_id User Id
     * @param friendgroup_id Parameter to filter all Friends of a specific group
     * @param exclude_friendgroup_id Parameter to filter all Friends which are not member of a specific group
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersUserIdFriendsGet(user_id: number, friendgroup_id?: number, exclude_friendgroup_id?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesFriendsFriendListHttpResponse>;
    public apiUsersUserIdFriendsGet(user_id: number, friendgroup_id?: number, exclude_friendgroup_id?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesFriendsFriendListHttpResponse>>;
    public apiUsersUserIdFriendsGet(user_id: number, friendgroup_id?: number, exclude_friendgroup_id?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesFriendsFriendListHttpResponse>>;
    public apiUsersUserIdFriendsGet(user_id: number, friendgroup_id?: number, exclude_friendgroup_id?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling apiUsersUserIdFriendsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (friendgroup_id !== undefined && friendgroup_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>friendgroup_id, 'friendgroup_id');
        }
        if (exclude_friendgroup_id !== undefined && exclude_friendgroup_id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exclude_friendgroup_id, 'exclude_friendgroup_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/users/${this.configuration.encodeParam({name: "user_id", value: user_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/friends`;
        return this.httpClient.request<AppHttpResponsesFriendsFriendListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
