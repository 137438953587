export enum TenantImageTypeEnum {
    APPICON = 'appicon',
    B2B_LOGIN_BUTTON = 'b2b_login_button',
    BACKGROUND_MAIN = 'background_main',
    BACKGROUND_MAINTENANCE = 'background_maintenance',
    DEFAULT_GAME_DETAILS = 'default_game_details_background',
    DEFAULT_HEADER = 'default_header',
    DEFAULT_HIGHLIGHT_GAME_CARD = 'default_highlight_game_card',
    DEFAULT_RANKING_BANNER = 'default_ranking_banner',
    FAVICON = 'favicon',
    FAVICON_PNG = 'favicon_png',
    HINT_AVATAR = 'hint_avatar',
    JOIN_MODAL_IMAGE = 'join_modal_image',
    LOGIN_MODAL_IMAGE = 'login_modal_image',
    LOGO = 'logo',
    LOGO_SQUARE = 'logo_square',
    PUBLISHER_WIDGET_BACKGROUND = 'publisher_widget_background',
    PUBLISHER_WIDGET_LOGO = 'publisher_widget_logo',
    PUBLISHER_WIDGET_MOTIVATION = 'publisher_widget_motivation',
    SOCIAL_MEDIA_SHARE_IMAGE = 'social_media_share_image',
}
