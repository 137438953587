import {Component, OnInit} from '@angular/core';
import {AlertMessage} from '../../../../shared/interfaces';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AppHttpRequestsUsersUserEmailSignupResendVerificationRequest as EmailSignupResendVerificationRequest,
    SignupApi
} from '../../../../api';
import {AuthenticationService, ErrorService, GoogleAnalyticsService, TenantService} from '../../../../shared';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ALoginRegisterComponent} from '../a-login-register.component';

@Component({
    selector: 'betsnaps-resend-email-verification-form',
    templateUrl: './resend-email-verification-form.component.html',
    styles: []
})
export class ResendEmailVerificationFormComponent extends ALoginRegisterComponent implements OnInit {

    public processForm: boolean = false;
    public alertMessage: AlertMessage;
    public showAlert: boolean = false;

    public initResendVerification: UntypedFormGroup = new UntypedFormGroup({
        resendverification_identifier: new UntypedFormControl('', [Validators.required]),
    });

    private isB2b: boolean = false;

    constructor(private signupApi: SignupApi,
                private authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                private errorService: ErrorService,
                protected googleAnalyticsService: GoogleAnalyticsService) {
        super(tenantService, googleAnalyticsService);
        this.isB2b = this.tenantService.isB2b;
    }

    onSubmit() {
        this.processForm = true;
        this.showAlert = false;
        this.alertMessage = null;

        const resendVerificationRequest: EmailSignupResendVerificationRequest = {
            tenant_id: this.tenantService.tenantData.id,
            email: this.initResendVerification.value.resendverification_identifier
        };

        this.signupApi.apiUsersSignupEmailResendverificationPut(
            resendVerificationRequest
        ).pipe(take(1))
        .subscribe({
            next: () => {
                this.processForm = false;
                this.initResendVerification.reset();
                this.alertMessage = {
                    type: 'success',
                    messagetranslatekey: 'RESENDVERIFICATION.initiate_successfully_title',
                    messagetxttranslatekey: 'RESENDVERIFICATION.initiate_successfully'
                };
                this.showAlert = true;
            },
            error: (err: HttpErrorResponse) => {
                this.processForm = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    ngOnInit() {
    }

}
