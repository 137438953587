<div class="ui segments" *ngIf="currentUser">
    <div class="ui clearing segment">
        <div class="ui middle aligned two column grid">
            <div class="row">
                <div class="thirteen wide column">
                    <h2 class="ui header">
                        {{'PROFILE.OVERVIEW.MENU.settings' | translate}}
                    </h2>
                </div>
                <div class="right aligned three wide column">
                    <a routerLink="/profile/overview" class="ui basic circular icon button margin-0">
                        <i class="close icon margin-0"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="ui segment">

        <div class="ui active centered inline text loader" *ngIf="showSpinner">
            {{'GENERAL.loadertext' | translate}}
        </div>

        <form class="ui form" novalidate [formGroup]="settingsForm" (ngSubmit)="onSubmit()"
              [class.success]="submitted && !processForm"
              [class.error]="settingsForm.invalid && submitted && !processForm" *ngIf="!showSpinner">

            <h3 class="ui dividing header" *ngIf="languages">
                {{'PROFILE.SETTINGS.LANGUAGE.heading' | translate}}
            </h3>
            <div class="field" *ngIf="languages">
                <select id="user_language_id" name="user_language_id"
                        formControlName="user_language_id" class="ui dropdown">
                    <option *ngFor="let language of languages"
                            [value]="language.id">
                        {{language.name_local}}
                    </option>
                </select>
            </div>

            <h3 class="ui dividing header">
                {{'PROFILE.SETTINGS.ODD_FORMAT.heading' | translate}}
            </h3>
            <div class="field">
                <select id="odd_display_format" name="odd_display_format"
                        formControlName="odd_display_format"
                        class="ui dropdown">
                    <option value="value" selected>
                        {{'PROFILE.SETTINGS.ODD_FORMAT.points' | translate}}
                    </option>
                    <option value="odds">
                        {{'PROFILE.SETTINGS.ODD_FORMAT.decimal' | translate}}
                    </option>
                    <option value="fractional">
                        {{'PROFILE.SETTINGS.ODD_FORMAT.fractional' | translate}}
                    </option>
                    <option value="moneyline">
                        {{'PROFILE.SETTINGS.ODD_FORMAT.moneyline' | translate}}
                    </option>
                </select>
            </div>

            <h3 class="ui dividing header" *ngIf="tenantService.tenantData.configuration.outgoing_notifications">
                {{'PROFILE.SETTINGS.NOTIFICATIONS.heading' | translate}}
            </h3>
            <div class="field" *ngIf="showEmailNotificationsSetting && tenantService.tenantData.configuration.outgoing_notifications">
                <fui-checkbox class="slider"
                              name="email_notifications"
                              id="email_notifications"
                              formControlName="email_notifications">
                    {{'PROFILE.SETTINGS.NOTIFICATIONS.email' | translate}}
                </fui-checkbox>
            </div>
            <div class="field" *ngIf="showSmsNotificationsSetting && tenantService.tenantData.configuration.outgoing_notifications">
                <fui-checkbox class="slider"
                              name="sms_notifications"
                              id="sms_notifications"
                              formControlName="sms_notifications">
                    {{'PROFILE.SETTINGS.NOTIFICATIONS.sms' | translate}}
                </fui-checkbox>
            </div>
            <div class="field" *ngIf="showPushNotificationsSetting && tenantService.tenantData.configuration.outgoing_notifications">
                <fui-checkbox class="slider"
                              name="push_notifications"
                              id="push_notifications"
                              formControlName="push_notifications">
                    {{'PROFILE.SETTINGS.NOTIFICATIONS.push' | translate}}
                </fui-checkbox>
            </div>

            <div class="field center aligned">
                <button class="ui large button margin-0" type="submit"
                        [class.loading]="processForm"
                        [disabled]="processForm || !settingsForm.valid">
                    {{'PROFILE.SETTINGS.save_changes_btn' | translate | uppercase}}
                </button>
            </div>
        </form>
    </div>
</div>
