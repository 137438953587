/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesCMSsCMSHttpResponse } from './appHttpResponsesCMSsCMSHttpResponse';
import { AppHttpResponsesTranslationsMediaTranslationHttpResponse } from './appHttpResponsesTranslationsMediaTranslationHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TenantCMSHttpResponse
 */
export interface AppHttpResponsesTenantsTenantCMSHttpResponse { 
    /**
     * Tenant CMS ID
     */
    id: number;
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Master CMS ID
     */
    master_cms_id: number;
    /**
     * Tenant CMS Value
     */
    value: string;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    master_cms?: AppHttpResponsesCMSsCMSHttpResponse;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Media per language
     */
    media_translations?: Array<AppHttpResponsesTranslationsMediaTranslationHttpResponse>;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

