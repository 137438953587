/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesUsersUserPermissionHttpResponse } from './appHttpResponsesUsersUserPermissionHttpResponse';


/**
 * UserRoleHttpResponse
 */
export interface AppHttpResponsesUsersUserRoleHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Name
     */
    name: string;
    /**
     * Guard Name
     */
    guard_name: string;
    /**
     * Permissions
     */
    permissions?: Array<AppHttpResponsesUsersUserPermissionHttpResponse>;
}

