/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * SimpleSnapTournamentHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTournamentHttpResponse { 
    /**
     * Tournament Id
     */
    id: number;
    /**
     * Name
     */
    name: string;
    /**
     * Category Name
     */
    category_name?: string;
    /**
     * Category Icon
     */
    category_icon?: string;
}

