<div class="ui vertical borderless very padded segment" *ngIf="processGameVendorGames">
    <div class="ui active centered inline text loader color-white text-shadow">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processGameVendorGames">
    <div *ngIf="gameVendorGames?.length > 0">
        <section *ngIf="runningGameVendorGames() && runningGameVendorGames().length > 0"
                 id="live-game-vendor-games"
                 class="card-group"
                 #runningGameVendorGamesSection>
            <div class="ui large fluid label card-group-type-label">
                <strong class="font-very-bold margin-r-10">{{'BETSNAPDETAIL.MATCHES.MENU.live' | translate | uppercase | uppercase}}</strong>{{runningGameVendorGames().length}}
                <div class="jump-to" *ngIf="(upComingGameVendorGames() && upComingGameVendorGames().length > 0) || (closedGameVendorGames && closedGameVendorGames.length > 0)">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="(upComingGameVendorGames() && upComingGameVendorGames().length > 0) ? '#upcoming-matches' : '#closed-matches'"
                                                                       (click)="$event.preventDefault(); scrollToNextSection((upComingGameVendorGames() && upComingGameVendorGames().length > 0) ? upcomingGameVendorGamesSection : closedGameVendorGamesSection)">
                    {{((upComingGameVendorGames() && upComingGameVendorGames().length > 0) ? ('BETSNAPDETAIL.MATCHES.MENU.upcoming' | translate) : ('BETSNAPDETAIL.MATCHES.MENU.completed' | translate)) | uppercase}} <i class="fa fa-long-arrow-down mini icon margin-0"></i>
                </a>
                </div>
            </div>
            <betsnaps-game-vendor-game-card-loader
                *ngFor="let gameVendorGame of runningGameVendorGames(); trackBy: gameVendorGamesTrackBy; let gameIndexInList = index"
                [attr.id]="'vendorGame'+ gameVendorGame.vendor_game_id"
                [gameUpdatedDate]="game.updated_at"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [gameVendorGame]="gameVendorGame"
                [gameVendorGameStatus]="gameVendorGame.status"
                [gameIndexInList]="gameIndexInList"
                [adPositions]="adPositions">
            </betsnaps-game-vendor-game-card-loader>
        </section>
        <section *ngIf="upComingGameVendorGames() && upComingGameVendorGames().length > 0"
                 id="upcoming-game-vendor-games"
                 class="card-group"
                 #upcomingGameVendorGamesSection>
            <div class="ui large fluid label card-group-type-label">
                <strong class="font-very-bold margin-r-10">{{'BETSNAPDETAIL.MATCHES.MENU.upcoming' | translate | uppercase}}</strong>{{upComingGameVendorGames().length}}
                <div class="jump-to" *ngIf="closedGameVendorGames() && closedGameVendorGames().length > 0">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="'#closed-matches'"
                                                                       (click)="$event.preventDefault(); scrollToNextSection(closedGameVendorGamesSection)">
                    {{'BETSNAPDETAIL.MATCHES.MENU.completed' | translate | uppercase}} <i class="fa fa-long-arrow-down mini icon margin-0"></i>
                </a>
                </div>
            </div>
            <betsnaps-game-vendor-game-card-loader
                *ngFor="let gameVendorGame of upComingGameVendorGames(); trackBy: gameVendorGamesTrackBy; let gameIndexInList = index"
                [attr.id]="'vendorGame'+ gameVendorGame.vendor_game_id"
                [gameUpdatedDate]="game.updated_at"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [gameVendorGame]="gameVendorGame"
                [gameVendorGameStatus]="gameVendorGame.status"
                [gameIndexInList]="gameIndexInList + runningGameVendorGames().length"
                [adPositions]="adPositions">
            </betsnaps-game-vendor-game-card-loader>
        </section>
        <section *ngIf="closedGameVendorGames() && closedGameVendorGames().length > 0"
                 id="completed-game-vendor-games"
                 class="card-group"
                 #closedGameVendorGamesSection>
            <div class="ui large fluid label card-group-type-label">
                <strong class="font-very-bold margin-r-10">{{'BETSNAPDETAIL.MATCHES.MENU.completed' | translate | uppercase}}</strong>{{closedGameVendorGames().length}}
                <div class="jump-to" *ngIf="(upComingGameVendorGames() && upComingGameVendorGames().length > 0) || (runningGameVendorGames() && runningGameVendorGames().length > 0)">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="(runningGameVendorGames() && runningGameVendorGames().length > 0) ? '#live-matches' : '#upcoming-matches'"
                                                                       (click)="$event.preventDefault(); scrollToNextSection((runningGameVendorGames() && runningGameVendorGames().length > 0) ? runningGameVendorGamesSection : upcomingGameVendorGamesSection)">
                    {{((runningGameVendorGames() && runningGameVendorGames().length > 0) ? ('BETSNAPDETAIL.MATCHES.MENU.live' | translate) : ('BETSNAPDETAIL.MATCHES.MENU.upcoming' | translate)) | uppercase}} <i class="fa fa-long-arrow-up mini icon margin-0"></i>
                </a>
                </div>
            </div>
            <betsnaps-game-vendor-game-card-loader
                *ngFor="let gameVendorGame of closedGameVendorGames(); trackBy: gameVendorGamesTrackBy; let gameIndexInList = index"
                [attr.id]="'vendorGame'+ gameVendorGame.vendor_game_id"
                [gameUpdatedDate]="game.updated_at"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [gameVendorGame]="gameVendorGame"
                [gameVendorGameStatus]="gameVendorGame.status"
                [gameIndexInList]="gameIndexInList + runningGameVendorGames().length + upComingGameVendorGames().length"
                [adPositions]="adPositions">
            </betsnaps-game-vendor-game-card-loader>
        </section>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="gameVendorGames?.length < 1">
        <p class="color-white text-shadow">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
