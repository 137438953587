import {Injectable} from '@angular/core';
import {
    AppHttpRequestsBetsnapsGamesGameFriendInvitationRequest as GameFriendInvitationRequest,
    AppHttpRequestsFriendsFriendGroupChangeFriendsRequest as FriendGroupChangeFriendsRequest,
    AppHttpRequestsFriendsFriendGroupCreateRequest as FriendGroupCreateRequest,
    AppHttpRequestsFriendsFriendGroupUpdateRequest as FriendGroupUpdateRequest,
    AppHttpRequestsTenantsTenantNewUserInvitationRequest as TenantNewUserInvitationRequest,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameListHttpResponse,
    AppHttpResponsesFriendsFriendGroupHttpResponse as FriendGroupHttpResponse,
    AppHttpResponsesFriendsFriendGroupListHttpResponse as FriendGroupListHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse,
    AppHttpResponsesFriendsFriendInvitationHttpResponse as FriendInvitationHttpResponse,
    AppHttpResponsesFriendsFriendInvitationListHttpResponse as FriendInvitationListHttpResponse,
    AppHttpResponsesFriendsFriendListHttpResponse as FriendListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersPlayerPublicListHttpResponse as PlayerPublicListHttpResponse,
    FriendsApi,
    GamesApi,
    TenantsApi,
    UsersApi
} from '../../api';
import {
    AppEventsFriendsFriendAdded as FriendAddedEvent,
    AppEventsFriendsFriendGroupCreated as FriendGroupCreatedEvent,
    AppEventsFriendsFriendGroupDeleted as FriendGroupDeletedEvent,
    AppEventsFriendsFriendGroupUpdated as FriendGroupUpdatedEvent,
    AppEventsFriendsFriendInvitationConfirmed as FriendInvitationConfirmedEvent,
    AppEventsFriendsFriendInvitationDeclined as FriendInvitationDeclinedEvent,
    AppEventsFriendsFriendInvitationDeleted as FriendInvitationDeletedEvent,
    AppEventsFriendsFriendInvitationReceived as FriendInvitationReceivedEvent,
    AppEventsFriendsFriendInvitationSent as FriendInvitationSentEvent,
    AppEventsFriendsFriendRemoved as FriendRemovedEvent,
} from '../';

import {FriendshipStateEnum} from '../enums';
import {FriendshipState} from '../interfaces';

import {TenantService} from './tenant.service';
import {MobiledetectService} from './mobiledetect.service';
import {BroadcastingService} from './broadcasting.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from './error.service';
import {DebugService} from './debug.service';
import {map, take} from 'rxjs/operators';
import {FriendInvitationStateEnum} from '../enums/friend-invitation-state.enum';

@Injectable({
    providedIn: 'root'
})
export class FriendsService {

    private _currentUser: PlayerHttpResponse;
    private _authToken: string;

    private userFriendsSubject = new BehaviorSubject<FriendListHttpResponse>(null);
    public userFriends$ = this.userFriendsSubject.asObservable();
    private userFriendsRequestRunning = false;

    private userFriendGroupsSubject = new BehaviorSubject<FriendGroupListHttpResponse>(null);
    public userFriendGroups$ = this.userFriendGroupsSubject.asObservable();
    private userFriendsGroupRequestRunning = false;

    private userFriendInvitationsIncomingSubject = new BehaviorSubject<FriendInvitationListHttpResponse>(null);
    public userFriendInvitationsIncoming$ = this.userFriendInvitationsIncomingSubject.asObservable();
    private userFriendInvitationsIncomingCountSubject = new BehaviorSubject<number>(0);
    public userFriendInvitationsIncomingCount$ = this.userFriendInvitationsIncomingCountSubject.asObservable();
    private userFriendInvitationsIncomingPendingRequestRunning = false;

    private userFriendInvitationsIncomingDeclinedSubject = new BehaviorSubject<FriendInvitationListHttpResponse>(null);
    public userFriendInvitationsIncomingDeclined$ = this.userFriendInvitationsIncomingDeclinedSubject.asObservable();
    private userFriendInvitationsIncomingDeclinedRequestRunning = false;

    private userFriendInvitationsOutgoingSubject = new BehaviorSubject<FriendInvitationListHttpResponse>(null);
    public userFriendInvitationsOutgoing$ = this.userFriendInvitationsOutgoingSubject.asObservable();
    private userFriendInvitationsOutgoingRequestRunning = false;

    private doFriendGroupUpdateSubject = new BehaviorSubject<FriendGroupHttpResponse>(null);
    public doFriendGroupUpdate$ = this.doFriendGroupUpdateSubject.asObservable();

    private broadcastEventSubscriptions: Subscription[] = [];
    private apiGetRequestSubscriptions: Subscription[] = [];

    constructor(private tenantService: TenantService,
                private mobileDetect: MobiledetectService,
                private tenantsApi: TenantsApi,
                private gamesApi: GamesApi,
                private friendsApi: FriendsApi,
                private usersApi: UsersApi,
                private broadcastingService: BroadcastingService,
                private debugService: DebugService,
                private errorService: ErrorService) {
    }

    public set currentUser(user: PlayerHttpResponse) {
        this._currentUser = user;
    }

    public set authToken(userAuthToken: string) {
        this._authToken = userAuthToken;
    }

    initFriendsData(currentUser: PlayerHttpResponse, authToken: string) {
        this._currentUser = currentUser;
        this._authToken = authToken;

        this.loadAllFriendsData();
    }

    public loadAllFriendsData() {
        if (this._currentUser) {
            this.getUserFriends(true);
            this.getUserFriendGroups(true);
            this.getUserFriendsInvitationsIncomingAndOutgoing(true);
            this.subscribeForFriendBroadcastEvents();
        }
    }

    getUserFriends(reloadData: boolean = false) {
        if (!this.userFriendsSubject.value || reloadData) {
            this.userFriendsRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.friendsApi.apiUsersUserIdFriendsGet(
                    this._currentUser.id
                ).pipe(take(1))
                .subscribe({
                    next: (friendListHttpResponse: FriendListHttpResponse) => {
                        this.userFriendsSubject.next(friendListHttpResponse);
                        this.userFriendsRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.userFriendsRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    searchUsers(perPage: number, currentPage: number, searchstring: string, only_friends?: boolean, exclude_friends?: boolean): Observable<PlayerPublicListHttpResponse> {
        return this.usersApi.apiPublicUsersGet(
            perPage,
            currentPage,
            searchstring,
            only_friends,
            exclude_friends
        );
    }

    get userFriends(): FriendListHttpResponse {
        return this.userFriendsSubject.getValue();
    }

    getUserFriendsInGroup(friendGroupId: number): Observable<FriendListHttpResponse> {
        return this.friendsApi.apiUsersUserIdFriendsGet(
            this._currentUser.id,
            friendGroupId
        );
    }

    getUserFriendsNotInGroup(friendGroupId: number): Observable<FriendListHttpResponse> {
        return this.friendsApi.apiUsersUserIdFriendsGet(
            this._currentUser.id,
            undefined,
            friendGroupId
        );
    }

    getUserFriendGroups(reloadData: boolean = false) {
        if (!this.userFriendGroupsSubject.value || reloadData) {
            this.userFriendsGroupRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.friendsApi.apiUsersUserIdFriendgroupsGet(
                    this._currentUser.id
                ).pipe(take(1))
                .subscribe({
                    next: (friendGroupListHttpResponse: FriendGroupListHttpResponse) => {
                        this.userFriendGroupsSubject.next(friendGroupListHttpResponse);
                        this.userFriendsGroupRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.userFriendsGroupRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    get userFriendsGroups(): FriendGroupListHttpResponse {
        return this.userFriendGroupsSubject.getValue();
    }

    createFriendGroup(friendGroupName, friendIdsToAdd: number[]): Observable<FriendGroupHttpResponse> {
        const createFriendGroupBody: FriendGroupCreateRequest = {
            name: friendGroupName,
            friends: friendIdsToAdd
        };
        return this.friendsApi.apiUsersUserIdFriendgroupsPost(
            this._currentUser.id,
            createFriendGroupBody
        ).pipe(map(
            (friendGroupHttpResponse: FriendGroupHttpResponse) => {
                this.addFriendGroupToList(friendGroupHttpResponse);
                return friendGroupHttpResponse;
            }
        ));
    }

    addFriendGroupToList(friendGroupHttpResponse: FriendGroupHttpResponse) {
        if (!this.userFriendsGroupRequestRunning) {
            const currentUserFriendGroupList = this.userFriendGroupsSubject.getValue();
            if (currentUserFriendGroupList) {
                const groupExistsInList = currentUserFriendGroupList.results.find(
                    (groupInList: FriendGroupHttpResponse) => groupInList.id === friendGroupHttpResponse.id);
                if (!groupExistsInList) {
                    currentUserFriendGroupList.results.push(friendGroupHttpResponse);
                    this.userFriendGroupsSubject.next(currentUserFriendGroupList);
                }
            }
        }
    }

    updateFriendGroup(groupId, updateFriendGroupFormData): Observable<FriendGroupHttpResponse> {
        const updateFriendGroupBody: FriendGroupUpdateRequest = {
            name: updateFriendGroupFormData.name,
            abbr: updateFriendGroupFormData.abbr
        };
        return this.friendsApi.apiUsersUserIdFriendgroupsGroupIdPut(
            this._currentUser.id,
            groupId,
            updateFriendGroupBody
        ).pipe(map(
            (friendGroupHttpResponse: FriendGroupHttpResponse) => {
                this.updateFriendGroupInList(friendGroupHttpResponse);
                return friendGroupHttpResponse;
            }
        ));
    }

    updateFriendGroupInList(friendGroupHttpResponse: FriendGroupHttpResponse) {
        const currentUserFriendGroupList = this.userFriendGroupsSubject.getValue();
        if (currentUserFriendGroupList) {
            currentUserFriendGroupList.results = currentUserFriendGroupList.results.map(
                (friendGroupInList: FriendGroupHttpResponse) => {
                    if (friendGroupInList.id === friendGroupHttpResponse.id) {
                        return friendGroupHttpResponse;
                    } else {
                        return friendGroupInList;
                    }
                }
            );
            this.userFriendGroupsSubject.next(currentUserFriendGroupList);
        }
    }

    deleteFriendGroup(friendGroupId: number): void {
        let apiMethodName = 'apiUsersUserIdFriendgroupsGroupIdDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteUsersUserIdFriendgroupsGroupIdPost';
        }

        this.friendsApi[apiMethodName](
            this._currentUser.id,
            friendGroupId
        ).pipe(take(1)).subscribe(
            () => {
                this.deleteFriendGroupFromList(friendGroupId);
            }, (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        );
    }

    deleteFriendGroupFromList(friendGroupId: number) {
        if (!this.userFriendsGroupRequestRunning) {
            const currentUserFriendGroupList = this.userFriendGroupsSubject.getValue();
            if (currentUserFriendGroupList) {
                currentUserFriendGroupList.results = currentUserFriendGroupList.results.filter(
                    (friendGroup: FriendGroupHttpResponse) => friendGroup.id !== friendGroupId);
                this.userFriendGroupsSubject.next(currentUserFriendGroupList);
            }
        }
    }

    addFriendsToGroup(friendsToAdd: number[], groupId: number): Observable<{}> {
        const friendGroupChangeFriendsRequest: FriendGroupChangeFriendsRequest = {
            friends: friendsToAdd
        };
        return this.friendsApi.apiUsersUserIdFriendgroupsGroupIdAddPost(
            this._currentUser.id,
            groupId,
            friendGroupChangeFriendsRequest
        ).pipe(map(
            (responseData) => {
                // update friends_count of group
                const currentUserFriendGroupList = this.userFriendGroupsSubject.getValue();
                if (currentUserFriendGroupList) {
                    currentUserFriendGroupList.results = currentUserFriendGroupList.results.map(
                        (friendGroupInList: FriendGroupHttpResponse) => {
                            if (friendGroupInList.id === groupId) {
                                friendGroupInList.friends_count += friendsToAdd.length;
                                return friendGroupInList;
                            } else {
                                return friendGroupInList;
                            }
                        }
                    );
                    this.userFriendGroupsSubject.next(currentUserFriendGroupList);
                }
                return responseData;
            }
        ));
    }

    removeFriendFromGroup(friendUserId: number, groupId: number): Observable<{}> {
        let apiMethodName = 'apiUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteUsersUserIdFriendgroupsGroupIdRemoveFriendUserIdPost';
        }

        return this.friendsApi[apiMethodName](
            this._currentUser.id,
            groupId,
            friendUserId
        ).pipe(map(
            (responseData) => {
                // update friends_count of group
                const currentUserFriendGroupList = this.userFriendGroupsSubject.getValue();
                if (currentUserFriendGroupList) {
                    currentUserFriendGroupList.results = currentUserFriendGroupList.results.map(
                        (friendGroupInList: FriendGroupHttpResponse) => {
                            if (friendGroupInList.id === groupId) {
                                friendGroupInList.friends_count -= 1;
                                return friendGroupInList;
                            } else {
                                return friendGroupInList;
                            }
                        }
                    );
                    this.userFriendGroupsSubject.next(currentUserFriendGroupList);
                }
                return responseData;
            }
        ));
    }

    getUserFriendsInvitationsIncomingAndOutgoing(reloadData: boolean = false) {
        if (!this.userFriendInvitationsIncomingSubject.value || !this.userFriendInvitationsIncomingDeclinedSubject.value || !this.userFriendInvitationsOutgoingSubject.value || reloadData) {
            this.userFriendInvitationsIncomingPendingRequestRunning = true;
            this.userFriendInvitationsIncomingDeclinedRequestRunning = true;
            this.userFriendInvitationsOutgoingRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.friendsApi.apiUsersUserIdFriendinvitationsGet(
                    this._currentUser.id, [FriendInvitationStateEnum.PENDING, FriendInvitationStateEnum.DECLINED].join(','), [FriendInvitationStateEnum.PENDING].join(',')
                ).pipe(take(1))
                .subscribe({
                    next: (friendInvitationList: FriendInvitationListHttpResponse) => {
                        const friendInvitationListHttpResponseIncomingPending = {total: 0, count: 0, results: []} as FriendInvitationListHttpResponse;
                        const friendInvitationListHttpResponseIncomingDeclined = {total: 0, count: 0, results: []} as FriendInvitationListHttpResponse;
                        const friendInvitationListHttpResponseOutgoingPending = {total: 0, count: 0, results: []} as FriendInvitationListHttpResponse;

                        friendInvitationList.results.forEach((friendInvitation: FriendInvitationHttpResponse) => {
                            // Incoming Invitations
                            if (friendInvitation.receiver_user_id === this._currentUser.id) {
                                if (friendInvitation.state === FriendInvitationStateEnum.PENDING) {
                                    friendInvitationListHttpResponseIncomingPending.results.push(friendInvitation);
                                }
                                if (friendInvitation.state === FriendInvitationStateEnum.DECLINED) {
                                    friendInvitationListHttpResponseIncomingDeclined.results.push(friendInvitation);
                                }
                            }

                            // Outgoing Invitations
                            if ((friendInvitation.sender_user_id === this._currentUser.id)) {
                                if (friendInvitation.state === FriendInvitationStateEnum.PENDING) {
                                    friendInvitationListHttpResponseOutgoingPending.results.push(friendInvitation);
                                }
                            }
                        });

                        friendInvitationListHttpResponseIncomingPending.count = friendInvitationListHttpResponseIncomingPending.results.length;
                        friendInvitationListHttpResponseIncomingPending.total = friendInvitationListHttpResponseIncomingPending.results.length;
                        this.userFriendInvitationsIncomingSubject.next(friendInvitationListHttpResponseIncomingPending);
                        this.userFriendInvitationsIncomingCountSubject.next(friendInvitationListHttpResponseIncomingPending.total);
                        this.userFriendInvitationsIncomingPendingRequestRunning = false;

                        friendInvitationListHttpResponseIncomingDeclined.count = friendInvitationListHttpResponseIncomingDeclined.results.length;
                        friendInvitationListHttpResponseIncomingDeclined.total = friendInvitationListHttpResponseIncomingDeclined.results.length;
                        this.userFriendInvitationsIncomingDeclinedSubject.next(friendInvitationListHttpResponseIncomingDeclined);
                        this.userFriendInvitationsIncomingDeclinedRequestRunning = false;

                        friendInvitationListHttpResponseOutgoingPending.count = friendInvitationListHttpResponseOutgoingPending.results.length;
                        friendInvitationListHttpResponseOutgoingPending.total = friendInvitationListHttpResponseOutgoingPending.results.length;
                        this.userFriendInvitationsOutgoingSubject.next(friendInvitationListHttpResponseOutgoingPending);
                        this.userFriendInvitationsOutgoingRequestRunning = false;

                    },
                    error: (err: HttpErrorResponse) => {
                        this.userFriendInvitationsIncomingPendingRequestRunning = false;
                        this.userFriendInvitationsIncomingDeclinedRequestRunning = false;
                        this.userFriendInvitationsOutgoingRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    getUserFriendInvitationsIncoming(reloadData: boolean = false) {
        if (!this.userFriendInvitationsIncomingSubject.value || reloadData) {
            this.userFriendInvitationsIncomingPendingRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.friendsApi.apiUsersUserIdFriendinvitationsIncomingGet(
                    this._currentUser.id,
                    FriendInvitationStateEnum.PENDING
                ).pipe(take(1))
                .subscribe({
                    next: (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                        this.userFriendInvitationsIncomingSubject.next(friendInvitationListHttpResponse);
                        this.userFriendInvitationsIncomingCountSubject.next(this.userFriendInvitationsIncoming.total);
                        this.userFriendInvitationsIncomingPendingRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.userFriendInvitationsIncomingPendingRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    get userFriendInvitationsIncoming() {
        return this.userFriendInvitationsIncomingSubject.getValue();
    }

    getUserFriendInvitationsIncomingDeclined(reloadData: boolean = false) {
        if (!this.userFriendInvitationsIncomingDeclinedSubject.value || reloadData) {
            this.userFriendInvitationsIncomingDeclinedRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.friendsApi.apiUsersUserIdFriendinvitationsIncomingGet(
                    this._currentUser.id,
                    FriendInvitationStateEnum.DECLINED
                ).pipe(take(1))
                .subscribe({
                    next: (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                        this.userFriendInvitationsIncomingDeclinedSubject.next(friendInvitationListHttpResponse);
                        this.userFriendInvitationsIncomingDeclinedRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.userFriendInvitationsIncomingDeclinedRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    getUserFriendInvitationsOutgoing(reloadData = false) {
        if (!this.userFriendInvitationsOutgoingSubject.value || reloadData) {
            this.userFriendInvitationsOutgoingRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.friendsApi.apiUsersUserIdFriendinvitationsOutgoingGet(
                    this._currentUser.id,
                    FriendInvitationStateEnum.PENDING
                ).pipe(take(1))
                .subscribe({
                    next: (friendInvitationListHttpResponse: FriendInvitationListHttpResponse) => {
                        this.userFriendInvitationsOutgoingSubject.next(friendInvitationListHttpResponse);
                        this.userFriendInvitationsOutgoingRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.userFriendInvitationsOutgoingRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    get userFriendInvitationsOutgoing() {
        return this.userFriendInvitationsOutgoingSubject.getValue();
    }

    public acceptFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse): Observable<FriendHttpResponse> {
        return this.friendsApi.apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdConfirmPut(
            this._currentUser.id,
            friendInvitationHttpResponse.id
        ).pipe(map(
            (friendHttpResponse: FriendHttpResponse) => {
                this.removeIncomingFriendInvitation(friendInvitationHttpResponse);
                this.removeIncomingDeclinedFriendInvitation(friendInvitationHttpResponse);
                this.addFriendToFriendList(friendHttpResponse);
                return friendHttpResponse;
            }
        ));
    }

    declineFriendInvitation(friendInvitationToDecline: FriendInvitationHttpResponse): Observable<FriendInvitationHttpResponse> {
        let apiMethodName = 'apiUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclineDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteUsersUserIdFriendinvitationsIncomingFriendInvitationIdDeclinePost';
        }

        return this.friendsApi[apiMethodName](
            this._currentUser.id,
            friendInvitationToDecline.id
        ).pipe(map(
            (friendInvitationHttpResponse: FriendInvitationHttpResponse) => {
                this.removeIncomingFriendInvitation(friendInvitationHttpResponse);
                this.addIncomingDeclinedFriendInvitation(friendInvitationHttpResponse);
                return friendInvitationHttpResponse;
            }
        ));
    }

    deleteFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse): Observable<any> {
        let apiMethodName = 'apiUsersUserIdFriendinvitationsOutgoingFriendInvitationIdDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteUsersUserIdFriendinvitationsOutgoingFriendInvitationIdPost';
        }

        return this.friendsApi[apiMethodName](
            this._currentUser.id,
            friendInvitationHttpResponse.id
        ).pipe(map(
            () => {
                this.deleteFriendInvitationInAllLists(friendInvitationHttpResponse);
            }
        ));
    }

    addOutgoingFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse) {
        if (!this.userFriendInvitationsOutgoingRequestRunning) {
            const currentUserFriendInvitationsOutgoing = this.userFriendInvitationsOutgoingSubject.getValue();
            if (currentUserFriendInvitationsOutgoing) {
                const invitationExistsInList = currentUserFriendInvitationsOutgoing.results.find(
                    (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                        friendInvitationHttpResponseInList.id === friendInvitationHttpResponse.id
                );
                if (!invitationExistsInList) {
                    currentUserFriendInvitationsOutgoing.results.push(friendInvitationHttpResponse);
                    this.userFriendInvitationsOutgoingSubject.next(currentUserFriendInvitationsOutgoing);
                }
            }
        }
    }

    removeOutgoingFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse) {
        if (!this.userFriendInvitationsOutgoingRequestRunning) {
            const currentUserFriendInvitationsOutgoing = this.userFriendInvitationsOutgoingSubject.getValue();
            if (currentUserFriendInvitationsOutgoing) {
                currentUserFriendInvitationsOutgoing.results = currentUserFriendInvitationsOutgoing.results.filter(
                    (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                        friendInvitationHttpResponseInList.id !== friendInvitationHttpResponse.id
                );
                this.userFriendInvitationsOutgoingSubject.next(currentUserFriendInvitationsOutgoing);
            }
        }
    }

    addIncomingFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse) {
        if (!this.userFriendInvitationsIncomingPendingRequestRunning) {
            const currentUserFriendInvitationsIncoming = this.userFriendInvitationsIncomingSubject.getValue();
            if (currentUserFriendInvitationsIncoming) {
                const invitationExistsInList = currentUserFriendInvitationsIncoming.results.find(
                    (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                        friendInvitationHttpResponseInList.id === friendInvitationHttpResponse.id
                );
                if (!invitationExistsInList) {
                    currentUserFriendInvitationsIncoming.results.push(friendInvitationHttpResponse);
                    currentUserFriendInvitationsIncoming.count = currentUserFriendInvitationsIncoming.count + 1;
                    currentUserFriendInvitationsIncoming.total = currentUserFriendInvitationsIncoming.total + 1;

                    this.userFriendInvitationsIncomingSubject.next(currentUserFriendInvitationsIncoming);

                    // update the open friend invitations counter
                    this.userFriendInvitationsIncomingCountSubject.next(this.userFriendInvitationsIncoming.total);
                }
            }
        }
    }

    removeIncomingFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse) {
        if (!this.userFriendInvitationsIncomingPendingRequestRunning) {
            const currentUserFriendInvitationsIncoming = this.userFriendInvitationsIncomingSubject.getValue();
            if (currentUserFriendInvitationsIncoming) {
                currentUserFriendInvitationsIncoming.results =
                    currentUserFriendInvitationsIncoming.results.filter(
                        (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                            friendInvitationHttpResponseInList.id !== friendInvitationHttpResponse.id
                    );
                if (currentUserFriendInvitationsIncoming.count > 0) {
                    currentUserFriendInvitationsIncoming.count = currentUserFriendInvitationsIncoming.count - 1;
                }
                if (currentUserFriendInvitationsIncoming.total > 0) {
                    currentUserFriendInvitationsIncoming.total = currentUserFriendInvitationsIncoming.total - 1;
                }
                this.userFriendInvitationsIncomingSubject.next(currentUserFriendInvitationsIncoming);

                // update the open friend invitations counter
                this.userFriendInvitationsIncomingCountSubject.next(this.userFriendInvitationsIncoming.total);
            }
        }
    }

    addIncomingDeclinedFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse) {
        if (!this.userFriendInvitationsIncomingDeclinedRequestRunning) {
            const currentUserFriendInvitationsIncomingDeclined = this.userFriendInvitationsIncomingDeclinedSubject.getValue();
            if (currentUserFriendInvitationsIncomingDeclined) {
                const invitationExistsInList = currentUserFriendInvitationsIncomingDeclined.results.find(
                    (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                        friendInvitationHttpResponseInList.id === friendInvitationHttpResponse.id
                );
                if (!invitationExistsInList) {
                    currentUserFriendInvitationsIncomingDeclined.results.push(friendInvitationHttpResponse);
                    this.userFriendInvitationsIncomingDeclinedSubject.next(currentUserFriendInvitationsIncomingDeclined);
                }
            }
        }
    }

    removeIncomingDeclinedFriendInvitation(friendInvitationHttpResponse: FriendInvitationHttpResponse) {
        if (!this.userFriendInvitationsIncomingDeclinedRequestRunning) {
            const currentUserFriendInvitationsIncomingDeclined = this.userFriendInvitationsIncomingDeclinedSubject.getValue();
            if (currentUserFriendInvitationsIncomingDeclined) {
                currentUserFriendInvitationsIncomingDeclined.results =
                    currentUserFriendInvitationsIncomingDeclined.results.filter(
                        (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                            friendInvitationHttpResponseInList.id !== friendInvitationHttpResponse.id
                    );
                this.userFriendInvitationsIncomingDeclinedSubject.next(currentUserFriendInvitationsIncomingDeclined);
            }
        }
    }

    deleteFriendInvitationInAllLists(friendInvitationHttpResponse: FriendInvitationHttpResponse) {
        const currentUserFriendInvitationsOutgoing = this.userFriendInvitationsOutgoingSubject.getValue();
        if (currentUserFriendInvitationsOutgoing) {
            currentUserFriendInvitationsOutgoing.results =
                currentUserFriendInvitationsOutgoing.results.filter(
                    (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                        friendInvitationHttpResponseInList.id !== friendInvitationHttpResponse.id
                );
            this.userFriendInvitationsOutgoingSubject.next(currentUserFriendInvitationsOutgoing);
        }

        const currentUserFriendInvitationsIncoming = this.userFriendInvitationsIncomingSubject.getValue();
        if (currentUserFriendInvitationsIncoming) {
            currentUserFriendInvitationsIncoming.results =
                currentUserFriendInvitationsIncoming.results.filter(
                    (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                        friendInvitationHttpResponseInList.id !== friendInvitationHttpResponse.id
                );
            if (currentUserFriendInvitationsIncoming.count > 0) {
                currentUserFriendInvitationsIncoming.count = currentUserFriendInvitationsIncoming.count - 1;
            }
            if (currentUserFriendInvitationsIncoming.total > 0) {
                currentUserFriendInvitationsIncoming.total = currentUserFriendInvitationsIncoming.total - 1;
            }
            this.userFriendInvitationsIncomingSubject.next(currentUserFriendInvitationsIncoming);

            // update the open friend invitations counter
            this.userFriendInvitationsIncomingCountSubject.next(this.userFriendInvitationsIncoming.total);
        }

        const currentUserFriendInvitationsIncomingDeclined = this.userFriendInvitationsIncomingDeclinedSubject.getValue();
        if (currentUserFriendInvitationsIncomingDeclined) {
            currentUserFriendInvitationsIncomingDeclined.results =
                currentUserFriendInvitationsIncomingDeclined.results.filter(
                    (friendInvitationHttpResponseInList: FriendInvitationHttpResponse) =>
                        friendInvitationHttpResponseInList.id !== friendInvitationHttpResponse.id
                );
            this.userFriendInvitationsIncomingDeclinedSubject.next(currentUserFriendInvitationsIncomingDeclined);
        }
    }

    addFriend(friendUserId: number, message?: string): Observable<FriendInvitationHttpResponse> {
        let requestBody = {};
        if (message !== undefined && message !== '') {
            requestBody = {'message': message};
        }
        return this.friendsApi.apiUsersUserIdFriendsFriendUserIdPost(
            this._currentUser.id,
            friendUserId,
            requestBody
        ).pipe(map(
            (friendInvitationHttpResponse: FriendInvitationHttpResponse) => {
                if (friendInvitationHttpResponse.sender_user_id === this._currentUser.id) { // outgoing friend invitation
                    this.addOutgoingFriendInvitation(friendInvitationHttpResponse);
                } else if (friendInvitationHttpResponse.receiver_user_id === this._currentUser.id) { // incoming friend invitation
                    this.removeIncomingFriendInvitation(friendInvitationHttpResponse);
                    this.removeIncomingDeclinedFriendInvitation(friendInvitationHttpResponse);
                    this.getUserFriends(true);
                }
                return friendInvitationHttpResponse;
            }
        ));
    }

    addFriendToFriendList(friendHttpResponse) {
        if (!this.userFriendsRequestRunning) {
            const currentUserFriends = this.userFriendsSubject.getValue();
            if (currentUserFriends) {
                const friendExistsInList = currentUserFriends.results.find(
                    (friendInList: FriendHttpResponse) => friendInList.friend_user_id === friendHttpResponse.friend_user_id);
                if (!friendExistsInList) {
                    currentUserFriends.results.push(friendHttpResponse);
                    this.userFriendsSubject.next(currentUserFriends);
                }
            }
        }
    }

    removeFriend(friendUserId: number): Observable<any> {
        let apiMethodName = 'apiUsersUserIdFriendsFriendUserIdDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteUsersUserIdFriendsFriendUserIdPost';
        }

        return this.friendsApi[apiMethodName](
            this._currentUser.id,
            friendUserId
        ).pipe(map(
            (apiResponse) => {
                this.removeFriendFromFriendList(friendUserId);
                return apiResponse;
            }
        ));
    }

    removeFriendFromFriendList(friendUserId: number) {
        if (!this.userFriendsRequestRunning) {
            const currentUserFriends = this.userFriendsSubject.getValue();
            if (currentUserFriends) {
                const friendExistsInList = currentUserFriends.results.find(
                    (friendInList: FriendHttpResponse) => friendInList.friend_user_id === friendUserId);
                if (friendExistsInList) {
                    currentUserFriends.results =
                        currentUserFriends.results.filter(
                            (friendHttpResponseInList: FriendHttpResponse) =>
                                friendHttpResponseInList.friend_user_id !== friendUserId
                        );
                    this.userFriendsSubject.next(currentUserFriends);
                    this.getUserFriendGroups(true);
                }
            }
        }
    }

    getJoinedGameListOfFriend(userId: number): Observable<GameListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGet(
            this.tenantService.tenantData.id,
            1000,
            1,
            undefined,
            '1,2', // published, runable
            undefined,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            userId,
            this._authToken,
            undefined,
            undefined,
            undefined,
            undefined,
            true
        );
    }

    public userIsFriend(userId: number): FriendHttpResponse {
        if (!this.userFriends) {
            return null;
        }
        return this.userFriends.results.find(
            (userFriend: FriendHttpResponse) => userFriend.friend_user_id === userId
        );
    }

    public getFriendshipState(userId: number): FriendshipState {

        let friendShipState = {state: FriendshipStateEnum.NOT_A_FRIEND, friendInvitation: null};

        const userIsFriend = this.userIsFriend(userId);
        if (userIsFriend) {
            friendShipState.state = FriendshipStateEnum.FRIEND;
        } else {

            let outgoingFriendInvitation = this.getOutgoingFriendInvitationForUser(userId);
            let incomingFriendInvitation = this.getIncomingFriendInvitationFromUser(userId);
            let incomingDeclinedFriendInvitation = this.getIncomingDeclinedFriendInvitationFromUser(userId);

            if (outgoingFriendInvitation) {
                friendShipState.state = FriendshipStateEnum.OUTGOING_FRIEND_INVITATION;
                friendShipState.friendInvitation = outgoingFriendInvitation;
            } else if (incomingFriendInvitation) {
                friendShipState.state = FriendshipStateEnum.INCOMING_PENDING_FRIEND_INVITATION;
                friendShipState.friendInvitation = incomingFriendInvitation;
            } else if (incomingDeclinedFriendInvitation) {
                friendShipState.state = FriendshipStateEnum.INCOMING_DECLINED_FRIEND_INVITATION;
                friendShipState.friendInvitation = incomingDeclinedFriendInvitation;
            }
        }
        return friendShipState;
    }

    public getOutgoingFriendInvitationForUser(userId: number): FriendInvitationHttpResponse | undefined {
        const currentUserFriendInvitationsOutgoing = this.userFriendInvitationsOutgoingSubject.getValue();
        let outgoingFriendInvitation = null;
        if (currentUserFriendInvitationsOutgoing) {
            outgoingFriendInvitation = currentUserFriendInvitationsOutgoing.results.find(
                (outgoingFriendInvitation: FriendInvitationHttpResponse) =>
                    outgoingFriendInvitation.receiver_user_id === userId
            );
        }

        return outgoingFriendInvitation;
    }

    public getIncomingFriendInvitationFromUser(userId: number): FriendInvitationHttpResponse | undefined {
        const currentUserFriendInvitationsIncoming = this.userFriendInvitationsIncomingSubject.getValue();
        let incomingFriendInvitation = null;
        if (currentUserFriendInvitationsIncoming) {
            incomingFriendInvitation = currentUserFriendInvitationsIncoming.results.find(
                (incomingFriendInvitation: FriendInvitationHttpResponse) =>
                    incomingFriendInvitation.sender_user_id === userId
            );
        }
        return incomingFriendInvitation;
    }

    public getIncomingDeclinedFriendInvitationFromUser(userId: number): FriendInvitationHttpResponse | undefined {
        const currentUserFriendInvitationsIncomingDeclined = this.userFriendInvitationsIncomingDeclinedSubject.getValue();
        let incomingDeclinedFriendInvitation = null;
        if (currentUserFriendInvitationsIncomingDeclined) {
            incomingDeclinedFriendInvitation = currentUserFriendInvitationsIncomingDeclined.results.find(
                (incomingDeclinedFriendInvitation: FriendInvitationHttpResponse) =>
                    incomingDeclinedFriendInvitation.sender_user_id === userId
            );
        }

        return incomingDeclinedFriendInvitation;
    }


    public inviteFriends(gameUniqueId: string, userId: number, friendUserIds: number[]): Observable<{}> {
        const gameFriendInvitationRequest: GameFriendInvitationRequest = {
            friend_user_ids: friendUserIds
        };
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdInvitefriendsPut(
            this.tenantService.tenantData.id,
            gameUniqueId,
            userId,
            gameFriendInvitationRequest
        );
    }

    public inviteNewUser(userId: number, emailAdresses: Array<string>): Observable<{}> {
        const tenantNewUserInvitationRequest: TenantNewUserInvitationRequest = {
            sender_user_id: userId,
            email_addresses: emailAdresses
        };
        return this.tenantsApi.apiTenantsTenantIdInvitenewuserPut(
            this.tenantService.tenantData.id,
            tenantNewUserInvitationRequest
        );
    }

    subscribeForFriendBroadcastEvents() {

        // unsubscribe from all broadcast events
        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];

        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendAdded')
                .subscribe((broadcastEventData: FriendAddedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendAddedEvent');
                        this.addFriendToFriendList(broadcastEventData.friendHttpResponse);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendRemoved')
                .subscribe((broadcastEventData: FriendRemovedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendRemovedEvent');
                        this.removeFriendFromFriendList(broadcastEventData.friendHttpResponse.friend_user_id);
                    }
                })
        );

        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendInvitationSent')
                .subscribe((broadcastEventData: FriendInvitationSentEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendInvitationSentEvent');
                        this.addOutgoingFriendInvitation(broadcastEventData.friendInvitationHttpResponse);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendInvitationConfirmed')
                .subscribe((broadcastEventData: FriendInvitationConfirmedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendInvitationConfirmedEvent');
                        this.deleteFriendInvitationInAllLists(broadcastEventData.friendInvitationHttpResponse);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendInvitationDeclined')
                .subscribe((broadcastEventData: FriendInvitationDeclinedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendInvitationDeclinedEvent');
                        if (broadcastEventData.friendInvitationHttpResponse.receiver_user_id === this._currentUser.id) {
                            this.removeIncomingFriendInvitation(broadcastEventData.friendInvitationHttpResponse);
                            this.addIncomingDeclinedFriendInvitation(broadcastEventData.friendInvitationHttpResponse);
                        } else if (broadcastEventData.friendInvitationHttpResponse.sender_user_id === this._currentUser.id) {
                            this.removeOutgoingFriendInvitation(broadcastEventData.friendInvitationHttpResponse);
                        }
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendInvitationDeleted')
                .subscribe((broadcastEventData: FriendInvitationDeletedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendInvitationDeletedEvent');
                        this.deleteFriendInvitationInAllLists(broadcastEventData.friendInvitationHttpResponse);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendInvitationReceived')
                .subscribe((broadcastEventData: FriendInvitationReceivedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendInvitationReceivedEvent');
                        this.addIncomingFriendInvitation(broadcastEventData.friendInvitationHttpResponse);
                    }
                })
        );

        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendGroupCreated')
                .subscribe((broadcastEventData: FriendGroupCreatedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendGroupCreatedEvent');
                        this.addFriendGroupToList(broadcastEventData.friendGroupHttpResponse);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendGroupUpdated')
                .subscribe((broadcastEventData: FriendGroupUpdatedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendGroupUpdatedEvent');
                        this.updateFriendGroupInList(broadcastEventData.friendGroupHttpResponse);
                        this.doFriendGroupUpdateSubject.next(broadcastEventData.friendGroupHttpResponse);
                    }
                })
        );
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Friends\\FriendGroupDeleted')
                .subscribe((broadcastEventData: FriendGroupDeletedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FriendGroupDeletedEvent');
                        this.deleteFriendGroupFromList(broadcastEventData.friendGroupHttpResponse.id);
                    }
                })
        );
    }

    resetAllData() {
        this._currentUser = null;
        this._authToken = null;
        this.userFriendsSubject.next(null);
        this.userFriendGroupsSubject.next(null);
        this.userFriendInvitationsIncomingSubject.next(null);
        this.userFriendInvitationsIncomingCountSubject.next(0);
        this.userFriendInvitationsIncomingDeclinedSubject.next(null);
        this.userFriendInvitationsOutgoingSubject.next(null);
        this.doFriendGroupUpdateSubject.next(null);

        this.apiGetRequestSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.apiGetRequestSubscriptions = [];

        // unsubscribe from all broadcast events
        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];
    }

}
