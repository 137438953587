import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {animate, style, transition, trigger, sequence} from '@angular/animations';

export const wobbleAnimation = sequence([
    animate('200ms ease',
        style({
            transform: 'scale(1.30)'
        })
    ),
    animate('200ms ease',
        style({
            transform: 'scale(0.70)'
        })
    ),
    animate('200ms ease',
        style({
            transform: 'scale(1.15)'
        })
    ),
    animate('200ms ease',
        style({
            transform: 'scale(0.85)'
        })
    ),
    animate('200ms ease',
        style({
            transform: 'scale(1)'
        })
    ),
]);

@Component({
    selector: 'betsnaps-notification-bubble',
    templateUrl: './notification-bubble.component.html',
    styles: [],
    animations: [
        trigger('valueChangeAnimation', [
            transition('stateOne <=> stateTwo', [
                wobbleAnimation
            ]),
            transition('void => *', [
                wobbleAnimation
            ])
        ]),
    ]
})

export class NotificationBubbleComponent implements OnChanges {

    public valueChangeState: string = 'stateOne';

    @Input() value: number = null;
    @Input() showValue: boolean = true;
    @Input() additionalClass: string = 'calltoaction floating';

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && !changes.value.firstChange) {
            this.valueChangeState = (this.valueChangeState === 'stateOne' ? 'stateTwo' : 'stateOne');
        }
    }
}
