/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTenantsTenantSeoHttpResponse } from './appHttpResponsesTenantsTenantSeoHttpResponse';
import { AppHttpResponsesTenantsTenantB2bHttpResponse } from './appHttpResponsesTenantsTenantB2bHttpResponse';
import { AppHttpResponsesTenantsTenantImageHttpResponse } from './appHttpResponsesTenantsTenantImageHttpResponse';
import { AppHttpResponsesTenantsTenantFinancialHttpResponse } from './appHttpResponsesTenantsTenantFinancialHttpResponse';
import { AppHttpResponsesFinancialsCurrenciesPlayerCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesPlayerCurrencyHttpResponse';
import { AppHttpResponsesTenantsTenantSocialNetworkHttpResponse } from './appHttpResponsesTenantsTenantSocialNetworkHttpResponse';
import { AppHttpResponsesTenantsTenantInternationalizationHttpResponse } from './appHttpResponsesTenantsTenantInternationalizationHttpResponse';
import { AppHttpResponsesTenantsTenantConfigurationHttpResponse } from './appHttpResponsesTenantsTenantConfigurationHttpResponse';


/**
 * TenantHttpResponse
 */
export interface AppHttpResponsesTenantsTenantHttpResponse { 
    /**
     * Tenant ID
     */
    id: number;
    /**
     * Name
     */
    name: string;
    /**
     * Domain Name
     */
    domain_name: string;
    /**
     * Relative Default Path
     */
    relative_default_path?: string;
    /**
     * CTS Tenant Id
     */
    cts_tenant_id?: number;
    /**
     * is B2B
     */
    is_b2b_tenant: boolean;
    /**
     * is freemium Tenant
     */
    is_freemium_tenant: boolean;
    /**
     * is demo Tenant
     */
    is_demo_tenant: boolean;
    /**
     * is Tenant active
     */
    is_active: boolean;
    configuration?: AppHttpResponsesTenantsTenantConfigurationHttpResponse;
    /**
     * Tenant images
     */
    images?: Array<AppHttpResponsesTenantsTenantImageHttpResponse>;
    internationalization?: AppHttpResponsesTenantsTenantInternationalizationHttpResponse;
    financial?: AppHttpResponsesTenantsTenantFinancialHttpResponse;
    /**
     * Player curencies
     */
    player_currencies?: Array<AppHttpResponsesFinancialsCurrenciesPlayerCurrencyHttpResponse>;
    b2b?: AppHttpResponsesTenantsTenantB2bHttpResponse;
    social_network?: AppHttpResponsesTenantsTenantSocialNetworkHttpResponse;
    seo?: AppHttpResponsesTenantsTenantSeoHttpResponse;
}

