<div [ngClass]="'ui fluid card element-clickable game-card-v3 single-player-game-card ' + (backgroundImage ? 'withBgImage' : '') + ' tile-style-large'"
     [ngStyle]="{
        'background-image': (backgroundImage) ? backgroundImage : 'none'
     }">
    <div class="content top container font-bold">
        <i class="right floated sport-icon icon bsi bsi-sport-999"></i>
        <span class="ui game-state-label black label margin-0">
            {{'GENERAL.LABELS.singleplayer' | translate | uppercase}}
        </span>
    </div>
    <div class="content main">
        <div class="content-main-inner">
            <div class="content-main-inner-text">
                <h2 class="game-name" *ngIf="tenantVendorGame.name">{{tenantVendorGame.name | dbtranslation:'name':tenantVendorGame.translations}}</h2>
                <h2 class="game-name" *ngIf="!tenantVendorGame.name">{{tenantVendorGame.vendor_game.name | dbtranslation:'name':tenantVendorGame.vendor_game.translations}}</h2>
            </div>
            <div class="content-main-inner-button">
                <button type="button"
                        (click)="googleAnalyticsService.trackEvent('singleplayergamecard', 'click', 'start participation'); openSingleplayerJoinModal()"
                        class="ui fluid large button margin-0"
                        [class.loading]="processParticipation"
                        [disabled]="processParticipation"
                        *ngIf="!activeParticipation">
                    {{'GENERAL.VENDORGAMES.start_participation' | translate}}
                </button>
                <button type="button"
                        (click)="googleAnalyticsService.trackEvent('singleplayergamecard', 'click', 'resume participation'); resumeParticipation(activeParticipation)"
                        class="ui fluid large button margin-0"
                        [class.loading]="processParticipation"
                        [disabled]="processParticipation"
                        *ngIf="activeParticipation">
                    {{'GENERAL.VENDORGAMES.resume_participation' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
<betsnaps-singleplayer-join-modal
    #singleplayerJoinModalComponent
    [tenantVendorGame]="tenantVendorGame"
    [profileHeaderImage]="backgroundImageUrl"
    (pointsConfirmed)="startParticipation($event)">
</betsnaps-singleplayer-join-modal>
