/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UserStatisticRequest
 */
export interface AppHttpRequestsStatisticsUserStatisticRequest { 
    /**
     * Date YYYY-MM-DD
     */
    from_date?: string;
    /**
     * Date YYYY-MM-DD
     */
    to_date?: string;
    /**
     * Users with the most (1...games participation, 2...buy-ins, 3...winnings)
     */
    sort_by?: number;
    /**
     * Limit User
     */
    limit_user?: number;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * User ID
     */
    user_id?: number;
    /**
     * B2B User ID
     */
    b2b_user_id?: number;
    /**
     * Username
     */
    username?: string;
    /**
     * Entries per Page
     */
    perPage?: number;
    /**
     * Page Nr
     */
    page?: number;
}

