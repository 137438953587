<div class="ui bottom fixed shadowless main-menu secondary labeled icon mini five center aligned menu computer or higher hidden"
     [class.authenticated]="(isAuthenticated$ | async)" [class.isjoined]="game.is_current_user_joined" *ngIf="game">
    <a routerLink="/arena" class="item"
       (click)="googleAnalyticsService.trackEvent('game - details', 'back', 'arrow icon')">
        <i class="icon bsi bsi-arena"></i>
        <span class="menuitem-label">
            {{'GENERAL.HEADER.MENU.arena' | translate}}
        </span>
    </a>
    <a [routerLink]="['./details']" [routerLinkActive]="['active']" class="item">
        <i class="home icon"></i>
        <span class="menuitem-label">
            {{'BETSNAPDETAIL.MENU.details' | translate}}
        </span>
    </a>
    <a [routerLink]="['./matches']" [routerLinkActive]="['active']" class="item" *ngIf="game.sport_id !== 999">
        <i class="icon bsi bsi-sport-{{game.sport_id}} color-white"></i>
        <span class="menuitem-label">
            {{'BETSNAPDETAIL.MENU.matches' | translate}}
        </span>
    </a>
    <a *ngIf="game.sport_id !== 999 && (game.is_current_user_joined || (betsnapdetailService.widgetBets$ | async))" [routerLink]="['./bets']" [routerLinkActive]="['active']" class="item">
        <i class="timeline icon"></i>
        <span class="menuitem-label">
            {{'BETSNAPDETAIL.MENU.bets' | translate}}
        </span>
    </a>
    <a [routerLink]="['./games']" [routerLinkActive]="['active']" class="item" *ngIf="game.sport_id === 999">
        <i class="icon bsi bsi-sport-{{game.sport_id}} color-white"></i>
        <span class="menuitem-label">
            {{'BETSNAPDETAIL.MENU.vendor-games' | translate}}
        </span>
    </a>
    <a *ngIf="game.sport_id === 999 && (game.is_current_user_joined || (betsnapdetailService.widgetBets$ | async))" [routerLink]="['./plays']" [routerLinkActive]="['active']" class="item">
        <i class="history icon"></i>
        <span class="menuitem-label">
            {{'BETSNAPDETAIL.MENU.vendor-game-participations' | translate}}
        </span>
    </a>
    <a *ngIf="!isRankingAccessible() && game.competition_type > 1"
       [routerLink]="['./players']" [routerLinkActive]="['active']" class="item">
        <i class="people icon"></i>
        <span class="menuitem-label">
            {{'BETSNAPDETAIL.MENU.players' | translate}}
        </span>
    </a>
    <a *ngIf="isRankingAccessible()"
       [routerLink]="['./ranking']" [routerLinkActive]="['active']" class="item">
        <i class="fa fa-trophy icon"></i>
        <span class="menuitem-label">
            {{'BETSNAPDETAIL.MENU.ranking' | translate}}
        </span>
    </a>
</div>

<div class="betsnapdetail-header-wrap"
     [class.game-user-bar-mobile-fixed]="gameUserBarMobileFixed"
     [class.notJoined]="!game.is_current_user_joined"
     [class.gameCancelled]="game.game_state === 99"
     #betsnapdetailHeaderWrap>
    <div class="big-header"
         [ngStyle]="{'background-image': backgroundImage}">
        <div class="ui centered relaxed bottom aligned grid container margin-t-0 margin-b-0 betsnapdetail-header-inner">
            <div class="row padding-0">
                <div class="four wide right aligned column tablet or lower hidden"></div>
                <div class="sixteen wide mobile eight wide computer left aligned column">
                    <div class="betsnapdetail-header-title-wrap"
                        [class.topbuttons-visible]="currentUser && ((joinedGameList && joinedGameList.results && joinedGameList.results.length > 0) || (game.current_user_friends && game.current_user_friends.length > 0))">
                        <div class="top-buttons right aligned computer or higher hidden">
                            <button *ngIf="currentUser && joinedGameList && joinedGameList.results && joinedGameList.results.length > 0"
                                    type="button"
                                    class="ui circular icon shadowed tiny calltoaction2 left floated button"
                                    (click)="openSwitchModal()">
                                <i class="swap horiz big icon"></i>
                            </button>
                            <button type="button" *ngIf="game.current_user_friends && game.current_user_friends.length > 0"
                                    class="ui circular icon shadowed tiny button padding-0 margin-0"
                                    fuiPopup [popupTemplate]="popupUserFriendsJoinedTemplate"
                                    popupPlacement="bottom right"
                                    popupTrigger="outsideClick"
                                    #popup="fuiPopup"
                                    (click)="googleAnalyticsService.trackEvent('game - details', 'show', 'friends joined')">
                                <img
                                    *ngIf="game.current_user_friends[game.current_user_friends.length - 1].friend_user.media_url_small"
                                    class="ui middle aligned right mini circular bordered primary image"
                                    [ngStyle]="{ 'width': '42px', 'height': '42px' }"
                                    [src]="game.current_user_friends[game.current_user_friends.length - 1].friend_user.media_url_small"/>
                                <span class="floating ui small circular shadowed calltoaction2 label">
                                    {{game.current_user_friends.length}}
                                </span>
                            </button>
                        </div>
                        <div class="betsnapdetail-header-title-wrap-inner" [class.showshare]="game.game_state < 3">
                            <p class="margin-0" *ngIf="game.game_state !== 99">
                                <span *ngIf="(secondsToStart > 3600 || secondsToStart < 0) && game.game_state < 3">
                                    {{game.start_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }} - {{game.end_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }}
                                </span>
                                <span class="ui black label countdown-label margin-0"
                                      *ngIf="secondsToStart <= 3600 && secondsToStart > 0 && game.game_state < 3">
                                    <betsnaps-countdown #gameStartCountdown
                                                        [timeInSeconds]="secondsToStart"
                                                        [autoStart]="true"
                                                        [secondsForHighlight]="secondsToStart"
                                                        [hideAfterFinish]="true"
                                                        (onCountdownFinished)="game.game_state = 3"></betsnaps-countdown>
                                    <span *ngIf="gameStartCountdown?.hasFinished()">
                                        {{'GENERAL.GAMES.STATI.running_until' | translate}} {{game.end_date | amLocal | amCalendar:
                                        {
                                            sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                            nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                            nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                            lastDay: tenantService.tenantData.internationalization.date_time_format,
                                            lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                            sameElse: tenantService.tenantData.internationalization.date_time_format
                                        }
                                        }}
                                    </span>
                                </span>
                                <span *ngIf="game.game_state === 3">
                                    {{'GENERAL.GAMES.STATI.running_until' | translate}} {{game.end_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }}
                                </span>
                                <span *ngIf="game.game_state === 4 || game.game_state === 5 || game.game_state === 6">
                                    {{'GENERAL.GAMES.STATI.finished' | translate | uppercase}}
                                    &nbsp;({{game.start_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }} - {{game.real_end_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }})
                                </span>
                                <span *ngIf="game.game_state === 7">
                                    {{'GENERAL.GAMES.STATI.closed' | translate | uppercase}}
                                    &nbsp;({{game.start_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }} - {{game.real_end_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }})
                                </span>
                            </p>
                            <p class="margin-0" *ngIf="game.game_state === 99">
                                {{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}
                            </p>
                            <h1 class="margin-0 text-huge">
                                <betsnaps-game-name [game]="game"></betsnaps-game-name>
                            </h1>
                            <button type="button"
                                    class="ui icon circular button share-button mobile only"
                                    (click)="openGameShareModal()"
                                    *ngIf="game.game_state < 3 && betsnapdetailService.gameShareData && betsnapdetailService.sentGameInvitations">
                                <i class="share alternat big icon"></i>
                            </button>
                        </div>
                    </div>

                <div class="betsnapdetail-header-menu-wrap tablet or lower hidden">
                    <div class="ui compact icon labeled inverted primary shadowed borderless mini menu"
                         [class.isjoined]="game.is_current_user_joined">
                        <a [routerLink]="['./details']" [routerLinkActive]="['active']" class="item">
                            <i class="home icon"></i>
                            {{'BETSNAPDETAIL.MENU.details' | translate}}
                        </a>
                        <a [routerLink]="['./matches']" [routerLinkActive]="['active']" class="item" *ngIf="game.sport_id !== 999">
                            <i class="icon bsi bsi-sport-{{game.sport_id}} color-white"></i>
                            {{'BETSNAPDETAIL.MENU.matches' | translate}}
                        </a>
                        <a *ngIf="game.sport_id !== 999 && (game.is_current_user_joined || (betsnapdetailService.widgetBets$ | async))" [routerLink]="['./bets']" [routerLinkActive]="['active']"
                           class="item">
                            <i class="timeline icon"></i>
                            {{'BETSNAPDETAIL.MENU.bets' | translate}}
                        </a>
                        <a [routerLink]="['./games']" [routerLinkActive]="['active']" class="item" *ngIf="game.sport_id === 999">
                            <i class="icon bsi bsi-sport-{{game.sport_id}} color-white"></i>
                            <span class="menuitem-label">
                                {{'BETSNAPDETAIL.MENU.vendor-games' | translate}}
                            </span>
                        </a>
                        <a *ngIf="game.sport_id === 999 && (game.is_current_user_joined || (betsnapdetailService.widgetBets$ | async))" [routerLink]="['./plays']" [routerLinkActive]="['active']" class="item">
                            <i class="history icon"></i>
                            <span class="menuitem-label">
                                {{'BETSNAPDETAIL.MENU.vendor-game-participations' | translate}}
                            </span>
                        </a>
                        <a *ngIf="!isRankingAccessible() && game.competition_type > 1"
                           [routerLink]="['./players']" [routerLinkActive]="['active']" class="item">
                            <i class="people icon"></i>
                            {{'BETSNAPDETAIL.MENU.players' | translate}}
                        </a>
                        <a *ngIf="isRankingAccessible()"
                           [routerLink]="['./ranking']" [routerLinkActive]="['active']" class="item">
                            <i class="fa fa-trophy icon"></i>
                            {{'BETSNAPDETAIL.MENU.ranking' | translate}}
                        </a>
                    </div>

                        <button *ngIf="currentUser && joinedGameList && joinedGameList.results &&joinedGameList.results.length > 0"
                                type="button"
                                class="ui circular icon shadowed big calltoaction2 right floated button"
                                (click)="openSwitchModal()">
                            <i class="swap horiz big icon"></i>
                        </button>

                        <button type="button"
                                *ngIf="game.current_user_friends && game.current_user_friends.length > 0"
                                class="ui circular icon shadowed tiny button padding-0 right floated"
                                fuiPopup
                                [popupTemplate]="popupUserFriendsJoinedTemplate"
                                popupPlacement="bottom right"
                                popupTrigger="outsideClick"
                                #popup="fuiPopup"
                                (click)="googleAnalyticsService.trackEvent('game - details', 'show', 'friends joined')">
                            <img
                                *ngIf="game.current_user_friends[game.current_user_friends.length - 1].friend_user.media_url_small"
                                class="ui middle aligned right tiny circular bordered primary image"
                                [src]="game.current_user_friends[game.current_user_friends.length - 1].friend_user.media_url_small"/>
                            <span class="floating ui small circular shadowed calltoaction2 label">
                                {{game.current_user_friends.length}}
                            </span>
                        </button>
                    </div>

                    <ng-template let-context let-modal="modal" #switchModalTemplate
                                 *ngIf="currentUser && joinedGameList && joinedGameList.results && joinedGameList.results.length > 0">
                        <div class="big header">
                            <i class="swap horiz big icon"></i>
                            {{'BETSNAPDETAIL.snapswitcher-modal_heading' | translate | uppercase}}
                        </div>
                        <div class="scrolling content user-modal">
                            <div class="ui horizontal divider margin-t-0">
                                {{joinedGameList.results.length}} {{joinedGameList.total | i18nPlural:translations.labelTranslations.joinedsnapplural}}
                            </div>
                            <betsnaps-game-card-loader *ngFor="let joinedGame of joinedGameList.results"
                                                [game]="joinedGame"
                                                [currentUser]="currentUser"
                                                [isSwitcher]="true"
                                                class="game-card-wrap"
                                                (onSnapSwitch)="modal.approve();"></betsnaps-game-card-loader>
                        </div>
                    </ng-template>

                    <ng-template let-popup #popupUserFriendsJoinedTemplate>
                        <div class="content center aligned friends-popup">
                            <h5>{{game.current_user_friends.length}} {{game.current_user_friends.length | i18nPlural:translations.labelTranslations.joinedfriendplural}}</h5>
                            <div class="ui three column centered tight grid">
                                <div *ngFor="let gameUserFriend of game.current_user_friends" class="column">
                                    <div class="ui center aligned column">
                                        <betsnaps-user-avatar-loader [user]="gameUserFriend.friend_user"
                                                              [imageSize]="'tiny'"
                                                              [closePopup]="popup"></betsnaps-user-avatar-loader>
                                        <span class="truncate">{{gameUserFriend.friend_user.username}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                </div>
                <div class="four wide center aligned column tablet or lower hidden">
                </div>
            </div>
        </div>
    </div>
    <div class="small-mobile-header"
         [class.showshare]="game.game_state < 3">
        <p class="margin-0" *ngIf="game.game_state != 99">
            <span *ngIf="(secondsToStart > 3600 || secondsToStart < 0) && game.game_state < 3">
                {{game.start_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }} - {{game.end_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }}
            </span>
            <span *ngIf="secondsToStart <= 3600 && secondsToStart > 0 && game.game_state < 3">
                <betsnaps-countdown #gameStartCountdown
                                    [timeInSeconds]="secondsToStart"
                                    [autoStart]="true"
                                    [secondsForHighlight]="secondsToStart"
                                    [hideAfterFinish]="true"
                                    (onCountdownFinished)="game.game_state = 3"></betsnaps-countdown>
                <span *ngIf="gameStartCountdown?.hasFinished()">
                    {{'GENERAL.GAMES.STATI.running_until' | translate}} {{game.end_date | amLocal | amCalendar:
                    {
                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                        sameElse: tenantService.tenantData.internationalization.date_time_format
                    }
                    }}
                </span>
            </span>
            <span *ngIf="game.game_state === 3">
                {{'GENERAL.GAMES.STATI.running_until' | translate}} {{game.end_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }}
            </span>
            <span *ngIf="game.game_state == 4 || game.game_state == 5 || game.game_state == 6">
                {{'GENERAL.GAMES.STATI.finished' | translate | uppercase}}
                &nbsp;({{game.start_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }} - {{game.real_end_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }})
            </span>
            <span *ngIf="game.game_state == 7">
                {{'GENERAL.GAMES.STATI.closed' | translate | uppercase}}
                &nbsp;({{game.start_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }} - {{game.real_end_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }})
            </span>
        </p>
        <p class="margin-0" *ngIf="game.game_state == 99">
            {{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}
        </p>
        <h1 class="margin-0"><betsnaps-game-name [game]="game"></betsnaps-game-name></h1>
        <button type="button"
                class="ui icon circular button share-button mobile only"
                (click)="openGameShareModal()"
                *ngIf="game.game_state < 3">
            <i class="share alternat big icon"></i>
        </button>
    </div>
</div>
<div class="game-user-bar mobile only"
     *ngIf="game.is_current_user_joined && game?.game_user && game.game_state !== 99"
     [class.game-user-bar-mobile-fixed]="gameUserBarMobileFixed"
     #gameUserBarMobile>
    <div class="ui middle aligned centered grid container margin-t-0 margin-b-0">
        <div class="row padding-0">
            <div class="eight wide column left aligned"
                 *ngIf="game.game_state > 2 && (game.competition_type === 1 || game.competition_type === 3) && game.game_user.is_participation_valid !== false">
                <button class="ui big label display-inline-block vertical-align-middle margin-t-0 margin-b-0 margin-l-0"
                        title="{{gameUserPointsChanged ? 'BETSNAPDETAIL.GAMEUSER.POINTS.refresh' : '' | translate}}"
                        *ngIf="(game.competition_type === 1 && game.game_user.rank) || (game.competition_type === 3 && game.game_user.game_user_group?.rank)"
                        [class.loading]="processData"
                        [ngClass]="{
                        'positive': (!gameUserPointsChanged) && ((game.competition_type === 1 && game.game_user.rank === 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank === 1)),
                        'negative': (!gameUserPointsChanged) && ((game.competition_type === 1 && game.game_user.rank > 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank > 1)),
                        'button calltoaction padding-t-0 padding-b-0': (gameUserPointsChanged)
                       }"
                       (click)="refreshGameData();
                                googleAnalyticsService.trackEvent('game - details - header', 'click', 'refresh user game points');">
                    <span *ngIf="gameUserPointsChanged"><i class="refresh icon large margin-0"></i><br/></span>
                    <span *ngIf="(game.competition_type === 1 && game.game_user.rank > 0)">{{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span></span>
                    <span *ngIf="(game.competition_type === 3 && game.game_user.game_user_group?.rank > 0)">{{game.game_user.game_user_group.rank}}<span class="text-mini">{{game.game_user.game_user_group.rank | ordinalNumber:true}}</span></span>
                    <span *ngIf="(game.competition_type === 1 && game.game_user.rank < 1) || (game.competition_type === 3 && game.game_user.game_user_group?.rank < 1)">-</span>
                </button>
                <span class="display-inline-block vertical-align-middle text-tiny"
                             *ngIf="betsnapdetailService.h2hCompetitor">
                    &nbsp;{{'GENERAL.GAMES.h2h_vs' | translate}}&nbsp;
                </span><span class="display-inline-block vertical-align-middle" *ngIf="betsnapdetailService.h2hCompetitor">
                    <betsnaps-user-avatar-loader *ngIf="betsnapdetailService.h2hCompetitor"
                                          [user]="betsnapdetailService.h2hCompetitor.user"
                                          [imageSize]="'mini'"></betsnaps-user-avatar-loader>
                </span><span class="display-inline-block vertical-align-middle font-very-bold text-tiny"
                             *ngIf="betsnapdetailService.h2hCompetitor && betsnapdetailService.h2hCompetitor.is_participation_valid !== false"
                             [ngClass]="{
                        'color-positive': (game.competition_type === 1 && game.game_user.rank === 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank === 1),
                        'color-negative': (game.competition_type === 1 && game.game_user.rank > 1) || (game.competition_type === 3 && game.game_user.game_user_group.rank > 1)
                    }">
                    &nbsp;{{((game.game_user.total_score - betsnapdetailService.h2hCompetitor.total_score) > 0) ? '+' : ''}}{{(game.game_user.total_score - betsnapdetailService.h2hCompetitor.total_score) | number:'1.2-2'}}
                </span>
            </div>
            <div class="four wide column center aligned" *ngIf="game.game_state > 2 && (game.competition_type === 2 || game.game_user.is_participation_valid === false)">
                <button class="ui big label margin-0"
                        title="{{gameUserPointsChanged ? 'BETSNAPDETAIL.GAMEUSER.POINTS.refresh' : '' | translate}}"
                        *ngIf="game.game_user.rank"
                        [class.text-large]="game.game_user.rank > 9999"
                        [class.loading]="processData"
                        [ngClass]="{'button calltoaction padding-t-0 padding-b-0': (gameUserPointsChanged)}"
                        (click)="refreshGameData();
                                 googleAnalyticsService.trackEvent('game - details - header', 'click', 'refresh user game points');">
                    <span *ngIf="gameUserPointsChanged"><i class="refresh icon big"></i><br/></span>
                    <span *ngIf="game.game_user.rank > 0">{{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span></span>
                    <span *ngIf="game.game_user.rank < 1">-</span>
                </button>
            </div>
            <div class="four wide column center aligned" *ngIf="game.game_user.is_participation_valid === false">
                <button type="button"
                        class="ui circular icon basic inverted mini button margin-0"
                        fuiPopup
                        [popupTemplate]="popupNoValidBetInfo"
                        popupTrigger="outsideClick"
                        popupPlacement="bottom">
                    <i class="fa fa-info small icon"></i>
                </button>
                <ng-template let-popup #popupNoValidBetInfo>
                    <div class="content no-valid-bet-info-popup">
                        {{(game.game_state < 4) ? ('GENERAL.GAMES.no_valid_bet_placed_yet' | translate) : ('GENERAL.GAMES.no_valid_bet' | translate)}}
                    </div>
                </ng-template>
            </div>
            <div class="four wide column center aligned"
                 *ngIf="game.game_state > 2 && game.competition_type === 2 && game.game_user.rank === 1 && game.game_user.points_in_lead !== null && game.game_user.is_participation_valid !== false">
                <span class="game-user-pointvalue">
                    {{game.game_user.points_in_lead | number:'1.2-2'}}
                </span>
                <span class="game-user-label truncate">
                    {{'GENERAL.GAMES.POINTS.points_in_lead' | translate}}
                </span>
            </div>
            <div class="four wide column center aligned"
                 *ngIf="game.game_state > 2 && game.competition_type === 2 && (game.game_user.rank > 1 || (game.game_user.rank === 1 && game.game_user.points_in_lead === null)) && game.game_user.is_participation_valid !== false && game.points_engine !== gamePointsEngineEnum.PREDICTION">
                <span class="game-user-pointvalue">
                    {{game.game_user.points_to_first | number:'1.2-2'}}
                </span>
                <span class="game-user-label truncate">
                    {{'GENERAL.GAMES.POINTS.points_to_first' | translate}}
                </span>
            </div>
            <div class="four wide column center aligned"
                 *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">
                <span class="game-user-pointvalue">
                    {{game.game_user.total_score | number:'1.2-2'}}
                </span>
                <span class="game-user-label truncate">
                    {{'GENERAL.GAMES.POINTS.total_points' | translate}}
                </span>
            </div>
            <div class="four wide column center aligned"
                 *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">
                <span class="game-user-pointvalue">
                    {{game.game_user.user_game_points | number:'1.2-2'}}
                </span>
                <span class="game-user-label truncate">
                    {{'GENERAL.GAMES.POINTS.free_points' | translate}}
                </span>
            </div>
        </div>
    </div>
</div>

<betsnaps-game-share-modal-default
    #gameShareModalDefaultComponent
    [game]="game"
    [gameShareData]="betsnapdetailService.gameShareData"
    [sentGameInvitations]="betsnapdetailService.sentGameInvitations"
    (onDeny)="betsnapdetailService.getSentGameInvitations(game)"
></betsnaps-game-share-modal-default>
