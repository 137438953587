import {ChangeDetectionStrategy, Component, Inject, OnChanges, SimpleChanges} from '@angular/core';
import {BetsnapdetailService} from '../../../../shared';
import {DOCUMENT} from '@angular/common';
import {ASpecialMarketComponent} from '../a-special-market-component';

@Component({
    selector: 'betsnaps-special-market-v3',
    templateUrl: './special-market-v3.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialMarketV3Component extends ASpecialMarketComponent implements OnChanges {

    constructor(@Inject(DOCUMENT) private document: any,
                public betsnapdetailService: BetsnapdetailService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.market) {
            this.createMarketOutcomesArray();
        }

        this.betPlacementAllowed = this.betsnapdetailService.isBetPlacementAllowed(this.match);
    }

}
