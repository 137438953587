import {BehaviorSubject, Observable, Subscription, throwError} from 'rxjs';
import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';

import {
    Configuration,
    AuthenticationApi, UsersApi, GeneralApi,
    AppAuthPassportSocialGrant as SocialGrant,
    AppAuthPassportB2bGrant as B2bGrant,
    LeagueOAuth2ServerGrantPasswordGrant as PasswordGrant,
    LeagueOAuth2ServerResponseTypesBearerTokenResponse as BearerTokenResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpRequestsUsersUserUpdateRequest as UserUpdateRequest,
    AppHttpResponsesGeneralLanguageHttpResponse as LanguageHttpResponse,
    AppHttpRequestsUsersResetPasswordInitializePasswordEmailResetRequest as InitializePasswordEmailResetRequest,
    AppHttpRequestsUsersResetPasswordPasswordEmailResetRequest as PasswordEmailResetRequest,
    AppHttpRequestsUsersResetPasswordInitializePasswordPhoneResetRequest as InitializePasswordPhoneResetRequest,
    AppHttpRequestsUsersResetPasswordPasswordPhoneResetRequest as PasswordPhoneResetRequest,
    AppHttpRequestsUsersFingerprintWriteRequest as FingerprintWriteRequest,
    AppAuthPassportLoginTokenGrant as LoginTokenGrant
} from '../../api';
import {
    AppEventsUsersPlayerUpdated as PlayerUpdatedEvent,
    AppEventsUsersUserSettingUpdated as UserSettingUpdatedEvent,
    AppEventsUsersUserWalletChanged as UserWalletChangedEvent,
    AppEventsMarketingPartnerUserPermissionsRequest as MarketingPartnerUserPermissionsRequestEvent
} from '../interfaces';
import {TranslateService} from '@ngx-translate/core';
import {BroadcastingService} from './broadcasting.service';
import {TenantService} from './tenant.service';
import {FriendsService} from './friends.service';
import {environment} from '../../../environments/environment';
import {FuiLocalizationService} from '@aligorji/ngx-fomantic-ui';
import {MyNotificationsService} from './my-notifications.service';
import {HintService} from './hint.service';
import {DebugService} from './debug.service';
import {ErrorService} from './error.service';
import * as Fingerprint2 from 'fingerprintjs2';
import {catchError, map, mergeMap, shareReplay, take} from 'rxjs/operators';
import {WindowRef} from './window.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ConnectionEvents} from 'ngx-laravel-echo-jn';
import {MobiledetectService} from './mobiledetect.service';
import {SUI_LANG_EL, SUI_LANG_ENGB} from '../const';
import {DOCUMENT} from '@angular/common';
import {PlayerService} from './player.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private TOKEN_NAME = 'authToken';
    public HEADER_TOKEN_PREFIX = 'Bearer ';

    public authToken: string;
    private jwtHelper = new JwtHelperService();

    private currentUserSubject = new BehaviorSubject<PlayerHttpResponse>(null);
    public currentUser$ = this.currentUserSubject.asObservable();
    private currentUserRequest: Observable<PlayerHttpResponse>;

    private broadcastEventSubscriptions: Subscription[] = [];

    private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.validToken());
    public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

    private userLoginSuccessSubject = new BehaviorSubject<boolean>(false);
    public userLoginSuccess$ = this.userLoginSuccessSubject.asObservable();

    private _currentLang: LanguageHttpResponse;

    private tenantLang: LanguageHttpResponse;
    private browserLang: LanguageHttpResponse;

    private fallBackLanguage: LanguageHttpResponse = {
        id: 1,
        name: 'English',
        name_local: 'English',
        iso_code2: 'en',
        iso_code3: 'eng'
    };

    private nativeWindow;
    private renderer: Renderer2;

    private reconnectDataReloadAfterSeconds = 60;
    private lastDataReloadAfterReconnect = null;

    constructor(@Inject(DOCUMENT) private document: any,
                private apiConfiguration: Configuration,
                private router: Router,
                private authenticationApi: AuthenticationApi,
                private usersApi: UsersApi,
                private generalApi: GeneralApi,
                private tenantService: TenantService,
                private friendsService: FriendsService,
                private broadcastingService: BroadcastingService,
                public myNotificationsService: MyNotificationsService,
                public hintService: HintService,
                private translateService: TranslateService,
                public localizationService: FuiLocalizationService,
                private debugService: DebugService,
                private errorService: ErrorService,
                private playerService: PlayerService,
                private httpClient: HttpClient,
                private windowRef: WindowRef,
                private rendererFactory: RendererFactory2,
                public mobileDetect: MobiledetectService) {

        this.nativeWindow = windowRef.nativeWindow;
        this.renderer = rendererFactory.createRenderer(null, null);

        // if user token exists and no b2b login request
        if (this.validToken() && !this.checkForB2bLoginRequest()) {
            // set auth token from local storage
            this.setAuthTokenFromStorage();
        }

        // reload user data on socket reconnect
        if (this.broadcastingService.echoService) {
            this.broadcastingService.echoService.rawConnectionState.subscribe(
                (connectionEvent: ConnectionEvents) => {
                    if (this.currentUser && connectionEvent.type === 'reconnect') {
                        if (this.lastDataReloadAfterReconnect === null ||
                            moment.duration(moment().utc().diff(this.lastDataReloadAfterReconnect)).asSeconds() > this.reconnectDataReloadAfterSeconds) {
                            this.lastDataReloadAfterReconnect = moment().utc();
                            this.debugService.writeMessageToConsoleLog('reload user data on socket reconnect');
                            this.reloadUserData(true);
                        }
                    }
                }
            );
        }

        this.errorService.notAuthenticatedApiError$.subscribe(
            (notAuthenticatedApiError: boolean) => {
                if (notAuthenticatedApiError && this.currentUser) {
                    this.localLogout();
                }
            }
        );

    }

    get isAuthenticated(): boolean {
        return this.isAuthenticatedSubject.value;
    }

    private checkForB2bLoginRequest(): boolean {
        let isB2bLoginRequest = false;
        if (this.tenantService.isB2b && this.router.url.indexOf('/b2b/login') !== -1) {
            isB2bLoginRequest = true;
        }
        return isB2bLoginRequest;
    }

    // check if token exists in local storage and is not expired
    public validToken(): boolean {
        const token = localStorage.getItem(this.TOKEN_NAME);
        return (token != null && !this.jwtHelper.isTokenExpired(token));
    }

    get currentUser(): PlayerHttpResponse {
        return this.currentUserSubject.value;
    }

    public getCurrentUser(): Observable<PlayerHttpResponse> {
        if (this.currentUserSubject.value) {
            this.currentUserRequest = null;
            return this.currentUser$;
        } else if (this.currentUserRequest) {
            return this.currentUserRequest;
        } else {
            if (this.validToken()) {
                // get userInfo of current user save in request to call only once
                this.currentUserRequest = this.usersApi.apiPlayerinfoGet().pipe(shareReplay(1));
                return this.currentUserRequest;
            } else {
                // return empty current user if authToken is expired
                return this.currentUser$;
            }
        }
    }

    public reloadUserData(reloadBasicData: boolean = false) {
        if (this.currentUser && this.validToken()) {
            this.usersApi.apiPlayerinfoGet()
                .pipe(take(1))
                .subscribe({
                    next: (userInfo: PlayerHttpResponse) => {
                        this.setCurrentUser(userInfo, reloadBasicData);
                    },
                    error: (err: HttpErrorResponse) => {
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    public setCurrentUser(userInfo: PlayerHttpResponse, reloadBasicData: boolean = false) {
        if (userInfo) {
            // set language of user
            if (this.tenantService.tenantLangs) {
                const userLang = this.tenantService.tenantLangs.find(
                    (language: LanguageHttpResponse) => language.id === userInfo.settings.user_language_id);
                if (userLang) {
                    this.setLanguage(userLang);
                } else {
                    this.setDefaultLanguage();
                }
            }

            // on first user set init basic data
            if (!this.currentUser) {

                // preload profile images
                const preLoadMedia = new Image();
                preLoadMedia.src = userInfo.media_url;
                const preLoadMediaSmall = new Image();
                preLoadMediaSmall.src = userInfo.media_url_small;
                const preLoadMediaBig = new Image();
                preLoadMediaBig.src = userInfo.media_url_big;

                // join broadcasting channels
                this.broadcastingService.login(this.authToken, userInfo.id);
                this.broadcastingService.joinChannel('User.' + userInfo.id, 'private');
                this.broadcastingService.joinChannel('UserWallet.' + userInfo.id, 'private');

                this.isAuthenticatedSubject.next(true);
                // set user information for hint service
                this.hintService.initUserData(userInfo, this._currentLang);
                // initialize friends service data
                this.friendsService.initFriendsData(userInfo, this.authToken);
                // set user information for notification service
                this.myNotificationsService.initUserData(userInfo, this._currentLang);

                if (this.nativeWindow.window.BetSnapMobileWrapper) {
                    this.nativeWindow.window.BetSnapMobileWrapper.saveUserTag(['bs_userId', userInfo.id.toString()]);
                }
            } else {

                // update user information
                this.friendsService.authToken = this.authToken;

                this.friendsService.currentUser = userInfo;
                this.myNotificationsService.currentUser = userInfo;
                this.hintService.currentUser = userInfo;

                if (reloadBasicData) {
                    this.friendsService.loadAllFriendsData();
                    this.myNotificationsService.getUnreadNewsCount();
                    this.hintService.loadAllHintsData();
                }
            }

            // Set current user data into observable
            this.currentUserSubject.next(userInfo);

            // Persist Public Player Information for further usage
            this.playerService.storePublicPlayerFromPlayerHttpResponse(userInfo);

            this.subscribeToUserEvents(userInfo);
        }
    }

    private subscribeToUserEvents(currentUser: PlayerHttpResponse) {

        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];

        // Listen for UserUpdated events
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + currentUser.id, 'Users\\UserUpdated')
                .subscribe((broadcastEventData: PlayerUpdatedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('PlayerUpdatedEvent');
                        currentUser = broadcastEventData.playerHttpResponse;
                        this.currentUserSubject.next(currentUser);
                    }
                })
        );
        // Listen for UserSettingUpdated events
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + currentUser.id, 'Users\\UserSettingUpdated')
                .subscribe((broadcastEventData: UserSettingUpdatedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('UserSettingUpdatedEvent');
                        currentUser.settings = broadcastEventData.userSettingHttpResponse;
                        this.currentUserSubject.next(currentUser);
                    }
                })
        );
        // Listen for Marketing Events
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + currentUser.id, 'Marketing\\MarketingPartnerUserPermissionsRequest')
                .subscribe((broadcastEventData: MarketingPartnerUserPermissionsRequestEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('MarketingPartnerUserPermissionsRequestEvent');
                        this.myNotificationsService.handleBroadcastMarketingPartnerResponses(broadcastEventData.marketingPartnerHttpResponses);
                    }
                })
        );
        // Listen for UserWalletChanged events
        this.broadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('UserWallet.' + currentUser.id, 'Users\\UserWalletChanged')
                .subscribe((broadcastEventData: UserWalletChangedEvent) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('UserWalletChangedEvent');
                        currentUser.wallets[0] = broadcastEventData.userWalletHttpResponse;
                        this.currentUserSubject.next(currentUser);
                    }
                })
        );
    }

    public login(login_email: string, login_password: string): Observable<any> {

        const passwordGrant: PasswordGrant = {
            grant_type: 'password',
            client_id: environment.CLIENT_ID,
            client_secret: environment.CLIENT_SECRET,
            username: login_email,
            password: login_password,
            scope: '*'
        };

        return this.authenticationApi.oauthTokenPasswordTenantIdPost(
            this.tenantService.tenantData.id.toString(),
            passwordGrant
        ).pipe(
            mergeMap((bearerTokenResponse: BearerTokenResponse) => {
                const bearerToken = this.HEADER_TOKEN_PREFIX + bearerTokenResponse.access_token;
                this.setAuthToken(bearerToken);
                return this.usersApi.apiPlayerinfoGet()
                    .pipe(
                        map((userInfo: PlayerHttpResponse) => {
                            this.finishUserLogin(bearerTokenResponse, userInfo);
                        }),
                        catchError((error: HttpErrorResponse) => {
                            this.setAuthToken(null);
                            return throwError(error);
                        })
                    );
            })
        );
    }

    public sociallogin(network: string, access_token: string): Observable<any> {
        this.debugService.writeMessageToConsoleLog('sociallogin');

        const socialGrant: SocialGrant = {
            grant_type: 'social',
            client_id: environment.CLIENT_ID,
            client_secret: environment.CLIENT_SECRET,
            network: network,
            access_token: access_token,
            scope: '*'
        };

        return this.authenticationApi.oauthTokenSocialTenantIdPost(
            this.tenantService.tenantData.id.toString(),
            socialGrant
        ).pipe(
            mergeMap((bearerTokenResponse: BearerTokenResponse) => {
                const bearerToken = this.HEADER_TOKEN_PREFIX + bearerTokenResponse.access_token;
                this.setAuthToken(bearerToken);
                return this.usersApi.apiPlayerinfoGet()
                    .pipe(
                        map((userInfo: PlayerHttpResponse) => {
                            this.finishUserLogin(bearerTokenResponse, userInfo);
                        }),
                        catchError((error: HttpErrorResponse) => {
                            this.setAuthToken(null);
                            return throwError(error);
                        })
                    );
            })
        );
    }

    public b2blogin(token: string): Observable<any> {

        const b2bGrant: B2bGrant = {
            grant_type: 'b2b',
            client_id: environment.CLIENT_ID,
            client_secret: environment.CLIENT_SECRET,
            b2btoken: token,
            scope: '*'
        };

        return this.authenticationApi.oauthTokenB2bTenantIdPost(
            this.tenantService.tenantData.id.toString(),
            b2bGrant
        ).pipe(
            mergeMap((bearerTokenResponse: BearerTokenResponse) => {
                const bearerToken = this.HEADER_TOKEN_PREFIX + bearerTokenResponse.access_token;
                this.setAuthToken(bearerToken);
                return this.usersApi.apiPlayerinfoGet()
                    .pipe(
                        map((userInfo: PlayerHttpResponse) => {
                            this.finishUserLogin(bearerTokenResponse, userInfo);
                        }),
                        catchError((error: HttpErrorResponse) => {
                            this.setAuthToken(null);
                            return throwError(error);
                        })
                    );
            })
        );
    }

    public loginTokenLogin(loginToken: string): Observable<any> {
        this.debugService.writeMessageToConsoleLog('sociallogin');

        const loginTokenGrant: LoginTokenGrant = {
            grant_type: 'login_token',
            client_id: environment.CLIENT_ID,
            client_secret: environment.CLIENT_SECRET,
            login_token: loginToken,
            scope: '*'
        };

        return this.authenticationApi.oauthTokenLoginTokenTenantIdPost(
            this.tenantService.tenantData.id.toString(),
            loginTokenGrant
        ).pipe(
            mergeMap((bearerTokenResponse: BearerTokenResponse) => {
                const bearerToken = this.HEADER_TOKEN_PREFIX + bearerTokenResponse.access_token;
                this.setAuthToken(bearerToken);
                return this.usersApi.apiPlayerinfoGet()
                    .pipe(
                        map((userInfo: PlayerHttpResponse) => {
                            this.finishUserLogin(bearerTokenResponse, userInfo);
                        }),
                        catchError((error: HttpErrorResponse) => {
                            this.setAuthToken(null);
                            return throwError(error);
                        })
                    );
            })
        );
    }

    private finishUserLogin(bearerTokenResponse: BearerTokenResponse, userInfo: PlayerHttpResponse) {
        localStorage.setItem(this.TOKEN_NAME, bearerTokenResponse.access_token);
        this.setCurrentUser(userInfo);
        this.setFingerprint(userInfo);
        this.userLoginSuccessSubject.next(true);
    }

    public setAuthTokenFromStorage(): void {
        this.setAuthToken(this.HEADER_TOKEN_PREFIX + localStorage.getItem(this.TOKEN_NAME));
    }

    private setAuthToken(bearerToken: string) {
        this.authToken = bearerToken;
        this.apiConfiguration.apiKeys = {'Authorization': bearerToken};
    }

    public logout() {
        if (this.authToken) {
            this.authenticationApi.oauthTokenDelete().pipe(take(1)).subscribe();
        }
        this.localLogout(false);
        this.router.navigate(['/']);
        return;
    }

    public localLogout(doRedirect: boolean = true) {

        this.broadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.broadcastEventSubscriptions = [];

        this.friendsService.resetAllData();
        this.myNotificationsService.resetAllData();
        this.hintService.resetAllData();

        if (this.currentUser) {
            this.broadcastingService.leaveChannel('User.' + this.currentUser.id);
            this.broadcastingService.leaveChannel('UserWallet.' + this.currentUser.id);
            this.broadcastingService.logout();
        }

        this.setAuthToken(null);
        localStorage.removeItem(this.TOKEN_NAME);

        this.isAuthenticatedSubject.next(false);
        this.currentUserSubject.next(null);
        this.currentUserRequest = null;

        this.clearLocalStorage();

        // reset lang
        this.setDefaultLanguage();

        if (doRedirect) {
            if (this.tenantService.noB2cSignupMethod && this.tenantService.getB2bWebsiteLoginUrl() && this.tenantService.b2bWebsiteLoginDirectForwarding) {
                this.nativeWindow.location.href = this.tenantService.getB2bWebsiteLoginUrl();
            } else if (this.tenantService.isB2b) {
                this.router.navigate(['/']);
                return;
            } else {
                this.router.navigate(['/login']);
                return;
            }
        }

    }

    get currentLang(): LanguageHttpResponse {
        return this._currentLang;
    }

    public languageInit() {
        if (this.currentUser) {
            // set language of user
            const userLang = this.tenantService.tenantLangs.find(
                (language: LanguageHttpResponse) => language.id === this.currentUser.settings.user_language_id);
            if (userLang) {
                this.setLanguage(userLang);
            } else {
                this.setDefaultLanguage();
            }
        } else {
            this.setDefaultLanguage();
        }
    }

    private setLanguage(language: LanguageHttpResponse) {
        if (language) {
            this.translateService.use(language.iso_code2);
            this._currentLang = language;

            this.myNotificationsService.currentLang = language;
            this.hintService.currentLang = language;
            this.tenantService.currentLang = language;

            // set language for semantic-ui modules
            let suiLangCode = language.iso_code2;
            if (suiLangCode === 'en') {
                suiLangCode = suiLangCode + 'GB';
            }
            this.localizationService.load(suiLangCode, SUI_LANG_ENGB);
            if (suiLangCode === 'el') {
                this.localizationService.patch('el', SUI_LANG_EL);
            }

            this.localizationService.patch(suiLangCode, {
                datepicker: {
                    formats: {
                        time: (this.tenantService.tenantData) ? this.tenantService.tenantData.internationalization.time_format : 'HH:mm',
                        datetime: (this.tenantService.tenantData) ? this.tenantService.tenantData.internationalization.date_time_format : 'DD.MM.YYYY, HH:mm',
                        date: (this.tenantService.tenantData) ? this.tenantService.tenantData.internationalization.date_format : 'DD.MM.YYYY',
                        month: 'MMMM YYYY',
                        year: 'YYYY'
                    }
                }
            });

            this.localizationService.setLanguage(suiLangCode);

            // set moment locale
            moment.locale(suiLangCode);

            this.renderer.setAttribute(this.document.documentElement, 'lang', language.iso_code2);
        }
    }

    private setDefaultLanguage() {

        if (this.tenantService.tenantData && this.tenantService.tenantLangs && this.tenantService.tenantLangs.length > 0) {
            this.tenantLang = this.tenantService.tenantLangs.find(
                (language: LanguageHttpResponse) => language.id === this.tenantService.tenantData.internationalization.default_language_id);

            const browserLangIsoCode2 = this.tenantService.browserLangIsoCode2;
            if (browserLangIsoCode2) {
                // check if browserlang exists in tenant langs
                this.browserLang = this.tenantService.tenantLangs.find(
                    (language: LanguageHttpResponse) => language.iso_code2 === browserLangIsoCode2);
            }
        }

        if (this.browserLang) {
            this.setLanguage(this.browserLang);
        } else if (this.tenantLang) {
            this.setLanguage(this.tenantLang);
        } else if (this.tenantService.tenantLangs && this.tenantService.tenantLangs.length > 0) {
            this.setLanguage(this.tenantService.tenantLangs[0]);
        } else {
            this.setLanguage(this.fallBackLanguage);
        }

    }

    public initiatePasswordEmailReset(userIdentifier: string): Observable<{}> {

        const initializePasswordEmailResetRequest: InitializePasswordEmailResetRequest = {
            tenant_id: this.tenantService.tenantData.id,
            identifier: userIdentifier
        };

        return this.usersApi.apiUsersPasswordEmailInitializeresetPut(
            initializePasswordEmailResetRequest
        );
    }

    public passwordEmailReset(newPasswordKey: string, newPassword: string): Observable<{}> {

        const passwordEmailResetRequest: PasswordEmailResetRequest = {
            tenant_id: this.tenantService.tenantData.id,
            new_password_key: newPasswordKey,
            new_password: newPassword
        };

        return this.usersApi.apiUsersPasswordEmailResetPut(
            passwordEmailResetRequest
        );
    }

    public initiatePasswordPhoneReset(phone: string): Observable<{}> {

        const initializePasswordPhoneResetRequest: InitializePasswordPhoneResetRequest = {
            tenant_id: this.tenantService.tenantData.id,
            phone: phone
        };

        return this.usersApi.apiUsersPasswordPhoneInitializeresetPut(
            initializePasswordPhoneResetRequest
        );
    }

    public passwordPhoneReset(phone: string, newPasswordKey: string, newPassword: string): Observable<{}> {

        const passwordPhoneResetRequest: PasswordPhoneResetRequest = {
            tenant_id: this.tenantService.tenantData.id,
            phone: phone,
            new_password_key: newPasswordKey,
            new_password: newPassword
        };

        return this.usersApi.apiUsersPasswordPhoneResetPut(
            passwordPhoneResetRequest
        );
    }

    public setUsername(username): Observable<PlayerHttpResponse> {
        const userUpdateRequest: UserUpdateRequest = {
            'username': username
        };

        return this.usersApi.apiUsersUserIdPut(
            this.currentUser.id,
            userUpdateRequest
        );
    }

    private clearLocalStorage() {
        if (localStorage.length > 0) {
            for (const storageKey of Object.keys(localStorage)) {
                if (storageKey !== 'loginRedirect') {
                    localStorage.removeItem(storageKey);
                }
            }
        }
    }

    private setFingerprint(userInfo?: PlayerHttpResponse) {
        const self = this;
        const options = {};
        let significance = 0;

        setTimeout(() => {
            Fingerprint2.get(options, function (components) {

                const fingerprintHash = Fingerprint2.x64hash128(components.map(function (pair) {
                    if (-1 === ['not available', 'error', 'excluded'].indexOf(pair.value)) {
                        significance += 1;
                    }
                    return pair.value;
                }).join(), undefined);

                const fingerprintWriteRequest: FingerprintWriteRequest = {
                    'fingerprint_hash': fingerprintHash,
                    'significance': significance
                };

                self.usersApi.apiUsersUserIdFingerprintsPost(
                    userInfo.id,
                    fingerprintWriteRequest
                ).pipe(take(1))
                .subscribe({
                    next: () => {},
                    error: (err: HttpErrorResponse) => self.errorService.handleHttpErrorResponse(err)
                });
            });
        }, 1500);
    }

    public showPrizes(): boolean {
        return !(this.nativeWindow.window.BetSnapMobileWrapper && this.mobileDetect.isAndroidDevice() && !this.currentUser);
    }
}
