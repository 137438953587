/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsRankingsSimpleTenantRankingHttpResponse } from './appHttpResponsesBetsnapsRankingsSimpleTenantRankingHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';
import { AppHttpResponsesBetsnapsGamesBasicGameHttpResponse } from './appHttpResponsesBetsnapsGamesBasicGameHttpResponse';


/**
 * UserTransactionHttpResponse
 */
export interface AppHttpResponsesUsersUserTransactionHttpResponse { 
    /**
     * Transaction ID
     */
    id: number;
    /**
     * Transaction Unique ID
     */
    payment_history_transaction_id: string;
    /**
     * User ID
     */
    user_id: number;
    /**
     * Tenant ID
     */
    tenant_id: number;
    game?: AppHttpResponsesBetsnapsGamesBasicGameHttpResponse;
    ranking?: AppHttpResponsesBetsnapsRankingsSimpleTenantRankingHttpResponse;
    /**
     * Payment Type
     */
    payment_type: number;
    /**
     * Description
     */
    description: string;
    /**
     * Transaction Key
     */
    transaction_key: string;
    /**
     * Transaction Value
     */
    transaction_value: string;
    /**
     * Transaction Value 2
     */
    transaction_value2?: string;
    /**
     * Transaction is Bonus Money
     */
    transaction_is_bonus_money: boolean;
    /**
     * Reward Code
     */
    reward_code?: string;
    /**
     * Created At
     */
    created_at: Date;
    /**
     * State of Transaction
     */
    state?: number;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

