import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse,
    AppHttpResponsesSportsDataMatchMarketHttpResponse as MatchMarketHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {GamePointsEngineEnum} from '../../../shared';

interface Market1X2Outcomes {
    col1: Array<MatchMarketOutcomeHttpResponse>;
    colX: Array<MatchMarketOutcomeHttpResponse>;
    col2: Array<MatchMarketOutcomeHttpResponse>;
}

@Directive()
export abstract class ASpecialMarketComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    @Input() currentUser: PlayerHttpResponse;
    @Input() game: GameHttpResponse;
    @Input() match: MatchHttpResponse;
    @Input() market: MatchMarketHttpResponse;

    // parameters for change detection update
    @Input() isCurrentUserJoined: boolean;
    @Input() gameUpdatedDate: Date;
    @Input() matchStatus: string;
    @Input() marketStatus: number;

    @Output() onPlaceBetClick: EventEmitter<{ market: MatchMarketHttpResponse, outcome: MatchMarketOutcomeHttpResponse }> = new EventEmitter();

    protected order1X2Markets = [41, 45, 47, 80, 81, 98, 118, 121, 207, 401, 402, 430, 431, 456, 100041];
    public order1X2Outcomes: Market1X2Outcomes;

    public betPlacementAllowed: boolean = true;

    public marketOutcomesArray: MatchMarketOutcomeHttpResponse[][] = [];
    public marketOutcomesCount: number = 0;

    public gamePointsEngineEnum = GamePointsEngineEnum;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    public outcomesRowTrackBy(index: number, outcomeRow: MatchMarketOutcomeHttpResponse[]) {
        return index + '-' + outcomeRow.length;
    }

    public outcomesTrackBy(index: number, outcome: MatchMarketOutcomeHttpResponse): string {
        if (outcome) {
            return outcome.match_id + '-' + outcome.market_id + '-' + outcome.specifier_val + '-' + outcome.outcome_id;
        } else {
            return null;
        }
    }

    protected createMarketOutcomesArray() {
        const tmpMarketOutcomesArray = [];
        let outcomeCount = 0;
        if (this.order1X2Markets.includes(this.market.market_id)) {
            // create outcome array of already ordered 1X2 outcomes
            this.fill1X2Outcomes();

            const highest1X2colLength = Math.max(
                this.order1X2Outcomes['col1'].length,
                this.order1X2Outcomes['colX'].length,
                this.order1X2Outcomes['col2'].length
            );
            let i: number = 0;
            while (i < highest1X2colLength) {
                let outcomeRowArray: MatchMarketOutcomeHttpResponse[] = [];
                if (this.order1X2Outcomes['col1'][i]) {
                    outcomeRowArray.push(this.order1X2Outcomes['col1'][i]);
                } else {
                    outcomeRowArray.push(null);
                }
                if (this.order1X2Outcomes['colX'][i]) {
                    outcomeRowArray.push(this.order1X2Outcomes['colX'][i]);
                } else {
                    outcomeRowArray.push(null);
                }
                if (this.order1X2Outcomes['col2'][i]) {
                    outcomeRowArray.push(this.order1X2Outcomes['col2'][i]);
                } else {
                    outcomeRowArray.push(null);
                }
                tmpMarketOutcomesArray.push(outcomeRowArray);
                i++;
            }
        } else {
            let outcomeRowArray: MatchMarketOutcomeHttpResponse[];
            this.market.outcomes.forEach(
                (outcome: MatchMarketOutcomeHttpResponse) => {
                    if (outcomeCount === 0 || (outcomeCount % 3 === 0)) {
                        if (outcomeRowArray) {
                            tmpMarketOutcomesArray.push(outcomeRowArray);
                        }
                        outcomeRowArray = [];
                    }
                    outcomeRowArray.push(outcome);
                    outcomeCount++;
                }
            );
            if (outcomeRowArray && outcomeRowArray.length > 0) {
                tmpMarketOutcomesArray.push(outcomeRowArray);
            }
        }

        this.marketOutcomesCount = outcomeCount;
        this.marketOutcomesArray = tmpMarketOutcomesArray;
    }

    protected fill1X2Outcomes() {
        this.order1X2Outcomes = {
            col1: [],
            colX: [],
            col2: []
        };
        if (this.market.outcomes) {
            this.market.outcomes.map(
                (outcome) => {
                    if (outcome.outcome_name.includes(':')) {
                        const outcomeParts = outcome.outcome_name.split(':');
                        if (outcomeParts[0] > outcomeParts[1]) {
                            this.order1X2Outcomes['col1'].push(outcome);
                        } else if (outcomeParts[0] === outcomeParts[1]) {
                            this.order1X2Outcomes['colX'].push(outcome);
                        } else if (outcomeParts[0] < outcomeParts[1]) {
                            this.order1X2Outcomes['col2'].push(outcome);
                        }
                    } else if (outcome.outcome_name.includes('/')) {
                        const outcomeParts = outcome.outcome_name.split('/');
                        if (outcomeParts[0] === '1') {
                            this.order1X2Outcomes['col1'].push(outcome);
                        } else if (outcomeParts[0] === 'X') {
                            this.order1X2Outcomes['colX'].push(outcome);
                        } else if (outcomeParts[0] === '2') {
                            this.order1X2Outcomes['col2'].push(outcome);
                        }
                    } else {
                        this.order1X2Outcomes['col1'].push(outcome);
                    }
                }
            );
        }
    }

}
