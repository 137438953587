/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesCurrencyHttpResponse';


/**
 * TenantFinancialHttpResponse
 */
export interface AppHttpResponsesTenantsTenantFinancialHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Site Rake
     */
    site_rake?: number;
    /**
     * Betsnap Rake Share
     */
    betsnaps_rake_share: number;
    /**
     * Is Rake Net
     */
    is_rake_net: any | null;
    /**
     * Vat Percentage
     */
    vat_percentage?: number;
    /**
     * Base Currency ID
     */
    base_currency_id: number;
    base_currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
    /**
     * Billing Currency ID
     */
    billing_currency_id?: number;
    billing_currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
}

