/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse';


/**
 * TenantPrizeStructureTemplateRankHttpResponse
 */
export interface AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeStructureTemplateRankHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Prize Structure Template Id
     */
    tenant_prize_structure_template_id: number;
    /**
     * rank
     */
    rank: number;
    /**
     * Value
     */
    value?: number;
    /**
     * Master Prize Type Id
     */
    tenant_prize_template_id?: number;
    tenant_prize_template?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplateHttpResponse;
}

