/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GeneralLogHttpResponse
 */
export interface AppHttpResponsesLogsGeneralLogHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Level
     */
    level: string;
    /**
     * Appkey
     */
    appkey: string;
    /**
     * Eventkey
     */
    eventkey: string;
    /**
     * Payload (JSON String)
     */
    payload?: string;
    /**
     * Payload (Decoded JSON String)
     */
    payload_decoded?: object;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

