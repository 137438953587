import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertMessage} from '../../../../shared/interfaces';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AppHttpRequestsUsersUserPhoneSignupVerifyRequest as PhoneSignupVerifyRequest,
    AppHttpResponsesUsersPlayerVerificationHttpResponse as PlayerVerificationHttpResponse,
    SignupApi
} from '../../../../api';
import {
    AuthenticationService,
    ErrorService,
    TenantService,
    GeneralHelpers,
    GoogleAnalyticsService
} from '../../../../shared';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ALoginRegisterComponent} from '../a-login-register.component';
import {Router} from '@angular/router';

@Component({
    selector: 'betsnaps-phone-verification-form',
    templateUrl: './phone-verification-form.component.html',
    styles: []
})
export class PhoneVerificationFormComponent extends ALoginRegisterComponent implements OnInit {

    public processForm: boolean = false;
    public alertMessage: AlertMessage;
    public showAlert: boolean = false;
    public initVerification: UntypedFormGroup = new UntypedFormGroup({
        token: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
    });

    @Input() phone: string;
    @Input() initialAlertMessage: { type: string, messagetranslatekey: string, messagetxttranslatekey: string };
    @Output() onFormSuccess: EventEmitter<any> = new EventEmitter();

    public showLoginButton: boolean = false;
    private GeneralHelpers = new GeneralHelpers();

    constructor(private signupApi: SignupApi,
                private router: Router,
                private authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                private errorService: ErrorService,
                protected googleAnalyticsService: GoogleAnalyticsService) {
        super(tenantService, googleAnalyticsService);
        this.loadPhoneSignupAllowedCountryCodes();
    }

    onSubmit() {
        this.processForm = true;
        this.showAlert = false;
        this.alertMessage = null;

        const verificationRequest: PhoneSignupVerifyRequest = {
            tenant_id: this.tenantService.tenantData.id,
            phone: this.phone ?? this.initVerification.value.phone_country_code + this.initVerification.value.phone,
            verification_token: this.initVerification.value.token.toString()
        };

        this.signupApi.apiUsersSignupPhoneVerifyPut(
            verificationRequest
        ).pipe(take(1))
        .subscribe({
            next: (playerVerificationHttpResponse: PlayerVerificationHttpResponse) => {

                this.alertMessage = {
                    type: 'success',
                    messagetranslatekey: 'PHONEVERIFICATION.initiate_successfully_title',
                    messagetxttranslatekey: 'PHONEVERIFICATION.initiate_successfully'
                };

                this.autoLogin(playerVerificationHttpResponse);
            },
            error: (err: HttpErrorResponse) => {
                this.processForm = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    autoLogin(playerVerificationHttpResponse: PlayerVerificationHttpResponse) {
        // login with verification login token
        if (playerVerificationHttpResponse && playerVerificationHttpResponse.verification_login_token) {
            this.authenticationService.loginTokenLogin(playerVerificationHttpResponse.verification_login_token)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.processForm = false;
                        if (playerVerificationHttpResponse.verification_redirect_url) {
                            this.router.navigateByUrl(playerVerificationHttpResponse.verification_redirect_url);
                        } else if (localStorage.getItem('loginRedirect')) {
                            this.GeneralHelpers.doLoginRedirectIfDefined(this.router);
                        } else {
                            this.router.navigate(['/arena']);
                        }

                        // Close Modal
                        this.onFormSuccess.emit();

                    },
                    error: (err: HttpErrorResponse) => {
                        this.processForm = false;

                        // else show login button
                        this.showLoginButton = true;
                        this.showAlert = true;
                    }
                });
        } else {
            this.processForm = false;
            // else show login button
            this.showLoginButton = true;
            this.showAlert = true;
        }
    }

    ngOnInit() {
        if (!this.phone) {
            // Add Phone Validation if phone is not set from the outside
            this.initVerification.addControl(
                'phone', new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9|\/|\(|\)|\x20]+'), Validators.minLength(4), Validators.maxLength(13)])
            );
            this.initVerification.addControl(
                'phone_country_code', new UntypedFormControl(this.phoneSignupAllowedCountryCodes[0], [Validators.required])
            );
        }
        if (this.initialAlertMessage) {
            // Show initial Alert Message if submitted from the outside
            this.alertMessage = this.initialAlertMessage;
            this.showAlert = true;
        }
    }
}
