import {
    AppHttpRequestsBetsnapsGamesGameSinglePlayerCreateRequest as GameSinglePlayerCreateRequest,
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameSinglePlayerCreateHttpResponse as GameSinglePlayerCreateHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationListHttpResponse as GameVendorGameParticipationListHttpResponse,
    AppHttpResponsesVendorGamesTenantVendorGameHttpResponse as TenantVendorGameHttpResponse,
    GameCreationsApi,
    VendorGamesApi
} from '../../api';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TenantService} from './tenant.service';
import {ErrorService} from './error.service';
import {NotificationType} from 'angular2-notifications';
import {BetsnapdetailService} from './betsnapdetail.service';
import {AuthenticationService} from './authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {MyNotificationsService} from './my-notifications.service';

@Injectable({
    providedIn: 'root'
})
export class SinglePlayerGameService {

    private fullscreenGameVendorGameParticipationSubject = new BehaviorSubject<GameVendorGameParticipationHttpResponse>(null);
    public fullscreenGameVendorGameParticipation$ = this.fullscreenGameVendorGameParticipationSubject.asObservable();

    private fullscreenTenantVendorGameSubject = new BehaviorSubject<TenantVendorGameHttpResponse>(null);
    public fullscreenTenantVendorGame$ = this.fullscreenTenantVendorGameSubject.asObservable();

    private showFullScreenGameSubject = new BehaviorSubject<boolean>(false);
    public showFullScreenGame$ = this.showFullScreenGameSubject.asObservable();

    private singlePlayerGameParticipationsSubject = new BehaviorSubject<GameVendorGameParticipationHttpResponse[]>(null);
    public singlePlayerGameParticipations$ = this.singlePlayerGameParticipationsSubject.asObservable();
    private singlePlayerGameParticipationsRequestRunning: boolean = false;

    public fullscreenTitleFallback: string = null;

    constructor(private tenantService: TenantService,
                private vendorGamesApi: VendorGamesApi,
                private gameCreationsApi: GameCreationsApi,
                private errorService: ErrorService,
                private betsnapDetailService: BetsnapdetailService,
                private authenticationService: AuthenticationService,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService) {
    }

    public getSinglePlayerParticipations(userId: number, forceReload: boolean = false) {
        if ((!this.singlePlayerGameParticipationsSubject.value || forceReload) && !this.singlePlayerGameParticipationsRequestRunning) {
            this.singlePlayerGameParticipationsRequestRunning = true;
            this.vendorGamesApi.apiTenantsTenantIdUsersUserIdSingleplayergamesvendorgameparticipationsGet(
                this.tenantService.tenantData.id,
                userId,
            ).pipe(take(1))
            .subscribe({
                next: (gameVendorGameParticipationList: GameVendorGameParticipationListHttpResponse) => {
                    this.singlePlayerGameParticipationsSubject.next(gameVendorGameParticipationList.results);
                    this.singlePlayerGameParticipationsRequestRunning = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.handleHttpErrorResponse(err);
                    this.singlePlayerGameParticipationsRequestRunning = false;
                }
            });
        }
    }

    public startGameVendorSinglePlayerGame(vendorGameId: number, points: number = null): Observable<GameSinglePlayerCreateHttpResponse> {
        const createGameRequest: GameSinglePlayerCreateRequest = {
            vendor_game_id: vendorGameId
        };

        if (points) {
            createGameRequest.points = points;
        }

        return this.gameCreationsApi.apiTenantsTenantIdSingleplayergamesPost(
            this.tenantService.tenantData.id,
            createGameRequest
        ).pipe(map(
            (singlePlayerGameCreateResponse: GameSinglePlayerCreateHttpResponse) => {

                this.betsnapDetailService.getGameVendorGameParticipations(
                    singlePlayerGameCreateResponse.game.game_unique_id,
                    this.authenticationService.currentUser.id
                );

                this.translateService.get(['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.started'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast('', translation['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.started'], NotificationType.Success);
                    });

                return singlePlayerGameCreateResponse;
            }
        ));
    }

    public finishGameVendorSinglePlayerGame(gameUniqueId: string): Observable<GameHttpResponse> {

        return this.gameCreationsApi.apiTenantsTenantIdSingleplayergamesGameUniqueIdFinishPut(
            this.tenantService.tenantData.id,
            gameUniqueId
        ).pipe(map(
            (gameResponse: GameHttpResponse) => {
                this.translateService.get(['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.closed'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast('', translation['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.closed'], NotificationType.Success);
                    });

                return gameResponse;
            }
        ));
    }

    public showFullscreenVendorGame(activeParticipation: GameVendorGameParticipationHttpResponse, tenantVendorGame: TenantVendorGameHttpResponse = null, fullscreenTitleFallback = null) {
        this.fullscreenGameVendorGameParticipationSubject.next(activeParticipation);
        this.fullscreenTenantVendorGameSubject.next(tenantVendorGame);
        this.fullscreenTitleFallback = fullscreenTitleFallback;
        this.showFullScreenGameSubject.next(true);
    }

    public hideFullScreenVendorGame() {
        this.showFullScreenGameSubject.next(false);
    }

    public closeFullscreenVendorGame() {
        this.hideFullScreenVendorGame();
        this.fullscreenGameVendorGameParticipationSubject.next(null);
        this.fullscreenTenantVendorGameSubject.next(null);
    }

    public setFullscreenVendorGameParticipation(participation: GameVendorGameParticipationHttpResponse ) {
        this.fullscreenGameVendorGameParticipationSubject.next(participation);
    }

    public getShowFullScreenGame(): boolean {
        return this.showFullScreenGameSubject.getValue();
    }

    public resetData() {
        this.fullscreenGameVendorGameParticipationSubject.next(null);
        this.fullscreenTenantVendorGameSubject.next(null);
        this.showFullScreenGameSubject.next(false);
        this.singlePlayerGameParticipationsSubject.next(null);
    }

}
