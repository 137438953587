import {Pipe, PipeTransform} from '@angular/core';
import {TenantService} from '../services/tenant.service';

@Pipe({
    name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

    constructor(private tenantService: TenantService) {
    }

    transform(amount: string | number, currencySymbol: string): string {
        switch (this.tenantService.tenantData.internationalization.currency_format) {
            case 'prefix':
                return currencySymbol + ' ' + amount;
            case 'suffix':
                return amount + ' ' + currencySymbol;
        }

    }

}
