import {Inject, Injectable, NgZone} from '@angular/core';
import {WEB_SOCKET_URL} from '../../app.tokens';
import {EMPTY} from 'rxjs';
import {DebugService} from './debug.service';
import {ConnectionEvents, EchoConfig, EchoService} from 'ngx-laravel-echo-jn';
import {ChannelType} from 'ngx-laravel-echo-jn/src/services/lib.service';
import {MobiledetectService} from './mobiledetect.service';

@Injectable({
    providedIn: 'root'
})
export class BroadcastingService {

    public echoService: EchoService;
    private echoConfig: EchoConfig;

    constructor(@Inject(WEB_SOCKET_URL) webSocketUrl: string,
                public ngZone: NgZone,
                public debugService: DebugService,
                private mobileDetect: MobiledetectService) {
        if (webSocketUrl) {

            let transportsArray = ['websocket', 'polling'];
            if (this.mobileDetect.browserName() === 'operamini') {
                transportsArray = ['polling', 'websocket'];
            }

            this.echoConfig = {
                userModel: 'User',
                notificationNamespace: 'App\\Notifications',
                options: {
                    broadcaster: 'socket.io',
                    host: webSocketUrl,
                    transports: transportsArray
                }
            };

            this.echoService = new EchoService(this.ngZone, this.echoConfig);

            this.echoService.rawConnectionState.subscribe(
                (connectionEvent: ConnectionEvents) => {
                    if ([
                            'connect',
                            'connect_error',
                            'connect_timeout',
                            'reconnect',
                            'reconnect_attempt',
                            'reconnecting',
                            'reconnect_error',
                            'reconnect_failed',
                            'disconnect'
                        ].includes(connectionEvent.type)) {
                        this.debugService.writeMessageToConsoleLog('socket ' + connectionEvent.type);
                    }
                }
            );

        }
    }

    public joinChannel(channel: string, channelType: ChannelType = 'public') {
        if (this.echoService) {
            this.debugService.writeMessageToConsoleLog('join broadcast channel ' + channel);
            this.echoService.join(channel, channelType);
        }
    }

    public leaveChannel(channel: string) {
        if (this.echoService) {
            this.debugService.writeMessageToConsoleLog('leave broadcast channel ' + channel);
            this.echoService.leave(channel);
        }
    }

    public listenOnEventInChannel(channel: string, event: string) {
        if (this.echoService) {
            return this.echoService.listen(channel, event);
        } else {
            return EMPTY;
        }
    }

    public login(authToken: string, userId: number) {
        if (this.echoService) {
            this.debugService.writeMessageToConsoleLog('broadcast authenticate');
            this.echoService.login({Authorization: authToken}, userId);
        }
    }

    public logout() {
        if (this.echoService) {
            this.debugService.writeMessageToConsoleLog('broadcast logout');
            this.echoService.logout();
        }
    }

}
