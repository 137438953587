import { Input, OnInit, Directive } from '@angular/core';
import {
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse,
    AppHttpResponsesSportsDataMatchScoreDetailHttpResponse as MatchScoreDetailHttpResponse
} from '../../../../api';
import * as moment from 'moment';
import { MatchPeriodTypeEnum } from 'src/app/shared/enums';

@Directive()
export class AMatchCardDetailsComponent implements OnInit {

    @Input() match: MatchHttpResponse;
    @Input() feedLiveProducerUp: boolean = true;

    public MatchPeriodTypeEnum = MatchPeriodTypeEnum;

    constructor() {
    }

    ngOnInit() {
    }

    public shouldShowScore(): boolean {
        return (
            this.feedLiveProducerUp &&
            this.matchHasStarted() &&
            this.match.score && this.match.score.match_status_code &&
            this.match.status !== 'not_started' && this.match.status !== 'scheduled' && this.match.status !== 'delayed' &&
            this.match.status !== 'cancelled' && this.match.status !== 'postponed' && this.match.status !== 'abandoned'
        );
    }

    public getPeriodColumnClasses(): string[] {
        let competitorColumnClass = 'ten';
        let scoreColumnClass = 'six';
        if (this.match.score.periods) {
            if (this.match.score.periods.length === 3) {
                competitorColumnClass = 'nine';
                scoreColumnClass = 'seven';
            } else if (this.match.score.periods.length === 4) {
                competitorColumnClass = 'eight';
                scoreColumnClass = 'eight';
            } else if (this.match.score.periods.length > 4) {
                competitorColumnClass = 'seven';
                scoreColumnClass = 'nine';
            }
        }
        return [competitorColumnClass, scoreColumnClass];
    }

    public matchHasStarted(): boolean {
        return moment(this.match.season_scheduled_date).isBefore(moment().utc());
    }

    public periodTrackBy(index: number, matchScoreDetail: MatchScoreDetailHttpResponse): string {
        return matchScoreDetail.period;
    }

    public periodIsFinished(index: number, match: MatchHttpResponse): boolean {
        return ((index + 1) < match.score.periods.length || this.matchIsFinished(match));
    }

    public matchIsFinished(match: MatchHttpResponse): boolean {
        return (match.status === 'closed' || match.status === 'ended');
    }

    public getMatchPeriods() {
        return (this.match.score.periods && this.match.score.periods.length > 1) ? this.match.score.periods : null;
    }

    public withScoreExtraInformation(): boolean {
        return (
            this.match.score &&
            this.match.score.match_status_code &&
            this.match.score.match_status_code.translations &&
            (
                this.match.score.match_status_code.betradar_status_id === 110 ||
                this.match.score.match_status_code.betradar_status_id === 120
            )
        );
    }

    public hasPenaltiesPeriod(match: MatchHttpResponse): boolean {
        return (
            match.score && match.score.periods && match.score.periods.length > 1 &&
            (match.score.periods.filter((period: MatchScoreDetailHttpResponse) => period.type === MatchPeriodTypeEnum.PENALTIES).length > 0)
        );
    }
}
