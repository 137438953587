/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TenantInquiryAnswerHttpResponse
 */
export interface AppHttpResponsesTenantsTenantInquiryAnswerHttpResponse { 
    /**
     * Tenant Inquiry ID
     */
    id: number;
    /**
     * Tenant Inquiry ID
     */
    tenant_inquiry_id: number;
    /**
     * Text of the inquiry
     */
    text: string;
    /**
     * Order
     */
    order?: number;
    /**
     * Translation
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

