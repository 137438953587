/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesUsersUserPermissionHttpResponse } from './appHttpResponsesUsersUserPermissionHttpResponse';


/**
 * ApiConsumerHttpResponse
 */
export interface AppHttpResponsesUsersApiConsumerHttpResponse { 
    /**
     * User Id
     */
    id: number;
    /**
     * Unique ID
     */
    unique_id: string;
    /**
     * Username
     */
    username: string;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Permissions
     */
    permissions?: Array<AppHttpResponsesUsersUserPermissionHttpResponse>;
}

