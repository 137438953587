<div class="ui centered relaxed grid container margin-t-0 margin-b-0">
    <div class="row padding-0">
        <betsnaps-left-sidebar-loader class="four wide tablet or lower hidden column sidebar left-sidebar"></betsnaps-left-sidebar-loader>
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">

            <div class="ui vertical borderless very padded segment" *ngIf="processTenantRankingLists">
                <div class="ui active centered inline text loader on-body-bg">
                    {{'GENERAL.loadertext' | translate}}
                </div>
            </div>

            <div *ngIf="!processTenantRankingLists">

                <div class="display-flex justify-flex-end" *ngIf="(tenantRankingVisible && tenantRankingVisible.length > 1) || (tenantRankingsNotVisible && tenantRankingsNotVisible.length > 0)">
                    <div class="ui fluid flexauto item tiny separatorless menu" *ngIf="tenantRankingVisible && tenantRankingVisible.length > 1">
                        <a href="#" class="item flex-truncate" *ngFor="let tenantRanking of tenantRankingVisible"
                           [class.active]="currentTenantRankingId === tenantRanking.id"
                           (click)="selectTenantRankingId(tenantRanking.id, false, $event)">
                            <span class="truncate">
                                {{tenantRanking.title | dbtranslation:'title':tenantRanking.translations | uppercase}}
                            </span>
                        </a>
                    </div>
                    <div class="menu-button-wrap" *ngIf="tenantRankingsNotVisible.length > 0">
                        <button class="ui fluid tiny button menu-button margin-0"
                                (click)="showTenantRankingsModal()">
                                <i class="fa fa-sliders big icon margin-0"></i>
                        </button>
                    </div>
                </div>

                <ng-template let-context let-modal="modal" #tenantRankingFilterModalTemplate *ngIf="tenantRankingList.total > 3">
                    <div class="header center aligned">
                        {{'GENERAL.GAMES.related_tenant_rankings' | translate }}
                    </div>
                    <div class="scrolling content">
                        <div class="ui divider margin-0"></div>
                        <div class="ui vertical fluid tiny menu margin-0">
                            <a *ngFor="let tenantRanking of tenantRankingsNotVisible"
                               class="item padding-15"
                               href="#"
                                [class.active]="currentTenantRankingId === tenantRanking.id"
                                (click)="$event.preventDefault(); selectTenantRankingId(tenantRanking.id, false, $event); modal.deny();">
                                {{tenantRanking.title | dbtranslation:'title':tenantRanking.translations | uppercase}}
                            </a>
                        </div>
                    </div>
                </ng-template>

                <div class="ui fluid card stretch-bottom-card margin-0">
                    <div *ngIf="tenantRankingList.total > 0">

                        <div class="ui vertical borderless very padded segment" *ngIf="processTenantRanking">
                            <div class="ui active centered inline text loader">
                                {{'GENERAL.loadertext' | translate}}
                            </div>
                        </div>

                        <div *ngIf="!processTenantRanking && currentTenantRanking">
                            <div class="ranking-description" [class.no-profile-header-image]="!profileHeaderImage">
                                <h2 class="text-big margin-0">
                                    {{currentTenantRanking.title | dbtranslation:'title':currentTenantRanking.translations | uppercase}}
                                </h2>
                                <p *ngIf="currentTenantRanking.description" class="margin-0">
                                    {{currentTenantRanking.description | dbtranslation:'description':currentTenantRanking.translations}}
                                </p>
                            </div>
                            <div class="ui fluid profile-header-image image" *ngIf="profileHeaderImage">
                                <img [src]="profileHeaderImage" [alt]="" />
                            </div>
                            <div class="content" [class.with-profile-header-image]="profileHeaderImage && authenticationService.currentUser">
                                <div *ngIf="authenticationService.currentUser" class="center aligned margin-b-30">
                                    <betsnaps-user-avatar-loader [user]="authenticationService.currentUser"
                                                                 [imageSize]="'small'">
                                    </betsnaps-user-avatar-loader>
                                    <p class="margin-t-10 margin-b-0" *ngIf="authenticationService.currentUser.username">
                                        <strong class="font-very-bold text-large">{{authenticationService.currentUser.username}}</strong>
                                    </p>
                                    <p *ngIf="authenticationService.currentUser"
                                       class="text-tiny color-light-grey text-uppercase font-bold margin-b-0">
                                        {{'GENERAL.GAMES.rank' | translate}}:&nbsp;
                                        <span *ngIf="currentTenantRanking.user_ranking_position?.rank > 0">
                                            {{currentTenantRanking.user_ranking_position?.rank}}<span class="text-mini">{{currentTenantRanking.user_ranking_position?.rank | ordinalNumber:true}}</span>
                                        </span>
                                        <span *ngIf="currentTenantRanking.user_ranking_position?.rank < 1">
                                            -
                                        </span>
                                    </p>

                                    <p class="text-tiny color-light-grey text-uppercase font-bold margin-b-0"
                                       *ngIf="currentTenantRanking && (currentTenantRanking.user_ranking_position?.tenant_prize || currentTenantRanking.user_ranking_position?.tenant_prize_template || (currentTenantRanking.user_ranking_position?.prize_amount !== null && currentTenantRanking.user_ranking_position?.prize_amount !== 0))">
                                        {{'GENERAL.GAMES.current_prize' | translate}}:&nbsp;
                                        <span *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index !== null)">
                                            {{currentTenantRanking.user_ranking_position.prize_amount | decimalPrize: 2 |
                                                currencyFormat:currentTenantRanking.prize_structures[currentTenantRanking.primary_cash_prize_structure_index].currency.symbol}}
                                        </span>
                                        <span *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index === null)">
                                            {{currentTenantRanking.user_ranking_position.prize_amount | decimalPrize: 2}}
                                        </span>
                                        <span *ngIf="currentTenantRanking.user_ranking_position.tenant_prize">
                                            <span *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0)"> + </span>
                                            {{currentTenantRanking.user_ranking_position.tenant_prize.title | dbtranslation:'title':currentTenantRanking.user_ranking_position.tenant_prize.translations}}
                                        </span>
                                        <span *ngIf="!currentTenantRanking.user_ranking_position.tenant_prize && currentTenantRanking.user_ranking_position.tenant_prize_template">
                                            <span *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0)"> + </span>
                                            {{currentTenantRanking.user_ranking_position.tenant_prize_template.title | dbtranslation:'title':currentTenantRanking.user_ranking_position.tenant_prize_template.translations}}
                                        </span>
                                    </p>

                                    <div class="margin-t-10" *ngIf="(checkIfHasAFromOrToDate(currentTenantRanking) || checkIfHasLastRecalculationDate(currentTenantRanking))">
                                        <div class="" *ngIf="checkIfHasAFromOrToDate(currentTenantRanking)">
                                            <p class="text-tiny color-light-grey text-uppercase font-bold" *ngIf="(currentTenantRanking.from_date && currentTenantRanking.to_date)">
                                                {{currentTenantRanking.from_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }} - {{currentTenantRanking.to_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                            <p class="text-tiny color-light-grey text-uppercase font-bold" *ngIf="(currentTenantRanking.from_date && !currentTenantRanking.to_date)">
                                                {{'RANKING.from' | translate}} {{currentTenantRanking.from_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                            <p class="text-tiny color-light-grey text-uppercase font-bold" *ngIf="(!currentTenantRanking.from_date && currentTenantRanking.to_date)">
                                                {{'RANKING.to' | translate}} {{currentTenantRanking.to_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                            <p class="text-tiny text-uppercase font-bold"
                                               *ngIf="(currentTenantRanking.to_date && currentTenantRanking.ranking_state < 6 && getDaysLeft(currentTenantRanking.to_date) > 1 && getDaysLeft(currentTenantRanking.to_date) <= 7)">
                                                {{'RANKING.days_left' | translate:{days: (getDaysLeft(currentTenantRanking.to_date))} | uppercase }}
                                            </p>
                                            <p class="text-tiny text-uppercase font-bold"
                                               *ngIf="(currentTenantRanking.to_date && currentTenantRanking.ranking_state < 6 && getDaysLeft(currentTenantRanking.to_date) === 1)">
                                                {{'RANKING.day_left' | translate:{days: (getDaysLeft(currentTenantRanking.to_date))} | uppercase }}
                                            </p>
                                        </div>
                                        <div *ngIf="checkIfHasLastRecalculationDate(currentTenantRanking)" class="item">
                                            <p class="text-tiny">{{'RANKING.last_recalculation' | translate}}: {{currentTenantRanking.last_recalculation_at | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="center aligned margin-b-30 margin-t-30"
                                     *ngIf="currentTenantRanking.user_friends_ranking_positions_count > 0">
                                    <div class="ui compact item tiny separatorless menu">
                                        <a href="#" class="item" [class.active]="menu.active"
                                           *ngFor="let menu of rankingMenu"
                                           (click)="$event.preventDefault(); rankingMenuChange(menu.key);">
                                            <span class="display-inline-block vertical-align-middle">
                                                {{menu.translatekey | translate | uppercase}}
                                            </span>
                                        </a>
                                    </div>
                                </div>

                                <div class="ui vertical borderless very padded segment" *ngIf="processTenantRankingPositions">
                                    <div class="ui active centered inline text loader on-body-bg">
                                        {{'GENERAL.loadertext' | translate}}
                                    </div>
                                </div>

                                <div *ngIf="!processTenantRankingPositions">
                                    <div class="ui divided list leaderboard-list v3" *ngIf="tenantRankingPositionsList.total > 0">
                                        <div class="item leaderboard-list-item" *ngFor="let tenantRankingPosition of tenantRankingPositionsList.results; trackBy: rankingPositionTrackBy; let i = index">
                                            <div class="ui middle aligned centered grid container"
                                                 [class.element-clickable]="(tenantRankingPosition.tenant_prize || tenantRankingPosition.tenant_prize_template || (tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0))"
                                                 (click)="((tenantRankingPosition.tenant_prize || tenantRankingPosition.tenant_prize_template || tenantRankingPosition.prize_amount !== null) ? showRankingUserInfoModal(tenantRankingPosition) : '')">
                                                <div class="row">
                                                    <div class="two wide column center aligned padding-0">
                                                        <strong [class.text-tiny]="(tenantRankingPosition.rank > 9999)">
                                                            {{(tenantRankingPosition.rank > 0) ? tenantRankingPosition.rank : '-'}}
                                                        </strong>
                                                    </div>
                                                    <div class="seven wide column user-column padding-r-0">
                                                        <betsnaps-user-avatar-loader
                                                            [user]="tenantRankingPosition.user"
                                                            [imageSize]="'tiny'"
                                                            [clickAble]="(tenantRankingPosition.tenant_prize === null && tenantRankingPosition.tenant_prize_template === null && tenantRankingPosition.prize_amount === null)"
                                                            [addClass]="'right spaced profileimg'">
                                                        </betsnaps-user-avatar-loader>
                                                        <div class="user-detail-content truncate">
                                                            <strong class="font-very-bold"
                                                                    [class.color-grey]="(tenantRankingPosition.tenant_prize === null && tenantRankingPosition.tenant_prize_template === null && tenantRankingPosition.prize_amount === null)">
                                                                {{tenantRankingPosition.user.username}}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="seven wide column padding-l-0 right aligned">
                                                        <img [src]="'./assets/img/prizes-rank-' + (i + 1) + '.png'" alt=""
                                                             class="iconimage"
                                                             *ngIf="((i + 1) < 4) && (tenantRankingPosition.tenant_prize || tenantRankingPosition.tenant_prize_template || (tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0))"/>
                                                        <img [src]="'./assets/img/prizerank.png'" alt=""
                                                             class="iconimage"
                                                             *ngIf="((i + 1) > 3) && (tenantRankingPosition.tenant_prize || tenantRankingPosition.tenant_prize_template || (tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0))"/>
                                                        <strong>
                                                            {{tenantRankingPosition.points | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.abbr_points' | translate}}
                                                        </strong>
                                                        <i *ngIf="(tenantRankingPosition.tenant_prize || tenantRankingPosition.tenant_prize_template || (tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0))" class="fa fa-chevron-right small icon color-light-grey color-lighter margin-0"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-template let-context let-modal="modal" #rankingUserInfoModalTemplate>
                                            <div class="scrolling content" [class.with-profile-header-image]="profileHeaderImage && rankingUserInfoModalUser">
                                                <div class="ui fluid profile-header-image image" *ngIf="profileHeaderImage && rankingUserInfoModalUser">
                                                    <img [src]="profileHeaderImage" [alt]="" />
                                                </div>
                                                <div class="inner-content" [class.with-profile-header-image]="profileHeaderImage && rankingUserInfoModalUser">
                                                    <div class="ui vertical borderless very padded segment" *ngIf="!rankingUserInfoModalUser">
                                                        <div class="ui active centered inline text loader">
                                                            {{'GENERAL.loadertext' | translate}}
                                                        </div>
                                                    </div>

                                                    <div *ngIf="rankingUserInfoModalUser" class="center aligned margin-b-15">
                                                        <betsnaps-user-avatar-loader [user]="rankingUserInfoModalUser.user"
                                                                                     [imageSize]="'medium'">
                                                        </betsnaps-user-avatar-loader>
                                                        <p class="margin-t-10 margin-b-0" *ngIf="rankingUserInfoModalUser.user.username">
                                                            <strong class="font-very-bold text-large">{{rankingUserInfoModalUser.user.username}}</strong>
                                                        </p>
                                                        <p class="text-tiny color-light-grey text-uppercase font-bold margin-b-0">
                                                            {{'GENERAL.GAMES.rank' | translate}}:&nbsp;
                                                            <span *ngIf="rankingUserInfoModalUser.rank > 0">
                                                                {{rankingUserInfoModalUser.rank}}<span class="text-mini">{{rankingUserInfoModalUser.rank | ordinalNumber:true}}</span>
                                                            </span>
                                                            <span *ngIf="rankingUserInfoModalUser.rank < 1">
                                                                -
                                                            </span>
                                                        </p>

                                                        <p class="text-tiny color-light-grey text-uppercase font-bold"
                                                           *ngIf="showPrizes && (rankingUserInfoModalUser.tenant_prize || rankingUserInfoModalUser.tenant_prize_template || (rankingUserInfoModalUser.prize_amount !== null && rankingUserInfoModalUser.prize_amount !== 0))">
                                                            {{'GENERAL.GAMES.current_prize' | translate}}:&nbsp;
                                                            <span *ngIf="(rankingUserInfoModalUser.prize_amount !== null && rankingUserInfoModalUser.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index !== null)">
                                                                    {{rankingUserInfoModalUser.prize_amount | decimalPrize: 2 |
                                                                currencyFormat:currentTenantRanking.prize_structures[currentTenantRanking.primary_cash_prize_structure_index].currency.symbol}}
                                                                </span>
                                                            <span *ngIf="(rankingUserInfoModalUser.prize_amount !== null && rankingUserInfoModalUser.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index === null)">
                                                                    {{rankingUserInfoModalUser.prize_amount | decimalPrize: 2}}
                                                                </span>
                                                            <span *ngIf="rankingUserInfoModalUser.tenant_prize">
                                                                <span *ngIf="(rankingUserInfoModalUser.prize_amount !== null && rankingUserInfoModalUser.prize_amount !== 0)"> + </span>
                                                                {{rankingUserInfoModalUser.tenant_prize.title | dbtranslation:'title':rankingUserInfoModalUser.tenant_prize.translations}}
                                                            </span>
                                                            <span *ngIf="!rankingUserInfoModalUser.tenant_prize && rankingUserInfoModalUser.tenant_prize_template">
                                                                <span *ngIf="(rankingUserInfoModalUser.prize_amount !== null && rankingUserInfoModalUser.prize_amount !== 0)"> + </span>
                                                                {{rankingUserInfoModalUser.tenant_prize_template.title | dbtranslation:'title':rankingUserInfoModalUser.tenant_prize_template.translations}}
                                                            </span>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-template>

                                        <div class="ui basic segment center aligned"
                                             *ngIf="tenantRankingPositionsList.results.length < tenantRankingPositionsList.total"
                                             #loadMoreButtonPositions>
                                            <button type="button"
                                                    class="ui button margin-0"
                                                    [class.loading]="processLoadMorePositions"
                                                    (click)="loadMorePositions()">
                                                {{'GENERAL.load-more_btn' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="tenantRankingPositionsList.total < 1"
                                         class="ui bottom attached borderless segment center aligned">
                                        <p>{{'RANKING.no_positions_available' | translate}}</p>
                                        <img class="image" [src]="'./assets/img/notification_images/gamewinnings-notification.png'" alt="Ranking">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui vertical borderless center aligned segment margin-0 padding-40"
                         *ngIf="tenantRankingList.total < 1">
                        <p>{{'RANKING.no_rankings_available' | translate}}</p>
                        <img class="image" [src]="'./assets/img/notification_images/gamewinnings-notification.png'" alt="Ranking">
                    </div>
                </div>
            </div>
        </div>
        <betsnaps-right-sidebar-loader class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar"></betsnaps-right-sidebar-loader>
    </div>
</div>
