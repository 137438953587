<div class="copy-to-clipboard">
    <div class="ui fluid action input">
        <input type="text" value="{{value}}" class="truncate center aligned" readonly>
        <button class="ui right icon button" (click)="googleAnalyticsService.trackEvent(analyticsEventCategory, analyticsEventAction, analyticsEventLabel);"
                ngxClipboard
                [cbContent]="value"
                (cbOnSuccess)="showCopyInformation()">
            <i class="fa fa-link icon"></i>
        </button>
    </div>
    <div *ngIf="showcopyinfo" class="ui vertical borderless segment padding-t-5 padding-b-0 margin-0">
        <div class="ui fluid borderless basic label center aligned text-normal font-normal">
            <i class="icon check color-positive"></i> {{'COPYTOCLIPBOARD.copy_success' | translate}}
        </div>
    </div>
</div>
