<div class="ui centered relaxed grid container">
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <div class="ui center aligned borderless vertical segment margin-0">
            <h2 class="ui center aligned small header">
                {{'RESETPASSWORD.heading' | translate | uppercase}}
            </h2>
        </div>
    </div>
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <div class="ui borderless vertical segment margin-0 padding-b-0">
            <fui-message *ngIf="showAlert" class="{{alertMessage.type ?? ''}} margin-b-20" [hasDismissButton]="false">
                <div class="header" *ngIf="alertMessage.messagetranslatekey">
                    {{alertMessage.messagetranslatekey | translate}}
                </div>
                <p *ngIf="alertMessage.messagetxttranslatekey">
                    {{alertMessage.messagetxttranslatekey | translate}}
                </p>
            </fui-message>

            <div *ngIf="showLoginButton">
                <button class="ui fluid button" type="button" (click)="showView('login')">{{'RESETPASSWORD.login_btn' | translate}}</button>
            </div>

            <form *ngIf="showInitForm" class="ui form" novalidate [formGroup]="initPasswordReset"
                  [class.error]="!processInitForm"
                  (ngSubmit)="onSubmitInitRequest()">

                <betsnaps-phone-field [processForm]="processInitForm"
                                      [phoneSignupAllowedCountryCodes]="phoneSignupAllowedCountryCodes"
                                      [form]="initPasswordReset"></betsnaps-phone-field>

                <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10 padding-b-0">
                    <button class="ui large fluid button margin-0"
                            type="submit"
                            [class.loading]="processInitForm"
                            [disabled]="processInitForm || initPasswordReset.invalid">
                        {{'RESETPASSWORD.initialize_btn' | translate | uppercase}}
                    </button>
                </div>
            </form>

            <form *ngIf="showResetForm" class="ui form" novalidate [formGroup]="resetPassword"
                  [class.error]="!processResetForm"
                  (ngSubmit)="onSubmitResetPasswordRequest()">
                <div class="ui field" formGroupName="passwordGroup">
                    <div class="field">
                        <div class="ui left input">
                            <input type="password"
                                   id="newpassword"
                                   name="newpassword"
                                   placeholder="{{'RESETPASSWORD.newpassword_field' | translate}}"
                                   formControlName="newpassword" required/>
                        </div>
                        <div
                            *ngIf="(resetPassword.controls['passwordGroup'].get('newpassword').touched || resetPassword.controls['passwordGroup'].get('newpassword').dirty) && resetPassword.controls['passwordGroup'].get('newpassword').invalid"
                            class="ui pointing red basic label">
                            <p *ngIf="resetPassword.controls['passwordGroup'].get('newpassword').hasError('required')">
                                {{'RESETPASSWORD.ERRORS.newpassword_field_required' | translate}}
                            </p>
                            <p *ngIf="resetPassword.controls['passwordGroup'].get('newpassword').hasError('minlength')">
                                {{'RESETPASSWORD.ERRORS.newpassword_too_short' | translate}}
                            </p>
                        </div>
                    </div>
                    <div class="field">
                        <div class="ui left input">
                            <input type="password"
                                   id="newpassword_confirm"
                                   name="newpassword_confirm"
                                   placeholder="{{'RESETPASSWORD.newpassword_confirm_field' | translate}}"
                                   formControlName="newpassword_confirm" required/>
                        </div>
                        <div
                            *ngIf="(resetPassword.controls['passwordGroup'].get('newpassword_confirm').touched || resetPassword.controls['passwordGroup'].get('newpassword_confirm').dirty) && resetPassword.controls['passwordGroup'].get('newpassword_confirm').invalid"
                            class="ui pointing red basic label">
                            <p *ngIf="resetPassword.controls['passwordGroup'].get('newpassword_confirm').hasError('required')">
                                {{'RESETPASSWORD.ERRORS.newpassword_confirm_field_required' | translate}}
                            </p>
                            <p *ngIf="resetPassword.controls['passwordGroup'].get('newpassword_confirm').hasError('minlength')">
                                {{'RESETPASSWORD.ERRORS.newpassword_too_short' | translate}}
                            </p>
                        </div>

                        <div
                            *ngIf="resetPassword.controls['passwordGroup'].get('newpassword').valid && resetPassword.controls['passwordGroup'].get('newpassword_confirm').valid && resetPassword.controls['passwordGroup'].hasError('areEqual')"
                            class="ui pointing red basic label">
                            <p>
                                {{'RESETPASSWORD.ERRORS.newpasswords_equal' | translate}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="field" [class.disabled]="processResetForm">
                    <div class="ui left input">
                        <input type="number" id="new_password_key" name="new_password_key"
                               placeholder="{{'RESETPASSWORD.new_password_key' | translate}}"
                               formControlName="new_password_key"
                               required/>
                    </div>
                    <div *ngIf="resetPassword.get('new_password_key').touched && resetPassword.get('new_password_key').invalid"
                         class="ui pointing red basic label">
                        {{'RESETPASSWORD.ERRORS.new_password_key_required' | translate}}
                    </div>
                </div>
                <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10 padding-b-0">
                    <button class="ui large fluid button margin-0"
                            type="submit"
                            [class.loading]="processResetForm"
                            [disabled]="processResetForm || resetPassword.invalid">
                        {{'RESETPASSWORD.reset_btn' | translate | uppercase}}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="ui sixteen wide center aligned column padding-b-0">
        <div class="ui row divider"></div>
        <div class="color-grey margin-b-10">
            {{'LOGIN.no_account_label' | translate}}
            <a class="text font-bold nowrap"
               (click)="showView('register')">
                {{'LOGIN.no_account_btn' | translate}}
            </a>
        </div>
        <div class="color-grey">
            {{'LOGIN.already_an_account_label' | translate}}
            <a class="text font-bold nowrap"
               (click)="showView('login')">
                {{'LOGIN.already_an_account_btn' | translate}}
            </a>
        </div>
    </div>
</div>
