import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse,
    AppHttpResponsesSportsDataMatchMarketHttpResponse,
    AppHttpResponsesSportsDataMatchMarketUpdateHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse,
    AppHttpResponsesSportsDataMatchUpdateHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse,
    AppHttpResponsesUsersUserSettingHttpResponse,
    AppHttpResponsesUsersUserWalletHttpResponse,
    AppHttpResponsesBetsnapsGamesGameUserHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse,
    AppHttpResponsesFriendsFriendInvitationHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse,
    AppHttpResponsesFriendsFriendGroupHttpResponse,
    AppHttpResponsesNotificationsUserNotificationHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerHttpResponse,
    AppHttpResponsesTenantsTenantHintHttpResponse,
    AppHttpResponsesChatsChatMessageHttpResponse,
    AppHttpResponsesChatsChatUserHttpResponse,
    AppHttpResponsesChatsChatBlockedUserHttpResponse,
    AppHttpResponsesChatsChatGhostedUserHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserUpdateHttpResponse
} from '../../api';

// Users Events
export interface AppEventsUsersPlayerUpdated {
    playerHttpResponse: AppHttpResponsesUsersPlayerHttpResponse;
}

export interface AppEventsUsersUserSettingUpdated {
    userSettingHttpResponse: AppHttpResponsesUsersUserSettingHttpResponse;
}

export interface AppEventsUsersUserWalletChanged {
    userWalletHttpResponse: AppHttpResponsesUsersUserWalletHttpResponse;
}

// Betsnaps Events
export interface AppEventsBetsnapsGamesUserJoinedGame {
    gameHttpResponse: AppHttpResponsesBetsnapsGamesGameHttpResponse;
    gameUserHttpResponse: AppHttpResponsesBetsnapsGamesGameUserHttpResponse;
    calculatedPrizeHttpResponses: AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeHttpResponse[];
}

export interface AppEventsBetsnapsGamesUserLeftGame {
    gameHttpResponse: AppHttpResponsesBetsnapsGamesGameHttpResponse;
    playerPublicHttpResponse: AppHttpResponsesUsersPlayerPublicHttpResponse;
    calculatedPrizeHttpResponses: AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeHttpResponse[];
}

export interface AppEventsBetsnapsFeedOddsChangeReceived {
    matchId: number;
    matchUpdateHttpResponse: AppHttpResponsesSportsDataMatchUpdateHttpResponse;
    matchMarketUpdateHttpResponses: AppHttpResponsesSportsDataMatchMarketUpdateHttpResponse[];
}

export interface AppEventsBetsnapsFeedBetStopReceived {
    matchId: number;
}

export interface AppEventsBetsnapsFeedMarketSettlementReceived {
    matchId: number;
    matchMarketHttpResponses: AppHttpResponsesSportsDataMatchMarketHttpResponse[];
}

export interface AppEventsBetsnapsFeedRollbackMarketSettlementReceived {
    matchId: number;
    matchMarketHttpResponses: AppHttpResponsesSportsDataMatchMarketHttpResponse[];
}

export interface AppEventsBetsnapsFeedBetSettlementReceived {
    matchId: number;
    matchMarketHttpResponses: AppHttpResponsesSportsDataMatchMarketHttpResponse[];
}

export interface AppEventsBetsnapsFeedRollbackBetSettlementReceived {
    matchId: number;
    matchMarketHttpResponses: AppHttpResponsesSportsDataMatchMarketHttpResponse[];
}

export interface AppEventsBetsnapsFeedBetCancelReceived {
    matchId: number;
    matchMarketHttpResponses: AppHttpResponsesSportsDataMatchMarketHttpResponse[];
}

export interface AppEventsBetsnapsFeedRollbackBetCancelReceived {
    matchId: number;
    matchMarketHttpResponses: AppHttpResponsesSportsDataMatchMarketHttpResponse[];
}

export interface AppEventsBetsnapsFeedFixtureChangeProcessed {
    matchId: number;
    matchHttpResponse: AppHttpResponsesSportsDataMatchHttpResponse;
}

export interface AppEventsBetsnapsFeedFeedProductStateChanged {
    productId: number;
    isProductUp: number;
    isInMaintenance: number;
}

export interface AppEventsBetsnapsGamesGameStateChanged {
    gameHttpResponse: AppHttpResponsesBetsnapsGamesGameHttpResponse;
}

export interface AppEventsBetsnapsGamesGameLeaderboardTimestampChanged {
    gameUniqueId: string;
    recalculationTimestamp: string;
}

export interface AppEventsBetsnapsGamesGameLeaderboardChanged {
    gameUniqueId: string;
    gameLeaderboardUserUpdateHttpResponses: AppHttpResponsesBetsnapsGamesGameLeaderboardUserUpdateHttpResponse[];
    gameLeaderboardUserAccess: GameLeaderboardUserAccessArray;
}

interface GameLeaderboardUserAccessArray {
    [index: number]: number;
}

export interface AppEventsBetsnapsGamesAutoCashedOutAllActiveBets {
    gameHttpResponse: AppHttpResponsesBetsnapsGamesGameHttpResponse;
}

export interface AppEventsBetsnapsGamesAutoCanceledAllActiveBets {
    gameHttpResponse: AppHttpResponsesBetsnapsGamesGameHttpResponse;
}

export interface AppEventsBetsnapsGamesAutoFailedAllPendingBets {
    gameHttpResponse: AppHttpResponsesBetsnapsGamesGameHttpResponse;
    matchId?: number;
    dateLimit?: Date;
}

export interface AppEventsBetsnapsGamesAutoFailedAllPendingCashouts {
    gameHttpResponse: AppHttpResponsesBetsnapsGamesGameHttpResponse;
    matchId?: number;
    dateLimit?: Date;
}

export interface AppEventsBetsnapsGamesGameMatchCancelled {
    gameMatchHttpResponse: AppHttpResponsesBetsnapsGamesGameMatchHttpResponse;
}

export interface AppEventsBetsnapsGamesUserPlacedBet {
    userBetHttpResponse: AppHttpResponsesBetsnapsGamesUserBetHttpResponse;
}

export interface AppEventsBetsnapsGamesBetConfirmed {
    userBetHttpResponse: AppHttpResponsesBetsnapsGamesUserBetHttpResponse;
}

export interface AppEventsBetsnapsGamesBetFailed {
    userBetHttpResponse: AppHttpResponsesBetsnapsGamesUserBetHttpResponse;
    errorCode: string;
}

export interface AppEventsBetsnapsGamesCashoutConfirmed {
    userBetHttpResponse: AppHttpResponsesBetsnapsGamesUserBetHttpResponse;
}

export interface AppEventsBetsnapsGamesCashoutFailed {
    userBetHttpResponse: AppHttpResponsesBetsnapsGamesUserBetHttpResponse;
    errorCode: string;
}

export interface AppEventsBetsnapsGamesUserCashedOutBet {
    userBetHttpResponse: AppHttpResponsesBetsnapsGamesUserBetHttpResponse;
}

// Friends Events
export interface AppEventsFriendsFriendAdded {
    friendHttpResponse: AppHttpResponsesFriendsFriendHttpResponse;
}

export interface AppEventsFriendsFriendRemoved {
    friendHttpResponse: AppHttpResponsesFriendsFriendHttpResponse;
}

export interface AppEventsFriendsFriendInvitationSent {
    friendInvitationHttpResponse: AppHttpResponsesFriendsFriendInvitationHttpResponse;
}

export interface AppEventsFriendsFriendInvitationConfirmed {
    friendInvitationHttpResponse: AppHttpResponsesFriendsFriendInvitationHttpResponse;
}

export interface AppEventsFriendsFriendInvitationDeclined {
    friendInvitationHttpResponse: AppHttpResponsesFriendsFriendInvitationHttpResponse;
}

export interface AppEventsFriendsFriendInvitationDeleted {
    friendInvitationHttpResponse: AppHttpResponsesFriendsFriendInvitationHttpResponse;
}

export interface AppEventsFriendsFriendInvitationReceived {
    friendInvitationHttpResponse: AppHttpResponsesFriendsFriendInvitationHttpResponse;
}

export interface AppEventsFriendsFriendGroupCreated {
    friendGroupHttpResponse: AppHttpResponsesFriendsFriendGroupHttpResponse;
}

export interface AppEventsFriendsFriendGroupUpdated {
    friendGroupHttpResponse: AppHttpResponsesFriendsFriendGroupHttpResponse;
}

export interface AppEventsFriendsFriendGroupDeleted {
    friendGroupHttpResponse: AppHttpResponsesFriendsFriendGroupHttpResponse;
}

// Notification Events
export interface AppEventsNotificationsUserNotification {
    userNotificationHttpResponse: AppHttpResponsesNotificationsUserNotificationHttpResponse;
}

export interface AppEventsNotificationsUserNotificationNow {
    userNotificationHttpResponse: AppHttpResponsesNotificationsUserNotificationHttpResponse;
}

export interface AppEventsNotificationsUserNotificationUpdated {
    userNotificationHttpResponse: AppHttpResponsesNotificationsUserNotificationHttpResponse;
}

// Marketing Events
export interface AppEventsMarketingPartnerUserPermissionsRequest {
    marketingPartnerHttpResponses: AppHttpResponsesMarketingMarketingPartnerHttpResponse[];
}

// Hint Events
export interface AppEventsHintsUserHintListUpdated {
    userId: number;
    tenantHintHttpResponses: AppHttpResponsesTenantsTenantHintHttpResponse[];
}

// Chat Events
export interface AppEventsChatsChatMessageCreated {
    chatMessageHttpResponse: AppHttpResponsesChatsChatMessageHttpResponse;
}

export interface AppEventsChatsChatGhostedUserMessageCreated {
    chatMessageHttpResponse: AppHttpResponsesChatsChatMessageHttpResponse;
}

export interface AppEventsChatsChatUserUpdated {
    chatUserHttpResponse: AppHttpResponsesChatsChatUserHttpResponse;
}

export interface AppEventsChatsChatUserBlocked {
    chatBlockedUserHttpResponse: AppHttpResponsesChatsChatBlockedUserHttpResponse;
}

export interface AppEventsChatsChatUserGhosted {
    chatGhostedUserHttpResponse: AppHttpResponsesChatsChatGhostedUserHttpResponse;
}

// Vendor Games

export interface AppEventsVendorGamesGameVendorGameStateChanged {
    gameVendorGameHttpResponse: AppHttpResponsesVendorGamesGameVendorGameHttpResponse;
}

export interface AppEventsVendorGamesGameVendorGameParticipationStateChanged {
    gameUniqueId: string;
    vendorGameId: number;
    gameVendorGameParticipationId: number;
    gameVendorGameParticipationHttpResponse: AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse;
}

export interface AppEventsVendorGamesGameVendorGameParticipationTransactionStateChanged {
    gameUniqueId: string;
    vendorGameId: number;
    gameVendorGameParticipationId: number;
    gameVendorGameParticipationHttpResponse: AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse;
    gameVendorGameParticipationTransactionHttpResponse: AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse;
}

export interface AppEventsVendorGamesClosedGameVendorGameParticipations {
    gameUniqueId: string;
    vendorGameId?: number;
}

export interface AppEventsVendorGamesCancelledGameVendorGameParticipations {
    gameUniqueId: string;
    vendorGameId?: number;
}

export interface AppEventsVendorGamesCancelledGameVendorGameParticipationTransactions {
    gameUniqueId: string;
    vendorGameId?: number;
}
