<div class="ui fluid card" *ngIf="currentUser" [class.mobile-stretch-bottom-card]="!showSpinner">
    <div class="content">
        <div class="ui active centered inline text loader" *ngIf="showSpinner">
            {{'GENERAL.loadertext' | translate}}
        </div>

        <form class="ui form v3" novalidate [formGroup]="settingsForm" (ngSubmit)="onSubmit()"
              [class.success]="submitted && !processForm"
              [class.error]="settingsForm.invalid && submitted && !processForm" *ngIf="!showSpinner">

            <div class="field" *ngIf="languages">
                <label for="user_language_id">{{'PROFILE.SETTINGS.LANGUAGE.heading' | translate}}</label>
                <select id="user_language_id" name="user_language_id"
                        formControlName="user_language_id" class="ui dropdown">
                    <option *ngFor="let language of languages"
                            [value]="language.id">
                        {{language.name_local}}
                    </option>
                </select>
            </div>

            <div class="field">
                <label for="odd_display_format">{{'PROFILE.SETTINGS.ODD_FORMAT.heading' | translate}}</label>
                <select id="odd_display_format" name="odd_display_format"
                        formControlName="odd_display_format"
                        class="ui dropdown">
                    <option value="value" selected>
                        {{'PROFILE.SETTINGS.ODD_FORMAT.points' | translate}}
                    </option>
                    <option value="odds">
                        {{'PROFILE.SETTINGS.ODD_FORMAT.decimal' | translate}}
                    </option>
                    <option value="fractional">
                        {{'PROFILE.SETTINGS.ODD_FORMAT.fractional' | translate}}
                    </option>
                    <option value="moneyline">
                        {{'PROFILE.SETTINGS.ODD_FORMAT.moneyline' | translate}}
                    </option>
                </select>
            </div>

            <p class="text-label" *ngIf="tenantService.tenantData.configuration.outgoing_notifications">
                {{'PROFILE.SETTINGS.NOTIFICATIONS.heading' | translate}}
            </p>
            <div class="ui row divider"></div>
            <div class="field" *ngIf="showEmailNotificationsSetting && tenantService.tenantData.configuration.outgoing_notifications">
                <fui-checkbox class="toggle v3"
                              name="email_notifications"
                              id="email_notifications"
                              formControlName="email_notifications">
                    {{'PROFILE.SETTINGS.NOTIFICATIONS.email' | translate}}
                </fui-checkbox>
                <div class="ui row divider"></div>
            </div>
            <div class="field" *ngIf="showSmsNotificationsSetting && tenantService.tenantData.configuration.outgoing_notifications">
                <fui-checkbox class="toggle v3"
                              name="sms_notifications"
                              id="sms_notifications"
                              formControlName="sms_notifications">
                    {{'PROFILE.SETTINGS.NOTIFICATIONS.sms' | translate}}
                </fui-checkbox>
                <div class="ui row divider"></div>
            </div>
            <div class="field" *ngIf="showPushNotificationsSetting && tenantService.tenantData.configuration.outgoing_notifications">
                <fui-checkbox class="toggle v3"
                              name="push_notifications"
                              id="push_notifications"
                              formControlName="push_notifications">
                    {{'PROFILE.SETTINGS.NOTIFICATIONS.push' | translate}}
                </fui-checkbox>
                <div class="ui row divider"></div>
            </div>

            <div class="ui borderless vertical padded segment center aligned margin-0">
                <button class="ui large button margin-0" type="submit"
                        [class.loading]="processForm"
                        [disabled]="processForm || !settingsForm.valid">
                    {{'PROFILE.SETTINGS.save_changes_btn' | translate | uppercase}}
                </button>
            </div>
        </form>
    </div>
</div>
