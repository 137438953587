import {Inject, Pipe, PipeTransform, LOCALE_ID} from '@angular/core';
import {DecimalPipe, getLocaleNumberSymbol, NumberSymbol} from '@angular/common';

/**
 * DecimalPrizePipe
 *
 * Transforms a number into a string like the DecimalPipe with specific settings:
 * - subsequent zeros in decimal values will be shown until the defined decimalDigits number
 * - if value has no decimal digits, returned string will be a full number without decimals
 */

@Pipe({
    name: 'decimalPrize'
})
export class DecimalPrizePipe implements PipeTransform {

    protected myLocale = null;
    protected decimalPipe: DecimalPipe;

    constructor(@Inject(LOCALE_ID) public locale: string) {
        this.myLocale = locale;
        this.decimalPipe = new DecimalPipe(locale);
    }

    /**
     * @param value: The number to be formatted.
     * @param decimalDigits: Specifies the number of digits behind the decimal separator.
     *        - subsequent zeros in decimal values will be shown until the defined decimalDigits number
     *        - if value has no decimal digits returned string will be a full number without decimals
     * @param locale: A locale code for the locale format rules to use.
     *        - when not supplied, uses the value of `LOCALE_ID`
     * @return String
     */
    transform(value: number | string, decimalDigits: number, locale?: string) {
        locale = locale || this.myLocale;
        let result = this.decimalPipe.transform(value, '1.0-' + decimalDigits, locale);

        const decimalSymbol = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
        if (result !== null) {
            const resultArray = result.split(decimalSymbol);
            if (resultArray.length === 2) {
                if (resultArray[1].length < decimalDigits) {
                    for (let i = resultArray[1].length; i < decimalDigits; i++) {
                        result = result + '0';
                    }
                }
            }
        }

        return result;
    }
}
