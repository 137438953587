/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesGeneralGUIThemeHttpResponse } from './appHttpResponsesGeneralGUIThemeHttpResponse';


/**
 * TenantConfigurationHttpResponse
 */
export interface AppHttpResponsesTenantsTenantConfigurationHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Theme ID
     */
    theme_id?: number;
    theme?: AppHttpResponsesGeneralGUIThemeHttpResponse;
    /**
     * Email Theme ID
     */
    email_theme_id?: number;
    email_theme?: AppHttpResponsesGeneralGUIThemeHttpResponse;
    /**
     * Email From Name
     */
    email_from_name?: string;
    /**
     * Email From Email
     */
    email_from_email?: string;
    /**
     * Email ReplyTo Name
     */
    email_replyto_name?: string;
    /**
     * Email ReplyTo Email
     */
    email_replyto_email?: string;
    /**
     * Admin Email-Addresses
     */
    email_admin_users?: string;
    /**
     * B2C Signup Method
     */
    b2c_signup_method: string;
    /**
     * B2C Signup Method Config
     */
    b2c_signup_method_config?: string;
    /**
     * color primary
     */
    color_primary: string;
    /**
     * color background
     */
    color_background: string;
    /**
     * Use initital bet outcome
     */
    use_initial_bet_outcome: boolean;
    /**
     * Confirm join process
     */
    confirm_join_process: boolean;
    /**
     * Leave free game
     */
    leave_free_game: boolean;
    /**
     * Max bet count default
     */
    max_bet_count_default: number;
    /**
     * Cashout Odd Limit
     */
    cashout_odd_limit?: number;
    /**
     * Markets default expanded
     */
    markets_default_expanded: boolean;
    /**
     * Game spots left limit
     */
    game_spots_left_limit: number;
    /**
     * Allow join of multi game clones
     */
    allow_join_multiple_game_clones: boolean;
    /**
     * Allow prizes without valid participation
     */
    allow_prizes_without_valid_participation: boolean;
    /**
     * Allow prizes with zero points
     */
    allow_prizes_with_zero_points: boolean;
    /**
     * Allow multi tenant games
     */
    allow_multi_tenant_games: boolean;
    /**
     * Allow H2H
     */
    allow_h2h: boolean;
    /**
     * Allow simple snap games
     */
    allow_simple_snaps: boolean;
    /**
     * Allow Battle Royal Snap
     */
    allow_battle_royal_snaps: boolean;
    /**
     * Show custom landingpage
     */
    show_custom_landingpage: boolean;
    /**
     * Show balance
     */
    show_balance: boolean;
    /**
     * Show global ranking
     */
    show_global_rankings: boolean;
    /**
     * Show create betsnaps
     */
    show_createbetsnaps: boolean;
    /**
     * Show arena filters
     */
    show_arena_filters: boolean;
    /**
     * Show match statistics widgets
     */
    show_match_statistics_widget: boolean;
    /**
     * Show game spots left
     */
    show_game_spots_left: boolean;
    /**
     * Show join success modal
     */
    show_join_success_modal?: boolean;
    /**
     * Show pre game start odd can change info
     */
    show_pre_game_start_odd_can_change_info: boolean;
    /**
     * Show consent text in username modal
     */
    show_consent_text_in_username_modal?: boolean;
    /**
     * Show cookie banner
     */
    show_cookie_banner: boolean;
    /**
     * Outgoing notifications
     */
    outgoing_notifications: boolean;
    /**
     * Send admin game-winners notification
     */
    admin_game_winners_notification: boolean;
    /**
     * Send admin global-leaderboard winners notification
     */
    admin_tenant_ranking_winners_notification: boolean;
    /**
     * Allow web push notifications
     */
    allow_web_push_notifications: boolean;
    /**
     * OneSignal APP ID for Web Push Notifications
     */
    one_signal_push_notifications_app_id?: string;
    /**
     * OneSignal API KEY for Web Push Notifications
     */
    one_signal_push_notifications_api_key?: string;
    /**
     * Public Chat enabled
     */
    chat_public_enabled: boolean;
    /**
     * Private Chat enabled
     */
    chat_private_enabled: boolean;
    /**
     * Chat Message Limit
     */
    chat_message_limit: number;
    /**
     * Chat Message Limit period in seconds
     */
    chat_message_limit_period_in_seconds: number;
    /**
     * Chat Ghosting Block Limit
     */
    chat_ghosting_block_limit: number;
    /**
     * Send no valid bets last match start notification
     */
    notification_no_valid_bets_last_match_start: boolean;
    /**
     * No valid bets last match start notification minutes after match start
     */
    notification_no_valid_bets_last_match_start_min?: number;
    /**
     * Send no valid bets game start notification
     */
    notification_no_valid_bets_game_start: boolean;
    /**
     * No valid bets game start notification minutes after game start
     */
    notification_no_valid_bets_game_start_min?: number;
    /**
     * Use External E-Mail Validation-Service
     */
    email_validation_service: boolean;
    /**
     * External E-Mail Validation-Service invalid states
     */
    email_validation_service_invalid_states?: string;
    /**
     * Is AgileCRM Sync Enabled
     */
    is_agilecrm_sync_enabled: boolean;
    /**
     * Broadcast Game Leaderboards
     */
    broadcast_game_leaderboard: boolean;
    /**
     * Publisher Widgets enabled
     */
    widgets_publisher_enabled: boolean;
    /**
     * odds / prediction based
     */
    points_engine: string;
    /**
     * Winning points for prediction based game bets
     */
    prediction_game_winning_points: number;
    /**
     * Default initial game points
     */
    default_initial_game_points?: number;
    /**
     * Default widget game points
     */
    default_widget_game_points?: number;
}

