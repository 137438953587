/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesCurrencyHttpResponse';


/**
 * CurrencyHttpResponse
 */
export interface AppHttpResponsesFinancialsAccountingInvoiceHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Invoice Number
     */
    invoice_number: string;
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Currency ID
     */
    currency_id: number;
    currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
    /**
     * Date
     */
    date: Date;
    /**
     * SUM Cash Entry Fee
     */
    sum_cash_entry_fee: number;
    /**
     * SUM Cash Refund Fee Cancel
     */
    sum_cash_refund_fee_cancel: number;
    /**
     * SUM Cash Refund Fee Leave
     */
    sum_cash_refund_fee_leave: number;
    /**
     * SUM Cash Transaction Win
     */
    sum_cash_transaction_win: number;
    /**
     * SUM Cash Rake
     */
    sum_cash_rake: number;
    /**
     * SUM Cash Rake Betsnaps
     */
    sum_cash_rake_betsnaps: number;
    /**
     * SUM Cash Rake Tenant
     */
    sum_cash_rake_tenant: number;
    /**
     * SUM Noncash Fee
     */
    sum_noncash_fee: number;
    /**
     * SUM Total
     */
    sum_total: number;
    /**
     * Games Total Cash
     */
    games_total_cash: number;
    /**
     * Games Total Noncash
     */
    games_total_noncash: number;
    /**
     * Games Total
     */
    games_total: number;
}

