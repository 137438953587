/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantB2bConnectionUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantB2bConnectionUpdateRequest { 
    /**
     * Is B2B Tenant
     */
    is_b2b_tenant: boolean;
    /**
     * Website URL - possible placeholder: {b2b_user_token}
     */
    website_url?: string;
    /**
     * Login Page URL
     */
    website_login_url?: string;
    /**
     * Register Page URL
     */
    website_register_url?: string;
    /**
     * Promo Code URL
     */
    website_promo_code_url?: string;
    /**
     * Defines if current user lang should be added to Website Login URL
     */
    website_login_url_add_lang: boolean;
    /**
     * Forward directly to Tenant-Login
     */
    website_login_direct_forwarding: boolean;
    /**
     * Currency Divisor
     */
    currency_divisor?: number;
    /**
     * Login Type
     */
    b2b_login_type?: string;
    /**
     * Service Type
     */
    b2b_service_type?: string;
    /**
     * OAuth2 Base URL
     */
    oauth2_url?: string;
    /**
     * OAuth2 Relative Token Path
     */
    oauth2_token_path?: string;
    /**
     * OAuth2 Relative Token Refresh Path
     */
    oauth2_token_refresh_path?: string;
    /**
     * OAuth2 Client ID
     */
    oauth2_client_id?: string;
    /**
     * OAuth2 Client Secret
     */
    oauth2_client_secret?: string;
    /**
     * OAuth2 Scope
     */
    oauth2_scope?: string;
    /**
     * OAuth2 Redirect URI (without closing slash)
     */
    oauth2_redirect_uri?: string;
    /**
     * OAuth2 Response Type
     */
    oauth2_response_type?: string;
    /**
     * SOAP Secret Key
     */
    soap_secretkey?: string;
    /**
     * SOAP URI
     */
    soap_uri?: string;
    /**
     * SOAP Version
     */
    soap_soap_version?: string;
    /**
     * SOAP Trace
     */
    soap_trace?: boolean;
    /**
     * Wallet Service
     */
    walletservice?: boolean;
    /**
     * Message Service
     */
    messageservice?: boolean;
    /**
     * User Service Base URL (without closing slash)
     */
    userservice_url?: string;
    /**
     * Wallet Service Base URL (without closing slash)
     */
    walletservice_url?: string;
    /**
     * Message Service Base URL (without closing slash)
     */
    messageservice_url?: string;
}

