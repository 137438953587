import {Component} from '@angular/core';

import {
    AuthenticationService, ErrorService, FriendsService, GoogleAnalyticsService, HintService, MyTranslateService,
    WindowRef
} from '../../../../../shared';
import {AFriendsListComponent} from '../a-friends-list.component';
import {
    AppHttpResponsesUsersPlayerPublicListHttpResponse as PlayerPublicListHttpResponse
} from '../../../../../api';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-friends-list-v3',
    templateUrl: './friends-list-v3.component.html',
    styles: []
})
export class FriendsListV3Component extends AFriendsListComponent {

    protected autoSubmitCount: number = 0;
    protected lastSearchString: string = '';
    public searchResultFriendsList: PlayerPublicListHttpResponse;

    constructor(public authenticationService: AuthenticationService,
                protected friendsService: FriendsService,
                protected hintService: HintService,
                protected windowRef: WindowRef,
                public translations: MyTranslateService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService) {

        super(
            authenticationService,
            friendsService,
            hintService,
            windowRef,
            translations,
            googleAnalyticsService,
            errorService
        );

    }

    submitSearch() {
        if(!this.searchForm.invalid){
            this.searchActive = true;
            this.processSearchList = true;
            this.currentPage = 1;
            this.lastSearchString = this.searchForm.value.searchstring;
            this.friendsService.searchUsers(
                1000,
                this.currentPage,
                this.searchForm.value.searchstring,
                true,
                false
            ).pipe(take(1))
            .subscribe({
                next: (playerPublicList: PlayerPublicListHttpResponse) => {
                    if (playerPublicList) {
                        this.searchResultFriendsList = playerPublicList;
                        this.friendsService.searchUsers(
                            this.perPage,
                            this.currentPage,
                            this.searchForm.value.searchstring,
                            false,
                            true
                        ).pipe(take(1))
                        .subscribe({
                            next: (playerPublicList: PlayerPublicListHttpResponse) => {
                                if (playerPublicList) {
                                    this.searchResultUsersList = playerPublicList;
                                    this.processSearchList = false;
                                }
                            },
                            error: (err: HttpErrorResponse) => {
                                this.processSearchList = false;
                                this.errorService.handleHttpErrorResponse(err);
                            }
                        });
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.processSearchList = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });

            this.googleAnalyticsService.trackEvent('friends', 'search', 'users');
        }
    }

    loadMore(): void {
        if (this.searchResultUsersList.results.length < this.searchResultUsersList.total) {
            this.processLoadMore = true;
            this.currentPage = this.currentPage + 1;
            this.friendsService.searchUsers(this.perPage, this.currentPage, this.searchForm.value.searchstring, false, true)
                .pipe(take(1))
                .subscribe({
                    next: (playerPublicList: PlayerPublicListHttpResponse) => {
                        this.searchResultUsersList.total = playerPublicList.total;
                        this.searchResultUsersList.last_page = playerPublicList.last_page;
                        this.searchResultUsersList.results.push(...playerPublicList.results);
                        this.processLoadMore = false;
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                });
        }
    }

    closeSearch() {
        super.closeSearch();
        this.searchResultFriendsList = null;
    }

    autoSubmitSearch(event: KeyboardEvent){
        this.autoSubmitCount++;
        if(event.key !== 'Enter'){
            if(this.searchForm.valid){
                const self = this;
                const currentAutoSubmitCount = this.autoSubmitCount;
                setTimeout(function(){
                    if(currentAutoSubmitCount === self.autoSubmitCount && self.lastSearchString !== self.searchForm.value.searchstring){
                        self.submitSearch();
                    }
                }, 1500);
            } else {
                if(!this.searchForm.value.searchstring){
                    this.closeSearch();
                }
            }
        }
    }

    hasFriendsOrFriendInvitations(): boolean {
        return ((this.userFriends && this.userFriends.length > 0) || this.hasFriendInvitationsIncoming || this.hasFriendInvitationsOutgoing || this.hasFriendInvitationsIncomingDeclined);
    }


}
