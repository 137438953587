import {Component, ViewChild} from '@angular/core';
import {
    AuthenticationService, FriendsService, GoogleAnalyticsService, MyModalService, MyTranslateService
} from '../../../../../shared';

import {AFriendsRequestsComponent} from '../a-friends-requests.component';
import {ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';

@Component({
    selector: 'betsnaps-friends-requests-v3',
    templateUrl: './friends-requests-v3.component.html',
    styles: []
})
export class FriendsRequestsV3Component extends AFriendsRequestsComponent {

    @ViewChild('declinedIncomingModalTemplate', { static: true }) public declinedIncomingModalTemplate: ModalTemplate<null, string, string>;

    constructor(public authenticationService: AuthenticationService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected friendsService: FriendsService,
                protected myModalService: MyModalService,
                public translations: MyTranslateService) {
        super(
            authenticationService,
            googleAnalyticsService,
            friendsService
        );
    }

    openDeclinedIncomingModal() {
        if(this.userFriendInvitationsIncomingDeclined?.length > 0){
            const modalConfig = new TemplateModalConfig<null, string, string>(this.declinedIncomingModalTemplate);
            modalConfig.size = 'tiny';
            modalConfig.mustScroll = true;
            this.declinedIncomingModal = this.myModalService.openModal(modalConfig)
                .onApprove(() => {
                    this.declinedIncomingModal = null;
                }).onDeny(() => {
                    this.declinedIncomingModal = null;
            });
            this.googleAnalyticsService.trackEvent('friends', 'show', 'declined friend invitations');
        }
    }
}
