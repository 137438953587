/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesTenantsTenantFooterTranslationHttpResponse } from './appHttpResponsesTenantsTenantFooterTranslationHttpResponse';


/**
 * TenantFooterHttpResponse
 */
export interface AppHttpResponsesTenantsTenantFooterHttpResponse { 
    /**
     * Footer ID
     */
    id: number;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Label of the Footer Entry
     */
    label: string;
    /**
     * Display-Order
     */
    display_order: number;
    /**
     * Icon Class
     */
    icon_class?: string;
    /**
     * Defined Landing-Page of Footer-Link
     */
    landing_page_url?: string;
    /**
     * Is defined Landing-Page an external or internal link
     */
    is_landing_page_external?: boolean;
    /**
     * Is a fixed item (not deletable and partially editable)
     */
    is_fixed?: boolean;
    /**
     * Show Footer Entry in App
     */
    show_in_app: boolean;
    /**
     * Translation
     */
    translations?: Array<AppHttpResponsesTenantsTenantFooterTranslationHttpResponse>;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
}

