/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantNotificationSettingsUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantNotificationSettingsUpdateRequest { 
    /**
     * Send Emails to players
     */
    outgoing_notifications?: boolean;
    /**
     * Sender EMail Address
     */
    email_from_email?: string;
    /**
     * EMail Sender Name
     */
    email_from_name?: string;
    /**
     * Reply To EMail Address
     */
    email_replyto_email?: string;
    /**
     * Reply To Sender Name
     */
    email_replyto_name?: string;
    /**
     * Send Push Notifications to players
     */
    allow_web_push_notifications?: boolean;
    /**
     * OneSignal App ID
     */
    one_signal_push_notifications_app_id?: string;
    /**
     * OneSignal API Key
     */
    one_signal_push_notifications_api_key?: string;
}

