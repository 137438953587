/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ReportGameUserBetHttpResponse
 */
export interface AppHttpResponsesReportsReportGameUserBetHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * User ID
     */
    user_id: number;
    /**
     * User B2B ID
     */
    user_b2b_user_id?: string;
    /**
     * Bet Placement Date
     */
    bet_placement_at: Date;
    /**
     * Bet Placement Game State
     */
    bet_placement_game_state?: string;
    /**
     * Bet Placement Match State
     */
    bet_placement_match_state?: string;
    /**
     * Bet Placement Odd
     */
    bet_placement_odd: number;
    /**
     * Bet Placement Points
     */
    bet_placement_points: number;
    /**
     * Points Earned
     */
    points_earned: number;
    /**
     * Status
     */
    status: string;
    /**
     * Market Name
     */
    market_name: string;
    /**
     * Market Specifier
     */
    market_specifier?: string;
    /**
     * Outcome Name
     */
    outcome_name: string;
    /**
     * Outcome Odd
     */
    outcome_odd: number;
    /**
     * Match Competitors
     */
    match: string;
}

