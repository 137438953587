<div class="ui fluid flexauto item tiny separatorless menu">
    <a href="#" class="item" [class.active]="menu.active" *ngFor="let menu of gameVendorGameParticipationsMenu" (click)="$event.preventDefault(); gameVendorGameParticipationsMenuChange(menu.key);">
            <span class="display-inline-block vertical-align-middle">
                {{menu.translatekey | translate | uppercase}}
            </span><span class="display-inline-block vertical-align-middle text-mini">&nbsp;({{menu.count}})</span>
    </a>
</div>
<div class="ui basic segment" *ngIf="processGameVendorGameParticipations || !gameVendorGameParticipations">
    <div class="ui active centered inline text loader on-body-bg">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processGameVendorGameParticipations && gameVendorGameParticipations">
    <div *ngIf="filteredGameVendorGameParticipations?.length > 0">
            <betsnaps-game-vendor-game-participation-card-default
                class="ui fluid card game-vendor-game-participation-card"
                *ngFor="let gameVendorGameParticipation of filteredGameVendorGameParticipations; trackBy: gameVendorGameParticipationsTrackBy; let participationIndexInList = index"
                [game]="game"
                [gameVendorGameParticipation]="gameVendorGameParticipation"
                [gameVendorGameParticipationUpdatedDate]="gameVendorGameParticipation.updated_at"
                [participationIndexInList]="participationIndexInList"
                [adPositions]="adPositions">
            </betsnaps-game-vendor-game-participation-card-default>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="filteredGameVendorGameParticipations?.length < 1">
        <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
