import {Directive, OnDestroy} from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as UserBetHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse as UserBetPlaceBetBatchResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse as UserBetPlaceBetReportHttpResponse
} from '../../../../api';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService,
    MyModalService, MyNotificationsService,
    OneSignalService, TenantImageTypeEnum,
    TenantService,
    WidgetBet
} from '../../../../shared';
import {btoaUnicode} from '../../../../shared/helpers';
import {take, takeWhile} from 'rxjs/operators';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Directive()
export abstract class ABetsnapdetailBetsComponent implements OnDestroy {

    public adPositions: number[] = [];

    protected componentAlive = true;

    public loginModalImage: string;

    public game: GameHttpResponse;
    public gameMatches: GameMatchHttpResponse[];
    public filteredGameMatches: GameMatchHttpResponse[];

    public userBets: UserBetHttpResponse[][];
    public processBets: boolean = true;
    public filteredBetsTotal: number = 0;

    public widgetBets: WidgetBet[];
    public processWidgetBets: boolean = false;

    public gameNotificationsForm: UntypedFormGroup = new UntypedFormGroup({
        game_notifications_not_joined: new UntypedFormControl('1')
    });
    public showGameNotificationsCheckbox: boolean = false;

    public placeWidgetBetsAfterJoin: boolean = false;

    constructor(public authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected betsnapdetailService: BetsnapdetailService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected myModalService: MyModalService,
                protected oneSignalService: OneSignalService,
                protected errorService: ErrorService,
                protected adService: AdService) {

        // set login widget image
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE)) {
            this.loginModalImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE).media_url;
        } else {
            this.loginModalImage = './assets/img/i-login-circle.png';
        }

        this.betsnapdetailService.gameMatches$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameMatchListResults: GameMatchHttpResponse[]) => {
                    if (gameMatchListResults) {
                        if (!this.adPositions || (Array.isArray(this.adPositions) && this.adPositions.length === 0)) {
                            this.adPositions = this.adService.defineAdPosition('game-content', gameMatchListResults.length);
                        }
                    }
                }
            );
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    activeGameMatches() {
        return this.filteredGameMatches.filter((gameMatch: GameMatchHttpResponse) =>
            gameMatch.match.status !== 'closed' && gameMatch.match.status !== 'ended' &&
            gameMatch.match.status !== 'cancelled' && gameMatch.match.status !== 'postponed' &&
            !gameMatch.is_cancel);
    }

    closedGameMatches() {
        return this.filteredGameMatches.filter((gameMatch: GameMatchHttpResponse) =>
            (gameMatch.match.status === 'closed' || gameMatch.match.status === 'ended' ||
                gameMatch.match.status === 'cancelled' || gameMatch.match.status === 'postponed' ||
                gameMatch.is_cancel));
    }

    matchesTrackBy(index: number, gameMatch: GameMatchHttpResponse): number {
        return gameMatch.match_id;
    }

    public initiatePlaceWidgetBets() {
        if (this.widgetBets && this.widgetBets.length > 0 && (!this.processWidgetBets || this.placeWidgetBetsAfterJoin)) {
            if (!this.authenticationService.currentUser) {
                const loginRegisterModal = this.myModalService.openLoginRegisterModal('login', null, true, 'widgetbet');
                if (loginRegisterModal) {
                    loginRegisterModal.onApprove(() => {
                        localStorage.setItem('widgetBets-force', 'true');
                        const gameUniqueId = this.game.game_unique_id;
                        this.game = null;
                        this.betsnapdetailService.getGameData(gameUniqueId);
                    });
                }
            } else if (!this.authenticationService.currentUser.username || !this.authenticationService.currentUser.is_username_confirmed) {
                this.myModalService.openUsernameInputModal().onApprove(() => this.initiatePlaceWidgetBets());
            } else {

                this.processWidgetBets = true;
                this.placeWidgetBetsAfterJoin = false;

                if (!this.game.is_current_user_joined) {

                    const subscribeForGameNotifications = (
                        this.authenticationService.currentUser && this.game &&
                        this.showGameNotificationsCheckbox &&
                        this.gameNotificationsForm &&
                        this.gameNotificationsForm.value.game_notifications_not_joined &&
                        this.oneSignalService.webPushAvailableForTenant
                    );

                    this.betsnapdetailService.joinGame(this.game, this.authenticationService.currentUser.id, subscribeForGameNotifications)
                        .pipe(take(1))
                        .subscribe({
                            next: (leaderboardUserHttpResponse: LeaderboardUserHttpResponse) => {
                                this.placeWidgetBetsAfterJoin = true;

                                if (subscribeForGameNotifications) {
                                    this.oneSignalService.subscribeForWebPushNotifications();
                                }

                                this.translateService.get([
                                    'GENERAL.GAMES.join-modal_heading',
                                    'GENERAL.GAMES.join-modal_firsttextpart',
                                    'GENERAL.GAMES.join-modal_secondtextpart'
                                ])
                                    .pipe(takeWhile(() => this.componentAlive))
                                    .subscribe(
                                        translation => {
                                            this.myNotificationsService.createNotificationToast(
                                                translation['GENERAL.GAMES.join-modal_heading'],
                                                translation['GENERAL.GAMES.join-modal_firsttextpart'] + ' ' + translation['GENERAL.GAMES.join-modal_secondtextpart'],
                                                NotificationType.Success
                                            );
                                        });

                            },
                            error: (err: HttpErrorResponse) => {
                                this.placeWidgetBetsAfterJoin = false;
                                this.processWidgetBets = false;
                                this.errorService.handleHttpErrorResponse(err);
                            }
                        });

                } else if (this.game.is_current_user_joined) {
                    if (this.game.max_bet_count > 0 && this.game.current_user_bet_count >= this.game.max_bet_count) {
                        this.alertUserNotification('GENERAL.BETS.ERRORS.max_bet_count_reached');
                        this.placeWidgetBetsAfterJoin = false;
                        this.processWidgetBets = false;
                    } else if (this.game.game_user.user_game_points === 0) {
                        this.alertUserNotification('GENERAL.BETS.ERRORS.insufficient_points');
                        this.placeWidgetBetsAfterJoin = false;
                        this.processWidgetBets = false;
                    } else {
                        this.placeWidgetBets();
                    }
                }
            }
        }
    }

    protected placeWidgetBets() {
        const totalWidgetBets = this.widgetBets.length;
        let processedWidgetBets: number = 0;

        let gamePointsToPlace = 0;
        this.widgetBets.forEach((widgetBet: WidgetBet) => {
            gamePointsToPlace += widgetBet.points;
        });

        this.betsnapdetailService.substractFromUserGamePoints(gamePointsToPlace);
        this.betsnapdetailService.addPlaceBetDialogPendingBet(totalWidgetBets);

        this.betsnapdetailService.placeBetBatch(this.widgetBets, this.authenticationService.currentUser.id)
            .pipe(take(1))
            .subscribe({
                next: (userBetPlaceBetBatchResponse: UserBetPlaceBetBatchResponse) => {
                    processedWidgetBets = userBetPlaceBetBatchResponse.report.length;

                    // process failed bets
                    const userBetPlaceBetFailed = userBetPlaceBetBatchResponse.report.filter((userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) => userBetPlaceBetReport.bet_place_status === 'error');
                    if (userBetPlaceBetFailed && userBetPlaceBetFailed.length > 0) {
                        this.betsnapdetailService.reduceUserBetCount(userBetPlaceBetFailed.length);

                        let gamePointsToReturn = 0;
                        userBetPlaceBetFailed.forEach((userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) => {
                            gamePointsToReturn += userBetPlaceBetReport.points;

                            // if odd difference error remove widgetbet from array
                            if (userBetPlaceBetReport.bet_place_error_code && userBetPlaceBetReport.bet_place_error_code === 'odd_bet_value_not_allowed_difference') {
                                this.removeProcessedWidgetBetFromArray(userBetPlaceBetReport);
                            }
                        });
                        this.betsnapdetailService.addToUserGamePoints(gamePointsToReturn);
                    }

                    // process placed bets
                    const userBetPlaceBetPlaced = userBetPlaceBetBatchResponse.report.filter((userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) => userBetPlaceBetReport.bet_place_status === 'ok');
                    if (userBetPlaceBetPlaced && userBetPlaceBetPlaced.length > 0) {
                        userBetPlaceBetPlaced.forEach((userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) => {
                            this.googleAnalyticsService.trackEvent(
                                'widgetbet',
                                'place',
                                userBetPlaceBetReport.market_id + '-' + userBetPlaceBetReport.outcome_id + '-' + userBetPlaceBetReport.specifier
                            );
                            this.removeProcessedWidgetBetFromArray(userBetPlaceBetReport);
                        });
                    }

                    this.betsnapdetailService.removePlaceBetDialogPendingBet(processedWidgetBets);
                    this.finishWidgetBetProcess();
                },
                error: (err: HttpErrorResponse) => {
                    this.errorService.handleHttpErrorResponse(err);

                    // On error no bet was placed
                    this.betsnapdetailService.removePlaceBetDialogPendingBet(totalWidgetBets);
                    this.betsnapdetailService.reduceUserBetCount(totalWidgetBets);
                    this.betsnapdetailService.addToUserGamePoints(gamePointsToPlace);

                    this.finishWidgetBetProcess();
                }
            });
    }

    protected finishWidgetBetProcess(): void {
        // save failed bets to local storage
        if (this.widgetBets.length < 1) {
            this.removeWidgetBets();
        } else {
            localStorage.setItem('widgetBets-' + this.game.game_unique_id, btoaUnicode(JSON.stringify(this.widgetBets)));
        }

        if (localStorage.getItem('widgetBets-force')) {
            localStorage.removeItem('widgetBets-force');
        }

        this.processWidgetBets = false;
    }

    protected abstract removeProcessedWidgetBetFromArray(userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse);

    protected abstract removeWidgetBets();

    alertUserNotification(key: string) {
        this.translateService.get(['GENERAL.BETS.ERRORS.bet_not_possible_title', key])
            .pipe(take(1)).subscribe(
            translation => {
                this.myNotificationsService.createNotificationToast(translation['GENERAL.BETS.ERRORS.bet_not_possible_title'], translation[key], NotificationType.Error);
            });
    }

    removeItemFromWidgetBets(userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse): void {
        const index = this.widgetBets.findIndex((widgetBet: WidgetBet) =>
            widgetBet.outcome.match_id === userBetPlaceBetReport.match_id &&
            widgetBet.outcome.market_id === userBetPlaceBetReport.market_id &&
            widgetBet.outcome.specifier_val === userBetPlaceBetReport.specifier &&
            widgetBet.outcome.odd_decimal === userBetPlaceBetReport.odd &&
            widgetBet.points === userBetPlaceBetReport.points
        );
        if (index > -1) {
            this.widgetBets.splice(index, 1);
        }
    }
}
