import {ComponentModalConfig, ModalSize} from '@aligorji/ngx-fomantic-ui';
import {LoginRegisterModalComponent} from '../../ui/components/login-register-modal/login-register-modal.component';

interface ILoginRegisterModalContext {
    viewToShow: string;
}

export class LoginRegisterModal extends ComponentModalConfig<ILoginRegisterModalContext, void, void> {
    constructor(viewToShow: string = 'login') {
        super(LoginRegisterModalComponent, { viewToShow });

        this.isClosable = true;
        this.transitionDuration = 200;
        this.size = ModalSize.Tiny;
        this.mustScroll = true;
    }
}
