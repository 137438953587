/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantB2bPublicHttpResponse
 */
export interface AppHttpResponsesTenantsTenantB2bPublicHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * website url
     */
    website_url?: string;
    /**
     * website login url
     */
    website_login_url?: string;
    /**
     * Defines if current user lang should be added to Website Login URL
     */
    website_login_url_add_lang: boolean;
    /**
     * website login direct forwarding
     */
    website_login_direct_forwarding: boolean;
    /**
     * website register url
     */
    website_register_url?: string;
    /**
     * website promo code url
     */
    website_promo_code_url?: string;
    /**
     * B2b Login Type, Possible: OAUTH2 or RGS
     */
    b2b_login_type?: string;
}

