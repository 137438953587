/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantPrizeTemplateUpdateRequest
 */
export interface AppHttpRequestsBetsnapsPrizeStructuresTenantPrizeTemplateUpdateRequest { 
    /**
     * Tenant Prize Template Title
     */
    title: string;
    /**
     * Tenant Prize Template Payload
     */
    payload?: string;
    /**
     * Is Pooling Prize
     */
    is_pooling_prize: boolean;
    /**
     * Constains Unique Prizes
     */
    contains_unique_prizes: boolean;
    /**
     * Self Service Prize Pickup
     */
    self_service_prize_pickup: boolean;
    /**
     * CTA Link URL
     */
    cta_link_url?: string;
}

