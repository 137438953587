import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService, TenantService} from '../../../shared';
import {LandingpageFallbackComponent} from './landingpage-fallback/landingpage-fallback.component';
import {LandingpageDefaultComponent} from './landingpage-default/landingpage-default.component';
import {LandingpageDefaultPublisherWidgetComponent} from './landingpage-default-publisher-widget/landingpage-default-publisher-widget.component';
import {LandingpageBetfoxComponent} from './landingpage-betfox/landingpage-betfox.component';
import {LandingpageBwinComponent} from './landingpage-bwin/landingpage-bwin.component';
import {LandingpageBwinPremiumComponent} from './landingpage-bwin-premium/landingpage-bwin-premium.component';
import {LandingpageEsoccerstarComponent} from './landingpage-esoccerstar/landingpage-esoccerstar.component';
import {LandingpageBwinBelgiumComponent} from './landingpage-bwin-belgium/landingpage-bwin-belgium.component';
import {LandingpageBwinGreeceComponent} from './landingpage-bwin-greece/landingpage-bwin-greece.component';
import {LandingpageBwinCanadaComponent} from './landingpage-bwin-canada/landingpage-bwin-canada.component';
import {LandingpageFcCologneComponent} from './landingpage-fccologne/landingpage-fccologne.component';
import {LandingpageBwinGermanyComponent} from './landingpage-bwin-germany/landingpage-bwin-germany.component';
import {take} from 'rxjs/operators';
import {
    AppHttpResponsesTenantsTenantLandingpageHttpResponse as TenantLandingpageHttpResponse,
    TenantsApi
} from '../../../api';

import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-home',
    templateUrl: './home.component.html',
    styles: []
})
export class HomeComponent implements OnInit, OnDestroy {

    public landingpageThemeName: string = 'fallback';
    public loading: boolean = true;

    private componentMap = {
        'fallback': LandingpageFallbackComponent,
        'default': LandingpageDefaultComponent,
        'default-publisher-widget': LandingpageDefaultPublisherWidgetComponent,
        'betfox': LandingpageBetfoxComponent,
        'bwin': LandingpageBwinComponent,
        'bwin-premium': LandingpageBwinPremiumComponent,
        'bwin-germany': LandingpageBwinGermanyComponent,
        'bwin-belgium': LandingpageBwinBelgiumComponent,
        'bwin-greece': LandingpageBwinGreeceComponent,
        'bwin-canada': LandingpageBwinCanadaComponent,
        'esoccerstar': LandingpageEsoccerstarComponent,
        'fccologne': LandingpageFcCologneComponent
    };

    private componentRef;

    constructor(private authenticationService: AuthenticationService,
                public tenantService: TenantService,
                private tenantsApi: TenantsApi,
                public router: Router,
                private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver) {


    }

    ngOnInit(): void {

        if (this.authenticationService.validToken()) {
            this.router.navigate(['/arena']);
            return;
        }

        if (!this.componentRef) {
            if (this.tenantService.tenantData.configuration.show_custom_landingpage) {
                this.tenantsApi.apiTenantsTenantIdLandingpageGet(
                    this.tenantService.tenantData.id
                )
                .pipe(take(1))
                .subscribe({
                    next: (tenantLandingPage: TenantLandingpageHttpResponse) => {
                        if (tenantLandingPage) {
                            this.landingpageThemeName = 'default';
                            if ('theme' in tenantLandingPage
                                && (tenantLandingPage?.theme)
                                && (tenantLandingPage?.theme.name in this.componentMap)) {
                                this.landingpageThemeName = tenantLandingPage.theme.name;
                            }
                            this.createLandingpageComponent(tenantLandingPage);
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        // Fallback Landingpage
                        this.createLandingpageComponent();
                    }
                });
            } else {
                // Fallback Landingpage
                this.createLandingpageComponent();
            }
        }
    }

    createLandingpageComponent(tenantLandingPage: TenantLandingpageHttpResponse = null): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentMap[this.landingpageThemeName]);
        this.viewContainerRef.clear();
        // ToDo: Typehint createComponent<className>() if we have an abstract Landingpage class (afterward remove ts-ignore)
        const componentRef = this.viewContainerRef.createComponent(componentFactory);
        if (tenantLandingPage) {
            // @ts-ignore
            componentRef.instance.tenantLandingpage = tenantLandingPage;
        }
        this.componentRef = componentRef;
        this.loading = false;
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

}
