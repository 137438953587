<div class="place-bet-dialog-inner" #placeBetContent
     id="placeBetDialog-{{outcome.market_id + '-' + outcome.outcome_id + '-' + outcome.specifier_val}}">
    <i class="close link icon margin-0"
       (click)="
            googleAnalyticsService.trackEvent('matchcard - bet', 'hide', outcome.market_id + '-' + outcome.outcome_id + '-' + outcome.specifier_val);
            closeDialog();
        "></i>
    <div class="ui middle aligned centered tight grid margin-t-0 margin-b-0">
        <div class="bottom aligned row padding-0">
            <div class="three wide center aligned column">
                <button
                    [class.disabled]="betPoints <= 0 || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
                    [disabled]="placeBetProcess"
                    type="button" class="ui basic lightgrey2 fluid large icon button margin-0 padding-l-0 padding-r-0"
                    (click)="
                        googleAnalyticsService.trackEvent('matchcard - bet', 'click', '-100');
                        decreaseBetPoints(100);
                    ">
                    <i class="fa fa-minus icon"></i>
                </button>
            </div>
            <div class="six wide center aligned column">
                <div class="margin-b-5">
                    {{'GENERAL.BETS.amount' | translate}}
                </div>
                <div class="ui large fluid input"
                     [class.disabled]="placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1">
                    <input type="text" inputmode="decimal" id="betpoints" class="center aligned font-bold" name="betpoints" #betPointsInput
                           (focus)="inputOnFocus()"
                           (blur)="inputOnBlur()"
                           [ngModel]="betPoints | number:'1.2-2'"
                           [formControl]="betPointsControl"
                           [autocomplete]="'off'"
                           required/>
                </div>
            </div>
            <div class="three wide center aligned column">
                <button
                    [class.disabled]="freePoints === betPoints || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
                    [disabled]="placeBetProcess"
                    type="button" class="ui basic lightgrey2 fluid large icon button margin-0 padding-l-0 padding-r-0"
                    (click)="
                        googleAnalyticsService.trackEvent('matchcard - bet', 'click', '+100');
                        increaseBetPoints(100);
                    ">
                    <i class="fa fa-plus icon"></i>
                </button>
            </div>
            <div class="four wide center aligned column">
                <button
                    [class.disabled]="freePoints === betPoints || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
                    [disabled]="placeBetProcess"
                    type="button" class="ui basic lightgrey2 fluid large button font-bold margin-0 padding-l-0 padding-r-0"
                    (click)="
                            googleAnalyticsService.trackEvent('matchcard - bet', 'click', 'ALL-IN');
                            allIn();
                        ">
                    {{'GENERAL.BETS.all_in' | translate | uppercase}}
                </button>
            </div>
        </div>
    </div>
    <div class="margin-t-10">
        <p class="center aligned color-negative"
           *ngIf="betPoints < 0.01">
            {{'GENERAL.BETS.ERRORS.invalid_bet_points' | translate}}
        </p>
        <button
            [class.disabled]="freePoints === 0 || betPoints < 0.01 || placeBetProcess || market.market_status !== 1 || outcome.outcome_status !== 1"
            [class.loading]="placeBetProcess" [disabled]="placeBetProcess"
            type="button" class="ui fluid tiny button line-height-standard padding-5 margin-0" (click)="placeBet()">
            <strong class="text-large">{{'GENERAL.BETS.possible-win' | translate}}: {{(betPoints * outcome.odd_decimal) | number:'1.2-2'}}</strong><br />
            <span *ngIf="game.max_bet_count === 0">
                {{'GENERAL.BETS.place-bet' | translate | uppercase}}
            </span>
            <span *ngIf="game.max_bet_count > 0">
                {{'GENERAL.BETS.place-bet-number-of-total' | translate:{
                        bet_number: ((game.current_user_bet_count + 1) | ordinalNumber),
                        max_bet_count: game.max_bet_count
                    } | uppercase
                }}
            </span>
        </button>
        <div *ngIf="cashoutOddLimitViolation() && cashoutAvailable()" class="text-mini center aligned margin-t-5 color-negative">
            {{'GENERAL.BETS.cashout_odd_limit_error_notice' | translate}}
        </div>
        <div *ngIf="preGameStartOddsCanChange" class="text-mini center aligned margin-t-5">
            {{'GENERAL.BETS.pre_game_start_odds_can_change' | translate}}
        </div>
    </div>
</div>
