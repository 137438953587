import {ComponentRef, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {HomeComponent} from '../../ui/views/home/home.component';
import {LoginComponent} from '../../ui/views/login/login.component';
import {B2bLoginComponent} from '../../ui/views/login/b2b-login.component';
import {BetsnapdetailPlayersComponent} from '../../ui/views/betsnapdetail/betsnapdetail-players/betsnapdetail-players.component';
import {BetsnapdetailLoaderComponent} from '../../ui/views/betsnapdetail/betsnapdetail-loader.component';
import {BetsnapdetailDetailsLoaderComponent} from '../../ui/views/betsnapdetail/betsnapdetail-details/betsnapdetail-details-loader.component';
import {BetsnapdetailMatchesLoaderComponent} from '../../ui/views/betsnapdetail/betsnapdetail-matches/betsnapdetail-matches-loader.component';
import {BetsnapdetailBetsLoaderComponent} from '../../ui/views/betsnapdetail/betsnapdetail-bets/betsnapdetail-bets-loader.component';
import {BetsnapdetailRankingLoaderComponent} from '../../ui/views/betsnapdetail/betsnapdetail-ranking/betsnapdetail-ranking-loader.component';
import {BetsnapdetailVendorGamesLoaderComponent} from '../../ui/views/betsnapdetail/betsnapdetail-vendor-games/betsnapdetail-vendor-games-loader.component';
import {BetsnapdetailVendorGameParticipationsLoaderComponent} from '../../ui/views/betsnapdetail/betsnapdetail-vendor-game-participations/betsnapdetail-vendor-game-participations-loader.component';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {

    private storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // Decides if the route should be stored
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // console.log('CustomReuseStrategy:shouldDetach', route);
        return (!!route.routeConfig && !!route.data.routeReuseKey);
    }

    // Store the information for the route we're destructing
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        // // console.log('CustomReuseStrategy:store', route, handle);
        this.storedRouteHandles.set(route.data.routeReuseKey, handle);
    }

    // Return true if we have a stored route object for the next route
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        // console.log('CustomReuseStrategy:shouldAttach', route);
        // clear handlers on logout
        if (route.component === LoginComponent || route.component === B2bLoginComponent || route.component === HomeComponent) {
            this.storedRouteHandles.forEach(
                (storedHandle, entryKey) => {
                    this.deactivateOutlet(entryKey);
                }
            );
            return false;
        }

        // clear handlers of betsnapdetail if reached component is not a betsnapdetail component
        if (route.routeConfig &&
            route.component !== BetsnapdetailLoaderComponent &&
            route.component !== BetsnapdetailDetailsLoaderComponent &&
            route.component !== BetsnapdetailMatchesLoaderComponent &&
            route.component !== BetsnapdetailBetsLoaderComponent &&
            route.component !== BetsnapdetailPlayersComponent &&
            route.component !== BetsnapdetailRankingLoaderComponent &&
            route.component !== BetsnapdetailVendorGamesLoaderComponent &&
            route.component !== BetsnapdetailVendorGameParticipationsLoaderComponent
        ) {
            this.storedRouteHandles.forEach(
                (storedHandle, entryKey) => {
                    if (entryKey.includes('betsnapdetail')) {
                        this.deactivateOutlet(entryKey);
                    }
                }
            );
        }

        return (!!route.routeConfig && this.storedRouteHandles.has(route.data.routeReuseKey));
    }

    // If we returned true in shouldAttach(), now return the actual route data for restoration
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        // console.log('CustomReuseStrategy:retrieve', route);
        return this.storedRouteHandles.get(route.data.routeReuseKey);
    }

    // Reuse the route if we're going to and from the same route
    shouldReuseRoute(curr: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot): boolean {
        // console.log('CustomReuseStrategy:shouldReuseRoute', future, curr);

        const futurePath = this.getPath(future);
        const currPath = this.getPath(curr);

        const futureUrl = this.getFullUrl(future);
        const currUrl = this.getFullUrl(curr);

        // clear handlers of betsnapdetail on betsnapdetail gameunique parameter change (switch snap)
        if (future.routeConfig && curr.routeConfig &&
            futurePath === 'betsnapdetail/:game_unique_id' && currPath === 'betsnapdetail/:game_unique_id' &&
            future.params['game_unique_id'] && curr.params['game_unique_id'] &&
            future.params['game_unique_id'] !== curr.params['game_unique_id']) {
            this.storedRouteHandles.forEach(
                (entryVal, entryKey) => {
                    if (entryKey.includes('betsnapdetail')) {
                        this.deactivateOutlet(entryKey);
                    }
                }
            );
        }

        return futureUrl === currUrl;
    }

    private deactivateOutlet(entryKey): void {
        const storedHandle = this.storedRouteHandles.get(entryKey);
        if (storedHandle && storedHandle['componentRef']) {
            const componentRef: ComponentRef<any> = storedHandle['componentRef'];
            if (componentRef) {
                componentRef.destroy();
            }
        }
        this.storedRouteHandles.delete(entryKey);
    }

    private getFullUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .filter(url => !!url)
            .join('/');
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map((el: ActivatedRouteSnapshot) => el.routeConfig ? el.routeConfig.path : '')
            .filter(str => str.length > 0)
            .join('/');
    }


}
