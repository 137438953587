/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MediaUploadRequest
 */
export interface AppHttpRequestsMediaMediaUploadRequest { 
    /**
     * User ID
     */
    user_id: number;
    /**
     * Media Data (Base64 Encoded)
     */
    data: string;
    /**
     * Tenant Ids
     */
    tenant_ids: Array<number>;
    /**
     * Media Type ID
     */
    media_type_id: number;
}

