import {Inject, Injectable} from '@angular/core';
import {
    AppHttpResponsesGeneralLanguageHttpResponse as LanguageHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerHttpResponse as MarketingPartnerHttpResponse,
    AppHttpResponsesNotificationsUserNotificationHttpResponse as UserNotificationHttpResponse,
    AppHttpResponsesNotificationsUserNotificationListHttpResponse as UserNotificationListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse,
    UsersApi
} from '../../api';
import {
    AppEventsNotificationsUserNotification as UserNotificationEvent,
    AppEventsNotificationsUserNotificationNow as UserNotificationNowEvent,
    AppEventsNotificationsUserNotificationUpdated as UserNotificationUpdatedEvent
} from '../interfaces';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {NotificationAnimationType, NotificationsService, NotificationType} from 'angular2-notifications';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from './error.service';
import {BroadcastingService} from './broadcasting.service';
import {DebugService} from './debug.service';
import {take} from 'rxjs/operators';
import {WindowRef} from './window.service';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {TenantService} from './tenant.service';

@Injectable({
    providedIn: 'root'
})
export class MyNotificationsService {

    private _currentUser: PlayerHttpResponse;
    private _currentLang: LanguageHttpResponse;

    private unreadNewsCountSubject = new BehaviorSubject<number>(0);
    public unreadNewsCount$ = this.unreadNewsCountSubject.asObservable();

    private updateUserNotificationSubject = new BehaviorSubject<UserNotificationHttpResponse>(null);
    public updateUserNotification$ = this.updateUserNotificationSubject.asObservable();

    private popupUserNotificationSubject = new BehaviorSubject<UserNotificationHttpResponse>(null);
    public popupUserNotification$ = this.popupUserNotificationSubject.asObservable();

    private addedUserNotificationSubject = new BehaviorSubject<UserNotificationHttpResponse>(null);
    public addedUserNotification$ = this.addedUserNotificationSubject.asObservable();

    private popupUserMarketingNotificationSubject = new BehaviorSubject<MarketingPartnerHttpResponse>(null);
    public popupUserMarketingNotification$ = this.popupUserMarketingNotificationSubject.asObservable();

    private currentBroadcastMarketingPartnerIndex: number = 0;
    public broadcastMarketingPartnerResponses: MarketingPartnerHttpResponse[];

    private allNotificationsReadSubject = new BehaviorSubject<boolean>(null);
    public allNotificationsRead$ = this.allNotificationsReadSubject.asObservable();

    private userNotificationEventSubscriptions: Subscription[] = [];
    private apiGetRequestSubscriptions: Subscription[] = [];

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    constructor(@Inject(DOCUMENT) private document: any,
                private usersApi: UsersApi,
                private notificationsService: NotificationsService,
                private broadcastingService: BroadcastingService,
                private translateService: TranslateService,
                private debugService: DebugService,
                private errorService: ErrorService,
                private tenantService: TenantService,
                private windowRef: WindowRef
    ) {
        this.nativeWindow = windowRef.nativeWindow;
        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    public get unreadNewsCount() {
        return this.unreadNewsCountSubject.value;
    }

    public set currentLang(language: LanguageHttpResponse) {
        this._currentLang = language;
    }

    public get currentLang() {
        return this._currentLang;
    }

    public set currentUser(user: PlayerHttpResponse) {
        this._currentUser = user;
    }

    public initUserData(currentUser: PlayerHttpResponse, currentLang: LanguageHttpResponse) {
        this._currentUser = currentUser;
        this._currentLang = currentLang;

        this.getUnreadNewsCount();
        this.subscribeToUserNotificationEvents();
    }

    public getUnreadNewsCount() {
        if (this._currentUser) {
            // get the unread news counter
            this.apiGetRequestSubscriptions.push(
                this.getUserNotifications(false, true, undefined, 1)
                    .pipe(take(1))
                    .subscribe({
                        next: (userNotificationListHttpResponse: UserNotificationListHttpResponse) => {
                            if (userNotificationListHttpResponse) {
                                this.unreadNewsCountSubject.next(userNotificationListHttpResponse.total);
                            }
                        },
                        error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                    })
            );
        }
    }

    // get the user notifications via api request
    public getUserNotifications(isRead: boolean = undefined, isShownInNews: boolean = undefined, isShownInFriends: boolean = undefined, perPage: number = 10, currentPage: number = 1): Observable<UserNotificationListHttpResponse> {
        if (!this._currentUser) {
            return null;
        }

        return this.usersApi.apiUsersUserIdNotificationsGet(
            this._currentUser.id,
            isRead,
            isShownInNews,
            isShownInFriends,
            perPage,
            currentPage
        );
    }

    // update a user notification read state
    public updateUserNotification(notification: any, isRead: boolean = true) {
        if (!this._currentUser) {
            return null;
        }

        this.apiGetRequestSubscriptions.push(
            this.usersApi.apiUsersUserIdNotificationsNotificationIdPut(this._currentUser.id, notification.id, isRead)
                .pipe(take(1))
                .subscribe({
                    next: (userNotificationHttpResponse: UserNotificationHttpResponse) => {
                        if (userNotificationHttpResponse && userNotificationHttpResponse.is_read || isRead) {
                            this.updateUnreadNotificationsCounter(userNotificationHttpResponse);
                            this.updateUserNotificationSubject.next(userNotificationHttpResponse);
                        }
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                })
        );
    }

    public markAllNotificationsAsRead(): Observable<{}> {
        if (!this._currentUser) {
            return null;
        }
        return this.usersApi.apiUsersUserIdNotificationsMarkallasreadPut(this._currentUser.id);
    }

    public updateNotificationsNewsCounter(newCount: number) {
        this.unreadNewsCountSubject.next(newCount);
    }

    // get a notification (material design icon) class for the correct icon
    public getNotificationIconClass(userNotification: UserNotificationHttpResponse, defaultClass: string = null) {
        let notificationClass = defaultClass;

        if (userNotification.notification && userNotification.notification.is_shown_in_friends) {
            notificationClass = 'group';
        }

        return notificationClass;
    }

    // get a notification (material design icon) class for the correct icon
    private getNotificationIconHtml(userNotification: UserNotificationHttpResponse, defaultClass: string = null) {
        let notificationIconHtml = '';

        if (this.tenantService.componentTemplateToLoad === 'v3') {
            const notificationIcon = this.getNotificationIconImageClass(userNotification);

            if (notificationIcon) {
                const notificationIconSize = this.getNotificationIconImageSizeClass(notificationIcon);
                notificationIconHtml = '<i class="ui circular bordered padded centered ' + notificationIconSize + ' icon inverted bsi ' + notificationIcon + '"></i>';
            }
        }

        if (notificationIconHtml === '') {
            const notificationIconClass = this.getNotificationIconClass(userNotification, defaultClass);
            if (notificationIconClass) {
                if (this.tenantService.componentTemplateToLoad === 'v3') {
                    notificationIconHtml = '<i class="ui circular bordered padded centered huge icon inverted ' + notificationIconClass + ' huge"></i>';
                } else {
                    notificationIconHtml = '<i class="ui icon ' + notificationIconClass + ' huge"></i>';
                }
            }
        }

        return notificationIconHtml;
    }

    public getNotificationJsonProperty(notificationVariablesJson: string, propertyKey: string): any | null {
        const jsonArray = JSON.parse(notificationVariablesJson);
        if (jsonArray.hasOwnProperty(propertyKey)) {
            return jsonArray[propertyKey];
        }
        return null;
    }

    private handleNotification(broadcastEventData: any) {
        // check if userNotificationHttpResponse
        if (!broadcastEventData.userNotificationHttpResponse) {
            return;
        }

        const userNotification: UserNotificationHttpResponse = broadcastEventData.userNotificationHttpResponse;

        if (userNotification.notification.is_screen_popup) {
            if (this.nativeWindow.window.BetSnapMobileWrapper) {
                if (!userNotification.notification.send_push) {
                    this.nativeWindow.window.BetSnapMobileWrapper.showNotification([
                        this.getReplacedText(userNotification),
                        userNotification.target_url
                    ]);
                }
            } else {
                const notificationOverride = Object.assign({}, this.getDefaultNotificationOverride());

                if (this.hasSenderUser(userNotification)) {
                    const senderUser = this.getSenderUser(userNotification);
                    let notificationImageHtml = '';

                    if (senderUser.media_id > 0) {
                        notificationImageHtml += '<img class="ui circular bordered centered image profileimg bg-white tiny" src="' + senderUser.media_url_small + '" alt="" />';
                    } else {
                        notificationImageHtml += '<img class="ui circular bordered centered image profileimg bg-white tiny" src="./assets/img/default-profile.png" alt="" />';
                    }

                    if (!notificationOverride.icons) {
                        notificationOverride.icons = {};
                    }
                    notificationOverride.icons.info = '<div class="user-avatar-wrap">' + notificationImageHtml + '</div>';
                } else {
                    const notificationIconHtml = this.getNotificationIconHtml(userNotification, 'notifications');

                    if (notificationIconHtml) {
                        // override the default icon with notification specific
                        if (!notificationOverride.icons) {
                            notificationOverride.icons = {};
                        }
                        notificationOverride.icons.info = notificationIconHtml;
                    }
                }
                // create the notification
                this.createNotificationToast('', this.getReplacedText(userNotification), NotificationType.Info, notificationOverride);
            }
        }

        // add new notification to observable (eg. for news route)
        this.addedUserNotificationSubject.next(userNotification);
        this.updateUnreadNotificationsCounter(userNotification);
    }

    // create the notification toast
    // possible values: success / alert / error
    public createNotificationToast(title: string = null, content: string = null, type: NotificationType = NotificationType.Info, notificationOverride: any = null) {
        const myNotificationOverride = Object.assign({}, (notificationOverride !== null ? notificationOverride : this.getDefaultNotificationOverride()));
        this.notificationsService.create(title, content, type, myNotificationOverride);
    }

    // create a notification modal (full screen)
    public createNotificationModal(notification: any) {
        this.popupUserNotificationSubject.next(notification);
    }

    public handleBroadcastMarketingPartnerResponses(marketingPartnerResponses: MarketingPartnerHttpResponse[]) {
        this.currentBroadcastMarketingPartnerIndex = 0;
        this.broadcastMarketingPartnerResponses = marketingPartnerResponses;
        this.createUserMarketingNotificationModal(this.broadcastMarketingPartnerResponses[this.currentBroadcastMarketingPartnerIndex]);
    }

    public checkIfOtherBroadcastMarketingPartnerExists() {
        if (this.broadcastMarketingPartnerResponses &&
            this.broadcastMarketingPartnerResponses.length >= this.currentBroadcastMarketingPartnerIndex + 1) {
            this.currentBroadcastMarketingPartnerIndex++;
            this.createUserMarketingNotificationModal(this.broadcastMarketingPartnerResponses[this.currentBroadcastMarketingPartnerIndex]);
        }
    }

    // create a marketing notification modal (full screen)
    public createUserMarketingNotificationModal(marketingPartner: MarketingPartnerHttpResponse) {
        this.popupUserMarketingNotificationSubject.next(marketingPartner);
    }

    // updates the news / friends notification counters determined by notification read state
    // (also the news list)
    private updateUnreadNotificationsCounter(notification: any) {
        let addOrSub = 1;
        // check if add or sub from the open value
        if (notification.is_read) {
            addOrSub = -1;
        }

        if (notification.notification.is_shown_in_news) {
            let currentUnreadNewsCount = this.unreadNewsCount;
            currentUnreadNewsCount += addOrSub;
            if (currentUnreadNewsCount < 0) {
                currentUnreadNewsCount = 0;
            }
            this.unreadNewsCountSubject.next(currentUnreadNewsCount);
        }
    }

    public hasNotificationVariable(notification: UserNotificationHttpResponse, variable: string): boolean {
        if (notification.notification_variables_json) {
            const jsonArray = JSON.parse(notification.notification_variables_json);
            if (jsonArray[variable]) {
                return true;
            }
        }

        return false;
    }

    public getNotificationVariable(notification: UserNotificationHttpResponse, variable: string) {
        if (notification.notification_variables_json) {
            const jsonArray = JSON.parse(notification.notification_variables_json);
            if (jsonArray[variable]) {
                return jsonArray[variable];
            }
        }

        return null;
    }

    // gets a notification text with its placeholder variables replaced
    public getReplacedText(notification: any): string {

        let translation = null;
        if (notification.notification.translations) {
            translation = notification.notification.translations.find(
                (translationItem) =>
                    translationItem.label_field === 'message' &&
                    translationItem.iso_code2 === this._currentLang.iso_code2);

            // if no translation found search english translation
            if (!translation) {
                translation = notification.notification.translations.find(
                    (translationItem) =>
                        translationItem.label_field === 'message' &&
                        translationItem.iso_code2 === 'en');
            }
        }

        let replacedText;

        // if no english translation found search use fallback
        if (!translation) {
            replacedText = notification.notification.message;
        } else {
            replacedText = translation.label_value;
        }

        if (notification.notification_variables_json) {
            const jsonArray = JSON.parse(notification.notification_variables_json);

            for (const entryKey in jsonArray) {
                if (jsonArray.hasOwnProperty(entryKey)) {
                    const entryValue = jsonArray[entryKey];
                    let newValue = entryValue;
                    switch (entryKey) {
                        case 'game_name':
                            if (jsonArray.hasOwnProperty('game_unique_id') && jsonArray['game_unique_id'] !== '') {
                                let gameName = entryValue;
                                if (this._currentLang) {
                                    if (jsonArray.hasOwnProperty('game_name_' + this._currentLang.iso_code2)) {
                                        gameName = jsonArray['game_name_' + this._currentLang.iso_code2];
                                    }
                                }
                                newValue = '<strong class="text-primary">' + gameName + '</strong>';
                            }
                            break;
                        case 'tenant_ranking_name':
                            if (jsonArray.hasOwnProperty('tenant_ranking_id') && jsonArray['tenant_ranking_id'] !== '') {
                                let rankingName = entryValue;
                                if (this._currentLang) {
                                    if (jsonArray.hasOwnProperty('tenant_ranking_name_' + this._currentLang.iso_code2)) {
                                        rankingName = jsonArray['tenant_ranking_name_' + this._currentLang.iso_code2];
                                    }
                                }
                                newValue = '<strong class="text-primary">' + rankingName + '</strong>';
                            }
                            break;
                        case 'prize':
                            if (jsonArray.hasOwnProperty('prize') && jsonArray['prize'] !== '') {
                                let prize = entryValue;
                                if (this._currentLang && jsonArray.hasOwnProperty('translations')) {
                                    const translation = jsonArray['translations'].find(
                                        (translationItem) =>
                                            translationItem.label_field === 'prize' &&
                                            translationItem.iso_code2 === this._currentLang.iso_code2);
                                    if (translation) {
                                        prize = translation.label_value;
                                    }
                                }
                                let isBonusMoney = false;
                                if (jsonArray.hasOwnProperty('is_bonus_money') && jsonArray['is_bonus_money'] !== '') {
                                    if (jsonArray['is_bonus_money'] === true) {
                                        isBonusMoney = true;
                                    }
                                }
                                if (isBonusMoney === true) {
                                    this.translateService.get(['GENERAL.LABELS.bet_bonus'])
                                        .pipe(take(1)).subscribe(
                                        translation => {
                                            prize += '&nbsp;' + translation['GENERAL.LABELS.bet_bonus'];
                                        });
                                }
                                newValue = '<span class="nowrap"><strong>' + prize + '</strong></span>';
                            }
                            break;
                        case 'global_ranking_points_txt':
                                if (jsonArray.hasOwnProperty('global_ranking_points_txt') && jsonArray['global_ranking_points_txt'] !== '') {
                                    newValue = ' ' + entryValue;
                                } else {
                                    newValue = '';
                                }
                            break;
                        case 'cancellation_reason':
                            if (jsonArray.hasOwnProperty('cancellation_reason') && jsonArray['cancellation_reason'] !== '') {
                                let cancellationReason = entryValue;
                                if (this._currentLang) {
                                    if (jsonArray.hasOwnProperty('cancellation_reason_' + this._currentLang.iso_code2)) {
                                        cancellationReason = jsonArray['cancellation_reason_' + this._currentLang.iso_code2];
                                    }
                                }
                                newValue = '<i>' + cancellationReason + '</i>';
                            }
                            break;
                    }
                    replacedText = replacedText.replace('{' + entryKey + '}', newValue);
                }
            }
        }

        return replacedText;
    }

    public hasSenderUser(notification: UserNotificationHttpResponse): boolean {
        return (notification.notification_variables_json &&
            JSON.parse(notification.notification_variables_json).hasOwnProperty('user_id') &&
            JSON.parse(notification.notification_variables_json)['user_id'] > 0
        );
    }

    public getSenderUser(notification: UserNotificationHttpResponse): PlayerPublicHttpResponse {
        if (notification.notification_variables_json) {
            const senderUser: PlayerPublicHttpResponse = {
                id: 0,
                username: null,
                is_active: 0,
                media_id: null,
                media_name: null,
                media_url: null,
                media_url_big: null,
                media_url_small: null
            };

            const jsonArray = JSON.parse(notification.notification_variables_json);
            if (jsonArray.hasOwnProperty('user_id')) {
                senderUser.id = jsonArray['user_id'];
            }
            if (jsonArray.hasOwnProperty('user_name')) {
                senderUser.username = jsonArray['user_name'];
            }
            if (jsonArray.hasOwnProperty('user_is_active')) {
                senderUser.is_active = jsonArray['user_is_active'];
            }
            if (jsonArray.hasOwnProperty('media_id')) {
                senderUser.media_id = jsonArray['media_id'];
            }
            if (jsonArray.hasOwnProperty('media_url_small')) {
                senderUser.media_url_small = jsonArray['media_url_small'];
            }
            if (jsonArray.hasOwnProperty('media_url_big')) {
                senderUser.media_url_big = jsonArray['media_url_big'];
            }

            if (senderUser.id > 0) {
                return senderUser;
            } else {
                return null;
            }
        }
        return null;
    }

    private subscribeToUserNotificationEvents() {
        if (this._currentUser) {

            this.userNotificationEventSubscriptions.forEach(subscription => subscription.unsubscribe());
            this.userNotificationEventSubscriptions = [];

            // Listen for UserNotification events
            this.userNotificationEventSubscriptions.push(
                this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Notifications\\UserNotification')
                    .subscribe((broadcastEventData: UserNotificationEvent) => {
                        if (broadcastEventData) {
                            this.debugService.writeMessageToConsoleLog('UserNotificationEvent');
                            this.handleNotification(broadcastEventData);
                        }
                    })
            );

            this.userNotificationEventSubscriptions.push(
                this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Notifications\\UserNotificationNow')
                    .subscribe((broadcastEventData: UserNotificationNowEvent) => {
                        if (broadcastEventData) {
                            this.debugService.writeMessageToConsoleLog('UserNotificationNowEvent');
                            this.handleNotification(broadcastEventData);
                        }
                    })
            );
            this.userNotificationEventSubscriptions.push(
                this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Notifications\\UserNotificationUpdated')
                    .subscribe((broadcastEventData: UserNotificationUpdatedEvent) => {
                        if (broadcastEventData) {
                            this.debugService.writeMessageToConsoleLog('UserNotificationUpdatedEvent');
                            this.updateUnreadNotificationsCounter(broadcastEventData.userNotificationHttpResponse);
                            this.updateUserNotificationSubject.next(broadcastEventData.userNotificationHttpResponse);
                        }
                    })
            );
            this.userNotificationEventSubscriptions.push(
                this.broadcastingService.listenOnEventInChannel('User.' + this._currentUser.id, 'Notifications\\UserAllNotificationsRead')
                    .subscribe((broadcastEventData) => {
                        if (broadcastEventData) {
                            this.debugService.writeMessageToConsoleLog('UserAllNotificationsReadEvent');
                            this.updateNotificationsNewsCounter(0);
                            this.allNotificationsReadSubject.next(true);
                        }
                    })
            );

        }
    }

    public getNotificationIconImageClass(userNotification: UserNotificationHttpResponse): string | null {
        let iconImageClass = null;

        switch (userNotification.notification.code) {
            case 'user_finished_in_price_ranks':
            case 'game_summary_winner':
            case 'game_summary_prizeranks':
            case 'game_summary_nonprizeranks':
            case 'game_summary_invalid_participation':
                iconImageClass = 'bsi-trophy';
                break;
            case 'game_summary_h2h_winner':
            case 'game_summary_h2h_loser':
            case 'game_summary_h2h_invalid_participation':
            case 'user_forced_to_leave_game_pooledh2h':
                iconImageClass = 'bsi-h2h';
                break;
            case 'tenant_ranking_summary_winner':
            case 'tenant_ranking_summary_noprizes_winner':
            case 'tenant_ranking_summary_prizeranks':
            case 'tenant_ranking_summary_nonprizeranks':
            case 'tenant_ranking_summary_noprizes_info':
            case 'tenant_ranking_highscore_new':
            case 'tenant_ranking_highscore_improved':
                iconImageClass = 'bsi-ranking';
                break;
            case 'game_starts_in_minutes':
                iconImageClass = 'bsi-alert';
                break;
            case 'user_signed_up':
            case 'user_daily_activity_reward':
            case 'user_accepted_marketing_partner':
                iconImageClass = 'bsi-daily-bonus';
                break;
            case 'game_invitation_received':
            case 'game_cancelled':
                const competitionType = this.getNotificationJsonProperty(userNotification.notification_variables_json, 'game_competition_type');
                if (competitionType) {
                    if (competitionType === 1 || competitionType === 3) {
                        iconImageClass = 'bsi-h2h';
                    } else {
                        iconImageClass = 'bsi-trophy';
                    }
                }
                break;
        }

        return iconImageClass;
    }

    public getNotificationIconImageSizeClass(iconImageClass: string): string {
        let iconImageSizeClass = 'big';

        switch (iconImageClass) {
            case 'bsi-ranking':
                iconImageSizeClass = 'huge';
                break;
        }

        return iconImageSizeClass;
    }

    public isNegative(userNotification: UserNotificationHttpResponse): boolean {
        let isNegative = false;

        switch (userNotification.notification.code) {
            case 'game_summary_nonprizeranks':
            case 'game_summary_h2h_loser':
            case 'game_summary_invalid_participation':
            case 'game_summary_h2h_invalid_participation':
            case 'tenant_ranking_summary_nonprizeranks':
            case 'tenant_ranking_summary_noprizes_info':
            case 'user_forced_to_leave_game_pooledh2h':
                isNegative = true;
                break;
        }

        return isNegative;
    }

    private getDefaultNotificationOverride(): any {
        if (this.tenantService.componentTemplateToLoad === 'v3') {
            return this.errorService.defaultNotificationOverrideV3;
        } else {
            return this.errorService.defaultNotificationOverride;
        }
    }

    public resetAllData() {
        this._currentUser = null;
        this._currentLang = null;

        this.unreadNewsCountSubject.next(0);
        this.updateUserNotificationSubject.next(null);
        this.popupUserNotificationSubject.next(null);
        this.addedUserNotificationSubject.next(null);
        this.allNotificationsReadSubject.next(null);

        this.popupUserMarketingNotificationSubject.next(null);
        this.currentBroadcastMarketingPartnerIndex = 0;
        this.broadcastMarketingPartnerResponses = null;

        this.userNotificationEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.userNotificationEventSubscriptions = [];

        this.apiGetRequestSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.apiGetRequestSubscriptions = [];
    }

}
