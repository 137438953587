import { OnDestroy, OnInit, Directive } from '@angular/core';
import {
    AppHttpResponsesMarketingMarketingPartnerListHttpResponse as MarketingPartnerListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
} from '../../../../api';
import {
    AuthenticationService, ErrorService, GoogleAnalyticsService, ProfileService,
    TenantService, WindowRef
} from '../../../../shared';
import {takeWhile} from 'rxjs/operators';

@Directive()
export abstract class AProfileOverviewComponent implements OnInit, OnDestroy {

    private componentAlive = true;

    public currentUser: PlayerHttpResponse;
    public logoutProcess: boolean = false;
    public isB2b: boolean = false;
    public showBalance: boolean = false;
    public showPrivacyButton: boolean = false;

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    protected constructor(protected authenticationService: AuthenticationService,
                          protected tenantService: TenantService,
                          protected profileService: ProfileService,
                          protected errorService: ErrorService,
                          protected googleAnalyticsService: GoogleAnalyticsService,
                          protected windowRef: WindowRef
    ) {
        this.nativeWindow = windowRef.nativeWindow;
        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }

        if (this.authenticationService.currentUser) {
            this.isB2b = this.authenticationService.currentUser.b2b_user_id !== null;
        }
        this.showBalance = this.tenantService.tenantData.configuration.show_balance;
    }

    ngOnInit() {
        this.currentUser = this.authenticationService.currentUser;
        this.profileService.marketingPartnerList$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (marketingPartnerList: MarketingPartnerListHttpResponse) => {
                    if (marketingPartnerList && marketingPartnerList.count > 0) {
                        this.showPrivacyButton = true;
                    }
                });
    }

    logout() {
        this.logoutProcess = true;
        this.googleAnalyticsService.trackEvent('profile - overview', 'click', 'logout');
        this.authenticationService.logout();

        if (this.isWrapperWindow) {
            this.nativeWindow.window.BetSnapMobileWrapper.facebookLogout([]);
            this.nativeWindow.window.BetSnapMobileWrapper.googleLogout([]);
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}
