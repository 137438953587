<div class="ui vertical borderless very padded segment" *ngIf="processMatches">
    <div class="ui active centered inline text loader color-white text-shadow">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processMatches">
    <div *ngIf="gameMatches?.length > 0">
        <section *ngIf="liveMatches() && liveMatches().length > 0"
                 id="live-matches"
                 class="card-group"
                 #liveMatchesSection>
            <div class="ui large fluid label card-group-type-label">
                <strong class="font-very-bold margin-r-10">{{'BETSNAPDETAIL.MATCHES.MENU.live' | translate | uppercase | uppercase}}</strong>{{liveMatches().length}}
                <div class="jump-to" *ngIf="(upComingMatches() && upComingMatches().length > 0) || (completedMatches() && completedMatches().length > 0)">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="(upComingMatches() && upComingMatches().length > 0) ? '#upcoming-matches' : '#closed-matches'"
                                     (click)="$event.preventDefault(); scrollToNextSection((upComingMatches() && upComingMatches().length > 0) ? upcomingMatchesSection : completedMatchesSection)">
                        {{((upComingMatches() && upComingMatches().length > 0) ? ('BETSNAPDETAIL.MATCHES.MENU.upcoming' | translate) : ('BETSNAPDETAIL.MATCHES.MENU.completed' | translate)) | uppercase}} <i class="fa fa-long-arrow-down mini icon margin-0"></i>
                    </a>
                </div>
            </div>
            <betsnaps-match-card-loader
                *ngFor="let gameMatch of liveMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
                [attr.id]="'match'+ gameMatch.match.match_id"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [match]="gameMatch.match"
                [currentUser]="currentUser"
                [marketsVisible]="true"
                [gameUpdatedDate]="game.updated_at"
                [matchStatus]="gameMatch.match.status"
                [matchIndexInList]="matchIndexInList"
                [adPositions]="adPositions">
            </betsnaps-match-card-loader>
        </section>
        <section *ngIf="upComingMatches() && upComingMatches().length > 0"
                 id="upcoming-matches"
                 class="card-group"
                 #upcomingMatchesSection>
            <div class="ui large fluid label card-group-type-label">
                <strong class="font-very-bold margin-r-10">{{'BETSNAPDETAIL.MATCHES.MENU.upcoming' | translate | uppercase}}</strong>{{upComingMatches().length}}
                <div class="jump-to" *ngIf="completedMatches() && completedMatches().length > 0">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="'#closed-matches'"
                                     (click)="$event.preventDefault(); scrollToNextSection(completedMatchesSection)">
                        {{'BETSNAPDETAIL.MATCHES.MENU.completed' | translate | uppercase}} <i class="fa fa-long-arrow-down mini icon margin-0"></i>
                    </a>
                </div>
            </div>
            <betsnaps-match-card-loader
                *ngFor="let gameMatch of upComingMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
                [attr.id]="'match'+ gameMatch.match.match_id"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [match]="gameMatch.match"
                [currentUser]="currentUser"
                [isCancelled]="gameMatch.is_cancel"
                [marketsVisible]="true"
                [gameUpdatedDate]="game.updated_at"
                [matchStatus]="gameMatch.match.status"
                [matchIndexInList]="matchIndexInList + liveMatches().length"
                [adPositions]="adPositions">
            </betsnaps-match-card-loader>
        </section>
        <section *ngIf="completedMatches() && completedMatches().length > 0"
                 id="completed-matches"
                 class="card-group"
                 #completedMatchesSection>
            <div class="ui large fluid label card-group-type-label">
                <strong class="font-very-bold margin-r-10">{{'BETSNAPDETAIL.MATCHES.MENU.completed' | translate | uppercase}}</strong>{{completedMatches().length}}
                <div class="jump-to" *ngIf="(upComingMatches() && upComingMatches().length > 0) || (liveMatches() && liveMatches().length > 0)">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="(liveMatches() && liveMatches().length > 0) ? '#live-matches' : '#upcoming-matches'"
                                     (click)="$event.preventDefault(); scrollToNextSection((liveMatches() && liveMatches().length > 0) ? liveMatchesSection : upcomingMatchesSection)">
                        {{((liveMatches() && liveMatches().length > 0) ? ('BETSNAPDETAIL.MATCHES.MENU.live' | translate) : ('BETSNAPDETAIL.MATCHES.MENU.upcoming' | translate)) | uppercase}} <i class="fa fa-long-arrow-up mini icon margin-0"></i>
                    </a>
                </div>
            </div>
            <betsnaps-match-card-loader
                *ngFor="let gameMatch of completedMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
                [attr.id]="'match'+ gameMatch.match.match_id"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [match]="gameMatch.match"
                [currentUser]="currentUser"
                [isCancelled]="gameMatch.is_cancel"
                [marketsVisible]="false"
                [gameUpdatedDate]="game.updated_at"
                [matchStatus]="gameMatch.match.status"
                [matchIndexInList]="matchIndexInList + liveMatches().length + upComingMatches().length"
                [adPositions]="adPositions">
            </betsnaps-match-card-loader>
        </section>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="gameMatches?.length < 1">
        <p class="color-white text-shadow">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
