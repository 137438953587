import {HttpErrorResponse} from '@angular/common/http';

export interface GlobalError {
    errorResponse: HttpErrorResponse;
    code: number;
    title: string;
    subTitle: string;
    message: string;
    subMessage: string;
    imageSrc: string;
}
