import {Component, OnInit} from '@angular/core';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GoogleAnalyticsService,
    MyModalService, MyNotificationsService,
    OneSignalService,
    TenantService,
    UserBetStateEnum
} from '../../../../../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {takeWhile} from 'rxjs/operators';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as UserBetHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse as UserBetPlaceBetReportHttpResponse
} from '../../../../../api';
import {ABetsnapdetailBetsComponent} from '../a-betsnapdetail-bets-component';

@Component({
    selector: 'betsnaps-betsnapdetail-bets-default',
    templateUrl: './betsnapdetail-bets-default.component.html',
    styles: []
})
export class BetsnapdetailBetsDefaultComponent extends ABetsnapdetailBetsComponent implements OnInit {

    public activeBetsVisible: boolean = true;
    public completedBetsVisible: boolean = false;

    protected activeBetsCount: number = 0;
    protected completedBetsCount: number = 0;

    public betsMenu = [];

    public activeTab: string = 'active';

    constructor(public authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected betsnapdetailService: BetsnapdetailService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected myModalService: MyModalService,
                protected oneSignalService: OneSignalService,
                protected errorService: ErrorService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected adService: AdService) {
        super(
            authenticationService,
            tenantService,
            betsnapdetailService,
            googleAnalyticsService,
            myNotificationsService,
            translateService,
            myModalService,
            oneSignalService,
            errorService,
            adService
        );
    }

    ngOnInit() {

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {

                    if (gameHttpResponse) {
                        if (this.authenticationService.currentUser && gameHttpResponse.is_current_user_joined && !this.gameMatches) {
                            // load matches from api
                            this.betsnapdetailService.getGameMatches(gameHttpResponse.game_unique_id);
                        }

                        // remove widget bets if game started
                        if (gameHttpResponse.game_state > 2) {
                            this.removeWidgetBets();
                        }

                        if (this.authenticationService.currentUser && gameHttpResponse.is_current_user_joined && (!this.game || !this.game.is_current_user_joined)) {
                            if (!this.betsMenu.find((menuItem) => menuItem.key === 'active')) {
                                this.betsMenu.push({
                                    'key': 'active',
                                    'translatekey': 'BETSNAPDETAIL.BETS.MENU.active',
                                    'count': 0
                                });
                            }
                            if (!this.betsMenu.find((menuItem) => menuItem.key === 'completed')) {
                                this.betsMenu.push({
                                    'key': 'completed',
                                    'translatekey': 'BETSNAPDETAIL.BETS.MENU.completed',
                                    'count': 0
                                });
                            }
                        }

                        if (!this.game) {
                            if ((!gameHttpResponse.is_current_user_joined || gameHttpResponse.sport_id === 999) && !this.betsnapdetailService.widgetBets) {
                                this.router.navigate(['/betsnapdetail/' + gameHttpResponse.game_unique_id + '/details']);
                                return;
                            } else {

                                if (this.betsnapdetailService.widgetBets) {
                                    this.widgetBets = this.betsnapdetailService.widgetBets;

                                    this.activeTab = 'to_place';
                                    this.betsMenu.unshift(
                                        {
                                            'key': 'to_place',
                                            'translatekey': 'BETSNAPDETAIL.BETS.MENU.to_place',
                                            'count': this.widgetBets.length
                                        }
                                    );

                                    this.activeBetsVisible = this.completedBetsVisible = false;

                                    this.processBets = false;

                                    // do automatic placement if force parameter available
                                    if (localStorage.getItem('widgetBets-force') && localStorage.getItem('widgetBets-force') === 'true') {
                                        this.game = gameHttpResponse;
                                        this.initiatePlaceWidgetBets();
                                    }
                                }

                                if (this.authenticationService.currentUser && gameHttpResponse.is_current_user_joined) {

                                    // show closed bets if game is closed
                                    if (gameHttpResponse.game_state === 7) {
                                        this.activeTab = 'completed';
                                        this.activeBetsVisible = false;
                                        this.completedBetsVisible = true;
                                    }

                                    // get user bets for current user
                                    this.betsnapdetailService.getUserBets(
                                        gameHttpResponse.game_unique_id,
                                        this.authenticationService.currentUser.id
                                    );

                                }
                            }
                        }

                        if (this.placeWidgetBetsAfterJoin && !this.game.is_current_user_joined && gameHttpResponse.is_current_user_joined) {
                            this.game = gameHttpResponse;
                            this.initiatePlaceWidgetBets();
                        }
                    }

                    this.game = gameHttpResponse;
                }
            );

        this.betsnapdetailService.userBets$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userBetsMatchGroup: UserBetHttpResponse[][]) => {
                    this.userBets = userBetsMatchGroup;
                    this.setMenuCounts();

                    // show completed bets if no active are available and completed bets exist
                    if (this.activeBetsCount < 1 && this.completedBetsCount > 0) {
                        this.activeTab = 'completed';
                        this.activeBetsVisible = false;
                        this.completedBetsVisible = true;
                    }

                    this.filterData();
                    this.processBets = false;
                }
            );

        this.betsnapdetailService.gameMatches$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameMatchListResults: GameMatchHttpResponse[]) => {
                    if (gameMatchListResults) {
                        this.gameMatches = gameMatchListResults;
                        this.filterData();
                    }
                }
            );

        if (this.oneSignalService.webPushAvailableForTenant) {
            this.showGameNotificationsCheckbox = true;
        }
    }

    filterData() {
        this.filteredBetsTotal = 0;
        if (this.gameMatches && this.userBets && Object.keys(this.userBets).length > 0) {
            this.filteredGameMatches = this.gameMatches.filter(
                (gameMatch: GameMatchHttpResponse) => {
                    if (this.userBets && this.userBets['match' + gameMatch.match_id]) {

                        // sort bets
                        this.userBets['match' + gameMatch.match_id].sort(function (bet1, bet2) {
                            if (bet1.user_bet_id > bet2.user_bet_id) {
                                return -1;
                            } else if (bet1.user_bet_id < bet2.user_bet_id) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });

                        let userBetsForMatch: UserBetHttpResponse[] = [];
                        if (this.activeBetsVisible && !this.completedBetsVisible) {
                            userBetsForMatch = this.userBets['match' + gameMatch.match_id].filter(
                                (userBet: UserBetHttpResponse) => (userBet.status === UserBetStateEnum.ACTIVE || userBet.status === UserBetStateEnum.PENDING || userBet.status === UserBetStateEnum.CASHOUT_PENDING));
                        } else if (!this.activeBetsVisible && this.completedBetsVisible) {
                            userBetsForMatch = this.userBets['match' + gameMatch.match_id].filter(
                                (userBet: UserBetHttpResponse) => (userBet.status !== UserBetStateEnum.ACTIVE && userBet.status !== UserBetStateEnum.PENDING && userBet.status !== UserBetStateEnum.CASHOUT_PENDING)
                            );
                        } else {
                            userBetsForMatch = this.userBets['match' + gameMatch.match_id];
                        }
                        this.filteredBetsTotal += userBetsForMatch.length;
                        return (userBetsForMatch.length > 0);
                    } else {
                        return false;
                    }
                }
            );
        }
    }

    betsMenuChange(activatekey: string) {
        this.processBets = true;
        this.activeTab = activatekey;

        if (activatekey === 'active') {
            this.activeBetsVisible = true;
            this.completedBetsVisible = false;
        } else if (activatekey === 'completed') {
            this.activeBetsVisible = false;
            this.completedBetsVisible = true;
        } else if (activatekey === 'to_place') {
            this.activeBetsVisible = this.completedBetsVisible = false;
        }

        if (activatekey !== 'to_place') {
            this.filterData();
        }
        this.processBets = false;

        this.googleAnalyticsService.trackEvent('game - bets', 'filter', 'bets - ' + activatekey);
    }

    protected setMenuCounts() {
        for (const menuitem of this.betsMenu) {
            if (menuitem.key === 'active') {
                let activeBetsCount = 0;
                for (const matchKey in this.userBets) {
                    this.userBets[matchKey].forEach((userBet: UserBetHttpResponse) => {
                        if (userBet.status === UserBetStateEnum.ACTIVE || userBet.status === UserBetStateEnum.PENDING || userBet.status === UserBetStateEnum.CASHOUT_PENDING) {
                            activeBetsCount++;
                        }
                    });
                }
                menuitem.count = this.activeBetsCount = activeBetsCount;
            } else if (menuitem.key === 'completed') {
                let completedBetsCount = 0;
                for (const matchKey in this.userBets) {
                    this.userBets[matchKey].forEach((userBet: UserBetHttpResponse) => {
                        if (userBet.status !== UserBetStateEnum.ACTIVE && userBet.status !== UserBetStateEnum.PENDING && userBet.status !== UserBetStateEnum.CASHOUT_PENDING) {
                            completedBetsCount++;
                        }
                    });
                }
                menuitem.count = this.completedBetsCount = completedBetsCount;
            }
        }
    }

    protected removeProcessedWidgetBetFromArray(userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) {
        this.removeItemFromWidgetBets(userBetPlaceBetReport);
        this.betsMenu.map((menuItem) => {
            if (menuItem.key === 'to_place') {
                menuItem.count -= 1;
            }
            return menuItem;
        });
    }

    protected removeWidgetBets(): void {
        if (this.widgetBets) {
            this.processWidgetBets = false;
            this.betsMenuChange('active');
            this.betsMenu = this.betsMenu.filter((menuItem) => menuItem.key !== 'to_place');
            this.betsnapdetailService.widgetBets = null;
            if (localStorage.getItem('widgetBets-' + this.game.game_unique_id)) {
                localStorage.removeItem('widgetBets-' + this.game.game_unique_id);
            }
        }
    }

}
