import {Component} from '@angular/core';
import {
    WindowRef,
    AuthenticationService,
    ProfileService,
    TenantService,
    ErrorService, MyNotificationsService, MyModalService, B2cSignupMethodEnum, TenantImageTypeEnum
} from '../../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AProfileEditComponent} from '../a-profile-edit.component';

@Component({
    selector: 'betsnaps-profile-edit-v3',
    templateUrl: './profile-edit-v3.component.html',
    styles: []
})
export class ProfileEditV3Component extends AProfileEditComponent {

    public profileHeaderImage: string;
    public b2cSignupMethod: string = B2cSignupMethodEnum.EMAIL;
    public b2cSignupMethodEnum = B2cSignupMethodEnum;

    constructor(protected authenticationService: AuthenticationService,
                protected profileService: ProfileService,
                protected tenantService: TenantService,
                protected windowRef: WindowRef,
                public myModalService: MyModalService,
                protected router: Router,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected errorService: ErrorService) {
        super(
            authenticationService,
            profileService,
            tenantService,
            windowRef,
            router,
            myNotificationsService,
            translateService,
            errorService
        );

        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER)) {
            this.profileHeaderImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER).media_url;
        }
        this.b2cSignupMethod = this.tenantService.tenantData.configuration.b2c_signup_method;
    }
}
