/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesTranslationsMediaTranslationHttpResponse } from './appHttpResponsesTranslationsMediaTranslationHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TenantAdvertisementHttpResponse
 */
export interface AppHttpResponsesTenantsTenantAdvertisementHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Title
     */
    title: string;
    /**
     * Display From Date
     */
    display_from_date?: Date;
    /**
     * Display To Date
     */
    display_to_date?: Date;
    /**
     * Text to show
     */
    text?: string;
    /**
     * Adserver Embed Code
     */
    adserver_embed_code?: string;
    /**
     * AD Type
     */
    type: string;
    /**
     * Position
     */
    position?: number;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    /**
     * Image Link
     */
    image_link?: string;
    /**
     * Should Image Link open in new browser tab
     */
    open_link_in_new_tab: boolean;
    /**
     * Android Image Link
     */
    android_image_link?: string;
    /**
     * Should Image Link open in new browser tab
     */
    android_open_link_in_new_tab: boolean;
    /**
     * iOS Image Link
     */
    ios_image_link?: string;
    /**
     * Should Image Link open in new browser tab
     */
    ios_open_link_in_new_tab: boolean;
    /**
     * Web Distribution
     */
    web_distribution?: boolean;
    /**
     * Android Distribution
     */
    android_distribution?: boolean;
    /**
     * iOS Distribution
     */
    ios_distribution?: boolean;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Media Translations
     */
    media_translations?: Array<AppHttpResponsesTranslationsMediaTranslationHttpResponse>;
}

