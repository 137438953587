export const environment = {
    production: true,
    APIURL: 'https://dev.api.betsnap.com',
    CLIENT_ID: 1,
    CLIENT_SECRET: 'ZnVHgA6YWk1TLwWfuL5FVi0IW4VDLtVYke6kUgEE',
    WEB_SOCKET_URL: 'https://dev.nodejs.betsnap.com',
    GOOGLE_RECAPTCHA_PUBLIC: '6Le5wlYUAAAAAGgfxeTVMpf-inBCQESaLfqiU0iT',
    DEBUG: true,
    bugreporter: false,
    GOOGLE_ANALYTICS_TRACKING_ID: 'G-SRR2QBGG0J',
    S3_NL_URL: 'https://static-cdn.s-tech-services.at/bs2api',
    S3_NL_BUCKET_ROOT_DATASHARE: 'datashare/development',
    S3_NL_BUCKET_ROOT_TRANSLATIONSHARE: 'translations/production'
};
