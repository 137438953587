export * from './adminApi.service';
import { AdminApi } from './adminApi.service';
export * from './authenticationApi.service';
import { AuthenticationApi } from './authenticationApi.service';
export * from './cMSApi.service';
import { CMSApi } from './cMSApi.service';
export * from './cRMApi.service';
import { CRMApi } from './cRMApi.service';
export * from './chatApi.service';
import { ChatApi } from './chatApi.service';
export * from './feedApi.service';
import { FeedApi } from './feedApi.service';
export * from './financialsApi.service';
import { FinancialsApi } from './financialsApi.service';
export * from './freemiumApi.service';
import { FreemiumApi } from './freemiumApi.service';
export * from './friendsApi.service';
import { FriendsApi } from './friendsApi.service';
export * from './gameCreationsApi.service';
import { GameCreationsApi } from './gameCreationsApi.service';
export * from './gamesApi.service';
import { GamesApi } from './gamesApi.service';
export * from './generalApi.service';
import { GeneralApi } from './generalApi.service';
export * from './globalRankingsApi.service';
import { GlobalRankingsApi } from './globalRankingsApi.service';
export * from './healthChecksApi.service';
import { HealthChecksApi } from './healthChecksApi.service';
export * from './hintsApi.service';
import { HintsApi } from './hintsApi.service';
export * from './logsApi.service';
import { LogsApi } from './logsApi.service';
export * from './marketingApi.service';
import { MarketingApi } from './marketingApi.service';
export * from './prizesApi.service';
import { PrizesApi } from './prizesApi.service';
export * from './reportsApi.service';
import { ReportsApi } from './reportsApi.service';
export * from './signupApi.service';
import { SignupApi } from './signupApi.service';
export * from './sportsDataApi.service';
import { SportsDataApi } from './sportsDataApi.service';
export * from './statisticsApi.service';
import { StatisticsApi } from './statisticsApi.service';
export * from './tenantsApi.service';
import { TenantsApi } from './tenantsApi.service';
export * from './userMediaApi.service';
import { UserMediaApi } from './userMediaApi.service';
export * from './usersApi.service';
import { UsersApi } from './usersApi.service';
export * from './vendorGamesApi.service';
import { VendorGamesApi } from './vendorGamesApi.service';
export * from './vendorGamesCWSApi.service';
import { VendorGamesCWSApi } from './vendorGamesCWSApi.service';
export const APIS = [AdminApi, AuthenticationApi, CMSApi, CRMApi, ChatApi, FeedApi, FinancialsApi, FreemiumApi, FriendsApi, GameCreationsApi, GamesApi, GeneralApi, GlobalRankingsApi, HealthChecksApi, HintsApi, LogsApi, MarketingApi, PrizesApi, ReportsApi, SignupApi, SportsDataApi, StatisticsApi, TenantsApi, UserMediaApi, UsersApi, VendorGamesApi, VendorGamesCWSApi];
