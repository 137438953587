import {Component, ComponentFactoryResolver, HostBinding, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameInvitationHttpResponse as GameInvitationHttpResponse,
    AppHttpResponsesBetsnapsGamesGameInvitationListHttpResponse as GameInvitationListHttpResponse,
    AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse as GameShareDataHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse, GamesApi
} from '../../../../api';
import {AGameCardComponent} from '../a-game-card-component';
import {
    AdService,
    ArenaService,
    AuthenticationService,
    ErrorService,
    GoogleAnalyticsService,
    MyTranslateService,
    TenantImageTypeEnum,
    TenantService
} from '../../../../shared';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {GameShareModalDefaultComponent} from '../../game-share-modal/game-share-modal-default/game-share-modal-default.component';
import {FeedService} from '../../../../shared/services/feed.service';

@Component({
    selector: 'betsnaps-game-card-default',
    templateUrl: './game-card-default.component.html',
    styles: []
})

export class GameCardDefaultComponent extends AGameCardComponent implements OnInit {
    public tenantImageTypeEnum = TenantImageTypeEnum;

    @HostBinding('class') componentClass = '';

    public gameShareData: GameShareDataHttpResponse;
    public sentGameInvitations: GameInvitationHttpResponse[];

    @ViewChild('gameShareModalDefaultComponent', {static: true}) gameShareModalDefaultComponent: GameShareModalDefaultComponent;

    constructor(protected viewContainerRef: ViewContainerRef,
                protected componentFactoryResolver: ComponentFactoryResolver,
                protected authenticationService: AuthenticationService,
                protected router: Router,
                protected errorService: ErrorService,
                public tenantService: TenantService,
                protected arenaService: ArenaService,
                public translations: MyTranslateService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected adService: AdService,
                protected gamesApi: GamesApi,
                protected feedService: FeedService) {
        super(
            viewContainerRef,
            componentFactoryResolver,
            authenticationService,
            router,
            errorService,
            tenantService,
            arenaService,
            translations,
            googleAnalyticsService,
            adService,
            feedService,
            gamesApi
        );
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.isPartOfList) {
            this.componentClass = 'sixteen wide column';
        } else {
            this.componentClass = 'game-card-wrap';
        }

        this.setBackgroundImage();
        this.preloadFriendImages();
    }

    private preloadFriendImages() {
        if (this.game.current_user_friends) {
            this.game.current_user_friends.forEach(
                (friend: FriendHttpResponse) => {
                    if (friend.friend_user.media_url) {
                        const preLoadMedia = new Image();
                        preLoadMedia.src = friend.friend_user.media_url;
                    }

                    if (friend.friend_user.media_url_small) {
                        const preLoadMediaSmall = new Image();
                        preLoadMediaSmall.src = friend.friend_user.media_url_small;
                    }

                    if (friend.friend_user.media_url_big) {
                        const preLoadMediaBig = new Image();
                        preLoadMediaBig.src = friend.friend_user.media_url_big;
                    }
                }
            );
        }
    }

    public openGameShareModal() {
        if (!this.gameShareData) {
            // get game sharedata
            this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdSharedataGet(
                this.tenantService.tenantData.id,
                this.game.game_unique_id
            ).pipe(take(1))
            .subscribe({
                next: (gameShareDataHttpResponse: GameShareDataHttpResponse) => {
                    if (gameShareDataHttpResponse) {
                        this.gameShareData = gameShareDataHttpResponse;

                        if (gameShareDataHttpResponse.game_share_img_url) {
                            const preLoadShareImage = new Image();
                            preLoadShareImage.src = gameShareDataHttpResponse.game_share_img_url;
                        }

                        this.openGameShareModal2();
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
        } else {
            this.openGameShareModal2();
        }
    }

    private openGameShareModal2() {
        if (this.currentUser) {
            this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet(
                this.tenantService.tenantData.id,
                this.game.game_unique_id,
                this.currentUser.id
            ).pipe(take(1))
            .subscribe({
                next: (gameInvitationListHttpResponse: GameInvitationListHttpResponse) => {
                    this.sentGameInvitations = gameInvitationListHttpResponse.results;
                    this.gameShareModalDefaultComponent.openGameShareModal();
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
        } else {
            this.gameShareModalDefaultComponent.openGameShareModal();
        }

        this.googleAnalyticsService.trackEvent('gamecard - footer', 'show', 'sharemodal');
    }

    public showGameSpotLimit(): boolean {
        return (
            !this.game.is_current_user_joined &&
            this.tenantData.configuration.show_game_spots_left &&
            this.game.game_spots_left_limit > 0 &&
            (this.game.size - this.game.users_count) <= this.game.game_spots_left_limit
        );
    }

}
