/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataSportHttpResponse } from './appHttpResponsesSportsDataSportHttpResponse';


/**
 * TenantSportHttpResponse
 */
export interface AppHttpResponsesTenantsTenantSportHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Sport Id
     */
    sport_id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * User Generation Allowed
     */
    user_generation_allowed: boolean;
    /**
     * Order
     */
    order: number;
    /**
     * Count of available Tournaments
     */
    tournaments_count?: number;
    /**
     * Count of available Matches
     */
    matches_count?: number;
    sport?: AppHttpResponsesSportsDataSportHttpResponse;
}

