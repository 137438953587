<div class="ui vertical borderless very padded segment" *ngIf="processBets">
    <div class="ui active centered inline text loader color-white text-shadow">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>

<div *ngIf="widgetBets">
    <section id="widget-bets" class="card-group" #widgetBetsSection>
        <div *ngIf="widgetBets.length > 0">
            <div class="ui large fluid label card-group-type-label">
                <strong class="font-very-bold margin-r-10">{{'BETSNAPDETAIL.BETS.MENU.to_place' | translate | uppercase}}</strong>{{widgetBets.length}}
                <div class="jump-to" *ngIf="filteredBetsTotal > 0">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="'#placed-bets'"
                                                                       (click)="$event.preventDefault(); scrollToNextSection(placedBetsSection)">
                    {{'GENERAL.LABELS.placed' | translate | uppercase}} <i class="fa fa-long-arrow-down mini icon margin-0"></i>
                </a>
                </div>
            </div>

            <betsnaps-widget-bet
                *ngFor="let widgetBet of widgetBets"
                [widgetBet]="widgetBet"
                class="ui fluid card">
            </betsnaps-widget-bet>

            <div class="game-detail-buttons-wrap">
                <button type="button" class="ui large button game-detail-button"
                        *ngIf="game.is_current_user_joined"
                        [class.loading]="processWidgetBets" [disabled]="processWidgetBets"
                        (click)="initiatePlaceWidgetBets()">
                    {{(widgetBets.length > 1) ? ('GENERAL.BETS.place-bets' | translate | uppercase) : ('GENERAL.BETS.place-bet' | translate | uppercase)}}
                </button>
                <button type="button" class="ui ui large button game-detail-button"
                        *ngIf="!game.is_current_user_joined"
                        [class.loading]="processWidgetBets" [disabled]="processWidgetBets"
                        (click)="initiatePlaceWidgetBets()">
                    <strong>{{'GENERAL.BETS.join_and_place_bets_btn' | translate | uppercase}}</strong>
                    <div *ngIf="(game.entry_fee !== 0)" class="small text">
                        {{'GENERAL.GAMES.entry_fee' | translate}}: {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                    </div>
                    <div *ngIf="(game.entry_fee === 0)" class="small text">
                        {{'GENERAL.GAMES.entry_fee_free' | translate}}
                    </div>
                </button>
            </div>
        </div>
    </section>
</div>

<div *ngIf="!processBets && userBets && gameMatches">
    <section id="placed-bets" class="card-group" #placedBetsSection>
        <div *ngIf="filteredBetsTotal > 0">
            <div class="ui large fluid label card-group-type-label margin-b-15" *ngIf="widgetBets && widgetBets.length > 0">
                <strong class="font-very-bold margin-r-10">{{'GENERAL.LABELS.placed' | translate | uppercase}}</strong>{{filteredBetsTotal}}
                <div class="jump-to" *ngIf="filteredBetsTotal > 0">
                    {{'BETSNAPDETAIL.MATCHES.jump_to' | translate}} <a [href]="'#widget-bets'"
                                                                       (click)="$event.preventDefault(); scrollToNextSection(widgetBetsSection)">
                    {{'BETSNAPDETAIL.BETS.MENU.to_place' | translate | uppercase}} <i class="fa fa-long-arrow-up mini icon margin-0"></i>
                </a>
                </div>
            </div>
            <betsnaps-match-card-loader
                *ngFor="let gameMatch of activeGameMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
                [attr.id]="'match'+ gameMatch.match.match_id"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [match]="gameMatch.match"
                [currentUser]="authenticationService.currentUser"
                [activeBetsVisible]="true"
                [completedBetsVisible]="true"
                [isCancelled]="gameMatch.is_cancel"
                [gameUpdatedDate]="game.updated_at"
                [matchStatus]="gameMatch.match.status"
                [matchIndexInList]="matchIndexInList"
                [adPositions]="adPositions">
            </betsnaps-match-card-loader>
            <betsnaps-match-card-loader
                *ngFor="let gameMatch of closedGameMatches(); trackBy: matchesTrackBy; let matchIndexInList = index"
                [attr.id]="'match'+ gameMatch.match.match_id"
                [game]="game"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [match]="gameMatch.match"
                [currentUser]="authenticationService.currentUser"
                [activeBetsVisible]="true"
                [completedBetsVisible]="true"
                [isCancelled]="gameMatch.is_cancel"
                [gameUpdatedDate]="game.updated_at"
                [matchStatus]="gameMatch.match.status"
                [matchIndexInList]="matchIndexInList + activeGameMatches().length"
                [adPositions]="adPositions">
            </betsnaps-match-card-loader>
        </div>
        <div class="ui vertical borderless center aligned padded segment" *ngIf="filteredBetsTotal < 1 && (!widgetBets || widgetBets.length === 0)">
            <p class="color-white text-shadow">{{'GENERAL.no_results_found' | translate}}</p>
        </div>
    </section>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300"
    [additionalClass]="'betsnapdetail-details-scroll-to-top'"
    [class.notJoined]="!game.is_current_user_joined">
</betsnaps-go-to-top-button>
