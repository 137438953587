/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesUsersUserWalletHttpResponse } from './appHttpResponsesUsersUserWalletHttpResponse';
import { AppHttpResponsesUsersUserSettingHttpResponse } from './appHttpResponsesUsersUserSettingHttpResponse';


/**
 * PlayerHttpResponse
 */
export interface AppHttpResponsesUsersPlayerHttpResponse { 
    /**
     * User Id
     */
    id: number;
    /**
     * Unique ID
     */
    unique_id: string;
    /**
     * B2b User ID
     */
    b2b_user_id?: string;
    /**
     * B2b User Session Token
     */
    b2b_user_token?: string;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Username
     */
    username?: string;
    /**
     * Is Username manually confirmed
     */
    is_username_confirmed: boolean;
    /**
     * E-Mail
     */
    email?: string;
    /**
     * is users email verified
     */
    is_email_verified: number;
    /**
     * Phone Number
     */
    phone?: string;
    /**
     * is users phone verified
     */
    is_phone_verified: number;
    /**
     * is User active (0... no, 1...yes)
     */
    is_active: number;
    /**
     * is User banned (0... no, 1...yes)
     */
    is_banned: number;
    /**
     * First name
     */
    first_name?: string;
    /**
     * Last name
     */
    last_name?: string;
    /**
     * Gender
     */
    gender?: string;
    /**
     * Date of Birth YYYY-mm-dd
     */
    dob?: string;
    /**
     * Media ID
     */
    media_id?: number;
    /**
     * Media Name
     */
    media_name?: string;
    /**
     * Absolute Media URL
     */
    media_url?: string;
    /**
     * Absolute Media URL (Thumb Big)
     */
    media_url_big?: string;
    /**
     * Absolute Media URL (Thumb Small)
     */
    media_url_small?: string;
    /**
     * Phone Code
     */
    phone_code?: string;
    /**
     * Phone No
     */
    phone_no?: string;
    /**
     * Facebook ID
     */
    facebook_id?: string;
    /**
     * Twitter ID
     */
    twitter_id?: string;
    /**
     * Google ID
     */
    google_id?: string;
    /**
     * ZIP Code
     */
    zip_code?: string;
    /**
     * Country
     */
    country?: string;
    /**
     * City
     */
    city?: string;
    /**
     * State ID
     */
    state_id?: number;
    settings?: AppHttpResponsesUsersUserSettingHttpResponse;
    /**
     * User Wallets
     */
    wallets?: Array<AppHttpResponsesUsersUserWalletHttpResponse>;
    /**
     * Tag Ids
     */
    tag_ids?: Array<number>;
}

