import { Component, OnInit, ViewChild, ElementRef, HostListener, Directive } from '@angular/core';
import {
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersUserTransactionHttpResponse as UserTransactionHttpResponse,
    AppHttpResponsesUsersUserTransactionListHttpResponse as UserTransactionListHttpResponse
} from '../../../../api';
import {
    AuthenticationService, ErrorService,
    ProfileService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {HttpErrorResponse} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';

@Directive()
export abstract class AProfileWalletComponent implements OnInit {

    public currentUser: PlayerHttpResponse;
    public transactionsList: UserTransactionListHttpResponse;

    // Pagination
    protected perPage: number = 5;
    protected currentPage: number = 1;

    public processList: boolean = true;
    public processLoadMore: boolean = false;

    @ViewChild('loadMoreButton') public loadMoreButton: ElementRef;
    protected nativeWindow: Window;

    public showBalance: boolean = false;

    constructor(protected authenticationService: AuthenticationService,
                protected profileService: ProfileService,
                public tenantService: TenantService,
                protected windowRef: WindowRef,
                protected errorService: ErrorService,
                protected router: Router) {
        this.nativeWindow = windowRef.nativeWindow;
        this.showBalance = this.tenantService.tenantData.configuration.show_balance;
    }

    ngOnInit() {
        this.currentUser = this.authenticationService.currentUser;
        if (this.currentUser) {
            this.profileService.getUserTransactions(this.perPage, this.currentPage)
                .pipe(take(1))
                .subscribe({
                    next: (userTransactionListHttpResponse: UserTransactionListHttpResponse) => {
                        this.transactionsList = userTransactionListHttpResponse;
                        if (userTransactionListHttpResponse) {
                            this.processList = false;
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processList = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    loadMore(): void {
        if (this.transactionsList.results.length < this.transactionsList.total) {
            this.processLoadMore = true;
            this.currentPage = this.currentPage + 1;
            this.profileService.getUserTransactions(this.perPage, this.currentPage)
                .pipe(take(1))
                .subscribe({
                    next: (userTransactionListHttpResponse: UserTransactionListHttpResponse) => {
                        this.transactionsList.total = userTransactionListHttpResponse.total;
                        this.transactionsList.last_page = userTransactionListHttpResponse.last_page;
                        this.transactionsList.results.push(...userTransactionListHttpResponse.results);
                        this.processLoadMore = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processLoadMore = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    navigateToTarget(transaction: UserTransactionHttpResponse) {
        if (transaction.game) {
            this.router.navigate(['/betsnapdetail/' + transaction.game.game_unique_id + '/details']);
            return;
        }
        if (transaction.ranking) {
            this.router.navigate(['/ranking/' + transaction.ranking.id]);
            return;
        }
    }

    infiniteScroll() {
        if (typeof this.loadMoreButton !== 'undefined' && this.transactionsList.results.length < this.transactionsList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMore && !this.processList && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMore();
            }
        }
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    profileWalletListeners() {
        this.infiniteScroll();
    }

}
