/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * OauthAccessTokenCreateRequest
 */
export interface AppHttpRequestsAuthOauthAccessTokenCreateRequest { 
    /**
     * Client ID
     */
    client_id: number;
    /**
     * Type [\'player\', \'admin\', \'api_consumer\']
     */
    user_type: string;
    /**
     * Token Expire Date (format: Y-m-dTH:i:s)
     */
    access_token_expires_at?: string;
}

