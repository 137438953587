import {Component} from '@angular/core';

import {
    AuthenticationService, ErrorService, FriendsService, GoogleAnalyticsService, HintService, MyTranslateService,
    WindowRef
} from '../../../../../shared';
import {AFriendsListComponent} from '../a-friends-list.component';
import {take} from 'rxjs/operators';
import {AppHttpResponsesUsersPlayerPublicListHttpResponse as PlayerPublicListHttpResponse} from '../../../../../api';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-friends-list-default',
    templateUrl: './friends-list-default.component.html',
    styles: []
})
export class FriendsListDefaultComponent extends AFriendsListComponent {

    constructor(public authenticationService: AuthenticationService,
                protected friendsService: FriendsService,
                protected hintService: HintService,
                protected windowRef: WindowRef,
                public translations: MyTranslateService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService) {

        super(
            authenticationService,
            friendsService,
            hintService,
            windowRef,
            translations,
            googleAnalyticsService,
            errorService
        );
    }

    submitSearch() {
        if(!this.searchForm.invalid){
            this.searchActive = true;
            this.processSearchList = true;
            this.currentPage = 1;
            this.friendsService.searchUsers(
                this.perPage,
                this.currentPage,
                this.searchForm.value.searchstring,
                null,
                null
            ).pipe(take(1))
            .subscribe({
                next: (playerPublicList: PlayerPublicListHttpResponse) => {
                    if (playerPublicList) {
                        this.searchResultUsersList = playerPublicList;
                        this.processSearchList = false;
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.processSearchList = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
            this.googleAnalyticsService.trackEvent('friends', 'search', 'users');
        }
    }

    loadMore(): void {
        if (this.searchResultUsersList.results.length < this.searchResultUsersList.total) {
            this.processLoadMore = true;
            this.currentPage = this.currentPage + 1;
            this.friendsService.searchUsers(this.perPage, this.currentPage, this.searchForm.value.searchstring, null, null)
                .pipe(take(1))
                .subscribe({
                    next: (playerPublicList: PlayerPublicListHttpResponse) => {
                        this.searchResultUsersList.total = playerPublicList.total;
                        this.searchResultUsersList.last_page = playerPublicList.last_page;
                        this.searchResultUsersList.results.push(...playerPublicList.results);
                        this.processLoadMore = false;
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                });
        }
    }
}
