/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ChatHttpResponse
 */
export interface AppHttpResponsesChatsChatHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Chat Title
     */
    title: string;
    /**
     * Chat Title
     */
    is_closed: boolean;
    /**
     * Chat Users Count
     */
    users_count: number;
    /**
     * Unread Messages
     */
    unread_messages_count?: number;
    /**
     * Blocked User Ids
     */
    blocked_user_ids?: Array<number>;
    /**
     * Ghosted User Ids
     */
    ghosted_user_ids?: Array<number>;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

