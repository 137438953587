/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantLandingpageCreateRequest
 */
export interface AppHttpRequestsTenantsTenantLandingpageCreateRequest { 
    /**
     * Theme ID
     */
    theme_id?: number;
    /**
     * Landingpage Title
     */
    title: string;
    /**
     * Landingpage Description
     */
    description: string;
    /**
     * Show Highlights
     */
    show_highlights: boolean;
    /**
     * Show Features
     */
    show_features: boolean;
    /**
     * Show Widgets
     */
    show_widgets: boolean;
    /**
     * Highlights Title
     */
    highlights_title?: string;
    /**
     * Highlights Subtitle
     */
    highlights_subtitle?: string;
    /**
     * Widgets Title
     */
    widgets_title?: string;
    /**
     * Widgets Description
     */
    widgets_description?: string;
    /**
     * Active
     */
    active: boolean;
}

