import {Component, OnInit} from '@angular/core';
import {FuiBaseModal} from '@aligorji/ngx-fomantic-ui';
import {LoginRegisterViewSwitcherInterface} from '../../../shared';

interface ILoginRegisterModalContext {
    viewToShow: string;
}

@Component({
    selector: 'betsnaps-login-register-modal',
    templateUrl: './login-register-modal.component.html',
    styles: []
})
export class LoginRegisterModalComponent implements OnInit {

    public viewToShow: string;
    public socialSignupPlatform: string;

    public phoneVerificationPhone: string;
    public phoneVerificationInitialAlertMessage: { type: string, messagetranslatekey: string, messagetxttranslatekey: string };

    constructor(public modal: FuiBaseModal<ILoginRegisterModalContext, void, void>) {
        this.viewToShow = this.modal.context.viewToShow;
    }

    ngOnInit() {
    }

    switchView(viewSwitcher: LoginRegisterViewSwitcherInterface): void {

        this.resetViewInputValues();

        switch (viewSwitcher.targetView) {
            case 'phone-verification':
                    if (viewSwitcher.payload) {
                        if (viewSwitcher.payload.has('phone')) {
                            this.phoneVerificationPhone = viewSwitcher.payload.get('phone');
                        }
                        if (viewSwitcher.payload.has('initialAlertMessage')) {
                            this.phoneVerificationInitialAlertMessage = viewSwitcher.payload.get('initialAlertMessage');
                        }
                    }
                break;
        }

        this.viewToShow = viewSwitcher.targetView;
    }

    private resetViewInputValues() {
        this.phoneVerificationPhone = null;
        this.phoneVerificationInitialAlertMessage = null;
    }
}
