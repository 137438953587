<betsnaps-simple-game-card [game]="game" [backgroundImage]="backgroundImage" [secondsToStart]="secondsToStart"
                           (close)="closeSimpleSnapCard($event)" *ngIf="simpleSnapCardShow"></betsnaps-simple-game-card>
<ng-container *ngIf="!simpleSnapCardShow">
    <div
        [ngClass]="'ui fluid card element-clickable game-card-v3 ' + (backgroundImage ? 'withBgImage' : '') + ' tile-style-' + game.game_card_tile_style"
        [class.isWidget]="isWidget || isLandingpageWidget"
        [class.game-closed]="game.game_state > 3"
        [ngStyle]="{
        'background-image': (backgroundImage) ? backgroundImage : 'none'
     }"
        *ngVar="GameHelpers.getAdvancedButtonType(game) as advancedButtonType"
        (click)="gameCardClick()">
        <div class="content top container font-bold">
            <i [ngClass]="'right floated sport-icon icon bsi bsi-sport-' + game.sport_id"></i>
            <span class="ui game-state-label label"
                  [class.black]="backgroundImage && game.game_state !== 3"
                  [class.red]="game.game_state === 3"
                  [class.game-closed]="game.game_state > 3">
                <span *ngIf="(secondsToStart > 7200 || secondsToStart < 0) && game.game_state < 3">
                    <i class="ui schedule icon font-very-bold"></i> {{game.start_date | amLocal | amCalendar:
                    {
                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                        sameElse: tenantService.tenantData.internationalization.date_time_format
                    }
                    }}
                </span>
                <span *ngIf="secondsToStart <= 7200 && secondsToStart > 0 && game.game_state < 3">
                    <betsnaps-countdown #gameStartCountdown
                                        [timeInSeconds]="secondsToStart"
                                        [autoStart]="true"
                                        [secondsForHighlight]="secondsToStart"
                                        [hideAfterFinish]="true"
                                        (onCountdownFinished)="game.game_state = 3"></betsnaps-countdown>
                    <span *ngIf="gameStartCountdown?.hasFinished()">
                        <i class="ui lens icon"></i> {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                    </span>
                </span>
                <span *ngIf="game.game_state === 3">
                    <i class="ui lens icon"></i> {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                </span>
                <span *ngIf="game.game_state === 4 || game.game_state === 5 || game.game_state === 6">
                      {{'GENERAL.GAMES.STATI.finished' | translate | uppercase}}
                    {{game.end_date | amLocal | amCalendar:
                    {
                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                        sameElse: tenantService.tenantData.internationalization.date_time_format
                    }
                    }}
                </span>
                <span *ngIf="game.game_state === 7">
                    {{'GENERAL.GAMES.STATI.closed' | translate | uppercase}}
                    {{game.end_date | amLocal | amCalendar:
                    {
                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                        sameElse: tenantService.tenantData.internationalization.date_time_format
                    }
                    }}
                </span>
                <span *ngIf="game.game_state === 99">
                    {{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}
                </span>
            </span>
            <span class="ui game-state-label label green"
                  *ngIf="game.competition_type === 1 || game.competition_type === 3">
                {{'GENERAL.LABELS.h2h' | translate}}
            </span>
            <span class="ui game-state-label label purple"
                  *ngIf="game.competition_type === 5">
                <i class="icon bsi bsi-battle-royal tiny"></i>{{'GENERAL.LABELS.battle_royal' | translate}}
            </span>
        </div>
        <div class="content main"
             *ngVar="GameHelpers.getAdvancedButtonType(game) as advancedButtonType"
             (click)="gameCardClick()">
            <div class="content-main-inner" [class.centered-block]="game.competition_type === 5 && game.game_state <= 3">
                <div class="content-main-inner-text"
                     [class.user-joined]="currentUser && game.is_current_user_joined"
                     [class.text-shadow-strong]="backgroundImage">
                    <h2 class="game-name"
                        [class.margin-0]="!showPrizes"
                        [class.margin-b-30]="game.game_subtitle === null">
                        <betsnaps-game-name [game]="game"></betsnaps-game-name>
                    </h2>
                    <ng-container *ngIf="game.game_subtitle">
                        <hr class="decent margin-t-10 margin-b-5" [class.margin-l-0]="game.game_state > 3 || game.competition_type !== 5"/>
                        {{game.game_subtitle | dbtranslation:'game_subtitle':game.translations}}
                    </ng-container>
                    <div class="extra-info" *ngIf="showPrizes && game.game_state < 3">
                        <i class="bsi bsi-winnings icon extra-info-icon" *ngIf="game.competition_type !== 5"></i>
                        <ng-container *ngIf="GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                            <span *ngFor="let prizeStructure of GameHelpers.getGamePrizeStructuresWithPrizeTitleInGame(game.prize_structures); let i = index">
                                <ng-container *ngIf="i !== 0">&nbsp;+</ng-container>
                                {{prizeStructure.prize_title_in_game | dbtranslation:'prize_title_in_game':prizeStructure.translations}}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                            <span *ngIf="(game.is_gift_game === 0 && !game.is_guaranteed && game.competition_type === 2)">
                                <span
                                    *ngIf="game.current_prize_pool > 0 && game.primary_cash_prize_structure_index !== null">
                                    {{game.current_prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                                    <span
                                        *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                                </span>
                                <span
                                    *ngIf="game.current_prize_pool === 0 && game.primary_cash_prize_structure_index !== null">
                                    {{'GENERAL.GAMES.maximum_abbr' | translate}} {{game.prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                                    <span
                                        *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                                </span>
                            </span>
                            <span *ngIf="(game.is_gift_game === 0 && game.is_guaranteed && game.competition_type === 2)">
                                <span *ngIf="game.primary_cash_prize_structure_index !== null">
                                    {{game.prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                                    <span
                                        *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                                </span>
                            </span>
                            <span *ngIf="(game.is_gift_game === 1 && game.competition_type === 2)">
                                {{'GENERAL.GAMES.giftprizes' | translate}}
                            </span>
                        </ng-container>
                    </div>
                    <div class="extra-info" [ngClass]="{'left aligned display-flex margin-t-20': game.competition_type === 5}"
                         *ngIf="game.is_current_user_joined && game.game_state > 2 && game.game_state !== 99">
                        <i class="bsi bsi-medal bsi-bold icon extra-info-icon"></i>
                        <ng-container *ngIf="game.competition_type !== 5">
                            <span *ngIf="game.game_user.rank > 0">
                                {{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span> {{'GENERAL.GAMES.rank' | translate}}
                            </span>
                            <span *ngIf="game.game_user.rank === 0">
                                {{'GENERAL.GAMES.no_rank' | translate}}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="game.competition_type === 5">
                            <span *ngIf="game.game_user.lost_bets_count === 0 && (game.game_user.is_participation_valid === true || game.game_user.is_participation_valid === null)">
                                {{(game.game_state === 7 ? 'BETSNAPDETAIL.RANKING.battle_royal_player_won' : 'BETSNAPDETAIL.RANKING.in_the_game') | translate}}
                            </span>
                            <span *ngIf="game.game_user.lost_bets_count > 0 || game.game_user.is_participation_valid === false">
                                {{(game.game_state === 7 ? 'BETSNAPDETAIL.RANKING.battle_royal_player_lost' : 'BETSNAPDETAIL.RANKING.out_the_game') | translate}}
                            </span>
                        </ng-container>
                    </div>
                    <div class="extra-info" [ngClass]="{'left aligned display-flex': game.competition_type === 5}"
                         *ngIf="game.is_current_user_joined && game.game_state > 3 && game.game_state !== 99">
                        <i class="bsi bsi-winnings icon extra-info-icon"></i>
                        <span *ngIf="advancedButtonType === 'pastSnap'">
                        {{'GENERAL.GAMES.not_in_prize_ranks' | translate}}
                    </span>
                        <span *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 0">
                        <strong
                            *ngIf="game.game_user && game.prize_structures && game.primary_cash_prize_structure_index !== null">
                            {{game.game_user.prize_amount | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                        </strong> <span class="text-small"
                                        *ngIf="game.primary_cash_prize_structure_index !== null && game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true">
                            {{'GENERAL.LABELS.bet_bonus' | translate | uppercase}}
                        </span>
                    </span>
                        <span *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 1">
                        <strong *ngIf="getLeaderBoardUserProperty(game, 'tenant_prize')">
                            {{getLeaderBoardUserProperty(game, 'tenant_prize')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize')?.translations}}
                        </strong>
                        <strong *ngIf="!getLeaderBoardUserProperty(game, 'tenant_prize') && getLeaderBoardUserProperty(game, 'tenant_prize_template')">
                            {{getLeaderBoardUserProperty(game, 'tenant_prize_template')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize_template')?.translations}}
                        </strong>
                    </span>
                    </div>
                </div>
                <div class="content-main-inner-button"
                     *ngIf="game.game_card_tile_style !== gameCardTileStyleEnum.HALF_WIDTH">
                    <!--- SWITCH BTN --->
                    <button *ngIf="isSwitcher"
                            type="button"
                            class="ui fluid large button margin-0"
                            (click)="switchSnap();">
                        <span>{{'GENERAL.GAMES.switch_to_snap_btn' | translate}}</span>
                    </button>
                    <div *ngIf="!isSwitcher">
                        <ng-container *ngIf="(!currentUser || !game.is_current_user_joined)">
                            <ng-container *ngIf="battleRoyalMissingMarkets">
                                <button type="button"
                                        class="ui fluid large basic button margin-0"
                                        fuiPopup
                                        (click)="$event.stopPropagation()"
                                        [popupTemplate]="popupMissingMarketsInfo"
                                        popupTrigger="outsideClick"
                                        popupPlacement="top right">
                                    {{'GENERAL.GAMES.CARD-BUTTON.battle_royal_missing_markets' | translate}}
                                </button>
                                <ng-template let-popup #popupMissingMarketsInfo>
                                    <div class="content">
                                        {{'GENERAL.GAMES.CARD-BUTTON.battle_royal_missing_markets_popup_text' | translate}}
                                    </div>
                                </ng-template>
                            </ng-container>

                            <!--- PRE UNJOINED BTN --->
                            <button *ngIf="!battleRoyalMissingMarkets"
                                    type="button"
                                    class="ui fluid large button margin-0"
                                    [class.padding-10]="game.entry_fee !== 0">
                                <span class="nowrap">{{(battleRoyalMissingMarkets) ? 'GENERAL.GAMES.CARD-BUTTON.battle_royal_missing_markets' : 'GENERAL.GAMES.CARD-BUTTON.pre_unjoined' | translate}}</span>
                                <span *ngIf="game.entry_fee !== 0"
                                      class="nowrap text-small font-normal display-block">
                                {{'GENERAL.GAMES.entry_fee' | translate}}
                                    : {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                                </span>
                            </button>
                        </ng-container>
                        <div *ngIf="currentUser && game.is_current_user_joined">
                            <!--- PRE JOINED BTN --->
                            <button *ngIf="game.game_state < 3"
                                    type="button"
                                    class="ui fluid large button margin-0 grey">
                                <span>{{((game.sport_id === 999) ? 'GENERAL.GAMES.CARD-BUTTON.pre_joined_casino' : ((game.competition_type !== 5 || (game.competition_type === 5 && (game.current_user_bet_count < game.simple_markets_count))) ? 'GENERAL.GAMES.CARD-BUTTON.pre_joined' : 'GENERAL.GAMES.SIMPLE.success_button_text')) | translate}}</span>
                            </button>
                            <!--- LIVE JOINED BTN --->
                            <button *ngIf="game.game_state === 3"
                                    type="button"
                                    class="ui fluid large button margin-0 grey">
                            <span>{{((game.sport_id === 999)
                                ? (game.size === 1
                                    ? (activeParticipation ? 'GENERAL.VENDORGAMES.resume_participation' : 'GENERAL.VENDORGAMES.start_participation')
                                    : 'GENERAL.GAMES.CARD-BUTTON.live_casino')
                                : ((game.competition_type !== 5 && game.bet_placement_option !== gameBetPlacementOptionEnum.ONLY_PRE_GAME) ? 'GENERAL.GAMES.CARD-BUTTON.live' : 'BETSNAPDETAIL.MENU.ranking')) | translate}}</span>
                            </button>
                            <!--- PAST JOINED BTN --->
                            <button *ngIf="game.game_state > 3 && game.game_state !== 99"
                                    type="button"
                                    class="ui fluid large button margin-0 grey">
                                <span>{{'GENERAL.GAMES.CARD-BUTTON.past' | translate}}</span>
                            </button>
                            <button *ngIf="game.game_state === 99"
                                    type="button"
                                    class="ui fluid large button margin-0 grey">
                                <span>{{'GENERAL.GAMES.STATI.cancelled' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
