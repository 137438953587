<div class="content head">
    <div class="ui middle aligned grid">
        <div class="fourteen wide column">
            <div class="text-mini color-light-grey">
                <span *ngIf="match.status === 'cancelled'">
                    {{'GENERAL.MATCHES.STATI.cancelled' | translate | uppercase}}
                </span>
                <span *ngIf="match.status === 'postponed'">
                    {{'GENERAL.MATCHES.STATI.postponed' | translate | uppercase}}
                </span>
                <div *ngIf="match.status === 'not_started' || match.status === 'scheduled' || match.status === 'delayed' || match.status === 'closed' || match.status === 'ended' ||
                            (match.status === 'live' && !match.score)">
                    <span *ngIf="match.status === 'delayed'">
                        {{'GENERAL.MATCHES.STATI.delayed' | translate | uppercase}},&nbsp;
                    </span><span *ngIf="match.status === 'closed' || match.status === 'ended'">
                        {{'GENERAL.MATCHES.STATI.finished' | translate | uppercase}},&nbsp;
                    </span>{{match.season_scheduled_date | amLocal | amCalendar:
                    {
                            sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                            nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                            nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                            lastDay: tenantService.tenantData.internationalization.date_time_format,
                            lastWeek: tenantService.tenantData.internationalization.date_time_format,
                            sameElse: tenantService.tenantData.internationalization.date_time_format
                        }
                    }}
                </div>
                <span *ngIf="match.status === 'interrupted' || match.status === 'suspended'">
                    {{'GENERAL.MATCHES.STATI.interrupted' | translate | uppercase}}
                </span>
                <span *ngIf="match.status === 'abandoned'">
                    {{'GENERAL.MATCHES.STATI.abandoned' | translate | uppercase}}
                </span>
                <span *ngIf="match.status === 'live' && matchHasStarted() && match.liveodds === 'booked' &&
                        match.score && match.score.match_status_code &&
                        feedService.feedLiveProducerUp"
                        class="display-flex match-time-status">
                    <i class="ui lens mini red icon"></i> {{'GENERAL.LABELS.live' | translate | uppercase}}
                    <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">
                        &nbsp;-&nbsp;{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations | uppercase}}
                    </span>
                    <span *ngIf="showMatchTime()">
                        <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.remaining_time_in_period">&nbsp;< {{match.score.remaining_time_in_period}}&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})</span></span>
                        <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.matchtime">&nbsp;-&nbsp;{{match.score.matchtime | matchtime}}'&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})</span>
                    </span>
                    </span>
                    <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">
                        &nbsp;-&nbsp;{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations | uppercase}}
                    </span>
                </span>
            </div>
            <div class="text-tiny truncate color-grey" fuiPopup
                 popupText="{{match.tournament.category_name | dbtranslation:'category_name':match.tournament.translations}}, {{match.tournament.name | dbtranslation:'name':match.tournament.translations}}"
                 popupTrigger="outsideClick">
                {{match.tournament.category_name | dbtranslation:'category_name':match.tournament.translations | uppercase}}, {{match.tournament.name | dbtranslation:'name':match.tournament.translations | uppercase}}
            </div>
        </div>
        <div class="two wide column right aligned">
            <img class="iconimage" [src]="categoryImageSrc" alt="{{match.tournament.category_name}}" />
        </div>
    </div>
</div>
<div class="content sport-event sport-{{game.sport_id}}">
    <betsnaps-match-card-details-v3
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="![SportEnum.BASKETBALL, SportEnum.ICE_HOCKEY, SportEnum.TENNIS, SportEnum.CRICKET].includes(match.sport_id)"></betsnaps-match-card-details-v3>
    <betsnaps-match-card-details-basketball-v3
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.BASKETBALL"></betsnaps-match-card-details-basketball-v3>
    <betsnaps-match-card-details-tennis-v3
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.TENNIS"></betsnaps-match-card-details-tennis-v3>
    <betsnaps-match-card-details-cricket-v3
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.CRICKET"></betsnaps-match-card-details-cricket-v3>
    <betsnaps-match-card-details-ice-hockey-v3
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.ICE_HOCKEY"></betsnaps-match-card-details-ice-hockey-v3>
</div>

<div class="content sr-widget sr-widget-{{match.match_id}}" id="sr-widget-{{match.match_id}}" *ngIf="showWidget()"></div>
<div id="sr-widget-script-{{match.match_id}}" *ngIf="showWidget()"></div>

<div class="content user-bets"
     *ngIf="!isLeaderBoardMatch && activeBets && activeBets.length > 0 && activeBetsVisible"
     [class.padding-0]="!activeBetsVisible">
    <betsnaps-user-bet-v3
        *ngFor="let activeBet of activeBets; trackBy: userBetsTrackBy"
        [gameUpdatedDate]="game?.updated_at"
        [marketUpdatedDate]="activeBet.market.updated_date"
        [userbetUpdatedDate]="activeBet.modified_date"
        [matchLiveodds]="match.liveodds"
        [matchStatus]="match.status"
        [marketStatus]="activeBet.market.market_status"
        [outcomeStatus]="activeBet.outcome.outcome_status"
        [userbetStatus]="activeBet.status"
        [userBet]="activeBet"
        [game]="game"
        [match]="match"
        [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
        class="user-bet-v3">
    </betsnaps-user-bet-v3>
</div>
<div class="content user-bets"
     *ngIf="completedBets && completedBets.length > 0 && completedBetsVisible">
    <betsnaps-user-bet-v3
        *ngFor="let completedBet of completedBets; trackBy: userBetsTrackBy"
        [gameUpdatedDate]="game?.updated_at"
        [marketUpdatedDate]="completedBet.market.updated_date"
        [userbetUpdatedDate]="completedBet.modified_date"
        [matchLiveodds]="match.liveodds"
        [matchStatus]="match.status"
        [marketStatus]="completedBet.market.market_status"
        [outcomeStatus]="completedBet.outcome.outcome_status"
        [userbetStatus]="completedBet.status"
        [userBet]="completedBet"
        [game]="game"
        [match]="match"
        [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
        class="user-bet-v3">
    </betsnaps-user-bet-v3>
</div>
<div class="content completed-bets-total"
     *ngIf="completedBets && completedBets.length > 0 && completedBetsVisible && (!activeBetsVisible || activeBets.length < 1)">
    <div class="ui middle aligned equal width grid">
        <div class="row">
            <div class="column">
                <strong class="font-very-bold">{{'GENERAL.BETS.match_total' | translate | uppercase}}</strong>
            </div>
            <div class="right aligned column">
                <strong class="font-very-bold">
                    <span *ngIf="completedBetsTotalPoints > 0">+</span>{{completedBetsTotalPoints | number:'1.2-2'}}
                </strong>
            </div>
        </div>
    </div>
</div>

<div class="content top-market padding-t-0"
     [hidden]="!betPlacementAllowed"
     *ngIf="!isLeaderBoardMatch && game.game_state < 4 && !isMatchCompleted() &&
            match.default_market && match.default_market.outcomes && marketsVisible">
    <div class="ui middle aligned equal width tight grid" id="default-market-{{match.match_id}}-{{match.default_market?.market_id}}-{{match.default_market?.specifier_val}}">
        <div class="row" *ngIf="match.default_market && match.default_market.outcomes">
            <div *ngFor="let outcome of match.default_market.outcomes; trackBy: outcomesTrackBy" class="column">
                <betsnaps-market-outcome-button-v3
                    [match]="match"
                    [game]="game"
                    [market]="match.default_market"
                    [outcome]="outcome"
                    [matchStatus]="match.status"
                    [marketUpdatedDate]="match.default_market.updated_date"
                    [marketStatus]="match.default_market.market_status"
                    [outcomeStatus]="outcome.outcome_status"
                    [oddDecimal]="outcome.odd_decimal"
                    [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
                    [gameState]="game.game_state"
                    [liveodds]="match.liveodds"
                    [betPlacementAllowed]="betPlacementAllowed"
                    (onPlaceBetClick)="openPlaceBetModal(match.default_market, outcome)"
                    [isUserBetAvailable]="game.points_engine === gamePointsEngineEnum.PREDICTION ? betsnapdetailService.hasUserBetOnMarketOutcomeSpecifier(match.match_id, match.default_market.market_id, outcome.specifier_val, outcome.outcome_id) : false"
                    [forceDisabled]="game.points_engine === gamePointsEngineEnum.PREDICTION ? betsnapdetailService.hasUserBetOnMarketOutcomeSpecifier(match.match_id, match.default_market.market_id, outcome.specifier_val)  : false">
                </betsnaps-market-outcome-button-v3>
            </div>
        </div>
    </div>
</div>

<div class="content special-markets element-clickable"
     [class.padding-t-0]="!match.default_market"
     *ngIf="!isLeaderBoardMatch && game.game_state < 4 && !isMatchCompleted() &&
            match.markets_count > 0 && marketsVisible && betPlacementAllowed"
     (click)="googleAnalyticsService.trackEvent('matchcard', 'show', 'special markets'); openSpecialMarketsModal()">
    <div class="ui middle aligned grid">
        <div class="fourteen wide column">
            {{'GENERAL.MATCHES.MARKETS.see_all_markets' | translate | uppercase}} ({{match.markets_count}})
        </div>
        <div class="two wide column right aligned">
            <i class="keyboard arrow right big icon margin-0"></i>
        </div>
    </div>
</div>

<div class="content no-markets"
     *ngIf="(match.status === 'not_started' || match.status === 'scheduled' || match.status === 'delayed') &&
            match.markets_count === 0 && !match.default_market && marketsVisible">
    <p class="center aligned margin-0">
        <strong>{{'GENERAL.MATCHES.currently_no_market_data_available' | translate}}</strong>
    </p>
</div>

<div class="content no-markets"
     *ngIf="!isMatchCompleted() && !betPlacementAllowed && (match.markets_count > 0 || match.default_market) && marketsVisible">
    <p class="center aligned margin-0" *ngIf="feedService.feedPrematchProducerUp && feedService.feedLiveProducerUp">
        <strong *ngIf="match.liveodds !== 'booked'">
            {{'ERRORS.GENERAL.live_odds_not_available' | translate}}
        </strong>
        <strong *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_PRE_GAME">
            {{'ERRORS.GENERAL.only_pre_game_bets_allowed' | translate}}
        </strong>
        <strong *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_PRE_MATCH">
            {{'ERRORS.GENERAL.only_pre_match_bets_allowed' | translate}}
        </strong>
        <strong
            *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_LIVE && match.liveodds === 'booked'">
            {{'ERRORS.GENERAL.only_live_bets_allowed' | translate}}
        </strong>
    </p>
    <p class="center aligned margin-0" *ngIf="!feedService.feedPrematchProducerUp || !feedService.feedLiveProducerUp">
        <strong>{{'GENERAL.MATCHES.no_markets_feed_product_down' | translate}}</strong>
    </p>
</div>

<ng-template let-context let-modal="modal" #specialMarketsModalTemplate
             *ngIf="match.markets_count > 0 && marketsVisible">
    <div class="header special-markets-modal-header center aligned slim">
        <div class="center aligned text-tiny color-light-grey">
            <span *ngIf="match.status === 'live' && matchHasStarted() && match.liveodds === 'booked' &&
                    match.score && match.score.match_status_code &&
                    feedService.feedLiveProducerUp"
                    class="display-inline-flex match-time-status">
                <i class="ui lens mini red icon"></i> {{'GENERAL.LABELS.live' | translate | uppercase}}
                <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">
                    &nbsp;-&nbsp;{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations | uppercase}}
                </span>
                <span *ngIf="showMatchTime()">
                    <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.remaining_time_in_period">&nbsp;-&nbsp;< {{match.score.remaining_time_in_period}}&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})</span></span>
                    <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.matchtime">&nbsp;-&nbsp;{{match.score.matchtime | matchtime}}'&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})</span>
                </span>
                </span>
                <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">
                    &nbsp;-&nbsp;{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations | uppercase}}
                </span>
            </span>
        </div>
        <div class="ui middle aligned grid center aligned text-large">
            <div class="seven wide column right aligned">
                <span *ngIf="match.home" class="truncate">
                    {{match.home.name | dbtranslation:'name':match.home?.translations}}
                </span>
                <span *ngIf="!match.home" class="truncate">
                    {{match.home_name}}
                </span>
            </div>
            <div class="two wide column">
                -
            </div>
            <div class="seven wide column left aligned">
                <span *ngIf="match.away" class="truncate">
                    {{match.away.name | dbtranslation:'name':match.away?.translations}}
                </span>
                <span *ngIf="!match.away" class="truncate">
                    {{match.away_name}}
                </span>
            </div>
        </div>
        <div class="center aligned color-light-grey text-small font-normal" *ngIf="game.is_current_user_joined && game?.game_user && game.game_state !== 99 && (game.points_engine !== gamePointsEngineEnum.PREDICTION)">
            {{game.game_user.user_game_points | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.free_points' | translate}}
            <span *ngIf="game.game_state > 2 && game.game_user.rank === 1 && game.game_user.points_in_lead !== null && game.game_user.is_participation_valid !== false">
                &nbsp;-&nbsp;{{game.game_user.points_in_lead | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_in_lead' | translate}}
            </span>
            <span *ngIf="game.game_state > 2 && (game.game_user.rank > 1 || (game.game_user.rank === 1 && game.game_user.points_in_lead === null)) && game.game_user.is_participation_valid !== false">
                &nbsp;-&nbsp;{{game.game_user.points_to_first | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_to_first' | translate}}
            </span>
        </div>
    </div>
    <div class="scrolling content specialmarkets-modal-content">
        <div class="ui vertical borderless very padded segment" *ngIf="!matchMarkets">
            <div class="ui active centered inline text loader">
                {{'GENERAL.loadertext' | translate}}
            </div>
        </div>
        <div *ngIf="matchMarkets && matchMarkets.length > 0">
            <betsnaps-special-market-v3
                *ngFor="let market of matchMarkets; trackBy: marketsTrackBy"
                [attr.id]="'market-' + market.market_id + '-' + market.specifier_val"
                [currentUser]="currentUser"
                [game]="game"
                [match]="match"
                [market]="market"
                [isCurrentUserJoined]="game.is_current_user_joined"
                [gameUpdatedDate]="game.updated_at"
                [marketStatus]="market.market_status"
                [matchStatus]="match.status"
                (onPlaceBetClick)="openPlaceBetModal($event.market, $event.outcome)"
                class="market-accordion">
            </betsnaps-special-market-v3>
        </div>
    </div>
</ng-template>


<ng-template let-context let-modal="modal" #placeBetModalTemplate
             *ngIf="marketsVisible && game.game_state < 4 && !isLeaderBoardMatch && !isMatchCompleted()">
    <i class="close icon" *ngIf="placeBetDialog && !placeBetDialog.placeBetProcess" (click)="closePlaceBetModal()"></i>
    <div class="header center aligned slim">
        <div class="header special-markets-modal-header center aligned tight">
            <div class="center aligned text-tiny color-light-grey">
            <span *ngIf="match.status === 'live' && matchHasStarted() && match.liveodds === 'booked' &&
                    match.score && match.score.match_status_code &&
                    feedService.feedLiveProducerUp"
                  class="display-inline-flex match-time-status">
                <i class="ui lens mini red icon"></i> {{'GENERAL.LABELS.live' | translate | uppercase}}
                <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">
                    &nbsp;-&nbsp;{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations | uppercase}}
                </span>
                <span *ngIf="showMatchTime()">
                    <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.remaining_time_in_period">&nbsp;-&nbsp;< {{match.score.remaining_time_in_period}}&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})</span></span>
                    <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.matchtime">&nbsp;-&nbsp;{{match.score.matchtime | matchtime}}'&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})</span>
                </span>
                </span>
                <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">
                    &nbsp;-&nbsp;{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations | uppercase}}
                </span>
            </span>
            </div>
            <div class="ui middle aligned grid center aligned text-large">
                <div class="seven wide column right aligned">
                <span *ngIf="match.home" class="truncate">
                    {{match.home.name | dbtranslation:'name':match.home?.translations}}
                </span>
                    <span *ngIf="!match.home" class="truncate">
                    {{match.home_name}}
                </span>
                </div>
                <div class="two wide column">
                    -
                </div>
                <div class="seven wide column left aligned">
                <span *ngIf="match.away" class="truncate">
                    {{match.away.name | dbtranslation:'name':match.away?.translations}}
                </span>
                    <span *ngIf="!match.away" class="truncate">
                    {{match.away_name}}
                </span>
                </div>
            </div>
            <div class="center aligned color-light-grey text-small font-normal" *ngIf="game.is_current_user_joined && game?.game_user && game.game_state !== 99 && (game.points_engine !== gamePointsEngineEnum.PREDICTION)">
                {{game.game_user.user_game_points | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.free_points' | translate}}
                <span *ngIf="game.game_state > 2 && game.game_user.rank === 1 && game.game_user.points_in_lead !== null && game.game_user.is_participation_valid !== false">
                    &nbsp;-&nbsp;{{game.game_user.points_in_lead | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_in_lead' | translate}}
                </span>
                <span *ngIf="game.game_state > 2 && (game.game_user.rank > 1 || (game.game_user.rank === 1 && game.game_user.points_in_lead === null)) && game.game_user.is_participation_valid !== false">
                    &nbsp;-&nbsp;{{game.game_user.points_to_first | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_to_first' | translate}}
                </span>
            </div>
        </div>
    </div>
    <div class="scrolling content" *ngIf="placeBetMarket && placeBetOutcome">
        <div [ngClass]="'font-very-bold color-light-grey margin-b-10 ' + ((game.points_engine === gamePointsEngineEnum.PREDICTION) ? 'text-large' : 'text-small')">
            {{placeBetMarket.market_name | dbtranslation:'name':placeBetMarket.translations | uppercase}} | {{'GENERAL.BETS.your-tip' | translate | uppercase}}: {{placeBetOutcome.outcome_name | dbtranslation:'name':placeBetOutcome.translations | uppercase}}
        </div>
        <betsnaps-place-bet-dialog-v3
            #placeBetDialog
            [match]="match"
            [marketUpdatedDate]="placeBetMarket.updated_date"
            [market]="placeBetMarket"
            [outcome]="placeBetOutcome"
            [currentUser]="currentUser"
            [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
            (betPlaced)="closePlaceBetModal()"
            class="place-bet-dialog-v3">
        </betsnaps-place-bet-dialog-v3>
    </div>
</ng-template>
