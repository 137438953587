/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantChatSettingsUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantChatSettingsUpdateRequest { 
    /**
     * Public Chat enabled
     */
    chat_public_enabled: boolean;
    /**
     * Private Chat enabled
     */
    chat_private_enabled: boolean;
    /**
     * Chat Message Limit
     */
    chat_message_limit?: number;
    /**
     * Chat Message Limit Period in Seconds
     */
    chat_message_limit_period_in_seconds?: number;
    /**
     * Chat Ghosting Block Limit
     */
    chat_ghosting_block_limit?: number;
}

