/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UniqueWinnersHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsUniqueWinnersHttpResponse { 
    /**
     * Week of Year
     */
    week_of_year: number;
    /**
     * Count of All UniquePlayers for a Week
     */
    unique_active_players_count: number;
    /**
     * Count of All UniqueWinners for a Week
     */
    unique_winners_count: number;
}

