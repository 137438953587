/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * MatchStatusCodeHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchStatusCodeHttpResponse { 
    /**
     * Betradar Status Id
     */
    betradar_status_id: number;
    /**
     * Betradar Status
     */
    betradar_status?: string;
    /**
     * Score Status Translation
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

