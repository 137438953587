/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse';


/**
 * GameWinnerUserHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameWinnerUserHttpResponse { 
    /**
     * Tenant Id
     */
    tenant_id?: number;
    /**
     * User Id
     */
    user_id: number;
    /**
     * B2B User Id
     */
    b2b_user_id?: string;
    /**
     * Username
     */
    username?: string;
    /**
     * User E-Mail
     */
    email?: string;
    /**
     * User phone number
     */
    phone?: string;
    /**
     * Accounting Start-Date
     */
    account_date: Date;
    /**
     * Total Score
     */
    total_score: number;
    /**
     * Rank
     */
    rank: number;
    /**
     * Prize Amount
     */
    prize_amount: number;
    /**
     * Giftprize
     */
    giftprize?: string;
    /**
     * Tenant Prize Id
     */
    tenant_prize_id?: number;
    tenant_prize?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse;
}

