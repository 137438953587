/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantVendorGameUpdateRequest
 */
export interface AppHttpRequestsVendorGamesTenantVendorGameUpdateRequest { 
    /**
     * Vendor Game ID
     */
    vendor_game_id: number;
    /**
     * Name
     */
    name?: string;
    /**
     * Initial Game Image Media ID
     */
    initial_game_image_media_id?: number;
    /**
     * Is Active
     */
    is_active: boolean;
    /**
     * Is Single Player
     */
    is_single_player?: boolean;
}

