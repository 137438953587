import {
    ChangeDetectorRef,
    Component, ElementRef,
    Input,
    NgZone,
    OnInit,
    OnDestroy,
    ViewChild, OnChanges, SimpleChanges
} from '@angular/core';
import {
    AppHttpRequestsUsersUserEmailSignupRequest as UserEmailSignupRequest,
    AppHttpRequestsUsersUserPhoneSignupRequest as UserPhoneSignupRequest,
    AppHttpRequestsUsersUserSocialSignupRequest as UserSocialSignupRequest,
    AppHttpResponsesFinancialsCurrenciesPlayerCurrencyHttpResponse as PlayerCurrencyHttpResponse,
    AppHttpResponsesFinancialsCurrenciesPlayerCurrencyListHttpResponse as PlayerCurrencyListHttpResponse,
    AppHttpResponsesTenantsTenantFooterHttpResponse as TenantFooterHttpResponse,
    AppHttpResponsesTenantsTenantFooterListHttpResponse as TenantFooterListHttpResponse,
    AppHttpResponsesTenantsTenantInquiryAnswerHttpResponse as TenantInquiryAnswerHttpResponse,
    AppHttpResponsesTenantsTenantInquiryHttpResponse as TenantInquiryHttpResponse,
    AppHttpResponsesTenantsTenantInquiryListHttpResponse as TenantInquiryListHttpResponse,
    FinancialsApi,
    SignupApi
} from '../../../../api';
import {AlertMessage} from '../../../../shared/interfaces';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {areEqualValidator} from '../../../../shared/validators';
import {RecaptchaComponent} from 'ng-recaptcha';
import {
    AuthenticationService, B2cSignupMethodEnum, DbTranslationsPipe,
    ErrorService,
    MobiledetectService, RecaptchaDynamicLanguageLoaderService,
    SocialService,
    TenantService,
    WindowRef,
    GoogleAnalyticsService, TenantImageTypeEnum
} from '../../../../shared';
import {take, takeWhile} from 'rxjs/operators';
import {WrapperAppSocialSignInCallback} from '../../../../shared/interfaces/wrapperappsocialsignincallback-interface';
import {HttpErrorResponse} from '@angular/common/http';
import {ALoginRegisterSocialComponent} from '../a-login-register-social.component';

@Component({
    selector: 'betsnaps-register-form',
    templateUrl: './register-form.component.html',
    styles: []
})
export class RegisterFormComponent extends ALoginRegisterSocialComponent implements OnInit, OnDestroy, OnChanges {

    @Input() socialSignupPlatform: string;

    public playerCurrencies: PlayerCurrencyHttpResponse[];
    public tenantFooterItem: TenantFooterHttpResponse;

    public showSpinner: boolean = true;
    public showForm: boolean = true;
    public processForm: boolean = false;
    public submitted: boolean = false;
    public loginInProgress: boolean = false;

    public isDemoTenant: boolean = false;
    public isB2b: boolean = false;
    public b2cSignupMethod: string = B2cSignupMethodEnum.EMAIL;
    public b2cSignupMethodEnum = B2cSignupMethodEnum;

    public alertMessage: AlertMessage;
    public showAlert = false;
    public registerForm: UntypedFormGroup;
    public tenantImageTypeEnum = TenantImageTypeEnum;
    public tenantInquiries = [];
    public passwordVisible: boolean = false;
    public passwordConfirmVisible: boolean = false;

    private componentAlive = true;

    @ViewChild('passwordInput') public passwordInputElement: ElementRef;
    @ViewChild('passwordConfirmInput') public passwordConfirmInputElement: ElementRef;

    private registerFormEmailSignup = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        'passwordGroup': new UntypedFormGroup({
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
            password_confirm: new UntypedFormControl('', Validators.required),
        }, areEqualValidator),
        currency_id: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]+')]),
        invite_token: new UntypedFormControl(''),
        terms_checked: new UntypedFormControl(false, Validators.required)
    });
    private registerFormPhoneSignup = new UntypedFormGroup({
        phone_country_code: new UntypedFormControl('', [Validators.required]),
        phone: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9|\/|\(|\)|\x20]+'), Validators.minLength(4), Validators.maxLength(13)]),
        'passwordGroup': new UntypedFormGroup({
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
            password_confirm: new UntypedFormControl('', Validators.required),
        }, areEqualValidator),
        currency_id: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]+')]),
        invite_token: new UntypedFormControl(''),
        terms_checked: new UntypedFormControl(false, Validators.required)
    });

    private registerFormSocialSignup = new UntypedFormGroup({
        currency_id: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]+')]),
        invite_token: new UntypedFormControl(''),
        terms_checked: new UntypedFormControl(false, Validators.required)
    });

    public recaptchaStr: string = '';
    public recaptchaLoaderReady: boolean = false;
    @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;

    public isIOSPrivateBrowser: boolean = false;

    constructor(public tenantService: TenantService,
                protected authenticationService: AuthenticationService,
                private financialsApi: FinancialsApi,
                private signupApi: SignupApi,
                protected socialService: SocialService,
                protected router: Router,
                private errorService: ErrorService,
                protected windowRef: WindowRef,
                protected mobileDetect: MobiledetectService,
                protected zone: NgZone,
                protected changeDetector: ChangeDetectorRef,
                private recaptchaLoader: RecaptchaDynamicLanguageLoaderService,
                protected googleAnalyticsService: GoogleAnalyticsService) {

        super(tenantService, googleAnalyticsService, authenticationService, socialService, windowRef, zone, changeDetector, mobileDetect, router);

        this.isB2b = this.tenantService.isB2b;
        this.b2cSignupMethod = this.tenantService.tenantData.configuration.b2c_signup_method;

        if (this.b2cSignupMethod === this.b2cSignupMethodEnum.PHONE) {
            this.loadPhoneSignupAllowedCountryCodes();
            this.registerFormPhoneSignup.patchValue({'phone_country_code': this.phoneSignupAllowedCountryCodes[0]});
        }

        if (tenantService.tenantData.is_demo_tenant) {
            this.isDemoTenant = true;

            this.registerFormEmailSignup.addControl(
                'signup_reason', new UntypedFormControl('', [Validators.required])
            );
            this.registerFormPhoneSignup.addControl(
                'signup_reason', new UntypedFormControl('', [Validators.required])
            );
            this.registerFormSocialSignup.addControl(
                'signup_reason', new UntypedFormControl('', [Validators.required])
            );
        }

        this.setRegisterForm();
    }

    protected setRegisterForm(): void {
        switch (this.b2cSignupMethod) {
            case B2cSignupMethodEnum.PHONE:
                this.registerForm = this.registerFormPhoneSignup;
                break;
            default:
                this.registerForm = this.registerFormEmailSignup;
        }
    }

    ngOnInit() {

        // load the recaptcha component with the current language
        this.recaptchaLoader.loadLanguage(this.authenticationService.currentLang.iso_code2);
        this.recaptchaLoader.ready.pipe(
            takeWhile(() => this.componentAlive)
        ).subscribe(recaptchaLoaderReady => {
            this.recaptchaLoaderReady = (recaptchaLoaderReady);
        });

        this.registerForm.patchValue({'invite_token': localStorage.getItem('inviteToken')});

        this.getGeneralData();
    }

    ngOnDestroy() {
        if (this.captchaRef) {
            this.captchaRef.reset();
        }
        this.componentAlive = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.socialSignupPlatform && !changes.socialSignupPlatform.firstChange) {
            if (this.socialSignupPlatform) {
                this.startSocialSignUp(this.socialSignupPlatform);
            }
        }
    }

    onSubmit() {
        this.showAlert = false;
        if (!this.socialSignupPlatform) {
            switch (this.b2cSignupMethod) {
                case B2cSignupMethodEnum.PHONE:
                    this.phoneSignup();
                    break;
                default:
                    this.emailSignup();
            }
            return;
        }

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.socialSignUpWrapperApp(this.socialSignupPlatform);
            return;
        }

        this.socialService.getSocialUser(this.socialSignupPlatform)
            .then(
                (userData) => {
                    this.socialSignUp(this.socialSignupPlatform, userData.authToken);
                }
            );
    }

    startSocialSignUp(socialPlatform: string) {
        this.showForm = true;
        this.showAlert = false;
        this.processForm = false;
        this.resetForms();
        this.registerForm = this.registerFormSocialSignup;
    }

    detectChangesForWrapper() {
        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            setTimeout(() => {
                this.zone.run(() => {
                    this.changeDetector.detectChanges();
                });
            });
        }
    }

    socialSignUpWrapperApp(socialPlatform: string) {
        this.nativeWindow.window.socialSignUpWrapperAppCallback = function (wrapperAppSocialSignUpCallback: WrapperAppSocialSignInCallback) {
            if (wrapperAppSocialSignUpCallback !== null) {
                this.socialSignUp(
                    wrapperAppSocialSignUpCallback.type,
                    wrapperAppSocialSignUpCallback.token
                );
            }
            this.detectChangesForWrapper();
        }.bind(this);

        if (socialPlatform === 'facebook') {
            this.nativeWindow.window.BetSnapMobileWrapper.facebookLogin(['window.socialSignUpWrapperAppCallback']);
        } else if (socialPlatform === 'google') {
            this.nativeWindow.window.BetSnapMobileWrapper.googleLogin(['window.socialSignUpWrapperAppCallback']);
        }
    }

    emailSignup() {
        if (!this.processForm) {
            this.processForm = true;

            const userEmailSignupRequest: UserEmailSignupRequest = {
                'tenant_id': Number(this.tenantService.tenantData.id),
                'email': this.registerForm.value.email,
                'password': this.registerForm.value.passwordGroup.password,
                'password_confirmation': this.registerForm.value.passwordGroup.password_confirm,
                'currency_id': Number(this.registerForm.value.currency_id),
                'language_id': Number(this.authenticationService.currentLang.id)
            };

            this.addAdditionalPayloadToRequest(userEmailSignupRequest);

            if (localStorage.getItem('verification-redirect-url')) {
                userEmailSignupRequest.verification_redirect_url = localStorage.getItem('verification-redirect-url');
            }

            this.signupApi.apiUsersSignupEmailPost(
                userEmailSignupRequest
            ).pipe(take(1))
            .subscribe({
                next: () => {
                    this.googleAnalyticsService.trackEvent('register modal', 'register', 'email success');

                    if (localStorage.getItem('inviteToken')) {
                        localStorage.removeItem('inviteToken');
                    }

                    if (localStorage.getItem('verification-redirect-url')) {
                        localStorage.removeItem('verification-redirect-url');
                    }

                    this.alertMessage = {
                        type: 'success',
                        messagetranslatekey: 'REGISTER.register_successfully',
                        messagetxttranslatekey: 'REGISTER.register_successfully_txt'
                    };

                    if (this.isDemoTenant === true) {
                        this.alertMessage.messagetxttranslatekey = 'REGISTER.register_successfully_txt_demo';
                    }

                    this.showAlert = true;
                    this.submitted = true;
                    this.showForm = false;
                    this.processForm = false;

                },
                error: (err: HttpErrorResponse) => {
                    this.googleAnalyticsService.trackEvent('register modal', 'register', 'email failed');
                    this.processForm = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
        }
    }

    phoneSignup() {
        if (!this.processForm) {
            this.processForm = true;

            const phoneNumberWithCountryCode = this.registerForm.value.phone_country_code + this.registerForm.value.phone;

            const userPhoneSignupRequest: UserPhoneSignupRequest = {
                'tenant_id': Number(this.tenantService.tenantData.id),
                'phone': phoneNumberWithCountryCode,
                'password': this.registerForm.value.passwordGroup.password,
                'password_confirmation': this.registerForm.value.passwordGroup.password_confirm,
                'currency_id': Number(this.registerForm.value.currency_id),
                'language_id': Number(this.authenticationService.currentLang.id)
            };

            this.addAdditionalPayloadToRequest(userPhoneSignupRequest);

            if (localStorage.getItem('verification-redirect-url')) {
                userPhoneSignupRequest.verification_redirect_url = localStorage.getItem('verification-redirect-url');
            }

            this.signupApi.apiUsersSignupPhonePost(
                userPhoneSignupRequest
            ).pipe(take(1))
            .subscribe({
                next: () => {
                    this.googleAnalyticsService.trackEvent('register modal', 'register', 'phone success');

                    if (localStorage.getItem('inviteToken')) {
                        localStorage.removeItem('inviteToken');
                    }

                    if (localStorage.getItem('verification-redirect-url')) {
                        localStorage.removeItem('verification-redirect-url');
                    }

                    this.submitted = true;
                    this.showForm = false;
                    this.processForm = false;

                    this.showView('phone-verification', new Map([
                        ['phone', phoneNumberWithCountryCode],
                        ['initialAlertMessage', {
                            type: 'success',
                            messagetranslatekey: 'REGISTER.register_successfully',
                            messagetxttranslatekey: 'REGISTER.register_phone_successfully_txt'
                        }]
                    ]));

                },
                error: (err: HttpErrorResponse) => {
                    this.googleAnalyticsService.trackEvent('register modal', 'register', 'phone failed');
                    this.processForm = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
        }
    }

    socialSignUp(socialPlatform: string, authToken: string) {
        if (!this.processForm) {
            this.processForm = true;

            const socialSignupRequest: UserSocialSignupRequest = {
                'tenant_id': Number(this.tenantService.tenantData.id),
                'network': socialPlatform,
                'access_token': authToken,
                'currency_id': Number(this.registerForm.value.currency_id),
                'language_id': Number(this.authenticationService.currentLang.id)
            };

            this.addAdditionalPayloadToRequest(socialSignupRequest);

            this.signupApi.apiUsersSignupSocialPost(
                socialSignupRequest
            ).pipe(take(1))
            .subscribe({
                next: () => {
                    this.googleAnalyticsService.trackEvent('register modal', 'register', 'social success - ' + socialPlatform);

                    if (localStorage.getItem('inviteToken')) {
                        localStorage.removeItem('inviteToken');
                    }

                    if (this.isDemoTenant === true) {

                        this.alertMessage = {
                            type: 'success',
                            messagetranslatekey: 'REGISTER.register_successfully',
                            messagetxttranslatekey: 'REGISTER.register_successfully_txt_demo'
                        };
                        this.showAlert = true;

                        this.submitted = true;
                        this.showForm = false;

                    } else {

                        this.alertMessage = {
                            type: 'success',
                            messagetranslatekey: 'REGISTER.register_successfully',
                            messagetxttranslatekey: 'REGISTER.register_successfully_txt_social'
                        };
                        this.showAlert = true;

                        this.submitted = true;
                        this.showForm = false;
                        this.showSpinner = true;
                        this.loginInProgress = true;

                        this.authenticationService.sociallogin(socialPlatform, authToken)
                            .pipe(take(1))
                            .subscribe({
                                next: () => {
                                    this.GeneralHelpers.doLoginRedirectIfDefined(this.router);
                                    this.onFormSuccess.emit();
                                },
                                error: (err: HttpErrorResponse) => {
                                    this.processForm = false;
                                    this.errorService.handleHttpErrorResponse(err);
                                }
                            });
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.googleAnalyticsService.trackEvent('register modal', 'register', 'social failed - ' + socialPlatform);
                    this.processForm = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
        }
    }

    private addAdditionalPayloadToRequest(request: UserEmailSignupRequest | UserPhoneSignupRequest | UserSocialSignupRequest): void {

        if (this.isDemoTenant) {
            request.signup_reason = this.registerForm.value.signup_reason;
        }

        if (this.registerForm.value.invite_token) {
            request.invite_token = this.registerForm.value.invite_token;
        }

        // check for tenant inquiries user answers
        const tenantInquiriesFormData = this.getTenantInquiryUserAnswers(this.tenantInquiries, this.registerForm);
        if (tenantInquiriesFormData.length > 0) {
            request.user_inquiry_answers = JSON.stringify(tenantInquiriesFormData);
        }
    }

    getTenantInquiryUserAnswers(tenantInquiries, formGroup: UntypedFormGroup) {
        const tenantInquiriesFormData = [];

        // check for tenant inquiries user answers
        if (tenantInquiries.length > 0) {
            tenantInquiries.forEach(tenantInquiry => {
                if (this.registerForm.value[tenantInquiry['name']]) {
                    tenantInquiriesFormData.push({
                        'tenant_inquiry_id': tenantInquiry['id'],
                        'tenant_inquiry_answer_id': formGroup.value[tenantInquiry['name']]
                    });
                }
            });
        }

        return tenantInquiriesFormData;
    }

    getGeneralData(): void {

        this.showSpinner = true;
        this.showAlert = false;

        this.loadTermsAndConditionsLink();

        this.financialsApi.apiFinancialsTenantsTenantIdPlayercurrenciesGet(
            this.tenantService.tenantData.id
        ).pipe(take(1))
        .subscribe({
            next: (playerCurrencyListHttpResponse: PlayerCurrencyListHttpResponse) => {
                if (playerCurrencyListHttpResponse) {
                    this.playerCurrencies = playerCurrencyListHttpResponse.results;
                    if (this.playerCurrencies.length === 1) {
                        this.registerFormEmailSignup.patchValue({'currency_id': this.playerCurrencies[0].currency_id});
                        this.registerFormPhoneSignup.patchValue({'currency_id': this.playerCurrencies[0].currency_id});
                        this.registerFormSocialSignup.patchValue({'currency_id': this.playerCurrencies[0].currency_id});
                    }

                    // get tenant inquiries and possible answers
                    this.tenantService.getTenantInquiries()
                        .pipe(take(1))
                        .subscribe({
                            next: (tenantInquiryListHttpResponse: TenantInquiryListHttpResponse) => {
                                if (tenantInquiryListHttpResponse) {
                                    tenantInquiryListHttpResponse.results.forEach(
                                        (tenantInquiry: TenantInquiryHttpResponse) => {

                                            const tenantInquiryText = new DbTranslationsPipe(this.authenticationService).transform(tenantInquiry.text, 'text', tenantInquiry.translations);
                                            const tenantInquiryAnswers = [];

                                            tenantInquiry.answers.forEach(
                                                (tenantInquiryAnswer: TenantInquiryAnswerHttpResponse) => {
                                                    const tenantInquiryAnswerText = new DbTranslationsPipe(this.authenticationService).transform(tenantInquiryAnswer.text, 'text', tenantInquiryAnswer.translations);
                                                    tenantInquiryAnswers.push({
                                                        'id': tenantInquiryAnswer.id,
                                                        'text': tenantInquiryAnswerText,
                                                        'tenant_inquiry_id': tenantInquiry.id
                                                    });
                                                }
                                            );

                                            this.tenantInquiries.push({
                                                'id': tenantInquiry.id,
                                                'name': 'tenant_inquiry_' + tenantInquiry.id,
                                                'text': tenantInquiryText,
                                                'answers': tenantInquiryAnswers
                                            });
                                            this.registerFormEmailSignup.addControl(
                                                'tenant_inquiry_' + tenantInquiry.id, new UntypedFormControl('', [Validators.required])
                                            );
                                            this.registerFormPhoneSignup.addControl(
                                                'tenant_inquiry_' + tenantInquiry.id, new UntypedFormControl('', [Validators.required])
                                            );
                                            this.registerFormSocialSignup.addControl(
                                                'tenant_inquiry_' + tenantInquiry.id, new UntypedFormControl('', [Validators.required])
                                            );

                                        }
                                    );

                                    this.setRegisterForm();
                                    this.showSpinner = false;

                                    if (this.socialSignupPlatform) {
                                        this.startSocialSignUp(this.socialSignupPlatform);
                                    }
                                }
                            },
                            error: (err: HttpErrorResponse) => {
                                this.showSpinner = false;
                                this.errorService.handleHttpErrorResponse(err);
                            }
                        });
                }
            },
            error: (err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.errorService.handleHttpErrorResponse(err);
            }
        });
    }

    private loadTermsAndConditionsLink(): void {
        this.tenantService.tenantFooters$
            .pipe(takeWhile(() => this.tenantFooterItem === undefined))
            .subscribe({
                next: (tenantFooterList: TenantFooterListHttpResponse) => {
                    if (tenantFooterList) {
                        this.tenantFooterItem = tenantFooterList.results.find(
                            (footer: TenantFooterHttpResponse) => footer.label === 'Terms of use'
                        );
                    }
                },
                error: () => {
                    this.tenantFooterItem = null;
                }
            });
        this.tenantService.loadTenantFooterDataIfNecessary();
    }

    onRegisterClick(captchaRef: any): void {
        if (this.recaptchaStr) {
            captchaRef.reset();
        }
        captchaRef.execute();
    }

    resetForms() {
        const resetValues = {
            'terms_checked': false
        };
        if (this.playerCurrencies.length === 1) {
            resetValues['currency_id'] = this.playerCurrencies[0].currency_id;
        } else {
            resetValues['currency_id'] = '';
        }
        if (localStorage.getItem('inviteToken')) {
            resetValues['invite_token'] = localStorage.getItem('inviteToken');
        }
        if (this.tenantService.tenantData.is_demo_tenant) {
            resetValues['signup_reason'] = '';
        }
        if (this.tenantInquiries) {
            this.tenantInquiries.forEach(
                (tenantInquiry) => {
                    resetValues['tenant_inquiry_' + tenantInquiry.id] = '';
                }
            );
        }
        this.registerFormEmailSignup.reset(resetValues);
        this.registerFormPhoneSignup.reset(resetValues);
        this.registerFormSocialSignup.reset(resetValues);
    }

    public resolved(captchaResponse: string): void {
        this.recaptchaStr = captchaResponse;
        if (this.recaptchaStr) {
            this.onSubmit();
        }
    }

    togglePasswordVisibility(passwordInputElement: ElementRef): boolean {
        if (passwordInputElement.nativeElement.type === 'password') {
            passwordInputElement.nativeElement.type = 'text';
            return true;
        } else {
            passwordInputElement.nativeElement.type = 'password';
            return false;
        }
    }

    public goToB2bRegister(eventCategory: string = 'header') {
        this.googleAnalyticsService.trackEvent(eventCategory, 'register', 'b2b redirect');
        this.nativeWindow.location.href = this.tenantService.getB2bWebsiteRegisterUrl();
        return false;
    }
}
