import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse as LeaderboardUserListHttpResponse,
    AppHttpResponsesBetsnapsGamesGameUserHttpResponse as GameUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameUserListHttpResponse as GameUserListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse,
    AppHttpResponsesUsersPlayerPublicListHttpResponse as PlayerPublicListHttpResponse,
    UsersApi
} from '../../api';
import {ErrorService} from './error.service';
import {DebugService} from './debug.service';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PlayerService {

    protected publicPlayerIdsFetchQueue: Array<number> = [];
    protected publicPlayers: Array<PlayerPublicHttpResponse> = [];

    // Observable for publicPlayer Updates (only updates, not whole list is submitted)
    private publicPlayersUpdateSubject = new BehaviorSubject<PlayerPublicHttpResponse[]>(null);
    public publicPlayersUpdate$ = this.publicPlayersUpdateSubject.asObservable();

    constructor(private errorService: ErrorService,
                private debugService: DebugService,
                private usersApi: UsersApi) {
        this.debugService.writeMessageToConsoleLog('playerService', 'constructor');
    }

    public addPublicPlayerIdToFetchQueue(userId: number): void {
        if (!this.publicPlayerIdsFetchQueue.includes(userId)) {
            this.publicPlayerIdsFetchQueue.push(userId);
            this.debugService.writeMessageToConsoleLog('playerService', 'addPublicPlayerIdToFetchQueue:' + userId);
            const playersToFetchCount = this.publicPlayerIdsFetchQueue.length;
            const self = this;
            setTimeout(() => {
                if (playersToFetchCount === self.publicPlayerIdsFetchQueue.length) {
                    this.fetchPublicPlayers();
                }
            }, 1000);
        }
    }

    public fetchPublicPlayers(): void {
        const userIdsToFetch = this.publicPlayerIdsFetchQueue;
        this.publicPlayerIdsFetchQueue = [];
        if (userIdsToFetch.length > 0) {
            if (userIdsToFetch.length > 1) {
                this.loadPublicPlayersFromAPI(userIdsToFetch);
            } else {
                userIdsToFetch.forEach((userId: number) => {
                    this.loadPublicPlayerFromAPI(userId);
                });
            }
        }
    }

    public getStoredPublicPlayers(): PlayerPublicHttpResponse[] {
        return this.publicPlayers;
    }

    public getStoredPublicPlayer(userId: number): PlayerPublicHttpResponse | null {
        return this.publicPlayers.find(
            (publicPlayer: PlayerPublicHttpResponse) => publicPlayer.id === userId);
    }


    public storePublicPlayers(newPublicPlayers: Array<PlayerPublicHttpResponse>, updateObservable: boolean = true): void {
        const addedPublicPlayers: Array<PlayerPublicHttpResponse> = [];
        newPublicPlayers.forEach((publicPlayer: PlayerPublicHttpResponse) => {
            const added = this.storePublicPlayer(publicPlayer);
            if (added) {
                addedPublicPlayers.push(publicPlayer);
            }
        });

        // if new players where added, push public Players to Observable
        if (addedPublicPlayers.length > 0 && updateObservable) {
            this.publicPlayersUpdateSubject.next(addedPublicPlayers);
            this.debugService.writeMessageToConsoleLog('playerService', 'storePublicPlayers');
            this.debugService.writeMessageToConsoleLog('playerService', this.publicPlayers);
        }
    }

    public storePublicPlayer(newPlayer: PlayerPublicHttpResponse, updateObservable: boolean = false): boolean {
        const existingPlayerIndex = this.publicPlayers.findIndex(
            (publicPlayer: PlayerPublicHttpResponse) => publicPlayer.id === newPlayer.id);

        // Not yet persisted
        if (existingPlayerIndex === -1) {
            // Add new Object
            this.publicPlayers.push(newPlayer);
            if (updateObservable) {
                const addedPublicPlayers: Array<PlayerPublicHttpResponse> = [];
                addedPublicPlayers.push(newPlayer);
                this.publicPlayersUpdateSubject.next(addedPublicPlayers);
                this.debugService.writeMessageToConsoleLog('playerService', 'storePublicPlayer new: ' + newPlayer.id);
                this.debugService.writeMessageToConsoleLog('playerService', this.publicPlayers);
            }
            return true;
        } else {
            // Update with new Object
            this.debugService.writeMessageToConsoleLog('playerService', 'storePublicPlayer update: ' + newPlayer.id);
            if (this.publicPlayers[existingPlayerIndex]) {
                this.publicPlayers[existingPlayerIndex] = newPlayer;
            }
        }
        return false;
    }

    public storePublicPlayerFromPlayerHttpResponse(newPlayerHttpResponse: PlayerHttpResponse): void {
        const newPlayer: PlayerPublicHttpResponse = {
            id: newPlayerHttpResponse.id,
            b2b_user_id: newPlayerHttpResponse.b2b_user_id,
            username: newPlayerHttpResponse.username,
            is_active: newPlayerHttpResponse.is_active,
            media_id: newPlayerHttpResponse.media_id,
            media_name: newPlayerHttpResponse.media_name,
            media_url: newPlayerHttpResponse.media_url,
            media_url_big: newPlayerHttpResponse.media_url_big,
            media_url_small: newPlayerHttpResponse.media_url_small,
            tag_ids: newPlayerHttpResponse.tag_ids,
        };

        this.storePublicPlayer(newPlayer);
    }

    public storePublicPlayersFromLeaderboardUserslist(leaderboardUsersList: LeaderboardUserListHttpResponse): void {
        // Only if game is running
        if (leaderboardUsersList.results && leaderboardUsersList.results.length > 0) {
            const publicPlayers: Array<PlayerPublicHttpResponse> = [];
            leaderboardUsersList.results.forEach((leaderboardUser: LeaderboardUserHttpResponse) => {
                if (leaderboardUser.user) {
                    publicPlayers.push(leaderboardUser.user);
                }
            });
            if (publicPlayers.length > 0) {
                this.storePublicPlayers(publicPlayers);
            }
        }
    }

    public storePublicPlayersFromGameUserslist(gameUsersList: GameUserListHttpResponse): void {
        // Only if game is running
        if (gameUsersList.results && gameUsersList.results.length > 0) {
            const publicPlayers: Array<PlayerPublicHttpResponse> = [];
            gameUsersList.results.forEach((gameUser: GameUserHttpResponse) => {
                if (gameUser.user) {
                    publicPlayers.push(gameUser.user);
                }
            });
            if (publicPlayers.length > 0) {
                this.storePublicPlayers(publicPlayers);
            }
        }
    }

    public loadPublicPlayerFromAPI(userId: number): void {
        this.debugService.writeMessageToConsoleLog('playerService', 'loadPublicPlayerFromAPI:' + userId);
        this.usersApi.apiPublicUsersUserIdGet(
            userId
        ).pipe(take(1))
        .subscribe({
            next: (playerPublic: PlayerPublicHttpResponse) => {
                if (playerPublic) {
                    this.storePublicPlayer(playerPublic, true);
                }
            },
            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        });
    }

    protected loadPublicPlayersFromAPI(userIds?: Array<number>): void {

        if (!userIds) {
            return;
        }

        const chunk = (arr, size) => Array.from({length: Math.ceil(arr.length / size)}, (v, i) => arr.slice(i * size, i * size + size));
        // Create Chunks of User Ids: Max 20 at a time
        const userIdsChunked = chunk(userIds, 20);
        userIdsChunked.forEach((userIdsSingleChunk) => {
            let userIdsString = null;
            if (userIdsSingleChunk) {
                userIdsString = userIdsSingleChunk.join(',');
            }
            if (!userIdsString) {
                return;
            }

            this.debugService.writeMessageToConsoleLog('playerService', 'loadPublicPlayersFromAPI:' + userIdsString);
            this.usersApi.apiPublicUsersGet(
                null,
                null,
                null,
                null,
                null,
                userIdsString
            ).pipe(take(1))
            .subscribe({
                next: (playerPublicList: PlayerPublicListHttpResponse) => {
                    if (playerPublicList.results) {
                        if (playerPublicList.results.length > 0) {
                            this.storePublicPlayers(playerPublicList.results);
                        }
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
        });
    }
}
