import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {
    AdService,
    AppEventsBetsnapsGamesGameStateChanged as GameStateChanged,
    ArenaService,
    AuthenticationService,
    BroadcastingService,
    DebugService,
    ErrorService,
    FriendsService,
    GoogleAnalyticsService,
    HintService,
    MyModalService,
    MyNotificationsService,
    MyTranslateService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';
import {AArenaComponent} from '../a-arena-component';
import {filter, take, takeWhile} from 'rxjs/operators';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameHttpListResponse,
    AppHttpResponsesBetsnapsGamesGameUserHttpResponse as GameUserHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse,
    AppHttpResponsesTenantsTenantSportHttpResponse as TenantSportHttpResponse,
    AppHttpResponsesTenantsTenantSportListHttpResponse as TenantSportListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesTenantVendorGameHttpResponse as TenantVendorGameHttpResponse,
    AppHttpResponsesVendorGamesTenantVendorGameListHttpResponse as TenantVendorGameListHttpResponse
} from '../../../../api';
import {HttpErrorResponse} from '@angular/common/http';
import {ConnectionEvents} from 'ngx-laravel-echo-jn';
import {Subscription} from 'rxjs';
import {SinglePlayerGameService} from '../../../../shared/services/singleplayer-game.service';
import {GameCardTileStyleEnum} from '../../../../shared/enums/game-card-tile-style.enum';
import {cloneDeep} from 'lodash';
import {SwiperOptions} from 'swiper';

@Component({
    selector: 'betsnaps-arena-v3',
    templateUrl: './arena-v3.component.html',
    styles: []
})
export class ArenaV3Component extends AArenaComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentClass = 'standardview';

    public arenaMenu = [
        {
            'key': 'all',
            'translatekey': 'ARENA.MENU.allsnaps',
            'active': true
        },
        {
            'key': 'sports',
            'translatekey': 'ARENA.MENU.sports',
            'active': false
        },
        {
            'key': 'casino',
            'translatekey': 'ARENA.MENU.casino',
            'active': false
        }
    ];

    public availableSportIds: number[] = [];
    public casinoSportAvailable: boolean = false;

    public singlePlayerTenantVendorGameList: TenantVendorGameListHttpResponse;
    private singlePlayerTenantVendorGameListSubscription: Subscription;

    public highlightGameList: GameHttpListResponse;
    private highlightGameListSubscription: Subscription;

    public singlePlayerSwiperConfig: SwiperOptions = {
        pagination: {
            el: '.singleplayer-games-swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.singleplayer-games-swiper-navigation-button-next',
            prevEl: '.singleplayer-games-swiper-navigation-button-prev',
            disabledClass: 'disabled'
        },
        spaceBetween: 0,
        loop: false,
        simulateTouch: true
    };

    public showSinglePlayerSwiper: boolean = false;
    public showHighlightSwiper: boolean = false;

    private perPage: number = 10;

    constructor(protected authenticationService: AuthenticationService,
                protected broadcastingService: BroadcastingService,
                public tenantService: TenantService,
                protected arenaService: ArenaService,
                protected friendsService: FriendsService,
                protected windowRef: WindowRef,
                protected debugService: DebugService,
                protected renderer: Renderer2,
                protected adService: AdService,
                protected hintService: HintService,
                protected notificationsService: MyNotificationsService,
                public myModalService: MyModalService,
                public translations: MyTranslateService,
                protected router: Router,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService,
                protected pageVisibilityService: AngularPageVisibilityService,
                private singlePlayerGameService: SinglePlayerGameService) {
        super(
            authenticationService,
            broadcastingService,
            tenantService,
            friendsService,
            arenaService,
            windowRef,
            debugService,
            renderer,
            adService
        );

        this.arenaFilters.is_highlight = false;

        this.tenantService.getTenantSports(this.tenantService.tenantData.id)
            .pipe(take(1))
            .subscribe((tenantSportList: TenantSportListHttpResponse) => {
                if (tenantSportList) {
                    tenantSportList.results.forEach((tenantSport: TenantSportHttpResponse) => {
                        if (tenantSport.sport.id === 999) {
                            this.casinoSportAvailable = true;
                        } else {
                            this.availableSportIds.push(tenantSport.sport.id);
                        }
                    });
                }
            });

        if (this.broadcastingService.echoService) {
            this.broadcastingService.echoService.rawConnectionState.subscribe(
                (connectionEvent: ConnectionEvents) => {
                    if (this.currentUser && connectionEvent.type === 'reconnect') {
                        this.debugService.writeMessageToConsoleLog('update arena content on socket reconnect');
                        this.updateArenaContent();
                    }
                }
            );
        }

        // handle page suspends
        this.pageVisibilityService.$onPageVisibilityChange
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                if (visibilityState === AngularPageVisibilityStateEnum.VISIBLE && this.componentInForeground) {
                    this.updateArenaContent();
                }
            });

        this.arenaFilters.per_page = this.perPage;

        this.getHighlightGameList();
        this.getSinglePlayerTenantVendorGameList();

        this.singlePlayerGameService.singlePlayerGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((singlePlayerGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                if (singlePlayerGameParticipations) {
                    if (this.singlePlayerTenantVendorGameList && this.singlePlayerTenantVendorGameList.results && singlePlayerGameParticipations.length > 0) {
                        const firstVendorGameWithParticipationIndex = this.singlePlayerTenantVendorGameList.results.findIndex(vendorGame => vendorGame.vendor_game_id === singlePlayerGameParticipations[0].vendor_game_id);
                        if (firstVendorGameWithParticipationIndex > 0) {
                            this.singlePlayerSwiperConfig.initialSlide = firstVendorGameWithParticipationIndex;
                        }
                    }
                    this.hideLoadingSpinner();

                    if (this.singlePlayerTenantVendorGameList && this.singlePlayerTenantVendorGameList.results) {
                        this.showSinglePlayerSwiper = true;
                    }
                }
            });
    }

    ngOnInit() {
        this.componentInForeground = true;
        this.router.onSameUrlNavigation = 'reload';

        // check when reused component is current navigation
        this.componentUrl = this.router.url;
        this.router.events
            .pipe(
                filter(routerEvent => (routerEvent instanceof NavigationStart || routerEvent instanceof NavigationEnd)),
                takeWhile(() => this.componentAlive)
            ).subscribe((routerEvent) => {
            if (routerEvent instanceof NavigationEnd && routerEvent.urlAfterRedirects === this.componentUrl) {
                this.componentInForeground = true;
                if (this.router.onSameUrlNavigation === 'reload') {
                    this.arenaMenuChange('all');
                } else {
                    this.updateArenaContent();
                }

                this.router.onSameUrlNavigation = 'reload';

                this.hintService.checkForHintToDisplay('arena');
            } else if (routerEvent instanceof NavigationStart && this.componentInForeground && routerEvent.url !== this.componentUrl) {
                this.componentInForeground = false;
                this.router.onSameUrlNavigation = 'ignore';
                this.stopRunningRequests();
            }
        });

        // get current user updates
        this.authenticationService.currentUser$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (userData && !this.currentUser) {
                        this.currentUser = userData;
                        this.hintService.checkForHintToDisplay('arena');
                        this.updateArenaContent();
                        this.getSinglePlayerParticipations();
                    }
                    this.updateSideBarStickyDimensions();
                });

        this.updateArenaContent();
    }

    ngOnDestroy() {
        this.stopRunningRequests();

        if (this.singlePlayerTenantVendorGameListSubscription) {
            this.singlePlayerTenantVendorGameListSubscription.unsubscribe();
        }

        this.gameList = null;

        this.singlePlayerGameService.resetData();

        super.onDestroy();
    }

    arenaMenuChange(activatekey: string, event?: Event) {

        if (event) {
            event.preventDefault();
        }

        this.stopRunningRequests();

        this.activeTab = activatekey;

        for (const menuitem of this.arenaMenu) {
            menuitem.active = (menuitem.key === activatekey);
        }

        if (activatekey === 'sports') {
            this.arenaFilters.sport_id = this.availableSportIds.join(',');
        } else if (activatekey === 'casino') {
            this.arenaFilters.sport_id = '999';
        } else {
            this.arenaFilters.sport_id = undefined;
        }

        this.arenaFilters.current_page = 1;
        this.arenaFilters.per_page = this.perPage;

        this.processList = true;
        this.gameList = null;
        this.updateGameList();

        this.googleAnalyticsService.trackEvent('arena', 'filter', 'games - ' + activatekey);
    }

    reloadData() {
        this.stopRunningRequests();

        this.arenaFilters.current_page = 1;
        this.arenaFilters.per_page = this.perPage;

        this.processList = true;
        this.gameList = null;
        this.updateGameList();
        this.updatedDataAvailable = false;

        this.googleAnalyticsService.trackEvent('arena', 'click', 'reload gamelist');
    }

    protected updateArenaContent() {
        this.arenaFilters.current_page = 1;
        if (this.gameList && this.gameList.results.length > this.perPage) {
            this.arenaFilters.per_page = this.gameList.results.length;
        }
        this.updateGameList();

        this.getHighlightGameList();
        this.getSinglePlayerParticipations(true);

        this.updateSideBarStickyDimensions();
    }

    protected updateGameList() {
        this.updatedDataAvailable = false;

        this.stopRunningRequests();

        this.gameListSubscription = this.arenaService.getGameList(this.arenaFilters)
            .pipe(take(1))
            .subscribe({
                next: (response: GameHttpListResponse) => {
                    // place adds
                    if (!this.gameList) {
                        this.adPositions = this.adService.defineAdPosition('content', response.total);
                    }
                    this.gameList = response;

                    this.checkForEmptyGameList();

                    this.arenaFilters.per_page = this.perPage;
                    if (this.gameList.results.length > this.perPage) {
                        this.arenaFilters.current_page = Math.ceil(this.gameList.results.length / this.perPage);
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.hideLoadingSpinner();
                    this.errorService.handleHttpErrorResponse(err);
                },
                complete: () => {
                    this.gameListSubscription.unsubscribe();
                    this.gameListSubscription = null;
                }
            });
    }

    private hideLoadingSpinner() {
        if (this.gameList && this.singlePlayerTenantVendorGameList) {
            this.processList = false;
        }
    }

    loadMore(): void {
        if (this.gameList.results.length < this.gameList.total && !this.gameListSubscription) {
            this.processLoadMore = true;
            this.arenaFilters.current_page = this.arenaFilters.current_page + 1;
            this.gameListSubscription = this.arenaService.getGameList(this.arenaFilters)
                .pipe(take(1))
                .subscribe({
                    next: (response: GameHttpListResponse) => {
                        this.gameList.total = response.total;
                        this.gameList.last_page = response.last_page;
                        this.gameList.results.push(...response.results);
                        this.processLoadMore = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processLoadMore = false;
                        this.errorService.handleHttpErrorResponse(err);
                    },
                    complete: () => {
                        this.gameListSubscription.unsubscribe();
                        this.gameListSubscription = null;
                    }
                });
        }
    }

    private getSinglePlayerTenantVendorGameList() {

        if (this.singlePlayerTenantVendorGameListSubscription) {
            this.singlePlayerTenantVendorGameListSubscription.unsubscribe();
        }

        this.singlePlayerTenantVendorGameListSubscription = this.arenaService.getSinglePlayerTenantVendorGameList()
            .pipe(take(1))
            .subscribe({
                next: (tenantVendorGameList: TenantVendorGameListHttpResponse) => {
                    this.singlePlayerTenantVendorGameList = tenantVendorGameList;

                    if (this.authenticationService.currentUser) {
                        this.getSinglePlayerParticipations(true);
                    } else {
                        this.hideLoadingSpinner();
                        this.showSinglePlayerSwiper = true;
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.hideLoadingSpinner();
                    this.errorService.handleHttpErrorResponse(err);
                },
                complete: () => {
                    this.singlePlayerTenantVendorGameListSubscription.unsubscribe();
                    this.singlePlayerTenantVendorGameListSubscription = null;
                }
            });
    }

    private getSinglePlayerParticipations(forceReload: boolean = false) {
        if (this.singlePlayerTenantVendorGameList && this.singlePlayerTenantVendorGameList.total > 0 &&
            this.authenticationService.currentUser) {
            this.singlePlayerGameService.getSinglePlayerParticipations(this.authenticationService.currentUser.id, forceReload);
        } else {
            this.hideLoadingSpinner();
        }
    }

    private getHighlightGameList() {
        if (this.highlightGameListSubscription) {
            this.highlightGameListSubscription.unsubscribe();
        }

        const highlightFilters = cloneDeep(this.arenaFilters);
        highlightFilters.is_highlight = true;
        highlightFilters.current_page = undefined;
        highlightFilters.per_page = undefined;

        this.highlightGameListSubscription = this.arenaService.getGameList(highlightFilters)
            .pipe(take(1))
            .subscribe({
                next: (response: GameHttpListResponse) => {
                    this.highlightGameList = response;

                    this.highlightGameList.results.forEach((highlightGame: GameHttpResponse) => {
                        highlightGame.game_card_tile_style = GameCardTileStyleEnum.LARGE;
                    });

                    if (this.highlightGameList && this.highlightGameList.results) {
                        this.showHighlightSwiper = true;
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.hideLoadingSpinner();
                    this.errorService.handleHttpErrorResponse(err);
                },
                complete: () => {
                    this.highlightGameListSubscription.unsubscribe();
                    this.highlightGameListSubscription = null;
                }
            });
    }

    public tenantVendorGameTrackBy(index: number, tenantVendorGame: TenantVendorGameHttpResponse): number {
        return tenantVendorGame.id;
    }

    public highlightGameTrackBy(index: number, highlightGame: GameHttpResponse): number {
        return highlightGame.id;
    }

    infiniteScroll() {
        if (typeof this.loadMoreButton !== 'undefined' && this.gameList.results.length < this.gameList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMore && !this.processList && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMore();
            }
        }
    }

    private stopRunningRequests() {
        if (this.gameListSubscription) {
            this.gameListSubscription.unsubscribe();
        }
    }

    protected handleUserJoinedGameEvent(receivedGame: GameHttpResponse, receivedGameUser: GameUserHttpResponse) {
        super.handleUserJoinedGameEvent(receivedGame, receivedGameUser);

        if (this.highlightGameList && this.highlightGameList.results.length > 0) {
            this.highlightGameList.results = this.highlightGameList.results.map(
                game => {
                    if (game.game_unique_id === receivedGame.game_unique_id) {
                        game.users_count = receivedGame.users_count;
                        game.current_prize_pool = receivedGame.current_prize_pool;
                        game.h2h_competitors = receivedGame.h2h_competitors;
                        if (this.currentUser) {
                            if (this.currentUser.id === receivedGameUser.user_id) {
                                game.is_current_user_joined = true;
                            } else {
                                // if user is a friend add to current_user_friends
                                const userFriend = this.friendsService.userIsFriend(receivedGameUser.user_id);
                                if (userFriend) {
                                    if (!game.current_user_friends) {
                                        game.current_user_friends = [];
                                    }
                                    const friendExistsInList = game.current_user_friends.find(
                                        (friendInList: FriendHttpResponse) => friendInList.user_id === receivedGameUser.user_id);
                                    if (!friendExistsInList) {
                                        game.current_user_friends.push(userFriend);
                                    }
                                }
                            }
                        }
                    }
                    return game;
                }
            );
        }
    }

    protected handleUserLeftGameEvent(receivedGame: GameHttpResponse, receivedPlayer: PlayerPublicHttpResponse) {
        super.handleUserLeftGameEvent(receivedGame, receivedPlayer, this.highlightGameList);

        // add game to swiper if necessary
        if (receivedGame.is_highlight && receivedGame.game_state < 3) {
            const highlightGame = this.highlightGameList.results.find((game: GameHttpResponse) => game.game_unique_id === receivedGame.game_unique_id);
            if (!highlightGame) {
                receivedGame.game_card_tile_style = GameCardTileStyleEnum.LARGE;
                this.highlightGameList.results.push(receivedGame);
                this.highlightGameList.total += 1;
                this.highlightGameList.count += 1;
                this.showHighlightSwiper = true;
            }
        }
    }

    handleGameStateChange(broadcastEventData: GameStateChanged) {
        super.handleGameStateChange(broadcastEventData);
        const receivedGame = broadcastEventData.gameHttpResponse;

        if (this.highlightGameList && this.highlightGameList.results.length > 0) {
            this.highlightGameList.results = this.highlightGameList.results.filter(
                (game: GameHttpResponse) => {
                    if (game.game_unique_id === receivedGame.game_unique_id) {
                        game.updated_at = receivedGame.updated_at;
                        game.game_state = receivedGame.game_state;
                        game.users_count = receivedGame.users_count;
                        game.current_prize_pool = receivedGame.current_prize_pool;
                    }
                    // hide if fully booked or already started
                    if (game.game_state < 3 && game.users_count < game.size) {
                        return game;
                    } else {
                        this.highlightGameList.total -= 1;
                        this.highlightGameList.count -= 1;
                        return false;
                    }
                }
            );
        }
    }
}
