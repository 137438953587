<div class="ui top aligned centered grid">
    <div class="two column row padding-b-0">
        <div class="center aligned tiny column">
            {{'GENERAL.GAMEVENDORGAMEPARTICIPATIONTRANSACTION.points' | translate}}
        </div>
        <div class="center aligned tiny column">
            <strong class="color-positive" *ngIf="calculateParticipationTransactionSaldo() >= 0">
                +{{calculateParticipationTransactionSaldo() | number:'1.2-2'}} &#x25B2;
            </strong>
            <strong class="color-negative" *ngIf="calculateParticipationTransactionSaldo() < 0">
                {{calculateParticipationTransactionSaldo() | number:'1.2-2'}} &#x25BC;
            </strong>
        </div>
    </div>
    <div class="two column middle aligned row padding-0">
        <div class="center aligned column">
            <div class="ui fluid label points-label">
                <strong class="text-medium">{{gameVendorGameParticipationTransaction.points | number:'1.2-2'}}</strong>
            </div>
        </div>
        <div class="center aligned column">
            <div class="ui fluid basic label points-label"
                 [ngClass]="{
                    'positive': (calculateParticipationTransactionSaldo() >= 0),
                    'negative': (calculateParticipationTransactionSaldo() < 0)
                }">
                <strong class="text-medium">{{gameVendorGameParticipationTransaction.total_points | number:'1.2-2'}}</strong>
            </div>
        </div>
    </div>
</div>
