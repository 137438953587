<div class="ui fluid card" [class.mobile-stretch-bottom-card]="!showSpinner">
    <div class="ui fluid profile-header-image image" *ngIf="profileHeaderImage && !showSpinner">
        <img [src]="profileHeaderImage" [alt]="" />
    </div>
    <div class="content" [class.with-profile-header-image]="profileHeaderImage && !showSpinner">

        <div class="ui vertical borderless very padded segment" *ngIf="showSpinner">
            <div class="ui active centered inline text loader">
                {{'GENERAL.loadertext' | translate}}
            </div>
        </div>

        <div *ngIf="!showSpinner">
            <div class="center aligned margin-b-15">
                <div class="user-avatar-wrap">
                    <betsnaps-user-avatar-loader [user]="this.currentUser"
                                                 [imageSize]="'medium'">
                    </betsnaps-user-avatar-loader>
                    <button type="button"
                            class="ui circular mini icon user-avatar-button button margin-0"
                            (click)="profileMediaV3ModalComponent.openMediaV3Modal()">
                        <i class="edit icon"></i>
                    </button>
                </div>
                <div class="margin-t-10 margin-b-0">
                    <strong class="font-very-bold text-huge" *ngIf="currentUser.username">
                        {{this.currentUser.username}}
                    </strong>
                    <div>
                        <button type="button" *ngIf="!currentUser.username || !currentUser.is_username_confirmed"
                                class="ui mini button margin-0"
                                (click)="myModalService.openUsernameInputModal()">
                            {{'PROFILE.EDIT.set_username_button' | translate}}
                        </button>
                    </div>
                </div>
            </div>

            <form class="ui form v3 margin-t-30" novalidate [formGroup]="profileForm" (ngSubmit)="onSubmit()"
                  [class.success]="submitted && !processForm"
                  [class.error]="profileForm.invalid && submitted && !processForm"
                  *ngIf="!showSpinner">

                <div class="field" *ngIf="b2cSignupMethod === b2cSignupMethodEnum.PHONE">
                    <p class="text-label">
                        {{'PROFILE.EDIT.phone_field' | translate}}
                        <i class="fa fa-edit large link icon margin-l-10"
                           (click)="profileChangePhoneComponent.openProfileChangePhoneV3Modal()"></i>
                    </p>
                    <p class="text-primary">{{currentUser.phone}}</p>
                </div>

                <div class="field">
                    <p class="text-label">
                        {{'PROFILE.EDIT.email_field' | translate}}
                        <i class="fa fa-edit large link icon margin-l-10"
                           (click)="profileChangeEmailV3ModalComponent.openProfileChangeEmailV3Modal()"></i>
                    </p>
                    <p class="text-primary">{{currentUser.email}}</p>
                </div>

                <div class="field">
                    <p class="text-label">
                        {{'LOGIN.password_field' | translate}}
                        <i class="fa fa-edit large link icon margin-l-10"
                           (click)="profilePasswordV3ModalComponent.openPasswordV3Modal()"></i>
                    </p>
                    <p class="text-primary">********</p>
                </div>

                <div class="field margin-t-15">
                    <label for="gender">{{'PROFILE.EDIT.gender_field' | translate}}</label>
                    <select id="gender" name="gender" formControlName="gender" class="ui dropdown">
                        <option selected="selected" value="">{{'PROFILE.EDIT.gender_select' | translate}}</option>
                        <option value="1">{{'PROFILE.EDIT.gender_male' | translate}}</option>
                        <option value="2">{{'PROFILE.EDIT.gender_female' | translate}}</option>
                    </select>
                </div>
                <div class="field">
                    <label for="first_name">{{'PROFILE.EDIT.first_name_field' | translate}}</label>
                    <input id="first_name" type="text" formControlName="first_name">
                </div>
                <div class="field">
                    <label for="last_name">{{'PROFILE.EDIT.last_name_field' | translate}}</label>
                    <input id="last_name" type="text" formControlName="last_name"/>
                </div>
                <div class="field">
                    <label for="dob">{{'PROFILE.EDIT.dob_field' | translate}}</label>
                    <input id="dob" type="date" formControlName="dob"/>
                </div>
                <div class="field">
                    <label for="zip_code">{{'PROFILE.EDIT.zipcode_field' | translate}}</label>
                    <input id="zip_code" type="text" formControlName="zip_code"/>
                </div>
                <div class="field">
                    <label for="city">{{'PROFILE.EDIT.city_field' | translate}}</label>
                    <input id="city" type="text" formControlName="city"/>
                </div>
                <div class="field">
                    <label for="country">{{'PROFILE.EDIT.country_field' | translate}}</label>
                    <select id="country" name="country" #country (change)="getStatesForCountryIso2(country.value)"
                            formControlName="country" class="ui dropdown">
                        <option selected="selected" value="">{{'PROFILE.EDIT.country_select' | translate}}</option>
                        <option *ngFor="let country of countries" [value]="country.iso2">{{country.country_name}}</option>
                    </select>
                </div>
                <div class="field" *ngIf="(states.length > 0)">
                    <label for="state_id">{{'PROFILE.EDIT.state_field' | translate}}</label>
                    <select id="state_id" name="state_id"
                            formControlName="state_id" class="ui dropdown">
                        <option selected="selected" value="">{{'PROFILE.EDIT.select_state' | translate}}</option>
                        <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
                    </select>
                </div>
                <div class="ui borderless vertical padded segment center aligned margin-0">
                    <button class="ui large button margin-0" type="submit" [class.loading]="processForm"
                            [disabled]="processForm || !profileForm.valid">
                        {{'PROFILE.EDIT.save_changes_btn' | translate}}
                    </button>
                </div>
                <div class="ui borderless vertical padded segment center aligned margin-0">
                    <button class="ui basic borderless shadowless button color-light-grey font-very-bold margin-0" type="button" [class.loading]="processForm"
                            (click)="$event.preventDefault(); profileAdministrationV3ModalComponent.openAdministrationV3Modal()"
                            [disabled]="processForm || !profileForm.valid">
                        {{'ROUTEDATA.PAGETITLES.deleteaccount' | translate | uppercase}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<betsnaps-profile-change-phone-v3-modal #profileChangePhoneComponent (profileChangePhoneSetSuccess)="profileChangePhoneComponent.closeProfileChangePhoneV3Modal()"></betsnaps-profile-change-phone-v3-modal>
<betsnaps-profile-change-email-v3 #profileChangeEmailV3ModalComponent></betsnaps-profile-change-email-v3>
<betsnaps-profile-password-modal-v3 #profilePasswordV3ModalComponent></betsnaps-profile-password-modal-v3>
<betsnaps-profile-media-modal-v3 #profileMediaV3ModalComponent (profileMediaV3ModalSetSuccess)="profileMediaV3ModalComponent.closeMediaV3Modal()"></betsnaps-profile-media-modal-v3>
<betsnaps-profile-administration-modal-v3 #profileAdministrationV3ModalComponent></betsnaps-profile-administration-modal-v3>
