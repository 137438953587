<div class="content top font-bold" [ngClass]="{
    'match-live': isMatchLive(),
    'match-closed': isMatchCompleted()
    }">
    <div class="ui list horizontal">
        <div class="left aligned item truncate element-clickable" fuiPopup
             popupText="{{match.tournament.category_name | dbtranslation:'category_name':match.tournament.translations}}, {{match.tournament.name | dbtranslation:'name':match.tournament.translations}}"
             popupTrigger="outsideClick">
            <img class="iconimage" [src]="categoryImageSrc" alt="{{match.tournament.category_name}}"/>
            <span class="mobile hidden">
                {{match.tournament.category_name | dbtranslation:'category_name':match.tournament.translations}},&nbsp;
            </span>{{match.tournament.name | dbtranslation:'name':match.tournament.translations}}
        </div>
        <div class="right aligned item">
            <span *ngIf="match.status === 'cancelled'">
                {{'GENERAL.MATCHES.STATI.cancelled' | translate | uppercase}}
            </span>
            <span *ngIf="match.status === 'postponed'">
                {{'GENERAL.MATCHES.STATI.postponed' | translate | uppercase}}
            </span>
            <div class="line-height-standard" *ngIf="match.status === 'not_started' || match.status === 'scheduled' || match.status === 'delayed' || match.status === 'closed' || match.status === 'ended' ||
                   (match.status === 'live' && !match.score)">
                <div *ngIf="match.status === 'delayed'">
                    {{'GENERAL.MATCHES.STATI.delayed' | translate | uppercase}}
                </div>
                <div *ngIf="match.status === 'closed' || match.status === 'ended'">
                    {{'GENERAL.MATCHES.STATI.finished' | translate | uppercase}}
                </div>
                {{match.season_scheduled_date | amLocal | amCalendar:
                {
                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                    sameElse: tenantService.tenantData.internationalization.date_time_format
                }
                }}
            </div>
            <span *ngIf="match.status === 'interrupted' || match.status === 'suspended'">
                {{'GENERAL.MATCHES.STATI.interrupted' | translate | uppercase}}
            </span>
            <span *ngIf="match.status === 'abandoned'">
                {{'GENERAL.MATCHES.STATI.abandoned' | translate | uppercase}}
            </span>
            <span *ngIf="match.status === 'live' && matchHasStarted() && match.liveodds === 'booked' &&
                    match.score && match.score.match_status_code &&
                    feedService.feedLiveProducerUp" class="text-primary">
                <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}}</span>
                <span *ngIf="showMatchTime()">
                    <span *ngIf="showRemainingTimeInPeriod(match.sport_id) && match.score.remaining_time_in_period">&nbsp;< {{match.score.remaining_time_in_period}}&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})&nbsp;</span></span>
                    <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.matchtime">{{match.score.matchtime | matchtime}}'&nbsp;<span *ngIf="match.score.stoppagetimeannounced">(+{{match.score.stoppagetimeannounced | matchtime}})&nbsp;</span>
                </span>
                </span>
                <span *ngIf="!showRemainingTimeInPeriod(match.sport_id) && match.score.match_status_code && match.score.match_status_code.translations">{{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}}</span>
            </span>
        </div>
    </div>
</div>
<div [ngClass]="'content sport-event sport-' + game.sport_id + ' element-clickable'"
     [class.match-live]="isMatchLive()"
     [class.match-closed]="isMatchCompleted()"
     (click)="toggleSpecialMarkets()">
    <betsnaps-match-card-details-default
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="![SportEnum.BASKETBALL, SportEnum.TENNIS, SportEnum.CRICKET, SportEnum.ICE_HOCKEY].includes(match.sport_id)"></betsnaps-match-card-details-default>
    <betsnaps-match-card-details-basketball
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.BASKETBALL"></betsnaps-match-card-details-basketball>
    <betsnaps-match-card-details-tennis
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.TENNIS"></betsnaps-match-card-details-tennis>
    <betsnaps-match-card-details-cricket
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.CRICKET"></betsnaps-match-card-details-cricket>
    <betsnaps-match-card-details-ice-hockey
        [match]="match"
        [feedLiveProducerUp]="feedService.feedLiveProducerUp"
        *ngIf="match.sport_id === SportEnum.ICE_HOCKEY"></betsnaps-match-card-details-ice-hockey>
</div>

<div [ngClass]="'content sr-widget sr-widget-' + match.match_id" id="sr-widget-{{match.match_id}}" *ngIf="showWidget()"></div>
<div id="sr-widget-script-{{match.match_id}}" *ngIf="showWidget()"></div>

<div class="content user-bets"
     *ngIf="!isLeaderBoardMatch && activeBets && activeBets.length > 0 && activeBetsVisible"
     [class.padding-0]="!activeBetsVisible">
    <betsnaps-user-bet-default
        *ngFor="let activeBet of activeBets; trackBy: userBetsTrackBy"
        [gameUpdatedDate]="game?.updated_at"
        [marketUpdatedDate]="activeBet.market.updated_date"
        [userbetUpdatedDate]="activeBet.modified_date"
        [matchLiveodds]="match.liveodds"
        [matchStatus]="match.status"
        [marketStatus]="activeBet.market.market_status"
        [outcomeStatus]="activeBet.outcome.outcome_status"
        [userbetStatus]="activeBet.status"
        [userBet]="activeBet"
        [game]="game"
        [match]="match"
        [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
        [compactUserBetView]="compactUserBetView"
        class="user-bet">
    </betsnaps-user-bet-default>
</div>
<div class="content user-bets"
     *ngIf="completedBets && completedBets.length > 0 && completedBetsVisible">
    <betsnaps-user-bet-default
        *ngFor="let completedBet of completedBets; trackBy: userBetsTrackBy"
        [gameUpdatedDate]="game?.updated_at"
        [marketUpdatedDate]="completedBet.market.updated_date"
        [userbetUpdatedDate]="completedBet.modified_date"
        [matchLiveodds]="match.liveodds"
        [matchStatus]="match.status"
        [marketStatus]="completedBet.market.market_status"
        [outcomeStatus]="completedBet.outcome.outcome_status"
        [userbetStatus]="completedBet.status"
        [userBet]="completedBet"
        [game]="game"
        [match]="match"
        [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
        class="user-bet">
    </betsnaps-user-bet-default>
</div>
<div class="content completed-bets-total"
     *ngIf="completedBets && completedBets.length > 0 && completedBetsVisible && (!activeBetsVisible || activeBets.length < 1)">
    <div class="ui middle aligned equal width grid">
        <div class="row">
            <div class="medium column">
                <strong>{{'GENERAL.BETS.match_total' | translate}}</strong>
            </div>
            <div class="right aligned big column">
                <strong>
                    <span *ngIf="completedBetsTotalPoints > 0">+</span>{{completedBetsTotalPoints | number:'1.2-2'}}
                    <span class="color-positive" *ngIf="completedBetsTotalPoints > 0"> &#x25B2;</span>
                    <span class="color-negative" *ngIf="completedBetsTotalPoints < 0"> &#x25BC;</span>
                </strong>
            </div>
        </div>
    </div>
</div>
<div class="content top-market"
     [hidden]="!betPlacementAllowed"
     *ngIf="!isLeaderBoardMatch && game?.game_state < 4 && !isMatchCompleted() &&
            ((match.default_market && match.default_market.outcomes) || (match.markets_count > 0)) && marketsVisible">
    <div class="ui middle aligned equal width grid margin-0" id="default-market-{{match.match_id}}-{{match.default_market?.market_id}}-{{match.default_market?.specifier_val}}">
        <div class="row padding-0" *ngIf="match.default_market && match.default_market.outcomes">
            <div class="column text-primary">
                <strong>{{match.default_market.market_name | dbtranslation:'name':match.default_market.translations}}</strong>
            </div>
        </div>
        <div class="row center padding-t-0" *ngIf="match.default_market && match.default_market.outcomes">
            <div *ngFor="let outcome of match.default_market.outcomes; trackBy: outcomesTrackBy" class="column">
                <betsnaps-market-outcome-button-default
                    [match]="match"
                    [game]="game"
                    [market]="match.default_market"
                    [outcome]="outcome"
                    [matchStatus]="match.status"
                    [marketUpdatedDate]="match.default_market.updated_date"
                    [marketStatus]="match.default_market.market_status"
                    [outcomeStatus]="outcome.outcome_status"
                    [oddDecimal]="outcome.odd_decimal"
                    [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
                    [gameState]="game.game_state"
                    [liveodds]="match.liveodds"
                    [betPlacementAllowed]="betPlacementAllowed"
                    [isSelected]="(placeBetOutcome &&
                                    placeBetOutcome.market_id === match.default_market.market_id &&
                                    placeBetOutcome.outcome_id === outcome.outcome_id &&
                                    placeBetOutcome.specifier_val === outcome.specifier_val)"
                    (onPlaceBetClick)="togglePlaceBetDialog(outcome)">
                </betsnaps-market-outcome-button-default>
            </div>
            <div class="column" *ngIf="match.markets_count > 0 && game">
                <button type="button"
                        *ngIf="!specialMarketsOpened"
                        class="ui calltoaction2 fluid button padding-l-0 padding-r-0"
                        (click)="showMarkets()">
                    +{{match.markets_count}}</button>
                <button type="button"
                        *ngIf="specialMarketsOpened"
                        class="ui calltoaction2 fluid button padding-l-0 padding-r-0"
                        (click)="hideMarkets()">
                    -{{match.markets_count}}</button>
            </div>
        </div>
        <div class="row"
             *ngIf="(!match.default_market || (match.default_market && !match.default_market.outcomes)) && match.markets_count > 0">
            <div class="column right aligned">
                <button type="button"
                        *ngIf="!specialMarketsOpened"
                        class="ui calltoaction2 button"
                        (click)="showMarkets()">
                    +{{match.markets_count}}
                </button>
                <button type="button"
                        *ngIf="specialMarketsOpened"
                        class="ui calltoaction2 button"
                        (click)="hideMarkets()">
                    -{{match.markets_count}}
                </button>
            </div>
        </div>
    </div>
</div>
<betsnaps-place-bet-dialog-default
    *ngIf="match.default_market && placeBetDialogVisible && marketsVisible && !isLeaderBoardMatch && !isMatchCompleted()"
    [hidden]="!betPlacementAllowed"
    [marketUpdatedDate]="match.default_market.updated_date"
    [match]="match"
    [market]="match.default_market"
    [outcome]="placeBetOutcome"
    [currentUser]="currentUser"
    [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
    (onCloseClick)="closePlaceBetDialog(true)"
    (betPlaced)="closePlaceBetDialog()"
    class="place-bet-dialog {{placeBetAnimationClass}}">
</betsnaps-place-bet-dialog-default>
<div [hidden]="!specialMarketsOpened || !betPlacementAllowed" class="content special-markets"
     *ngIf="marketsVisible && game?.game_state < 4 && !isLeaderBoardMatch && match.markets_count > 0 && !isMatchCompleted()">
    <div class="ui basic segment loader-segment" *ngIf="!matchMarkets">
        <div class="ui active centered inline text loader">
            {{'GENERAL.loadertext' | translate}}
        </div>
    </div>
    <div *ngIf="matchMarkets && matchMarkets.length > 0">
        <div class="ui middle aligned equal width grid margin-0">
            <div class="row special-market-head">
                <div class="column">
                    <strong>{{matchMarkets.length}} {{'GENERAL.MATCHES.MARKETS.special_markets' | translate}}</strong>
                </div>
                <div class="column right aligned">
                    <button type="button"
                            class="ui basic tiny button margin-0"
                            (click)="openCloseAllMarketAccordions()">
                        {{(!allMarketsOpen) ? ('GENERAL.MATCHES.MARKETS.open_all_markets' | translate | uppercase) : ('GENERAL.MATCHES.MARKETS.close_all_markets' | translate | uppercase)}}
                    </button>
                </div>
            </div>
        </div>
        <betsnaps-special-market-default
            *ngFor="let market of matchMarkets; trackBy: marketsTrackBy"
            [attr.id]="'market-' + market.market_id + '-' + market.specifier_val"
            [marketOpen]="allMarketsOpen"
            [marketStatus]="market.market_status"
            [market]="market"
            [game]="game"
            [isCurrentUserJoined]="game.is_current_user_joined"
            [match]="match"
            [currentUser]="currentUser"
            [gameUpdatedDate]="game.updated_at"
            [matchStatus]="match.status"
            (onPlaceBetClick)="handlePlaceBetClickOfSpecialMarket()"
            class="market-accordion">
        </betsnaps-special-market-default>
    </div>
</div>
<div *ngIf="(match.status === 'not_started' || match.status === 'scheduled' || match.status === 'delayed') &&
            match.markets_count === 0 && !match.default_market"
     class="content no-markets">
    <p class="center aligned margin-0">
        <strong>{{'GENERAL.MATCHES.currently_no_market_data_available' | translate}}</strong>
    </p>
</div>
<div *ngIf="!isMatchCompleted() && !betPlacementAllowed && (match.markets_count > 0 || match.default_market)"
     class="content no-markets">
    <p class="center aligned margin-0" *ngIf="feedService.feedPrematchProducerUp && feedService.feedLiveProducerUp">
        <strong *ngIf="match.liveodds !== 'booked'">
            {{'ERRORS.GENERAL.live_odds_not_available' | translate}}
        </strong>
        <strong *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_PRE_GAME">
            {{'ERRORS.GENERAL.only_pre_game_bets_allowed' | translate}}
        </strong>
        <strong *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_PRE_MATCH">
            {{'ERRORS.GENERAL.only_pre_match_bets_allowed' | translate}}
        </strong>
        <strong
            *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_LIVE && match.liveodds === 'booked'">
            {{'ERRORS.GENERAL.only_live_bets_allowed' | translate}}
        </strong>
    </p>
    <p class="center aligned margin-0" *ngIf="!feedService.feedPrematchProducerUp || !feedService.feedLiveProducerUp">
        <strong>{{'GENERAL.MATCHES.no_markets_feed_product_down' | translate}}</strong>
    </p>
</div>
