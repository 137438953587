<div class="ui vertical borderless very padded segment" *ngIf="processData">
    <div class="ui active centered inline text loader on-body-bg">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>

<div id="srWidgetScriptPlaceholder"></div>
<betsnaps-betsnapdetail-header
    *ngIf="game && !processData"
    [game]="game"
    [gameUniqueId]="game.game_unique_id"
    class="betsnapdetail-header"
    id="betsnapdetail-header"
    [class.showsmallmobileheader]="smallMobileHeader"
    #betsnapDetailHeader>
</betsnaps-betsnapdetail-header>
<div class="ui centered relaxed grid container margin-t-0  margin-b-0">
    <div class="row padding-0">
        <div class="four wide tablet or lower hidden column sidebar left-sidebar" #leftSidebar>
            <div [class.sticky-container]="sidebarSticky" #leftSidebarSticky>
                <div *ngIf="game && !processData">
                    <betsnaps-chat-accordion
                        [chatId]="game.chat_id"
                        *ngIf="windowWidth > 991 && game.chat_id > 0 && game.is_current_user_joined && game.chat_enabled">
                    </betsnaps-chat-accordion>
                </div>
            </div>
        </div>
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div *ngIf="game && !processData" class="betsnapdetail-content-inner" #betsnapDetailContent
                 [class.notJoined]="!game.is_current_user_joined">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar" #rightSidebar>
            <div [class.sticky-container]="sidebarSticky" #rightSidebarSticky>
                <div *ngIf="game && !processData">
                    <betsnaps-sidebar-game-user [game]="game"
                                                [gameUniqueId]="game.game_unique_id"
                                                [h2hCompetitor]="betsnapdetailService.h2hCompetitor"
                                                *ngIf="sidebarData?.game_user && game.game_state !== 99"></betsnaps-sidebar-game-user>

                    <betsnaps-chat-accordion
                        [chatId]="game.chat_id"
                        *ngIf="windowWidth > 767 && windowWidth < 992 && game.chat_id > 0 && game.is_current_user_joined && game.chat_enabled">
                    </betsnaps-chat-accordion>

                    <betsnaps-sidebar-share-snap [game]="game"
                                                 [gameUniqueId]="game.game_unique_id"
                                                 *ngIf="sidebarData?.share && game.game_state < 3"></betsnaps-sidebar-share-snap>

                    <betsnaps-sidebar-tenant-content [adTypeTop]="'game-sidebar-right'" [adTypeBottom]="'game-sidebar-right-2'"></betsnaps-sidebar-tenant-content>
                </div>
            </div>
        </div>
    </div>
</div>

<betsnaps-chat-bottom-fixed
    [chatId]="game.chat_id"
    [betsnapDetailView]="true"
    *ngIf="windowWidth < 768 && game && !processData && game?.chat_id > 0 && game?.is_current_user_joined && game?.chat_enabled">
</betsnaps-chat-bottom-fixed>
