<fui-accordion [closeOthers]="false" [transitionDuration]="0">
    <fui-accordion-panel [(isOpen)]="marketOpen" (isOpenChange)="onMarketOpenChange()">
        <div title
             (click)="!marketOpen ?
                      googleAnalyticsService.trackEvent('matchcard - market', 'expand', market.market_id.toString()) :
                      googleAnalyticsService.trackEvent('matchcard - market', 'collapse', market.market_id.toString())">
            <i class="expand right icon large" [ngClass]="{'less': marketOpen, 'more': !marketOpen}"></i>
            {{market.market_name | dbtranslation:'name':market.translations}}
        </div>
        <div content #marketAccordionContent>
            <div *ngIf="marketOutcomesArray"
                 class="ui top aligned centered grid"
                 [ngClass]="{'equal width': marketOutcomesCount < 4, 'three column': marketOutcomesCount > 3}">
                <div *ngFor="let outcomeRow of marketOutcomesArray; let rowIndex = index; trackBy: outcomesRowTrackBy;"
                     class="row padding-0"
                     id="{{match.match_id}}-{{market.market_id}}-{{market.specifier_val}}-{{rowIndex}}">
                    <div *ngFor="let outcome of outcomeRow; trackBy: outcomesTrackBy;"
                         class="column">
                        <betsnaps-market-outcome-button-default
                            *ngIf="outcome"
                            [match]="match"
                            [game]="game"
                            [market]="market"
                            [outcome]="outcome"
                            [matchStatus]="match.status"
                            [marketUpdatedDate]="market.updated_date"
                            [marketStatus]="market.market_status"
                            [outcomeStatus]="outcome.outcome_status"
                            [oddDecimal]="outcome.odd_decimal"
                            [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
                            [gameState]="game.game_state"
                            [liveodds]="match.liveodds"
                            [betPlacementAllowed]="betPlacementAllowed"
                            [isSelected]="(placeBetOutcome &&
                                            placeBetOutcome.market_id === market.market_id &&
                                            placeBetOutcome.outcome_id === outcome.outcome_id &&
                                            placeBetOutcome.specifier_val === outcome.specifier_val)"
                            (onPlaceBetClick)="togglePlaceBetDialog(outcome, match.match_id + '-' + market.market_id + '-' + market.specifier_val + '-' + rowIndex)">
                        </betsnaps-market-outcome-button-default>
                    </div>
                </div>
            </div>
        </div>
    </fui-accordion-panel>
</fui-accordion>

<div class="place-bet-dialog-wrap" #placeBetDialogWrap>
    <betsnaps-place-bet-dialog-default
        #placeBetDialog
        *ngIf="placeBetDialogVisible"
        [hidden]="!betPlacementAllowed"
        [marketUpdatedDate]="market.updated_date"
        [match]="match"
        [market]="market"
        [outcome]="placeBetOutcome"
        [currentUser]="currentUser"
        [oddDisplayFormat]="currentUser?.settings?.odd_display_format"
        (onCloseClick)="closePlaceBetDialog(true)"
        (apiErrorOccured)="doChangeDetection()"
        (betPlaced)="closePlaceBetDialog()"
        (betPointsChanged)="doChangeDetection()"
        class="place-bet-dialog {{placeBetAnimationClass}}">
    </betsnaps-place-bet-dialog-default>
</div>
