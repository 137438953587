import {Component} from '@angular/core';
import {AMatchCardDetailsComponent} from '../a-match-card-details-component';

@Component({
    selector: 'betsnaps-match-card-details-default',
    templateUrl: './match-card-details-default.component.html',
    styles: []
})
export class MatchCardDetailsDefaultComponent extends AMatchCardDetailsComponent {

    public getDefaultColumnClasses(): string[] {
        let competitorColumnClass = 'ten';
        let scoreColumnClass = 'four';
        if (this.match.score.home_score > 99 || this.match.score.away_score > 99) {
            competitorColumnClass = 'eight';
            scoreColumnClass = 'six';
        } else if (this.match.score.home_score > 9 || this.match.score.away_score > 9) {
            competitorColumnClass = 'nine';
            scoreColumnClass = 'five';
        }
        return [competitorColumnClass, scoreColumnClass];
    }

}
