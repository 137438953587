/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UserHttpResponse
 */
export interface AppHttpResponsesUsersUserHttpResponse { 
    /**
     * User Id
     */
    id: number;
    /**
     * Unique ID
     */
    unique_id: string;
    /**
     * B2b User ID
     */
    b2b_user_id?: string;
    /**
     * B2b User Session Token
     */
    b2b_user_token?: string;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Username
     */
    username?: string;
    /**
     * Is Username manually confirmed
     */
    is_username_confirmed: boolean;
    /**
     * E-Mail
     */
    email?: string;
    /**
     * is users email verified
     */
    is_email_verified: number;
    /**
     * Phone Number
     */
    phone?: string;
    /**
     * is users phone verified
     */
    is_phone_verified: number;
    /**
     * is User active (0... no, 1...yes)
     */
    is_active: number;
    /**
     * is User banned (0... no, 1...yes)
     */
    is_banned: number;
}

