import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesTenantsTenantPublicHttpResponse as TenantPublicHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse, GamesApi,
} from '../../../api';
import { ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, ViewContainerRef, Directive } from '@angular/core';
import {
    AdService,
    ArenaService,
    AuthenticationService,
    ErrorService,
    GameBetPlacementOptionEnum,
    GameHelpers,
    GoogleAnalyticsService,
    MyTranslateService,
    SimpleGameCardExitTypeEnum,
    TenantImageTypeEnum,
    TenantService
} from '../../../shared';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {TenantContentComponent} from '../tenant-content/tenant-content.component';
import {GameCardTileStyleEnum} from '../../../shared/enums/game-card-tile-style.enum';
import {FeedService} from '../../../shared/services/feed.service';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Directive()
export abstract class AGameCardComponent implements OnInit, OnDestroy {

    @Input() gameIndexInList: number;
    @Input() adPositions: number[];

    public currentUser: PlayerHttpResponse;
    public game: GameHttpResponse;

    public isPartOfList: boolean;
    public isSwitcher: boolean;
    public isWidget: boolean;
    public isLandingpageWidget: boolean;

    public onGameCardClick: EventEmitter<any>;
    public onSnapSwitch: EventEmitter<any>;

    public secondsToStart: number;
    public backgroundImage: string;

    public tenantData: TenantPublicHttpResponse;
    public showPrizes: boolean = true;
    public battleRoyalMissingMarkets: boolean = false;

    public GameHelpers = new GameHelpers();
    protected adComponentRef: ComponentRef<TenantContentComponent>;

    public simpleSnapCardShow: boolean = false;
    public disableSimpleSnapCard: boolean = false;

    public gameBetPlacementOptionEnum = GameBetPlacementOptionEnum;

    constructor(protected viewContainerRef: ViewContainerRef,
                protected componentFactoryResolver: ComponentFactoryResolver,
                protected authenticationService: AuthenticationService,
                protected router: Router,
                protected errorService: ErrorService,
                public tenantService: TenantService,
                protected arenaService: ArenaService,
                public translations: MyTranslateService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected adService: AdService,
                protected feedService: FeedService,
                protected gamesApi: GamesApi) {
        this.tenantData = this.tenantService.tenantData;
        this.showPrizes = this.authenticationService.showPrizes();
    }

    ngOnInit() {
        // place ad
        const adSlotPosition: number = this.gameIndexInList + 1;
        this.adComponentRef = this.adService.placeTenantAd('content', adSlotPosition, this.viewContainerRef, this.adPositions, this.gameIndexInList, 'gameCard' + this.gameIndexInList, 'sixteen wide column');
        if (this.shouldLoadSimpleSnapCard() && this.game.simple_snap_start_with_first_bet) {
            this.googleAnalyticsService.trackEvent('gamecard - body', 'simple snap card: autoload', this.game.game_unique_id);
            this.loadSimpleSnapCard();
        }
    }

    protected loadSimpleSnapCard(): void {
        this.simpleSnapCardShow = true;
        this.arenaService.registerActiveSimpleSnap(this.game.game_unique_id);
    }

    public closeSimpleSnapCard(closeType: string = SimpleGameCardExitTypeEnum.SUCCESS): void {
        switch (closeType) {
            case SimpleGameCardExitTypeEnum.ERROR:
                this.simpleSnapCardShow = false;
                this.disableSimpleSnapCard = true;
                break;
            case SimpleGameCardExitTypeEnum.EXIT_AND_TO_DETAILS:
                this.simpleSnapCardShow = false;
                this.disableSimpleSnapCard = true;
                this.navigateToDetail(this.game.game_unique_id);
                break;
            case SimpleGameCardExitTypeEnum.MISSING_MARKETS:
                this.battleRoyalMissingMarkets = true;
                this.simpleSnapCardShow = false;
                this.disableSimpleSnapCard = true;
                break;
            default:
                // Reload Data of Game and
                this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdGet(
                    this.tenantService.tenantData.id,
                    this.game.game_unique_id,
                    this.authenticationService.authToken
                ).pipe(take(1)).subscribe(
                    (game: GameHttpResponse) => {
                        this.game = game;
                        this.simpleSnapCardShow = false;
                        this.arenaService.updateGame(game);
                        const self = this;
                        setTimeout(function () {
                            self.arenaService.unregisterActiveSimpleSnap(game.game_unique_id);
                        }, 500);
                    }
                );
                break;
        }
    }

    protected shouldLoadSimpleSnapCard(): boolean {
        return (
            this.tenantService.tenantData.configuration.allow_simple_snaps &&
            this.tenantService.componentTemplateToLoad === 'v3' &&
            this.game.is_simple_snap &&
            this.game.game_state < 3 &&
            !this.game.is_current_user_joined &&
            (this.game.game_card_tile_style === GameCardTileStyleEnum.FULL_WIDTH ||
            this.game.game_card_tile_style === GameCardTileStyleEnum.LARGE) &&
            !this.disableSimpleSnapCard
        );
    }

    public navigateToDetail(gameUniqueId, advancedButtonType?: string) {
        this.onGameCardClick.emit();
        if (!this.isWidget) {
            if (this.shouldLoadSimpleSnapCard()) {
                this.googleAnalyticsService.trackEvent('gamecard - body', 'simple snap card: load', this.game.game_unique_id);
                this.loadSimpleSnapCard();
            } else {

                this.googleAnalyticsService.trackEvent('gamecard - body', 'view', 'game');
                let urlSuffix = 'details';

                if (advancedButtonType) {
                    switch (advancedButtonType) {
                        case ('actionDefault'):
                        case ('pastSnap'):
                        case ('pastSnapIsWinner'):
                            if (this.game.sport_id === 999 && this.game.size === 1) {
                                urlSuffix = 'plays';
                            } else {
                                urlSuffix = 'ranking';
                            }
                            break;
                        case ('actionPlaceBet'):
                            urlSuffix = 'matches';
                            break;
                        case ('actionCashout'):
                            urlSuffix = 'bets';
                            break;
                        case ('actionCasinoSnap'):
                            urlSuffix = 'games';
                            break;
                        default:
                            urlSuffix = 'details';
                            break;
                    }
                } else {
                    if (this.game.is_current_user_joined && this.game.game_state < 4 && this.game.game_state !== 99) {
                        if (this.game.sport_id === 999) {
                            urlSuffix = 'games';
                        } else {
                            urlSuffix = 'matches';
                        }
                    } else if (this.game.is_current_user_joined && this.game.game_state > 3 && this.game.game_state !== 99) {
                        urlSuffix = 'ranking';
                    }
                }

                this.router.navigate(['betsnapdetail', gameUniqueId, urlSuffix]);
                return;

            }
        }
    }

    public switchSnap(): void {
        this.onSnapSwitch.emit();
    }

    public getLeaderBoardUserProperty(game: GameHttpResponse, property: string) {
        const leaderBoardUser = game.game_user;

        if (leaderBoardUser.game_user_group && property === 'is_participation_valid') {
            leaderBoardUser.game_user_group['is_participation_valid'] = leaderBoardUser['is_participation_valid'];
        }
        if (leaderBoardUser.game_user_group && property === 'is_eliminated') {
            leaderBoardUser.game_user_group['is_eliminated'] = leaderBoardUser['is_eliminated'];
        }
        return (game.competition_type === 1 || game.competition_type === 2 || game.competition_type === 5) ? leaderBoardUser[property] : leaderBoardUser.game_user_group[property];
    }

    public checkSecondsToStart() {
        if (this.game) {
            const gameStartDate = moment(this.game.start_date);
            this.secondsToStart = gameStartDate.diff(moment(), 'seconds', false);
        }
    }

    protected setBackgroundImage() {
        if (this.game.bgimg_game_card) {
            this.backgroundImage = 'url("' + this.game.bgimg_game_card + '")';
        } else if (this.game.is_highlight) {
            if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HIGHLIGHT_GAME_CARD)) {
                this.backgroundImage = 'url("' + this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HIGHLIGHT_GAME_CARD).media_url + '")';
            } else {
                this.backgroundImage = '';
            }
        }
    }

    ngOnDestroy() {
        if (this.adComponentRef) {
            this.adComponentRef.destroy();
        }
    }
}
