import {Pipe, PipeTransform} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';

const ordinalsEN: string[] = ['th', 'st', 'nd', 'rd'];
const ordinalsFR: string[] = ['ère', 'ème'];

@Pipe({
    name: 'ordinalNumber',
    pure: false
})
export class OrdinalNumberPipe implements PipeTransform {

    constructor(private authenticationService: AuthenticationService) {
    }

    transform(n: number, ...args: any[]) {
        const withOutNumber = (args[0] && typeof args[0] === 'boolean' && args[0] === true);
        const transLang = this.authenticationService.currentLang;
        if (transLang.iso_code2 === 'en') {
            const v = n % 100;
            if (withOutNumber) {
                return (ordinalsEN[(v - 20) % 10] || ordinalsEN[v] || ordinalsEN[0]);
            } else {
                return n + (ordinalsEN[(v - 20) % 10] || ordinalsEN[v] || ordinalsEN[0]);
            }
        } else if (transLang.iso_code2 === 'fr') {
            if (withOutNumber) {
                return (n === 1) ? ordinalsFR[0] : ordinalsFR[1];
            } else {
                return n + ((n === 1) ? ordinalsFR[0] : ordinalsFR[1]);
            }
        } else if (transLang.iso_code2 === 'el') {
            if (withOutNumber) {
                return '';
            } else {
                return n;
            }
        } else {
            if (withOutNumber) {
                return '.';
            } else {
                return n + '.';
            }
        }
    }
}
