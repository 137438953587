/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameSettingHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameSettingHttpResponse { 
    /**
     * Tenant Market Ids
     */
    tenant_market_ids: Array<number>;
    /**
     * Tenant Market Specifier Disabled (Format: marketId_specifierVal)
     */
    tenant_market_specifier_disabled?: Array<string>;
    /**
     * Tenant Match Market Disabled (Format: matchId_marketId)
     */
    tenant_match_market_disabled?: Array<string>;
    /**
     * All Default Market Ids for Sport of Game ordered by priority
     */
    default_market_ids?: Array<number>;
}

