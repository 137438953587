/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';
import { AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse } from './appHttpResponsesSportsDataMatchMarketOutcomeHttpResponse';


/**
 * MatchMarketHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchMarketHttpResponse { 
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Market Id
     */
    market_id: number;
    /**
     * Specifier Val
     */
    specifier_val: string;
    /**
     * Updated Date
     */
    updated_date: Date;
    /**
     * Market Name
     */
    market_name?: string;
    /**
     * Market Status
     */
    market_status: number;
    /**
     * Cashout Status
     */
    cashout_status: number;
    /**
     * Favourite
     */
    favourite: boolean;
    /**
     * Initial Favourite
     */
    initial_favourite?: boolean;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Outcomes
     */
    outcomes?: Array<AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse>;
}

