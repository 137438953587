import {Component} from '@angular/core';
import {
    WindowRef,
    AuthenticationService,
    ProfileService,
    TenantService,
    ErrorService, OneSignalService, MyNotificationsService
} from '../../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {AProfileSettingsComponent} from '../a-profile-settings.component';

@Component({
    selector: 'betsnaps-profile-settings-default',
    templateUrl: './profile-settings-default.component.html',
    styles: []
})
export class ProfileSettingsDefaultComponent extends AProfileSettingsComponent {

    constructor(
        protected authenticationService: AuthenticationService,
        public tenantService: TenantService,
        protected profileService: ProfileService,
        protected windowRef: WindowRef,
        protected myNotificationsService: MyNotificationsService,
        protected translateService: TranslateService,
        protected oneSignalService: OneSignalService,
        protected errorService: ErrorService
    ) {
        super(
            authenticationService,
            tenantService,
            profileService,
            windowRef,
            myNotificationsService,
            translateService,
            oneSignalService,
            errorService
        );
    }
}
