<div class="ui vertical borderless very padded segment" *ngIf="showLoader">
    <div class="ui active centered inline text loader on-body-bg">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!showLoader && game">
    <div class="ui top attached header bgcolor-light-grey2">
        <div class="ui middle aligned grid">
            <div class="row">
                <div class="six wide column truncate">
                    <h5 class="ui left aligned header">{{'GENERAL.LABELS.details' | translate | uppercase}}</h5>
                </div>
                <div class="ten wide column right aligned text-tiny bet-placement-cashout-options">
                    <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ALWAYS"
                         class="ui small calltoaction label margin-0 margin-r-10 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.always' | translate}}" popupTrigger="outsideClick"
                         popupPlacement="top right">
                        {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout' | translate | uppercase}}
                    </div>
                    <div *ngIf="game.cashout_option === gameCashoutOptionEnum.NEVER"
                         class="ui small lightgrey disabled label margin-0 margin-r-10 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.cashout_not_allowed' | translate}}"
                         popupTrigger="outsideClick" popupPlacement="top right">
                        {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout' | translate | uppercase}}
                    </div>
                    <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ONLY_LIVE"
                         class="ui small calltoaction label margin-0 margin-r-10 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.only_live_cashout_allowed' | translate}}"
                         popupTrigger="outsideClick" popupPlacement="top right">
                        {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout_live_only' | translate | uppercase}}
                    </div>
                    <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ONLY_PRE_GAME"
                         class="ui small lightgrey disabled label margin-0 margin-r-10 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.only_pre_game_cashout_allowed' | translate}}"
                         popupTrigger="outsideClick" popupPlacement="top right">
                        {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout_live' | translate | uppercase}}
                    </div>
                    <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ONLY_PRE_MATCH"
                         class="ui small lightgrey disabled label margin-0 margin-r-10 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.only_pre_match_cashout_allowed' | translate}}"
                         popupTrigger="outsideClick" popupPlacement="top right">
                        {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout_live' | translate | uppercase}}
                    </div>
                    <div *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ALWAYS"
                         class="ui small calltoaction label margin-0 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.BET-OPTIONS.always' | translate}}" popupTrigger="outsideClick"
                         popupPlacement="top right">
                        {{'GENERAL.GAMES.BET-OPTIONS.LABELS.livebets' | translate | uppercase}}
                    </div>
                    <div *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_LIVE"
                         class="ui small calltoaction label margin-0 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.BET-OPTIONS.only_live_bets_allowed' | translate}}"
                         popupTrigger="outsideClick" popupPlacement="top right">
                        {{'GENERAL.GAMES.BET-OPTIONS.LABELS.livebets_only' | translate | uppercase}}
                    </div>
                    <div *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_PRE_MATCH"
                         class="ui small lightgrey disabled label margin-0 element-clickable"
                         fuiPopup popupText="{{'GENERAL.GAMES.BET-OPTIONS.only_pre_match_bets_allowed' | translate}}"
                         popupTrigger="outsideClick" popupPlacement="top right">
                        {{'GENERAL.GAMES.BET-OPTIONS.LABELS.livebets' | translate | uppercase}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ui attached segment game-info">
        <div class="ui middle aligned centered vertically divided grid">
            <div *ngIf="showPrizes" class="row bgcolor-highlight">
                <div class="two wide column center aligned">
                    <img class="iconimage display-inline-block vertical-align-middle" [src]="'./assets/img/winnings.png'"
                         alt="{{'GENERAL.GAMES.potsize' | translate}}" title="{{'GENERAL.GAMES.potsize' | translate}}">
                </div>
                <div class="eight wide column left aligned">
                    <span class="font-bold" *ngIf="game.competition_type === 2">
                        {{'GENERAL.GAMES.potsize' | translate}}
                    </span>
                    <span class="font-bold" *ngIf="game.competition_type !== 2">
                        {{'GENERAL.GAMES.h2h_win' | translate}}
                    </span>
                </div>
                <ng-container *ngIf="GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                    <div class="six wide column right aligned truncate">
                        <span class="prizepool-label font-bold"
                              *ngFor="let prizeStructure of GameHelpers.getGamePrizeStructuresWithPrizeTitleInGame(game.prize_structures); let i = index">
                            <ng-container *ngIf="i !== 0"><br/>+ </ng-container>
                            {{prizeStructure.prize_title_in_game | dbtranslation:'prize_title_in_game':prizeStructure.translations}}
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                    <div class="six wide column right aligned"
                         *ngIf="(game.is_gift_game === 0 && !game.is_guaranteed && game.competition_type === 2)">
                        <span class="prizepool-label font-bold" *ngIf="game.current_prize_pool > 0 && game.primary_cash_prize_structure_index !== null">
                            {{game.current_prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                        <span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                        </span>
                        <span class="prizepool-label font-bold" *ngIf="game.current_prize_pool === 0 && game.primary_cash_prize_structure_index !== null">
                            {{'GENERAL.GAMES.maximum_abbr' | translate}} {{game.prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                            <span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                        </span>
                    </div>
                    <div class="six wide column right aligned"
                         *ngIf="(game.is_gift_game === 0 && game.is_guaranteed && game.competition_type === 2)">
                        <span class="prizepool-label font-bold" *ngIf="game.primary_cash_prize_structure_index !== null">
                            {{game.prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                        <span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                        </span>
                    </div>
                    <div class="six wide column right aligned"
                         *ngIf="(game.is_gift_game === 1 && game.competition_type === 2)">
                        <span class="prizepool-label font-bold">{{'GENERAL.GAMES.giftprizes' | translate}}</span>
                    </div>
                    <div class="six wide column right aligned" *ngIf="game.competition_type !== 2">
                        <span class="prizepool-label font-bold">{{h2hWinnerInformation}}</span>
                    </div>
                </ng-container>
            </div>

            <div class="row">
                <div class="two wide column center aligned">
                    <img *ngIf="game.competition_type === 2"
                         class="iconimage display-inline-block vertical-align-middle" [src]="'./assets/img/tournament.png'"
                         alt="{{'GENERAL.LABELS.tournament' | translate}}"
                         title="{{'GENERAL.LABELS.tournament' | translate}}">
                    <img *ngIf="game.competition_type !== 2"
                         class="iconimage display-inline-block vertical-align-middle h2h" [src]="'./assets/img/head2head.png'"
                         alt="{{'GENERAL.LABELS.h2h' | translate}}"
                         title="{{'GENERAL.LABELS.h2h' | translate}}">
                </div>
                <div class="eight wide column left aligned" *ngIf="game.competition_type === 2">
                    {{'GENERAL.LABELS.tournament' | translate}}
                </div>
                <div class="eight wide column left aligned" *ngIf="game.competition_type !== 2">
                    {{'GENERAL.LABELS.h2h' | translate}}
                </div>
                <div class="six wide column right aligned" *ngIf="game.is_public">
                    {{'GENERAL.LABELS.public' | translate}}
                </div>
                <div class="six wide column right aligned" *ngIf="!game.is_public">
                    {{'GENERAL.LABELS.private' | translate}}
                </div>
            </div>
            <!-- Tournament or PooledH2H -->
            <div class="row" *ngIf="(game.competition_type === 2 || game.competition_type === 3)">
                <div class="two wide column center aligned">
                    <i class="people icon big"></i>
                </div>
                <div class="eight wide column left aligned">
                    {{'GENERAL.LABELS.players' | translate}}<span
                    *ngIf="(game.minimum_size > 0)"> {{'GENERAL.LABELS.player_minimum_size' | translate: { minimum_size: game.minimum_size.toString()} }}</span>
                </div>
                <div class="six wide column right aligned">
                    <span class="font-bold">{{game.users_count}}</span><span
                    *ngIf="game.tournaments_type_id !== 3"> / {{game.size}}</span>
                </div>
            </div>
            <div class="row">
                <div class="two wide column center aligned">
                    <i class="display-inline-block vertical-align-middle big icon bsi bsi-sport-{{game.sport_id}} bsi-bold"></i>
                </div>
                <div class="eight wide column left aligned">
                    {{game.sport_translations[0].label_value | dbtranslation:'sports_desc':game.sport_translations}}
                </div>
                <div class="six wide column right aligned" *ngIf="game.sport_id !== 999">
                    {{game.matches_count + ' ' + (game.matches_count | i18nPlural:translations.labelTranslations.matchplural)}}
                </div>
                <div class="six wide column right aligned" *ngIf="game.sport_id === 999">
                    {{game.vendor_games_count + ' ' + (game.vendor_games_count | i18nPlural:translations.labelTranslations.vendorgameplural)}}
                </div>
            </div>
            <div class="row" *ngIf="game.max_bet_count > 0">
                <div class="two wide column center aligned">
                    <i class="timeline icon"></i>
                </div>
                <div class="eight wide column left aligned">
                    {{'GENERAL.GAMES.max_bet_count' | translate:{max_bet_count: ''} }}
                </div>
                <div class="six wide column right aligned">
                    {{game.max_bet_count}}
                </div>
            </div>
            <div class="row" *ngIf="relatedTenantRankings?.count > 0">
                <div class="two wide column center aligned">
                    <i class="fa fa-trophy icon big"></i>
                </div>
                <div class="six wide column left aligned">
                    {{'GENERAL.GAMES.related_tenant_rankings' | translate }}
                </div>
                <div class="eight wide column right aligned">
                    <button *ngFor="let relatedTenantRanking of relatedTenantRankings.results;" class="ui small calltoaction label margin-5 margin-r-0 element-clickable" [routerLink]="'../../../ranking/' + relatedTenantRanking.id">
                        {{relatedTenantRanking.title | dbtranslation:'title':relatedTenantRanking.translations | uppercase}}
                    </button>
                </div>
            </div>
            <div class="one column row padding-0"
                 *ngIf="game.competition_type === 3 && (game?.game_state < 3 || game.is_current_user_joined)">
                <div class="column">
                    <div class="ui basic segment margin-0 h2h-segment auto-height">
                        <div class="ui tight grid margin-0">
                            <div class="row middle aligned centered padding-0 margin-0">
                                <div class="seven wide column center aligned">
                                    <betsnaps-user-avatar-loader
                                        *ngIf="game.is_current_user_joined && game?.game_state >= 3 && game?.game_user_group_competitors && game?.game_user_group_competitors[0]"
                                        [user]="game?.game_user_group_competitors[0]"
                                        [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                                    <betsnaps-user-avatar-loader
                                        *ngIf="game.is_current_user_joined && currentUser && (game?.game_state < 3 || !game?.game_user_group_competitors)"
                                        [user]="currentUser"
                                        [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                                    <i class="help icon avatar-placeholder text-primary margin-0"
                                       *ngIf="!currentUser"></i>
                                    <div class="img-wrap" *ngIf="currentUser && !game.is_current_user_joined">
                                        <img [src]="currentUser.media_url_small"
                                             class="ui circular bordered centered tiny primary image"/>
                                        <span class="image-overlay circular">
                                            <i class="fa fa-question icon"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="two wide column center aligned">
                                    <strong class="text-massive font-very-bold">
                                        {{'GENERAL.GAMES.h2h_vs' | translate}}
                                    </strong>
                                </div>
                                <div class="seven wide column center aligned">
                                    <betsnaps-user-avatar-loader
                                        *ngIf="game.is_current_user_joined && game?.game_state >= 3 && game?.game_user_group_competitors && game?.game_user_group_competitors[1]"
                                        [user]="game?.game_user_group_competitors[1]"
                                        [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                                    <i class="help icon avatar-placeholder text-primary margin-0"
                                       *ngIf="!(currentUser && game.is_current_user_joined) || (game.is_current_user_joined && (game?.game_state < 3 || !game?.game_user_group_competitors))"></i>
                                </div>
                            </div>
                            <div class="row middle aligned centered padding-0 margin-0">
                                <div class="seven wide column center aligned">
                                    <strong
                                        *ngIf="game.is_current_user_joined && game?.game_state >= 3 && game?.game_user_group_competitors && game?.game_user_group_competitors[0]">{{game?.game_user_group_competitors[0].username}}</strong>
                                    <strong
                                        *ngIf="game.is_current_user_joined && currentUser && (game?.game_state < 3 || !game?.game_user_group_competitors)">{{currentUser.username}}</strong>
                                    <strong *ngIf="!currentUser">
                                        {{'GENERAL.GAMES.h2h_random_player' | translate}}
                                    </strong>
                                    <strong *ngIf="currentUser && !game.is_current_user_joined">
                                        {{'GENERAL.LABELS.you' | translate}}?
                                    </strong>
                                </div>
                                <div class="two wide column center aligned">
                                </div>
                                <div class="seven wide column center aligned">
                                    <strong
                                        *ngIf="game.is_current_user_joined && game?.game_state >= 3 && game?.game_user_group_competitors && game?.game_user_group_competitors[1]">{{game?.game_user_group_competitors[1].username}}</strong>
                                    <strong
                                        *ngIf="!(currentUser && game.is_current_user_joined) || (game.is_current_user_joined && (game?.game_state < 3 || !game?.game_user_group_competitors))">
                                        {{'GENERAL.GAMES.h2h_random_player' | translate}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <p class="margin-t-10 text-tiny center aligned" *ngIf="game?.game_state < 3">
                            {{'GENERAL.GAMES.h2h_draw' | translate}} {{game.start_date | amLocal | amCalendar:
                            {
                                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                lastDay: tenantService.tenantData.internationalization.date_time_format,
                                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                sameElse: tenantService.tenantData.internationalization.date_time_format
                            }
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="one column row padding-0" *ngIf="game.competition_type === 1">
                <div class="column">
                    <div class="ui basic segment margin-0 h2h-segment auto-height">
                        <div class="ui tight grid margin-0">
                            <div class="row middle aligned centered padding-0 margin-0">
                                <div class="seven wide column center aligned">
                                    <betsnaps-user-avatar-loader *ngIf="game?.h2h_competitors[0]"
                                                          [user]="game?.h2h_competitors[0]"
                                                          [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                                    <i class="help icon avatar-placeholder text-primary margin-0"
                                       *ngIf="!game?.h2h_competitors[0]"></i>
                                </div>
                                <div class="two wide column center aligned">
                                    <strong class="text-massive font-very-bold">
                                        {{'GENERAL.GAMES.h2h_vs' | translate}}
                                    </strong>
                                </div>
                                <div class="seven wide column center aligned">
                                    <betsnaps-user-avatar-loader *ngIf="game?.h2h_competitors[1]"
                                                          [user]="game?.h2h_competitors[1]"
                                                          [imageSize]="'tiny'"></betsnaps-user-avatar-loader>
                                    <i class="help icon avatar-placeholder text-primary margin-0"
                                       *ngIf="!game?.h2h_competitors[1]"></i>
                                </div>
                            </div>
                            <div class="row middle aligned centered padding-0 margin-0">
                                <div class="seven wide column center aligned">
                                    <strong
                                        *ngIf="game?.h2h_competitors[0]">{{game?.h2h_competitors[0]?.username}}</strong>
                                </div>
                                <div class="two wide column center aligned">
                                </div>
                                <div class="seven wide column center aligned">
                                    <strong
                                        *ngIf="game?.h2h_competitors[1]">{{game?.h2h_competitors[1]?.username}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="one column row" *ngIf="showGameNotificationsCheckbox && (!game.is_current_user_joined || (game.is_current_user_joined && game.game_state < 4))">
                <div class="column">
                    <div class="ui basic segment center aligned margin-0 padding-5"
                         *ngIf="!game.is_current_user_joined">
                        <form class="ui form" novalidate [formGroup]="gameNotificationsForm">
                            <fui-checkbox formControlName="game_notifications_not_joined"
                                          class="single-checkbox"
                                          [isDisabled]="processJoin">
                                {{'GENERAL.GAMES.subscribe_for_game_notifications' | translate}}
                            </fui-checkbox>
                        </form>
                    </div>
                    <div class="ui basic segment center aligned margin-0 padding-5"
                         *ngIf="game.is_current_user_joined && game.game_state < 4">
                        <fui-checkbox [(ngModel)]="this.game.game_notifications_subscribed"
                                      class="single-checkbox"
                                      (checkChange)="checkGameNotificationChange()"
                                      [isDisabled]="processGameNotificationChange">
                            {{'GENERAL.GAMES.subscribe_for_game_notifications' | translate}} <span class="ui active inline mini loader" *ngIf="processGameNotificationChange"></span>
                        </fui-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui middle aligned grid margin-t-10 mobile-bottom-fixed-join-row"
             *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state < 3 && game.size !== game.users_count">
            <div class="row padding-0">
                <div class="column" *ngIf="!joinApproveVisible">
                    <button
                        *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state < 3 && game.size !== game.users_count"
                        type="button" class="ui calltoaction fluid large button game-detail-button"
                        (click)="approveJoin(); googleAnalyticsService.trackEvent('game - details', 'click', 'join game')"
                        [class.loading]="processJoin"
                        [class.padding-t-10]="!showPrizes"
                        [class.padding-b-10]="!showPrizes">
                        <span class="nowrap">
                            <strong>{{'GENERAL.GAMES.join_btn' | translate | uppercase}}</strong>
                        </span>
                        <div *ngIf="showPrizes && (game.entry_fee !== 0)" class="small text">
                            {{'GENERAL.GAMES.entry_fee' | translate}}: {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                        </div>
                        <div *ngIf="showPrizes && (game.entry_fee === 0)" class="small text">
                            {{'GENERAL.GAMES.entry_fee_free' | translate}}
                        </div>
                    </button>
                </div>
                <div *ngIf="joinApproveVisible" class="three wide column hidden">
                    <button class="ui basic button fluid large game-detail-button"
                            (click)="joinApproveVisible = false; googleAnalyticsService.trackEvent('game - details', 'click', 'cancel join');">
                        <i class="icon close huge margin-0"></i>
                    </button>
                </div>
                <div *ngIf="joinApproveVisible" class="thirteen wide column left aligned hidden">
                    <button type="button" class="ui calltoaction fluid large button game-detail-button"
                            (click)="joinGame(); googleAnalyticsService.trackEvent('game - details', 'click', 'approve join');"
                            [class.loading]="processJoin">
                        <span class="nowrap">
                            <strong>{{'GENERAL.GAMES.confirm_join_btn' | translate | uppercase}}</strong>
                        </span>
                        <div *ngIf="(game.entry_fee !== 0)" class="small text">
                            {{'GENERAL.LABELS.for' | translate}}: {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                        </div>
                        <div *ngIf="(game.entry_fee === 0)" class="small text">
                            {{'GENERAL.GAMES.entry_fee_free' | translate}}
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="ui grid margin-t-10"
             *ngIf="(currentUser && game.is_current_user_joined) || game.game_state > 2 || game.size === game.users_count">
            <div class="row padding-0">
                <div class="column">
                    <!-- FULLY BOOKED -->
                    <button
                        *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state < 3 && game.size === game.users_count"
                        type="button" class="ui calltoaction fluid large black button game-detail-button"
                        [routerLink]="(game.competition_type > 1) ? '../players' : null">
                        <span class="nowrap">
                            <strong>{{'GENERAL.GAMES.STATI.fully-booked' | translate | uppercase}}</strong>
                        </span>
                        <div *ngIf="game.competition_type > 1" class="small text">
                            {{'GENERAL.GAMES.show_players' | translate}}
                        </div>
                    </button>
                    <!-- JOINED BEFORE FINISHED -->
                    <button *ngIf="currentUser && game.is_current_user_joined && game.game_state < 4"
                            type="button" class="ui calltoaction fluid large button game-detail-button" [class.padding-10]="advancedButtonType === 'actionCasinoSnap'"
                            [routerLink]="(advancedButtonType === 'actionCasinoSnap') ? '../games' : ((advancedButtonType === 'actionPlaceBet') ? '../matches' : ((advancedButtonType === 'actionCashout') ? '../bets' : '../ranking'))">
                        <span class="nowrap"><strong>{{advancedButtonTitleLabel | translate | uppercase}}</strong></span>
                        <div class="small text" *ngIf="advancedButtonType === 'actionDefault' || advancedButtonType === 'actionPlaceBet'">{{'GENERAL.GAMES.POINTS.free_points' | translate }}: {{game.game_user.user_game_points | number:'1.2-2'}}</div>
                        <div class="small text" *ngIf="advancedButtonType === 'actionCashout'">{{'GENERAL.GAMES.open_bets' | translate }}: {{game.current_user_open_bet_count}}</div>
                    </button>
                    <!-- NOT JOINED RUNNING -->
                    <button *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state === 3"
                            type="button" class="ui calltoaction fluid large button game-detail-button"
                            [routerLink]="(!currentUser || game.competition_type === 3) ? ((game.competition_type > 1) ? '../players' : null) : '../ranking'">
                        <span class="nowrap">
                            <strong>{{'GENERAL.GAMES.STATI.running' | translate | uppercase}}</strong>
                        </span>
                        <div class="small text">
                            {{(!currentUser || game.competition_type === 3) ?
                            ((game.competition_type > 1) ? ('GENERAL.GAMES.show_players' | translate) : null) :
                            ('GENERAL.GAMES.show_ranking' | translate)}}
                        </div>
                    </button>
                    <!-- FINISHED / CLOSED -->
                    <button *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state > 3 && game.game_state <= 7" type="button"
                            class="ui calltoaction fluid large black button game-detail-button"
                            [routerLink]="(game.competition_type === 1) ? null : ((game.competition_type === 2 && currentUser) ? '../ranking' : '../players')">
                        <span class="nowrap">
                            <strong *ngIf="game.game_state === 7">{{'GENERAL.GAMES.STATI.closed' | translate | uppercase}}</strong>
                            <strong *ngIf="game.game_state !== 7">{{'GENERAL.GAMES.STATI.finished' | translate | uppercase}}</strong>
                        </span>
                        <div class="small text" *ngIf="(game.competition_type > 1)">
                            {{(game.competition_type === 2 && currentUser) ? ('GENERAL.GAMES.show_ranking' | translate) : ('GENERAL.GAMES.show_players' | translate)}}
                        </div>
                    </button>
                    <button *ngIf="currentUser && game.is_current_user_joined && game.game_state > 3 && game.game_state <= 7" type="button"
                            class="ui calltoaction fluid large black button game-detail-button"
                            [routerLink]="'../ranking'">
                        <span class="nowrap">
                            <strong *ngIf="advancedButtonType === 'pastSnap'"><strong>{{'GENERAL.GAMES.not_in_prize_ranks' | translate | uppercase}}</strong></strong>
                            <strong *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 0 && game.primary_cash_prize_structure_index !== null">{{game.game_user.prize_amount | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}</strong>
                            <strong *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 1 && getLeaderBoardUserProperty(game, 'tenant_prize')">{{getLeaderBoardUserProperty(game, 'tenant_prize')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize')?.translations}}</strong>
                            <strong *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 1 && !getLeaderBoardUserProperty(game, 'tenant_prize') && getLeaderBoardUserProperty(game, 'tenant_prize_template')">{{getLeaderBoardUserProperty(game, 'tenant_prize_template')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize_template')?.translations}}</strong>
                        </span>
                        <div class="small text" *ngIf="advancedButtonType === 'pastSnap'">{{'GENERAL.GAMES.better_luck_next_time' | translate }}</div>
                        <div class="small text" *ngIf="advancedButtonType === 'pastSnapIsWinner'&& game.is_gift_game === 0"><span *ngIf="game.primary_cash_prize_structure_index !== null && game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true">{{'GENERAL.LABELS.bet_bonus' | translate | uppercase}}&nbsp;</span>{{'GENERAL.LABELS.won' | translate | uppercase}}</div>
                        <div class="small text" *ngIf="advancedButtonType === 'pastSnapIsWinner'&& game.is_gift_game === 1">{{'GENERAL.LABELS.won' | translate}}</div>
                    </button>
                    <!-- CANCELLED -->
                    <button *ngIf="game.game_state === 99" type="button" class="ui calltoaction fluid large black button game-detail-button"
                            [routerLink]="(currentUser && game.is_current_user_joined) ? '/profile/wallet' : null">
                        <span class="nowrap">
                            <strong>{{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}</strong>
                        </span>
                        <div class="small text" *ngIf="(currentUser && game.is_current_user_joined)">
                            {{('GENERAL.GAMES.entry_fee_refunded' | translate | uppercase)}}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="ui top attached header bgcolor-light-grey2 margin-t-15" *ngIf="prizeStructureVisible() && betsnapdetailService.isTournament">
        <div class="ui middle aligned  grid">
            <div class="row">
                <div class="sixteen wide column">
                    <h5 class="ui left aligned header">{{'GENERAL.GAMES.prize_structure' | translate | uppercase}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="ui attached segment prize-structure-ranges-segment"
         *ngIf="prizeStructureVisible() && betsnapdetailService.isTournament">
        <div class="ui middle aligned centered vertically divided grid">
            <div class="row" *ngFor="let prizeStructureRange of prizeStructureRanges">
                <div class="two wide column center aligned">
                    <img *ngIf="prizeStructureRange.fromRank <= 3"
                         class="iconimage display-inline-block vertical-align-middle"
                         [src]="'./assets/img/prizes-rank-' + prizeStructureRange.fromRank + '.png'"
                         alt="">
                    <img *ngIf="prizeStructureRange.fromRank > 3"
                         class="iconimage display-inline-block vertical-align-middle"
                         [src]="'./assets/img/prizes-rank-other.png'"
                         alt="">
                </div>
                <div class="eight wide column left aligned">
                    <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="prizeStructureRange.fromRank === prizeStructureRange.toRank">{{prizeStructureRange.fromRank}}<span class="text-mini">{{prizeStructureRange.fromRank | ordinalNumber:true}}</span> {{'GENERAL.GAMES.rank' | translate}}</span>
                    <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="prizeStructureRange.fromRank !== prizeStructureRange.toRank">{{prizeStructureRange.fromRank}}<span class="text-mini">{{prizeStructureRange.fromRank | ordinalNumber:true}}</span> - {{prizeStructureRange.toRank}}<span class="text-mini">{{prizeStructureRange.toRank | ordinalNumber:true}}</span> {{'GENERAL.GAMES.rank' | translate}}</span>
                </div>
                <div class="six wide column right aligned">
                    <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}"
                          *ngIf="(game.is_gift_game === 0)">
                        <span *ngIf="game.primary_cash_prize_structure_index !== null">{{prizeStructureRange.prize_amount | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}</span>
                        <span *ngIf="game.primary_cash_prize_structure_index === null">{{prizeStructureRange.prize_amount | decimalPrize: 2}}</span>
                        <span
                            *ngIf="game.primary_cash_prize_structure_index !== null && game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonus' | translate}}</span>
                        <div *ngIf="prizeStructureRange.tenant_prize" class="gift-title">
                            + {{prizeStructureRange.tenant_prize.title | dbtranslation:'title':prizeStructureRange.tenant_prize.translations}}
                        </div>
                        <div *ngIf="!prizeStructureRange.tenant_prize && prizeStructureRange.tenant_prize_template" class="gift-title">
                            + {{prizeStructureRange.tenant_prize_template.title | dbtranslation:'title':prizeStructureRange.tenant_prize_template.translations}}
                        </div>
                    </span>
                    <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="(game.is_gift_game === 1 && prizeStructureRange.tenant_prize)">
                        {{prizeStructureRange.tenant_prize?.title | dbtranslation:'title':prizeStructureRange.tenant_prize?.translations}}
                    </span>
                    <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="(game.is_gift_game === 1 && !prizeStructureRange.tenant_prize && prizeStructureRange.tenant_prize_template)">
                        {{prizeStructureRange.tenant_prize_template?.title | dbtranslation:'title':prizeStructureRange.tenant_prize_template?.translations}}
                    </span>
                </div>
            </div>

        </div>
    </div>
    <div class="ui basic segment center aligned"
         *ngIf="game.game_state < 3 && game.is_current_user_joined && (game.entry_fee > 0 || (game.entry_fee === 0 && game.leave_free_game))">
        <button type="button" class="ui large button game-leave margin-0"
                (click)="openLeaveModal(); googleAnalyticsService.trackEvent('game - details', 'click', 'leave game')">
            {{'GENERAL.GAMES.leave-snap_btn' | translate | uppercase}}
        </button>
    </div>
</div>

<ng-template let-context let-modal="modal" #modalJoinedTemplate>
    <div class="big header">{{'GENERAL.GAMES.join-modal_heading' | translate}}</div>
    <div class="content inverted primary">
        <div class="ui centered grid padded">
            <div class="two column row">
                <div class="six wide column middle aligned">
                    <img class="ui middle aligned right spaced image" [src]="joinModalImage"/>
                </div>
                <div class="ten wide column middle aligned">
                    <p>
                        {{'GENERAL.GAMES.join-modal_firsttextpart' | translate}} <span
                        class="font-bold huge">{{'GENERAL.GAMES.join-modal_secondtextpart' | translate}}</span>
                    </p>
                    <button type="button" class="ui button action large"
                            (click)="googleAnalyticsService.trackEvent('game - details - joinModal', 'click', 'place bets now'); modalRedirect(((game.sport_id === 999) ? '../games' : '../matches'), modalJoined);">
                        <strong>{{'GENERAL.GAMES.place_bets_now_btn' | translate | uppercase}}</strong>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template let-context let-modal="modal" #modalLeaveTemplate
             *ngIf="game.game_state < 3 && game.is_current_user_joined && (game.entry_fee > 0 || (game.entry_fee === 0 && game.leave_free_game))">
    <div class="header">
        {{'GENERAL.GAMES.leave-modal_heading' | translate}}
    </div>
    <div class="actions">
        <button class="ui inverted button"
                (click)="modalLeave.deny(); googleAnalyticsService.trackEvent('game - details - leaveModal', 'click', 'cancel leave');">
            {{'GENERAL.GAMES.leave_no_btn' | translate}}
        </button>
        <button class="ui inverted calltoaction button"
                (click)="leaveGame(); googleAnalyticsService.trackEvent('game - details - leaveModal', 'click', 'approve leave');"
                [class.loading]="processLeave"
                [disabled]="processLeave">
            {{'GENERAL.GAMES.leave_yes_btn' | translate}}
        </button>
    </div>
</ng-template>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300"
    [additionalClass]="'betsnapdetail-details-scroll-to-top'"
    [class.notJoined]="!game.is_current_user_joined">
</betsnaps-go-to-top-button>
