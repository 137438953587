/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * WidgetTenantRankingHttpResponse
 */
export interface AppHttpResponsesWidgetsWidgetTenantRankingHttpResponse { 
    /**
     * Tenant Ranking Id
     */
    id: number;
    /**
     * Tenant Ranking Title
     */
    title: string;
    /**
     * Cash Currency Symbol
     */
    currency_symbol?: string;
    /**
     * Ranking Positions
     */
    ranking_positions?: Array<AppHttpResponsesWidgetsWidgetTenantRankingHttpResponse>;
}

