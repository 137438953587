/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * OauthAccessTokenHttpResponse
 */
export interface AppHttpResponsesAuthOauthAccessTokenHttpResponse { 
    /**
     * Oauth Access Token Id
     */
    id: string;
    /**
     * User Id
     */
    user_id?: number;
    /**
     * Client Id
     */
    client_id: number;
    /**
     * Name
     */
    name?: string;
    /**
     * Scopes
     */
    scopes?: Array<string>;
    /**
     * Is revoked (false, true)
     */
    revoked: boolean;
    /**
     * Created Date
     */
    created_at: Date;
    /**
     * Updated Date
     */
    updated_at: Date;
    /**
     * Expire Date
     */
    expires_at: Date;
}

