import {Component, HostBinding, QueryList, SimpleChanges, ViewChildren, ViewContainerRef} from '@angular/core';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    GoogleAnalyticsService,
    HintService,
    TenantService
} from '../../../../shared';
import {AngularPageVisibilityService} from 'angular-page-visibility-v2';
import {FeedService} from '../../../../shared/services/feed.service';
import {AMatchCardComponent} from '../a-match-card-component';
import {AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse} from '../../../../api';
import {SpecialMarketDefault} from '../../special-market/special-market-default/special-market-default';

@Component({
    selector: 'betsnaps-match-card-default',
    templateUrl: './match-card-default.component.html',
    styles: []
})
export class MatchCardDefaultComponent extends AMatchCardComponent {
    @HostBinding('class') componentClass = 'ui fluid card match-card';

    public placeBetAnimationClass: string;
    public placeBetDialogVisible: boolean = false;
    public placeBetOutcome: MatchMarketOutcomeHttpResponse;

    public specialMarketsOpened: boolean = false;
    public allMarketsOpen: boolean = false;

    @ViewChildren(SpecialMarketDefault) marketAccordions: QueryList<SpecialMarketDefault>;

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                protected pageVisibilityService: AngularPageVisibilityService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public tenantService: TenantService,
                public feedService: FeedService,
                protected adService: AdService,
                protected viewContainerRef: ViewContainerRef) {
        super(
            authenticationService,
            betsnapdetailService,
            pageVisibilityService,
            hintService,
            googleAnalyticsService,
            tenantService,
            feedService,
            adService,
            viewContainerRef
        );

        if (this.tenantService.tenantData.configuration.markets_default_expanded) {
            this.allMarketsOpen = true;
        }
    }

    onChanges(changes: SimpleChanges) {
        super.onChanges(changes);

        // remove and reset bet placement dialog if user not joined
        if (!this.game.is_current_user_joined) {
            this.placeBetAnimationClass = null;
            this.placeBetOutcome = null;
            this.placeBetDialogVisible = false;
        }
    }

    toggleSpecialMarkets() {
        if (this.match.markets_count > 0) {
            if (!this.specialMarketsOpened) {
                this.showMarkets();

                this.googleAnalyticsService.trackEvent(
                    'matchcard',
                    'click',
                    'sportevent click show special markets'
                );
            } else {
                this.hideMarkets();
            }
        }
    }

    showMarkets() {
        if (this.game) {
            this.specialMarketsOpened = true;
            // get market list for match
            if (!this.matchMarkets) {
                this.betsnapdetailService.getMatchMarkets(
                    this.game.game_unique_id,
                    this.match.match_id
                );
            }

            if (!this.matchMarkets && this.currentUser) {
                this.hintService.checkForHintToDisplay('betsnapdetail-matches-specialmarkets');
            }

            this.googleAnalyticsService.trackEvent(
                'matchcard',
                'show',
                'special markets'
            );
        }
    }

    hideMarkets() {
        this.specialMarketsOpened = false;

        this.googleAnalyticsService.trackEvent(
            'matchcard',
            'hide',
            'special markets'
        );
    }

    openCloseAllMarketAccordions() {
        this.allMarketsOpen = !(this.allMarketsOpen);

        if (this.allMarketsOpen) {
            this.googleAnalyticsService.trackEvent(
                'matchcard',
                'expand',
                'all markets'
            );
        } else {
            this.googleAnalyticsService.trackEvent(
                'matchcard',
                'collapse',
                'all markets'
            );
        }
    }

    togglePlaceBetDialog(outcome: MatchMarketOutcomeHttpResponse) {
        if (this.placeBetDialogVisible &&
            this.placeBetOutcome.market_id === outcome.market_id &&
            this.placeBetOutcome.outcome_id === outcome.outcome_id &&
            this.placeBetOutcome.specifier_val === outcome.specifier_val) {
            this.closePlaceBetDialog(true);
        } else {
            this.openPlaceBetDialog(outcome);
        }
    }

    openPlaceBetDialog(outcome: MatchMarketOutcomeHttpResponse) {
        if (this.currentUser &&
            this.game.is_current_user_joined &&
            this.betPlacementAllowed &&
            (this.game.max_bet_count === 0 || (this.game.max_bet_count > 0 && this.game.current_user_bet_count < this.game.max_bet_count)) &&
            this.game.game_user.user_game_points > 0) {

            if (!this.placeBetDialogVisible) {
                this.closeOpenPlaceBetDialogs();
                this.onPlaceBetClick.emit();
            }

            this.placeBetOutcome = outcome;
            this.placeBetDialogVisible = true;
            setTimeout(() => {
                if (!this.placeBetAnimationClass) {
                    this.placeBetAnimationClass = 'visible';
                }
            }, 10);

        }
    }

    closePlaceBetDialog(animated: boolean = false) {
        if (this.placeBetDialogVisible) {
            if (animated) {
                this.placeBetAnimationClass = null;
                setTimeout(() => {
                    this.placeBetDialogVisible = false;
                    this.placeBetOutcome = null;
                }, 500);
            } else {
                this.placeBetAnimationClass = null;
                this.placeBetDialogVisible = false;
                this.placeBetOutcome = null;
            }
        }
    }

    handlePlaceBetClickOfSpecialMarket() {
        this.closePlaceBetDialog();
        this.closeOpenPlaceBetDialogs();
        this.onPlaceBetClick.emit();
    }

    closeOpenPlaceBetDialogs() {
        const specialMarkets: SpecialMarketDefault[] = this.marketAccordions.toArray();
        specialMarkets.map((specialMarket) => specialMarket.closePlaceBetDialog());
    }

    protected handleOpenedPlaceBetDialog() {
        if (this.placeBetDialogVisible && this.placeBetOutcome) {
            // if default market exists
            if (this.match.default_market) {
                // find outcome of placebetdialog
                const placeBetOutcomeExists = this.match.default_market.outcomes.find(
                    (marketOutcome: MatchMarketOutcomeHttpResponse) => (
                        marketOutcome.match_id === this.placeBetOutcome.match_id &&
                        marketOutcome.market_id === this.placeBetOutcome.market_id &&
                        marketOutcome.specifier_val === this.placeBetOutcome.specifier_val &&
                        marketOutcome.outcome_id === this.placeBetOutcome.outcome_id
                    )
                );
                if (placeBetOutcomeExists) {
                    this.placeBetOutcome = placeBetOutcomeExists;
                } else {
                    this.closePlaceBetDialog();
                }
            } else {
                this.closePlaceBetDialog();
            }
        }
    }

}
