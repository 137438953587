<div class="ui fluid flexauto item tiny separatorless menu" *ngIf="!processList && gameUsersFriendsList && gameUsersFriendsList.total > 0">
    <a href="#" class="item" [class.active]="menu.active" *ngFor="let menu of playersMenu" (click)="$event.preventDefault(); playersMenuChange(menu.key);">
            <span class="display-inline-block vertical-align-middle">
                {{menu.translatekey | translate | uppercase}}
            </span><span class="display-inline-block vertical-align-middle text-mini">&nbsp;{{
                    (menu.key === 'all') ? '(' + gameUsersList.total + ')' : ''
                }}{{
                    (menu.key === 'friends' && gameUsersFriendsList) ? '(' + gameUsersFriendsList.total + ')' : ''
                }}
            </span>
    </a>
</div>
<div class="ui vertical borderless segment center aligned padding-t-0" *ngIf="gameUsersChanged">
    <button type="button"
            class="ui basic icon fluid button margin-0"
            (click)="reloadData()">
        <i class="refresh icon"></i> {{'BETSNAPDETAIL.PLAYERS.refresh_playerlist_btn' | translate}}
    </button>
</div>
<div class="ui vertical borderless very padded segment" *ngIf="processList">
    <div class="ui active centered inline text loader on-body-bg">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="!processList && visibleGameUsersList">
    <div class="ui three column tight grid" *ngIf="visibleGameUsersList.total > 0">
        <div *ngFor="let gameUser of visibleGameUsersList.results; trackBy: gameUserTrackBy" class="column">
            <div class="ui center aligned card margin-0">
                <div class="content">
                    <betsnaps-user-avatar-loader [user]="gameUser.user"></betsnaps-user-avatar-loader>
                    <span class="truncate">{{gameUser.user.username}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="ui vertical borderless center aligned padded segment" *ngIf="visibleGameUsersList.total < 1">
        <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
    </div>
    <div class="ui basic segment center aligned" *ngIf="activeTab === 'all' && gameUsersList?.results.length < gameUsersList.total"
         #loadMoreButton>
        <button type="button"
                class="ui button margin-0"
                [class.loading]="processLoadMore"
                (click)="loadMore()">
            {{'GENERAL.load-more_btn' | translate}}
        </button>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
