/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsFreemiumTenantRewardHttpResponse } from './appHttpResponsesBetsnapsFreemiumTenantRewardHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * MarketingPartnerHttpResponse
 */
export interface AppHttpResponsesMarketingMarketingPartnerHttpResponse { 
    /**
     * Marketing Partner ID
     */
    id: number;
    /**
     * Marketing Partner Name
     */
    name: string;
    /**
     * Data Transfer Declaration
     */
    data_transfer_declaration: string;
    tenant_reward?: AppHttpResponsesBetsnapsFreemiumTenantRewardHttpResponse;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

