/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameTenantHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameTenantHttpResponse { 
    /**
     * Game Tenant Id
     */
    game_tenant_id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Game Unique Id
     */
    game_unique_id: string;
    /**
     * Allow join multiple Game Clones
     */
    allow_join_multiple_game_clones?: boolean;
}

