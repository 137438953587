/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesUsersUserPermissionHttpResponse } from './appHttpResponsesUsersUserPermissionHttpResponse';
import { AppHttpResponsesUsersUserRoleHttpResponse } from './appHttpResponsesUsersUserRoleHttpResponse';
import { AppHttpResponsesTenantsTenantHttpResponse } from './appHttpResponsesTenantsTenantHttpResponse';


/**
 * AdminHttpResponse
 */
export interface AppHttpResponsesUsersAdminHttpResponse { 
    /**
     * User Id
     */
    id: number;
    /**
     * Unique ID
     */
    unique_id: string;
    /**
     * Username
     */
    username?: string;
    /**
     * E-Mail
     */
    email?: string;
    /**
     * is users email verified
     */
    is_email_verified: number;
    /**
     * Phone Number
     */
    phone?: string;
    /**
     * is users phone verified
     */
    is_phone_verified: number;
    /**
     * is User active (0... no, 1...yes)
     */
    is_active: number;
    /**
     * is User banned (0... no, 1...yes)
     */
    is_banned: number;
    /**
     * First name
     */
    first_name?: string;
    /**
     * Last name
     */
    last_name?: string;
    media?: AppHttpResponsesMediaMediaPublicHttpResponse;
    /**
     * Tenants
     */
    tenants?: Array<AppHttpResponsesTenantsTenantHttpResponse>;
    /**
     * Roles
     */
    roles?: Array<AppHttpResponsesUsersUserRoleHttpResponse>;
    /**
     * Permissions
     */
    permissions?: Array<AppHttpResponsesUsersUserPermissionHttpResponse>;
}

