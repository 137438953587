import {Component, OnInit} from '@angular/core';
import {GoogleAnalyticsService} from '../../../../shared';

@Component({
    selector: 'betsnaps-sidebar-create-snap',
    templateUrl: './sidebar-create-snap.component.html',
    styles: []
})
export class SidebarCreateSnapComponent implements OnInit {

    constructor(public googleAnalyticsService: GoogleAnalyticsService) {
    }

    ngOnInit() {
    }

}
