<button type="button"
        [ngClass]="'ui basic lightgrey2 fluid button outcome-button outcome-' + outcome.outcome_id"
        [class.disabled]="isDisabled"
        [disabled]="isDisabled"
        [class.selected]="isSelected"
        (click)="openPlaceBetDialog()">
    <span class="outcome-name tiny">
        {{outcome.outcome_name | dbtranslation:'name':outcome.translations}}
    </span>
    <span class="outcome-value" *ngIf="oddDisplayFormat === 'odds'">
        {{outcome.odd_decimal | number:'1.2-2'}}
    </span>
    <span class="outcome-value" *ngIf="oddDisplayFormat === 'value'">
        +{{outcome.odd_points}}
    </span>
    <span class="outcome-value" *ngIf="oddDisplayFormat === 'fractional'">
        {{outcome.odd_fractional}}
    </span>
    <span class="outcome-value" *ngIf="oddDisplayFormat === 'moneyline'">
        {{outcome.odd_moneyline}}
    </span>
    <span class="outcome-change-arrow"
          *ngIf="outcomeChange !== 0"
          [ngClass]="{
            'flashit-animation' : showAnimation,
            'outcome-change-positive color-positive' : outcomeChange === 1,
            'outcome-change-negative color-negative' : outcomeChange === -1
          }">
    </span>
</button>

<ng-template let-context let-modal="modal" #modalJoinTemplate>
    <div class="big header">{{'GENERAL.BETS.ERRORS.bet_not_possible_title' | translate}}</div>
    <div class="content inverted primary">
        <div class="ui centered grid padded">
            <div class="two column row">
                <div class="six wide column middle aligned">
                    <img class="ui middle aligned right spaced image" [src]="loginModalImage"/>
                </div>
                <div class="ten wide column middle aligned">
                    <p>
                        {{'GENERAL.BETS.ERRORS.join_snap_first' | translate}}
                    </p>
                    <button
                        (click)="linkRedirect('../details'); modal.approve();"
                        class="ui button action large margin-t-15">
                        {{'GENERAL.GAMES.snap_details_btn' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
