import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'groupBy'})

export class GroupByPipe implements PipeTransform {
    transform(value: Array<any>, field: string): Array<any> {

        let groupedObj;
        const fields = field.split('.');

        if (fields.length === 2) {
            groupedObj = value.reduce((prev, cur) => {
                if (!prev[cur[fields[0]][fields[1]]]) {
                    prev[cur[fields[0]][fields[1]]] = [cur];
                } else {
                    prev[cur[fields[0]][fields[1]]].push(cur);
                }
                return prev;
            }, {});
        } else {
            groupedObj = value.reduce((prev, cur) => {
                if (!prev[cur[field]]) {
                    prev[cur[field]] = [cur];
                } else {
                    prev[cur[field]].push(cur);
                }
                return prev;
            }, {});
        }

        return Object.keys(groupedObj).map((key) => {
            return {key, items: groupedObj[key]};
        });
    }
}
