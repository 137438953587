import { OnDestroy, OnInit, Directive } from '@angular/core';
import {
    AuthenticationService,
    ErrorService, MyNotificationsService,
    ProfileService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../api';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {take, takeWhile} from 'rxjs/operators';

@Directive()
export abstract class AProfileChangeEmailComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    public currentUser: PlayerHttpResponse;

    public processForm: boolean = false;
    public showAlert: boolean = false;
    public processConfirmation: boolean = false;

    public emailForm: UntypedFormGroup = new UntypedFormGroup({
        new_email: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    protected isB2b: boolean = false;

    protected nativeWindow: Window;

    protected navigationTargetUrl = '/profile/email';
    public backUrl = '/profile/overview';

    protected constructor(protected authenticationService: AuthenticationService,
                          protected profileService: ProfileService,
                          protected tenantService: TenantService,
                          protected myNotificationsService: MyNotificationsService,
                          protected translateService: TranslateService,
                          protected errorService: ErrorService,
                          protected windowRef: WindowRef,
                          protected activatedRoute: ActivatedRoute,
                          protected router: Router) {
        this.nativeWindow = windowRef.nativeWindow;
        if (this.authenticationService.currentUser) {
            this.isB2b = this.authenticationService.currentUser.b2b_user_id !== null;
        }

        if (this.tenantService.componentTemplateToLoad === 'v3') {
            this.backUrl = '/profile/edit';
        }

        this.activatedRoute.params
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((params: Params) => {
                const newEmailVerificationToken = params['new_email_verification_token'] || null;
                if (newEmailVerificationToken) {
                    this.processConfirmation = true;
                    this.profileService.changeEmail(
                        newEmailVerificationToken
                    ).pipe(take(1))
                    .subscribe({
                        next: (player: PlayerHttpResponse) => {
                            this.authenticationService.setCurrentUser(player);

                            this.emailForm.reset();
                            this.translateService.get(['PROFILE.CHANGEEMAIL.updated_successfully'])
                                .pipe(take(1)).subscribe(
                                translation => {
                                    this.myNotificationsService.createNotificationToast('', translation['PROFILE.CHANGEEMAIL.updated_successfully'], NotificationType.Success);
                                });

                            this.router.navigate([this.navigationTargetUrl]);
                            return;
                        },
                        error: (err: HttpErrorResponse) => {
                            this.processConfirmation = false;
                            this.errorService.handleHttpErrorResponse(err);
                        }
                    });
                }
            });
    }

    ngOnInit() {
        this.currentUser = this.authenticationService.currentUser;
        if (this.isB2b) {
            this.router.navigate(['/profile/overview']);
            return;
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    onSubmit() {
        if (!this.processForm) {
            this.processForm = true;

            this.profileService.initializeEmailChange(this.emailForm.value.new_email)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.emailForm.reset();
                        this.showAlert = true;
                        this.processForm = false;
                        this.nativeWindow.window.scrollTo(0, 0);
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processForm = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }
}
