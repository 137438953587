<div class="ui segments" *ngIf="currentUser">
    <div class="ui clearing segment">
        <div class="ui middle aligned two column grid">
            <div class="row">
                <div class="thirteen wide column">
                    <h2 class="ui header">{{'PROFILE.OVERVIEW.MENU.privacy' | translate}}</h2>
                </div>
                <div class="right aligned three wide column">
                    <a routerLink="/profile/overview" class="ui basic circular icon button margin-0">
                        <i class="close icon margin-0"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="ui segment">

        <div class="ui active centered inline text loader" *ngIf="showSpinner">
            {{'GENERAL.loadertext' | translate}}
        </div>

        <form class="ui form" novalidate [formGroup]="privacyForm" *ngIf="!showSpinner">
            <h3 class="ui dividing header">
                {{'PROFILE.PRIVACY.data_processing' | translate}}
            </h3>
            <div class="field" *ngFor="let marketingPartner of marketingPartnersList?.results">
                <div class="ui container grid">
                    <div class="fourteen wide column">
                        <fui-checkbox class="slider"
                                      name="{{'data-' + marketingPartner.id}}"
                                      id="{{'data-' + marketingPartner.id}}"
                                      formControlName="{{'data-' + marketingPartner.id}}"
                                      (click)="setUsersMarketingPartnerPermissions(marketingPartner.id);">
                            {{'PROFILE.PRIVACY.data_transfer_approval' | translate}}
                            <span class="font-very-bold">
                                {{marketingPartner.name | dbtranslation:'name':marketingPartner.translations}}
                            </span>
                        </fui-checkbox>
                    </div>
                    <div class="two wide column center aligned">
                        <i class="info icon big link" (click)="showMarketingNotification(marketingPartner);"></i>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
