import {Component, HostBinding, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService, GamePointsEngineEnum,
    GoogleAnalyticsService,
    HintService,
    MyModalService,
    TenantService
} from '../../../../shared';
import {AngularPageVisibilityService} from 'angular-page-visibility-v2';
import {FeedService} from '../../../../shared/services/feed.service';
import {AMatchCardComponent} from '../a-match-card-component';
import {
    AppHttpResponsesSportsDataMatchMarketHttpResponse as MatchMarketHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse
} from '../../../../api';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {PlaceBetDialogV3Component} from '../../place-bet-dialog/place-bet-dialog-v3/place-bet-dialog-v3.component';

@Component({
    selector: 'betsnaps-match-card-v3',
    templateUrl: './match-card-v3.component.html',
    styles: []
})
export class MatchCardV3Component extends AMatchCardComponent {
    @HostBinding('class') componentClass = 'ui fluid card match-card-v3';

    @HostBinding('class.match-completed') get matchCompletedClass() {
        return this.isMatchCompleted();
    };

    public placeBetOutcome: MatchMarketOutcomeHttpResponse;
    public placeBetMarket: MatchMarketHttpResponse;

    @ViewChild('placeBetDialog') public placeBetDialog: PlaceBetDialogV3Component;

    @ViewChild('specialMarketsModalTemplate') specialMarketsModalTemplate: ModalTemplate<null, string, string>;
    public modalSpecialMarkets = null;

    @ViewChild('placeBetModalTemplate') placeBetModalTemplate: ModalTemplate<null, string, string>;
    public modalPlaceBet = null;

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                protected pageVisibilityService: AngularPageVisibilityService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public tenantService: TenantService,
                public feedService: FeedService,
                protected adService: AdService,
                protected viewContainerRef: ViewContainerRef,
                private myModalService: MyModalService) {
        super(
            authenticationService,
            betsnapdetailService,
            pageVisibilityService,
            hintService,
            googleAnalyticsService,
            tenantService,
            feedService,
            adService,
            viewContainerRef
        );
    }

    onChanges(changes: SimpleChanges) {
        super.onChanges(changes);

        // remove and reset bet placement dialog if user not joined
        if (!this.game.is_current_user_joined) {
            this.closePlaceBetModal();
        }
    }

    public openSpecialMarketsModal() {
        if (!this.modalSpecialMarkets &&
            this.game && this.match &&
            this.match.markets_count > 0 && this.marketsVisible) {

            const modalSpecialMarketsConfig = new TemplateModalConfig<null, string, string>(this.specialMarketsModalTemplate);
            modalSpecialMarketsConfig.size = ModalSize.Tiny;
            this.modalSpecialMarkets = this.myModalService.openModal(modalSpecialMarketsConfig)
                .onApprove(() => {
                    this.modalSpecialMarkets = null;
                })
                .onDeny(() => {
                    this.modalSpecialMarkets = null;
                });

            // get market list for match
            if (!this.matchMarkets) {
                this.betsnapdetailService.getMatchMarkets(
                    this.game.game_unique_id,
                    this.match.match_id
                );
            }

            if (!this.matchMarkets && this.currentUser) {
                this.hintService.checkForHintToDisplay('betsnapdetail-matches-specialmarkets');
            }

            this.googleAnalyticsService.trackEvent(
                'matchcard',
                'show',
                'special markets'
            );
        }
    }

    public openPlaceBetModal(market: MatchMarketHttpResponse, outcome: MatchMarketOutcomeHttpResponse) {

        if (!this.modalPlaceBet &&
            this.currentUser &&
            this.game.is_current_user_joined &&
            this.betPlacementAllowed &&
            (this.game.max_bet_count === 0 || (this.game.max_bet_count > 0 && this.game.current_user_bet_count < this.game.max_bet_count)) &&
            this.game.game_user.user_game_points > 0) {

            this.placeBetMarket = market;
            this.placeBetOutcome = outcome;

            const modalPlaceBetConfig = new TemplateModalConfig<null, string, string>(this.placeBetModalTemplate);
            modalPlaceBetConfig.size = ModalSize.Tiny;
            modalPlaceBetConfig.isClosable = false;

            this.modalPlaceBet = this.myModalService.openModal(modalPlaceBetConfig)
                .onApprove(() => {
                    this.modalPlaceBet = null;
                    this.placeBetMarket = null;
                    this.placeBetOutcome = null;
                })
                .onDeny(() => {
                    this.modalPlaceBet = null;
                    this.placeBetMarket = null;
                    this.placeBetOutcome = null;
                });
        }
    }

    public closePlaceBetModal() {
        this.placeBetMarket = null;
        this.placeBetOutcome = null;

        // Refresh the data to disable market outcomes by specifier. if it's prediction based snap
        if (this.game.points_engine === GamePointsEngineEnum.PREDICTION) {
            this.refreshData();
        }

        if (this.modalPlaceBet) {
            this.modalPlaceBet.deny();
            this.modalPlaceBet = null;
        }
    }

    protected handleOpenedPlaceBetDialog() {
        let placeBetMarketExists: MatchMarketHttpResponse;
        let placeBetOutcomeExists: MatchMarketOutcomeHttpResponse;
        if (this.modalPlaceBet && this.placeBetOutcome && this.placeBetMarket) {
            if (this.match.default_market && this.placeBetMarket.market_id === this.match.default_market.market_id) {
                placeBetMarketExists = this.checkIfPlaceBetMarketExists([this.match.default_market]);
            } else if (this.matchMarkets) {
                placeBetMarketExists = this.checkIfPlaceBetMarketExists(this.matchMarkets);
            }
            if (placeBetMarketExists) {
                placeBetOutcomeExists = this.checkIfPlaceBetOutcomeExists(placeBetMarketExists);
            }
        }
        if (placeBetMarketExists && placeBetOutcomeExists) {
            // Update Market and Outcome
            this.placeBetMarket = placeBetMarketExists;
            this.placeBetOutcome = placeBetOutcomeExists;
        }
    }

    private checkIfPlaceBetMarketExists(markets: MatchMarketHttpResponse[]): MatchMarketHttpResponse {
        return markets.find((market: MatchMarketHttpResponse) => (
                market.match_id === this.placeBetMarket.match_id &&
                market.market_id === this.placeBetMarket.market_id &&
                market.specifier_val === this.placeBetMarket.specifier_val
            )
        );
    }

    private checkIfPlaceBetOutcomeExists(market: MatchMarketHttpResponse): MatchMarketOutcomeHttpResponse {
        if (market && market.outcomes) {
            return market.outcomes.find(
                (marketOutcome: MatchMarketOutcomeHttpResponse) => (
                    marketOutcome.match_id === this.placeBetOutcome.match_id &&
                    marketOutcome.market_id === this.placeBetOutcome.market_id &&
                    marketOutcome.specifier_val === this.placeBetOutcome.specifier_val &&
                    marketOutcome.outcome_id === this.placeBetOutcome.outcome_id
                )
            );
        }
        return null;
    }

}
