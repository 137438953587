/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTournamentHttpResponse } from './appHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTournamentHttpResponse';
import { AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTeamHttpResponse } from './appHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTeamHttpResponse';
import { AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchMarketHttpResponse } from './appHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchMarketHttpResponse';


/**
 * SimpleSnapMatchHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchHttpResponse { 
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Season Scheduled Date
     */
    season_scheduled_date: Date;
    /**
     * Home Id
     */
    home_id?: number;
    /**
     * Home Name
     */
    home_name?: string;
    /**
     * Away Id
     */
    away_id?: number;
    /**
     * Away Name
     */
    away_name?: string;
    tournament?: AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTournamentHttpResponse;
    home?: AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTeamHttpResponse;
    away?: AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapTeamHttpResponse;
    /**
     * Markets
     */
    markets?: Array<AppHttpResponsesBetsnapsGamesSimpleSnapsSimpleSnapMatchMarketHttpResponse>;
}

