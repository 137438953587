/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ReportGameUserHttpResponse
 */
export interface AppHttpResponsesReportsReportGameUserHttpResponse { 
    /**
     * ID
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * User ID
     */
    user_id: number;
    /**
     * User B2B ID
     */
    user_b2b_user_id?: string;
    /**
     * Joined Game Date
     */
    joined_at: Date;
}

