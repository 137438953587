<div class="ui borderless segment" *ngIf="currentUser">
    <div class="ui one column middle aligned centered grid">

        <div class="three column middle aligned row padding-b-0">
            <div class="three wide column"></div>
            <div class="ten wide center aligned column text-large font-very-bold">
                {{currentUser.username}}
            </div>
            <div class="three wide right aligned column">
                <a routerLink="/profile/overview" class="ui circular icon basic button margin-0">
                    <i class="close icon"></i>
                </a>
            </div>
        </div>

        <div class="one column middle aligned row">
            <div class="column center aligned">
                <img class="ui middle aligned circular bordered secondary small image profileimg bg-white"
                     [src]="currentUser.media_url_small"/>
            </div>
        </div>

        <div class="one column row">
            <div class="column center aligned">
                <h2>{{'PROFILE.OVERVIEW.MENU.wallet_details' | translate}}</h2>
            </div>
        </div>

        <div *ngIf="showBalance" class="one column middle aligned row padding-0">
            <div class="column center aligned padding-t-0 padding-b-0 text small">
                {{'PROFILE.OVERVIEW.current_balance' | translate}}
            </div>
            <div class="column center aligned padding-t-0">
                <div class="ui currency label margin-l-0">
                    <span class="currency-wrap">
                        <span class="currency-symbol"
                              [class.longsymbol]="currentUser.wallets[0].currency.symbol.length > 1">
                            {{currentUser.wallets[0].currency.symbol}}
                        </span>
                    </span>
                    <span class="detail">
                        {{currentUser.wallets[0].balance | number:'1.2-2' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<h2 class="text-on-body-bg">
    {{'PROFILE.WALLET.TRANSACTIONS.heading' | translate}}
</h2>

<div class="ui vertical borderless very padded segment" *ngIf="processList">
    <div class="ui active centered inline text loader on-body-bg">{{'GENERAL.loadertext' | translate}}</div>
</div>

<div *ngIf="!processList">

    <div *ngFor="let transaction of transactionsList?.results" class="ui fluid card transaction-card">
        <div class="content top container font-bold">
            <div class="ui list horizontal">
                <div class="left aligned item">
                    <span class="color-positive huge" *ngIf="transaction.payment_type === 2">&#x25B2; </span>
                    <span class="color-negative huge" *ngIf="transaction.payment_type === 1">&#x25BC; </span>
                    ID {{transaction.id}}
                </div>
                <div class="right aligned item">
                    {{transaction.created_at | amLocal | amCalendar:
                    {
                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                        sameElse: tenantService.tenantData.internationalization.date_time_format
                    }
                    }}
                </div>
            </div>
        </div>
        <div class="center aligned content">
            <span class="color-grey" *ngIf="transaction.state === 1">
                {{'PROFILE.WALLET.TRANSACTIONS.completed' | translate}}
            </span>
            <h2 class="ui header margin-0" *ngIf="transaction.transaction_value">
                {{transaction.transaction_value | dbtranslation:'transaction_value':transaction.translations}}
                <span *ngIf="transaction.transaction_is_bonus_money === true">{{'GENERAL.LABELS.bet_bonus' | translate}}</span>
            </h2>
            <h3 class="ui header margin-0" *ngIf="transaction.transaction_value2">
                {{transaction.transaction_value2 | dbtranslation:'transaction_value2':transaction.translations}}
            </h3>
            <span class="color-grey" *ngIf="transaction.transaction_key">
                {{'PROFILE.WALLET.TRANSACTIONS.' + transaction.transaction_key | translate}}
                <span *ngIf="transaction.reward_code">
                    &nbsp;({{'PROFILE.WALLET.TRANSACTIONS.REWARDS.' + transaction.reward_code | translate}})
                </span>
            </span>
            <h3 class="ui header margin-0" *ngIf="transaction.game">
                <a (click)="navigateToTarget(transaction)">
                    <betsnaps-game-name [game]="transaction.game"></betsnaps-game-name>
                </a>
            </h3>
            <h3 class="ui header margin-0" *ngIf="transaction.ranking">
                <a (click)="navigateToTarget(transaction)">
                    {{transaction.ranking.description | dbtranslation:'description':transaction.ranking.translations}}
                </a>
            </h3>
        </div>
    </div>
    <div class="ui vertical borderless center aligned segment" *ngIf="transactionsList.total < 1">
        <p class="text-on-body-bg">
            {{'GENERAL.no_results_found' | translate}}
        </p>
    </div>
    <div class="ui basic segment center aligned"
         *ngIf="transactionsList?.results.length < transactionsList?.total"
         #loadMoreButton>
        <button type="button" class="ui button margin-0" [class.loading]="processLoadMore" (click)="loadMore()">
            {{'GENERAL.load-more_btn' | translate}}
        </button>
    </div>
</div>
