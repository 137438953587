<div class="game-list margin-t-0 margin-b-0" *ngIf="gameList">

    <div class="ui horizontal divider text-on-body-bg" *ngIf="showHeaderDivider">
        {{(showCount) ? gameList.total + ' ' : ''}}{{
            (isHistory) ? (
                (showCount) ? (gameList.total | i18nPlural:translations.labelTranslations.snaphistoryplural) : ('GENERAL.LABELS.snaphistoryplural.other' | translate)
            ) : (gameList.total | i18nPlural:translations.labelTranslations.snapplural)
        }}
    </div>

    <div class="ui grid">
        <betsnaps-game-card-loader *ngFor="let game of gameList.results; trackBy: gamesTrackBy; let gameIndexInList = index"
                            [gameIndexInList]="gameIndexInList"
                            [game]="game"
                            [currentUser]="currentUser"
                            [adPositions]="adPositions"
                            [isPartOfList]="true">
        </betsnaps-game-card-loader>
    </div>

    <div class="ui active centered inline text loader on-body-bg" *ngIf="!gameList">
        {{'GENERAL.loadertext' | translate}}
    </div>

    <div class="ui vertical borderless center aligned segment margin-t-40 margin-b-40" *ngIf="showNoResultsMessage && gameList && gameList.total < 1">
        <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
    </div>

</div>
