/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse';
import { AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse } from './appHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse';
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from './appHttpResponsesUsersPlayerPublicHttpResponse';


/**
 * TenantRankingPositionHttpResponse
 */
export interface AppHttpResponsesBetsnapsRankingsTenantRankingPositionHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Ranking Id
     */
    tenant_ranking_id: number;
    /**
     * Ranking-Period Rank
     */
    rank?: number;
    /**
     * User-Id
     */
    user_id: number;
    /**
     * Points
     */
    points: number;
    /**
     * Tie-Breaker
     */
    tie_break: number;
    /**
     * Prize Amount
     */
    prize_amount: number;
    /**
     * Tenant Prize Template Id
     */
    tenant_prize_template_id?: number;
    /**
     * Tenant Prize Id
     */
    tenant_prize_id?: number;
    tenant_prize_template?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizeTemplatePublicHttpResponse;
    tenant_prize?: AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse;
    user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
}

