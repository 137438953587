/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse } from './appHttpResponsesTenantsTenantLandingpageFeatureHttpResponse';
import { AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse } from './appHttpResponsesTenantsTenantLandingpageHighlightHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';
import { AppHttpResponsesGeneralGUIThemeHttpResponse } from './appHttpResponsesGeneralGUIThemeHttpResponse';


/**
 * TenantLandingpageHttpResponse
 */
export interface AppHttpResponsesTenantsTenantLandingpageHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Theme ID
     */
    theme_id?: number;
    theme?: AppHttpResponsesGeneralGUIThemeHttpResponse;
    /**
     * Title
     */
    title: string;
    /**
     * Description
     */
    description: string;
    /**
     * Show Highlights
     */
    show_highlights: boolean;
    /**
     * Show Features
     */
    show_features: boolean;
    /**
     * Show Widgets
     */
    show_widgets: boolean;
    /**
     * Highlights Title
     */
    highlights_title?: string;
    /**
     * Highlights Subtitle
     */
    highlights_subtitle?: string;
    /**
     * Title
     */
    widgets_title?: string;
    /**
     * Description
     */
    widgets_description?: string;
    /**
     * Active
     */
    active?: boolean;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Highlights
     */
    highlights?: Array<AppHttpResponsesTenantsTenantLandingpageHighlightHttpResponse>;
    /**
     * Features
     */
    features?: Array<AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse>;
}

