/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UserStatisticHttpResponse
 */
export interface AppHttpResponsesStatisticsUsersUserStatisticHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * User Id
     */
    user_id: number;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Last Game Participation
     */
    last_game_participation?: string;
    /**
     * Games Played
     */
    games_played?: number;
    /**
     * Non Cash Games Playedd
     */
    non_cash_games_played?: number;
    /**
     * Cash Games Played
     */
    cash_games_played?: number;
    /**
     * Percent of Cash Games Played
     */
    percent_of_cash_games_played?: number;
    /**
     * Tournament Games Played
     */
    tournament_games_played?: number;
    /**
     * H2 HPlayed
     */
    h2h_played?: number;
    /**
     * Pooled H2 HPlayed
     */
    pooled_h2h_played?: number;
    /**
     * Private Games Played
     */
    private_games_played?: number;
    /**
     * Games Won
     */
    games_won?: number;
    /**
     * Percent of games Won
     */
    percent_of_games_won?: number;
    /**
     * Games Finished Inside Prize Ranks
     */
    games_finished_inside_prize_ranks?: number;
    /**
     * Percent of games Finished Inside Prize Ranks
     */
    percent_of_games_finished_inside_prize_ranks?: number;
    /**
     * Gift Prizes Won
     */
    gift_prizes_won?: number;
    /**
     * Total Winnings
     */
    total_winnings?: number;
    /**
     * Total Winnings Withdrawable
     */
    total_winnings_withdrawable?: number;
    /**
     * Total Winnings Non Withdrawable
     */
    total_winnings_non_withdrawable?: number;
    /**
     * Largest Game Size Played
     */
    largest_game_size_played?: number;
    /**
     * Smallest Game Size Played
     */
    smallest_game_size_played?: number;
    /**
     * Average Game Size Played
     */
    average_game_size_played?: number;
    /**
     * Day At
     */
    day: string;
    /**
     * B2B user Id
     */
    b2b_user_id?: number;
    /**
     * username
     */
    username?: string;
    /**
     * Is Active
     */
    is_active?: number;
    /**
     * Account Creation
     */
    account_creation?: string;
    /**
     * Account Deletion
     */
    account_deletion?: string;
}

