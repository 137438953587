import {ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {TenantContentComponent} from '../../ui/components/tenant-content/tenant-content.component';
import {TenantService} from './tenant.service';
import {AppHttpResponsesTenantsTenantAdvertisementHttpResponse as AdvertisementHttpResponse} from '../../api';

@Injectable({
    providedIn: 'root'
})
export class AdService {

    private adPositionInterval: number = 5;
    private generateRandomPosition: boolean = true;
    private generatedPositionShouldBeUnique: boolean = true;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                protected tenantService: TenantService) {
    }

    public placeTenantAd(adType: string, adSlotPosition: number, viewContainerRef: ViewContainerRef, adPositions: number[], entryIndexInList: number, scriptIdText: string, componentClass: string = null): ComponentRef<TenantContentComponent> | null {
        let adComponentRef: ComponentRef<TenantContentComponent> = null;
        if (adPositions && adPositions.indexOf((entryIndexInList + 1)) > -1) {
            adComponentRef = this.createTenantAd(adType, adSlotPosition, viewContainerRef, componentClass, scriptIdText, entryIndexInList);
        }
        return adComponentRef;
    }

    public createTenantAd(adType: string, adSlotPosition: number, viewContainerRef: ViewContainerRef, componentClass: string = null, scriptIdText: string = null, appendIndex: number = null): ComponentRef<TenantContentComponent> | null {
        let adComponentRef: ComponentRef<TenantContentComponent> = null;
        const componentFactory: ComponentFactory<TenantContentComponent> = this.componentFactoryResolver.resolveComponentFactory(TenantContentComponent);
        adComponentRef = viewContainerRef.createComponent<TenantContentComponent>(componentFactory);
        adComponentRef.instance.adType = adType;
        adComponentRef.instance.adSlotPosition = adSlotPosition;

        if (scriptIdText) {
            adComponentRef.instance.adScriptId = scriptIdText + (appendIndex ? appendIndex : '');
        }
        if (componentClass) {
            adComponentRef.instance.componentClass = componentClass;
        }
        return adComponentRef;
    }

    public defineAdPosition(adType: string, entryCount: number, startPosition: number = 0, adPositions: number[] = null): Array<number> {
        if (adPositions === null) {
            adPositions = [];
        }

        const adTypeAdvertisements: AdvertisementHttpResponse[] = this.tenantService.tenantAdvertisements.filter((tenantAdvertisement: AdvertisementHttpResponse) => tenantAdvertisement.type === adType && tenantAdvertisement.position);

        let adPosition;

        if (adTypeAdvertisements.length) {
            adPositions = adTypeAdvertisements
                .map(({position}) => position)
                .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);
        } else {
            adPosition = 1 + startPosition;

            // Push the position if it's not already set.
            if (!adPositions.filter((position: number) => position === adPosition).length) {
                adPositions.push(adPosition);
            }
        }

        // If we do not have random ads, return the fix positions.
        if (!this.tenantService.tenantAdvertisements.filter((ad: AdvertisementHttpResponse) => ad.position === null).length) {
            return adPositions;
        }

        if (entryCount >= this.adPositionInterval) {
            let minValue = this.adPositionInterval + startPosition;

            if (this.generateRandomPosition) {
                while (minValue < entryCount) {
                    adPosition = this.randomIntFromInterval(minValue, (minValue + this.adPositionInterval > entryCount ? entryCount : minValue + this.adPositionInterval));
                    if (this.generatedPositionShouldBeUnique) {
                        while (!!adPositions.find((position: number) => position === adPosition)) {
                            adPosition = this.randomIntFromInterval(minValue, (minValue + this.adPositionInterval > entryCount ? entryCount : minValue + this.adPositionInterval));
                        }
                    }
                    adPositions.push(adPosition);
                    minValue = minValue + this.adPositionInterval;
                }
            }
        }
        return adPositions;
    }

    private randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
}
