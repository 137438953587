<div class="betsnapdetail-v3-background" [ngStyle]="{'background-image': backgroundImage}"></div>
<div class="betsnapdetail-v3-wrap" #betsnapDetailWrap>
    <div class="ui vertical borderless very padded segment" *ngIf="processData">
        <div class="ui active centered inline text loader color-white text-shadow">
            {{'GENERAL.loadertext' | translate}}
        </div>
    </div>

    <div id="srWidgetScriptPlaceholder"></div>

    <div #betsnapDetailHeader *ngIf="game && !processData" class="betsnapdetail-v3-header">
        <div class="betsnapdetail-v3-header-bg visible-on-scroll"></div>
        <div class="ui centered relaxed bottom aligned grid container margin-t-0 margin-b-0">
            <div class="row padding-0">
                <div class="four wide right aligned column tablet or lower hidden"></div>
                <div class="sixteen wide mobile eight wide computer left aligned column">
                    <div class="betsnapdetail-v3-header-inner">
                        <a href="#" class="close-link"
                            (click)="$event.preventDefault(); navigateBack();">
                            <i class="close huge icon"></i>
                        </a>

                        <p class="center aligned hide-on-scroll">
                            {{(game.is_public) ? ('GENERAL.LABELS.public' | translate) : ('GENERAL.LABELS.private' | translate)}} {{(betsnapdetailService.isTournament) ? ('GENERAL.LABELS.tournament' | translate) : ('GENERAL.LABELS.h2h' | translate)}} - {{game.sport_translations[0].label_value | dbtranslation:'sports_desc':game.sport_translations}}
                        </p>
                        <h2 class="margin-0 text-huge center aligned game-name"
                            [class.truncate]="headerSize === 'small'">
                            <betsnaps-game-name [game]="game"></betsnaps-game-name>
                        </h2>
                        <div class="center aligned hide-on-scroll">
                            <span class="ui game-state-label label margin-0 margin-t-5"
                                  [class.black]="game.game_state !== 3"
                                  [class.red]="game.game_state === 3">
                                <span *ngIf="(!game.is_current_user_joined || (game.is_current_user_joined && (secondsToStart > 3600 || secondsToStart <= 0))) && game.game_state < 3">
                                    <i class="ui schedule icon font-very-bold"></i> {{game.start_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }}
                                </span>
                                <span *ngIf="game.is_current_user_joined && game.game_state < 3 && secondsToStart > 0 && secondsToStart <= 3600">
                                    <betsnaps-countdown #gameStartCountdown
                                                        [timeInSeconds]="secondsToStart"
                                                        [autoStart]="true"
                                                        [secondsForHighlight]="secondsToStart"
                                                        [hideAfterFinish]="true"
                                                        (onCountdownFinished)="game.game_state = 3"></betsnaps-countdown>
                                    <span *ngIf="gameStartCountdown?.hasFinished()">
                                        <i class="ui lens tiny icon"></i> {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                                    </span>
                                </span>
                                <span *ngIf="game.game_state === 3">
                                    <i class="ui lens tiny icon"></i> {{'GENERAL.GAMES.STATI.running' | translate | uppercase}}
                                </span>
                                <span *ngIf="game.game_state === 4 || game.game_state === 5 || game.game_state === 6">
                                      {{'GENERAL.GAMES.STATI.finished' | translate | uppercase}}
                                    {{game.end_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }}
                                </span>
                                <span *ngIf="game.game_state === 7">
                                    {{'GENERAL.GAMES.STATI.closed' | translate | uppercase}}
                                    {{game.end_date | amLocal | amCalendar:
                                    {
                                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                        sameElse: tenantService.tenantData.internationalization.date_time_format
                                    }
                                    }}
                                </span>
                                <span *ngIf="game.game_state === 99">
                                    {{'GENERAL.GAMES.STATI.cancelled' | translate | uppercase}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="game.is_current_user_joined && game?.game_user && game.game_state !== 99">
                        <div class="game-user-bar hide-on-scroll">
                            <div class="game-user-bar-inner">
                                <div class="ui middle aligned centered tight grid margin-t-0 margin-b-0">
                                    <div class="row padding-0">
                                        <div class="four wide column center aligned padding-0" *ngIf="game.game_state > 2">
                                            <ng-container *ngIf="game.competition_type !== 5">
                                                <span class="truncate text-mini">
                                                    {{'GENERAL.GAMES.rank' | translate | uppercase}}
                                                </span>
                                                <strong class="font-very-bold">
                                                    <span *ngIf="getGameUser().rank > 0">{{getGameUser().rank}}<span class="text-mini">{{getGameUser().rank | ordinalNumber:true}}</span></span>
                                                    <span *ngIf="getGameUser().rank < 1">-</span>
                                                </strong>
                                            </ng-container>
                                            <ng-container *ngIf="game.competition_type === 5">
                                                <span class="truncate text-mini">
                                                    {{'BETSNAPDETAIL.RANKING.win_or_loose' | translate | uppercase}}
                                                </span>
                                                <i *ngIf="game.game_user.lost_bets_count === 0 && (game.game_user.is_participation_valid === true || game.game_user.is_participation_valid === null)" class="icon bsi bsi-checkmark font-very-bold small green margin-0"></i>
                                                <i *ngIf="game.game_user.lost_bets_count > 0 || game.game_user.is_participation_valid === false" class="fa fa-times icon big red margin-0"></i>
                                            </ng-container>
                                        </div>
                                        <div class="four wide column center aligned padding-0" *ngIf="game.competition_type !== 5 && game.game_user.is_participation_valid === false">
                                            <button type="button"
                                                    class="ui circular icon basic inverted mini button margin-0"
                                                    fuiPopup
                                                    [popupTemplate]="popupNoValidBetInfo"
                                                    popupTrigger="outsideClick"
                                                    popupPlacement="bottom">
                                                <i class="fa fa-info small icon"></i>
                                            </button>
                                            <ng-template let-popup #popupNoValidBetInfo>
                                                <div class="content no-valid-bet-info-popup">
                                                    {{isBettingAllowed() ? ('GENERAL.GAMES.no_valid_bet_placed_yet' | translate) : ('GENERAL.GAMES.no_valid_bet' | translate)}}
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="four wide column center aligned padding-0" *ngIf="game.competition_type === 5 && game.current_user_bet_count < game.simple_markets_count">
                                            <button type="button"
                                                    class="ui circular icon basic inverted mini button margin-0"
                                                    fuiPopup
                                                    [popupTemplate]="popupNoValidBetInfo"
                                                    popupTrigger="outsideClick"
                                                    popupPlacement="bottom">
                                                <i class="fa fa-info small icon"></i>
                                            </button>
                                            <ng-template let-popup #popupNoValidBetInfo>
                                                <div class="content no-valid-bet-info-popup">
                                                    {{isBettingAllowed() ? ('GENERAL.GAMES.not_all_bets_placed_yet' | translate) : ('GENERAL.GAMES.not_all_bets_placed' | translate)}}
                                                </div>
                                            </ng-template>
                                        </div>
                                        <ng-container *ngIf="game.competition_type !== 5">
                                            <div class="four wide column center aligned"
                                                 *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">
                                                <span class="truncate text-mini">
                                                    {{'GENERAL.GAMES.POINTS.free_points' | translate | uppercase}}
                                                </span>
                                                <strong class="font-very-bold">
                                                    {{game.game_user.user_game_points | number:'1.2-2'}}
                                                </strong>
                                            </div>
                                            <div class="four wide column center aligned"
                                                 *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION || (game.points_engine === gamePointsEngineEnum.PREDICTION && game.game_state >= 3)">
                                                <div class="truncate text-mini">
                                                    {{'GENERAL.GAMES.POINTS.total_points' | translate | uppercase}}
                                                </div>
                                                <strong class="font-very-bold">
                                                    {{game.game_user.total_score | number:'1.2-2'}}
                                                </strong>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="game.competition_type === 5">
                                            <div class="four wide column center aligned">
                                                <span class="truncate text-mini">
                                                    {{'GENERAL.GAMES.won_bets' | translate | uppercase}}
                                                </span>
                                                <strong class="font-very-bold">
                                                    {{game.simple_markets_count - game.game_user.lost_bets_count}}
                                                </strong>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="game.competition_type !== 5">
                                            <div class="four wide column center aligned"
                                                 *ngIf="game.game_state > 2 && game.game_user.rank === 1 && game.game_user.points_in_lead !== null && game.game_user.is_participation_valid !== false">
                                                <div class="truncate text-mini">
                                                    {{'GENERAL.GAMES.POINTS.points_in_lead' | translate | uppercase}}
                                                </div>
                                                <strong class="font-very-bold">
                                                    {{game.game_user.points_in_lead | number:'1.2-2'}}
                                                </strong>
                                            </div>
                                            <div class="four wide column center aligned"
                                                 *ngIf="game.game_state > 2 && (game.game_user.rank > 1 || (game.game_user.rank === 1 && game.game_user.points_in_lead === null)) && game.game_user.is_participation_valid !== false">
                                                <div class="truncate text-mini">
                                                    {{'GENERAL.GAMES.POINTS.points_to_first' | translate | uppercase}}
                                                </div>
                                                <strong class="font-very-bold">
                                                    {{game.game_user.points_to_first | number:'1.2-2'}}
                                                </strong>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="game.competition_type === 5">
                                            <div class="four wide column center aligned">
                                                <span class="truncate text-mini">
                                                    {{'GENERAL.GAMES.lost_bets' | translate | uppercase}}
                                                </span>
                                                <strong class="font-very-bold">
                                                    {{game.game_user.lost_bets_count}}
                                                </strong>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="center aligned visible-on-scroll margin-0">
                            <span *ngIf="game.points_engine !== gamePointsEngineEnum.PREDICTION">{{game.game_user.user_game_points | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.free_points' | translate}}</span>
                            <span *ngIf="game.game_state > 2 && game.game_user.rank === 1 && game.game_user.points_in_lead !== null && game.game_user.is_participation_valid !== false">
                                &nbsp;-&nbsp;{{game.game_user.points_in_lead | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_in_lead' | translate}}
                            </span>
                            <span *ngIf="game.game_state > 2 && (game.game_user.rank > 1 || (game.game_user.rank === 1 && game.game_user.points_in_lead === null)) && game.game_user.is_participation_valid !== false">
                                &nbsp;-&nbsp;{{game.game_user.points_to_first | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_to_first' | translate}}
                            </span>
                        </p>
                    </div>
                    <div class="ui fluid flexauto item tiny borderless inverted menu">
                        <a [routerLink]="['./details']" [routerLinkActive]="['active']"
                           class="item">
                            {{'BETSNAPDETAIL.MENU.details' | translate | uppercase}}
                        </a>
                        <a *ngIf="game.sport_id !== 999"
                           [routerLink]="['./matches']" [routerLinkActive]="['active']"
                           class="item">
                            {{'BETSNAPDETAIL.MENU.matches' | translate | uppercase}}
                        </a>
                        <a *ngIf="game.sport_id !== 999 && (game.is_current_user_joined || (betsnapdetailService.widgetBets$ | async))"
                           [routerLink]="['./bets']" [routerLinkActive]="['active']"
                           class="item">
                            {{'BETSNAPDETAIL.MENU.bets' | translate | uppercase}}
                            <betsnaps-notification-bubble [value]="game.current_user_open_bet_count" [additionalClass]="'calltoaction floating margin-l-0'"></betsnaps-notification-bubble>
                        </a>
                        <a [routerLink]="['./games']" [routerLinkActive]="['active']"
                           class="item" *ngIf="game.sport_id === 999">
                            {{'BETSNAPDETAIL.MENU.vendor-games' | translate | uppercase}}
                        </a>
                        <a *ngIf="game.sport_id === 999 && (game.is_current_user_joined || (betsnapdetailService.widgetBets$ | async))"
                           [routerLink]="['./plays']" [routerLinkActive]="['active']"
                           class="item">
                            {{'BETSNAPDETAIL.MENU.vendor-game-participations' | translate | uppercase}}
                        </a>
                        <a *ngIf="isRankingAccessible()"
                           [routerLink]="['./ranking']" [routerLinkActive]="['active']"
                           class="item">
                            {{'BETSNAPDETAIL.MENU.ranking' | translate | uppercase}}
                        </a>
                    </div>
                </div>
                <div class="four wide center aligned column tablet or lower hidden">
            </div>
            </div>
        </div>
    </div>
    <div class="ui centered relaxed grid container margin-t-0  margin-b-0">
        <div class="row padding-0">
            <div class="four wide tablet or lower hidden column sidebar left-sidebar" #leftSidebar>
                <div [class.sticky-container]="sidebarSticky" #leftSidebarSticky>
                    <div *ngIf="game && !processData">
                        <betsnaps-chat-accordion
                            [chatId]="game.chat_id"
                            *ngIf="windowWidth > 991 && game.chat_id > 0 && game.is_current_user_joined && game.chat_enabled">
                        </betsnaps-chat-accordion>
                    </div>
                </div>
            </div>
            <div class="sixteen wide mobile ten wide tablet eight wide computer column">
                <div *ngIf="game && !processData" #betsnapDetailContent>
                    <router-outlet></router-outlet>
                    <div class="game-detail-buttons-wrap" *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state < 3 && game.size !== game.users_count">
                        <div class="ui grid">
                            <div class="two wide mobile three wide tablet six wide computer column"></div>
                            <div class="twelve wide mobile five wide tablet four wide computer column center aligned">

                                <button *ngIf="!battleRoyalMissingMarkets"
                                        type="button" class="ui fluid button"
                                        (click)="approveJoin(); googleAnalyticsService.trackEvent('game - details', 'click', 'join game')"
                                        [class.loading]="processJoin && !game.confirm_join_process"
                                        [class.padding-t-10]="!showPrizes"
                                        [class.padding-b-10]="!showPrizes">
                        <span class="nowrap text-large">
                            {{'GENERAL.GAMES.join_btn' | translate}}
                        </span>
                                    <span *ngIf="showPrizes && (game.entry_fee !== 0)" class="text-small font-normal display-block">
                            {{'GENERAL.GAMES.entry_fee' | translate}}: {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                        </span>
                                    <span *ngIf="showPrizes && (game.entry_fee === 0)" class="text-small font-normal display-block">
                            {{'GENERAL.GAMES.entry_fee_free' | translate}}
                        </span>
                                </button>
                                <ng-container *ngIf="battleRoyalMissingMarkets">
                                    <button type="button"
                                            class="ui fluid basic button margin-0"
                                            fuiPopup
                                            (click)="$event.stopPropagation()"
                                            [popupTemplate]="popupMissingMarketsInfo"
                                            popupTrigger="outsideClick"
                                            popupPlacement="top">
                                        {{'GENERAL.GAMES.CARD-BUTTON.battle_royal_missing_markets' | translate}}
                                    </button>
                                    <ng-template let-popup #popupMissingMarketsInfo>
                                        <div class="content">
                                            {{'GENERAL.GAMES.CARD-BUTTON.battle_royal_missing_markets_popup_text' | translate}}
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </div>
                        </div>
                        <div class="two wide mobile eight wide tablet six wide computer column"></div>
                    </div>
                </div>
            </div>
            <div class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar" #rightSidebar>
                <div [class.sticky-container]="sidebarSticky" #rightSidebarSticky>
                    <div *ngIf="game && !processData">
                        <betsnaps-chat-accordion
                            [chatId]="game.chat_id"
                            *ngIf="windowWidth > 767 && windowWidth < 992 && game.chat_id > 0 && game.is_current_user_joined && game.chat_enabled">
                        </betsnaps-chat-accordion>

                        <betsnaps-sidebar-tenant-content [adTypeTop]="'game-sidebar-right'" [adTypeBottom]="'game-sidebar-right-2'"></betsnaps-sidebar-tenant-content>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <betsnaps-chat-bottom-fixed
        [chatId]="game.chat_id"
        [betsnapDetailView]="true"
        *ngIf="windowWidth < 768 && game && !processData && game?.chat_id > 0 && game?.is_current_user_joined && game?.chat_enabled">
    </betsnaps-chat-bottom-fixed>
</div>

<ng-template let-context let-modal="modal" #modalJoinedTemplate>
    <div class="content inverted primary">
        <div class="ui centered grid padded display-block">
            <div class="three column row middle aligned">
                <div class="four wide column middle aligned"></div>
                <div class="eight wide column middle aligned padding-20"><img class="ui middle aligned image" [src]="joinModalImage"/></div>
                <div class="four wide column middle aligned"></div>
            </div>
            <div class="font-bold huge margin-b-0">{{'GENERAL.GAMES.join-modal_heading' | translate}}</div>
            <div class="font-bold large">
                {{'GENERAL.GAMES.join-modal_firsttextpart' | translate}} <span
                class="font-bold huge">{{'GENERAL.GAMES.join-modal_secondtextpart' | translate}}</span>
            </div>
            <button type="button" class="ui primary button large margin-20 padding-l-30 padding-r-30"
                    (click)="googleAnalyticsService.trackEvent('game - details - joinModal', 'click', 'place bets now'); modalRedirect(((game.sport_id === 999) ? 'games' : 'matches'), modalJoined);">
                <span class="nowrap">{{'GENERAL.GAMES.place_bets_now_btn' | translate}}</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template let-context let-modal="modal" #modalJoinApproveTemplate>
    <div class="content inverted primary">
        <div class="ui centered grid padded display-block">
            <div class="font-bold huge">{{'GENERAL.GAMES.confirm-join-modal-heading' | translate}}</div>
            <div class="font-bold large">
                {{'GENERAL.GAMES.confirm-join-modal-text' | translate}}
            </div>
            <button type="button" class="ui primary button large margin-20 padding-l-20 padding-r-20"
                    (click)="joinGame(); googleAnalyticsService.trackEvent('game - details', 'click', 'approve join');"
                    [class.loading]="processJoin">
                <span class="nowrap padding-l-10 padding-r-10">{{'GENERAL.GAMES.confirm_join_btn' | translate}}</span>
                <span *ngIf="showPrizes && (game.entry_fee !== 0)" class="margin-t-5 text-small font-normal display-block">
                    {{'GENERAL.LABELS.for' | translate}}: {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                </span>
                <span *ngIf="showPrizes && (game.entry_fee === 0)" class="margin-t-5 text-small font-normal display-block">
                    {{'GENERAL.GAMES.entry_fee_free' | translate}}
                </span>
            </button>
        </div>
    </div>
</ng-template>
