<div class="twelve wide mobile six wide tablet five wide computer column">
    <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
            (click)="myModalService.openLoginRegisterModal('login')"
            class="ui fluid large shadowed button margin-0 padding-r-0 padding-l-0">
        {{'HOME.login_btn' | translate | uppercase}}
    </button>
    <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteLoginUrl()" (click)="goToB2bLogin()"
            class="ui fluid large shadowed button margin-0 padding-r-0 padding-l-0">
        {{'HOME.login_btn' | translate | uppercase}}
    </button>
</div>
<div class="twelve wide mobile six wide tablet five wide computer column margin-t-15 center aligned register-link color-white"
     *ngIf="showRegisterButton && (!tenantService.noB2cSignupMethod || (tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteRegisterUrl()))">
    {{'LOGIN.no_account_label' | translate}}
    <a *ngIf="!tenantService.noB2cSignupMethod"
       class="text color-white font-bold padding-l-5 nowrap"
       (click)="myModalService.openLoginRegisterModal('register')">
        {{'GENERAL.LABELS.register_btn' | translate}}
    </a>
    <a *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteRegisterUrl()"
       class="text color-white font-bold padding-l-5 nowrap"
       (click)="goToB2bRegister()">
        {{'GENERAL.LABELS.register_btn' | translate}}
    </a>
</div>
