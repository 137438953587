/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UserSocialSignupRequest
 */
export interface AppHttpRequestsUsersUserSocialSignupRequest { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Network (facebook, twitter, google)
     */
    network: string;
    /**
     * Access Token
     */
    access_token: string;
    /**
     * Access Token Secret
     */
    access_token_secret?: string;
    /**
     * Currency ID
     */
    currency_id?: number;
    /**
     * Language ID
     */
    language_id?: number;
    /**
     * Signup Reason
     */
    signup_reason?: string;
    /**
     * Invite Token
     */
    invite_token?: string;
    /**
     * Tenant Inquiries JSON
     */
    user_inquiry_answers?: string;
}

