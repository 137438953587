import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, UrlSegment } from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {TenantService} from '../services/tenant.service';
import {
    AppHttpResponsesTenantsTenantInfoHttpResponse as TenantInfoHttpResponse,
    AppHttpResponsesTenantsTenantPublicHttpResponse as TenantPublicHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../api';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private authenticationService: AuthenticationService,
        private tenantService: TenantService) {
    }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, routerSnapshot: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.tenantService.getTenantInfo().pipe(mergeMap((tenantInfo: TenantInfoHttpResponse) => {
            if (!this.authenticationService.validToken()) {
                this.saveUrlToLocalStorageAndLocalLogout(routerSnapshot);
                return of(false);
            } else {
                return this.authenticationService.getCurrentUser().pipe(
                    map((currentUser: PlayerHttpResponse) => {
                        if (currentUser) {
                            return true;
                        }
                        this.saveUrlToLocalStorageAndLocalLogout(routerSnapshot);
                        return false;
                    }), catchError(() => {
                        this.saveUrlToLocalStorageAndLocalLogout(routerSnapshot);
                        return of(false);
                    })
                );
            }
        }), catchError(() => {
            // if api not reachable and tenantinfo is tried to load from fallback json
            if (!this.authenticationService.validToken()) {
                return of(false);
            } else {
                return of(true);
            }
        }));
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }

    private saveUrlToLocalStorageAndLocalLogout(routerSnapshot: RouterStateSnapshot) {
        const url = encodeURI(routerSnapshot.url);
        localStorage.setItem('loginRedirect', url);
        this.authenticationService.localLogout();
    }

}
