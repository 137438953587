import { OnDestroy, OnInit, Directive } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';

import {
    AppHttpResponsesMarketingMarketingPartnerHttpResponse as MarketingPartnerHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerListHttpResponse as MarketingPartnerListHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerUserPermissionHttpResponse as MarketingPartnerUserPermissionHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerUserPermissionListHttpResponse as MarketingPartnerUserPermissionListHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../api';
import {
    AuthenticationService,
    ErrorService,
    MyNotificationsService,
    ProfileService,
    TenantService
} from '../../../../shared';
import {take, takeWhile} from 'rxjs/operators';

@Directive()
export abstract class AProfilePrivacyComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    public currentUser: PlayerHttpResponse;
    public marketingPartnersList: MarketingPartnerListHttpResponse;

    protected marketingPartnerUserPermissionArray = [];

    public showSpinner: boolean = true;
    public submitted: boolean = false;

    public privacyForm: UntypedFormGroup;
    protected formControls = [];

    constructor(protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected profileService: ProfileService,
                protected formBuilder: UntypedFormBuilder,
                protected myNotificationsService: MyNotificationsService,
                protected errorService: ErrorService) {
    }

    ngOnInit() {
        this.currentUser = this.authenticationService.currentUser;
        this.profileService.getUsersMarketingPartnerPermissions(this.currentUser.id)
            .pipe(take(1))
            .subscribe({
                next: (marketingPartnerUserPermissionListHttpResponse: MarketingPartnerUserPermissionListHttpResponse) => {
                    for (const userPermission of marketingPartnerUserPermissionListHttpResponse.results) {
                        this.marketingPartnerUserPermissionArray[userPermission.marketing_partner_id] = userPermission.is_permitted;
                    }
                    this.profileService.marketingPartnerList$
                        .pipe(takeWhile(() => this.componentAlive))
                        .subscribe(
                            (marketingPartnerList: MarketingPartnerListHttpResponse) => {
                                if (marketingPartnerList) {
                                    this.marketingPartnersList = marketingPartnerList;

                                    // create the form fields
                                    for (const marketingPartner of this.marketingPartnersList.results) {
                                        this.formControls['data-' + marketingPartner.id] = new UntypedFormControl(this.marketingPartnerUserPermissionArray[marketingPartner.id]);
                                    }
                                    this.privacyForm = this.formBuilder.group(this.formControls);

                                    this.profileService.marketingPartnerPermissionsUpdate$
                                        .pipe(takeWhile(() => this.componentAlive))
                                        .subscribe(
                                            (marketingPartnerUserPermissionHttpResponse: MarketingPartnerUserPermissionHttpResponse) => {
                                                if (marketingPartnerUserPermissionHttpResponse) {
                                                    this.privacyForm.controls['data-' + marketingPartnerUserPermissionHttpResponse.marketing_partner_id].patchValue(marketingPartnerUserPermissionHttpResponse.is_permitted);
                                                    this.marketingPartnerUserPermissionArray[marketingPartnerUserPermissionHttpResponse.marketing_partner_id] = marketingPartnerUserPermissionHttpResponse.is_permitted;
                                                    this.profileService.marketingPartnerPermissionsUpdateProcessed();
                                                }
                                            }
                                        );

                                    this.showSpinner = false;
                                }
                            }
                        );
                },
                error: (err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    setUsersMarketingPartnerPermissions(partnerId: number): void {
        const is_permitted: boolean = (this.marketingPartnerUserPermissionArray[partnerId] === undefined) ? true : !(this.marketingPartnerUserPermissionArray[partnerId]);
        this.profileService.setUsersMarketingPartnerPermissions(this.currentUser.id, partnerId, is_permitted);
    }

    showMarketingNotification(marketingPartner: MarketingPartnerHttpResponse): void {
        this.myNotificationsService.createUserMarketingNotificationModal(marketingPartner);
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

}
