export enum UserBetStateEnum {
    FAILED = -3,
    CASHOUT_PENDING = -2,
    PENDING = -1,
    ACTIVE = 1,
    SETTLED = 2,
    CANCELLED = 3,
    CASHED_OUT = 4,
    ROLLED_BACK_BET_SETTLEMENT = 5,
    ROLLED_BACK_BET_CANCELLATION = 6,
    AUTO_CASHED_OUT = 7
}
