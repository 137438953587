import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {
    AuthenticationService,
    BetsnapdetailService,
    DebugService,
    ErrorService, FriendsService,
    GoogleAnalyticsService,
    HintService, LoggerService,
    MyModalService,
    MyTranslateService,
    TenantService, WindowRef
} from '../../../../../shared';
import {ABetsnapdetailRankingComponent} from '../a-betsnapdetail-ranking.component';
import {take} from 'rxjs/operators';
import {
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse as LeaderboardUserListHttpResponse
} from '../../../../../api';
import {ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';

@Component({
    selector: 'betsnaps-betsnapdetail-ranking-default',
    templateUrl: './betsnapdetail-ranking-default.component.html',
    styles: [],
})
export class BetsnapdetailRankingDefaultComponent extends ABetsnapdetailRankingComponent {

    @ViewChild('topRankedLeaderBoardModalTemplate') public topRankedLeaderBoardModalTemplate: ModalTemplate<null, string, string>;

    public topRankedLeaderBoardUsers: LeaderboardUserHttpResponse[];
    public topRankedLeaderBoardOpenTimestamp: Date;
    public processOpenTopRankedLeaderboard: boolean = false;

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                public tenantService: TenantService,
                protected router: Router,
                public translations: MyTranslateService,
                protected friendsService: FriendsService,
                protected myModalService: MyModalService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected debugService: DebugService,
                protected errorService: ErrorService,
                protected loggerService: LoggerService,
                protected windowRef: WindowRef) {
        super(
            authenticationService,
            betsnapdetailService,
            tenantService,
            router,
            translations,
            friendsService,
            myModalService,
            hintService,
            googleAnalyticsService,
            debugService,
            errorService,
            loggerService,
            windowRef
        );
    }

    protected getRankingMenuFilter() {
        this.rankingMenu = [
            {
                'key': 'all',
                'translatekey': 'BETSNAPDETAIL.RANKING.MENU.all',
                'active': true
            }, {
                'key': 'friends',
                'translatekey': 'BETSNAPDETAIL.RANKING.MENU.friends',
                'active': false
            }
        ];
    }

    showTopRankedLeaderboard() {
        if (this.showCompactLeaderboardTransition) {
            this.loadPaginatedLeaderboardDueToTransition();
        } else {

            this.processOpenTopRankedLeaderboard = true;
            this.topRankedLeaderBoardOpenTimestamp = new Date();
            this.topRankedLeaderBoardUsers = null;

            this.betsnapdetailService.loadTopRankedLeaderboardUsers(this.game)
                .pipe(take(1)).subscribe(
                (leaderboardUserListHttpResponse: LeaderboardUserListHttpResponse) => {
                    if (leaderboardUserListHttpResponse) {
                        this.betsnapdetailService.storePublicPlayersFromLeaderboardUserslist(leaderboardUserListHttpResponse);
                        this.topRankedLeaderBoardUsers = leaderboardUserListHttpResponse.results;
                        this.openTopWinnerLeaderboardModal();
                    }
                });

        }
    }

    protected openTopWinnerLeaderboardModal() {
        const topRankedLeaderBoardModalConfig = new TemplateModalConfig<null, string, string>(this.topRankedLeaderBoardModalTemplate);
        topRankedLeaderBoardModalConfig.size = 'tiny';
        topRankedLeaderBoardModalConfig.mustScroll = true;
        this.myModalService.openModal(
            topRankedLeaderBoardModalConfig
        );

        this.processOpenTopRankedLeaderboard = false;
    }
}
