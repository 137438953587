import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import * as postscribe from 'betsnap-krux-postscribe';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {TenantService} from '../../../shared/services/tenant.service';
import {WindowRef} from '../../../shared/services/window.service';
import {clearNotReplacedPlaceholder, replacePlaceholder} from '../../../shared/helpers/string-helpers';
import {
    AppHttpResponsesMediaMediaPublicHttpResponse as MediaPublicHttpResponse,
    AppHttpResponsesTenantsTenantAdvertisementHttpResponse as AdvertisementHttpResponse,
    AppHttpResponsesTenantsTenantAdvertisementHttpResponse as TenantAdvertisementHttpResponse,
    AppHttpResponsesTranslationsMediaTranslationHttpResponse as MediaTranslationHttpResponse
} from '../../../api';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'betsnaps-tenant-content',
    templateUrl: './tenant-content.component.html',
    styles: []
})
export class TenantContentComponent implements OnInit, AfterViewInit, OnDestroy {

    @HostBinding('class') @Input() componentClass: string = '';

    private componentAlive = true;

    @Input() adType: string;
    @Input() adSlotPosition: number;
    @Input() adScriptId: string;
    @Input() adImageClass: string;
    @ViewChild('adScriptPlaceholder') adScriptPlaceholder: ElementRef;

    public adToDisplay: AdvertisementHttpResponse;
    public showAd = false;
    public isMediaAvailable = true;
    public isScriptAd: boolean = false;
    public imageLinkTranslationName: string = 'image_link';

    private preLoadedImageIds = [];

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    constructor(@Inject(DOCUMENT) private document: any,
                private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    ngOnInit() {
        const currentUser = this.authenticationService.currentUser;

        this.adImageClass = (this.adImageClass ? this.adImageClass + ' ' + this.tenantService.componentTemplateToLoad : this.tenantService.componentTemplateToLoad);

        let adsToDisplay = this.tenantService.tenantAdvertisements.filter((ad: AdvertisementHttpResponse) => ad.type === this.adType);
        if (adsToDisplay.length > 0) {
            const slotPositionAds = adsToDisplay.filter((ad: AdvertisementHttpResponse) => ad.position === this.adSlotPosition);

            // Determine which banners to be given to choose from: random or fixed positions ad banner
            if (this.adSlotPosition && slotPositionAds.length) {
                if (slotPositionAds.length) {
                    adsToDisplay = slotPositionAds;
                }
            } else {
                adsToDisplay = adsToDisplay.filter((ad: AdvertisementHttpResponse) => ad.position === null);
            }

            const randomIndex = Math.floor(Math.random() * adsToDisplay.length);
            this.adToDisplay = adsToDisplay[randomIndex];

            if (this.adToDisplay.adserver_embed_code) {
                this.isScriptAd = true;
                this.showAd = true;
            } else if (this.adToDisplay.media) {
                // check for android distribution channel values
                if (this.tenantService.isWrapperAndroid) {
                    this.adToDisplay.open_link_in_new_tab = this.adToDisplay.android_open_link_in_new_tab;
                    if (this.adToDisplay.android_image_link) {
                        this.adToDisplay.image_link = this.adToDisplay.android_image_link;
                        this.imageLinkTranslationName = 'android_image_link';
                    }
                }
                // check for iOS distribution channel values
                if (this.tenantService.isWrapperIos) {
                    this.adToDisplay.open_link_in_new_tab = this.adToDisplay.ios_open_link_in_new_tab;
                    if (this.adToDisplay.ios_image_link) {
                        this.adToDisplay.image_link = this.adToDisplay.ios_image_link;
                        this.imageLinkTranslationName = 'ios_image_link';
                    }
                }
                if (this.adToDisplay.image_link) {
                    if (this.authenticationService.isAuthenticated && currentUser) {
                        this.adToDisplay.image_link = replacePlaceholder(currentUser, this.adToDisplay.image_link);
                    } else {
                        this.adToDisplay.image_link = clearNotReplacedPlaceholder(this.adToDisplay.image_link);
                    }
                }
                this.showAd = true;
            } else if (this.adToDisplay.text) {
                this.isMediaAvailable = false;
                this.showAd = true;
            }
        }
        if (!this.showAd) {
            this.componentClass += ' padding-0';
        }
    }

    ngAfterViewInit() {
        if (this.isScriptAd && this.adToDisplay.adserver_embed_code) {
            if (this.document.getElementById('adScriptPlaceholder-' + this.adScriptId) !== null) {
                this.document.getElementById('adScriptPlaceholder-' + this.adScriptId).innerHTML = '';
            }
            postscribe('#adScriptPlaceholder-' + this.adScriptId, this.adToDisplay.adserver_embed_code, {
                shouldEscapeQuotes: false
            });
        }
    }

    ngOnDestroy(): void {
        this.componentAlive = false;
    }

    public getAdvertisementMediaTranslationForLanguage(advertisement: TenantAdvertisementHttpResponse): MediaPublicHttpResponse {
        let media = advertisement.media;
        if (advertisement.media_translations && advertisement.media_translations.length > 0) {
            const mediaForLanguage = advertisement.media_translations.find((advertisementMediaTranslation: MediaTranslationHttpResponse) => (advertisementMediaTranslation.iso_code2 === this.authenticationService.currentLang.iso_code2 && advertisementMediaTranslation.label_field === 'media_id'));
            if (mediaForLanguage) {
                media = mediaForLanguage.media;
            }
        }
        if (media.media_url && !this.preLoadedImageIds.includes(media.media_id)) {
            const preLoadMedia = new Image();
            preLoadMedia.src = media.media_url;
            this.preLoadedImageIds.push(media.media_id);
        }
        return media;
    }

}
