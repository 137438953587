import {Component, HostBinding, ViewContainerRef} from '@angular/core';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GameVendorGameParticipationStatusEnum,
    TenantService
} from '../../../../shared';
import {AGameVendorGameCardComponent} from '../a-game-vendor-game-card-component';
import {take, takeWhile} from 'rxjs/operators';
import {AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse} from '../../../../api';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-game-vendor-game-card-default',
    templateUrl: './game-vendor-game-card-default.component.html',
    styles: []
})
export class GameVendorGameCardDefaultComponent extends AGameVendorGameCardComponent {
    @HostBinding('class') componentClass = 'ui fluid card vendor-game-card';

    public activeParticipations: GameVendorGameParticipationHttpResponse[];

    constructor(protected viewContainerRef: ViewContainerRef,
                protected authenticationService: AuthenticationService,
                protected betsnapdetailService: BetsnapdetailService,
                public tenantService: TenantService,
                protected errorService: ErrorService,
                protected adService: AdService) {
        super(
            viewContainerRef,
            authenticationService,
            betsnapdetailService,
            tenantService,
            errorService,
            adService
        );

        this.betsnapdetailService.gameVendorGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                    if (gameVendorGameParticipations) {
                        this.activeParticipations = gameVendorGameParticipations.filter((participation: GameVendorGameParticipationHttpResponse) => (
                            participation.vendor_game_id === this.gameVendorGame.vendor_game_id &&
                            participation.status === this.gameVendorGameParticipationStatusEnum.ACTIVE
                        ));

                        this.loadingParticipations = false;
                    }
                });
    }

    startParticipation() {
        if (this.authenticationService.currentUser && this.game.is_current_user_joined) {
            const points = this.game.game_user.user_game_points;
            if (points > 0) {
                this.processParticipation = true;

                this.betsnapdetailService.substractFromUserGamePoints(points);

                this.betsnapdetailService.startGameVendorGameParticipation(this.game.game_unique_id, this.gameVendorGame.vendor_game_id, points)
                    .pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.processParticipation = false;
                        },
                        error: (err: HttpErrorResponse) => {
                            this.betsnapdetailService.addToUserGamePoints(points);
                            this.processParticipation = false;
                            this.errorService.handleHttpErrorResponse(err);
                        }
                    });
            }
        }
    }

}
