/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataMatchScoreHttpResponse } from './appHttpResponsesSportsDataMatchScoreHttpResponse';
import { AppHttpResponsesSportsDataMatchTeamStatisticHttpResponse } from './appHttpResponsesSportsDataMatchTeamStatisticHttpResponse';


/**
 * MatchUpdateHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchUpdateHttpResponse { 
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Status
     */
    status: string;
    /**
     * Count all Markets
     */
    markets_count: number;
    score?: AppHttpResponsesSportsDataMatchScoreHttpResponse;
    home_statistics?: AppHttpResponsesSportsDataMatchTeamStatisticHttpResponse;
    away_statistics?: AppHttpResponsesSportsDataMatchTeamStatisticHttpResponse;
}

