/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PasswordEmailResetRequest
 */
export interface AppHttpRequestsUsersResetPasswordPasswordEmailResetRequest { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * New Password Token
     */
    new_password_key: string;
    /**
     * New Password (min. 5 characters)
     */
    new_password: string;
}

