import {Component, Renderer2} from '@angular/core';
import {AuthenticationService, TenantService} from '../../../../../shared';
import {ASidebarComponent} from '../../a-sidebar-component';

@Component({
    selector: 'betsnaps-left-sidebar-default',
    templateUrl: './left-sidebar-default.component.html',
    styles: []
})
export class LeftSidebarDefaultComponent extends ASidebarComponent {

    constructor(protected renderer: Renderer2,
                public tenantService: TenantService,
                protected authenticationService: AuthenticationService) {
        super(
            authenticationService,
            renderer
        );
    }

}
