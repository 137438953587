/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MediaPublicHttpResponse
 */
export interface AppHttpResponsesMediaMediaPublicHttpResponse { 
    /**
     * Media ID
     */
    media_id: number;
    /**
     * Media Type ID
     */
    media_type_id: number;
    /**
     * User ID
     */
    user_id: number;
    /**
     * Media Name
     */
    name: string;
    /**
     * Absolute Media URL
     */
    media_url: string;
    /**
     * Absolute Media URL (Thumb Big)
     */
    media_url_big?: string;
    /**
     * Absolute Media URL (Thumb Small)
     */
    media_url_small?: string;
    /**
     * Order
     */
    order: number;
}

