<div class="landingpage-container">
    <div class="landingpage-section landingpage-section-hero">
        <div #landingpageHeroContent class="landingpage-section-content landingpage-hero">
            <h1 class="hero-headline left aligned">
                {{tenantLandingpage?.title | dbtranslation:'title':tenantLandingpage?.translations}}
            </h1>
            <p class="hero-subheadline">
                {{tenantLandingpage?.description | dbtranslation:'description':tenantLandingpage?.translations}}
            </p>

            <div [ngClass]="'ui one column grid hero-buttons ' + ((windowWidth < 768) ? 'center aligned' : '')">
                <div class="row">
                    <div class="eleven wide column">
                        <button type="button" routerLink="/arena" class="ui calltoaction2 fluid big shadowed button margin-0 padding-r-0 padding-l-0">
                            {{'HOME.arena_btn' | translate | uppercase}}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="eleven wide column">
                        <betsnaps-landingpage-login-register-buttons [showRegisterButton]="false" remove-wrapping>
                        </betsnaps-landingpage-login-register-buttons>
                    </div>
                </div>
            </div>

            <div class="element-clickable center aligned scroll-down-info" (click)="scrollToNextSection()">
                <i class="keyboard arrow down massive icon color-white margin-0"></i>
            </div>

            <img class="landingpage-image hero-image-small" [alt]="" [src]="'./assets/img/landingpage/default/hero-small.png'" />
            <img class="landingpage-image hero-image-big" [alt]="" [src]="'./assets/img/landingpage/default/hero-big.png'" />
        </div>
    </div>

    <div #landingpageHighlights class="landingpage-section landingpage-section-highlights"
         [class.with-sections-after]="((tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0) || tenantLandingpage?.show_widgets)"
         *ngIf="tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length > 0">
        <div class="landingpage-section-content landingpage-highlights">
            <h2 class="highlights-title center aligned color-white margin-0" *ngIf="tenantLandingpage?.highlights_title">
                {{tenantLandingpage.highlights_title | dbtranslation:'highlights_title':tenantLandingpage.translations}}
            </h2>
            <h3 class="highlights-title center aligned color-white margin-t-0 margin-b-15" *ngIf="tenantLandingpage?.highlights_subtitle">
                {{tenantLandingpage.highlights_subtitle | dbtranslation:'highlights_subtitle':tenantLandingpage.translations}}
            </h3>
            <div class="ui raised segment highlight-segment"
                 [class.animate]="this.highlightsAnimate"
                 [class.fromthird]="iHighlight > 1"
                 *ngFor="let highlight of tenantLandingpage.highlights; let iHighlight = index">
                <div class="ui middle aligned grid">
                    <div class="thirteen wide mobile fourteen wide tablet column fourteen wide computer column highlight-title">
                        {{highlight.title | dbtranslation:'title':highlight.translations}}
                    </div>
                    <div class="three wide mobile two wide tablet column two wide computer column center aligned">
                        <i *ngIf="highlight.icon_class && !highlight.icon_media" [ngClass]="(highlight.icon_class | dbtranslation:'icon_class':highlight.translations) + ' highlight-icon margin-0'"></i>
                        <img class="highlight-icon-image margin-0" *ngIf="highlight.icon_media" [alt]="" [src]="highlight.icon_media.media_url"/>
                    </div>
                </div>
            </div>

            <img class="landingpage-image highlight-image" [alt]="" [src]="'./assets/img/landingpage/default/highlights.png'" />
        </div>
    </div>

    <div #landingpageFeatures class="landingpage-section landingpage-section-features"
         [class.highlights-before]="tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length"
         *ngIf="tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0">
        <div class="landingpage-features-content-wrap">
            <div class="landingpage-section-content landingpage-features">
                <swiper class="features-swiper" [config]="featuresSwiperConfig">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let feature of tenantLandingpage?.features">
                            <div class="ui mobile reversed stackable bottom aligned grid">
                                <div class="ten wide column">
                                    <h2 class="feature-title mobile hidden color-grey">
                                        {{feature.title | dbtranslation:'title':feature.translations | uppercase}}
                                    </h2>
                                    <div class="feature-description" [innerHtml]="feature.description | dbtranslation:'description':feature.translations"></div>
                                </div>
                                <div class="six wide column center aligned">
                                    <div class="ui image feature-image" *ngIf="feature.media">
                                        <img [alt]="" [src]="getFeatureMediaTranslationForLanguage(feature).media_url" />
                                    </div>
                                </div>
                                <div class="sixteen wide column mobile only">
                                    <h2 class="feature-title color-grey">
                                        {{feature.title | dbtranslation:'title':feature.translations | uppercase}}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-bottom">
                        <button type="button"
                                class="ui basic icon button features-swiper-navigation-button features-swiper-navigation-button-prev padding-0 margin-0">
                            <i class="navigate before huge icon"></i>
                        </button>
                        <div class="features-swiper-pagination center aligned"></div>
                        <button type="button"
                                class="ui basic icon button features-swiper-navigation-button features-swiper-navigation-button-next padding-0 margin-0">
                            <i class="navigate next huge icon"></i>
                        </button>
                    </div>
                </swiper>
            </div>
        </div>
    </div>

    <div #landingpageWidgets class="landingpage-section landingpage-section-widgets"
         [class.with-sections-before]="((tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length) || (tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0))"
         *ngIf="tenantLandingpage?.show_widgets">
        <div class="landingpage-widgets-header">
            <div class="landingpage-widgets-header-inner" [class.no-widget-content]="gameList?.results?.length < 1">
                <h2 class="widgets-title" *ngIf="tenantLandingpage.widgets_title">
                    {{tenantLandingpage.widgets_title | dbtranslation:'widgets_title':tenantLandingpage?.translations}}
                </h2>
                <p class="widgets-description" *ngIf="tenantLandingpage.widgets_description">
                    {{tenantLandingpage.widgets_description | dbtranslation:'widgets_description':tenantLandingpage?.translations}}
                </p>
            </div>
        </div>
        <div class="landingpage-section-content landingpage-widgets" *ngIf="publisherWidgetUrl?.length > 0">
            <div class="ui one column centered grid margin-t-15">
                <div class="row">
                    <div class="fourteen wide mobile ten wide tablet ten wide computer column">
                        <iframe [src]="publisherWidgetUrl | safeUrl" width="100%" height="250px" frameborder="0" allowfullscreen="true" scrolling="no"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div #landingpageFooter class="landingpage-section landingpage-section-footer"
         [class.with-images]="((tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length) || (tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0) || tenantLandingpage?.show_widgets)">
        <div class="landingpage-section-content landingpage-footer center aligned">
            <div class="footer-buttons"
                *ngIf="((tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length) || (tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0) || tenantLandingpage?.show_widgets)">
                <div class="ui one column centered grid">
                    <div class="row">
                        <div class="twelve wide mobile six wide tablet five wide computer column">
                            <button type="button" routerLink="/arena" class="ui calltoaction2 fluid large button margin-0 padding-r-0 padding-l-0">
                                {{'HOME.arena_btn' | translate | uppercase}}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <betsnaps-landingpage-login-register-buttons [showRegisterButton]="false" remove-wrapping>
                        </betsnaps-landingpage-login-register-buttons>
                    </div>
                </div>
            </div>

            <p class="share-title margin-b-0">
                {{'GENERAL.GAMES.share' | translate}}
            </p>

            <div *ngIf="isWrapperWindow" class="margin-t-15">
                <button type="button"
                        class="ui circular calltoaction2 icon shadowed button"
                        (click)="callWrapperShare(); googleAnalyticsService.trackEvent('home', 'share', 'wrapper app')">
                    <i class="share alternat icon"></i>
                </button>
            </div>

            <div *ngIf="!isWrapperWindow" class="margin-t-15">
                <button type="button" class="ui circular facebook icon shadowed button" shareButton="facebook"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'facebook')">
                    <i class="fa fa-facebook icon"></i>
                </button>
                <button type="button" class="ui circular twitter icon shadowed button" shareButton="twitter"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'twitter')">
                    <i class="fa fa-twitter icon"></i>
                </button>
                <button type="button" class="ui circular whatsapp icon shadowed button" shareButton="whatsapp"
                        [url]="shareUrl"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'whatsapp')"
                        *ngIf="mobileDetect.device_detection() == 'desktop'">
                    <i class="fa fa-whatsapp icon"></i>
                </button>
                <a [href]="'whatsapp://send?text=' + shareUrl | safeUrl" class="ui circular whatsapp icon shadowed button"
                   (click)="googleAnalyticsService.trackEvent('home', 'share', 'whatsapp')"
                   *ngIf="mobileDetect.device_detection() == 'mobile' || mobileDetect.device_detection() == 'tablet'">
                    <i class="fa fa-whatsapp icon"></i>
                </a>
                <button type="button" class="ui circular black icon shadowed button margin-0"
                        (click)="googleAnalyticsService.trackEvent('home', 'share', 'copied to clipboard')"
                        ngxClipboard
                        [cbContent]="shareUrl"
                        (cbOnSuccess)="showCopyInformation()">
                    <i class="fa fa-link icon"></i>
                </button>
            </div>

            <p *ngIf="showcopyinfo" class="color-white margin-t-5">
                <i class="icon check color-positive"></i> {{'GENERAL.LABELS.NOTIFICATIONS.link_copied' | translate}}
            </p>

            <div class="footer-images" *ngIf="((tenantLandingpage?.show_highlights && tenantLandingpage?.highlights?.length) || (tenantLandingpage?.show_features && tenantLandingpage?.features?.length > 0) || tenantLandingpage?.show_widgets)">
                <img class="landingpage-image footer-image-small"
                     [class.animate]="this.footerAnimate"
                     [alt]="" [src]="'./assets/img/landingpage/default/footer-small.png'" />
                <img class="landingpage-image footer-image-big"
                     [class.animate]="this.footerAnimate"
                     [alt]="" [src]="'./assets/img/landingpage/default/footer-big.png'">
            </div>
        </div>
    </div>
</div>
