<div class="content head">
    <div class="ui middle aligned grid">
        <div class="twelve wide column">
            <div class="text-mini color-light-grey">
                {{gameVendorGameParticipation.id}}
                <div *ngIf="gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.INACTIVE">
                    {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.STATI.inactive' | translate | uppercase}}
                </div>
                <div class="display-flex" *ngIf="gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.ACTIVE">
                    <i class="ui lens mini red icon"></i> {{'GENERAL.LABELS.live' | translate | uppercase}}
                </div>
                <div *ngIf="gameVendorGameParticipation.status === gameVendorGameParticipationStatusEnum.CLOSED">
                    {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.STATI.closed' | translate | uppercase}}
                </div>
            </div>
            <div class="text-tiny truncate color-grey" fuiPopup
                 popupText="{{gameVendorGameParticipation.vendor_game.name | dbtranslation:'name':gameVendorGameParticipation.vendor_game.translations}}"
                 popupTrigger="outsideClick">
                {{gameVendorGameParticipation.vendor_game.name | dbtranslation:'name':gameVendorGameParticipation.vendor_game.translations | uppercase}}
            </div>
        </div>
        <div class="four wide column right aligned">
            <i class="sport-icon icon bsi bsi-sport-999"></i>
        </div>
    </div>
</div>

<div class="content participation-details">
    <div class="ui middle aligned two column grid">
        <div class="row">
            <div class="column">
                <div class="color-light-grey">
                    {{'GENERAL.GAMEVENDORGAMEPARTICIPATION.entry_points' | translate | uppercase}}: <strong>{{gameVendorGameParticipation.entry_points | number:'1.2-2'}}</strong>
                </div>
                <div class="color-light-grey">
                    {{'GENERAL.GAMEVENDORGAMEPARTICIPATIONLOG.total' | translate | uppercase}}: <strong><span *ngIf="participationSaldo() > 0">+</span>{{participationSaldo() | number:'1.2-2'}}</strong>
                </div>
            </div>
            <div class="right aligned column">
                <div class="ui small label margin-0"
                     [ngClass]="{
                        'black' : (participationSaldo() === 0),
                        'positive': (participationSaldo() > 0),
                        'negative': (participationSaldo() < 0)
                    }">
                    {{gameVendorGameParticipation.game_user_points | number:'1.2-2'}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content completed-participation-transactions" *ngIf="closedParticipationTransactions() && closedParticipationTransactions().length > 0">
    <div class="center aligned color-light-grey">
        <strong>{{'GENERAL.GAMEVENDORGAMEPARTICIPATION.logs' | translate | uppercase}} ({{closedParticipationTransactions().length}})</strong>
    </div>
    <div class="ui middle aligned two column grid">
        <div class="row completed-participation-transaction-row" *ngFor="let gameVendorGameParticipationTransaction of closedParticipationTransactions(); trackBy: gameVendorGameParticipationTransactionsTrackBy">
            <div class="column color-light-grey">
                {{'GENERAL.GAMEVENDORGAMEPARTICIPATIONTRANSACTION.points' | translate | uppercase}}: <strong>{{gameVendorGameParticipationTransaction.points | number:'1.2-2'}}</strong>
            </div>
            <div class="right aligned column">
                <span class="ui label color-positive mini" *ngIf="calculateParticipationTransactionSaldo(gameVendorGameParticipationTransaction) >= 0">
                    +{{calculateParticipationTransactionSaldo(gameVendorGameParticipationTransaction) | number:'1.2-2'}}
                </span>
                <span class="ui label color-negative mini" *ngIf="calculateParticipationTransactionSaldo(gameVendorGameParticipationTransaction) < 0">
                    {{calculateParticipationTransactionSaldo(gameVendorGameParticipationTransaction) | number:'1.2-2'}}
                </span>
                {{gameVendorGameParticipationTransaction.total_points | number:'1.2-2'}}
            </div>
        </div>
    </div>
</div>

<div class="content completed-participation-transaction-total">
    <div class="ui middle aligned equal width grid">
        <div class="row">
            <div class="column">
                <strong class="font-very-bold">{{'GENERAL.GAMEVENDORGAMEPARTICIPATIONLOG.total' | translate | uppercase}}</strong>
            </div>
            <div class="right aligned column">
                <strong class="font-very-bold">
                    <span *ngIf="participationSaldo() > 0">+</span>{{participationSaldo() | number:'1.2-2'}}
                </strong>
            </div>
        </div>
    </div>
</div>
