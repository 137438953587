import {Component, ElementRef, HostBinding, ViewChild} from '@angular/core';
import {AdService, AuthenticationService, BetsnapdetailService, GoogleAnalyticsService} from '../../../../../shared';
import {Router} from '@angular/router';
import {ABetsnapdetailVendorGamesComponent} from '../a-betsnapdetail-vendor-games-component';
import {takeWhile} from 'rxjs/operators';
import {AppHttpResponsesVendorGamesGameVendorGameHttpResponse as GameVendorGameHttpResponse} from '../../../../../api';

@Component({
    selector: 'betsnaps-betsnapdetail-vendor-games-v3',
    templateUrl: './betsnapdetail-vendor-games-v3.component.html',
    styles: []
})
export class BetsnapdetailVendorGamesV3Component extends ABetsnapdetailVendorGamesComponent {
    @HostBinding('class') componentClass = 'betsnapdetail-content';

    @ViewChild('runningGameVendorGamesSection') runningGameVendorGamesSection: ElementRef;
    @ViewChild('upcomingGameVendorGamesSection') upcomingGameVendorGamesSection: ElementRef;
    @ViewChild('closedGameVendorGamesSection') closedGameVendorGamesSection: ElementRef;

    constructor(public betsnapdetailService: BetsnapdetailService,
                protected authenticationService: AuthenticationService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected router: Router,
                protected adService: AdService) {
        super(
            betsnapdetailService,
            authenticationService,
            googleAnalyticsService,
            router,
            adService
        );

        this.betsnapdetailService.gameVendorGames$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameListResults: GameVendorGameHttpResponse[]) => {
                    if (gameVendorGameListResults) {
                        this.gameVendorGames = gameVendorGameListResults;
                        this.processGameVendorGames = false;
                    }
                }
            );
    }

    scrollToNextSection(element: ElementRef) {
        element.nativeElement.scrollIntoView({behavior: 'smooth'});
    }

}
