/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsGamesUserBetHttpResponse } from './appHttpResponsesBetsnapsGamesUserBetHttpResponse';
import { AppHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse } from './appHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse';


/**
 * UserBetPlaceBetBatchHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse { 
    /**
     * Place bet report
     */
    report: Array<AppHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse>;
    /**
     * User bets successfully placed
     */
    user_bets?: Array<AppHttpResponsesBetsnapsGamesUserBetHttpResponse>;
}

