/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMarketingMarketingPartnerHttpResponse } from './appHttpResponsesMarketingMarketingPartnerHttpResponse';


/**
 * MarketingPartnerUserPermissionHttpResponse
 */
export interface AppHttpResponsesMarketingMarketingPartnerUserPermissionHttpResponse { 
    /**
     * Marketing Partner User Permission ID
     */
    id: number;
    /**
     * Marketing Partner ID
     */
    marketing_partner_id: number;
    marketing_partner?: AppHttpResponsesMarketingMarketingPartnerHttpResponse;
    /**
     * User ID
     */
    user_id: number;
    /**
     * is permitted
     */
    is_permitted: boolean;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

