/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantCMSCreateRequest
 */
export interface AppHttpRequestsTenantsTenantCMSCreateRequest { 
    /**
     * Master CMS ID
     */
    master_cms_id: number;
    /**
     * Tenant CMS Value
     */
    value: string;
    /**
     * Media ID
     */
    media_id?: number;
}

