import {Component, ComponentFactoryResolver, HostBinding, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {
    AppHttpResponsesBetsnapsGamesGameSinglePlayerCreateHttpResponse as GameSinglePlayerCreateHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameHttpResponse as GameVendorGameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    GamesApi
} from '../../../../api';
import {
    AuthenticationService,
    ArenaService,
    BetsnapdetailService,
    ErrorService,
    GameVendorGameParticipationStatusEnum,
    GoogleAnalyticsService, MyNotificationsService,
    MyTranslateService, MyModalService,
    TenantService, AdService
} from '../../../../shared';
import {AGameCardComponent} from '../a-game-card-component';
import {GameCardTileStyleEnum} from '../../../../shared/enums/game-card-tile-style.enum';
import {take, takeWhile} from 'rxjs/operators';
import {SinglePlayerGameService} from '../../../../shared/services/singleplayer-game.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {FeedService} from '../../../../shared/services/feed.service';

@Component({
    selector: 'betsnaps-game-card-v3',
    templateUrl: './game-card-v3.component.html',
    styles: []
})

export class GameCardV3Component extends AGameCardComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentClass = '';
    public gameCardTileStyleEnum = GameCardTileStyleEnum;

    public activeParticipation: GameVendorGameParticipationHttpResponse;
    public gameVendorGame: GameVendorGameHttpResponse;
    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;

    public processParticipation: boolean = false;

    protected componentAlive = true;

    constructor(protected viewContainerRef: ViewContainerRef,
                protected componentFactoryResolver: ComponentFactoryResolver,
                protected authenticationService: AuthenticationService,
                protected router: Router,
                protected errorService: ErrorService,
                public tenantService: TenantService,
                protected arenaService: ArenaService,
                public translations: MyTranslateService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected adService: AdService,
                private singlePlayerGameService: SinglePlayerGameService,
                public betsnapDetailService: BetsnapdetailService,
                private myModalService: MyModalService,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService,
                protected feedService: FeedService,
                protected gamesApi: GamesApi) {
        super(
            viewContainerRef,
            componentFactoryResolver,
            authenticationService,
            router,
            errorService,
            tenantService,
            arenaService,
            translations,
            googleAnalyticsService,
            adService,
            feedService,
            gamesApi
        );
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.isPartOfList) {
            if (this.game.game_card_tile_style === GameCardTileStyleEnum.HALF_WIDTH) {
                this.componentClass = 'eight wide column';
            } else {
                this.componentClass = 'sixteen wide column';
            }
        } else {
            this.componentClass = 'game-card-wrap';
        }

        this.setBackgroundImage();

        if (this.game.size === 1 && this.game.game_state === 3) {
            this.singlePlayerGameService.singlePlayerGameParticipations$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                        if (gameVendorGameParticipations && this.game) {
                            this.activeParticipation = gameVendorGameParticipations.find((participation: GameVendorGameParticipationHttpResponse) => (
                                participation.game_unique_id === this.game.game_unique_id &&
                                participation.status === this.gameVendorGameParticipationStatusEnum.ACTIVE &&
                                participation.game_user_points > 0
                            ));
                        } else {
                            this.activeParticipation = null;
                        }
                    });

            this.singlePlayerGameService.getSinglePlayerParticipations(this.authenticationService.currentUser.id);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.componentAlive = false;
    }

    startParticipation() {
        if (!this.processParticipation) {
            if (!this.authenticationService.currentUser.username || !this.authenticationService.currentUser.is_username_confirmed) {
                this.myModalService.openUsernameInputModal().onApprove(() => this.startParticipation());
            } else if (this.authenticationService.currentUser.wallets[0].balance === 0) {
                this.translateService.get(['GENERAL.LABELS.alert', 'GENERAL.SINGLE_PLAYER_GAME.insufficient_amount_to_start'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast(translation['GENERAL.LABELS.warning'], translation['GENERAL.SINGLE_PLAYER_GAME.insufficient_amount_to_start'], NotificationType.Alert);
                    });
            } else {
                this.processParticipation = true;

                this.singlePlayerGameService.startGameVendorSinglePlayerGame(this.activeParticipation.vendor_game_id)
                    .pipe(take(1))
                    .subscribe({
                        next: (singlePlayerGameCreateResponse: GameSinglePlayerCreateHttpResponse) => {
                            this.gameVendorGame = singlePlayerGameCreateResponse.game_vendor_game;
                            this.activeParticipation = singlePlayerGameCreateResponse.game_vendor_game_participation;

                            this.singlePlayerGameService.getSinglePlayerParticipations(this.authenticationService.currentUser.id);

                            this.openGameUrl();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.processParticipation = false;
                            this.errorService.handleHttpErrorResponse(err);
                        }
                    });
            }
        }
    }

    resumeParticipation(participation: GameVendorGameParticipationHttpResponse) {
        if (!this.processParticipation &&
            this.authenticationService.currentUser &&
            participation) {
            this.processParticipation = true;

            // check if url expired
            if (moment.utc() < moment(participation.game_url_expiration)) {
                this.openGameUrl();
            } else {
                this.betsnapDetailService.getGameVendorGameParticipation(
                    this.game.game_unique_id,
                    participation.id
                ).pipe(take(1))
                .subscribe({
                    next: (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                        this.activeParticipation = gameVendorGameParticipation;
                        this.openGameUrl();
                    },
                    error: (err: HttpErrorResponse) => {
                        this.processParticipation = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
            }
        }
    }

    openGameUrl() {
        this.processParticipation = false;
        this.singlePlayerGameService.showFullscreenVendorGame(this.activeParticipation, null, this.game.game_name);
    }

    public gameCardClick() {
        if (this.game.sport_id === 999 && this.game.size === 1 && this.game.game_state === 3) {
            if (this.activeParticipation) {
                this.googleAnalyticsService.trackEvent('gamevendorgamecard', 'click', 'resume participation');
                this.resumeParticipation(this.activeParticipation);
            } else {
                this.googleAnalyticsService.trackEvent('gamevendorgamecard', 'click', 'start participation');
                this.startParticipation();
            }
        } else {
            this.navigateToDetail(this.game.game_unique_id, this.GameHelpers.getAdvancedButtonType(this.game));
            this.switchSnap();
        }
    }
}
