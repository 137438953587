import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {
    FeedApi,
    AppHttpResponsesFeedFeedProductListHttpResponse as FeedProductListHttpResponse,
    AppHttpResponsesFeedFeedProductHttpResponse as FeedProductHttpResponse
} from '../../api';
import {
    AppEventsBetsnapsFeedFeedProductStateChanged as FeedProductStateChanged
} from '../';
import {BroadcastingService} from './broadcasting.service';
import {ErrorService} from './error.service';
import {DebugService} from './debug.service';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FeedService {

    private feedPrematchProducerUpSubject = new BehaviorSubject<boolean>(true);
    public feedPrematchProducerUp$ = this.feedPrematchProducerUpSubject.asObservable();

    private feedLiveProducerUpSubject = new BehaviorSubject<boolean>(true);
    public feedLiveProducerUp$ = this.feedLiveProducerUpSubject.asObservable();

    constructor(private broadcastingService: BroadcastingService,
                private errorService: ErrorService,
                private debugService: DebugService,
                private feedApi: FeedApi) {
    }

    public get feedPrematchProducerUp() {
        return this.feedPrematchProducerUpSubject.value;
    }

    public get feedLiveProducerUp() {
        return this.feedLiveProducerUpSubject.value;
    }

    public getFeedProductStatus() {
        this.feedApi.apiFeedFeedproductsGet()
            .pipe(take(1))
            .subscribe({
                next: (feedProductList: FeedProductListHttpResponse) => {
                    if (feedProductList) {
                        if (this.isAnyFeedProductInMaintenance(feedProductList)) {
                            this.feedLiveProducerUpSubject.next(false);
                            this.feedPrematchProducerUpSubject.next(false);
                        } else {
                            feedProductList.results.forEach(
                                (feedProductHttpResponse: FeedProductHttpResponse) => {
                                    if (feedProductHttpResponse.id === 1) {
                                        // LIVE Producer
                                        this.feedLiveProducerUpSubject.next(Boolean(feedProductHttpResponse.is_product_up));
                                    } else if (feedProductHttpResponse.id === 3) {
                                        // PREMATCH Producer
                                        this.feedPrematchProducerUpSubject.next(Boolean(feedProductHttpResponse.is_product_up));
                                    }
                                }
                            );
                        }
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
    }

    private isAnyFeedProductInMaintenance(feedProductList: FeedProductListHttpResponse): boolean {
        let isInMaintenance = false;
        feedProductList.results.forEach(
            (feedProductHttpResponse: FeedProductHttpResponse) => {
                if (feedProductHttpResponse.is_in_maintenance) {
                    isInMaintenance = true;
                }
            }
        );
        return isInMaintenance;
    }

    public subscribeToBroadcastEvents() {
        this.broadcastingService.joinChannel('Feed');

        this.broadcastingService.listenOnEventInChannel('Feed', 'Betsnaps\\Feed\\FeedProductStateChanged')
            .subscribe((broadcastEventData: FeedProductStateChanged) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('FEED PRODUCT STATE CHANGED - ' + broadcastEventData.productId);
                        // if any product is in maintenance
                        if (broadcastEventData.isInMaintenance) {
                            this.feedLiveProducerUpSubject.next(false);
                            this.feedPrematchProducerUpSubject.next(false);
                        } else {
                            if (broadcastEventData.productId === 1) {
                                // LIVE Producer
                                this.feedLiveProducerUpSubject.next(Boolean(broadcastEventData.isProductUp));
                            } else if (broadcastEventData.productId === 3) {
                                // PREMATCH Producer
                                this.feedPrematchProducerUpSubject.next(Boolean(broadcastEventData.isProductUp));
                            }
                        }
                    }
                }
            );
    }

    public unsubscribeFromBroadcastEvents() {
        this.broadcastingService.leaveChannel('Feed');
    }

}
