/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataMatchMarketOutcomeUpdateHttpResponse } from './appHttpResponsesSportsDataMatchMarketOutcomeUpdateHttpResponse';


/**
 * MatchMarketUpdateHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchMarketUpdateHttpResponse { 
    /**
     * Market Id
     */
    market_id: number;
    /**
     * Market Name
     */
    market_name?: string;
    /**
     * Market Name Translations
     */
    market_name_translations?: string;
    /**
     * Specifier Val
     */
    specifier_val: string;
    /**
     * Updated Date
     */
    updated_date: Date;
    /**
     * Market Status
     */
    market_status: number;
    /**
     * Cashout Status
     */
    cashout_status: number;
    /**
     * Favourite
     */
    favourite: boolean;
    /**
     * Initial Favourite
     */
    initial_favourite?: boolean;
    /**
     * Outcomes
     */
    outcomes?: Array<AppHttpResponsesSportsDataMatchMarketOutcomeUpdateHttpResponse>;
}

