<header>
    <div class="ui centered relaxed middle aligned grid container margin-t-0 margin-b-0">
        <div class="row padding-0 tablet or lower hidden" *ngIf="!bigLogoHeader">
            <div class="four wide column">
                <a (click)="onLogoClick()" class="header-tenant-logo">
                    <img
                        [src]="(tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO)) ? tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO).media_url : './assets/img/betsnap_logo.png'"
                        alt="{{tenantService.tenantData?.name}}"/>
                </a>
            </div>
            <div class="eight wide computer column">
                <nav class="ui shadowless borderless labeled icon mini secondary menu main-menu">
                    <a routerLink="/arena" [routerLinkActive]="['active']" class="item">
                        <i class="icon bsi bsi-home"></i>
                        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.arena' | translate}}</span>
                    </a>
                    <a routerLink="/my-snaps" [routerLinkActive]="['active']" class="item"
                       *ngIf="(isAuthenticated$ | async)">
                        <i class="icon bsi bsi-my-games"></i>
                        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.my-snaps' | translate}}</span>
                    </a>
                    <a routerLink="/friends" [routerLinkActive]="['active']" class="item"
                       *ngIf="(isAuthenticated$ | async)">
                        <i class="icon bsi bsi-players"></i>
                        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.friends' | translate}}</span>
                        <betsnaps-notification-bubble
                            [value]="(unreadFriendsCount$ | async)"
                            [additionalClass]="'calltoaction floating margin-l-10'">
                        </betsnaps-notification-bubble>
                    </a>
                    <a routerLink="/ranking" [routerLinkActive]="['active']" class="item"
                       *ngIf="tenantService.tenantData?.configuration?.show_global_rankings">
                        <i class="icon bsi bsi-ranking"></i>
                        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.ranking' | translate}}</span>
                    </a>
                </nav>
            </div>
            <div class="four wide computer column right aligned" *ngIf="!bigLogoHeader">
                <a *ngIf="!(isAuthenticated$ | async)"
                   class="header-link"
                   (click)="sideNavService.toggleSideProfileMenu()">
                    <i class="menu huge icon margin-0"></i>
                </a>
                <a *ngIf="(isAuthenticated$ | async) && currentUser"
                   class="header-link line-height-1"
                   (click)="sideNavService.toggleSideProfileMenu()"
                   [@walletValueChangeAnimation]="walletValueChangeState">
                    <span class="display-inline-block vertical-align-middle right aligned" *ngIf="tenantService.tenantData?.configuration?.show_balance">
                        <span class="font-very-bold balance-label">{{'GENERAL.LABELS.balance' | translate}}</span><br/>
                        <strong class="balance-value">{{currentUser.wallets[0].balance | number:'1.2-2' | currencyFormat:currentUser.wallets[0].currency.symbol }}</strong>
                    </span>
                    <img class="ui middle aligned circular bordered secondary image profileimg bg-white"
                         [ngStyle]="{ 'width': '40px', 'height': '40px' }"
                         [ngClass]="{'left spaced': tenantService.tenantData?.configuration?.show_balance}"
                         [src]="currentUser.media_url_small" [alt]="" />
                    <betsnaps-notification-bubble
                        [value]="(unreadNewsCount$ | async)"
                        [showValue]="false"
                        [additionalClass]="'red empty floating'">
                    </betsnaps-notification-bubble>
                </a>
            </div>
        </div>
        <div class="row padding-0 tablet and lower computer or higher hidden" *ngIf="!bigLogoHeader">
            <div class="ten wide column">
                <h1 class="page-title truncate">
                    {{pageTitle}}
                </h1>
            </div>
            <div class="six wide column right aligned">
                <a (click)="onLogoClick()" class="header-tenant-logo">
                    <img
                        [src]="(tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO)) ? tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO).media_url : './assets/img/betsnap_logo.png'"
                        alt="{{tenantService.tenantData?.name}} Logo"/>
                </a>
            </div>
        </div>
        <div class="row padding-0" *ngIf="bigLogoHeader">
            <div class="sixteen wide column center aligned">
                <a (click)="onLogoClick()" class="header-tenant-logo">
                    <img
                        [src]="(tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO)) ? tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO).media_url : './assets/img/betsnap_logo.png'"
                        alt="{{tenantService.tenantData?.name}} Logo"/>
                </a>
            </div>
        </div>
    </div>
</header>

<section class="login-register-bar" *ngIf="!(isAuthenticated$ | async) && !bigLogoHeader">
    <div class="ui centered relaxed middle aligned grid container">
        <div class="row">
            <div class="eight wide column">
                {{'GENERAL.LABELS.login_register_bar_text' | translate}}
            </div>
            <div class="eight wide column right aligned">
                <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                        (click)="myModalService.openLoginRegisterModal('login', router.url, false, 'header')"
                        class="ui inverted small button margin-0">
                    {{'GENERAL.LABELS.login_btn' | translate}}
                </button>
                <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteLoginUrl()"
                        (click)="goToB2bLogin()"
                        class="ui inverted small button margin-0">
                    {{'GENERAL.LABELS.login_btn' | translate}}
                </button>
                <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                        (click)="myModalService.openLoginRegisterModal('register', router.url, false, 'header')"
                        class="ui inverted small button margin-l-5 margin-r-0">
                    {{'GENERAL.LABELS.register_btn' | translate}}
                </button>
                <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteRegisterUrl()"
                        (click)="goToB2bRegister()"
                        class="ui inverted small button margin-l-5 margin-r-0">
                    {{'GENERAL.LABELS.register_btn' | translate}}
                </button>
            </div>
        </div>
    </div>
</section>

<nav class="ui bottom fixed shadowless borderless icon mini center aligned flexauto menu computer or higher hidden main-menu"
     *ngIf="!bigLogoHeader">
    <a routerLink="/arena" [routerLinkActive]="['active']" class="item center aligned">
        <i class="huge icon bsi bsi-home"></i>
    </a>
    <a routerLink="/my-snaps" [routerLinkActive]="['active']" class="item center aligned"
       *ngIf="(isAuthenticated$ | async)">
        <i class="huge icon bsi bsi-my-games"></i>
    </a>
    <a routerLink="/friends" [routerLinkActive]="['active']" class="item center aligned"
       *ngIf="(isAuthenticated$ | async)">
        <i class="huge icon bsi bsi-players"></i>
        <betsnaps-notification-bubble [value]="(unreadFriendsCount$ | async)"></betsnaps-notification-bubble>
    </a>
    <a routerLink="/ranking" [routerLinkActive]="['active']" class="item center aligned"
       *ngIf="tenantService.tenantData?.configuration?.show_global_rankings">
        <i class="huge icon bsi bsi-ranking"></i>
    </a>
    <a (click)="sideNavService.toggleSideProfileMenu()" class="item center aligned">
        <i class="huge menu icon margin-0"></i>
        <betsnaps-notification-bubble
            [value]="(unreadNewsCount$ | async)"
            [showValue]="false"
            [additionalClass]="'red empty floating margin-b-10'">
        </betsnaps-notification-bubble>
    </a>
</nav>

<nav class="side-profile-menu-wrap" id="side-profile-menu-wrap"
     [ngClass]="{'visible': sideNavService.sideProfileMenuVisible}">
    <div class="side-profile-inner">
        <div class="ui basic segment margin-0">
            <div class="ui grid middle aligned container margin-t-0 margin-b-0" *ngIf="(isAuthenticated$ | async) && authenticationService.currentUser">
                <div class="ten wide column left aligned padding-l-0">
                    <p class="margin-0 line-height-1">
                        <strong class="font-very-bold text-big truncate">{{authenticationService.currentUser.username}}</strong>
                    </p>
                    <p *ngIf="tenantService.tenantData?.configuration?.show_balance" class="text-primary text-small line-height-1">
                        <strong>{{currentUser.wallets[0].balance | number:'1.2-2' | currencyFormat:currentUser.wallets[0].currency.symbol }}</strong>
                    </p>
                    <a *ngIf="(isAuthenticated$ | async) && authenticationService.currentUser"
                       [routerLink]="['/profile/edit']"
                       (click)="sideNavService.toggleSideProfileMenu()"
                        class="ui mini button margin-0 padding-t-5 padding-b-5">
                        {{'GENERAL.HEADER.MENU.profile' | translate}}
                    </a>
                </div>
                <div class="six wide column left aligned padding-r-0">
                    <img class="ui small circular bordered image bg-white" [src]="authenticationService.currentUser.media_url_big" [alt]="" />
                </div>
            </div>
            <div *ngIf="!(isAuthenticated$ | async)" (click)="sideNavService.toggleSideProfileMenu()">
                <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                        (click)="myModalService.openLoginRegisterModal('login', router.url, false, 'header')"
                        class="ui fluid button">
                    {{'GENERAL.LABELS.login_btn' | translate}}
                </button>
                <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteLoginUrl()"
                        (click)="goToB2bLogin()"
                        class="ui fluid button">
                    {{'GENERAL.LABELS.login_btn' | translate}}
                </button>
                <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                        (click)="myModalService.openLoginRegisterModal('register', router.url, false, 'header')"
                        class="ui fluid button margin-t-15">
                    {{'GENERAL.LABELS.register_btn' | translate}}
                </button>
                <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteRegisterUrl()"
                        (click)="goToB2bRegister()"
                        class="ui fluid button margin-t-15">
                    {{'GENERAL.LABELS.register_btn' | translate}}
                </button>
            </div>

            <div class="ui divider margin-b-0"></div>
            <div class="ui vertical fluid tiny menu margin-0" (click)="sideNavService.toggleSideProfileMenu()">
                <div *ngIf="!tenantFooterListResults" class="ui vertical borderless very padded segment">
                    <div class="ui active centered inline text loader"></div>
                </div>
                <div *ngIf="tenantFooterListResults && tenantFooterListResults.length > 0">
                    <div *ngFor="let footeritem of tenantFooterListResults">
                        <a class="item"
                           [href]="footeritem.landing_page_url | dbtranslation:'landing_page_url':footeritem.translations"
                           target="_blank"
                           rel="noopener noreferrer"
                           *ngIf="footeritem.landing_page_url && (footeritem.is_landing_page_external && !isMailToLink(footeritem.landing_page_url))">
                            <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                                <i [ngClass]="footeritem.icon_class + ' icon margin-0'"
                                   *ngIf="footeritem.icon_class && !footeritem.media"></i>
                                <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media"/>
                            </span><span class="menu-item-text">
                                {{footeritem.label | dbtranslation:'label':footeritem.translations | uppercase}}
                            </span>
                        </a>
                        <a class="item"
                           [routerLink]="[footeritem.landing_page_url]"
                           *ngIf="(!footeritem.is_fixed && footeritem.landing_page_url && (!footeritem.is_landing_page_external && !isMailToLink(footeritem.landing_page_url)))
                                    || (footeritem.is_fixed && (isAuthenticated$ | async) && authenticationService.currentUser && ((footeritem.landing_page_url !== '/profile/privacy') || (footeritem.landing_page_url === '/profile/privacy' && showPrivacyMenuItem)))">
                            <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                                <i [ngClass]="footeritem.icon_class + ' icon margin-0'"
                                   *ngIf="footeritem.icon_class && !footeritem.media"></i>
                                <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media"/>
                            </span>
                            <span class="menu-item-text">
                                {{footeritem.label | dbtranslation:'label':footeritem.translations | uppercase}}
                                <betsnaps-notification-bubble
                                    *ngIf="footeritem.is_fixed && footeritem.landing_page_url === '/news'"
                                    [value]="(unreadNewsCount$ | async)"
                                    [additionalClass]="'red margin-l-10'">
                                </betsnaps-notification-bubble>
                            </span>
                        </a>
                        <a class="item"
                           [href]="footeritem.landing_page_url | dbtranslation:'landing_page_url':footeritem.translations"
                           *ngIf="footeritem.landing_page_url && isMailToLink(footeritem.landing_page_url)">
                            <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                                <i [ngClass]="footeritem.icon_class + ' icon margin-0'"
                                   *ngIf="footeritem.icon_class && !footeritem.media"></i>
                                <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media"/>
                            </span><span class="menu-item-text">
                                {{footeritem.label | dbtranslation:'label':footeritem.translations | uppercase}}
                            </span>
                        </a>
                        <span class="item nolink-item" *ngIf="!footeritem.landing_page_url && !footeritem.is_fixed">
                            <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                                <i [ngClass]="footeritem.icon_class + ' icon margin-0'"
                                   *ngIf="footeritem.icon_class && !footeritem.media"></i>
                                <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media"/>
                            </span><span class="menu-item-text">
                                {{footeritem.label | dbtranslation:'label':footeritem.translations | uppercase}}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="ui vertical center aligned padded borderless segment padding-b-0"
                 *ngIf="(isAuthenticated$ | async) && this.authenticationService.currentUser">
                <button type="button" class="ui large basic primary button margin-0"
                        (click)="logout()">
                    {{'PROFILE.OVERVIEW.logout_btn' | translate | uppercase}}
                </button>
            </div>
        </div>
        <div class="copyright">
            <div class="ui vertical borderless segment margin-0">
                <img class="ui image centered small"
                     [src]="'./assets/img/betsnap-logo.svg'"
                     alt="BetSnap"/>
            </div>
            <p class="tiny center aligned color-light-grey margin-0">
                {{'GENERAL.FOOTER.copyright' | translate}} {{currYear}} BetSnap
            </p>
        </div>
    </div>
</nav>
<div class="ui active page dimmer"
     *ngIf="sideNavService.sideProfileMenuVisible"
     (click)="sideNavService.toggleSideProfileMenu()">
</div>

