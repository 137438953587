/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from './appHttpResponsesUsersPlayerPublicHttpResponse';


/**
 * FriendHttpResponse
 */
export interface AppHttpResponsesFriendsFriendHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * User Id
     */
    user_id: number;
    user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    /**
     * Friend User Id
     */
    friend_user_id: number;
    friend_user?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    /**
     * Games played together
     */
    games_played_together?: number;
    /**
     * Last Interaction Date
     */
    last_interacted_at?: string;
}

