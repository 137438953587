/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * UnifiedForecastHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsUnifiedForecastHttpResponse { 
    /**
     * Date of quarter
     */
    date: string;
    /**
     * Time of quarter
     */
    time: string;
    /**
     * Number of games in this quarter
     */
    games_count?: number;
    /**
     * Number of matches in games this quarter
     */
    matches_count?: number;
    /**
     * Number of players in games this quarter
     */
    players_count?: number;
}

