/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantFooterCreateRequest
 */
export interface AppHttpRequestsTenantsTenantFooterCreateRequest { 
    /**
     * Tenant ID
     */
    tenant_id: number;
    /**
     * Label
     */
    label: string;
    /**
     * Display order
     */
    display_order?: number;
    /**
     * Media ID
     */
    media_id?: number;
    /**
     * Icon Class (optional)
     */
    icon_class?: string;
    /**
     * Landing Page URL
     */
    landing_page_url?: string;
    /**
     * Is external Link
     */
    is_landing_page_external: boolean;
    /**
     * Show in App
     */
    show_in_app: boolean;
}

