/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesGeneralGUIThemeHttpResponse } from './appHttpResponsesGeneralGUIThemeHttpResponse';


/**
 * TenantConfigurationPublicHttpResponse
 */
export interface AppHttpResponsesTenantsTenantConfigurationPublicHttpResponse { 
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Theme ID
     */
    theme_id?: number;
    theme?: AppHttpResponsesGeneralGUIThemeHttpResponse;
    /**
     * color primary
     */
    color_primary: string;
    /**
     * color background
     */
    color_background: string;
    /**
     * Markets default expanded
     */
    markets_default_expanded: boolean;
    /**
     * Allow H2H
     */
    allow_h2h: boolean;
    /**
     * B2C Signup Method
     */
    b2c_signup_method?: string;
    /**
     * B2C Signup Method Phone Config: Allowed Country Codes
     */
    b2c_signup_method_phone_allowed_country_codes?: Array<string>;
    /**
     * Show custom landingpage
     */
    show_custom_landingpage: boolean;
    /**
     * Show balance
     */
    show_balance: boolean;
    /**
     * Show global ranking
     */
    show_global_rankings: boolean;
    /**
     * Show create betsnaps
     */
    show_createbetsnaps: boolean;
    /**
     * Show arena filters
     */
    show_arena_filters: boolean;
    /**
     * Show match statistics widgets
     */
    show_match_statistics_widget: boolean;
    /**
     * Show game spots left
     */
    show_game_spots_left: boolean;
    /**
     * Show join success modal
     */
    show_join_success_modal?: boolean;
    /**
     * Show pre game start odd can change info
     */
    show_pre_game_start_odd_can_change_info: boolean;
    /**
     * Show consent text in username modal
     */
    show_consent_text_in_username_modal: boolean;
    /**
     * Show cookie banner
     */
    show_cookie_banner: boolean;
    /**
     * Outgoing notifications
     */
    outgoing_notifications: boolean;
    /**
     * Allow web push notifications
     */
    allow_web_push_notifications: boolean;
    /**
     * OneSignal APP ID for Web Push Notifications
     */
    one_signal_push_notifications_app_id?: string;
    /**
     * Allow Simple Snaps
     */
    allow_simple_snaps: boolean;
    /**
     * Allow Battle Royal Snaps
     */
    allow_battle_royal_snaps: boolean;
    /**
     * Broadcast Game Leaderboards
     */
    broadcast_game_leaderboard?: boolean;
}

