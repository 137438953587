<div class="ui secondary borderless segment" *ngIf="game">
    <div class="ui header margin-t-0">
        <i class="share alternat icon"></i>
        <div class="content">
            {{'GENERAL.GAMES.SHAREGAME.heading' | translate | uppercase}}
        </div>
    </div>
    <div class="center aligned content">
        <div *ngIf="isWrapperWindow" class="ui segment vertical borderless segment padding-0 margin-0">
            <button type="button"
                    class="ui circular calltoaction2 icon button"
                    (click)="callWrapperShare(); googleAnalyticsService.trackEvent('game', 'share', 'wrapper app - ' + game.game_unique_id)">
                <i class="share alternat icon"></i>
            </button>
        </div>

        <div *ngIf="!isWrapperWindow" class="ui segment vertical borderless segment padding-0 margin-0">
            <button type="button"
                    class="ui circular facebook icon button"
                    shareButton="facebook"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'facebook - ' + game.game_unique_id)">
                <i class="fa fa-facebook icon"></i>
            </button>
            <button type="button"
                    class="ui circular twitter icon button"
                    shareButton="twitter"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'twitter - ' + game.game_unique_id)">
                <i class="fa fa-twitter icon"></i>
            </button>
            <button type="button"
                    class="ui circular whatsapp icon button"
                    shareButton="whatsapp"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'whatsapp - ' + game.game_unique_id)"
                *ngIf="mobileDetect.device_detection() == 'desktop'">
                <i class="fa fa-whatsapp icon"></i>
            </button>
            <a [href]="'whatsapp://send?text=' + shareUrl | safeUrl"
               class="ui circular whatsapp icon button"
               (click)="googleAnalyticsService.trackEvent('game', 'share', 'whatsapp - ' + game.game_unique_id)"
               *ngIf="mobileDetect.device_detection() == 'mobile' || mobileDetect.device_detection() == 'tablet'">
                <i class="fa fa-whatsapp icon"></i>
            </a>
            <button type="button"
                    class="ui circular black icon button margin-0"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'copied to clipboard - ' + game.game_unique_id)"
                    ngxClipboard
                    [cbContent]="shareUrl"
                    (cbOnSuccess)="showCopyInformation()">
                <i class="fa fa-link icon"></i>
            </button>
        </div>
        <p *ngIf="showcopyinfo">
            <i class="icon check color-positive"></i> {{'GENERAL.LABELS.NOTIFICATIONS.link_copied' | translate}}
        </p>
        <div class="ui segment vertical borderless segment padding-b-0 margin-0" *ngIf="userFriends.length > 0 && sentGameInvitations && game.size !== game.users_count">
            <p>{{'GENERAL.GAMES.SHAREGAME.share_with_friends' | translate | uppercase}}</p>
            <div *ngIf="!friendsInvited">
                <div class="ui three column centered tight grid">
                    <div *ngFor="let userFriend of userFriends" class="column">
                        <div class="ui center aligned column">
                            <div class="ui link" (click)="selectFriend(userFriend)">
                                <div>
                                    <div class="img-wrap">
                                        <img *ngIf="userFriend.friend_user.media_id > 0"
                                             class="ui circular bordered centered tiny image white"
                                             [src]="userFriend.friend_user.media_url_small"
                                             [ngClass]="{'primary': selectedUserFriends.includes(userFriend)}"/>
                                        <span class="image-overlay primary circular" *ngIf="selectedUserFriends.includes(userFriend)">
                                            <i class="check small icon"></i>
                                        </span>
                                    </div>
                                </div>
                                <span class="truncate"
                                      [ngClass]="{'text-primary font-bold': selectedUserFriends.includes(userFriend) }">
                                    {{userFriend.friend_user.username}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui segment vertical borderless segment padding-b-0 margin-0" *ngIf="noSelectionError">
                    <p class="color-negative margin-0">
                        {{'GENERAL.GAMES.SHAREGAME.no_friends_selected' | translate}}
                    </p>
                </div>
                <div class="ui segment vertical borderless segment padding-b-0 margin-0">
                    <button type="button" class="ui button black fluid margin-0" (click)="inviteFriends()"
                            [class.loading]="processInvite" [class.disabled]="processInvite" [disabled]="processInvite"
                            *ngIf="userFriends.length > 0">
                        {{'GENERAL.GAMES.SHAREGAME.invite-friends_btn' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
        <div class="ui success message" *ngIf="friendsInvited">
            <p>{{'GENERAL.GAMES.SHAREGAME.friends_invited_success' | translate}}</p>
            <button type="button"
                    class="ui fluid button"
                    *ngIf="userFriends.length > 0"
                    (click)="friendsInvited = false">
                {{'GENERAL.GAMES.SHAREGAME.invite-more-friends_btn' | translate | uppercase}}
            </button>
        </div>
    </div>
</div>
