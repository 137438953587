import {Component, Input, OnInit} from '@angular/core';
import {AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse as GameVendorGameParticipationTransactionHttpResponse} from '../../../api';

@Component({
    selector: 'betsnaps-game-vendor-game-participation-transaction',
    templateUrl: './game-vendor-game-participation-transaction.component.html',
    styles: []
})
export class GameVendorGameParticipationTransactionComponent implements OnInit {

    @Input() gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse;

    constructor() {
    }

    ngOnInit() {
    }

    calculateParticipationTransactionSaldo() {
        return this.gameVendorGameParticipationTransaction.total_points - this.gameVendorGameParticipationTransaction.points;
    }

}
