import {Component} from '@angular/core';
import {AdService, AuthenticationService, BetsnapdetailService, GoogleAnalyticsService} from '../../../../../shared';
import {Router} from '@angular/router';
import {ABetsnapdetailVendorGameParticipationsComponent} from '../a-betsnapdetail-vendor-game-participations-component';
import {takeWhile} from 'rxjs/operators';
import {AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse} from '../../../../../api';
import {SinglePlayerGameService} from '../../../../../shared/services/singleplayer-game.service';

@Component({
    selector: 'betsnaps-betsnapdetail-vendor-game-participations-default',
    templateUrl: './betsnapdetail-vendor-game-participations-default.component.html',
    styles: []
})
export class BetsnapdetailVendorGameParticipationsDefaultComponent extends ABetsnapdetailVendorGameParticipationsComponent {

    public filteredGameVendorGameParticipations: GameVendorGameParticipationHttpResponse[];

    public gameVendorGameParticipationsMenu = [
        {
            'key': 'active',
            'translatekey': 'BETSNAPDETAIL.GAMEVENDORGAMEPARTICIPATIONS.MENU.active',
            'count': 0,
            'active': true
        },
        {
            'key': 'closed',
            'translatekey': 'BETSNAPDETAIL.GAMEVENDORGAMEPARTICIPATIONS.MENU.closed',
            'count': 0,
            'active': false
        }
    ];

    public currentMenuKey: string = 'active';

    constructor(protected betsnapdetailService: BetsnapdetailService,
                public authenticationService: AuthenticationService,
                protected singlePlayerGameService: SinglePlayerGameService,
                protected router: Router,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected adService: AdService) {
        super(
            betsnapdetailService,
            authenticationService,
            singlePlayerGameService,
            router,
            googleAnalyticsService,
            adService
        );

        this.betsnapdetailService.gameVendorGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                    this.gameVendorGameParticipations = gameVendorGameParticipations;
                    if (gameVendorGameParticipations) {
                        this.setMenuCounts();

                        // show completed participations if no active are available and completed exist
                        if (this.activeGameVendorGameParticipations().length < 1 && this.closedGameVendorGameParticipations().length > 0) {
                            this.currentMenuKey = 'closed';
                            for (const menuitem of this.gameVendorGameParticipationsMenu) {
                                menuitem.active = (menuitem.key === 'closed');
                            }
                        }

                        this.filterGameVendorGameParticipations(this.currentMenuKey);
                        this.processGameVendorGameParticipations = false;
                    } else {
                        this.processGameVendorGameParticipations = true;
                        this.filteredGameVendorGameParticipations = null;
                        for (const menuitem of this.gameVendorGameParticipationsMenu) {
                            menuitem.count = 0;
                        }
                    }
                }
            );
    }

    private setMenuCounts() {
        for (const menuitem of this.gameVendorGameParticipationsMenu) {
            if (menuitem.key  === 'active') {
                menuitem.count = this.activeGameVendorGameParticipations().length;
            } else if (menuitem.key  === 'closed') {
                menuitem.count = this.closedGameVendorGameParticipations().length;
            }
        }
    }

    private filterGameVendorGameParticipations(key) {
        if (key === 'active') {
            this.filteredGameVendorGameParticipations = this.activeGameVendorGameParticipations();
        } else if (key === 'closed') {
            this.filteredGameVendorGameParticipations = this.closedGameVendorGameParticipations();
        }
    }

    public gameVendorGameParticipationsMenuChange(activatekey: string) {
        this.processGameVendorGameParticipations = true;
        for (const menuitem of this.gameVendorGameParticipationsMenu) {
            menuitem.active = (menuitem.key === activatekey);
        }
        this.currentMenuKey = activatekey;
        this.filterGameVendorGameParticipations(activatekey);
        this.processGameVendorGameParticipations = false;

        this.googleAnalyticsService.trackEvent('game - vendorgameparticipations', 'filter', 'vendorgameparticipations - ' + activatekey);
    }


}
