/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameCreateDataHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameCreateDataHttpResponse { 
    /**
     * Recommended Minimum Size
     */
    minimum_size: number;
    /**
     * Prize Pool
     */
    prize_pool: number;
    /**
     * Is Gift Game
     */
    is_gift: number;
}

