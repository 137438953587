<div class="ui borderless segment margin-0">
    <img class="ui fluid image" src="./assets/img/friends_avatars.jpg">

    <h1 class="ui center aligned header">
        {{'FRIENDS.OVERVIEW_SEARCH.heading' | translate}}
    </h1>

    <form class="ui form" novalidate [formGroup]="searchForm" (ngSubmit)="submitSearch()">
        <div class="ui action fluid input">
            <input type="search"
                   placeholder="{{'FRIENDS.OVERVIEW_SEARCH.searchstring_field' | translate}}"
                   formControlName="searchstring" name="searchstring"/>
            <button class="ui icon button"
                    [class.loading]="processSearchList"
                    [disabled]="processSearchList || searchForm.invalid">
                <i class="search icon"></i>
            </button>
        </div>
        <div *ngIf="searchForm.get('searchstring').touched && searchForm.get('searchstring').hasError('minlength')"
             class="ui pointing red basic label">
            {{'FRIENDS.OVERVIEW_SEARCH.searchstring_minlength' | translate:{minlength: 2} }}
        </div>
    </form>

</div>

<div class="ui vertical borderless segment" *ngIf="!searchActive">

    <div class="ui one column middle aligned centered grid">
        <div class="two column row">
            <div class="column">
                <button [routerLink]="['../groups']"
                        class="ui basic fluid button margin-0 padding-r-10 padding-l-10">
                    {{'FRIENDS.friend_groups' | translate | uppercase}}
                </button>
            </div>
            <div class="column">
                <button [routerLink]="['../invite']"
                        class="ui basic fluid button margin-0 padding-r-10 padding-l-10">
                    {{'FRIENDS.invite_friends' | translate | uppercase}}
                </button>
            </div>
        </div>
    </div>

    <betsnaps-friends-requests-loader></betsnaps-friends-requests-loader>

    <div class="ui vertical borderless very padded segment margin-0" *ngIf="processList">
        <div class="ui active centered inline text loader on-body-bg">
            {{'GENERAL.loadertext' | translate}}
        </div>
    </div>

    <div class="ui vertical borderless segment margin-0 padding-t-0" *ngIf="!processList && userFriends">
        <h2 class="text-on-body-bg">
            {{userFriends.length}} {{userFriends.length | i18nPlural:translations.labelTranslations.friendplural}}
        </h2>

        <div class="ui three column centered tight grid" *ngIf="userFriends.length > 0">
            <div *ngFor="let userFriend of userFriends; trackBy: userFriendsTrackBy" class="column">
                <div class="ui center aligned card margin-0">
                    <div class="content">
                        <betsnaps-user-avatar-loader [user]="userFriend.friend_user"></betsnaps-user-avatar-loader>
                        <span class="truncate">{{userFriend.friend_user.username}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui vertical borderless center aligned segment" *ngIf="userFriends.length < 1">
            <p class="text-on-body-bg">{{'FRIENDS.OVERVIEW_SEARCH.no_friends' | translate}}</p>
        </div>
    </div>
</div>

<div class="ui vertical borderless segment" *ngIf="searchActive">
    <div class="ui one column middle aligned centered  grid">
        <div class="two column row">
            <div class="twelve wide left aligned column">
                <h2 class="text-on-body-bg">
                    {{'FRIENDS.OVERVIEW_SEARCH.search_results' | translate}} <span *ngIf="searchResultUsersList?.total">
                        ({{searchResultUsersList?.total}})
                    </span>
                </h2>
            </div>
            <div class="four wide right aligned column">
                <button type="button"
                        (click)="closeSearch()"
                        class="ui circular icon basic button margin-t-0 margin-b-0">
                    <i class="close icon"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="ui vertical borderless very padded segment" *ngIf="processSearchList">
        <div class="ui active centered inline text loader on-body-bg">
            {{'GENERAL.loadertext' | translate}}
        </div>
    </div>

    <div class="ui vertical borderless segment" *ngIf="!processSearchList && searchResultUsersList">
        <div class="ui three column centered grid" *ngIf="searchResultUsersList.total > 0">
            <div *ngFor="let publicUser of searchResultUsersList.results" class="column">
                <div class="ui center aligned card margin-0">
                    <div class="content">
                        <betsnaps-user-avatar-loader [user]="publicUser"></betsnaps-user-avatar-loader>
                        <span class="truncate">{{publicUser.username}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui vertical borderless center aligned segment" *ngIf="searchResultUsersList.total < 1">
            <p class="text-on-body-bg">{{'GENERAL.no_results_found' | translate}}</p>
        </div>

        <div class="ui basic segment center aligned"
             *ngIf="searchResultUsersList.results.length < searchResultUsersList.total" #loadMoreButton>
            <button type="button" class="ui button margin-0" [class.loading]="processLoadMore" (click)="loadMore()">
                {{'GENERAL.load-more_btn' | translate}}
            </button>
        </div>
    </div>

</div>
