/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataMatchMarketHttpResponse } from './appHttpResponsesSportsDataMatchMarketHttpResponse';
import { AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse } from './appHttpResponsesSportsDataMatchMarketOutcomeHttpResponse';


/**
 * UserBetHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesUserBetHttpResponse { 
    /**
     * User Bet Id
     */
    user_bet_id: number;
    /**
     * Game Unique Id
     */
    game_unique_id: string;
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Market Id
     */
    market_id: number;
    /**
     * Outcome Id
     */
    outcome_id: number;
    /**
     * Specifier Val
     */
    specifier_val: string;
    /**
     * User Id
     */
    user_id: number;
    /**
     * Initial Bet Outcome
     */
    initial_bet_outcome: number;
    /**
     * Initial Bet Outcome Unlimited
     */
    initial_bet_outcome_unlimited: number;
    /**
     * Initial Bet Outcome Probability
     */
    initial_bet_outcome_probability: number;
    /**
     * Initial Bet Outcome Decimal
     */
    initial_bet_outcome_decimal?: number;
    /**
     * Initial Bet Outcome Fractional
     */
    initial_bet_outcome_fractional?: string;
    /**
     * Initial Bet Outcome Moneyline
     */
    initial_bet_outcome_moneyline?: string;
    /**
     * Initial Bet Outcome Points
     */
    initial_bet_outcome_points?: number;
    /**
     * Bet Outcome
     */
    bet_outcome: number;
    /**
     * Bet Outcome Unlimited
     */
    bet_outcome_unlimited: number;
    /**
     * Bet Outcome Probability
     */
    bet_outcome_probability: number;
    /**
     * Bet Outcome Decimal
     */
    bet_outcome_decimal?: number;
    /**
     * Bet Outcome Fractional
     */
    bet_outcome_fractional?: string;
    /**
     * Bet Outcome Moneyline
     */
    bet_outcome_moneyline?: string;
    /**
     * Bet Outcome Points
     */
    bet_outcome_points?: number;
    /**
     * Current Outcome Probability
     */
    current_outcome_probability: number;
    /**
     * Current Outcome Decimal
     */
    current_outcome?: number;
    /**
     * Current Outcome Decimal Unlimited
     */
    current_outcome_unlimited?: number;
    /**
     * Point
     */
    point: number;
    /**
     * Total Point
     */
    total_point: number;
    /**
     * Bet Cancel Reason
     */
    bet_cancel_reason?: string;
    /**
     * Status
     */
    status: number;
    /**
     * Defines if a user bet is valid (all settled/cancelled bets, all cashed out / active bets which at least 1 odds_change)
     */
    is_valid?: boolean;
    /**
     * Void Factor
     */
    void_factor?: number;
    /**
     * Bet ID of the rolled back bet
     */
    rollback_bet_id?: number;
    /**
     * Defines if a bet was rolled back
     */
    is_rolled_back?: boolean;
    /**
     * Created Date
     */
    created_date: Date;
    /**
     * Modified Date
     */
    modified_date: Date;
    /**
     * Pending at date
     */
    pending_at?: Date;
    /**
     * Confirmed at date
     */
    confirmed_at?: Date;
    /**
     * Failed at date
     */
    failed_at?: Date;
    /**
     * Cashout pending at date
     */
    cashout_pending_at?: Date;
    /**
     * Cashout confirmed at date
     */
    cashout_confirmed_at?: Date;
    /**
     * Tenant Id
     */
    tenant_id: number;
    market?: AppHttpResponsesSportsDataMatchMarketHttpResponse;
    outcome?: AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse;
    /**
     * Result Outcomes
     */
    result_outcomes?: Array<AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse>;
}

