/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TimezoneHttpResponse
 */
export interface AppHttpResponsesGeneralTimezoneHttpResponse { 
    /**
     * Timezone ID
     */
    time_zone_id: number;
    /**
     * Timezone Name
     */
    time_zone_name: string;
    /**
     * Timezone Country Code
     */
    country_code: string;
}

