import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {TenantService} from '../../../shared';
import {Router} from '@angular/router';
import {MaintenanceFallbackComponent} from './maintenance-fallback/maintenance-fallback.component';

@Component({
    selector: 'betsnaps-maintenance',
    template: ''
})
export class MaintenanceComponent implements OnInit {

    public maintenancePageTheme: string = 'fallback';

    private componentMap = {
        fallback : MaintenanceFallbackComponent
    };

    constructor(public tenantService: TenantService,
                public router: Router,
                private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {

        if (!this.tenantService.isMaintenanceMode) {
            if (localStorage.getItem('maintenanceRedirect')) {
                this.router.navigate([localStorage.getItem('maintenanceRedirect')]);
                localStorage.removeItem('maintenanceRedirect');
            } else {
                this.router.navigate(['/']);
            }
            return;
        }

        const componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(this.componentMap[this.maintenancePageTheme]);
        this.viewContainerRef.clear();
        this.viewContainerRef.createComponent(componentFactory);
    }

}
