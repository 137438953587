<div class="ui vertical borderless very padded segment" *ngIf="!game">
    <div class="ui active centered inline text loader on-body-bg">{{'GENERAL.loadertext' | translate}}</div>
</div>
<div *ngIf="game">
    <div class="ui fluid flexauto item tiny separatorless menu" *ngIf="game.current_user_friends?.length > 0 && game.competition_type === 2">
        <a href="#" class="item" [class.active]="menu.active" *ngFor="let menu of rankingMenu" (click)="$event.preventDefault(); rankingMenuChange(menu.key);">
            <span class="display-inline-block vertical-align-middle">
                {{menu.translatekey | translate | uppercase}}
            </span><span class="display-inline-block vertical-align-middle text-mini">&nbsp;{{
                    (menu.key === 'all' && leaderBoardUserList?.total) ? '(' + leaderBoardUserList.total + ')' : ''
                }}{{
                    (menu.key === 'friends' && game.current_user_friends.length) ? '(' + game.current_user_friends?.length + ')' : ''
                }}
            </span>
        </a>
    </div>

    <h2 *ngIf="game.competition_type === 1 || game.competition_type === 3" class="text-on-body-bg">{{'BETSNAPDETAIL.MENU.ranking' | translate}}</h2>
    <div class="ui vertical borderless very padded segment" *ngIf="processList">
        <div class="ui active centered inline text loader on-body-bg">{{'GENERAL.loadertext' | translate}}</div>
    </div>

    <div *ngIf="!processList">
        <div *ngIf="currentUser && currentLeaderBoardUser && game.competition_type === 2 &&
            ((!showOnlyFriends && !showCompactLeaderboard && (currentLeaderBoardUser.rank > 1 || currentLeaderBoardUser.is_participation_valid === false)) || showOnlyFriends)">
            <div class="ui divided list leaderboard-list">
                <betsnaps-leaderboard-user-default
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="currentLeaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item currentuser-on-top">
                </betsnaps-leaderboard-user-default>
            </div>
        </div>

        <div *ngIf="game.competition_type === 2 && (game.game_state < 6 || ((isGamePrizeDistributedOrClosed(game)) && showCompactLeaderboard))">

            <div class="ui vertical borderless segment padding-0" *ngIf="!showOnlyFriends && showCompactLeaderboard">
                <button type="button" class="ui fluid button margin-0"
                        [disabled]="processOpenTopRankedLeaderboard"
                        [class.loading]="processOpenTopRankedLeaderboard"
                        (click)="showTopRankedLeaderboard();
                                googleAnalyticsService.trackEvent('game - ranking', 'click', 'show top ranked leaderboard');">
                    {{'BETSNAPDETAIL.RANKING.showtopranked' | translate}}
                </button>
            </div>
            <div class="ui divided list leaderboard-list" *ngIf="!showOnlyFriends && showCompactLeaderboard">
                <betsnaps-leaderboard-user-default
                    *ngFor="let leaderBoardUser of arroundCurrentUserFilter(); trackBy: leaderBoardUserTrackBy"
                    [game]="game"
                    [gameState]="game.game_state"
                    [leaderBoardUser]="leaderBoardUser"
                    [currentUser]="currentUser"
                    [gameMatches]="gameMatches"
                    class="item leaderboard-list-item"
                    [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-default>
            </div>
            <div class="ui divided list leaderboard-list" *ngIf="!showOnlyFriends && !showCompactLeaderboard">
                <betsnaps-leaderboard-user-default
                        *ngFor="let leaderBoardUser of leaderBoardUserList.results; trackBy: leaderBoardUserTrackBy"
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="leaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item"
                        [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-default>
            </div>
            <div class="ui divided list leaderboard-list" *ngIf="showOnlyFriends">
                <betsnaps-leaderboard-user-default
                        *ngFor="let leaderBoardUser of friendLeaderBoardUsers(); trackBy: leaderBoardUserTrackBy"
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="leaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item"
                        [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-default>
            </div>
        </div>

        <div *ngIf="(isGamePrizeDistributedOrClosed(game) && !showCompactLeaderboard) || game.competition_type === 1 || game.competition_type === 3">
            <div *ngIf="game.is_current_user_joined && game?.game_user && game.game_state !== 99 && betsnapdetailService.h2hCompetitor?.user !== null">
                <div class="four wide tablet or lower hidden column"></div>
                <div class="sixteen wide mobile ten wide tablet eight wide computer column">
                    <div class="ui segment margin-t-0">
                        <div class="ui tight grid centered margin-0">
                            <div class="row padding-0">
                                <div class="seven wide center aligned column middle aligned">
                                    <betsnaps-user-avatar-loader *ngIf="currentUserPositionLeft" [user]="currentUser" [imageSize]="(betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') === 1 && betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') !== betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank')) ? 'small' : 'tiny'"></betsnaps-user-avatar-loader>
                                    <betsnaps-user-avatar-loader *ngIf="!currentUserPositionLeft" [user]="betsnapdetailService.h2hCompetitor.user" [imageSize]="(betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank') === 1 && betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') !== betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank')) ? 'small' : 'tiny'"></betsnaps-user-avatar-loader>
                                </div>
                                <div class="two wide middle aligned centered column font-very-bold huge">{{'GENERAL.GAMES.h2h_vs' | translate}}</div>
                                <div class="seven wide center aligned column middle aligned">
                                    <betsnaps-user-avatar-loader *ngIf="currentUserPositionLeft" [user]="betsnapdetailService.h2hCompetitor.user" [imageSize]="(betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank') === 1 && betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') !== betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank')) ? 'small' : 'tiny'"></betsnaps-user-avatar-loader>
                                    <betsnaps-user-avatar-loader *ngIf="!currentUserPositionLeft" [user]="currentUser" [imageSize]="(betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') === 1 && betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') !== betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank')) ? 'small' : 'tiny'"></betsnaps-user-avatar-loader>
                                </div>
                            </div>
                            <div class="row padding-0">
                                <div class="seven wide center aligned column">
                                    <strong *ngIf="currentUserPositionLeft">{{currentUser.username}}</strong>
                                    <strong *ngIf="!currentUserPositionLeft">{{betsnapdetailService.h2hCompetitor.user.username}}</strong>
                                </div>
                                <div class="two wide middle aligned centered column"></div>
                                <div class="seven wide center aligned column">
                                    <strong *ngIf="currentUserPositionLeft">{{betsnapdetailService.h2hCompetitor.user.username}}</strong>
                                    <strong *ngIf="!currentUserPositionLeft">{{currentUser.username}}</strong>
                                </div>
                            </div>
                            <div class="row padding-0 font big" *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') !== (game.competition_type === 1 ? betsnapdetailService.h2hCompetitor.rank : betsnapdetailService.h2hCompetitor.game_user_group.rank)">
                                <div class="seven wide center aligned column" *ngIf="currentUserPositionLeft">
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') === 1" class="color-calltoaction"><strong>{{(game.game_state < 4 ? 'BETSNAPDETAIL.RANKING.leader' : 'BETSNAPDETAIL.RANKING.winner') | translate}}</strong></span>
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') > 1"><strong>{{(game.game_state < 4) ? (2 | ordinalNumber) : ('BETSNAPDETAIL.RANKING.loser' | translate)}}</strong></span>
                                    <button *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'is_participation_valid') === false"
                                            type="button"
                                            class="ui circular icon basic mini button margin-0"
                                            fuiPopup
                                            popupText="{{'GENERAL.GAMES.no_valid_bet' | translate}}"
                                            popupTrigger="outsideClick"
                                            popupPlacement="bottom">
                                        <i class="fa fa-info small icon"></i>
                                    </button>
                                </div>
                                <div class="seven wide center aligned column" *ngIf="!currentUserPositionLeft">
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank') === 1"><strong>{{(game.game_state < 4 ? 'BETSNAPDETAIL.RANKING.leader' : 'BETSNAPDETAIL.RANKING.winner') | translate}}</strong></span>
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank') > 1" class="color-calltoaction"><strong>{{(game.game_state < 4) ? (2 | ordinalNumber) : ('BETSNAPDETAIL.RANKING.loser' | translate)}}</strong></span>
                                    <button *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'is_participation_valid') === false"
                                            type="button"
                                            class="ui circular icon basic mini button margin-0"
                                            fuiPopup
                                            popupText="{{'GENERAL.GAMES.no_valid_bet' | translate}}"
                                            popupTrigger="outsideClick"
                                            popupPlacement="bottom">
                                        <i class="fa fa-info small icon"></i>
                                    </button>
                                </div>
                                <div class="two wide middle aligned centered column"></div>
                                <div class="seven wide center aligned column" *ngIf="!currentUserPositionLeft">
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') === 1" class="color-calltoaction"><strong>{{(game.game_state < 4 ? 'BETSNAPDETAIL.RANKING.leader' : 'BETSNAPDETAIL.RANKING.winner') | translate}}</strong></span>
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') > 1"><strong>{{(game.game_state < 4) ? (2 | ordinalNumber) : ('BETSNAPDETAIL.RANKING.loser' | translate)}}</strong></span>
                                    <button *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'is_participation_valid') === false"
                                            type="button"
                                            class="ui circular icon basic mini button margin-0"
                                            fuiPopup
                                            popupText="{{'GENERAL.GAMES.no_valid_bet' | translate}}"
                                            popupTrigger="outsideClick"
                                            popupPlacement="bottom">
                                        <i class="fa fa-info small icon"></i>
                                    </button>
                                </div>
                                <div class="seven wide center aligned column" *ngIf="currentUserPositionLeft">
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank') === 1"><strong>{{(game.game_state < 4 ? 'BETSNAPDETAIL.RANKING.leader' : 'BETSNAPDETAIL.RANKING.winner') | translate}}</strong></span>
                                    <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank') > 1" class="color-calltoaction"><strong>{{(game.game_state < 4) ? (2 | ordinalNumber) : ('BETSNAPDETAIL.RANKING.loser' | translate)}}</strong></span>
                                    <button *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'is_participation_valid') === false"
                                            type="button"
                                            class="ui circular icon basic mini button margin-0"
                                            fuiPopup
                                            popupText="{{'GENERAL.GAMES.no_valid_bet' | translate}}"
                                            popupTrigger="outsideClick"
                                            popupPlacement="bottom">
                                        <i class="fa fa-info small icon"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row padding-0 font big" *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'rank') === betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'rank') &&
                                                                       betsnapdetailService.getLeaderBoardUserProperty(game, game.game_user, 'is_participation_valid') !== false &&
                                                                       betsnapdetailService.getLeaderBoardUserProperty(game, betsnapdetailService.h2hCompetitor, 'is_participation_valid') !== false
                                                                       && game.game_state > 3">
                                <div class="sixteen wide center aligned column">
                                    <strong>{{'BETSNAPDETAIL.RANKING.draw' | translate}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="four wide tablet or lower hidden column"></div>
            </div>

            <div class="ui divided list leaderboard-list" *ngIf="!showOnlyFriends">
                <betsnaps-leaderboard-user-default
                        *ngFor="let leaderBoardUser of leaderBoardUserList.results; trackBy: leaderBoardUserTrackBy"
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="leaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item"
                        [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-default>
            </div>
            <div class="ui divided list leaderboard-list" *ngIf="showOnlyFriends">
                <betsnaps-leaderboard-user-default
                        *ngFor="let leaderBoardUser of friendLeaderBoardUsers(); trackBy: leaderBoardUserTrackBy"
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="leaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item"
                        [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-default>
            </div>
            <div class="ui basic segment center aligned" *ngIf="!showOnlyFriends && leaderBoardUserList.results?.length < leaderBoardUserList.total && game.competition_type === 2" #loadMoreButton>
                <button type="button" class="ui button margin-0" [class.loading]="processLoadMore" (click)="loadMore()">{{'GENERAL.load-more_btn' | translate}}</button>
            </div>
        </div>

        <ng-template let-context let-modal="modal" #topRankedLeaderBoardModalTemplate *ngIf="game.competition_type === 2">
            <div class="big header">
                <h2><betsnaps-game-name [game]="game"></betsnaps-game-name></h2>
            </div>
            <div class="scrolling content">
                <div class="center aligned">
                    <h3 class="margin-0">{{'BETSNAPDETAIL.RANKING.topranked' | translate}}</h3>
                    <p class="color-grey">
                        {{'BETSNAPDETAIL.RANKING.timestamp' | translate}} {{ topRankedLeaderBoardOpenTimestamp | amLocal | amDateFormat: tenantService.tenantData.internationalization.date_time_format }}
                    </p>
                </div>
                <div class="ui divider margin-b-0"></div>
                <div class="ui divided list leaderboard-list margin-0" *ngIf="currentUser">
                    <betsnaps-leaderboard-user-default
                            *ngFor="let leaderBoardUser of topRankedLeaderBoardUsers; trackBy: leaderBoardUserTrackBy"
                            [game]="game"
                            [gameState]="game.game_state"
                            [leaderBoardUser]="leaderBoardUser"
                            [currentUser]="currentUser"
                            [gameMatches]="gameMatches"
                            [showExtra]="false"
                            class="item leaderboard-list-item"
                            [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                    </betsnaps-leaderboard-user-default>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
