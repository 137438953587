import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component
} from '@angular/core';
import {
    AuthenticationService,
    GoogleAnalyticsService,
    MyModalService, MyNotificationsService,
    TenantService
} from '../../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AMarketOutcomeButtonComponent} from '../a-market-outcome-button.component';

@Component({
    selector: 'betsnaps-market-outcome-button-default',
    templateUrl: './market-outcome-button-default.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketOutcomeButtonDefaultComponent extends AMarketOutcomeButtonComponent {

    constructor(public googleAnalyticsService: GoogleAnalyticsService,
                protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected myNotificationsService: MyNotificationsService,
                protected translateService: TranslateService,
                protected myModalService: MyModalService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected cdr: ChangeDetectorRef) {
        super(googleAnalyticsService,
            authenticationService,
            tenantService,
            myNotificationsService,
            translateService,
            myModalService,
            router,
            route,
            cdr);
    }
}
