import {
    Component,
    Inject, ViewEncapsulation
} from '@angular/core';
import {
    AuthenticationService,
    MyModalService,
    MyNotificationsService,
    ProfileService,
    HintService, ErrorService, TenantService, CurrencyFormatPipe, WindowRef
} from '../../../../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {AngularPageVisibilityService} from 'angular-page-visibility-v2';
import {AMainComponent} from '../a-main.component';
import {NotificationAnimationType, Options} from 'angular2-notifications';

@Component({
    selector: 'betsnaps-main-v3',
    templateUrl: './main-v3.component.html',
    encapsulation: ViewEncapsulation.None,
    styles: []
})
export class MainV3Component extends AMainComponent {

    public options: Options = null;

    constructor(@Inject(DOCUMENT) protected document: any,
            protected myModalService: MyModalService,
            protected authenticationService: AuthenticationService,
            protected tenantService: TenantService,
            protected myNotificationsService: MyNotificationsService,
            protected profileService: ProfileService,
            protected hintService: HintService,
            protected errorService: ErrorService,
            protected activatedRoute: ActivatedRoute,
            protected router: Router,
            protected pageVisibilityService: AngularPageVisibilityService,
            protected currencyFormatPipe: CurrencyFormatPipe,
            protected windowRef: WindowRef) {
        super(
            document,
            myModalService,
            authenticationService,
            tenantService,
            myNotificationsService,
            profileService,
            hintService,
            errorService,
            activatedRoute,
            router,
            pageVisibilityService,
            currencyFormatPipe,
            windowRef
        );
    }
}
