/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * FeedProductHttpResponse
 */
export interface AppHttpResponsesFeedFeedProductHttpResponse { 
    /**
     * FeedProduct ID
     */
    id: number;
    /**
     * Is Product Up
     */
    is_product_up: boolean;
    /**
     * Is Product In Maintenance
     */
    is_in_maintenance: boolean;
}

