<div class="ui vertical borderless very padded segment" *ngIf="showLoader">
    <div class="ui active centered inline text loader color-white text-shadow">
        {{'GENERAL.loadertext' | translate}}
    </div>
</div>
<div *ngIf="game && !showLoader" class="ui fluid card stretch-bottom-card" #betsnapDetailContentCard>
    <div class="ui fluid profile-header-image image">
        <img *ngIf="profileHeaderImage" [src]="profileHeaderImage" [alt]="" />
        <button *ngIf="game.game_state < 3 && game.size !== game.users_count"
                type="button"
                class="ui black circular share-button icon mini button margin-0"
                (click)="openGameShareModal()">
            <i class="bsi bsi-share icon margin-0"></i>
        </button>
    </div>
    <div class="content" [class.with-profile-header-image]="profileHeaderImage">
        <div class="center aligned margin-b-15" *ngIf="betsnapdetailService.isTournament">
            <div *ngIf="game.game_creator">
                <betsnaps-user-avatar-loader [user]="game.game_creator"
                                             [imageSize]="'small'">
                </betsnaps-user-avatar-loader>
                <p class="margin-t-10 margin-b-0">
                    <strong class="font-very-bold text-primary text-large">{{game.game_creator.username}}</strong>
                </p>
            </div>
            <div *ngIf="!game.game_creator">
                <img class="ui circular bordered centered small image bg-grey"
                     [src]="(tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO_SQUARE)) ? tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO_SQUARE).media_url : './assets/img/betsnap_logo_mobile.png'"
                     [alt]="" />
                <p class="margin-t-10 margin-b-0">
                    <strong class="font-very-bold text-primary text-big">{{tenantService.tenantData.name}}</strong>
                </p>
            </div>
            <p class="center aligned color-grey text-tiny font-bold">
                {{'GENERAL.GAMES.created_by' | translate | uppercase}}
            </p>
        </div>

        <div class="center aligned margin-b-15" *ngIf="!betsnapdetailService.isTournament">
            <div class="ui basic segment margin-0 h2h-segment auto-height v3 padding-t-0">
                <div class="ui tight grid margin-0">
                    <div class="row top aligned centered padding-0 margin-0">
                        <div class="seven wide column center aligned">
                            <betsnaps-user-avatar-loader
                                *ngIf="getFirstCompetitor()"
                                [user]="getFirstCompetitor()"></betsnaps-user-avatar-loader>
                            <div class="user-avatar-wrap img-wrap"
                                 *ngIf="!currentUser && !getFirstCompetitor()">
                                <img class="ui circular bordered centered image profileimg placeholder small bgcolor-dark-grey"/>
                                <span class="image-overlay circular">
                                    <i class="fa fa-question icon small"></i>
                                </span>
                            </div>
                            <div class="img-wrap" *ngIf="currentUser && !getFirstCompetitor()">
                                <img [src]="currentUser.media_url_small"
                                     class="ui circular bordered centered primary image small"/>
                                <span class="image-overlay circular">
                                    <i class="fa fa-question icon small"></i>
                                </span>
                            </div>
                            <div class="text-big font-very-bold margin-t-10">
                                {{getFirstCompetitor() ? getFirstCompetitor().username : currentUser ? currentUser.username : '?'}}
                            </div>
                        </div>
                        <div class="two wide column bottom aligned padding-b-15">
                            <strong class="text-massive font-very-bold color-grey text-uppercase nowrap">
                                {{'GENERAL.GAMES.h2h_vs' | translate}}
                            </strong>
                        </div>
                        <div class="seven wide column center aligned">
                            <betsnaps-user-avatar-loader
                                *ngIf="getSecondCompetitor()"
                                [clickAble]="false"
                                [user]="getSecondCompetitor()"></betsnaps-user-avatar-loader>
                            <div class="user-avatar-wrap img-wrap"
                                 *ngIf="!getSecondCompetitor()">
                                <img class="ui circular bordered centered image profileimg placeholder small bgcolor-dark-grey"/>
                                <span class="image-overlay circular">
                                    <i class="fa fa-question icon small"></i>
                                </span>
                            </div>
                            <div class="text-big font-very-bold margin-t-10">
                                {{(getSecondCompetitor()) ? getSecondCompetitor().username : '?'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ui divider margin-t-10 margin-b-5"></div>
        </div>

        <div class="ui centered top aligned tight grid">
            <div class="four wide column center aligned" *ngIf="showPrizes">
                <i class="huge icon bsi bsi-winnings color-grey margin-r-0 margin-b-10"></i>
                <p class="margin-0 text-mini color-light-grey truncate font-bold">
                    {{(betsnapdetailService.isTournament) ? ('GENERAL.GAMES.potsize' | translate | uppercase) : ('GENERAL.GAMES.h2h_win' | translate | uppercase)}}
                </p>
                <ng-container *ngIf="GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                    <p class="margin-0 font-bold">
                        <span *ngFor="let prizeStructure of GameHelpers.getGamePrizeStructuresWithPrizeTitleInGame(game.prize_structures); let i = index">
                            <ng-container *ngIf="i !== 0"><br/>+ </ng-container>
                            {{prizeStructure.prize_title_in_game | dbtranslation:'prize_title_in_game':prizeStructure.translations}}
                        </span>
                    </p>
                </ng-container>
                <ng-container *ngIf="!GameHelpers.hasPrizeTitleInGame(game.prize_structures)">
                    <p class="margin-0 font-bold" *ngIf="(game.is_gift_game === 0 && !game.is_guaranteed && betsnapdetailService.isTournament)">
                        <span *ngIf="game.current_prize_pool > 0 && game.primary_cash_prize_structure_index !== null">
                            {{game.current_prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                            <span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                        </span>
                        <span *ngIf="game.current_prize_pool === 0 && game.primary_cash_prize_structure_index !== null">
                            {{'GENERAL.GAMES.maximum_abbr' | translate}} {{game.prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                            <span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                        </span>
                    </p>
                    <p class="margin-0 font-bold" *ngIf="(game.is_gift_game === 0 && game.is_guaranteed && betsnapdetailService.isTournament)">
                        <span *ngIf="game.primary_cash_prize_structure_index !== null">
                            {{game.prize_pool | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}
                            <span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonuses' | translate}}</span>
                        </span>
                    </p>
                    <p class="margin-0 font-bold" *ngIf="(game.is_gift_game === 1 && betsnapdetailService.isTournament)">
                        {{'GENERAL.GAMES.giftprizes' | translate}}
                    </p>
                    <p class="margin-0 font-bold" *ngIf="!betsnapdetailService.isTournament">
                        {{h2hWinnerInformation}}
                    </p>
                </ng-container>
            </div>
            <div class="four wide column center aligned">
                <i class="huge icon bsi bsi-players color-grey margin-r-0 margin-b-10"></i>
                <p class="margin-0 text-mini color-light-grey truncate font-bold">
                    {{'GENERAL.LABELS.players' | translate | uppercase}}<span *ngIf="game.minimum_size > 0" class="text-mini"> {{'GENERAL.LABELS.player_minimum_size' | translate: { minimum_size: game.minimum_size.toString()} }}</span>
                </p>
                <p class="margin-0 font-bold">
                    {{game.users_count}}<span *ngIf="game.tournaments_type_id !== 3"> / {{game.size}}</span>
                </p>
            </div>
            <div class="four wide column center aligned">
                <i class="huge icon bsi bsi-events color-grey margin-r-0 margin-b-10"></i>
                <p class="margin-0 text-mini color-light-grey truncate font-bold">
                    <span *ngIf="game.sport_id !== 999">
                        {{game.matches_count | i18nPlural:translations.labelTranslations.matchplural | uppercase}}
                    </span>
                    <span *ngIf="game.sport_id === 999">
                        {{game.vendor_games_count | i18nPlural:translations.labelTranslations.vendorgameplural | uppercase}}
                    </span>
                </p>
                <p class="margin-0 font-bold">
                    <span *ngIf="game.sport_id !== 999">
                        {{game.matches_count}}
                    </span>
                    <span *ngIf="game.sport_id === 999">
                        {{game.vendor_games_count}}
                    </span>
                </p>
            </div>
            <div class="four wide column center aligned" *ngIf="showPrizes">
                <i class="huge icon bsi bsi-bets color-grey margin-r-0 margin-b-10"></i>
                <p class="margin-0 text-mini color-light-grey truncate font-bold">
                    {{'GENERAL.GAMES.entry_fee' | translate | uppercase}}
                </p>
                <p class="margin-0 font-bold">
                    <span *ngIf="game.entry_fee !== 0">
                        {{game.entry_fee | decimalPrize: 2 | currencyFormat: game.currency.symbol}}
                    </span>
                    <span *ngIf="game.entry_fee === 0">
                        {{'GENERAL.GAMES.entry_fee_free' | translate}}
                    </span>
                </p>
            </div>
        </div>

        <div class="ui divider margin-t-10 margin-b-5"></div>

        <div class="ui middle aligned tight grid bet-placement-cashout-options">
            <div class="six wide column color-light-grey text-mini font-very-bold">
                {{('GENERAL.GAMES.features' | translate | uppercase)}}
            </div>
            <div class="ten wide column right aligned">
                <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ALWAYS"
                     class="ui small label margin-0 margin-r-10 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.always' | translate}}" popupTrigger="outsideClick"
                     popupPlacement="top right">
                    {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout' | translate | uppercase}}
                </div>
                <div *ngIf="game.cashout_option === gameCashoutOptionEnum.NEVER"
                     class="ui small disabled label margin-0 margin-r-10 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.cashout_not_allowed' | translate}}"
                     popupTrigger="outsideClick" popupPlacement="top right">
                    {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout' | translate | uppercase}}
                </div>
                <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ONLY_LIVE"
                     class="ui small label margin-0 margin-r-10 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.only_live_cashout_allowed' | translate}}"
                     popupTrigger="outsideClick" popupPlacement="top right">
                    {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout_live_only' | translate | uppercase}}
                </div>
                <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ONLY_PRE_GAME"
                     class="ui small disabled label margin-0 margin-r-10 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.only_pre_game_cashout_allowed' | translate}}"
                     popupTrigger="outsideClick" popupPlacement="top right">
                    {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout_live' | translate | uppercase}}
                </div>
                <div *ngIf="game.cashout_option === gameCashoutOptionEnum.ONLY_PRE_MATCH"
                     class="ui small disabled label margin-0 margin-r-10 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.CASHOUT-OPTIONS.only_pre_match_cashout_allowed' | translate}}"
                     popupTrigger="outsideClick" popupPlacement="top right">
                    {{'GENERAL.GAMES.CASHOUT-OPTIONS.LABELS.cashout_live' | translate | uppercase}}
                </div>
                <div *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ALWAYS"
                     class="ui small label margin-0 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.BET-OPTIONS.always' | translate}}" popupTrigger="outsideClick"
                     popupPlacement="top right">
                    {{'GENERAL.GAMES.BET-OPTIONS.LABELS.livebets' | translate | uppercase}}
                </div>
                <div *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_LIVE"
                     class="ui small label margin-0 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.BET-OPTIONS.only_live_bets_allowed' | translate}}"
                     popupTrigger="outsideClick" popupPlacement="top right">
                    {{'GENERAL.GAMES.BET-OPTIONS.LABELS.livebets_only' | translate | uppercase}}
                </div>
                <div *ngIf="game.bet_placement_option === gameBetPlacementOptionEnum.ONLY_PRE_MATCH"
                     class="ui small disabled label margin-0 element-clickable"
                     fuiPopup popupText="{{'GENERAL.GAMES.BET-OPTIONS.only_pre_match_bets_allowed' | translate}}"
                     popupTrigger="outsideClick" popupPlacement="top right">
                    {{'GENERAL.GAMES.BET-OPTIONS.LABELS.livebets' | translate | uppercase}}
                </div>
            </div>
        </div>

        <div class="ui divider margin-t-5 margin-b-10"></div>

        <div *ngIf="prizeStructureVisible()">
            <div class="ui middle aligned centered vertically divided grid">
                <div class="row" *ngFor="let prizeStructureRange of prizeStructureRanges">
                    <div class="two wide column center aligned">
                        <img *ngIf="prizeStructureRange.fromRank <= 3"
                             class="iconimage"
                             [src]="'./assets/img/prizes-rank-' + prizeStructureRange.fromRank + '.png'"
                             alt="">
                        <img *ngIf="prizeStructureRange.fromRank > 3"
                             class="iconimage"
                             [src]="'./assets/img/prizes-rank-other.png'"
                             alt="">
                    </div>
                    <div class="eight wide column left aligned color-light-grey">
                        <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="prizeStructureRange.fromRank === prizeStructureRange.toRank">{{prizeStructureRange.fromRank}}<span class="text-mini">{{prizeStructureRange.fromRank | ordinalNumber:true}}</span> {{'GENERAL.GAMES.rank' | translate}}</span>
                        <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="prizeStructureRange.fromRank !== prizeStructureRange.toRank">{{prizeStructureRange.fromRank}}<span class="text-mini">{{prizeStructureRange.fromRank | ordinalNumber:true}}</span> - {{prizeStructureRange.toRank}}<span class="text-mini">{{prizeStructureRange.toRank | ordinalNumber:true}}</span> {{'GENERAL.GAMES.rank' | translate}}</span>
                    </div>
                    <div class="six wide column right aligned">
                    <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}"
                          *ngIf="(game.is_gift_game === 0)">
                        <span *ngIf="game.primary_cash_prize_structure_index !== null">{{prizeStructureRange.prize_amount | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}</span>
                        <span *ngIf="game.primary_cash_prize_structure_index === null">{{prizeStructureRange.prize_amount | decimalPrize: 2}}</span>
                        <span *ngIf="game.primary_cash_prize_structure_index !== null && game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true"> {{'GENERAL.LABELS.bet_bonus' | translate}}</span>
                        <span *ngIf="prizeStructureRange.tenant_prize" class="gift-title display-block">
                            + {{prizeStructureRange.tenant_prize.title | dbtranslation:'title':prizeStructureRange.tenant_prize.translations}}
                        </span>
                        <span *ngIf="!prizeStructureRange.tenant_prize && prizeStructureRange.tenant_prize_template" class="gift-title display-block">
                            + {{prizeStructureRange.tenant_prize_template.title | dbtranslation:'title':prizeStructureRange.tenant_prize_template.translations}}
                        </span>
                    </span>
                        <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="(game.is_gift_game === 1 && prizeStructureRange.tenant_prize)">
                        {{prizeStructureRange.tenant_prize?.title | dbtranslation:'title':prizeStructureRange.tenant_prize.translations}}
                        </span>
                        <span [ngClass]="{'font-bold': [1,2,3].includes(prizeStructureRange.fromRank)}" *ngIf="(game.is_gift_game === 1 && !prizeStructureRange.tenant_prize && prizeStructureRange.tenant_prize_template)">
                        {{prizeStructureRange.tenant_prize_template?.title | dbtranslation:'title':prizeStructureRange.tenant_prize_template.translations}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui borderless vertical padded segment center aligned margin-0 padding-b-0"
             *ngIf="game.game_state < 3 && game.is_current_user_joined && (game.entry_fee > 0 || (game.entry_fee === 0 && game.leave_free_game))">
            <button type="button" class="ui basic borderless shadowless button color-light-grey font-very-bold margin-0"
                    (click)="openLeaveModal(); googleAnalyticsService.trackEvent('game - details', 'click', 'leave game')">
                {{'GENERAL.GAMES.leave-snap_btn' | translate | uppercase}}
            </button>
        </div>

        <div class="game-detail-buttons-wrap" *ngIf="(currentUser && game.is_current_user_joined) || game.game_state > 2 || game.size === game.users_count">
            <!-- FULLY BOOKED -->
            <button
                *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state < 3 && game.size === game.users_count"
                type="button" class="ui large black button game-detail-button"
                [routerLink]="(game.competition_type > 1) ? '../players' : null">
                <span class="nowrap">
                    {{'GENERAL.GAMES.STATI.fully-booked' | translate}}
                </span>
                <span *ngIf="game.competition_type > 1" class="text-small font-normal display-block">
                    {{'GENERAL.GAMES.show_players' | translate}}
                </span>
            </button>
            <!-- JOINED BEFORE FINISHED -->
            <button *ngIf="currentUser && game.is_current_user_joined && game.game_state < 4 && game.points_engine !== gamePointsEngineEnum.PREDICTION || (game.points_engine === gamePointsEngineEnum.PREDICTION && game.game_state >= 3)"
                    type="button" class="ui large button game-detail-button" [class.padding-10]="advancedButtonType === 'actionCasinoSnap'"
                    [routerLink]="(advancedButtonType === 'actionCasinoSnap') ? '../games' : ((advancedButtonType === 'actionPlaceBet') ? '../matches' : ((advancedButtonType === 'actionCashout') ? '../bets' : '../ranking'))">
                <span class="nowrap">{{advancedButtonTitleLabel | translate}}</span>
                <span class="text-small font-normal display-block" *ngIf="advancedButtonType === 'actionDefault' || advancedButtonType === 'actionPlaceBet'">{{(game.points_engine === gamePointsEngineEnum.PREDICTION ? 'GENERAL.GAMES.POINTS.total_points' : 'GENERAL.GAMES.POINTS.free_points') | translate }}: {{(game.points_engine === gamePointsEngineEnum.PREDICTION ? game.game_user.total_score : game.game_user.user_game_points) | number:'1.2-2'}}</span>
                <span class="text-small font-normal display-block" *ngIf="advancedButtonType === 'actionCashout'">{{'GENERAL.GAMES.open_bets' | translate }}: {{game.current_user_open_bet_count}}</span>
            </button>
            <!-- NOT JOINED RUNNING -->
            <button *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state === 3"
                    type="button" class="ui large black button game-detail-button"
                    [routerLink]="(!currentUser || game.competition_type === 3) ? ((game.competition_type > 1) ? '../players' : null) : '../ranking'">
                <span class="nowrap">
                    {{'GENERAL.GAMES.STATI.running' | translate}}
                </span>
                <span class="text-small font-normal display-block">
                    {{(!currentUser || game.competition_type === 3) ?
                    ((game.competition_type > 1) ? ('GENERAL.GAMES.show_players' | translate) : null) :
                    ('GENERAL.GAMES.show_ranking' | translate)}}
                </span>
            </button>
            <!-- FINISHED / CLOSED -->
            <button *ngIf="(!currentUser || !game.is_current_user_joined) && game.game_state > 3 && game.game_state <= 7" type="button"
                    class="ui large black button game-detail-button"
                    [routerLink]="(game.competition_type === 1) ? null : ((betsnapdetailService.isTournament && currentUser) ? '../ranking' : '../players')">
                <span class="nowrap" *ngIf="game.game_state === 7">{{'GENERAL.GAMES.STATI.closed' | translate}}</span>
                <span class="nowrap" *ngIf="game.game_state !== 7">{{'GENERAL.GAMES.STATI.finished' | translate}}</span>
                <span class="text-small display-block" *ngIf="(game.competition_type > 1)">
                    {{(betsnapdetailService.isTournament && currentUser) ? ('GENERAL.GAMES.show_ranking' | translate) : ('GENERAL.GAMES.show_players' | translate)}}
                </span>
            </button>
            <button *ngIf="currentUser && game.is_current_user_joined && game.game_state > 3 && game.game_state <= 7" type="button"
                    class="ui large black button game-detail-button display-block"
                    [routerLink]="'../ranking'">
                <span class="nowrap" *ngIf="advancedButtonType === 'pastSnap'">{{'GENERAL.GAMES.not_in_prize_ranks' | translate}}</span>
                <span class="nowrap" *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 0 && game.primary_cash_prize_structure_index !== null">{{game.game_user.prize_amount | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}</span>
                <span class="nowrap" *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 1 && getLeaderBoardUserProperty(game, 'tenant_prize')">{{getLeaderBoardUserProperty(game, 'tenant_prize')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize')?.translations}}</span>
                <span class="nowrap" *ngIf="advancedButtonType === 'pastSnapIsWinner' && game.is_gift_game === 1 && !getLeaderBoardUserProperty(game, 'tenant_prize') && getLeaderBoardUserProperty(game, 'tenant_prize_template')">{{getLeaderBoardUserProperty(game, 'tenant_prize')?.title | dbtranslation:'title':getLeaderBoardUserProperty(game, 'tenant_prize')?.translations}}</span>
                <span class="text-small display-block" *ngIf="advancedButtonType === 'pastSnap'">{{'GENERAL.GAMES.better_luck_next_time' | translate }}</span>
                <span class="text-small display-block" *ngIf="advancedButtonType === 'pastSnapIsWinner'&& game.is_gift_game === 0"><span *ngIf="game.primary_cash_prize_structure_index !== null && game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true">{{'GENERAL.LABELS.bet_bonus' | translate}}&nbsp;</span>{{'GENERAL.LABELS.won' | translate}}</span>
                <span class="text-small display-block" *ngIf="advancedButtonType === 'pastSnapIsWinner'&& game.is_gift_game === 1">{{'GENERAL.LABELS.won' | translate}}</span>
            </button>
            <!-- CANCELLED -->
            <button *ngIf="game.game_state === 99" type="button" class="ui large black button game-detail-button"
                    [routerLink]="(currentUser && game.is_current_user_joined) ? '/profile/wallet' : null">
                <span class="nowrap">
                    {{'GENERAL.GAMES.STATI.cancelled' | translate}}
                </span>
                <span class="text-small font-normal display-block" *ngIf="(currentUser && game.is_current_user_joined)">
                    {{('GENERAL.GAMES.entry_fee_refunded' | translate)}}
                </span>
            </button>
        </div>
    </div>
</div>

<ng-template let-context let-modal="modal" #modalLeaveTemplate
             *ngIf="game.game_state < 3 && game.is_current_user_joined && (game.entry_fee > 0 || (game.entry_fee === 0 && game.leave_free_game))">
    <div class="header center aligned">
        {{'GENERAL.GAMES.leave-modal_heading' | translate}}
    </div>
    <div class="actions">
        <button class="ui inverted button"
                (click)="modalLeave.deny(); googleAnalyticsService.trackEvent('game - details - leaveModal', 'click', 'cancel leave');">
            {{'GENERAL.GAMES.leave_no_btn' | translate}}
        </button>
        <button class="ui inverted calltoaction button"
                (click)="leaveGame(); googleAnalyticsService.trackEvent('game - details - leaveModal', 'click', 'approve leave');"
                [class.loading]="processLeave"
                [disabled]="processLeave">
            {{'GENERAL.GAMES.leave_yes_btn' | translate}}
        </button>
    </div>
</ng-template>

<betsnaps-game-share-modal-v3
    #gameShareModalV3Component
    [game]="game"
    [gameShareData]="betsnapdetailService.gameShareData"
    [sentGameInvitations]="betsnapdetailService.sentGameInvitations"
    (onDeny)="betsnapdetailService.getSentGameInvitations(game)"
></betsnaps-game-share-modal-v3>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300"
    [additionalClass]="'betsnapdetail-details-scroll-to-top'"
    [class.notJoined]="!game.is_current_user_joined">
</betsnaps-go-to-top-button>
