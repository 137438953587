/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MatchTimelineEventHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchTimelineEventHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Type Id
     */
    type_id: number;
    /**
     * Type
     */
    type?: string;
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Time
     */
    time: Date;
    /**
     * Event Payload
     */
    event_payload?: object;
}

