/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTenantsTenantTagHttpResponse } from './appHttpResponsesTenantsTenantTagHttpResponse';
import { AppHttpResponsesBetsnapsGamesGameHttpResponse } from './appHttpResponsesBetsnapsGamesGameHttpResponse';


/**
 * GameTagHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesGameTagHttpResponse { 
    /**
     * Game Tag ID
     */
    id: number;
    /**
     * Tenant Tag ID
     */
    tenant_tag_id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    game?: AppHttpResponsesBetsnapsGamesGameHttpResponse;
    tenant_tag?: AppHttpResponsesTenantsTenantTagHttpResponse;
}

