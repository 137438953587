/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesBetsnapsGamesGameTenantHttpResponse } from './appHttpResponsesBetsnapsGamesGameTenantHttpResponse';
import { AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse } from './appHttpResponsesFinancialsCurrenciesCurrencyHttpResponse';
import { AppHttpResponsesMediaMediaHttpResponse } from './appHttpResponsesMediaMediaHttpResponse';
import { AppHttpResponsesBetsnapsGamesAdminGamePrizeStructureHttpResponse } from './appHttpResponsesBetsnapsGamesAdminGamePrizeStructureHttpResponse';
import { AppHttpResponsesFriendsFriendHttpResponse } from './appHttpResponsesFriendsFriendHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';
import { AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse } from './appHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse';
import { AppHttpResponsesUsersPlayerPublicHttpResponse } from './appHttpResponsesUsersPlayerPublicHttpResponse';


/**
 * AdminGameHttpResponse
 */
export interface AppHttpResponsesBetsnapsGamesAdminGameHttpResponse { 
    /**
     * Game Id
     */
    id: number;
    /**
     * Game Unique ID
     */
    game_unique_id: string;
    /**
     * Game Name
     */
    game_name: string;
    /**
     * Game Subtitle
     */
    game_subtitle?: string;
    /**
     * Snap status
     */
    game_state: number;
    /**
     * Sport ID
     */
    sport_id: number;
    /**
     * is this a public Snap?
     */
    is_public: boolean;
    /**
     * indicates a problem with the Game
     */
    is_flagged: boolean;
    /**
     * BetSnap Start-Date
     */
    start_date: Date;
    /**
     * BetSnap End-Date
     */
    end_date: Date;
    /**
     * Publish Date
     */
    publish_date?: Date;
    /**
     * Real End Date
     */
    real_end_date?: Date;
    /**
     * Size
     */
    size: number;
    /**
     * Minimum Size
     */
    minimum_size: number;
    /**
     * Game Spots Left Limit
     */
    game_spots_left_limit: number;
    /**
     * Is Highlight
     */
    is_highlight: boolean;
    /**
     * Master Currency Id
     */
    master_currency_id: number;
    /**
     * Currency Exchange Rate Heads Id
     */
    currency_exchange_rate_heads_id?: number;
    /**
     * Entry Fee
     */
    entry_fee: number;
    /**
     * Site Rake
     */
    site_rake: number;
    /**
     * Betsnaps Rake Share
     */
    betsnaps_rake_share: number;
    /**
     * Prize-Pool
     */
    prize_pool: number;
    /**
     * current Prize-Pool
     */
    current_prize_pool: number;
    /**
     * H2h Prize-Pool
     */
    h2h_prize_pool?: number;
    /**
     * Prize Type
     */
    prize_type: number;
    /**
     * Created By User Id
     */
    created_by: number;
    /**
     * Game Points
     */
    game_points: number;
    /**
     * Widget Game Points
     */
    widget_game_points: number;
    /**
     * Competition Type
     */
    competition_type: number;
    /**
     * Tournaments Type Id
     */
    tournaments_type_id?: number;
    /**
     * Is Simple Snap
     */
    is_simple_snap?: boolean;
    /**
     * Simple Snap: start with first bet
     */
    simple_snap_start_with_first_bet?: boolean;
    /**
     * Is Guaranted
     */
    is_guaranteed: boolean;
    /**
     * Auto Recreate
     */
    auto_recreate: boolean;
    /**
     * Recreate Deadline
     */
    recreate_deadline: Date;
    /**
     * Original Game Unique Id
     */
    original_game_unique_id?: string;
    /**
     * Clone number
     */
    clone_number?: number;
    /**
     * Bet Placement Option (1=always, 2=only pre-game, 3=only pre-match, 4=only live)
     */
    bet_placement_option: number;
    /**
     * Cashout Option (1=always, 2=only pre-game, 3=only pre-match, 4=only live, 5=never)
     */
    cashout_option: number;
    /**
     * Is Simulation Game
     */
    is_simulation: boolean;
    /**
     * Is Gift Game
     */
    is_gift_game: number;
    /**
     * Use Initial Bet Outcome
     */
    use_initial_bet_outcome: boolean;
    /**
     * Outcome Odd Limit
     */
    outcome_odd_limit: number;
    /**
     * Show pre game start odd can change info
     */
    show_pre_game_start_odd_can_change_info: boolean;
    /**
     * Confirm Join Process
     */
    confirm_join_process: boolean;
    /**
     * Leave Free Game
     */
    leave_free_game: boolean;
    /**
     * Max allowed Bets - 0 means unlimited
     */
    max_bet_count: number;
    /**
     * Cashout Odd Limit
     */
    cashout_odd_limit?: number;
    /**
     * Master Cancellation Reasons Id
     */
    master_cancellation_reasons_id?: number;
    /**
     * Chat enabled flag
     */
    chat_enabled?: boolean;
    /**
     * Chat ID
     */
    chat_id?: number;
    /**
     * Allow Prizes without valid participation
     */
    allow_prizes_without_valid_participation: boolean;
    /**
     * Allow Prizes with zero points
     */
    allow_prizes_with_zero_points: boolean;
    /**
     * Created At
     */
    created_at: Date;
    /**
     * Updated At
     */
    updated_at?: Date;
    /**
     * Count all joined users
     */
    users_count: number;
    /**
     * Count all game matches
     */
    matches_count: number;
    /**
     * Count open game matches
     */
    open_matches_count?: number;
    /**
     * Count vendor games
     */
    vendor_games_count?: number;
    /**
     * Count open vendor games
     */
    open_vendor_games_count?: number;
    /**
     * Is Current User Joined
     */
    is_current_user_joined?: boolean;
    /**
     * Is Favoured By User
     */
    is_favoured_by_user?: boolean;
    /**
     * Bet Count of Current User
     */
    current_user_bet_count?: number;
    /**
     * Open Bet Count of Current User
     */
    current_user_open_bet_count?: number;
    /**
     * Has Current User Game Notifications Subscribed
     */
    game_notifications_subscribed?: boolean;
    /**
     * \'No valid bets\' notification at game start
     */
    notification_no_valid_bets_game_start?: boolean;
    /**
     * \'No valid bets\' notification at game start min
     */
    notification_no_valid_bets_last_match_start_min?: number;
    /**
     * \'No bets\' notification at start of last match 
     */
    notification_no_valid_bets_last_match_start?: boolean;
    /**
     * \'No bets\' notification at start of last match  min
     */
    notification_no_valid_bets_game_start_min?: number;
    /**
     * Is Closed Door Game
     */
    is_closed_door: boolean;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Game Tenants
     */
    game_tenants?: Array<AppHttpResponsesBetsnapsGamesGameTenantHttpResponse>;
    /**
     * Sport Translations
     */
    sport_translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    /**
     * Prize Structures
     */
    prize_structures?: Array<AppHttpResponsesBetsnapsGamesAdminGamePrizeStructureHttpResponse>;
    /**
     * Primary Cash Prize Structure Index
     */
    primary_cash_prize_structure_index?: number;
    game_user?: AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse;
    /**
     * Current User joined friends
     */
    current_user_friends?: Array<AppHttpResponsesFriendsFriendHttpResponse>;
    bgimg_game_card?: AppHttpResponsesMediaMediaHttpResponse;
    bgimg_game_header?: AppHttpResponsesMediaMediaHttpResponse;
    bgimg_game_details?: AppHttpResponsesMediaMediaHttpResponse;
    /**
     * Game Card Tile Style
     */
    game_card_tile_style?: string;
    /**
     * Non-Human-Player Fill Percentage
     */
    nhp_fill_percentage?: number;
    /**
     * Non-Human-Player max. Bets to place
     */
    nhp_max_bets?: number;
    currency?: AppHttpResponsesFinancialsCurrenciesCurrencyHttpResponse;
    game_creator?: AppHttpResponsesUsersPlayerPublicHttpResponse;
    /**
     * H2H Competitors
     */
    h2h_competitors?: Array<AppHttpResponsesUsersPlayerPublicHttpResponse>;
    /**
     * Game User Group Competitors (For PooledH2H Games)
     */
    game_user_group_competitors?: Array<AppHttpResponsesUsersPlayerPublicHttpResponse>;
    /**
     * Tag Ids
     */
    tag_ids?: Array<number>;
    /**
     * odds / prediction based
     */
    points_engine?: string;
    /**
     * Winning points for prediction based game bets
     */
    prediction_game_winning_points?: number;
    /**
     * Battle Royal Minimum Won Bets Count
     */
    battle_royal_minimum_won_bets_count?: number;
}

