import {
    Component,
    Inject,
    OnInit,
    Renderer2,
} from '@angular/core';
import {
    ArenaService,
    AuthenticationService,
    GoogleAnalyticsService,
    MobiledetectService, MyModalService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {ErrorService} from '../../../../shared';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../../../../environments/environment';
import {ALandingpageComponent} from '../a-landingpage-component';

@Component({
    selector: 'betsnaps-landingpage-default-publisher-widget',
    templateUrl: './landingpage-default-publisher-widget.component.html',
    styles: []
})
export class LandingpageDefaultPublisherWidgetComponent extends ALandingpageComponent implements OnInit {

    public publisherWidgetUrl;

    constructor(@Inject(DOCUMENT) protected document: any,
                protected authenticationService: AuthenticationService,
                public tenantService: TenantService,
                public mobileDetect: MobiledetectService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected windowRef: WindowRef,
                protected errorService: ErrorService,
                protected arenaService: ArenaService,
                protected renderer: Renderer2,
                public myModalService: MyModalService
    ) {
        super(
            document,
            authenticationService,
            tenantService,
            mobileDetect,
            googleAnalyticsService,
            windowRef,
            errorService,
            arenaService,
            renderer,
            myModalService
        );
    }

    ngOnInit() {
        this.shareUrl = this.tenantService.getTenantDomainWithDefaultRelativePath();

        if (this.tenantLandingpage && this.tenantLandingpage.show_widgets) {
            this.publisherWidgetUrl = environment.APIURL + '/widgets/publisher?tenant=IQu9Uwr6h&lang=' + this.authenticationService.currentLang.iso_code2;
        }

        this.contentWrap = this.document.getElementById('betsnaps-layout');
        this.renderer.addClass(this.contentWrap, 'landingpage');
    }
}
