import {Inject, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {NotificationAnimationType, NotificationsService, NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from './logger.service';
import {GlobalError} from '../interfaces';
import {take} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    private modalErrorSubject = new BehaviorSubject<HttpErrorResponse>(null);
    public modalError$ = this.modalErrorSubject.asObservable();

    private modalAlertSubject = new BehaviorSubject<string>(null);
    public modalAlert$ = this.modalAlertSubject.asObservable();

    private globalErrorSubject = new BehaviorSubject<GlobalError>(null);
    public globalError$ = this.globalErrorSubject.asObservable();

    private notAuthenticatedApiErrorSubject = new BehaviorSubject<boolean>(false);
    public notAuthenticatedApiError$ = this.notAuthenticatedApiErrorSubject.asObservable();

    public defaultNotificationOverride = {
        timeOut: 5000,
        pauseOnHover: true,
        closeOnClick: true,
        showProgressBar: true,
        theClass: 'full-width',
        animate: NotificationAnimationType.FromTop
    };

    public defaultNotificationOverrideV3 = {
        icons: {
            success: '<i class="ui circular bordered padded centered big icon inverted bsi bsi-checkmark"></i>',
            alert: '<i class="ui circular bordered padded centered huge icon inverted bsi bsi-alert"></i>',
            error: '<i class="ui circular bordered padded centered huge icon inverted bsi bsi-callsign"></i>',
            info: '<i class="ui circular bordered padded centered big icon inverted bsi bsi-checkmark"></i>',
            warning: '<i class="ui circular bordered padded centered huge icon inverted bsi bsi-callsign"></i>'
        },
        timeOut: 5000,
        pauseOnHover: true,
        closeOnClick: true,
        showProgressBar: false,
        theClass: 'v3',
        animate: NotificationAnimationType.FromBottom
    };

    constructor(@Inject(DOCUMENT) private document: any,
                private injector: Injector,
                private logService: LoggerService) {
    }

    public createErrorModal(errorResponse: HttpErrorResponse) {
        this.modalErrorSubject.next(errorResponse);
    }

    public createAlertModal(alertKey: string) {
        this.modalAlertSubject.next(alertKey);
    }

    public handleHttpErrorResponse(errorResponse: HttpErrorResponse) {
        if (errorResponse.status === 401 && errorResponse.error.error !== 'invalid_credentials') {
            this.notAuthenticatedApiErrorSubject.next(true);
        } else {
            if (errorResponse.error) {

                // set error data
                const errorData: GlobalError = {
                    imageSrc: './assets/img/css-error.png',
                    errorResponse: errorResponse,
                    code: errorResponse.status,
                    title: 'Error' + (errorResponse.status && errorResponse.status > 0 ? ' ' + errorResponse.status : ''),
                    subTitle: 'Something went wrong',
                    message: errorResponse.error.message ? errorResponse.error.message : errorResponse.statusText,
                    subMessage: errorResponse.message
                };

                // api is not available
                if (errorResponse.status === 0) {
                    errorData.message = 'Please try again later';
                } else {
                    const errorBody = errorResponse.error;
                    if (errorBody.details) {
                        this.logService.writeLogEntry('errorService-handleFieldError', errorResponse);
                        for (const errorDetails of errorBody.details) {
                            this.handleFieldError(errorDetails.error, errorDetails.field);
                        }
                    } else if (errorBody.error) {
                        this.logService.writeLogEntry('errorService-handleGeneralError', errorResponse);
                        this.handleGeneralError(errorBody.error, errorBody.message ? errorBody.message : null);
                    } else {
                        this.logService.writeLogEntry('errorService-createErrorModal', errorResponse);
                        this.createErrorModal(errorResponse);
                    }
                }

                this.globalErrorSubject.next(errorData);
            } else {
                this.logService.writeLogEntry('errorResponse-withoutErrorParameter', errorResponse);
            }
        }
    }

    public handleGeneralError(error_key: string, error_message: string = null) {
        if (error_key) {
            const notificationsService = this.injector.get(NotificationsService);
            const translateService = this.injector.get(TranslateService);
            let error_section = 'GENERAL';

            if (error_key === 'b2b_error') {
                error_key = (error_message != null) ? error_message : 'error';
                error_section = 'B2B';
            }

            translateService.get(['ERRORS.' + error_section + '.title', 'ERRORS.' + error_section + '.' + error_key])
                .pipe(take(1)).subscribe(
                translation => {
                    notificationsService.create(translation['ERRORS.' + error_section + '.title'], translation['ERRORS.' + error_section + '.' + error_key], NotificationType.Error, this.getDefaultNotificationOverride());
                });
        }
    }

    private getDefaultNotificationOverride(): any {
        if (this.document.getElementsByClassName('v3').length > 0) {
            return this.defaultNotificationOverrideV3;
        } else {
            return this.defaultNotificationOverride;
        }
    }

    private handleFieldError(error_key: string, error_field: string) {
        if (error_key && error_field) {
            const notificationsService = this.injector.get(NotificationsService);
            const translateService = this.injector.get(TranslateService);
            translateService.get(['ERRORS.VALIDATION.title', 'ERRORS.VALIDATION.' + error_field + ':' + error_key])
                .pipe(take(1)).subscribe(
                translation => {
                    notificationsService.create(translation['ERRORS.VALIDATION.title'], translation['ERRORS.VALIDATION.' + error_field + ':' + error_key], NotificationType.Error, this.getDefaultNotificationOverride());
                });
        }
    }

}
