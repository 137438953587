<div class="ui top aligned centered grid" *ngIf="!compactUserBetView">
    <div class="one column row padding-t-0">
        <div class="center aligned column tiny color-grey">
            {{'GENERAL.BETS.STATI.placed' | translate}}
            : {{userBet.created_date | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}
            <span *ngIf="userBet.status === userBetStateEnum.SETTLED && userBet.void_factor !== 1">
                <br/>{{'GENERAL.BETS.STATI.settled' | translate}}
                : {{userBet.modified_date | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}
            </span>
            <span
                *ngIf="userBet.status === userBetStateEnum.CANCELLED || (userBet.status === userBetStateEnum.SETTLED && userBet.void_factor === 1)">
                <br/>{{'GENERAL.BETS.STATI.refunded' | translate}}
                : {{userBet.modified_date | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}
            </span>
            <span *ngIf="userBet.status === userBetStateEnum.CASHED_OUT">
                <br/>{{'GENERAL.BETS.STATI.cashedout' | translate}}
                : {{userBet.modified_date | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}
            </span>
            <span *ngIf="userBet.status === userBetStateEnum.ROLLED_BACK_BET_SETTLEMENT">
                <br/>{{'GENERAL.BETS.STATI.rollback_betsettlement' | translate}}
                : {{userBet.modified_date | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}
            </span>
            <span *ngIf="userBet.status === userBetStateEnum.ROLLED_BACK_BET_CANCELLATION">
                <br/>{{'GENERAL.BETS.STATI.rollback_betcancel' | translate}}
                : {{userBet.modified_date | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}
            </span>
            <span *ngIf="userBet.status === userBetStateEnum.AUTO_CASHED_OUT">
                <br/>{{'GENERAL.BETS.STATI.auto_cashedout' | translate}}
                : {{userBet.modified_date | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}</span>
            <span *ngIf="userBet.status === userBetStateEnum.FAILED">
                <br/>{{'GENERAL.BETS.STATI.failed' | translate}}
                : {{userBet.failed_at | amLocal | amCalendar:
            {
                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                lastDay: tenantService.tenantData.internationalization.date_time_format,
                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                sameElse: tenantService.tenantData.internationalization.date_time_format
            }
            }}</span>
        </div>
    </div>
    <div class="one column row padding-0">
        <div class="center aligned column">
            <strong class="large">
                {{userBet.market.market_name | dbtranslation:'name':userBet.market.translations}}
            </strong>
        </div>
        <div class="center aligned column">
            <p class="margin-b-5"><strong>{{((authenticationService.currentUser && userBet.user_id === authenticationService.currentUser.id) ? 'GENERAL.BETS.your-tip' : 'GENERAL.BETS.tip') | translate}}: {{userBet.outcome.outcome_name | dbtranslation:'name':userBet.outcome.translations}}</strong></p>
            <p *ngIf="settledOutcomesTxt && userBet.status === userBetStateEnum.SETTLED"><strong>{{'GENERAL.BETS.result' | translate}}: {{settledOutcomesTxt}}</strong></p>
        </div>
    </div>
    <div class="three column row padding-b-0">
        <div class="center aligned tiny column">
            {{'GENERAL.BETS.amount_odd' | translate}}
        </div>
        <div class="center aligned tiny column">
            {{'GENERAL.BETS.possible-win' | translate}}
        </div>
        <div class="center aligned tiny column"
             *ngIf="userBet.status !== userBetStateEnum.ROLLED_BACK_BET_SETTLEMENT && userBet.status !== userBetStateEnum.ROLLED_BACK_BET_CANCELLATION">
            <strong class="color-positive" *ngIf="calculateUserBetSaldo() > 0">
                +{{calculateUserBetSaldo() | number:'1.2-2'}} &#x25B2;
            </strong>
            <strong class="color-negative" *ngIf="calculateUserBetSaldo() < 0">
                {{calculateUserBetSaldo() | number:'1.2-2'}} &#x25BC;
            </strong>
        </div>
        <div class="center aligned tiny column"
             *ngIf="userBet.status === userBetStateEnum.ROLLED_BACK_BET_SETTLEMENT || userBet.status === userBetStateEnum.ROLLED_BACK_BET_CANCELLATION">
            <strong class="color-negative" *ngIf="calculateUserBetSaldo() >= 0">
                -{{calculateUserBetSaldo() | number:'1.2-2'}} &#x25BC;
            </strong>
            <strong class="color-positive" *ngIf="calculateUserBetSaldo() < 0">
                +{{(calculateUserBetSaldo() * -1) | number:'1.2-2'}} &#x25B2;
            </strong>
        </div>
    </div>
    <div class="three column middle aligned row padding-0">
        <div class="center aligned column">
            <div class="ui fluid label">
                <div class="large">
                    <strong class="text-medium">{{userBet.point | number:'1.2-2'}}</strong>
                </div>
                <div class="tiny" *ngIf="oddDisplayFormat === 'odds'">
                    x {{userBet.bet_outcome_decimal | number:'1.2-2'}}
                </div>
                <div class="tiny" *ngIf="oddDisplayFormat === 'value'">
                    +{{userBet.bet_outcome_points}}
                </div>
                <div class="tiny" *ngIf="oddDisplayFormat === 'fractional'">
                    x {{userBet.bet_outcome_fractional}}
                </div>
                <div class="tiny" *ngIf="oddDisplayFormat === 'moneyline'">
                    {{userBet.bet_outcome_moneyline}}
                </div>
            </div>
        </div>
        <div class="center aligned column">
            <div class="ui fluid big one-line label">
                <strong class="text-large">{{(userBet.point * userBet.bet_outcome) | number:'1.2-2'}}</strong>
            </div>
        </div>
        <div class="center aligned column" *ngIf="userBet.status === userBetStateEnum.ACTIVE">
            <button (click)="cashOutBet()" type="button" class="ui fluid button"
                    [class.loading]="processCashOut"
                    [disabled]="(processCashOut || !isCashoutAllowed())"
                    [ngClass]="{
                'grey disabled': (processCashOut || !isCashoutAllowed()),
                'positive': (calculateUserBetSaldo() > 0),
                'negative': (calculateUserBetSaldo() < 0),
                'big one-line': (!isCashoutAllowed()),
                'opacity-50': (isCashoutAllowed() && !isCashoutAvailable())
              }">
                <span *ngIf="(isCashoutAllowed() && isCashoutAvailable())">{{'GENERAL.BETS.cashout' | translate | uppercase}}</span>
                <span class="text-mini" *ngIf="(isCashoutAllowed() && !isCashoutAvailable())">{{'GENERAL.BETS.no_cashout' | translate | uppercase}}</span>
                <span>{{cashoutPoints | number:'1.2-2'}}</span>
            </button>
        </div>
        <div class="center aligned column"
             *ngIf="isBetFinished()">
            <button type="button" class="ui fluid basic font-bold big one-line button bet-finished"
                    [ngClass]="{'positive': calculateUserBetSaldo() >= 0, 'negative': calculateUserBetSaldo() < 0}">
                {{userBet.total_point | number:'1.2-2'}}
            </button>
        </div>
        <div class="center aligned column" *ngIf="userBet.status === userBetStateEnum.FAILED">
            <button type="button" class="ui fluid basic font-bold button bet-finished negative">
                {{userBet.total_point | number:'1.2-2'}}
                <span class="text-mini">{{'GENERAL.BETS.bet_placement_failed' | translate}}</span>
            </button>
        </div>
    </div>
</div>

<div class="ui middle aligned centered grid" *ngIf="compactUserBetView">
    <div class="three column row">
        <div class="column element-clickable" fuiPopup
             [popupTemplate]="popupBetMarketOutcome"
             popupTrigger="outsideClick">
            <div class="text-tiny color-grey truncate">
                {{userBet.market.market_name | dbtranslation:'name':userBet.market.translations}}
            </div>
            <div class="font-bold truncate">
               {{userBet.outcome.outcome_name | dbtranslation:'name':userBet.outcome.translations}}
            </div>
        </div>
        <div class="column">
            <div class="text-tiny color-grey truncate">
                {{'GENERAL.BETS.possible-win' | translate}}
            </div>
            <strong>{{(userBet.point * userBet.bet_outcome) | number:'1.2-2'}}</strong>
        </div>
        <div class="center aligned column">
            <button *ngIf="userBet.status === userBetStateEnum.FAILED" (click)="removeFailedBet()" type="button"
                    class="ui fluid button negative">
                <span>{{'GENERAL.BETS.bet_placement' | translate}}</span>
                <span class="text-mini">{{'GENERAL.BETS.STATI.failed' | translate | lowercase}}</span>
            </button>
            <button type="button" class="ui grey disabled fluid button"
                    *ngIf="userBet.status === userBetStateEnum.PENDING">
                <div class="ui active inline mini inverted loader"></div>
                <span class="text-mini">{{'GENERAL.BETS.bet_verifying' | translate}}</span>
            </button>
            <button type="button"
                    class="ui fluid button cashout-button disabled"
                    [ngClass]="{
                    'positive': (calculateUserBetSaldo() > 0),
                    'negative': (calculateUserBetSaldo() < 0),
                    'grey': (calculateUserBetSaldo() === 0)
                    }" *ngIf="userBet.status === userBetStateEnum.CASHOUT_PENDING">
                <span class="button-text">
                    <span class="ui active inline mini inverted loader margin-0 padding-0 display-inline-block"></span>
                    <span class="text-mini">{{'GENERAL.BETS.cashout_verifying' | translate}}</span>
                </span>
            </button>
            <button *ngIf="userBet.status === userBetStateEnum.ACTIVE"
                    type="button"
                    class="ui fluid button cashout-button"
                    (click)="cashOutBet()"
                    [class.loading]="processCashOut"
                    [disabled]="(processCashOut || !isCashoutAllowed())"
                    [ngClass]="{
                        'grey disabled': (processCashOut || !isCashoutAllowed()),
                        'positive': (calculateUserBetSaldo() > 0),
                        'negative': (calculateUserBetSaldo() < 0),
                        'opacity-50': (isCashoutAllowed() && !isCashoutAvailable())
                      }">
                <span class="cashout-progress cashout-progress-1">
                    <span class="cashout-progress-inner" [style.width.%]="cashOutProgress1"></span>
                </span>
                <span class="cashout-progress cashout-progress-2">
                    <span class="cashout-progress-inner" [style.width.%]="cashOutProgress2"></span>
                </span>
                <span class="button-text">
                    <span *ngIf="isCashoutAllowed() && isCashoutAvailable()"><strong>{{'GENERAL.BETS.cashout' | translate | uppercase}}</strong></span>
                    <span class="text-mini" *ngIf="isCashoutAllowed() && !isCashoutAvailable()">{{'GENERAL.BETS.no_cashout' | translate | uppercase}}</span>
                    <span [class.text-big]="!isCashoutAllowed()">{{cashoutPoints | number:'1.2-2'}}</span>
                </span>
            </button>
        </div>
    </div>
</div>

<ng-template let-popup #popupBetMarketOutcome>
    <div class="content betmarketoutcome-popup">
        <p class="margin-b-5">
            {{userBet.market.market_name | dbtranslation:'name':userBet.market.translations}}
        </p>
        <p>
            <strong>{{userBet.outcome.outcome_name | dbtranslation:'name':userBet.outcome.translations}}</strong>
        </p>
    </div>
</ng-template>

<div class="ui top aligned centered grid"
     *ngIf="preGameStartOddsCanChange && game.show_pre_game_start_odd_can_change_info && (userBet.status === userBetStateEnum.ACTIVE || userBet.status === userBetStateEnum.CASHOUT_PENDING)">
    <div class="one column row">
        <div class="center aligned column text-mini">
            {{'GENERAL.BETS.pre_game_start_odds_can_change' | translate}}
        </div>
    </div>
</div>
