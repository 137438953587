/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameVendorGameParticipationTransactionHttpResponse
 */
export interface AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse { 
    /**
     * Game Vendor Game Participation Log Id
     */
    id: number;
    /**
     * Game Unique Id
     */
    game_unique_id: string;
    /**
     * Vendor Game Id
     */
    vendor_game_id: number;
    /**
     * User Id
     */
    user_id: number;
    /**
     * Game Vendor Game Participation Id
     */
    game_vendor_game_participation_id: number;
    /**
     * Entry Points
     */
    points: number;
    /**
     * Cashout Points
     */
    total_points: number;
    /**
     * Status
     */
    status: number;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

