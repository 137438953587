<div class="ui text center aligned container">
    <div class="ui very padded basic segment padding-l-0 padding-r-0 margin-0">
        <h1 class="ui center aligned inverted header hyphenate">{{'GENERAL.404_page_notfound' | translate}}</h1>
        <div class="ui one column centered grid">
            <div class="ten wide mobile five wide tablet five wide computer column">
                <a routerLink="/arena" class="ui calltoaction2 fluid large button margin-0 padding-r-0 padding-l-0">
                    {{'HOME.arena_btn' | translate | uppercase}}</a>
            </div>
        </div>
    </div>
</div>
