import { AfterViewInit, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild, Directive } from '@angular/core';
import {
    ArenaService,
    AuthenticationService,
    GoogleAnalyticsService,
    MobiledetectService, MyModalService,
    TenantService,
    WindowRef
} from '../../../shared';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../../shared';
import {
    AppHttpResponsesTenantsTenantLandingpageHttpResponse as TenantLandingpageHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameHttpListResponse,
    AppHttpResponsesTenantsTenantLandingpageFeatureHttpResponse as TenantLandingpageFeatureHttpResponse,
    AppHttpResponsesMediaMediaPublicHttpResponse as MediaPublicHttpResponse,
    AppHttpResponsesTranslationsMediaTranslationHttpResponse as MediaTranslationHttpResponse

} from '../../../api';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {SwiperOptions} from 'swiper';


@Directive()
export abstract class ALandingpageComponent implements OnInit, OnDestroy, AfterViewInit {

    public shareUrl: string;
    public showcopyinfo: boolean = false;

    protected nativeWindow;
    public isWrapperWindow: boolean = false;

    public showSpinner: boolean = false;

    protected contentWrap: HTMLElement;

    @Input() tenantLandingpage: TenantLandingpageHttpResponse;

    protected gameListSubscription: Subscription;
    public gameList: GameHttpListResponse;

    public featuresSwiperConfig: SwiperOptions = {
        pagination: {
            el: '.features-swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.features-swiper-navigation-button-next',
            prevEl: '.features-swiper-navigation-button-prev',
            disabledClass: 'disabled'
        },
        spaceBetween: 0,
        loop: false,
        simulateTouch: true
    };

    public widgetsSwiperConfig: SwiperOptions = {
        pagination: {
            el: '.widgets-swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.widgets-swiper-navigation-button-next',
            prevEl: '.widgets-swiper-navigation-button-prev',
            disabledClass: 'disabled'
        },
        spaceBetween: 0,
        loop: false,
        simulateTouch: true
    };

    public windowWidth: number = 0;
    public windowHeight: number = 0;

    protected preLoadedImageIds = [];

    @ViewChild('landingpageHeroContent', {static: true}) public landingpageHeroContent: ElementRef;
    @ViewChild('landingpageHighlights') public landingpageHighlights: ElementRef;
    @ViewChild('landingpageFeatures') public landingpageFeatures: ElementRef;
    @ViewChild('landingpageWidgets') public landingpageWidgets: ElementRef;
    @ViewChild('landingpageFooter', {static: true}) public landingpageFooter: ElementRef;

    public highlightsAnimate = false;
    public footerAnimate = false;

    constructor(protected document: any,
                protected authenticationService: AuthenticationService,
                public tenantService: TenantService,
                public mobileDetect: MobiledetectService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected windowRef: WindowRef,
                protected errorService: ErrorService,
                protected arenaService: ArenaService,
                protected renderer: Renderer2,
                public myModalService: MyModalService
    ) {
        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }

        this.windowWidth = this.nativeWindow.window.innerWidth;
        this.windowHeight = this.nativeWindow.window.innerHeight;
    }

    ngOnInit() {
        this.shareUrl = this.tenantService.getTenantDomainWithDefaultRelativePath();

        if (this.tenantLandingpage && this.tenantLandingpage.show_widgets) {

            const arenaFilters = {
                'per_page': 3,
                'current_page': 1,
                'game_state': '1,2',
                'sport_id': undefined,
                'is_public': 1,
                'competition_type': undefined,
                'is_highlight': undefined,
                'joined_user_id': undefined,
                'only_with_friends': undefined,
                'exclude_fully_booked': 1,
                'include_all_joined_snaps': 1,
                'sorting': 'asc'
            };

            this.gameListSubscription = this.arenaService.getGameList(arenaFilters)
                .pipe(take(1))
                .subscribe({
                    next: (response: GameHttpListResponse) => {
                        this.gameList = response;
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err),
                    complete: ()=>
                    {
                        this.gameListSubscription.unsubscribe();
                        this.gameListSubscription = null;
                    }
                });
        }

        this.contentWrap = this.document.getElementById('betsnaps-layout');
        this.renderer.addClass(this.contentWrap, 'landingpage');
    }

    ngAfterViewInit(): void {
        this.setHeroSegmentHeight();
    }

    ngOnDestroy(): void {

        if (this.gameListSubscription) {
            this.gameListSubscription.unsubscribe();
            this.gameListSubscription = null;
        }

        this.renderer.removeClass(this.contentWrap, 'landingpage');
    }

    showCopyInformation() {
        this.showcopyinfo = true;
        const self = this;
        setTimeout(() => {
            self.showcopyinfo = false;
        }, 3000);
    }

    callWrapperShare() {
        if (this.isWrapperWindow) {
            this.nativeWindow.window.BetSnapMobileWrapper.share([this.shareUrl]);
        }
    }

    scrollToNextSection() {
        if (this.tenantLandingpage) {
            if (this.tenantLandingpage.show_highlights) {
                this.landingpageHighlights.nativeElement.scrollIntoView({behavior: 'smooth'});
            } else {
                if (this.tenantLandingpage.show_features) {
                    this.landingpageFeatures.nativeElement.scrollIntoView({behavior: 'smooth'});
                } else {
                    if (this.tenantLandingpage.show_widgets) {
                        this.landingpageWidgets.nativeElement.scrollIntoView({behavior: 'smooth'});
                    } else {
                        this.landingpageFooter.nativeElement.scrollIntoView({behavior: 'smooth'});
                    }
                }
            }
        }
    }

    private setHeroSegmentHeight() {
        this.renderer.setStyle(this.landingpageHeroContent.nativeElement, 'height', (this.windowHeight - 58) + 'px');
    }

    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    landingPageListener() {
        this.windowWidth = this.nativeWindow.window.innerWidth;
        this.windowHeight = this.nativeWindow.window.innerHeight;
        this.setHeroSegmentHeight();
    }

    @HostListener('window:scroll', [])
    landingPageScrollListener() {

        if (this.landingpageHighlights &&
            this.landingpageHighlights.nativeElement.getBoundingClientRect().top < (this.windowHeight - (this.windowHeight / 3))) {
            this.highlightsAnimate = true;
        }
        if (this.landingpageFooter.nativeElement.getBoundingClientRect().top < (this.windowHeight - (this.windowHeight / 3))) {
            this.footerAnimate = true;
        }

    }


    public getFeatureMediaTranslationForLanguage(feature: TenantLandingpageFeatureHttpResponse): MediaPublicHttpResponse {
        let media = feature.media;
        if (feature.media_translations && feature.media_translations.length > 0) {
            const mediaForLanguage = feature.media_translations.find((featureMediaTranslation: MediaTranslationHttpResponse) => (featureMediaTranslation.iso_code2 === this.authenticationService.currentLang.iso_code2 && featureMediaTranslation.label_field === 'media_id'));
            if (mediaForLanguage) {
                media = mediaForLanguage.media;
            }
        }
        if (media.media_url && !this.preLoadedImageIds.includes(media.media_id)) {
            const preLoadMedia = new Image();
            preLoadMedia.src = media.media_url;
            this.preLoadedImageIds.push(media.media_id);
        }
        return media;
    }
}
