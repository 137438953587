/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * SportHttpResponse
 */
export interface AppHttpResponsesSportsDataSportHttpResponse { 
    /**
     * Sport Id
     */
    id: number;
    /**
     * Sport Desc
     */
    sport_desc: string;
    /**
     * Order
     */
    order: number;
    /**
     * Active
     */
    active: number;
    /**
     * Sport Icon
     */
    sport_icon: string;
    /**
     * Sport Translation
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

