import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {
    AuthenticationService, MyNotificationsService,
    TenantService,
    GeneralHelpers
} from '../../../shared';
import {
    AppHttpResponsesUsersPlayerVerificationHttpResponse as PlayerVerificationHttpResponse,
    SignupApi
} from '../../../api';
import {TranslateService} from '@ngx-translate/core';
import {NotificationType} from 'angular2-notifications';
import {take, takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-register-verify',
    templateUrl: './register-verify.component.html',
    styles: []
})
export class RegisterVerifyComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentClass = 'standardview';

    private componentAlive = true;
    private GeneralHelpers = new GeneralHelpers();

    showSpinner = true;
    alert = {
        type: '',
        messagetranslatekey: '',
        messagetxttranslatekey: ''
    };
    showAlert = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService,
                private tenantService: TenantService,
                private signupApi: SignupApi) {
    }

    ngOnInit() {

        if (this.authenticationService.validToken()) {
            this.router.navigate(['/arena']);
            return;
        } else if (!this.authenticationService.validToken() && this.tenantService.noB2cSignupMethod) {
            this.router.navigate(['/']);
            return;
        }

        this.activatedRoute.params
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((params: Params) => {
                this.showAlert = false;
                this.showSpinner = true;
                const verificationToken = params['verification_token'];
                this.verifyRegistration(verificationToken);
            });
    }

    verifyRegistration(verification_token: string) {

        this.signupApi.apiUsersSignupEmailVerifyVerificationTokenPut(
            verification_token
        ).pipe(take(1))
        .subscribe({
            next: (playerVerification: PlayerVerificationHttpResponse) => {
                this.translateService.get(['REGISTER.register_verify_successfully', 'REGISTER.register_verify_successfully_txt'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast(translation['REGISTER.register_verify_successfully'], translation['REGISTER.register_verify_successfully_txt'], NotificationType.Success);
                    });

                // login with verification login token
                this.authenticationService.loginTokenLogin(playerVerification.verification_login_token)
                    .pipe(take(1))
                    .subscribe({
                        next: () => {
                            if (playerVerification.verification_redirect_url) {
                                this.router.navigateByUrl(playerVerification.verification_redirect_url);
                                return;
                            } else if (localStorage.getItem('loginRedirect')) {
                                this.GeneralHelpers.doLoginRedirectIfDefined(this.router);
                                return;
                            } else {
                                this.router.navigate(['/arena']);
                                return;
                            }
                        },
                        error: (err: HttpErrorResponse) => {
                            this.displayAlert();
                        }
                    });
            },
            error: (err: HttpErrorResponse) => {
                this.displayAlert();
            }
        });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    private displayAlert() {
        this.alert = {
            type: 'error',
            messagetranslatekey: 'REGISTER.ERRORS.verify_failed',
            messagetxttranslatekey: 'REGISTER.ERRORS.verify_failed_txt'
        };

        this.showAlert = true;
        this.showSpinner = false;
    }

}
