import { Component, OnDestroy, OnInit, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse
} from '../../../../api';
import {takeWhile} from 'rxjs/operators';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    GameVendorGameParticipationStatusEnum,
    GoogleAnalyticsService
} from '../../../../shared';
import {Router} from '@angular/router';
import {SinglePlayerGameService} from '../../../../shared/services/singleplayer-game.service';

@Directive()
export abstract class ABetsnapdetailVendorGameParticipationsComponent implements OnInit, OnDestroy {

    public adPositions: number[] = [];

    protected componentAlive = true;

    public game: GameHttpResponse;

    public gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[] = null;
    public processGameVendorGameParticipations: boolean = true;

    protected constructor(protected betsnapdetailService: BetsnapdetailService,
                public authenticationService: AuthenticationService,
                protected singlePlayerGameService: SinglePlayerGameService,
                protected router: Router,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected adService: AdService) {

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (gameHttpResponse) {
                        if (!this.game && !this.authenticationService.currentUser || !gameHttpResponse.is_current_user_joined || gameHttpResponse.sport_id !== 999) {
                            this.router.navigate(['/betsnapdetail/' + gameHttpResponse.game_unique_id + '/details']);
                            return;
                        }

                        if (this.gameVendorGameParticipations === null) {
                            this.betsnapdetailService.getGameVendorGameParticipations(
                                gameHttpResponse.game_unique_id,
                                this.authenticationService.currentUser.id
                            );
                            this.singlePlayerGameService.getSinglePlayerParticipations(this.authenticationService.currentUser.id);
                        }
                    }
                    this.game = gameHttpResponse;
                }
            );

        this.betsnapdetailService.gameVendorGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                    if (gameVendorGameParticipations) {
                        if (!this.adPositions || (Array.isArray(this.adPositions) && this.adPositions.length === 0)) {
                            this.adPositions = this.adService.defineAdPosition('game-content', gameVendorGameParticipations.length);
                        }
                    }
                }
            );
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    public activeGameVendorGameParticipations() {
        return this.gameVendorGameParticipations.filter((gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) =>
            (
                (gameVendorGameParticipation.status === GameVendorGameParticipationStatusEnum.ACTIVE || gameVendorGameParticipation.status === GameVendorGameParticipationStatusEnum.INACTIVE)
            )
        );
    }

    public closedGameVendorGameParticipations() {
        const closedGameVendorGameParticipations = this.gameVendorGameParticipations.filter((gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) =>
            (
                gameVendorGameParticipation.status === GameVendorGameParticipationStatusEnum.CLOSED
            )
        );

        return closedGameVendorGameParticipations.sort(function (participation1, participation2) {
            if (participation1.id > participation2.id) {
                return -1;
            } else if (participation1.id < participation2.id) {
                return 1;
            } else {
                return 0;
            }
        });

    }

    public gameVendorGameParticipationsTrackBy(index: number, gameVendorGameParticipation: GameVendorGameParticipationHttpResponse): number {
        return gameVendorGameParticipation.id;
    }

}
