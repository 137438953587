import {Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MyTranslateService {

    private translationsLoadedSubject = new BehaviorSubject<boolean>(false);
    public translationsLoaded$ = this.translationsLoadedSubject.asObservable();

    public labelTranslations;
    public pageTitleTranslations;

    constructor(private translateService: TranslateService) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.labelTranslations = event.translations.GENERAL.LABELS;
            this.pageTitleTranslations = event.translations.ROUTEDATA.PAGETITLES;
        });
    }

    loadTranslations() {
        this.translateService.get(['GENERAL', 'ROUTEDATA'])
            .pipe(take(1)).subscribe((translations) => {
            if (translations) {
                if (typeof translations['GENERAL']['LABELS'] === 'object' && Object.keys(translations['GENERAL']['LABELS']).length > 0 &&
                    typeof translations['ROUTEDATA']['PAGETITLES'] === 'object' && Object.keys(translations['ROUTEDATA']['PAGETITLES']).length > 0) {
                    this.labelTranslations = translations['GENERAL']['LABELS'];
                    this.pageTitleTranslations = translations['ROUTEDATA']['PAGETITLES'];
                    this.translationsLoadedSubject.next(true);
                }
            }
        });
    }

}
