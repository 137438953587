/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesVendorGamesVendorGamePublicHttpResponse } from './appHttpResponsesVendorGamesVendorGamePublicHttpResponse';
import { AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse } from './appHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse';


/**
 * GameVendorGameParticipationHttpResponse
 */
export interface AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse { 
    /**
     * Game Vendor Game Participation Id
     */
    id: number;
    /**
     * Game Unique Id
     */
    game_unique_id: string;
    /**
     * Vendor Game Id
     */
    vendor_game_id: number;
    /**
     * User Id
     */
    user_id: number;
    /**
     * Entry Points
     */
    entry_points: number;
    /**
     * Game User Points
     */
    game_user_points: number;
    /**
     * Status
     */
    status: number;
    /**
     * Game Url
     */
    game_url?: string;
    /**
     * Game Url Expiration
     */
    game_url_expiration?: Date;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
    vendor_game?: AppHttpResponsesVendorGamesVendorGamePublicHttpResponse;
    /**
     * Game Vendor Game Participation Transactions
     */
    game_vendor_game_participation_transactions?: Array<AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse>;
}

