/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TeamJerseyHttpResponse
 */
export interface AppHttpResponsesSportsDataTeamJerseyHttpResponse { 
    /**
     * Team Id
     */
    team_id: number;
    /**
     * Jersey Type
     */
    type: string;
    /**
     * Base Color
     */
    base: string;
    /**
     * Number Color
     */
    number: string;
    /**
     * Sleeve Color
     */
    sleeve: string;
    /**
     * Shirt Type
     */
    shirt_type?: string;
    /**
     * Sleeve Detail Color
     */
    sleeve_detail?: string;
    /**
     * Squares
     */
    squares?: boolean;
    /**
     * Squares Color
     */
    squares_color?: string;
    /**
     * Stripes
     */
    stripes?: boolean;
    /**
     * Stripes Color
     */
    stripes_color?: string;
    /**
     * Horizontal Stripes
     */
    horizontal_stripes?: boolean;
    /**
     * Horizontal Stripes Color
     */
    horizontal_stripes_color?: string;
    /**
     * Split
     */
    split?: boolean;
    /**
     * Split Color
     */
    split_color?: string;
}

