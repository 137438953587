/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesSportsDataTeamHttpResponse } from './appHttpResponsesSportsDataTeamHttpResponse';
import { AppHttpResponsesSportsDataMatchMarketHttpResponse } from './appHttpResponsesSportsDataMatchMarketHttpResponse';
import { AppHttpResponsesSportsDataTournamentSeasonHttpResponse } from './appHttpResponsesSportsDataTournamentSeasonHttpResponse';
import { AppHttpResponsesSportsDataMatchScoreHttpResponse } from './appHttpResponsesSportsDataMatchScoreHttpResponse';
import { AppHttpResponsesSportsDataSportHttpResponse } from './appHttpResponsesSportsDataSportHttpResponse';
import { AppHttpResponsesSportsDataMatchTeamStatisticHttpResponse } from './appHttpResponsesSportsDataMatchTeamStatisticHttpResponse';
import { AppHttpResponsesSportsDataTournamentHttpResponse } from './appHttpResponsesSportsDataTournamentHttpResponse';


/**
 * MatchHttpResponse
 */
export interface AppHttpResponsesSportsDataMatchHttpResponse { 
    /**
     * Match Id
     */
    match_id: number;
    /**
     * Season Scheduled Date
     */
    season_scheduled_date: Date;
    /**
     * Scheduled End Date
     */
    scheduled_end_date: Date;
    /**
     * Sport Id
     */
    sport_id: number;
    /**
     * Category Id
     */
    category_id: number;
    /**
     * Tournament Id
     */
    tournament_id: number;
    /**
     * Home Id
     */
    home_id: number;
    /**
     * Home Name
     */
    home_name?: string;
    /**
     * Home Abbr
     */
    home_abbr?: string;
    /**
     * Away Id
     */
    away_id: number;
    /**
     * Away Name
     */
    away_name?: string;
    /**
     * Away Abbr
     */
    away_abbr?: string;
    /**
     * Status
     */
    status: string;
    /**
     * Liveodds
     */
    liveodds?: string;
    /**
     * Tournament Round Type
     */
    tournament_round_type?: string;
    /**
     * Tournament Round Number
     */
    tournament_round_number?: number;
    /**
     * Tournament Round Name
     */
    tournament_round_name?: string;
    /**
     * Tournament Round Group Name
     */
    tournament_round_group_name?: string;
    /**
     * Tournament Round Matches per Round Count
     */
    tournament_round_cup_round_matches?: number;
    /**
     * Tournament Round Match Number
     */
    tournament_round_cup_round_match_number?: number;
    /**
     * Tournament Round Other Match ID
     */
    tournament_round_other_match_id?: string;
    /**
     * Tournament Round Tournament Match Number
     */
    tournament_round_tournament_match_number?: number;
    /**
     * Tournament Round Betradar Id
     */
    tournament_round_betradar_id?: number;
    /**
     * Count all Markets
     */
    markets_count: number;
    /**
     * Simulation Original Match Id
     */
    simulation_original_match_id?: number;
    sport?: AppHttpResponsesSportsDataSportHttpResponse;
    tournament?: AppHttpResponsesSportsDataTournamentHttpResponse;
    season?: AppHttpResponsesSportsDataTournamentSeasonHttpResponse;
    home?: AppHttpResponsesSportsDataTeamHttpResponse;
    away?: AppHttpResponsesSportsDataTeamHttpResponse;
    score?: AppHttpResponsesSportsDataMatchScoreHttpResponse;
    home_statistics?: AppHttpResponsesSportsDataMatchTeamStatisticHttpResponse;
    away_statistics?: AppHttpResponsesSportsDataMatchTeamStatisticHttpResponse;
    default_market?: AppHttpResponsesSportsDataMatchMarketHttpResponse;
}

