import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse} from '../../../../api';
import {BetsnapdetailService, GoogleAnalyticsService} from '../../../../shared';
import {DOCUMENT} from '@angular/common';
import {PlaceBetDialogDefaultComponent} from '../../place-bet-dialog/place-bet-dialog-default/place-bet-dialog-default.component';
import {ASpecialMarketComponent} from '../a-special-market-component';

@Component({
    selector: 'betsnaps-special-market-default',
    templateUrl: './special-market-default.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialMarketDefault extends ASpecialMarketComponent implements OnChanges {

    @Input() marketOpen: boolean = false;

    public placeBetDialogVisible: boolean = false;
    public placeBetOutcome: MatchMarketOutcomeHttpResponse;
    public placeBetAnimationClass: string;

    @ViewChild('placeBetDialogWrap', {static: true}) public placeBetDialogWrap: ElementRef;
    @ViewChild('placeBetDialog') public placeBetDialog: PlaceBetDialogDefaultComponent;
    @ViewChild('marketAccordionContent', {static: true}) public marketAccordionContent: ElementRef;
    private currentOutcomeRowId: string;

    constructor(@Inject(DOCUMENT) private document: any,
                private betsnapdetailService: BetsnapdetailService,
                public googleAnalyticsService: GoogleAnalyticsService,
                private cdr: ChangeDetectorRef,
                private renderer: Renderer2) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.betPlacementAllowed = this.betsnapdetailService.isBetPlacementAllowed(this.match);

        if (changes.market) {
            if (this.placeBetOutcome) {
                const findCurrentPlaceBetOutcome = changes.market.currentValue.outcomes.find(
                    o => o.outcome_id === this.placeBetOutcome.outcome_id
                );
                if (findCurrentPlaceBetOutcome) {
                    this.placeBetOutcome = findCurrentPlaceBetOutcome;
                } else {
                    this.closePlaceBetDialog();
                }
            }
            this.createMarketOutcomesArray();
        }

        // remove and reset bet placement dialog if user not joined
        if (!this.game.is_current_user_joined) {
            this.placeBetAnimationClass = null;
            this.placeBetOutcome = null;
            this.placeBetDialogVisible = false;
        }
    }

    togglePlaceBetDialog(outcome: MatchMarketOutcomeHttpResponse, outcomeRowId: string = null) {
        if (this.placeBetDialogVisible &&
            this.placeBetOutcome.market_id === outcome.market_id &&
            this.placeBetOutcome.outcome_id === outcome.outcome_id &&
            this.placeBetOutcome.specifier_val === outcome.specifier_val) {
            this.closePlaceBetDialog(true);
        } else {
            this.openPlaceBetDialog(outcome, outcomeRowId);
        }
    }

    openPlaceBetDialog(outcome: MatchMarketOutcomeHttpResponse, outcomeRowId: string = null) {
        if (this.currentUser &&
            this.game.is_current_user_joined &&
            this.betPlacementAllowed &&
            (this.game.max_bet_count === 0 || (this.game.max_bet_count > 0 && this.game.current_user_bet_count < this.game.max_bet_count)) &&
            this.game.game_user.user_game_points > 0) {

            let outcomeRow: ElementRef;
            if (outcomeRowId) {
                outcomeRow = this.document.getElementById(outcomeRowId);
            }

            if (!this.placeBetDialogVisible) {
                this.onPlaceBetClick.emit();
            } else {
                setTimeout(() => {
                    this.placeBetDialog.centerDialogInView();
                }, 50);
            }

            this.placeBetOutcome = outcome;
            this.placeBetDialogVisible = true;
            if (outcomeRow) {
                if (this.currentOutcomeRowId !== outcomeRowId) {
                    this.currentOutcomeRowId = outcomeRowId;
                    this.renderer.appendChild(outcomeRow, this.placeBetDialogWrap.nativeElement);
                }
            }
            setTimeout(() => {
                if (!this.placeBetAnimationClass) {
                    this.placeBetAnimationClass = 'visible';
                    this.doChangeDetection();
                }
            }, 50);

        }
    }

    closePlaceBetDialog(animated: boolean = false) {
        if (this.placeBetDialogVisible) {
            if (animated) {
                this.placeBetAnimationClass = null;
                setTimeout(() => {
                    this.placeBetDialogVisible = false;
                    this.placeBetOutcome = null;
                    this.currentOutcomeRowId = null;
                    this.doChangeDetection();
                }, 500);
            } else {
                this.placeBetAnimationClass = null;
                this.placeBetDialogVisible = false;
                this.currentOutcomeRowId = null;
                this.placeBetOutcome = null;
            }
            this.doChangeDetection();
        }
    }

    onMarketOpenChange() {
        // close place bet dialog if accordion is closed
        if (!this.marketOpen) {
            this.closePlaceBetDialog();
        }
    }

    doChangeDetection() {
        this.cdr.markForCheck();
    }

}
