/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * GameFillingDegreeHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsGameFillingDegreeHttpResponse { 
    /**
     * Week of Year
     */
    week_of_year: number;
    /**
     * Average Snap size
     */
    avg_snap_size: number;
    /**
     * Average Snap size
     */
    filling_degree_in_percent: number;
}

