/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MostSuccessfulPlayersHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsMostSuccessfulPlayersHttpResponse { 
    /**
     * User Id
     */
    user_id: number;
    /**
     * B2b User Id
     */
    b2b_user_id: string;
    /**
     * Username
     */
    username: string;
    /**
     * User finished inside Prize Ranks Count
     */
    finished_inside_prizeranks_count: number;
    /**
     * Participation Count
     */
    game_participation_count: number;
    /**
     * User Game wins
     */
    game_wins_count: number;
    /**
     * User Cash Prize Sum
     */
    cash_prizes_sum: number;
    /**
     * Average played Game Sizes
     */
    avg_played_game_sizes: number;
}

