import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TenantService} from '../../../../shared';


@Component({
    selector: 'betsnaps-phone-field',
    templateUrl: './phone-field.component.html',
    styles: []
})
export class PhoneFieldComponent {

    @Input() processForm: boolean;
    @Input() form: UntypedFormGroup;
    @Input() phoneSignupAllowedCountryCodes;

    public componentTemplateToLoad: string;

    constructor(private tenantService: TenantService
    ) {
        this.componentTemplateToLoad = this.tenantService.componentTemplateToLoad;
    }

}
