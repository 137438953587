import {Component, ElementRef, HostBinding, ViewChild} from '@angular/core';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {
    AuthenticationService,
    CreateSnapService,
    ErrorService,
    FriendsService,
    GoogleAnalyticsService,
    MyModalService,
    MyTranslateService,
    TenantImageTypeEnum,
    TenantService
} from '../../../../shared';
import {Router} from '@angular/router';
import {AUserAvatarComponent} from '../a-user-avatar-component';
import {PlayerService} from '../../../../shared/services/player.service';

@Component({
    selector: 'betsnaps-user-avatar-v3',
    templateUrl: './user-avatar-v3.component.html',
    styles: []
})
export class UserAvatarV3Component extends AUserAvatarComponent {
    @HostBinding('class') componentClass = 'display-inline-block';

    @ViewChild('inviteMessage') public inviteMessage: ElementRef;
    @ViewChild('userProfileModalTemplate', {static: true}) public userProfileModalTemplate: ModalTemplate<null, string, string>;

    public profileHeaderImage: string;

    constructor(public translations: MyTranslateService,
                protected myModalService: MyModalService,
                protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected friendsService: FriendsService,
                protected createSnapService: CreateSnapService,
                protected playerService: PlayerService,
                protected errorService: ErrorService,
                protected router: Router) {
        super(
            translations,
            myModalService,
            authenticationService,
            tenantService,
            googleAnalyticsService,
            friendsService,
            createSnapService,
            playerService,
            errorService,
            router
        );

        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER)) {
            this.profileHeaderImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER).media_url;
        }

    }

    protected openUserProfileModal() {
        if (!this.userProfileModal) {
            const userProfileModalConfig = new TemplateModalConfig<null, string, string>(this.userProfileModalTemplate);
            userProfileModalConfig.size = ModalSize.Tiny;
            this.userProfileModal = this.myModalService.openModal(userProfileModalConfig)
                .onApprove(() => {
                    this.userProfileModal = null;
                }).onDeny(() => {
                    this.userProfileModal = null;
                });
        }
    }

}
