/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesNotificationsNotificationHttpResponse } from './appHttpResponsesNotificationsNotificationHttpResponse';


/**
 * UserNotificationHttpResponse
 */
export interface AppHttpResponsesNotificationsUserNotificationHttpResponse { 
    /**
     * User-Notification ID
     */
    id: number;
    /**
     * User ID
     */
    user_id: number;
    /**
     * Game Unique ID
     */
    game_unique_id?: string;
    /**
     * Tenant Ranking ID
     */
    tenant_ranking_id?: number;
    /**
     * Notification ID
     */
    master_notification_id: number;
    /**
     * is already read
     */
    is_read: boolean;
    /**
     * Landing page relative path
     */
    target_relative_path?: string;
    /**
     * Landing page url
     */
    target_url?: string;
    /**
     * Replacement-Variables for the message
     */
    notification_variables_json: string;
    notification?: AppHttpResponsesNotificationsNotificationHttpResponse;
    /**
     * Created Date
     */
    created_at?: Date;
    /**
     * Updated Date
     */
    updated_at?: Date;
}

