<header id="header"
        class="ui top fixed shadowless menu main-menu"
        [class.authenticated]="(isAuthenticated$ | async)"
        [class.biglogoheader]="bigLogoHeader">
    <div class="ui container">
        <div class="header borderless item">
            <a (click)="onLogoClick()" class="ui link header-tenant-logo">
                <img
                    [src]="(tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO)) ?
                    tenantService.getTenantImageMediaTranslationForLanguage(tenantImageTypeEnum.LOGO).media_url :
                     './assets/img/betsnap_logo.png'"
                    alt="{{tenantService.tenantData?.name}} Logo"/>
            </a>
        </div>
        <nav class="ui secondary labeled shadowless borderless icon mini flexauto menu tablet or lower hidden" *ngIf="!bigLogoHeader">
            <a routerLink="/arena" [routerLinkActive]="['active']" class="item">
                <i class="icon bsi bsi-arena"></i>
                <span class="menuitem-label">{{'GENERAL.HEADER.MENU.arena' | translate}}</span>
            </a>
            <a routerLink="/ranking" [routerLinkActive]="['active']" class="item"
               *ngIf="tenantService.tenantData?.configuration?.show_global_rankings">
                <i class="fa fa-trophy icon"></i>
                <span class="menuitem-label">{{'GENERAL.HEADER.MENU.ranking' | translate}}</span>
            </a>
            <a routerLink="/friends" [routerLinkActive]="['active']" class="item"
               *ngIf="(isAuthenticated$ | async)">
                <i class="group icon"></i>
                <span class="menuitem-label">{{'GENERAL.HEADER.MENU.friends' | translate}}</span>
                <div class="floating ui tiny circular calltoaction label"
                     *ngIf="(unreadFriendsCount$ | async) > 0">
                    {{unreadFriendsCount$ | async}}
                </div>
            </a>
            <a routerLink="/news" [routerLinkActive]="['active']" class="item"
               *ngIf="(isAuthenticated$ | async)">
                <i class="notifications icon"></i>
                <span class="menuitem-label">{{'GENERAL.HEADER.MENU.news' | translate}}</span>
                <div class="floating ui tiny circular calltoaction label"
                     *ngIf="(unreadNewsCount$ | async) > 0">
                    {{unreadNewsCount$ | async}}
                </div>
            </a>
        </nav>
        <div class="right separatorless menu" *ngIf="!bigLogoHeader">
            <div class="headerauth">
                <a class="vertically fitted center aligned borderless item"
                   *ngIf="!(isAuthenticated$ | async)"
                   (click)="sideNavService.toggleSideProfileMenu()">
                    <i class="menu huge icon margin-0"></i>
                </a>
                <a class="vertically fitted borderless item user-item"
                   *ngIf="(isAuthenticated$ | async) && currentUser"
                   (click)="sideNavService.toggleSideProfileMenu()"
                   [@walletValueChangeAnimation]="walletValueChangeState">
                    <span class="display-inline-block vertical-align-middle right aligned" *ngIf="tenantService.tenantData?.configuration?.show_balance">
                        {{'GENERAL.LABELS.balance' | translate}}<br/>
                        <strong>{{currentUser.wallets[0].balance | number:'1.2-2' | currencyFormat:currentUser.wallets[0].currency.symbol }}</strong>
                    </span>
                    <img class="ui middle aligned circular bordered secondary image profileimg bg-white"
                         [ngClass]="{'left spaced': tenantService.tenantData?.configuration?.show_balance}"
                         [src]="currentUser.media_url_small" [alt]="" />
                </a>
            </div>
        </div>
    </div>
</header>
<section class="login-register-bar" *ngIf="!(isAuthenticated$ | async) && !bigLogoHeader">
    <div class="ui centered relaxed middle aligned grid container">
        <div class="row">
            <div class="eight wide column">
                {{'GENERAL.LABELS.login_register_bar_text' | translate}}
            </div>
            <div class="eight wide column right aligned">
                <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                        (click)="myModalService.openLoginRegisterModal('login', router.url, false, 'header')"
                        class="ui inverted small button margin-0">
                    {{'GENERAL.LABELS.login_btn' | translate}}
                </button>
                <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteLoginUrl()"
                        (click)="goToB2bLogin()"
                        class="ui inverted small button margin-0">
                    {{'GENERAL.LABELS.login_btn' | translate}}
                </button>
                <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                        (click)="myModalService.openLoginRegisterModal('register', router.url, false, 'header')"
                        class="ui inverted small button margin-l-5 margin-r-0">
                    {{'GENERAL.LABELS.register_btn' | translate}}
                </button>
                <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteRegisterUrl()"
                        (click)="goToB2bRegister()"
                        class="ui inverted small button margin-l-5 margin-r-0">
                    {{'GENERAL.LABELS.register_btn' | translate}}
                </button>
            </div>
        </div>
    </div>
</section>
<nav class="ui bottom fixed shadowless borderless labeled icon mini center aligned flexauto menu computer or higher hidden main-menu"
     *ngIf="!bigLogoHeader">
    <a routerLink="/arena" [routerLinkActive]="['active']" class="item">
        <i class="icon bsi bsi-arena"></i>
        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.arena' | translate}}</span>
    </a>
    <a routerLink="/ranking" [routerLinkActive]="['active']" class="item"
       *ngIf="tenantService.tenantData?.configuration?.show_global_rankings">
        <i class="fa fa-trophy icon"></i>
        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.ranking' | translate}}</span>
    </a>
    <a routerLink="/friends" [routerLinkActive]="['active']" class="item"
       *ngIf="(isAuthenticated$ | async)">
        <i class="group icon"></i>
        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.friends' | translate}}</span>
        <div class="floating ui tiny circular calltoaction label"
             *ngIf="(unreadFriendsCount$ | async) > 0">
            {{unreadFriendsCount$ | async}}
        </div>
    </a>
    <a routerLink="/news" [routerLinkActive]="['active']" class="item"
       *ngIf="(isAuthenticated$ | async)">
        <i class="notifications icon"></i>
        <span class="menuitem-label">{{'GENERAL.HEADER.MENU.news' | translate}}</span>
        <div class="floating ui tiny circular calltoaction label"
             *ngIf="(unreadNewsCount$ | async) > 0">
            {{unreadNewsCount$ | async}}
        </div>
    </a>
</nav>

<nav class="side-profile-menu-wrap" id="side-profile-menu-wrap"
     [ngClass]="{'visible': sideNavService.sideProfileMenuVisible}">
    <div class="ui basic center aligned padded segment margin-0">
        <div class="ui grid middle aligned container margin-t-0 margin-b-0" *ngIf="(isAuthenticated$ | async) && authenticationService.currentUser">
            <div class="six wide column left aligned padding-l-0">
                <img class="ui medium circular bordered secondary image" [src]="authenticationService.currentUser.media_url_big" [alt]="" />
            </div>
            <div class="ten wide column left aligned padding-r-0">
                <div class="truncate"><strong>{{authenticationService.currentUser.username}}</strong></div>
                <div *ngIf="tenantService.tenantData?.configuration?.show_balance" class="ui currency label margin-l-0 margin-b-0">
                    <span class="currency-wrap">
                        <span class="currency-symbol"
                              [class.longsymbol]="authenticationService.currentUser.wallets[0].currency.symbol.length > 1">
                            {{authenticationService.currentUser.wallets[0].currency.symbol}}
                        </span>
                    </span>
                    <span class="detail">{{authenticationService.currentUser.wallets[0].balance | number:'1.2-2' }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="!(isAuthenticated$ | async)" (click)="sideNavService.toggleSideProfileMenu()">
            <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                    (click)="myModalService.openLoginRegisterModal('login', router.url, false, 'header')"
                    class="ui fluid button">
                {{'HOME.login_btn' | translate | uppercase}}
            </button>
            <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteLoginUrl()"
                    (click)="goToB2bLogin()"
                    class="ui fluid button">
                {{'HOME.login_btn' | translate | uppercase}}
            </button>
            <button type="button" *ngIf="!tenantService.noB2cSignupMethod"
                    (click)="myModalService.openLoginRegisterModal('register', router.url, false, 'header')"
                    class="ui fluid button margin-t-10">
                {{'GENERAL.LABELS.register_btn' | translate}}
            </button>
            <button type="button" *ngIf="tenantService.noB2cSignupMethod && tenantService.getB2bWebsiteRegisterUrl()"
                    (click)="goToB2bRegister()"
                    class="ui fluid button margin-t-10">
                {{'GENERAL.LABELS.register_btn' | translate}}
            </button>
        </div>
    </div>
    <div class="ui divider margin-0"></div>
    <div class="ui vertical fluid menu margin-0" (click)="sideNavService.toggleSideProfileMenu()">
        <a *ngIf="(isAuthenticated$ | async) && authenticationService.currentUser" [routerLink]="['/profile']" class="item">
            <span class="menu-item-image">
                <i class="person icon margin-0"></i>
            </span><span class="menu-item-text">
                {{'GENERAL.HEADER.MENU.profile' | translate}}
            </span>
        </a>
        <div *ngIf="!tenantFooterListResults" class="ui vertical borderless very padded segment">
            <div class="ui active centered inline text loader"></div>
        </div>
        <div *ngIf="tenantFooterListResults && tenantFooterListResults.length > 0">
            <div *ngFor="let footeritem of tenantFooterListResults">
                <a class="item"
                   [href]="footeritem.landing_page_url | dbtranslation:'landing_page_url':footeritem.translations"
                   target="_blank"
                   rel="noopener noreferrer"
                   *ngIf="footeritem.landing_page_url && (footeritem.is_landing_page_external && !isMailToLink(footeritem.landing_page_url))">
                    <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                        <i [ngClass]="footeritem.icon_class + 'icon margin-0'" *ngIf="footeritem.icon_class && !footeritem.media"></i>
                        <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media" />
                    </span><span class="menu-item-text">
                        {{footeritem.label | dbtranslation:'label':footeritem.translations}}
                    </span>
                </a>
                <a class="item"
                   [routerLink]="[footeritem.landing_page_url]"
                   *ngIf="footeritem.landing_page_url &&  (!footeritem.is_landing_page_external && !isMailToLink(footeritem.landing_page_url))">
                    <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                        <i [ngClass]="footeritem.icon_class + ' icon margin-0'" *ngIf="footeritem.icon_class && !footeritem.media"></i>
                        <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media" />
                    </span><span class="menu-item-text">
                        {{footeritem.label | dbtranslation:'label':footeritem.translations}}
                    </span>
                </a>
                <a class="item"
                   [href]="footeritem.landing_page_url | dbtranslation:'landing_page_url':footeritem.translations"
                   *ngIf="footeritem.landing_page_url && isMailToLink(footeritem.landing_page_url)">
                    <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                        <i [ngClass]="footeritem.icon_class + ' icon margin-0'" *ngIf="footeritem.icon_class && !footeritem.media"></i>
                        <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media" />
                    </span><span class="menu-item-text">
                        {{footeritem.label | dbtranslation:'label':footeritem.translations}}
                    </span>
                </a>
                <span class="item nolink-item" *ngIf="!footeritem.landing_page_url">
                    <span class="menu-item-image" *ngIf="footeritem.media || footeritem.icon_class">
                        <i [ngClass]="footeritem.icon_class + ' icon margin-0'" *ngIf="footeritem.icon_class && !footeritem.media"></i>
                        <img [src]="footeritem.media.media_url" alt="" *ngIf="footeritem.media" />
                    </span><span class="menu-item-text">
                        {{footeritem.label | dbtranslation:'label':footeritem.translations}}
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="ui vertical center aligned padded segment"
         *ngIf="(isAuthenticated$ | async) && this.authenticationService.currentUser">
        <button type="button" class="ui large calltoaction2 button margin-0"
                (click)="logout()">
            {{'PROFILE.OVERVIEW.logout_btn' | translate | uppercase}}
        </button>
    </div>
    <p class="tiny margin-t-15 margin-b-15 center aligned">
        {{'GENERAL.FOOTER.copyright' | translate}} {{currYear}} BetSnap
    </p>
</nav>
<div class="ui active page dimmer"
     *ngIf="sideNavService.sideProfileMenuVisible"
     (click)="sideNavService.toggleSideProfileMenu()">
</div>

