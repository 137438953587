<div class="ui centered relaxed grid container margin-t-0 margin-b-0">
    <div class="row padding-0">
        <betsnaps-left-sidebar-loader class="four wide tablet or lower hidden column sidebar left-sidebar"></betsnaps-left-sidebar-loader>
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div class="ui vertical borderless very padded segment" *ngIf="processTenantRankingLists">
                <div class="ui active centered inline text loader on-body-bg">
                    {{'GENERAL.loadertext' | translate}}
                </div>
            </div>
            <div *ngIf="!processTenantRankingLists">
                <div class="ui grid container three column margin-b-15 margin-t-0 button-menu" *ngIf="tenantRankingVisible?.length > 1 || historyTenantRankingList.total > 0">
                    <div class="column padding-0" *ngFor="let tenantRanking of tenantRankingVisible; let i = index">
                        <button class="ui ranking-menu-button button fluid truncate"
                                [class.border-radius-right]="(i + 1) % 3 == 0 || (historyTenantRankingList.total < 1 && tenantRankingList.total < 3 && (i + 1) % 2 == 0)"
                                [class.border-radius-left]="(i + 1) == 1 || (i + 1) % 3 == 1"
                                title="{{tenantRanking.title | dbtranslation:'title':tenantRanking.translations | uppercase}}"
                                (click)="selectTenantRankingId(tenantRanking.id)" [class.active]="currentTenantRankingId === tenantRanking.id">
                            {{tenantRanking.title | dbtranslation:'title':tenantRanking.translations | uppercase}}
                        </button>
                    </div>
                    <div class="column padding-0" *ngIf="historyTenantRankingList.total > 0 && (tenantRankingsAllVisible || tenantRankingList.total < 3)">
                        <button class="ui ranking-menu-button button fluid truncate"
                                [class.border-radius-left]="tenantRankingList.total < 1 || tenantRankingVisible.length % 3 === 0"
                                [class.border-radius-right]="tenantRankingList.total < 3 || tenantRankingVisible.length % 5 === 0"
                                title="{{'RANKING.MENU.history' | translate | uppercase}}"
                                (click)="selectTenantRankingId(0)" [class.active]="currentTenantRankingId === 0">
                            {{'RANKING.MENU.history' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="column padding-0" *ngIf="(
                            (tenantRankingList.total > 3 && historyTenantRankingList.total < 1) ||
                            (tenantRankingList.total > 2 && historyTenantRankingList.total > 0)
                        ) && !tenantRankingsAllVisible">
                        <button class="ui ranking-menu-button button fluid truncate border-radius-right"
                                (click)="getButtonsForTenantRankings(!tenantRankingsAllVisible)">
                            {{'RANKING.show_more' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="column padding-0" *ngIf="tenantRankingsAllVisible">
                        <button class="ui ranking-menu-button button fluid truncate border-radius-right"
                                [class.border-radius-left]="(tenantRankingVisible.length % 3 === 0 && historyTenantRankingList.total < 1) || (tenantRankingVisible.length % 5 === 0 && historyTenantRankingList.total > 0)"
                                (click)="getButtonsForTenantRankings(!tenantRankingsAllVisible)">
                            {{'RANKING.show_less' | translate | uppercase}}
                        </button>
                    </div>
                </div>
                <div class="ui vertical borderless very padded segment" *ngIf="processTenantRankingLists">
                    <div class="ui active centered inline text loader on-body-bg">
                        {{'GENERAL.loadertext' | translate}}
                    </div>
                </div>
                <div *ngIf="!processTenantRankingLists">

                    <div *ngIf="historyTenantRankingList && showHistoryList">
                        <h2 class="text-on-body-bg" *ngIf="historyTenantRankingList.total > 0">
                            {{historyTenantRankingList.total}} {{historyTenantRankingList.total | i18nPlural:translations.labelTranslations.rankingplural}}
                        </h2>

                        <div class="ui centered grid margin-r-0" *ngIf="historyTenantRankingList.results.length > 0">
                            <div class="sixteen wide column"
                                 *ngFor="let tenantRanking of historyTenantRankingList.results">
                                <div class="ui fluid card ranking-card link center aligned"
                                     (click)="selectTenantRankingId(tenantRanking.id)">
                                    <div class="content">
                                        <div class="image">
                                            <div *ngIf="tenantRanking.user_rank"
                                                 class="ui calltoaction right ribbon label text-large">
                                                {{tenantRanking.user_rank | ordinalNumber}}
                                            </div>
                                        </div>
                                        <div class="header margin-b-0">
                                            <h4 class="margin-b-0">
                                                {{tenantRanking.title | dbtranslation:'title':tenantRanking.translations | uppercase}}
                                            </h4>
                                        </div>
                                        <div class="text-small color-grey margin-t-0 margin-b-5" *ngIf="tenantRanking.from_date || tenantRanking.to_date">
                                            <span *ngIf="tenantRanking.from_date && tenantRanking.to_date">{{tenantRanking.from_date | amLocal | amDateFormat:'DD.MM.YYYY'}} <span *ngIf="(tenantRanking.from_date | amDateFormat:'DD.MM.YYYY') !== (tenantRanking.to_date | amDateFormat:'DD.MM.YYYY')">- {{tenantRanking.to_date | amLocal | amDateFormat:'DD.MM.YYYY'}}</span></span>
                                            <span *ngIf="tenantRanking.from_date && !tenantRanking.to_date">{{'RANKING.from' | translate}} {{tenantRanking.from_date | amLocal | amDateFormat:'DD.MM.YYYY'}}</span>
                                            <span *ngIf="!tenantRanking.from_date && tenantRanking.to_date">{{'RANKING.to' | translate}} {{tenantRanking.to_date | amLocal | amDateFormat:'DD.MM.YYYY'}}</span>
                                        </div>
                                        <div class="text-medium center aligned">
                                            <p class="font-bold margin-0">
                                                {{tenantRanking.ranking_positions_total}} {{tenantRanking.ranking_positions_total | i18nPlural:translations.labelTranslations.playerplural}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui basic segment center aligned"
                                 *ngIf="historyTenantRankingList.results.length < historyTenantRankingList.total"
                                 #loadMoreButtonTenantRankings>
                                <button type="button"
                                        class="ui button margin-0"
                                        [class.loading]="processLoadMoreHistory"
                                        (click)="loadMoreHistory()">
                                    {{'GENERAL.load-more_btn' | translate}}
                                </button>
                            </div>
                        </div>
                        <div class="ui vertical borderless center aligned segment padding-t-0"
                             *ngIf="historyTenantRankingList.total < 1">
                            <p class="text-on-body-bg">
                                {{'GENERAL.no_results_found' | translate}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="!showHistoryList && (tenantRankingList.total > 0 || historyTenantRankingList.total > 0)">

                        <div class="ui vertical borderless very padded segment" *ngIf="processTenantRanking">
                            <div class="ui active centered inline text loader on-body-bg">
                                {{'GENERAL.loadertext' | translate}}
                            </div>
                        </div>

                        <div *ngIf="!processTenantRanking && currentTenantRanking">
                            <div class="ui segment top attached center aligned ranking-header">
                                <h2 class="ui header font-very-bold padding-t-10 padding-r-10 padding-b-5 padding-l-10 margin-0 text-big">
                                    {{currentTenantRanking.title | dbtranslation:'title':currentTenantRanking.translations | uppercase}}
                                    <span class="sub header" *ngIf="currentTenantRanking.description">{{currentTenantRanking.description | dbtranslation:'description':currentTenantRanking.translations}}</span>
                                </h2>
                                <div [ngClass]="{'banner-img': hasRankingBannerImg(currentTenantRanking), 'margin-t-5' : hasRankingBannerImg(currentTenantRanking)}"
                                     [ngStyle]="{'background-image': (hasRankingBannerImg(currentTenantRanking)) ? getRankingBannerImg(currentTenantRanking) : 'none'}">
                                    <div class="ui basic list" *ngIf="(checkIfHasAFromOrToDate(currentTenantRanking) || checkIfHasLastRecalculationDate(currentTenantRanking))" [ngClass]="{'bg-dimmed': hasRankingBannerImg(currentTenantRanking)}">
                                        <div class="item" *ngIf="checkIfHasAFromOrToDate(currentTenantRanking)">
                                            <p class="margin-b-0" *ngIf="(currentTenantRanking.from_date && currentTenantRanking.to_date)">
                                                {{currentTenantRanking.from_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }} - {{currentTenantRanking.to_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                            <p class="margin-b-0" *ngIf="(currentTenantRanking.from_date && !currentTenantRanking.to_date)">
                                                {{'RANKING.from' | translate}} {{currentTenantRanking.from_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                            <p class="margin-b-0" *ngIf="(!currentTenantRanking.from_date && currentTenantRanking.to_date)">
                                                {{'RANKING.to' | translate}} {{currentTenantRanking.to_date | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                            <p class="font-bold text-primary"
                                               *ngIf="(currentTenantRanking.to_date && currentTenantRanking.ranking_state < 6 && getDaysLeft(currentTenantRanking.to_date) > 1 && getDaysLeft(currentTenantRanking.to_date) <= 7)">
                                                {{'RANKING.days_left' | translate:{days: (getDaysLeft(currentTenantRanking.to_date))} | uppercase }}
                                            </p>
                                            <p class="font-bold text-primary"
                                               *ngIf="(currentTenantRanking.to_date && currentTenantRanking.ranking_state < 6 && getDaysLeft(currentTenantRanking.to_date) === 1)">
                                                {{'RANKING.day_left' | translate:{days: (getDaysLeft(currentTenantRanking.to_date))} | uppercase }}
                                            </p>
                                        </div>
                                        <div *ngIf="checkIfHasLastRecalculationDate(currentTenantRanking)" class="item">
                                            <p class="text-tiny">{{'RANKING.last_recalculation' | translate}}: {{currentTenantRanking.last_recalculation_at | amLocal | amCalendar:
                                                {
                                                    sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                                    nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                                    lastDay: tenantService.tenantData.internationalization.date_time_format,
                                                    lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                                    sameElse: tenantService.tenantData.internationalization.date_time_format
                                                }
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="content-img" *ngIf="currentTenantRanking.contentimg" [ngClass]="{'margin-t-10': !hasRankingBannerImg(currentTenantRanking), 'margin-t-0': hasRankingBannerImg(currentTenantRanking), 'border-top':hasRankingBannerImg(currentTenantRanking)}">
                                    <img class="ui image fluid" src="{{currentTenantRanking.contentimg}}"/>
                                </div>
                            </div>
                            <div class="ui segment bottom attached">
                                <div class="margin-b-15"
                                     *ngIf="currentTenantRanking.user_friends_ranking_positions_count > 0">
                                    <div class="ui fluid flexauto item tiny separatorless menu">
                                        <a href="#" class="item" [class.active]="menu.active"
                                           *ngFor="let menu of rankingMenu"
                                           (click)="$event.preventDefault(); rankingMenuChange(menu.key);">
                                            <span class="display-inline-block vertical-align-middle">
                                                {{menu.translatekey | translate | uppercase}}
                                            </span>
                                            <span class="display-inline-block vertical-align-middle text-mini">&nbsp;{{
                                                (menu.key === 'all' && currentTenantRanking.ranking_positions_total) ? '(' + currentTenantRanking.ranking_positions_total + ')' : ''
                                                }}{{
                                                (menu.key === 'friends' && currentTenantRanking.user_friends_ranking_positions_count > 0) ? '(' + currentTenantRanking.user_friends_ranking_positions_count + ')' : ''
                                                }}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div class="ui vertical borderless very padded segment" *ngIf="processTenantRankingPositions">
                                    <div class="ui active centered inline text loader on-body-bg">
                                        {{'GENERAL.loadertext' | translate}}
                                    </div>
                                </div>
                                <div *ngIf="!processTenantRankingPositions">
                                    <div class="ui divided list leaderboard-list" *ngIf="tenantRankingPositionsList.total > 0">
                                        <div class="item leaderboard-list-item currentuser-on-top"
                                             *ngIf="currentTenantRanking.user_ranking_position?.rank &&
                                            ((!showOnlyFriends && currentTenantRanking.user_ranking_position.rank > 1) || showOnlyFriends)">
                                            <div class="ui middle aligned centered grid container padding-b-5">
                                                <div class="row">
                                                    <div class="three wide column center aligned">
                                                        <strong [ngClass]="
                                                            {
                                                                'text-huge': currentTenantRanking.user_ranking_position.rank < 1000,
                                                                'text-large': currentTenantRanking.user_ranking_position.rank > 999 && currentTenantRanking.user_ranking_position.rank < 10000,
                                                                'text-tiny': currentTenantRanking.user_ranking_position.rank > 9999
                                                            }
                                                        ">
                                                            {{currentTenantRanking.user_ranking_position.rank}}
                                                        </strong>
                                                    </div>
                                                    <div class="six wide left aligned column padding-r-0 truncate">
                                                        <betsnaps-user-avatar-loader
                                                            class="display-inline-block vertical-align-middle"
                                                            [user]="currentTenantRanking.user_ranking_position.user"
                                                            [imageSize]="'tiny'"
                                                            [addClass]="'right spaced profileimg'">
                                                        </betsnaps-user-avatar-loader>
                                                        <strong>{{currentTenantRanking.user_ranking_position.user.username}}</strong>
                                                    </div>
                                                    <div class="seven wide column right aligned">
                                                        <strong class="large">
                                                            {{currentTenantRanking.user_ranking_position?.points | number:'1.2-2'}}
                                                            {{'GENERAL.GAMES.POINTS.abbr_points' | translate}}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div class="row padding-t-0"
                                                     *ngIf="(currentTenantRanking.user_ranking_position?.tenant_prize || currentTenantRanking.user_ranking_position?.tenant_prize_template || (currentTenantRanking.user_ranking_position?.prize_amount !== null && currentTenantRanking.user_ranking_position?.prize_amount !== 0))">
                                                    <div class="three wide column center aligned">
                                                    </div>
                                                    <div class="eleven wide left aligned column hyphenate">
                                                        <div class="mini">{{'GENERAL.GAMES.current_prize' | translate}}</div>
                                                        <strong *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index !== null)">
                                                            {{currentTenantRanking.user_ranking_position.prize_amount | decimalPrize: 2 |
                                                            currencyFormat:currentTenantRanking.prize_structures[currentTenantRanking.primary_cash_prize_structure_index].currency.symbol}}
                                                        </strong>
                                                        <strong *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index === null)">
                                                            {{currentTenantRanking.user_ranking_position.prize_amount | decimalPrize: 2}}
                                                        </strong>
                                                        <strong *ngIf="currentTenantRanking.user_ranking_position.tenant_prize">
                                                            <span *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0)"> + </span>{{currentTenantRanking.user_ranking_position.tenant_prize.title |
                                                            dbtranslation:'title':currentTenantRanking.user_ranking_position.tenant_prize.translations}}
                                                        </strong>
                                                        <strong *ngIf="!currentTenantRanking.user_ranking_position.tenant_prize && currentTenantRanking.user_ranking_position.tenant_prize_template">
                                                            <span *ngIf="(currentTenantRanking.user_ranking_position.prize_amount !== null && currentTenantRanking.user_ranking_position.prize_amount !== 0)"> + </span>{{currentTenantRanking.user_ranking_position.tenant_prize_template.title |
                                                            dbtranslation:'title':currentTenantRanking.user_ranking_position.tenant_prize_template.translations}}
                                                        </strong>
                                                    </div>
                                                    <div class="two wide column right aligned">
                                                        <img [src]="'./assets/img/prizes-rank-' + currentTenantRanking.user_ranking_position.rank + '.png'" alt=""
                                                             class="ui mini image display-inline-block"
                                                             *ngIf="((currentTenantRanking.user_ranking_position.rank) < 4)"/>
                                                        <img [src]="'./assets/img/prizerank.png'" alt="" class="ui mini image display-inline-block"
                                                             *ngIf="((currentTenantRanking.user_ranking_position.rank) > 3)"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item leaderboard-list-item"
                                             *ngFor="let tenantRankingPosition of tenantRankingPositionsList.results; let i = index;"
                                             [class.currentuser]="currentUser?.id === tenantRankingPosition.user.id">
                                            <div class="ui middle aligned centered grid container">
                                                <div class="row">
                                                    <div class="three wide column center aligned">
                                                        <strong [ngClass]="
                                                    {
                                                        'text-huge': (tenantRankingPosition.rank) < 1000,
                                                        'text-large': (tenantRankingPosition.rank) > 999 && (tenantRankingPosition.rank) < 10000,
                                                        'text-tiny': (tenantRankingPosition.rank) > 9999
                                                    }
                                                ">
                                                            {{tenantRankingPosition.rank}}
                                                        </strong>
                                                    </div>
                                                    <div class="six wide left aligned column padding-r-0 truncate">
                                                        <betsnaps-user-avatar-loader
                                                            class="display-inline-block vertical-align-middle"
                                                            [user]="tenantRankingPosition.user"
                                                            [imageSize]="'tiny'"
                                                            [addClass]="'right spaced profileimg'">
                                                        </betsnaps-user-avatar-loader>
                                                        <strong>{{tenantRankingPosition.user.username}}</strong>
                                                    </div>
                                                    <div class="seven wide column right aligned">
                                                        <strong class="large">
                                                            {{tenantRankingPosition.points | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.abbr_points' | translate}}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div class="row padding-t-0"
                                                     *ngIf="showPrizes && (tenantRankingPosition.tenant_prize || tenantRankingPosition.tenant_prize_template || (tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0))">
                                                    <div class="three wide column center aligned">
                                                    </div>
                                                    <div class="eleven wide left aligned column hyphenate">
                                                        <div class="mini">{{'GENERAL.GAMES.current_prize' | translate}}</div>
                                                        <strong *ngIf="(tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index !== null)">
                                                            {{tenantRankingPosition.prize_amount | decimalPrize: 2 | currencyFormat:currentTenantRanking.prize_structures[currentTenantRanking.primary_cash_prize_structure_index].currency.symbol}}
                                                        </strong>
                                                        <strong *ngIf="(tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0 && currentTenantRanking.primary_cash_prize_structure_index === null)">
                                                            {{tenantRankingPosition.prize_amount | decimalPrize: 2}}
                                                        </strong>
                                                        <strong *ngIf="tenantRankingPosition.tenant_prize">
                                                            <span *ngIf="(tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0)"> + </span>{{tenantRankingPosition.tenant_prize.title | dbtranslation:'title':tenantRankingPosition.tenant_prize.translations}}
                                                        </strong>
                                                        <strong *ngIf="!tenantRankingPosition.tenant_prize && tenantRankingPosition.tenant_prize_template">
                                                            <span *ngIf="(tenantRankingPosition.prize_amount !== null && tenantRankingPosition.prize_amount !== 0)"> + </span>{{tenantRankingPosition.tenant_prize_template.title | dbtranslation:'title':tenantRankingPosition.tenant_prize_template.translations}}
                                                        </strong>
                                                    </div>
                                                    <div class="two wide column right aligned">
                                                        <img [src]="'./assets/img/prizes-rank-' + (i + 1) + '.png'" alt=""
                                                             class="ui mini image display-inline-block" *ngIf="((i + 1) < 4)"/>
                                                        <img [src]="'./assets/img/prizerank.png'" alt="" class="ui mini image display-inline-block"
                                                             *ngIf="((i + 1) > 3)"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ui basic segment center aligned"
                                             *ngIf="tenantRankingPositionsList.results.length < tenantRankingPositionsList.total"
                                             #loadMoreButtonPositions>
                                            <button type="button"
                                                    class="ui button margin-0"
                                                    [class.loading]="processLoadMorePositions"
                                                    (click)="loadMorePositions()">
                                                {{'GENERAL.load-more_btn' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="tenantRankingPositionsList.total < 1"
                                         class="ui bottom attached borderless segment center aligned">
                                        <p>{{'RANKING.no_positions_available' | translate}}</p>
                                        <img class="image" [src]="'./assets/img/notification_images/gamewinnings-notification.png'" alt="Ranking">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui vertical borderless center aligned segment padding-t-0"
                         *ngIf="tenantRankingList.total < 1 && historyTenantRankingList.total < 1">
                        <p class="text-on-body-bg">{{'RANKING.no_rankings_available' | translate}}</p>
                        <img class="image" [src]="'./assets/img/notification_images/gamewinnings-notification.png'" alt="Ranking">
                    </div>
                </div>
            </div>
        </div>
        <betsnaps-right-sidebar-loader class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar"></betsnaps-right-sidebar-loader>
    </div>
</div>
