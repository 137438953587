import {Component, HostBinding, OnInit} from '@angular/core';
import {
    ErrorService,
    GoogleAnalyticsService,
    MyNotificationsService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {Router} from '@angular/router';
import {ANewsfeedComponent} from '../a-newsfeed-component';
import {take, takeWhile} from 'rxjs/operators';
import {
    AppHttpResponsesNotificationsUserNotificationHttpResponse as UserNotificationHttpResponse,
    AppHttpResponsesNotificationsUserNotificationListHttpResponse as UserNotificationListHttpResponse
} from '../../../../api';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'betsnaps-newsfeed-default',
    templateUrl: './newsfeed-default.component.html',
    styles: []
})
export class NewsfeedDefaultComponent extends ANewsfeedComponent implements OnInit {
    @HostBinding('class') componentClass = 'standardview';

    public processAllNotificationsRead: boolean = false;
    public processNewsRead: boolean[] = [];

    public newsFeedMenu = [
        {
            'key': 'new',
            'translatekey': 'NEWS.MENU.new',
            'active': true
        }, {
            'key': 'archive',
            'translatekey': 'NEWS.MENU.archive',
            'active': false
        }
    ];

    constructor(public tenantService: TenantService,
                public notificationService: MyNotificationsService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected errorService: ErrorService,
                protected router: Router,
                protected windowRef: WindowRef) {
        super(
            tenantService,
            notificationService,
            googleAnalyticsService,
            errorService,
            router,
            windowRef
        );

        this.activeTab = 'new';

        this.notificationService.updateUserNotification$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (userNotification: UserNotificationHttpResponse) => {
                    if (!this.processList && this.activeTab === 'new' &&
                        this.userNotifications && this.userNotifications.results &&
                        userNotification && userNotification.is_read) {
                        const notificationInList = this.userNotifications.results.find((userNotificationResponse: UserNotificationHttpResponse) => userNotificationResponse.id === userNotification.id);
                        if (notificationInList) {
                            this.userNotifications.results = this.userNotifications.results.filter(
                                (userNotificationInList: UserNotificationHttpResponse) => userNotificationInList.id !== userNotification.id);
                            this.userNotifications.count = this.userNotifications.count - 1;
                            this.userNotifications.total = this.userNotifications.total - 1;
                        }
                    }
                }
            );

        this.notificationService.allNotificationsRead$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (markAllAsRead: boolean) => {
                    if (typeof markAllAsRead === 'boolean' && markAllAsRead === true) {
                        this.resetNotificationList();
                    }
                }
            );
    }

    ngOnInit(): void {
        this.getUserNotifications();
    }

    getUserNotifications(): void {
        const isRead = this.getIsReadValue();

        if (this.userNotificationListSubscription) {
            this.userNotificationListSubscription.unsubscribe();
        }

        this.userNotificationListSubscription = this.notificationService.getUserNotifications(
            isRead,
            true,
            undefined,
            this.perPage,
            this.currentPage
        ).pipe(take(1))
        .subscribe({
            next: (userNotificationList: UserNotificationListHttpResponse) => {
                if (userNotificationList) {
                    this.userNotifications = userNotificationList;
                    this.processList = false;
                }
            },
            error: (err: HttpErrorResponse) => {
                this.userNotifications = null;
                this.errorService.handleHttpErrorResponse(err);
            },
            complete: () => {
                this.userNotificationListSubscription.unsubscribe();
                this.userNotificationListSubscription = null;
            }
        });
    }

    newsFeedMenuChange(activateKey: string) {
        this.activeTab = activateKey;
        this.currentPage = 1;
        for (const menuItem of this.newsFeedMenu) {
            menuItem.active = (menuItem.key === activateKey);
        }

        this.processList = true;
        this.userNotifications = null;
        this.getUserNotifications();

        this.googleAnalyticsService.trackEvent('news', 'filter', 'notifications - ' + activateKey);
    }

    loadMore(): void {
        if (this.userNotifications.results.length < this.userNotifications.total) {
            this.processLoadMore = true;
            this.currentPage = this.currentPage + 1;

            if (this.userNotificationListSubscription) {
                this.userNotificationListSubscription.unsubscribe();
            }

            this.userNotificationListSubscription = this.notificationService.getUserNotifications(
                this.getIsReadValue(),
                true,
                undefined,
                this.perPage,
                this.currentPage
            ).pipe(take(1))
            .subscribe({
                next: (userNotificationList: UserNotificationListHttpResponse) => {
                    if (userNotificationList) {
                        this.userNotifications.total = userNotificationList.total;
                        this.userNotifications.last_page = userNotificationList.last_page;
                        this.userNotifications.results.push(...userNotificationList.results);
                        this.processLoadMore = false;
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
        }
    }

    getIsReadValue(): boolean {
        let isRead = true;
        if (this.activeTab === 'new') {
            isRead = false;
        }
        return isRead;
    }

    updateUserNotification(userNotification: UserNotificationHttpResponse, isRead: boolean = true) {
        if (!this.processNewsRead[userNotification.id]) {
            this.processNewsRead[userNotification.id] = true;
            this.notificationService.updateUserNotification(userNotification, isRead);
        }
    }

    markAllNotificationsAsRead() {
        this.processAllNotificationsRead = true;
        this.notificationService.markAllNotificationsAsRead()
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.resetNotificationList();
                    this.notificationService.updateNotificationsNewsCounter(0);
                    this.processAllNotificationsRead = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.processAllNotificationsRead = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    protected resetNotificationList() {
        if (this.userNotifications && this.activeTab === 'new') {
            this.userNotifications.total = 0;
            this.userNotifications.count = 0;
            this.userNotifications.results = [];
        }
    }

    navigateToTarget(userNotification: UserNotificationHttpResponse) {
        if (userNotification.target_relative_path) {
            if (userNotification && !userNotification.is_read) {
                this.updateUserNotification(userNotification);
            }
            this.googleAnalyticsService.trackEvent('news', 'click', 'notification - ' + userNotification.notification.code);
            this.router.navigate([userNotification.target_relative_path]);
            return;
        }
    }

    public getNotificationImage(userNotification: UserNotificationHttpResponse): string | null {
        const notificationCode = userNotification.notification.code;
        if (notificationCode === 'user_finished_in_price_ranks') {
            return './assets/img/notification_images/gamewinnings-notification.png';
        } else if (notificationCode === 'game_summary_winner' || notificationCode === 'game_summary_h2h_winner') {
            return './assets/img/notification_images/gamesummary-winner-notification.png';
        } else if (notificationCode === 'game_summary_prizeranks') {
            return './assets/img/notification_images/gamesummary-prizeranks-notification.png';
        } else if (notificationCode === 'game_summary_nonprizeranks' || notificationCode === 'game_summary_h2h_loser') {
            return './assets/img/notification_images/gamesummary-nonprizeranks-notification.png';
        } else if (notificationCode === 'game_summary_invalid_participation' || notificationCode === 'game_summary_h2h_invalid_participation') {
            return './assets/img/notification_images/gamesummary-invalid_participation-notification.png';
        } else if (notificationCode === 'tenant_ranking_summary_winner' || notificationCode === 'tenant_ranking_summary_noprizes_winner') {
            return './assets/img/notification_images/tenantrankingsummary-winner-notification.png';
        } else if (notificationCode === 'tenant_ranking_summary_prizeranks') {
            return './assets/img/notification_images/tenantrankingsummary-prizeranks-notification.png';
        } else if (notificationCode === 'tenant_ranking_summary_nonprizeranks' || notificationCode === 'tenant_ranking_summary_noprizes_info') {
            return './assets/img/notification_images/tenantrankingsummary-nonprizeranks-notification.png';
        } else if (notificationCode === 'tenant_ranking_highscore_new' || notificationCode === 'tenant_ranking_highscore_improved') {
            return './assets/img/notification_images/tenantrankinghighscore_notification.png';
        } else if (notificationCode === 'game_starts_in_minutes') {
            return './assets/img/notification_images/gamestart-notification.png';
        } else if (notificationCode === 'user_signed_up' || notificationCode === 'user_daily_activity_reward' || notificationCode === 'user_accepted_marketing_partner') {
            return './assets/img/notification_images/reward-notification.png';
        } else if (notificationCode === 'user_forced_to_leave_game_pooledh2h') {
            return './assets/img/notification_images/h2h-notification.png';
        } else if (notificationCode === 'game_invitation_received' || notificationCode === 'game_cancelled') {
            const competitionType = this.notificationService.getNotificationJsonProperty(userNotification.notification_variables_json, 'game_competition_type');
            if (competitionType && competitionType === 2) {
                return './assets/img/notification_images/tournament-notification.png';
            } else if (competitionType && (competitionType === 1 || competitionType === 3)) {
                return './assets/img/notification_images/h2h-notification.png';
            } else {
                return null;
            }
        }
        return null;
    }

    public getSecondaryNotificationIcon(userNotification: UserNotificationHttpResponse): string | null {
        const notificationCode = userNotification.notification.code;
        if (notificationCode === 'user_forced_to_leave_game_pooledh2h' || notificationCode === 'game_cancelled') {
            return 'block red';
        }
        return null;
    }

}
