import { AfterViewInit, Component, HostListener, Inject, Input, OnInit, ViewChild, ViewEncapsulation, Directive } from '@angular/core';
import {
    AuthenticationService,
    MyModalService,
    MyNotificationsService,
    ProfileService,
    HintService, ErrorService, TenantService, CurrencyFormatPipe, WindowRef, TenantImageTypeEnum
} from '../../../shared';
import {Observable} from 'rxjs';
import {
    AppHttpResponsesNotificationsUserNotificationHttpResponse as UserNotificationHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerHttpResponse as MarketingPartnerHttpResponse,
    AppHttpResponsesMarketingMarketingPartnerUserPermissionHttpResponse as MarketingPartnerUserPermissionHttpResponse,
    AppHttpResponsesTenantsTenantHintHttpResponse as TenantHintHttpResponse,
    AppHttpResponsesTenantsTenantAdvertisementHttpResponse as AdvertisementHttpResponse
} from '../../../api';
import {TemplateModalConfig, ModalTemplate, ModalSize} from '@aligorji/ngx-fomantic-ui';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter, take} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';
import {NotificationAnimationType, Options} from 'angular2-notifications';

export interface IContext {
    data: string;
    icon: string;
    type: string;
}

export interface IContextMarketing {
    data: string;
    name: string;
    id: number;
    reward: string;
}

@Directive()
export abstract class AMainComponent implements OnInit, AfterViewInit {

    @ViewChild('notificationModalTemplate') public notificationModalTemplate: ModalTemplate<IContext, string, string>;
    @ViewChild('marketingNotificationModalTemplate') public marketingNotificationModalTemplate: ModalTemplate<IContextMarketing, string, string>;

    @Input() isWidget: boolean;

    public isAuthenticated$: Observable<boolean>;

    protected nativeWindow;
    public isWrapperWindow: boolean = false;

    public options: Options = {
        timeOut: 5000,
        showProgressBar: false,
        pauseOnHover: true,
        clickToClose: true,
        lastOnBottom: true,
        theClass: 'full-width',
        position: ['top', 'left'],
        animate: NotificationAnimationType.FromTop
    };

    public marketingNotificationModal = null;

    public processApproveMarketingNotification: boolean = false;

    @ViewChild('hintContainer') protected hintContainer;
    public hintAvatarImage: string = null;
    public hintClass: string = 'hidden';
    public hintProcessing: boolean = false;
    public hintDisabled: boolean = false;
    public currentHintToDisplay: TenantHintHttpResponse;

    public showGeneralScrollToTopButton: boolean = true;

    protected ignoreClick: boolean = false;

    public mobilePresentedByAd: AdvertisementHttpResponse;
    public showMobileAd: boolean = false;

    public bottomMenu: boolean = true;

    constructor(@Inject(DOCUMENT) protected document: any,
                protected myModalService: MyModalService,
                protected authenticationService: AuthenticationService,
                protected tenantService: TenantService,
                protected myNotificationsService: MyNotificationsService,
                protected profileService: ProfileService,
                protected hintService: HintService,
                protected errorService: ErrorService,
                protected activatedRoute: ActivatedRoute,
                protected router: Router,
                protected pageVisibilityService: AngularPageVisibilityService,
                protected currencyFormatPipe: CurrencyFormatPipe,
                protected windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;

        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    ngOnInit() {
        this.isAuthenticated$ = this.authenticationService.isAuthenticated$;

        // get presented by mobile ad
        this.mobilePresentedByAd = this.tenantService.getMobilePresentedByAd();

        this.profileService.marketingPartnerPermissionsUpdate$
            .subscribe((marketingPartnerUserPermissionHttpResponse: MarketingPartnerUserPermissionHttpResponse) => {
                    this.processApproveMarketingNotification = false;
                    if (this.marketingNotificationModal) {
                        this.marketingNotificationModal.approve();
                    }
                    this.myNotificationsService.checkIfOtherBroadcastMarketingPartnerExists();
                }
            );

        // reload userdata for b2b clients
        this.pageVisibilityService.$onPageVisibilityChange
            .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                if (this.tenantService.isB2b && this.tenantService.tenantData.configuration.show_balance &&
                    this.authenticationService.currentUser && visibilityState === AngularPageVisibilityStateEnum.VISIBLE) {
                    this.authenticationService.reloadUserData();
                }
            });

        if (this.tenantService.componentTemplateToLoad !== 'v3') {
            this.hintAvatarImage = './assets/img/hint-avatar.png';
        }

        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.HINT_AVATAR)) {
            this.hintAvatarImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.HINT_AVATAR).media_url;
        }

        this.hintService.currentHintToDisplay$.subscribe(
            (tenantHintHttpResponse: TenantHintHttpResponse) => {
                if (tenantHintHttpResponse) {
                    this.currentHintToDisplay = tenantHintHttpResponse;
                    this.hintClass = 'visible';
                } else {
                    this.currentHintToDisplay = null;
                    this.hintClass = 'hidden';
                    this.hintProcessing = false;
                    this.hintDisabled = false;
                }
            }
        );

        this.handleNavigationData();
        this.checkForBottomMenu();
        this.router.events.pipe(
            filter(routerEvent => (routerEvent instanceof NavigationStart || routerEvent instanceof NavigationEnd))
        ).subscribe((currRouteEvent) => {
            if (currRouteEvent instanceof NavigationEnd) {
                this.handleNavigationData();
                this.checkForBottomMenu();
            }
            if (currRouteEvent instanceof NavigationStart) {
                this.ignoreClick = true;
                // reset hint on route change
                this.hintService.resetHint();
            }
        });

    }

    ngAfterViewInit() {

        this.myNotificationsService.popupUserNotification$.subscribe(
            (userNotificationHttpResponse: UserNotificationHttpResponse) => {
                if (userNotificationHttpResponse && userNotificationHttpResponse.notification) {
                    this.openNotificationModal(userNotificationHttpResponse);
                }
            }
        );

        this.myNotificationsService.popupUserMarketingNotification$.subscribe(
            (marketingPartner: MarketingPartnerHttpResponse) => {
                if (marketingPartner) {
                    this.openMarketingNotificationModal(marketingPartner);
                }
            }
        );

    }

    protected handleNavigationData() {
        let route = this.activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
            if (typeof route.snapshot.data['showGeneralScrollToTopButton'] !== 'undefined') {
                this.showGeneralScrollToTopButton = route.snapshot.data['showGeneralScrollToTopButton'];
            } else {
                this.showGeneralScrollToTopButton = true;
            }
            if (typeof route.snapshot.data['showMobileAd'] !== 'undefined') {
                this.showMobileAd = route.snapshot.data['showMobileAd'];
            } else {
                this.showMobileAd = true;
            }
        }
    }

    protected checkForBottomMenu() {
        this.bottomMenu = !this.isTitleInActiveRoute('SnapDetail');
    }

    private isTitleInActiveRoute(title: string): boolean {
        let route = this.activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
            if (typeof route.snapshot.data['title'] !== 'undefined') {
                if (route.snapshot.data['title'] === title) {
                    return true;
                }
            }
        }
        return false;
    }

    public openNotificationModal(userNotification: UserNotificationHttpResponse, userNotificationType: string = 'success') {
        const config = new TemplateModalConfig<IContext, string, string>(this.notificationModalTemplate);

        config.context = {
            data: this.myNotificationsService.getReplacedText(userNotification),
            icon: this.myNotificationsService.getNotificationIconClass(userNotification),
            type: userNotificationType
        };
        config.size = ModalSize.Large;
        config.isBasic = true;

        this.myModalService.openModal(config);
    }

    public openMarketingNotificationModal(marketingPartnerHttpResponse: MarketingPartnerHttpResponse) {
        if (marketingPartnerHttpResponse) {
            const config = new TemplateModalConfig<IContextMarketing, string, string>(this.marketingNotificationModalTemplate);

            let dataNotificationText = marketingPartnerHttpResponse.data_transfer_declaration;

            if (marketingPartnerHttpResponse.translations) {
                const translation = marketingPartnerHttpResponse.translations.find(
                    (translationItem) =>
                        translationItem.label_field === 'data_transfer_declaration' &&
                        translationItem.iso_code2 === this.myNotificationsService.currentLang.iso_code2);

                if (translation) {
                    dataNotificationText = translation.label_value;
                }
            }

            let dataNotificationReward = null;

            if (marketingPartnerHttpResponse.tenant_reward) {
                dataNotificationReward = this.currencyFormatPipe.transform(marketingPartnerHttpResponse.tenant_reward.reward, marketingPartnerHttpResponse.tenant_reward.master_currency.symbol);
            }

            config.context = {
                data: dataNotificationText,
                name: marketingPartnerHttpResponse.name,
                id: marketingPartnerHttpResponse.id,
                reward: dataNotificationReward
            };
            config.size = ModalSize.Normal;
            config.isBasic = true;

            this.marketingNotificationModal = this.myModalService.openModal(config).onDeny(() => {
                this.myNotificationsService.checkIfOtherBroadcastMarketingPartnerExists();
            });
        }
    }

    public approveMarketingNotification(partnerId: number) {
        if (this.authenticationService.currentUser) {
            this.processApproveMarketingNotification = true;
            this.profileService.setUsersMarketingPartnerPermissions(this.authenticationService.currentUser.id, partnerId, true);
        }
    }

    public readHint(masterHintId: number) {
        if (this.currentHintToDisplay) {
            this.hintProcessing = true;
            this.hintDisabled = true;
            this.hintService.markHintsAsRead([masterHintId])
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.hideCurrentHint();
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                });
        }
    }

    public hideCurrentHint() {
        if (this.currentHintToDisplay) {
            this.hintClass = 'hidden';
            this.hintService.resetHint();
        }
    }

    @HostListener('document:click', ['$event'])
    documentClick(event) {

        let clickInsideHintContainer = false;
        let clickInsideHeaderUserItem = false;

        const headerUserItem = this.document.getElementById('header-user-item');
        if (headerUserItem && headerUserItem.contains(event.target)) {
            clickInsideHeaderUserItem = true;
        }
        if (this.hintContainer && this.hintContainer.nativeElement && this.hintContainer.nativeElement.contains(event.target)) {
            clickInsideHintContainer = true;
        }

        // only hide if not clicked inside hint container or header user item
        if (!this.ignoreClick && !clickInsideHeaderUserItem && !clickInsideHintContainer) {
            this.hideCurrentHint();
        } else if (this.ignoreClick) {
            this.ignoreClick = false;
        }
    }

}
