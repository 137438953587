import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {DebugService} from './debug.service';
import {TenantService} from './tenant.service';
import {Title} from '@angular/platform-browser';

declare var gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    private accountName: string[] = [];
    private accountNameTrackingId: string[] = [];
    private routerEvent;
    public isGoogleAnalyticsCodeInitialized: boolean = false;

    constructor(public router: Router,
                private tenantService: TenantService,
                private titleService: Title,
                private debugService: DebugService) {
    }

    activateGoogleAnalytics(document: any) {
        if (this.isGoogleAnalyticsCodeInitialized) {
            return;
        }

        if (environment.GOOGLE_ANALYTICS_TRACKING_ID
        || (this.tenantService.tenantData && this.tenantService.tenantData.social_network.google_analytics_tracking_id)) {
            this.initializeGoogleAnalyticsTrackingCode(document);
        }

        if (this.isGoogleAnalyticsCodeInitialized) {
            if (environment.GOOGLE_ANALYTICS_TRACKING_ID) {
                this.configGTag(
                    environment.GOOGLE_ANALYTICS_TRACKING_ID,
                    'bsall'
                );
            }

            // add google analytics tenant tracker
            if (this.tenantService.tenantData && this.tenantService.tenantData.social_network.google_analytics_tracking_id) {
                this.configGTag(
                    this.tenantService.tenantData.social_network.google_analytics_tracking_id,
                    'tenant' + this.tenantService.tenantData.id
                );
            }

            this.subscribeRouterEventsNavigationEnd();
        }
    }

    public trackEvent(eventCategory: string, eventAction: string, eventLabel: string = null) {
        this.debugService.writeMessageToConsoleLog(
            'analytics: ' + '[Category: ' + eventCategory + '] ' + '[Action: ' + eventAction + '] ' + '[Label: ' + eventLabel + ']'
        );

        if (this.isGoogleAnalyticsCodeInitialized === false) {
            return;
        }

        this.accountName.forEach(
            accountName => {
                gtag('event', eventAction, {
                    'send_to': this.accountNameTrackingId[accountName],
                    'event_category': eventCategory,
                    'event_label': eventLabel
                });
            }
        );
    }

    private trackUrl(url: string, title: string = null) {
        if (this.isGoogleAnalyticsCodeInitialized === false) {
            return;
        }

        if (title === null) {
            title = this.titleService.getTitle();
        }

        this.accountName.forEach(
            accountName => {
                gtag('event', 'page_view', {'send_to': this.accountNameTrackingId[accountName]});
            }
        );

        this.debugService.writeMessageToConsoleLog(
            'analytics: ' + url + ' [' + title + '] |' + this.accountName.toString() + '|'
        );
    }

    private configGTag(trackerId: string, accountName: string) {
        this.accountName.push(accountName);
        this.accountNameTrackingId[accountName] = trackerId;
        gtag('config', trackerId);
    }

    private subscribeRouterEventsNavigationEnd() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.routerEvent = event;
                this.trackUrl(this.routerEvent.urlAfterRedirects);
            }
        });
    }

    private initializeGoogleAnalyticsTrackingCode(document: any) {
        const googleTagManagerScript = document.createElement('script');
        googleTagManagerScript.type = 'text/javascript';
        googleTagManagerScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_ANALYTICS_TRACKING_ID;
        googleTagManagerScript.async = true;
        document.head.appendChild(googleTagManagerScript);

        const googleTagManagerConfigScript = document.createElement('script');
        googleTagManagerConfigScript.innerHTML = 'window.dataLayer = window.dataLayer || [];' +
            'function gtag(){dataLayer.push(arguments);};' +
            'gtag("js", new Date());';
        document.head.appendChild(googleTagManagerConfigScript);

        if (typeof gtag === 'function') {
            this.isGoogleAnalyticsCodeInitialized = true;

            this.debugService.writeMessageToConsoleLog(
                'analytics: tracking code initialized'
            );
        }
    }

}
