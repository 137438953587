/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * AdminCreateRequest
 */
export interface AppHttpRequestsUsersAdminCreateRequest { 
    /**
     * Username
     */
    username: string;
    /**
     * E-Mail
     */
    email: string;
    /**
     * First Name
     */
    first_name?: string;
    /**
     * Last Name
     */
    last_name?: string;
    /**
     * Tenant Ids
     */
    tenant_ids?: Array<number>;
    /**
     * Role Ids
     */
    role_ids?: Array<number>;
    /**
     * Permission Ids
     */
    permission_ids?: Array<number>;
}

