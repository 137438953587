import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {DynamicComponentLoaderService} from '../../../shared/services/dynamic-component-loader.service';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {DynamicComponentHostDirective} from '../../../shared/directives/dynamic-component-host.directive';
import {AGameCardComponent} from './a-game-card-component';

@Component({
    selector: 'betsnaps-game-card-loader',
    template: `
        <ng-template dynamicComponentHost></ng-template>
    `
})

export class GameCardLoaderComponent implements OnInit, OnChanges, OnDestroy {
    @HostBinding('class') componentClass = 'padding-0';

    @ViewChild(DynamicComponentHostDirective, { static: true }) dynamicComponentHost: DynamicComponentHostDirective;

    @Input() currentUser: PlayerHttpResponse;
    @Input() game: GameHttpResponse;

    @Input() isPartOfList: boolean = false;
    @Input() isSwitcher: boolean = false;
    @Input() isWidget: boolean = false;
    @Input() isLandingpageWidget: boolean = false;

    @Input() gameIndexInList: number;
    @Input() adPositions: number[];

    @Output() onGameCardClick: EventEmitter<any> = new EventEmitter();
    @Output() onSnapSwitch: EventEmitter<any> = new EventEmitter();

    private componentRef: ComponentRef<AGameCardComponent>;

    constructor(private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private dynamicComponentLoaderService: DynamicComponentLoaderService) {
    }

    ngOnInit() {

        const componentToLoad = this.dynamicComponentLoaderService.getComponent('game-card');

        if (componentToLoad) {
            const componentFactory: ComponentFactory<AGameCardComponent> = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);

            if (this.isPartOfList) {
                this.viewContainerRef.clear();
                this.componentRef = this.viewContainerRef.createComponent<AGameCardComponent>(componentFactory);
            } else {
                const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
                viewContainerRef.clear();
                this.componentRef = viewContainerRef.createComponent<AGameCardComponent>(componentFactory);
            }

            this.componentRef.instance.currentUser = this.currentUser;
            this.componentRef.instance.game = this.game;
            this.componentRef.instance.gameIndexInList = this.gameIndexInList;
            this.componentRef.instance.isWidget = this.isWidget;
            this.componentRef.instance.isPartOfList = this.isPartOfList;
            this.componentRef.instance.isSwitcher = this.isSwitcher;
            this.componentRef.instance.isLandingpageWidget = this.isLandingpageWidget;
            this.componentRef.instance.onGameCardClick = this.onGameCardClick;
            this.componentRef.instance.onSnapSwitch = this.onSnapSwitch;

            this.componentRef.instance.adPositions = this.adPositions;
            this.componentRef.instance.gameIndexInList = this.gameIndexInList;

            this.componentRef.instance.checkSecondsToStart();
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.componentRef) {
            this.componentRef.instance.currentUser = this.currentUser;
            this.componentRef.instance.game = this.game;

            if (changes.game) {
                this.componentRef.instance.checkSecondsToStart();
            }

            this.componentRef.instance.gameIndexInList = this.gameIndexInList;
            this.componentRef.instance.isPartOfList = this.isPartOfList;
            this.componentRef.instance.isSwitcher = this.isSwitcher;
            this.componentRef.instance.isWidget = this.isWidget;
            this.componentRef.instance.isLandingpageWidget = this.isLandingpageWidget;
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }
}
