import {Injectable} from '@angular/core';
import {TenantService} from './tenant.service';
import {
    AppHttpResponsesTenantsTenantPublicHttpResponse as TenantPublicHttpResponse,
    AppHttpResponsesUsersUserHttpResponse as UserHttpResponse
} from '../../api';
import {DebugService} from './debug.service';
import {WindowRef} from './window.service';
import {AuthenticationService} from './authentication.service';
import {take} from 'rxjs/operators';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {GoogleAnalyticsService} from './google-analytics.service';
import {MyNotificationsService} from './my-notifications.service';

let OneSignal;

@Injectable({
    providedIn: 'root'
})
export class OneSignalService {

    private nativeWindow;
    public isWrapperWindow: boolean = false;

    private tenant: TenantPublicHttpResponse;
    private user: UserHttpResponse;

    private oneSignalUserId: string;

    public isInitialized: boolean = false;
    public isPushNotificationsSupported: boolean = true;
    public currentNotificationPermission: string = 'default';

    constructor(private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private translateService: TranslateService,
                private myNotificationsService: MyNotificationsService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private debugService: DebugService,
                private windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;
        if (this.nativeWindow.window.BetSnapMobileWrapper) {
            this.isWrapperWindow = true;
        }
    }

    public init() {
        // only initialize if no wrapper app and tenant web push is enabled
        if (!this.isWrapperWindow && this.webPushAvailableForTenant) {
            this.tenant = this.tenantService.tenantData;

            this.setOneSignalGlobal();

            const self = this;
            OneSignal.push(function() {
                self.isPushNotificationsSupported = OneSignal.isPushNotificationsSupported();
                self.debugService.writeMessageToConsoleLog('OneSignal: isPushNotificationsSupported = ' + self.isPushNotificationsSupported);
                if (!self.isInitialized && self.isPushNotificationsSupported) {
                    self.debugService.writeMessageToConsoleLog('OneSignal: initialize');

                    OneSignal.push(['init', {
                        appId: self.tenant.configuration.one_signal_push_notifications_app_id,
                        autoResubscribe: true,
                        allowLocalhostAsSecureOrigin: true,
                        persistNotification: true,
                        notifyButton: {
                            enable: false,
                        },
                        welcomeNotification: {
                            disable: true
                        },
                        requiresUserPrivacyConsent: false,
                        notificationClickHandlerMatch: 'origin',
                        notificationClickHandlerAction: 'navigate'
                    }]);

                    OneSignal.push(['getNotificationPermission', function(permission) {
                        self.debugService.writeMessageToConsoleLog('OneSignal: current notification permission = ' + permission);
                        self.currentNotificationPermission = permission;
                        if (permission === 'granted') {
                            self.getUserID();
                        }
                    }]);

                    OneSignal.push(function() {
                        OneSignal.on('notificationPermissionChange', function (permissionChange) {
                            if (self.currentNotificationPermission !== permissionChange.to) {
                                self.debugService.writeMessageToConsoleLog('OneSignal: notification permission changed ' + permissionChange.to);
                                self.googleAnalyticsService.trackEvent('notification permission', 'changed', permissionChange.to);

                                self.currentNotificationPermission = permissionChange.to;
                                if (self.currentNotificationPermission === 'granted') {
                                    self.translateService.get('GENERAL.LABELS.web_push_notifications_enabled')
                                        .pipe(take(1)).subscribe(
                                        translation => {
                                            self.myNotificationsService.createNotificationToast(
                                                '',
                                                translation,
                                                NotificationType.Success
                                            );
                                        });
                                } else if (self.currentNotificationPermission === 'denied') {
                                    self.translateService.get('GENERAL.LABELS.web_push_notifications_blocked')
                                        .pipe(take(1)).subscribe(
                                        translation => {
                                            self.myNotificationsService.createNotificationToast(
                                                '',
                                                translation,
                                                NotificationType.Success
                                            );
                                        });
                                }
                            }
                        });
                    });

                    OneSignal.push(function() {
                        OneSignal.on('subscriptionChange', (isSubscribed) => {
                            self.debugService.writeMessageToConsoleLog('OneSignal: subscription changed ' + isSubscribed);
                            self.googleAnalyticsService.trackEvent('OneSignal subscription', 'changed', isSubscribed);
                            if (isSubscribed) {
                                self.getUserID();
                            } else {
                                self.oneSignalUserId = null;
                            }
                        });
                    });

                    OneSignal.push(function() {
                        OneSignal.on('permissionPromptDisplay', function(permissionChange) {
                            self.debugService.writeMessageToConsoleLog('OneSignal: notification prompt displayed');
                            self.googleAnalyticsService.trackEvent('notification prompt', 'displayed');
                        });
                    });

                    OneSignal.push(['addListenerForNotificationOpened', function(data) {
                        self.debugService.writeMessageToConsoleLog('OneSignal: notification opened');
                        self.debugService.writeMessageToConsoleLog(data);

                        const eventLabel = (data.data.game_unique_id) ? 'game ' + data.data.game_unique_id : '';

                        if (data.action && data.buttons) {
                            const actionButtonClicked = data.buttons.find((button) => button.action === data.action);
                            if (actionButtonClicked) {
                                self.googleAnalyticsService.trackEvent('notification ' + data.data.message_type, data.action, eventLabel);
                            }
                        } else {
                            self.googleAnalyticsService.trackEvent('notification ' + data.data.message_type, 'notification.opened', eventLabel);
                        }
                    }]);

                    self.isInitialized = true;
                }
            });
        }
    }

    public subscribeForWebPushNotifications() {
        if (this.isWrapperWindow) {
            // show native permission prompt on wrapper device
            this.nativeWindow.window.BetSnapMobileWrapper.requestNotificationPermission([]);
        } else {
            // show native permission prompt on web device
            this.setOneSignalGlobal();

            if (this.isInitialized && this.isPushNotificationsSupported) {
                if (this.currentNotificationPermission !== 'denied') {
                    this.setUser(this.authenticationService.currentUser);
                }
                if (this.currentNotificationPermission === 'default') {
                    this.showNotificationPermissionPrompt();
                } else if (this.currentNotificationPermission === 'granted') {
                    this.getUserID();
                }
            }
        }
    }

    private setOneSignalGlobal() {
        OneSignal = this.nativeWindow.window['OneSignal'] || [];
    }

    private setUser(user: UserHttpResponse) {
        if (this.isInitialized && this.isPushNotificationsSupported) {
            this.debugService.writeMessageToConsoleLog('OneSignal: set user ' + user.id);

            this.user = user;

            OneSignal.push(['sendTag', 'bs_userId', user.id.toString()]);
            OneSignal.push(['setExternalUserId', user.id.toString()]);
        }
    }

    private showNotificationPermissionPrompt() {
        if (this.isInitialized && this.isPushNotificationsSupported) {
            this.debugService.writeMessageToConsoleLog('OneSignal: Show notification permission prompt');

            OneSignal.push(function() {
                OneSignal.showNativePrompt();
            });
        }
    }

    private getUserID() {
        if (this.isInitialized && this.isPushNotificationsSupported) {
            const self = this;
            OneSignal.push(function() {
                OneSignal.getUserId(function(userId) {
                    if (userId) {
                        self.debugService.writeMessageToConsoleLog('OneSignal: User ID is ' + userId);
                        self.oneSignalUserId = userId.toString();
                    }
                });
            });
        }
    }

    public get webPushAvailableForTenant(): boolean {
        return (
            this.tenantService.tenantData.configuration.outgoing_notifications &&
            this.tenantService.tenantData.configuration.allow_web_push_notifications &&
            this.tenantService.tenantData.configuration.one_signal_push_notifications_app_id !== null &&
            this.tenantService.tenantData.configuration.one_signal_push_notifications_app_id !== ''
        );
    }

}
