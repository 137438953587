import {Component, HostBinding} from '@angular/core';
import {AdService, AuthenticationService, BetsnapdetailService, GoogleAnalyticsService} from '../../../../../shared';
import {Router} from '@angular/router';
import {ABetsnapdetailVendorGameParticipationsComponent} from '../a-betsnapdetail-vendor-game-participations-component';
import {takeWhile} from 'rxjs/operators';
import {AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse} from '../../../../../api';
import {SinglePlayerGameService} from '../../../../../shared/services/singleplayer-game.service';

@Component({
    selector: 'betsnaps-betsnapdetail-vendor-game-participations-v3',
    templateUrl: './betsnapdetail-vendor-game-participations-v3.component.html',
    styles: []
})
export class BetsnapdetailVendorGameParticipationsV3Component extends ABetsnapdetailVendorGameParticipationsComponent {
    @HostBinding('class') componentClass = 'betsnapdetail-content';

    constructor(protected betsnapdetailService: BetsnapdetailService,
                public authenticationService: AuthenticationService,
                protected singlePlayerGameService: SinglePlayerGameService,
                protected router: Router,
                protected googleAnalyticsService: GoogleAnalyticsService,
                protected adService: AdService) {
        super(
            betsnapdetailService,
            authenticationService,
            singlePlayerGameService,
            router,
            googleAnalyticsService,
            adService
        );

        this.betsnapdetailService.gameVendorGameParticipations$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameVendorGameParticipations: GameVendorGameParticipationHttpResponse[]) => {
                    this.gameVendorGameParticipations = gameVendorGameParticipations;
                    if (gameVendorGameParticipations) {
                        this.processGameVendorGameParticipations = false;
                    }
                }
            );
    }

}
