<div class="content head">
    <div class="ui middle aligned grid">
        <div class="twelve wide column">
            <div class="text-mini color-light-grey">
                <div *ngIf="gameVendorGame.status !== gameVendorGameStatusEnum.RUNNING">
                    <span *ngIf="gameVendorGame.status === gameVendorGameStatusEnum.FINISHED || gameVendorGame.status === gameVendorGameStatusEnum.CLOSED">
                        {{'GENERAL.VENDORGAMES.STATI.closed' | translate | uppercase}}
                    </span>
                    <span *ngIf="gameVendorGame.status === gameVendorGameStatusEnum.CANCELLED">
                        {{'GENERAL.VENDORGAMES.STATI.cancelled' | translate | uppercase}}
                    </span>
                    {{gameVendorGame.start_date | amLocal | amCalendar:
                    {
                        sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                        nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                        lastDay: tenantService.tenantData.internationalization.date_time_format,
                        lastWeek: tenantService.tenantData.internationalization.date_time_format,
                        sameElse: tenantService.tenantData.internationalization.date_time_format
                    }
                    }}
                </div>
                <div *ngIf="gameVendorGame.status === gameVendorGameStatusEnum.RUNNING" class="display-flex">
                    <i class="ui lens mini red icon"></i> {{'GENERAL.GAMES.STATI.running_until' | translate}}, {{gameVendorGame.end_date | amLocal | amCalendar:
                        {
                                sameDay: '[' + ('GENERAL.DATES.today_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextDay: '[' + ('GENERAL.DATES.tomorrow_at' | translate) + '], ' + tenantService.tenantData.internationalization.time_format,
                                nextWeek: 'dddd, ' + tenantService.tenantData.internationalization.time_format,
                                lastDay: tenantService.tenantData.internationalization.date_time_format,
                                lastWeek: tenantService.tenantData.internationalization.date_time_format,
                                sameElse: tenantService.tenantData.internationalization.date_time_format
                            }
                        }}
                </div>
            </div>
            <div class="text-tiny truncate color-grey" fuiPopup
                 popupText="{{gameVendorGame.vendor_game.name | dbtranslation:'name':gameVendorGame.vendor_game.translations}}"
                 popupTrigger="outsideClick">
                {{gameVendorGame.vendor_game.name | dbtranslation:'name':gameVendorGame.vendor_game.translations | uppercase}}
            </div>
        </div>
        <div class="four wide column right aligned">
            <i class="sport-icon icon bsi bsi-sport-999"></i>
        </div>
    </div>
</div>
<div class="content preview-image"
     *ngIf="gameVendorGame.vendor_game.initial_game_image_media">
    <div class="ui fluid image">
        <img [src]="gameVendorGame.vendor_game.initial_game_image_media.media_url" alt="" />
    </div>
    <div *ngIf="showParticipationButton() && !loadingParticipations">
        <button type="button"
                (click)="googleAnalyticsService.trackEvent('gamevendorgamecard', 'click', 'start participation'); startParticipation()"
                class="ui large button participation-button margin-0"
                [class.loading]="processParticipation"
                [disabled]="processParticipation"
                *ngIf="!activeParticipation && (this.game.game_user.user_game_points > 0 || this.processParticipation)">
            {{'GENERAL.VENDORGAMES.start_participation' | translate}}
        </button>
        <button type="button"
                (click)="googleAnalyticsService.trackEvent('gamevendorgamecard', 'click', 'resume participation'); resumeParticipation(activeParticipation)"
                class="ui large button participation-button margin-0"
                [class.loading]="processParticipation"
                [disabled]="processParticipation"
                *ngIf="activeParticipation">
            {{'GENERAL.VENDORGAMES.resume_participation' | translate}}
        </button>
    </div>
</div>
<div class="content"
     *ngIf="!gameVendorGame.vendor_game.initial_game_image_media && showParticipationButton() && !loadingParticipations">
    <button type="button"
            (click)="googleAnalyticsService.trackEvent('gamevendorgamecard', 'click', 'start participation'); startParticipation()"
            class="ui large fluid button"
            [class.loading]="processParticipation"
            [disabled]="processParticipation"
            *ngIf="!activeParticipation && (this.game.game_user.user_game_points > 0 || this.processParticipation)">
        {{'GENERAL.VENDORGAMES.start_participation' | translate}}
    </button>
    <button type="button"
            (click)="googleAnalyticsService.trackEvent('gamevendorgamecard', 'click', 'resume participation'); resumeParticipation(activeParticipation)"
            class="ui large fluid button"
            [class.loading]="processParticipation"
            [disabled]="processParticipation"
            *ngIf="activeParticipation">
        {{'GENERAL.VENDORGAMES.resume_participation' | translate}}
    </button>
</div>

<div class="fullscreen-vendor-game" *ngIf="showFullScreenGame && activeParticipation &&
                                           gameVendorGame.status === gameVendorGameStatusEnum.RUNNING &&
                                           !gameVendorGameEnded()">
    <div class="fullscreen-vendor-game-header">
        <a href="#" class="close-link"
           (click)="$event.preventDefault(); googleAnalyticsService.trackEvent('gamevendorgamecard', 'click', 'close participation');  closeParticipation(activeParticipation);">
            <i class="close huge icon"></i>
        </a>
        <span *ngIf="game.game_user.rank > 0">
            {{game.game_user.rank}}<span class="text-mini">{{game.game_user.rank | ordinalNumber:true}}</span>  {{'GENERAL.GAMES.rank' | translate}}&nbsp;-&nbsp;
        </span><span *ngIf="game.game_state > 2 && game.game_user.rank === 1 && game.game_user.points_in_lead !== null && game.game_user.is_participation_valid !== false">
            {{game.game_user.points_in_lead | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_in_lead' | translate}}
        </span><span *ngIf="game.game_state > 2 && (game.game_user.rank > 1 || (game.game_user.rank === 1 && game.game_user.points_in_lead === null)) && game.game_user.is_participation_valid !== false">
            {{game.game_user.points_to_first | number:'1.2-2'}} {{'GENERAL.GAMES.POINTS.points_to_first' | translate}}
        </span> - <betsnaps-countdown [timeInSeconds]="secondsToEnd"
                                      [autoStart]="true"
                                      [secondsForHighlight]="0"
                                      (onCountdownFinished)="closeParticipation(activeParticipation)"></betsnaps-countdown>
    </div>
    <iframe [src]="activeParticipation.game_url | safeUrl"></iframe>
</div>
