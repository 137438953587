import {Directive, ElementRef, Renderer2} from '@angular/core';
import {WindowRef} from '../services/window.service';

@Directive({
    selector: '[scroller]',
})
export class ScrollerDirective {

    private nativeWindow: Window;
    private touchStart: number = 0;

    constructor(
        private elRef: ElementRef,
        private renderer: Renderer2,
        private windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;

        renderer.listen(elRef.nativeElement, 'wheel', (e) => {
            this.preventDefault(e, e.deltaY);
        });
        renderer.listen(elRef.nativeElement, 'touchstart', (e) => {
            this.touchStart = e.touches[0].clientY;
        });
        renderer.listen(elRef.nativeElement, 'touchmove', (e) => {
            const currentY = e.changedTouches[0].clientY;
            const deltaY = this.touchStart - currentY;
            this.preventDefault(e, deltaY);
        });
    }

    preventDefault(e, deltaY: number) {
        if ((((this.elRef.nativeElement.scrollTop + this.elRef.nativeElement.offsetHeight >= this.elRef.nativeElement.scrollHeight) && deltaY > 0) || (this.elRef.nativeElement.scrollTop === 0 && deltaY < 0))) {
            e = e || this.nativeWindow.window.event;
            if (e.cancelable) {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                e.returnValue = false;
            }
        }
    }
}
