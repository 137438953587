/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesMediaMediaPublicHttpResponse } from './appHttpResponsesMediaMediaPublicHttpResponse';
import { AppHttpResponsesVendorGamesVendorHttpResponse } from './appHttpResponsesVendorGamesVendorHttpResponse';
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * VendorGameHttpResponse
 */
export interface AppHttpResponsesVendorGamesVendorGameHttpResponse { 
    /**
     * Vendor Game Id
     */
    id: number;
    /**
     * Identifier
     */
    identifier: string;
    /**
     * Vendor ID
     */
    vendor_id: number;
    /**
     * External game ID
     */
    external_id?: string;
    /**
     * Name
     */
    name: string;
    /**
     * Config JSON
     */
    config_json?: string;
    /**
     * Is Active
     */
    is_active: boolean;
    /**
     * Hooks enabled
     */
    hooks_enabled: boolean;
    /**
     * Is single player capable
     */
    is_single_player_capable: boolean;
    vendor?: AppHttpResponsesVendorGamesVendorHttpResponse;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
    initial_game_image_media?: AppHttpResponsesMediaMediaPublicHttpResponse;
}

