import { ComponentRef, Input, OnInit, ViewContainerRef, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse as GameVendorGameParticipationTransactionHttpResponse
} from '../../../api';
import {
    GameVendorGameParticipationStatusEnum,
    GameVendorGameParticipationTransactionStatusEnum
} from '../../../shared/enums';
import {AdService, TenantService} from '../../../shared';
import {TenantContentComponent} from '../tenant-content/tenant-content.component';

@Directive()
export abstract class AGameVendorGameParticipationCardComponent implements OnInit {

    @Input() game: GameHttpResponse;
    @Input() gameVendorGameParticipation: GameVendorGameParticipationHttpResponse;
    @Input() gameVendorGameParticipationUpdatedDate: Date;

    @Input() participationIndexInList: number;
    @Input() adPositions: number[];

    private adComponentRef: ComponentRef<TenantContentComponent>;

    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;

    constructor(protected viewContainerRef: ViewContainerRef,
                public tenantService: TenantService,
                protected adService: AdService) {
    }

    ngOnInit() {
        // place ad
        const adSlotPosition: number = this.participationIndexInList + 1;
        this.adComponentRef = this.adService.placeTenantAd('game-content', adSlotPosition, this.viewContainerRef, this.adPositions, this.participationIndexInList, 'vendorGameParticipationCard');
    }

    participationSaldo(): number {
        let participationSaldo = 0;
        this.closedParticipationTransactions().forEach(
            (gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) => {
                participationSaldo += (gameVendorGameParticipationTransaction.total_points - gameVendorGameParticipationTransaction.points);
            });
        return participationSaldo;
    }

    public closedParticipationTransactions(): GameVendorGameParticipationTransactionHttpResponse[] {

        if (!this.gameVendorGameParticipation.game_vendor_game_participation_transactions) {
            return [];
        }

        return this.gameVendorGameParticipation.game_vendor_game_participation_transactions.filter(
            (gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) =>  gameVendorGameParticipationTransaction.status === GameVendorGameParticipationTransactionStatusEnum.CLOSED
        );
    }

}
