/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantMonthlyStatisticHttpResponse
 */
export interface AppHttpResponsesStatisticsTenantsTenantMonthlyStatisticHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Statistic Month (YYYYMM)
     */
    month: number;
    /**
     * Tenant ID
     */
    tenant_id?: number;
    /**
     * Total Revenue
     */
    revenue?: number;
    /**
     * Revenue increase/decrease percentage compared to last month
     */
    revenue_change?: number;
    /**
     * New Users
     */
    users_new?: number;
    /**
     * New Users increase/decrease percentage compared to last month
     */
    users_new_change?: number;
    /**
     * Deleted Users
     */
    users_deleted?: number;
    /**
     * Active Users
     */
    users_sessions?: number;
    /**
     * Total number of players
     */
    users_total?: number;
    /**
     * Total number of players increase/decrease percentage compared to last month
     */
    users_total_change?: number;
    /**
     * Total number of cash-players
     */
    users_cash?: number;
    /**
     * Total number of cash-players increase/decrease percentage compared to last month
     */
    users_cash_change?: number;
    /**
     * Total number of non-cash players
     */
    users_non_cash?: number;
    /**
     * Total number of non-cash players increase/decrease percentage compared to last month
     */
    users_non_cash_change?: number;
    /**
     * Total number of missing players (max-size - joined players)
     */
    users_game_missing?: number;
    /**
     * Total number of missing players increase/decrease percentage compared to last month
     */
    users_game_missing_change?: number;
    /**
     * Total number of players that have never joined a game
     */
    users_never_joined?: number;
    /**
     * Total number of players that have never joined a game increase/decrease percentage compared to last month
     */
    users_never_joined_change?: number;
    /**
     * Total amount of revenue of all players making 20% of all buy-ins
     */
    mvp_revenue?: number;
    /**
     * Total amount of winnings of the 20% most successful players
     */
    mvp_winning?: number;
    /**
     * Number of Snaps played
     */
    games_played?: number;
    /**
     * Number of Snaps played increase/decrease percentage compared to last month
     */
    games_played_change?: number;
    /**
     * Number of tenant-created Snaps
     */
    games_created_tenant?: number;
    /**
     * Number of tenant-created Snaps increase/decrease percentage compared to last month
     */
    games_created_tenant_change?: number;
    /**
     * Number of player-created Snaps
     */
    games_created_user?: number;
    /**
     * Number of player-created Snaps increase/decrease percentage compared to last month
     */
    games_created_user_change?: number;
    /**
     * Number of recreated Snaps
     */
    games_recreated?: number;
    /**
     * Number of Snaps that were recreation Snaps but were not recreated
     */
    games_recreated_fail?: number;
    /**
     * Number of Snaps that were recreation Snaps but were not recreated percentage
     */
    games_recreated_fail_percentage?: number;
    /**
     * Sum of buy-ins
     */
    buy_in_total?: number;
    /**
     * Sum of buy-ins increase/decrease percentage compared to last month
     */
    buy_in_total_change?: number;
    /**
     * Sum of buy-ins of tenant-created Snaps
     */
    buy_in_created_tenant?: number;
    /**
     * Sum of buy-ins of tenant-created Snaps increase/decrease percentage compared to last month
     */
    buy_in_created_tenant_change?: number;
    /**
     * Sum of buy-ins of player-created Snaps
     */
    buy_in_created_user?: number;
    /**
     * Sum of buy-ins of player-created Snaps increase/decrease percentage compared to last month
     */
    buy_in_created_user_change?: number;
    /**
     * Sum of pay-out
     */
    pay_out_total?: number;
    /**
     * Sum of pay-out increase/decrease percentage compared to last month
     */
    pay_out_total_change?: number;
    /**
     * Sum of pay-out of tenant-created Snaps
     */
    pay_out_created_tenant?: number;
    /**
     * Sum of pay-out of tenant-created Snaps increase/decrease percentage compared to last month
     */
    pay_out_created_tenant_change?: number;
    /**
     * Sum of pay-out of player-created Snaps
     */
    pay_out_created_user?: number;
    /**
     * Sum of pay-out of player-created Snaps increase/decrease percentage compared to last month
     */
    pay_out_created_user_change?: number;
}

