import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

import {
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesVendorGamesTenantVendorGameHttpResponse as TenantVendorGameHttpResponse
} from '../../../api';
import {
    AuthenticationService,
    ErrorService,
    GoogleAnalyticsService, MyModalService, MyNotificationsService,
    stringNumberInputToFloat,
    TenantImageTypeEnum,
    TenantService
} from '../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, take, takeWhile} from 'rxjs/operators';
import {NotificationType} from 'angular2-notifications';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'betsnaps-singleplayer-join-modal',
    templateUrl: './singleplayer-join-modal.component.html',
    styles: []
})
export class SingleplayerJoinModalComponent implements OnInit, OnDestroy {

    protected componentAlive = true;

    @Input() title: string;
    @Input() tenantVendorGame: TenantVendorGameHttpResponse;
    @Input() profileHeaderImage: string;

    @Output() apiErrorOccured: EventEmitter<any> = new EventEmitter();
    @Output() pointsConfirmed: EventEmitter<number> = new EventEmitter();
    @Output() pointsChanged: EventEmitter<any> = new EventEmitter();

    @Output() onApprove: EventEmitter<any> = new EventEmitter();
    @Output() onDeny: EventEmitter<any> = new EventEmitter();

    protected initialPoints: number = 100.00;
    public points: number = 0.00;
    public freePoints: number = 0.00;
    public pointsControl: UntypedFormControl = new UntypedFormControl();

    protected pointCheckChangedValue: boolean = false;
    public confirmPointsProcess: boolean = false;

    protected singleplayerJoinModal = null;

    @ViewChild('singleplayerJoinModalTemplate') public singleplayerJoinModalTemplate: ModalTemplate<null, string, string>;

    constructor(@Inject(DOCUMENT) private document: any,
                public authenticationService: AuthenticationService,
                public googleAnalyticsService: GoogleAnalyticsService,
                private myNotificationsService: MyNotificationsService,
                protected myModalService: MyModalService,
                protected translateService: TranslateService,
                protected tenantService: TenantService,
                protected errorService: ErrorService) {

        if (!this.profileHeaderImage && this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER)) {
            this.profileHeaderImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_HEADER).media_url;
        }

        // Subscribe to value changes of input field
        this.pointsControl.valueChanges
            .pipe(
                takeWhile(() => this.componentAlive),
                debounceTime(1000)
            ).subscribe(inputValue => {
            this.pointsInputChange(inputValue);
        });
    }

    ngOnInit() {
        if (this.authenticationService.currentUser) {
            const userBalance = this.authenticationService.currentUser.wallets[0].balance;
            if (userBalance === 0) {
                this.translateService.get(['GENERAL.LABELS.alert', 'GENERAL.SINGLE_PLAYER_GAME.insufficient_amount_to_start'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast(translation['GENERAL.LABELS.warning'], translation['GENERAL.SINGLE_PLAYER_GAME.insufficient_amount_to_start'], NotificationType.Alert);
                    });
            } else {
                if (userBalance < this.initialPoints) {
                    this.setPoints(userBalance);
                } else {
                    this.setPoints(this.initialPoints);
                }
                this.freePoints = userBalance;
            }
        }

        // get current user updates
        this.authenticationService.currentUser$
            .subscribe(
                (userData: PlayerHttpResponse) => {
                    if (!this.confirmPointsProcess) {
                        if (userData) {
                            this.freePoints = userData.wallets[0].balance;
                            if (this.points > this.freePoints) {
                                this.setPoints(this.freePoints);
                            }
                        } else {
                            this.freePoints = 0.00;
                        }
                    }
                }
            );
    }

    ngOnDestroy() {
        this.confirmPointsProcess = false;
        this.componentAlive = false;
    }

    increasePoints(value: number): void {
        let newValue = this.points + value;
        if (newValue > this.freePoints) {
            newValue = this.freePoints;
        }
        this.setPoints(newValue);
    }

    decreasePoints(value: number): void {
        let newValue = this.points - value;
        if (newValue < 0) {
            newValue = 0;
        }
        this.setPoints(newValue);
    }

    allIn(): void {
        this.setPoints(this.freePoints);
    }

    inputOnFocus() {
        setTimeout(() => {
            this.document.getElementById('points-' + this.tenantVendorGame.id).select();
        }, 100);
        this.pointCheckChangedValue = true;
    }

    inputOnBlur() {
        this.pointCheckChangedValue = false;
    }

    // only on user input
    private pointsInputChange(value, doCheck = false, doFocus = true): void {
        let newValue = stringNumberInputToFloat(value);
        if ((this.pointCheckChangedValue && newValue !== this.points) || doCheck) {
            if (isNaN(newValue) || newValue < 0) {
                newValue = 0;
            } else if (newValue > this.freePoints) {
                newValue = this.freePoints;
            }
            this.setPoints(newValue);
            if (doFocus) {
                setTimeout(() => {
                    this.document.getElementById('points-' + this.tenantVendorGame.id).select();
                }, 100);
            }

            this.googleAnalyticsService.trackEvent('singleplayergamecard - points', 'change', 'input field');
        }
    }

    public confirmPoints(): void {
        // get current input value
        this.pointsInputChange(this.pointsControl.value, true, false);

        if (!this.confirmPointsProcess) {
            this.confirmPointsProcess = true;
            this.pointsConfirmed.emit(this.points);
        }
    }

    protected setPoints(points: number) {
        this.points = points;
        this.pointsChanged.emit();
    }

    public open() {
        const singleplayerJoinModalConfig = new TemplateModalConfig<null, string, string>(this.singleplayerJoinModalTemplate);
        singleplayerJoinModalConfig.size = ModalSize.Tiny;
        singleplayerJoinModalConfig.mustScroll = true;
        singleplayerJoinModalConfig.isClosable = true;
        this.singleplayerJoinModal = this.myModalService.openModal(
            singleplayerJoinModalConfig
        ).onApprove(() => {
            this.onApprove.emit();
        }).onDeny(() => {
            this.onDeny.emit();
        });
    }

    public close() {
        this.confirmPointsProcess = false;
        if (this.singleplayerJoinModal) {
            this.singleplayerJoinModal.approve();
        }
    }
}
