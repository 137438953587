import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GameVendorGameParticipationStatusEnum, GameVendorGameParticipationTransactionStatusEnum
} from '../../../shared';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse as GameVendorGameParticipationTransactionHttpResponse
} from '../../../api';

@Component({
    selector: 'betsnaps-game-vendor-game-participation',
    templateUrl: './game-vendor-game-participation.component.html',
    styles: []
})
export class GameVendorGameParticipationComponent implements OnInit, OnChanges {

    @Input() game: GameHttpResponse;
    @Input() gameVendorGameParticipation: GameVendorGameParticipationHttpResponse;
    @Input() gameVendorGameParticipationUpdatedDate: Date;

    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;
    public processCashOut = false;

    public cashoutValue: number = 0;

    constructor(private authenticationService: AuthenticationService,
                private betsnapdetailService: BetsnapdetailService,
                private errorService: ErrorService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cashoutValue = this.gameVendorGameParticipation.game_user_points;

        this.activeParticipationTransactions().forEach(
            (gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) => {
                this.cashoutValue += gameVendorGameParticipationTransaction.total_points;
            });
    }

    participationSaldo(): number {
        let participationSaldo = 0;
        this.closedParticipationTransactions().forEach(
            (gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) => {
                participationSaldo += (gameVendorGameParticipationTransaction.total_points - gameVendorGameParticipationTransaction.points);
            });
        return participationSaldo;
    }

    cashOutVendorGameParticipation() {
        if (this.authenticationService.currentUser && this.game.is_current_user_joined) {
            this.processCashOut = true;
            this.betsnapdetailService.closeGameVendorGameParticipation(
                this.game.game_unique_id,
                this.gameVendorGameParticipation.id,
                this.gameVendorGameParticipation.game_user_points,
            ).pipe(take(1))
            .subscribe({
                next: (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                    // TODO: notification "participation closed successfully"
                    this.betsnapdetailService.addToUserGamePoints(gameVendorGameParticipation.game_user_points);
                    this.processCashOut = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.processCashOut = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
        }
    }

    isCashoutAvailable() {
        return (
            this.gameVendorGameParticipation.status === this.gameVendorGameParticipationStatusEnum.ACTIVE &&
            this.activeParticipationTransactions().length === 0
        );
    }

    gameVendorGameParticipationTransactionsTrackBy(index: number, gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse): number {
        return gameVendorGameParticipationTransaction.id;
    }

    public activeParticipationTransactions(): GameVendorGameParticipationTransactionHttpResponse[] {

        if (!this.gameVendorGameParticipation.game_vendor_game_participation_transactions) {
            return [];
        }

        return this.gameVendorGameParticipation.game_vendor_game_participation_transactions.filter(
            (gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) =>  (gameVendorGameParticipationTransaction.status === GameVendorGameParticipationTransactionStatusEnum.ACTIVE || gameVendorGameParticipationTransaction.status === GameVendorGameParticipationTransactionStatusEnum.INACTIVE)
        );
    }

    public closedParticipationTransactions(): GameVendorGameParticipationTransactionHttpResponse[] {

        if (!this.gameVendorGameParticipation.game_vendor_game_participation_transactions) {
            return [];
        }

        return this.gameVendorGameParticipation.game_vendor_game_participation_transactions.filter(
            (gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) =>  gameVendorGameParticipationTransaction.status === GameVendorGameParticipationTransactionStatusEnum.CLOSED
        );
    }

}
