import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'betsnaps-script-include',
    templateUrl: './script-include.component.html',
    styles: []
})
export class ScriptIncludeComponent implements OnInit, AfterViewInit {

    @Input() src: string;
    @Input() type: string;

    @ViewChild('script', { static: true }) script: ElementRef;
    @Input() parentElement: ElementRef;

    constructor(@Inject(DOCUMENT) private document: any) {
    }

    convertToScript() {
        const element = this.script.nativeElement;
        const script = this.document.createElement('script');
        script.type = this.type ? this.type : 'text/javascript';
        // script.async = true;
        if (this.src) {
            script.src = this.src;
        }
        if (element.innerHTML) {
            script.innerHTML = element.innerHTML;
        }
        const parent = element.parentElement;
        parent.parentElement.replaceChild(script, parent);
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.convertToScript();
    }

}
