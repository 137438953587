import { ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, Directive } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';

import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as UserBetHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse,
    AppHttpResponsesSportsDataMatchMarketHttpResponse as MatchMarketHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {
    AuthenticationService,
    BetsnapdetailService,
    ErrorService,
    GameCashoutOptionEnum,
    GamePointsEngineEnum,
    GoogleAnalyticsService,
    stringNumberInputToFloat,
    TenantService
} from '../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, take, takeWhile} from 'rxjs/operators';

@Directive()
export abstract class APlaceBetDialogComponent implements OnInit, OnChanges, OnDestroy {

    protected componentAlive = true;

    @Input() marketUpdatedDate: Date;
    @Input() match: MatchHttpResponse;
    @Input() market: MatchMarketHttpResponse;
    @Input() outcome: MatchMarketOutcomeHttpResponse;
    @Input() currentUser: PlayerHttpResponse;
    @Input() oddDisplayFormat: string = 'value';

    @Output() apiErrorOccured: EventEmitter<any> = new EventEmitter();
    @Output() betPlaced: EventEmitter<any> = new EventEmitter();
    @Output() betPointsChanged: EventEmitter<any> = new EventEmitter();

    public outcomeChange: number; // 1 = up; 0 = same; -1 = down
    protected oldOutcomeValue: number;

    public game: GameHttpResponse;

    protected initialBetPoints: number = 100.00;
    public betPoints: number = 0.00;
    public freePoints: number = 0.00;
    public betPointsControl: UntypedFormControl = new UntypedFormControl();

    protected betPointCheckChangedValue: boolean = false;
    public preGameStartOddsCanChange: boolean = false;
    public gameUsesPredictionPointsEngine: boolean = false;

    public placeBetProcess: boolean = false;

    @ViewChild('placeBetContent', {static: true}) public placeBetEL: ElementRef;
    @ViewChild('betPointsInput', {static: false}) public betPointsInput: ElementRef;

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected translateService: TranslateService,
                protected tenantService: TenantService,
                protected errorService: ErrorService) {

        // Subscribe to value changes of input field
        this.betPointsControl.valueChanges
            .pipe(
                takeWhile(() => this.componentAlive),
                debounceTime(1000)
            ).subscribe(inputValue => {
            this.betPointsInputChange(inputValue);
        });

    }

    ngOnInit() {

        this.game = this.betsnapdetailService.game;
        this.gameUsesPredictionPointsEngine = this.game.points_engine === GamePointsEngineEnum.PREDICTION;

        if (this.game.game_user.user_game_points < this.initialBetPoints) {
            this.setBetPoints(this.game.game_user.user_game_points);
        } else {
            this.setBetPoints(this.initialBetPoints);
        }

        this.freePoints = this.game.game_user.user_game_points;
        this.oldOutcomeValue = this.outcome.odd_decimal;
        this.preGameStartOddsCanChange = this.betsnapdetailService.preGameStartOddsCanChange;

        this.betsnapdetailService.substractFromUserGamePoints(this.betPoints);

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((gameHttpResponse: GameHttpResponse) => {
                if (gameHttpResponse) {
                    if (gameHttpResponse.game_user) {
                        if (!this.placeBetProcess) {
                            this.reduceBetPointsIfUserGamePointsAreNegative(gameHttpResponse);
                        }
                        this.freePoints = gameHttpResponse.game_user.user_game_points + this.betPoints;
                    }

                    this.game = gameHttpResponse;
                }
            });

    }

    protected reduceBetPointsIfUserGamePointsAreNegative(game: GameHttpResponse) {
        if (game.game_user.user_game_points < 0) {

            let newBetPoints = this.betPoints + game.game_user.user_game_points;

            if (newBetPoints < 0) {
                newBetPoints = 0;
            }

            const difference = this.betPoints - newBetPoints;
            this.setBetPoints(newBetPoints);
            this.betsnapdetailService.addToUserGamePoints(difference);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.marketUpdatedDate && !changes.marketUpdatedDate.firstChange) {
            // compare outcomes
            if (this.oldOutcomeValue > this.outcome.odd_decimal) {
                this.outcomeChange = -1;
            } else if (this.oldOutcomeValue < this.outcome.odd_decimal) {
                this.outcomeChange = 1;
            } else {
                this.outcomeChange = 0;
            }
            this.oldOutcomeValue = this.outcome.odd_decimal;
        }

        if (changes.oddDisplayFormat && !changes.oddDisplayFormat.currentValue) {
            this.oddDisplayFormat = this.tenantService.tenantData.internationalization.odd_display_format;
        }

        this.preGameStartOddsCanChange = this.betsnapdetailService.preGameStartOddsCanChange;
    }

    ngOnDestroy() {
        if (!this.placeBetProcess) {
            this.betsnapdetailService.addToUserGamePoints(this.betPoints);
            this.betsnapdetailService.setPlaceBetDialogBetPoints(0);
        }

        this.placeBetProcess = false;
        this.componentAlive = false;
    }

    increaseBetPoints(value: number): void {
        let newValue = this.betPoints + value;
        if (newValue > this.freePoints) {
            newValue = this.freePoints;
        }

        const difference = newValue - this.betPoints;

        this.setBetPoints(newValue);
        this.betsnapdetailService.substractFromUserGamePoints(difference);
    }

    decreaseBetPoints(value: number): void {
        let newValue = this.betPoints - value;
        if (newValue < 0) {
            newValue = 0;
        }

        const difference = newValue - this.betPoints;

        this.setBetPoints(newValue);
        this.betsnapdetailService.substractFromUserGamePoints(difference);
    }

    allIn(): void {
        const difference = this.freePoints - this.betPoints;
        this.setBetPoints(this.freePoints);
        this.betsnapdetailService.substractFromUserGamePoints(difference);
    }

    inputOnFocus() {
        setTimeout(() => {
            this.betPointsInput.nativeElement.select();
        }, 100);
        this.betPointCheckChangedValue = true;
    }

    inputOnBlur() {
        this.betPointCheckChangedValue = false;
    }

    // only on user input
    betPointsInputChange(value, doCheck = false, doFocus = true): void {
        let newValue = stringNumberInputToFloat(value);
        if ((this.betPointCheckChangedValue && newValue !== this.betPoints) || doCheck) {
            if (isNaN(newValue) || newValue < 0) {
                newValue = 0;
            } else if (newValue > this.freePoints) {
                newValue = this.freePoints;
            }
            this.setBetPoints(newValue);
            this.betsnapdetailService.updateUserGamePoints(this.freePoints - newValue);
            if (doFocus) {
                setTimeout(() => {
                    this.betPointsInput.nativeElement.select();
                }, 100);
            }

            this.googleAnalyticsService.trackEvent('matchcard - bet', 'change', 'input field');
        }
    }

    placeBet(): void {

        if (this.gameUsesPredictionPointsEngine) {
            this.setBetPoints(1);
        } else {
            // get current input value
            this.betPointsInputChange(this.betPointsControl.value, true, false);
        }

        if (!this.placeBetProcess) {
            this.placeBetProcess = true;
            this.betsnapdetailService.addPlaceBetDialogPendingBet();
            this.betsnapdetailService.placeBet(this.betPoints, this.outcome, this.currentUser.id)
                .pipe(take(1))
                .subscribe({
                    next: (userBet: UserBetHttpResponse) => {

                        this.betsnapdetailService.removePlaceBetDialogPendingBet();
                        this.betsnapdetailService.setPlaceBetDialogBetPoints(0);

                        this.betPlaced.emit();

                        this.googleAnalyticsService.trackEvent(
                            'matchcard - bet',
                            'place',
                            this.outcome.market_id + '-' + this.outcome.outcome_id + '-' + this.outcome.specifier_val
                        );
                    },
                    error: (err: HttpErrorResponse) => {
                        this.placeBetProcess = false;
                        this.betsnapdetailService.removePlaceBetDialogPendingBet();
                        this.betsnapdetailService.reduceUserBetCount();

                        if (!this.componentAlive) {
                            this.betsnapdetailService.addToUserGamePoints(this.betPoints);
                        }

                        if (err.error && err.error.error && err.error.error === 'odd_bet_value_not_allowed_difference') {
                            this.betsnapdetailService.getMatchMarkets(this.game.game_unique_id, this.outcome.match_id, true);
                        }

                        this.apiErrorOccured.emit();
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    protected setBetPoints(betPoints: number) {
        this.betPoints = betPoints;
        this.betsnapdetailService.setPlaceBetDialogBetPoints(betPoints);
        this.betPointsChanged.emit();
    }

    cashoutOddLimitViolation(): boolean {
        return (this.game.cashout_odd_limit !== null && this.outcome.odd_decimal >= this.game.cashout_odd_limit);
    }

    cashoutAvailable(): boolean {
        return (this.game.cashout_option === GameCashoutOptionEnum.ALWAYS ||
            this.game.cashout_option === GameCashoutOptionEnum.ONLY_PRE_GAME && this.game.game_state < 3 ||
            this.game.cashout_option === GameCashoutOptionEnum.ONLY_PRE_MATCH && this.match.status !== 'live');
    }
}
