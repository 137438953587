<div class="ui centered relaxed grid container">
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <div class="ui center aligned borderless vertical segment margin-0">
            <h2 class="ui center aligned small header">
                {{'RESETPASSWORD.heading' | translate | uppercase}}
            </h2>
        </div>
    </div>
    <div class="sixteen wide mobile fourteen wide tablet ten wide computer center aligned column">
        <div class="ui borderless vertical segment margin-0 padding-b-0">
            <fui-message *ngIf="showAlert" class="{{alertMessage.type ?? ''}}" [hasDismissButton]="false">
                <div class="header" *ngIf="alertMessage.messagetranslatekey">
                    {{alertMessage.messagetranslatekey | translate}}
                </div>
                <p *ngIf="alertMessage.messagetxttranslatekey">
                    {{alertMessage.messagetxttranslatekey | translate}}
                </p>
            </fui-message>

            <form *ngIf="!showAlert" class="ui form" novalidate [formGroup]="initPasswordReset"
                  [class.error]="!processForm"
                  (ngSubmit)="onSubmit()">
                <div class="field">
                    <div class="ui left input">
                        <input type="text"
                               id="passwordreset_identifier"
                               name="passwordreset_identifier"
                               placeholder="{{'RESETPASSWORD.identifier_field' | translate}}"
                               formControlName="passwordreset_identifier"
                               required/>
                    </div>
                    <div
                        *ngIf="initPasswordReset.get('passwordreset_identifier').touched && initPasswordReset.get('passwordreset_identifier').invalid"
                        class="ui pointing red basic label">
                        {{'RESETPASSWORD.ERRORS.identifier_field_required' | translate}}
                    </div>
                </div>
                <div class="ui borderless vertical padded segment center aligned margin-0 padding-t-10 padding-b-0">
                    <button class="ui large fluid button margin-0"
                            type="submit"
                            [class.loading]="processForm"
                            [disabled]="processForm || initPasswordReset.invalid">
                        {{'RESETPASSWORD.initialize_btn' | translate | uppercase}}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="ui sixteen wide center aligned column padding-b-0">
        <div class="ui row divider"></div>
        <div class="color-grey">
            {{'LOGIN.no_account_label' | translate}}
            <a class="text font-bold padding-l-5 nowrap"
               (click)="showView('register')">
                {{'LOGIN.no_account_btn' | translate}}
            </a>
        </div>
    </div>
</div>
