/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse } from '../model/appHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class GlobalRankingsApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get all Tenant-Rankings
     * Get all Tenant-Rankings
     * @param tenant_id ID of Tenant
     * @param get_history Filter for only Rankings with Start-Date &lt; \&#39;date\&#39; and End-Date &lt; \&#39;date\&#39;
     * @param get_positions_count Get positions count
     * @param get_user_rank Get user rank
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param authorization Valid oAuth access_token (Format-Hint: Bearer {Token}) (optional)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdRankingsGet(tenant_id: number, get_history?: boolean, get_positions_count?: boolean, get_user_rank?: boolean, per_page?: number, page?: number, authorization?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>;
    public apiTenantsTenantIdRankingsGet(tenant_id: number, get_history?: boolean, get_positions_count?: boolean, get_user_rank?: boolean, per_page?: number, page?: number, authorization?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>>;
    public apiTenantsTenantIdRankingsGet(tenant_id: number, get_history?: boolean, get_positions_count?: boolean, get_user_rank?: boolean, per_page?: number, page?: number, authorization?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>>;
    public apiTenantsTenantIdRankingsGet(tenant_id: number, get_history?: boolean, get_positions_count?: boolean, get_user_rank?: boolean, per_page?: number, page?: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdRankingsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (get_history !== undefined && get_history !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>get_history, 'get_history');
        }
        if (get_positions_count !== undefined && get_positions_count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>get_positions_count, 'get_positions_count');
        }
        if (get_user_rank !== undefined && get_user_rank !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>get_user_rank, 'get_user_rank');
        }
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a specific Tenant-Rankings Details
     * Get a specific Tenant-Rankings Details
     * @param tenant_id ID of Tenant
     * @param tenant_ranking_id Tenant-Ranking-Id
     * @param authorization Valid oAuth access_token (Format-Hint: Bearer {Token}) (optional)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, authorization?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>;
    public apiTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, authorization?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>>;
    public apiTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, authorization?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>>;
    public apiTenantsTenantIdRankingsTenantRankingIdGet(tenant_id: number, tenant_ranking_id: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdRankingsTenantRankingIdGet.');
        }
        if (tenant_ranking_id === null || tenant_ranking_id === undefined) {
            throw new Error('Required parameter tenant_ranking_id was null or undefined when calling apiTenantsTenantIdRankingsTenantRankingIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings/${this.configuration.encodeParam({name: "tenant_ranking_id", value: tenant_ranking_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsFullTenantRankingHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get specific Tenant-Rankings Leaderboard
     * Get specific Tenant-Rankings Leaderboard
     * @param tenant_id ID of Tenant
     * @param tenant_ranking_id Tenant-Ranking-Id
     * @param only_friends Show only users who are friends of authenticated user
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param authorization Valid oAuth access_token (Format-Hint: Bearer {Token}) (optional)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, only_friends?: boolean, per_page?: number, page?: number, authorization?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse>;
    public apiTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, only_friends?: boolean, per_page?: number, page?: number, authorization?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse>>;
    public apiTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, only_friends?: boolean, per_page?: number, page?: number, authorization?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse>>;
    public apiTenantsTenantIdRankingsTenantRankingIdLeaderboardGet(tenant_id: number, tenant_ranking_id: number, only_friends?: boolean, per_page?: number, page?: number, authorization?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdRankingsTenantRankingIdLeaderboardGet.');
        }
        if (tenant_ranking_id === null || tenant_ranking_id === undefined) {
            throw new Error('Required parameter tenant_ranking_id was null or undefined when calling apiTenantsTenantIdRankingsTenantRankingIdLeaderboardGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (only_friends !== undefined && only_friends !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_friends, 'only_friends');
        }
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/rankings/${this.configuration.encodeParam({name: "tenant_ranking_id", value: tenant_ranking_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/leaderboard`;
        return this.httpClient.request<AppHttpResponsesBetsnapsRankingsTenantRankingPositionListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
