import { AfterViewInit, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, SimpleChanges, ViewContainerRef, Directive } from '@angular/core';
import {cloneDeep} from 'lodash';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as UserBetHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse,
    AppHttpResponsesSportsDataMatchMarketHttpResponse as MatchMarketHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../api';
import {
    AdService,
    AuthenticationService,
    BetsnapdetailService,
    GameBetPlacementOptionEnum,
    GamePointsEngineEnum,
    GoogleAnalyticsService,
    HintService,
    SportEnum,
    TenantService,
    UserBetStateEnum
} from '../../../shared';
import {AngularPageVisibilityService, AngularPageVisibilityStateEnum} from 'angular-page-visibility-v2';
import * as moment from 'moment';
import {takeWhile} from 'rxjs/operators';
import * as postscribe from 'betsnap-krux-postscribe';
import {FeedService} from '../../../shared/services/feed.service';
import {TenantContentComponent} from '../tenant-content/tenant-content.component';

@Directive()
export abstract class AMatchCardComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() adPositions: number[];
    @Input() matchIndexInList: number;

    protected componentAlive = true;

    public currentUser: PlayerHttpResponse;
    public game: GameHttpResponse;
    public match: MatchHttpResponse;
    public leaderBoardBets: UserBetHttpResponse[][];

    public gameUpdatedDate: Date;
    public matchStatus: string;
    public isCancelled: boolean = false;
    public isCurrentUserJoined: boolean;

    public marketsVisible: boolean = false;
    public activeBetsVisible: boolean = false;
    public completedBetsVisible: boolean = false;

    public isLeaderBoardMatch: boolean = false;
    public compactUserBetView: boolean = false;

    public onPlaceBetClick: EventEmitter<any> = new EventEmitter();

    public matchMarkets: MatchMarketHttpResponse[];
    public specialMarketsLoaded: boolean = false;

    public activeBets: UserBetHttpResponse[] = [];
    public completedBets: UserBetHttpResponse[] = [];
    public completedBetsTotalPoints: number = 0;

    public categoryImageSrc = './assets/img/match_flags/international.svg';

    public gameBetPlacementOptionEnum = GameBetPlacementOptionEnum;

    public betPlacementAllowed: boolean = true;

    public matchStatisticWidgetAvailableSportIds = [SportEnum.SOCCER, SportEnum.CRICKET];

    private adComponentRef: ComponentRef<TenantContentComponent>;

    public SportEnum = SportEnum;
    public gamePointsEngineEnum = GamePointsEngineEnum;

    constructor(protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                protected pageVisibilityService: AngularPageVisibilityService,
                protected hintService: HintService,
                public googleAnalyticsService: GoogleAnalyticsService,
                public tenantService: TenantService,
                public feedService: FeedService,
                protected adService: AdService,
                protected viewContainerRef: ViewContainerRef) {
    }

    ngOnInit() {

        if (this.match.tournament.category_icon) {
            this.categoryImageSrc = './assets/img/match_flags/' + this.match.tournament.category_icon;
        }

        if (!this.isLeaderBoardMatch) {
            this.betsnapdetailService.userBets$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (userBetsMatchGroup: UserBetHttpResponse[][]) => {
                        if (userBetsMatchGroup && userBetsMatchGroup['match' + this.match.match_id]) {
                            if (userBetsMatchGroup['match' + this.match.match_id].length > 0) {

                                // sort bets
                                userBetsMatchGroup['match' + this.match.match_id].sort(function (bet1, bet2) {
                                    if (bet1.user_bet_id > bet2.user_bet_id) {
                                        return -1;
                                    } else if (bet1.user_bet_id < bet2.user_bet_id) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                });

                                this.activeBets = userBetsMatchGroup['match' + this.match.match_id]
                                    .filter((userBet: UserBetHttpResponse) => (userBet.status === UserBetStateEnum.ACTIVE || userBet.status === UserBetStateEnum.PENDING || userBet.status === UserBetStateEnum.CASHOUT_PENDING || this.betsnapdetailService.isFailedBet(userBet)));
                                this.completedBets = userBetsMatchGroup['match' + this.match.match_id]
                                    .filter((userBet: UserBetHttpResponse) => (userBet.status !== UserBetStateEnum.ACTIVE && userBet.status !== UserBetStateEnum.PENDING && userBet.status !== UserBetStateEnum.CASHOUT_PENDING));
                                if (this.completedBetsVisible) {
                                    this.calculateTotalCompletedBetPoints();
                                }
                            }
                        } else {
                            this.activeBets = [];
                            this.completedBets = [];
                        }
                    }
                );
            this.betsnapdetailService.matchesMarkets$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (matchesMarkets: MatchMarketHttpResponse[][]) => {
                        if (matchesMarkets && matchesMarkets[this.match.match_id]) {
                            if (matchesMarkets[this.match.match_id].length > 0) {
                                this.specialMarketsLoaded = true;

                                let markets = cloneDeep(matchesMarkets[this.match.match_id]);
                                markets = this.betsnapdetailService.removeInactiveMarketsAndOutcomes(markets);

                                // handle default market
                                if (this.match.default_market && markets.length > 0) {
                                    this.match.default_market = this.betsnapdetailService.setMatchDefaultMarket(this.match.match_id, markets);
                                    if (this.match.default_market) {
                                        // remove default market from special markets
                                        markets = markets.filter(
                                            (market: MatchMarketHttpResponse) =>
                                                market.market_id + '_' + market.specifier_val !==
                                                this.match.default_market.market_id + '_' + this.match.default_market.specifier_val
                                        );
                                    }
                                }

                                if (markets.length > 0) {
                                    if (this.betsnapdetailService.gameSettings.tenant_market_ids) {
                                        const tenantMarketIds = this.betsnapdetailService.gameSettings.tenant_market_ids;

                                        // sort markets
                                        markets.sort(function (market1, market2) {
                                            return tenantMarketIds.indexOf(market1.market_id) - tenantMarketIds.indexOf(market2.market_id);
                                        });
                                    }
                                }

                                this.matchMarkets = markets;
                                this.match.markets_count = this.matchMarkets.length;

                                // if placebetdialog is opened
                                this.handleOpenedPlaceBetDialog();
                            }
                        } else {
                            this.matchMarkets = null;
                        }
                    }
                );

            // handle page suspends
            this.pageVisibilityService.$onPageVisibilityChange
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe((visibilityState: AngularPageVisibilityStateEnum) => {
                    if (visibilityState === AngularPageVisibilityStateEnum.VISIBLE) {
                        this.refreshData();
                    }
                });

            this.feedService.feedPrematchProducerUp$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe((isProductUp: boolean) => {
                    this.betPlacementAllowed = this.betsnapdetailService.isBetPlacementAllowed(this.match);
                });

            this.feedService.feedLiveProducerUp$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe((isProductUp: boolean) => {
                    this.betPlacementAllowed = this.betsnapdetailService.isBetPlacementAllowed(this.match);
                });

        }

        // set completed bets of leaderboard user bets Input
        if (this.isLeaderBoardMatch && this.leaderBoardBets) {
            this.completedBetsVisible = true;
            if (this.leaderBoardBets && this.leaderBoardBets['match' + this.match.match_id]) {
                if (this.leaderBoardBets['match' + this.match.match_id].length > 0) {
                    this.completedBets = this.leaderBoardBets['match' + this.match.match_id]
                        .filter((userBet: UserBetHttpResponse) => (userBet.status !== UserBetStateEnum.ACTIVE && userBet.status !== UserBetStateEnum.PENDING && userBet.status !== UserBetStateEnum.CASHOUT_PENDING));
                    this.calculateTotalCompletedBetPoints();
                }
            }
        }

        // place ad
        const adSlotPosition: number = this.matchIndexInList + 1;
        this.adComponentRef = this.adService.placeTenantAd('game-content', adSlotPosition, this.viewContainerRef, this.adPositions, this.matchIndexInList, 'matchCard');
    }

    ngAfterViewInit(): void {
        if (this.showWidget()) {
            // load widget
            const widgetMatchId = (this.game.is_simulation) ? this.match.simulation_original_match_id : this.match.match_id;
            setTimeout(() => {
                postscribe('#sr-widget-script-' + this.match.match_id,
                    '<script> SIR("addWidget", ".sr-widget-' + this.match.match_id + '", "match.lmtPlus", {' +
                    'showOdds:false,' +
                    'adsFrequency:false,' +
                    'scoreboard: "disable", ' +
                    'collapseTo: "momentum", ' +
                    'goalBannerCustomBgColor: "#333333", ' +
                    'matchId:' + widgetMatchId + ',' +
                    'onTrack: function(eventType, eventData) { ' +
                    'if (eventType === "license_error" || (eventType === "data_change" && eventData.error !== null)) { ' +
                    'SIR("removeWidget", ".sr-widget-' + this.match.match_id + '");' +
                    'this.document.getElementById("sr-widget-' + this.match.match_id + '").style.display = "none"; ' +
                    '}' +
                    '}' +
                    '}' +
                    '); </script>');
            }, 50);
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
        if (this.adComponentRef) {
            this.adComponentRef.destroy();
        }
    }

    onChanges(changes: SimpleChanges) {
        this.betPlacementAllowed = this.betsnapdetailService.isBetPlacementAllowed(this.match);

        if (this.isCancelled || (this.match.score && this.match.score.match_status === 'cancelled')) {
            this.match.status = 'cancelled';
        }

        // recalculate total bet points for completed bets
        if (changes.completedBetsVisible && changes.completedBetsVisible.currentValue === true && !changes.completedBetsVisible.firstChange) {
            this.calculateTotalCompletedBetPoints();
        }

        // update placeBetOutcome if special markets not Loaded
        if (!this.specialMarketsLoaded) {
            this.handleOpenedPlaceBetDialog();
        }
    }

    marketsTrackBy(index: number, market: MatchMarketHttpResponse) {
        return market.match_id + '-' + market.market_id + '-' + market.specifier_val;
    }

    outcomesTrackBy(index: number, outcome: MatchMarketOutcomeHttpResponse) {
        return outcome.match_id + '-' + outcome.market_id + '-' + outcome.specifier_val + '-' + outcome.outcome_id;
    }

    userBetsTrackBy(index: number, userBet: UserBetHttpResponse): number {
        return userBet.user_bet_id;
    }

    protected abstract handleOpenedPlaceBetDialog();

    calculateTotalCompletedBetPoints() {
        // calculate completedBets total points
        let total_point = 0;
        let match_total = 0;
        this.completedBets.forEach(
            (userBet: UserBetHttpResponse) => {
                let pot_win = 0;
                if (userBet.status === UserBetStateEnum.SETTLED || userBet.status === UserBetStateEnum.CASHED_OUT ||
                    userBet.status === UserBetStateEnum.ROLLED_BACK_BET_SETTLEMENT || userBet.status === UserBetStateEnum.ROLLED_BACK_BET_CANCELLATION ||
                    userBet.status === UserBetStateEnum.AUTO_CASHED_OUT) {
                    pot_win = userBet.total_point;
                    if (this.game.points_engine === this.gamePointsEngineEnum.PREDICTION) {
                        pot_win += 1;
                    }
                } else if (userBet.status === UserBetStateEnum.CANCELLED || userBet.status === UserBetStateEnum.FAILED) {
                    pot_win = userBet.point;
                }
                let bet_point = userBet.point;
                // Rollback Bet Cancel / Rollback Bet Settlement --> Minus
                if (userBet.status === UserBetStateEnum.ROLLED_BACK_BET_SETTLEMENT || userBet.status === UserBetStateEnum.ROLLED_BACK_BET_CANCELLATION) {
                    pot_win = pot_win * (-1);
                    bet_point = bet_point * (-1);
                }
                match_total = match_total + pot_win;
                total_point = total_point + bet_point;
            }
        );
        this.completedBetsTotalPoints = match_total - total_point;
    }

    public refreshData() {
        if (this.game && this.game.game_unique_id && this.matchMarkets) {
            // get market list for match
            this.betsnapdetailService.getMatchMarkets(
                this.game.game_unique_id,
                this.match.match_id,
                true
            );
        }
    }

    public matchHasStarted(): boolean {
        return moment(this.match.season_scheduled_date).isBefore(moment().utc());
    }

    public showMatchTime(): boolean {
        let showMatchTime = false;

        if (this.match.sport_id === SportEnum.BASKETBALL || this.match.sport_id === SportEnum.ICE_HOCKEY) {
            // basketball or ice hockey
            showMatchTime = this.match.score.match_status_code &&
                this.match.score.matchtime &&
                this.match.score.match_status_code.betradar_status_id !== 31 &&
                this.match.score.match_status_code.betradar_status_id !== 32 &&
                this.match.score.match_status_code.betradar_status_id !== 90 &&
                this.match.score.match_status_code.betradar_status_id !== 100 &&
                this.match.score.match_status_code.betradar_status_id !== 301 &&
                this.match.score.match_status_code.betradar_status_id !== 302 &&
                this.match.score.match_status_code.betradar_status_id !== 303;
        } else if (this.match.sport_id !== SportEnum.CRICKET) {
            // not cricket
            showMatchTime = this.match.score.match_status_code &&
                this.match.score.matchtime &&
                this.match.score.match_status_code.betradar_status_id !== 31 &&
                this.match.score.match_status_code.betradar_status_id !== 32 &&
                this.match.score.match_status_code.betradar_status_id !== 33 &&
                this.match.score.match_status_code.betradar_status_id !== 34 &&
                this.match.score.match_status_code.betradar_status_id !== 36 &&
                this.match.score.match_status_code.betradar_status_id !== 50 &&
                this.match.score.match_status_code.betradar_status_id !== 51 &&
                this.match.score.match_status_code.betradar_status_id !== 52;
        }

        return showMatchTime;
    }

    public showWidget(): boolean {
        return (
            this.tenantService.tenantData.configuration.show_match_statistics_widget &&
            this.matchStatisticWidgetAvailableSportIds.includes(this.match.sport_id) &&
            !this.isCancelled &&
            this.match.status !== 'cancelled' &&
            this.match.status !== 'postponed'
        );
    }

    public isMatchCompleted(): boolean {
        return (
            this.match.status === 'closed' ||
            this.match.status === 'ended' ||
            this.match.status === 'cancelled' ||
            this.match.status === 'postponed' ||
            this.match.status === 'abandoned'
        );
    }

    public isMatchLive(): boolean {
        return (
            this.match.status === 'live' ||
            this.match.status === 'interrupted' ||
            this.match.status === 'suspended'
        );
    }

    public showRemainingTimeInPeriod(sportId: number): boolean {
        switch (sportId) {
            case SportEnum.BASKETBALL:
            case SportEnum.ICE_HOCKEY:
                return true;
        }

        return false;
    }
}
