import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, Params} from '@angular/router';

import {HttpErrorResponse} from '@angular/common/http';
import {
    areEqualValidator,
    AuthenticationService,
    TenantService,
    ErrorService, MyNotificationsService
} from '../../../shared';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NotificationType} from 'angular2-notifications';
import {take, takeWhile} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-reset-password',
    templateUrl: './reset-password.component.html',
    styles: []
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentClass = 'standardview';

    private componentAlive = true;

    public processForm = false;
    private newPasswordKey = null;

    public resetForm = new UntypedFormGroup({
        'passwordGroup': new UntypedFormGroup({
            newpassword: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
            newpassword_confirm: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
        }, areEqualValidator),
    });

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private myNotificationsService: MyNotificationsService,
                private translateService: TranslateService,
                private tenantService: TenantService,
                private errorService: ErrorService) {
    }

    resetPassword() {
        this.processForm = true;
        this.authenticationService.passwordEmailReset(this.newPasswordKey, this.resetForm.value.passwordGroup.newpassword)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.processForm = false;
                    this.resetForm.reset();
                    this.translateService.get(['RESETPASSWORD.reset_successfully_title', 'RESETPASSWORD.reset_successfully'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast(translation['RESETPASSWORD.reset_successfully_title'], translation['RESETPASSWORD.reset_successfully'], NotificationType.Success);
                        });
                    this.router.navigate(['/login']);
                    return;
                },
                error: (err: HttpErrorResponse) => {
                    this.processForm = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    ngOnInit() {

        if (this.authenticationService.validToken()) {
            this.router.navigate(['/arena']);
            return;
        }
        if (!this.authenticationService.validToken() && this.tenantService.noB2cSignupMethod) {
            this.router.navigate(['/']);
            return;
        }

        this.activatedRoute.params
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((params: Params) => {
                this.newPasswordKey = params['new_password_key'];
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

}
