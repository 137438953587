<betsnaps-layout *ngIf="initialLoadFinished"
                 id="betsnaps-layout"
                 [class.with-bg-image]="withBgImg"
                 [class.bg-dimmed]="withMaintenanceBgImg"
                 [isWidget]="isWidget"
                 [withMaintenanceBgImg]="withMaintenanceBgImg">
</betsnaps-layout>

<div class="ui vertical borderless very padded segment" *ngIf="!globalError && !initialLoadFinished">
    <div class="ui active centered inline text loader"></div>
</div>

<div class="ui grid padded centered" *ngIf="globalError && !initialLoadFinished">
    <div class="ui vertical borderless very padded segment">
        <div class="ui raised centered card">
            <div class="content">
                <div class="header">{{globalError.title}}</div>
                <div class="margin-t-15">
                    <span class="date">{{globalError.subTitle}}</span>
                </div>
            </div>
            <div class="image">
                <img class="ui image" [src]="globalError.imageSrc">
            </div>
            <div class="extra content">
                <fui-accordion-panel>
                    <div title>
                        {{globalError.message}}
                    </div>
                    <div content *ngIf="globalError.subMessage">
                        <p>{{globalError.subMessage}}</p>
                    </div>
                </fui-accordion-panel>
            </div>
        </div>
    </div>
</div>

<ng-template let-context let-modal="modal" #errorModalTemplate>
    <div class="ui header">
        <div class="error">
            <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24"
                 viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path
                    d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
            </svg>
        </div>
        <div class="content">
            {{'ERRORS.GENERAL.title' | translate}}
        </div>
    </div>
    <div class="content">
        <p>{{'ERRORS.GENERAL.unknown_error' | translate}}</p>
    </div>
    <div class="actions">
        <button class="ui error button margin-0" (click)="reloadPage()" autofocus>{{'GENERAL.reload_page' | translate | uppercase}}</button>
    </div>
</ng-template>

<ng-template let-context let-modal="modal" #alertModalTemplate>
    <div class="header">
        <div class="alert">
            <svg class="simple-notification-svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="64"
                 viewBox="0 0 64 64" height="64">
                <circle cx="32.086" cy="50.142" r="2.256"/>
                <path
                    d="M30.08 25.012V42.32c0 1.107.897 2.005 2.006 2.005s2.006-.897 2.006-2.005V25.012c0-1.107-.897-2.006-2.006-2.006s-2.006.898-2.006 2.006z"/>
                <path
                    d="M63.766 59.234L33.856 3.082c-.697-1.308-2.844-1.308-3.54 0L.407 59.234c-.331.622-.312 1.372.051 1.975.362.605 1.015.975 1.72.975h59.816c.705 0 1.357-.369 1.721-.975.361-.603.381-1.353.051-1.975zM5.519 58.172L32.086 8.291l26.568 49.881H5.519z"/>
            </svg>
        </div>
        <div class="content">
            {{ context.title }}
        </div>
    </div>
    <div class="content">
        <p>{{ context.text }}</p>
    </div>
    <div class="actions">
        <button class="ui alert button margin-0" (click)="modal.approve()" autofocus>OK</button>
    </div>
</ng-template>

<div class="ui page active dimmer hints-dimmer" *ngIf="generalHintsOverlayVisible">
    <div class="hints-dimmer-content">
        <swiper class="hint-swiper" #hintSwiper [config]="hintSwiperConfig" [initialize]="hintSwiperInit">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let hint of unreadGeneralHints; trackBy: hintTrackBy;">
                    <div class="ui vertical borderless segment padding-t-0" *ngIf="getHintMediaTranslationForLanguage(hint)">
                        <div class="ui fluid image center aligned">
                            <img [src]="getHintMediaTranslationForLanguage(hint).media_url" />
                        </div>
                    </div>
                    <div class="text-large margin-0 padding-r-5 padding-l-5 line-height-standard">
                        <p class="ui header margin-0"><strong>{{hint.title}}</strong></p>
                        <div class="margin-0" [innerHtml]="hint.description"></div>
                    </div>
                </div>
            </div>
        </swiper>
        <div class="hints-swiper-bottom">
            <div class="element-clickable hints-swiper-bottom-link hints-swiper-bottom-link-left"
                 *ngIf="hintSwiper.swiper && hintSwiper.swiper.activeIndex < (hintSwiper.swiper.slides.length - 1)"
                 (click)="closeHintsOverlay()">
                {{'HINTS.general_skip' | translate}}
            </div>
            <div class="hints-swiper-bottom-pagination center aligned"></div>
            <div class="element-clickable hints-swiper-bottom-link hints-swiper-bottom-link-right"
                 *ngIf="hintSwiper.swiper && hintSwiper.swiper.activeIndex < (hintSwiper.swiper.slides.length - 1)"
                 (click)="hintSwiper.swiper.slideNext();">
                {{'HINTS.general_next' | translate}}
            </div>
            <button type="button" class="ui mini button margin-0 font-bold hints-swiper-bottom-link hints-swiper-bottom-link-right"
                 *ngIf="(hintSwiper.swiper && hintSwiper.swiper.activeIndex === (hintSwiper.swiper.slides.length - 1)) || !hintSwiper.swiper"
                 (click)="readGeneralHints()">
                {{'HINTS.general_letsgo_btn' | translate}} <span class="ui active inline tiny loader" *ngIf="generalHintsProcessing"></span>
            </button>
        </div>
    </div>
</div>

<ng-template let-context let-modal="modal" #generalHintsModalTemplate>
    <div class="scrolling content with-profile-header-image v3">
        <div class="hints-dimmer-content">
            <swiper class="hint-swiper" #hintSwiper [config]="hintSwiperConfig" [initialize]="hintSwiperInit">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let hint of unreadGeneralHints; trackBy: hintTrackBy;">
                        <div class="ui vertical borderless segment padding-t-0" *ngIf="getHintMediaTranslationForLanguage(hint)">
                            <div class="ui fluid image center aligned">
                                <img [src]="getHintMediaTranslationForLanguage(hint).media_url" />
                            </div>
                        </div>
                        <div class="center aligned margin-0 padding-15 line-height-standard">
                            <p class="ui header text-large"><strong>{{hint.title}}</strong></p>
                            <div class="color-light-grey" [innerHtml]="hint.description"></div>
                        </div>
                    </div>
                </div>
            </swiper>
        </div>
    </div>
    <div class="actions v3 left aligned">
        <div class="ui grid container">
            <div class="six wide column">
                <button class="ui basic borderless button color-light-grey"
                     *ngIf="hintSwiper.swiper && hintSwiper.swiper.activeIndex < (hintSwiper.swiper.slides.length - 1)"
                     (click)="modal.deny()">
                    {{'HINTS.general_skip' | translate}}
                </button>
            </div>
            <div class="four wide column">
                <div class="hints-swiper-bottom-pagination center aligned swiper-pagination-bullets"
                    *ngIf="hintSwiper.swiper && hintSwiper.swiper.slides.length > 1">
                    <span class="swiper-pagination-bullet"
                        *ngFor="let slide of getSwiperSlidesIndexArray(hintSwiper.swiper.slides.length); let i = index"
                    [class.swiper-pagination-bullet-active]="hintSwiper.swiper.activeIndex === i"></span>
                </div>
            </div>
            <div class="six wide column">
                <button class="ui basic borderless button pull-right text-primary"
                     *ngIf="hintSwiper.swiper && hintSwiper.swiper.activeIndex < (hintSwiper.swiper.slides.length - 1)"
                     (click)="hintSwiper.swiper.slideNext();">
                    {{'HINTS.general_next' | translate}}
                </button>
                <button type="button" class="ui button font-bold pull-right"
                        *ngIf="(hintSwiper.swiper && hintSwiper.swiper.activeIndex === (hintSwiper.swiper.slides.length - 1)) || !hintSwiper.swiper"
                        (click)="modal.approve();">
                    {{'HINTS.general_letsgo_btn' | translate}} <span class="ui active inline tiny loader" *ngIf="generalHintsProcessing"></span>
                </button>
            </div>
        </div>
    </div>
</ng-template>
