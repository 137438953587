<div class="ui segments" *ngIf="currentUser">
    <div class="ui clearing segment">
        <div class="ui middle aligned two column grid">
            <div class="row">
                <div class="thirteen wide column">
                    <h2 class="ui header">{{'PROFILE.OVERVIEW.MENU.change-email' | translate}}</h2>
                </div>
                <div class="right aligned three wide column">
                    <a routerLink="{{backUrl}}" class="ui basic circular icon button margin-0">
                        <i class="close icon margin-0"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="ui segment" *ngIf="!showAlert">

        <div class="ui active centered inline text loader" *ngIf="processConfirmation">
            {{'GENERAL.loadertext' | translate}}
        </div>

        <form *ngIf="!processConfirmation" class="ui form" novalidate [formGroup]="emailForm"
              (ngSubmit)="onSubmit()"
              [class.error]="emailForm.invalid && !processForm">

            <p class="margin-t-0">
                <strong>{{'PROFILE.CHANGEEMAIL.current_email' | translate}}</strong><br/>
                {{currentUser.email}}
            </p>

            <div class="field">
                <label for="new_email">{{'PROFILE.CHANGEEMAIL.new_email_field' | translate}}</label>
                <input id="new_email" type="text" formControlName="new_email">
                <div class="ui pointing red basic label"
                     *ngIf="emailForm.get('new_email').invalid && (emailForm.get('new_email').dirty || emailForm.get('new_email').touched)">
                    <p *ngIf="emailForm.get('new_email').hasError('required')">
                        {{'PROFILE.CHANGEEMAIL.ERRORS.new_email_field_required' | translate}}
                    </p>
                    <p *ngIf="emailForm.get('new_email').dirty && emailForm.get('new_email').hasError('email')">
                        {{'PROFILE.CHANGEEMAIL.ERRORS.new_email:validation_error_email' | translate}}
                    </p>
                </div>
            </div>

            <div class="ui center aligned">
                <button class="ui large button margin-0" type="submit" [class.loading]="processForm"
                        [disabled]="processForm || !emailForm.valid">
                    {{'PROFILE.CHANGEEMAIL.initialize_emailchange_btn' | translate | uppercase}}
                </button>
            </div>

        </form>
    </div>

    <div class="ui segment" *ngIf="showAlert">
        <fui-message class="success" [hasDismissButton]="false">
            <div class="header">
                {{'PROFILE.CHANGEEMAIL.emailchange_initialize_successfully_title' | translate}}
            </div>
            <p>{{'PROFILE.CHANGEEMAIL.emailchange_initialize_successfully' | translate}}</p>
        </fui-message>
    </div>
</div>
