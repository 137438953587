/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantNewUserInvitationRequest } from '../model/appHttpRequestsTenantsTenantNewUserInvitationRequest';
// eslint-disable-next-line
import { AppHttpRequestsTenantsTenantTagCreateRequest } from '../model/appHttpRequestsTenantsTenantTagCreateRequest';
// eslint-disable-next-line
import { AppHttpResponsesGeneralLanguageListHttpResponse } from '../model/appHttpResponsesGeneralLanguageListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesSportsDataMatchListHttpResponse } from '../model/appHttpResponsesSportsDataMatchListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantAdvertisementListHttpResponse } from '../model/appHttpResponsesTenantsTenantAdvertisementListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantAppStorePackageListHttpResponse } from '../model/appHttpResponsesTenantsTenantAppStorePackageListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantExternalScriptListHttpResponse } from '../model/appHttpResponsesTenantsTenantExternalScriptListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantFooterListHttpResponse } from '../model/appHttpResponsesTenantsTenantFooterListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantGameEntryFeeListHttpResponse } from '../model/appHttpResponsesTenantsTenantGameEntryFeeListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantGameMaxPlayerListHttpResponse } from '../model/appHttpResponsesTenantsTenantGameMaxPlayerListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantInfoHttpResponse } from '../model/appHttpResponsesTenantsTenantInfoHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantInquiryListHttpResponse } from '../model/appHttpResponsesTenantsTenantInquiryListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantLandingpageHttpResponse } from '../model/appHttpResponsesTenantsTenantLandingpageHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantMarketListHttpResponse } from '../model/appHttpResponsesTenantsTenantMarketListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantPublicHttpResponse } from '../model/appHttpResponsesTenantsTenantPublicHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantPublicListHttpResponse } from '../model/appHttpResponsesTenantsTenantPublicListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantSportListHttpResponse } from '../model/appHttpResponsesTenantsTenantSportListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantTagHttpResponse } from '../model/appHttpResponsesTenantsTenantTagHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantTagListHttpResponse } from '../model/appHttpResponsesTenantsTenantTagListHttpResponse';
// eslint-disable-next-line
import { AppHttpResponsesTenantsTenantTournamentListHttpResponse } from '../model/appHttpResponsesTenantsTenantTournamentListHttpResponse';
// eslint-disable-next-line
import { BetsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse } from '../model/betsnapSharedFrameworkHttpResponsesErrorErrorHttpResponse';

// eslint-disable-next-line
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class TenantsApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // eslint-disable-next-line
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get tenant info by domain name
     * Get tenant info by domain name
     * @param domain Domain Name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantinfoGet(domain: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantInfoHttpResponse>;
    public apiTenantinfoGet(domain: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantInfoHttpResponse>>;
    public apiTenantinfoGet(domain: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantInfoHttpResponse>>;
    public apiTenantinfoGet(domain: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (domain === null || domain === undefined) {
            throw new Error('Required parameter domain was null or undefined when calling apiTenantinfoGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (domain !== undefined && domain !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>domain, 'domain');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenantinfo`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantInfoHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenants
     * Get all Tenants
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantPublicListHttpResponse>;
    public apiTenantsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantPublicListHttpResponse>>;
    public apiTenantsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantPublicListHttpResponse>>;
    public apiTenantsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantPublicListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all App-Store packages for tenant
     * Get all App-Store packages for tenant
     * @param tenant_id Tenant-ID
     * @param is_active is active?
     * @param date query date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdAppstorepackagesGet(tenant_id: number, is_active?: boolean, date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantAppStorePackageListHttpResponse>;
    public apiTenantsTenantIdAppstorepackagesGet(tenant_id: number, is_active?: boolean, date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantAppStorePackageListHttpResponse>>;
    public apiTenantsTenantIdAppstorepackagesGet(tenant_id: number, is_active?: boolean, date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantAppStorePackageListHttpResponse>>;
    public apiTenantsTenantIdAppstorepackagesGet(tenant_id: number, is_active?: boolean, date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdAppstorepackagesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (is_active !== undefined && is_active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>is_active, 'is_active');
        }
        if (date !== undefined && date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>date, 'date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/appstorepackages`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantAppStorePackageListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tenant Entry Fees
     * Get all tenant Entry Fees
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdEntryfeesGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantGameEntryFeeListHttpResponse>;
    public apiTenantsTenantIdEntryfeesGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantGameEntryFeeListHttpResponse>>;
    public apiTenantsTenantIdEntryfeesGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantGameEntryFeeListHttpResponse>>;
    public apiTenantsTenantIdEntryfeesGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdEntryfeesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/entryfees`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantGameEntryFeeListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all external scripts for tenant
     * Get all external scripts for tenant
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdExternalscriptsGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantExternalScriptListHttpResponse>;
    public apiTenantsTenantIdExternalscriptsGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantExternalScriptListHttpResponse>>;
    public apiTenantsTenantIdExternalscriptsGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantExternalScriptListHttpResponse>>;
    public apiTenantsTenantIdExternalscriptsGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdExternalscriptsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/externalscripts`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantExternalScriptListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenants-Footers
     * Get Tenant-Footer
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdFootersGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantFooterListHttpResponse>;
    public apiTenantsTenantIdFootersGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantFooterListHttpResponse>>;
    public apiTenantsTenantIdFootersGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantFooterListHttpResponse>>;
    public apiTenantsTenantIdFootersGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdFootersGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/footers`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantFooterListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single tenant by tenant id
     * Get a single tenant by tenant id
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantPublicHttpResponse>;
    public apiTenantsTenantIdGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantPublicHttpResponse>>;
    public apiTenantsTenantIdGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantPublicHttpResponse>>;
    public apiTenantsTenantIdGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantPublicHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenants Inquiries
     * Get Tenant-Inquiries
     * @param tenant_id Tenant ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdInquiriesGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantInquiryListHttpResponse>;
    public apiTenantsTenantIdInquiriesGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantInquiryListHttpResponse>>;
    public apiTenantsTenantIdInquiriesGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantInquiryListHttpResponse>>;
    public apiTenantsTenantIdInquiriesGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdInquiriesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/inquiries`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantInquiryListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Invite New Users per Email
     * Invite New Users per Email
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_new_user_invitation_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdInvitenewuserPut(tenant_id: number, app_http_requests_tenants_tenant_new_user_invitation_request: AppHttpRequestsTenantsTenantNewUserInvitationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public apiTenantsTenantIdInvitenewuserPut(tenant_id: number, app_http_requests_tenants_tenant_new_user_invitation_request: AppHttpRequestsTenantsTenantNewUserInvitationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTenantsTenantIdInvitenewuserPut(tenant_id: number, app_http_requests_tenants_tenant_new_user_invitation_request: AppHttpRequestsTenantsTenantNewUserInvitationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTenantsTenantIdInvitenewuserPut(tenant_id: number, app_http_requests_tenants_tenant_new_user_invitation_request: AppHttpRequestsTenantsTenantNewUserInvitationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdInvitenewuserPut.');
        }
        if (app_http_requests_tenants_tenant_new_user_invitation_request === null || app_http_requests_tenants_tenant_new_user_invitation_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_new_user_invitation_request was null or undefined when calling apiTenantsTenantIdInvitenewuserPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/invitenewuser`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_new_user_invitation_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Tenant Landingpage Information
     * Get Tenant-Landingpage Information
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdLandingpageGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantLandingpageHttpResponse>;
    public apiTenantsTenantIdLandingpageGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiTenantsTenantIdLandingpageGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantLandingpageHttpResponse>>;
    public apiTenantsTenantIdLandingpageGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdLandingpageGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/landingpage`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantLandingpageHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tenant languages
     * Get all tenant languages
     * @param tenant_id Tenant-ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdLanguagesGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesGeneralLanguageListHttpResponse>;
    public apiTenantsTenantIdLanguagesGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesGeneralLanguageListHttpResponse>>;
    public apiTenantsTenantIdLanguagesGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesGeneralLanguageListHttpResponse>>;
    public apiTenantsTenantIdLanguagesGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdLanguagesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/languages`;
        return this.httpClient.request<AppHttpResponsesGeneralLanguageListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Markets for a Tenant
     * Get all Markets for a Tenant
     * @param tenant_id Tenant Id
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param sport_ids Filter Sport Ids (comma separated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdMarketsGet(tenant_id: number, per_page?: number, page?: number, sport_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantMarketListHttpResponse>;
    public apiTenantsTenantIdMarketsGet(tenant_id: number, per_page?: number, page?: number, sport_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantMarketListHttpResponse>>;
    public apiTenantsTenantIdMarketsGet(tenant_id: number, per_page?: number, page?: number, sport_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantMarketListHttpResponse>>;
    public apiTenantsTenantIdMarketsGet(tenant_id: number, per_page?: number, page?: number, sport_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdMarketsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/markets`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantMarketListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Matches
     * Get all Tenant Matches
     * @param tenant_id Tenant ID
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param match_ids Match Ids
     * @param sport_ids Sport Ids
     * @param tournament_ids Tournament Ids
     * @param start_date Filter BetSnap Start-Date
     * @param end_date Filter BetSnap End-Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdMatchesGet(tenant_id: number, per_page?: number, page?: number, match_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesSportsDataMatchListHttpResponse>;
    public apiTenantsTenantIdMatchesGet(tenant_id: number, per_page?: number, page?: number, match_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesSportsDataMatchListHttpResponse>>;
    public apiTenantsTenantIdMatchesGet(tenant_id: number, per_page?: number, page?: number, match_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesSportsDataMatchListHttpResponse>>;
    public apiTenantsTenantIdMatchesGet(tenant_id: number, per_page?: number, page?: number, match_ids?: string, sport_ids?: string, tournament_ids?: string, start_date?: string, end_date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdMatchesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (match_ids !== undefined && match_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>match_ids, 'match_ids');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }
        if (tournament_ids !== undefined && tournament_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tournament_ids, 'tournament_ids');
        }
        if (start_date !== undefined && start_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start_date, 'start_date');
        }
        if (end_date !== undefined && end_date !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end_date, 'end_date');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/matches`;
        return this.httpClient.request<AppHttpResponsesSportsDataMatchListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tenant Max Player
     * Get all tenant Max Players
     * @param tenant_id Tenant Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdMaxplayersGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantGameMaxPlayerListHttpResponse>;
    public apiTenantsTenantIdMaxplayersGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantGameMaxPlayerListHttpResponse>>;
    public apiTenantsTenantIdMaxplayersGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantGameMaxPlayerListHttpResponse>>;
    public apiTenantsTenantIdMaxplayersGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdMaxplayersGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/maxplayers`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantGameMaxPlayerListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tenant sports
     * Get all tenant sports
     * @param tenant_id Tenant-ID
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param with_counts with tournament and matches count
     * @param user_generation_allowed if user generation is allowed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdSportsGet(tenant_id: number, per_page?: number, page?: number, with_counts?: boolean, user_generation_allowed?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantSportListHttpResponse>;
    public apiTenantsTenantIdSportsGet(tenant_id: number, per_page?: number, page?: number, with_counts?: boolean, user_generation_allowed?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantSportListHttpResponse>>;
    public apiTenantsTenantIdSportsGet(tenant_id: number, per_page?: number, page?: number, with_counts?: boolean, user_generation_allowed?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantSportListHttpResponse>>;
    public apiTenantsTenantIdSportsGet(tenant_id: number, per_page?: number, page?: number, with_counts?: boolean, user_generation_allowed?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdSportsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (with_counts !== undefined && with_counts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>with_counts, 'with_counts');
        }
        if (user_generation_allowed !== undefined && user_generation_allowed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>user_generation_allowed, 'user_generation_allowed');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/sports`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantSportListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tenant Tags for Tenant
     * Get all Tenant Tags for Tenant
     * @param tenant_id Tenant ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdTagsGet(tenant_id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantTagListHttpResponse>;
    public apiTenantsTenantIdTagsGet(tenant_id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantTagListHttpResponse>>;
    public apiTenantsTenantIdTagsGet(tenant_id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantTagListHttpResponse>>;
    public apiTenantsTenantIdTagsGet(tenant_id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdTagsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tags`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantTagListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Tenant Tag
     * @param tenant_id Tenant Id
     * @param app_http_requests_tenants_tenant_tag_create_request Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdTagsPost(tenant_id: number, app_http_requests_tenants_tenant_tag_create_request: AppHttpRequestsTenantsTenantTagCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantTagHttpResponse>;
    public apiTenantsTenantIdTagsPost(tenant_id: number, app_http_requests_tenants_tenant_tag_create_request: AppHttpRequestsTenantsTenantTagCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantTagHttpResponse>>;
    public apiTenantsTenantIdTagsPost(tenant_id: number, app_http_requests_tenants_tenant_tag_create_request: AppHttpRequestsTenantsTenantTagCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantTagHttpResponse>>;
    public apiTenantsTenantIdTagsPost(tenant_id: number, app_http_requests_tenants_tenant_tag_create_request: AppHttpRequestsTenantsTenantTagCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdTagsPost.');
        }
        if (app_http_requests_tenants_tenant_tag_create_request === null || app_http_requests_tenants_tenant_tag_create_request === undefined) {
            throw new Error('Required parameter app_http_requests_tenants_tenant_tag_create_request was null or undefined when calling apiTenantsTenantIdTagsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (token) required
        localVarCredential = this.configuration.lookupCredential('token');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tags`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantTagHttpResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: app_http_requests_tenants_tenant_tag_create_request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all advertisements for tenant
     * Get all advertisements for tenant
     * @param tenant_id Tenant-ID
     * @param web_distribution Distribution in Web?
     * @param android_distribution Distribution in Android?
     * @param ios_distribution Distribution in iOS?
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdTenantContentGet(tenant_id: number, web_distribution?: boolean, android_distribution?: boolean, ios_distribution?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>;
    public apiTenantsTenantIdTenantContentGet(tenant_id: number, web_distribution?: boolean, android_distribution?: boolean, ios_distribution?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>>;
    public apiTenantsTenantIdTenantContentGet(tenant_id: number, web_distribution?: boolean, android_distribution?: boolean, ios_distribution?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>>;
    public apiTenantsTenantIdTenantContentGet(tenant_id: number, web_distribution?: boolean, android_distribution?: boolean, ios_distribution?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdTenantContentGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (web_distribution !== undefined && web_distribution !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>web_distribution, 'web_distribution');
        }
        if (android_distribution !== undefined && android_distribution !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>android_distribution, 'android_distribution');
        }
        if (ios_distribution !== undefined && ios_distribution !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ios_distribution, 'ios_distribution');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tenant-content`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantAdvertisementListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tournaments for a Tenant
     * Get all Tournaments for a Tenant
     * @param tenant_id Tenant Id
     * @param per_page Entries per Page
     * @param page Page Nr
     * @param only_with_available_matches only tournaments with available matches
     * @param sport_ids Filter Sport Ids (comma separated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTenantsTenantIdTournamentsGet(tenant_id: number, per_page?: number, page?: number, only_with_available_matches?: boolean, sport_ids?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AppHttpResponsesTenantsTenantTournamentListHttpResponse>;
    public apiTenantsTenantIdTournamentsGet(tenant_id: number, per_page?: number, page?: number, only_with_available_matches?: boolean, sport_ids?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AppHttpResponsesTenantsTenantTournamentListHttpResponse>>;
    public apiTenantsTenantIdTournamentsGet(tenant_id: number, per_page?: number, page?: number, only_with_available_matches?: boolean, sport_ids?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AppHttpResponsesTenantsTenantTournamentListHttpResponse>>;
    public apiTenantsTenantIdTournamentsGet(tenant_id: number, per_page?: number, page?: number, only_with_available_matches?: boolean, sport_ids?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant_id === null || tenant_id === undefined) {
            throw new Error('Required parameter tenant_id was null or undefined when calling apiTenantsTenantIdTournamentsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (per_page !== undefined && per_page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>per_page, 'perPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (only_with_available_matches !== undefined && only_with_available_matches !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>only_with_available_matches, 'only_with_available_matches');
        }
        if (sport_ids !== undefined && sport_ids !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sport_ids, 'sport_ids');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/tenants/${this.configuration.encodeParam({name: "tenant_id", value: tenant_id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/tournaments`;
        return this.httpClient.request<AppHttpResponsesTenantsTenantTournamentListHttpResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
