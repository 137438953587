<ng-template let-context let-modal="modal" #gameShareModalTemplate>
    <div class="header center aligned">
        {{'GENERAL.GAMES.SHAREGAME.heading' | translate | uppercase}}
        <div class="text-small color-light-grey">
            <betsnaps-game-name [game]="game"></betsnaps-game-name>
        </div>
    </div>
    <div class="scrolling content game-share-modal-content padding-t-0">
        <div class="center aligned">
            <button type="button"
                    class="ui circular facebook icon button"
                    shareButton="facebook"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'facebook - ' + game.game_unique_id)">
                <i class="fa fa-facebook icon"></i>
            </button>
            <button type="button"
                    class="ui circular twitter icon button"
                    shareButton="twitter"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'twitter - ' + game.game_unique_id)">
                <i class="fa fa-twitter icon"></i>
            </button>
            <button type="button"
                    class="ui circular whatsapp icon button"
                    shareButton="whatsapp"
                    [url]="shareUrl"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'whatsapp - ' + game.game_unique_id)"
                    *ngIf="mobileDetect.device_detection() == 'desktop'">
                <i class="fa fa-whatsapp icon"></i>
            </button>
            <a [href]="'whatsapp://send?text=' + shareUrl | safeUrl"
               class="ui circular whatsapp icon button"
               (click)="googleAnalyticsService.trackEvent('game', 'share', 'whatsapp - ' + game.game_unique_id)"
               *ngIf="mobileDetect.device_detection() == 'mobile' || mobileDetect.device_detection() == 'tablet'">
                <i class="fa fa-whatsapp icon"></i>
            </a>
            <button type="button"
                    class="ui circular black icon button margin-0"
                    (click)="googleAnalyticsService.trackEvent('game', 'share', 'copied to clipboard - ' + game.game_unique_id)"
                    ngxClipboard
                    [cbContent]="shareUrl"
                    (cbOnSuccess)="showCopyInformation()">
                <i class="fa fa-link icon"></i>
            </button>
        </div>
        <p class="center aligned margin-t-5" *ngIf="showcopyinfo">
            <i class="icon check color-positive"></i> {{'GENERAL.LABELS.NOTIFICATIONS.link_copied' | translate}}
        </p>
        <div class="ui vertical borderless very padded segment center aligned padding-b-0 margin-0"
             *ngIf="userFriends.length > 0 && sentGameInvitations">
            <p class="text-label margin-b-15">
                {{'GENERAL.GAMES.SHAREGAME.share_with_friends' | translate | uppercase}}
            </p>
            <div *ngIf="!friendsInvited">
                <div class="ui four column center aligned grid">
                    <div *ngFor="let userFriend of userFriends" class="column">
                        <div class="column center aligned">
                            <div class="element-clickable" (click)="selectFriend(userFriend)">
                                <div class="img-wrap">
                                    <img *ngIf="userFriend.friend_user.media_id > 0"
                                         class="ui circular bordered centered tiny image white"
                                         [src]="userFriend.friend_user.media_url_small"
                                         [ngClass]="{'primary': selectedUserFriends.includes(userFriend)}"/>
                                    <span class="image-overlay primary circular" *ngIf="selectedUserFriends.includes(userFriend)">
                                        <i class="check small icon"></i>
                                    </span>
                                </div>
                                <span class="truncate text-small margin-t-5">
                                    {{userFriend.friend_user.username}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui borderless vertical very padded segment center aligned margin-0 padding-b-0">
                    <button type="button" class="ui large button margin-0" (click)="inviteFriends()"
                            [class.loading]="processInvite"
                            [class.disabled]="processInvite || selectedUserFriends.length < 1"
                            [disabled]="processInvite || selectedUserFriends.length < 1">
                        {{'GENERAL.GAMES.SHAREGAME.invite-friends_btn' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
        <div class="ui success message center aligned" *ngIf="friendsInvited">
            <p>{{'GENERAL.GAMES.SHAREGAME.friends_invited_success' | translate}}</p>
            <button type="button"
                    class="ui large button margin-0"
                    *ngIf="userFriends.length > 0"
                    (click)="friendsInvited = false">
                {{'GENERAL.GAMES.SHAREGAME.invite-more-friends_btn' | translate | uppercase}}
            </button>
        </div>
    </div>
</ng-template>
