import {
    Component,
    ElementRef,
    HostListener, Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import * as moment from 'moment';
import {
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameListHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse
} from '../../../../api';
import {
    AuthenticationService,
    BetsnapdetailService,
    GamePointsEngineEnum,
    GoogleAnalyticsService,
    MyModalService,
    MyTranslateService,
    TenantImageTypeEnum,
    TenantService,
    WindowRef
} from '../../../../shared';
import {Observable} from 'rxjs';
import {filter, takeWhile} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {GameShareModalDefaultComponent} from '../../../components/game-share-modal/game-share-modal-default/game-share-modal-default.component';

@Component({
    selector: 'betsnaps-betsnapdetail-header',
    templateUrl: './betsnapdetail-header.component.html',
    styles: []
})
export class BetsnapdetailHeaderComponent implements OnInit, OnChanges, OnDestroy {

    private componentAlive = true;

    @Input() game: GameHttpResponse;
    @Input() gameUniqueId: string;
    public gameUserPointsChanged: boolean = false;
    public currentUser: PlayerHttpResponse;
    public isAuthenticated$: Observable<boolean>;
    public joinedGameList: GameListHttpResponse;
    public secondsToStart: number;

    @ViewChild('switchModalTemplate') public switchModalTemplate: ModalTemplate<null, string, string>;
    @ViewChild('betsnapdetailHeaderWrap', { static: true }) private betsnapdetailHeaderWrap: ElementRef;
    @ViewChild('gameUserBarMobile') private gameUserBarMobile: ElementRef;
    @ViewChild('gameShareModalDefaultComponent', {static: true}) gameShareModalDefaultComponent: GameShareModalDefaultComponent;

    public backgroundImage: string = '';

    private currentScrollPosition: number = 0;
    private nativeWindow: Window;

    public gameUserBarMobileFixed: boolean = false;
    public processData: boolean = false;

    public gamePointsEngineEnum = GamePointsEngineEnum;

    constructor(@Inject(DOCUMENT) private document: any,
                private authenticationService: AuthenticationService,
                public tenantService: TenantService,
                private myModalService: MyModalService,
                public betsnapdetailService: BetsnapdetailService,
                public googleAnalyticsService: GoogleAnalyticsService,
                private router: Router,
                public translations: MyTranslateService,
                private windowRef: WindowRef) {

        this.nativeWindow = windowRef.nativeWindow;

        this.isAuthenticated$ = this.authenticationService.isAuthenticated$;

        if (!this.tenantService.tenantData.configuration.broadcast_game_leaderboard) {
            this.betsnapdetailService.leaderboardTimestamp$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (gameLeaderboardTimestamp: string) => {
                        this.gameUserPointsChanged = (gameLeaderboardTimestamp !== null);
                    }
                );
        }
    }

    ngOnInit() {

        this.currentUser = this.authenticationService.currentUser;
        if (this.currentUser) {
            this.betsnapdetailService.getJoinedGameList(
                this.authenticationService.currentUser.id
            );
        }

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeWhile(() => this.componentAlive)
            ).subscribe(() => {
            this.checkGameUserBarFixed();
        });

        this.betsnapdetailService.joinedGameList$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameListHttpResponse: GameListHttpResponse) => {
                    if (gameListHttpResponse) {
                        gameListHttpResponse.results = gameListHttpResponse.results.filter(g => g.game_unique_id !== this.game.game_unique_id);
                    }
                    this.joinedGameList = gameListHttpResponse;
                }
            );

        // preload friend images
        if (this.game.current_user_friends) {
            this.game.current_user_friends.forEach(
                (friend: FriendHttpResponse) => {

                    if (friend.friend_user.media_url) {
                        const preLoadMedia = new Image();
                        preLoadMedia.src = friend.friend_user.media_url;
                    }

                    if (friend.friend_user.media_url_small) {
                        const preLoadMediaSmall = new Image();
                        preLoadMediaSmall.src = friend.friend_user.media_url_small;
                    }

                    if (friend.friend_user.media_url_big) {
                        const preLoadMediaBig = new Image();
                        preLoadMediaBig.src = friend.friend_user.media_url_big;
                    }
                }
            );
        }

        if (!this.tenantService.tenantData.configuration.broadcast_game_leaderboard) {
            this.betsnapdetailService.game$
                .pipe(takeWhile(() => this.componentAlive))
                .subscribe(
                    (gameHttpResponse: GameHttpResponse) => {
                        if (gameHttpResponse) {
                            this.game = gameHttpResponse;
                            this.processData = false;
                        }
                    }
                );
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.game) {
            this.game = changes.game.currentValue;
            this.checkSecondsToStart();

            // set header background image
            if (this.game && this.game.bgimg_game_header) {
                this.backgroundImage = 'url("' + this.game.bgimg_game_header + '")';
            } else if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_GAME_DETAILS)) {
                this.backgroundImage = 'url("' + this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_GAME_DETAILS).media_url + '")';
            }
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    private checkSecondsToStart() {
        const gameStartDate = moment(this.game.start_date);
        this.secondsToStart = gameStartDate.diff(moment(), 'seconds', false);
    }

    public isRankingAccessible(): boolean {
        return (this.game.game_state > 2 && this.game.game_state !== 99 &&
            !!this.currentUser && (this.game.is_current_user_joined || this.game.competition_type !== 3)
        );
    }

    openSwitchModal() {
        const switchModalConfig = new TemplateModalConfig<null, string, string>(this.switchModalTemplate);
        switchModalConfig.size = 'tiny';
        switchModalConfig.mustScroll = true;
        this.myModalService.openModal(switchModalConfig);
        this.googleAnalyticsService.trackEvent('game - header', 'switch', 'snap');
    }

    openGameShareModal() {
        this.gameShareModalDefaultComponent.openGameShareModal();
        this.googleAnalyticsService.trackEvent('game - header', 'show', 'sharemodal');
    }

    private checkGameUserBarFixed() {
        this.gameUserBarMobileFixed = (this.currentScrollPosition > this.betsnapdetailHeaderWrap.nativeElement.clientHeight && this.game.is_current_user_joined);
    }

    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    @HostListener('window:scroll', [])
    betsnapdetailHeaderListener() {
        this.currentScrollPosition = Math.floor(
            this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
        );
        this.checkGameUserBarFixed();
    }

    public refreshGameData() {
        this.processData = true;
        this.betsnapdetailService.refreshGameData(true, false, false, false, false, false, false, false, false);
    }

}
