/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHttpResponsesTranslationsTranslationHttpResponse } from './appHttpResponsesTranslationsTranslationHttpResponse';


/**
 * TenantPrizePublicHttpResponse
 */
export interface AppHttpResponsesBetsnapsPrizeStructuresTenantPrizePublicHttpResponse { 
    /**
     * Id
     */
    id: number;
    /**
     * Tenant Id
     */
    tenant_id: number;
    /**
     * Title
     */
    title: string;
    /**
     * Translations
     */
    translations?: Array<AppHttpResponsesTranslationsTranslationHttpResponse>;
}

