import {Injectable} from '@angular/core';
import {
    REG_TABLETS, REG_MOBILES, REG_SMARTS_TV, REG_BROWSERS, REG_SORT_NAMES,
    REG_GAME_DEVICES, REG_BOTS, REG_OS, WINDOWS_OS_VERSION, LINUX_OS
} from '../const';
import {WindowRef} from './window.service';

@Injectable({
    providedIn: 'root'
})
export class MobiledetectService {

    private _userAgent: any;
    private nativeWindow;

    constructor(private windowRef: WindowRef) {
        this.nativeWindow = windowRef.nativeWindow;
        this._userAgent = this.nativeWindow.navigator.userAgent.toLowerCase();
    }

    public browserName(): string {
        if (REG_BROWSERS.OPERA_MINI.test(this._userAgent)) return 'operamini';
        else if (REG_BROWSERS.OPERA.test(this._userAgent)) return 'opera';
        else if (REG_SORT_NAMES.MOZILLA.test(this._userAgent) && REG_BROWSERS.FIREFOX.test(this._userAgent)) return 'firefox';
        else if (REG_BROWSERS.IE[0].test(this._userAgent) || REG_BROWSERS.IE[1].test(this._userAgent) || REG_BROWSERS.IE[2].test(this._userAgent)) return 'ie';
        else if (REG_SORT_NAMES.SAFARI.test(this._userAgent) && REG_SORT_NAMES.WEBKIT[1].test(this._userAgent) && !REG_SORT_NAMES.CHROME.test(this._userAgent)) return 'safari';
        else if (REG_SORT_NAMES.WEBKIT[0].test(this._userAgent) && REG_SORT_NAMES.CHROME.test(this._userAgent) && !REG_BROWSERS.IE[2].test(this._userAgent)) return 'chrome';
        else if (REG_BROWSERS.SILK.test(this._userAgent)) return 'silk';
        else if (REG_BROWSERS.YANDEX.test(this._userAgent)) return 'yandex';
        else return 'NA';
    }

    public device_detection(): string {
        if (this.isMobile()) return 'mobile';
        else if (this.isTablet()) return 'tablet';
        else if (this.isSMART()) return 'smarttv';
        else if (this.isDesktop()) return 'desktop';
        return null;
    }

    public ie_version_detect(): any {
        let msie = this._userAgent.indexOf('msie ');
        if (REG_BROWSERS.IE[0].test(this._userAgent)) {
            let ie_version = parseInt(this._userAgent.substring(msie + 5, this._userAgent.indexOf('.', msie)), 10);

            if (ie_version === 7) return 'ie 7';
            else if (ie_version === 8) return 'ie 8';
            else if (ie_version === 9) return 'ie 9';
            else if (ie_version === 10) return 'ie 10';
        }
        if (REG_BROWSERS.IE[1].test(this._userAgent)) {
            let rv = this._userAgent.indexOf('rv:');
            let ie_version = parseInt(this._userAgent.substring(rv + 3, this._userAgent.indexOf('.', rv)), 10);
            if (ie_version == 11) return 'ie 11';
        }
        if (REG_BROWSERS.IE[2].test(this._userAgent)) return 'ie +12';

        return null;
    }

    public standard_devices(): string {
        if (REG_MOBILES.IPHONE.test(this._userAgent)) return 'iphone';
        else if (REG_TABLETS.IPAD.test(this._userAgent)) return 'ipad';
        else if (this.isMobile() && REG_MOBILES.ANDROID.test(this._userAgent)) return 'android mobile';
        else if (this.isTablet() && REG_MOBILES.ANDROID.test(this._userAgent)) return 'android tablet';
        else if (REG_MOBILES.WINDOWS_PHONE.test(this._userAgent)) return 'windows phone';
        return null;
    }

    public game_devices(): string {
        if (REG_GAME_DEVICES.PS4.test(this._userAgent)) return 'Playstation 4';
        else if (REG_GAME_DEVICES.PS3.test(this._userAgent)) return 'Playstation 3';
        else if (REG_GAME_DEVICES.XBOX_ONE.test(this._userAgent)) return 'Xbox one';
        else if (REG_GAME_DEVICES.XBOX.test(this._userAgent)) return 'Xbox';
        else if (REG_GAME_DEVICES.WII.test(this._userAgent)) return 'Wii';
        else if (REG_GAME_DEVICES.WII_U.test(this._userAgent)) return 'Wii U';
        else if (REG_GAME_DEVICES.NINTENDO_3DS.test(this._userAgent)) return 'Nintendo 3DS';
        else if (REG_GAME_DEVICES.PS_VITA.test(this._userAgent)) return 'Playstation Vita';
        else if (REG_GAME_DEVICES.PSP.test(this._userAgent)) return 'PSP';
        return null;
    }

    public smart_tv(): string {
        if (REG_SMARTS_TV.CHROMECAST.test(this._userAgent)) return 'Chromecast';
        else if (REG_SMARTS_TV.APPLE_TV.test(this._userAgent)) return 'Apple tv';
        else if (REG_SMARTS_TV.GOOGLE_TV.test(this._userAgent)) return 'Google tv';
        else if (REG_GAME_DEVICES.XBOX_ONE.test(this._userAgent)) return 'Xbox One';
        else if (REG_GAME_DEVICES.PS4.test(this._userAgent)) return 'Playstation 4';
        else if (REG_SMARTS_TV.GENERIC_TV.test(this._userAgent)) return 'Generic smartv';
        return null;
    }

    public desktop(): string {
        if (this.isDesktop()) {
            if (REG_OS.WINDOWS.test(this._userAgent)) return 'Windows';
            else if (REG_OS.MAC_OS.test(this._userAgent)) return 'Mac';
            else if (REG_OS.LINUX.test(this._userAgent)) return 'Linux';
        }
        return null;
    }

    public tablet(): string {
        if (this.isTablet()) {
            if (REG_TABLETS.IPAD.test(this._userAgent)) return 'Ipad';
            else if (REG_TABLETS.TABLET.test(this._userAgent) && REG_MOBILES.ANDROID.test(this._userAgent)) return 'Android';
            else if (REG_TABLETS.KINDLE.test(this._userAgent)) return 'Kindle';
            else if (REG_TABLETS.TABLET.test(this._userAgent)) return 'Generic Tablet';
        }
        return null;
    }

    public mobile(): string {
        if (this.isMobile()) {
            if (REG_MOBILES.IPHONE.test(this._userAgent)) return 'Iphone';
            else if (REG_MOBILES.ANDROID.test(this._userAgent)) return 'Android';
            else if (REG_MOBILES.WINDOWS_PHONE.test(this._userAgent)) return 'Windows Phone';
            else if (REG_MOBILES.BLACKBERRY.test(this._userAgent) || REG_MOBILES.BB10.test(this._userAgent)) return 'Blackberry';
            else return 'Generic Mobile';
        }
        return null;
    }

    public windows(): string {
        if (this.isDesktop() && this.isWindows()) {
            if (WINDOWS_OS_VERSION.WINDOWS_XP.test(this._userAgent)) return 'Windows XP';
            else if (WINDOWS_OS_VERSION.WINDOWS_VISTA.test(this._userAgent)) return 'Windows Vista';
            else if (WINDOWS_OS_VERSION.WINDOWS_7.test(this._userAgent)) return 'Windows 7';
            else if (WINDOWS_OS_VERSION.WINDOWS_8.test(this._userAgent) || WINDOWS_OS_VERSION.WINDOWS_8_1.test(this._userAgent)) return 'Windows 8';
            else if (WINDOWS_OS_VERSION.WINDOWS_10.test(this._userAgent)) return 'Windows 10';
            else return 'Generic Windows';
        }
        return null;
    }

    public linux(): string {
        if (this.isDesktop() && this.isLinux()) {
            if (LINUX_OS.DEBIAN.test(this._userAgent)) return 'Debian';
            else if (LINUX_OS.KNOPPIX.test(this._userAgent)) return 'Knoppix';
            else if (LINUX_OS.MINT.test(this._userAgent)) return 'Mint';
            else if (LINUX_OS.UBUNTU.test(this._userAgent)) return 'Ubuntu';
            else if (LINUX_OS.KUBUNTU.test(this._userAgent)) return 'Kubuntu';
            else if (LINUX_OS.XUBUNTU.test(this._userAgent)) return 'Xubuntu';
            else if (LINUX_OS.LUBUNTU.test(this._userAgent)) return 'Lubuntu';
            else if (LINUX_OS.FEDORA.test(this._userAgent)) return 'Fedora';
            else if (LINUX_OS.RED_HAT.test(this._userAgent)) return 'Red hat';
            else if (LINUX_OS.MANDRIVA.test(this._userAgent)) return 'Mandriva';
            else if (LINUX_OS.GENTOO.test(this._userAgent)) return 'Gentoo';
            else if (LINUX_OS.SABAYON.test(this._userAgent)) return 'Sabayon';
            else if (LINUX_OS.SLACKWARE.test(this._userAgent)) return 'Slackware';
            else if (LINUX_OS.SUSE.test(this._userAgent)) return 'Suse';
            else if (LINUX_OS.CENT_OS.test(this._userAgent)) return 'CentOS';
            else if (LINUX_OS.BACKTRACK.test(this._userAgent)) return 'Backtrack';
            else return 'Generic Linux';
        }
        return null;
    }

    public isMobile(): boolean {
        if (REG_MOBILES.GENERIC_REG[0].test(this._userAgent) && this.isTablet() === false ||
            REG_MOBILES.GENERIC_REG[1].test(this._userAgent.substr(0, 4)) && this.isTablet() === false) return true;
        else return false;
    }

    public isTablet(): boolean {
        if (REG_TABLETS.IPAD.test(this._userAgent) || REG_TABLETS.KINDLE.test(this._userAgent) || REG_TABLETS.PLAYBOOK[0].test(this._userAgent) || REG_TABLETS.PLAYBOOK[1].test(this._userAgent) || REG_TABLETS.TABLET.test(this._userAgent)) return true;
        else return false;
    }

    public isSMART(): boolean {
        if (REG_SMARTS_TV.GENERIC_TV.test(this._userAgent) || REG_SMARTS_TV.PS4.test(this._userAgent) || REG_SMARTS_TV.XBOX_ONE.test(this._userAgent)) return true;
        else return false;
    }

    public isDesktop(): boolean {
        if (!this.isMobile() || !this.isTablet() || !this.isSMART()) return true;
        else return false;
    }

    public isGameDevice(): boolean {
        if (REG_GAME_DEVICES.PS4.test(this._userAgent) || REG_GAME_DEVICES.PS3.test(this._userAgent)
            || REG_GAME_DEVICES.XBOX.test(this._userAgent) || REG_GAME_DEVICES.XBOX_ONE.test(this._userAgent)
            || REG_GAME_DEVICES.WII.test(this._userAgent) || REG_GAME_DEVICES.WII_U.test(this._userAgent)
            || REG_GAME_DEVICES.NINTENDO_3DS.test(this._userAgent) || REG_GAME_DEVICES.PS_VITA.test(this._userAgent)
            || REG_GAME_DEVICES.PSP.test(this._userAgent)) return true;
        else return false;
    }

    public isWindows(): boolean {
        if (REG_OS.WINDOWS.test(this._userAgent)) return true;
        else return false;
    }

    public isLinux(): boolean {
        if (REG_OS.LINUX.test(this._userAgent)) return true;
        else return false;
    }

    public isIOS(): boolean {
        if (REG_OS.IOS.test(this._userAgent)) return true;
        else return false;
    }

    public isChromeIos(): boolean {
        if (REG_BROWSERS.CHROME[1].test(this._userAgent)) return true;
        else return false;
    }

    public isBot(): boolean {
        if (REG_BOTS.GENERIC_BOT.test(this._userAgent)) return true;
        else return false;
    }

    public userAgent_data(): any {
        let ie_version_name: any = '';
        let ie_version_state: any = false;
        let game_device_name: any = '';
        let smart_tv_name: any = '';
        let desktop_name: any = '';
        let tablet_name: any = '';
        let mobile_name: any = '';
        let windows_name: any = '';
        let linux_name: any = '';
        if (this.ie_version_detect() != null) {
            ie_version_name = this.ie_version_detect();
            ie_version_state = true;
        }
        if (this.isGameDevice()) {
            game_device_name = this.game_devices();
        }
        if (this.isSMART()) {
            smart_tv_name = this.smart_tv();
        }
        if (this.isDesktop()) {
            desktop_name = this.desktop();
        }
        if (this.isTablet()) {
            tablet_name = this.tablet();
        }
        if (this.isMobile()) {
            mobile_name = this.mobile();
        }

        if (this.isWindows()) {
            windows_name = this.windows();
        }
        if (this.isLinux()) {
            linux_name = this.linux();
        }
        return {
            device: this.device_detection(),
            browser: this.browserName(),
            ie_version: {
                name: ie_version_name,
                state: ie_version_state
            },
            game_device: {
                name: game_device_name,
                state: this.isGameDevice()
            },
            smart_tv: {
                name: smart_tv_name,
                state: this.isSMART()
            },
            desktop: {
                name: desktop_name,
                state: this.isDesktop()
            },
            tablet: {
                name: tablet_name,
                state: this.isTablet()
            },
            mobile: {
                name: mobile_name,
                state: this.isMobile()
            },
            window_os: {
                name: windows_name,
                state: this.isWindows()
            },
            linux_os: {
                name: linux_name,
                state: this.isLinux()
            },
            bot: this.isBot()
        };
    }

    public getUserAgent(): any {
        return this._userAgent.toLowerCase();
    }

    public isPrivateBrowser(callback) {
        let is_private;
        if (this.nativeWindow.window.webkitRequestFileSystem) {
            this.nativeWindow.window.webkitRequestFileSystem(
                this.nativeWindow.window.TEMPORARY, 1,
                function () {
                    is_private = false;
                },
                function (e) {
                    is_private = true;
                }
            );
        } else if (this.nativeWindow.window.indexedDB && /Firefox/.test(this.nativeWindow.window.navigator.userAgent)) {
            let db;
            try {
                db = this.nativeWindow.window.indexedDB.open('test');
            } catch (e) {
                is_private = true;
            }

            if (typeof is_private === 'undefined') {
                this.retry(
                    function isDone() {
                        return db.readyState === 'done' ? true : false;
                    },
                    function next(is_timeout) {
                        if (!is_timeout) {
                            is_private = db.result ? false : true;
                        }
                    }
                );
            }
        } else if (this.isIE10OrLater(this.nativeWindow.window.navigator.userAgent)) {
            is_private = false;
            try {
                if (!this.nativeWindow.window.indexedDB) {
                    is_private = true;
                }
            } catch (e) {
                is_private = true;
            }
        } else if (this.nativeWindow.window.localStorage && /Safari/.test(this.nativeWindow.window.navigator.userAgent)) {

            // One-off check for weird sports 2.0 polyfill
            // This also impacts iOS Firefox and Chrome (newer versions), apparently
            // @see bglobe-js/containers/App.js:116
            if (this.nativeWindow.window.safariIncognito) {
                is_private = true;
            } else {
                try {
                    this.nativeWindow.window.openDatabase(null, null, null, null);
                } catch (e) {
                    is_private = true;
                }

                try {
                    this.nativeWindow.window.localStorage.setItem('test', 1);
                } catch (e) {
                    is_private = true;
                }
            }

            if (typeof is_private === 'undefined') {
                is_private = false;
                this.nativeWindow.window.localStorage.removeItem('test');
            }
        }

        this.retry(
            function isDone() {
                return typeof is_private !== 'undefined' ? true : false;
            },
            function next(is_timeout) {
                callback(is_private);
            }
        );
    }


    private retry(isDone, next) {
        let current_trial = 0;
        const max_retry = 50;
        const interval = 10;
        let is_timeout = false;

        const id = window.setInterval(
            function () {
                if (isDone()) {
                    window.clearInterval(id);
                    next(is_timeout);
                }
                if (current_trial++ > max_retry) {
                    window.clearInterval(id);
                    is_timeout = true;
                    next(is_timeout);
                }
            },
            10
        );
    }

    private isIE10OrLater(user_agent) {
        const ua = user_agent.toLowerCase();
        if (ua.indexOf('msie') === 0 && ua.indexOf('trident') === 0) {
            return false;
        }
        const match = /(?:msie|rv:)\s?([\d\.]+)/.exec(ua);
        if (match && parseInt(match[1], 10) >= 10) {
            return true;
        }
        // MS Edge Detection from this gist: https://gist.github.com/cou929/7973956
        const edge = /edge/.exec(ua);
        if (edge && edge[0] === 'edge') {
            return true;
        }
        return false;
    }

    public isAndroidDevice() {
        return ((/android/i).test(this._userAgent));
    }
}
