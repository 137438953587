<div class="ui middle aligned grid">
    <div class="row">
        <div [ngClass]="(shouldShowScore() ? getDefaultColumnClasses()[0] : 'sixteen') + ' wide column left aligned'">
            <div class="ui list team-names">
                <div class="item">
                    <span *ngIf="match.home" class="truncate">
                        {{match.home.name | dbtranslation:'name':match.home?.translations}}
                    </span>
                    <span *ngIf="!match.home" class="truncate">
                        {{match.home_name}}
                    </span>
                    <span class="red-card-indicator-wrap" *ngIf="match.home_statistics && (match.home_statistics.red_cards + match.home_statistics.yellow_red_cards) > 0">
                        <span class="red-card-indicator"></span>
                    </span>
                </div>
                <div class="item truncate">
                    <span *ngIf="match.away" class="truncate">
                        {{match.away.name | dbtranslation:'name':match.away?.translations}}
                    </span>
                    <span *ngIf="!match.away" class="truncate">
                        {{match.away_name}}
                    </span>
                    <span class="red-card-indicator-wrap" *ngIf="match.away_statistics && (match.away_statistics.red_cards + match.away_statistics.yellow_red_cards) > 0">
                        <span class="red-card-indicator"></span>
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="getDefaultColumnClasses()[1] + ' wide column right aligned padding-l-0'" *ngIf="shouldShowScore()">
            <div class="match-score-default padding-r-10"
                 [ngClass]="{
                        'live': match.status === 'live' || match.status === 'interrupted' || match.status === 'suspended',
                        'closed': match.status === 'closed' || match.status === 'ended' || match.status === 'abandoned'
                    }">
                <div class="display-inline-block center aligned">
                    {{match.score.home_score}}:{{match.score.away_score}}
                    <div class="text-mini font-normal" *ngIf="withScoreExtraInformation()">
                        ({{match.score.match_status_code.translations[0].label_value | dbtranslation:'betradar_status':match.score.match_status_code.translations}})
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
