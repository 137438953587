<div class="ui vertical borderless very padded segment" *ngIf="!gameLoaded">
    <div class="ui active centered inline text loader">{{'GENERAL.loadertext' | translate}}</div>
</div>
<div *ngIf="gameLoaded" class="ui fluid card" [class.mobile-stretch-bottom-card]="leaderBoardUserList && leaderBoardUserList.total > 2">
    <div class="ui fluid profile-header-image image"
         *ngIf="profileHeaderImage && currentLeaderBoardUser"
        [class.dimmed]="betsnapdetailService.isTournament || game.game_state < 3"
        [class.slim]="betsnapdetailService.isTournament || game.game_state < 3">
        <img [src]="profileHeaderImage" [alt]="" />
    </div>
    <div class="content" *ngIf="betsnapdetailService.isTournament || game.game_state < 3">
        <div class="ui left aligned grid margin-b-15">
            <div *ngIf="currentLeaderBoardUser" class="row left aligned">
                <div class="margin-l-5 margin-r-15">
                    <betsnaps-user-avatar-loader [user]="currentLeaderBoardUser.user"
                                                 [imageSize]="'small'">
                    </betsnaps-user-avatar-loader>
                </div>
                <div class="display-inline-block">
                    <p class="margin-t-10 margin-b-0" *ngIf="currentLeaderBoardUser.user?.username">
                        <strong class="font-very-bold text-large">{{currentLeaderBoardUser.user.username}}</strong>
                    </p>
                    <p *ngIf="currentLeaderBoardUser" class="text-tiny text-uppercase font-bold margin-b-0">
                        <ng-container *ngIf="game.competition_type !== 5">
                            {{'GENERAL.GAMES.rank' | translate}}:&nbsp;
                            <span *ngIf="currentLeaderBoardUser.rank > 0">
                                {{currentLeaderBoardUser.rank}}<span class="text-mini">{{currentLeaderBoardUser.rank | ordinalNumber:true}}</span><span class="text-tiny" *ngIf="currentLeaderBoardUser.is_eliminated === true"> ({{'GENERAL.GAMES.player_eliminated' | translate}})</span>
                            </span>
                            <span *ngIf="currentLeaderBoardUser.rank < 1">
                                -
                            </span>
                        </ng-container>
                        <ng-container *ngIf="game.competition_type === 5">
                            <ng-container *ngIf="game.game_state < 6">
                                <span *ngIf="currentLeaderBoardUser.lost_bets_count === 0 && (currentLeaderBoardUser.is_participation_valid === true || currentLeaderBoardUser.is_participation_valid === null)">
                                    {{'BETSNAPDETAIL.RANKING.in_the_game' | translate}}
                                </span>
                                <span *ngIf="currentLeaderBoardUser.lost_bets_count > 0 || currentLeaderBoardUser.is_participation_valid === false">
                                    {{'BETSNAPDETAIL.RANKING.out_the_game' | translate}}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="game.game_state >= 6">
                                <span *ngIf="currentLeaderBoardUser.is_winner === '1'">
                                    {{'BETSNAPDETAIL.RANKING.battle_royal_player_won' | translate}}
                                </span>
                                <span *ngIf="currentLeaderBoardUser.is_winner === '0'">
                                    {{'BETSNAPDETAIL.RANKING.battle_royal_player_lost' | translate}}
                                </span>
                            </ng-container>
                        </ng-container>
                    </p>
                    <p class="text-tiny text-uppercase font-bold"
                        *ngIf="game && currentLeaderBoardUser && betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'is_winner') === '1'">
                        {{'GENERAL.GAMES.current_prize' | translate}}:&nbsp;
                        <span *ngIf="(game.is_gift_game === 1 && betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize'))">
                            {{betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize')?.title | dbtranslation:'title':betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize')?.translations}}
                        </span>
                        <span *ngIf="(game.is_gift_game === 1 && !betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize') && betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize_template'))">
                            {{betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize_template')?.title | dbtranslation:'title':betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize_template')?.translations}}
                        </span>
                        <span *ngIf="(game.is_gift_game === 0)">
                            <span *ngIf="game.primary_cash_prize_structure_index !== null">
                                {{betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'prize_amount') | decimalPrize: 2 | currencyFormat:game.prize_structures[game.primary_cash_prize_structure_index].currency.symbol}}<span *ngIf="game.prize_structures[game.primary_cash_prize_structure_index].is_bonus_money === true">&nbsp;{{'GENERAL.LABELS.bet_bonus' | translate}}</span>
                            </span>
                            <span *ngIf="game.primary_cash_prize_structure_index === null">
                                {{betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'prize_amount') | decimalPrize: 2}}
                            </span>
                            <span *ngIf="betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize')" class="text-small">
                                <strong>
                                    + {{betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize').title | dbtranslation:'title':betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize').translations}}
                                </strong>
                            </span>
                            <span *ngIf="!betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize') && betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize_template')" class="text-small">
                                <strong>
                                    + {{betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize_template').title | dbtranslation:'title':betsnapdetailService.getLeaderBoardUserProperty(game, currentLeaderBoardUser, 'tenant_prize_template').translations}}
                                </strong>
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div *ngIf="!processList && currentLeaderBoardUser && game.competition_type === 5 && game.game_state >= 6" class="center aligned centered text-huge padding-t-10 padding-b-15">
            <ng-container *ngIf="currentLeaderBoardUser.is_winner === '1'">
                <i class="icons margin-b-5">
                    <i class="ui centered huge icon bsi bsi-trophy yellow"></i>
                </i>
                <div class="font-very-bold color-grey">{{'BETSNAPDETAIL.RANKING.battle_royal_player_won' | translate}}</div>
            </ng-container>
            <ng-container *ngIf="currentLeaderBoardUser.is_winner === '0'">
                <i class="icons margin-b-5">
                    <i class="ui circular bordered centered huge icon bsi bsi-trophy"></i>
                    <i class="ui circular bordered centered huge icon bsi bsi-stroke negative"></i>
                </i>
                <div class="font-very-bold color-grey">{{'BETSNAPDETAIL.RANKING.battle_royal_player_lost' | translate}}</div>
            </ng-container>
        </div>

        <div class="center aligned margin-b-15" *ngIf="rankingMenu && !processList && rankingMenu.length > 0">
            <div class="ui compact item tiny separatorless menu">
                <a href="#" class="item" [class.active]="menu.active" *ngFor="let menu of rankingMenu" (click)="$event.preventDefault(); rankingMenuChange(menu.key);">
                    <span class="display-inline-block vertical-align-middle">
                        {{menu.translatekey | translate}}
                    </span>
                </a>
            </div>
        </div>

        <div class="ui vertical borderless very padded segment" *ngIf="processList">
            <div class="ui active centered inline text loader">{{'GENERAL.loadertext' | translate}}</div>
        </div>

        <div class="ui vertical borderless segment center aligned padding-t-0" *ngIf="gameUsersChanged && !processList">
            <button type="button"
                    class="ui icon fluid button margin-0"
                    (click)="reloadPaginatedLeaderBoard()">
                <i class="refresh icon"></i> {{'BETSNAPDETAIL.PLAYERS.refresh_playerlist_btn' | translate}}
            </button>
        </div>

        <ng-container *ngIf="game.competition_type === 5 && game.game_state >= 3 && leaderBoardUserList && leaderBoardUserList.total > 0">

            <div class="ui middle aligned centered grid container border-top-1">
                <div class="eleven wide column padding-r-0 text tiny">
                    {{'BETSNAPDETAIL.RANKING.player_name' | translate}}
                </div>
                <div class="three wide column padding-l-0 center aligned text tiny">
                    {{'BETSNAPDETAIL.RANKING.win_or_loose' | translate}}
                </div>
                <div class="two wide column padding-l-0 right aligned text tiny">
                    {{'BETSNAPDETAIL.RANKING.total' | translate}}
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="game.competition_type === 5 && game.game_state >= 3 && game.game_state < 6 && leaderBoardUserList && leaderBoardUserList.total > 0">
            <div *ngIf="game.game_state < 6" class="ui divided list leaderboard-list v3 margin-t-5">
                <betsnaps-leaderboard-user-v3
                    [game]="game"
                    [gameState]="game.game_state"
                    [leaderBoardUser]="currentLeaderBoardUser"
                    [currentUser]="currentUser"
                    [gameMatches]="gameMatches"
                    class="item leaderboard-list-item"
                    [class.currentuser]="true">
                </betsnaps-leaderboard-user-v3>
            </div>

            <ng-container *ngIf="!showOnlyFriends">
                <ng-container *ngIf="inGameLeaderBoardUserList && inGameLeaderBoardUserList.length > 0">
                    <div class="ui one column row center aligned text tiny">
                        {{'BETSNAPDETAIL.RANKING.in_the_game' | translate}}
                    </div>
                    <div class="ui divided list leaderboard-list v3">
                        <betsnaps-leaderboard-user-v3
                            *ngFor="let leaderBoardUser of inGameLeaderBoardUserList; trackBy: leaderBoardUserTrackBy"
                            [game]="game"
                            [gameState]="game.game_state"
                            [leaderBoardUser]="leaderBoardUser"
                            [currentUser]="currentUser"
                            [gameMatches]="gameMatches"
                            class="item leaderboard-list-item"
                            [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                        </betsnaps-leaderboard-user-v3>
                    </div>
                </ng-container>
                <ng-container *ngIf="outGameLeaderBoardUserList && outGameLeaderBoardUserList.length > 0">
                    <div class="ui one column row center aligned text tiny">
                        {{'BETSNAPDETAIL.RANKING.out_the_game' | translate}}
                    </div>
                    <div class="ui divided list leaderboard-list v3">
                        <betsnaps-leaderboard-user-v3
                            *ngFor="let leaderBoardUser of outGameLeaderBoardUserList; trackBy: leaderBoardUserTrackBy"
                            [game]="game"
                            [gameState]="game.game_state"
                            [leaderBoardUser]="leaderBoardUser"
                            [currentUser]="currentUser"
                            [gameMatches]="gameMatches"
                            class="item leaderboard-list-item"
                            [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                        </betsnaps-leaderboard-user-v3>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="showOnlyFriends">
                <ng-container *ngIf="inGameLeaderBoardFriendUserList && inGameLeaderBoardFriendUserList.length > 0">
                    <div class="ui one column row center aligned text tiny">
                        {{'BETSNAPDETAIL.RANKING.in_the_game' | translate}}
                    </div>
                    <div class="ui divided list leaderboard-list v3">
                        <betsnaps-leaderboard-user-v3
                            *ngFor="let leaderBoardUser of inGameLeaderBoardFriendUserList; trackBy: leaderBoardUserTrackBy"
                            [game]="game"
                            [gameState]="game.game_state"
                            [leaderBoardUser]="leaderBoardUser"
                            [currentUser]="currentUser"
                            [gameMatches]="gameMatches"
                            class="item leaderboard-list-item"
                            [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                        </betsnaps-leaderboard-user-v3>
                    </div>
                </ng-container>
                <ng-container *ngIf="outGameLeaderBoardFriendUserList && outGameLeaderBoardFriendUserList.length > 0">
                    <div class="ui one column row center aligned text tiny">
                        {{'BETSNAPDETAIL.RANKING.out_the_game' | translate}}
                    </div>
                    <div class="ui divided list leaderboard-list v3">
                        <betsnaps-leaderboard-user-v3
                            *ngFor="let leaderBoardUser of outGameLeaderBoardFriendUserList; trackBy: leaderBoardUserTrackBy"
                            [game]="game"
                            [gameState]="game.game_state"
                            [leaderBoardUser]="leaderBoardUser"
                            [currentUser]="currentUser"
                            [gameMatches]="gameMatches"
                            class="item leaderboard-list-item"
                            [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                        </betsnaps-leaderboard-user-v3>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <div *ngIf="leaderBoardUserList && leaderBoardUserList.total > 0 && betsnapdetailService.isTournament && (game.game_state > 2 && game.game_state < 6) || ((isGamePrizeDistributedOrClosed(game) || game.game_state === 99) && showCompactLeaderboard) && game.competition_type !== 5">
            <div class="ui divided list leaderboard-list v3" *ngIf="!showOnlyFriends && game.competition_type !== 5 && showCompactLeaderboard">
                <betsnaps-leaderboard-user-v3
                    *ngFor="let leaderBoardUser of arroundCurrentUserFilter(); trackBy: leaderBoardUserTrackBy"
                    [game]="game"
                    [gameState]="game.game_state"
                    [leaderBoardUser]="leaderBoardUser"
                    [currentUser]="currentUser"
                    [gameMatches]="gameMatches"
                    class="item leaderboard-list-item"
                    [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-v3>
            </div>

            <ng-container *ngIf="!showOnlyFriends
            && ((game.competition_type !== 5 && !showCompactLeaderboard) || (game.competition_type === 5))
            && betsnapdetailService.rankingMenuActiveKey !== 'top'">
                <div class="ui divided list leaderboard-list v3" *ngIf="game.competition_type !== 5">
                    <betsnaps-leaderboard-user-v3
                            *ngFor="let leaderBoardUser of leaderBoardUserList.results; trackBy: leaderBoardUserTrackBy"
                            [game]="game"
                            [gameState]="game.game_state"
                            [leaderBoardUser]="leaderBoardUser"
                            [currentUser]="currentUser"
                            [gameMatches]="gameMatches"
                            class="item leaderboard-list-item"
                            [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                    </betsnaps-leaderboard-user-v3>
                </div>
            </ng-container>
            <div class="ui divided list leaderboard-list v3" *ngIf="!showOnlyFriends && !showCompactLeaderboard && betsnapdetailService.rankingMenuActiveKey === 'top'">
                <betsnaps-leaderboard-user-v3
                        *ngFor="let leaderBoardUser of showTopUsersFilter(100); trackBy: leaderBoardUserTrackBy"
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="leaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item"
                        [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-v3>
            </div>
            <ng-container class="ui divided list leaderboard-list v3" *ngIf="showOnlyFriends">
                <ng-container *ngIf="game.competition_type !== 5 || (game.competition_type === 5 && game.game_state < 3)">
                    <div class="ui divided list leaderboard-list v3">
                        <betsnaps-leaderboard-user-v3
                                *ngFor="let leaderBoardUser of friendLeaderBoardUsers(); trackBy: leaderBoardUserTrackBy"
                                [game]="game"
                                [gameState]="game.game_state"
                                [leaderBoardUser]="leaderBoardUser"
                                [currentUser]="currentUser"
                                [gameMatches]="gameMatches"
                                class="item leaderboard-list-item"
                                [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                        </betsnaps-leaderboard-user-v3>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div *ngIf="leaderBoardUserList && leaderBoardUserList.total > 0 && ((isGamePrizeDistributedOrClosed(game) || game.game_state === 99 || game.game_state < 3) && !showCompactLeaderboard)">
            <div class="ui divided list leaderboard-list v3" *ngIf="!showOnlyFriends">
                <betsnaps-leaderboard-user-v3
                        *ngFor="let leaderBoardUser of leaderBoardUserList.results; trackBy: leaderBoardUserTrackBy"
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="leaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item"
                        [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-v3>
            </div>
            <div class="ui divided list leaderboard-list v3" *ngIf="showOnlyFriends">
                <betsnaps-leaderboard-user-v3
                        *ngFor="let leaderBoardUser of friendLeaderBoardUsers(); trackBy: leaderBoardUserTrackBy"
                        [game]="game"
                        [gameState]="game.game_state"
                        [leaderBoardUser]="leaderBoardUser"
                        [currentUser]="currentUser"
                        [gameMatches]="gameMatches"
                        class="item leaderboard-list-item"
                        [class.currentuser]="currentUser?.id === leaderBoardUser.user_id">
                </betsnaps-leaderboard-user-v3>
            </div>
            <div class="ui basic segment center aligned"
                 *ngIf="!showOnlyFriends && leaderBoardUserList.results?.length < leaderBoardUserList.total && betsnapdetailService.isTournament &&
                        (game.game_state < 3 || this.game.game_state >= 6)"
                 #loadMoreButton>
                <button type="button" class="ui button margin-0" [class.loading]="processLoadMore" (click)="loadMore()">{{'GENERAL.load-more_btn' | translate}}</button>
            </div>
        </div>
        <div class="ui vertical borderless center aligned padded segment margin-t-30" *ngIf="!processList && (!leaderBoardUserList || leaderBoardUserList.total === 0)">
            {{'GENERAL.no_results_found' | translate}}
        </div>
    </div>
    <div class="content" *ngIf="!betsnapdetailService.isTournament && game.game_state >= 3"
         [class.with-profile-header-image]="profileHeaderImage">
        <betsnaps-game-h2h-competitors
            [game]="game"
            [leaderBoardUserList]="leaderBoardUserList"
            [currentUser]="currentUser">
        </betsnaps-game-h2h-competitors>
    </div>
</div>

<betsnaps-go-to-top-button
    [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300">
</betsnaps-go-to-top-button>
