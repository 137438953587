import {Component} from '@angular/core';
import {AMatchCardDetailsComponent} from '../a-match-card-details-component';

@Component({
    selector: 'betsnaps-match-card-details-tennis-v3',
    templateUrl: './match-card-details-tennis-v3.component.html',
    styles: []
})
export class MatchCardDetailsTennisV3Component extends AMatchCardDetailsComponent {

}
