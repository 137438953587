/**
 * BetSnaps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TenantGameFeaturesUpdateRequest
 */
export interface AppHttpRequestsTenantsTenantGameFeaturesUpdateRequest { 
    /**
     * Freemium-Concept
     */
    is_freemium?: boolean;
    /**
     * Publisher Widget enabled
     */
    widgets_publisher_enabled?: boolean;
    /**
     * Allow Multi-Tenant Games
     */
    allow_multi_tenant_games?: boolean;
    /**
     * Allow player created Games
     */
    show_createbetsnaps?: boolean;
    /**
     * Allow player created Head2Head
     */
    allow_h2h?: boolean;
    /**
     * Allow simple snap games
     */
    allow_simple_snaps?: boolean;
    /**
     * Allow battle royal
     */
    allow_battle_royal_snaps?: boolean;
    /**
     * Show Global-Rankings
     */
    show_global_rankings?: boolean;
}

