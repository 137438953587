import {ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AuthenticationService, B2cSignupMethodEnum,
    ErrorService,
    MobiledetectService,
    SocialService,
    TenantService,
    WindowRef,
    TenantImageTypeEnum, GoogleAnalyticsService
} from '../../../../shared';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {ALoginRegisterSocialComponent} from '../a-login-register-social.component';

@Component({
    selector: 'betsnaps-login-form',
    templateUrl: './login-form.component.html',
    styles: []
})
export class LoginFormComponent extends ALoginRegisterSocialComponent {

    public processForm: boolean = false;
    public submitted: boolean = false;

    public loginModalImage: string;

    public loginForm: UntypedFormGroup;

    public loginFormEmail: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
    });

    public loginFormPhone: UntypedFormGroup = new UntypedFormGroup({
        phone_country_code: new UntypedFormControl('', Validators.required),
        phone: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9|\/|\(|\)|\x20]+'), Validators.minLength(4), Validators.maxLength(13)]),
        password: new UntypedFormControl('', Validators.required),
    });

    public passwordVisible: boolean = false;

    public b2cSignupMethod: string = B2cSignupMethodEnum.EMAIL;
    public b2cSignupMethodEnum = B2cSignupMethodEnum;

    public tenantImageTypeEnum = TenantImageTypeEnum;

    @ViewChild('passwordInput', {static: true}) public passwordInputElement: ElementRef;

    constructor(private activatedRoute: ActivatedRoute,
                protected router: Router,
                protected authenticationService: AuthenticationService,
                protected socialService: SocialService,
                private socialAuthService: SocialAuthService,
                public tenantService: TenantService,
                private errorService: ErrorService,
                protected windowRef: WindowRef,
                protected zone: NgZone,
                protected changeDetector: ChangeDetectorRef,
                protected mobileDetect: MobiledetectService,
                protected googleAnalyticsService: GoogleAnalyticsService
    ) {

        super(tenantService, googleAnalyticsService, authenticationService, socialService, windowRef, zone, changeDetector, mobileDetect, router);

        // set login widget image
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE)) {
            this.loginModalImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE).media_url;
        } else {
            this.loginModalImage = './assets/img/i-login-circle.png';
        }

        this.b2cSignupMethod = this.tenantService.tenantData.configuration.b2c_signup_method;
        if (this.b2cSignupMethod === this.b2cSignupMethodEnum.PHONE) {
            this.loadPhoneSignupAllowedCountryCodes();
            this.loginFormPhone.patchValue({'phone_country_code': this.phoneSignupAllowedCountryCodes[0]});
        }

        this.setLoginForm();
    }

    protected setLoginForm(): void {
        switch (this.b2cSignupMethod) {
            case B2cSignupMethodEnum.PHONE:
                this.loginForm = this.loginFormPhone;
                break;
            default:
                this.loginForm = this.loginFormEmail;
        }
    }

    onSubmit() {
        this.processForm = true;

        let username = '';

        switch (this.b2cSignupMethod) {
            case this.b2cSignupMethodEnum.PHONE:
                username = this.loginForm.value.phone_country_code + this.loginForm.value.phone;
                break;
            default:
                username = this.loginForm.value.email;
                break;
        }

        this.authenticationService.login(username, this.loginForm.value.password)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.googleAnalyticsService.trackEvent('login modal', 'login', 'b2c success');
                    this.processForm = false;
                    this.submitted = true;

                    this.GeneralHelpers.doLoginRedirectIfDefined(this.router);
                    this.onFormSuccess.emit();
                },
                error: (err: HttpErrorResponse) => {
                    this.googleAnalyticsService.trackEvent('login modal', 'login', 'b2c failed');
                    this.processForm = false;
                    this.submitted = true;
                    // reset password field on login error
                    this.loginForm.get('password').reset();
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    togglePasswordVisibility(passwordInputElement: ElementRef): boolean {
        if (passwordInputElement.nativeElement.type === 'password') {
            passwordInputElement.nativeElement.type = 'text';
            return true;
        } else {
            passwordInputElement.nativeElement.type = 'password';
            return false;
        }
    }

    public goToB2bLogin(eventCategory: string = 'header') {
        this.googleAnalyticsService.trackEvent(eventCategory, 'login', 'b2b redirect');
        this.nativeWindow.location.href = this.tenantService.getB2bWebsiteLoginUrl();
        return false;
    }
}
