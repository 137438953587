import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AlertMessage,
    AuthenticationService,
    ErrorService, GoogleAnalyticsService, TenantService
} from '../../../../shared';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ALoginRegisterComponent} from '../a-login-register.component';

@Component({
    selector: 'betsnaps-reset-password-email-form',
    templateUrl: './reset-password-email-form.component.html',
    styles: []
})
export class ResetPasswordEmailFormComponent extends ALoginRegisterComponent implements OnInit {

    public processForm: boolean = false;
    public showAlert: boolean = false;

    public alertMessage: AlertMessage;

    public initPasswordReset: UntypedFormGroup = new UntypedFormGroup({
        passwordreset_identifier: new UntypedFormControl('', [Validators.required]),
    });

    constructor(protected tenantService: TenantService,
                private authenticationService: AuthenticationService,
                private errorService: ErrorService,
                protected googleAnalyticsService: GoogleAnalyticsService) {
        super(tenantService, googleAnalyticsService);
    }

    onSubmit() {
        this.processForm = true;
        this.authenticationService.initiatePasswordEmailReset(this.initPasswordReset.value.passwordreset_identifier)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.processForm = false;
                    this.initPasswordReset.reset();

                    this.alertMessage = {
                        type: 'success',
                        messagetranslatekey: 'RESETPASSWORD.initiate_successfully_title',
                        messagetxttranslatekey: 'RESETPASSWORD.initiate_successfully'
                    };
                    this.showAlert = true;

                },
                error: (err: HttpErrorResponse) => {
                    this.processForm = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            });
    }

    ngOnInit() {
    }
}
