import { ComponentRef, Input, OnDestroy, OnInit, ViewContainerRef, Directive } from '@angular/core';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameHttpResponse as GameVendorGameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
} from '../../../api';

import {GameVendorGameParticipationStatusEnum, GameVendorGameStatusEnum} from '../../../shared/enums';
import {AdService, AuthenticationService, BetsnapdetailService, ErrorService, TenantService} from '../../../shared';
import {TenantContentComponent} from '../tenant-content/tenant-content.component';

@Directive()
export abstract class AGameVendorGameCardComponent implements OnInit, OnDestroy {

    @Input() gameIndexInList: number;
    @Input() adPositions: number[];

    protected componentAlive = true;

    public gameUpdatedDate: Date;
    public game: GameHttpResponse;
    public isCurrentUserJoined: boolean;

    public gameVendorGame: GameVendorGameHttpResponse;
    public gameVendorGameStatus: number;

    public gameVendorGameStatusEnum = GameVendorGameStatusEnum;
    public gameVendorGameParticipationStatusEnum = GameVendorGameParticipationStatusEnum;

    public processParticipation: boolean = false;

    public loadingParticipations: boolean = false;

    private adComponentRef: ComponentRef<TenantContentComponent>;

    constructor(protected viewContainerRef: ViewContainerRef,
                protected authenticationService: AuthenticationService,
                protected betsnapdetailService: BetsnapdetailService,
                public tenantService: TenantService,
                protected errorService: ErrorService,
                protected adService: AdService) {
    }

    ngOnInit() {
        // place ad
        const adSlotPosition: number = this.gameIndexInList + 1;
        this.adComponentRef = this.adService.placeTenantAd('content', adSlotPosition, this.viewContainerRef, this.adPositions, this.gameIndexInList, 'vendorGameCard');
    }

    ngOnDestroy() {
        this.componentAlive = false;
        if (this.adComponentRef) {
            this.adComponentRef.destroy();
        }
    }

    public gameVendorGameParticipationsTrackBy(index: number, gameVendorGameParticipation: GameVendorGameParticipationHttpResponse): number {
        return gameVendorGameParticipation.id;
    }

    public isGameVendorGameCompleted(): boolean {
        return (
            this.gameVendorGame.status === this.gameVendorGameStatusEnum.FINISHED ||
            this.gameVendorGame.status === this.gameVendorGameStatusEnum.CLOSED ||
            this.gameVendorGame.status === this.gameVendorGameStatusEnum.CANCELLED
        );
    }

}
