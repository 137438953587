import { AfterContentChecked, AfterViewChecked, DoCheck, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import {
    AuthenticationService,
    BetsnapdetailService,
    DebugService,
    ErrorService,
    FriendsService,
    GameLeaderboardViewEnum,
    GoogleAnalyticsService,
    HintService,
    LoggerService,
    MyModalService,
    MyTranslateService,
    TenantService,
    WindowRef
} from '../../../../shared';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse as LeaderboardUserListHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse
} from '../../../../api';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import * as moment from 'moment';
import {HttpErrorResponse} from '@angular/common/http';
import {filter, take, takeWhile} from 'rxjs/operators';

interface RankingMenuItem {
    'key': string;
    'translatekey': string;
    'active': boolean;
}

@Directive()
export abstract class ABetsnapdetailRankingComponent implements OnInit, OnDestroy, DoCheck, AfterContentChecked, AfterViewChecked {

    protected componentAlive = true;
    protected componentInForeground = false;
    protected componentUrl: string;

    public game: GameHttpResponse;
    public gameLoaded: boolean = false;
    public gameMatches: GameMatchHttpResponse[];

    public currentUser: PlayerHttpResponse;
    public currentLeaderBoardUser: LeaderboardUserHttpResponse;

    public leaderBoardUserList: LeaderboardUserListHttpResponse;
    protected leaderBoardFriendUserList: LeaderboardUserHttpResponse[];
    protected forceReloadLeaderBoardFriendUserListOnTabChange = false;

    public showCompactLeaderboard: boolean = false;
    public showCompactLeaderboardTransition: boolean = false;

    public rankingMenu: RankingMenuItem[];
    public showOnlyFriends: boolean = false;
    public processList: boolean = true;

    public inGameLeaderBoardUserList: LeaderboardUserHttpResponse[];
    public outGameLeaderBoardUserList: LeaderboardUserHttpResponse[];
    public inGameLeaderBoardFriendUserList: LeaderboardUserHttpResponse[];
    public outGameLeaderBoardFriendUserList: LeaderboardUserHttpResponse[];

    @ViewChild('loadMoreButton') public loadMoreButton: ElementRef;

    protected nativeWindow: Window;

    protected componentRoute;

    public currentUserPositionLeft: boolean = true;

    // Pagination
    protected perPage = 15;
    protected currentPage = 1;
    public processLoadMore: boolean = false;

    protected measureProcessTime: boolean = false;

    protected constructor(protected authenticationService: AuthenticationService,
                          public betsnapdetailService: BetsnapdetailService,
                          public tenantService: TenantService,
                          protected router: Router,
                          public translations: MyTranslateService,
                          protected friendsService: FriendsService,
                          protected myModalService: MyModalService,
                          protected hintService: HintService,
                          public googleAnalyticsService: GoogleAnalyticsService,
                          protected debugService: DebugService,
                          protected errorService: ErrorService,
                          protected loggerService: LoggerService,
                          protected windowRef: WindowRef) {

        this.currentUser = this.authenticationService.currentUser;
        this.nativeWindow = windowRef.nativeWindow;

        this.componentRoute = this.router.url;

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (gameHttpResponse) {
                        if (this.checkForPlayersRouteRedirect(gameHttpResponse)) {
                            this.router.navigate(['/betsnapdetail/' + gameHttpResponse.game_unique_id + '/players']);
                            return;
                        } else {
                            this.checkRankingMenuState(gameHttpResponse);
                            this.game = gameHttpResponse;

                            if (this.game.is_current_user_joined) {
                                if (!this.currentLeaderBoardUser) {
                                    this.currentLeaderBoardUser = this.game.game_user;
                                }
                            } else {
                                this.currentLeaderBoardUser = null;
                            }

                            this.showCompactLeaderboard = (
                                this.shouldRenderCompactLeaderBoard() ||
                                this.showCompactLeaderboardTransition
                            );

                            if (this.leaderBoardUserList) {
                                if (this.isGamePrizeDistributedOrClosed(this.game) && this.betsnapdetailService.isTournament) {
                                    this.handleStateTransitionFromFinishedToPrizeDistributed();
                                }
                            } else if (!this.leaderBoardUserList) {
                                if (this.shouldGetPaginatedLeaderBoard()) {
                                    this.getPaginatedLeaderBoard(this.game);
                                } else if (this.showCompactLeaderboard) {
                                    this.betsnapdetailService.getFullLeaderBoard(this.game, GameLeaderboardViewEnum.COMPACT);
                                } else {
                                    this.betsnapdetailService.getFullLeaderBoard(this.game);
                                }
                            }

                            if (!this.gameLoaded) {
                                this.betsnapdetailService.rankingMenuActiveKey = this.getDefaultRankingMenuActiveKey();
                            }
                            // set the ranking menu filter
                            this.getRankingMenuFilter();

                            if (!this.gameMatches && this.game.game_state === 7) {
                                // load matches from api
                                this.betsnapdetailService.getGameMatches(this.game.game_unique_id);
                            }

                            if (this.game.competition_type === 3) {
                                this.currentUserPositionLeft = (this.game.game_user_group_competitors && this.game.game_user_group_competitors[0].id === this.currentUser.id);
                            }
                        }

                        if (!this.gameLoaded) {
                            this.handleRankingMenuChange(this.betsnapdetailService.rankingMenuActiveKey);

                            if (this.game.is_current_user_joined && this.game.game_user) {
                                const hintConditionValues = {
                                    is_participation_valid: this.game.game_user.is_participation_valid,
                                    is_eliminated: this.game.game_user.is_eliminated
                                };
                                this.hintService.checkForHintToDisplay('betsnapdetail-ranking', hintConditionValues);
                            }

                            this.betsnapdetailService.leaderboardUserList$
                                .pipe(takeWhile(() => this.componentAlive))
                                .subscribe(
                                    (leaderboardUserListHttpResponse: LeaderboardUserListHttpResponse) => {
                                        if (leaderboardUserListHttpResponse) {

                                            this.measureProcessTime = true;

                                            this.loggerService.endMeasurement('forwardLeaderboardDataToComponent');
                                            this.debugService.writeMessageToConsoleLog(
                                                'leaderboard received in component: ' + moment().utc().format('DD.MM.YYYY HH:mm:ss.SSS')
                                            );

                                            this.filterData(leaderboardUserListHttpResponse);
                                            this.leaderBoardUserList = leaderboardUserListHttpResponse;
                                            this.getInAndOutGameLeaderBoardUserList();
                                        }
                                    }
                                );

                            this.betsnapdetailService.leaderboardFriendUserList$
                                .pipe(takeWhile(() => this.componentAlive))
                                .subscribe(
                                    (leaderboardUserListHttpResponse: LeaderboardUserListHttpResponse) => {
                                        if (leaderboardUserListHttpResponse) {
                                            this.leaderBoardFriendUserList = leaderboardUserListHttpResponse.results;
                                            this.getInAndOutGameLeaderBoardUserList(true);
                                        }
                                    }
                                );

                            this.gameLoaded = true;
                        }
                    }
                }
            );

        this.betsnapdetailService.gameMatches$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameMatchListResults: GameMatchHttpResponse[]) => {
                    if (gameMatchListResults) {
                        this.gameMatches = gameMatchListResults;
                    }
                }
            );

    }

    ngOnInit() {
        // check when reused component is current navigation
        this.componentUrl = this.router.url;
        this.router.events
            .pipe(
                filter(routerEvent => (routerEvent instanceof NavigationStart || routerEvent instanceof NavigationEnd)),
                takeWhile(() => this.componentAlive)
            ).subscribe((routerEvent) => {
            if (routerEvent instanceof NavigationEnd && routerEvent.urlAfterRedirects === this.componentUrl) {
                this.componentInForeground = true;
                if (this.checkForPlayersRouteRedirect(this.game)) {
                    this.router.navigate(['/betsnapdetail/' + this.game.game_unique_id + '/players']);
                    return;
                }
            } else if (routerEvent instanceof NavigationStart && this.componentInForeground) {
                this.componentInForeground = false;
            }
        });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    ngDoCheck() {
        if (this.measureProcessTime) {
            this.loggerService.startMeasurement('renderLeaderboardView');
            this.debugService.writeMessageToConsoleLog(
                'leaderboardView ngDoCheck: ' + moment().utc().format('DD.MM.YYYY HH:mm:ss.SSS')
            );
        }
    }

    ngAfterContentChecked() {
        if (this.measureProcessTime) {
            this.debugService.writeMessageToConsoleLog(
                'leaderboardView ngAfterContentChecked: ' + moment().utc().format('DD.MM.YYYY HH:mm:ss.SSS')
            );
        }
    }

    ngAfterViewChecked() {
        if (this.measureProcessTime && this.game) {
            this.loggerService.endMeasurement('renderLeaderboardView');
            this.debugService.writeMessageToConsoleLog(
                'leaderboardView ngAfterViewChecked: ' + moment().utc().format('DD.MM.YYYY HH:mm:ss.SSS')
            );
            this.loggerService.endMeasurement('total');
            const logGameData = {
                'game_unique_id': this.game.game_unique_id,
                'game_state': this.game.game_state,
                'responseSize': (this.leaderBoardUserList) ? (JSON.stringify(this.leaderBoardUserList).length / 1024).toFixed(3) + ' kb' : 0,
                'total': (this.loggerService.getMeasurement('total')) ? this.loggerService.getMeasurement('total').duration : 0
            };
            this.loggerService.finishCurrentMeasurement(logGameData);
            this.measureProcessTime = false;
        }
    }

    filterData(leaderBoardUserList: LeaderboardUserListHttpResponse = null) {
        if (leaderBoardUserList === null) {
            leaderBoardUserList = this.leaderBoardUserList;
        }
        if (this.measureProcessTime) {
            this.loggerService.startMeasurement('filterLeaderboardData');
            this.debugService.writeMessageToConsoleLog(
                'filterLeaderboard start: ' + moment().utc().format('DD.MM.YYYY HH:mm:ss.SSS')
            );
        }

        if (this.game.is_current_user_joined) {
            const currentUserInLeaderBoard = leaderBoardUserList.results.find(
                (leaderBoardUser: LeaderboardUserHttpResponse) => leaderBoardUser.user_id === this.game.game_user.user_id);

            if (currentUserInLeaderBoard) {
                this.currentLeaderBoardUser = currentUserInLeaderBoard;
            }
        }

        if (this.betsnapdetailService.isTournament && this.allLeaderboardUsersAlreadyLoaded(this.game, leaderBoardUserList)) {
            this.copyFriendUsersFromLeaderBoardUserList(this.game, leaderBoardUserList);
        }

        if (this.game.competition_type === 3 && this.game.game_state >= 3) {
            leaderBoardUserList.results = leaderBoardUserList.results.filter(
                (leaderBoardUser) => (leaderBoardUser.game_user_group && leaderBoardUser.game_user_group.group_id === this.game.game_user.game_user_group.group_id)
            );
            leaderBoardUserList.total = leaderBoardUserList.results.length;
        }

        this.processList = false;

        if (this.measureProcessTime) {
            this.loggerService.endMeasurement('filterLeaderboardData');
            this.debugService.writeMessageToConsoleLog(
                'filterLeaderboard end: ' + moment().utc().format('DD.MM.YYYY HH:mm:ss.SSS')
            );
        }
    }

    protected getPaginatedLeaderBoard(game: GameHttpResponse, reset: boolean = false) {
        this.betsnapdetailService.getPaginatedLeaderBoard(
            game,
            this.perPage,
            this.currentPage,
            false,
            reset
        ).pipe(take(1))
        .subscribe({
            next: (leaderboardUserListHttpResponse: LeaderboardUserListHttpResponse) => {
                this.leaderBoardUserList = leaderboardUserListHttpResponse;

                if (this.betsnapdetailService.isTournament && this.allLeaderboardUsersAlreadyLoaded(game)) {
                    this.copyFriendUsersFromLeaderBoardUserList(game);
                }

                this.processList = false;

            },
            error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
        });
    }

    protected getFriendLeaderBoard(gameHttpResponse: GameHttpResponse) {
        this.betsnapdetailService.loadFriendLeaderboardUsers(gameHttpResponse)
            .pipe(take(1)).subscribe(
            (leaderboardUserListHttpResponse: LeaderboardUserListHttpResponse) => {
                if (leaderboardUserListHttpResponse) {

                    this.betsnapdetailService.storePublicPlayersFromLeaderboardUserslist(leaderboardUserListHttpResponse);

                    this.leaderBoardFriendUserList = leaderboardUserListHttpResponse.results;
                    this.getInAndOutGameLeaderBoardUserList(true);
                    this.forceReloadLeaderBoardFriendUserListOnTabChange = false;

                    this.processList = false;
                }
            });
    }

    rankingMenuChange(activateKey: string) {
        this.processList = true;
        for (const menuitem of this.rankingMenu) {
            menuitem.active = (menuitem.key === activateKey);
        }
        this.handleRankingMenuChange(activateKey);
        this.betsnapdetailService.rankingMenuActiveKey = activateKey;

        if ((this.betsnapdetailService.isTournament && this.showOnlyFriends && !this.leaderBoardFriendUserList) || this.forceReloadLeaderBoardFriendUserListOnTabChange) {
            this.getFriendLeaderBoard(this.game);
        } else {
            this.processList = false;
        }

        this.googleAnalyticsService.trackEvent('game - ranking', 'filter', 'ranking - ' + activateKey);
    }

    isGamePrizeDistributedOrClosed(game: GameHttpResponse): boolean {
        return (game.game_state === 6 || game.game_state === 7);
    }

    leaderBoardUserTrackBy(index: number, leaderBoardUser: LeaderboardUserHttpResponse) {
        if (leaderBoardUser !== undefined) {
            return leaderBoardUser.user_id;
        }
        return 0;
    }

    friendLeaderBoardUsers() {
        if (this.leaderBoardFriendUserList) {
            return this.leaderBoardFriendUserList;
        }
    }

    protected handleStateTransitionFromFinishedToPrizeDistributed() {
        if (this.showCompactLeaderboard) {
            this.showCompactLeaderboardTransition = true;
        }
    }

    protected copyFriendUsersFromLeaderBoardUserList(game: GameHttpResponse, leaderBoardUserList: LeaderboardUserListHttpResponse = null) {
        if (leaderBoardUserList === null) {
            leaderBoardUserList = this.leaderBoardUserList;
        }
        if (game.current_user_friends && game.current_user_friends.length > 0) {
            const friendUsers = leaderBoardUserList.results.filter(
                (leaderBoardUser) => this.friendsService.userIsFriend(leaderBoardUser.user_id)
            );

            if (friendUsers && friendUsers.length > 0) {
                this.leaderBoardFriendUserList = friendUsers;
                this.getInAndOutGameLeaderBoardUserList(true);
            }
        }
    }

    arroundCurrentUserFilter() {
        if (this.leaderBoardUserList) {

            const arroundCurrentUser = [];

            let startPosition = 0;
            let endPosition = 9;

            if (this.game.is_current_user_joined && this.currentLeaderBoardUser) {
                const currentUsersPosition = this.leaderBoardUserList.results.indexOf(this.currentLeaderBoardUser);

                if (currentUsersPosition >= 5) {
                    startPosition = currentUsersPosition - 5;
                    endPosition = startPosition + 10;
                } else {
                    endPosition = 10;
                }
            }

            const lastPosition = this.leaderBoardUserList.count - 1;

            if (endPosition > lastPosition) {
                startPosition -= (endPosition - lastPosition);
                if (startPosition < 0) {
                    startPosition = 0;
                }
                endPosition = lastPosition;
            }

            let showPosition = startPosition;
            while (showPosition <= endPosition) {
                arroundCurrentUser.push(this.leaderBoardUserList.results[showPosition]);
                showPosition++;
            }

            return arroundCurrentUser;
        }
        return null;
    }

    showTopUsersFilter(usersCount: number = this.perPage) {
        if (this.leaderBoardUserList) {

            const topUsers = [];

            let startPosition = 0;
            let endPosition = usersCount - 1;

            const lastPosition = this.leaderBoardUserList.count - 1;

            if (endPosition > lastPosition) {
                startPosition -= (endPosition - lastPosition);
                if (startPosition < 0) {
                    startPosition = 0;
                }
                endPosition = lastPosition;
            }

            let showPosition = startPosition;
            while (showPosition <= endPosition) {
                topUsers.push(this.leaderBoardUserList.results[showPosition]);
                showPosition++;
            }

            return topUsers;
        }
        return null;
    }

    protected allLeaderboardUsersAlreadyLoaded(game: GameHttpResponse, leaderBoardUserList: LeaderboardUserListHttpResponse = null): boolean {
        if (leaderBoardUserList === null) {
            leaderBoardUserList = this.leaderBoardUserList;
        }
        return leaderBoardUserList.results.length === game.users_count;
    }

    protected loadPaginatedLeaderboardDueToTransition() {
        this.processList = true;
        this.getPaginatedLeaderBoard(this.game);

        this.forceReloadLeaderBoardFriendUserListOnTabChange = true;
        this.showCompactLeaderboard = false;
        this.showCompactLeaderboardTransition = false;
    }

    loadMore(): void {
        if (this.leaderBoardUserList.results.length < this.leaderBoardUserList.total) {
            this.processLoadMore = true;
            this.currentPage = this.currentPage + 1;
            this.betsnapdetailService.getPaginatedLeaderBoard(
                this.game,
                this.perPage,
                this.currentPage,
                this.showOnlyFriends
            ).pipe(take(1))
            .subscribe({
                next: (leaderboardUserListHttpResponse: LeaderboardUserListHttpResponse) => {
                    this.leaderBoardUserList.results.push(...leaderboardUserListHttpResponse.results);
                    this.processLoadMore = false;

                    if (this.betsnapdetailService.isTournament && this.allLeaderboardUsersAlreadyLoaded(this.game)) {
                        this.copyFriendUsersFromLeaderBoardUserList(this.game);
                    }

                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
        }
    }

    protected infiniteScroll() {
        if (typeof this.loadMoreButton !== 'undefined' && this.leaderBoardUserList.results.length < this.leaderBoardUserList.total) {
            // check if loadmore button is coming to view
            const windowHeight = this.nativeWindow.window.innerHeight;
            const elementTop = this.loadMoreButton.nativeElement.getBoundingClientRect().top;
            const scrollOffset = (windowHeight / 3);
            if (!this.processLoadMore && !this.processList && ((elementTop - scrollOffset) < windowHeight)) {
                this.loadMore();
            }
        }
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    profileWalletListeners() {
        this.infiniteScroll();
    }

    protected abstract getRankingMenuFilter();

    showTopRankedLeaderboard() {}

    checkForPlayersRouteRedirect(gameHttpResponse: GameHttpResponse) {
        return (!this.game || this.componentInForeground) && (
            (gameHttpResponse.competition_type === 3 && !gameHttpResponse.is_current_user_joined) ||
            gameHttpResponse.game_state < 3 || gameHttpResponse.game_state === 99);
    }

    shouldRenderCompactLeaderBoard() {
        return this.betsnapdetailService.shouldRenderCompactLeaderBoard(this.game);
    }

    shouldGetPaginatedLeaderBoard() {
        return this.isGamePrizeDistributedOrClosed(this.game) && this.game.competition_type === 2;
    }

    handleRankingMenuChange(activateKey: string) {
        if (activateKey === 'all') {
            this.showOnlyFriends = false;
        } else if (activateKey === 'friends') {
            this.showOnlyFriends = true;
        }
    }

    getDefaultRankingMenuActiveKey() {
        return 'all';
    }

    checkRankingMenuState(game: GameHttpResponse) {}

    protected getInAndOutGameLeaderBoardUserList(getFriendsList: boolean = false): void {
        if (this.game.competition_type === 5 && this.game.game_state > 2) {
            if (getFriendsList) {
                this.inGameLeaderBoardFriendUserList = this.leaderBoardFriendUserList.filter((leaderboardUser: LeaderboardUserHttpResponse) =>
                    leaderboardUser.lost_bets_count === 0 && !leaderboardUser.is_eliminated && leaderboardUser.is_participation_valid !== false
                );
                this.outGameLeaderBoardFriendUserList = this.leaderBoardFriendUserList.filter((leaderboardUser: LeaderboardUserHttpResponse) =>
                    leaderboardUser.lost_bets_count > 0 || leaderboardUser.is_eliminated || leaderboardUser.is_participation_valid === false
                );
            } else {
                this.inGameLeaderBoardUserList = this.leaderBoardUserList.results.filter((leaderboardUser: LeaderboardUserHttpResponse) =>
                    leaderboardUser.lost_bets_count === 0 && !leaderboardUser.is_eliminated && leaderboardUser.is_participation_valid !== false
                );
                this.outGameLeaderBoardUserList = this.leaderBoardUserList.results.filter((leaderboardUser: LeaderboardUserHttpResponse) =>
                    leaderboardUser.lost_bets_count > 0 || leaderboardUser.is_eliminated || leaderboardUser.is_participation_valid === false
                );
            }
        }
    }
}
