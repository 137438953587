<div class="ui centered relaxed grid container margin-t-0 margin-b-0">
    <div class="row padding-0">
        <div class="four wide tablet or lower hidden column sidebar left-sidebar" #leftSidebar>
            <div class="sticky-container with-skyscraper" #leftSidebarSticky>
                <betsnaps-tenant-content [adType]="'sidebar-left-skyscraper'" [adScriptId]="'sidebar-left-skyscraper'"></betsnaps-tenant-content>
            </div>
        </div>
        <div class="sixteen wide mobile ten wide tablet eight wide computer column">
            <div class="ui fluid flexauto item tiny separatorless menu" *ngIf="casinoSportAvailable && availableSportIds.length > 0">
                <a href="#" class="item" *ngFor="let menu of arenaMenu" [class.active]="menu.active" (click)="arenaMenuChange(menu.key, $event);">
                    {{menu.translatekey | translate | uppercase}}
                </a>
            </div>

            <div class="ui vertical borderless segment center aligned padding-t-0" *ngIf="updatedDataAvailable">
                <button type="button"
                        class="ui basic icon fluid button margin-0"
                        (click)="reloadData()">
                    <i class="refresh icon"></i> {{'ARENA.refresh_games_btn' | translate}}
                </button>
            </div>

            <div class="ui vertical borderless very padded segment" *ngIf="processList">
                <div class="ui active centered inline text loader on-body-bg">
                    {{'GENERAL.loadertext' | translate}}
                </div>
            </div>

            <div *ngIf="!processList && gameList && singlePlayerTenantVendorGameList">

                <swiper class="singleplayer-games-swiper" [config]="singlePlayerSwiperConfig"
                        *ngIf="(singlePlayerTenantVendorGameList.total > 0 && activeTab !== 'sports' && showSinglePlayerSwiper) ||
                               (highlightGameList?.total > 0 && activeTab !== 'casino' && showHighlightSwiper)">
                    <div class="swiper-wrapper">
                        <ng-container *ngIf="highlightGameList?.total > 0 && activeTab !== 'casino' && showHighlightSwiper">
                            <div class="swiper-slide" *ngFor="let highlightGame of highlightGameList.results; trackBy: highlightGameTrackBy; let gameIndexInList = index">
                                <div *ngIf="highlightGame.sport_id !== 999">
                                    <betsnaps-game-card-loader [gameIndexInList]="gameIndexInList"
                                                               [game]="highlightGame"
                                                               [currentUser]="currentUser"
                                                               [isPartOfList]="true">
                                    </betsnaps-game-card-loader>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="singlePlayerTenantVendorGameList.total > 0 && activeTab !== 'sports' && showSinglePlayerSwiper">
                            <div class="swiper-slide" *ngFor="let tenantVendorGame of singlePlayerTenantVendorGameList.results; trackBy: tenantVendorGameTrackBy;">
                                <betsnaps-singleplayer-game-card
                                    [tenantVendorGame]="tenantVendorGame">
                                </betsnaps-singleplayer-game-card>
                            </div>
                        </ng-container>
                    </div>
                    <div class="singleplayer-games-swiper-navigation-button singleplayer-games-swiper-navigation-button-prev">
                        <i class="navigate before huge icon"></i>
                    </div>
                    <div class="singleplayer-games-swiper-navigation-button singleplayer-games-swiper-navigation-button-next">
                        <i class="navigate next huge icon"></i>
                    </div>
                    <div class="swiper-bottom">
                        <div class="singleplayer-games-swiper-pagination center aligned"></div>
                    </div>
                </swiper>

                <h3 class="text-large margin-b-10 margin-t-0" *ngIf="singlePlayerTenantVendorGameList.total > 0">
                    {{'GENERAL.LABELS.snaps' | translate}}
                </h3>

                <a routerLink="/createsnap" class="ui fluid large button mobile only margin-b-15"
                   *ngIf="currentUser && tenantService.tenantData.configuration.show_createbetsnaps">
                    <i class="add circle big icon"></i>{{'ROUTEDATA.PAGETITLES.createsnap' | translate | uppercase}}
                </a>

                <betsnaps-game-list [gameList]="gameList"
                                    [currentUser]="currentUser"
                                    [adPositions]="adPositions"
                                    [showHeaderDivider]="false"
                                    [showNoResultsMessage]="!showEmptyArenaAd">
                </betsnaps-game-list>

                <div class="ui basic segment center aligned"
                     *ngIf="gameList?.results.length < gameList.total"
                     #loadMoreButton>
                    <button type="button"
                            class="ui button margin-0"
                            [class.loading]="processLoadMore"
                            (click)="loadMore()">
                        {{'GENERAL.load-more_btn' | translate}}
                    </button>
                </div>
            </div>
            <div [hidden]="!showEmptyArenaAd">
                <ng-container #emptyArenaPlaceholder></ng-container>
            </div>
        </div>
        <div class="mobile hidden six wide tablet four wide computer column sidebar right-sidebar general-right-sidebar" #rightSidebar>
            <div class="sticky-container" #rightSidebarSticky>
                <betsnaps-sidebar-create-snap
                    *ngIf="currentUser && tenantService.tenantData.configuration.show_createbetsnaps"></betsnaps-sidebar-create-snap>
                <betsnaps-sidebar-tenant-content></betsnaps-sidebar-tenant-content>
            </div>
        </div>
    </div>
</div>
