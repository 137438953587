import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

import {
    AlertMessage,
    AuthenticationService, ErrorService,
    TenantService,
    GeneralHelpers, GoogleAnalyticsService
} from '../../../shared';
import {take} from 'rxjs/operators';

@Component({
    selector: 'betsnaps-b2b-login',
    templateUrl: './b2b-login.component.html',
    styles: []
})
export class B2bLoginComponent implements OnInit {
    @HostBinding('class') componentClass = 'standardview';

    public showSpinner: boolean = false;
    public showAlert: boolean = false;

    public alertMessage: AlertMessage;
    private GeneralHelpers = new GeneralHelpers();

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private errorService: ErrorService,
                private googleAnalyticsService: GoogleAnalyticsService) {
    }

    login(token: string) {
        this.showSpinner = true;
        this.authenticationService.b2blogin(token)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.googleAnalyticsService.trackEvent('home', 'login', 'b2b success');
                    this.showSpinner = false;
                    if (localStorage.getItem('loginRedirect')) {
                        this.GeneralHelpers.doLoginRedirectIfDefined(this.router);
                        return;
                    } else {
                        this.router.navigate(['/arena']);
                        return;
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.googleAnalyticsService.trackEvent('home', 'login', 'b2b failed');
                    this.errorService.handleHttpErrorResponse(err);
                    this.alertMessage = {
                        type: 'error',
                        messagetranslatekey: 'LOGIN.ERRORS.b2b_login_failed',
                        messagetxttranslatekey: 'LOGIN.ERRORS.b2b_login_failed_txt'
                    };

                    this.showAlert = true;
                    this.showSpinner = false;
                }
            });
    }

    ngOnInit() {

        this.showSpinner = false;

        if (!this.tenantService.isB2b) {
            this.router.navigate(['/login']);
            return;
        }

        this.activatedRoute.queryParams
            .pipe(take(1)).subscribe((params: Params) => {

            let token = params['token'];
            let lang = params['lang'];

            if (this.tenantService.b2bLoginType === 'OAUTH2') {
                const state = params['state'];
                if (state && localStorage.getItem('oauth2State') === state) {
                    token = params['code'];
                    this.tenantService.revokeB2bOauth2State();
                }
            }

            if (token) {
                this.login(token);
            } else {
                this.router.navigate(['/']);
                return;
            }
        });
    }

}
